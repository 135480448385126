import { Grid, Typography } from '@material-ui/core';
import React, { CSSProperties } from 'react';

interface EllispsissedTypography {
  width: number | string;
  textStyle?: CSSProperties;
  children?: React.ReactNode;
}

// eslint-disable-next-line no-redeclare
const EllispsissedTypography: React.FC<EllispsissedTypography> = ({ width, textStyle, children }) => {
  return (
    <Grid style={{ width }}>
      <Typography
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...textStyle,
        }}
      >
        {children}
      </Typography>
    </Grid>
  );
};

export default EllispsissedTypography;
