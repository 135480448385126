/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-param-last */
import React, { Reducer, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { useUser } from './UserContext';
import {
  CreateLeaseInventoryEncodingInput,
  UpdateLeaseInventoryEncodingInput,
  LeaseInventoryEncoding,
  cleanInputCreate,
  cleanInputUpdate,
  getReadId,
  getTableClientId,
  syncLeaseInventoryEncodings as syncQuery,
  getLeaseInventoryEncoding as getQuery,
  createLeaseInventoryEncoding as createMutation,
  updateLeaseInventoryEncoding as updateMutation,
  deleteLeaseInventoryEncoding as deleteMutation,
  CreateLeaseInventoryEncodingMutationVariables as CreateMutationVariables,
  DeleteLeaseInventoryEncodingMutationVariables as DeleteMutationVariables,
  CreateLeaseInventoryEncodingInput as CreateInput,
  Model,
  File,
  LeaseInventoryCheckedItem,
} from '@rentguru/commons-utils';
import { isEmpty, isNil } from 'lodash';
import { usePermissions } from './utils/PermissionsContext';
import {
  deleteEntityWithFetchBefore,
  getFilterFieldNameForIndex,
  list,
  mutation,
  recordWasUpdated,
} from '@up2rent/fetch-utils';
import { useFiles } from './FilesContext';
import { useLeaseInventoryCheckedItems } from './LeaseInventoryCheckedItemContext';

const ENTITY_MODEL_NAME: Model = 'LeaseInventoryEncoding';

export interface LeaseInventoryEncodingsContext extends LeaseInventoryEncodingState {
  getLeaseInventoryEncoding: (id: string) => LeaseInventoryEncoding | null | undefined;
  createLeaseInventoryEncoding: (
    input: Omit<CreateLeaseInventoryEncodingInput, 'clientId' | 'readId'>
  ) => Promise<LeaseInventoryEncoding>;
  updateLeaseInventoryEncoding: (
    original: LeaseInventoryEncoding,
    updates: UpdateLeaseInventoryEncodingInput
  ) => Promise<LeaseInventoryEncoding>;
  deleteLeaseInventoryEncoding: (id: string) => Promise<LeaseInventoryEncoding | null>;
  deepDeleteLeaseInventoryEncoding: (
    leaseInventoryEncoding: LeaseInventoryEncoding,
    encodingsFiles: File[]
  ) => Promise<void>;
  setFetchLeaseInventoryEncoding: () => void;
}

interface LeaseInventoryEncodingState {
  leaseInventoryEncodings: LeaseInventoryEncoding[] | null;
  loading: boolean;
  lastSync?: Date;
  shouldFetch: boolean;
}

type Action =
  | {
      type: 'SHOULD_FETCH' | 'IS_FETCHING';
    }
  | {
      type: 'ADD_LEASE_INVENTORY_ENCODING';
      payload: { leaseInventoryEncoding: LeaseInventoryEncoding };
    }
  | {
      type: 'FETCHED';
      payload: { leaseInventoryEncodings: LeaseInventoryEncoding[]; error?: string };
    }
  | {
      type: 'ERROR';
      payload: { error: string };
    }
  | {
      type: 'UPDATE_LEASE_INVENTORY_ENCODING';
      payload: { leaseInventoryEncoding: LeaseInventoryEncoding };
    }
  | {
      type: 'DELETE_LEASE_INVENTORY_ENCODING';
      payload: { id: string };
    };

const initialState: LeaseInventoryEncodingState = {
  leaseInventoryEncodings: [],
  loading: false,
  shouldFetch: false,
};

const leaseInventoryEncodingReducer = (
  state: LeaseInventoryEncodingState,
  action: Action
): LeaseInventoryEncodingState => {
  switch (action.type) {
    case 'SHOULD_FETCH':
      if (state.loading) {
        return state;
      }
      return {
        ...state,
        shouldFetch: true,
      };
    case 'IS_FETCHING':
      return {
        ...state,
        loading: true,
      };

    case 'FETCHED':
      if (action.payload.error) {
        return {
          ...state,
          leaseInventoryEncodings: [],
          loading: false,
          shouldFetch: false,
        };
      }
      const leaseInventoryEncodings = action.payload.leaseInventoryEncodings;
      return {
        ...state,
        loading: false,
        shouldFetch: false,
        lastSync: new Date(),
        leaseInventoryEncodings,
      };
    case 'ADD_LEASE_INVENTORY_ENCODING':
      return {
        ...state,
        leaseInventoryEncodings: [...(state.leaseInventoryEncodings ?? []), action.payload.leaseInventoryEncoding],
      };
    case 'UPDATE_LEASE_INVENTORY_ENCODING':
      if (!state.leaseInventoryEncodings) {
        return state;
      }

      const updatedLeaseInventoryEncoding = action.payload.leaseInventoryEncoding;
      const oldRecord = state.leaseInventoryEncodings.find(
        (leaseInventoryEncoding) => leaseInventoryEncoding.id === updatedLeaseInventoryEncoding.id
      );
      if (isNil(oldRecord) || !recordWasUpdated(oldRecord, updatedLeaseInventoryEncoding)) {
        return state;
      }

      return {
        ...state,
        leaseInventoryEncodings: state.leaseInventoryEncodings.map((leaseInventoryEncoding) => {
          if (leaseInventoryEncoding.id === updatedLeaseInventoryEncoding.id) {
            return updatedLeaseInventoryEncoding;
          }
          return leaseInventoryEncoding;
        }),
      };
    case 'DELETE_LEASE_INVENTORY_ENCODING':
      if (!state.leaseInventoryEncodings) {
        return state;
      }

      return {
        ...state,
        leaseInventoryEncodings: state.leaseInventoryEncodings.filter(
          (leaseInventoryEncoding) => leaseInventoryEncoding.id !== action.payload.id
        ),
      };
    default:
      return state;
  }
};

const fetchLeaseInventoryEncodings = async (
  clientId: string,
  additionalFilter?: object
): Promise<LeaseInventoryEncoding[]> => {
  return await list<LeaseInventoryEncoding>(
    syncQuery,
    getFilterFieldNameForIndex('byClientId'),
    getTableClientId(clientId, ENTITY_MODEL_NAME),
    additionalFilter
  );
};

export const LeaseInventoryEncodingsContext = React.createContext<LeaseInventoryEncodingsContext | null>(null);

export const LeaseInventoryEncodingsContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<LeaseInventoryEncodingState, Action>>(
    leaseInventoryEncodingReducer,
    initialState
  );
  const { clientId } = useUser();
  const { inventoryOfFixturesCreationDelete, furnituresInventoryCreationDelete } = usePermissions();
  const { deleteLeaseInventoryCheckedItem, leaseInventoryCheckedItems } = useLeaseInventoryCheckedItems();
  const { deleteFile } = useFiles();

  const { loading, leaseInventoryEncodings } = state;

  const setFetchLeaseInventoryEncoding = () => {
    dispatch({ type: 'SHOULD_FETCH' });
  };

  useEffect(() => {
    const fetchAndSet = async () => {
      dispatch({ type: 'IS_FETCHING' });
      const result = await fetchLeaseInventoryEncodings(clientId!);
      dispatch({ type: 'FETCHED', payload: { leaseInventoryEncodings: result } });
    };
    if (state.shouldFetch) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetch]);

  const getLeaseInventoryEncoding = (id: string) => {
    if (loading || !leaseInventoryEncodings) {
      return null;
    }
    return leaseInventoryEncodings.find((item) => item.id === id);
  };

  const createLeaseInventoryEncoding = async (
    input: Omit<CreateLeaseInventoryEncodingInput, 'clientId' | 'readId'>
  ): Promise<LeaseInventoryEncoding> => {
    const leaseInventoryEncoding = await mutation<LeaseInventoryEncoding, CreateMutationVariables>(createMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateInput),
        clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME),
        readId: getReadId(clientId!, ENTITY_MODEL_NAME),
      },
    });
    dispatch({ type: 'ADD_LEASE_INVENTORY_ENCODING', payload: { leaseInventoryEncoding } });
    return leaseInventoryEncoding;
  };

  const updateLeaseInventoryEncoding = async (
    original: LeaseInventoryEncoding,
    updates: UpdateLeaseInventoryEncodingInput
  ): Promise<LeaseInventoryEncoding> => {
    const result = await mutation<LeaseInventoryEncoding>(updateMutation, {
      input: { id: original.id, _version: original._version, ...cleanInputUpdate(updates, false) },
    });
    dispatch({ type: 'UPDATE_LEASE_INVENTORY_ENCODING', payload: { leaseInventoryEncoding: result } });
    return result;
  };

  const deleteLeaseInventoryEncoding = async (id: string): Promise<LeaseInventoryEncoding | null> => {
    const leaseInventoryEncoding = getLeaseInventoryEncoding(id);
    if (!leaseInventoryEncoding) {
      return null;
    }
    if (!inventoryOfFixturesCreationDelete && !furnituresInventoryCreationDelete) {
      return leaseInventoryEncoding;
    }

    await deleteEntityWithFetchBefore<Pick<LeaseInventoryEncoding, 'id'>, DeleteMutationVariables>(
      { id: leaseInventoryEncoding.id },
      getQuery,
      deleteMutation
    );

    dispatch({ type: 'DELETE_LEASE_INVENTORY_ENCODING', payload: { id: leaseInventoryEncoding.id } });
    return leaseInventoryEncoding;
  };

  const getLeaseInventoryCheckedItemForLeaseInventoryEncoding = (
    leaseInventoryEncodingId: string
  ): LeaseInventoryCheckedItem[] => {
    return (leaseInventoryCheckedItems ?? []).filter(
      (lic) => lic.leaseInventoryEncodingId === leaseInventoryEncodingId
    );
  };

  const deepDeleteLeaseInventoryEncoding = async (
    leaseInventoryEncoding: LeaseInventoryEncoding,
    encodingsFiles: File[]
  ) => {
    const deleteLeaseInventoryEncodingPromises: Promise<
      | LeaseInventoryCheckedItem[]
      | LeaseInventoryEncoding[]
      | File
      | void
      | null
      | LeaseInventoryCheckedItem
      | LeaseInventoryEncoding
    >[] = [];
    // Deleting checked items of encoding
    const checkedItems = getLeaseInventoryCheckedItemForLeaseInventoryEncoding(leaseInventoryEncoding.id);
    checkedItems.forEach((checkedItem) =>
      deleteLeaseInventoryEncodingPromises.push(deleteLeaseInventoryCheckedItem(checkedItem.id))
    );

    // Deleting files of encoding
    encodingsFiles.forEach((ef: File) => {
      if (ef.foreignKey === leaseInventoryEncoding.id) {
        deleteLeaseInventoryEncodingPromises.push(deleteFile(ef));
      }
    });
    // Deleting encoding
    deleteLeaseInventoryEncodingPromises.push(deleteLeaseInventoryEncoding(leaseInventoryEncoding.id));
    await Promise.all(deleteLeaseInventoryEncodingPromises);
  };

  const values = useMemo(
    () => ({
      ...state,
      error: null,
      getLeaseInventoryEncoding,
      createLeaseInventoryEncoding,
      updateLeaseInventoryEncoding,
      deleteLeaseInventoryEncoding,
      deepDeleteLeaseInventoryEncoding,
      setFetchLeaseInventoryEncoding,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <LeaseInventoryEncodingsContext.Provider value={values}>{children}</LeaseInventoryEncodingsContext.Provider>;
};

export const useLeaseInventoryEncodings = (): LeaseInventoryEncodingsContext => {
  const firstRender = useRef<boolean>(false);
  const context = useContext<LeaseInventoryEncodingsContext | null>(LeaseInventoryEncodingsContext);

  if (isNil(context)) {
    throw new Error(
      '`useLeaseInventoryEncodings` hook must be used within a `LeaseInventoryEncodingsContext` component'
    );
  }
  useEffect(() => {
    if (!firstRender.current && isEmpty(context.leaseInventoryEncodings) && isNil(context.lastSync)) {
      context.setFetchLeaseInventoryEncoding();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  return context;
};
