import { Grid, Typography } from '@material-ui/core';
import { Colors, LeaseVariousOperationType } from '@rentguru/commons-utils';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as AddIcon } from 'src/icons/add.svg';
import { ActionButtonSecond, actionButtonSecondStyles } from '@up2rent/ui';
import { OperationFormValue } from '../FormField/AddOperation';
import RentOperationsList from './RentOperationsList';
import AddLeaseVariousOperationDialog from 'src/components/ui/Dialogs/AddLeaseVariousOperationDialog';

interface DiscountsProps {
  unitId: string;
}

export interface AddDiscountFormValues {
  discounts: OperationFormValue[];
}

const Discounts: React.FC<DiscountsProps> = ({ unitId }) => {
  const actionButtonClasses = actionButtonSecondStyles();
  const { formatMessage } = useIntl();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <Grid style={{ margin: '10px 0px 0px 30px', maxWidth: 580 }}>
      <Grid
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginTop: 15,
        }}
      >
        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
          {formatMessage({ id: 'lease.detail.variousOperations.discount' })}
        </Typography>
        <ActionButtonSecond
          className={actionButtonClasses.buttonWithMargin}
          onClick={() => setDialogOpen(true)}
          id="addDiscountButton"
        >
          <AddIcon fill={Colors.BLUE_GREY} /> {formatMessage({ id: 'lease.addLease.addDiscount' })}
        </ActionButtonSecond>
      </Grid>
      <RentOperationsList unitId={unitId} operationType={LeaseVariousOperationType.DISCOUNT} />
      <AddLeaseVariousOperationDialog
        unitId={unitId}
        operationType={LeaseVariousOperationType.DISCOUNT}
        onClose={() => setDialogOpen(false)}
        open={dialogOpen}
      />
    </Grid>
  );
};

export default Discounts;
