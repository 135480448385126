import { TableCell, TableRow, Typography } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import React from 'react';
import { useIntl } from 'react-intl';

const LeaseIndexationHeader = () => {
  const { formatMessage } = useIntl();
  const headerPadding = { paddingLeft: 0, paddingBottom: 10, paddingRight: 0, paddingTop: 10 };
  return (
    <TableRow>
      <TableCell align="left" padding="none" style={{ ...headerPadding, paddingLeft: 20 }}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'lease.addLease.leaseName' })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none" style={{ ...headerPadding }}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'lease.detail.indexation.type' })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none" style={headerPadding}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'settings.indexation.leaseAnniversary' })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none" style={headerPadding}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'tickets.list.status' })}
        </Typography>
      </TableCell>
      <TableCell align="center" padding="none">
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'lease.detail.indexationGeneral.proposedChargeEvolution' })}
        </Typography>
      </TableCell>
      <TableCell align="center" padding="none">
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'lease.detail.indexationGeneral.proposedRentEvolution' })}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default LeaseIndexationHeader;
