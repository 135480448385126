import React, { useState } from 'react';
import { LeaseInventory, LeaseInventoryType, Colors } from '@rentguru/commons-utils';
import { Grid, Typography, Collapse, Divider } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { ReactComponent as AddIcon } from 'src/icons/add.svg';
import { ExtendedUnitInventory } from 'src/hooks/UnitInventoryContext';
import { FilePreviewDialogBundle } from 'src/components/LeaseInventory/LeaseInventoryEncoding/InventoryEncodings';
import FurnitureStructureEncoding from './FurnitureStructureEncoding';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import { TablePlaceHolder , CustomIconButton } from '@up2rent/ui';
import { FurnitureIssueDialogBundle } from './FurnitureInventoryEncoding';

interface FurnitureStructureCollapseProps {
  leaseInventory: LeaseInventory;
  completeUnitInventory: ExtendedUnitInventory;
  unitInventoryIndex: number;
  proposeUnitInventory: (unitInventoryId: string) => void;
  previewFileFunction: (fileBundle: FilePreviewDialogBundle) => void;
  encodeFurnitureIssue: (issueBundle: FurnitureIssueDialogBundle) => void;
  readOnly: boolean;
  divider?: boolean;
}

const FurnitureStructureCollapse: React.FC<FurnitureStructureCollapseProps> = ({
  leaseInventory,
  completeUnitInventory,
  unitInventoryIndex,
  proposeUnitInventory,
  previewFileFunction,
  encodeFurnitureIssue,
  readOnly,
  divider = true,
}) => {
  const { formatMessage } = useIntl();
  const [collapse, setCollapse] = useState<boolean>(false);
  const rotatingIconStyle = (isOpen: boolean): object => ({
    transform: `rotate(${isOpen ? 90 : 0}deg)`,
    fill: Colors.DARK_SLATE_GREY,
    marginRight: 10,
  });
  const entrance = leaseInventory.inventoryType === LeaseInventoryType.FURNITURES_ENTRANCE;
  return (
    <>
      <div style={{ marginTop: 20, marginBottom: 20, marginRight: 30, marginLeft: 30 }}>
        <Grid container onClick={() => setCollapse(!collapse)} alignItems="center" style={{ cursor: 'pointer' }}>
          <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <NavigateNextIcon style={rotatingIconStyle(collapse)} />
            <Typography style={{ fontSize: 14, fontWeight: 800 }}>{completeUnitInventory.roomName}</Typography>
          </Grid>
          <Grid item xs={7} />
          {!readOnly && entrance && (
            <Grid item xs={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CustomIconButton
                onClick={(event) => {
                  event.stopPropagation();
                  proposeUnitInventory(completeUnitInventory.id);
                }}
                size="small"
                Icon={AddIcon}
                iconStyle={{ fill: Colors.DARK_SLATE_GREY }}
              />
            </Grid>
          )}
        </Grid>
        <Collapse in={collapse} mountOnEnter unmountOnExit>
          {isEmpty(completeUnitInventory.structures) && (
            <TablePlaceHolder
              mainText={formatMessage({ id: 'leaseInventory.pieceOfFurniturePlaceholder.mainText' })}
              subText={formatMessage(
                { id: 'leaseInventory.pieceOfFurniturePlaceholder.subText' },
                { readOnly: readOnly || !entrance }
              )}
            />
          )}
          {!isEmpty(completeUnitInventory.structures) &&
            completeUnitInventory.structures.map((inventoryStructure, index) => {
              return (
                <FurnitureStructureEncoding
                  key={inventoryStructure.id}
                  leaseInventory={leaseInventory}
                  completeUnitInventory={completeUnitInventory}
                  structureFieldName={`localUnitInventories[${unitInventoryIndex}].structures[${index}]`}
                  inventoryStructure={inventoryStructure}
                  previewFileFunction={previewFileFunction}
                  encodeFurnitureIssue={encodeFurnitureIssue}
                  readOnly={readOnly}
                />
              );
            })}
        </Collapse>
      </div>
      {divider && <Divider />}
    </>
  );
};

export default FurnitureStructureCollapse;
