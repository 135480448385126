import { TableCell, TableRow, Typography } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import React from 'react';
import { useIntl } from 'react-intl';

const TenantStatementsHeader = () => {
  const { formatMessage } = useIntl();
  const headerPadding = { paddingLeft: 0, paddingBottom: 10, paddingRight: 0, paddingTop: 10 };
  return (
    <TableRow>
      <TableCell align="left" padding="none" style={{ ...headerPadding, paddingLeft: 20 }}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'financial.period' })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none" style={headerPadding}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'building.detail.general.owner' })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none" style={headerPadding}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'building.columnHeader.units' })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none" style={headerPadding}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'tickets.filter.sourceType' })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none" style={headerPadding}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'accounting.statement.statementType' })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none" style={headerPadding}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'transactions.list.amount' })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12, justifyContent: 'flex-end' }}>
          {formatMessage({ id: 'transactions.list.action' })}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default TenantStatementsHeader;
