import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { LoaderButton, ElevatedPaper , ContentHeader } from '@up2rent/ui';
import CustomizedSwitch from '../ui/CustomizedSwitch';
import { useSettings } from '../../hooks/SettingsContext';
import {
  getSettingValueForAutomaticIndexation,
  getSettingObjectIfExist,
  SETTING_AUTOMATIC_INDEXATION,
  Colors,
  Setting,
} from '@rentguru/commons-utils';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { useLeases } from 'src/hooks/LeasesContext';
import { Collapse } from '@material-ui/core';
import FormAlertBox from '../ui/FormAlertBox';
import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar } from 'notistack';

const ChangeIndexationSchema = Yup.object().shape({
  enableIndexation: Yup.boolean().required(),
});

export const createOrActivateIndexationSetting = async (
  settings: Setting[],
  values: { enableIndexation: boolean },
  createSetting: (input: Omit<Setting, 'id' | 'clientId' | 'readId'>) => Promise<Setting>,
  updateSetting: (original: Setting, updates: Partial<Setting>) => Promise<Setting>,
  setStatus: (status?: boolean) => void,
  setSubmitting: (isSubmitting: boolean) => void,
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
  formatMessage: (descriptor: MessageDescriptor) => string
) => {
  const operationsPromises: Promise<Setting | undefined>[] = [];
  const settingIndexation = getSettingObjectIfExist(settings, SETTING_AUTOMATIC_INDEXATION);
  if (isNil(settingIndexation)) {
    operationsPromises.push(
      createSetting({ name: SETTING_AUTOMATIC_INDEXATION, value: values.enableIndexation.toString() })
    );
  } else if ((settingIndexation.value === 'true' || settingIndexation.value === '1') !== values.enableIndexation) {
    operationsPromises.push(
      updateSetting(settingIndexation as Setting, {
        id: settingIndexation.id,
        value: (+values.enableIndexation).toString(),
      })
    );
  }
  await Promise.all(operationsPromises);
  setStatus(true);
  setSubmitting(false);
  enqueueSnackbar(formatMessage({ id: 'settings.changesSaved' }), { variant: 'success' });
};

const IndexationSettings = () => {
  const { formatMessage } = useIntl();
  const { settingsAutomationWrite } = usePermissions();
  const { leases, leasesLoading } = useLeases();
  const { enqueueSnackbar } = useSnackbar();
  const { settings, settingsLoading, settingsError, createSetting, updateSetting } = useSettings();

  if (settingsLoading || leasesLoading || isNil(settings)) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{formatMessage({ id: 'loading' })}</Typography>
      </ElevatedPaper>
    );
  }
  if (settingsError) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{settingsError}</Typography>
      </ElevatedPaper>
    );
  }
  const automaticIndexationEnabled = getSettingValueForAutomaticIndexation(settings);
  const disableForm = !settingsAutomationWrite;
  const hasAtLeastOnLeaseActiveIndexation = leases.some((lease) =>
    lease.units?.some((unitLease) => unitLease.indexation || unitLease.chargesIndexation)
  );
  return (
    <ElevatedPaper style={{ margin: 20, maxWidth: 640, paddingBottom: 0 }}>
      <ContentHeader title={formatMessage({ id: 'settings.indexationMenu' })} />
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      <Formik
        initialValues={{
          enableIndexation: automaticIndexationEnabled,
        }}
        validationSchema={ChangeIndexationSchema}
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          createOrActivateIndexationSetting(
            settings,
            values,
            createSetting,
            updateSetting,
            setStatus,
            setSubmitting,
            enqueueSnackbar,
            formatMessage
          );
        }}
      >
        {({ values, handleBlur, isSubmitting, status, setFieldValue }) => (
          <Form>
            <div style={{ marginLeft: 30, marginRight: 30, width: '50%' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                  {formatMessage({
                    id: 'settings.indexation.action',
                  })}
                </Typography>
                <CustomizedSwitch
                  checked={values.enableIndexation}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(event: any) => {
                    setFieldValue('enableIndexation', event.target.checked);
                  }}
                  onBlur={handleBlur}
                  aria-label="Collapse"
                  color="primary"
                  disableRipple
                  id="enableIndexation"
                  name="enableIndexation"
                  disabled={disableForm}
                />
              </div>
            </div>
            <Typography
              style={{
                marginLeft: 40,
                marginRight: 40,
                marginTop: 5,
                fontSize: '12px',
                lineHeight: '20px',
                color: Colors.CHARCOAL_GREY,
                textAlign: 'left',
              }}
            >
              {formatMessage({ id: 'settings.indexation.explanation' })}
            </Typography>
            <Collapse in={hasAtLeastOnLeaseActiveIndexation && !values.enableIndexation}>
              <FormAlertBox
                messagesGridStyle={{ marginRight: 30 }}
                message1={formatMessage({ id: 'settings.indexation.inactiveIndexationWithIndexedLease' })}
              />
            </Collapse>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            {!disableForm && (
              <div
                style={{
                  marginRight: 30,
                  marginBottom: 20,
                  display: 'flex',
                  float: 'right',
                }}
              >
                <LoaderButton loading={isSubmitting} success={status}>
                  {formatMessage({
                    id: 'save',
                  })}
                </LoaderButton>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </ElevatedPaper>
  );
};

export default IndexationSettings;
