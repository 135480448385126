import Dialog from 'src/components/ui/Dialog';
import React from 'react';
import AddUtilityForm from '../UtilityProviders/AddUtilityForm';

interface AddMeterDialogProps {
  open: boolean;
  id: string;
  onClose: () => void;
  showComplementaryData?: boolean;
  shouldRedirectAfterCreate?: boolean;
}

const AddMeterDialog: React.FC<AddMeterDialogProps> = ({
  id,
  onClose,
  open,
  showComplementaryData,
  shouldRedirectAfterCreate = false,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      fullWidth
      PaperProps={{ style: { borderRadius: 10, maxWidth: 640 } }}
    >
      <AddUtilityForm
        id={id}
        afterSubmit={onClose}
        cancelSubmit={onClose}
        showComplementaryData={showComplementaryData}
        shouldRedirectAfterCreate={shouldRedirectAfterCreate}
      />
    </Dialog>
  );
};

export default AddMeterDialog;
