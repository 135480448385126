import { Grid, MenuItem, Typography, Divider, Menu } from '@material-ui/core';
import Dialog from 'src/components/ui/Dialog';
import isNil from 'lodash/isNil';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  ActionButtonSecond,
  actionButtonSecondStyles,
  TablePlaceHolder,
  MenuItemText,
  ConfirmDialog,
} from '@up2rent/ui';
import { Team, UserTeam, Colors, getContactNameFromObject } from '@rentguru/commons-utils';
import { ReactComponent as TeamSvg } from 'src//icons/team.svg';
import { useUsers } from 'src/hooks/UsersContext';
import { useTeams } from 'src/hooks/TeamsContext';
import AddMemberToTeamForm from 'src/components/Settings/Members/AddMemberToTeamForm';
import isEmpty from 'lodash/isEmpty';
import DropDownIconButton from 'src/components/ui/CustomIconButtons/DropDownIconButton';

interface TeamMembersRowProps {
  teamMember: UserTeam;
  divider?: boolean;
}

const TeamMembersRow: React.FC<TeamMembersRowProps> = ({ teamMember, divider }) => {
  const { formatMessage } = useIntl();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { getUser, updateUser } = useUsers();
  const { getUserTeams, deleteUserTeam } = useTeams();
  const member = teamMember.user!;
  const completeUser = getUser(member.id);
  const userTeams = getUserTeams(member.id);
  if (isNil(completeUser)) return null;
  const isPending = isNil(completeUser.cognitoSub) && isNil(completeUser.facebookSub) && isNil(completeUser.googleSub);
  const memberName = isPending
    ? `${completeUser.email}`
    : completeUser.contact
    ? getContactNameFromObject(completeUser.contact)
    : `${completeUser.firstName} ${completeUser.lastName}`;
  return (
    <>
      <Grid container style={{ marginTop: 15, marginBottom: 15, alignItems: 'center' }}>
        <Grid item xs={4}>
          <Typography style={{ fontWeight: 600, fontSize: 14 }}>{memberName}</Typography>
        </Grid>

        <Grid item xs={3} />
        <Grid
          item
          xs={2}
          style={{ color: Colors.SLATE_GREY, fontSize: 14, display: 'flex', justifyContent: 'flex-end' }}
        >
          <Typography>{!isNil(completeUser.userRole) ? completeUser.userRole.name : ''}</Typography>
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            color: Colors.SLATE_GREY,
            fontSize: 14,
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: 20,
          }}
        >
          <TeamSvg style={{ width: 14, height: 14, fill: Colors.TOWER_GREY, marginRight: 10 }} />
          <Typography>{userTeams.length}</Typography>
        </Grid>
        <Grid item xs={1}>
          {!member.rootUser && <DropDownIconButton onClick={(event) => setAnchorEl(event.currentTarget)} />}
        </Grid>
        <Menu
          id="action-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          style={{ transform: 'translateY(40px)' }}
        >
          <MenuItem
            onClick={() => {
              setDeleteDialogOpen(true);
              setAnchorEl(null);
            }}
          >
            <MenuItemText primary={formatMessage({ id: 'delete' })} deleteOption />
          </MenuItem>
        </Menu>
      </Grid>
      {divider && <Divider />}
      <ConfirmDialog
        mainText={formatMessage({ id: 'settings.editTeam.deleteMember' }, { name: memberName })}
        open={deleteDialogOpen}
        cancelAction={() => setDeleteDialogOpen(false)}
        confirmText={formatMessage({ id: 'lease.detail.action.confirm' })}
        confirmAction={async () => {
          await Promise.all([deleteUserTeam(teamMember.id), updateUser(completeUser, { refreshToken: true })]);
          setDeleteDialogOpen(false);
        }}
        formatMessage={formatMessage}
      />
    </>
  );
};

interface TeamMembersProps {
  team: Team;
}

const TeamMembers: React.FC<TeamMembersProps> = ({ team }) => {
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const actionButtonClasses = actionButtonSecondStyles();

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30 }}>
        <Typography variant="h6">{formatMessage({ id: 'settings.members' })}</Typography>
        <ActionButtonSecond className={actionButtonClasses.buttonWithMargin} onClick={() => setAddDialogOpen(true)}>
          {formatMessage({ id: 'add' })}
        </ActionButtonSecond>
      </div>
      {isEmpty(team.users) && (
        <TablePlaceHolder mainText={formatMessage({ id: 'settings.editTeam.noMemberPlaceHolder' })} subText="" />
      )}

      {team.users!.map((tm, index) => {
        return <TeamMembersRow key={tm.id} teamMember={tm} divider={index !== team.users!.length - 1} />;
      })}
      <Dialog
        PaperProps={{ style: { maxWidth: 'none', borderRadius: 15 } }}
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        scroll="body"
      >
        <AddMemberToTeamForm team={team} onClose={() => setAddDialogOpen(false)} />
      </Dialog>
    </>
  );
};

export default TeamMembers;
