/* eslint-disable @typescript-eslint/no-explicit-any */
import { isNil } from 'lodash';
import { AddEditAgencyRateValues } from '../AddEditAgencyRateForm';

export const handleCheckedItemsInList = (
  setFieldValue: (field: string, value: any) => void,
  field: keyof AddEditAgencyRateValues,
  values: AddEditAgencyRateValues,
  element: string,
  exclusiveElements?: string[]
) => {
  if (values[field] && (values[field] as string[]).includes(element)) {
    setFieldValue(
      field,
      (values[field] as string[]).filter((obj) => obj !== element)
    );
  } else if (exclusiveElements && exclusiveElements.length > 0) {
    setFieldValue(field, [
      ...((values[field] as string[])
        ? (values[field] as string[]).filter((obj) => !exclusiveElements.includes(obj))
        : []),
      element,
    ]);
  } else {
    setFieldValue(field, [...((values[field] as string[]) ? (values[field] as string[]) : []), element]);
  }
};

export const getExclusiveValueInCheckedList = (
  field: keyof AddEditAgencyRateValues,
  values: AddEditAgencyRateValues,
  exclusiveElements: string[]
) => {
  return values[field] && !isNil(values[field] as string[])
    ? (values[field] as string[]).find((obj) => exclusiveElements.includes(obj))
    : undefined;
};

export const isItemCheckedInList = (
  field: keyof AddEditAgencyRateValues,
  values: AddEditAgencyRateValues,
  element: string
): boolean => {
  return Boolean(values[field] && !isNil((values[field] as string[]).find((obj) => obj === element)));
};

export const handleCheckedAllItemsInList = (
  setFieldValue: (field: string, value: any) => void,
  field: keyof AddEditAgencyRateValues,
  entity: any, // So it can be generic :s
  checked: boolean
) => {
  setFieldValue(field, checked ? Object.keys(entity).map((obj) => obj) : []);
};

export const isAllItemCheckedInList = (
  field: keyof AddEditAgencyRateValues,
  values: AddEditAgencyRateValues,
  entity: any
): boolean => {
  if (!values[field]) return false;
  return Object.keys(entity).every((obj) => (values[field] as string[]).includes(obj));
};
