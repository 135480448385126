import React from 'react';
import NotificationBox from './NotificationBox';
import { LeaseExtended, useLeases } from '../../../hooks/LeasesContext';
import { LeaseInventory, LeaseStatus, Lease, DRAFT_BOX_KEY } from '@rentguru/commons-utils';
import LeaseDraftRow from './BoxCustomRows/LeaseDraftRow';
import { useLeaseInventories } from '../../../hooks/LeaseInventoryContext';
import SlicedContent from './SlicedContent';
import LeaseInventoryDraftRow from './BoxCustomRows/LeaseInventoryDraftRow';
import LeaseDraftHeader from './BoxCustomHeaders/LeaseDraftHeader';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import { compareAsc } from 'date-fns';
import TodosLoading from './TodosLoading';

export const ROW_HEIGHT = 27;

const draftSortFunction = (draft1: Lease | LeaseInventory, draft2: Lease | LeaseInventory): number => {
  if (isNil(draft1.createdAt)) return 1;
  if (isNil(draft2.createdAt)) return -1;
  const draftCmp = compareAsc(new Date(draft1.createdAt), new Date(draft2.createdAt));
  if (draftCmp !== 0) return draftCmp;

  const type1 = 'type' in draft1 ? draft1.type : (draft1 as LeaseInventory).inventoryType;
  const type2 = 'type' in draft2 ? draft2.type : (draft2 as LeaseInventory).inventoryType;
  if (isNil(type1)) return 1;
  if (isNil(type2)) return -1;
  return type1.localeCompare(type2);
};

const DashboardDrafts: React.FC = () => {
  const { leases, leasesLoading } = useLeases();
  const { leaseInventories, loading: leaseInventoriesLoading } = useLeaseInventories();
  const { formatMessage } = useIntl();
  if (leasesLoading || leaseInventoriesLoading) {
    return (
      <>
        <TodosLoading />
      </>
    );
  }
  const leaseDrafts = leases.filter((l) => l.status === LeaseStatus.Draft);
  const leaseInventoriesDraft = (leaseInventories ?? []).filter((l) => l.status === LeaseStatus.Draft);
  const drafts = [...leaseDrafts, ...leaseInventoriesDraft].sort(draftSortFunction);

  const draftsToDisplay = drafts.map((draft: LeaseExtended | LeaseInventory) => {
    if ('inventoryType' in draft) {
      return <LeaseInventoryDraftRow key={draft.id} leaseInventory={draft} />;
    }
    return <LeaseDraftRow key={draft.id} lease={draft} />;
  });

  return (
    <NotificationBox
      boxKey={DRAFT_BOX_KEY}
      title={`${formatMessage({ id: 'dashboard.leaseDrafts' })} (${draftsToDisplay.length})`}
    >
      <SlicedContent
        data={draftsToDisplay}
        header={<LeaseDraftHeader />}
        type="table"
        limit={5}
        boxKey={DRAFT_BOX_KEY}
        rowHeight={ROW_HEIGHT}
      />
    </NotificationBox>
  );
};

export default DashboardDrafts;
