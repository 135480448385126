import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import isNil from 'lodash/isNil';
import OwnerStatementsToValidate from './OwnerStatementsToValidate/OwnerStatementsToValidate';
import OwnerStatementsArchived from './OwnerStatementsArchived/OwnerStatementsArchived';
import { StatementContext } from 'src/hooks/StatementContext';
import { Typography } from '@material-ui/core';
import OwnerStatementsFiltersComponent from './OwnerStatementsFilters';
import { Statement, Unit } from '@rentguru/commons-utils';
import { useIntl } from 'react-intl';
import { useUnits } from 'src/hooks/UnitsContext';
import { RouteDestination } from 'src/components/Routes/Routes';

const TO_PREFIX = '';

export const defaultOwnerStatementsRoute = {
  to: `${TO_PREFIX}`,
  component: OwnerStatementsToValidate,
  filterComponent: OwnerStatementsFiltersComponent,
};

export interface OwnerStatementExtended extends Statement {
  units: Unit[];
}

export const ownerStatementsNavItems = [
  {
    to: `${TO_PREFIX}toValidate`,
    labelId: 'accounting.statement.toValidate',
    component: OwnerStatementsToValidate,
    filterComponent: OwnerStatementsFiltersComponent,
  },
  {
    to: `${TO_PREFIX}archived`,
    labelId: 'tickets.menu.archived',
    component: OwnerStatementsArchived,
    filterComponent: OwnerStatementsFiltersComponent,
  },
];

export const extendOwnerStatement = (
  ownerStatement: Statement,
  getUnit: (id: string) => Unit | undefined
): OwnerStatementExtended => {
  const statementUnits = ownerStatement.unitIds
    ? ownerStatement.unitIds.reduce((acc: Unit[], unitId) => {
        const unit = getUnit(unitId);
        if (unit) {
          acc.push(unit);
        }
        return acc;
      }, [])
    : [];
  return { ...ownerStatement, units: statementUnits };
};

interface OwnerStatementsProps
  extends Pick<
    StatementContext,
    | 'ownerStatementsToCheck'
    | 'ownerStatementsArchived'
    | 'statementsLoading'
    | 'createStatement'
    | 'updateStatement'
    | 'deleteStatement'
    | 'recalculateStatement'
  > {}

const OwnerStatements: React.FC<OwnerStatementsProps> = ({
  ownerStatementsToCheck,
  ownerStatementsArchived,
  statementsLoading,
  // createStatement,
  updateStatement,
  deleteStatement,
  recalculateStatement,
}) => {
  const { formatMessage } = useIntl();
  const { getUnit, unitsLoading } = useUnits();
  const match = useRouteMatch<{ section?: string; tabName?: string }>({
    path: `${RouteDestination.ACCOUNTING}/:section?/:tabName?`,
  });

  if (isNil(match)) {
    return null;
  }
  if (statementsLoading || unitsLoading) {
    return <Typography>{formatMessage({ id: 'loading' })}</Typography>;
  }

  const ownerStatementsToCheckExtended = ownerStatementsToCheck.map((ts) => extendOwnerStatement(ts, getUnit));
  const ownerStatementsArchivedExtended = ownerStatementsArchived.map((ts) => extendOwnerStatement(ts, getUnit));
  return (
    <Switch>
      {ownerStatementsNavItems.map((m) => {
        const path = match.path.replace(':tabName?', m.to);
        const Comp = m.component;
        return (
          <Route
            path={path}
            key={m.to}
            exact={true}
            render={() => {
              return (
                <Comp
                  ownerStatementsToCheck={ownerStatementsToCheckExtended}
                  ownerStatementsArchived={ownerStatementsArchivedExtended}
                  // createStatement={createStatement}
                  updateStatement={updateStatement}
                  deleteStatement={deleteStatement}
                  recalculateStatement={recalculateStatement}
                />
              );
            }}
          />
        );
      })}
      <Route
        path="/accounting/ownerStatements"
        key="default-charges"
        render={() => {
          return (
            <defaultOwnerStatementsRoute.component
              ownerStatementsToCheck={ownerStatementsToCheckExtended}
              recalculateStatement={recalculateStatement}
              updateStatement={updateStatement}
              deleteStatement={deleteStatement}
            />
          );
        }}
      />
    </Switch>
  );
};

export default OwnerStatements;
