/* eslint-disable react/no-array-index-key */
/* eslint-disable no-return-assign */
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { isNil } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import AddInfoButton from 'src/components/ui/AddInfoButton';
import { useAdvertisement } from 'src/hooks/PublicationsContext';
import { useUnitInventories } from 'src/hooks/UnitInventoryContext';
import { AmenitiesType, Unit, UnitInventory, UnitInventoryRoomType, Colors } from '@rentguru/commons-utils';
import { getIconOfInventory } from 'src/utils/unitInventoryUtils';
import AlignedTextDetail from '../AlignedTextDetail';
import { EDIT_ADVERTISEMENT_INTERIOR_MENU } from '../EditPublication/EditPublication';
import TextDetailWithFrontIcon from '../TextDetailWithFrontIcon';
import { Skeleton } from '@material-ui/lab';

interface AdvertisementDescriptionInsideProps {
  unit: Unit;
}

const useLocalStyles = makeStyles({
  title: {
    fontSize: 14,
    marginBottom: 20,
  },
});

export const NoRoomsMessage: React.FC = () => {
  const { formatMessage } = useIntl();
  return (
    <Grid
      container
      style={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: Colors.PORCELAIN_GREY_3,
        padding: '30px 0px',
        margin: '0 30px 10px 0',
        borderRadius: 12,
      }}
    >
      <Grid>
        <Typography variant="subtitle2" style={{ fontSize: 16 }}>
          {formatMessage({ id: 'rentalUnit.detail.inside.rooms.noRooms' })}
        </Typography>
      </Grid>
    </Grid>
  );
};

const AdvertisementDescriptionInside: React.FC<AdvertisementDescriptionInsideProps> = ({ unit }) => {
  const { advertisement, loading } = useAdvertisement(
    true,
    unit ? (unit!.unitAdvertisementId ? unit!.unitAdvertisementId! : '') : ''
  );

  const { getUnitInventoriesFor } = useUnitInventories();
  const unitInventories = getUnitInventoriesFor(unit.id);

  const { formatMessage } = useIntl();
  const localClasses = useLocalStyles();
  const everyRoomHasSize: boolean =
    unitInventories.filter((inv) => !isNil(inv.surface) && !isNil(inv.showSurface) && inv.showSurface && inv.inside)
      .length === unitInventories.filter((inv) => !isNil(inv.showSurface) && inv.showSurface && inv.inside).length &&
    unitInventories.filter((inv) => inv.inside).length !== 0; // Will check if every room has a surface.
  // const hasSurface = !isNil(unitInventories.find((inv) => !isNil(inv.surface) && inv.inside));
  // Will check if at least on room has a surface.
  // const oneShown = !isNil(unitInventories.find((inv) => !isNil(inv.showSurface) && inv.showSurface && inv.inside));
  const somethingToShow = !isNil(
    unitInventories.find((inv) => !isNil(inv.surface) && !isNil(inv.showSurface) && inv.showSurface && inv.inside)
  );

  let roomList: Partial<Record<UnitInventoryRoomType, number>> = {};
  const getAcceptableRoomName = (roomType: UnitInventoryRoomType): string => {
    roomList = {
      ...roomList,
      [roomType]: roomList[roomType] ? (roomList[roomType] as number) + 1 : 1,
    };

    return `${formatMessage({ id: `leaseInventory.roomType.${roomType.toString().toLowerCase()}` })} ${
      roomList[roomType] === 1 ? '' : roomList[roomType]
    }`;
  };

  const getSubtitleIfNecessary = (unitInv: UnitInventory): string => {
    if (
      unitInv.roomType !== UnitInventoryRoomType.KITCHEN &&
      unitInv.roomType !== UnitInventoryRoomType.LIVING_WITH_OPEN_KITCHEN &&
      unitInv.roomType !== UnitInventoryRoomType.BATHROOM &&
      unitInv.roomType !== UnitInventoryRoomType.SHOWER
    ) {
      return '';
    }
    if (
      [UnitInventoryRoomType.KITCHEN, UnitInventoryRoomType.LIVING_WITH_OPEN_KITCHEN].includes(
        unitInv.roomType as UnitInventoryRoomType
      ) &&
      !isNil(unitInv.kitchenEquipment)
    ) {
      return formatMessage({ id: `rentalUnit.detail.kitchenEquipment.${unitInv.kitchenEquipment}` });
    }
    if (unitInv.roomType === UnitInventoryRoomType.BATHROOM && !isNil(unitInv.privateBatthRoom)) {
      return unitInv.privateBatthRoom
        ? formatMessage({ id: 'rentalUnit.detail.inside.listedRoom.privateBathroom' })
        : formatMessage({ id: 'rentalUnit.detail.inside.listedRoom.sharedBathroom' });
    }
    return '';
  };

  const getTotalSurfaceAccordingToInventory = (): number => {
    let totalSurface = 0;
    unitInventories.forEach((inv) => (totalSurface += inv.surface && inv.inside ? inv.surface : 0));
    return totalSurface;
  };

  if (loading) {
    return <Skeleton />;
  }

  return (
    <>
      <Typography variant="h6" className={localClasses.title}>
        {formatMessage({ id: 'rentalUnit.detail.general.inside.inside' })}
      </Typography>
      <Grid container style={{ margin: ' 0 0 30px 0' }}>
        {unitInventories &&
          somethingToShow && // If there is at least one unitInventory with a surface
          unitInventories.map(
            (
              unitInv,
              index // Check and show up all of them values.
            ) =>
              // Only if the surface has been given for the current unitInventory.
              unitInv.surface && !isNil(unitInv.showSurface) && unitInv.showSurface && unitInv.inside ? (
                <Grid key={index} style={{ margin: '0 0 10px 0' }} item xs={4}>
                  <TextDetailWithFrontIcon
                    style={{ fontWeight: 'normal' }}
                    title={
                      unitInv.roomName
                        ? unitInv.roomName
                        : getAcceptableRoomName(unitInv.roomType as UnitInventoryRoomType)
                    }
                    endAdornment="m²"
                    text={unitInv.surface}
                    FrontIcon={getIconOfInventory(UnitInventoryRoomType[unitInv.roomType])}
                    subTitle={getSubtitleIfNecessary(unitInv)}
                  />
                </Grid>
              ) : null // if the surface is not given, don't show anything.
          )}
        {
          (!unitInventories || !somethingToShow) && (
            <NoRoomsMessage />
          ) /* Show a "no rooms" message if first condition is not respected. */
        }
        <Grid container style={{ marginTop: 30 }}>
          {/* All cases for the displayal of total superficy (with rooms, without rooms, etc */}
          {
            unit && unit.surface ? ( // if unit simply has superficy, use it as totalSuperficy
              <Grid item xs={6}>
                <AlignedTextDetail
                  style={{ fontWeight: 'normal' }}
                  title={formatMessage({ id: 'rentalUnit.detail.general.totalSuperficy' })}
                  text={unit.surface}
                  endAdornment=" m²"
                />
              </Grid>
            ) : // if unit doesn't have surface value, calculate sum of all unitIventories surfaces.
            unitInventories && unitInventories.length !== 0 ? (
              <Grid item xs={6}>
                <AlignedTextDetail
                  style={{ fontWeight: 'normal' }}
                  title={formatMessage({ id: 'rentalUnit.detail.general.totalSuperficy' })}
                  text={getTotalSurfaceAccordingToInventory()}
                  endAdornment=" m²"
                />
              </Grid>
            ) : null // Return null if none of the above conditions are respected.
          }

          <Grid item xs={6}>
            <AlignedTextDetail
              style={{ fontWeight: 'normal' }}
              title={formatMessage({ id: 'rentalUnit.detail.inside.amenities.doorPhone' })}
              text={formatMessage({
                id: `rentalUnit.editUnit.advertisement.general.${
                  advertisement?.amenities?.indexOf(AmenitiesType.DOOR_PHONE) !== -1 &&
                  advertisement?.amenities?.indexOf(AmenitiesType.DOOR_PHONE) !== undefined
                }`,
              })}
            />
          </Grid>

          <Grid item xs={6} style={{ marginTop: 10 }}>
            <AlignedTextDetail
              style={{ fontWeight: 'normal' }}
              title={formatMessage({ id: 'rentalUnit.detail.inside.amenities.tvConnection' })}
              text={formatMessage({
                id: `rentalUnit.editUnit.advertisement.general.${
                  advertisement?.amenities?.indexOf(AmenitiesType.TV_CONNECTION) !== -1 &&
                  advertisement?.amenities?.indexOf(AmenitiesType.TV_CONNECTION) !== undefined
                }`,
              })}
            />
          </Grid>

          <Grid item xs={6} style={{ marginTop: 10 }}>
            <AlignedTextDetail
              style={{ fontWeight: 'normal' }}
              title={formatMessage({ id: 'rentalUnit.detail.inside.amenities.alarm' })}
              text={formatMessage({
                id: `rentalUnit.editUnit.advertisement.general.${
                  advertisement?.amenities?.indexOf(AmenitiesType.ALARM) !== -1 &&
                  advertisement?.amenities?.indexOf(AmenitiesType.ALARM) !== undefined
                }`,
              })}
            />
          </Grid>

          <Grid item xs={6} style={{ marginTop: 10 }}>
            <AlignedTextDetail
              style={{ fontWeight: 'normal' }}
              title={formatMessage({ id: 'rentalUnit.detail.inside.amenities.armouredDoor' })}
              text={formatMessage({
                id: `rentalUnit.editUnit.advertisement.general.${
                  advertisement?.amenities?.indexOf(AmenitiesType.ARMOURED_DOOR) !== -1 &&
                  advertisement?.amenities?.indexOf(AmenitiesType.ARMOURED_DOOR) !== undefined
                }`,
              })}
            />
          </Grid>

          <Grid item xs={6} style={{ marginTop: 10 }}>
            <AlignedTextDetail
              style={{ fontWeight: 'normal' }}
              title={formatMessage({ id: 'rentalUnit.detail.inside.amenities.firePlace' })}
              text={formatMessage({
                id: `rentalUnit.editUnit.advertisement.general.${
                  advertisement?.amenities?.indexOf(AmenitiesType.FIRE_PLACE) !== -1 &&
                  advertisement?.amenities?.indexOf(AmenitiesType.FIRE_PLACE) !== undefined
                }`,
              })}
            />
          </Grid>

          <Grid item xs={6} style={{ marginTop: 10 }}>
            <AlignedTextDetail
              style={{ fontWeight: 'normal' }}
              title={formatMessage({ id: 'rentalUnit.detail.inside.amenities.sauna' })}
              text={formatMessage({
                id: `rentalUnit.editUnit.advertisement.general.${
                  advertisement?.amenities?.indexOf(AmenitiesType.SAUNA) !== -1 &&
                  advertisement?.amenities?.indexOf(AmenitiesType.SAUNA) !== undefined
                }`,
              })}
            />
          </Grid>

          <Grid item xs={6} style={{ marginTop: 10 }}>
            <AlignedTextDetail
              style={{ fontWeight: 'normal' }}
              title={formatMessage({ id: 'rentalUnit.detail.inside.amenities.jacuzzi' })}
              text={formatMessage({
                id: `rentalUnit.editUnit.advertisement.general.${
                  advertisement?.amenities?.indexOf(AmenitiesType.JACUZZI) !== -1 &&
                  advertisement?.amenities?.indexOf(AmenitiesType.JACUZZI) !== undefined
                }`,
              })}
            />
          </Grid>

          <Grid item xs={6} style={{ marginTop: 10 }}>
            <AlignedTextDetail
              style={{ fontWeight: 'normal' }}
              title={formatMessage({ id: 'rentalUnit.detail.inside.amenities.sPetsAllowed' })}
              text={formatMessage({
                id: `rentalUnit.editUnit.advertisement.general.${
                  advertisement?.amenities?.indexOf(AmenitiesType.SMALL_PETS_ALLOWED) !== -1 &&
                  advertisement?.amenities?.indexOf(AmenitiesType.SMALL_PETS_ALLOWED) !== undefined
                }`,
              })}
            />
          </Grid>

          <Grid item xs={6} style={{ marginTop: 10 }}>
            <AlignedTextDetail
              style={{ fontWeight: 'normal' }}
              title={formatMessage({ id: 'rentalUnit.detail.inside.amenities.lPetsAllowed' })}
              text={formatMessage({
                id: `rentalUnit.editUnit.advertisement.general.${
                  advertisement?.amenities?.indexOf(AmenitiesType.LARGE_PETS_ALLOWED) !== -1 &&
                  advertisement?.amenities?.indexOf(AmenitiesType.LARGE_PETS_ALLOWED) !== undefined
                }`,
              })}
            />
          </Grid>

          {!everyRoomHasSize ? (
            <AddInfoButton
              destination={{
                pathname: '/editAdvertisement',
                state: { unit, location: EDIT_ADVERTISEMENT_INTERIOR_MENU },
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

export default AdvertisementDescriptionInside;
