import { Grid, Typography } from '@material-ui/core';
import { Colors, IndexationRowStatus, LeasePriceHistoryType } from '@rentguru/commons-utils';
import React, { CSSProperties } from 'react';
import { useIntl } from 'react-intl';

interface StatusBoxProps {
  status: IndexationRowStatus;
  type: LeasePriceHistoryType;
  limitedHeight?: boolean;
  style?: CSSProperties;
  textStyle?: CSSProperties;
}

const getColor = (status: IndexationRowStatus) => {
  // eslint-disable-next-line default-case
  switch (status) {
    case IndexationRowStatus.INDEXED:
    case IndexationRowStatus.PARTIALLY_INDEXED:
      return Colors.TURQUOISE_BLUE;
    case IndexationRowStatus.REJECTED:
    case IndexationRowStatus.ERROR:
      return Colors.CARNATION_RED;
    case IndexationRowStatus.DRAFT:
      return Colors.CARROT_ORANGE;
    case IndexationRowStatus.OUTDATED:
      return Colors.DARK_SKY_BLUE;
  }
};

const IndexationStatusBox: React.FC<StatusBoxProps> = ({ status, limitedHeight = true, type, style, textStyle }) => {
  const { formatMessage } = useIntl();
  return (
    <Grid
      style={{
        color: getColor(status),
        textAlign: 'center',
        display: 'inline-block',
        padding: '5px 8px',
        ...(limitedHeight ? { height: 22 } : { height: 'none' }),
        ...(limitedHeight ? { maxHeight: 22 } : { maxHeight: 'none' }),
        border: `1px solid ${getColor(status)}`,
        borderRadius: 4,
        ...style,
      }}
    >
      <Typography style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 14, ...textStyle }}>
        {formatMessage({
          id: `lease.detail.indexationStatus.${status}${
            [IndexationRowStatus.INDEXED, IndexationRowStatus.PARTIALLY_INDEXED].includes(status) &&
            [LeasePriceHistoryType.ADJUSTMENT, LeasePriceHistoryType.TERMS_CHANGE].includes(type)
              ? 'Provisional'
              : ''
          }`,
        })}
      </Typography>
    </Grid>
  );
};

export default IndexationStatusBox;
