import { Colors, isSubscriptionMandateInProblem } from '@rentguru/commons-utils';
import { useIntl } from 'react-intl';
import { ReactComponent as WarningIcon } from 'src/icons/warning.svg';
import { useClient } from 'src/hooks/ClientContext';
import { Grid, Tooltip } from '@material-ui/core';
import { RouteDestination } from 'src/components/Routes/Routes';
import { SettingsRouteDestination } from '../Settings';
import { SUBSCRIPTION_PLAN_TAB_ROUTE } from '../SubscriptionSettings';
import { useHistory } from 'react-router-dom';

const SubscriptionProblemWarning = () => {
  const { formatMessage } = useIntl();
  const { push: historyPush } = useHistory();

  const { applicationSubscription, loading } = useClient();

  if (loading) {
    return null;
  }
  const hasSubscriptionProblem = isSubscriptionMandateInProblem(applicationSubscription);

  if (!hasSubscriptionProblem) {
    return null;
  }

  return (
    <Grid style={{ paddingRight: 20 }}>
      <Tooltip title={formatMessage({ id: 'settings.subscription.subscriptionWarning' })}>
        <Grid
          onClick={() =>
            historyPush({
              // eslint-disable-next-line max-len
              pathname: `${RouteDestination.SETTINGS}/${SettingsRouteDestination.SUBSCRIPTION_ROUTE}/${SUBSCRIPTION_PLAN_TAB_ROUTE}`,
            })
          }
        >
          <WarningIcon style={{ fill: Colors.BURNING_ORANGE, width: 20, height: 20, paddingTop: 10 }} />
        </Grid>
      </Tooltip>
    </Grid>
  );
};

export default SubscriptionProblemWarning;
