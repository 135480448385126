/* eslint-disable no-unused-expressions */
import React from 'react';
import { FilterFromTo } from 'src/hooks/FiltersContext';
import isNil from 'lodash/isNil';
import { ListItem, ListItemText, Divider, Grid } from '@material-ui/core';
import {
  startOfMonth,
  endOfMonth,
  isSameMonth,
  parseISO,
  isSameDay,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  setSeconds,
  setMinutes,
  setHours,
} from 'date-fns';
import CustomDatePicker from 'src/components/ui/CustomDatePicker';
import { useIntl } from 'react-intl';
import { getLastTrimester, getLastSemester, getLastYear, isDateValid } from '@rentguru/commons-utils';
import StyledRadio from 'src/components/ui/StyledRadio';

interface StatementDateFilterProps {
  dateFromTo: FilterFromTo | null;
  type: 'statementPeriod' | 'statementSendDate';
  handleFilterDateFromTo: (
    from: Date | null,
    to: Date | null,
    filterName: 'statementPeriod' | 'statementSendDate'
  ) => void;
}

export const StatementDateFilter: React.FC<StatementDateFilterProps> = ({
  dateFromTo,
  type,
  handleFilterDateFromTo,
}) => {
  const { formatMessage } = useIntl();
  // Date filter rendering
  const startDateFilter = isNil(dateFromTo)
    ? new Date()
    : isNil(dateFromTo.from)
    ? new Date()
    : parseISO(dateFromTo.from);
  const endDateFilter = isNil(dateFromTo) ? new Date() : isNil(dateFromTo.to) ? new Date() : parseISO(dateFromTo.to);

  let today = new Date();
  today = setSeconds(today, 0);
  today = setMinutes(today, 0);
  today = setHours(today, 0);
  const monthChecked =
    isFirstDayOfMonth(startDateFilter) &&
    isLastDayOfMonth(endDateFilter) &&
    isSameMonth(today, startDateFilter) &&
    isSameMonth(today, endDateFilter);

  const lastTrimester = getLastTrimester(today);
  const lastTrimesterChecked =
    isSameDay(startDateFilter, lastTrimester.start) && isSameDay(endDateFilter, lastTrimester.end);

  const lastSemester = getLastSemester(today);
  const lastSemesterChecked =
    isSameDay(startDateFilter, lastSemester.start) && isSameDay(endDateFilter, lastSemester.end);

  const lastYear = getLastYear(today);
  const lastYearChecked = isSameDay(startDateFilter, lastYear.start) && isSameDay(endDateFilter, lastYear.end);
  return (
    <>
      <ListItem
        key="firstRow"
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Grid container style={{ width: 320 }}>
          <Grid
            item
            xs={6}
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => {
              handleFilterDateFromTo(startOfMonth(today), endOfMonth(today), type);
            }}
          >
            <StyledRadio checked={monthChecked} />
            <ListItemText primary={formatMessage({ id: 'transactions.filter.thisMonth' })} />
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
            onClick={() => {
              handleFilterDateFromTo(lastTrimester.start, lastTrimester.end, type);
            }}
          >
            <StyledRadio checked={lastTrimesterChecked} />
            <ListItemText primary={formatMessage({ id: 'dashboard.filters.lastTrimester' })} />
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => {
              handleFilterDateFromTo(lastSemester.start, lastSemester.end, type);
            }}
          >
            <StyledRadio checked={lastSemesterChecked} />
            <ListItemText primary={formatMessage({ id: 'dashboard.filters.lastSemester' })} />
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
            onClick={() => {
              handleFilterDateFromTo(lastYear.start, lastYear.end, type);
            }}
          >
            <StyledRadio checked={lastYearChecked} />
            <ListItemText primary={formatMessage({ id: 'dashboard.filters.lastYear' })} />
          </Grid>
        </Grid>
      </ListItem>
      <Divider key="divider1" />
      <ListItem key="selectFromTo" style={{ marginTop: 10, marginBottom: 10, paddingTop: 0, paddingBottom: 0 }}>
        <CustomDatePicker
          name={`from${type}`}
          label={formatMessage({ id: 'transactions.filter.from' })}
          value={isNil(dateFromTo) ? null : parseISO(dateFromTo.from)}
          style={{ width: 150, marginRight: 20 }}
          onChange={(date) => handleFilterDateFromTo(date, null, type)}
          keyboardIconStyle={{ width: '20px' }}
          keyboardIconProps={{ size: 'small' }}
          safeDateFormat={true}
        />
        <CustomDatePicker
          name={`to${type}`}
          label={formatMessage({ id: 'transactions.filter.to' })}
          value={isNil(dateFromTo) ? null : parseISO(dateFromTo.to)}
          style={{ width: 150 }}
          minDate={!isNil(dateFromTo) && isDateValid(parseISO(dateFromTo.from)) ? parseISO(dateFromTo.from) : undefined}
          onChange={(date) => handleFilterDateFromTo(null, date, type)}
          keyboardIconStyle={{ width: '20px' }}
          keyboardIconProps={{ size: 'small' }}
          safeDateFormat={true}
        />
      </ListItem>
    </>
  );
};
