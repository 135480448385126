import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import { Colors } from '@rentguru/commons-utils';

export const CustomTableCell = withStyles(() => ({
  head: {
    backgroundColor: Colors.PORCELAIN_GREY_1,
    fontSize: 12,
  },
  body: {
    fontSize: 14,
    color: Colors.SLATE_GREY,
    height: 73,
    fontWeight: 300,
  },
}))(TableCell);

export const SmallHeightCustomTableCell = withStyles(() => ({
  head: {
    backgroundColor: Colors.PORCELAIN_GREY_1,
    fontSize: 12,
  },
  body: {
    fontSize: 14,
    color: Colors.SLATE_GREY,
    height: 40,
    fontWeight: 300,
  },
}))(TableCell);
