/* eslint-disable no-console */
import React from 'react';
import { useIntl } from 'react-intl';
import { Formik, FormikHelpers, Form } from 'formik';
import * as Yup from 'yup';

import { useUser } from 'src/hooks/UserContext';
import { Typography, TextField, Grid } from '@material-ui/core';
import { ElevatedPaper, LoaderButton } from '@up2rent/ui';
import { authStyles } from './authStyles';

const ConfirmSignUpSchema = Yup.object().shape({
  confirmationCode: Yup.string().required(),
});

interface ConfirmFormValues {
  confirmationCode: string;
  defaultErrorMessage?: string;
}

const initialValues: ConfirmFormValues = {
  confirmationCode: '',
};

const ConfirmSignUp: React.FC = () => {
  const { formatMessage } = useIntl();
  const styles = authStyles();

  const { confirmSignUp, resendConfirmSignUp, tempEmail, tempPass } = useUser();

  const handleSubmit = async (
    values: ConfirmFormValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<ConfirmFormValues>
  ) => {
    try {
      // Confirmation
      await confirmSignUp(tempEmail!, tempPass!, values.confirmationCode);
      setStatus(true);
      setSubmitting(false);
    } catch (error) {
      const err: Error = error as Error;
      console.error('Error during confirmation code validation: ', err);
      setErrors({ confirmationCode: `CUSTOM_${err.name}`, defaultErrorMessage: err.message });
      setSubmitting(false);
    }
  };

  const handleResend = async () => {
    await resendConfirmSignUp(tempEmail!);
  };

  return (
    <Grid alignContent="center" alignItems="center" container direction="column">
      <Formik
        initialValues={initialValues}
        validationSchema={ConfirmSignUpSchema}
        onSubmit={handleSubmit}
        validateOnChange
      >
        {({ values, errors, touched, handleChange, handleBlur, isSubmitting, status }) => {
          return (
            <ElevatedPaper className={styles.mainContainer}>
              <Form>
                <Typography variant="h5" paragraph component="h2" gutterBottom>
                  {formatMessage({ id: 'signup.createAccountEmail' })}
                </Typography>

                <TextField
                  label={formatMessage({ id: 'signup.confirmationCodeLabel' })}
                  id="confirmationCode"
                  type="tel"
                  name="confirmationCode"
                  autoComplete="tel"
                  margin="normal"
                  variant="filled"
                  fullWidth
                  value={values.confirmationCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  error={Boolean(errors.confirmationCode && touched.confirmationCode)}
                  helperText={
                    errors.confirmationCode && touched.confirmationCode
                      ? values.confirmationCode === ''
                        ? formatMessage({ id: 'error.empty' })
                        : errors.confirmationCode.startsWith('CUSTOM_')
                        ? formatMessage({ id: `error.${errors.confirmationCode.split('_')[1]}` })
                        : errors.confirmationCode
                      : formatMessage({ id: 'signup.confirmationCodeHelp' })
                  }
                />

                <LoaderButton loading={isSubmitting} success={status} disabled={status}>
                  {formatMessage({
                    id: 'signup.registerButton',
                  })}
                </LoaderButton>
                <LoaderButton onClick={handleResend} type="button" style={{ marginTop: 20 }} invertedColors>
                  {formatMessage({
                    id: 'signup.confirmationCodeResendLabel',
                  })}
                </LoaderButton>
              </Form>
            </ElevatedPaper>
          );
        }}
      </Formik>
    </Grid>
  );
};

export default ConfirmSignUp;
