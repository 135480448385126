import { Divider, Grid, Typography } from '@material-ui/core';
import { compileTemplate, setRegisterHelpers, Template } from '@rentguru/commons-utils';
import { EditorState } from 'draft-js';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { getStateFromHTML } from 'src/components/Communications/CommunicationDetail';
import { ActionButton , LoaderButton } from '@up2rent/ui';
import Dialog from 'src/components/ui/Dialog';
import DiscardChangesDialog from 'src/components/ui/Dialogs/DiscardChanges/DiscardChangesDialog';
import TemplateEditor from 'src/components/ui/TemplateEditor';
import TextButton from 'src/components/ui/TextButton';
import {
  convertEditorStateWithTagsToHandleBar,
  convertNewLinesFromHtmlBody,
  convertTemplateToStringFormat,
} from 'src/utils/templateutils';

interface TemplatePreviewFilledDialogProps {
  open: boolean;
  template: Template;
  data: object;
  onClose: () => void;
  setTemplate: (template: Template) => void;
}

const TemplatePreviewFilledDialog: React.FC<TemplatePreviewFilledDialogProps> = ({
  open,
  onClose,
  template,
  data,
  setTemplate,
}) => {
  const { formatMessage } = useIntl();
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [unsavedEditorState, setUnsavedEditorState] = useState<EditorState>(EditorState.createEmpty());
  useEffect(() => {
    try {
      setRegisterHelpers();
      const { body: bodyInHtml } = compileTemplate(template as unknown as Template, data);
      const correctedBody = convertNewLinesFromHtmlBody(bodyInHtml);
      const previewEditorState = EditorState.createWithContent(getStateFromHTML(correctedBody));
      setEditorState(previewEditorState);
    } catch (err) {
      console.error('err', err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, data]);

  useEffect(() => {
    if (isInEditMode) {
      setUnsavedEditorState(editorState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInEditMode]);

  const onCancelClick = () => {
    setEditorState(unsavedEditorState);
    setIsModalOpen(false);
    setIsInEditMode(false);
  };

  const onSaveClick = () => {
    setTemplate({
      ...template,
      body: convertEditorStateWithTagsToHandleBar(editorState, formatMessage, template),
      bodyRawFormat: convertTemplateToStringFormat(editorState),
    });
  };

  return (
    <Dialog open={open} onClose={onClose} scroll="body" fullWidth>
      <Grid
        style={{
          marginLeft: 20,
          marginRight: 20,
          marginTop: 25,
          marginBottom: 25,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: 20,
            textAlign: 'left',
          }}
        >
          {formatMessage({ id: 'templates.tags.preview.previewOfRegisteredLetter' })}
        </Typography>
        <ActionButton
          onClick={() => {
            if (isInEditMode) {
              onSaveClick();
            }
            setIsInEditMode((prev) => !prev);
          }}
        >
          {formatMessage({ id: isInEditMode ? 'save' : 'edit' })}
        </ActionButton>
      </Grid>
      <Divider />
      <Grid style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 20, paddingBottom: 20 }}>
        <TemplateEditor
          body={editorState}
          setBody={setEditorState}
          editMode={isInEditMode}
          editable={true}
          dividerStyle={{ marginBottom: 15 }}
        />
      </Grid>
      <Divider style={{ marginBottom: 10 }} />
      <Grid
        style={{
          marginBottom: 20,
          marginRight: 30,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {isInEditMode ? (
          <TextButton onClick={() => setIsModalOpen(true)}>{formatMessage({ id: 'cancel' })}</TextButton>
        ) : (
          <LoaderButton loading={false} onClick={onClose}>
            {formatMessage({ id: 'close' })}
          </LoaderButton>
        )}
      </Grid>
      <DiscardChangesDialog open={isModalOpen} onConfirm={onCancelClick} onClose={() => setIsModalOpen(false)} />
    </Dialog>
  );
};

export default TemplatePreviewFilledDialog;
