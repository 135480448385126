import React from 'react';
import { Colors, TemplateFileMetaData } from '@rentguru/commons-utils';
import { ReactComponent as PDFIcon } from '../../../icons/pdf.svg';
import { ReactComponent as ImageIcon } from '../../../icons/image.svg';
import { ReactComponent as ClipIcon } from '../../../icons/paperclip.svg';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

interface TemplateFileMetaDataProps {
  templateFileMetadata: TemplateFileMetaData;
}

const TemplateFileMetaDataComponent: React.FC<TemplateFileMetaDataProps> = ({ templateFileMetadata }) => {
  const { formatMessage } = useIntl();
  const FileIcon = templateFileMetadata.type === 'pdf' ? PDFIcon : ImageIcon;
  return (
    <div
      style={{
        height: 60,
        backgroundColor: Colors.PORCELAIN_GREY_3,
        borderRadius: 10,
        paddingLeft: 30,
        paddingRight: 30,
        margin: '10px 20px 10px 20px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <ClipIcon style={{ fill: Colors.BLUEY }} />
        <Typography style={{ marginLeft: 20, fontSize: 14, fontWeight: 600 }}>
          {formatMessage({ id: templateFileMetadata.labelId })}
        </Typography>
      </div>
      <FileIcon />
    </div>
  );
};

export default TemplateFileMetaDataComponent;
