/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { LoaderButton , ActionButton } from '@up2rent/ui';
import { Formik, Form, FormikHelpers } from 'formik';
import { Divider, Typography } from '@material-ui/core';
import { useBuildings } from '../../../hooks/BuildingsContext';
import { useUnits } from '../../../hooks/UnitsContext';
import RenovationCostsFields, {
  getDynamicRenovationCostsSchema,
  StaticRenovationCostsSchema,
} from '../../ui/Forms/FormField/RenovationCostsFields';
import toUpper from 'lodash/toUpper';
import { Building, Unit, UnitEventType, startOfUTCDay, endOfUTCDay, Colors } from '@rentguru/commons-utils';
import { addDays } from 'date-fns';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { Skeleton } from '@material-ui/lab';

interface AddRenovationProps {
  unitId?: string;
  buildingId?: string;
  startDate?: string;
  endDate?: string;
  afterSubmit?: (id: string) => void;
  cancelSubmit?: () => void;
}

interface RenovationInitialValuesType {
  renovations: [
    {
      id?: string;
      startDate: string;
      buildingId: string;
      unitId: string;
      type: string;
      endDate: string;
      totalCost?: number;
      note: string;
    }
  ];
}

const AddRenovation: React.FC<AddRenovationProps> = ({
  unitId,
  buildingId,
  startDate,
  endDate,
  afterSubmit,
  cancelSubmit,
}) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const { createBuildingEvent, buildingsLoading } = useBuildings();
  const { createUnitEvent, unitsLoading, getUnit } = useUnits();

  if (buildingsLoading || unitsLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton />
      </div>
    );
  }
  const handleAfterSubmit = () => {
    if (!isNil(afterSubmit) && buildingId) {
      afterSubmit(buildingId);
    }
    if (!isNil(afterSubmit) && unitId) {
      afterSubmit(unitId);
    }
  };
  const handleCancel = () => {
    if (!isNil(cancelSubmit)) {
      cancelSubmit();
    }
  };

  const handleRenovationCreate = async (
    values: RenovationInitialValuesType,
    { setSubmitting, setStatus }: FormikHelpers<RenovationInitialValuesType>
  ) => {
    const renovation = values.renovations[0];
    const { totalCost, startDate, endDate, buildingId, note, unitId } = renovation;
    const amount: number = Number(totalCost ?? 0);
    if (!isNil(buildingId) && !isEmpty(buildingId)) {
      await createBuildingEvent({
        type: 'WORK',
        startDate,
        endDate,
        buildingId,
        totalCost: amount,
        note,
      });
    } else if (!isNil(unitId) && !isEmpty(unitId)) {
      await createUnitEvent({
        type: UnitEventType.WORK,
        startDate,
        endDate,
        unitId,
        totalCost: amount,
        note,
      });
    }
    setStatus(true);
    setSubmitting(false);
    handleAfterSubmit();
  };

  let entity: Building | Unit | undefined;
  if (!isNil(unitId)) {
    entity = getUnit(unitId);
  } /* Building events can overlap ATM
  else if (!isNil(buildingId)) {
    entity = getBuilding(buildingId);
  } */

  const validationSchema = !isNil(entity)
    ? getDynamicRenovationCostsSchema(entity, false, formatMessage, language)
    : StaticRenovationCostsSchema;

  const initStartDate = !isNil(startDate) ? startDate : startOfUTCDay(new Date()).toJSON();
  const initEndDate = !isNil(endDate) ? endDate : endOfUTCDay(addDays(new Date(initStartDate), 1)).toJSON();

  return (
    <Formik
      initialValues={{
        renovations: [
          {
            id: 'NEW', // Needed for schema validation (same schema for add and edit)
            buildingId: !isNil(buildingId) && !isEmpty(buildingId) ? buildingId : '',
            unitId: !isNil(unitId) && !isEmpty(unitId) ? unitId : '',
            type: UnitEventType.WORK,
            startDate: initStartDate,
            endDate: initEndDate,
            totalCost: undefined,
            note: '',
          },
        ],
      }}
      validationSchema={validationSchema}
      onSubmit={handleRenovationCreate}
    >
      {({ isSubmitting, status }) => {
        return (
          <Form>
            <div style={{ marginLeft: 20, marginRight: 20, marginTop: 25, marginBottom: 25 }}>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: 20,
                  textAlign: 'left',
                }}
              >
                {formatMessage({ id: 'rentalUnit.detail.general.addRenovationCosts' })}
              </Typography>
            </div>
            <Divider style={{ marginBottom: 10 }} />
            <div style={{ alignItems: 'baseline', marginLeft: 20, marginRight: 20 }}>
              <RenovationCostsFields multiple={false} />
            </div>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <div
              style={{
                marginBottom: 20,
                marginRight: 30,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <ActionButton
                onClick={() => {
                  handleCancel();
                }}
                style={{ background: 'none', color: Colors.DARK_SLATE_GREY, marginRight: 20 }}
              >
                {toUpper(formatMessage({ id: 'cancel' }))}
              </ActionButton>
              <LoaderButton loading={isSubmitting} success={status}>
                {formatMessage({ id: 'save' })}
              </LoaderButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddRenovation;
