import ChevronRight from '@material-ui/icons/ChevronRight';
import { Colors } from '@rentguru/commons-utils';
import { CustomIconButton } from './CustomIconButton';

export const rotatingIconStyle = (isOpen: boolean, isPointingLeft: boolean, height: number, width: number): object => ({
  transform: `rotate(${isOpen ? 90 : isPointingLeft ? 180 : 0}deg)`,
  fill: isOpen ? Colors.DARK_SLATE_GREY : Colors.BLUEY,
  height,
  width,
});

interface CollapsingChevronProps {
  setOpen: (value: boolean) => void;
  isOpen: boolean;
  isPointingLeft?: boolean;
  height?: number;
  width?: number;
}

export const CollapsingChevron: React.FC<CollapsingChevronProps> = ({
  setOpen,
  isOpen,
  height = 20,
  width = 20,
  isPointingLeft = false,
}) => {
  return (
    <CustomIconButton
      style={{ color: Colors.BLUE_GREY, height: 'fit-content', padding: 0 }}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        setOpen(!isOpen);
      }}
      Icon={ChevronRight}
      iconStyle={rotatingIconStyle(isOpen, isPointingLeft, height, width)}
    />
  );
};
