import {
  EntityType,
  DocumentCategoryWithoutReadIdAndClientId,
  FolderBooleanAttributes,
  DocumentModel,
  FolderModel,
  Contact,
  ExtendedSearchModel,
} from '@rentguru/commons-utils';
import { isNil } from 'lodash';

export interface FolderState {
  id?: string;
  entityType?: EntityType;
  folders: FolderModel[];
  filteredFolders: FolderModel[];
  selectedFolders: string[];
  draggedFolders: string[];
  folderNavigation: (FolderModel | undefined)[];
  folderDragging: boolean;
  loadingOtherContexts: boolean;
  contextIsBusy: boolean;
  isInitialLoading: boolean;
  addingFolder: boolean;
  seeAllFolders: boolean;
  files: { [key: string]: DocumentModel[] };
  categories: { [key: string]: DocumentCategoryWithoutReadIdAndClientId[] };
  categoryFolders: FolderModel[];
  onPage: boolean | undefined;
  contact: Contact | undefined;
  searchFolders: boolean;
  extendSearchProperties: ExtendedSearchModel;
  foldersToSearchIn: FolderModel[];
  sortType: string[];
}

export type Action =
  | {
      type: 'SET_FOLDER_ATTRIBUTES';
      payload: { key: FolderBooleanAttributes[]; value: boolean };
    }
  | {
      type: 'SET_SELECTED_FILES';
      payload: DocumentModel[];
    }
  | {
      type: 'INITIALIZE_FOLDERS';
      payload: FolderModel[];
    }
  | {
      type: 'ADD_FOLDERS';
      payload: { foldersToAdd: FolderModel[]; foldersToDelete: FolderModel[] };
    }
  | {
      type: 'CHANGE_FOLDERS';
      payload: FolderModel[];
    }
  | {
      type: 'CHANGE_SELECTED_FOLDERS';
      payload: { selectedFoldersToAdd: string[]; selectedFoldersToDelete: string[] };
    }
  | {
      type: 'SET_SELECTED_FOLDERS';
      payload: string[];
    }
  | {
      type: 'SET_FILTERED_FOLDERS';
      payload: FolderModel[];
    }
  | {
      type: 'ADD_FILTERED_FOLDERS';
      payload: FolderModel;
    }
  | {
      type: 'SET_FOLDER_NAVIGATION';
      payload: (FolderModel | undefined)[];
    }
  | {
      type: 'SET_DRAGGED_FOLDERS';
      payload: string[];
    }
  | {
      type: 'UPDATE_FOLDER';
      payload: Partial<FolderModel>;
    }
  | {
      type: 'SET_ENTITY_DOCUMENT_CATEGORIES';
      payload: DocumentCategoryWithoutReadIdAndClientId[];
    }
  | {
      type: 'SET_FILES';
      payload: {
        files: { [key: string]: DocumentModel[] };
      };
    }
  | {
      type: 'UPDATE_FILES';
      payload: { files: DocumentModel[]; type: EntityType };
    }
  | {
      type: 'SET_ID_AND_TYPE';
      payload: { id: string; type: EntityType };
    }
  | {
      type: 'SET_CATEGORIES';
      payload: {
        categories: { [key: string]: DocumentCategoryWithoutReadIdAndClientId[] };
      };
    }
  | {
      type: 'UPDATE_CATEGORY';
      payload: { category: DocumentCategoryWithoutReadIdAndClientId[]; type: EntityType };
    }
  | {
      type: 'SET_CATEGORY_FOLDERS';
      payload: { folders: FolderModel[] };
    }
  | {
      type: 'SET_CONTACT';
      payload: Contact | undefined;
    }
  | {
      type: 'SET_EXTENDED_SEARCH_PROPERTIES';
      payload: { extendedSearch: ExtendedSearchModel };
    }
  | {
      type: 'SET_FOLDERS_TO_SEARCH_IN';
      payload: { foldersToSeachIn: FolderModel[] };
    }
  | {
      type: 'SET_SORT_TYPE';
      payload: { sortType: string };
    };

export const initialState: FolderState = {
  id: '',
  entityType: EntityType.UNIT,
  folders: [],
  selectedFolders: [],
  filteredFolders: [],
  folderNavigation: [undefined],
  loadingOtherContexts: true,
  folderDragging: false,
  contextIsBusy: false,
  isInitialLoading: true,
  addingFolder: true,
  draggedFolders: [],
  seeAllFolders: false,
  files: {},
  categories: {},
  categoryFolders: [],
  onPage: undefined,
  contact: undefined,
  searchFolders: false,
  extendSearchProperties: {},
  foldersToSearchIn: [],
  sortType: [],
};

export const documentReducer = (state: FolderState, action: Action): FolderState => {
  switch (action.type) {
    case 'SET_FOLDER_ATTRIBUTES':
      const updatedState = action.payload.key.reduce((acc, key) => {
        acc[key] = action.payload.value;
        return acc;
      }, {} as FolderState);
      return {
        ...state,
        ...updatedState,
      };
    case 'SET_FILTERED_FOLDERS':
      return { ...state, filteredFolders: action.payload };
    case 'ADD_FILTERED_FOLDERS':
      return { ...state, filteredFolders: [...state.filteredFolders, action.payload] };
    case 'INITIALIZE_FOLDERS':
      return { ...state, folders: action.payload };
    case 'ADD_FOLDERS':
      const stateFoldersWithoutDeleted = state.folders.filter(
        (folder) => !action.payload.foldersToDelete.includes(folder)
      );
      stateFoldersWithoutDeleted.push(...action.payload.foldersToAdd);
      return {
        ...state,
        folders: stateFoldersWithoutDeleted,
      };
    case 'CHANGE_FOLDERS':
      return {
        ...state,
        folders: state.folders.map((folder) => {
          const folderInAction = action.payload.find((actionFolder) => actionFolder.id === folder.id);
          if (!isNil(folderInAction)) {
            return folderInAction;
          }
          return folder;
        }),
      };
    case 'SET_FOLDER_NAVIGATION':
      return { ...state, folderNavigation: action.payload };
    case 'CHANGE_SELECTED_FOLDERS':
      const selectedFoldersWithoutDeleted = state.selectedFolders.filter(
        (folder) => !action.payload.selectedFoldersToDelete.includes(folder)
      );
      selectedFoldersWithoutDeleted.push(...action.payload.selectedFoldersToAdd);
      return {
        ...state,
        selectedFolders: selectedFoldersWithoutDeleted,
      };
    case 'SET_SELECTED_FOLDERS':
      return { ...state, selectedFolders: action.payload };
    case 'SET_DRAGGED_FOLDERS':
      return { ...state, draggedFolders: action.payload };
    case 'UPDATE_FOLDER':
      return {
        ...state,
        folders: state.folders.map((folder) => {
          if (folder.id === action.payload.id) {
            return { ...folder, ...action.payload };
          }
          return folder;
        }),
      };
    case 'SET_FILES':
      return {
        ...state,
        files: action.payload.files,
      };
    case 'UPDATE_FILES':
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.type]: action.payload.files,
        },
      };
    case 'SET_ID_AND_TYPE':
      return { ...state, id: action.payload.id, entityType: action.payload.type };
    case 'SET_CATEGORIES':
      return {
        ...state,
        categories: action.payload.categories,
      };
    case 'UPDATE_CATEGORY':
      return {
        ...state,
        categories: {
          ...state.categories,
          [action.payload.type]: action.payload.category,
        },
      };
    case 'SET_CATEGORY_FOLDERS':
      return {
        ...state,
        categoryFolders: action.payload.folders,
      };
    case 'SET_CONTACT':
      return {
        ...state,
        contact: action.payload,
      };
    case 'SET_EXTENDED_SEARCH_PROPERTIES':
      return {
        ...state,
        extendSearchProperties: action.payload.extendedSearch,
      };
    case 'SET_FOLDERS_TO_SEARCH_IN':
      return {
        ...state,
        foldersToSearchIn: action.payload.foldersToSeachIn,
      };
    case 'SET_SORT_TYPE':
      const newSortTypes = [...state.sortType];
      if (newSortTypes.includes(action.payload.sortType)) {
        return {
          ...state,
          sortType: newSortTypes.filter((sort) => sort !== action.payload.sortType),
        };
      }
      newSortTypes.push(action.payload.sortType);

      return {
        ...state,
        sortType: newSortTypes,
      };
    default:
      return state;
  }
};
