/* eslint-disable no-redeclare */
import React, { useContext, useEffect, useMemo } from 'react';
import { useUser } from './UserContext';
import {
  syncUnitLeases as syncQuery,
  getUnitLease as getQuery,
  createUnitLease as createMutation,
  updateUnitLease as updateMutation,
  deleteUnitLease as deleteMutation,
  CreateUnitLeaseMutationVariables as CreateMutationVariables,
  DeleteUnitLeaseMutationVariables as DeleteMutationVariables,
  CreateUnitLeaseInput as CreateInput,
  cleanInputCreate,
  cleanInputUpdate,
  getReadId,
  getTableClientId,
  UnitLease,
  isNilOrEmpty,
  Model,
  OnCreateUnitLeaseSubscription,
  OnUpdateUnitLeaseSubscription,
  OnDeleteUnitLeaseSubscription,
  onCreateUnitLease,
  onUpdateUnitLease,
  onDeleteUnitLease,
} from '@rentguru/commons-utils';
import { ContextLoaderAction, ContextLoaderStore, useContextLoader } from './ContextLoader';
import { usePermissions } from './utils/PermissionsContext';
import {
  deleteEntityWithFetchBefore,
  getFilterFieldNameForIndex,
  list,
  mutation,
  recordWasUpdated,
  useSubscriptions,
} from '@up2rent/fetch-utils';

const ENTITY_MODEL_NAME: Model = 'UnitLease';

export type ActionUnitLease =
  | {
      type: 'SHOULD_FETCH_UNIT_LEASE' | 'IS_FETCHING_UNIT_LEASE';
    }
  | {
      type: 'ADD_UNIT_LEASE';
      payload: { unitLease: UnitLease };
    }
  | {
      type: 'FETCHED_UNIT_LEASE';
      payload: { unitLeases: UnitLease[] };
    }
  | {
      type: 'UPDATE_UNIT_LEASE';
      payload: { unitLease: UnitLease };
    }
  | {
      type: 'DELETE_UNIT_LEASE';
      payload: { id: string };
    };

export interface UnitLeaseContext extends UnitLeaseState {
  createUnitLease: (input: Omit<UnitLease, 'id' | 'clientId' | 'readId'>) => Promise<UnitLease>;
  getUnitLease: (id: string) => UnitLease | null | undefined;
  updateUnitLease: (original: UnitLease, updates: Partial<UnitLease>) => Promise<UnitLease>;
  deleteUnitLease: (id: string) => Promise<UnitLease>;
  setFetchUnitLease: () => void;
}

interface UnitLeaseState {
  unitLeases: UnitLease[];
  loading: boolean;
  shouldFetchAddress: boolean;
  error: string | null;
}

export const unitLeaseReducerDelegation = (
  state: ContextLoaderStore,
  action: ContextLoaderAction
): ContextLoaderStore => {
  switch (action.type) {
    case 'SHOULD_FETCH_UNIT_LEASE':
      if (state.UnitLease.loading || state.UnitLease.shouldFetch) {
        return state;
      }
      return {
        ...state,
        UnitLease: { ...state.UnitLease, shouldFetch: true },
      };
    case 'IS_FETCHING_UNIT_LEASE':
      if (state.UnitLease.loading) {
        return state;
      }
      return {
        ...state,
        UnitLease: { ...state.UnitLease, loading: true },
      };
    case 'FETCHED_UNIT_LEASE':
      return {
        ...state,
        UnitLease: {
          ...state.UnitLease,
          data: action.payload.unitLeases,
          loading: false,
          shouldFetch: false,
          lastFetch: new Date(),
        },
      };
    case 'ADD_UNIT_LEASE':
      // Check if already present - If already added by this user or coming from another user
      if (state.UnitLease.data?.find((object) => object.id === action.payload.unitLease.id)) {
        return state;
      }

      return {
        ...state,
        UnitLease: {
          ...state.UnitLease,
          data: [...state.UnitLease.data, action.payload.unitLease],
        },
      };
    case 'UPDATE_UNIT_LEASE':
      // No data
      if (isNilOrEmpty(state.UnitLease.data)) {
        return state;
      }

      // Already present and same object
      const currentObject = state.UnitLease.data?.find((object) => object.id === action.payload.unitLease.id);
      if (!currentObject) {
        return {
          ...state,
          UnitLease: {
            ...state.UnitLease,
            data: [...state.UnitLease.data, action.payload.unitLease],
          },
        };
      }
      if (!recordWasUpdated(currentObject, action.payload.unitLease)) {
        return state;
      }

      // Update
      return {
        ...state,
        UnitLease: {
          ...state.UnitLease,
          data: state.UnitLease.data.map((object) => {
            if (object.id === action.payload.unitLease.id) {
              return action.payload.unitLease;
            }
            return object;
          }),
        },
      };
    case 'DELETE_UNIT_LEASE':
      if (isNilOrEmpty(state.UnitLease.data)) {
        return state;
      }
      return {
        ...state,
        UnitLease: {
          ...state.UnitLease,
          data: state.UnitLease.data.filter((object) => object.id !== action.payload.id),
        },
      };

    default:
      return state;
  }
};

const fetchUnitLeases = async (clientId: string, additionalFilter?: object): Promise<UnitLease[]> => {
  return await list<UnitLease>(
    syncQuery,
    getFilterFieldNameForIndex('byClientId'),
    getTableClientId(clientId, ENTITY_MODEL_NAME),
    additionalFilter
  );
};

export const UnitLeaseContext = React.createContext<UnitLeaseContext | null>(null);

export const UnitLeaseContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { clientId } = useUser();
  const {
    UnitLease: { data: unitLeases, loading: unitLeasesLoading, shouldFetch },
    dispatch: contextDispatch,
  } = useContextLoader();
  const { leasesCreationDelete } = usePermissions();

  const setFetchUnitLease = () => {
    contextDispatch({ type: 'SHOULD_FETCH_UNIT_LEASE' });
  };

  const loading = unitLeasesLoading;

  useEffect(() => {
    const fetchAndSet = async () => {
      contextDispatch({ type: 'IS_FETCHING_UNIT_LEASE' });
      const result = await fetchUnitLeases(clientId!);
      contextDispatch({ type: 'FETCHED_UNIT_LEASE', payload: { unitLeases: result } });
    };
    if (shouldFetch) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch]);

  useSubscriptions<OnCreateUnitLeaseSubscription, OnUpdateUnitLeaseSubscription, OnDeleteUnitLeaseSubscription>(
    onCreateUnitLease,
    onUpdateUnitLease,
    onDeleteUnitLease,
    (data) => {
      contextDispatch({
        type: 'ADD_UNIT_LEASE',
        payload: { unitLease: data.onCreateUnitLease as UnitLease },
      });
    },
    (data) => {
      contextDispatch({
        type: 'UPDATE_UNIT_LEASE',
        payload: { unitLease: data.onUpdateUnitLease as UnitLease },
      });
    },
    (data) => {
      const { id } = data.onDeleteUnitLease as UnitLease;
      contextDispatch({
        type: 'DELETE_UNIT_LEASE',
        payload: { id },
      });
    }
  );

  const createUnitLease = async (input: Omit<UnitLease, 'id' | 'clientId' | 'readId'>): Promise<UnitLease> => {
    const unitLease = await mutation<UnitLease, CreateMutationVariables>(createMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateInput),
        clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME),
        readId: getReadId(clientId!, ENTITY_MODEL_NAME),
      },
    });
    contextDispatch({ type: 'ADD_UNIT_LEASE', payload: { unitLease } });
    return unitLease;
  };

  const getUnitLease = (id: string) => {
    if (loading) {
      return null;
    }
    return unitLeases.find((stateObject) => stateObject.id === id);
  };

  const updateUnitLease = async (original: UnitLease, updates: Partial<UnitLease>): Promise<UnitLease> => {
    const result = await mutation<UnitLease>(updateMutation, {
      input: { ...cleanInputUpdate({ id: original.id, _version: original._version, ...updates }, false) },
    });
    contextDispatch({ type: 'UPDATE_UNIT_LEASE', payload: { unitLease: result } });
    return result;
  };

  const deleteUnitLease = async (id: string): Promise<UnitLease> => {
    const unitLease = getUnitLease(id)!;
    if (!leasesCreationDelete) {
      return unitLease;
    }

    await deleteEntityWithFetchBefore<Pick<UnitLease, 'id'>, DeleteMutationVariables>({ id }, getQuery, deleteMutation);

    contextDispatch({ type: 'DELETE_UNIT_LEASE', payload: { id } });
    return unitLease;
  };

  const values = useMemo(
    () => ({
      unitLeases: unitLeases as UnitLease[],
      createUnitLease,
      getUnitLease,
      updateUnitLease,
      deleteUnitLease,
      setFetchUnitLease,
      error: null,
      loading,
      shouldFetchAddress: shouldFetch,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [unitLeases, loading, shouldFetch]
  );

  return <UnitLeaseContext.Provider value={values}>{children}</UnitLeaseContext.Provider>;
};

export const useUnitLeases = (): UnitLeaseContext => {
  const context = useContext<UnitLeaseContext | null>(UnitLeaseContext);

  if (context === undefined) {
    throw new Error('`useUnitLeases` hook must be used within a `UnitLeaseContextProvider` component');
  }
  return context as UnitLeaseContext;
};
