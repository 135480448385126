import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import isNil from 'lodash/isNil';
import { useDashboardFilters } from 'src/hooks/DashboardFiltersContext';
import { RankingBoxSwitchFilter, SwitchFilterName } from './BoxSwitchFilters';
import { MenuItemText } from '@up2rent/ui';

interface BoxSwitchOverflowMenuProps {
  name: SwitchFilterName;
  filter: RankingBoxSwitchFilter | null;
  anchor: HTMLElement | null;
  onClose: () => void;
}

const BoxSwitchOverflowMenu: React.FC<BoxSwitchOverflowMenuProps> = ({ name, filter, anchor, onClose }) => {
  const { switchFilters, updateSwitchFilters } = useDashboardFilters();

  const handleOverflowFilterChange = (value: string) => {
    updateSwitchFilters({
      ...switchFilters,
      [name]: { name: filter!.label, value },
    });
    onClose();
  };

  if (isNil(filter) || isNil(filter.menuItems)) {
    return null;
  }

  const currentFilter = switchFilters[name];
  const currentSelectedValue = !isNil(currentFilter) ? currentFilter.value : null;

  return (
    <Menu open={!isNil(anchor)} anchorEl={anchor} onClose={onClose}>
      {filter.menuItems!.map((item) => {
        const isFilteredSelected = !isNil(currentSelectedValue) && item.value === currentSelectedValue;
        return (
          <MenuItem
            key={item.value}
            onClick={() => {
              handleOverflowFilterChange(item.value);
            }}
          >
            <MenuItemText primary={item.label} selected={isFilteredSelected} />
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export default BoxSwitchOverflowMenu;
