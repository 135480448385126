/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker, DatePickerProps } from '@material-ui/pickers';
import { Colors } from '@rentguru/commons-utils';

export const useStyles = makeStyles({
  input: {
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    caretColor: Colors.CLASSICAL_WHITE,
    fontSize: 12,
    color: Colors.BLUE_GREY,
    fontWeight: 700,
    fontFamily: 'Mulish',
    width: 40,
    '&:hover': {
      color: Colors.SILVER,
    },
    '&:before': {
      border: 'none',
    },
  },
});

export interface CustomYearPickerProps extends DatePickerProps {
  value: string | Date | null;
  onChange: (date: Date | null) => void;
}

const CustomYearPicker: React.FC<CustomYearPickerProps> = ({ value, onChange, ...rest }) => {
  const classes = useStyles();

  return (
    <DatePicker
      variant={'inline'}
      views={['year']}
      value={value ?? new Date().getFullYear().toString()}
      onChange={onChange}
      InputProps={{ classes: { root: classes.input } }}
      {...rest}
    />
  );
};

export default CustomYearPicker;
