import { Divider, MenuItem, makeStyles } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import {
  Colors,
  LeaseInventoryFurnitureExitIssue,
  LeaseInventoryFurnitureExitIssueAction,
  LeaseInventoryFurnitureExitIssueType,
  LooseObject,
  NewFile,
  formatNumber,
} from '@rentguru/commons-utils';
import { ActionButton, ContentHeader, CustomIconButton, LoaderButton } from '@up2rent/ui';
import { useFormikContext } from 'formik';
import { isEmpty, isNil, toUpper } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import CustomizedSelect from 'src/components/ui/CustomizedSelect';
import DropZoneField from 'src/components/ui/Forms/FormField/DropZoneField';
import PlusMinusButtonGroup from 'src/components/ui/PlusMinusButtonGroup';
import TextDetailEditable from 'src/components/ui/TextDetailEditable';
import { ExtendedLeaseInventoryEncoding, ExtendedUnitInventoryStructure } from 'src/hooks/UnitInventoryContext';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as DeleteIcon } from 'src/icons/delete.svg';
import { getSafeValueInObject } from 'src/utils/object';
import { AddFurnitureIssueFormValues, defaultIssue } from './IssueDialog';

const useStyles = makeStyles({
  addButton: {
    background: Colors.CLASSICAL_WHITE,
    color: Colors.DARK_SLATE_GREY,
    '&:hover': {
      background: Colors.PORCELAIN_GREY_2,
    },
  },
});

const getTotalNumberOfIssues = (issues: LeaseInventoryFurnitureExitIssue[]) => {
  return issues.reduce((acc: number, issue) => {
    return acc + issue.quantity;
  }, 0);
};

interface IssueRowProps {
  name: string;
  onDelete: () => void;
  maxQuantity: number;
  deletable?: boolean;
}

const IssueRow: React.FC<IssueRowProps> = ({ name, onDelete, maxQuantity, deletable = true }) => {
  const { formatMessage } = useIntl();
  const { values, setFieldValue, errors, touched } = useFormikContext<AddFurnitureIssueFormValues>();
  const issue = getSafeValueInObject(values, name) as LeaseInventoryFurnitureExitIssue;
  if (!isNil(issue.penalty) && issue.action === LeaseInventoryFurnitureExitIssueAction.NOTHING_TO_DO) {
    // Reset penalty to undefined
    setFieldValue(`${name}.penalty`, undefined);
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <PlusMinusButtonGroup
        text=""
        initialValue={issue.quantity}
        onChange={(value) => {
          setFieldValue(`${name}.quantity`, value);
        }}
        style={{ marginLeft: -10, marginRight: -5, transform: 'translateY(4px)' }}
        minValue={1}
        maxValue={maxQuantity}
      />
      <CustomizedSelect
        label={formatMessage({ id: 'furnitureInventory.chooseIssue' })}
        fieldName={`${name}.issue`}
        value={issue.issue}
        FormControlProps={{ style: { width: 175, marginRight: 10 } }}
        margin="dense"
      >
        {Object.keys(LeaseInventoryFurnitureExitIssueType).map((key) => {
          return (
            <MenuItem id={key} key={key} value={(LeaseInventoryFurnitureExitIssueType as LooseObject)[key]}>
              {formatMessage({ id: `enums.LeaseInventoryFurnitureExitIssueType.${key}` })}
            </MenuItem>
          );
        })}
      </CustomizedSelect>
      <CustomizedSelect
        label={formatMessage({ id: 'furnitureInventory.actionToTake' })}
        fieldName={`${name}.action`}
        value={issue.action}
        FormControlProps={{ style: { width: 175, marginRight: 10 } }}
        margin="dense"
      >
        {Object.keys(LeaseInventoryFurnitureExitIssueAction).map((key) => {
          return (
            <MenuItem id={key} key={key} value={(LeaseInventoryFurnitureExitIssueAction as LooseObject)[key]}>
              {formatMessage({ id: `enums.LeaseInventoryFurnitureExitIssueAction.${key}` })}
            </MenuItem>
          );
        })}
      </CustomizedSelect>

      <TextDetailEditable
        editMode={true}
        title={formatMessage({ id: 'furnitureInventory.penalty' })}
        name={`${name}.penalty`}
        type="number"
        min={0}
        style={{
          width: 175,
        }}
        disabled={issue.action === LeaseInventoryFurnitureExitIssueAction.NOTHING_TO_DO}
        endAdornment="€"
        error={Boolean(
          getSafeValueInObject(errors, `${name}.penalty`) && getSafeValueInObject(touched, `${name}.penalty`)
        )}
      />

      {deletable && (
        <CustomIconButton
          onClick={onDelete}
          size="small"
          Icon={DeleteIcon}
          iconStyle={{ fill: Colors.BLUEY, width: 16 }}
        />
      )}
    </div>
  );
};
interface IssueDialogContentProps {
  onClose: () => void;
  encoding: ExtendedLeaseInventoryEncoding;
  structure: ExtendedUnitInventoryStructure;
}

const IssueDialogContent: React.FC<IssueDialogContentProps> = ({ encoding, structure, onClose }) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const [displayNote, setDisplayNote] = useState<boolean>(!isNil(encoding.note) && encoding.note !== '');
  const { values, setFieldValue, isSubmitting, status } = useFormikContext<AddFurnitureIssueFormValues>();
  const classes = useStyles();
  const numberOfIssues = getTotalNumberOfIssues(values.issues);
  const maxQuantity = !isNil(encoding.furnitureQuantity) ? encoding.furnitureQuantity : 1;
  const potentialNewIssues = maxQuantity - numberOfIssues;

  const deleteIssue = (index: number) => {
    setFieldValue(
      'issues',
      values.issues.filter((_i, index2) => index2 !== index)
    );
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (isEmpty(acceptedFiles)) {
        return;
      }
      const filesToAdd: NewFile[] = acceptedFiles.map((acceptedFile: File) => ({
        mimeType: acceptedFile.type,
        name: acceptedFile.name,
        size: acceptedFile.size,
        file: acceptedFile,
      }));
      setFieldValue(`files`, [...values.files, ...filesToAdd]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.files, setFieldValue]
  );

  return (
    <>
      <ContentHeader
        title={`${formatMessage({ id: 'furnitureInventory.reportIssue' })}: ${structure.name} (${
          encoding.furnitureQuantity
        }) · ${formatNumber(encoding.furniturePrice as number, language, { style: 'currency', currency: 'EUR' })}`}
      />
      <Divider style={{ marginBottom: 20 }} />
      <div style={{ marginLeft: 24, marginRight: 24 }}>
        {values.issues.map((issue, index) => {
          return (
            <IssueRow
              key={`${issue.issue} ${issue.action} ${issue.quantity}`}
              name={`issues[${index}]`}
              onDelete={() => {
                deleteIssue(index);
              }}
              maxQuantity={issue.quantity + potentialNewIssues}
              deletable={values.issues.length > 1}
            />
          );
        })}
      </div>
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      <div
        style={{
          display: 'flex',
          justifyContent: !isEmpty(values.files) ? 'flex-start' : 'center',
          marginBottom: 15,
          marginLeft: 8, // + 16 padding inside the component
          marginRight: 8,
        }}
      >
        <DropZoneField
          currentFiles={values.files}
          onDrop={onDrop}
          removeFileByIndex={(index: number) => {
            setFieldValue(
              `files`,
              values.files.filter((_file, i) => i !== index)
            );
          }}
          height={45}
          width={45}
          style={{ width: '100%', paddingLeft: 0, justifyContent: !isEmpty(values.files) ? 'flex-start' : 'center' }}
          itemStyle={{ marginRight: 10 }}
          AddIconMultiple={<Add style={{ fill: Colors.SLATE_GREY }} />}
        />
      </div>
      {displayNote && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: 24,
            marginRight: 24,
            alignItems: 'center',
          }}
        >
          <TextDetailEditable
            editMode={true}
            title={formatMessage({ id: 'leaseInventory.room.structureDetails.notes' }, { value: 1 })}
            name="note"
            type="string"
            rowsMax={8}
            style={{
              width: '100%',
              maxWidth: 800,
            }}
            multiline
          />
          <CustomIconButton
            onClick={() => {
              setFieldValue('note', undefined);
              setDisplayNote(false);
            }}
            size="small"
            Icon={DeleteIcon}
            iconStyle={{ fill: Colors.BLUEY }}
          />
        </div>
      )}
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      <div
        style={{
          marginBottom: 20,
          marginRight: 30,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <ActionButton
            onClick={() => setFieldValue('issues', [...values.issues, defaultIssue])}
            style={{
              marginLeft: 20,
            }}
            className={classes.addButton}
            disabled={numberOfIssues === encoding.furnitureQuantity}
          >
            <Add />
            {toUpper(formatMessage({ id: 'furnitureInventory.addIssue' }))}
          </ActionButton>
          <ActionButton
            onClick={() => setDisplayNote(true)}
            style={{
              marginLeft: 20,
            }}
            className={classes.addButton}
            disabled={displayNote}
          >
            <Add />
            {toUpper(formatMessage({ id: 'furnitureInventory.addComment' }))}
          </ActionButton>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <ActionButton
            onClick={onClose}
            style={{ background: 'none', color: Colors.DARK_SLATE_GREY, marginRight: 20 }}
          >
            {toUpper(formatMessage({ id: 'cancel' }))}
          </ActionButton>
          <LoaderButton loading={isSubmitting} success={status}>
            {formatMessage({ id: 'save' })}
          </LoaderButton>
        </div>
      </div>
    </>
  );
};

export default IssueDialogContent;
