import { Divider, Grid, Typography } from '@material-ui/core';
import {
  DEFAULT_SENDING_EMAIL,
  Colors,
  getSendingSourceStatusColor,
  getSendingSourceStatusName,
  SendingSourceErrorType,
  SendingSourceStatus,
  SendingSourceVerifiedType,
} from '@rentguru/commons-utils';
import { toUpper } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { ActionButton, ContentHeader, ColoredBox } from '@up2rent/ui';
import { useSendingSources } from 'src/hooks/SendingSourceContext';

interface ErrorDomainDialogProps {
  id: string;
  closeAction: (nextAction?: 'RESET' | 'ADD', emailAddress?: string, errorMessage?: SendingSourceErrorType) => void;
}

const ErrorDomainDialog: React.FC<ErrorDomainDialogProps> = ({ id, closeAction }) => {
  const { formatMessage } = useIntl();
  const { getSendingSource } = useSendingSources();

  const sendingSource = getSendingSource(id)!;
  const isDefault = sendingSource.verifiedType === SendingSourceVerifiedType.DEFAULT;
  const statusText = getSendingSourceStatusName(
    sendingSource.status as SendingSourceStatus,
    sendingSource.verifiedType as SendingSourceVerifiedType,
    formatMessage
  );
  const statusColor = getSendingSourceStatusColor(sendingSource.status as SendingSourceStatus);
  return (
    <Grid style={{ width: 600 }}>
      <ContentHeader title={formatMessage({ id: 'settings.sendingSources.error.title' })}>
        <ColoredBox boxColor={statusColor}>{statusText}</ColoredBox>
      </ContentHeader>

      <Divider style={{ marginBottom: 20 }} />
      {sendingSource.errorMessage !== SendingSourceErrorType.CRASH && (
        <Grid style={{ marginLeft: 30, marginRight: 30 }}>
          <Typography>
            {formatMessage({ id: 'settings.sendingSources.error.info1' })}
            <b>{sendingSource.name}</b>
            {formatMessage({ id: 'settings.sendingSources.error.info2' })}
          </Typography>
          <Typography>
            {formatMessage({
              id: 'settings.sendingSources.error.info3',
            })}
          </Typography>
          <Typography style={{ color: Colors.CARNATION_RED, marginTop: 20, marginBottom: 20, textAlign: 'center' }}>
            {sendingSource.errorMessage !== SendingSourceErrorType.OTHER &&
              formatMessage(
                {
                  id: `settings.sendingSources.error.${sendingSource.errorMessage?.toLowerCase()}1`,
                },
                { name: sendingSource.name }
              )}
            {sendingSource.errorMessage === SendingSourceErrorType.OTHER &&
              // should never happen
              formatMessage({
                id: 'settings.sendingSources.error.other',
              })}
          </Typography>
          {(sendingSource.errorMessage === SendingSourceErrorType.TIME ||
            sendingSource.errorMessage === SendingSourceErrorType.SES) && (
            <Typography>
              {formatMessage({
                id: `settings.sendingSources.error.${sendingSource.errorMessage?.toLowerCase()}2`,
              })}
            </Typography>
          )}
        </Grid>
      )}
      {sendingSource.errorMessage === SendingSourceErrorType.CRASH && (
        <Grid>
          <Typography
            style={{ color: Colors.CARNATION_RED, marginTop: 20, marginBottom: 20, marginLeft: 30, marginRight: 30 }}
          >
            {formatMessage(
              {
                id: `settings.sendingSources.error.crash1`,
              },
              { name: sendingSource.name }
            )}
          </Typography>
          <Typography style={{ marginLeft: 30, marginRight: 30 }}>
            {formatMessage({
              id: 'settings.sendingSources.error.crash2',
            })}
            <b>{sendingSource.emailAddress}</b>
            {formatMessage({
              id: 'settings.sendingSources.error.crash3',
            })}
          </Typography>
          {isDefault && (
            <Grid>
              <Typography style={{ marginLeft: 30, marginRight: 30 }}>
                {formatMessage({
                  id: 'settings.sendingSources.error.crash4',
                })}
                <b>{DEFAULT_SENDING_EMAIL}</b>
                {formatMessage({
                  id: 'settings.sendingSources.error.crash5',
                })}
              </Typography>
              <Divider style={{ marginBottom: 20, marginTop: 20 }} />
              <Grid
                style={{
                  marginLeft: 30,
                  marginRight: 30,
                  marginBottom: 20,
                  display: 'flex',
                  alignItems: 'stretch',
                }}
              >
                <ActionButton
                  onClick={() =>
                    closeAction('ADD', sendingSource.emailAddress, sendingSource.errorMessage as SendingSourceErrorType)
                  }
                  style={{ marginRight: 10, textTransform: 'none', width: 260 }}
                >
                  {formatMessage({
                    id: 'settings.sendingSources.error.crashButtonCustom',
                  })}
                </ActionButton>

                <ActionButton
                  onClick={() => closeAction('RESET')}
                  style={{ marginLeft: 10, textTransform: 'none', width: 260, height: '100%' }}
                >
                  {formatMessage(
                    {
                      id: 'settings.sendingSources.error.crashButtonDefault',
                    },
                    { emailAddress: DEFAULT_SENDING_EMAIL }
                  )}
                </ActionButton>
              </Grid>
              <Divider style={{ marginBottom: 20, marginTop: 20 }} />
              <Typography style={{ marginLeft: 30, marginRight: 30 }}>
                {formatMessage({
                  id: 'settings.sendingSources.error.crash6',
                })}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      <Grid style={{ display: 'flex', float: 'right', marginRight: 30 }}>
        <ActionButton
          onClick={closeAction}
          style={{
            background: 'none',
            color: Colors.DARK_SLATE_GREY,
            marginRight: 30,
            marginBottom: 20,
          }}
        >
          {toUpper(
            formatMessage({
              id: 'close',
            })
          )}
        </ActionButton>
        {sendingSource.errorMessage === SendingSourceErrorType.CRASH && !isDefault && (
          <ActionButton
            onClick={() => closeAction('ADD', sendingSource.emailAddress)}
            style={{ textTransform: 'none' }}
          >
            {formatMessage({
              id: 'settings.sendingSources.error.crashButtonCustom',
            })}
          </ActionButton>
        )}
      </Grid>
    </Grid>
  );
};

export default ErrorDomainDialog;
