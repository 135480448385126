import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import { useUsers } from '../../../hooks/UsersContext';
import { User } from '@rentguru/commons-utils';

interface DeleteMemberProps {
  afterSubmit: () => void;
  user: User;
}
const DeleteMember: React.FC<DeleteMemberProps> = ({ afterSubmit, user }) => {
  const { formatMessage } = useIntl();
  const { loading, error, deepDeleteUser } = useUsers();
  const handleClose = () => {
    afterSubmit();
  };
  if (loading || isNil(user)) {
    return (
      <>
        <DialogContent>
          <Typography>{formatMessage({ id: 'loading' })}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {formatMessage({ id: 'cancel' })}
          </Button>
        </DialogActions>
      </>
    );
  }
  if (error) {
    return <Typography>{error}</Typography>;
  }

  const isPending = isNil(user.cognitoSub) && isNil(user.facebookSub) && isNil(user.googleSub);
  if (!isPending) {
    // Only allowed to delete pending user
    return null;
  }

  return (
    <>
      <DialogTitle id="alert-dialog-slide-title">
        {formatMessage({ id: 'settings.deleteMember.title' }, { name: user.email })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description" style={{ color: 'red' }}>
          {formatMessage({ id: 'settings.deleteMember.warning' })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {formatMessage({ id: 'cancel' })}
        </Button>
        <Button
          onClick={async (e) => {
            e.preventDefault();

            await deepDeleteUser(user);
            handleClose();
          }}
          color="primary"
        >
          {formatMessage({ id: 'delete' })}
        </Button>
      </DialogActions>
    </>
  );
};

export default DeleteMember;
