import { Typography } from '@material-ui/core';
import { getTenantsName, getTableClientId, Invoice, InvoiceType } from '@rentguru/commons-utils';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { LeasesTableProps } from '../Leases';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { FilterEntity, useFilters } from 'src/hooks/FiltersContext';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { RouteDestination } from '../../Routes/Routes';
import { useFieldFilterReset } from '../../ui/FieldFilterSelector';
import { isEmpty } from 'lodash';
import {
  EnhancedTableSharedContextProvider,
  TablePlaceHolder,
  ActionButton,
  EnhancedTableScrollable,
} from '@up2rent/ui';
import { ReactComponent as Logo } from '../../../icons/unitPlaceholder.svg';
import { useUnits } from 'src/hooks/UnitsContext';
import LeaseActiveRow from './LeaseActiveRow';
import { filterLeasesActiveByFilters, isOneLeaseActiveFilterSelected } from './leaseFiltersUtils';
import { fetchInvoices } from 'src/hooks/InvoicesContext';
import { useUser } from 'src/hooks/UserContext';
import { getSortableLeaseName } from './leaseTableUtils';
import { ReactComponent as NoResultIcon } from 'src/icons/noResult.svg';

const columns = [
  {
    id: 'name',
    labelId: 'rentalUnit.columnHeader.name',
    numeric: false,
    disablePadding: false,
    sortable: true,
    displayFunction: getSortableLeaseName,
    style: { paddingLeft: 30, minWidth: 150 },
  },
  {
    id: 'units',
    labelId: 'lease.addLease.mainUnit',
    numeric: false,
    disablePadding: false,
    sortable: false,
    style: { minWidth: 150, paddingLeft: 0 },
  },
  {
    id: 'endDate',
    labelId: 'financial.period',
    numeric: false,
    disablePadding: true,
    sortable: true,
    style: { minWidth: 250 },
  },
  {
    id: 'type',
    labelId: 'rentalUnit.columnHeader.type',
    numeric: false,
    disablePadding: false,
    sortable: true,
    style: { minWidth: 100, paddingLeft: 0 },
  },
  {
    id: 'tenants',
    labelId: 'lease.detail.general.tenants',
    numeric: false,
    disablePadding: false,
    sortable: true,
    displayFunction: getTenantsName,
    style: { minWidth: 100, paddingLeft: 0 },
  },
  {
    id: 'balance',
    labelId: 'rentalUnit.columnHeader.balance',
    numeric: false,
    disablePadding: true,
    sortable: true,
    style: { minWidth: 100 },
  },
];

const LeasesTableActive: React.FC<LeasesTableProps> = ({ loading, leasesActive: leases }) => {
  const [unpaidInvoicesOfClient, setUnpaidInvoicesOfClient] = useState<Invoice[] | null>(null);
  const { formatMessage } = useIntl();
  const { leasesDetailsRead, buildingsUnitsDetailsWrite, leasesCreationWrite } = usePermissions();
  const { units } = useUnits();
  const { clientId } = useUser();

  const { leasesActiveFilters, resetFilters } = useFilters();
  const { executeResetFilters } = useFieldFilterReset();

  const history = useHistory();
  const match = useRouteMatch<{ tabName?: string; id?: string }>({
    path: `${RouteDestination.LEASES}/:tabName?/:id?`,
  });

  useEffect(() => {
    const getAllUnpaidInvoices = async () => {
      const unpaidInvoices = await fetchInvoices('byClientId', getTableClientId(clientId!, 'Invoice'), {
        and: [{ paid: { eq: false } }, { type: { eq: InvoiceType.RENT } }],
      });
      setUnpaidInvoicesOfClient(unpaidInvoices);
    };
    if (leasesActiveFilters.balanceLatePaymentMonth !== 0 && !unpaidInvoicesOfClient) {
      // We are fetching unpaid lease invoice of client fot the balance Late payment filter.
      // We are doing it here because the paid status of the invoice can be set in multiple places
      // Some invoice can be partially paid and some new invoice may be paid before old ones
      getAllUnpaidInvoices();
    }
    // eslint-disable-next-line
  }, [leasesActiveFilters.balanceLatePaymentMonth, clientId]);

  if (!match) {
    return null;
  }

  const handleDetail = (leaseId: string) => {
    if (!leasesDetailsRead) {
      return;
    }
    history.push({
      pathname: `${RouteDestination.LEASES}/detail/${leaseId}`,
      state: { goBackUrl: `${RouteDestination.LEASES}/active` },
    });
  };

  // Filtered list based on menu
  const isOneFilterSelected = isOneLeaseActiveFilterSelected(leasesActiveFilters);
  const filteredLeases = filterLeasesActiveByFilters(leases, leasesActiveFilters, unpaidInvoicesOfClient);

  const canCreateLease = units && !isEmpty(units) && leasesCreationWrite;
  const noUnitAndCanCreateUnit = units && !isEmpty(units) && buildingsUnitsDetailsWrite;
  const placeHolderSubText = canCreateLease
    ? formatMessage({ id: 'lease.detail.placeholderSubtext' })
    : noUnitAndCanCreateUnit
    ? formatMessage({ id: 'building.placeholder.subText' })
    : '';
  const placeHolderActionDestination = canCreateLease ? RouteDestination.ADD_LEASE : RouteDestination.ADD_UNIT;
  const placeHolderActionLabelId = canCreateLease ? 'lease.addLease.action' : 'rentalUnit.addUnit.action';

  return (
    <EnhancedTableSharedContextProvider value={{ orderBy: 'name' }} datas={filteredLeases} handleDetail={handleDetail}>
      {loading && <Typography>{formatMessage({ id: 'loading' })}</Typography>}
      {!loading && (
        <EnhancedTableScrollable
          NoResultIcon={<NoResultIcon />}
          formatMessage={formatMessage}
          datas={filteredLeases}
          columns={columns}
          defaultOrderBy="name"
          rowComponent={LeaseActiveRow}
          placeHolder={
            <TablePlaceHolder
              Icon={<Logo width={72} />}
              mainText={formatMessage({ id: 'lease.detail.placeholderNoActive' })}
              subText={placeHolderSubText}
              actionButton={
                canCreateLease || noUnitAndCanCreateUnit ? (
                  <ActionButton component={Link} to={placeHolderActionDestination}>
                    {formatMessage({ id: placeHolderActionLabelId })}
                  </ActionButton>
                ) : undefined
              }
            />
          }
          filtersSelected={isOneFilterSelected}
          resetFiltersFunction={() => {
            executeResetFilters();
            resetFilters(FilterEntity.LEASES_ACTIVE);
          }}
        />
      )}
    </EnhancedTableSharedContextProvider>
  );
};

export default LeasesTableActive;
