import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import NotificationBox from './NotificationBox';
import SlicedContent from './SlicedContent';
import { ReactComponent as ExternalIcon } from '../../../icons/external.svg';
import { Link } from 'react-router-dom';
import {
  Statement,
  Colors,
  getContactNameFromObject,
  getStatementTotalAmount,
  TENANT_STATEMENTS_TO_VALIDATE_KEY,
  StatementStatus,
  ModelWithVersion,
} from '@rentguru/commons-utils';
import { isAfter } from 'date-fns';
import { useStatements } from 'src/hooks/StatementContext';
import { ConfirmDialog, MenuItemText } from '@up2rent/ui';
import { useLeases } from 'src/hooks/LeasesContext';
import { extendTenantStatement } from 'src/components/Accounting/TenantStatements/TenantStatements';
import { ActionMenuChargeInbox } from 'src/components/Accounting/TenantStatements/TenantStatementsToValidate/TenantStatementsToValidate';
import TenantStatementsToValidateRow from 'src/components/Accounting/TenantStatements/TenantStatementsToValidate/TenantStatementsToValidateRow';
import ConfirmValidateTenantStatementDialog from 'src/components/Accounting/TenantStatements/TenantStatementsToValidate/ValidateTenantStatement/ConfirmValidateTenantStatementDialog';
import TenantStatementsHeader from './BoxCustomHeaders/TenantStatementsHeader';
import TodosLoading from './TodosLoading';
import { stopPropagation } from 'src/components/Dashboard/Dashboard';

export const ROW_HEIGHT = 74;

export const statementSortFunction = (st1: Statement, st2: Statement): number => {
  if (isAfter(new Date(st1.periodFrom), new Date(st2.periodFrom))) return -1;
  if (isAfter(new Date(st2.periodFrom), new Date(st1.periodFrom))) return 1;
  return isAfter(new Date(st1.periodTo), new Date(st2.periodTo)) ? -1 : 1;
};

const DashboardTenantStatements: React.FC = () => {
  const { formatMessage } = useIntl();
  const { tenantStatementsToCheck, statementsLoading, updateStatement, deleteStatement, recalculateStatement } =
    useStatements(true);
  const { getLease, leasesLoading } = useLeases();
  const [dialogDeleteOpen, setDialogDeleteOpen] = useState<string>('');
  const [validateDialogOpen, setValidateDialogOpen] = useState<string>('');
  const [actionMenu, setActionMenu] = useState<ActionMenuChargeInbox | null>(null);

  const handleActionMenu = async (
    anchorEl: React.MouseEvent<HTMLElement>,
    id: string,
    eventType: 'MORE' | 'RECALCULATE' | 'VALIDATE',
    afterSubmit?: () => void
  ) => {
    if (eventType === 'MORE') {
      setActionMenu({ anchorEl: anchorEl.currentTarget, id });
    } else if (eventType === 'VALIDATE') {
      setValidateDialogOpen(id);
    } else {
      await recalculateStatement(id);
      if (afterSubmit) afterSubmit();
    }
  };

  if (statementsLoading || leasesLoading) {
    return (
      <>
        <TodosLoading />
      </>
    );
  }

  const tenantStatementsToCheckData = tenantStatementsToCheck.map((statement) => {
    const extendedStatement = extendTenantStatement(statement, getLease);
    const tenantsNames = extendedStatement.tenants.map((t) => getContactNameFromObject(t)).join(', ');
    const unitsNames = extendedStatement.units.map((u) => u.name).join(', ');
    const totalAmount = getStatementTotalAmount(extendedStatement);
    const periodSortLabel = `${extendedStatement.periodFrom}${extendedStatement.periodTo}`;
    return {
      ...extendedStatement,
      amounts: statement.amounts ?? [],
      tenantsNames,
      unitsNames,
      totalAmount,
      periodSortLabel,
    };
  });

  const statementToValidate =
    validateDialogOpen !== '' ? tenantStatementsToCheck.find((st) => st.id === validateDialogOpen) : null;

  const validateStatement = async () => {
    if (statementToValidate) {
      await updateStatement({
        id: statementToValidate.id,
        sentDate: new Date().toISOString(),
        status: StatementStatus.DONE,
        _version: (statementToValidate as ModelWithVersion<Statement>)._version,
      });
      setValidateDialogOpen('');
    }
  };

  const statementToDisplay = tenantStatementsToCheckData.sort(statementSortFunction).map((statement) => {
    return (
      <TenantStatementsToValidateRow
        key={statement.id}
        {...statement}
        isSelected={false}
        actionMenu={handleActionMenu}
      />
    );
  });

  const titleComponent = (
    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
      <Typography
        style={{
          flex: 1,
          textAlign: 'left',
          color: Colors.CLASSICAL_BLACK,
          fontFamily: 'Mulish',
          fontSize: '16px',
          fontWeight: 'bold',
          marginLeft: 20,
        }}
      >
        {`${formatMessage({ id: 'dashboard.tenantStatements' })} (${statementToDisplay.length})`}
      </Typography>
      <IconButton
        size="small"
        onMouseDown={stopPropagation}
        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => e.stopPropagation()}
        component={Link}
        to="/accounting/tenantStatements"
        style={{ marginLeft: 10 }}
      >
        <ExternalIcon style={{ fill: Colors.DODGER_BLUE }} />
      </IconButton>
    </div>
  );

  return (
    <>
      <NotificationBox boxKey={TENANT_STATEMENTS_TO_VALIDATE_KEY} titleComponent={titleComponent}>
        <SlicedContent
          data={statementToDisplay}
          header={<TenantStatementsHeader />}
          limit={3}
          rowHeight={ROW_HEIGHT}
          boxKey={TENANT_STATEMENTS_TO_VALIDATE_KEY}
        />
      </NotificationBox>
      {actionMenu && (
        <Menu
          id="action-menu"
          anchorEl={actionMenu.anchorEl}
          open={Boolean(actionMenu.anchorEl)}
          onClose={() => setActionMenu(null)}
        >
          <MenuItem
            onClick={() => {
              setDialogDeleteOpen(actionMenu.id);
              setActionMenu(null);
            }}
          >
            <MenuItemText primary={formatMessage({ id: 'delete' })} deleteOption />
          </MenuItem>
        </Menu>
      )}
      <ConfirmDialog
        open={dialogDeleteOpen !== ''}
        confirmText={formatMessage({ id: 'accounting.statement.confirmDelete' })}
        confirmAction={async () => {
          const statementToDelete = tenantStatementsToCheck.find((st) => st.id === dialogDeleteOpen);
          if (statementToDelete) await deleteStatement(statementToDelete);
          setDialogDeleteOpen('');
        }}
        cancelAction={() => {
          setDialogDeleteOpen('');
        }}
        mainText={formatMessage(
          { id: 'accounting.statement.toValidateSection.deleteWarning' },
          { statement: formatMessage({ id: 'accounting.statement.tenantStatements' }, { value: 1 }).toLowerCase() }
        )}
        formatMessage={formatMessage}
      />
      {validateDialogOpen !== '' && statementToValidate && (
        <ConfirmValidateTenantStatementDialog
          open={validateDialogOpen !== ''}
          onClose={() => setValidateDialogOpen('')}
          validateStatement={validateStatement}
          statement={statementToValidate}
        />
      )}
    </>
  );
};

export default DashboardTenantStatements;
