import { Grid, Typography } from '@material-ui/core';
import { formatNumberToEuroCurrency, getAmountColor } from '@rentguru/commons-utils';
import { useIntl } from 'react-intl';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as ArrowRightIcon } from 'src/icons/arrow-right.svg';
import clsx from 'clsx';
import { useAddCustomInvoiceStyles } from './addCustomInvoiceDialogUtils';

interface NewBalancePartProps {
  oldBalance: number;
  newBalance: number;
}

const NewBalancePart: React.FC<NewBalancePartProps> = ({ oldBalance, newBalance }) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const classes = useAddCustomInvoiceStyles();

  return (
    <>
      <Typography className={classes.subtitle}>
        {`${formatMessage({ id: 'lease.detail.addCustomInvoice.newLeaseBalance' })} ${formatMessage({
          id: `lease.detail.indexationGeneral.vatIncl`,
        })}`}
      </Typography>
      <Grid className={clsx(classes.marginY10, classes.editableCustomPostingRow)}>
        <Typography
          className={classes.subtitle}
          style={{
            color: getAmountColor(oldBalance),
          }}
        >
          {formatNumberToEuroCurrency(oldBalance, language)}
        </Typography>
        <ArrowRightIcon className={classes.leftArrow} />
        <Typography
          className={classes.subtitle}
          style={{
            color: getAmountColor(newBalance),
          }}
        >
          {formatNumberToEuroCurrency(newBalance, language)}
        </Typography>
      </Grid>
    </>
  );
};

export default NewBalancePart;
