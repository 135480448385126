import {
  Lease,
  REMITTANCE_TYPE_STRUCTURED,
  RemittanceType,
  encodeStructuredRemittanceInformation,
  isValidOGM,
} from '@rentguru/commons-utils';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { useLeases } from 'src/hooks/LeasesContext';
import BankAccountSelectorFields, {
  BankAccountSelectorSchema,
} from 'src/components/ui/Forms/FormField/BankAccountSelectorFields';
import { RemittanceInformationSchema } from 'src/components/ui/Forms/FormField/RemittanceInformationField';
import { BankAccountSelectorFormValues } from 'src/components/ui/Forms/FormSection/BankAccountSelectorForm';
import { CustomSimpleDialog } from '@up2rent/ui';
import { Grid } from '@material-ui/core';

interface EditBankAccountDialogProps {
  open: boolean;
  lease: Lease;
  onClose: () => void;
}

const EditBankAccountSchema = BankAccountSelectorSchema.concat(RemittanceInformationSchema);

const EditBankAccountDialog: React.FC<EditBankAccountDialogProps> = ({ lease, open, onClose }) => {
  const { formatMessage } = useIntl();
  const { updateLease } = useLeases();

  const initialBankAccountId = lease.bankAccountId ?? '';
  const initialRemittanceInformation = lease.remittanceInformation ?? '';
  const initialRemittanceInformationType = isValidOGM(initialRemittanceInformation)
    ? RemittanceType.structured
    : RemittanceType.unstructured;

  const handleSubmit = async (
    values: BankAccountSelectorFormValues,
    { setSubmitting, setStatus }: FormikHelpers<BankAccountSelectorFormValues>
  ): Promise<void> => {
    const { bankAccountId, remittanceInformation, remittanceInformationType } = values;
    const shouldUpdate =
      initialBankAccountId !== bankAccountId ||
      initialRemittanceInformation !== remittanceInformation ||
      initialRemittanceInformationType !== remittanceInformationType;
    if (shouldUpdate) {
      const remittanceInformationFormatted =
        remittanceInformationType === REMITTANCE_TYPE_STRUCTURED
          ? encodeStructuredRemittanceInformation(remittanceInformation)
          : remittanceInformation;
      await updateLease(lease, { bankAccountId, remittanceInformation: remittanceInformationFormatted });
    }

    setStatus(true);
    setSubmitting(false);
    onClose();
  };

  const initialValues: BankAccountSelectorFormValues = {
    bankAccountId: initialBankAccountId,
    remittanceInformation: initialRemittanceInformation,
    remittanceInformationType: initialRemittanceInformationType,
  };

  return (
    <Formik initialValues={initialValues} validationSchema={EditBankAccountSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, handleSubmit: onActionButtonClick }) => (
        <Form>
          <CustomSimpleDialog
            open={open}
            onClose={onClose}
            onActionButtonClick={onActionButtonClick}
            actionButtonLabel={formatMessage({ id: 'save' })}
            actionButtonLoading={isSubmitting}
            dividerBelowTitle
            title={formatMessage({ id: 'lease.detail.action.changeBank' })}
            formatMessage={formatMessage}
          >
            <Grid style={{ marginTop: 10 }}>
              <BankAccountSelectorFields
                allowGenerateStructuredRemittanceInformation
                bankAccountInputWidth={552}
                containerXPadding={0}
              />
            </Grid>
          </CustomSimpleDialog>
        </Form>
      )}
    </Formik>
  );
};

export default EditBankAccountDialog;
