import React, { useState } from 'react';
import { Grid, Chip, Collapse } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import {
  Colors,
  integrationPrices,
  IntegrationPrice,
  IntegrationName,
  IntegrationStatus,
} from '@rentguru/commons-utils';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { useIntl } from 'react-intl';
import IconnedRemark from 'src/components/ui/IconnedRemark';
import { ReactComponent as Bulb } from 'src/icons/bulb.svg';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import { ReactComponent as Ponto } from 'src/icons/ponto.svg';
import { ActionButton, LoaderButton, ConfirmDialog } from '@up2rent/ui';
import { usePontoConnect } from './usePontoConnect';
import { formatDistance, parseISO } from 'date-fns';
import { dateLocaleMap, dateLocaleMapType, useLocale } from 'src/i18n/IntlProviderWrapper';
import { getDisplayPrice } from './IntegrationBox';
import Dialog from 'src/components/ui/Dialog';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: Colors.PORCELAIN_GREY_1,
    padding: 15,
  },
  paragraph: {
    fontSize: '14px',
    lineHeight: '20px',
    color: Colors.CHARCOAL_GREY,
    textAlign: 'left',
  },
  pontoLogo: {
    height: 70,
  },
  pontoText: {
    fontFamily: 'Mulish',
    fontSize: 20,
    fontWeight: 'bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: Colors.CLASSICAL_BLACK,
  },
  errorText: {
    fontFamily: 'Mulish',
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: Colors.SLATE_GREY,
    textAlign: 'left',
  },
});

interface PontoIntegrationDialogProps {
  open: boolean;
  onClose: () => void;
}

const getSaveButtonTextId = (alreadyRun: boolean, hasError: boolean) => {
  if (alreadyRun && !hasError) {
    return 'lease.detail.action.revoke';
  }
  if (alreadyRun && hasError) {
    return 'reconnect';
  }
  return 'connect';
};

const PontoIntegrationDialog: React.FC<PontoIntegrationDialogProps> = ({ open, onClose }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { settingsAutomationWrite } = usePermissions();
  const { language } = useLocale();
  const { url, loading, integration, revokePontoIntegration } = usePontoConnect();
  const [confirmRevokeDialogOpen, setConfirmRevokeDialogOpen] = useState<boolean>(false);

  const integrationPrice = integrationPrices.find((price: IntegrationPrice) => price.name === IntegrationName.PONTO);
  const price = integrationPrice
    ? getDisplayPrice(
        integrationPrice!.price!,
        formatMessage({ id: `settings.integrations.frequency.${integrationPrice.frequency}` })
      )
    : formatMessage({ id: 'settings.integrations.free' });

  const hasError = integration?.status === IntegrationStatus.ERROR;
  const errorMessage = integration?.error ?? '';
  const alreadyRun = Boolean(integration?.lastRun);
  const lastSyncDistance = alreadyRun
    ? formatDistance(parseISO(integration!.lastRun!), new Date(), {
        addSuffix: true,
        locale: (dateLocaleMap as dateLocaleMapType)[language],
      })
    : '';
  const integrationName = formatMessage({ id: `settings.integrations.IntegrationName.${IntegrationName.PONTO}` });
  const canRevoke = settingsAutomationWrite && alreadyRun && !hasError;
  return (
    <>
      <Dialog open={open} onClose={onClose} scroll="body">
        <div className={classes.root}>
          <Ponto className={classes.pontoLogo} />
        </div>
        <Grid
          container
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingRight: 30,
            paddingLeft: 30,
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <Grid item>
            <Typography className={classes.pontoText}>{integrationName}</Typography>
          </Grid>
          <Grid item style={{ marginLeft: 10, display: 'flex', justifyContent: 'flex-end' }}>
            <Chip
              label={price}
              style={{
                color: Colors.LIGHT_BLUE_GREY,
                fontFamily: 'Mulish',
                fontSize: 14,
                lineHeight: 18,
                fontWeight: 600,
              }}
            />
          </Grid>
        </Grid>
        <Grid style={{ paddingLeft: 30, paddingRight: 30 }}>
          <Typography paragraph classes={{ root: classes.paragraph }}>
            {formatMessage({ id: 'settings.integrations.explanation' })}
          </Typography>
          {alreadyRun && (
            <>
              <IconnedRemark
                Icon={InfoSvg}
                noFixWidth
                style={{ marginLeft: 0 }}
                message={
                  <Typography classes={{ root: classes.paragraph }}>
                    {`${formatMessage(
                      { id: 'settings.integrations.lastSync' },
                      { name: integrationName }
                    )} ${lastSyncDistance}${
                      hasError ? ` ${formatMessage({ id: 'settings.integrations.lastSyncError' })}` : ''
                    }`}
                  </Typography>
                }
              />
              <Collapse in={hasError}>
                <IconnedRemark
                  Icon={InfoSvg}
                  noFixWidth
                  style={{ marginTop: 10, marginBottom: 10, marginLeft: 0 }}
                  message={<Typography className={classes.errorText}>{errorMessage}</Typography>}
                />
              </Collapse>
            </>
          )}

          <Collapse in={!alreadyRun}>
            <Typography paragraph className={classes.paragraph}>
              {formatMessage({ id: 'settings.integrations.activateIntegration' })}
            </Typography>
            <ul style={{ color: Colors.CHARCOAL_GREY, lineHeight: '20px', paddingRight: 30 }}>
              <li>
                <Typography className={classes.paragraph}>
                  {formatMessage({ id: 'settings.integrations.steps.first' })}
                </Typography>
              </li>
              <li>
                <Typography className={classes.paragraph}>
                  {formatMessage({ id: 'settings.integrations.steps.second' })}
                </Typography>
              </li>
            </ul>
          </Collapse>
        </Grid>
        <IconnedRemark
          Icon={Bulb}
          noFixWidth
          style={{ marginRight: 30 }}
          message={
            <Grid style={{ textAlign: 'left', color: Colors.SLATE_GREY }}>
              <Typography>
                {formatMessage({ id: 'settings.integrations.additionalInformation1' }, { integrationName })}
              </Typography>
              <Typography>{formatMessage({ id: 'settings.integrations.additionalInformation2' })}</Typography>
              <Typography>{formatMessage({ id: 'settings.integrations.additionalInformation3' })}</Typography>
            </Grid>
          }
        />
        <Grid
          style={{
            paddingRight: 30,
            paddingLeft: 30,
            marginBottom: 20,
            marginTop: 20,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <ActionButton
            onClick={onClose}
            style={{
              background: 'none',
              color: Colors.DARK_SLATE_GREY,
              marginRight: 20,
              textTransform: 'uppercase',
            }}
          >
            {formatMessage({ id: 'cancel' })}
          </ActionButton>
          {settingsAutomationWrite && (
            <LoaderButton
              style={{ minWidth: 120, height: 50, fontSize: 14, fontWeight: 800 }}
              loading={loading}
              onClick={canRevoke ? () => setConfirmRevokeDialogOpen(true) : undefined}
              href={!canRevoke ? url ?? '#' : undefined}
            >
              {formatMessage({ id: getSaveButtonTextId(alreadyRun, hasError) })}
            </LoaderButton>
          )}
        </Grid>
      </Dialog>
      <ConfirmDialog
        open={confirmRevokeDialogOpen}
        confirmText={formatMessage({ id: 'settings.integrations.remove' })}
        confirmAction={async () => {
          await revokePontoIntegration();
          setConfirmRevokeDialogOpen(false);
        }}
        cancelAction={() => {
          setConfirmRevokeDialogOpen(false);
        }}
        mainText={formatMessage({ id: 'settings.integrations.confirmRemove' }, { name: integrationName })}
        formatMessage={formatMessage}
      />
    </>
  );
};

export default PontoIntegrationDialog;
