import { Divider, FormControlLabel, Grid, makeStyles, MenuItem, RadioGroup, Typography } from '@material-ui/core';
import { HeatingAmenities, HeatingType } from '@rentguru/commons-utils';
import { useFormikContext } from 'formik';
import { isNil } from 'lodash';
import { useIntl } from 'react-intl';
import CustomizedSelect from 'src/components/RentalUnits/Details/Publication/EditPublication/CustomizedSelectWithImportance';
import { EditAdvertisementFormValues } from 'src/components/RentalUnits/Details/Publication/EditPublication/EditPublicationForm';
import useEditPublicationFormStyles from 'src/components/RentalUnits/Details/Publication/EditPublication/utils/EditPublicationFormStyle';
import CustomCheckBox from 'src/components/ui/CustomCheckBox';
import StyledRadio from 'src/components/ui/StyledRadio';

const useLocalStyles = makeStyles({
  rootSelect: {
    '& > *': {
      width: 280,
    },
  },
});

const HeatingEdit = () => {
  const { formatMessage } = useIntl();
  const classes = useLocalStyles();
  const publicationFormClasses = useEditPublicationFormStyles();

  const { values, setFieldValue } = useFormikContext<EditAdvertisementFormValues>();

  const getRadioValue = () => {
    const glazingType: Array<HeatingAmenities> = values.advertisement.heatingAmenities as Array<HeatingAmenities>;
    if (!isNil(glazingType) && glazingType.find((type) => type === HeatingAmenities.SIMPLE_GLAZING)) {
      return HeatingAmenities.SIMPLE_GLAZING;
    }
    if (!isNil(glazingType) && glazingType.find((type) => type === HeatingAmenities.DOUBLE_GLAZING)) {
      return HeatingAmenities.DOUBLE_GLAZING;
    }
    if (!isNil(glazingType) && glazingType.find((type) => type === HeatingAmenities.TRIPLE_GLAZING)) {
      return HeatingAmenities.TRIPLE_GLAZING;
    }
    if (!isNil(glazingType) && glazingType.find((type) => type === HeatingAmenities.QUADRUPLE_GLAZING)) {
      return HeatingAmenities.QUADRUPLE_GLAZING;
    }
    return 'none';
  };

  let heatingAmenities: Array<HeatingAmenities> = values.advertisement.heatingAmenities as Array<HeatingAmenities>;

  const handleRadioCheck = (glazingType: HeatingAmenities) => {
    heatingAmenities = heatingAmenities.filter(
      (amenitiesType) =>
        amenitiesType !== HeatingAmenities.SIMPLE_GLAZING &&
        amenitiesType !== HeatingAmenities.DOUBLE_GLAZING &&
        amenitiesType !== HeatingAmenities.TRIPLE_GLAZING &&
        amenitiesType !== HeatingAmenities.QUADRUPLE_GLAZING
    );

    heatingAmenities.push(glazingType);
    setFieldValue('advertisement.heatingAmenities', heatingAmenities);
  };

  const glazingTypes = [
    HeatingAmenities.SIMPLE_GLAZING,
    HeatingAmenities.DOUBLE_GLAZING,
    HeatingAmenities.TRIPLE_GLAZING,
    HeatingAmenities.QUADRUPLE_GLAZING,
  ];

  const heatingTypes = [
    HeatingAmenities.COLLECTIVE_HEATING,
    HeatingAmenities.COLLECTIVE_WATER,
    HeatingAmenities.AIR_CONDITIONNING,
  ];

  return (
    <>
      <Typography variant="h6" className={publicationFormClasses.title}>
        {formatMessage({ id: 'rentalUnit.detail.energy.heating.heating' })}
      </Typography>
      <Grid container style={{ justifyContent: 'space-evenly', margin: '15px 0 15px 0' }}>
        <Grid item>
          <CustomizedSelect
            label={formatMessage({ id: 'rentalUnit.detail.energy.heating.heatingType' })}
            fieldName="advertisement.heatingType"
            value={values.advertisement.heatingType}
            className={classes.rootSelect}
            error={false}
            importance="recommended"
          >
            {Object.keys(HeatingType).map((type) => (
              <MenuItem
                id={type}
                key={type}
                value={type}
                onClick={() => {
                  setFieldValue(`advertisement.heatingType`, type);
                }}
              >
                {formatMessage({ id: `rentalUnit.detail.energy.heating.${type}` })}
              </MenuItem>
            ))}
          </CustomizedSelect>
        </Grid>
        <Grid item style={{ width: 280 }} />
      </Grid>

      <Grid container>
        {heatingTypes.map((type, index) => (
          <Grid
            key={type}
            item
            xs={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              position: 'relative',
              left: index % 2 === 0 ? 30 : 0,
            }}
          >
            <FormControlLabel
              control={
                <CustomCheckBox
                  isChecked={
                    !isNil(values.advertisement.heatingAmenities) &&
                    values.advertisement.heatingAmenities.includes(type)
                  }
                  onCheck={() => {
                    if (!isNil(values.advertisement?.heatingAmenities)) {
                      const collectiveHeatingIndex = heatingAmenities.indexOf(type);
                      if (collectiveHeatingIndex !== -1) {
                        heatingAmenities.splice(collectiveHeatingIndex, 1);
                        setFieldValue('advertisement.heatingAmenities', heatingAmenities);
                      } else {
                        setFieldValue('advertisement.heatingAmenities', heatingAmenities.concat(type));
                      }
                    }
                  }}
                  secondary
                  name={type}
                />
              }
              label={formatMessage({ id: `rentalUnit.detail.energy.heating.${type}` })}
              id={type}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', position: 'relative' }}>
        <RadioGroup
          style={{ padding: '0px 30px 0 37px', width: '100%' }}
          name="GlazingType"
          value={getRadioValue()}
          onChange={(_, value) => handleRadioCheck(value as HeatingAmenities)}
        >
          <Grid container>
            {glazingTypes.map((type, index) => (
              <Grid key={type} item xs={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <FormControlLabel
                  value={type}
                  control={
                    <StyledRadio size="small" name={type} style={{ width: '5%', left: index % 2 === 0 ? 0 : 2 }} />
                  }
                  label={
                    <Typography
                      className={publicationFormClasses.label}
                      style={{ position: 'relative', left: index % 2 === 0 ? 0 : 5 }}
                    >
                      {formatMessage({ id: `rentalUnit.detail.energy.heating.${type}` })}
                    </Typography>
                  }
                  id={type}
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      </Grid>
      <Divider style={{ margin: '10px 0 10px 0' }} />
    </>
  );
};

export default HeatingEdit;
