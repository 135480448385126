import React from 'react';
import { FieldFilterSelector } from 'src/components/ui/FieldFilterSelector';
import { isEmpty, isNil, orderBy } from 'lodash';
import { useIntl } from 'react-intl';
import FieldFilterResetButton from '../../../ui/FieldFilterResetButton';
import { Building, Contact, Unit, getContactNameFromObject, uniquePush } from '@rentguru/commons-utils';
import { useBuildings } from '../../../../hooks/BuildingsContext';
import { useUnits } from '../../../../hooks/UnitsContext';
import { useContacts } from '../../../../hooks/ContactsContext';
import { filterProposedValuesBetweenEachOthers } from '../utils/DashboardFilterUtils';
import { useDashboardFilters } from 'src/hooks/DashboardFiltersContext';

export interface FilterContent {
  label: string;
  value: string;
}

export interface FilterProposedValues {
  building: Building[];
  unit: Unit[];
  owner: Contact[];
  unitType: string[];
  city: string[];
}

const DashboardFilter: React.FC = () => {
  const { formatMessage } = useIntl();
  const { getUnit, units } = useUnits();
  const { getBuilding, buildings } = useBuildings();
  const { getContact, owners: singleOwners, jointOwners } = useContacts();
  const { dashboardFilters, updateDashboardFilters, resetDashboardFilters } = useDashboardFilters();
  const { filters: filtersData, ...dashboardTimeFilters } = dashboardFilters;

  const owners = [...singleOwners, ...jointOwners];

  // Filter menu elements
  const filterBuildingValues = filtersData.find((f) => f.name === 'building');
  const initialBuildingsSelectedItems = isNil(filterBuildingValues)
    ? null
    : filterBuildingValues.items.map((b: string) => ({ value: b }));
  const filterUnitValues = filtersData.find((f) => f.name === 'unit');
  const initialUnitsSelectedItems = isNil(filterUnitValues)
    ? null
    : filterUnitValues.items.map((i: string) => ({ value: i }));
  const filterOwnerValues = filtersData.find((f) => f.name === 'owner');
  const initialOwnersSelectedItems = isNil(filterOwnerValues)
    ? null
    : filterOwnerValues.items.map((i: string) => ({ value: i }));
  const filterUnitTypesValues = filtersData.find((f) => f.name === 'unitType');
  const initialUnitsTypesSelectedItems = isNil(filterUnitTypesValues)
    ? null
    : filterUnitTypesValues.items.map((i: string) => ({ value: i }));
  const initialUnitTypes: string[] = [];
  units.forEach((unit) => {
    uniquePush(initialUnitTypes, unit.type);
  });
  const filterCityValues = filtersData.find((f) => f.name === 'city');
  const initialCitySelectedItems = isNil(filterCityValues)
    ? null
    : filterCityValues.items.map((i: string) => ({ value: i }));
  const initialCity: string[] = [];
  buildings.forEach((building) => {
    if (building.address?.city) {
      uniquePush(initialCity, building.address.city);
    }
  });
  const proposedValues: FilterProposedValues = {
    building: buildings,
    unit: units,
    owner: owners,
    unitType: initialUnitTypes,
    city: initialCity,
  };

  /* Handle filter between each other */
  const {
    building: proposedBuildings,
    unit: proposedUnits,
    owner: proposedOwners,
    unitType: proposedUnitTypes,
    city: proposedCity,
  } = filterProposedValuesBetweenEachOthers(proposedValues, filtersData, getBuilding, getContact, getUnit);

  const handleFilterChange = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedItems: any[],
    filterName: string,
    fieldName: string,
    menuClosed: boolean
  ) => {
    const filter = { name: filterName, field: fieldName, items: selectedItems.map((i) => i.value) };
    let newFilters = filtersData;
    const index = newFilters.findIndex((f) => f.name === filterName);
    if (index === -1) {
      if (!isEmpty(selectedItems)) {
        newFilters = [...newFilters, filter];
      }
    } else if (isEmpty(selectedItems)) {
      newFilters = [...newFilters.filter((f) => f.name !== filterName)];
    } else {
      newFilters[index] = filter;
      newFilters = [...newFilters];
    }
    if (!menuClosed) {
      await updateDashboardFilters({ ...dashboardTimeFilters, filters: newFilters });
    }
  };

  const resetAllFilters = async () => {
    await resetDashboardFilters('filters');
  };

  const proposedBuildingsItems = (proposedBuildings as Building[]).map((b: Building) => {
    return { label: b.name, value: b.id };
  });
  const orderedProposedBuildings = orderBy(proposedBuildingsItems, 'label');

  const proposedUnitsItems = (proposedUnits as Unit[]).map((u: Unit) => {
    return { label: u.name, value: u.id };
  });
  const orderedProposedUnits = orderBy(proposedUnitsItems, 'label');

  const proposedUnitTypesItems = (proposedUnitTypes as string[]).map((ut: string) => {
    return { label: formatMessage({ id: `enums.UnitType.${ut}` }), value: ut };
  });
  const orderedProposedUnitTypes = orderBy(proposedUnitTypesItems, 'label');

  const proposedOwnersItems = (proposedOwners as Contact[]).map((contact: Contact) => {
    return { label: getContactNameFromObject(contact), value: contact.id };
  });
  const orderedProposedOwners = orderBy(proposedOwnersItems, 'label');

  const proposedCitiesItems = (proposedCity as string[]).map((city: string) => {
    return { label: city, value: city };
  });
  const orderedProposedCities = orderBy(proposedCitiesItems, 'label');

  return (
    <div
      style={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: 20,
      }}
    >
      <FieldFilterSelector
        label={formatMessage({ id: 'building.columnHeader.city' })}
        multipleSelection
        showResetButton
        filterName="city"
        fieldName="building.address.city"
        indicator={false}
        textReset={formatMessage({ id: 'reinitFilter' })}
        textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
        textSearchField={formatMessage({ id: 'search' })}
        textSelectAll={formatMessage({ id: 'selectAll' })}
        onChange={handleFilterChange}
        initialSelectedItems={initialCitySelectedItems}
      >
        {orderedProposedCities}
      </FieldFilterSelector>

      <FieldFilterSelector
        label={formatMessage({ id: 'rentalUnit.columnHeader.building' })}
        multipleSelection
        showResetButton
        filterName="building"
        fieldName="building.id"
        indicator={false}
        textReset={formatMessage({ id: 'reinitFilter' })}
        textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
        textSearchField={formatMessage({ id: 'search' })}
        textSelectAll={formatMessage({ id: 'selectAll' })}
        onChange={handleFilterChange}
        initialSelectedItems={initialBuildingsSelectedItems}
      >
        {orderedProposedBuildings}
      </FieldFilterSelector>

      <FieldFilterSelector
        label={formatMessage({ id: 'communications.list.rentalUnit' })}
        multipleSelection
        showResetButton
        filterName="unit"
        fieldName="unit.name"
        indicator={false}
        textReset={formatMessage({ id: 'reinitFilter' })}
        textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
        textSearchField={formatMessage({ id: 'search' })}
        textSelectAll={formatMessage({ id: 'selectAll' })}
        onChange={handleFilterChange}
        initialSelectedItems={initialUnitsSelectedItems}
        scrollable
      >
        {orderedProposedUnits}
      </FieldFilterSelector>
      <FieldFilterSelector
        label={formatMessage({ id: 'rentalUnit.filter.unitTypePlaceholder' })}
        multipleSelection
        showResetButton
        filterName="unitType"
        fieldName="unitType"
        indicator={false}
        textReset={formatMessage({ id: 'reinitFilter' })}
        textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
        textSearchField={formatMessage({ id: 'search' })}
        textSelectAll={formatMessage({ id: 'selectAll' })}
        onChange={handleFilterChange}
        initialSelectedItems={initialUnitsTypesSelectedItems}
      >
        {orderedProposedUnitTypes}
      </FieldFilterSelector>
      <FieldFilterSelector
        label={formatMessage({ id: 'building.columnHeader.owner' })}
        multipleSelection
        showResetButton
        filterName="owner"
        fieldName="owners.owner.id"
        indicator={false}
        textReset={formatMessage({ id: 'reinitFilter' })}
        textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
        textSearchField={formatMessage({ id: 'search' })}
        textSelectAll={formatMessage({ id: 'selectAll' })}
        onChange={handleFilterChange}
        initialSelectedItems={initialOwnersSelectedItems}
      >
        {orderedProposedOwners}
      </FieldFilterSelector>
      <div>
        <FieldFilterResetButton resetFilterValues={async () => await resetAllFilters()} />
      </div>
    </div>
  );
};

export default DashboardFilter;
