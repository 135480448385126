/* eslint-disable no-redeclare */
import React, { useContext, useEffect, useMemo } from 'react';
import { ContextLoaderAction, ContextLoaderStore, useContextLoader } from './ContextLoader';
import { useUser } from './UserContext';
import {
  User,
  UserRole,
  syncUserRoles as syncQuery,
  getUserRole as getQuery,
  createUserRole as createMutation,
  CreateUserRoleMutationVariables as CreateMutationVariables,
  DeleteUserRoleMutationVariables as DeleteMutationVariables,
  CreateUserRoleInput as CreateInput,
  updateUserRole as updateMutation,
  deleteUserRole as deleteMutation,
  cleanInputCreate,
  cleanInputUpdate,
  getReadId,
  getTableClientId,
  Model,
  isNilOrEmpty,
  OnCreateUserRoleSubscription,
  OnUpdateUserRoleSubscription,
  OnDeleteUserRoleSubscription,
  onCreateUserRole,
  onUpdateUserRole,
  onDeleteUserRole,
} from '@rentguru/commons-utils';
import { isEqual } from 'lodash';
import { useUsers } from './UsersContext';
import {
  deleteEntityWithFetchBefore,
  getFilterFieldNameForIndex,
  list,
  mutation,
  recordWasUpdated,
  useSubscriptions,
} from '@up2rent/fetch-utils';

const ENTITY_MODEL_NAME: Model = 'UserRole';

export type ActionUserRole =
  | {
      type: 'SHOULD_FETCH_USER_ROLE' | 'IS_FETCHING_USER_ROLE';
    }
  | {
      type: 'ADD_USER_ROLE';
      payload: { userRole: UserRole };
    }
  | {
      type: 'FETCHED_USER_ROLE';
      payload: { userRoles: UserRole[] };
    }
  | {
      type: 'UPDATE_USER_ROLE';
      payload: { userRole: UserRole };
    }
  | {
      type: 'DELETE_USER_ROLE';
      payload: { id: string };
    };

export interface UserRolesContext extends UserRoleState {
  getUserRole: (id: string) => UserRole | undefined;
  createUserRole: (input: Omit<UserRole, 'id' | 'clientId' | 'readId'>) => Promise<UserRole>;
  updateUserRole: (original: UserRole, updates: Partial<UserRole>, promptRefreshToken?: boolean) => Promise<UserRole>;
  deleteUserRole: (id: string) => Promise<void>;
  getUsersWithRole: (id: string) => User[];
  setFetchUserRole: () => void;
}

interface UserRoleState {
  userRoles: UserRole[];
  loading: boolean;
  shouldFetch: boolean;
  error: string | null;
}

export const userRoleReducerDelegation = (
  state: ContextLoaderStore,
  action: ContextLoaderAction
): ContextLoaderStore => {
  switch (action.type) {
    case 'SHOULD_FETCH_USER_ROLE':
      if (state.UserRole.loading || state.UserRole.shouldFetch) {
        return state;
      }
      return {
        ...state,
        UserRole: { ...state.UserRole, shouldFetch: true },
      };
    case 'IS_FETCHING_USER_ROLE':
      if (state.UserRole.loading) {
        return state;
      }
      return {
        ...state,
        UserRole: { ...state.UserRole, loading: true },
      };
    case 'FETCHED_USER_ROLE':
      return {
        ...state,
        UserRole: {
          ...state.UserRole,
          data: action.payload.userRoles,
          loading: false,
          shouldFetch: false,
          lastFetch: new Date(),
        },
      };
    case 'ADD_USER_ROLE':
      // Check if already present - If already added by this user or coming from another user
      if (state.UserRole.data?.find((object) => object.id === action.payload.userRole.id)) {
        return state;
      }

      return {
        ...state,
        UserRole: {
          ...state.UserRole,
          data: [...state.UserRole.data, action.payload.userRole],
        },
      };
    case 'UPDATE_USER_ROLE':
      // No data
      if (isNilOrEmpty(state.UserRole.data)) {
        return state;
      }

      // Already present and same object
      const currentObject = state.UserRole.data?.find((object) => object.id === action.payload.userRole.id);
      if (!currentObject) {
        return {
          ...state,
          UserRole: {
            ...state.UserRole,
            data: [...state.UserRole.data, action.payload.userRole],
          },
        };
      }
      if (!recordWasUpdated(currentObject, action.payload.userRole)) {
        return state;
      }

      // Update
      return {
        ...state,
        UserRole: {
          ...state.UserRole,
          data: state.UserRole.data.map((object) => {
            if (object.id === action.payload.userRole.id) {
              return action.payload.userRole;
            }
            return object;
          }),
        },
      };
    case 'DELETE_USER_ROLE':
      if (isNilOrEmpty(state.UserRole.data)) {
        return state;
      }
      return {
        ...state,
        UserRole: {
          ...state.UserRole,
          data: state.UserRole.data.filter((object) => object.id !== action.payload.id),
        },
      };

    default:
      return state;
  }
};

const fetchUserRoles = async (clientId: string, additionalFilter?: object): Promise<UserRole[]> => {
  return await list<UserRole>(
    syncQuery,
    getFilterFieldNameForIndex('byClientId'),
    getTableClientId(clientId, ENTITY_MODEL_NAME),
    additionalFilter
  );
};

export const UserRolesContext = React.createContext<UserRolesContext | null>(null);

export const UserRolesContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const {
    UserRole: { data: userRoles, loading: userRolesLoading, shouldFetch },
    User: { data: users, loading: usersLoading },
    dispatch: contextDispatch,
  } = useContextLoader();
  const { updateUser } = useUsers();
  const { clientId } = useUser();

  const setFetchUserRole = () => {
    contextDispatch({ type: 'SHOULD_FETCH_USER_ROLE' });
  };

  const loading = userRolesLoading || usersLoading;

  useEffect(() => {
    const fetchAndSet = async () => {
      contextDispatch({ type: 'IS_FETCHING_USER_ROLE' });
      const result = await fetchUserRoles(clientId!);
      contextDispatch({ type: 'FETCHED_USER_ROLE', payload: { userRoles: result } });
    };
    if (shouldFetch) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch]);

  useSubscriptions<OnCreateUserRoleSubscription, OnUpdateUserRoleSubscription, OnDeleteUserRoleSubscription>(
    onCreateUserRole,
    onUpdateUserRole,
    onDeleteUserRole,
    (data) => {
      contextDispatch({
        type: 'ADD_USER_ROLE',
        payload: { userRole: data.onCreateUserRole as UserRole },
      });
    },
    (data) => {
      contextDispatch({
        type: 'UPDATE_USER_ROLE',
        payload: { userRole: data.onUpdateUserRole as UserRole },
      });
    },
    (data) => {
      const { id } = data.onDeleteUserRole as UserRole;
      contextDispatch({
        type: 'DELETE_USER_ROLE',
        payload: { id },
      });
    }
  );

  const getUserRole = (id: string): UserRole | undefined => {
    if (loading) {
      return undefined;
    }
    return userRoles.find((u) => u.id === id);
  };

  const createUserRole = async (input: Omit<UserRole, 'id' | 'clientId' | 'readId'>) => {
    const userRole = await mutation<UserRole, CreateMutationVariables>(createMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateInput),
        clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME),
        readId: getReadId(clientId!, ENTITY_MODEL_NAME),
      },
    });
    contextDispatch({ type: 'ADD_USER_ROLE', payload: { userRole } });
    return userRole;
  };

  const getUsersWithRole = (id: string) => {
    return users.filter((user) => user.userRoleId === id);
  };

  const updateUserRole = async (
    original: UserRole,
    updates: Partial<UserRole>,
    promptRefreshToken?: boolean
  ): Promise<UserRole> => {
    const input = { ...cleanInputUpdate({ id: original.id, _version: original._version, ...updates }, false, true) };
    const result = await mutation<UserRole>(updateMutation, {
      input,
    });
    contextDispatch({ type: 'UPDATE_USER_ROLE', payload: { userRole: result } });
    if (updates.userRights && !isEqual(original.userRights, updates.userRights) && promptRefreshToken) {
      const usersWithRole = getUsersWithRole(original.id);
      await Promise.all(usersWithRole.map(async (user) => await updateUser(user, { refreshToken: true })));
    }
    return result;
  };

  const deleteUserRole = async (id: string) => {
    await deleteEntityWithFetchBefore<Pick<UserRole, 'id'>, DeleteMutationVariables>({ id }, getQuery, deleteMutation);

    contextDispatch({ type: 'DELETE_USER_ROLE', payload: { id } });
  };

  const values = useMemo(
    () => ({
      shouldFetch,
      userRoles,
      error: null,
      loading,
      getUserRole,
      createUserRole,
      updateUserRole,
      deleteUserRole,
      getUsersWithRole,
      setFetchUserRole,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userRoles, loading, shouldFetch]
  );

  return <UserRolesContext.Provider value={values}>{children}</UserRolesContext.Provider>;
};

export const useUserRoles = (): UserRolesContext => {
  const context = useContext<UserRolesContext | null>(UserRolesContext);

  if (context === undefined) {
    throw new Error('`useUserRoles` hook must be used within a `UserRolesContextProvider` component');
  }
  return context as UserRolesContext;
};
