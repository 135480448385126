/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable func-names */
import { Divider, Grid } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { isEmpty, isEqual, isNil, isNumber, toUpper } from 'lodash';
import React, { useMemo } from 'react';
import { useIntl, MessageDescriptor } from 'react-intl';
import { IntlListFormatOptions } from '@formatjs/intl-listformat';
import {
  AgencyRateOwner,
  AgencyRateType,
  UnitType,
  AgencyRate,
  AgencyRateAmountDetailType,
  AgencyRateFeesType,
  AgencyRateRepartitionType,
  AgencyRateIncludedAmount,
  Contact,
  Unit,
  Building,
  AgencyRateOwner as AgencyRateOwnerModel,
  Colors,
  getContactNameFromObject,
  getSelectableUnitTypeForAgencyRateOfOwner,
} from '@rentguru/commons-utils';
import { ActionButton , ContentHeader, LoaderButton } from '@up2rent/ui';
import TextDetailEditable from 'src/components/ui/TextDetailEditable';
import { useAgencyRates } from 'src/hooks/AgencyRatesContext';
import { isValidPositiveNumber } from 'src/utils/formutils';
import * as Yup from 'yup';
import AgencyRateAmountDetailsFields from './AddEditForms/AgencyRateAmountDetailsFields';
import AgencyRateFeesAppliedToField from './AddEditForms/AgencyRateFeesAppliedToField';
import AgencyRateIncludedInAmountField from './AddEditForms/AgencyRateIncludedInAmountField';
import AgencyRateMinimumFeesAndAmountIfVacantFields from './AddEditForms/AgencyRateMinimumFeesAndAmountIfVacantFields';
import AgencyRateRepartitionTypeField from './AddEditForms/AgencyRateRepartitionTypeField';
import AgencyRateSimulationField from './AddEditForms/AgencyRateSimulationField';
import AgencyRateTypeField from './AddEditForms/AgencyRateTypeField';
import AgencyRateUnitTypeField from './AddEditForms/AgencyRateUnitTypeField';
import { useUnits } from 'src/hooks/UnitsContext';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { useContacts } from 'src/hooks/ContactsContext';
import RouteDiscardChanges from 'src/components/ui/Dialogs/DiscardChanges/RouteDiscardChanges';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Skeleton } from '@material-ui/lab';

export const getDynamicAddEditAgencyRateSchema = (
  oldAgencyRate: AgencyRate | null | undefined,
  getContact: (id: string) => Contact | undefined,
  buildings: Building[],
  units: Unit[],
  agencyRateOwners: AgencyRateOwnerModel[] | null,
  formatList: (values: readonly string[], opts?: IntlListFormatOptions | undefined) => string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatMessage: (descriptor: MessageDescriptor, values?: any) => string
) => {
  return Yup.object().shape({
    amountDetails: Yup.array()
      .of(
        Yup.object().shape({
          amountType: Yup.string().required(),
          endAmount: Yup.number()
            .positive()
            .notRequired()
            .nullable()
            .test('endAmountBiggerThanStart', 'endAmountBiggerThanStart', function (value) {
              // Test endAmount > startAmount
              return isNil(value) || this.parent.startAmount < value;
            }),
          feeApplied: Yup.number().positive().required(),
          startAmount: Yup.number().required(),
        })
      )
      .test('If amounts check line', 'check previous value is < than next', function (value) {
        if (isNil(value)) {
          return true;
        }

        const innerErrors = value.reduce((innerErrors, currentAmountDetail, currentIndex) => {
          if (isNil(currentAmountDetail)) {
            return innerErrors;
          }

          // Check valid and positive for 3 fields isValidPositiveNumber
          if (!isValidPositiveNumber(currentAmountDetail.feeApplied)) {
            innerErrors.push(
              this.createError({
                path: `amountDetails[${currentIndex}].feeApplied`,
                message: 'Mandatory feeApplied field',
              })
            );
          }
          if (!isValidPositiveNumber(currentAmountDetail.startAmount) && currentIndex !== 0) {
            // Start amount is hardcoded at 0
            innerErrors.push(
              this.createError({
                path: `amountDetails[${currentIndex}].startAmount`,
                message: 'Mandatory startAmount field',
              })
            );
          }
          if (!isValidPositiveNumber(currentAmountDetail.endAmount) && currentIndex + 1 !== value.length) {
            innerErrors.push(
              this.createError({
                path: `amountDetails[${currentIndex}].endAmount`,
                message: 'Mandatory endAmount field',
              })
            );
          }

          // Check that endAmount > startAmount
          if (
            isNumber(currentAmountDetail.startAmount) &&
            isNumber(currentAmountDetail.endAmount) &&
            currentAmountDetail.endAmount < currentAmountDetail.startAmount
          ) {
            innerErrors.push(
              this.createError({
                path: `amountDetails[${currentIndex}].startAmount`,
                message: 'Start amount should be lower than endAmount',
              })
            );
            innerErrors.push(
              this.createError({
                path: `amountDetails[${currentIndex}].endAmount`,
                message: 'Start amount should be lower than endAmount',
              })
            );
          }

          if (currentIndex !== 0) {
            // Check that startAmount > previousEndAmount
            const previousEndAmount = value[currentIndex - 1].endAmount;
            if (
              isNumber(currentAmountDetail.startAmount) &&
              isNumber(previousEndAmount) &&
              currentAmountDetail.startAmount < previousEndAmount
            ) {
              innerErrors.push(
                this.createError({
                  path: `amountDetails[${currentIndex - 1}].endAmount`,
                  message: 'Start amount should be lower than previous endAmount',
                })
              );
              innerErrors.push(
                this.createError({
                  path: `amountDetails[${currentIndex}].startAmount`,
                  message: 'Start amount should be lower than previous endAmount',
                })
              );
            }
            // Check there is no hole in the amounts
            if (
              isNumber(currentAmountDetail.startAmount) &&
              isNumber(previousEndAmount) &&
              currentAmountDetail.startAmount - previousEndAmount > 1
            ) {
              innerErrors.push(
                this.createError({
                  path: `amountDetails[${currentIndex}].startAmount`,
                  message: 'There is a gap between this startAmount and previous amount',
                })
              );
            }
          }

          return innerErrors;
        }, [] as Yup.ValidationError[]);

        if (!isEmpty(innerErrors)) {
          const error = new Yup.ValidationError('Mandatory amountDetails fields', value, 'amountDetails');
          error.inner = innerErrors;
          return error;
        }
        return true;
      }),
    amountIfVacantUnit: Yup.number().positive().notRequired().nullable(),
    amountMinimumFees: Yup.number().positive().notRequired().nullable(),
    custom: Yup.bool().required(),
    feesAppliedTo: Yup.string().required(),
    includedInAmount: Yup.array().of(Yup.string()).min(1),
    name: Yup.string().required(),
    repartitionType: Yup.string().required(),
    type: Yup.string().required(),
    unitType: Yup.array()
      .of(Yup.string())
      .test(
        'Check if already assigned owner can have those types',
        'owner type mismatch error',
        async function (value) {
          if (isNil(value)) return false;
          if (!oldAgencyRate || !agencyRateOwners) {
            return true;
          }
          // Get new selected unit type
          const newUnitTypes = value.reduce((acc, currentType) => {
            if (!oldAgencyRate.unitType.includes(currentType as UnitType)) {
              acc.push(currentType as UnitType);
            }
            return acc;
          }, [] as UnitType[]);

          // Check on each owner assinged to this rate card if they already have rate card covered by this new type
          const ownersInError = oldAgencyRate.owners?.reduce((currentResult: string[], agencyRateOwner) => {
            // Get complete
            const currentOwner = getContact(agencyRateOwner.owner!.id);
            if (!currentOwner) {
              return currentResult;
            }
            const selectableUnitTypes = getSelectableUnitTypeForAgencyRateOfOwner(
              currentOwner,
              buildings,
              units,
              agencyRateOwners as AgencyRateOwner[]
            );
            if (!selectableUnitTypes) {
              currentResult.push(getContactNameFromObject(currentOwner));
            }

            if (newUnitTypes.some((newUnitType) => !selectableUnitTypes!.includes(newUnitType))) {
              currentResult.push(getContactNameFromObject(currentOwner));
            }
            return currentResult;
          }, []);

          if (!ownersInError || ownersInError.length === 0) {
            return true;
          }

          const ownerList = formatList(ownersInError, { type: 'conjunction' });
          return this.createError({
            path: `unitType`,
            message: formatMessage(
              { id: 'agencyRates.detail.unitTypeAlreadyCovered' },
              { ownersName: ownerList, owners: ownersInError.length }
            ),
          });
        }
      ),
  });
};

export type AddEditAgencyRateValues = Pick<
  AgencyRate,
  | 'amountDetails'
  | 'amountIfVacantUnit'
  | 'amountMinimumFees'
  | 'custom'
  | 'feesAppliedTo'
  | 'includedInAmount'
  | 'name'
  | 'repartitionType'
  | 'type'
  | 'unitType'
> & {
  id?: string;
  applyImmediatly: boolean;
  simulationAmount: number;
  selectableUnitType: UnitType[];
};

export const emptyAmountDetail = { amountType: AgencyRateAmountDetailType.PERCENTAGE, startAmount: 0, feeApplied: 0 };

const areValuesEquals = (initialValues: AddEditAgencyRateValues, values: AddEditAgencyRateValues) => {
  const { simulationAmount: _simulationAmount, ...initialWithoutSimulation } = initialValues;
  const { simulationAmount: _valuesSimulation, ...valuesWithoutSimulation } = values;

  return isEqual(initialWithoutSimulation, valuesWithoutSimulation);
};

interface AddEditAgencyRateForm {
  id?: string;
  owner?: Contact;
  afterSubmit: (agencyRate?: AgencyRate) => void;
}
// eslint-disable-next-line no-redeclare
const AddEditAgencyRateForm: React.FC<AddEditAgencyRateForm> = ({ id, owner, afterSubmit }) => {
  const { formatMessage, formatList } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { createAgencyRate, updateAgencyRate, getAgencyRate, loading, error, createAgencyRateOwner, agencyRateOwners } =
    useAgencyRates();
  const { units, unitsLoading } = useUnits();
  const { buildings, buildingsLoading } = useBuildings();
  const { contactsLoading, getContact } = useContacts();
  const history = useHistory();

  const dataLoading = loading || unitsLoading || buildingsLoading || contactsLoading;

  const selectableUnitType = useMemo<UnitType[]>(() => {
    const allUnitTypes = Object.keys(UnitType);
    if (!owner || dataLoading) {
      return allUnitTypes as UnitType[];
    }
    const selectableTypes = getSelectableUnitTypeForAgencyRateOfOwner(
      owner,
      buildings,
      units,
      agencyRateOwners ?? ([] as AgencyRateOwner[])
    );

    return selectableTypes ? (selectableTypes as UnitType[]) : (allUnitTypes as UnitType[]);
  }, [dataLoading, agencyRateOwners, buildings, owner, units]);

  const handleCreateUpdate = async (
    values: AddEditAgencyRateValues,
    { setSubmitting, setStatus }: FormikHelpers<AddEditAgencyRateValues>
  ) => {
    const {
      applyImmediatly: _applyImmediatly,
      simulationAmount: _simulationAmount,
      amountDetails,
      selectableUnitType: _selectableUnitType,
      ...rest
    } = values;
    let agencyRate;
    if (isNil(id)) {
      agencyRate = await createAgencyRate({
        ...rest,
        feesAppliedTo: rest.feesAppliedTo as AgencyRateFeesType,
        includedInAmount: rest.includedInAmount as AgencyRateIncludedAmount[],
        repartitionType: rest.repartitionType as AgencyRateRepartitionType,
        type: rest.type as AgencyRateType,
        unitType: rest.unitType as UnitType[],
        amountDetails: amountDetails.map((ad) => ({
          ...ad,
          endAmount: ad.endAmount && isNaN(parseFloat(`${ad.endAmount}`)) ? undefined : parseFloat(`${ad.endAmount}`),
          amountType: ad.amountType as AgencyRateAmountDetailType,
        })),
        custom: !isNil(owner),
      });
      if (!isNil(owner)) {
        await createAgencyRateOwner({ agencyRateId: agencyRate.id, contactId: owner.id });
      }
    } else {
      const oldAgencyRate = getAgencyRate(id);
      const { owners: _owners, ...oldAgencyRateWithoutOwners } = oldAgencyRate as AgencyRate & {
        owners: AgencyRateOwner[];
      };
      // Update
      agencyRate = await updateAgencyRate({
        ...oldAgencyRateWithoutOwners,
        ...rest,
        feesAppliedTo: rest.feesAppliedTo as AgencyRateFeesType,
        includedInAmount: rest.includedInAmount as AgencyRateIncludedAmount[],
        repartitionType: rest.repartitionType as AgencyRateRepartitionType,
        type: rest.type as AgencyRateType,
        unitType: rest.unitType as UnitType[],
        amountDetails: amountDetails.map((ad) => ({
          ...ad,
          endAmount: ad.endAmount && isNaN(parseFloat(`${ad.endAmount}`)) ? undefined : parseFloat(`${ad.endAmount}`),
          amountType: ad.amountType as AgencyRateAmountDetailType,
        })),
        id: id!,
      });
    }

    setStatus(true);
    setSubmitting(false);
    afterSubmit(agencyRate!);
    enqueueSnackbar(formatMessage({ id: 'settings.changesSaved' }), { variant: 'success' });
  };

  let oldAgencyRate: AgencyRate | null | undefined;
  if (!isNil(id)) {
    if (dataLoading || error) {
      return (
        <Grid style={{ marginLeft: 30, marginRight: 30 }}>
          <Skeleton />
        </Grid>
      );
    }
    oldAgencyRate = getAgencyRate(id);
  }

  const AddEditAgencyRateSchema = getDynamicAddEditAgencyRateSchema(
    oldAgencyRate,
    getContact,
    buildings,
    units,
    agencyRateOwners,
    formatList,
    formatMessage
  );

  const initialValues: AddEditAgencyRateValues = {
    id,
    amountDetails: oldAgencyRate?.amountDetails || [emptyAmountDetail],
    custom: oldAgencyRate?.custom || false,
    feesAppliedTo: oldAgencyRate?.feesAppliedTo || AgencyRateFeesType.AMOUNT_COLLECTED,
    name: oldAgencyRate?.name || '',
    repartitionType: oldAgencyRate?.repartitionType || AgencyRateRepartitionType.BY_UNIT,
    type: oldAgencyRate?.type || AgencyRateType.FIXED,
    unitType: oldAgencyRate?.unitType || (selectableUnitType as UnitType[]),
    amountIfVacantUnit: oldAgencyRate?.amountIfVacantUnit || undefined,
    amountMinimumFees: oldAgencyRate?.amountMinimumFees || undefined,
    includedInAmount: oldAgencyRate?.includedInAmount || [AgencyRateIncludedAmount.INDEXED_PRICE],
    applyImmediatly: true,
    simulationAmount: 1000,
    selectableUnitType,
  };

  return (
    <>
      {!id && (
        <>
          <ContentHeader
            toolbarStyle={{ display: 'flex', justifyContent: 'space-between' }}
            title={
              isNil(owner)
                ? formatMessage({ id: `agencyRates.add` })
                : formatMessage({ id: `agencyRates.addCustom` }, { name: getContactNameFromObject(owner) })
            }
          />
          <Divider style={{ marginBottom: 20 }} />
        </>
      )}
      <Formik initialValues={initialValues} validationSchema={AddEditAgencyRateSchema} onSubmit={handleCreateUpdate}>
        {({ values, errors, touched, isSubmitting, status }) => {
          return (
            <Form>
              <div style={{ marginLeft: 30, marginRight: 30 }}>
                <Grid container>
                  <Grid item xs={6}>
                    <TextDetailEditable
                      title={formatMessage({ id: 'agencyRates.detail.name' })}
                      editMode={true}
                      name="name"
                      type="text"
                      style={{ width: 280, maxWidth: 'none', marginBottom: 35 }}
                      error={Boolean(errors.name && touched.name)}
                    />
                  </Grid>
                  <Grid item xs={6} />
                  <Grid item xs={6}>
                    <AgencyRateFeesAppliedToField />
                  </Grid>
                  <Grid item xs={6}>
                    <AgencyRateIncludedInAmountField />
                  </Grid>
                </Grid>
              </div>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <div style={{ marginLeft: 30, marginRight: 30 }}>
                <AgencyRateTypeField />
              </div>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <div style={{ marginLeft: 30, marginRight: 30 }}>
                <AgencyRateUnitTypeField />
              </div>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <div style={{ marginLeft: 30, marginRight: 30 }}>
                <AgencyRateRepartitionTypeField />
              </div>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <div style={{ marginLeft: 30, marginRight: 30 }}>
                <AgencyRateAmountDetailsFields />
              </div>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <div style={{ marginLeft: 30, marginRight: 30 }}>
                <AgencyRateMinimumFeesAndAmountIfVacantFields />
              </div>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <div style={{ marginLeft: 30, marginRight: 30 }}>
                <AgencyRateSimulationField />
              </div>
              {!oldAgencyRate && <Divider style={{ marginTop: 20, marginBottom: 20 }} />}
              <Grid
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  marginRight: 30,
                  marginLeft: 30,
                  ...(oldAgencyRate
                    ? { backgroundColor: 'white', position: 'sticky', bottom: 0, height: 70, zIndex: 99 }
                    : { marginBottom: 20 }),
                }}
              >
                {!oldAgencyRate && (
                  <ActionButton
                    onClick={() => afterSubmit()}
                    style={{
                      background: 'none',
                      color: Colors.DARK_SLATE_GREY,
                      marginRight: 20,
                    }}
                  >
                    {toUpper(formatMessage({ id: 'cancel' }))}
                  </ActionButton>
                )}

                <LoaderButton loading={isSubmitting} success={status}>
                  {formatMessage({ id: 'save' })}
                </LoaderButton>
              </Grid>
              <RouteDiscardChanges
                when={!areValuesEquals(initialValues, values)}
                history={history}
                onConfirm={() => afterSubmit()}
                shouldBlockNavigation={() => {
                  return true;
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddEditAgencyRateForm;
