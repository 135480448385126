import { type BasicModel } from '@rentguru/commons-utils';
import { getFilters, getGraphQlMethodName, getLambdaAuthorizerTokenParam } from './utils';

const sendGraphqlRequest = async (
  url: string,
  data: {
    query: string;
    variables?: { filter?: object | null; limit?: number; nextToken?: string; id?: string };
  }
) => {
  const token = (await getLambdaAuthorizerTokenParam(true)) ?? '';
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Authorization: token,
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const getGraphqlWithFetch = async <ResultType extends object>(url: string, getQuery: string, id: string) => {
  const method = getGraphQlMethodName(getQuery, 'query');
  const variables = { id };
  const { data } = (await sendGraphqlRequest(url, { query: getQuery, variables })) as {
    data: { [method: string]: ResultType };
  };

  const result = data[method] as BasicModel<ResultType>;

  return result;
};

export const mutationGraphqlWithFetch = async <ResultType extends object, VariablesType extends object = object>(
  url: string,
  query: string,
  variables?: VariablesType
) => {
  const method = getGraphQlMethodName(query, 'mutation');
  const { data } = (await sendGraphqlRequest(url, { query, variables })) as {
    data: { [method: string]: ResultType };
  };

  const result = data[method] as BasicModel<ResultType>;

  return result;
};

export const listGraphqlWithFetch = async <ResultType extends object>(
  url: string,
  query: string,
  indexName?: string,
  indexValue?: string,
  additionalFilter?: object,
  lastSync?: Date,
  limit: number = 1000
) => {
  const method = getGraphQlMethodName(query, 'query');
  const filter = getFilters(indexName, indexValue, additionalFilter);
  const isSync = Boolean(query.match(/query Sync[\W\w]*\([\W\w]*\$lastSync/gm));
  let entities: ResultType[] = [];
  let nextToken: string | null = null;

  try {
    do {
      const variables: { limit: number; filter?: object | null; nextToken?: string; lastSync?: string } = {
        limit,
        filter,
        nextToken: !nextToken || nextToken === '' ? undefined : nextToken,
      };
      if (isSync && lastSync) {
        variables['lastSync'] = lastSync.toISOString();
      }

      const listResultRaw = (await sendGraphqlRequest(url, { query, variables })) as {
        data: { [method: string]: { items: ResultType[]; nextToken: string | null } };
      };

      const listResultCleaned = listResultRaw.data[method].items.filter(
        (res: { _deleted?: boolean }) => res._deleted !== true
      );
      entities = [...entities, ...listResultCleaned];
      nextToken = listResultRaw.data[method].nextToken;
    } while (nextToken);
    return entities;
  } catch (err) {
    console.error('error', err);
    return [] as ResultType[];
  }
};
