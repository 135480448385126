import {
  EmptyFile,
  EmptyFileWithUrl,
  File as FileModel,
  LeaseInventory,
  LeaseInventoryEncoding,
  LeaseInventoryFurnitureExitIssue,
  LeaseInventoryType,
  LooseObject,
  S3Object,
} from '@rentguru/commons-utils';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { FurnitureInventoryFormik } from 'src/components/FurnitureInventory/FurnitureInventoryDetails';
import { getS3ObjectUrls } from 'src/hooks/FilesContext';
import { ExtendedUnitInventory } from 'src/hooks/UnitInventoryContext';

export const fetchEncodingMediaFiles = async (
  medias: FileModel[],
  unmounted: boolean,
  afterSubmitAudioFiles?: (audioFiles: (S3Object | EmptyFileWithUrl)[]) => void,
  afterSubmitPictureFiles?: (pictureFiles: (S3Object | EmptyFileWithUrl)[]) => void
) => {
  const fetchAudio = !isNil(afterSubmitAudioFiles);
  const fetchPictures = !isNil(afterSubmitPictureFiles);
  const audioFileNested: (S3Object | EmptyFileWithUrl)[] = [];
  const pictureFilesNested: (S3Object | EmptyFileWithUrl)[] = [];
  const files = (medias as unknown as (FileModel | EmptyFile)[]).reduce(
    (acc: FileModel[], media: EmptyFile | FileModel) => {
      if ('file' in media) {
        const emptyMediaWithUrl = { ...media, url: URL.createObjectURL(media.file) };
        if (media.file.type.includes('audio') && fetchAudio) {
          audioFileNested.push(emptyMediaWithUrl);
        } else if (fetchPictures) {
          pictureFilesNested.push(emptyMediaWithUrl);
        }
      } else {
        acc.push(media);
      }
      return acc;
    },
    []
  );
  const filesPromise = getS3ObjectUrls(files);
  const s3Objects = await filesPromise;
  s3Objects.forEach((s3Object) => {
    if (!isNil(s3Object.mimeType)) {
      if (s3Object.mimeType.includes('audio') && fetchAudio) {
        audioFileNested.push(s3Object);
      } else if (fetchPictures) {
        pictureFilesNested.push(s3Object);
      }
    }
  });
  if (!unmounted) {
    if (!isNil(afterSubmitAudioFiles)) afterSubmitAudioFiles(audioFileNested);
    if (!isNil(afterSubmitPictureFiles)) afterSubmitPictureFiles(pictureFilesNested);
  }
};

export interface UnitInventoryFlattenOnIssues {
  unitInventoryName: string;
  structures: UnitStructureFlattenOnIssues[];
}
interface UnitStructureFlattenOnIssues {
  structureName: string;
  furnitureQuantity: number;
  issues: NamedLeaseInventoryFurnitureExitIssue[];
}

interface NamedLeaseInventoryFurnitureExitIssue extends LeaseInventoryFurnitureExitIssue {
  name: string;
}
export const flattenUnitInventoriesOnIssues = (
  unitInventories: ExtendedUnitInventory[]
): UnitInventoryFlattenOnIssues[] => {
  const unitInventoryFlattenOnIssues = unitInventories.reduce(
    (acc: UnitInventoryFlattenOnIssues[], unitInventory, index) => {
      const unitInventoryFlattenedStructures = flattenUnitStructuresOnIssues(unitInventory, index);
      if (!isEmpty(unitInventoryFlattenedStructures))
        acc.push({ unitInventoryName: unitInventory.roomName!, structures: unitInventoryFlattenedStructures });
      return acc;
    },
    []
  );
  return unitInventoryFlattenOnIssues;
};

const flattenUnitStructuresOnIssues = (unitInventory: ExtendedUnitInventory, unitInventoryIndex: number) => {
  const flattenedUnitStructures = unitInventory.structures.reduce(
    (acc: UnitStructureFlattenOnIssues[], structure, structureIndex) => {
      let issues: NamedLeaseInventoryFurnitureExitIssue[] = [];
      let furnitureQuantity: number = 1;
      structure.encodings.forEach((encoding, encodingIndex) => {
        const namedExitIssues = getNamedExitIssuesOfEncoding(
          encoding,
          unitInventoryIndex,
          structureIndex,
          encodingIndex
        );
        if (!isNil(encoding.furnitureQuantity)) furnitureQuantity = encoding.furnitureQuantity;
        issues = [...issues, ...namedExitIssues];
      });
      if (!isEmpty(issues)) acc.push({ structureName: structure.name, furnitureQuantity, issues });
      return acc;
    },
    []
  );
  return flattenedUnitStructures;
};

const getNamedExitIssuesOfEncoding = (
  encoding: LeaseInventoryEncoding,
  unitInventoryIndex: number,
  unitStructureIndex: number,
  encodingIndex: number
): NamedLeaseInventoryFurnitureExitIssue[] => {
  let namesExitIssues: NamedLeaseInventoryFurnitureExitIssue[] = [];
  if (!isNil(encoding.furnitureExitIssues)) {
    namesExitIssues = encoding.furnitureExitIssues.reduce(
      (acc: NamedLeaseInventoryFurnitureExitIssue[], exitIssue, issueIndex) => {
        const namedExitIssue = {
          ...exitIssue,
          // eslint-disable-next-line max-len
          name: `localUnitInventories[${unitInventoryIndex}].structures[${unitStructureIndex}].encodings[${encodingIndex}].furnitureExitIssues[${issueIndex}]`,
        };
        acc.push(namedExitIssue);
        return acc;
      },
      []
    );
  }
  return namesExitIssues;
};

interface TotalPenaltyAndNumberOfIssues {
  totalPenalty: number;
  numberOfIssues: number;
}
export const calculateTotalPriceAndNumberOfIssues = (flattenedUnitInventories: UnitInventoryFlattenOnIssues[]) => {
  return flattenedUnitInventories.reduce(
    (acc: TotalPenaltyAndNumberOfIssues, flattenedUnitInventory) => {
      for (const structure of flattenedUnitInventory.structures) {
        for (const issue of structure.issues) {
          acc.numberOfIssues += issue.quantity;
          if (!isNil(issue.penalty)) {
            acc.totalPenalty += issue.penalty;
          }
        }
      }
      return acc;
    },
    { totalPenalty: 0, numberOfIssues: 0 }
  );
};

export const deleteNamedLeaseInventoryFurnitureExitIssue = (
  issueName: string,
  values: FurnitureInventoryFormik,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue: (name: string, value: any) => void
) => {
  // Get all Index in [Index]blabla[Index]....[Index]
  const regex = /\[(.+?)\]/gm;
  const allIndexes: number[] = [];
  let nextMatch: RegExpExecArray | null = regex.exec(issueName);
  while (nextMatch) {
    allIndexes.push(Number(nextMatch[1]));
    nextMatch = regex.exec(issueName);
  }
  if (!isEmpty(allIndexes)) {
    const [unitInventoryIndex, structureIndex, encodingIndex, issueIndex] = allIndexes;
    const newUnitInventories = [...values.localUnitInventories];
    const unitInventory = newUnitInventories[unitInventoryIndex];
    const unitStructure = unitInventory.structures[structureIndex];
    const encoding = unitStructure.encodings[encodingIndex];
    const issues = encoding.furnitureExitIssues!;
    const newIssues = issues.filter((_issue, index) => index !== issueIndex);
    (encoding as LooseObject).furnitureExitIssues = newIssues;
    setFieldValue('localUnitInventories', newUnitInventories);
  }
};

export const exitFurnitureInventoryHasUncheckedEncodings = (
  leaseInventory: LeaseInventory,
  getEncodingsOfLeaseInventory: (leaseInventoryId: string) => LeaseInventoryEncoding[]
) => {
  let foundUncheckedEncoding = false;
  if (leaseInventory.inventoryType === LeaseInventoryType.FURNITURES_EXIT) {
    const encodings = getEncodingsOfLeaseInventory(leaseInventory.id);
    foundUncheckedEncoding = encodings.some(
      (encoding) => !isNil(encoding.furnitureQuantity) && isNil(encoding.furnitureExitIssues)
    );
  }
  return foundUncheckedEncoding;
};
