import { Divider, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import { addDays, addMonths } from 'date-fns';
import React from 'react';
import { useIntl } from 'react-intl';
import { stopPropagation } from 'src/components/Dashboard/Dashboard';

interface SnoozeMenuProps {
  anchorEl: HTMLElement | null;
  snoozeAction: (snoozeDate: Date) => Promise<void>;
  customSnooze: () => void;
  onClose: () => void;
}

const SnoozeMenu: React.FC<SnoozeMenuProps> = ({ anchorEl, snoozeAction, customSnooze, onClose }) => {
  const { formatMessage } = useIntl();
  return (
    <Menu
      onMouseDown={stopPropagation}
      id="action-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={(e: React.MouseEvent) => {
        e.stopPropagation();
        onClose();
      }}
      style={{ transform: 'translateY(40px)' }}
    >
      <MenuItem
        onMouseDown={stopPropagation}
        onClick={async (e) => {
          e.stopPropagation();
          await snoozeAction(addDays(new Date(), 7));
        }}
      >
        <ListItemText
          primary={formatMessage({ id: 'dashboard.snooze.oneWeek' }).toUpperCase()}
          primaryTypographyProps={{
            style: {
              fontSize: 11,
              color: Colors.DARK_SLATE_GREY,
              fontWeight: 700,
              letterSpacing: 1,
              fontFamily: 'Mulish',
            },
          }}
        />
      </MenuItem>
      <MenuItem
        onClick={async (e) => {
          e.stopPropagation();
          await snoozeAction(addMonths(new Date(), 1));
        }}
      >
        <ListItemText
          primary={formatMessage({ id: 'dashboard.snooze.oneMonth' }).toUpperCase()}
          primaryTypographyProps={{
            style: {
              fontSize: 11,
              color: Colors.DARK_SLATE_GREY,
              fontWeight: 700,
              letterSpacing: 1,
              fontFamily: 'Mulish',
            },
          }}
        />
      </MenuItem>
      <MenuItem
        onClick={async (e) => {
          e.stopPropagation();
          await snoozeAction(addMonths(new Date(), 3));
        }}
      >
        <ListItemText
          primary={formatMessage({ id: 'dashboard.snooze.threeMonths' }).toUpperCase()}
          primaryTypographyProps={{
            style: {
              fontSize: 11,
              color: Colors.DARK_SLATE_GREY,
              fontWeight: 700,
              letterSpacing: 1,
              fontFamily: 'Mulish',
            },
          }}
        />
      </MenuItem>
      <Divider style={{ marginTop: 5, marginBottom: 5 }} />
      <MenuItem
        onClick={(e) => {
          e.stopPropagation();
          customSnooze();
        }}
      >
        <ListItemText
          primary={formatMessage({ id: 'dashboard.snooze.custom' }).toUpperCase()}
          primaryTypographyProps={{
            style: {
              fontSize: 11,
              color: Colors.DARK_SLATE_GREY,
              fontWeight: 700,
              letterSpacing: 1,
              fontFamily: 'Mulish',
            },
          }}
        />
      </MenuItem>
    </Menu>
  );
};

export default SnoozeMenu;
