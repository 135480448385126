/* eslint-disable default-case */
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { capitalize, isNil } from 'lodash';
import { formatDistanceToNow } from 'date-fns/esm';
import { friendlyFormatIBAN } from 'ibantools';
import { Grid, ListItem, Typography } from '@material-ui/core';
import {
  CustomSubscription,
  Colors,
  getLocaleFromLanguage,
  IntegrationNotificationMessageType,
} from '@rentguru/commons-utils';
import { useCustomSubscriptions } from 'src/hooks/CustomSubscriptionContext';
import { useBankAccounts } from 'src/hooks/BankAccountsContext';
import { useUser } from 'src/hooks/UserContext';
import { ReactComponent as SettingsIcon } from 'src/icons/icon-bigones-integrations.svg';
import { RouteDestination } from 'src/components/Routes/Routes';
import NotificationDeleteIconButton from 'src/components/ui/CustomIconButtons/NotificiationDeleteIconButton';
import { useNotificationListStyle } from './NotificationsList';

interface NotificationsIntegrationItemProps {
  name: string;
  type: IntegrationNotificationMessageType;
  newBankAccountId?: string;
  customSubscription: CustomSubscription;
}

const NotificationsIntegrationItem: React.FC<NotificationsIntegrationItemProps> = ({
  name,
  type,
  newBankAccountId,
  customSubscription,
}) => {
  const { formatMessage } = useIntl();
  const localStyles = useNotificationListStyle();

  const { language } = useUser();
  const { deleteCustomSubscription } = useCustomSubscriptions();
  const { getBankAccount, loading } = useBankAccounts();

  const isNewBankAccount = type === IntegrationNotificationMessageType.BANKING_NEW_BANK_ACCOUNT;
  if (isNewBankAccount && !isNil(newBankAccountId) && loading) {
    return null;
  }

  let bankAccountNumber = '';
  if (isNewBankAccount && !isNil(newBankAccountId)) {
    bankAccountNumber = friendlyFormatIBAN(getBankAccount(newBankAccountId)?.number ?? '', ' ') ?? '';
  }

  const nameCamelCase = capitalize(name);

  return (
    <ListItem className={localStyles.listItem}>
      <Grid>
        <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <SettingsIcon
                style={{
                  transform: 'scale(0.6)',
                  width: 45,
                  height: 45,
                }}
              />
            </div>
            <Typography variant="h6" style={{ color: Colors.CLASSICAL_BLACK, textJustify: 'inter-word' }}>
              {formatMessage(
                {
                  id: `settings.integrations.notification.title.${type}`,
                },
                { name: nameCamelCase }
              )}
            </Typography>
          </Grid>
          <NotificationDeleteIconButton
            onClick={() => {
              deleteCustomSubscription(customSubscription);
            }}
          />
        </Grid>
        {!isNil(customSubscription.createdAt) && (
          <Typography style={{ color: Colors.BLUEY, paddingBottom: 5 }}>
            {formatDistanceToNow(new Date(customSubscription.createdAt!), {
              locale: getLocaleFromLanguage(language || 'en'),
            })}
          </Typography>
        )}
        <Grid style={{ color: Colors.SLATE_GREY }}>
          <Typography>
            {formatMessage(
              { id: `settings.integrations.notification.body.${type}1` },
              { bankAccountNumber, name: nameCamelCase }
            )}
            <Link
              to={{
                pathname: `${RouteDestination.SETTINGS}/${isNewBankAccount ? 'bankAccounts' : 'integrations'}`,
                state: { from: name.toLowerCase() },
              }}
              onClick={(e) => e.stopPropagation()}
              style={{ color: Colors.DODGER_BLUE, textDecoration: 'underline', cursor: 'pointer' }}
            >
              {formatMessage(
                { id: `settings.integrations.notification.body.${type}2` },
                { bankAccountNumber, name: nameCamelCase }
              )}
            </Link>
            {!isNewBankAccount &&
              formatMessage(
                { id: `settings.integrations.notification.body.${type}3` },
                { bankAccountNumber, name: nameCamelCase }
              )}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default NotificationsIntegrationItem;
