import React from 'react';
import AddRoomForm from './AddRoomForm';
import Dialog from 'src/components/ui/Dialog';

interface AddRoomDialogProps {
  onClose?: () => void;
  open: boolean;
  unitId: string;
  inside: boolean;
  addGeneralStructure?: boolean;
}
const AddRoomDialog: React.FC<AddRoomDialogProps> = ({
  open,
  onClose = () => {},
  unitId,
  inside,
  addGeneralStructure = true,
}) => {
  return (
    <Dialog open={open} onClose={() => onClose()} scroll="body" PaperProps={{ style: { borderRadius: 10 } }}>
      <AddRoomForm
        onClose={() => onClose()}
        unitId={unitId}
        inside={inside}
        addGeneralStructure={addGeneralStructure}
      />
    </Dialog>
  );
};

export default AddRoomDialog;
