/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable func-names */
import React from 'react';
import { isNil, get } from 'lodash';
import { useIntl } from 'react-intl';
import { providerLocaleMap, useLocale } from 'src/i18n/IntlProviderWrapper';
import { ElevatedPaper, ContentHeader } from '@up2rent/ui';
import { Divider, Typography } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { useUsers } from 'src/hooks/UsersContext';
import * as Yup from 'yup';
import { useUser } from 'src/hooks/UserContext';
import { LooseObject, getLegalDocuments, Language } from '@rentguru/commons-utils';
import { parseInvalidPasswordException, parseInvalidParameterException } from '../Auth/Signup';
import { useSnackbar } from 'notistack';
import EditContact from 'src/components/Contacts/EditContact';
import { useContacts } from 'src/hooks/ContactsContext';
import SecuritySettingsFormFields from './SecuritySettingsFormFields';
import { BlueLink } from 'src/components/ui/BlueLink';

const EditUserSchema = Yup.object().shape({
  newEmail: Yup.string().min(1).required(),
  newPassword1: Yup.string(),
  newPassword2: Yup.string().test('SamePassword', 'The two passwords should be the same', function (value) {
    const { newPassword1 } = this.parent;
    return newPassword1 === value;
  }),
  oldPassword: Yup.string().test(
    'DifferentPassword',
    'The old and new passwords should be different',
    function (value) {
      const { newPassword1 } = this.parent;
      return isNil(value) || newPassword1 !== value;
    }
  ),
});

const SecuritySettings: React.FC = () => {
  const { formatMessage } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  // User should not be able to edit its contact info
  const { editUserPassword, isOwner, updateCognitoEmail } = useUser();
  const { loading, error, getCurrentCognitoUser } = useUsers();
  const { updateContact } = useContacts();
  const { language, updateLanguage } = useLocale();

  const getMessage = (language: Language) => {
    const messages = providerLocaleMap[language.toLocaleLowerCase()];
    return get(messages, 'settings.security.accountModified');
  };

  const user = getCurrentCognitoUser();

  if (loading || isNil(user)) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{formatMessage({ id: 'loading' })}</Typography>
      </ElevatedPaper>
    );
  }
  if (error) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{error}</Typography>
      </ElevatedPaper>
    );
  }

  const handleEdit = async (
    values: LooseObject,
    {
      setErrors,
      setStatus,
      setSubmitting,
    }: {
      setErrors: (params: LooseObject) => void;
      setStatus: (val: boolean) => void;
      setSubmitting: (val: boolean) => void;
    }
  ) => {
    let errorOccurred = false;
    // If there is a new password (and confirmed with Yup))
    if (values.newPassword1 !== '') {
      try {
        await editUserPassword(values.oldPassword, values.newPassword1);
      } catch (err) {
        const error = err as Error;
        console.error('err', error);
        errorOccurred = true;
        if (error.name === 'InvalidPasswordException') {
          setErrors({ newPassword1: parseInvalidPasswordException(error), defaultErrorMessage: error.message });
        } else if (error.name === 'InvalidParameterException') {
          setErrors({ newPassword1: parseInvalidParameterException(error), defaultErrorMessage: error.message });
        } else if (error.name === 'NotAuthorizedException') {
          setErrors({ oldPassword: error.name, defaultErrorMessage: error.message });
        } else {
          setErrors({ newPassword1: error.name, defaultErrorMessage: error.message });
        }
      }
    }
    // If at least one value has changed => Execute update query
    if (!errorOccurred && values.originalValues.email !== values.newEmail) {
      const { success, message } = await updateCognitoEmail(user.id, values.newEmail, values.language);
      if (success) {
        const userContact = user.contact;
        if (userContact) await updateContact(userContact, { email: values.newEmail });
      } else {
        enqueueSnackbar(
          message === 'New mail already exists!' ? formatMessage({ id: 'error.UsernameExistsException' }) : message,
          { variant: 'error' }
        );
        errorOccurred = true;
      }
    }
    if (!errorOccurred && values.language !== language) {
      await updateLanguage(values.language);
    }
    if (!errorOccurred) {
      setStatus(true);
      enqueueSnackbar(getMessage(values.language.toUpperCase() as Language), {
        variant: 'success',
      });
    }
    setStatus(true);
    setSubmitting(false);
  };

  const legalDocuments = getLegalDocuments(language);

  return (
    <>
      {user.contact && (
        <ElevatedPaper style={{ margin: 20, maxWidth: 640, padding: 0 }}>
          <ContentHeader title={formatMessage({ id: 'settings.security.profile' })} />
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />

          <EditContact
            id={user.contact!.id}
            displayCancelButton={false}
            displayTitle={false}
            displayEmail={isOwner}
            displayTabs={false}
            byOwner={true}
            afterSubmit={() => {
              enqueueSnackbar(formatMessage({ id: 'settings.changesSaved' }), { variant: 'success' });
            }}
            showSendInvite={false}
          />
        </ElevatedPaper>
      )}
      <Formik
        initialValues={{
          originalValues: user,
          newEmail: user.email,
          oldPassword: '',
          newPassword1: '',
          newPassword2: '',
          language,
          defaultErrorMessage: '',
        }}
        validationSchema={EditUserSchema}
        onSubmit={handleEdit}
        validateOnChange
      >
        {() => {
          return (
            <Form>
              <SecuritySettingsFormFields />
            </Form>
          );
        }}
      </Formik>
      <ElevatedPaper style={{ margin: 20, maxWidth: 640, padding: 0, paddingBottom: 20 }}>
        <ContentHeader title={formatMessage({ id: 'settings.security.legal' })} />
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <div
          style={{
            marginTop: 20,
            marginLeft: 30,
            marginRight: 30,
            marginBottom: 20,
          }}
        >
          {legalDocuments.map((document) => (
            <BlueLink href={document.url} key={document.labelId} target="_blank">
              <Typography
                style={{
                  textAlign: 'left',
                  fontWeight: 600,
                  textTransform: 'capitalize',
                }}
              >
                {formatMessage({ id: document.labelId })}
              </Typography>
            </BlueLink>
          ))}
        </div>
      </ElevatedPaper>
    </>
  );
};

export default SecuritySettings;
