import { snakeCase } from 'lodash';
import { useRouteMatch } from 'react-router-dom';
import { removeUnitPrefix } from '../Properties/propertyDetailRouteUtils';
import { RouteDestination } from '../Routes/Routes';

const useTechnicsUtils = () => {
  const {
    params: { destination },
  } = useRouteMatch<{ destination: string }>(`/:destination`)!;

  const matchPropertyRoute = useRouteMatch<{ destination: string; propertyId: string; unitId: string }>(
    `${RouteDestination.PROPERTIES}/:propertyId/:unitId?`
  );

  const leaseMatchRoute = useRouteMatch<{ destination: string; leaseId: string; unitId: string }>(
    `${RouteDestination.LEASES}/detail/:leaseId/:unitId?`
  );

  const getSource = () => RouteDestination[snakeCase(destination).toUpperCase() as keyof typeof RouteDestination];

  const getPropertyId = () => {
    return matchPropertyRoute?.params.propertyId;
  };

  const getCurrentDestinationId = () => {
    return leaseMatchRoute?.params.leaseId ?? matchPropertyRoute?.params.propertyId;
  };

  const getUnitId = () => {
    if (`/${destination}` === RouteDestination.PROPERTIES) {
      return removeUnitPrefix(matchPropertyRoute?.params.unitId);
    }
    return removeUnitPrefix(leaseMatchRoute?.params.unitId);
  };

  const canUseUrlAsInfoSource = () => {
    return `/${destination}` === RouteDestination.PROPERTIES || `/${destination}` === RouteDestination.LEASES;
  };

  return { getSource, getPropertyId, getUnitId, canUseUrlAsInfoSource, getCurrentDestinationId };
};

export default useTechnicsUtils;
