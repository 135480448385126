/* eslint-disable @typescript-eslint/no-shadow */
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as Logo } from '../../../../icons/transactionPlaceholder.svg';
import { EnhancedTableScrollable, EnhancedTableSharedContextProvider, TablePlaceHolder } from '@up2rent/ui';
import { FilterEntity, useFilters } from 'src/hooks/FiltersContext';
import {
  getContactNameFromObject,
  getStatementTotalAmount,
  EntityType,
  FileCategory,
  S3Object,
} from '@rentguru/commons-utils';
import { filterTenantStatements } from '../TenantStatementsFilters';
import { useFieldFilterReset } from '../../../ui/FieldFilterSelector';
import { TenantStatementExtended } from '../TenantStatements';
import PreviewFileDialog from 'src/components/ui/PreviewFileDialog';
import { getS3ObjectUrls, useFiles } from 'src/hooks/FilesContext';
import TenantStatementsArchivedRow from './TenantStatementsArchivedRow';
import { useInvoices } from 'src/hooks/InvoicesContext';
import { ReactComponent as NoResultIcon } from 'src/icons/noResult.svg';

const columns = [
  {
    id: 'periodSortLabel',
    labelId: 'financial.period',
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
  {
    id: 'sentDate',
    labelId: 'accounting.statement.archivedSection.sentDate',
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
  {
    id: 'tenantsNames',
    labelId: 'lease.detail.general.tenants',
    numeric: false,
    disablePadding: true,
    sortable: true,
  },
  {
    id: 'unitsNames',
    labelId: 'building.columnHeader.units',
    numeric: false,
    disablePadding: true,
    sortable: true,
  },
  {
    id: 'source',
    labelId: 'tickets.filter.sourceType',
    numeric: false,
    disablePadding: true,
    sortable: true,
  },
  {
    id: 'totalAmount',
    labelId: 'transactions.list.amount',
    numeric: false,
    disablePadding: true,
    sortable: true,
  },
  {
    id: 'more',
    labelId: 'transactions.list.action',
    numeric: false,
    disablePadding: false,
    sortable: false,
    style: { textAlign: 'center' },
  },
];

interface TenantStatementsToArchivedProps {
  tenantStatementsArchived: TenantStatementExtended[];
}

const TenantStatementsToArchived: React.FC<TenantStatementsToArchivedProps> = ({ tenantStatementsArchived }) => {
  const { formatMessage } = useIntl();
  const { getTenantStatementsInvoices, loading: invoicesLoading } = useInvoices();
  const [statementFiles, setStatementFiles] = useState<S3Object[] | null>(null);
  const [fileToPreview, setFileToPreview] = useState<S3Object | null>(null);
  const { tenantStatementsFilters, resetFilters } = useFilters();
  const { executeResetFilters } = useFieldFilterReset();
  const { getFiles, loading: filesLoading } = useFiles();

  const invoices = getTenantStatementsInvoices();

  useEffect(() => {
    let unmounted = false;
    const fetchAllStatementsFiles = async () => {
      const statementInvoices = invoices.filter(
        (invoice) =>
          invoice.statement &&
          tenantStatementsArchived.some((tenantStatement) => tenantStatement.id === invoice.statement!.id)
      );
      const statementsArchivedFiles = getFiles(
        EntityType.STATEMENT,
        tenantStatementsArchived.map((statement) => statement.id)
      );
      const statementsArchivedInvoiceFiles = getFiles(
        EntityType.INVOICE,
        statementInvoices.map((invoice) => invoice.id)
      );
      const allStatementFiles = [...statementsArchivedFiles, ...statementsArchivedInvoiceFiles];
      const statementS3Objects = await getS3ObjectUrls(allStatementFiles);
      if (!unmounted) {
        setStatementFiles(statementS3Objects);
      }
    };

    if (!invoicesLoading) {
      fetchAllStatementsFiles();
    }
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesLoading, filesLoading]);

  const noFiltersSelected =
    isEmpty(tenantStatementsFilters.filters) &&
    isNil(tenantStatementsFilters.statementsPeriod) &&
    isNil(tenantStatementsFilters.statementsSentDate);

  const tenantStatementsToCheckData = tenantStatementsArchived.map((statement) => {
    const tenantsNames = statement.tenants.map((t) => getContactNameFromObject(t)).join(', ');
    const unitsNames = statement.units.map((u) => u.name).join(', ');
    const totalAmount = getStatementTotalAmount(statement);
    const periodSortLabel = `${statement.periodFrom}${statement.periodTo}`;
    const statementDetail = statementFiles?.find(
      (sf) => sf.foreignKey === statement.id && sf.category?.fileCategory === FileCategory.STATEMENT_DETAIL
    );
    const statementInvoicesIds = invoices.reduce((acc: string[], invoice) => {
      if (invoice.statement && invoice.statement.id === statement.id) {
        acc.push(invoice.id);
      }
      return acc;
    }, []);

    const statementReceipt = statementFiles?.find(
      (sf) => sf.foreignTableName === 'Invoice' && statementInvoicesIds.includes(sf.foreignKey)
    );
    return {
      ...statement,
      tenantsNames,
      unitsNames,
      totalAmount,
      periodSortLabel,
      statementDetail,
      statementReceipt,
    };
  });
  const filteredTenantStatementsToCheck = filterTenantStatements(tenantStatementsToCheckData, tenantStatementsFilters);

  return (
    <EnhancedTableSharedContextProvider
      value={{ orderBy: 'periodFrom' }}
      orderFirst="desc"
      datas={filteredTenantStatementsToCheck}
      handleActionMenu={(_e: React.MouseEvent, _id: string, fileToPreview: S3Object) => {
        setFileToPreview(fileToPreview);
      }}
    >
      <EnhancedTableScrollable
        NoResultIcon={<NoResultIcon />}
        formatMessage={formatMessage}
        datas={filteredTenantStatementsToCheck}
        columns={columns}
        defaultOrderBy="periodFrom"
        rowComponent={TenantStatementsArchivedRow}
        placeHolder={
          <TablePlaceHolder
            Icon={<Logo width={72} />}
            mainText={formatMessage(
              { id: 'accounting.statement.archivedSection.noStatements' },
              { statements: formatMessage({ id: 'accounting.statement.tenantStatements' }, { value: 1 }).toLowerCase() }
            )}
            subText=""
            mainTextStyle={{ maxWidth: 450 }}
          />
        }
        filtersSelected={!noFiltersSelected}
        resetFiltersFunction={() => {
          executeResetFilters();
          resetFilters(FilterEntity.TENANT_STATEMENTS);
        }}
      />
      {fileToPreview && (
        <PreviewFileDialog
          open={!isNil(fileToPreview)}
          file={fileToPreview}
          onClose={() => setFileToPreview(null)}
          paperStyle={{ width: 'auto', overflowY: 'unset' }}
        />
      )}
    </EnhancedTableSharedContextProvider>
  );
};

export default TenantStatementsToArchived;
