/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-param-last */
import { useEffect, useRef, useState } from 'react';
import {
  Statement,
  RepaymentStatement,
  syncStatements,
  syncChargeStatements,
  getStatement as getStatementQuery,
  syncPostings as syncPostingsQuery,
  syncRepaymentStatements as syncRepaymentStatementsQuery,
  syncAgencyRateStatements as syncAgencyRateStatementsQuery,
  createStatement as createMutation,
  updateStatement as updateMutation,
  deleteStatement as deleteMutation,
  recalculateStatement as recalculateStatementMutation,
  CreateStatementMutationVariables,
  DeleteStatementMutationVariables,
  CreateStatementInput,
  UpdateStatementInput,
  StatementStatus,
  RecalculateStatementMutationVariables,
  ChargeStatement,
  PostingStatus,
  PostingChargeType,
  Posting,
  AgencyRateStatement,
  cleanInputUpdate,
  cleanInputCreate,
  getReadId,
  getTableClientId,
  LooseObject,
} from '@rentguru/commons-utils';
import { deleteAndHideEntity, get, list, mutation } from '@up2rent/fetch-utils';
import { useUser } from './UserContext';

export interface StatementContext {
  statementsLoading: boolean;
  statements: Statement[];
  tenantStatementsToCheck: Statement[];
  tenantStatementsArchived: Statement[];
  ownerStatementsToCheck: Statement[];
  ownerStatementsArchived: Statement[];
  getStatement: (id: string) => Statement | undefined;
  createStatement: (input: Omit<CreateStatementInput, 'clientId' | 'readId'>) => Promise<Statement>;
  updateStatement: (updates: UpdateStatementInput) => Promise<Statement>;
  deleteStatement: (statement: Statement) => Promise<Statement>;
  recalculateStatement: (id: string) => Promise<boolean>;
}

export const fetchStatements = async (
  by: 'byClientId' | 'byOwnerId' | 'byLeaseId',
  byValue: string,
  additionalFilter?: LooseObject
): Promise<Statement[]> => {
  const indexName = by === 'byClientId' ? 'clientId' : by === 'byOwnerId' ? 'ownerId' : 'leaseId';
  return await list<Statement>(syncStatements, indexName, byValue, additionalFilter);
};

export const fetchChargeStatements = async (
  by: 'byClientId' | 'byStatement' | 'byCharge',
  byValue: string,
  additionalFilter?: LooseObject
): Promise<ChargeStatement[]> => {
  const indexName = by === 'byClientId' ? 'clientId' : by === 'byStatement' ? 'statementId' : 'chargeId';
  return await list<ChargeStatement>(syncChargeStatements, indexName, byValue, additionalFilter);
};

export const fetchRepaymentStatements = async (
  by: 'byClientId' | 'byStatement',
  byValue: string,
  additionalFilter?: LooseObject
): Promise<RepaymentStatement[]> => {
  const indexName = by === 'byClientId' ? 'clientId' : 'statementId';
  return await list<RepaymentStatement>(syncRepaymentStatementsQuery, indexName, byValue, additionalFilter);
};

export const fetchAgencyRateStatements = async (
  by: 'byClientId' | 'byStatement' | 'byAgencyRate',
  byValue: string,
  additionalFilter?: LooseObject
): Promise<AgencyRateStatement[]> => {
  const indexName = by === 'byClientId' ? 'clientId' : by === 'byAgencyRate' ? 'agencyRateId' : 'statementId';
  return await list<AgencyRateStatement>(syncAgencyRateStatementsQuery, indexName, byValue, additionalFilter);
};

export const getAvailableTenantPostingsForLease = async (statementLeaseId: string, periodTo: string) => {
  const additionalFilter = {
    status: { eq: PostingStatus.TO_BE_PAID_BY_TENANT },
    chargeId: { attributeExists: true },
    statementId: { attributeExists: false },
    periodFrom: { le: periodTo },
    chargeType: { eq: PostingChargeType.TENANT },
  };

  return await list<Posting>(syncPostingsQuery, 'leaseId', statementLeaseId, additionalFilter);
};

const classifyStatements = (statements: Statement[]) => {
  return statements.reduce(
    (
      acc: {
        tenantStatementsToCheck: Statement[];
        tenantStatementsArchived: Statement[];
        ownerStatementsToCheck: Statement[];
        ownerStatementsArchived: Statement[];
      },
      statement
    ) => {
      if (statement.lease) {
        // Tenant statement
        if (statement.status === StatementStatus.TO_VALIDATE) {
          acc.tenantStatementsToCheck.push(statement);
        } else {
          acc.tenantStatementsArchived.push(statement);
        }
      } else if (statement.owner) {
        // Owner statmeent
        if (statement.status === StatementStatus.TO_VALIDATE) {
          acc.ownerStatementsToCheck.push(statement);
        } else {
          acc.ownerStatementsArchived.push(statement);
        }
      }
      return acc;
    },
    {
      tenantStatementsToCheck: [],
      tenantStatementsArchived: [],
      ownerStatementsToCheck: [],
      ownerStatementsArchived: [],
    }
  );
};

export const useStatements = (
  shouldFetchStatement: boolean = false,
  by: 'byClientId' | 'byLeaseId' | 'byOwnerId' = 'byClientId',
  byValue?: string
): StatementContext => {
  const [statementsLoading, setStatementsLoading] = useState<boolean>(false);
  const [statements, setStatements] = useState<Statement[]>([]);
  const firstRender = useRef<boolean>(false);
  const { clientId, userId } = useUser();

  const { tenantStatementsToCheck, tenantStatementsArchived, ownerStatementsToCheck, ownerStatementsArchived } =
    classifyStatements(statements);

  useEffect(() => {
    const buildStatements = async () => {
      setStatementsLoading(true);
      firstRender.current = true;
      const result = await fetchStatements(by, byValue || getTableClientId(clientId!, 'Statement'));
      setStatements(result);
      setStatementsLoading(false);
    };
    if (shouldFetchStatement && !firstRender.current) buildStatements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchStatement, firstRender]);

  const getStatement = (id: string): Statement | undefined => {
    return statements.find((statement) => statement.id === id);
  };

  const createStatement = async (input: Omit<CreateStatementInput, 'clientId' | 'readId'>): Promise<Statement> => {
    const statement = await mutation<Statement, CreateStatementMutationVariables>(createMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateStatementInput),
        readId: getReadId(clientId!, 'Statement'),
        clientId: getTableClientId(clientId!, 'Statement'),
      },
    });
    setStatements((m) => [...m, statement]);
    return statement;
  };

  const updateStatement = async (updates: UpdateStatementInput): Promise<Statement> => {
    const result = await mutation<Statement>(updateMutation, {
      input: cleanInputUpdate(updates, false),
    });
    setStatements((statements) =>
      statements.map((statement) => {
        if (statement.id === result.id) return result;
        return statement;
      })
    );
    return result;
  };

  const deleteStatement = async (statement: Statement): Promise<Statement> => {
    const result = await deleteAndHideEntity<Statement, DeleteStatementMutationVariables>(
      statement,
      deleteMutation,
      updateMutation
    );
    setStatements(statements.filter((m) => m.id !== statement.id));
    return result;
  };

  const recalculateStatement = async (id: string) => {
    const result = await mutation<{ status: boolean; id: string }, RecalculateStatementMutationVariables>(
      recalculateStatementMutation,
      {
        input: {
          statementId: id,
          userId: userId!,
          clientId: clientId!,
        },
      }
    );
    if (result.status) {
      const updatedStatement = await get<Statement>(getStatementQuery, id);
      setStatements((statements) =>
        statements.map((statement) => {
          if (statement.id === updatedStatement.id) return updatedStatement;
          return statement;
        })
      );
    }
    return result.status;
  };

  return {
    statementsLoading,
    statements,
    tenantStatementsToCheck,
    tenantStatementsArchived,
    ownerStatementsToCheck,
    ownerStatementsArchived,
    getStatement,
    createStatement,
    updateStatement,
    deleteStatement,
    recalculateStatement,
  };
};
