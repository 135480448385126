import React, { JSXElementConstructor, ReactElement, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import isNil from 'lodash/isNil';
import { Colors } from '@rentguru/commons-utils';
// eslint-disable-next-line import/named
import { TabProps } from '@material-ui/core';

export const useCustomizedTabStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabsIndicator: {
    backgroundColor: Colors.CARNATION_RED,
    height: '2px',
  },
  tabRoot: {
    color: Colors.CLASSICAL_BLACK,
    fontWeight: 700,
    '&:hover': {
      color: Colors.CLASSICAL_BLACK,
      opacity: 1,
      fontWeight: 700,
    },
    '&$tabSelected': {
      color: Colors.SCARLET_ORANGE,
      fontWeight: 'bold',
      fontSize: '12px',
      letterSpacing: '1.2px',
      backgroundColor: Colors.CARNATION_RED,
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
    },
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing(3),
  },
}));

interface CustomizedTabsProps {
  tabStyle?: React.CSSProperties;
  tabsStyle?: React.CSSProperties;
  variant?: 'scrollable' | 'standard' | 'fullWidth';
  value?: number | string;
  handleChange?: ((value: number) => void) | ((value: string) => void);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  completeHandleChange?: (event: any, value: any) => void;
  dynamicDefaultTab?: boolean;
  tabIndicatorProps?: Partial<React.HTMLAttributes<HTMLDivElement>>;
  children?: React.ReactNode;
}

export const CustomizedTabs: React.FC<CustomizedTabsProps> = ({
  variant,
  value: initialValue = 0,
  handleChange,
  completeHandleChange,
  children: childrenProp,
  tabStyle,
  tabsStyle = {},
  dynamicDefaultTab,
  tabIndicatorProps,
}) => {
  const classes = useCustomizedTabStyles();
  const [value, setValue] = useState<number | string>(initialValue);

  useEffect(() => {
    if (dynamicDefaultTab) {
      setValue(initialValue);
    }
  }, [initialValue, dynamicDefaultTab]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const nestedHandleChange = (event: any, newValue: number | string) => {
    setValue(newValue);
    if (!isNil(handleChange)) {
      if (typeof newValue === 'string') {
        (handleChange as (value: string) => void)(newValue as string);
      } else {
        (handleChange as (value: number) => void)(newValue as number);
      }
    } else if (!isNil(completeHandleChange)) {
      completeHandleChange(event, newValue);
    }
  };

  const children = React.Children.map(
    //@ts-ignore: ReactNode can have undefined but we check it in the if
    childrenProp,
    (child: ReactElement<TabProps, string | JSXElementConstructor<unknown>>) => {
      if (!React.isValidElement(child)) {
        return null;
      }

      return React.cloneElement<TabProps>(child, {
        disableRipple: true,
        classes: { root: classes.tabRoot, selected: classes.tabSelected },
        ...(!isNil(tabStyle) && { style: tabStyle }),
      });
    }
  );

  return (
    <div className={classes.root} style={{ ...tabsStyle }}>
      <Tabs
        value={value}
        onChange={nestedHandleChange}
        classes={{ indicator: classes.tabsIndicator }}
        variant={variant}
        TabIndicatorProps={tabIndicatorProps}
      >
        {children}
      </Tabs>
    </div>
  );
};
