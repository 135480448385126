import React from 'react';
import RoleSelectorField, { RoleSelectorFieldFieldProps } from '../FormField/RoleSelectorField';
import { useUserRoles } from '../../../../hooks/UserRolesContext';

type RoleSelectorFormProps = Omit<RoleSelectorFieldFieldProps, 'userRoles'>;

const RoleSelectorForm: React.FC<RoleSelectorFormProps> = ({ ...allProps }) => {
  const { userRoles } = useUserRoles();

  return (
    <RoleSelectorField userRoles={userRoles} required inputStyle={{ width: 280, maxWidth: 'none' }} {...allProps} />
  );
};

export default RoleSelectorForm;
