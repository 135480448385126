/* eslint-disable @typescript-eslint/no-shadow */
import { Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';
import {
  Posting,
  AccountLabel as AccountLabelAPI,
  AccountLabel,
  formatNumber,
  getAmountsBetweenOwnerAndTenant,
  groupPostingsByAccountLabels,
  getAccountLanguageByLanguage,
  Colors,
} from '@rentguru/commons-utils';
import { useLocale } from 'src/i18n/IntlProviderWrapper';

interface AddTenantStatementPostingsProps {
  postings: Posting[];
  accountLabels: AccountLabel[];
}

const AddTenantStatementPostings: React.FC<AddTenantStatementPostingsProps> = ({ postings, accountLabels }) => {
  const { language } = useLocale();
  const chargePostingsGrouped = groupPostingsByAccountLabels(postings, accountLabels as unknown as AccountLabelAPI[]);
  const accountLabelsAndPostings = Object.entries(chargePostingsGrouped).sort((g1, g2) => {
    return g1[1].accountLabel.class! - g2[1].accountLabel.class!;
  });
  return (
    <div style={{ borderRadius: 5, backgroundColor: Colors.PORCELAIN_GREY_3 }}>
      {accountLabelsAndPostings.map((accountLabelAndPostings, index) => {
        const [, postingsAndAccountLabel] = accountLabelAndPostings;
        const { accountLabel, postings } = postingsAndAccountLabel;
        const accountLabelName = getAccountLanguageByLanguage(accountLabel as unknown as AccountLabel, language);
        const { tenantAmount } = getAmountsBetweenOwnerAndTenant(postings);

        return (
          <>
            <Grid container style={{ paddingLeft: 15, paddingRight: 15, alignItems: 'center' }}>
              <Grid item xs={10} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Typography style={{ color: Colors.DARK, fontSize: 12, display: 'inline-block', marginRight: 5 }}>
                  {accountLabel.class}
                </Typography>
                <Typography style={{ color: Colors.DARK, fontSize: 12, fontWeight: 700, display: 'inline-block' }}>
                  {accountLabelName}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                style={{ display: 'flex', justifyContent: 'flex-end', borderLeft: `2px solid ${Colors.GEYSER_GREY}` }}
              >
                <Typography
                  style={{
                    color: Colors.DARK,
                    fontSize: 12,
                    fontWeight: 600,
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                >
                  {formatNumber(tenantAmount, language, { style: 'currency', currency: 'EUR' })}
                </Typography>
              </Grid>
            </Grid>
            {index !== accountLabelsAndPostings.length - 1 && <Divider />}
          </>
        );
      })}
    </div>
  );
};

export default AddTenantStatementPostings;
