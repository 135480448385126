import { Grid, Typography } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import { isNil } from 'lodash';
import { useIntl } from 'react-intl';
import { FormType } from 'src/App';
import { decodeHash } from 'src/components/Settings/Integrations/cryptoUtils';
import Logo from 'src/components/ui/Logo';
import { useUser } from 'src/hooks/UserContext';
import { querystring } from 'src/utils/routeutils';

interface Up2RentLogoProps {
  isContact: boolean;
  isOwner: boolean;
  joinQuery: string | null;
  formType: FormType;
}

const getTranslationIdForJoinPage = (isContact: boolean, isOwner: boolean) => {
  if (isContact) {
    return 'login.joinContactTitle';
  }
  if (isOwner) {
    return 'login.joinOwnerTitle';
  }
  return 'login.joinTitle';
};

const Up2RentLoginLogo: React.FC<Up2RentLogoProps> = ({ isContact, isOwner, joinQuery, formType }) => {
  const { formatMessage } = useIntl();
  const { userAttributes } = useUser();

  const displayJoinText = !userAttributes && formType === FormType.JOIN_SIGN_UP && !isNil(joinQuery);
  if (!displayJoinText) {
    return <Logo withText logoStyle={{ width: 70, height: 70 }} />;
  }

  return (
    <Grid alignContent="center" alignItems="center" container direction="column">
      <Logo logoStyle={{ width: 40, height: 40 }} logoContainerStyle={{ width: 40, height: 40, marginBottom: 15 }} />
      <Typography
        style={{
          fontSize: 24,
          fontWeight: 'bold',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 'normal',
          textAlign: 'center',
          color: Colors.CLASSICAL_BLACK,
          width: 420,
          marginBottom: 30,
        }}
      >
        {formatMessage(
          { id: getTranslationIdForJoinPage(isContact, isOwner) },
          { name: querystring('company', `?${decodeHash(joinQuery)}`, false) }
        )}
      </Typography>
    </Grid>
  );
};

export default Up2RentLoginLogo;
