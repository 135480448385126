/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Button, Grid, Typography } from '@material-ui/core';
import {
  Colors,
  EmptyFileWithUrl,
  S3Object,
  Technic,
  TechnicMaintenanceHistory,
  TechnicType,
  getContactNameFromObject,
  isNilOrEmpty,
} from '@rentguru/commons-utils';
import { format, isAfter } from 'date-fns';
import { useFormikContext } from 'formik';
import isNil from 'lodash/isNil';
import { IconComponent } from 'src/components/ui/FileType';
import PlusButton from 'src/components/ui/PlusButton';
import { useContacts } from 'src/hooks/ContactsContext';
import { getS3ObjectUrls } from 'src/hooks/FilesContext';
import { dateLocaleMap, useLocale } from 'src/i18n/IntlProviderWrapper';
import { v4 as uuidv4 } from 'uuid';

import { MaintenanceDialogBundle, NewTechnic, TechnicFormValues } from './TechnicFields';
import FieldsDeleteIconButton from './utils/FieldsDeleteIconButton';

export interface NewMaintenanceHistory extends TechnicMaintenanceHistory {
  id?: string;
  file?: EmptyFileWithUrl;
}

const getEmptyMainteance = (): NewMaintenanceHistory => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return { id: `NEW-${uuidv4()}`, date: null as any };
};

const addNewControl = (
  technic: NewTechnic,
  setFieldValue: (field: string, value: NewMaintenanceHistory[]) => void,
  technicFieldName: string
) => {
  const maintenanceHistory = technic.maintenanceHistory;
  const emptyMaintenance = getEmptyMainteance();
  if (isNil(maintenanceHistory)) {
    setFieldValue(`${technicFieldName}.maintenanceHistory`, [emptyMaintenance]);
  } else {
    const technicsWithoutNewMaintenance = maintenanceHistory.filter((maintenance) => isNil(maintenance.id));
    setFieldValue(`${technicFieldName}.maintenanceHistory`, [...technicsWithoutNewMaintenance, emptyMaintenance]);
  }
};

// Return the latest maintenance or the new one if the user is adding one
export const getDefaultMaintenance = (technic: Technic): NewMaintenanceHistory | null => {
  const maintenanceHistory = technic.maintenanceHistory;
  if (isNilOrEmpty(maintenanceHistory)) {
    return null;
  }
  return maintenanceHistory.reduce((acc: NewMaintenanceHistory | null, maintenance: NewMaintenanceHistory) => {
    if (isNil(acc)) return maintenance;
    if (!isNil(acc.id)) {
      // Value already found no need to search further
      return acc;
    }
    if (!isNil(maintenance.id)) return maintenance;

    if (isAfter(new Date(maintenance.date), new Date(maintenance.date))) return maintenance;
    return acc;
  }, null);
};

interface MaintenanceFieldsProps {
  technic: Technic;
  name: string;
  type: TechnicType;
  previewMaintenanceFile: (file: S3Object | EmptyFileWithUrl | null) => void;
  openNewControlDialog: (bundle: MaintenanceDialogBundle) => void;
  removeFunction?: (technic: Technic) => void;
  index?: number;
}
const MaintenanceFields: React.FC<MaintenanceFieldsProps> = ({
  technic,
  name,
  type,
  removeFunction,
  previewMaintenanceFile,
  openNewControlDialog,
  index,
}) => {
  const { language } = useLocale();
  const { contractors } = useContacts();
  const { setFieldValue } = useFormikContext<TechnicFormValues>();
  const [S3MaintenanceFile, setS3MaintenanceFile] = useState<S3Object | EmptyFileWithUrl | null>(null);
  const { formatMessage } = useIntl();
  if (isNilOrEmpty(technic.maintenanceHistory)) {
    addNewControl(technic, setFieldValue, name); // By default each technic has an empty control
  }

  const maintenance = getDefaultMaintenance(technic);

  useEffect(() => {
    const getLatestMaintenanceFile = async (latestMaintenance: NewMaintenanceHistory | null) => {
      if (!isNil(latestMaintenance)) {
        const latestMaintenanceFileId = latestMaintenance.fileId; // Exisiting S3 Object file
        const latestMaintenanceFile = latestMaintenance.file; // file that was added
        // Existing file => Let's get the files into the existing ones
        if (!isNil(latestMaintenanceFileId) && !isNil(technic.files)) {
          const latestMaintenanceFile = technic.files.find((file) => file.id === latestMaintenanceFileId);
          if (!isNil(latestMaintenanceFile)) {
            const s3File = await getS3ObjectUrls([latestMaintenanceFile]);
            if (!isNil(s3File)) {
              setS3MaintenanceFile(s3File[0]);
            }
          }
        }
        // File that was manually added
        else if (!isNil(latestMaintenanceFile)) {
          setS3MaintenanceFile(latestMaintenanceFile);
        } else {
          setS3MaintenanceFile(null);
        }
      }
    };
    getLatestMaintenanceFile(maintenance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maintenance]);

  const existingControl = !isNil(maintenance) && !isNil(maintenance.date);
  const onBuilding = !isNil(technic.buildingId);
  const onUnit = !isNil(technic.unitId);
  const onLease = !isNil(technic.leaseId);
  let maintenanceFileType = '';
  let maintenanceFileName = '';
  if (!isNil(S3MaintenanceFile) && 'file' in S3MaintenanceFile) {
    maintenanceFileType = S3MaintenanceFile.file.type;
    maintenanceFileName = S3MaintenanceFile.file.name;
  } else if (!isNil(S3MaintenanceFile) && 'fileName' in S3MaintenanceFile) {
    maintenanceFileType = S3MaintenanceFile.mimeType!;
    maintenanceFileName = S3MaintenanceFile.fileName;
  }
  const contractor = contractors.find((c) => !isNil(maintenance) && c.id === maintenance.contactId);
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: !isNil(removeFunction) ? 'space-between' : 'flex-start',
          alignItems: 'center',
          marginRight: 30,
        }}
      >
        <Typography
          style={{
            color: Colors.LIGHT_BLUE_GREY,
            fontSize: 12,
            marginBottom: 10,
            marginTop: 10,
            marginLeft: 30,
            textAlign: 'left',
          }}
        >
          {`${formatMessage({ id: `enums.TechnicType.${type}` })} ${!isNil(index) ? index + 1 : ''} (${
            onBuilding
              ? formatMessage({ id: 'technic.technicOnBuilding' })
              : onUnit
              ? formatMessage({ id: 'technic.technicOnUnit' })
              : formatMessage({ id: 'technic.technicOnLease' })
          })`}
        </Typography>
        {!isNil(removeFunction) && (technic.id.startsWith('NEW-') || onLease) && (
          <FieldsDeleteIconButton style={{ color: Colors.BLUE_GREY }} onClick={() => removeFunction(technic)} />
        )}
      </div>
      {!isNil(maintenance) && (
        <div
          style={{
            height: 60,
            marginLeft: 30,
            marginRight: 30,
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 6,
            display: 'flex',
            border: existingControl ? `solid 1px ${Colors.SEPARATOR_GREY}` : `dashed 1px ${Colors.BLUEY}`,
          }}
        >
          {!existingControl && (
            <PlusButton
              onClick={() => {
                const afterSubmit = (newMaintenance: NewMaintenanceHistory) => {
                  const cleanedMaintenanceHistory = technic.maintenanceHistory!.filter((m) =>
                    isNil((m as unknown as NewMaintenanceHistory).id)
                  );
                  setFieldValue(`${name}.maintenanceHistory`, [...cleanedMaintenanceHistory, newMaintenance]);
                };
                openNewControlDialog({ afterSubmit });
              }}
              text={formatMessage({ id: 'technic.addControl' })}
            />
          )}
          {existingControl && (
            <Grid container style={{ alignItems: 'center' }}>
              <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Button
                  href={!isNil(S3MaintenanceFile) && isNil(previewMaintenanceFile) ? S3MaintenanceFile.url : undefined}
                  onClick={!isNil(previewMaintenanceFile) ? () => previewMaintenanceFile(S3MaintenanceFile) : undefined}
                  disabled={isNil(S3MaintenanceFile)}
                  style={{ padding: 0 }}
                >
                  {maintenanceFileType.includes('image/') ? (
                    <img
                      src={!isNil(S3MaintenanceFile) ? S3MaintenanceFile.url : '#'}
                      style={{ display: 'block', width: 20, height: 26, borderRadius: 6 }}
                      alt={maintenanceFileName}
                    />
                  ) : (
                    <IconComponent dataType={maintenanceFileType} iconWidth={20} iconHeight={26} />
                  )}
                  <Typography
                    variant="h6"
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      marginLeft: 20,
                      color: Colors.CLASSICAL_BLACK,
                      textTransform: 'none',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      maxWidth: '200px',
                      overflow: 'hidden',
                    }}
                  >
                    {isNil(S3MaintenanceFile)
                      ? formatMessage({ id: 'lease.addLease.noFile' })
                      : 'file' in S3MaintenanceFile
                      ? S3MaintenanceFile.file.name
                      : S3MaintenanceFile.fileName}
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  style={{ fontSize: 14, color: Colors.SLATE_GREY, display: 'flex', justifyContent: 'flex-start' }}
                >
                  {!isNil(contractor) ? getContactNameFromObject(contractor) : ''}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  style={{ fontSize: 14, color: Colors.SLATE_GREY, display: 'flex', justifyContent: 'flex-start' }}
                >
                  {!isNil(maintenance.date)
                    ? format(new Date(maintenance.date), 'd/MM/yyyy', {
                        locale: dateLocaleMap[language],
                      })
                    : ''}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <FieldsDeleteIconButton
                  style={{ color: Colors.BLUE_GREY }}
                  onClick={() => addNewControl(technic, setFieldValue, name)}
                  size="small"
                />
              </Grid>
            </Grid>
          )}
        </div>
      )}
    </>
  );
};

export default MaintenanceFields;
