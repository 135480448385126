import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import isNil from 'lodash/isNil';
import { Grid, makeStyles, MenuList, Typography } from '@material-ui/core';
import Dialog from 'src/components/ui/Dialog';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Colors } from '@rentguru/commons-utils';
import { ReactComponent as ContactSvg } from 'src/icons/contact.svg';
import { useTeams } from 'src/hooks/TeamsContext';
import MenuNotFound from 'src/components/Routes/MenuNotFound';
import { RouteDestination } from 'src/components/Routes/Routes';
import CustomizedMenuItem from 'src/components/ui/CustomizedMenuItem';
import ContentDetailHeader from 'src/components/ui/ContentDetailHeader';
import { ActionButtonSecond, actionButtonSecondStyles , CustomIconButton } from '@up2rent/ui';
import DeleteTeam from '../DeleteTeam';
import TeamMembers from './TeamMembers';
import TeamBuildings from './TeamBuildings';

const TO_PREFIX = '';

const useStyles = makeStyles({
  sectionTabTitle: {
    color: Colors.LIGHT_BLUE_GREY,
    fontSize: 10,
    letterSpacing: '1px',
    lineHeight: '12px',
    textTransform: 'uppercase',
    marginLeft: 15,
    marginRight: 15,
  },
});

interface GeneralDetailsProps {
  teamId: string;
  onClose?: () => void;
}

const GeneralDetails: React.FC<GeneralDetailsProps> = ({ teamId, onClose }) => {
  const navItems = [
    {
      to: `${TO_PREFIX}buildings`,
      labelId: 'menu.buildings',
      exact: true,
      component: TeamBuildings,
      editable: true,
    },
    {
      to: `${TO_PREFIX}members`,
      labelId: 'settings.members',
      exact: true,
      component: TeamMembers,
      editable: true,
    },
  ];
  const { formatMessage } = useIntl();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const classes = useStyles();
  const actionButtonClasses = actionButtonSecondStyles();
  const { teams, getTeam } = useTeams();
  const match = useRouteMatch<{ teamId: string; detailName: string }>({
    path: `${RouteDestination.SETTINGS}/teams/:teamId?/:detailName?`,
  });
  const team = getTeam(teamId);
  if (isNil(match) || isNil(team)) {
    return null;
  }
  const handleCloseDialogs = () => {
    setDeleteDialogOpen(false);
  };
  const isLeaf = isNil(teams.find((t) => t.parentId === teamId));

  return (
    <Grid container style={{ flexGrow: 1, overflow: 'auto', height: '100%' }}>
      <Grid
        item
        style={{
          height: '100%',
          width: 240,
          overflow: 'visible',
          marginLeft: 15,
          marginRight: 15,
        }}
      >
        <CustomIconButton
          style={{ marginTop: 30, marginBottom: 70 }}
          aria-label="Menu"
          onClick={onClose}
          Icon={ArrowBack}
          iconStyle={{ fill: Colors.BLUE_GREY }}
        />
        <Typography variant="subtitle1" className={classes.sectionTabTitle}>
          {formatMessage({
            id: 'rentalUnit.detail.menu.detailTitle',
          })}
        </Typography>
        <MenuList>
          {navItems.map((m) => {
            const to = !isNil(match.params.detailName)
              ? match.url.replace(match.params.detailName, m.to)
              : match.url + (match.url.charAt(match.url.length - 1) === '/' ? '' : '/') + m.to;
            const selected =
              (!isNil(match.params.detailName) && match.params.detailName === m.to) ||
              (m.to === TO_PREFIX && isNil(match.params.detailName));

            return (
              <CustomizedMenuItem key={m.to} to={to} selected={selected}>
                {formatMessage({ id: m.labelId })}
              </CustomizedMenuItem>
            );
          })}
        </MenuList>
      </Grid>
      <Grid
        style={{
          flexGrow: 1,
          width: 'calc(100% - 280px)',
        }}
      >
        <ContentDetailHeader
          EntityIcon={ContactSvg}
          title={team.name}
          actions={
            isLeaf
              ? [
                  <ActionButtonSecond
                    className={actionButtonClasses.buttonWithMargin}
                    key="delete"
                    onClick={() => setDeleteDialogOpen(true)}
                  >
                    {formatMessage({ id: 'delete' })}
                  </ActionButtonSecond>,
                ]
              : []
          }
        />
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: '10px',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 24,
            paddingBottom: 24,
            minHeight: 'calc(100% - 168px)',
          }}
        >
          <Switch>
            {navItems.map((m) => {
              const path = match.path.replace(':detailName?', m.to);
              const Comp = m.component;
              return (
                <Route
                  path={path}
                  key={m.to}
                  exact={m.exact}
                  render={() => {
                    return <Comp team={team} />;
                  }}
                />
              );
            })}

            <Route
              path="*"
              render={() => {
                return <MenuNotFound />;
              }}
            />
          </Switch>
        </div>
      </Grid>
      <Dialog
        open={deleteDialogOpen}
        onClose={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();
          handleCloseDialogs();
        }}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DeleteTeam afterSubmit={handleCloseDialogs} id={team.id} />
      </Dialog>
    </Grid>
  );
};

export default GeneralDetails;
