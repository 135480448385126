/* eslint-disable @typescript-eslint/no-shadow */
import {
  EmptyFile,
  EmptyFileWithUrl,
  LeaseInventoryFurnitureExitIssue,
  LeaseInventoryFurnitureExitIssueAction,
  LeaseInventoryFurnitureExitIssueType,
  NewFile,
  S3Object,
  getEmptyFile,
} from '@rentguru/commons-utils';
import { Form, Formik, FormikHelpers } from 'formik';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import Dialog from 'src/components/ui/Dialog';
import { fetchEncodingMediaFiles } from 'src/utils/leaseInventoryUtils';
import * as Yup from 'yup';
import { ExtendedLeaseInventoryEncoding, ExtendedUnitInventoryStructure } from '../../../hooks/UnitInventoryContext';
import IssueDialogContent from './IssueDialogContent';

export interface AddFurnitureIssueFormValues {
  issues: LeaseInventoryFurnitureExitIssue[];
  files: (S3Object | EmptyFileWithUrl | NewFile)[];
  note: string;
}

export const AddFurnitureIssueSchema = Yup.object()
  .shape({
    issues: Yup.array()
      .min(1)
      .of(
        Yup.object().shape({
          quantity: Yup.number().min(0).required(),
          issue: Yup.string().min(1).required(),
          action: Yup.string().min(1).required(),
          penalty: Yup.number().min(0).nullable(),
        })
      )
      .required(),
    files: Yup.array().nullable(),
    note: Yup.string().min(1).notRequired(),
  })
  .required();

export const defaultIssue = {
  quantity: 1,
  issue: LeaseInventoryFurnitureExitIssueType.DAMAGED,
  action: LeaseInventoryFurnitureExitIssueAction.NOTHING_TO_DO,
};

interface IssueDialogProps {
  afterSubmit: (encoding: ExtendedLeaseInventoryEncoding) => void;
  onClose: () => void;
  open: boolean;
  encoding: ExtendedLeaseInventoryEncoding;
  structure: ExtendedUnitInventoryStructure;
}

const IssueDialog: React.FC<IssueDialogProps> = ({ open, encoding, structure, afterSubmit, onClose }) => {
  const [pictureFiles, setPictureFiles] = useState<(S3Object | EmptyFileWithUrl)[]>([]);
  const [fetchedFiles, setFetchedFiles] = useState<boolean>(false);

  const handleCreate = async (
    values: AddFurnitureIssueFormValues,
    { setSubmitting, setStatus }: FormikHelpers<AddFurnitureIssueFormValues>
  ) => {
    const { issues, files, note } = values;
    const mediaFiles: (S3Object | EmptyFile)[] = [];
    for (const file of files) {
      if ('file' in file) mediaFiles.push(getEmptyFile(file.file));
      else mediaFiles.push(file);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newEncoding = { ...encoding, furnitureExitIssues: issues, medias: mediaFiles as any, note };
    afterSubmit(newEncoding);
    setStatus(true);
    setSubmitting(false);
    onClose();
  };

  useEffect(() => {
    let unmounted = false;
    if (!isNil(encoding) && !isNil(encoding.medias)) {
      const afterSubmit = (pictureFiles: (S3Object | EmptyFileWithUrl)[]) => {
        setPictureFiles(pictureFiles);
        setFetchedFiles(true);
      };
      fetchEncodingMediaFiles(encoding.medias, unmounted, undefined, afterSubmit);
    }
    return () => {
      unmounted = true;
    };
  }, [encoding, setFetchedFiles]);

  if (!fetchedFiles) {
    return null;
  }
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      scroll="body"
      PaperProps={{ style: { borderRadius: 10, maxWidth: 800 } }}
    >
      <Formik
        initialValues={{
          issues:
            !isNil(encoding.furnitureExitIssues) && !isEmpty(encoding.furnitureExitIssues)
              ? encoding.furnitureExitIssues
              : [defaultIssue],
          files: pictureFiles,
          note: !isNil(encoding.note) ? encoding.note : '',
        }}
        validationSchema={AddFurnitureIssueSchema}
        onSubmit={handleCreate}
      >
        {() => {
          return (
            <Form>
              <IssueDialogContent encoding={encoding} structure={structure} onClose={onClose} />
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default IssueDialog;
