import React, { useEffect } from 'react';
import DashboardTabHeader from './DashboardHeader';
import DashboardMetrics from './DashboardMetrics/DashboardMetrics';
import DashboardTodos from './DashboardTodos/DashboardTodos';
import DashboardRanking from './DashboardRanking/DashboardRanking';
import { FieldFilterResetProvider } from 'src/components/ui/FieldFilterSelector';
import { CenteredGrid, ElevatedPaper } from '@up2rent/ui';
import { isNil } from 'lodash';
import { Route, useRouteMatch, Switch, useHistory } from 'react-router-dom';
import DashboardFilters from './DashboardMetrics/DashboardFilters/DashboardFilters';
import { DashboardFiltersContextProvider } from 'src/hooks/DashboardFiltersContext';
import { RouteDestination } from '../Routes/Routes';
import { getCacheItem } from 'src/utils/cacheUtils';

const TO_PREFIX = '';

export const DashboardNavItems = [
  {
    to: `${TO_PREFIX}`,
    labelId: 'dashboard.topTabs.metrics',
    exact: true,
    component: DashboardMetrics,
  },
  {
    to: `${TO_PREFIX}ranking`,
    labelId: 'dashboard.topTabs.ranking',
    exact: true,
    component: DashboardRanking,
  },
  {
    to: `${TO_PREFIX}todos`,
    labelId: 'dashboard.topTabs.todos',
    exact: true,
    component: DashboardTodos,
  },
];

export const CACHE_DASHBOARD_TAB = 'dashboardTab';

export const stopPropagation = (e: React.MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();
};

const Dashboard: React.FC = () => {
  const history = useHistory();
  const localMatch = useRouteMatch<{ tabName: string }>({
    path: [`${RouteDestination.DASHBOARD}/:tabName?`, `${RouteDestination.DASHBOARD.substring(1)}/:tabName?`],
  })!;
  const globalMatch = useRouteMatch();
  const match = !isNil(localMatch) ? localMatch : globalMatch;

  useEffect(() => {
    const getLastViewedTab = async () => {
      const cachedItem = await getCacheItem(CACHE_DASHBOARD_TAB);
      if (cachedItem) {
        history.replace(cachedItem);
      }
    };
    getLastViewedTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardFiltersContextProvider>
      <FieldFilterResetProvider>
        <Switch>
          <CenteredGrid>
            <ElevatedPaper style={{ paddingBottom: 0, width: '98%', marginRight: '1%', marginLeft: '1%' }}>
              <DashboardTabHeader />
              {(match.url === '/' || match.url === '/dashboard/' || match.url === '/dashboard') && <DashboardFilters />}
            </ElevatedPaper>

            <Route path="/" key="/" exact={true} render={() => <DashboardMetrics />} />

            {DashboardNavItems.map((m) => {
              const path = `/dashboard/${m.to}`;
              const Comp = m.component;

              return <Route path={path} key={m.to} exact={m.exact} render={() => <Comp />} />;
            })}
          </CenteredGrid>
        </Switch>
      </FieldFilterResetProvider>
    </DashboardFiltersContextProvider>
  );
};

export default Dashboard;
