/* eslint-disable react/no-array-index-key */

import React from 'react';
import { useIntl } from 'react-intl';
import { isNil, isEmpty } from 'lodash';
import { FormikErrors, FormikTouched, useFormikContext } from 'formik';
import { Divider, Grid, Typography } from '@material-ui/core';
import { Colors, formatNumber } from '@rentguru/commons-utils';
import {
  deleteNamedLeaseInventoryFurnitureExitIssue,
  UnitInventoryFlattenOnIssues,
} from 'src/utils/leaseInventoryUtils';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { getSafeValueInObject } from 'src/utils/object';
import { ReactComponent as Delete } from 'src/icons/delete.svg';
import { FurnitureInventoryFormik } from 'src/components/FurnitureInventory/FurnitureInventoryDetails';
import TextDetailEditable from 'src/components/ui/TextDetailEditable';
import { TablePlaceHolder , CustomIconButton } from '@up2rent/ui';

interface FurnitureInventorySummaryRowProps {
  flattenedOnIssuesUnitInventory: UnitInventoryFlattenOnIssues;
  language: string;
  values: FurnitureInventoryFormik;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue: (name: string, value: any) => void;
  errors: FormikErrors<FurnitureInventoryFormik>;
  touched: FormikTouched<FurnitureInventoryFormik>;
  readOnly: boolean;
  divider: boolean;
}

const FurnitureInventorySummaryRow: React.FC<FurnitureInventorySummaryRowProps> = ({
  flattenedOnIssuesUnitInventory,
  language,
  values,
  setFieldValue,
  errors,
  touched,
  readOnly,
  divider,
}) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Typography style={{ fontSize: 12, color: Colors.LIGHT_BLUE_GREY, letterSpacing: 1 }}>
          {flattenedOnIssuesUnitInventory.unitInventoryName.toUpperCase()}
        </Typography>
        {flattenedOnIssuesUnitInventory.structures.map((flattenedStruct, index) => {
          return (
            <div key={`${flattenedStruct.structureName} ${index}`}>
              {flattenedStruct.issues.map((issue) => {
                const name = issue.name;
                return (
                  <Grid container key={issue.name} style={{ marginTop: 10, alignItems: 'center' }}>
                    <Grid item xs={3}>
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          color: Colors.CLASSICAL_BLACK,
                        }}
                      >
                        {`${flattenedStruct.structureName} ${
                          flattenedStruct.furnitureQuantity > 1 ? `(${flattenedStruct.furnitureQuantity})` : ''
                        }`}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography style={{ fontSize: 14, color: Colors.SLATE_GREY, display: 'inline-flex' }}>
                        {`${issue.quantity > 1 ? `${issue.quantity} x ` : ''}${formatMessage({
                          id: `enums.LeaseInventoryFurnitureExitIssueType.${issue.issue}`,
                        })}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography style={{ fontSize: 14, color: Colors.SLATE_GREY, display: 'inline-flex' }}>
                        {`${formatMessage({
                          id: `enums.LeaseInventoryFurnitureExitIssueAction.${issue.action}`,
                        })}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
                      {readOnly ? (
                        <Typography style={{ fontSize: 14, color: Colors.SLATE_GREY, display: 'inline-flex' }}>
                          {formatNumber(!isNil(issue.penalty) ? issue.penalty : 0, language, {
                            currency: 'EUR',
                            style: 'currency',
                          })}
                        </Typography>
                      ) : (
                        <TextDetailEditable
                          editMode={true}
                          title={formatMessage({ id: 'furnitureInventory.penalty' })}
                          name={`${name}.penalty`}
                          type="number"
                          min={0}
                          style={{ width: 125 }}
                          endAdornment="€"
                          error={Boolean(
                            getSafeValueInObject(errors, `${name}.penalty`) &&
                              getSafeValueInObject(touched, `${name}.penalty`)
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={1}>
                      {!readOnly && (
                        <CustomIconButton
                          style={{ color: Colors.LIGHT_BLUE_GREY }}
                          onClick={() => {
                            deleteNamedLeaseInventoryFurnitureExitIssue(name, values, setFieldValue);
                          }}
                          size="small"
                          Icon={Delete}
                          iconStyle={{ fill: Colors.BLUE_GREY }}
                        />
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            </div>
          );
        })}
      </div>
      {divider && <Divider style={{ marginBottom: 20 }} />}
    </>
  );
};

interface FurnitureInventorySummaryProps {
  flattenedOnIssuesUnitInventories: UnitInventoryFlattenOnIssues[];
  totalPenalty?: number;
  numberOfIssues?: number;
  readOnly?: boolean;
}

const FurnitureInventorySummary: React.FC<FurnitureInventorySummaryProps> = ({
  flattenedOnIssuesUnitInventories,
  totalPenalty,
  numberOfIssues,
  readOnly = true,
}) => {
  const { values, setFieldValue, errors, touched } = useFormikContext<FurnitureInventoryFormik>();
  const { formatMessage } = useIntl();
  const { language } = useLocale();

  if (isEmpty(flattenedOnIssuesUnitInventories)) {
    return (
      <TablePlaceHolder
        mainText={formatMessage({ id: 'furnitureInventory.noIssuesPlaceHolderMainText' })}
        subText={formatMessage({ id: 'furnitureInventory.noIssuesPlaceHolderSubText' })}
      />
    );
  }
  return (
    <div style={{ padding: 30 }}>
      {readOnly && (
        <Typography
          style={{
            color: Colors.CLASSICAL_BLACK,
            fontFamily: 'Mulish',
            fontSize: '16px',
            fontWeight: 700,
            marginBottom: 20,
          }}
        >
          {`${numberOfIssues} ${formatMessage(
            { id: 'furnitureInventory.issue' },
            { value: numberOfIssues }
          )} · ${formatNumber(totalPenalty!, language, {
            style: 'currency',
            currency: 'EUR',
          })}`}
        </Typography>
      )}
      {flattenedOnIssuesUnitInventories.map((flattenedOnIssuesUnitInventory, index) => {
        return (
          <FurnitureInventorySummaryRow
            key={index}
            flattenedOnIssuesUnitInventory={flattenedOnIssuesUnitInventory}
            language={language}
            errors={errors}
            touched={touched}
            readOnly={readOnly}
            divider={index !== flattenedOnIssuesUnitInventories.length - 1}
            values={values}
            setFieldValue={setFieldValue}
          />
        );
      })}
    </div>
  );
};

export default FurnitureInventorySummary;
