import { Grid, Typography } from '@material-ui/core';
import {
  PERMISSIONS_SECTIONS,
  FEATURE_INVENTORY,
  isFeatureEnabled,
  Colors,
  UserRight,
  UserRole,
} from '@rentguru/commons-utils';
import { FormikHelpers, Formik, Form } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import { useIntl } from 'react-intl';
import RolePermissionsSection from './RolePermissionsSection';
import { LoaderButton , ActionButton } from '@up2rent/ui';
import { isNil } from 'lodash';
import { useUserRoles } from 'src/hooks/UserRolesContext';
import { useUsers } from 'src/hooks/UsersContext';
import { useUser } from 'src/hooks/UserContext';
import { useSnackbar } from 'notistack';

export interface UpdateRoleUserRightFormValues {
  changed: boolean;
  userRights: UserRight[];
}

const UpdateRoleUserRightSchema = Yup.object()
  .shape({
    userRights: Yup.array()
      .of(
        Yup.object().shape({
          section: Yup.string().required(),
          access: Yup.array().of(Yup.string()),
        })
      )
      .required(),
  })
  .required();

const isSectionDisplayableWithFeatures = (sectionKey: string, features: string[]) => {
  if (sectionKey.includes('INVENTORY') && !isFeatureEnabled(features, FEATURE_INVENTORY)) {
    return false;
  }
  return true;
};

interface RolePermissionsProps {
  userRole: UserRole;
}

const RolePermissions: React.FC<RolePermissionsProps> = ({ userRole }) => {
  const { formatMessage } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { updateUserRole, getUsersWithRole } = useUserRoles();
  const { updateUser } = useUsers();
  const { isAgency, features } = useUser();

  const handleCreate = async (
    values: UpdateRoleUserRightFormValues,
    { setSubmitting, setStatus, setFieldValue }: FormikHelpers<UpdateRoleUserRightFormValues>
  ) => {
    await updateUserRole(userRole, { name: userRole.name, userRights: values.userRights }, true);

    const userWithGivenRole = getUsersWithRole(userRole.id);
    const updateUsersPromises = userWithGivenRole.map(async (userWithRole) => {
      await updateUser(userWithRole, { refreshToken: true });
    });
    await Promise.all(updateUsersPromises);
    setStatus(true);
    setSubmitting(false);
    setFieldValue('changed', false);
    setStatus(false);
    enqueueSnackbar(formatMessage({ id: 'settings.changesSaved' }), { variant: 'success' });
  };

  if (isNil(userRole.userRights)) {
    return null;
  }

  const initialValues: UpdateRoleUserRightFormValues = {
    changed: false,
    userRights: userRole.userRights,
  };

  const permissionSections = isAgency
    ? PERMISSIONS_SECTIONS.filter((permissionSection) =>
        isSectionDisplayableWithFeatures(permissionSection.keyStart, features)
      )
    : PERMISSIONS_SECTIONS.filter(
        (permissionSection) =>
          permissionSection.keyStart !== 'AGENCY_' &&
          isSectionDisplayableWithFeatures(permissionSection.keyStart, features)
      );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UpdateRoleUserRightSchema}
      onSubmit={handleCreate}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ status, isSubmitting, resetForm }) => {
        return (
          <Form>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30 }}>
              <Typography variant="h6">{formatMessage({ id: 'settings.roles.permissions' })}</Typography>
            </div>
            <Grid container style={{ marginTop: 15, marginBottom: 20, paddingRight: 30, alignItems: 'center' }}>
              <Grid item xs={9}>
                <Typography
                  style={{
                    fontWeight: 'normal',
                    fontSize: 12,
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    color: Colors.BLUE_GREY,
                  }}
                >
                  {formatMessage({ id: 'settings.roles.section' })}
                </Typography>
              </Grid>

              <Grid item xs={1}>
                <Typography
                  style={{
                    fontWeight: 'normal',
                    fontSize: 12,
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    color: Colors.BLUE_GREY,
                  }}
                >
                  {formatMessage({ id: 'settings.roles.read' })}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography
                  style={{
                    fontWeight: 'normal',
                    fontSize: 12,
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    color: Colors.BLUE_GREY,
                  }}
                >
                  {formatMessage({ id: 'settings.roles.write' })}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography
                  style={{
                    fontWeight: 'normal',
                    fontSize: 12,

                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    color: Colors.BLUE_GREY,
                  }}
                >
                  {formatMessage({ id: 'settings.roles.delete' })}
                </Typography>
              </Grid>
            </Grid>
            {permissionSections.map((section, index) => (
              <RolePermissionsSection
                title={formatMessage({ id: section.labelId })}
                keyStart={section.keyStart}
                key={section.labelId}
                divider={index !== permissionSections.length - 1}
              />
            ))}
            <Grid
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                backgroundColor: 'white',
                position: 'sticky',
                bottom: 0,
                height: 70,
                paddingRight: 30,
                zIndex: 99,
              }}
            >
              <ActionButton
                onClick={() => resetForm()}
                style={{
                  background: 'none',
                  color: Colors.DARK_SLATE_GREY,
                  marginRight: 20,
                }}
              >
                {formatMessage({ id: 'cancel' })}
              </ActionButton>
              <LoaderButton id="editRolePermissions" loading={isSubmitting} success={status} style={{ height: 30 }}>
                {formatMessage({ id: 'save' })}
              </LoaderButton>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RolePermissions;
