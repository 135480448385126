import { Avatar, Chip, Grid, Typography } from '@material-ui/core';
import {
  Colors,
  Contact,
  contactMostImportantType,
  getContactNameFromObject,
  getFirstLettersUppercase,
  stringToColor,
} from '@rentguru/commons-utils';
import { isEmpty, sortBy } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { useContacts } from 'src/hooks/ContactsContext';
import FullCustomComboBox, { CustomInputItemType } from './ComboBox/FullCustomComboBox';
import { useCustomizedComboStyles } from './ComboBox/TextComboBox';
import { Skeleton } from '@material-ui/lab';

type ContactComboBoxItem = {
  value: Contact;
  name: string;
  type: string;
  color: string;
};
interface MultipleContactSelectorProps {
  contacts: Contact[];
  selectedContactIds: string[];
  setSelectedContactIds: (values: string[]) => void;
  disabled?: boolean;
  inputWidth?: number;
  allContactsChip?: boolean;
  noContactChip?: boolean;
  labelFormatMessageId?: string;
}

const MultipleContactSelector: React.FC<MultipleContactSelectorProps> = ({
  contacts,
  selectedContactIds,
  setSelectedContactIds,
  disabled = false,
  inputWidth = 552,
  allContactsChip = true,
  noContactChip = false,
  labelFormatMessageId = 'communications.addCommunication.contacts.title',
}) => {
  const { formatMessage } = useIntl();
  const comboBoxClasses = useCustomizedComboStyles();
  const { getContact, contactsLoading } = useContacts();

  if (contactsLoading) {
    return <Skeleton />;
  }

  const inputStartAdornment = (value: CustomInputItemType | CustomInputItemType[]) => {
    if (isEmpty(value)) {
      return allContactsChip || noContactChip ? (
        <Chip
          key="AllContactsChip"
          variant="outlined"
          label={formatMessage({ id: noContactChip ? 'none' : 'communications.addCommunication.contacts.allContacts' })}
          style={{ marginLeft: 15, marginBottom: 10, marginTop: 10 }}
        />
      ) : undefined;
    }
  };

  const contactToComboItem = (contact: Contact) => {
    const name = getContactNameFromObject(contact);
    return {
      name,
      color: stringToColor(name),
      type: contactMostImportantType(contact),
      value: contact,
    } as ContactComboBoxItem;
  };

  const contactIdToComboItem = (id: string) => {
    const contact = getContact(id)!;
    return contactToComboItem(contact);
  };

  const selectedContactsComboBoxItems = selectedContactIds.map((selectedContactId) =>
    contactIdToComboItem(selectedContactId)
  );

  return (
    <Grid
      style={{
        alignItems: 'baseline',
        display: 'flex',
      }}
    >
      <FullCustomComboBox
        value={selectedContactsComboBoxItems}
        label={formatMessage({ id: labelFormatMessageId })}
        options={sortBy(
          contacts.map((contact) => contactToComboItem(contact)),
          'name'
        )}
        onChange={(_event, values) => {
          const contactIds = values?.map((value) => value.value.id) ?? [];
          setSelectedContactIds(contactIds);
        }}
        inputStyle={{ width: inputWidth }}
        inputStartAdornment={inputStartAdornment}
        renderOption={(contactComboBoxItem: CustomInputItemType) => (
          <Grid container alignContent="space-between" style={{ marginBottom: 5, marginTop: 5 }}>
            <Grid item xs={10} style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                style={{
                  color: `${contactComboBoxItem.color}`,
                  backgroundColor: Colors.CLASSICAL_WHITE,
                  border: `1.5px ${contactComboBoxItem.color} solid`,
                  fontSize: 10,
                  fontWeight: 'bold',
                  width: 20,
                  height: 20,
                  marginRight: 10,
                }}
              >
                {getFirstLettersUppercase(contactComboBoxItem.name)}
              </Avatar>
              {contactComboBoxItem.name}
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'right' }}>
              <Typography className={comboBoxClasses.secondaryText}>
                {formatMessage({ id: `contact.type.${contactComboBoxItem.type.toLowerCase()}` })}
              </Typography>
            </Grid>
          </Grid>
        )}
        // eslint-disable-next-line react/no-unstable-nested-components
        renderInput={(value: CustomInputItemType) => (
          <>
            <Avatar
              style={{
                color: `${value.color}`,
                backgroundColor: 'rgba(0,0,0,0)',
                border: `1.5px ${value.color} solid`,
                fontSize: '8px',
                fontWeight: 'bold',
                width: 15,
                height: 15,
                marginRight: 10,
              }}
            >
              {getFirstLettersUppercase(value.name)}
            </Avatar>
            {value.name}
          </>
        )}
        renderTags={(value: CustomInputItemType[], getTagProps) =>
          value.map((option: CustomInputItemType, index: number) => (
            <Chip
              key={option.value.id}
              variant="outlined"
              label={option.name}
              {...getTagProps({ index })}
              style={{ marginLeft: 15, marginBottom: 10, marginTop: 10 }}
            />
          ))
        }
        noOptionsText={formatMessage({ id: 'comboBox.noContacts' })}
        stringToCompare={(value: CustomInputItemType) => value.name}
        disableClearable={false}
        clearOnEscape
        disableCloseOnSelect
        disabled={disabled}
      />
    </Grid>
  );
};

export default MultipleContactSelector;
