import React from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import { LoaderButton , ActionButton } from '@up2rent/ui';
import { Formik, Form, FormikHelpers } from 'formik';
import { Divider, Typography } from '@material-ui/core';
import { useBuildings } from '../../../hooks/BuildingsContext';
import { useUnits } from '../../../hooks/UnitsContext';
import RenovationCostsFields, {
  getDynamicRenovationCostsSchema,
  RenovationCostsFormValues,
  StaticRenovationCostsSchema,
} from '../../ui/Forms/FormField/RenovationCostsFields';
import toUpper from 'lodash/toUpper';
import { BuildingEvent, Unit, UnitEvent, Colors, shouldUpdate } from '@rentguru/commons-utils';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { Skeleton } from '@material-ui/lab';

interface EditRenovationFormProps {
  event: BuildingEvent | UnitEvent;
  afterSubmit?: (event: BuildingEvent | UnitEvent) => void;
  cancelSubmit?: () => void;
}

const EditRenovationForm: React.FC<EditRenovationFormProps> = ({ event, afterSubmit, cancelSubmit }) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const { updateBuildingEvent, buildingsLoading } = useBuildings();
  const { updateUnitEvent, unitsLoading, getUnit } = useUnits();

  if (buildingsLoading || unitsLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton />
      </div>
    );
  }

  const handleAfterSubmit = () => {
    if (!isNil(afterSubmit)) afterSubmit(event);
  };
  const handleCancel = () => {
    if (!isNil(cancelSubmit)) {
      cancelSubmit();
    }
  };

  const handleRenovationUpdate = async (
    values: RenovationCostsFormValues,
    { setSubmitting, setStatus }: FormikHelpers<RenovationCostsFormValues>
  ) => {
    const renovation = values.renovations[0];
    const { totalCost, startDate, endDate, note } = renovation;

    if (shouldUpdate(event, renovation)) {
      if ('buildingId' in event) {
        await updateBuildingEvent(event as BuildingEvent, {
          totalCost,
          startDate,
          endDate,
          note,
        });
      } else {
        await updateUnitEvent(event as UnitEvent, {
          totalCost,
          startDate,
          endDate,
          note,
        });
      }
    }
    setStatus(true);
    setSubmitting(false);
    handleAfterSubmit();
  };

  let entity: Unit | undefined;
  if ('unitId' in event) {
    entity = getUnit((event as UnitEvent).unitId);
  }

  const validationSchema = !isNil(entity)
    ? getDynamicRenovationCostsSchema(entity, false, formatMessage, language)
    : StaticRenovationCostsSchema;

  return (
    <Formik
      initialValues={
        {
          renovations: [event],
        } as RenovationCostsFormValues
      }
      validationSchema={validationSchema}
      onSubmit={handleRenovationUpdate}
    >
      {({ isSubmitting, status }) => {
        return (
          <Form>
            <div style={{ marginLeft: 20, marginRight: 20, marginTop: 25, marginBottom: 25 }}>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: 20,
                  textAlign: 'left',
                }}
              >
                {formatMessage({ id: 'rentalUnit.detail.general.editRenovation' })}
              </Typography>
            </div>
            <Divider style={{ marginBottom: 10 }} />
            <div style={{ alignItems: 'baseline', marginLeft: 20, marginRight: 20 }}>
              <RenovationCostsFields multiple={false} />
            </div>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <div
              style={{
                marginBottom: 20,
                marginRight: 30,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <ActionButton
                onClick={() => {
                  handleCancel();
                }}
                style={{ background: 'none', color: Colors.DARK_SLATE_GREY, marginRight: 20 }}
              >
                {toUpper(formatMessage({ id: 'cancel' }))}
              </ActionButton>
              <LoaderButton loading={isSubmitting} success={status}>
                {formatMessage({
                  id: 'save',
                })}
              </LoaderButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditRenovationForm;
