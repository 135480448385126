/* eslint-disable react/no-array-index-key */
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import { isEmpty } from 'lodash';
import React from 'react';
import { FileRejection } from 'react-dropzone';
import { useIntl } from 'react-intl';

const useLocalStyles = makeStyles({
  errorMessages: {
    marginRight: 20,
    paddingLeft: 0,
    color: Colors.RED_ORANGE,
  },
});

interface DropZoneFieldErrorMessageProps {
  fileRejections: FileRejection[];
  totalFileSize: number;
  maxFileSize: number;
}

const DropZoneFieldErrorMessage: React.FC<DropZoneFieldErrorMessageProps> = ({
  fileRejections,
  totalFileSize,
  maxFileSize,
}) => {
  const { formatMessage } = useIntl();
  const localClasses = useLocalStyles();
  const maxFileSizeInMb = maxFileSize / (1024 * 1024);

  return (
    <Grid>
      {!isEmpty(fileRejections) && fileRejections[0].errors[0].code === 'file-too-large' && (
        <Typography className={localClasses.errorMessages}>
          {formatMessage(
            {
              id: 'tickets.detail.warningFileSizeExcess',
            },
            { maxSize: `${maxFileSizeInMb}` }
          )}
        </Typography>
      )}
      {!isEmpty(fileRejections) && fileRejections[0].errors[0].code !== 'file-too-large' && (
        <Typography className={localClasses.errorMessages}>{fileRejections[0].errors[0].message}</Typography>
      )}
      {totalFileSize > maxFileSize && (
        <Typography className={localClasses.errorMessages}>
          {formatMessage(
            {
              id: 'tickets.detail.warningTotalFileSizeExcess',
            },
            { maxSize: `${maxFileSizeInMb}` }
          )}
        </Typography>
      )}
    </Grid>
  );
};

export default DropZoneFieldErrorMessage;
