/* eslint-disable default-case */
/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-param-last */
import React, { useContext, useState, useEffect } from 'react';
import isNil from 'lodash/isNil';
import { getCacheItem, setCacheItem } from 'src/utils/cacheUtils';

export interface Filter {
  name: string;
  field: string;
  items: string[];
}
export interface FilterFromTo {
  from: string;
  to: string;
}
interface FilterMinMax {
  min: number | '';
  max: number | '';
}

export interface CommonFilter {
  filters: Filter[];
}

export interface CommunicationsFilters extends CommonFilter {
  communicationsFromTo: FilterFromTo | null;
}
export interface ContactsFilters extends CommonFilter {}

export interface PropertiesFilters extends CommonFilter {
  statusFilterOwnUse: boolean;
  statusFilterRenovation: boolean;
  statusFilterRented: boolean;
  statusFilterVacant: boolean;
  statusFilterVacantWithinMonths: number;
}

export interface LeasesActiveFilters extends CommonFilter {
  endingInFromTo: FilterFromTo | null;
  balanceLatePaymentMonth: number;
  balanceDept: number;
}

export interface LeasesDraftsFilters extends CommonFilter {}

export interface LeasesArchivedFilters extends CommonFilter {
  endedSinceFromTo: FilterFromTo | null;
  balanceDept: number;
}

export interface BuildingsFilters extends CommonFilter {}
export interface UnitsFilters extends CommonFilter {
  leaseFilterOwnUse: boolean;
  leaseFilterRenovation: boolean;
  leaseFilterRented: boolean;
  leaseFilterVacant: boolean;
  leaseFilterFromTo: FilterFromTo | null;
  leaseFilterRentedButVacantFromTo: FilterFromTo | null;
  leaseFilterRentedAfter: boolean;
  balanceFilterLatePaymentMonth: number;
  balanceFilterDept: number;
}

export interface TicketsFilters extends CommonFilter {
  ticketsFromTo: FilterFromTo | null;
  plannedFromTo: FilterFromTo | null;
}

export interface TransactionRulesFilters extends CommonFilter {}

export interface TransactionsFilters extends CommonFilter {
  transactionsFromTo: FilterFromTo | null;
  transactionsAmount: FilterMinMax | null;
}

export interface ChargesFilters extends CommonFilter {
  chargesFromTo: FilterFromTo | null;
  chargesAmount: FilterMinMax | null;
}

export interface StatementsFilters extends CommonFilter {
  statementsPeriod: FilterFromTo | null;
  statementsSentDate: FilterFromTo | null;
}

export interface RateCardAssignableOwnersFilters extends CommonFilter {}

export interface ReconciliationFilters extends CommonFilter {
  hidePaid: boolean;
}

export interface FiltersContext {
  buildingsFilters: BuildingsFilters;
  unitsFilters: UnitsFilters;
  chargesFilters: ChargesFilters;
  communicationsFilters: CommunicationsFilters;
  contactsFilters: ContactsFilters;
  ticketsFilters: TicketsFilters;
  transactionRulesFilters: TransactionRulesFilters;
  transactionsFilters: TransactionsFilters;
  tenantStatementsFilters: StatementsFilters;
  ownerStatementsFilters: StatementsFilters;
  rateCardAssignableOwners: RateCardAssignableOwnersFilters;
  propertiesFilters: PropertiesFilters;
  leasesActiveFilters: LeasesActiveFilters;
  leasesDraftsFilters: LeasesDraftsFilters;
  leasesArchivedFilters: LeasesArchivedFilters;
  reconciliationFilters: ReconciliationFilters;
  updateFilters<T extends CommonFilter>(filterEntity: FilterEntity, variables: T): Promise<void>;
  resetFilters(filterEntity: FilterEntity, subFilter?: string): Promise<void>;
}

export enum FilterEntity {
  BUILDINGS = 'Filters_Buildings',
  UNITS = 'Filters_Units',
  CHARGES = 'Filters_Charges',
  COMMUNICATIONS = 'Filters_Communications',
  CONTACTS = 'Filters_Contacts',
  LEASES_ACTIVE = 'Filters_Leases_Active',
  LEASES_DRAFTS = 'Filters_Leases_Drafts',
  LEASES_ARCHIVED = 'Filters_Leases_Archived',
  OWNER_STATEMENTS = 'Filters_Owner_Statements',
  PROPERTIES = 'Filters_Properties',
  RATE_CARD_ASSIGNABLE_OWNERS = 'Filters_RateCardAssignableOwners',
  TENANT_STATEMENTS = 'Filters_Tenant_Statements',
  TICKETS = 'Filters_Tickets',
  TRANSACTIONS = 'Filters_Transactions',
  TRANSACTIONRULES = 'Filters_TransactionRules',
  DOCUMENTS = 'Filters_Documents',
  RECONCILIATION = 'Filters_Reconciliation',
}
export const FiltersContext = React.createContext<FiltersContext | null>(null);
export const DEFAULT_PROPERTIES_VACANT_WITHIN_MONTHS = 4;
export const FiltersContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const defaultBuildingsFilters = { filters: [] };
  const defaultUnitsFilters = {
    filters: [],
    leaseFilterOwnUse: true,
    leaseFilterRenovation: true,
    leaseFilterRented: true,
    leaseFilterVacant: true,
    leaseFilterFromTo: null,
    leaseFilterRentedButVacantFromTo: null,
    leaseFilterRentedAfter: false,
    balanceFilterLatePaymentMonth: 0,
    balanceFilterDept: 0,
  };
  const defaultPropertiesFilters = {
    filters: [],
    statusFilterVacantWithinMonths: DEFAULT_PROPERTIES_VACANT_WITHIN_MONTHS,
    statusFilterOwnUse: true,
    statusFilterRenovation: true,
    statusFilterRented: true,
    statusFilterVacant: true,
  };
  const defaultLeasesActiveFilters = {
    filters: [],
    endingInFromTo: null,
    balanceLatePaymentMonth: 0,
    balanceDept: 0,
  };
  const defaultLeasesDraftsFilters = {
    filters: [],
  };
  const defaultLeasesArchivedFilters = {
    filters: [],
    balanceDept: 0,
    endedSinceFromTo: null,
  };
  const defaultCommunicationsFilters = {
    filters: [],
    communicationsFromTo: null,
  };
  const defaultContactsFilters = { filters: [] };
  const defaultTicketsFilters = {
    filters: [],
    ticketsFromTo: null,
    plannedFromTo: null,
  };
  const defaultTransactionsRuleFilters = { filters: [] };
  const defaultTransactionsFilters = {
    filters: [],
    transactionsFromTo: null,
    transactionsAmount: null,
  };
  const defaultChargesFilters = {
    filters: [],
    chargesFromTo: null,
    chargesAmount: null,
  };
  const defaultStatementsFilters = {
    filters: [],
    statementsPeriod: null,
    statementsSentDate: null,
  };
  const defaultRateCardAssignableOwners = { filters: [] };
  const defaultReconciliationFilters = { filters: [], hidePaid: true };

  const [buildingsFilters, setBuildingsFilters] = useState<BuildingsFilters>(defaultBuildingsFilters);
  const [communicationsFilters, setCommunicationsFilters] =
    useState<CommunicationsFilters>(defaultCommunicationsFilters);
  const [contactsFilters, setContactsFilters] = useState<ContactsFilters>(defaultContactsFilters);
  const [unitsFilters, setUnitsFilters] = useState<UnitsFilters>(defaultUnitsFilters);
  const [ticketsFilters, setTicketsFilters] = useState<TicketsFilters>(defaultTicketsFilters);
  const [transactionRulesFilters, setTransactionRulesFilters] =
    useState<TransactionRulesFilters>(defaultTransactionsRuleFilters);
  const [transactionsFilters, setTransactionsFilters] = useState<TransactionsFilters>(defaultTransactionsFilters);
  const [chargesFilters, setChargesFilters] = useState<ChargesFilters>(defaultChargesFilters);
  const [tenantStatementsFilters, setTenantStatementsFilters] = useState<StatementsFilters>(defaultStatementsFilters);
  const [ownerStatementsFilters, setOwnerStatementsFilters] = useState<StatementsFilters>(defaultStatementsFilters);
  const [rateCardAssignableOwners, setRateCardAssignableOwners] = useState<RateCardAssignableOwnersFilters>(
    defaultRateCardAssignableOwners
  );
  const [propertiesFilters, setPropertiesFilters] = useState<PropertiesFilters>(defaultPropertiesFilters);
  const [leasesActiveFilters, setLeasesActiveFilters] = useState<LeasesActiveFilters>(defaultLeasesActiveFilters);
  const [leasesDraftsFilters, setLeasesDraftsFilters] = useState<LeasesDraftsFilters>(defaultLeasesDraftsFilters);
  const [leasesArchivedFilters, setLeasesArchivedFilters] =
    useState<LeasesArchivedFilters>(defaultLeasesArchivedFilters);

  const [reconciliationFilters, setReconciliationFilters] =
    useState<ReconciliationFilters>(defaultReconciliationFilters);

  const getFilters = async (filterEntity: FilterEntity) => {
    return await getCacheItem(filterEntity);
  };

  const updateFilters = async <T extends CommonFilter>(filterEntity: FilterEntity, variables: T) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let setFct: (filters: any) => void = setBuildingsFilters;
    switch (filterEntity) {
      case FilterEntity.COMMUNICATIONS:
        setFct = setCommunicationsFilters;
        break;
      case FilterEntity.CONTACTS:
        setFct = setContactsFilters;
        break;
      case FilterEntity.UNITS:
        setFct = setUnitsFilters;
        break;
      case FilterEntity.TICKETS:
        setFct = setTicketsFilters;
        break;
      case FilterEntity.TRANSACTIONRULES:
        setFct = setTransactionRulesFilters;
        break;
      case FilterEntity.TRANSACTIONS:
        setFct = setTransactionsFilters;
        break;
      case FilterEntity.CHARGES:
        setFct = setChargesFilters;
        break;
      case FilterEntity.TENANT_STATEMENTS:
        setFct = setTenantStatementsFilters;
        break;
      case FilterEntity.OWNER_STATEMENTS:
        setFct = setOwnerStatementsFilters;
        break;
      case FilterEntity.RATE_CARD_ASSIGNABLE_OWNERS:
        setFct = setRateCardAssignableOwners;
        break;
      case FilterEntity.PROPERTIES:
        setFct = setPropertiesFilters;
        break;
      case FilterEntity.LEASES_ACTIVE:
        setFct = setLeasesActiveFilters;
        break;
      case FilterEntity.LEASES_DRAFTS:
        setFct = setLeasesDraftsFilters;
        break;
      case FilterEntity.LEASES_ARCHIVED:
        setFct = setLeasesArchivedFilters;
        break;
      case FilterEntity.RECONCILIATION:
        setFct = setReconciliationFilters;
        break;
    }
    await setCacheItem(filterEntity, variables);
    setFct(variables);
  };

  const resetFilters = async (filterEntity: FilterEntity) => {
    let defaultValue = defaultBuildingsFilters;
    switch (filterEntity) {
      case FilterEntity.COMMUNICATIONS:
        defaultValue = defaultCommunicationsFilters;
        break;
      case FilterEntity.CONTACTS:
        defaultValue = defaultContactsFilters;
        break;
      case FilterEntity.UNITS:
        defaultValue = defaultUnitsFilters;
        break;
      case FilterEntity.TICKETS:
        defaultValue = defaultTicketsFilters;
        break;
      case FilterEntity.TRANSACTIONRULES:
        defaultValue = defaultTransactionsRuleFilters;
        break;
      case FilterEntity.TRANSACTIONS:
        defaultValue = defaultTransactionsFilters;
        break;
      case FilterEntity.CHARGES:
        defaultValue = defaultChargesFilters;
        break;
      case FilterEntity.OWNER_STATEMENTS:
      case FilterEntity.TENANT_STATEMENTS:
        defaultValue = defaultStatementsFilters;
        break;
      case FilterEntity.RATE_CARD_ASSIGNABLE_OWNERS:
        defaultValue = defaultRateCardAssignableOwners;
        break;
      case FilterEntity.PROPERTIES:
        defaultValue = defaultPropertiesFilters;
        break;
      case FilterEntity.LEASES_ACTIVE:
        defaultValue = defaultLeasesActiveFilters;
        break;
      case FilterEntity.LEASES_DRAFTS:
        defaultValue = defaultLeasesDraftsFilters;
        break;
      case FilterEntity.LEASES_ARCHIVED:
        defaultValue = defaultLeasesArchivedFilters;
        break;
    }
    await updateFilters(filterEntity, defaultValue);
  };

  useEffect(() => {
    const initializeFilters = async () => {
      const buildingsFilters = await getFilters(FilterEntity.BUILDINGS);
      if (!isNil(buildingsFilters)) {
        setBuildingsFilters(buildingsFilters);
      }
      const communicationsFilters = await getFilters(FilterEntity.COMMUNICATIONS);
      if (!isNil(communicationsFilters)) {
        setCommunicationsFilters(communicationsFilters);
      }
      const contactsFilters = await getFilters(FilterEntity.CONTACTS);
      if (!isNil(contactsFilters)) {
        setContactsFilters(contactsFilters);
      }
      const unitsFilters = await getFilters(FilterEntity.UNITS);
      if (!isNil(unitsFilters)) {
        setUnitsFilters(unitsFilters);
      }
      const ticketsFilters = await getFilters(FilterEntity.TICKETS);
      if (!isNil(ticketsFilters)) {
        setTicketsFilters(ticketsFilters);
      }
      const transactionRulesFilters = await getFilters(FilterEntity.TRANSACTIONRULES);
      if (!isNil(transactionRulesFilters)) {
        setTransactionRulesFilters(transactionRulesFilters);
      }
      const transactionsFilters = await getFilters(FilterEntity.TRANSACTIONS);
      if (!isNil(transactionsFilters)) {
        setTransactionsFilters(transactionsFilters);
      }
      const chargesFilters = await getFilters(FilterEntity.CHARGES);
      if (!isNil(chargesFilters)) {
        setChargesFilters(chargesFilters);
      }
      const tenantStatementsFilters = await getFilters(FilterEntity.TENANT_STATEMENTS);
      if (!isNil(tenantStatementsFilters)) {
        setTenantStatementsFilters(tenantStatementsFilters);
      }
      const ownerStatementsFilters = await getFilters(FilterEntity.OWNER_STATEMENTS);
      if (!isNil(ownerStatementsFilters)) {
        setOwnerStatementsFilters(ownerStatementsFilters);
      }
      const rateCardAssignableOwners = await getFilters(FilterEntity.RATE_CARD_ASSIGNABLE_OWNERS);
      if (!isNil(rateCardAssignableOwners)) {
        setRateCardAssignableOwners(rateCardAssignableOwners);
      }
      const propertiesFilters = await getFilters(FilterEntity.PROPERTIES);
      if (!isNil(propertiesFilters)) {
        setPropertiesFilters(propertiesFilters);
      }
      const leasesActiveFilters = await getFilters(FilterEntity.LEASES_ACTIVE);
      if (!isNil(leasesActiveFilters)) {
        setLeasesActiveFilters(leasesActiveFilters);
      }
      const leasesDraftsFilters = await getFilters(FilterEntity.LEASES_DRAFTS);
      if (!isNil(leasesDraftsFilters)) {
        setLeasesDraftsFilters(leasesDraftsFilters);
      }
      const leasesArchivedFilters = await getFilters(FilterEntity.LEASES_ARCHIVED);
      if (!isNil(leasesArchivedFilters)) {
        setLeasesArchivedFilters(leasesArchivedFilters);
      }
      const reconciliationFilters = await getFilters(FilterEntity.RECONCILIATION);
      if (!isNil(reconciliationFilters)) {
        setReconciliationFilters(reconciliationFilters);
      }
    };
    initializeFilters();
  }, []);

  const values = React.useMemo(
    () => ({
      buildingsFilters,
      chargesFilters,
      communicationsFilters,
      contactsFilters,
      unitsFilters,
      ticketsFilters,
      transactionRulesFilters,
      transactionsFilters,
      tenantStatementsFilters,
      ownerStatementsFilters,
      rateCardAssignableOwners,
      propertiesFilters,
      leasesActiveFilters,
      leasesDraftsFilters,
      leasesArchivedFilters,
      updateFilters,
      resetFilters,
      reconciliationFilters,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      buildingsFilters,
      chargesFilters,
      communicationsFilters,
      contactsFilters,
      unitsFilters,
      ticketsFilters,
      transactionRulesFilters,
      transactionsFilters,
      tenantStatementsFilters,
      ownerStatementsFilters,
      rateCardAssignableOwners,
      propertiesFilters,
      leasesActiveFilters,
      leasesDraftsFilters,
      leasesArchivedFilters,
      reconciliationFilters,
    ]
  );
  return <FiltersContext.Provider value={values}>{children}</FiltersContext.Provider>;
};

export const useFilters = (): FiltersContext => {
  const context = useContext<FiltersContext | null>(FiltersContext);

  if (context === undefined) {
    throw new Error('`useFilters` hook must be used within a `FiltersContextProvider` component');
  }
  return context as FiltersContext;
};
