import { Divider, Typography } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import isNil from 'lodash/isNil';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import { CustomPopover , ElevatedPaper } from '@up2rent/ui';
import FormHeader from 'src/components/ui/FormHeader';
import UnitStructureFields, {
  UnitInventoriesFormValues as ImpUnitInventoriesFormValues,
} from 'src/components/ui/Forms/FormField/UnitStructureFields';

export { UnitInventoriesSchema } from 'src/components/ui/Forms/FormField/UnitStructureFields';

export type UnitInventoriesFormValues = ImpUnitInventoriesFormValues;

interface UnitStructureFormProps {
  helpIcon?: boolean;
  canDelete?: boolean;
  unitId?: string;
}

export interface StructureHelperBundle {
  anchor: SVGSVGElement | null;
  inside: boolean;
}

const UnitStructureForm: React.FC<UnitStructureFormProps> = ({ helpIcon = false, canDelete = true, unitId }) => {
  const { formatMessage } = useIntl();
  const [openStructureHelper, setOpenStructureHelper] = useState<StructureHelperBundle>({
    anchor: null,
    inside: false,
  });
  const [deletingWarning, setDeletingWarning] = useState<'inside' | 'outside' | ''>('');

  const handlePopoverOpen = (event: React.MouseEvent<SVGSVGElement, MouseEvent>, inside: boolean) => {
    setOpenStructureHelper({ anchor: event.currentTarget, inside });
  };

  const handlePopoverClose = () => {
    setOpenStructureHelper({ anchor: null, inside: false });
  };

  return (
    <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
      <ElevatedPaper style={{ width: 310 }}>
        <FormHeader
          title={formatMessage({
            id: `lease.addLease.numberOfRooms`,
          })}
          toolbarStyle={{
            display: 'flex',
            justifyContent: helpIcon ? 'flex-start' : 'space-between',
            alignItems: 'center',
          }}
        >
          {helpIcon && (
            <InfoSvg
              style={{
                fill: deletingWarning === 'inside' ? Colors.CARNATION_RED : Colors.SILVER,
                marginLeft: 10,
                cursor: 'default',
              }}
              onMouseEnter={(e) => handlePopoverOpen(e, true)}
              onMouseLeave={handlePopoverClose}
            />
          )}
        </FormHeader>
        <Divider style={{ marginBottom: 10 }} />
        <UnitStructureFields
          inside
          canDelete={canDelete}
          unitId={unitId}
          handleDeleteWarning={(action: 'open' | 'close') => setDeletingWarning(action === 'open' ? 'inside' : '')}
          fieldName="unitStructures"
          originalFieldName="originalUnitInventories"
        />
      </ElevatedPaper>
      <ElevatedPaper style={{ width: 310 }}>
        <FormHeader
          title={formatMessage({
            id: `lease.addLease.numberOfOutbuildings`,
          })}
          toolbarStyle={{
            display: 'flex',
            justifyContent: helpIcon ? 'flex-start' : 'space-between',
            alignItems: 'center',
          }}
        >
          {helpIcon && (
            <InfoSvg
              style={{
                fill: deletingWarning === 'outside' ? Colors.CARNATION_RED : Colors.SILVER,
                marginLeft: 10,
                cursor: 'default',
              }}
              onMouseEnter={(e) => handlePopoverOpen(e, false)}
              onMouseLeave={handlePopoverClose}
            />
          )}
        </FormHeader>
        <Divider style={{ marginBottom: 10 }} />
        <UnitStructureFields
          inside={false}
          canDelete={canDelete}
          unitId={unitId}
          handleDeleteWarning={(action: 'open' | 'close') => setDeletingWarning(action === 'open' ? 'outside' : '')}
          fieldName="unitStructures"
          originalFieldName="originalUnitInventories"
        />
      </ElevatedPaper>
      <CustomPopover
        open={!isNil(openStructureHelper.anchor)}
        anchorEl={openStructureHelper.anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        paperPadding={5}
      >
        <>
          <Typography display="inline">
            {formatMessage({ id: 'lease.editLease.warningDeleteUnitInventory1' })}
          </Typography>
          <Typography display="inline" style={{ fontWeight: 700 }}>
            {` ${formatMessage({
              id: `furnitureInventory.${openStructureHelper.inside ? 'inside' : 'outside'}`,
            }).toLowerCase()} `}
          </Typography>
          <Typography display="inline">{`${formatMessage({
            id: 'lease.editLease.warningDeleteUnitInventory2',
          })}.`}</Typography>
          <Typography
            style={{
              color:
                (openStructureHelper.inside && deletingWarning === 'inside') ||
                (!openStructureHelper.inside && deletingWarning === 'outside')
                  ? Colors.CARNATION_RED
                  : Colors.CLASSICAL_BLACK,
            }}
          >
            {formatMessage({ id: 'lease.editLease.warningDeleteUnitInventory3' })}
          </Typography>
        </>
      </CustomPopover>
    </div>
  );
};

export default UnitStructureForm;
