import { Grid, Collapse, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { AddLeaseFormValues } from '../../../Leases/AddLease/AddLeaseForm';
import ChargesFields, { MonthlyChargesFieldsFormValues } from '../FormField/ChargesFields';
import TitledSwitch from '../../TitledSwitch';
import { resolveMainUnitAndSubUnitIndexAndPrefix } from 'src/components/Leases/AddLease/useAddEditLeaseUtils';
import { get } from 'lodash';
import BulbRemark from '../../IconnedRemark';
import { ReactComponent as BulbIcon } from '../../../../icons/bulb.svg';
import { useIntl } from 'react-intl';

interface MonthlyChargesProps {
  unitId: string;
}

export interface MonthlyChargesFormValues extends MonthlyChargesFieldsFormValues {}

const MonthlyCharges: FC<MonthlyChargesProps> = ({ unitId }) => {
  const { formatMessage } = useIntl();
  const { values } = useFormikContext<AddLeaseFormValues>();
  const { prefixFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(values, unitId);
  const includeMonthlyCharge = get(values, `${prefixFieldName}includeMonthlyCharge`);

  return (
    <Grid style={{ margin: '10px 10px 0px 30px', maxWidth: 580 }}>
      <TitledSwitch
        titleMessageId="lease.detail.general.monthlyCharges"
        fieldName={`${prefixFieldName}includeMonthlyCharge`}
        switchOnTextId="lease.addLease.includeInLease"
        switchOffTextId="lease.addLease.ignore"
      />
      <Collapse in={includeMonthlyCharge}>
        <ChargesFields unitId={unitId} />
      </Collapse>
      {!includeMonthlyCharge && (
        <BulbRemark
          Icon={BulbIcon}
          message={
            <Typography
              style={{
                fontSize: 12,
                color: '#546e7a',
              }}
            >
              {formatMessage({ id: 'lease.addLease.noChargesSelected' })}
            </Typography>
          }
          style={{ margin: 0 }}
        />
      )}
    </Grid>
  );
};

export default MonthlyCharges;
