/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const PASS_FULL_OBJECT_TO_FUNCTION = 'object_';
export const COLUMN_FIELD_TYPE_STRING = 'string';
export const COLUMN_FIELD_TYPE_NUMBER = 'number';

export function stableSort<T>(array: T[], cmp: Function): T[] {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return (a[1] as number) - (b[1] as number);
  });
  return stabilizedThis.map((el) => el[0]) as T[];
}

export function getSorting(order: 'asc' | 'desc', orderBy: string, fn?: Function, orderByColumnType?: string) {
  return order === 'desc'
    ? (a: object, b: object) => desc(a, b, orderBy, fn, orderByColumnType)
    : (a: object, b: object) => -desc(a, b, orderBy, fn, orderByColumnType);
}

function desc<T>(a: T, b: T, orderBy: keyof T | string, fn?: Function, orderByColumnType?: string) {
  let x: any;
  let y: any;
  x = b[orderBy as keyof T];
  y = a[orderBy as keyof T];
  const fields = (orderBy as string).split('.');
  if (typeof fields !== 'undefined' && fields.length > 1) {
    x = b[fields[0] as keyof T];
    y = a[fields[0] as keyof T];
    for (let i = 1; i < fields.length; i++) {
      x = x?.[fields[i]];
      y = y?.[fields[i]];
    }
  }

  if (typeof fn !== 'undefined') {
    if ((orderBy as string).indexOf(PASS_FULL_OBJECT_TO_FUNCTION) !== -1) {
      x = fn(b);
      y = fn(a);
    } else {
      x = fn(x);
      y = fn(y);
    }
  }

  if (x === null) {
    x = orderByColumnType === COLUMN_FIELD_TYPE_STRING ? '' : 0;
  }
  if (y === null) {
    y = orderByColumnType === COLUMN_FIELD_TYPE_STRING ? '' : 0;
  }
  if (x < y) {
    return -1;
  }
  if (x > y) {
    return 1;
  }
  return 0;
}
