import {
  Communication,
  CommunicationChannel,
  CommunicationStatus,
  CommunicationType,
  Template,
  TemplateType,
  defaultContractTemplates,
  defaultEmailTemplates,
  defaultLetterTemplates,
  extractEmailAddress,
} from '@rentguru/commons-utils';
import { isWithinInterval, maxTime, minTime } from 'date-fns';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import isNil from 'lodash/isNil';
import { IntlFormatters } from 'react-intl';
import { verifyDisposableEmail } from 'src/components/Contacts/AddContact';
import { CommunicationsFilters, Filter, FilterFromTo } from 'src/hooks/FiltersContext';
import * as Yup from 'yup';
import { isEntityInClassicFilters } from './filtersUtils';

export enum CommunicationFilterType {
  TO_SEND = 'TO_SEND',
  HISTORY = 'HISTORY',
  OUTBOX = 'OUTBOX',
  OUTDATED = 'OUTDATED',
}

export enum CommunicationState {
  BLOCKED = 'BLOCKED',
  NOT_BLOCKED = 'NOT_BLOCKED',
}

export interface CommunicationWithTemplate extends Communication {
  template?: Template;
}

export const addTemplateToCommunication = (
  communication: Communication,
  templates: Template[]
): CommunicationWithTemplate => {
  const communicationTemplateId = communication.communicationTemplateId;
  if (isNil(communicationTemplateId)) return communication;

  const customTemplate = templates.find((template) => template.id === communicationTemplateId);
  if (customTemplate) return { ...communication, template: customTemplate };

  const defaultTemplates = [...defaultEmailTemplates, ...defaultLetterTemplates, ...defaultContractTemplates];
  const defaultTemplate = defaultTemplates.find((template) => template.id === communicationTemplateId);
  if (defaultTemplate) return { ...communication, template: defaultTemplate };
  return communication;
};

export const filterCommunicationsByFilters = (
  communications: Communication[],
  communicationFilters: CommunicationsFilters,
  filterType: CommunicationFilterType
) => {
  const { filters, communicationsFromTo } = communicationFilters;
  const [typeFilter, classicFilters, stateFilter] = extractTypeFilterAndUnnecessary(filters, filterType);
  return communications.filter((communication) => {
    if (classicFilters.length > 0 && !isEntityInClassicFilters(communication, classicFilters)) return false;
    if (
      typeFilter &&
      typeFilter.items.length > 0 &&
      !communicationHasCorrectType(communication, typeFilter.items as CommunicationType[])
    )
      return false;

    if (
      stateFilter &&
      stateFilter.items.length &&
      !communicationHasCorrectState(communication, stateFilter.items as CommunicationState[])
    ) {
      return false;
    }
    if (communicationsFromTo && !communicationIsInInterval(communication, communicationsFromTo, filterType))
      return false;
    return true;
  });
};

const extractTypeFilterAndUnnecessary = (filters: Filter[], filterType: CommunicationFilterType) => {
  return filters.reduce(
    (acc: [Filter | undefined, Filter[], Filter | undefined], filter) => {
      if (filter.name === 'type') acc[0] = filter;
      else if (filter.name === 'state') acc[2] = filter;
      else if (filterType !== CommunicationFilterType.OUTBOX || filter.name !== 'recipient') {
        acc[1].push(filter);
      }
      return acc;
    },
    [undefined, [], undefined]
  );
};

const communicationHasCorrectType = (communication: Communication, types: CommunicationType[]) =>
  types.some((type) => communication.type === type);

const communicationHasCorrectState = (communication: Communication, states: CommunicationState[]) => {
  if (states.includes(CommunicationState.BLOCKED) && communication.status === CommunicationStatus.BOUNCE_ERROR) {
    return true;
  }
  if (states.includes(CommunicationState.NOT_BLOCKED) && communication.status !== CommunicationStatus.BOUNCE_ERROR) {
    return true;
  }
  return false;
};

const communicationIsInInterval = (
  communication: Communication,
  communicationsFromTo: FilterFromTo,
  filterType: CommunicationFilterType
) => {
  const { from, to } = communicationsFromTo;
  const communicationDate = [CommunicationFilterType.TO_SEND, CommunicationFilterType.OUTBOX].includes(filterType)
    ? communication.createdAt!
    : communication.sentAt!;
  const start = from ? new Date(from) : new Date(minTime);
  const end = to ? new Date(to) : new Date(maxTime);
  return isWithinInterval(new Date(communicationDate), { start, end });
};

export const isLetterCommunication = (channel: CommunicationChannel) =>
  [CommunicationChannel.REGISTERED_LETTER, CommunicationChannel.LETTER].includes(channel);

export const channelToTemplateType = (channel: CommunicationChannel) => {
  if ([CommunicationChannel.REGISTERED_LETTER, CommunicationChannel.LETTER].includes(channel)) {
    return TemplateType.LETTER;
  }
  return TemplateType.MAIL;
};

export const getValidEmailSchema = (formatMessage: IntlFormatters['formatMessage']) =>
  Yup.array().of(
    Yup.string()
      // eslint-disable-next-line func-names
      .test('Checking email validity', 'disposable email error', async function (value) {
        if (isNil(value)) return false;
        const email = extractEmailAddress(value);
        const isEmailValid = await Yup.string().email().isValid(email);
        if (!isEmailValid) {
          return false;
        }
        // eslint-disable-next-line react/no-this-in-sfc
        const isDisposable = await verifyDisposableEmail(email, formatMessage, this.createError);
        return isDisposable === true;
      })
  );

export const stringifyEditorStateTitle = (titleEditor: EditorState) =>
  stateToHTML(titleEditor.getCurrentContent()).replace(/<\/?[^>]+(>|$)|&nbsp;/g, '');

export const stringifyEditorStateBody = (bodyEditor: EditorState) =>
  stateToHTML(bodyEditor.getCurrentContent()).replace(/(\r\n|\n|\r|\u200C)/gm, '');
