import { Grid, Collapse, InputAdornment, FormControlLabel, Typography } from '@material-ui/core';
import { isBefore, isToday } from 'date-fns';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { AddLeaseFormValues } from '../../../Leases/AddLease/AddLeaseForm';
import { resolveMainUnitAndSubUnitIndexAndPrefix } from '../../../Leases/AddLease/useAddEditLeaseUtils';
import CustomCheckBox from '../../CustomCheckBox';
import TextDetailEditable from '../../TextDetailEditable';
import TitledSwitch from '../../TitledSwitch';
import { useSettings } from 'src/hooks/SettingsContext';
import { getSettingValueForAutomaticIndexation, LeaseMonthlyChargesType } from '@rentguru/commons-utils';
import FormAlertBox from '../../FormAlertBox';

export interface RentIndexationFormValues {
  initialRentalPrice: number;
  initialChargePrice: number;
  rentalIndexation: boolean;
  chargeIndexation: boolean;
}

interface RentIndexationProps {
  unitId: string;
}

const RentIndexation: React.FC<RentIndexationProps> = ({ unitId }) => {
  const { values, setFieldValue, errors, touched } = useFormikContext<AddLeaseFormValues>();
  const { formatMessage } = useIntl();
  const { settings } = useSettings();
  const settingIndexation = getSettingValueForAutomaticIndexation(settings);
  const { prefixFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(values, unitId);

  return (
    <>
      <Grid container style={{ margin: '10px 10px 0px 30px', maxWidth: 580 }}>
        <TitledSwitch
          titleMessageId="lease.addLease.rentIndexation"
          fieldName={`${prefixFieldName}indexation`}
          switchOnTextId="lease.addLease.includeInLease"
          switchOffTextId="lease.addLease.ignore"
        />
      </Grid>
      <Collapse in={!settingIndexation && get(values, `${prefixFieldName}indexation`)}>
        <FormAlertBox
          messagesGridStyle={{ marginRight: 30 }}
          message1={formatMessage({ id: 'lease.addLease.inactiveIndexationInSettings' })}
        />
      </Collapse>
      <Collapse in={get(values, `${prefixFieldName}indexation`)} style={{ marginTop: 10 }}>
        <Grid style={{ textAlign: 'left', marginLeft: 30 }}>
          <Grid style={{ width: 580 }}>
            <FormControlLabel
              style={{ width: '50%' }}
              control={
                <CustomCheckBox
                  onCheck={(event) => {
                    setFieldValue(`${prefixFieldName}rentalIndexation`, event.target.checked);
                  }}
                  isChecked={get(values, `${prefixFieldName}rentalIndexation`)}
                />
              }
              label={
                <Typography style={{ fontSize: 15, fontWeight: 500 }}>
                  {formatMessage({ id: 'lease.addLease.rentalIndexation' })}
                </Typography>
              }
            />
            <Collapse
              in={
                get(values, `${prefixFieldName}rentalIndexation`) &&
                isBefore(
                  new Date(!values.allDatesAreSame ? get(values, `${prefixFieldName}startDate`) : values.startDate),
                  new Date()
                ) &&
                !isToday(
                  new Date(!values.allDatesAreSame ? get(values, `${prefixFieldName}startDate`) : values.startDate)
                )
              }
            >
              <Grid container style={{ margin: '0px 10px 0px 0px', maxWidth: 580 }}>
                <Grid item xs={6} style={{ textAlign: 'left' }}>
                  <TextDetailEditable
                    editMode
                    title={formatMessage({
                      id: `lease.indexation.${values.applyVat ? 'initialRentalPriceNoVAT' : 'initialRentalPrice'}`,
                    })}
                    name={`${prefixFieldName}initialRentalPrice`}
                    type="number"
                    style={{ width: 280 }}
                    min={0}
                    endAdornment={<InputAdornment position="end">EUR</InputAdornment>}
                    typeNumberNoEndArrow
                    error={Boolean(get(errors, `${prefixFieldName}initialRentalPrice`))}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextDetailEditable
                    editMode
                    title={formatMessage({
                      id: `lease.addLease.${values.applyVat ? 'rentalPriceNoVat' : 'rentalPrice'}`,
                    })}
                    name={`${prefixFieldName}rentalPrice`}
                    type="number"
                    style={{ width: 280 }}
                    min={0}
                    endAdornment={<InputAdornment position="end">EUR</InputAdornment>}
                    typeNumberNoEndArrow
                    error={Boolean(
                      get(errors, `${prefixFieldName}rentalPrice`) && get(touched, `${prefixFieldName}rentalPrice`)
                    )}
                    disabled
                  />
                </Grid>
              </Grid>
            </Collapse>
            <Collapse
              in={
                get(values, `${prefixFieldName}includeMonthlyCharge`) &&
                get(values, `${prefixFieldName}monthlyChargesFields.monthlyChargesType`) ===
                  LeaseMonthlyChargesType.FixedPrice
              }
            >
              <FormControlLabel
                control={
                  <CustomCheckBox
                    onCheck={(event) => {
                      setFieldValue(`${prefixFieldName}chargeIndexation`, event.target.checked);
                    }}
                    isChecked={get(values, `${prefixFieldName}chargeIndexation`)}
                  />
                }
                label={
                  <Typography style={{ fontSize: 15, fontWeight: 500 }}>
                    {formatMessage({ id: 'lease.addLease.chargeIndexation' })}
                  </Typography>
                }
              />
              <Collapse
                in={
                  get(values, `${prefixFieldName}chargeIndexation`) &&
                  isBefore(
                    new Date(!values.allDatesAreSame ? get(values, `${prefixFieldName}startDate`) : values.startDate),
                    new Date()
                  ) &&
                  !isToday(
                    new Date(!values.allDatesAreSame ? get(values, `${prefixFieldName}startDate`) : values.startDate)
                  )
                }
              >
                <Grid container style={{ margin: '0px 10px 0px 0px', maxWidth: 580 }}>
                  <Grid item xs={6} style={{ textAlign: 'left' }}>
                    <TextDetailEditable
                      editMode
                      title={formatMessage({
                        id: `lease.indexation.${values.applyVat ? 'initialChargePriceNoVAT' : 'initialChargePrice'}`,
                      })}
                      name={`${prefixFieldName}initialChargePrice`}
                      type="number"
                      style={{ width: 280 }}
                      min={0}
                      endAdornment={<InputAdornment position="end">EUR</InputAdornment>}
                      typeNumberNoEndArrow
                      error={Boolean(get(errors, `${prefixFieldName}initialChargePrice`))}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextDetailEditable
                      editMode
                      title={formatMessage({
                        id: `lease.addLease.${values.applyVat ? 'rentalChargeNoVat' : 'chargePrice'}`,
                      })}
                      name={`${prefixFieldName}monthlyChargesFields.monthlyCharges`}
                      type="number"
                      style={{ width: 280 }}
                      min={0}
                      endAdornment={<InputAdornment position="end">EUR</InputAdornment>}
                      typeNumberNoEndArrow
                      error={Boolean(
                        get(errors, `${prefixFieldName}monthlyChargesFields.monthlyCharges`) &&
                          get(touched, `${prefixFieldName}monthlyChargesFields.monthlyCharges`)
                      )}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Collapse>
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default RentIndexation;
