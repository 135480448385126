import { useLocation } from 'react-router-dom';
import { isNil } from 'lodash';
import EditAdvertisementForm from './EditPublicationForm';
import { Unit } from '@rentguru/commons-utils';
import EditAdvertisementLoader from './EditPublicationLoader';

export interface StateInterface {
  unit: Unit;
  location: number;
}

// TODO BL: use those values when calling this page or typescript
export const EDIT_ADVERTISEMENT_DESCRIPTION_MENU = 0;
export const EDIT_ADVERTISEMENT_GENERAL_MENU = 1;
export const EDIT_ADVERTISEMENT_INTERIOR_MENU = 2;
export const EDIT_ADVERTISEMENT_OUTSIDE_MENU = 3;
export const EDIT_ADVERTISEMENT_ENERGY_MENU = 4;
export const EDIT_ADVERTISEMENT_MORE_MENU = 5;

const EditAdvertisement = () => {
  const { state } = useLocation<StateInterface>();
  const unit = state.unit;

  if (isNil(unit)) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <EditAdvertisementLoader />
      </div>
    );
  }
  return <EditAdvertisementForm unit={unit} location={state.location} />;
};

export default EditAdvertisement;
