import React, { useState } from 'react';
import { LeaseInventory, UnitInventoryStructureType, Colors } from '@rentguru/commons-utils';
import { Grid, Typography, Collapse, Divider } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { ReactComponent as AddIcon } from 'src/icons/add.svg';
import GeneralTab from './LeaseInventoryEncodingTabs/GeneralTab';
import StructuresTab from './LeaseInventoryEncodingTabs/StructuresTab';
import { ExtendedUnitInventory } from 'src/hooks/UnitInventoryContext';
import { AddCheckedItemDialogBundle, FilePreviewDialogBundle } from './InventoryEncodings';
import { EQUIPMENT_TAB, FINISHES_TAB, GENERAL_TAB } from '../EditInventory/EditInventoryForm';
import EquipmentTab from './LeaseInventoryEncodingTabs/EquipmentTab';
import { CustomIconButton } from '@up2rent/ui';

interface InventoryStructureCollapseProps {
  leaseInventory: LeaseInventory;
  completeUnitInventory: ExtendedUnitInventory;
  unitInventoryIndex: number;
  proposeUnitInventory: (unitInventoryId: string) => void;
  previewFileFunction: (fileBundle: FilePreviewDialogBundle) => void;
  encodeDefects: (defectBundle: AddCheckedItemDialogBundle) => void;
  setStructure: (structureType: UnitInventoryStructureType) => void;
  tab: number;
  readOnly: boolean;
  divider?: boolean;
}

const InventoryStructureCollapse: React.FC<InventoryStructureCollapseProps> = ({
  leaseInventory,
  completeUnitInventory,
  unitInventoryIndex,
  proposeUnitInventory,
  previewFileFunction,
  encodeDefects,
  setStructure,
  tab,
  readOnly,
  divider = true,
}) => {
  const [collapse, setCollapse] = useState<boolean>(true);
  const rotatingIconStyle = (isOpen: boolean): object => ({
    transform: `rotate(${isOpen ? 90 : 0}deg)`,
    fill: Colors.DARK_SLATE_GREY,
    marginRight: 10,
  });
  return (
    <>
      <div style={{ marginTop: 20, marginBottom: 20, marginRight: 30, marginLeft: 30 }}>
        <Grid container onClick={() => setCollapse(!collapse)} alignItems="center" style={{ cursor: 'pointer' }}>
          <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <NavigateNextIcon style={rotatingIconStyle(collapse)} />
            <Typography style={{ fontSize: 14, fontWeight: 800 }}>{completeUnitInventory.roomName}</Typography>
          </Grid>
          <Grid item xs={7} />
          {tab !== GENERAL_TAB && !readOnly && (
            <Grid item xs={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CustomIconButton
                onClick={(event) => {
                  event.stopPropagation();
                  setStructure(
                    tab === FINISHES_TAB ? UnitInventoryStructureType.OTHER : UnitInventoryStructureType.EQUIPMENT
                  );
                  proposeUnitInventory(completeUnitInventory.id);
                }}
                size="small"
                Icon={AddIcon}
                iconStyle={{ fill: Colors.DARK_SLATE_GREY }}
              />
            </Grid>
          )}
        </Grid>
        <Collapse in={collapse} mountOnEnter unmountOnExit>
          {GENERAL_TAB === tab && (
            <GeneralTab
              leaseInventory={leaseInventory}
              completeUnitInventory={completeUnitInventory}
              unitInventoryIndex={unitInventoryIndex}
              previewFileFunction={previewFileFunction}
              encodeDefects={encodeDefects}
              readOnly={readOnly}
            />
          )}
          {FINISHES_TAB === tab && (
            <StructuresTab
              leaseInventory={leaseInventory}
              completeUnitInventory={completeUnitInventory}
              unitInventoryIndex={unitInventoryIndex}
              previewFileFunction={previewFileFunction}
              encodeDefects={encodeDefects}
              readOnly={readOnly}
            />
          )}
          {EQUIPMENT_TAB === tab && (
            <EquipmentTab
              leaseInventory={leaseInventory}
              completeUnitInventory={completeUnitInventory}
              unitInventoryIndex={unitInventoryIndex}
              previewFileFunction={previewFileFunction}
              encodeDefects={encodeDefects}
              readOnly={readOnly}
            />
          )}
        </Collapse>
      </div>
      {divider && <Divider />}
    </>
  );
};

export default InventoryStructureCollapse;
