import React from 'react';
import { TableCell, TableRow, Tooltip, Typography } from '@material-ui/core';
import { ReactComponent as EditIcon } from 'src/icons/edit.svg';
import { useIntl } from 'react-intl';
import { useUnits } from 'src/hooks/UnitsContext';
import { useLeases } from 'src/hooks/LeasesContext';
import isNil from 'lodash/isNil';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { useLocale, dateLocaleMap, dateLocaleMapType } from 'src/i18n/IntlProviderWrapper';
import { LeaseInventory, LeaseInventoryType, Colors, getContactNameFromObject } from '@rentguru/commons-utils';
import isEmpty from 'lodash/isEmpty';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { RouteDestination } from 'src/components/Routes/Routes';
import { LEASES_DETAIL_TO } from 'src/components/Leases/Leases';
import { ROW_HEIGHT } from '../DashboardDrafts';
import { CustomIconButton } from '@up2rent/ui';
import { stopPropagation } from 'src/components/Dashboard/Dashboard';

interface LeaseInventoryDraftRowProps {
  leaseInventory: LeaseInventory;
}

const LeaseInventoryDraftRow: React.FC<LeaseInventoryDraftRowProps> = ({ leaseInventory }) => {
  const { inventoryOfFixturesCreationWrite } = usePermissions();
  const { formatMessage } = useIntl();
  const { getLease } = useLeases();
  const { getUnit } = useUnits();
  const history = useHistory();
  const { language } = useLocale();
  const lease = getLease(leaseInventory.leaseId);
  if (isNil(lease)) {
    return (
      <TableRow style={{ minHeight: ROW_HEIGHT, height: ROW_HEIGHT }}>
        <TableCell>
          <Typography>{formatMessage({ id: 'loading' })}</Typography>
        </TableCell>
      </TableRow>
    );
  }
  const leaseUnit = getUnit(lease.units![0].unit!.id);
  if (isNil(leaseUnit)) {
    return (
      <TableRow style={{ minHeight: ROW_HEIGHT, height: ROW_HEIGHT }}>
        <TableCell>
          <Typography>{formatMessage({ id: 'loading' })}</Typography>
        </TableCell>
      </TableRow>
    );
  }
  const leaseBuilding = leaseUnit.building!;
  const destination = `${RouteDestination.LEASES}/${LEASES_DETAIL_TO}/${lease.id}/inventory-${leaseInventory.id}`;

  return (
    <TableRow
      hover
      style={{ cursor: 'pointer', textDecoration: 'none', minHeight: ROW_HEIGHT, height: ROW_HEIGHT }}
      onClick={() => history.push({ pathname: destination, state: { goBackUrl: history.location } })}
    >
      <TableCell align="left" padding="none" style={{ paddingLeft: 20 }}>
        <Typography style={{ color: Colors.CLASSICAL_BLACK, fontSize: 14, fontWeight: 700 }}>
          {leaseInventory.inventoryType === LeaseInventoryType.FIXTURES_ENTRANCE
            ? formatMessage({ id: 'leaseInventory.entranceInventory' })
            : formatMessage({ id: 'leaseInventory.exitInventory' })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14, fontWeight: 500 }}>
          {lease.units!.map((u) => u.unit!.name).join(',')}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14, fontWeight: 500 }}>
          {leaseBuilding.name}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14, fontWeight: 500 }}>
          {lease.tenants.length > 1
            ? `${lease.tenants.length} ${formatMessage({ id: 'lease.detail.general.tenants' })}`
            : isEmpty(lease.tenants)
            ? null
            : getContactNameFromObject(lease.tenants[0])}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14, fontWeight: 500 }}>
          {!isNil(leaseInventory.createdAt)
            ? format(new Date(leaseInventory.createdAt), 'd/MM/yyyy', {
                locale: (dateLocaleMap as dateLocaleMapType)[language],
              })
            : null}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        {inventoryOfFixturesCreationWrite && (
          <Tooltip title={formatMessage({ id: 'edit' })} placement="top">
            <CustomIconButton
              onMouseDown={stopPropagation}
              onClick={(event) => {
                event.stopPropagation();
                history.push({
                  pathname: RouteDestination.EDIT_INVENTORY,
                  state: { leaseInventory, unitId: leaseUnit.id },
                });
              }}
              size="small"
              Icon={EditIcon}
              iconStyle={{ fill: Colors.DODGER_BLUE }}
            />
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default LeaseInventoryDraftRow;
