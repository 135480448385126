/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-unused-prop-types */
import { Button, Divider, FormControl, InputLabel, MenuItem, Select, Typography, makeStyles } from '@material-ui/core';
import { ActionButton } from '@up2rent/ui';
import isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { blobToFile, dataURItoBlob, getFileBlobFromUrl } from '../../hooks/FilesContext';
import TextButton from './TextButton';
// @ts-ignore
import UndoOutlined from '@material-ui/icons/UndoOutlined';
import { Skeleton } from '@material-ui/lab';
import { Colors, EmptyFileWithUrl, S3Object } from '@rentguru/commons-utils';
import { isEqual } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import ImgMarkup from 'react-img-markup';
import Dialog from 'src/components/ui/Dialog';

const useStyles = makeStyles({
  paperWidthFalse: {
    borderRadius: 10,
    maxWidth: '90%',
    position: 'unset',
  },
  formControl: {
    minWidth: 120,
  },
  formControlSmall: {
    minWidth: 50,
  },
  rootDisplay: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
});

const shapes = [
  {
    value: 'line',
    legend: <div style={{ width: 10, height: 1, backgroundColor: Colors.DARK_SLATE_GREY }} />,
  },
  {
    value: 'rect',
    legend: <div style={{ width: 10, height: 10, border: `1px solid ${Colors.DARK_SLATE_GREY}` }} />,
  },
  {
    value: 'ellipse',
    legend: <div style={{ width: 10, height: 10, borderRadius: 15, border: `1px solid ${Colors.DARK_SLATE_GREY}` }} />,
  },
  {
    value: 'arrow',
    legend: (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ width: 5, height: 1, backgroundColor: Colors.DARK_SLATE_GREY }} />
        <div
          style={{
            width: 0,
            height: 0,
            borderTop: '5px solid transparent',
            borderBottom: '5px solid transparent',
            borderLeft: `5px solid ${Colors.DARK_SLATE_GREY}`,
          }}
        />
      </div>
    ),
  },
  {
    value: 'text',
    legend: <Typography style={{ fontSize: 12, color: Colors.DARK_SLATE_GREY }}>aA</Typography>,
  },
];
const colors = [
  Colors.RED_ORANGE,
  Colors.STRAWBERRY_PINK,
  Colors.ELECTRIC_VIOLET,
  Colors.RIBBON_BLUE,
  Colors.CYAN_BLUE,
  Colors.SCREAMING_GREEN,
  Colors.FIZZ_YELLOW,
  Colors.CARROT_ORANGE,
  Colors.CLASSICAL_WHITE,
  Colors.CLASSICAL_BLACK,
];
const shapeSizes = [
  {
    value: 2,
    legend: <div style={{ width: 15, height: 1, backgroundColor: Colors.DARK_SLATE_GREY }} />,
  },
  {
    value: 4,
    legend: <div style={{ width: 15, height: 2, backgroundColor: Colors.DARK_SLATE_GREY }} />,
  },
  {
    value: 6,
    legend: <div style={{ width: 15, height: 3, backgroundColor: Colors.DARK_SLATE_GREY }} />,
  },
  {
    value: 8,
    legend: <div style={{ width: 15, height: 4, backgroundColor: Colors.DARK_SLATE_GREY }} />,
  },
  {
    value: 10,
    legend: <div style={{ width: 15, height: 5, backgroundColor: Colors.DARK_SLATE_GREY }} />,
  },
];
const textSizes = [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72];

interface PictureAnnotatorProps {
  file: EmptyFileWithUrl | S3Object | null;
  open: boolean;
  editFunction: (oldFile: EmptyFileWithUrl | S3Object, newFile: File) => void;
  onClose: () => void;
  style?: React.CSSProperties;
}

interface ImgMarkupProps {
  activeType: string;
  activeColor: string;
  activeStrokeWidth: number;
  activeFontSize: number;
  setActiveType: (type: string) => void;
  setActiveColor: (color: string) => void;
  setActiveStrokeWidth: (width: number) => void;
  setActiveFontSize: (fontSize: number) => void;
  undo: () => void;
  deletePath: (id: string) => void;
  save: () => void;
  activePathId: (id: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  imgMarkupModifiers: any;
}

const PictureAnnotatorDialog: React.FC<PictureAnnotatorProps> = ({ file, open, editFunction, onClose, style }) => {
  const classes = useStyles();
  const [fileUrl, setFileUrl] = useState('');
  const [imgAnnotatorFunctionBundle, setImgAnnotatorFunctionBundle] = useState<
    Omit<ImgMarkupProps, 'activeType' | 'activeColor' | 'activeStrokeWidth' | 'activeFontSize'> | undefined
  >(undefined);
  const [imgAnnotatorVariablesBundle, setImgAnnotatorVariableBundle] = useState<
    Pick<ImgMarkupProps, 'activeType' | 'activeColor' | 'activeStrokeWidth' | 'activeFontSize'>
  >({ activeColor: Colors.RED_ORANGE, activeFontSize: 36, activeStrokeWidth: 4, activeType: 'line' });
  const { formatMessage } = useIntl();
  useEffect(() => {
    const getPublicUrl = async (file: EmptyFileWithUrl | S3Object) => {
      let fileUrl: string;
      if ('file' in file) {
        fileUrl = file.url;
      } else {
        // @ts-ignore
        fileUrl = URL.createObjectURL(await getFileBlobFromUrl(file.url));
      }
      return fileUrl;
    };
    if (!isNil(file)) {
      getPublicUrl(file).then((url) => setFileUrl(url));
    }
  }, [file, setFileUrl]);

  if (isNil(file)) return null;

  const markupDefaultValues = {
    color: Colors.RED_ORANGE,
    fontSize: 36,
    strokeWidth: 4,
    type: 'line',
    text: 'insert text',
  };

  const saveAnnotatedPicture = (fileUri: string) => {
    const annotatedBlob = dataURItoBlob(fileUri);
    const fileName = 'file' in file ? file.file.name : file!.fileName;
    const splittedFilename = fileName.split('.');
    splittedFilename[splittedFilename.length - 1] = 'jpeg';
    const newFilename = splittedFilename.join('.');
    const annotatedFile = blobToFile(annotatedBlob, newFilename);
    editFunction(file, annotatedFile);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paperWidthFalse }} style={style}>
      {isEmpty(fileUrl) && (
        <div style={{ display: 'flex', justifyContent: 'center', width: 500, height: 500 }}>
          <Skeleton />
        </div>
      )}
      {!isEmpty(fileUrl) && (
        <>
          <div style={{ alignItems: 'center', maxHeight: 500, overflow: 'auto' }}>
            <ImgMarkup
              imgSrc={fileUrl}
              imgStyles={{ maxHeight: 500 }}
              onSave={saveAnnotatedPicture}
              defaultValues={markupDefaultValues}
            >
              {({
                activeType,
                activeColor,
                activeStrokeWidth,
                activeFontSize,
                setActiveType,
                setActiveColor,
                setActiveStrokeWidth,
                setActiveFontSize,
                undo,
                deletePath,
                save,
                activePathId,
                imgMarkupModifiers,
              }: ImgMarkupProps) => {
                const variablesBundle = { activeType, activeColor, activeStrokeWidth, activeFontSize };
                const functionsBundle = {
                  setActiveType,
                  setActiveColor,
                  setActiveStrokeWidth,
                  setActiveFontSize,
                  undo,
                  deletePath,
                  save,
                  activePathId,
                  imgMarkupModifiers,
                };
                if (isNil(imgAnnotatorFunctionBundle)) {
                  setImgAnnotatorFunctionBundle(functionsBundle);
                }
                if (!isEqual(imgAnnotatorVariablesBundle, variablesBundle)) {
                  setImgAnnotatorVariableBundle(variablesBundle);
                }
                return null;
              }}
            </ImgMarkup>
          </div>

          {!isNil(imgAnnotatorFunctionBundle) && (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  marginTop: 10,
                  marginLeft: 10,
                  marginRight: 10,
                }}
                ref={imgAnnotatorFunctionBundle.imgMarkupModifiers}
              >
                <FormControl className={classes.formControlSmall}>
                  <InputLabel id="color-label">{formatMessage({ id: 'leaseInventory.editFile.color' })}</InputLabel>
                  <Select
                    labelId="color-label"
                    value={imgAnnotatorVariablesBundle.activeColor}
                    onChange={(e) => imgAnnotatorFunctionBundle.setActiveColor(e.target.value as string)}
                    classes={{ root: classes.rootDisplay }}
                  >
                    {colors.map((color) => {
                      return (
                        <MenuItem key={color} value={color}>
                          <div style={{ borderRadius: 10, width: 10, height: 10, backgroundColor: color }} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl className={classes.formControlSmall}>
                  <InputLabel id="shape-label">{formatMessage({ id: 'leaseInventory.editFile.shape' })}</InputLabel>
                  <Select
                    labelId="shape-label"
                    value={imgAnnotatorVariablesBundle.activeType}
                    onChange={(e) => imgAnnotatorFunctionBundle.setActiveType(e.target.value as string)}
                    classes={{ root: classes.rootDisplay }}
                  >
                    {shapes.map((shape) => {
                      return (
                        <MenuItem key={shape.value} value={shape.value}>
                          {shape.legend}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {imgAnnotatorVariablesBundle.activeType !== 'text' && (
                  <FormControl className={classes.formControlSmall}>
                    <InputLabel id="shape-size-label">
                      {formatMessage({ id: 'leaseInventory.editFile.shapeSize' })}
                    </InputLabel>
                    <Select
                      labelId="shape-size-label"
                      value={imgAnnotatorVariablesBundle.activeStrokeWidth}
                      onChange={(e) => imgAnnotatorFunctionBundle.setActiveStrokeWidth(e.target.value as number)}
                      classes={{ root: classes.rootDisplay }}
                    >
                      {shapeSizes.map((shapeSize) => {
                        return (
                          <MenuItem key={shapeSize.value} value={shapeSize.value}>
                            {shapeSize.legend}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
                {imgAnnotatorVariablesBundle.activeType === 'text' && (
                  <FormControl className={classes.formControlSmall}>
                    <InputLabel id="text-size-label">
                      {formatMessage({ id: 'leaseInventory.editFile.textSize' })}
                    </InputLabel>
                    <Select
                      labelId="text-size-label"
                      value={imgAnnotatorVariablesBundle.activeFontSize}
                      onChange={(e) => imgAnnotatorFunctionBundle.setActiveFontSize(e.target.value as number)}
                      classes={{ root: classes.rootDisplay }}
                    >
                      {textSizes.map((textSize) => {
                        return (
                          <MenuItem key={`text ${textSize}`} value={textSize}>
                            <Typography style={{ fontSize: 14 }}>{textSize}</Typography>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
                <Button onClick={imgAnnotatorFunctionBundle.undo} style={{ padding: 0 }}>
                  <UndoOutlined style={{ fill: Colors.DARK_SLATE_GREY }} />
                  <Typography
                    variant="h6"
                    style={{
                      fontSize: 14,
                      color: Colors.DARK_SLATE_GREY,
                    }}
                  >
                    {formatMessage({ id: 'undo' })}
                  </Typography>
                </Button>
              </div>

              <Divider style={{ marginBottom: 20, marginTop: 10 }} />
              <div
                style={{
                  marginLeft: 30,
                  marginRight: 30,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingBottom: 20,
                }}
              >
                <TextButton style={{ fontSize: 11, letterSpacing: 1.1, fontWeight: 800 }} onClick={onClose}>
                  {formatMessage({ id: 'cancel' })}
                </TextButton>
                <ActionButton
                  style={{ minWidth: 120, height: 50, fontSize: 14, fontWeight: 800 }}
                  onClick={() => imgAnnotatorFunctionBundle.save()}
                >
                  {formatMessage({ id: 'leaseInventory.editFile.saveAnnotation' })}
                </ActionButton>
              </div>
            </>
          )}
        </>
      )}
    </Dialog>
  );
};

export default PictureAnnotatorDialog;
