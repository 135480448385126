import React, { useState } from 'react';
import { ElevatedPaper, ContentHeader, ActionButton , MenuItemText } from '@up2rent/ui';
import { useIntl } from 'react-intl';
import { Typography, Menu, MenuItem, Divider, Grid } from '@material-ui/core';
import Dialog from 'src/components/ui/Dialog';
import { useBankAccounts } from 'src/hooks/BankAccountsContext';
import { AddEditBankAccountDialog } from 'src/components/ui/Dialogs/AddEditBankAccountDialog';
import { useTransactions } from 'src/hooks/TransactionsContext';
import BankAccountRow from './BankAccounts/BankAccountRow';
import DeleteBankAccount from './BankAccounts/DeleteBankAccount';
import { BankAccount } from '@rentguru/commons-utils';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

export interface AddEditBankMenuBundle {
  anchorEl: HTMLElement;
  bankAccount: BankAccount;
}

const BankAccountsSettings: React.FC = () => {
  const { formatMessage } = useIntl();
  const { bankAccounts, loading, error, updateBankAccount } = useBankAccounts();
  const { transactionsLoading } = useTransactions();
  const [actionMenu, setActionMenu] = useState<AddEditBankMenuBundle | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<BankAccount | null>(null);
  const [addEditDialogOpen, setAddEditDialogOpen] = useState<BankAccount | boolean>(false);

  if (loading || transactionsLoading) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{formatMessage({ id: 'loading' })}</Typography>
      </ElevatedPaper>
    );
  }
  if (error) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{error}</Typography>
      </ElevatedPaper>
    );
  }

  // Only charges
  const [enabledBankAccounts, disabledBankAccounts] = bankAccounts.reduce(
    (acc: [BankAccount[], BankAccount[]], bankAccount) => {
      if (bankAccount.disabled) acc[1].push(bankAccount);
      else {
        acc[0].push(bankAccount);
      }

      return acc;
    },
    [[], []]
  );

  const closeAddEditDialog = () => {
    setAddEditDialogOpen(false);
  };

  return (
    <>
      <ElevatedPaper style={{ margin: 20, maxWidth: 900 }}>
        <ContentHeader title={formatMessage({ id: 'settings.bankAccountsMenu' })}>
          <ActionButton onClick={() => setAddEditDialogOpen(true)}>{formatMessage({ id: 'add' })}</ActionButton>
        </ContentHeader>
        <div style={{ paddingLeft: 30, paddingRight: 30 }}>
          {enabledBankAccounts.map((bankAccount, index) => (
            <BankAccountRow
              key={bankAccount.id}
              bankAccount={bankAccount}
              openMenu={setActionMenu}
              divider={index !== enabledBankAccounts.length - 1}
            />
          ))}
          {!isEmpty(disabledBankAccounts) && (
            <>
              <Typography
                variant="h6"
                style={{ marginTop: 30, marginBottom: 15, fontSize: 14, fontWeight: 700, textAlign: 'left' }}
              >
                {formatMessage({ id: 'settings.deleteBankAccount.disabledBankAccounts' })}
              </Typography>
              <Divider style={{ marginLeft: -30, marginRight: -30 }} />
              {disabledBankAccounts.map((bankAccount, index) => (
                <BankAccountRow
                  key={bankAccount.id}
                  bankAccount={bankAccount}
                  openMenu={setActionMenu}
                  divider={index !== disabledBankAccounts.length - 1}
                  disabled={true}
                />
              ))}
            </>
          )}
        </div>
      </ElevatedPaper>
      {actionMenu && (
        <Menu
          id="action-menu"
          anchorEl={actionMenu.anchorEl}
          open={Boolean(actionMenu)}
          onClose={() => setActionMenu(null)}
          style={{ transform: 'translateY(-20px)' }}
        >
          {!actionMenu.bankAccount.disabled && (
            <Grid>
              <MenuItem
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setAddEditDialogOpen(actionMenu.bankAccount);
                  setActionMenu(null);
                }}
              >
                <MenuItemText primary={formatMessage({ id: 'edit' })} />
              </MenuItem>
              <MenuItem
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setDeleteDialogOpen(actionMenu.bankAccount);
                  setActionMenu(null);
                }}
              >
                <MenuItemText primary={formatMessage({ id: 'delete' })} deleteOption />
              </MenuItem>
            </Grid>
          )}
          {actionMenu.bankAccount.disabled && (
            <>
              <MenuItem
                onClick={async (event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  await updateBankAccount(actionMenu.bankAccount, { disabled: undefined });
                  setActionMenu(null);
                }}
              >
                <MenuItemText primary={formatMessage({ id: 'enable' })} />
              </MenuItem>
            </>
          )}
        </Menu>
      )}
      {!isNil(deleteDialogOpen) && (
        <Dialog
          open={!isNil(deleteDialogOpen)}
          onClose={() => setDeleteDialogOpen(null)}
          scroll="body"
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DeleteBankAccount afterSubmit={() => setDeleteDialogOpen(null)} bankAccount={deleteDialogOpen} />
        </Dialog>
      )}
      {addEditDialogOpen && (
        <AddEditBankAccountDialog
          open={!isNil(addEditDialogOpen)}
          id={typeof addEditDialogOpen !== 'boolean' ? addEditDialogOpen.id : undefined}
          onClose={closeAddEditDialog}
        />
      )}
    </>
  );
};

export default BankAccountsSettings;
