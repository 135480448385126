import { isNil } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { ConfirmDialog } from '@up2rent/ui';
import { useAgencyRates } from 'src/hooks/AgencyRatesContext';

interface DeleteOwnerFromAgencyRateProps {
  onClose: () => void;
  id: string;
}

const DeleteOwnerFromAgencyRate: React.FC<DeleteOwnerFromAgencyRateProps> = ({ onClose, id }) => {
  const { formatMessage } = useIntl();
  const { loading, getAgencyRateOwner, deleteAgencyRateOwner } = useAgencyRates();

  const agencyRateOwner = getAgencyRateOwner(id);

  if (loading || !agencyRateOwner) {
    return null;
  }

  return (
    <ConfirmDialog
      open={!isNil(id)}
      mainText={formatMessage({ id: 'agencyRates.deleteAgencyRateOwner.message' })}
      confirmText={formatMessage({ id: 'delete' })}
      confirmAction={async () => {
        await deleteAgencyRateOwner(agencyRateOwner);
        onClose();
      }}
      cancelAction={onClose}
      autofocus
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      formatMessage={formatMessage}
    />
  );
};

export default DeleteOwnerFromAgencyRate;
