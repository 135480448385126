import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { CSSProperties } from 'react';

interface DashboardLoaderProps {
  width?: number | string;
  height?: number | string | undefined;
  marginBottom?: number | string | undefined;
  style?: CSSProperties;
  skeletonStyle?: CSSProperties;
}

const DashboardLoader: React.FC<DashboardLoaderProps> = ({
  width = 422,
  height = 422,
  marginBottom = 20,
  style,
  skeletonStyle,
}) => {
  return (
    <Box
      style={{
        flex: '1 1 45%',
        boxSizing: 'border-box',
        paddingLeft: '.5%',
        minWidth: 0,
        height,
        width,
        marginBottom,
        ...style,
      }}
    >
      <Skeleton style={{ height: '100%', width: '99%', transform: 'none', ...skeletonStyle }} />
    </Box>
  );
};

export default DashboardLoader;
