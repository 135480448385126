/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { Divider, Grid, Menu, MenuItem, Typography } from '@material-ui/core';
import { isEmpty, isNil } from 'lodash';
import React, { useState } from 'react';
import { IntlFormatters, useIntl } from 'react-intl';
import {
  EnhancedTableScrollable,
  EnhancedTableSharedContextProvider,
  ActionButtonSecond,
  EnhanceTableColumn,
  ActionButton,
 MenuItemText } from '@up2rent/ui';
import { useSendingSources } from 'src/hooks/SendingSourceContext';
import DomainRow, { getButtonName } from './DomainRow';
import { SelectedDomainAction } from '../SendingSourceSettings';
import {
  SendingSource,
  SendingSourceErrorType,
  SendingSourceStatus,
  SendingSourceVerifiedType,
  DEFAULT_SENDING_EMAIL,
  getDefaultSendingSource,
} from '@rentguru/commons-utils';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { ReactComponent as NoResultIcon } from 'src/icons/noResult.svg';

const columns: EnhanceTableColumn[] = [
  {
    id: 'empty',
    labelId: '',
    numeric: false,
    style: { width: 50, paddingLeft: 0, paddingRight: 0 },
  },
  {
    id: 'name',
    labelId: 'settings.sendingSources.domain',
    numeric: false,
    style: { width: 230, paddingLeft: 0 },
  },
  {
    id: 'status',
    labelId: 'settings.sendingSources.status.status',
    numeric: false,
    style: { width: 260 },
  },
  {
    id: 'actions',
    labelId: 'settings.sendingSources.actions',
    numeric: false,
    style: { paddingLeft: 0 },
  },
];

const getCurrentEmailMessage = (formatMessage: IntlFormatters['formatMessage'], activeDomain?: SendingSource) => {
  if (activeDomain?.status === SendingSourceStatus.ERROR) {
    return formatMessage(
      { id: 'settings.sendingSources.currentEmailCrash' },
      { name: activeDomain.name.toUpperCase(), defaultEmail: DEFAULT_SENDING_EMAIL }
    );
  }
  if (activeDomain?.status === SendingSourceStatus.PENDING) {
    if (activeDomain.errorMessage === SendingSourceErrorType.CRASH) {
      return formatMessage(
        { id: 'settings.sendingSources.currentEmailPendingCrash' },
        { emailAddress: activeDomain.emailAddress, defaultEmailAddress: DEFAULT_SENDING_EMAIL }
      );
    }
    return `${formatMessage(
      { id: 'settings.sendingSources.currentEmail' },
      { emailAddress: DEFAULT_SENDING_EMAIL }
    )} ${formatMessage(
      { id: 'settings.sendingSources.currentEmailPending' },
      { emailAddress: activeDomain.emailAddress }
    )}`;
  }
  return formatMessage(
    { id: 'settings.sendingSources.currentEmail' },
    { emailAddress: activeDomain?.emailAddress ?? DEFAULT_SENDING_EMAIL }
  );
};

interface DomainsTableProps {
  setSelectedDomainActions: (action: SelectedDomainAction) => void;
}

const DomainsTable: React.FC<DomainsTableProps> = ({ setSelectedDomainActions }) => {
  const { formatMessage } = useIntl();
  const { getSendingSource, sendingSources, sendingSourcesLoading, sendingSourcesError } = useSendingSources();
  const { settingsAutomationWrite } = usePermissions();

  const [menuEvent, setMenuEvent] = useState<null | {
    anchor: HTMLElement | null;
    id: string;
  }>(null);

  if (sendingSourcesLoading || isNil(sendingSources)) {
    return <Typography style={{ marginTop: 30, marginBottom: 30 }}>{formatMessage({ id: 'loading' })}</Typography>;
  }
  if (sendingSourcesError) {
    return <Typography>{sendingSourcesError}</Typography>;
  }
  if (isEmpty(sendingSources)) {
    return (
      <Typography style={{ paddingTop: 20, paddingBottom: 20 }}>
        {formatMessage({ id: 'settings.sendingSources.noDomain' })}
      </Typography>
    );
  }

  const defaultSendingSource = getDefaultSendingSource(sendingSources);

  const handleActionMenu = (anchor: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    setMenuEvent({ anchor: anchor as unknown as HTMLElement, id });
  };

  const handleDetailClick = (id: string) => {
    const detailedSendingSource = getSendingSource(id)!;
    if (detailedSendingSource.status === SendingSourceStatus.ERROR) {
      handleErrorClick(id);
    }
    if (
      detailedSendingSource.status === SendingSourceStatus.NONE ||
      detailedSendingSource.status === SendingSourceStatus.PENDING
    ) {
      handleDnsClick(id);
    }
  };

  const handleDeleteClick = () => {
    handleCloseMenu();
    setSelectedDomainActions({ action: 'DELETE', id: selectedSendingSource?.id! });
  };

  const handleDnsClick = (id: string) => {
    handleCloseMenu();
    setSelectedDomainActions({ action: 'DNS', id });
  };

  const handleErrorClick = (id: string) => {
    handleCloseMenu();
    setSelectedDomainActions({ action: 'ERROR', id });
  };

  const handleVerifiedDnsClick = () => {
    handleCloseMenu();
    setSelectedDomainActions({ action: 'VERIFIED', id: selectedSendingSource?.id! });
  };

  const handleMakeDefaultClick = () => {
    handleCloseMenu();
    setSelectedDomainActions({ action: 'DEFAULT', id: selectedSendingSource?.id! });
  };

  const handleResetClick = () => {
    handleCloseMenu();
    setSelectedDomainActions({ action: 'RESET', id: selectedSendingSource?.id! });
  };

  const handleCloseMenu = () => {
    setMenuEvent(null);
  };

  const selectedSendingSource = sendingSources.find((sendingSource) => sendingSource.id === menuEvent?.id);
  const displayDnsMenuItem = selectedSendingSource?.status === SendingSourceStatus.VERIFIED;
  const displayMakeDefaultMenuItem =
    selectedSendingSource?.status === SendingSourceStatus.VERIFIED &&
    selectedSendingSource?.verifiedType === SendingSourceVerifiedType.NONE;

  const showInnerButton = defaultSendingSource?.status !== SendingSourceStatus.VERIFIED;
  const showResetButton =
    defaultSendingSource?.status === SendingSourceStatus.VERIFIED ||
    (defaultSendingSource?.status === SendingSourceStatus.PENDING &&
      defaultSendingSource?.errorMessage === SendingSourceErrorType.CRASH);

  return (
    <>
      <Grid
        style={{
          marginLeft: 30,
          marginRight: 30,
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          style={{
            fontWeight: 'bold',
            marginTop: 20,
            width: defaultSendingSource ? 510 : 570,
            textAlign: defaultSendingSource ? 'left' : 'center',
          }}
        >
          {getCurrentEmailMessage(formatMessage, defaultSendingSource)}
        </Typography>
        {defaultSendingSource && settingsAutomationWrite && (
          <Grid style={{ textAlign: 'right' }}>
            {showResetButton && (
              <ActionButtonSecond
                onClick={handleResetClick}
                style={{ marginTop: 15, width: 'max-content', textTransform: 'none' }}
              >
                {formatMessage({ id: 'settings.sendingSources.defaultReset' })}
              </ActionButtonSecond>
            )}
            {showInnerButton && (
              <ActionButton
                onClick={() => {
                  handleDetailClick(defaultSendingSource.id);
                }}
                style={{ marginLeft: 10, marginTop: 15, textTransform: 'none' }}
              >
                {getButtonName(
                  defaultSendingSource.status as SendingSourceStatus,
                  defaultSendingSource.verifiedType as SendingSourceVerifiedType,
                  formatMessage
                )}
              </ActionButton>
            )}
          </Grid>
        )}
      </Grid>

      <Divider style={{ marginTop: 20 }} />
      <EnhancedTableSharedContextProvider
        value={{ orderBy: 'name' }}
        datas={sendingSources}
        handleActionMenu={handleActionMenu}
        handleDetail={handleDetailClick}
      >
        <EnhancedTableScrollable
          NoResultIcon={<NoResultIcon />}
          formatMessage={formatMessage}
          datas={sendingSources}
          columns={columns.filter((column) => column.id !== 'actions' || settingsAutomationWrite)}
          defaultOrderBy="name"
          rowComponent={DomainRow}
          noMinWidth
        />
      </EnhancedTableSharedContextProvider>
      {selectedSendingSource && settingsAutomationWrite && (
        <Menu id="action-menu" anchorEl={menuEvent?.anchor} open={Boolean(menuEvent?.anchor)} onClose={handleCloseMenu}>
          <MenuItem onClick={handleDeleteClick}>
            <MenuItemText primary={formatMessage({ id: 'delete' })} deleteOption />
          </MenuItem>
          {displayDnsMenuItem && (
            <MenuItem onClick={handleVerifiedDnsClick}>
              <MenuItemText primary={formatMessage({ id: 'settings.sendingSources.dns.records' })} />
            </MenuItem>
          )}
          {displayMakeDefaultMenuItem && (
            <MenuItem onClick={handleMakeDefaultClick}>
              <MenuItemText primary={formatMessage({ id: 'settings.sendingSources.defaultMenu' })} />
            </MenuItem>
          )}
        </Menu>
      )}
    </>
  );
};

export default DomainsTable;
