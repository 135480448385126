import { Grid, MenuItem, Typography, makeStyles } from '@material-ui/core';
import { CertificateOfConformityType, Colors } from '@rentguru/commons-utils';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import CustomizedSelect from '../CustomizedSelectWithImportance';
import { EditAdvertisementFormValues } from '../EditPublicationForm';

const useLocalStyles = makeStyles({
  rootSelect: {
    '& > *': {
      width: 580,
    },
  },
  title: {
    flex: 1,
    textAlign: 'left' as const,
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Mulish',
    fontSize: '16px',
    fontWeight: 700,
    margin: '10px 0 10px 30px',
  },
});

const ElectricalInstallationEdit = () => {
  const { formatMessage } = useIntl();
  const classes = useLocalStyles();
  const { values, setFieldValue } = useFormikContext<EditAdvertisementFormValues>();

  return (
    <>
      <Typography variant="h6" className={classes.title}>
        {formatMessage({ id: 'rentalUnit.detail.energy.energy' })}
      </Typography>

      <Grid container style={{ justifyContent: 'space-evenly', margin: '15px 0 15px 0' }}>
        <Grid item>
          <CustomizedSelect
            label={formatMessage({ id: 'rentalUnit.detail.energy.electrivalInstallation.conformity' })}
            fieldName="electricalInstallation"
            value={values.advertisement.certificateOfConformity}
            className={classes.rootSelect}
            error={false}
          >
            {Object.keys(CertificateOfConformityType).map((type) => (
              <MenuItem
                id={type}
                key={type}
                value={type}
                onClick={() => setFieldValue('advertisement.certificateOfConformity', type)}
              >
                {formatMessage({ id: `rentalUnit.detail.energy.heating.certificateOfConformityType.${type}` })}
              </MenuItem>
            ))}
          </CustomizedSelect>
        </Grid>
        <Grid item style={{ width: 280 }} />
      </Grid>
    </>
  );
};

export default ElectricalInstallationEdit;
