/* eslint-disable func-names */
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Formik, FormikHelpers, Form } from 'formik';
import * as Yup from 'yup';
import { isNil, isEmpty } from 'lodash';
import { parsePhoneNumberFromString, CountryCode as PhoneCountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { Typography, TextField, Collapse, Divider, Link, Grid } from '@material-ui/core';
import {
  handleDisposableEmail,
  getCountryCodeFromDialCode,
  Colors,
  getPrivacyPolicyUrl,
  getGeneralConditionsUrl,
  PhoneNumber,
  isNilOrEmpty,
} from '@rentguru/commons-utils';
import { AuthProps, FormType } from 'src/App';
import { onChangeToLowerCaseFieldValue } from 'src/utils/formutils';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { useUser } from 'src/hooks/UserContext';
import { ElevatedPaper, LoaderButton } from '@up2rent/ui';
import { useStyles } from 'src/components/ui/TextDetailEditable';
import PhoneNumbersFields from 'src/components/ui/Forms/FormField/PhoneNumbersFields';
import CustomCheckBox from 'src/components/ui/CustomCheckBox';
import { authStyles } from './authStyles';

export const parseInvalidPasswordException = (e: Error) => {
  if (e.message.indexOf('Password not long') !== -1) return 'InvalidPasswordExceptionLong';
  if (e.message.indexOf('Password must have upper') !== -1) return 'InvalidPasswordExceptionUpper';
  if (e.message.indexOf('Password must have lower') !== -1) return 'InvalidPasswordExceptionLower';
  if (e.message.indexOf('Password must have number') !== -1) return 'InvalidPasswordExceptionNumeric';
  return e.message;
};

export const GENERAL_CONDITIONS_VERSION = 'v1.0';
export const GDPR_VERSION = 'v1.0';

export const parseInvalidParameterException = (e: Error) => {
  if (e.message.indexOf("Value at 'password' failed") !== -1) return 'InvalidParameterExceptionPassword';
  return e.message;
};

const INVALID_PHONE_NUMBER_FORMAT = 'The format of this phone number is not valid';
const SignupSchema = Yup.object().shape({
  email: Yup.string().min(1).required(),
  password: Yup.string()
    .required()
    .matches(
      // eslint-disable-next-line max-len
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One special Character'
    ),
  confirmPassword: Yup.string().test('sameAsPassword', 'Passwords should be the same', function (value) {
    // keep function for this.parent below
    const { password } = this.parent;
    return !isNil(value) && password === value;
  }),
  name: Yup.string().min(1).required(),
  familyName: Yup.string().min(1).required(),
  phoneNumber: Yup.string().test('validPhoneNumber', INVALID_PHONE_NUMBER_FORMAT, function (value) {
    if (isNil(value)) return true;
    const parsedPhoneNumber = parsePhoneNumberFromString(this.parent.countryCode + value);
    return !isNil(parsedPhoneNumber) && parsedPhoneNumber.isValid();
  }),
  countryCode: Yup.string(),
  conditionsAccepted: Yup.bool().oneOf([true]),
});

interface SignupFormValues {
  email: string;
  password: string;
  confirmPassword: string;
  name: string;
  familyName: string;
  phoneNumbers: Omit<PhoneNumber, 'id' | 'clientId' | 'readId' | 'contactId'>[];
  defaultErrorMessage?: string;
  conditionsAccepted: boolean;
}

const Signup: React.FC<AuthProps> = ({ updateFormType }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { signUp, tempEmail } = useUser();
  const { language } = useLocale();
  const [showMailSignup, setMailSignup] = useState(!!tempEmail);

  const styles = authStyles();

  const initialValues: SignupFormValues = {
    email: tempEmail || '',
    password: '',
    confirmPassword: '',
    name: '',
    familyName: '',
    phoneNumbers: [
      {
        countryCode: '',
        number: '',
      },
    ],
    conditionsAccepted: false,
  };

  const handleSubmit = async (
    values: SignupFormValues,
    { setSubmitting, setErrors, setStatus, setTouched }: FormikHelpers<SignupFormValues>
  ) => {
    if (!showMailSignup) {
      setMailSignup(true);
      setSubmitting(false);
      // since formik has already been submitted before creating the account,
      // all fields have touched set to true instead of default false.
      // this creates falsy error handling hence the following setTouched to set things back to default
      setTouched({
        confirmPassword: false,

        email: false,
        familyName: false,
        name: false,
        password: false,
      });
    } else {
      let isCorrectEmail: boolean = true;
      if (!values.email.includes('gmail.com' && 'hotmail.com' && 'yahoo.com')) {
        const result = await handleDisposableEmail(values.email)!;

        if (!isNil(result) && !isNil(result.disposable) && result.disposable === true) {
          setErrors({
            email: 'disposable mail',
            defaultErrorMessage: formatMessage({ id: 'signup.disposableEmail' }),
          });
          isCorrectEmail = false;
          setStatus(false);
          setSubmitting(false);
        } else if ((!isNil(result) && isNil(result.dns)) || (!isNil(result) && result.dns === false)) {
          setErrors({ email: 'no dns', defaultErrorMessage: formatMessage({ id: 'signup.noDnsEmail' }) });
          isCorrectEmail = false;
          setStatus(false);
          setSubmitting(false);
        }
      }
      if (isCorrectEmail) {
        try {
          const rawPhoneNumber = values.phoneNumbers[0].number;
          let parsedPhoneNumber;
          if (!isNilOrEmpty(rawPhoneNumber)) {
            try {
              parsedPhoneNumber = parsePhoneNumber(
                values.phoneNumbers[0].number as string,
                getCountryCodeFromDialCode(values.phoneNumbers[0].countryCode) as PhoneCountryCode
              );
              parsedPhoneNumber = parsedPhoneNumber.number as string;
            } catch (err) {
              console.error(err);
            }
          }
          const phoneNumber = !isEmpty(parsedPhoneNumber) ? (parsedPhoneNumber as string) : '';

          await signUp(values.email, values.password, values.name, values.familyName, phoneNumber);
          setSubmitting(false);
          setStatus(true);
        } catch (error) {
          const err: Error = error as Error;
          console.error('err', err);

          if (err.name === 'UsernameExistsException') {
            setErrors({ email: err.name, defaultErrorMessage: formatMessage({ id: 'signup.emailAlreadyUsedError' }) });
          } else if (err.name === 'InvalidPasswordException') {
            setErrors({ password: parseInvalidPasswordException(err), defaultErrorMessage: err.message });
          } else if (err.name === 'InvalidParameterException') {
            setErrors({ password: parseInvalidParameterException(err), defaultErrorMessage: err.message });
          } else {
            setErrors({ email: err.name, defaultErrorMessage: err.message });
          }

          setStatus(false);
          setSubmitting(false);
        }
      }
    }
  };

  return (
    <Grid alignContent="center" alignItems="center" container direction="column">
      <Formik
        initialValues={initialValues}
        validationSchema={showMailSignup ? SignupSchema : null}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, isSubmitting, status, setFieldValue }) => {
          return (
            <ElevatedPaper className={styles.mainContainer}>
              <Form>
                <Typography variant="h5" paragraph component="h2" gutterBottom>
                  {formatMessage({ id: 'signup.createAccountEmail' })}
                </Typography>
                <Collapse in={showMailSignup}>
                  <TextField
                    label={formatMessage({ id: 'signup.emailLabel' })}
                    id="email"
                    type="email"
                    name="email"
                    autoComplete="email"
                    margin="normal"
                    variant="filled"
                    fullWidth
                    value={values.email}
                    onChange={(event) => {
                      onChangeToLowerCaseFieldValue(event, 'email', setFieldValue);
                    }}
                    onBlur={handleBlur}
                    required={showMailSignup}
                    error={Boolean(errors.email && touched.email)}
                    helperText={errors.email && touched.email ? errors.defaultErrorMessage : ''}
                    InputProps={{ classes: { underline: classes.underline } }}
                    InputLabelProps={{ className: classes.label }}
                  />
                  <TextField
                    label={formatMessage({ id: 'signup.passwordLabel' })}
                    id="password"
                    type="password"
                    autoComplete="current-password"
                    margin="normal"
                    variant="filled"
                    fullWidth
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={showMailSignup}
                    error={Boolean(errors.password && touched.password)}
                    helperText={
                      errors.password && touched.password
                        ? formatMessage({
                            id: `error.${errors.password}`,
                            defaultMessage: errors.defaultErrorMessage
                              ? errors.defaultErrorMessage
                              : formatMessage({ id: 'signup.passwordHelp' }),
                          })
                        : formatMessage({ id: 'signup.passwordHelp' })
                    }
                    InputProps={{ classes: { underline: classes.underline } }}
                    InputLabelProps={{ className: classes.label }}
                  />
                  <TextField
                    label={formatMessage({ id: 'signup.confirmPasswordLabel' })}
                    id="confirmPassword"
                    type="password"
                    autoComplete="current-password"
                    margin="normal"
                    variant="filled"
                    fullWidth
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={showMailSignup}
                    error={Boolean(errors.confirmPassword && touched.confirmPassword)}
                    helperText={
                      errors.confirmPassword && touched.confirmPassword
                        ? formatMessage({ id: 'error.confirmationPassword' })
                        : ''
                    }
                    InputProps={{ classes: { underline: classes.underline } }}
                    InputLabelProps={{ className: classes.label }}
                  />
                  <TextField
                    label={formatMessage({ id: 'signup.nameLabel' })}
                    id="name"
                    type="text"
                    margin="normal"
                    variant="filled"
                    fullWidth
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={showMailSignup}
                    error={Boolean(errors.name && touched.name)}
                    InputProps={{ classes: { underline: classes.underline } }}
                    InputLabelProps={{ className: classes.label }}
                  />
                  <TextField
                    label={formatMessage({ id: 'signup.familyNameLabel' })}
                    id="familyName"
                    type="text"
                    margin="normal"
                    variant="filled"
                    fullWidth
                    value={values.familyName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={showMailSignup}
                    error={Boolean(errors.familyName && touched.familyName)}
                    InputProps={{ classes: { underline: classes.underline } }}
                    InputLabelProps={{ className: classes.label }}
                  />
                  <PhoneNumbersFields noClearButton marginLeftRight={0} />
                </Collapse>
                <Divider style={{ margin: '15px -40px 15px -40px' }} />
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <CustomCheckBox
                    onCheck={handleChange}
                    isChecked={values.conditionsAccepted}
                    name="conditionsAccepted"
                    secondary
                  />
                  <div
                    style={{
                      color: errors.conditionsAccepted ? Colors.CARNATION_RED : Colors.CLASSICAL_BLACK,
                      marginLeft: 10,
                      textAlign: 'left',
                    }}
                  >
                    <Typography style={{ display: 'inline-block', fontSize: 14, fontWeight: 600 }}>
                      {formatMessage({ id: 'signup.readAndAccept' })}
                    </Typography>
                    <Link href={getPrivacyPolicyUrl(language)} target="_blank">
                      <Typography
                        style={{
                          display: 'inline-block',
                          fontSize: 14,
                          fontWeight: 600,
                          color: Colors.DODGER_BLUE,
                          marginLeft: 5,
                        }}
                      >
                        {` ${formatMessage({ id: 'signup.privacyPolicy' })} `}
                      </Typography>
                    </Link>
                    <Typography
                      style={{ display: 'inline-block', fontSize: 14, fontWeight: 600, marginLeft: 5, marginRight: 5 }}
                    >
                      {` ${formatMessage({ id: 'rentalUnit.deleteUnit.and' })} `}
                    </Typography>
                    <Link href={getGeneralConditionsUrl(language)} target="_blank">
                      <Typography
                        style={{
                          display: 'inline-block',
                          fontWeight: 600,
                          fontSize: 14,
                          color: Colors.DODGER_BLUE,
                        }}
                      >
                        {` ${formatMessage({ id: 'signup.generalConditions' })} `}
                      </Typography>
                    </Link>
                  </div>
                </div>
                <LoaderButton loading={isSubmitting} success={status} style={{ marginTop: 16 }}>
                  {formatMessage({ id: showMailSignup ? 'signup.registerButton' : 'signup.registerViaEmail' })}
                </LoaderButton>
              </Form>
            </ElevatedPaper>
          );
        }}
      </Formik>
      <Link onClick={() => updateFormType(FormType.SIGN_IN)}>
        <Typography style={{ cursor: 'pointer' }}>{formatMessage({ id: 'login.connectWithMail' })}</Typography>
      </Link>
    </Grid>
  );
};

export default Signup;
