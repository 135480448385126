import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { User, Colors } from '@rentguru/commons-utils';
import { ReactComponent as ContactSvg } from 'src/icons/contact.svg';
import { ReactComponent as Team } from 'src/icons/team.svg';
import { ReactComponent as ExternalIcon } from 'src/icons/external.svg';
import { RouteDestination } from 'src/components/Routes/Routes';
import GeneralDetails from 'src/components/Contacts/Details/GeneralDetails';
import TextDetail from 'src/components/ui/TextDetail';
import { CustomIconButton } from '@up2rent/ui';
import UserRoleField from 'src/components/Settings/Members/UserRoleField';
import clsx from 'clsx';

const useStyles = makeStyles({
  headerContent: { display: 'flex', flexDirection: 'row', marginTop: 10 },
  headerContentRole: { alignItems: 'center' },
  typography: { color: Colors.CLASSICAL_BLACK, fontSize: 16, fontWeight: 500 },
  teamList: { display: 'flex', flexDirection: 'column' },
  button: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  divider: { marginTop: 30, marginBottom: 30, marginLeft: -30, marginRight: -30 },
});
interface MemberDetailProps {
  user: User;
}
const MemberDetail: React.FC<MemberDetailProps> = ({ user }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      <Grid style={{ flexGrow: 1, marginRight: 8 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextDetail title={formatMessage({ id: 'contact.columnHeader.type' })}>
              <Grid className={clsx(classes.headerContent, classes.headerContentRole)}>
                {!user.rootUser ? (
                  <>
                    <ContactSvg style={{ fill: Colors.TOWER_GREY, paddingRight: 5 }} />
                    <Typography className={classes.typography}>{user.userRole ? user.userRole.name : ''}</Typography>
                    <CustomIconButton
                      size="small"
                      onClick={() =>
                        history.push({
                          pathname: `${RouteDestination.SETTINGS}/roles/${
                            user.userRole ? user.userRole.id : ''
                          }/permissions`,
                          state: { goBackUrl: history.location },
                        })
                      }
                      className={classes.button}
                      Icon={ExternalIcon}
                      iconStyle={{ fill: Colors.DODGER_BLUE, marginLeft: '5px' }}
                    />
                  </>
                ) : (
                  <UserRoleField user={user} />
                )}
              </Grid>
            </TextDetail>
          </Grid>
          <Grid item xs={6}>
            <TextDetail title={formatMessage({ id: 'settings.team' })}>
              {user.teams && (
                <Grid className={classes.headerContent}>
                  <Team style={{ paddingRight: 5 }} />
                  <Grid className={classes.teamList}>
                    {user.teams.map((team) => (
                      <Typography key={team.id} className={classes.typography}>
                        {team.team!.name}
                      </Typography>
                    ))}
                  </Grid>
                </Grid>
              )}
            </TextDetail>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
      </Grid>
      {user.contact && <GeneralDetails id={user.contact.id} />}
    </>
  );
};

export default MemberDetail;
