import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import isNil from 'lodash/isNil';
import LeaseChargeAdjustments from './LeaseChargeAdjustments/LeaseChargeAdjustments';
import TenantStatementsToValidate from './TenantStatementsToValidate/TenantStatementsToValidate';
import TenantStatementsArchived from './TenantStatementsArchived/TenantStatementsArchived';
import { StatementContext } from 'src/hooks/StatementContext';
import { Typography } from '@material-ui/core';
import TenantStatementsFiltersComponent from './TenantStatementsFilters';
import {
  Contact,
  Statement,
  Unit,
  ContactType,
  LeasePriceHistory,
  UpdateLeasePriceHistoryInput,
} from '@rentguru/commons-utils';
import { LeaseExtended, useLeases } from 'src/hooks/LeasesContext';
import { useIntl } from 'react-intl';
import { RouteDestination } from 'src/components/Routes/Routes';

const TO_PREFIX = '';

export const defaultTenantStatementsRoute = {
  to: `${TO_PREFIX}`,
  component: TenantStatementsToValidate,
  filterComponent: TenantStatementsFiltersComponent,
};

export interface TenantStatementExtended extends Statement {
  units: Unit[];
  tenants: Contact[];
}

export interface LeaseChargeAdjustementExtended extends LeasePriceHistory {
  tenants: Contact[];
}

export const tenantStatementsNavItems = [
  {
    to: `${TO_PREFIX}toValidate`,
    labelId: 'accounting.statement.toValidate',
    component: TenantStatementsToValidate,
    filterComponent: TenantStatementsFiltersComponent,
  },
  {
    to: `${TO_PREFIX}archived`,
    labelId: 'tickets.menu.archived',
    component: TenantStatementsArchived,
    filterComponent: TenantStatementsFiltersComponent,
  },
  {
    to: `${TO_PREFIX}provisionsAdjustments`,
    labelId: 'accounting.statement.provisionAdjustments',
    component: LeaseChargeAdjustments,
    filterComponent: TenantStatementsFiltersComponent,
  },
];

export const extendTenantStatement = (
  tenantStatement: Statement,
  getLease: (id: string) => LeaseExtended | undefined | null
): TenantStatementExtended => {
  const statementLease = tenantStatement.lease;
  let statementUnits: Unit[] = [];
  let statementTenants: Contact[] = [];
  if (statementLease) {
    const completeLease = getLease(statementLease.id);
    if (completeLease) {
      statementTenants = completeLease.contacts
        ? completeLease.contacts.reduce((acc: Contact[], leaseContact) => {
            if (leaseContact.contactRole === ContactType.TENANT && leaseContact.contact) {
              acc.push(leaseContact.contact);
            }
            return acc;
          }, [])
        : [];
      statementUnits = completeLease.units
        ? completeLease.units.reduce((acc: Unit[], unitLease) => {
            if (unitLease.unit) acc.push(unitLease.unit);
            return acc;
          }, [])
        : [];
    }
  }
  return { ...tenantStatement, units: statementUnits, tenants: statementTenants };
};

export const extendChargeAdjustment = (
  chargeAdjustment: LeasePriceHistory,
  getLease: (id: string) => LeaseExtended | undefined | null
): LeaseChargeAdjustementExtended => {
  const chargeAdjustmentLease = chargeAdjustment.lease;
  let statementTenants: Contact[] = [];
  let completeLease: LeaseExtended | undefined | null;
  if (chargeAdjustmentLease) {
    completeLease = getLease(chargeAdjustmentLease.id);
    if (completeLease) {
      statementTenants = completeLease.contacts
        ? completeLease.contacts.reduce((acc: Contact[], leaseContact) => {
            if (leaseContact.contactRole === ContactType.TENANT && leaseContact.contact) {
              acc.push(leaseContact.contact);
            }
            return acc;
          }, [])
        : [];
    }
  }
  return { ...chargeAdjustment, tenants: statementTenants, lease: completeLease ?? chargeAdjustment.lease };
};

interface TenantStatementsProps
  extends Pick<
    StatementContext,
    | 'tenantStatementsToCheck'
    | 'tenantStatementsArchived'
    | 'statementsLoading'
    | 'createStatement'
    | 'updateStatement'
    | 'deleteStatement'
    | 'recalculateStatement'
  > {
  leaseChargeAdjustmentsTodos: LeasePriceHistory[];
  leaseChargeAdjustmentsLoading: boolean;
  updateLeaseChargeAdjustment: (updates: UpdateLeasePriceHistoryInput) => Promise<LeasePriceHistory>;
}

const TenantStatements: React.FC<TenantStatementsProps> = ({
  tenantStatementsToCheck,
  tenantStatementsArchived,
  leaseChargeAdjustmentsTodos,
  leaseChargeAdjustmentsLoading,
  statementsLoading,
  updateStatement,
  deleteStatement,
  updateLeaseChargeAdjustment,
  recalculateStatement,
}) => {
  const { formatMessage } = useIntl();
  const { getLease, leasesLoading } = useLeases();
  const match = useRouteMatch<{ section?: string; tabName?: string }>({
    path: `${RouteDestination.ACCOUNTING}/:section?/:tabName?`,
  });

  if (isNil(match)) {
    return null;
  }
  if (statementsLoading || leasesLoading) {
    return <Typography>{formatMessage({ id: 'loading' })}</Typography>;
  }

  const tenantStatementsToCheckExtended = tenantStatementsToCheck.map((ts) => extendTenantStatement(ts, getLease));
  const tenantStatementsArchivedExtended = tenantStatementsArchived.map((ts) => extendTenantStatement(ts, getLease));
  const leaseChargeAdjustmentsTodosExtended = leaseChargeAdjustmentsTodos.map((l) =>
    extendChargeAdjustment(l, getLease)
  );
  return (
    <Switch>
      {tenantStatementsNavItems.map((m) => {
        const path = match.path.replace(':tabName?', m.to);
        const Comp = m.component;
        return (
          <Route
            path={path}
            key={m.to}
            exact={true}
            render={() => {
              return (
                <Comp
                  tenantStatementsToCheck={tenantStatementsToCheckExtended}
                  tenantStatementsArchived={tenantStatementsArchivedExtended}
                  leaseChargeAdjustmentsTodos={leaseChargeAdjustmentsTodosExtended}
                  leaseChargeAdjustmentsLoading={leaseChargeAdjustmentsLoading}
                  updateStatement={updateStatement}
                  deleteStatement={deleteStatement}
                  updateLeaseChargeAdjustment={updateLeaseChargeAdjustment}
                  recalculateStatement={recalculateStatement}
                />
              );
            }}
          />
        );
      })}
      <Route
        path="/accounting/tenantStatements"
        key="default-charges"
        render={() => {
          return (
            <defaultTenantStatementsRoute.component
              tenantStatementsToCheck={tenantStatementsToCheckExtended}
              recalculateStatement={recalculateStatement}
              updateStatement={updateStatement}
              deleteStatement={deleteStatement}
            />
          );
        }}
      />
    </Switch>
  );
};

export default TenantStatements;
