/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import Tooltip from '@material-ui/core/Tooltip';
// eslint-disable-next-line import/named
import { File, Colors } from '@rentguru/commons-utils';
import { ActionButtonSecond } from '../buttons';
import { CustomTableCell } from './CustomTableCell';
import { StyledCheckbox } from '../checkboxes';
import { SortLabel } from './SortLabel';
import { TablePlaceHolder } from './TablePlaceHolder';
import { EnhancedTableSharedContextConsumer } from './EnhancedTableSharedContext';
import { getSorting, stableSort } from '../../utils';
import { type IntlFormatters } from 'react-intl';

export interface EnhanceTableColumn {
  id: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  displayFunction?: Function;
  checkbox?: boolean;
  empty?: boolean;
  sortable?: boolean;
  numeric?: boolean;
  disablePadding?: boolean;
  labelId: string;
  sortField?: string;
  style?: React.CSSProperties;
  infoTextMessageId?: string;
}

export interface EnhanceTableRowLooseData {
  id: string;
  [key: string]: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface EnhanceTableProps {
  noMinWidth?: boolean;
  datas: EnhanceTableRowLooseData[];
  columns: EnhanceTableColumn[];
  rowComponent: any; // à changer !!!!!!!!!!!!
  footerComponent?: React.ComponentType<EnhanceTableFooterRowProps>;
  footerProps?: EnhanceTableFooterRowProps;
  defaultOrderBy: string;
  placeHolder?: React.ReactNode;
  filtersSelected?: boolean;
  resetFiltersFunction?: () => void;
  fileArray?: File[];
  NoResultIcon: React.ReactNode;
  formatMessage: IntlFormatters['formatMessage'];
}

export interface EnhanceTableShareContextValues {
  handleClick: (e: any, id: string) => void;
  createSortHandler: (column: EnhanceTableColumn) => void;
  order: string;
  orderBy: string;
  orderBySortField?: string | null;
  orderByColumnType: string;
  numSelected: number;
  handleSelectAllClick: (event: any) => void;
  selected: string[];
  handleUnselect?: (ids: string[]) => void;
  handleDetail: (id: string) => void;
  handleActionMenu: (e: any, id: string) => void;
}

export interface EnhanceTableRowProps {
  isSelected: boolean;
  handleSelect?: (event: any, id: string) => void;
  handleDetail?: (id: string) => void;
  actionMenu?: (e: any, id: string) => void;
  formatMessage?: IntlFormatters['formatMessage'];
  filtersSelected?: boolean;
  [key: string]: any;
}

export interface EnhanceTableFooterRowProps {
  id: string;
  [key: string]: any;
}

export const EnhancedTable = ({
  // piste 1
  noMinWidth = false,
  datas,
  columns,
  rowComponent: RowComponent,
  footerComponent: FooterComponent,
  footerProps = { id: '' },
  defaultOrderBy,
  placeHolder = null,
  filtersSelected = false,
  resetFiltersFunction = () => {},
  fileArray,
  NoResultIcon,
  formatMessage,
}: EnhanceTableProps) => {
  const rowCount = datas.length;
  return (
    <EnhancedTableSharedContextConsumer>
      {({
        handleClick,
        createSortHandler,
        order,
        orderBy,
        orderBySortField,
        orderByColumnType,
        numSelected,
        handleSelectAllClick,
        selected,
        handleDetail,
        handleActionMenu,
      }: EnhanceTableShareContextValues) => {
        let orderByColumn = isEmpty(orderBy) ? defaultOrderBy : orderBy;

        let orderByColumnFunction;
        columns.forEach((c) => {
          if (c.id === orderByColumn && !isNil(c.displayFunction)) {
            orderByColumnFunction = c.displayFunction;
          }
        });
        orderByColumn = !isNil(orderBySortField) ? orderBySortField : orderByColumn;
        const stabledOrder = stableSort(
          datas,
          getSorting(order as unknown as 'asc' | 'desc', orderByColumn, orderByColumnFunction, orderByColumnType)
        );
        return (
          <Table style={{ minWidth: noMinWidth ? 0 : 1020, overflowX: 'auto' }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  if (!isNil(column.checkbox) && column.checkbox) {
                    return (
                      <CustomTableCell
                        key={column.id}
                        padding="checkbox"
                        {...(column.style && { style: column.style })}
                      >
                        <StyledCheckbox
                          indeterminate={numSelected > 0 && numSelected < rowCount}
                          checked={numSelected === rowCount && rowCount !== 0}
                          onChange={handleSelectAllClick}
                        />
                      </CustomTableCell>
                    );
                  }
                  if (!isNil(column.empty) && column.empty) {
                    return (
                      <CustomTableCell
                        key={column.id}
                        padding="checkbox"
                        {...(column.style && { style: column.style })}
                      />
                    );
                  }
                  if (!isNil(column.sortable) && column.sortable) {
                    return (
                      <CustomTableCell
                        key={column.id}
                        align={column.numeric ? 'right' : 'inherit'}
                        padding={column.disablePadding ? 'none' : 'normal'}
                        {...(column.style && { style: column.style })}
                      >
                        <Tooltip
                          title="Sort"
                          placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                          enterDelay={300}
                        >
                          <SortLabel
                            active={
                              (isNil(orderBySortField) && orderByColumn === column.id) ||
                              (!isNil(orderBySortField) && orderByColumn === column.sortField)
                            }
                            direction={order}
                            onClick={() => {
                              createSortHandler(column);
                            }}
                          >
                            {formatMessage({ id: column.labelId })}
                          </SortLabel>
                        </Tooltip>
                      </CustomTableCell>
                    );
                  }
                  return (
                    <CustomTableCell
                      key={column.id}
                      align={column.numeric ? 'right' : 'inherit'}
                      padding={column.disablePadding ? 'none' : 'normal'}
                      {...(column.style && { style: column.style })}
                    >
                      {isNil(column.labelId) || isEmpty(column.labelId) ? (
                        ''
                      ) : (
                        <Typography style={{ color: Colors.BLUE_GREY }}>
                          {formatMessage({ id: column.labelId })}
                        </Typography>
                      )}
                    </CustomTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {stabledOrder.map((data: EnhanceTableRowLooseData) => {
                const isSelected = (selected as string[]).indexOf(data.id) !== -1;
                return (
                  <RowComponent // piste 2
                    key={data.id}
                    isSelected={isSelected}
                    {...data}
                    handleSelect={handleClick}
                    handleDetail={handleDetail}
                    actionMenu={handleActionMenu}
                    formatMessage={formatMessage}
                    fileArray={fileArray}
                    filtersSelected={filtersSelected}
                  />
                );
              })}
              {!datas ||
                (datas.length === 0 && (
                  <TableRow style={{ height: 49 }}>
                    <CustomTableCell colSpan={columns.length}>
                      {filtersSelected ? (
                        <TablePlaceHolder
                          Icon={NoResultIcon}
                          mainText={formatMessage({ id: 'noResult' })}
                          subText=""
                          actionButton={
                            <ActionButtonSecond onClick={resetFiltersFunction}>
                              {formatMessage({ id: 'reinitFilterLong' })}
                            </ActionButtonSecond>
                          }
                        />
                      ) : (
                        placeHolder
                      )}
                    </CustomTableCell>
                  </TableRow>
                ))}
              {FooterComponent && <FooterComponent id={footerProps.id} />}
            </TableBody>
          </Table>
        );
      }}
    </EnhancedTableSharedContextConsumer>
  );
};
