import { isNil } from 'lodash';
import { Permissions } from 'src/hooks/utils/PermissionsContext';
import { RouteDestination } from './Routes';

export const isMenuVisibleWithRight = (menuLink: RouteDestination, permissions: Partial<Permissions>): boolean => {
  if (menuLink.startsWith(RouteDestination.DASHBOARD)) {
    return true;
  }

  if (menuLink.startsWith(RouteDestination.COMMUNICATIONS)) {
    return (
      (!isNil(permissions.communicationsDetailsRead) && permissions.communicationsDetailsRead) ||
      (!isNil(permissions.communicationsDetailsWrite) && permissions.communicationsDetailsWrite) ||
      (!isNil(permissions.communicationsDetailsDelete) && permissions.communicationsDetailsDelete)
    );
  }

  if (menuLink.startsWith(RouteDestination.ACCOUNTING)) {
    return (
      (!isNil(permissions.financialTransactionsRead) && permissions.financialTransactionsRead) ||
      (!isNil(permissions.financialTransactionsWrite) && permissions.financialTransactionsWrite) ||
      (!isNil(permissions.financialTransactionsDelete) && permissions.financialTransactionsDelete)
    );
  }

  if (menuLink.startsWith(RouteDestination.PROPERTIES) || menuLink.startsWith(RouteDestination.LEASES)) {
    return (
      (!isNil(permissions.buildingsUnitsDetailsRead) && permissions.buildingsUnitsDetailsRead) ||
      (!isNil(permissions.buildingsUnitsDetailsWrite) && permissions.buildingsUnitsDetailsWrite) ||
      (!isNil(permissions.buildingsUnitsDetailsDelete) && permissions.buildingsUnitsDetailsDelete) ||
      (!isNil(permissions.buildingsUnitsTechnicsRead) && permissions.buildingsUnitsTechnicsRead) ||
      (!isNil(permissions.buildingsUnitsTechnicsWrite) && permissions.buildingsUnitsTechnicsWrite) ||
      (!isNil(permissions.buildingsUnitsTechnicsDelete) && permissions.buildingsUnitsTechnicsDelete) ||
      (!isNil(permissions.buildingsUnitsUtilityProvidersRead) && permissions.buildingsUnitsUtilityProvidersRead) ||
      (!isNil(permissions.buildingsUnitsUtilityProvidersWrite) && permissions.buildingsUnitsUtilityProvidersWrite) ||
      (!isNil(permissions.buildingsUnitsUtilityProvidersDelete) && permissions.buildingsUnitsUtilityProvidersDelete)
    );
  }

  if (menuLink.startsWith(RouteDestination.TEMPLATES)) {
    return (
      (!isNil(permissions.templatesContractsRead) && permissions.templatesContractsRead) ||
      (!isNil(permissions.templatesContractsWrite) && permissions.templatesContractsWrite) ||
      (!isNil(permissions.templatesCommunicationsRead) && permissions.templatesCommunicationsRead) ||
      (!isNil(permissions.templatesCommunicationsWrite) && permissions.templatesCommunicationsWrite)
    );
  }

  if (menuLink.startsWith(RouteDestination.CONTACTS)) {
    return !isNil(permissions.contactsDetailsRead) && permissions.contactsDetailsRead;
  }

  if (menuLink.startsWith(RouteDestination.TICKETS) || menuLink.startsWith(RouteDestination.TICKETS_DETAILS)) {
    return (
      (!isNil(permissions.ticketsDetailsRead) && permissions.ticketsDetailsRead) ||
      (!isNil(permissions.ticketsDetailsWrite) && permissions.ticketsDetailsWrite) ||
      (!isNil(permissions.ticketsDetailsDelete) && permissions.ticketsDetailsDelete)
    );
  }

  if (
    menuLink.startsWith(RouteDestination.ADD_PROPERTY) ||
    menuLink.startsWith(RouteDestination.EDIT_PROPERTY) ||
    menuLink.startsWith(RouteDestination.ADD_UNIT) ||
    menuLink.startsWith(RouteDestination.EDIT_UNIT)
  ) {
    return !isNil(permissions.buildingsUnitsDetailsWrite) && permissions.buildingsUnitsDetailsWrite;
  }

  if (menuLink.startsWith(RouteDestination.ADD_LEASE) || menuLink.startsWith(RouteDestination.EDIT_LEASE)) {
    return !isNil(permissions.leasesCreationWrite) && permissions.leasesCreationWrite;
  }

  if (menuLink.startsWith(RouteDestination.ADD_TICKET)) {
    return !isNil(permissions.ticketsDetailsWrite) && permissions.ticketsDetailsWrite;
  }

  if (
    menuLink.startsWith(RouteDestination.ADD_TRANSACTION) ||
    menuLink.startsWith(RouteDestination.ADD_CHARGE) ||
    menuLink.startsWith(RouteDestination.AFFECT_CHARGE) ||
    menuLink.startsWith(RouteDestination.CHARGE_SUMMARY) ||
    menuLink.startsWith(RouteDestination.RECONCILE_TRANSACTION) ||
    menuLink.startsWith(RouteDestination.VALIDATE_TENANT_STATEMENT) ||
    menuLink.startsWith(RouteDestination.VALIDATE_OWNER_STATEMENT)
  ) {
    return !isNil(permissions.financialTransactionsWrite) && permissions.financialTransactionsWrite;
  }

  if (menuLink.startsWith(RouteDestination.SETTINGS)) {
    return true;
  }

  if (menuLink.startsWith(RouteDestination.EDIT_INVENTORY)) {
    return !isNil(permissions.inventoryOfFixturesCreationWrite) && permissions.inventoryOfFixturesCreationWrite;
  }

  if (menuLink.startsWith(RouteDestination.EDIT_FURNITURE_INVENTORY)) {
    return !isNil(permissions.furnituresInventoryCreationWrite) && permissions.furnituresInventoryCreationWrite;
  }

  if (menuLink.startsWith(RouteDestination.EDIT_ADVERTISEMENT)) {
    return !isNil(permissions.advertisementsDetailsWrite) && permissions.advertisementsDetailsWrite;
  }

  if (menuLink.startsWith(RouteDestination.EDIT_SIGNATURES)) {
    return !isNil(permissions.signatureDetailsWrite) && permissions.signatureDetailsWrite;
  }

  if (menuLink.startsWith(RouteDestination.INDEXATION)) {
    return !isNil(permissions.indexationCreationWrite) && permissions.indexationCreationWrite;
  }

  if (menuLink.startsWith(RouteDestination.DOCUMENTS)) {
    return !isNil(permissions.documents) && permissions.documents;
  }

  return false;
};
