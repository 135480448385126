import { Grid, Avatar, Typography } from '@material-ui/core';
import { getContactNameFromObject, stringToColor, getFirstLettersUppercase } from '@rentguru/commons-utils';
import React from 'react';
import { useContacts } from 'src/hooks/ContactsContext';

interface SummaryContactLineProps {
  arrayOfContacts: { id: string }[];
}

const SummaryContactLine: React.FC<SummaryContactLineProps> = ({ arrayOfContacts }) => {
  const { getContact } = useContacts();

  return (
    <Grid>
      {arrayOfContacts.map((contact, index) => {
        const completeContact = getContact(contact.id);
        const name = getContactNameFromObject(completeContact);
        const color = stringToColor(name);
        return (
          <Grid
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: index === arrayOfContacts.length - 1 ? 0 : 10,
            }}
            key={contact.id}
          >
            <Grid item xs={10} style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                style={{
                  color: `${color}`,
                  backgroundColor: 'rgba(0,0,0,0)',
                  border: `1.5px ${color} solid`,
                  fontSize: '10px',
                  fontWeight: 'bold',
                  width: 20,
                  height: 20,
                  marginRight: 10,
                }}
              >
                {getFirstLettersUppercase(name)}
              </Avatar>
              <Typography
                style={{
                  fontSize: '13px',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  width: 'calc(100% - 15px)',
                  display: 'block',
                  overflow: 'hidden',
                  textAlign: 'left',
                }}
              >
                {name}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default SummaryContactLine;
