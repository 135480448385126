/* eslint-disable react/no-array-index-key */
import React, { ReactElement, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { useStyles } from './TemplateEditor';

interface SplitButtonProps {
  options: string[];
  disabled?: boolean;
  aliases: ReactElement[];
  defaultIndex?: number;
  selectedAliases?: ReactElement[];
  onOptionClick: (opt: string) => void;
}

const SplitButton: React.FC<SplitButtonProps> = ({
  options,
  aliases,
  defaultIndex = 0,
  selectedAliases,
  onOptionClick,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex);
  const classes = useStyles();

  const handleMenuItemClick = (_event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid container direction="column" alignItems="center" style={{ zIndex: 3 }}>
      <ButtonGroup fullWidth ref={anchorRef} aria-label="split button" classes={{ root: classes.buttonGroupRoot }}>
        <Button
          disabled={disabled}
          variant="outlined"
          classes={{ root: classes.buttonRoot, disabled: classes.buttonTextDisabled }}
          onClick={() => onOptionClick(options[selectedIndex])}
          onMouseDown={(e) => {
            e.preventDefault();
          }}
        >
          {selectedAliases ? selectedAliases[selectedIndex] : aliases[selectedIndex]}
        </Button>
        <Button
          disabled={disabled}
          variant="outlined"
          style={{ width: 24 }}
          classes={{ root: classes.buttonRoot, disabled: classes.buttonTextDisabled }}
          onClick={handleToggle}
          onMouseDown={(e) => {
            e.preventDefault();
          }}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper open={open} style={{ zIndex: 1 }} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {aliases.map((alias, index) => (
                    <MenuItem
                      key={index}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                      onMouseDown={(e) => {
                        e.preventDefault();
                      }}
                      className={classes.itemRoot}
                    >
                      {alias}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
};

export default SplitButton;
