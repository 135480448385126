import React from 'react';
import { useFormikContext, FormikProps } from 'formik';
import { useIntl } from 'react-intl';
import ComboBox, { CustomizedComboBoxProps, CustomMenuItemType } from '../../ComboBox/TextComboBox';
import isNil from 'lodash/isNil';
import { format } from 'date-fns';
import { dateLocaleMap, dateLocaleMapType, useLocale } from '../../../../i18n/IntlProviderWrapper';
import { formatStandardizedUTCDate, LeaseExtended } from '@rentguru/commons-utils';

export interface LeaseSelectorFormValues {
  lease: {
    id?: string;
  };
}
interface LeaseSelectorFieldProps
  extends Omit<CustomizedComboBoxProps, 'onChange' | 'initialValue' | 'label' | 'data'> {
  leases: LeaseExtended[];
  fieldName: string;
  initialValue?: string;
  style?: React.CSSProperties;
  onChange?: (event: string) => void;
}

const LeaseSelectorField: React.FC<LeaseSelectorFieldProps> = ({
  leases,
  fieldName,
  initialValue: _initialLeaseId,
  style: _style,
  onChange,
  ...rest
}) => {
  const { setFieldValue }: FormikProps<LeaseSelectorFormValues> = useFormikContext();
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const leasesMenuItems = leases.map((lease) => {
    return {
      primary: lease.name,
      secondary: `${format(new Date(lease.startDate), 'd/MM/yyyy', {
        locale: (dateLocaleMap as dateLocaleMapType)[language],
      })} - ${formatStandardizedUTCDate(new Date(lease.endDate), 'd/MM/yyyy', {
        locale: (dateLocaleMap as dateLocaleMapType)[language],
      })}`,
      value: lease.id,
    };
  });
  return (
    <ComboBox
      onChange={(_e: unknown, value: CustomMenuItemType | null) => {
        const safeValue = isNil(value) ? null : (value as CustomMenuItemType).value;
        setFieldValue(fieldName, safeValue);
        if (!isNil(onChange)) {
          onChange(safeValue);
        }
      }}
      data={leasesMenuItems}
      label={formatMessage({ id: 'lease.header.title' })}
      {...rest}
    />
  );
};

export default LeaseSelectorField;
