/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import isNil from 'lodash/isNil';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '@rentguru/commons-utils';
import { useDashboardFilters } from 'src/hooks/DashboardFiltersContext';
import { CustomIconButton , ElevatedPaper } from '@up2rent/ui';
import { Collapse, Typography } from '@material-ui/core';
import { stopPropagation } from 'src/components/Dashboard/Dashboard';

const useStyles = makeStyles(() =>
  createStyles({
    flexDiv: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
    },
    title: {
      flex: 1,
      textAlign: 'left',
      color: Colors.CLASSICAL_BLACK,
      fontFamily: 'Mulish',
      fontSize: '16px',
      fontWeight: 'bold',
      marginLeft: 20,
    },
    expansionText: {
      color: Colors.BLUE_GREY,
      cursor: 'pointer',
      textTransform: 'none',
    },
  })
);

interface NotificationBoxProps {
  boxKey: string;
  title?: string;
  titleComponent?: React.ReactNode;
  children?: React.ReactNode;
}

const NotificationBox: React.FC<NotificationBoxProps> = ({ boxKey, title, titleComponent, children }) => {
  const { layoutToDo, saveNewBoxToDoLayout } = useDashboardFilters();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const classes = useStyles();

  const expandIconStyle = (collapsed: boolean): object => ({
    transform: `rotate(${collapsed ? 180 : 0}deg)`,
    fill: Colors.DARK_SLATE_GREY,
  });

  useEffect(() => {
    const collapsed = layoutToDo.find((layout) => layout.i === boxKey)?.collapse;
    setCollapsed(collapsed ?? false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxKey]);

  return (
    <ElevatedPaper style={{ overflow: 'visible', margin: 0 }}>
      <div className={classes.flexDiv} style={{ marginBottom: 0 }}>
        {isNil(titleComponent) && <Typography className={classes.title}> {title} </Typography>}
        {!isNil(titleComponent) && titleComponent}
        <CustomIconButton
          onMouseDown={stopPropagation}
          style={{ fill: Colors.BLUEY, marginRight: 20 }}
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            saveNewBoxToDoLayout(boxKey, true);
            setCollapsed(!collapsed);
          }}
          Icon={ExpandMore}
          iconStyle={expandIconStyle(collapsed)}
        />
      </div>
      <Collapse in={!collapsed} mountOnEnter unmountOnExit>
        {children}
      </Collapse>
    </ElevatedPaper>
  );
};
export default NotificationBox;
