import { CommunicationSettingsProfileReferent } from '@rentguru/commons-utils';
import LeaseCommunicationSettingsForm from '../CommunicationSettingsProfileForm/LeaseCommunicationSettingsProfileForm';
import AddCommunicationSettingsProfileDialog from './AddCommunicationSettingsProfileDialog';
import {
  LeaseCommunicationSettingsProfileFormValues,
  defaultLeaseSettings,
} from '../CommunicationSettingsProfileUtils/LeaseCommunicationSettingsUtils';

const initialValues: LeaseCommunicationSettingsProfileFormValues = {
  defaultName: '',
  ...defaultLeaseSettings,
};

const AddLeaseCommunicationSettingsProfileDialog: React.FC<{ closeDialog: () => void }> = ({ closeDialog }) => (
  <AddCommunicationSettingsProfileDialog
    initialValues={initialValues}
    referent={CommunicationSettingsProfileReferent.LEASE}
    closeDialog={closeDialog}
    CustomForm={LeaseCommunicationSettingsForm}
  />
);
export default AddLeaseCommunicationSettingsProfileDialog;
