import { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import './LoadingScreen.css';
import random from 'lodash/random';
import LoadingProgress from './LoadingProgress';
import loadingLegends from './loadingLegends';

const LoadingScreen = () => {
  const legendIndex = random(0, loadingLegends.length - 1);
  const [legend] = useState(loadingLegends[legendIndex]);
  return (
    <Grid
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: '100vh',
      }}
    >
      <LoadingProgress loading={true} width={216} height={216} />
      <Typography variant="h4" className="pulsating">
        {legend}
      </Typography>
    </Grid>
  );
};

export default LoadingScreen;
