import { Form, Formik, FormikHelpers } from 'formik';
import { Colors, CommunicationSettingsProfileReferent } from '@rentguru/commons-utils';
import TextDetailEditable from 'src/components/ui/TextDetailEditable';
import { useIntl } from 'react-intl';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { DialogActions, DialogContent, Divider, Grid } from '@material-ui/core';
import { ActionButton, LoaderButton, ContentHeader } from '@up2rent/ui';
import { toUpper } from 'lodash';
import { useCommunicationSettingsProfiles } from 'src/hooks/CommunicationSettingsProfilesContext';
import { useSnackbar } from 'notistack';
import {
  CommunicationSettingsFormValues,
  getCommunicationSettingsFromValues,
  getCommunicationSettingsProfileSchema,
} from '../CommunicationSettingsProfileUtils/CommunicationSettingsCommonsUtils';

type AddCommunicationSettingsProfileDialogProps<CommunicationSettingsFormValues> = {
  initialValues: CommunicationSettingsFormValues;
  referent: CommunicationSettingsProfileReferent;
  closeDialog: () => void;
  CustomForm: React.FC<{ defaultCollapse?: boolean }>;
};

const AddCommunicationSettingsProfileDialog = <T extends CommunicationSettingsFormValues>({
  initialValues,
  referent,
  closeDialog,
  CustomForm,
}: AddCommunicationSettingsProfileDialogProps<T>) => {
  const { formatMessage } = useIntl();
  const { settingsAutomationWrite } = usePermissions();
  const { createCommunicationSettingsProfile } = useCommunicationSettingsProfiles();
  const { enqueueSnackbar } = useSnackbar();

  const schema = getCommunicationSettingsProfileSchema(referent);

  const handleCreate = async (values: T, { setSubmitting, setStatus }: FormikHelpers<T>) => {
    setSubmitting(true);
    const newSettings = getCommunicationSettingsFromValues(values, referent);

    await createCommunicationSettingsProfile({
      defaultName: values.defaultName,
      communicationsSettings: newSettings,
      isDefaultSetting: true,
      referent,
    });

    setStatus(true);
    setSubmitting(false);
    closeDialog();
    enqueueSnackbar(formatMessage({ id: 'settings.changesSaved' }), { variant: 'success' });
  };

  return (
    <Grid style={{ width: 600 }}>
      <ContentHeader title={formatMessage({ id: 'settings.communications.addProfile.title' })} />
      <Divider style={{ marginBottom: 20 }} />
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleCreate}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ isSubmitting, status, errors, touched }) => (
          <Form>
            <DialogContent style={{ height: 600, padding: 0 }}>
              <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                <TextDetailEditable
                  editMode
                  title={formatMessage({ id: 'name' })}
                  disabled={!settingsAutomationWrite}
                  name="defaultName"
                  type="string"
                  style={{ width: 300, fontSize: 12, marginLeft: 30, marginRight: 30 }}
                  error={Boolean(errors.defaultName && touched.defaultName)}
                />
              </Grid>

              <Grid style={{ marginLeft: 30, marginRight: 30 }}>
                <CustomForm defaultCollapse />
              </Grid>
            </DialogContent>

            <DialogActions style={{ padding: 0 }}>
              <Grid style={{ width: '100%' }}>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <Grid
                  style={{
                    marginRight: 30,
                    marginBottom: 20,
                    display: 'flex',
                    float: 'right',
                    alignItems: 'center',
                  }}
                >
                  <ActionButton
                    onClick={closeDialog}
                    style={{
                      background: 'none',
                      color: Colors.DARK_SLATE_GREY,
                      marginRight: 20,
                    }}
                  >
                    {toUpper(
                      formatMessage({
                        id: 'cancel',
                      })
                    )}
                  </ActionButton>
                  <LoaderButton loading={isSubmitting} success={status}>
                    {formatMessage({
                      id: 'settings.communications.addProfile.title',
                    })}
                  </LoaderButton>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default AddCommunicationSettingsProfileDialog;
