/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-param-last */
import React, { useContext, useEffect, useMemo } from 'react';
import {
  Notification,
  getNotification as getQuery,
  createNotification as createMutation,
  updateNotification as updateMutation,
  deleteNotification as deleteMutation,
  CreateNotificationMutationVariables as CreateMutationVariables,
  DeleteNotificationMutationVariables as DeleteMutationVariables,
  CreateNotificationInput as CreateInput,
  getReadId,
  getTableClientId,
  cleanInputUpdate,
  cleanInputCreate,
  Model,
  isNilOrEmpty,
  OnCreateNotificationSubscription,
  OnUpdateNotificationSubscription,
  OnDeleteNotificationSubscription,
  onCreateNotification,
  onDeleteNotification,
  onUpdateNotification,
} from '@rentguru/commons-utils';
import { useUser } from './UserContext';
import { ContextLoaderAction, ContextLoaderStore, useContextLoader } from './ContextLoader';
import {
  deleteEntityWithFetchBefore,
  getFilterFieldNameForIndex,
  list,
  mutation,
  recordWasUpdated,
  removeTechnicObject,
  useSubscriptions,
} from '@up2rent/fetch-utils';
import { syncNotifications } from './utils/cleanedQueries';

const ENTITY_MODEL_NAME: Model = 'Notification';

export type ActionNotification =
  | {
      type: 'SHOULD_FETCH_NOTIFICATION' | 'IS_FETCHING_NOTIFICATION';
    }
  | {
      type: 'ADD_NOTIFICATION';
      payload: { notification: Notification };
    }
  | {
      type: 'FETCHED_NOTIFICATION';
      payload: { notifications: Notification[] };
    }
  | {
      type: 'UPDATE_NOTIFICATION';
      payload: { notification: Notification };
    }
  | {
      type: 'DELETE_NOTIFICATION';
      payload: { id: string };
    };

export interface NotificationsContext {
  notifications: Notification[];
  getNotification: (id: string) => Notification | undefined;
  createNotification: (input: Omit<Notification, 'id' | 'clientId' | 'readId'>) => Promise<Notification>;
  updateNotification: (original: Notification, updates: Partial<Notification>) => Promise<Notification>;
  deleteNotification: (id: string) => Promise<void>;
  notificationsLoading: boolean;
  notificationsError: string | undefined;
  setFetchNotification: () => void;
}

export const notificationReducerDelegation = (
  state: ContextLoaderStore,
  action: ContextLoaderAction
): ContextLoaderStore => {
  switch (action.type) {
    case 'SHOULD_FETCH_NOTIFICATION':
      if (state.Notification.loading || state.Notification.shouldFetch) {
        return state;
      }
      return {
        ...state,
        Notification: { ...state.Notification, shouldFetch: true },
      };
    case 'IS_FETCHING_NOTIFICATION':
      if (state.Notification.loading) {
        return state;
      }
      return {
        ...state,
        Notification: { ...state.Notification, loading: true },
      };
    case 'FETCHED_NOTIFICATION':
      return {
        ...state,
        Notification: {
          ...state.Notification,
          data: action.payload.notifications,
          loading: false,
          shouldFetch: false,
          lastFetch: new Date(),
        },
      };
    case 'ADD_NOTIFICATION':
      // Check if already present - If already added by this user or coming from another user
      if (state.Notification.data?.find((object) => object.id === action.payload.notification.id)) {
        return state;
      }

      return {
        ...state,
        Notification: {
          ...state.Notification,
          data: [...state.Notification.data, action.payload.notification],
        },
      };
    case 'UPDATE_NOTIFICATION':
      // No data
      if (isNilOrEmpty(state.Notification.data)) {
        return state;
      }

      // Already present and same object
      const currentObject = state.Notification.data?.find((object) => object.id === action.payload.notification.id);
      if (!currentObject) {
        return {
          ...state,
          Notification: {
            ...state.Notification,
            data: [...state.Notification.data, action.payload.notification],
          },
        };
      }
      if (!recordWasUpdated(currentObject, action.payload.notification)) {
        return state;
      }

      // Update
      return {
        ...state,
        Notification: {
          ...state.Notification,
          data: state.Notification.data.map((object) => {
            if (object.id === action.payload.notification.id) {
              return action.payload.notification;
            }
            return object;
          }),
        },
      };
    case 'DELETE_NOTIFICATION':
      if (isNilOrEmpty(state.Notification.data)) {
        return state;
      }
      return {
        ...state,
        Notification: {
          ...state.Notification,
          data: state.Notification.data.filter((object) => object.id !== action.payload.id),
        },
      };

    default:
      return state;
  }
};

const fetchNotifications = async (clientId: string, additionalFilter?: object): Promise<Notification[]> => {
  return await list<Notification>(
    syncNotifications,
    getFilterFieldNameForIndex('byClientId'),
    getTableClientId(clientId, ENTITY_MODEL_NAME),
    additionalFilter
  );
};

export const NotificationsContext = React.createContext<NotificationsContext | null>(null);

export const NotificationsContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const {
    Notification: { data: notificationsLoader, loading, shouldFetch },
    Technic: { data: technicsLoader, loading: technicsLoading },
    dispatch: contextDispatch,
  } = useContextLoader();
  const { clientId } = useUser();

  const notificationsLoading = loading || technicsLoading;

  const notifications = useMemo<Notification[]>(() => {
    if (loading) {
      return [];
    }

    const notificationsReduced = notificationsLoader.reduce((acc: Notification[], notification: Notification) => {
      const technic = technicsLoader.find((currentTechnic) => currentTechnic.id === notification.technicId);
      if (notification.technicId && !technic) {
        return acc;
      }

      acc.push({
        ...notification,
        technic,
      });

      return acc;
    }, []);

    return notificationsReduced;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsLoader, technicsLoader, notificationsLoading]);

  const setFetchNotification = () => {
    contextDispatch({ type: 'SHOULD_FETCH_NOTIFICATION' });
  };

  useEffect(() => {
    const fetchAndSet = async () => {
      contextDispatch({ type: 'IS_FETCHING_NOTIFICATION' });
      const result = await fetchNotifications(clientId!);
      contextDispatch({ type: 'FETCHED_NOTIFICATION', payload: { notifications: result } });
    };
    if (shouldFetch) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch]);

  useSubscriptions<
    OnCreateNotificationSubscription,
    OnUpdateNotificationSubscription,
    OnDeleteNotificationSubscription
  >(
    removeTechnicObject(onCreateNotification),
    removeTechnicObject(onUpdateNotification),
    removeTechnicObject(onDeleteNotification),
    (data) => {
      contextDispatch({
        type: 'ADD_NOTIFICATION',
        payload: { notification: data.onCreateNotification as Notification },
      });
    },
    (data) => {
      contextDispatch({
        type: 'UPDATE_NOTIFICATION',
        payload: { notification: data.onUpdateNotification as Notification },
      });
    },
    (data) => {
      const { id } = data.onDeleteNotification as Notification;
      contextDispatch({
        type: 'DELETE_NOTIFICATION',
        payload: { id },
      });
    }
  );

  const getNotification = (id: string) => {
    return notifications.find((b) => b.id === id);
  };

  const createNotification = async (input: Omit<Notification, 'id' | 'clientId' | 'readId'>): Promise<Notification> => {
    const notification = await mutation<Notification, CreateMutationVariables>(removeTechnicObject(createMutation), {
      input: {
        ...(cleanInputCreate(input) as CreateInput),
        clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME),
        readId: getReadId(clientId!, ENTITY_MODEL_NAME),
      },
    });
    contextDispatch({ type: 'ADD_NOTIFICATION', payload: { notification } });
    return notification;
  };

  const updateNotification = async (original: Notification, updates: Partial<Notification>) => {
    const result = await mutation<Notification>(removeTechnicObject(updateMutation), {
      input: { ...cleanInputUpdate({ id: original.id, _version: original._version, ...updates }, false) },
    });
    contextDispatch({ type: 'UPDATE_NOTIFICATION', payload: { notification: result } });
    return result;
  };

  const deleteNotification = async (id: string) => {
    await deleteEntityWithFetchBefore<Pick<Notification, 'id'>, DeleteMutationVariables>(
      { id },
      removeTechnicObject(getQuery),
      removeTechnicObject(deleteMutation)
    );

    contextDispatch({ type: 'DELETE_NOTIFICATION', payload: { id } });
  };

  const values = useMemo(
    () => ({
      notifications,
      getNotification,
      createNotification,
      updateNotification,
      deleteNotification,
      notificationsError: undefined,
      notificationsLoading,
      setFetchNotification,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notifications, notificationsLoading]
  );

  return <NotificationsContext.Provider value={values}>{children}</NotificationsContext.Provider>;
};

export const useNotifications = (): NotificationsContext => {
  const context = useContext<NotificationsContext | null>(NotificationsContext);

  if (context === undefined) {
    throw new Error('`useNotifications` hook must be used within a `NotificationsContextProvider` component');
  }
  return context as NotificationsContext;
};
