/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-redeclare */
import React, { Reducer, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import {
  cleanInputCreate,
  cleanInputUpdate,
  getReadId,
  getTableClientId,
  EntityType,
  UnitInventory,
  UnitInventoryStructure,
  LeaseInventoryEncoding,
  LeaseInventoryCheckedItem,
  InventoryCustomItem,
  UnitInventoryStructureType,
  File as FileModel,
  syncUnitInventories as syncQuery,
  syncUnitInventoryStructures,
  createUnitInventory as createMutation,
  updateUnitInventory as updateMutation,
  CreateUnitInventoryMutationVariables as CreateMutationVariables,
  CreateUnitInventoryInput as CreateInput,
  updateUnitInventoryStructure as updateUnitInventoryStructureMutation,
  deleteUnitInventoryStructure as deleteUnitInventoryStructureMutation,
  updateUnitInventory as updateUnitInventoryMutation,
  deleteUnitInventory as deleteUnitInventoryMutation,
  createUnitInventoryStructure as createUnitInventoryStructureMutation,
  Model,
  isNilOrEmpty,
  CreateUnitInventoryStructureMutationVariables,
  OnCreateUnitInventorySubscription,
  OnUpdateUnitInventorySubscription,
  OnDeleteUnitInventorySubscription,
  onCreateUnitInventory,
  onUpdateUnitInventory,
  onDeleteUnitInventory,
} from '@rentguru/commons-utils';
import { useUser } from './UserContext';
import { useFiles } from './FilesContext';
import isNil from 'lodash/isNil';
import { isAfter } from 'date-fns';
import { usePermissions } from './utils/PermissionsContext';
import { useUnits } from './UnitsContext';
import { isEmpty } from 'lodash';
import {
  deleteAndHideEntity,
  getFilterFieldNameForIndex,
  list,
  mutation,
  recordWasUpdated,
  useSubscriptions,
} from '@up2rent/fetch-utils';
import { useInventoryCustomItems } from './InventoryCustomItemContext';
import { useLeaseInventoryEncodings } from './LeaseInventoryEncodingContext';

const ENTITY_MODEL_NAME: Model = 'UnitInventory';
const ENTITY_MODEL_NAME_UNIT_INTENTORY_STRUCTURE: Model = 'UnitInventoryStructure';

export type ActionUnitInventory =
  | {
      type: 'SHOULD_FETCH_UNIT_INVENTORY' | 'IS_FETCHING_UNIT_INVENTORY';
    }
  | {
      type: 'ADD_UNIT_INVENTORY';
      payload: { unitInventory: UnitInventory };
    }
  | {
      type: 'FETCHED_UNIT_INVENTORY';
      payload: { unitInventories: UnitInventory[] };
    }
  | {
      type: 'UPDATE_UNIT_INVENTORY';
      payload: { unitInventory: UnitInventory };
    }
  | {
      type: 'DELETE_UNIT_INVENTORY';
      payload: { id: string };
    };

export type ActionUnitInventoryStructure =
  | {
      type: 'SHOULD_FETCH_UNIT_INVENTORY_STRUCTURE' | 'IS_FETCHING_UNIT_INVENTORY_STRUCTURE';
    }
  | {
      type: 'ADD_UNIT_INVENTORY_STRUCTURE';
      payload: { unitInventoryStructure: UnitInventoryStructure };
    }
  | {
      type: 'FETCHED_UNIT_INVENTORY_STRUCTURE';
      payload: { unitInventoryStructures: UnitInventoryStructure[] };
    }
  | {
      type: 'UPDATE_UNIT_INVENTORY_STRUCTURE';
      payload: { unitInventoryStructure: UnitInventoryStructure };
    }
  | {
      type: 'DELETE_UNIT_INVENTORY_STRUCTURE';
      payload: { id: string };
    };

export interface ExtendedUnitInventory extends Omit<UnitInventory, 'structures'> {
  structures: ExtendedUnitInventoryStructure[];
}

export interface ExtendedUnitInventoryStructure extends UnitInventoryStructure {
  encodings: ExtendedLeaseInventoryEncoding[];
}

export interface ExtendedLeaseInventoryEncoding extends LeaseInventoryEncoding {
  checkedItems: LeaseInventoryCheckedItem[];
}

export interface UnitInventoryContext extends UnitInventoryState {
  getUnitInventory: (id: string) => UnitInventory | undefined;
  getUnitInventoriesFor: (unitId: string, date?: Date) => UnitInventory[];
  createUnitInventory: (
    input: Omit<UnitInventory, 'id' | 'clientId' | 'readId'>,
    createGeneralStructure?: boolean
  ) => Promise<UnitInventory>;
  updateUnitInventory: (original: UnitInventory, updates: Partial<UnitInventory>) => Promise<UnitInventory>;
  deleteUnitInventory: (
    id: string,
    deepDeleteLeaseInventoryEncoding: (
      leaseInventoryEncoding: LeaseInventoryEncoding,
      encodingsFiles: FileModel[]
    ) => Promise<void>,
    flaggedDate?: Date
  ) => Promise<void>;
  createUnitInventoryStructure: (
    input: Omit<UnitInventoryStructure, 'id' | 'clientId' | 'readId'>
  ) => Promise<UnitInventoryStructure>;
  updateUnitInventoryStructure: (
    original: UnitInventoryStructure,
    updates: Partial<UnitInventoryStructure>
  ) => Promise<UnitInventoryStructure>;
  deleteUnitInventoryStructure: (id: string, flaggedDate?: Date) => Promise<void>;
  buildCompleteInventoriesForUnitAndLeaseInventory: (
    unitId: string,
    leaseInventoryId: string,
    date?: Date,
    type?: 'fixtures' | 'furnitures'
  ) => Promise<ExtendedUnitInventory[]>;
  buildCompleteStructures: (
    unitId: string,
    leaseInventoryId: string,
    date?: Date
  ) => Promise<ExtendedUnitInventoryStructure[]>;
  buildCompleteInventoryEncodings: (unitId: string, leaseInventoryId: string) => Promise<LeaseInventoryEncoding[]>;
  getEncodingsOfLeaseInventory: (leaseInventoryId: string) => LeaseInventoryEncoding[];
  listInventoryCustomItems: () => InventoryCustomItem[];
  unitInventoriesLoading: boolean | undefined;
  unitInventoriesError: string | undefined;
  setFetchUnitInventory: () => void;
  setFetchUnitInventoryStructure: () => void;
  unitInventoryStructures: UnitInventoryStructure[] | null;
}

interface UnitInventoryState {
  unitInventories: UnitInventory[] | null;
  loading: boolean;
  shouldFetch: boolean;
  lastFetch: Date | null;
}

const unitInventoryInitialState: UnitInventoryState = {
  loading: false,
  unitInventories: null,
  shouldFetch: false,
  lastFetch: null,
};

const unitInventoryReducer = (state: UnitInventoryState, action: ActionUnitInventory): UnitInventoryState => {
  switch (action.type) {
    case 'SHOULD_FETCH_UNIT_INVENTORY':
      if (state.loading || state.shouldFetch) {
        return state;
      }
      return {
        ...state,
        shouldFetch: true,
      };
    case 'IS_FETCHING_UNIT_INVENTORY':
      return {
        ...state,
        loading: true,
      };
    case 'FETCHED_UNIT_INVENTORY':
      return {
        ...state,

        unitInventories: action.payload.unitInventories,
        loading: false,
        shouldFetch: false,
        lastFetch: new Date(),
      };
    case 'ADD_UNIT_INVENTORY':
      // Check if already present - If already added by this user or coming from another user
      if (state.unitInventories?.find((object) => object.id === action.payload.unitInventory.id)) {
        return state;
      }

      return {
        ...state,

        unitInventories: [...(state.unitInventories ?? []), action.payload.unitInventory],
      };
    case 'UPDATE_UNIT_INVENTORY':
      // No data
      if (isNilOrEmpty(state.unitInventories)) {
        return state;
      }

      // Already present and same object
      const currentObject = state.unitInventories?.find((object) => object.id === action.payload.unitInventory.id);
      if (!currentObject) {
        return {
          ...state,

          unitInventories: [...state.unitInventories, action.payload.unitInventory],
        };
      }
      if (!recordWasUpdated(currentObject, action.payload.unitInventory)) {
        return state;
      }

      // Update
      return {
        ...state,
        unitInventories: state.unitInventories.map((object) => {
          if (object.id === action.payload.unitInventory.id) {
            return action.payload.unitInventory;
          }
          return object;
        }),
      };
    case 'DELETE_UNIT_INVENTORY':
      if (isNilOrEmpty(state.unitInventories)) {
        return state;
      }
      return {
        ...state,
        unitInventories: state.unitInventories.filter((object) => object.id !== action.payload.id),
      };

    default:
      return state;
  }
};

interface UnitInventoryStructureState {
  unitInventoryStructures: UnitInventoryStructure[] | null;
  loading: boolean;
  shouldFetch: boolean;
  lastFetch: Date | null;
}

const unitInventoryStructureInitialState: UnitInventoryStructureState = {
  loading: false,
  unitInventoryStructures: null,
  shouldFetch: false,
  lastFetch: null,
};

const unitInventoryStructureReducer = (
  state: UnitInventoryStructureState,
  action: ActionUnitInventoryStructure
): UnitInventoryStructureState => {
  switch (action.type) {
    case 'SHOULD_FETCH_UNIT_INVENTORY_STRUCTURE':
      if (state.loading || state.shouldFetch) {
        return state;
      }
      return {
        ...state,
        shouldFetch: true,
      };
    case 'IS_FETCHING_UNIT_INVENTORY_STRUCTURE':
      return {
        ...state,
        loading: true,
      };
    case 'FETCHED_UNIT_INVENTORY_STRUCTURE':
      return {
        ...state,

        unitInventoryStructures: action.payload.unitInventoryStructures,
        loading: false,
        shouldFetch: false,
        lastFetch: new Date(),
      };
    case 'ADD_UNIT_INVENTORY_STRUCTURE':
      // Check if already present - If already added by this user or coming from another user
      if (state.unitInventoryStructures?.find((object) => object.id === action.payload.unitInventoryStructure.id)) {
        return state;
      }

      return {
        ...state,

        unitInventoryStructures: [...(state.unitInventoryStructures ?? []), action.payload.unitInventoryStructure],
      };
    case 'UPDATE_UNIT_INVENTORY_STRUCTURE':
      // No data
      if (isNilOrEmpty(state.unitInventoryStructures)) {
        return state;
      }

      // Already present and same object
      const currentObject = state.unitInventoryStructures?.find(
        (object) => object.id === action.payload.unitInventoryStructure.id
      );
      if (!currentObject) {
        return {
          ...state,

          unitInventoryStructures: [...state.unitInventoryStructures, action.payload.unitInventoryStructure],
        };
      }
      if (!recordWasUpdated(currentObject, action.payload.unitInventoryStructure)) {
        return state;
      }

      // Update
      return {
        ...state,
        unitInventoryStructures: state.unitInventoryStructures.map((object) => {
          if (object.id === action.payload.unitInventoryStructure.id) {
            return action.payload.unitInventoryStructure;
          }
          return object;
        }),
      };
    case 'DELETE_UNIT_INVENTORY_STRUCTURE':
      if (isNilOrEmpty(state.unitInventoryStructures)) {
        return state;
      }
      return {
        ...state,
        unitInventoryStructures: state.unitInventoryStructures.filter((object) => object.id !== action.payload.id),
      };

    default:
      return state;
  }
};

const fetchUnitInventories = async (clientId: string, additionalFilter?: object): Promise<UnitInventory[]> => {
  return await list<UnitInventory>(
    syncQuery,
    getFilterFieldNameForIndex('byClientId'),
    getTableClientId(clientId, ENTITY_MODEL_NAME),
    additionalFilter
  );
};
const fetchUnitInventoryStructures = async (
  clientId: string,
  additionalFilter?: object
): Promise<UnitInventoryStructure[]> => {
  return await list<UnitInventoryStructure>(
    syncUnitInventoryStructures,
    getFilterFieldNameForIndex('byClientId'),
    clientId,
    additionalFilter
  );
};

export const UnitInventoryContext = React.createContext<UnitInventoryContext | null>(null);

export const UnitInventoryContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [unitInventoriesState, unitInventoriesDispatch] = useReducer<Reducer<UnitInventoryState, ActionUnitInventory>>(
    unitInventoryReducer,
    unitInventoryInitialState
  );
  const [unitInventoryStructuresState, unitInventoryStructuresDispatch] = useReducer<
    Reducer<UnitInventoryStructureState, ActionUnitInventoryStructure>
  >(unitInventoryStructureReducer, unitInventoryStructureInitialState);
  const { inventoryCustomItems } = useInventoryCustomItems();
  const { leaseInventoryEncodings, deepDeleteLeaseInventoryEncoding } = useLeaseInventoryEncodings();
  const { buildingsUnitsDetailsDelete } = usePermissions();
  const { getUnit } = useUnits();
  const { clientId } = useUser();
  const { fetchFiles } = useFiles();

  const { loading: unitInventoriesLoading, unitInventories } = unitInventoriesState;
  const { loading: unitInventoriesStructureLoading, unitInventoryStructures = [] } = unitInventoryStructuresState;

  useEffect(() => {
    const fetchAndSet = async () => {
      unitInventoriesDispatch({ type: 'IS_FETCHING_UNIT_INVENTORY' });
      const result = await fetchUnitInventories(clientId!);
      unitInventoriesDispatch({ type: 'FETCHED_UNIT_INVENTORY', payload: { unitInventories: result } });
    };
    if (unitInventoriesState.shouldFetch) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitInventoriesState.shouldFetch]);

  useEffect(() => {
    const fetchAndSet = async () => {
      unitInventoryStructuresDispatch({ type: 'IS_FETCHING_UNIT_INVENTORY_STRUCTURE' });
      const result = await fetchUnitInventoryStructures(clientId!);
      unitInventoryStructuresDispatch({
        type: 'FETCHED_UNIT_INVENTORY_STRUCTURE',
        payload: { unitInventoryStructures: result },
      });
    };
    if (unitInventoryStructuresState.shouldFetch) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitInventoryStructuresState.shouldFetch]);

  useSubscriptions<
    OnCreateUnitInventorySubscription,
    OnUpdateUnitInventorySubscription,
    OnDeleteUnitInventorySubscription
  >(
    onCreateUnitInventory,
    onUpdateUnitInventory,
    onDeleteUnitInventory,
    (data) => {
      unitInventoriesDispatch({
        type: 'ADD_UNIT_INVENTORY',
        payload: { unitInventory: data.onCreateUnitInventory as UnitInventory },
      });
    },
    (data) => {
      unitInventoriesDispatch({
        type: 'UPDATE_UNIT_INVENTORY',
        payload: { unitInventory: data.onUpdateUnitInventory as UnitInventory },
      });
    },
    (data) => {
      const { id } = data.onDeleteUnitInventory as UnitInventory;
      unitInventoriesDispatch({
        type: 'DELETE_UNIT_INVENTORY',
        payload: { id },
      });
    }
  );

  const setFetchUnitInventory = () => {
    unitInventoriesDispatch({ type: 'SHOULD_FETCH_UNIT_INVENTORY' });
  };

  const setFetchUnitInventoryStructure = () => {
    unitInventoryStructuresDispatch({ type: 'SHOULD_FETCH_UNIT_INVENTORY_STRUCTURE' });
  };

  const createUnitInventory = async (
    input: Omit<UnitInventory, 'id' | 'clientId' | 'readId'>,
    createGeneralStructure: boolean = true
  ) => {
    const unitInventory = await mutation<UnitInventory, CreateMutationVariables>(createMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateInput),
        clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME),
        readId: getReadId(clientId!, ENTITY_MODEL_NAME),
      },
    });
    unitInventoriesDispatch({ type: 'ADD_UNIT_INVENTORY', payload: { unitInventory } });

    if (createGeneralStructure) {
      const generalStructure = await createUnitInventoryStructure({
        unitInventoryId: unitInventory.id,
        type: UnitInventoryStructureType.GENERAL,
        name: 'General structure',
      });

      return { ...unitInventory, structures: [generalStructure] };
    }
    return unitInventory;
  };

  const updateUnitInventory = async (original: UnitInventory, updates: Partial<UnitInventory>) => {
    const result = await mutation<UnitInventory>(updateMutation, {
      input: { ...cleanInputUpdate({ id: original.id, _version: original._version, ...updates }, false) },
    });
    unitInventoriesDispatch({ type: 'UPDATE_UNIT_INVENTORY', payload: { unitInventory: result } });
    return result;
  };

  const updateUnitInventoryStructure = async (
    original: UnitInventoryStructure,
    updates: Partial<UnitInventoryStructure>
  ) => {
    const result = await mutation<UnitInventoryStructure>(updateUnitInventoryStructureMutation, {
      input: { ...cleanInputUpdate({ id: original.id, _version: original._version, ...updates }, false) },
    });
    unitInventoryStructuresDispatch({
      type: 'UPDATE_UNIT_INVENTORY_STRUCTURE',
      payload: { unitInventoryStructure: result },
    });
    return result;
  };

  const deleteUnitInventoryStructure = async (id: string, flaggedDate?: Date) => {
    if (!buildingsUnitsDetailsDelete) {
      return;
    }
    const unitInventoryStructure = getUnitInventoryStructure(id);
    if (unitInventoryStructure) {
      if (flaggedDate) {
        await updateUnitInventoryStructure(unitInventoryStructure, {
          deletedAt: flaggedDate.toISOString(),
        });
      } else {
        const hardDeleteStructurePromises: Promise<UnitInventoryStructure[] | void | UnitInventoryStructure>[] = [];
        if (unitInventoryStructure.encodings) {
          const encodingsFiles = (await fetchFiles(EntityType.ENCODING)) as FileModel[];
          for (const leaseInventoryEncoding of unitInventoryStructure.encodings) {
            hardDeleteStructurePromises.push(deepDeleteLeaseInventoryEncoding(leaseInventoryEncoding, encodingsFiles));
          }
        }
        hardDeleteStructurePromises.push(
          deleteAndHideEntity<UnitInventoryStructure>(
            unitInventoryStructure,
            deleteUnitInventoryStructureMutation,
            updateUnitInventoryStructureMutation
          )
        );
        await Promise.all(hardDeleteStructurePromises);
      }
    }
  };

  /**
   * Delete the unit inventory.
   * Perform a hard delete if the unit of the unitInventory is not linked to any Lease
   * Otherwise flag the unitInventory to the flaggedDate (= today if none is given ).
   */
  const deleteUnitInventory = async (
    id: string,
    deepDeleteLeaseInventoryEncoding: (
      leaseInventoryEncoding: LeaseInventoryEncoding,
      encodingsFiles: FileModel[]
    ) => Promise<void>,
    flaggedDate?: Date
  ) => {
    if (!buildingsUnitsDetailsDelete) {
      return;
    }
    const unitInventory = getUnitInventory(id);
    if (!isNil(unitInventory)) {
      const unitOfUnitInventory = getUnit(unitInventory.unitId);
      if (!isNil(unitOfUnitInventory)) {
        const shouldFlag = !isNil(unitOfUnitInventory.leases) && !isEmpty(unitOfUnitInventory.leases);
        const unitInventoryStructures = getUnitInventoryStructuresForUnitInventory(id);
        let deleteUnitInventoryPromise: Promise<UnitInventory | UnitInventory[]> | undefined;
        let deleteUnitInventoryStructurePromises: Promise<void>[] = [];
        if (shouldFlag) {
          const originalUnitInventory = getUnitInventory(id);
          if (!isNil(originalUnitInventory)) {
            deleteUnitInventoryPromise = updateUnitInventory(originalUnitInventory, {
              deletedAt: flaggedDate ? flaggedDate.toISOString() : new Date().toISOString(),
            });
            deleteUnitInventoryStructurePromises = unitInventoryStructures.map((struct) => {
              return deleteUnitInventoryStructure(struct.id, flaggedDate || new Date());
            });
          }
        } else {
          deleteUnitInventoryPromise = deleteAndHideEntity<UnitInventory>(
            unitInventory,
            deleteUnitInventoryMutation,
            updateUnitInventoryMutation
          );

          deleteUnitInventoryStructurePromises = unitInventoryStructures.map((struct) => {
            return deleteUnitInventoryStructure(struct.id);
          });
        }
        await Promise.all([deleteUnitInventoryPromise, ...deleteUnitInventoryStructurePromises] as Promise<
          UnitInventory | UnitInventoryStructure
        >[]);
      }
    }
  };

  const getUnitInventory = (id: string): UnitInventory | undefined => {
    if (unitInventoriesLoading) {
      return undefined;
    }
    if (isNil(unitInventories)) {
      return;
    }
    return unitInventories.find((u) => u.id === id);
  };

  const getUnitInventoryStructure = (id: string): UnitInventoryStructure | undefined => {
    if (unitInventoriesStructureLoading) {
      return undefined;
    }
    if (isNil(unitInventoryStructures)) {
      return;
    }
    return unitInventoryStructures.find((u) => u.id === id);
  };

  const getEncodingsOfLeaseInventory = (leaseInventoryId: string) => {
    return (leaseInventoryEncodings ?? []).filter((le) => le.leaseInventoryId === leaseInventoryId);
  };

  /**
   * If a date is specified, returns the unit inventories that existed at date
   */
  const getUnitInventoriesFor = (unitId: string, date?: Date): UnitInventory[] => {
    if (unitInventoriesLoading) {
      return [];
    }
    if (isNil(unitInventories)) {
      return [];
    }
    if (isNil(date)) return unitInventories.filter((u) => u.unitId === unitId);

    return unitInventories.filter((u) => {
      if (u.unitId === unitId) {
        const notDeletedBeforeDate =
          isNil(u.deletedAt) || (!isNil(u.deletedAt) && isAfter(new Date(u.deletedAt), date));
        return notDeletedBeforeDate;
      }
      return false;
    });
  };

  const createUnitInventoryStructure = async (input: Omit<UnitInventoryStructure, 'id' | 'clientId' | 'readId'>) => {
    const unitInventoryStructure = await mutation<
      UnitInventoryStructure,
      CreateUnitInventoryStructureMutationVariables
    >(createUnitInventoryStructureMutation, {
      input: {
        ...(cleanInputCreate(input) as Omit<UnitInventoryStructure, 'id' | 'clientId' | 'readId'>),
        clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME_UNIT_INTENTORY_STRUCTURE),
        readId: getReadId(clientId!, ENTITY_MODEL_NAME_UNIT_INTENTORY_STRUCTURE),
      },
    });
    unitInventoryStructuresDispatch({ type: 'ADD_UNIT_INVENTORY_STRUCTURE', payload: { unitInventoryStructure } });
    return unitInventoryStructure;
  };

  /**
   * If a date is specified, returns the unit structures that existed at date
   */
  const getUnitInventoryStructuresForUnitInventory = (
    unitInventoryId: string,
    date?: Date,
    type: 'fixtures' | 'furnitures' = 'fixtures'
  ): UnitInventoryStructure[] => {
    if (isNil(unitInventoryStructures)) {
      return [];
    }
    if (type === 'fixtures') {
      if (isNil(date))
        return unitInventoryStructures.filter(
          (uis) => uis.unitInventoryId === unitInventoryId && uis.type !== UnitInventoryStructureType.FURNITURE
        );

      return unitInventoryStructures.filter((uis) => {
        if (uis.unitInventoryId === unitInventoryId && uis.type !== UnitInventoryStructureType.FURNITURE) {
          const notDeletedBeforeDate =
            isNil(uis.deletedAt) || (!isNil(uis.deletedAt) && isAfter(new Date(uis.deletedAt), date));
          return notDeletedBeforeDate;
        }
        return false;
      });
    }
    if (isNil(date))
      return unitInventoryStructures.filter(
        (uis) => uis.unitInventoryId === unitInventoryId && uis.type === UnitInventoryStructureType.FURNITURE
      );

    return unitInventoryStructures.filter((uis) => {
      if (uis.unitInventoryId === unitInventoryId && uis.type === UnitInventoryStructureType.FURNITURE) {
        const notDeletedBeforeDate =
          isNil(uis.deletedAt) || (!isNil(uis.deletedAt) && isAfter(new Date(uis.deletedAt), date));
        return notDeletedBeforeDate;
      }
      return false;
    });
  };

  const getLeaseInventoryEncodingForUnitInventoryStructureAndLeaseInventory = (
    unitInventoryStructureId: string,
    leaseInventoryId: string
  ): LeaseInventoryEncoding[] => {
    return (leaseInventoryEncodings ?? []).filter(
      (lie) => lie.leaseInventoryId === leaseInventoryId && lie.structureId === unitInventoryStructureId
    );
  };

  const listInventoryCustomItems = (): InventoryCustomItem[] => {
    return inventoryCustomItems ?? [];
  };

  const buildCompleteInventoryEncodings = async (
    structureId: string,
    leaseInventoryId: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    type: 'fixtures' | 'furnitures' = 'fixtures'
  ) => {
    const structureEncodings = getLeaseInventoryEncodingForUnitInventoryStructureAndLeaseInventory(
      structureId,
      leaseInventoryId
    );
    return await Promise.all(
      structureEncodings.map(async (strEnc) => {
        const encodingCheckedItems = [] as LeaseInventoryCheckedItem[];
        // type === 'fixtures'
        //   ? getLeaseInventoryCheckedItemForLeaseInventoryEncoding(strEnc.id)
        //   : ([] as LeaseInventoryCheckedItem[]);
        const inventoryFiles = (await fetchFiles(EntityType.ENCODING, strEnc.id)) as FileModel[];
        return { ...strEnc, checkedItems: encodingCheckedItems, medias: inventoryFiles };
      })
    );
  };

  const buildCompleteStructures = async (
    unitInventoryId: string,
    leaseInventoryId: string,
    date?: Date,
    type: 'fixtures' | 'furnitures' = 'fixtures'
  ) => {
    const structures = getUnitInventoryStructuresForUnitInventory(unitInventoryId, date, type);
    return await Promise.all(
      structures.map(async (struct) => {
        const extendedStructureEncodings = await buildCompleteInventoryEncodings(struct.id, leaseInventoryId, type);
        return { ...struct, encodings: extendedStructureEncodings };
      })
    );
  };

  const buildCompleteInventoriesForUnitAndLeaseInventory = async (
    unitId: string,
    leaseInventoryId: string,
    date?: Date,
    type: 'fixtures' | 'furnitures' = 'fixtures'
  ) => {
    const simpleUnitInventories = getUnitInventoriesFor(unitId, date);
    return await Promise.all(
      simpleUnitInventories.map(async (ui: UnitInventory) => {
        return { ...ui, structures: await buildCompleteStructures(ui.id, leaseInventoryId, date, type) };
      })
    );
  };

  const values = useMemo(
    () => ({
      unitInventoryStructures: unitInventoryStructuresState.unitInventoryStructures,
      ...unitInventoriesState,
      unitInventoriesLoading: unitInventoriesState.loading,
      getUnitInventory,
      getUnitInventoriesFor,
      createUnitInventory,
      updateUnitInventory,
      deleteUnitInventory,
      createUnitInventoryStructure,
      updateUnitInventoryStructure,
      deleteUnitInventoryStructure,
      buildCompleteInventoriesForUnitAndLeaseInventory,
      buildCompleteStructures,
      buildCompleteInventoryEncodings,
      getEncodingsOfLeaseInventory,
      listInventoryCustomItems,
      unitInventoriesError: undefined,
      setFetchUnitInventoryStructure,
      setFetchUnitInventory,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [unitInventoriesState, unitInventoryStructuresState]
  );

  return <UnitInventoryContext.Provider value={values}>{children}</UnitInventoryContext.Provider>;
};

export const useUnitInventories = (): UnitInventoryContext => {
  const firstRender = useRef<boolean>(false);
  const context = useContext<UnitInventoryContext | null>(UnitInventoryContext);

  if (isNil(context)) {
    throw new Error('`useUnitInventories` hook must be used within a `UnitInventoryContextProvider` component');
  }

  useEffect(() => {
    if (!firstRender.current && isNil(context.unitInventories)) {
      context.setFetchUnitInventory();
      context.setFetchUnitInventoryStructure();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  return context as UnitInventoryContext;
};
