import { Divider, Grid, Typography } from '@material-ui/core';
import {
  Colors,
  DEFAULT_SENDING_EMAIL,
  isSendingSourceDefault,
  SendingSource,
  SendingSourceStatus,
} from '@rentguru/commons-utils';
import { toUpper } from 'lodash';
import React from 'react';
import { IntlFormatters, useIntl } from 'react-intl';
import { ActionButton , ContentHeader, LoaderButton } from '@up2rent/ui';
import { useSendingSources } from 'src/hooks/SendingSourceContext';

interface DeleteDomainDialogProps {
  id: string;
  closeAction: () => void;
}

const getDeleteDialogContent = (sendingSource: SendingSource, formatMessage: IntlFormatters['formatMessage']) => {
  if (sendingSource.status === SendingSourceStatus.ERROR) {
    return (
      <Typography>
        {formatMessage({ id: 'settings.sendingSources.deleteErrorInfo1' })}
        <b>{sendingSource.name}</b>
        {formatMessage({ id: 'settings.sendingSources.deleteErrorInfo2' })}
      </Typography>
    );
  }
  if (sendingSource.status === SendingSourceStatus.VERIFIED) {
    return (
      <Typography>
        {formatMessage({ id: 'settings.sendingSources.deleteVerifiedInfo1' })}
        <b>{sendingSource.name}</b>
        {formatMessage({ id: 'settings.sendingSources.deleteVerifiedInfo2' })}
        <b>{sendingSource.emailAddress}</b>
        {formatMessage({ id: 'settings.sendingSources.deleteVerifiedInfo3' })}
      </Typography>
    );
  }
  return (
    <Typography>
      {formatMessage({ id: 'settings.sendingSources.deletePendingInfo1' })}
      <b>{sendingSource.name}</b>
      {formatMessage({ id: 'settings.sendingSources.deletePendingInfo2' })}
      <b>{sendingSource.emailAddress}</b>
      {formatMessage({ id: 'settings.sendingSources.deletePendingInfo3' })}
    </Typography>
  );
};

const DeleteDomainDialog: React.FC<DeleteDomainDialogProps> = ({ id, closeAction }) => {
  const { formatMessage } = useIntl();
  const { getSendingSource, deleteSendingSource, sendingSourcesLoading } = useSendingSources();

  const sendingSource = getSendingSource(id)!;

  const handleDelete = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    await deleteSendingSource(sendingSource);
    closeAction();
  };

  return (
    <Grid style={{ width: 600 }}>
      <ContentHeader title={formatMessage({ id: 'settings.sendingSources.deleteTitle' })} />

      <Divider style={{ marginBottom: 20 }} />

      <Grid style={{ marginLeft: 30, marginRight: 30 }}>
        {getDeleteDialogContent(sendingSource, formatMessage)}
        {isSendingSourceDefault(sendingSource.verifiedType) && (
          <Typography style={{ marginTop: 20 }}>
            {formatMessage(
              { id: 'settings.sendingSources.defaultDeleteInfo' },
              { emailAddress: DEFAULT_SENDING_EMAIL }
            )}
          </Typography>
        )}
      </Grid>

      <Divider style={{ marginBottom: 20, marginTop: 20 }} />

      <Grid
        style={{
          marginRight: 30,
          marginBottom: 20,
          display: 'flex',
          float: 'right',
          alignItems: 'center',
        }}
      >
        <ActionButton
          onClick={closeAction}
          style={{
            background: 'none',
            color: Colors.DARK_SLATE_GREY,
            marginRight: 20,
          }}
        >
          {toUpper(
            formatMessage({
              id: 'close',
            })
          )}
        </ActionButton>
        <LoaderButton loading={sendingSourcesLoading} onClick={handleDelete}>
          {formatMessage({
            id: 'delete',
          })}
        </LoaderButton>
      </Grid>
    </Grid>
  );
};

export default DeleteDomainDialog;
