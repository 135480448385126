import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import React from 'react';
import { LeaseInventory, UnitInventoryStructureType } from '@rentguru/commons-utils';
import { ExtendedUnitInventory, ExtendedUnitInventoryStructure } from '../../../../hooks/UnitInventoryContext';
import InventoryStructureEncoding from '../InventoryStructureEncoding';
import { TablePlaceHolder } from '@up2rent/ui';
import { AddCheckedItemDialogBundle, FilePreviewDialogBundle } from '../InventoryEncodings';

interface EquipmentTabProps {
  leaseInventory: LeaseInventory;
  completeUnitInventory: ExtendedUnitInventory;
  unitInventoryIndex: number;
  encodeDefects: (defectBundle: AddCheckedItemDialogBundle) => void;
  previewFileFunction: (bundle: FilePreviewDialogBundle) => void;
  readOnly: boolean;
}

const EquipmentTab: React.FC<EquipmentTabProps> = ({
  leaseInventory,
  completeUnitInventory,
  unitInventoryIndex,
  encodeDefects,
  previewFileFunction,
  readOnly,
}) => {
  const { formatMessage } = useIntl();
  const equipments = completeUnitInventory.structures.reduce(
    (acc: { struct: ExtendedUnitInventoryStructure; index: number }[], struct, index) => {
      if (struct.type === UnitInventoryStructureType.EQUIPMENT) {
        acc.push({ index, struct });
      }
      return acc;
    },
    []
  );

  if (isEmpty(equipments)) {
    return (
      <>
        <TablePlaceHolder
          mainText={formatMessage({ id: 'leaseInventory.equipmentPlaceholder.mainText' })}
          subText={formatMessage({ id: 'leaseInventory.equipmentPlaceholder.subText' }, { readOnly })}
        />
      </>
    );
  }
  return (
    <>
      {equipments.map(({ struct, index }) => (
        <InventoryStructureEncoding
          key={struct.id}
          completeUnitInventory={completeUnitInventory}
          leaseInventory={leaseInventory}
          structureFieldName={`localUnitInventories[${unitInventoryIndex}].structures[${index}]`}
          inventoryStructure={struct}
          previewFileFunction={previewFileFunction}
          encodeDefects={encodeDefects}
          readOnly={readOnly}
        />
      ))}
    </>
  );
};

export default EquipmentTab;
