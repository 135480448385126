import React from 'react';
import { CustomTableCell } from '@up2rent/ui';
import TableRow from '@material-ui/core/TableRow';
import { Typography } from '@material-ui/core';
import { format, formatDistance, parseISO } from 'date-fns';
import { useLocale, dateLocaleMap, dateLocaleMapType } from '../../i18n/IntlProviderWrapper';
import isNil from 'lodash/isNil';
import { useHistory } from 'react-router-dom';
import { stripHTML } from '../../utils/stripHTML';
import { Colors, getContactNameFromObject, TicketStatus } from '@rentguru/commons-utils';
import StatusBox from '../ui/StatusBox';
import { TicketsToMeRowProps } from './TicketsToMeRow';
import { useIntl } from 'react-intl';
import { RouteDestination } from '../Routes/Routes';
import { ROW_HEIGHT } from '../Dashboard/DashboardTodos/DashboardTickets';

const TicketsToOthersRow: React.FC<TicketsToMeRowProps> = ({
  id,
  contact,
  subject,
  status,
  createdAt,
  building,
  unit,
  assignedTo,
  plannedAt,
}) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const history = useHistory();

  function handleClick() {
    history.push(`${RouteDestination.TICKETS_DETAILS}/${id}`);
  }
  return (
    <TableRow
      hover
      tabIndex={-1}
      key={id}
      style={{ paddingTop: 10, minHeight: ROW_HEIGHT, height: ROW_HEIGHT }}
      // eslint-disable-next-line react/jsx-no-bind
      onClick={handleClick}
    >
      <CustomTableCell scope="row" padding="none" style={{ paddingLeft: 15 }}>
        {!isNil(contact) ? getContactNameFromObject(contact) : ''}
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none" style={{ maxWidth: 350 }}>
        <Typography
          style={{
            fontWeight: 600,
            color: Colors.CLASSICAL_BLACK,
            fontSize: 14,
            marginBottom: 4,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {stripHTML(subject)}
        </Typography>
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none" style={{ paddingRight: 30 }}>
        <StatusBox status={status! as TicketStatus} />
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        {formatDistance(parseISO(createdAt!), new Date(), {
          includeSeconds: true,
          addSuffix: true,
          locale: (dateLocaleMap as dateLocaleMapType)[language],
        })}
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        {isNil(building) ? null : isNil(unit) ? building.name : `${building.name} - ${unit.name}`}
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        {!isNil(assignedTo)
          ? `${assignedTo.firstName} ${assignedTo.lastName}`
          : formatMessage({ id: 'tickets.menu.unassigned' })}
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        {isNil(plannedAt)
          ? null
          : format(parseISO(plannedAt), 'd MMM yyyy', { locale: (dateLocaleMap as dateLocaleMapType)[language] })}
      </CustomTableCell>
    </TableRow>
  );
};

export default TicketsToOthersRow;
