/* eslint-disable react-hooks/rules-of-hooks */
import { Menu, MenuItem } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Building, Colors, LeaseExtended } from '@rentguru/commons-utils';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ActionButtonSecond } from '@up2rent/ui';
import { ReactComponent as AddIcon } from 'src/icons/add.svg';

import { makeStyles } from '@material-ui/core/styles';
import { isNil } from 'lodash';
import LeaseActionMenuDialogs from './ActionsMenu/LeaseActionMenuDialogs';
import useLeaseActionsMenuUtils, { LeaseAction, usePropertyActionsUtils } from './ActionsMenu/useLeaseActionsMenuUtils';
import PropertyActionMenu from 'src/components/Properties/PropertyActionMenu/PropertyActionMenu';

const useStyles = makeStyles({
  addAndManageIcon: { height: 15 },
  addAndManageText: { fontWeight: 'bold', color: Colors.DARK },
  menu: {
    '& .MuiPaper-rounded': {
      borderRadius: 10,
    },
  },
  menuItem: { paddingTop: 10, paddingBottom: 10, display: 'flex', alignItems: 'center' },
  menuItemText: { marginLeft: 10, color: Colors.CLASSICAL_BLACK, fontSize: 14 },
});

interface AddAndManageButtonProps {
  lease?: LeaseExtended;
  property?: Building;
}

export const AddAndManageButton: React.FC<AddAndManageButtonProps> = ({ lease, property }) => {
  if (isNil(lease) === isNil(property)) {
    throw new Error('Add and Manage button must receive a lease XOR a property');
  }
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const leaseActionsObject = lease ? useLeaseActionsMenuUtils(lease) : undefined;
  const { actions: propertyActions } = usePropertyActionsUtils();
  const actions = leaseActionsObject?.actions ?? propertyActions;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogOpen, setDialogOpen] = useState<{
    open: boolean;
    type: LeaseAction | null;
  }>({
    open: false,
    type: null,
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = async (type: LeaseAction) => {
    if (leaseActionsObject) {
      const { editLease, setLeaseToDraft } = leaseActionsObject;
      if (!isNil(editLease) && type === LeaseAction.EDIT) {
        editLease();
        return;
      }
      if (!isNil(setLeaseToDraft) && [LeaseAction.SET_TO_DRAFT, LeaseAction.RETRACT].includes(type)) {
        await setLeaseToDraft();
        return;
      }
    }
    handleClose();
    setDialogOpen({ open: true, type });
  };

  const onCloseDialog = () => setDialogOpen({ open: false, type: null });

  return (
    <>
      <ActionButtonSecond
        style={{ height: 35, alignItems: 'center', display: 'flex' }}
        onClick={handleClick}
        startIcon={<AddIcon className={classes.addAndManageIcon} fill={Colors.DARK} />}
      >
        <Typography className={classes.addAndManageText}>
          {formatMessage({ id: 'lease.detail.action.addAndManage' })}
        </Typography>
      </ActionButtonSecond>
      <Menu anchorEl={anchorEl} open={!isNil(anchorEl)} onClose={handleClose} className={classes.menu}>
        {actions.map(
          ({ textLabelId, iconComponentWithoutCircle, type }) =>
            // Disabled for now
            ![
              LeaseAction.ADJUST_CHARGE,
              LeaseAction.CHANGE_TENANTS,
              LeaseAction.CHANGE_UNITS,
              LeaseAction.GENERATE_PDF,
            ].includes(type) && (
              <MenuItem onClick={() => handleMenuItemClick(type)} key={textLabelId} className={classes.menuItem}>
                {iconComponentWithoutCircle}
                <Typography className={classes.menuItemText}>{formatMessage({ id: textLabelId })}</Typography>
              </MenuItem>
            )
        )}
      </Menu>
      {leaseActionsObject && (
        <LeaseActionMenuDialogs
          lease={lease!}
          {...dialogOpen}
          onCloseDialog={onCloseDialog}
          leaseContract={leaseActionsObject.leaseContract}
        />
      )}
      {property && <PropertyActionMenu property={property} {...dialogOpen} onCloseDialog={onCloseDialog} />}
    </>
  );
};
