import { Typography } from '@material-ui/core';
import { Statement, formatStandardizedUTCDate } from '@rentguru/commons-utils';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { CustomSimpleDialog } from '@up2rent/ui';
import { dateLocaleMap, useLocale } from 'src/i18n/IntlProviderWrapper';

interface ConfirmValidateTenantStatementDialogProps {
  open: boolean;
  statement: Statement;
  validateStatement: () => Promise<void>;
  onClose: () => void;
}

const ConfirmValidateTenantStatementDialog: React.FC<ConfirmValidateTenantStatementDialogProps> = ({
  open,
  statement,
  validateStatement,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const [validationLoading, setValidationLoading] = useState<boolean>(false);

  const fromDateToString = formatStandardizedUTCDate(new Date(statement.periodFrom), 'dd/MM/yyyy', {
    locale: dateLocaleMap[language],
  });
  const toDateToString = formatStandardizedUTCDate(new Date(statement.periodTo), 'dd/MM/yyyy', {
    locale: dateLocaleMap[language],
  });
  return (
    <CustomSimpleDialog
      open={open}
      onClose={onClose}
      onActionButtonClick={async () => {
        setValidationLoading(true);
        await validateStatement();
        setValidationLoading(false);
      }}
      actionButtonLabel={formatMessage({ id: 'accounting.statement.toValidateSection.validateAndSend' })}
      title={formatMessage({ id: 'accounting.statement.toValidateSection.validateDialogTitle' })}
      dividerBelowTitle
      actionButtonLoading={validationLoading}
      formatMessage={formatMessage}
    >
      <Typography style={{ fontSize: 16, marginTop: 20, textAlign: 'justify' }}>
        {formatMessage(
          { id: 'accounting.statement.toValidateSection.validateHelper' },
          { fromDate: fromDateToString, toDate: toDateToString }
        )}
      </Typography>
      <Typography style={{ fontSize: 16, textAlign: 'justify' }}>
        {formatMessage({ id: 'accounting.statement.toValidateSection.validateHelper2' })}
      </Typography>
      <Typography style={{ fontSize: 16, textAlign: 'justify' }}>
        {formatMessage({ id: 'accounting.statement.toValidateSection.validateHelper3' })}
      </Typography>
    </CustomSimpleDialog>
  );
};

export default ConfirmValidateTenantStatementDialog;
