import { Grid, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Colors, formatStandardizedUTCDate, isSubscriptionMandateInProblem } from '@rentguru/commons-utils';
import { LoaderButton } from '@up2rent/ui';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { ExitableLocation } from 'src/components/Routes/Routes';
import SkeletonComponent from 'src/components/ui/SkeletonComponent';
import { useClient } from 'src/hooks/ClientContext';

const useStyles = makeStyles({
  statusText: {
    color: Colors.CLASSICAL_BLACK,
    fontSize: 14,
    fontWeight: 400,
  },
  statusDetail: {
    color: Colors.SLATE_GREY,
    paddingLeft: 10,
    fontSize: 14,
    fontWeight: 400,
  },
});

const DATE_FORMAT = 'd/MM/yyyy';

const MandateStatus = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { applicationSubscription, loading, initiatePayment } = useClient();
  const [mandateLoading, setMandateLoading] = useState<boolean>(false);
  const [hostedUiUrl, setHostedUiUrl] = useState<string | undefined | null>(null);

  const { state: locationState } = useLocation<ExitableLocation | { from: 'mandateProvider' }>();
  const isRedirectedFromMandateProvider = locationState && locationState.from === 'mandateProvider';
  useEffect(() => {
    if (!mandateLoading && isRedirectedFromMandateProvider) {
      setMandateLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRedirectedFromMandateProvider]);

  const hasSubscriptionProblem = isSubscriptionMandateInProblem(applicationSubscription);

  useEffect(() => {
    const initiateMandate = async () => {
      setMandateLoading(true);
      const response = await initiatePayment();
      setHostedUiUrl(response?.redirectUrl);
      setMandateLoading(false);
    };

    if (applicationSubscription && hasSubscriptionProblem && !isRedirectedFromMandateProvider) {
      initiateMandate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSubscriptionProblem, applicationSubscription]);

  if (loading) {
    return <SkeletonComponent loading />;
  }

  return (
    <Grid container direction="column">
      <Grid style={{ display: 'flex' }}>
        <Typography className={classes.statusText}>
          {formatMessage({ id: 'settings.subscription.lastStatus' })}
        </Typography>
        {applicationSubscription?.paymentLastStatus && (
          <Tooltip title={applicationSubscription?.paymentLastError ?? ''} placement="top">
            <Typography className={classes.statusDetail}>
              {formatMessage({
                id: `enums.PaymentStatus.${applicationSubscription?.paymentLastStatus}`,
                defaultMessage: '',
              })}
            </Typography>
          </Tooltip>
        )}
      </Grid>

      {hasSubscriptionProblem && (
        <LoaderButton
          href={hostedUiUrl ?? ''}
          loading={mandateLoading}
          style={{ textTransform: 'none', width: 280, paddingTop: 20 }}
        >
          {formatMessage({ id: 'settings.subscription.configureMandate' })}
        </LoaderButton>
      )}
      {!hasSubscriptionProblem && (
        <Grid style={{ display: 'flex' }}>
          <Typography className={classes.statusText}>
            {formatMessage({ id: 'settings.subscription.lastDirectDebit' })}
          </Typography>
          {applicationSubscription?.paymentLastExecution && (
            <Typography className={classes.statusDetail}>
              {formatStandardizedUTCDate(new Date(applicationSubscription.paymentLastExecution), DATE_FORMAT)}
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default MandateStatus;
