/* eslint-disable @typescript-eslint/no-shadow */
import { isEmpty, sum } from 'lodash';
import { TICKETS_METRICS_BOX_KEY, contactContainsType, ContactType } from '@rentguru/commons-utils';
import { isAfter, format, differenceInMinutes, endOfMonth, endOfYear, endOfDay } from 'date-fns';
import { Point2D } from 'framer-motion';
import isNil from 'lodash/isNil';
import React from 'react';
import { useIntl } from 'react-intl';
import LineChart from 'src/components/ui/Graphs/LineChart';
import { getTicksWithFormatForTimeWindow, getMaxYInBase10 } from 'src/components/ui/Graphs/utils/axis';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { useDashboardFilters } from 'src/hooks/DashboardFiltersContext';
import { useTickets } from 'src/hooks/TicketsContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { useLocale, dateLocaleMap, dateLocaleMapType } from 'src/i18n/IntlProviderWrapper';
import {
  getLast3MonthsFilter,
  getLastYearFilter,
  getThisYearFilter,
  MetricBoxTimeFiltersProps,
} from './DashboardFilters/MetricBoxTimeFilter';
import { CustomFilterMenuBundle } from './DashboardMetrics';
import MetricBox, { MetricBoxLayoutProps } from './MetricBox';

interface DashboardMetricTicketsProps extends MetricBoxLayoutProps {
  openCustomFilterMenu: (bundle: CustomFilterMenuBundle) => void;
}

interface FirstMessageTimeAndValues {
  messageTime: Date | null;
  values: number[];
}

const DashboardMetricTickets: React.FC<DashboardMetricTicketsProps> = ({
  openCustomFilterMenu: openCustomFilterMenuFunction,
  forceUpdateLayout,
}) => {
  const { formatMessage } = useIntl();
  const {
    filteredBuildings,
    filteredUnits,
    dashboardFilters: { tickets: ticketsFilters },
  } = useDashboardFilters();
  const { buildingsLoading } = useBuildings();
  const { unitsLoading } = useUnits();
  const { ticketsAll, ticketsLoading } = useTickets();
  const { language } = useLocale();
  const unitIds = filteredUnits.map((unit) => unit.id);
  const buildingIds = filteredBuildings.map((building) => building.id);
  const fromDate = new Date(ticketsFilters.from);
  const toDate = new Date(ticketsFilters.to);

  const timeFilters: MetricBoxTimeFiltersProps = {
    name: 'tickets',
    filters: [getLastYearFilter(formatMessage), getThisYearFilter(formatMessage), getLast3MonthsFilter(formatMessage)],
    openCustomFilterMenu: (event?: React.MouseEvent<HTMLElement>) => {
      if (!isNil(event)) {
        openCustomFilterMenuFunction({ anchor: event.currentTarget, name: 'tickets' });
      }
    },
  };

  const {
    tickValues: xTickValues,
    tickFormatString: xTickFormatString,
    tooltipFormat: tooltipDateFormat,
  } = getTicksWithFormatForTimeWindow(fromDate, toDate);

  const assignationTimeLineData = xTickValues.reduce((acc: Point2D[], xTickValue) => {
    const startDate = new Date(xTickValue);
    let endDate = endOfMonth(startDate);
    if (xTickFormatString === 'yyyy') {
      endDate = endOfYear(startDate);
    } else if (xTickFormatString === 'd/LL') {
      endDate = endOfDay(startDate);
    }

    const assignationTimes = ticketsAll.reduce((assignationTimes: number[], ticket) => {
      if (
        isNil(ticket.status) ||
        !isNil(ticket.parentId) ||
        (!isNil(ticket.unit) && !unitIds.includes(ticket.unit.id)) ||
        (!isNil(ticket.building) && !buildingIds.includes(ticket.building.id))
      ) {
        return assignationTimes;
      }
      if (isAfter(new Date(ticket.createdAt!), startDate) && isAfter(endDate, new Date(ticket.createdAt!))) {
        if (!isNil(ticket.assignedAt)) {
          assignationTimes.push(differenceInMinutes(new Date(ticket.assignedAt), new Date(ticket.createdAt!)) / 60);
        }
      }
      return assignationTimes;
    }, []);
    if (!isEmpty(assignationTimes)) {
      const averageAssignationTime = sum(assignationTimes) / assignationTimes.length;
      acc.push({ x: xTickValue, y: averageAssignationTime });
      return acc;
    }
    acc.push({ x: xTickValue, y: 0 });
    return acc;
  }, []);

  const closureTimeLineData = xTickValues.reduce((acc: Point2D[], xTickValue) => {
    const startDate = new Date(xTickValue);
    let endDate = endOfMonth(startDate);
    if (xTickFormatString === 'yyyy') {
      endDate = endOfYear(startDate);
    } else if (xTickFormatString === 'd/LL') {
      endDate = endOfDay(startDate);
    }
    const closureTimes = ticketsAll.reduce((closureTimes: number[], ticket) => {
      if (
        isNil(ticket.status) ||
        !isNil(ticket.parentId) ||
        (!isNil(ticket.unit) && !unitIds.includes(ticket.unit.id)) ||
        (!isNil(ticket.building) && !buildingIds.includes(ticket.building.id))
      ) {
        return closureTimes;
      }
      if (isAfter(new Date(ticket.createdAt!), startDate) && isAfter(endDate, new Date(ticket.createdAt!))) {
        if (!isNil(ticket.closedAt)) {
          closureTimes.push(differenceInMinutes(new Date(ticket.closedAt), new Date(ticket.createdAt!)) / 60);
        }
      }
      return closureTimes;
    }, []);
    if (!isEmpty(closureTimes)) {
      const averageClosureTime = sum(closureTimes) / closureTimes.length;
      acc.push({ x: xTickValue, y: averageClosureTime });
      return acc;
    }
    acc.push({ x: xTickValue, y: 0 });
    return acc;
  }, []);

  const resolutionTimeLineData = xTickValues.reduce((acc: Point2D[], xTickValue) => {
    const startDate = new Date(xTickValue);
    let endDate = endOfMonth(startDate);
    if (xTickFormatString === 'yyyy') {
      endDate = endOfYear(startDate);
    } else if (xTickFormatString === 'd/LL') {
      endDate = endOfDay(startDate);
    }
    const resolutionTimes = ticketsAll.reduce((resolutionTimes: number[], ticket) => {
      if (
        isNil(ticket.status) ||
        !isNil(ticket.parentId) ||
        (!isNil(ticket.unit) && !unitIds.includes(ticket.unit.id)) ||
        (!isNil(ticket.building) && !buildingIds.includes(ticket.building.id))
      ) {
        return resolutionTimes;
      }
      if (isAfter(new Date(ticket.createdAt!), startDate) && isAfter(endDate, new Date(ticket.createdAt!))) {
        if (!isNil(ticket.plannedAt)) {
          resolutionTimes.push(differenceInMinutes(new Date(ticket.plannedAt), new Date(ticket.createdAt!)) / 60);
        }
      }
      return resolutionTimes;
    }, []);
    if (!isEmpty(resolutionTimes)) {
      const averageResolutionTime = sum(resolutionTimes) / resolutionTimes.length;

      acc.push({ x: xTickValue, y: averageResolutionTime });
      return acc;
    }
    acc.push({ x: xTickValue, y: 0 });
    return acc;
  }, []);

  const responseTimeLineData = xTickValues.reduce((acc: Point2D[], xTickValue) => {
    const startDate = new Date(xTickValue);
    let endDate = endOfMonth(startDate);
    if (xTickFormatString === 'yyyy') {
      endDate = endOfYear(startDate);
    } else if (xTickFormatString === 'd/LL') {
      endDate = endOfDay(startDate);
    }
    const responseTimes = ticketsAll.reduce((responseTimes: number[], ticket) => {
      if (
        isNil(ticket.status) ||
        !isNil(ticket.parentId) ||
        (!isNil(ticket.unit) && !unitIds.includes(ticket.unit.id)) ||
        (!isNil(ticket.building) && !buildingIds.includes(ticket.building.id))
      ) {
        return responseTimes;
      }
      if (
        isAfter(new Date(ticket.createdAt!), startDate) &&
        isAfter(endDate, new Date(ticket.createdAt!)) &&
        !isNil(ticket.conversations) &&
        !isEmpty(ticket.conversations)
      ) {
        ticket.conversations.sort((a, b) => new Date(a.createdAt!).getTime() - new Date(b.createdAt!).getTime());
        const oneTicketResults = ticket.conversations.reduce(
          (firstMessageTimeAndValues: FirstMessageTimeAndValues, conversation) => {
            if (
              !isNil(conversation.contact) &&
              contactContainsType(conversation.contact, ContactType.MEMBER) &&
              !isNil(firstMessageTimeAndValues.messageTime)
            ) {
              const time =
                differenceInMinutes(new Date(conversation.createdAt!), firstMessageTimeAndValues.messageTime) / 60;
              firstMessageTimeAndValues.values.push(time);
              firstMessageTimeAndValues.messageTime = null;
            }
            if (
              !isNil(conversation.contact) &&
              !contactContainsType(conversation.contact, ContactType.MEMBER) &&
              isNil(firstMessageTimeAndValues.messageTime)
            ) {
              firstMessageTimeAndValues.messageTime = new Date(conversation.createdAt!);
            }
            return firstMessageTimeAndValues;
          },
          { messageTime: new Date(ticket.createdAt!), values: [] }
        );
        if (!isEmpty(oneTicketResults.values)) {
          responseTimes.push(sum(oneTicketResults.values) / oneTicketResults.values.length);
        }
      }
      return responseTimes;
    }, []);

    if (!isEmpty(responseTimes)) {
      const averageResponseTime = sum(responseTimes) / responseTimes.length;
      acc.push({ x: xTickValue, y: averageResponseTime });
      return acc;
    }
    acc.push({ x: xTickValue, y: 0 });
    return acc;
  }, []);

  const data = [assignationTimeLineData, closureTimeLineData, responseTimeLineData, resolutionTimeLineData];

  const metricNames = [
    formatMessage({ id: 'dashboard.ticket.assignationTime' }),
    formatMessage({ id: 'dashboard.ticket.closingTime' }),
    formatMessage({ id: 'dashboard.ticket.responseTime' }),
    formatMessage({ id: 'dashboard.ticket.resolutionTime' }),
  ];

  return (
    <MetricBox
      data-test="ticketDashboardBox"
      boxKey={TICKETS_METRICS_BOX_KEY}
      title={formatMessage({ id: 'dashboard.cellNames.ticketsSolved' })}
      timeFilterBundle={timeFilters}
      forceUpdateLayout={forceUpdateLayout}
      loading={unitsLoading || buildingsLoading || ticketsLoading}
    >
      <LineChart
        datas={data}
        lineNames={metricNames}
        xOptions={{
          minX: fromDate.getTime(),
          maxX: toDate.getTime(),
          xTickValues,
          xTickFormat: (x: number) =>
            format(new Date(x), xTickFormatString, { locale: (dateLocaleMap as dateLocaleMapType)[language] }),
          xTooltipFormat: (x: number) =>
            format(new Date(x), tooltipDateFormat, { locale: (dateLocaleMap as dateLocaleMapType)[language] }),
        }}
        yOptions={{
          minY: 0,
          maxY: getMaxYInBase10(data) === 0 ? 10 : getMaxYInBase10(data),
          numberOfYTicks: 6,
          yTickFormat: (y: number) => `${y}h`,
          yTooltipFormat: (y: number) => `${Math.round(y * 100) / 100}h`,
        }}
        curved={false}
      />
    </MetricBox>
  );
};

export default DashboardMetricTickets;
