/* eslint-disable default-case */
import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { isNil } from 'lodash';
import { formatDistanceToNow } from 'date-fns/esm';
import { Grid, ListItem, Typography } from '@material-ui/core';
import {
  CustomSubscription,
  Colors,
  getLocaleFromLanguage,
  CheckAndConsumeCreditsResponseStatus,
} from '@rentguru/commons-utils';
import { useCustomSubscriptions } from 'src/hooks/CustomSubscriptionContext';
import { useUser } from 'src/hooks/UserContext';
import { ReactComponent as SettingsIcon } from 'src/icons/lease-action-charge.svg';
import { RouteDestination } from 'src/components/Routes/Routes';
import NotificationDeleteIconButton from 'src/components/ui/CustomIconButtons/NotificiationDeleteIconButton';
import { useNotificationListStyle } from './NotificationsList';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';

export interface NotificationsCreditsItemProps {
  action: 'checkAndConsumeCredits';
  status: CheckAndConsumeCreditsResponseStatus;
  creditsBalance: number;
  customSubscription: CustomSubscription;
}

const NotificationsCreditsItem: React.FC<NotificationsCreditsItemProps> = ({ status, customSubscription }) => {
  const { formatMessage } = useIntl();
  const localStyles = useNotificationListStyle();
  const { language } = useUser();
  const { deleteCustomSubscription } = useCustomSubscriptions();
  const { settingsManagementWrite } = usePermissions();

  if (status !== CheckAndConsumeCreditsResponseStatus.NOT_ENOUGH_CREDITS) {
    return null;
  }

  return (
    <ListItem className={localStyles.listItem}>
      <Grid>
        <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            <SettingsIcon
              style={{
                transform: 'scale(0.6)',
              }}
            />

            <Typography variant="h6" style={{ color: Colors.CLASSICAL_BLACK, textJustify: 'inter-word' }}>
              {formatMessage({
                id: `settings.credits.notification.title`,
              })}
            </Typography>
          </Grid>
          <NotificationDeleteIconButton
            onClick={() => {
              deleteCustomSubscription(customSubscription);
            }}
          />
        </Grid>
        {!isNil(customSubscription.createdAt) && (
          <Typography style={{ color: Colors.BLUEY, paddingBottom: 5 }}>
            {formatDistanceToNow(new Date(customSubscription.createdAt!), {
              locale: getLocaleFromLanguage(language || 'en'),
            })}
          </Typography>
        )}
        <Grid style={{ color: Colors.SLATE_GREY }}>
          <Typography>
            {formatMessage({ id: `settings.credits.notification.body1` })}
            {settingsManagementWrite && (
              <Link
                to={`${RouteDestination.SETTINGS}`}
                onClick={(e) => e.stopPropagation()}
                style={{ color: Colors.DODGER_BLUE, textDecoration: 'underline', cursor: 'pointer' }}
              >
                {formatMessage({ id: `settings.credits.notification.body2` })}
              </Link>
            )}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default NotificationsCreditsItem;
