/* eslint-disable no-redeclare */
import React, { useReducer, useEffect, useContext, useRef, Reducer, useMemo } from 'react';
import { useUser } from './UserContext';
import { deleteAndHideEntity, list, mutation, NUMBER_OF_MINUTES_FOR_REFETCH } from '@up2rent/fetch-utils';
import {
  VariousOperation,
  CreateVariousOperationInput,
  CreateVariousOperationMutationVariables,
  DeleteVariousOperationMutationVariables,
  UpdateVariousOperationInput,
  createVariousOperation as createMutation,
  updateVariousOperation as updateMutation,
  deleteVariousOperation as deleteMutation,
  syncVariousOperations,
  cleanInputCreate,
  cleanInputUpdate,
  getReadId,
  getTableClientId,
} from '@rentguru/commons-utils';
import { isNil } from 'lodash';
import { usePermissions } from './utils/PermissionsContext';
import { differenceInMinutes } from 'date-fns';

interface VariousOperationsState {
  loading: boolean;
  error: string | null;
  shouldFetchVariousOperations: boolean;
  variousOperations: VariousOperation[] | null;
  lastFetchForVariousOperation: Date | null;
}

interface VariousOperationsContext extends VariousOperationsState {
  setFetchVariousOperations: () => void;
  getVariousOperation: (id: string) => VariousOperation | null | undefined;
  createVariousOperation: (
    input: Omit<CreateVariousOperationInput, 'clientId' | 'readId'>
  ) => Promise<VariousOperation>;
  updateVariousOperation: (updates: UpdateVariousOperationInput) => Promise<VariousOperation>;
  deleteVariousOperation: (variousOperation: VariousOperation) => Promise<VariousOperation | null>;
}

type Action =
  | {
      type: 'SHOULD_FETCH' | 'IS_FETCHING';
    }
  | {
      type: 'FETCHED';

      payload: { variousOperations: VariousOperation[] };
    }
  | {
      type: 'ADD_VARIOUSOPERATION' | 'UPDATE_VARIOUSOPERATION';

      payload: { variousOperation: VariousOperation };
    }
  | {
      type: 'DELETE_VARIOUSOPERATION';

      payload: { id: string };
    };

const initialState: VariousOperationsState = {
  loading: false,
  error: null,
  shouldFetchVariousOperations: false,
  variousOperations: null,
  lastFetchForVariousOperation: null,
};

const variousOperationsReducer = (state: VariousOperationsState, action: Action): VariousOperationsState => {
  switch (action.type) {
    case 'SHOULD_FETCH':
      // Check //lastFetchForVariousOperation
      if (
        state.loading ||
        state.shouldFetchVariousOperations ||
        (state.lastFetchForVariousOperation &&
          differenceInMinutes(new Date(), state.lastFetchForVariousOperation) < NUMBER_OF_MINUTES_FOR_REFETCH)
      ) {
        return state;
      }
      return {
        ...state,
        shouldFetchVariousOperations: true,
      };
    case 'IS_FETCHING':
      return {
        ...state,
        loading: true,
      };
    case 'FETCHED':
      return {
        ...state,
        loading: false,
        shouldFetchVariousOperations: false,
        variousOperations: action.payload.variousOperations,
        lastFetchForVariousOperation: new Date(),
      };
    case 'ADD_VARIOUSOPERATION':
      if (!state.variousOperations) {
        return state;
      }
      return {
        ...state,
        variousOperations: [...state.variousOperations, action.payload.variousOperation],
      };
    case 'UPDATE_VARIOUSOPERATION':
      if (!state.variousOperations) {
        return state;
      }
      return {
        ...state,
        variousOperations: state.variousOperations.map((vo) => {
          if (vo.id === action.payload.variousOperation.id) {
            return action.payload.variousOperation;
          }
          return vo;
        }),
      };
    case 'DELETE_VARIOUSOPERATION':
      if (!state.variousOperations) {
        return state;
      }
      return {
        ...state,
        variousOperations: state.variousOperations.filter((vo) => vo.id !== action.payload.id),
      };
    default:
      return state;
  }
};

export const VariousOperationsContext = React.createContext<VariousOperationsContext | null>(null);

export const VariousOperationsContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { clientId } = useUser();
  const { financialTransactionsDelete } = usePermissions();
  const [state, dispatch] = useReducer<Reducer<VariousOperationsState, Action>>(variousOperationsReducer, initialState);

  const fetchVariousOperations = async (): Promise<VariousOperation[]> => {
    return await list<VariousOperation>(
      syncVariousOperations,
      'clientId',
      getTableClientId(clientId!, 'VariousOperation')
    );
  };

  const setFetchVariousOperations = () => {
    dispatch({ type: 'SHOULD_FETCH' });
  };

  useEffect(() => {
    const fetchAndSet = async () => {
      dispatch({ type: 'IS_FETCHING' });
      const result = await fetchVariousOperations();
      dispatch({ type: 'FETCHED', payload: { variousOperations: result } });
    };
    if (state.shouldFetchVariousOperations) fetchAndSet();
    // eslint-disable-next-line
  }, [state.shouldFetchVariousOperations]);

  const getVariousOperation = (id: string) => {
    if (state.loading || state.error || !state.variousOperations) {
      return null;
    }
    return state.variousOperations.find((vo) => vo.id === id);
  };

  const createVariousOperation = async (
    input: Omit<CreateVariousOperationInput, 'clientId' | 'readId'>
  ): Promise<VariousOperation> => {
    const result = await mutation<VariousOperation, CreateVariousOperationMutationVariables>(createMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateVariousOperationInput),
        readId: getReadId(clientId!, 'VariousOperation'),
        clientId: getTableClientId(clientId!, 'VariousOperation'),
      },
    });
    dispatch({ type: 'ADD_VARIOUSOPERATION', payload: { variousOperation: result } });
    dispatch({ type: 'SHOULD_FETCH' });
    return result;
  };

  const updateVariousOperation = async (updates: UpdateVariousOperationInput): Promise<VariousOperation> => {
    const result = await mutation<VariousOperation>(updateMutation, {
      input: cleanInputUpdate(updates, false),
    });
    dispatch({ type: 'UPDATE_VARIOUSOPERATION', payload: { variousOperation: result } });
    dispatch({ type: 'SHOULD_FETCH' });
    return result;
  };

  const deleteVariousOperation = async (variousOperation: VariousOperation): Promise<VariousOperation | null> => {
    if (!financialTransactionsDelete) {
      return null;
    }
    const result = await deleteAndHideEntity<VariousOperation, DeleteVariousOperationMutationVariables>(
      variousOperation,
      deleteMutation,
      updateMutation
    );

    dispatch({ type: 'DELETE_VARIOUSOPERATION', payload: { id: variousOperation.id } });
    return result;
  };

  const values = useMemo(
    () => ({
      ...state,
      setFetchVariousOperations,
      getVariousOperation,
      createVariousOperation,
      updateVariousOperation,
      deleteVariousOperation,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <VariousOperationsContext.Provider value={values}>{children}</VariousOperationsContext.Provider>;
};

export const useVariousOperations = (): VariousOperationsContext => {
  const firstRender = useRef<boolean>(false);
  const context = useContext<VariousOperationsContext | null>(VariousOperationsContext);

  if (isNil(context)) {
    throw new Error('`useVariousOperations` hook must be used within a `VariousOperationsContext` component');
  }

  useEffect(() => {
    if (!firstRender.current) context.setFetchVariousOperations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  return context;
};
