/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete, AutocompleteGetTagProps } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { isArray, isNil } from 'lodash';
import { ReactComponent as DropDown } from '../../../icons/drop-down.svg';
import { useIntl } from 'react-intl';
import isEqual from 'lodash/isEqual';
import { useCustomizedComboStyles } from './TextComboBox';
import { useStyles as disabledStyles } from '../CustomDatePicker';

export interface CustomInputItemType {
  value: any;
  [key: string]: any;
}
/* Standard ComboBox, if you want to use it with Formik use the FormikComboBox instead */
export interface CustomInputComboBoxProps {
  options: CustomInputItemType[];
  value: CustomInputItemType | CustomInputItemType[];
  label: string;
  inputStyle?: React.CSSProperties;
  paperClass?: string;
  renderOption: (value: CustomInputItemType) => React.ReactNode;
  renderInput: (value: CustomInputItemType) => React.ReactNode;
  renderTags?: (values: CustomInputItemType[], getTagProps: AutocompleteGetTagProps) => React.ReactNode;
  onChange: (event: any, value: CustomInputItemType[] | null) => void;
  inputAdornment?: React.ReactNode;
  stringToCompare?: (value: CustomInputItemType) => string;
  getOptionSelected?: (option1: CustomInputItemType, option2: CustomInputItemType) => boolean;
  error?: boolean;
  noOptionsText?: string;
  disabled?: boolean;
  groupBy?: keyof CustomInputItemType;
  disableClearable?: boolean;
  disableCloseOnSelect?: boolean;
  clearOnEscape?: boolean;
  id?: string;
  dataTest?: string;
  inputStartAdornment?: (value: CustomInputItemType[] | CustomInputItemType) => React.ReactNode;
}

export const useStyles = makeStyles({
  paper: {
    minWidth: '300px',
  },
  popper: {
    minWidth: '300px',
  },
  inputRoot: {
    '& $input': { minWidth: '0px !important' },
  },
  tag: {
    margin: 2,
  },
});

const defaultRenderTags = (
  values: CustomInputItemType[],
  getTagProps: AutocompleteGetTagProps,
  renderInput: (value: CustomInputItemType) => React.ReactNode
) => {
  const value = values[0];
  const tagProps = { ...getTagProps({ index: 0 }) };
  // @ts-ignore
  delete tagProps.onDelete;
  return (
    <div style={{ display: 'flex', alignItems: 'center' }} {...tagProps}>
      {renderInput(value)}
    </div>
  );
};

const CustomInputComboBox: React.FC<CustomInputComboBoxProps> = ({
  options,
  value,
  label,
  inputStyle = {},
  paperClass = '',
  renderOption,
  renderInput,
  renderTags,
  stringToCompare,
  getOptionSelected,
  inputAdornment,
  onChange,
  error = false,
  noOptionsText,
  disabled = false,
  disableClearable = true,
  disableCloseOnSelect = false,
  clearOnEscape = true,
  groupBy,
  id,
  dataTest = 'CustomInputComboBox',
  inputStartAdornment,
}) => {
  const disabledClasses = disabledStyles();
  const classes = useCustomizedComboStyles();
  const localClasses = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Autocomplete
      multiple
      clearOnEscape={clearOnEscape}
      options={options}
      classes={{
        option: classes.option,
        paper: paperClass !== '' ? paperClass : localClasses.paper,
        inputRoot: localClasses.inputRoot,
        tag: localClasses.tag,
        popper: localClasses.popper,
      }}
      value={isArray(value) ? value : isNil(value) || isNil(value.value) ? [] : [value]}
      autoHighlight
      getOptionSelected={
        !isNil(getOptionSelected) ? getOptionSelected : (option, value) => isEqual(option.value, value.value)
      }
      getOptionLabel={isNil(stringToCompare) ? (option) => option.value : stringToCompare}
      popupIcon={<DropDown className={classes.icon} />}
      renderOption={renderOption}
      onChange={onChange}
      disableClearable={disableClearable}
      noOptionsText={!isNil(noOptionsText) ? noOptionsText : formatMessage({ id: 'comboBox.noOptions' })}
      disabled={disabled}
      groupBy={!isNil(groupBy) ? (option) => option[groupBy] : undefined}
      renderInput={(params) => {
        return (
          <>
            <TextField
              {...params}
              label={label}
              margin="dense"
              variant="filled"
              style={{ ...inputStyle }}
              inputProps={{
                ...params.inputProps,
              }}
              InputProps={{
                ...params.InputProps,
                classes: { underline: classes.underline },
                endAdornment: isNil(inputAdornment) ? (
                  params.InputProps.endAdornment
                ) : (
                  <>
                    {inputAdornment}
                    {params.InputProps.endAdornment}
                  </>
                ),
                startAdornment: isNil(inputStartAdornment) ? (
                  params.InputProps.startAdornment
                ) : (
                  <>
                    {inputStartAdornment(value)}
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
              InputLabelProps={{ className: classes.label }}
              SelectProps={{
                classes: { select: classes.select, root: disabledClasses.root, disabled: disabledClasses.disabled },
              }}
              error={Boolean(error)}
            />
          </>
        );
      }}
      renderTags={(values, getTagProps) => {
        if (renderTags) {
          return renderTags(values, getTagProps);
        }
        return defaultRenderTags(values, getTagProps, renderInput);
      }}
      disableCloseOnSelect={disableCloseOnSelect}
      id={id}
      data-test={dataTest}
    />
  );
};

export default CustomInputComboBox;
