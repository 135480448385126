import React from 'react';
import { ActionButton } from '@up2rent/ui';
import { useIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { CreateClientFormValues } from './CreateAccount';
import { ReactComponent as OwnerIcon } from 'src/icons/owner.svg';
import { ReactComponent as AgencyIcon } from 'src/icons/agency.svg';
import { ReactComponent as FundIcon } from 'src/icons/fund.svg';
import { AccountType, Colors } from '@rentguru/commons-utils';
import AccountTypeItem from 'src/components/ui/AccountTypeItem';

interface SelectAccountProps {
  goToNextStep: () => void;
}

const SelectAccount: React.FC<SelectAccountProps> = ({ goToNextStep }) => {
  const { formatMessage } = useIntl();
  const { values, setFieldValue } = useFormikContext<CreateClientFormValues>();
  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Typography style={{ fontWeight: 800, fontSize: 18, marginBottom: 10 }} data-test="select-account-type">
          {formatMessage({ id: 'signup.selectAccountTypeTitle' })}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ fontSize: 14, color: Colors.SLATE_GREY }}>
          {formatMessage({ id: 'signup.selectAccountTypeSubtitle' })}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <AccountTypeItem
          Icon={OwnerIcon}
          onClick={() => setFieldValue('accountType', AccountType.OWNER)}
          mainText={formatMessage({ id: 'building.detail.general.owner' })}
          subText={formatMessage({ id: 'signup.ownerSubText' })}
          selected={values.accountType === AccountType.OWNER}
          style={{ marginTop: 40 }}
        />
      </Grid>
      <Grid item xs={12}>
        <AccountTypeItem
          Icon={AgencyIcon}
          onClick={() => {
            setFieldValue('accountType', AccountType.AGENCY);
            setFieldValue('privateUser', false);
          }}
          mainText={formatMessage({ id: 'lease.addLease.realEstateAgency' })}
          subText={formatMessage({ id: 'signup.agencySubText' })}
          selected={values.accountType === AccountType.AGENCY}
          style={{ marginTop: 20 }}
        />
      </Grid>
      <Grid item xs={12}>
        <AccountTypeItem
          Icon={FundIcon}
          onClick={() => {
            setFieldValue('accountType', AccountType.FUND);
          }}
          mainText={formatMessage({ id: 'signup.realEstateFund' })}
          subText={formatMessage({ id: 'signup.fundSubText' })}
          selected={values.accountType === AccountType.FUND}
          style={{ marginTop: 20, marginBottom: 30 }}
        />
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ActionButton
          style={{ minWidth: 120, height: 50, fontSize: 16, fontWeight: 800 }}
          onClick={goToNextStep}
          data-test="account-type-next-button"
        >
          {formatMessage({ id: 'next' })}
        </ActionButton>
      </Grid>
    </Grid>
  );
};

export default SelectAccount;
