import React from 'react';
import { IntegrationName } from '@rentguru/commons-utils';
import myBanx from '../../../icons/integration_mybanx.png';
import sageBob from '../../../icons/integration_sagebob50.png';
import openAI from '../../../icons/integration_openai.svg';
import { ReactComponent as Ponto } from '../../../icons/ponto.svg';

interface IntegrationLogoProps {
  name: IntegrationName;
  height?: number;
}

const IntegrationLogo: React.FC<IntegrationLogoProps> = ({ name, height }) => {
  if (name === IntegrationName.PONTO) {
    return <Ponto style={{ height: height ?? 50 }} />;
  }
  if (name === IntegrationName.MYBANX) {
    return (
      <img
        src={myBanx}
        style={{
          height: height ?? 25,
          display: 'inline-block',
          background: '#000',
          marginTop: 10,
          padding: 2,
        }}
        alt=""
      />
    );
  }
  if (name === IntegrationName.SAGE_BOB50) {
    return <img src={sageBob} style={{ height: height ?? 20, display: 'inline-block', paddingTop: 10 }} alt="" />;
  }
  if (name === IntegrationName.OCR) {
    return <img src={openAI} style={{ height: height ?? 30, display: 'inline-block', paddingTop: 10 }} alt="" />;
  }

  return null;
};

export default IntegrationLogo;
