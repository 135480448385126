import Dialog from 'src/components/ui/Dialog';
import React, { useState } from 'react';
import { UnitEvent, UnitEventType } from '@rentguru/commons-utils';
import AddRenovationForm from '../../FinancialDashboard/Forms/AddRenovationForm';
import EditRenovationForm from '../../FinancialDashboard/Forms/EditRenovationForm';
import EditOwnUseForm from '../../RentalUnits/Events/EditOwnUseForm';
import AddOwnUseForm from '../../RentalUnits/Events/AddOwnUseForm';

interface AddEditUnitEventDialogProps {
  open: boolean;
  eventType: UnitEventType;
  onClose: () => void;
  eventToEdit: UnitEvent | null;
  startDate?: string;
  endDate?: string;
  unitId: string;
}

const AddEditUnitEventDialog: React.FC<AddEditUnitEventDialogProps> = ({
  open,
  eventType,
  onClose,
  eventToEdit,
  startDate,
  endDate,
  unitId,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="body"
      fullWidth
      PaperProps={{ style: { borderRadius: 10, maxWidth: 620 } }}
    >
      {open && !eventToEdit && eventType === UnitEventType.WORK && (
        <AddRenovationForm
          unitId={unitId}
          afterSubmit={onClose}
          cancelSubmit={onClose}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      {open && !eventToEdit && eventType === UnitEventType.OWN_USE && (
        <AddOwnUseForm
          unitId={unitId}
          afterSubmit={onClose}
          cancelSubmit={onClose}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      {open && eventToEdit && eventType === UnitEventType.WORK && (
        <EditRenovationForm event={eventToEdit} afterSubmit={onClose} cancelSubmit={onClose} />
      )}
      {open && eventToEdit && eventType === UnitEventType.OWN_USE && (
        <EditOwnUseForm event={eventToEdit} afterSubmit={onClose} cancelSubmit={onClose} />
      )}
    </Dialog>
  );
};

export default AddEditUnitEventDialog;

export const useAddEditUnitEventDialog = () => {
  const [addEditUnitEventDialog, setAddEditUnitEventDialog] = useState<
    Omit<AddEditUnitEventDialogProps, 'onClose' | 'unitId'>
  >({ eventToEdit: null, eventType: UnitEventType.WORK, open: false, startDate: undefined, endDate: undefined });
  const handleOpenAddUnitEventDialog = (type: UnitEventType, startDate?: string, endDate?: string) => {
    setAddEditUnitEventDialog({ eventToEdit: null, eventType: type, open: true, startDate, endDate });
  };
  const handleOpenEditUnitEventDialog = (event: UnitEvent) => {
    setAddEditUnitEventDialog({
      eventToEdit: event,
      eventType: event.type as UnitEventType,
      open: true,
      startDate: event.startDate,
      endDate: event.endDate,
    });
  };
  const handleCloseAddEditUnitEventDialog = () =>
    setAddEditUnitEventDialog({
      eventToEdit: null,
      eventType: UnitEventType.WORK,
      open: false,
      startDate: undefined,
      endDate: undefined,
    });

  return {
    ...addEditUnitEventDialog,
    handleOpenAddUnitEventDialog,
    handleOpenEditUnitEventDialog,
    handleCloseAddEditUnitEventDialog,
  };
};
