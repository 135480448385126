import { FormControlLabel, Grid, makeStyles, Typography } from '@material-ui/core';
import { Colors, AmenitiesType } from '@rentguru/commons-utils';
import { useFormikContext } from 'formik';
import { isNil } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import CustomCheckBox from 'src/components/ui/CustomCheckBox';
import { EditAdvertisementFormValues } from '../EditPublicationForm';

const useLocalStyles = makeStyles({
  customCheckBox: {
    '&checked': {
      color: 'red',
    },
  },
  title: {
    flex: 1,
    textAlign: 'left' as const,
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Mulish',
    fontSize: '16px',
    fontWeight: 700,
    margin: '10px 0 15px 30px',
  },
});

const AmenitiesEdit = () => {
  const { values, setFieldValue } = useFormikContext<EditAdvertisementFormValues>();
  const { formatMessage } = useIntl();
  const classes = useLocalStyles();

  return (
    <>
      <Typography variant="h6" className={classes.title}>
        {formatMessage({ id: 'rentalUnit.detail.inside.amenities.amenities' })}
      </Typography>
      <Grid container>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-start', position: 'relative', left: 30 }}>
          <FormControlLabel
            control={
              <CustomCheckBox
                secondary={true}
                isChecked={
                  !isNil(values) &&
                  !isNil(values.advertisement) &&
                  !isNil(values.advertisement.amenities) &&
                  !isNil(
                    values.advertisement.amenities[values.advertisement.amenities.indexOf(AmenitiesType.DOOR_PHONE)]
                  )
                }
                onCheck={() => {
                  if (!isNil(values) && !isNil(values.advertisement)) {
                    const amenitiesArray: Array<AmenitiesType> = values.advertisement.amenities
                      ? (values.advertisement.amenities as Array<AmenitiesType>)
                      : // eslint-disable-next-line no-array-constructor
                        new Array<AmenitiesType>();
                    const indexOfDoorPhone = amenitiesArray.indexOf(AmenitiesType.DOOR_PHONE);
                    if (indexOfDoorPhone !== -1) {
                      amenitiesArray.splice(indexOfDoorPhone, 1);
                      setFieldValue('advertisement.amenities', amenitiesArray);
                    } else {
                      setFieldValue('advertisement.amenities', amenitiesArray.concat(AmenitiesType.DOOR_PHONE));
                    }
                  }
                }}
                name="doorPhone"
              />
            }
            label={formatMessage({ id: 'rentalUnit.detail.inside.amenities.doorPhone' })}
            name="doorPhone"
            id="doorPhone"
          />
        </Grid>

        <Grid item style={{ display: 'flex', justifyContent: 'flex-start', position: 'relative', left: 30 }}>
          <FormControlLabel
            control={
              <CustomCheckBox
                secondary={true}
                isChecked={
                  !isNil(values) &&
                  !isNil(values.advertisement) &&
                  !isNil(values.advertisement.amenities) &&
                  !isNil(values.advertisement.amenities[values.advertisement.amenities.indexOf(AmenitiesType.SAUNA)])
                }
                onCheck={() => {
                  if (!isNil(values) && !isNil(values.advertisement) && !isNil(values.advertisement.amenities)) {
                    const amenitiesArray: Array<AmenitiesType> = values.advertisement.amenities as Array<AmenitiesType>;
                    const indexOfSauna = amenitiesArray.indexOf(AmenitiesType.SAUNA);
                    if (indexOfSauna !== -1) {
                      amenitiesArray.splice(indexOfSauna, 1);
                      setFieldValue('advertisement.amenities', amenitiesArray);
                    } else {
                      setFieldValue('advertisement.amenities', amenitiesArray.concat(AmenitiesType.SAUNA));
                    }
                  }
                }}
                name="sauna"
              />
            }
            label={formatMessage({ id: 'rentalUnit.detail.inside.amenities.sauna' })}
            id="sauna"
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-start', position: 'relative', left: 30 }}>
          <FormControlLabel
            control={
              <CustomCheckBox
                secondary={true}
                isChecked={
                  !isNil(values) &&
                  !isNil(values.advertisement) &&
                  !isNil(values.advertisement.amenities) &&
                  !isNil(
                    values.advertisement.amenities[values.advertisement.amenities.indexOf(AmenitiesType.TV_CONNECTION)]
                  )
                }
                onCheck={() => {
                  if (!isNil(values) && !isNil(values.advertisement) && !isNil(values.advertisement.amenities)) {
                    const amenitiesArray: Array<AmenitiesType> = values.advertisement.amenities as Array<AmenitiesType>;
                    const indexOfTvConnection = amenitiesArray.indexOf(AmenitiesType.TV_CONNECTION);
                    if (indexOfTvConnection !== -1) {
                      amenitiesArray.splice(indexOfTvConnection, 1);
                      setFieldValue('advertisement.amenities', amenitiesArray);
                    } else {
                      setFieldValue('advertisement.amenities', amenitiesArray.concat(AmenitiesType.TV_CONNECTION));
                    }
                  }
                }}
                name="tvConnection"
              />
            }
            label={formatMessage({ id: 'rentalUnit.detail.inside.amenities.tvConnection' })}
            id="tvConnection"
          />
        </Grid>

        <Grid item style={{ display: 'flex', justifyContent: 'flex-start', position: 'relative', left: 30 }}>
          <FormControlLabel
            control={
              <CustomCheckBox
                secondary={true}
                isChecked={
                  !isNil(values) &&
                  !isNil(values.advertisement) &&
                  !isNil(values.advertisement.amenities) &&
                  !isNil(values.advertisement.amenities[values.advertisement.amenities.indexOf(AmenitiesType.JACUZZI)])
                }
                onCheck={() => {
                  if (!isNil(values) && !isNil(values.advertisement) && !isNil(values.advertisement.amenities)) {
                    const amenitiesArray: Array<AmenitiesType> = values.advertisement.amenities as Array<AmenitiesType>;
                    const indexOfJaccuzi = amenitiesArray.indexOf(AmenitiesType.JACUZZI);
                    if (indexOfJaccuzi !== -1) {
                      amenitiesArray.splice(indexOfJaccuzi, 1);
                      setFieldValue('advertisement.amenities', amenitiesArray);
                    } else {
                      setFieldValue('advertisement.amenities', amenitiesArray.concat(AmenitiesType.JACUZZI));
                    }
                  }
                }}
                name="jacuzzi"
              />
            }
            label={formatMessage({ id: 'rentalUnit.detail.inside.amenities.jacuzzi' })}
            id="jacuzzi"
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-start', position: 'relative', left: 30 }}>
          <FormControlLabel
            control={
              <CustomCheckBox
                secondary={true}
                isChecked={
                  !isNil(values) &&
                  !isNil(values.advertisement) &&
                  !isNil(values.advertisement.amenities) &&
                  !isNil(values.advertisement.amenities[values.advertisement.amenities.indexOf(AmenitiesType.ALARM)])
                }
                onCheck={() => {
                  if (!isNil(values) && !isNil(values.advertisement) && !isNil(values.advertisement.amenities)) {
                    const amenitiesArray: Array<AmenitiesType> = values.advertisement.amenities as Array<AmenitiesType>;
                    const indexOfAlarm = amenitiesArray.indexOf(AmenitiesType.ALARM);
                    if (indexOfAlarm !== -1) {
                      amenitiesArray.splice(indexOfAlarm, 1);
                      setFieldValue('advertisement.amenities', amenitiesArray);
                    } else {
                      setFieldValue('advertisement.amenities', amenitiesArray.concat(AmenitiesType.ALARM));
                    }
                  }
                }}
                name="alarm"
              />
            }
            label={formatMessage({ id: 'rentalUnit.detail.inside.amenities.alarm' })}
            id="alarm"
          />
        </Grid>

        <Grid item style={{ display: 'flex', justifyContent: 'flex-start', position: 'relative', left: 30 }}>
          <FormControlLabel
            control={
              <CustomCheckBox
                secondary={true}
                isChecked={
                  !isNil(values) &&
                  !isNil(values.advertisement) &&
                  !isNil(values.advertisement.amenities) &&
                  !isNil(
                    values.advertisement.amenities[
                      values.advertisement.amenities.indexOf(AmenitiesType.SMALL_PETS_ALLOWED)
                    ]
                  )
                }
                onCheck={() => {
                  if (!isNil(values) && !isNil(values.advertisement) && !isNil(values.advertisement.amenities)) {
                    const amenitiesArray: Array<AmenitiesType> = values.advertisement.amenities as Array<AmenitiesType>;
                    const indexOfSPetsAllowed = amenitiesArray.indexOf(AmenitiesType.SMALL_PETS_ALLOWED);
                    if (indexOfSPetsAllowed !== -1) {
                      amenitiesArray.splice(indexOfSPetsAllowed, 1);
                      setFieldValue('advertisement.amenities', amenitiesArray);
                    } else {
                      setFieldValue('advertisement.amenities', amenitiesArray.concat(AmenitiesType.SMALL_PETS_ALLOWED));
                    }
                  }
                }}
                name="sPetsAllowed"
              />
            }
            label={formatMessage({ id: 'rentalUnit.detail.inside.amenities.sPetsAllowed' })}
            id="smallPetsAllowed"
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-start', position: 'relative', left: 30 }}>
          <FormControlLabel
            control={
              <CustomCheckBox
                secondary={true}
                isChecked={
                  !isNil(values) &&
                  !isNil(values.advertisement) &&
                  !isNil(values.advertisement.amenities) &&
                  !isNil(
                    values.advertisement.amenities[values.advertisement.amenities.indexOf(AmenitiesType.ARMOURED_DOOR)]
                  )
                }
                onCheck={() => {
                  if (!isNil(values) && !isNil(values.advertisement) && !isNil(values.advertisement.amenities)) {
                    const amenitiesArray: Array<AmenitiesType> = values.advertisement.amenities as Array<AmenitiesType>;
                    const indexOfSArmouredDoor = amenitiesArray.indexOf(AmenitiesType.ARMOURED_DOOR);
                    if (indexOfSArmouredDoor !== -1) {
                      amenitiesArray.splice(indexOfSArmouredDoor, 1);
                      setFieldValue('advertisement.amenities', amenitiesArray);
                    } else {
                      setFieldValue('advertisement.amenities', amenitiesArray.concat(AmenitiesType.ARMOURED_DOOR));
                    }
                  }
                }}
                name="armouredDoor"
              />
            }
            label={formatMessage({ id: 'rentalUnit.detail.inside.amenities.armouredDoor' })}
            id="armouredDoor"
          />
        </Grid>

        <Grid item style={{ display: 'flex', justifyContent: 'flex-start', position: 'relative', left: 30 }}>
          <FormControlLabel
            control={
              <CustomCheckBox
                secondary={true}
                isChecked={
                  !isNil(values) &&
                  !isNil(values.advertisement) &&
                  !isNil(values.advertisement.amenities) &&
                  !isNil(
                    values.advertisement.amenities[
                      values.advertisement.amenities.indexOf(AmenitiesType.LARGE_PETS_ALLOWED)
                    ]
                  )
                }
                onCheck={() => {
                  if (!isNil(values) && !isNil(values.advertisement) && !isNil(values.advertisement.amenities)) {
                    const amenitiesArray: Array<AmenitiesType> = values.advertisement.amenities as Array<AmenitiesType>;
                    const indexOfSLPetsAllowed = amenitiesArray.indexOf(AmenitiesType.LARGE_PETS_ALLOWED);
                    if (indexOfSLPetsAllowed !== -1) {
                      amenitiesArray.splice(indexOfSLPetsAllowed, 1);
                      setFieldValue('advertisement.amenities', amenitiesArray);
                    } else {
                      setFieldValue('advertisement.amenities', amenitiesArray.concat(AmenitiesType.LARGE_PETS_ALLOWED));
                    }
                  }
                }}
                name="lPetsAllowed"
              />
            }
            label={formatMessage({ id: 'rentalUnit.detail.inside.amenities.lPetsAllowed' })}
            id="largePetsAllowed"
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-start', position: 'relative', left: 30 }}>
          <FormControlLabel
            control={
              <CustomCheckBox
                secondary={true}
                isChecked={
                  !isNil(values) &&
                  !isNil(values.advertisement) &&
                  !isNil(values.advertisement.amenities) &&
                  !isNil(
                    values.advertisement.amenities[values.advertisement.amenities.indexOf(AmenitiesType.FIRE_PLACE)]
                  )
                }
                onCheck={() => {
                  if (!isNil(values) && !isNil(values.advertisement) && !isNil(values.advertisement.amenities)) {
                    const amenitiesArray: Array<AmenitiesType> = values.advertisement.amenities as Array<AmenitiesType>;
                    const indexOfSFirePlace = amenitiesArray.indexOf(AmenitiesType.FIRE_PLACE);
                    if (indexOfSFirePlace !== -1) {
                      amenitiesArray.splice(indexOfSFirePlace, 1);
                      setFieldValue('advertisement.amenities', amenitiesArray);
                    } else {
                      setFieldValue('advertisement.amenities', amenitiesArray.concat(AmenitiesType.FIRE_PLACE));
                    }
                  }
                }}
                name="firePlace"
              />
            }
            label={formatMessage({ id: 'rentalUnit.detail.inside.amenities.firePlace' })}
            id="firePlace"
          />
        </Grid>

        <Grid item />
      </Grid>
    </>
  );
};

export default AmenitiesEdit;
