/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-param-last */
import { useEffect, useRef, useState } from 'react';
import {
  StatementSetting,
  syncStatementSettings,
  getStatementSetting as getQuery,
  createStatementSetting as createMutation,
  updateStatementSetting as updateMutation,
  deleteStatementSetting as deleteMutation,
  CreateStatementSettingMutationVariables,
  DeleteStatementSettingMutationVariables,
  CreateStatementSettingInput,
  UpdateStatementSettingInput,
  cleanInputUpdate,
  cleanInputCreate,
  getReadId,
  getTableClientId,
  LooseObject,
} from '@rentguru/commons-utils';
import { deleteEntityWithFetchBefore, list, mutation } from '@up2rent/fetch-utils';
import { useUser } from './UserContext';

export interface StatementSettingContext {
  statementSettingsLoading: boolean;
  statementSettings: StatementSetting[];
  getStatementSetting: (id: string) => StatementSetting | undefined;
  createStatementSetting: (
    input: Omit<CreateStatementSettingInput, 'clientId' | 'readId'>
  ) => Promise<StatementSetting>;
  updateStatementSetting: (updates: UpdateStatementSettingInput) => Promise<StatementSetting>;
  deleteStatementSetting: (statementSetting: StatementSetting) => Promise<StatementSetting>;
}

export const fetchStatementSettings = async (
  by: 'byClientId' | 'byLeaseId' | 'byOwnerId',
  byValue: string,
  additionalFilter?: LooseObject
): Promise<StatementSetting[]> => {
  const indexName = by === 'byClientId' ? 'clientId' : by === 'byLeaseId' ? 'leaseId' : 'ownerId';
  return await list<StatementSetting>(syncStatementSettings, indexName, byValue, additionalFilter);
};

export const useStatementSettings = (
  shouldFetchStatementSetting: boolean = false,
  by: 'byClientId' | 'byLeaseId' | 'byOwnerId' = 'byClientId',
  byValue?: string
): StatementSettingContext => {
  const [statementSettingsLoading, setStatementSettingsLoading] = useState<boolean>(false);
  const [statementSettings, setStatementSettings] = useState<StatementSetting[]>([]);

  const firstRender = useRef<boolean>(false);
  const { clientId } = useUser();

  useEffect(() => {
    const buildStatementSettings = async () => {
      setStatementSettingsLoading(true);
      firstRender.current = true;
      const result = await fetchStatementSettings(by, byValue || getTableClientId(clientId!, 'StatementSetting'));
      setStatementSettings(result);
      setStatementSettingsLoading(false);
    };
    if (shouldFetchStatementSetting && !firstRender.current) buildStatementSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchStatementSetting, firstRender]);

  const getStatementSetting = (id: string): StatementSetting | undefined => {
    return statementSettings.find((statementSetting) => statementSetting.id === id);
  };

  const createStatementSetting = async (
    input: Omit<CreateStatementSettingInput, 'clientId' | 'readId'>
  ): Promise<StatementSetting> => {
    const statementSetting = await mutation<StatementSetting, CreateStatementSettingMutationVariables>(createMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateStatementSettingInput),
        readId: getReadId(clientId!, 'StatementSetting'),
        clientId: getTableClientId(clientId!, 'StatementSetting'),
      },
    });
    setStatementSettings((m) => [...m, statementSetting]);
    return statementSetting;
  };

  const updateStatementSetting = async (updates: UpdateStatementSettingInput): Promise<StatementSetting> => {
    const result = await mutation<StatementSetting>(updateMutation, {
      input: cleanInputUpdate(updates, false),
    });
    setStatementSettings((statementSettings) =>
      statementSettings.map((statementSetting) => {
        if (statementSetting.id === result.id) return result;
        return statementSetting;
      })
    );
    return result;
  };

  const deleteStatementSetting = async (statementSetting: StatementSetting): Promise<StatementSetting> => {
    const result = await deleteEntityWithFetchBefore<StatementSetting, DeleteStatementSettingMutationVariables>(
      statementSetting,
      getQuery,
      deleteMutation
    );

    setStatementSettings(statementSettings.filter((m) => m.id !== statementSetting.id));
    return result;
  };

  return {
    statementSettingsLoading,
    statementSettings,
    getStatementSetting,
    createStatementSetting,
    updateStatementSetting,
    deleteStatementSetting,
  };
};
