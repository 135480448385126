/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Unit, Colors } from '@rentguru/commons-utils';
import { Divider, Paper, Typography } from '@material-ui/core';

interface OwnerStatementUnitsPopperProps {
  units: Unit[];
}

const OwnerStatementUnitsPopper: React.FC<OwnerStatementUnitsPopperProps> = ({ units }) => {
  return (
    <Paper
      elevation={8}
      style={{
        position: 'absolute',
        margin: 0,
        zIndex: 1,
        maxHeight: 500,
        overflowY: 'auto',
        minHeight: 16,
        padding: 8,
        borderRadius: 10,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {units.map((unit, index) => {
        return (
          <React.Fragment key={index}>
            <Typography
              style={{
                fontSize: 12,
                fontWeight: 'normal',
                fontStyle: 'normal',
                letterSpacing: 0,
                color: Colors.SLATE_GREY,
                paddingTop: 16,
                paddingBottom: 16,
              }}
            >
              {unit.name}
            </Typography>
            {index !== units.length - 1 && <Divider />}
          </React.Fragment>
        );
      })}
    </Paper>
  );
};

export default OwnerStatementUnitsPopper;
