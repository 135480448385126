import { Typography } from '@material-ui/core';
import { LeasePriceHistory, getLeaseNextDueDate } from '@rentguru/commons-utils';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { CustomSimpleDialog } from '@up2rent/ui';
import { dateLocaleMap, useLocale } from 'src/i18n/IntlProviderWrapper';

interface ConfirmLeaseAdjustmentDialogProps {
  open: boolean;
  leaseChargeAdjustment: LeasePriceHistory;
  valideAdjustment: (leaseChargeAdjustment: LeasePriceHistory) => Promise<void>;
  onClose: () => void;
}

const ConfirmLeaseAdjustmentDialog: React.FC<ConfirmLeaseAdjustmentDialogProps> = ({
  open,
  leaseChargeAdjustment,
  valideAdjustment,
  onClose,
}) => {
  const { language } = useLocale();
  const { formatMessage } = useIntl();
  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const { lease } = leaseChargeAdjustment;
  const adjustmentDate = lease ? getLeaseNextDueDate(lease) : null;

  return (
    <CustomSimpleDialog
      open={open}
      onClose={onClose}
      onActionButtonClick={async () => {
        setValidationLoading(true);
        await valideAdjustment(leaseChargeAdjustment);
        setValidationLoading(false);
      }}
      actionButtonLabel={formatMessage({ id: 'accounting.statement.toValidateSection.validateAndSend' })}
      actionButtonLoading={validationLoading}
      title={formatMessage({ id: 'accounting.chargeAdjustments.validateChargeAdjustment' })}
      dividerBelowTitle
      formatMessage={formatMessage}
    >
      <Typography style={{ fontSize: 16, marginTop: 20, textAlign: 'justify' }}>
        {formatMessage({ id: 'accounting.chargeAdjustments.validateMainText' })}
      </Typography>
      {adjustmentDate && (
        <Typography style={{ fontSize: 16, textAlign: 'justify' }}>{`${formatMessage(
          { id: 'accounting.chargeAdjustments.nextDueDate' },
          {
            dueDate: format(adjustmentDate, 'dd/MM/yyyy', {
              locale: dateLocaleMap[language],
            }),
          }
        )}.`}</Typography>
      )}
    </CustomSimpleDialog>
  );
};

export default ConfirmLeaseAdjustmentDialog;
