import { Grid, TextField, Typography } from '@material-ui/core';
import { ChangeEvent, useState } from 'react';
import { type IntlFormatters } from 'react-intl';
import { BoldSpan } from '../texts';

interface DeleteCheckFieldProps {
  deleteLabel: string;
  setIsDeleteLabelTyped: (isTyped: boolean) => void;
  formatMessage: IntlFormatters['formatMessage'];
}

export const DeleteCheckField: React.FC<DeleteCheckFieldProps> = ({
  deleteLabel,
  setIsDeleteLabelTyped,
  formatMessage,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    setIsDeleteLabelTyped(value === deleteLabel);
  };

  return (
    <Grid>
      <Typography style={{ marginTop: 10, marginBottom: 5, fontSize: 14 }}>
        {formatMessage({
          id: 'contact.deleteContact.deletionFieldLabel1',
        })}
        <BoldSpan>{` ${deleteLabel} `}</BoldSpan>
        {formatMessage({
          id: 'contact.deleteContact.deletionFieldLabel2',
        })}
      </Typography>
      <Grid>
        <TextField
          variant="filled"
          value={inputValue}
          onChange={handleInputChange}
          placeholder={deleteLabel}
          style={{ height: 30, marginBottom: 10 }}
          inputProps={{ style: { paddingTop: 15 } }}
          size="small"
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
