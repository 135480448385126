/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-shadow */
import { Divider, FormControlLabel, Grid, makeStyles, MenuItem, Typography } from '@material-ui/core';
import { FormikErrors, FormikTouched, useFormikContext } from 'formik';
import { isEmpty, isNil } from 'lodash';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import CustomCheckBox from 'src/components/ui/CustomCheckBox';
import { UnitStructure } from 'src/components/ui/Forms/FormField/UnitStructureFields';
import TextFieldArea from 'src/components/ui/TextFieldArea';
import { KitchenEquipment, UnitInventory, UnitInventoryRoomType, Colors } from '@rentguru/commons-utils';
import { EditAdvertisementFormValues } from './EditPublicationForm';
import TextDetailEditableWithImportance from './TextDetailEditableWithImportance';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import EditRoomsDialog from './InteriorEdits/EditRoomsDialog';
import { NoRoomsMessage } from '../PublicationDescription/PublicationDescriptionInside';
import { StructureHelperBundle } from 'src/components/ui/Forms/FormSection/UnitStructureForm';
import CustomizedSelectWithImportance from './CustomizedSelectWithImportance';
import { CustomPopover } from '@up2rent/ui';

const useLocalStyles = makeStyles({
  rootSelect: {
    '& > *': {
      width: 200,
    },
  },
  text: {
    '& fieldset': {
      background: Colors.CLASSICAL_WHITE,
      border: 'none',
      padding: 0,
      margin: 0,
    },
  },
  commodity: {
    textAlign: 'left',
    width: 150,
    minWidth: 150,
    maxWidth: 150,
    fontSize: 14,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    margin: '0',
    padding: '0',
    bottom: 0,
  },
  checkBoxLabel: {
    color: Colors.SLATE_GREY,
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  title: {
    textAlign: 'left',
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Mulish',
    fontSize: '16px',
    fontWeight: 700,
    margin: '10px 0 5px 30px',
  },
  editButton: {
    textAlign: 'left',
    fill: Colors.NOBEL_GREY,
  },
  repositionned: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    bottom: 5,
  },
});

interface InsideOutsideUnitInventoriesListProps {
  inside: boolean;
}

const InsideOutsideUnitInventoriesList: React.FC<InsideOutsideUnitInventoriesListProps> = ({ inside }) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<EditAdvertisementFormValues>();
  let counter: number = 0;
  const localClasses = useLocalStyles();
  const [editMode, _setEditMode] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [openStructureHelper, setOpenStructureHelper] = useState<StructureHelperBundle>({
    anchor: null,
    inside: false,
  });

  const handlePopoverOpen = (event: React.MouseEvent<SVGSVGElement, MouseEvent>, inside: boolean) => {
    setOpenStructureHelper({ anchor: event.currentTarget, inside });
  };

  const handlePopoverClose = () => {
    setOpenStructureHelper({ anchor: null, inside: false });
  };

  const numberOfInventory = values.unitStructures.reduce((numberOfInventory, currentStructure) => {
    if (isEmpty(currentStructure.unitInventories)) {
      return numberOfInventory;
    }
    return (
      numberOfInventory +
      currentStructure.unitInventories.filter((unitInventory) => unitInventory.inside === inside).length
    );
  }, 0);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography variant="h6" className={localClasses.title}>
            {formatMessage({ id: 'rentalUnit.detail.inside.rooms.rooms' })}
          </Typography>
        </div>
        <InfoSvg
          style={{
            marginRight: 30,
            cursor: 'default',
            fill: Colors.NOBEL_GREY,
          }}
          onMouseEnter={(e) => handlePopoverOpen(e, true)}
          onMouseLeave={handlePopoverClose}
        />
      </div>

      {numberOfInventory === 0 ? (
        <Grid style={{ width: '90%', marginLeft: '5%' }}>
          <NoRoomsMessage />
        </Grid>
      ) : (
        <>
          <Grid style={{ margin: 20, marginBottom: 25, border: '1px solid #B5C1C8', borderRadius: 6, paddingTop: 25 }}>
            {values.unitStructures.map((structure, unitStructureIndex) => {
              return structure.unitInventories.map((unitInventory, unitInventoryIndex) => {
                if (unitInventory.inside !== inside) {
                  return null;
                }
                counter++;
                return (
                  <Grid key={unitInventory.id}>
                    <Grid container style={{ justifyContent: 'space-evenly', marginBottom: 0, alignItems: 'center' }}>
                      <Grid
                        item
                        xs={9}
                        style={{
                          marginLeft: 20,
                        }}
                      >
                        <div className={localClasses.repositionned}>
                          {!editMode ? (
                            <Typography variant="h6" color="inherit" className={localClasses.commodity}>
                              {unitInventory.roomName
                                ? unitInventory.roomName
                                : formatMessage({
                                    id: `leaseInventory.roomType.${unitInventory.roomType.toLowerCase()}`,
                                  })}
                            </Typography>
                          ) : (
                            // Modifier l'input pour changer le noms des unités!!
                            <TextFieldArea
                              // eslint-disable-next-line max-len
                              name={`unitStructures[${unitStructureIndex}].unitInventories[${unitInventoryIndex}].roomName`}
                              rows={1}
                              rowsMax={1}
                              style={{
                                maxWidth: 200,
                                position: 'relative',
                                top: 1, // Stays
                                borderLeft: '5px solid #E0E0E0',
                                borderTopLeftRadius: 10,
                                borderBottomLeftRadius: 10,
                                paddingLeft: 10,
                              }}
                              inputProps={{ style: { textAlign: 'left' } }}
                              InputProps={{ style: { fontSize: 14, fontWeight: 600, width: '100%' } }}
                              onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
                            />
                          )}
                          {[UnitInventoryRoomType.KITCHEN, UnitInventoryRoomType.LIVING_WITH_OPEN_KITCHEN].includes(
                            unitInventory.roomType as UnitInventoryRoomType
                          ) && (
                            <Grid style={{ textAlign: 'right', width: '200px', left: 10 }}>
                              <CustomizedSelectWithImportance
                                label={formatMessage({ id: 'rentalUnit.detail.general.inside.kitchenEquipment' })}
                                // eslint-disable-next-line max-len
                                fieldName={`unitStructures[${unitStructureIndex}].unitInventories[${unitInventoryIndex}].kitchenEquipment`}
                                value={
                                  !isNil(
                                    values.unitStructures[unitStructureIndex].unitInventories[unitInventoryIndex]
                                      .kitchenEquipment
                                  )
                                    ? values.unitStructures[unitStructureIndex].unitInventories[unitInventoryIndex]
                                        .kitchenEquipment
                                    : KitchenEquipment.FULLY_EQUIPPED
                                }
                                className={localClasses.rootSelect}
                                error={false}
                                margin="dense"
                              >
                                {Object.keys(KitchenEquipment).map((equipment) => {
                                  return (
                                    <MenuItem id={equipment} key={equipment} value={equipment}>
                                      {formatMessage({ id: `rentalUnit.detail.kitchenEquipment.${equipment}` })}
                                    </MenuItem>
                                  );
                                })}
                              </CustomizedSelectWithImportance>
                            </Grid>
                          )}

                          {(unitInventory.roomType === UnitInventoryRoomType.BATHROOM ||
                            unitInventory.roomType === UnitInventoryRoomType.SHOWER) && (
                            <Grid style={{ textAlign: 'right', width: '200px', left: 10 }}>
                              <CustomizedSelectWithImportance
                                label={formatMessage({ id: 'rentalUnit.detail.inside.listedRoom.bathroomType' })}
                                // eslint-disable-next-line max-len
                                fieldName={`unitStructures[${unitStructureIndex}].unitInventories[${unitInventoryIndex}].bathroomType`}
                                value={
                                  !isNil(
                                    values.unitStructures[unitStructureIndex].unitInventories[unitInventoryIndex]
                                      .privateBatthRoom
                                  ) &&
                                  values.unitStructures[unitStructureIndex].unitInventories[unitInventoryIndex]
                                    .privateBatthRoom // TODO BL: Only one T
                                    ? 'private' // TODO BL : translate
                                    : 'shared'
                                }
                                className={localClasses.rootSelect}
                                error={false}
                                margin="dense"
                              >
                                <MenuItem
                                  id="private"
                                  key="private"
                                  value="private"
                                  onClick={() =>
                                    setFieldValue(
                                      // eslint-disable-next-line max-len
                                      `unitStructures[${unitStructureIndex}].unitInventories[${unitInventoryIndex}].privateBatthRoom`,
                                      true
                                    )
                                  }
                                >
                                  {formatMessage({ id: 'rentalUnit.detail.inside.listedRoom.privateBathroom' })}
                                </MenuItem>
                                <MenuItem
                                  id="shared"
                                  key="shared"
                                  value="shared"
                                  onClick={() =>
                                    setFieldValue(
                                      // eslint-disable-next-line max-len
                                      `unitStructures[${unitStructureIndex}].unitInventories[${unitInventoryIndex}].privateBatthRoom`,
                                      false
                                    )
                                  }
                                >
                                  {formatMessage({ id: 'rentalUnit.detail.inside.listedRoom.sharedBathroom' })}
                                </MenuItem>
                              </CustomizedSelectWithImportance>
                            </Grid>
                          )}
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                              position: 'relative',
                              left: 20,
                            }}
                          >
                            <FormControlLabel
                              control={
                                <CustomCheckBox
                                  secondary
                                  isChecked={Boolean(
                                    values.unitStructures[unitStructureIndex].unitInventories[unitInventoryIndex]
                                      .showSurface
                                  )}
                                  onCheck={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue(event.target.name, event.target.checked);
                                  }}
                                  // eslint-disable-next-line max-len
                                  name={`unitStructures[${unitStructureIndex}].unitInventories[${unitInventoryIndex}].showSurface`}
                                />
                              }
                              label={formatMessage({ id: 'rentalUnit.detail.inside.rooms.showSuperficy' })}
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={2} style={{ position: 'relative', bottom: 10, width: 80, padding: 0, left: 20 }}>
                        {/* TODO BL: Surface to translate */}
                        <TextDetailEditableWithImportance
                          title=""
                          disabled={
                            !values.unitStructures[unitStructureIndex].unitInventories[unitInventoryIndex].showSurface
                          }
                          style={{ width: 80, margin: '0' }}
                          editMode={true}
                          name={`unitStructures[${unitStructureIndex}].unitInventories[${unitInventoryIndex}].surface`}
                          endAdornment="m²"
                          endAdornmentStyle={{ position: 'relative', top: 8 }}
                          error={Boolean(
                            errors.unitStructures &&
                              (errors.unitStructures as FormikErrors<UnitStructure>[])[unitStructureIndex] &&
                              (errors.unitStructures as FormikErrors<UnitStructure>[])[unitStructureIndex]
                                .unitInventories &&
                              (
                                (errors.unitStructures as FormikErrors<UnitStructure>[])[unitStructureIndex]!
                                  .unitInventories as FormikErrors<UnitInventory>[]
                              )[unitInventoryIndex] &&
                              (touched.unitStructures as FormikTouched<UnitStructure>[]) &&
                              (touched.unitStructures as FormikTouched<UnitStructure>[])[unitStructureIndex] &&
                              (touched.unitStructures as FormikTouched<UnitStructure>[])[unitStructureIndex]
                                .unitInventories &&
                              (
                                (touched.unitStructures as FormikTouched<UnitStructure>[])[unitStructureIndex]
                                  .unitInventories as FormikTouched<UnitInventory>[]
                              )[unitInventoryIndex]
                          )}
                          importance="recommended"
                        />
                      </Grid>
                    </Grid>
                    {counter !== numberOfInventory && <Divider style={{ marginBottom: 20 }} />}
                  </Grid>
                );
              });
            })}
          </Grid>
        </>
      )}
      <Divider style={{ margin: '20px 0 20px 0' }} />
      {dialogOpen && (
        <EditRoomsDialog
          inside={inside}
          unitId={values.unit.id}
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          selectUnitInventoryToBeDeleted={true}
        />
      )}
      <CustomPopover
        open={!isNil(openStructureHelper.anchor)}
        anchorEl={openStructureHelper.anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        paperPadding={5}
        paperWidth={'unset'}
        disableRestoreFocus
      >
        <>
          <Typography display="inline">
            {formatMessage({ id: 'rentalUnit.detail.popover.InfoShowMessage1' })}
          </Typography>
          <Typography style={{ fontWeight: 'bold' }} display="inline">
            {formatMessage({
              id: `rentalUnit.detail.popover.${inside ? 'InfoShowMessageInside' : 'InfoShowMessageOutside'}`,
            })}
          </Typography>
          <Typography display="inline">
            {formatMessage({ id: 'rentalUnit.detail.popover.InfoShowMessage2' })}
          </Typography>
        </>
      </CustomPopover>
    </>
  );
};

export default InsideOutsideUnitInventoriesList;
