import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { useTeams } from '../../../hooks/TeamsContext';
import { useBuildings } from '../../../hooks/BuildingsContext';
import { Building, User, UserTeam } from '@rentguru/commons-utils';
import { useUsers } from 'src/hooks/UsersContext';

interface DeleteTeamProps {
  afterSubmit: () => void;
  id: string;
}
const DeleteTeam: React.FC<DeleteTeamProps> = ({ afterSubmit, id }) => {
  const { formatMessage } = useIntl();
  const { buildings, updateBuilding } = useBuildings();
  const { createUserTeam, getUser, updateUser } = useUsers();
  const { getTeam, loading, error, deleteUserTeam, deleteTeam } = useTeams();
  const handleClose = () => {
    afterSubmit();
  };
  const team = getTeam(id);

  if (loading || isNil(team)) {
    return (
      <>
        <DialogContent>
          <Typography>{formatMessage({ id: 'loading' })}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {formatMessage({ id: 'cancel' })}
          </Button>
        </DialogActions>
      </>
    );
  }
  if (error) {
    return <Typography>{error}</Typography>;
  }
  const teamBuildings = buildings.filter((b) => !isNil(b.teams) && b.teams.includes(team.id));
  const hasMembers = !isNil(team.users) && !isEmpty(team.users);
  const hasBuildings = !isEmpty(teamBuildings);
  return (
    <>
      <DialogTitle id="alert-dialog-slide-title">
        {formatMessage({ id: 'settings.deleteTeam.title' }, { name: team.name })}
      </DialogTitle>
      {(hasMembers || hasBuildings) && (
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" style={{ color: 'red' }}>
            {hasMembers && hasBuildings
              ? formatMessage(
                  { id: 'settings.deleteTeam.warning' },
                  { buildings: teamBuildings.length, users: team.users!.length }
                )
              : hasMembers
              ? formatMessage({ id: 'settings.deleteTeam.warningUsers' }, { users: team.users!.length })
              : formatMessage({ id: 'settings.deleteTeam.warningBuilding' }, { buildings: teamBuildings.length })}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleClose();
          }}
          color="primary"
        >
          {formatMessage({ id: 'cancel' })}
        </Button>
        <Button
          onClick={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            const switchToParentTeamPromise: Promise<Building | UserTeam | User | void>[] = [];
            const parentTeam = !isNil(team.parentId) ? getTeam(team.parentId) : undefined;
            const teamUsers = team.users!;
            if (!isNil(parentTeam)) {
              teamBuildings.forEach((b) => {
                const teamsOfTheBuilding = b.teams!;
                const newTeamsOfTheBuilding = teamsOfTheBuilding.filter((t) => t !== team.id);
                switchToParentTeamPromise.push(updateBuilding(b, { teams: newTeamsOfTheBuilding }));
              });
              teamUsers.forEach((ut) => {
                switchToParentTeamPromise.push(deleteUserTeam(ut.id));
                const completeUser = ut.user ? getUser(ut.user.id) : undefined;
                if (completeUser) {
                  switchToParentTeamPromise.push(createUserTeam({ teamId: parentTeam.id, userId: completeUser.id }));
                  switchToParentTeamPromise.push(updateUser(completeUser, { refreshToken: true }));
                }
              });
            }

            await Promise.all(switchToParentTeamPromise);
            await deleteTeam(id);
            handleClose();
          }}
          color="primary"
        >
          {formatMessage({ id: 'delete' })}
        </Button>
      </DialogActions>
    </>
  );
};

export default DeleteTeam;
