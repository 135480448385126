import { Chip, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Colors, IntegrationName } from '@rentguru/commons-utils';
import { Form, Formik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useIntl } from 'react-intl';
import { ActionButton , LoaderButton } from '@up2rent/ui';
import { useIntegrations } from 'src/hooks/IntegrationsContext';
import IntegrationLogo from './IntegrationLogo';
import Dialog from 'src/components/ui/Dialog';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: Colors.PORCELAIN_GREY_1,
    padding: 15,
    paddingLeft: 30,
  },
  paragraph: {
    fontSize: '14px',
    lineHeight: '20px',
    color: Colors.CHARCOAL_GREY,
    textAlign: 'left',
  },
  integrationName: {
    fontFamily: 'Mulish',
    fontSize: 20,
    fontWeight: 'bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: Colors.CLASSICAL_BLACK,
  },
});

interface Bob50FormValues {}

interface Bob50IntegrationDialogProps {
  open: boolean;
  onClose: () => void;
  id?: string | null;
}

const Bob50IntegrationDialog: React.FC<Bob50IntegrationDialogProps> = ({ open, onClose, id }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { loading, error } = useIntegrations();

  if (loading) {
    return <Typography>{formatMessage({ id: 'loading' })}</Typography>;
  }
  if (error) {
    return <Typography>{error}</Typography>;
  }

  const onSubmit = async (_values: Bob50FormValues, { setSubmitting, setStatus }: FormikHelpers<Bob50FormValues>) => {
    setStatus(true);
    setSubmitting(false);
    enqueueSnackbar(formatMessage({ id: 'settings.changesSaved' }), { variant: 'success' });
    onClose();
  };

  const initialValues: Bob50FormValues = {};

  return (
    <Dialog open={open} onClose={onClose} scroll="body">
      <div className={classes.root}>
        <IntegrationLogo name={IntegrationName.SAGE_BOB50} height={30} />
      </div>
      <Grid
        container
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingRight: 30,
          paddingLeft: 30,
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <Grid item>
          <Typography className={classes.integrationName}>
            {formatMessage({ id: `settings.integrations.IntegrationName.${IntegrationName.SAGE_BOB50}` })}
          </Typography>
        </Grid>
        <Grid item style={{ marginLeft: 10, display: 'flex', justifyContent: 'flex-end' }}>
          <Chip
            label={formatMessage({ id: 'settings.integrations.free' })}
            style={{
              color: Colors.LIGHT_BLUE_GREY,
              fontFamily: 'Mulish',
              fontSize: 14,
              lineHeight: 18,
              fontWeight: 600,
            }}
          />
        </Grid>
      </Grid>
      <Grid style={{ paddingLeft: 30, paddingRight: 30 }}>
        <Typography paragraph classes={{ root: classes.paragraph }}>
          {formatMessage({ id: `settings.integrations.description.${IntegrationName.SAGE_BOB50}` })}
        </Typography>
      </Grid>
      <Formik onSubmit={onSubmit} initialValues={initialValues}>
        {({ isSubmitting, status }) => (
          <Form>
            <Grid style={{ marginLeft: 30, marginRight: 30, display: 'none' }}>{id}</Grid>
            <Grid
              style={{
                paddingRight: 30,
                paddingLeft: 30,
                marginBottom: 20,
                marginTop: 20,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <ActionButton
                onClick={onClose}
                style={{
                  background: 'none',
                  color: Colors.DARK_SLATE_GREY,
                  marginRight: 20,
                  textTransform: 'uppercase',
                }}
              >
                {formatMessage({ id: 'cancel' })}
              </ActionButton>
              <LoaderButton loading={isSubmitting} success={status}>
                {formatMessage({
                  id: 'save',
                })}
              </LoaderButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default Bob50IntegrationDialog;
