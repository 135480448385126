import React, { useState, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { formatDistanceToNow, differenceInCalendarDays, addMonths, differenceInCalendarMonths } from 'date-fns';
import { isNil, min } from 'lodash';
import { Grid, ListItem, Paper, Typography } from '@material-ui/core';
import { Colors, getLocaleFromLanguage, CustomSubscription } from '@rentguru/commons-utils';
import { useCustomSubscriptions } from 'src/hooks/CustomSubscriptionContext';
import { useUser } from 'src/hooks/UserContext';
import { LeaseExtended, useLeases } from 'src/hooks/LeasesContext';
import { ReactComponent as ExtendIcon } from 'src/icons/lease-action-extend.svg';
import NotificationDeleteIconButton from 'src/components/ui/CustomIconButtons/NotificiationDeleteIconButton';
import { useNotificationListStyle } from './NotificationsList';

interface NotificationsTacitRenewalItemProps {
  customSubscription: CustomSubscription;
  leaseIds: string[];
  isWarning?: boolean;
}

const getLeftDays = (startDate?: string, endDate?: string, notificationCreationDate?: string | null) => {
  if (isNil(startDate) || isNil(endDate) || isNil(notificationCreationDate)) {
    return 0;
  }
  const durationOfLeaseInMonth = differenceInCalendarMonths(new Date(endDate), new Date(startDate));
  if (durationOfLeaseInMonth === 0) {
    return differenceInCalendarDays(new Date(endDate), new Date());
  }
  const limitDate = addMonths(new Date(notificationCreationDate), 1);
  return differenceInCalendarDays(limitDate, new Date());
};

const getLeasesLinks = (
  leasesToShow: (LeaseExtended | null | undefined)[],
  allLeases: (LeaseExtended | null | undefined)[]
) => (
  <>
    {leasesToShow.map(
      (currentLease, index) =>
        currentLease && (
          <Fragment key={`lease-link-${currentLease.id}`}>
            <Link to={`/leases/detail/${currentLease.id}`} style={{ color: Colors.DODGER_BLUE }}>
              {currentLease.name}
            </Link>
            {index !== leasesToShow.length - 1 && <>, </>}
          </Fragment>
        )
    )}
    {leasesToShow.length !== allLeases.length && <>, ...</>}
  </>
);

const getTooltipLeasesLinks = (allLeases: (LeaseExtended | null | undefined)[]) =>
  allLeases.map(
    (currentLease) =>
      currentLease && (
        <Typography key={`tooltip-lease-link-${currentLease.id}`}>
          <span style={{ marginRight: 2 }}>• </span>
          <Link to={`/leases/detail/${currentLease.id}`} style={{ color: Colors.DODGER_BLUE }}>
            {currentLease.name}
          </Link>
        </Typography>
      )
  );

export const NotificationsTacitRenewalItem: React.FC<NotificationsTacitRenewalItemProps> = ({
  customSubscription,
  leaseIds,
  isWarning,
}) => {
  const { formatMessage } = useIntl();
  const localStyles = useNotificationListStyle();
  const { language } = useUser();
  const { deleteCustomSubscriptions } = useCustomSubscriptions();
  const { getLease } = useLeases();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const allLeases = leaseIds.map((leaseId) => getLease(leaseId));
  const leasesToShow = allLeases.slice(0, 5);

  const daysLeft = min(
    allLeases.map((lease) => getLeftDays(lease?.startDate, lease?.endDate, customSubscription.createdAt))
  );

  return (
    <ListItem className={localStyles.listItem}>
      <Grid>
        <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            <ExtendIcon
              style={{
                width: 45,
                height: 45,
                transform: 'scale(0.6)',
              }}
            />
            <Typography variant="h6" style={{ color: Colors.CLASSICAL_BLACK }}>
              {formatMessage({ id: 'settings.communications.tacitRenewalTitle' })}
            </Typography>
          </Grid>
          <NotificationDeleteIconButton
            onClick={() => {
              deleteCustomSubscriptions([customSubscription]);
            }}
          />
        </Grid>
        {!isNil(customSubscription.createdAt) && (
          <Typography style={{ color: Colors.BLUEY, paddingBottom: 5 }}>
            {formatDistanceToNow(new Date(customSubscription.createdAt!), {
              locale: getLocaleFromLanguage(language || 'en'),
            })}
          </Typography>
        )}
        {customSubscription && (
          <>
            <Typography style={{ color: Colors.SLATE_GREY }}>
              {isWarning
                ? formatMessage({ id: 'settings.communications.tacitRenewalWarningContent' })
                : formatMessage({ id: 'settings.communications.tacitRenewalContent' })}
            </Typography>
            <Typography onMouseEnter={() => setIsTooltipOpen(true)} onMouseLeave={() => setIsTooltipOpen(false)}>
              {getLeasesLinks(leasesToShow, allLeases)}
            </Typography>
            {isTooltipOpen && (
              <Paper
                elevation={8}
                style={{
                  position: 'absolute',
                  marginBottom: 20,
                  zIndex: 100,
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 10,
                  right: 0,
                }}
                onMouseEnter={() => setIsTooltipOpen(true)}
                onMouseLeave={() => setIsTooltipOpen(false)}
                onClick={(e) => e.stopPropagation()}
              >
                <Typography
                  style={{ color: Colors.CLASSICAL_BLACK, paddingBottom: 5, fontSize: 14, fontWeight: 'bold' }}
                >
                  {formatMessage({ id: 'settings.communications.concernedLeases' })}
                </Typography>
                {getTooltipLeasesLinks(allLeases)}
              </Paper>
            )}
            {isWarning && (
              <Typography style={{ color: Colors.CARNATION_RED, marginTop: 5 }}>
                {formatMessage({ id: 'settings.communications.tacitRenewalWarningTimer' }, { days: daysLeft })}
              </Typography>
            )}
          </>
        )}
      </Grid>
    </ListItem>
  );
};
