/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import Fade from '@material-ui/core/Fade';
import ListItem from '@material-ui/core/ListItem';
import { ReactComponent as FaceSvg } from '../../icons/face.svg';
import { ReactComponent as LogOutIcon } from '../../icons/exitIcon.svg';
import { ReactComponent as SettingsIcon } from '../../icons/cog.svg';
import { useIntl } from 'react-intl';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useUser } from '../../hooks/UserContext';
import isNil from 'lodash/isNil';
import { useTeams } from 'src/hooks/TeamsContext';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { useContacts } from 'src/hooks/ContactsContext';
import { useUsers } from 'src/hooks/UsersContext';
import SkeletonComponent from '../ui/SkeletonComponent';
import { Badge, Button, CircularProgress, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { CUSTOM_SUBSCRIPTIONS_FOR_NOTIFICATION, useCustomSubscriptions } from 'src/hooks/CustomSubscriptionContext';
import NotificationsLoader from './NotificationsLoader';
import NotificationsList from './NotificationsList';
import { CustomSubscription, Colors } from '@rentguru/commons-utils';
import CustomizedSwitch from '../ui/CustomizedSwitch';
import { ReactComponent as BellIcon } from '../../icons/icon-bell.svg';

import '../../css/HiddenScrollbar.css';
import { getCacheItem, setCacheItem } from 'src/utils/cacheUtils';

const useLocalStyles = makeStyles({
  menu: {
    width: 350,
    minWidth: 350,
    maxWidth: 350,
    height: 700,
    minHeight: 700,
    maxHeight: 700,
    paddingBottom: 0,
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headIconsGroup: {
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
  },
  footer: {
    height: 60,
    minHeight: 60,
    maxHeight: 60,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    position: 'relative',
    top: 15,
  },
});

export const getFilteredSubscription = (customSubscriptions: CustomSubscription[]) => {
  return customSubscriptions.filter((customSubscription) =>
    CUSTOM_SUBSCRIPTIONS_FOR_NOTIFICATION.includes(customSubscription.name)
  );
};

const AccountListItem: React.FC = () => {
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();
  const { logout } = useUser();
  const [open, setOpen] = React.useState<boolean>(false);
  const { loading: teamsLoading } = useTeams();
  const { contactsLoading } = useContacts();
  const { loading: usersLoading } = useUsers();
  const { buildingsLoading } = useBuildings();
  const localStyles = useLocalStyles();
  const history = useHistory();
  const {
    loading: customSubscriptionLoading,
    customSubscriptions: notifications = [],
    deleteCustomSubscriptions,
  } = useCustomSubscriptions();
  const [bellValue, setBellValue] = useState<boolean>();
  const [numberOfNotifications, setNumberOfNotifications] = useState<number>(
    getFilteredSubscription(notifications).length
  );
  const [disconnectLoading, setDisconnectLoading] = useState(false);

  useEffect(() => {
    const handleBellStatus = async () => {
      const currentValue = await getCacheItem('notificationBellActive');
      if (isNil(currentValue)) {
        await setCacheItem('notificationBellActive', true);
      }
      setBellValue(currentValue);
    };
    handleBellStatus();
  }, []);

  const loading = teamsLoading || contactsLoading || usersLoading || buildingsLoading || customSubscriptionLoading;
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const svgFill = pathname.startsWith('/settings') ? Colors.CARNATION_RED : Colors.BLUE_GREY;

  const handleDeleteAll = (customSubscriptions: CustomSubscription[] | null) => {
    if (isNil(customSubscriptions)) {
      return;
    }
    deleteCustomSubscriptions(customSubscriptions);
  };

  useEffect(() => {
    // Basically, I am remembering the previous state in the notificationsState so
    // It does not open the menu every time I click on another menu item.
    // The state will be updated only if the notifications actually change.
    const numberOfNewNotifications = getFilteredSubscription(notifications).length;
    if (!loading && numberOfNewNotifications !== numberOfNotifications) {
      if (bellValue) {
        setOpen(true);
      }
      setNumberOfNotifications(numberOfNewNotifications);
    }
    // eslint-disable-next-line
  }, [notifications]);

  const handleSwitchChange = async () => {
    const currentValue = await getCacheItem('notificationBellActive');
    const bellValue = Boolean(currentValue);
    await setCacheItem('notificationBellActive', !bellValue);
    setBellValue(!bellValue);
  };

  const onLogout = async () => {
    setDisconnectLoading(true);
    await logout();
    history.replace('/');
    setDisconnectLoading(false);
  };

  return (
    <>
      <ListItem
        button
        key="logout"
        onClick={handleClick}
        aria-haspopup="true"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
        data-test="account-menu"
      >
        <Badge overlap="rectangular" color="secondary" variant="dot" invisible={numberOfNotifications === 0}>
          <FaceSvg style={{ fill: svgFill }} />
        </Badge>
      </ListItem>
      <Menu
        id="fade-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        anchorEl={document.getElementById('notifications')}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        className={localStyles.menu}
        PaperProps={{
          style: {
            width: 350,
            minWidth: 350,
            maxWidth: 350,
            height: 700,
            minHeight: 700,
            maxHeight: 700,
            borderRadius: 10,
          },
        }}
      >
        <Grid className={localStyles.head}>
          <Grid style={{ display: 'flex' }}>
            <Typography variant="h6" style={{ marginLeft: 10, marginRight: 10 }}>
              {formatMessage({ id: 'accountMenu.notifications' })}{' '}
              {numberOfNotifications !== 0 ? (
                <span style={{ fontSize: 12, color: Colors.BLUEY }}>({numberOfNotifications})</span>
              ) : (
                ''
              )}
            </Typography>
            <CustomizedSwitch
              checked={bellValue}
              onChange={async () => {
                await handleSwitchChange();
              }}
              style={{ position: 'relative' }}
              dataTest="notifications"
            />
            <BellIcon fill={bellValue ? Colors.DODGER_BLUE : Colors.BLUEY} style={{ position: 'relative', top: 3 }} />
          </Grid>
          <Button
            style={{ height: 40, fontSize: '12px', marginRight: 10 }}
            onClick={() => handleDeleteAll(getFilteredSubscription(notifications))}
            disabled={numberOfNotifications === 0}
          >
            {formatMessage({ id: 'accountMenu.deleteAll' })}
          </Button>
        </Grid>
        <Divider style={{ marginTop: 5 }} />
        <Grid className="body">{customSubscriptionLoading ? <NotificationsLoader /> : <NotificationsList />}</Grid>
        <Divider style={{ marginTop: 5 }} />
        <Grid className={localStyles.footer}>
          <Grid container style={{ marginRight: 10, marginLeft: 10, display: 'flex', alignItems: 'center' }}>
            <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <SkeletonComponent loading={loading}>
                {!disconnectLoading ? (
                  <Button onClick={onLogout} style={{ textTransform: 'none', width: '100%' }} data-test="logout">
                    <Typography style={{ marginRight: 10, textAlign: 'center' }}>
                      {formatMessage({ id: 'accountMenu.logout' })}
                    </Typography>
                    <LogOutIcon fill={Colors.SLATE_GREY} style={{ width: 24, height: 24 }} />
                  </Button>
                ) : (
                  <CircularProgress />
                )}
              </SkeletonComponent>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <SkeletonComponent loading={loading}>
                <Button
                  component={Link}
                  to="/settings"
                  disabled={loading}
                  style={{ textTransform: 'none', width: '100%' }}
                >
                  <Typography style={{ marginRight: 10, textAlign: 'center' }}>
                    {formatMessage({ id: 'accountMenu.settings' })}
                  </Typography>
                  <SettingsIcon fill={Colors.SLATE_GREY} style={{ width: 24, height: 24 }} />
                </Button>
              </SkeletonComponent>
            </Grid>
          </Grid>
        </Grid>
      </Menu>
    </>
  );
};

export default AccountListItem;
