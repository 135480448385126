/* eslint-disable no-redeclare */
import React, { Reducer, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import {
  SendingSource,
  syncSendingSources,
  getSendingSource as getSendingSourceQuery,
  createSendingSource as createMutation,
  updateSendingSource as updateMutation,
  deleteSendingSource as deleteMutation,
  verifyPendingSendingSource as verifyPendingMutation,
  CreateSendingSourceMutationVariables,
  DeleteSendingSourceMutationVariables,
  CreateSendingSourceInput,
  UpdateSendingSourceInput,
  VerifyPendingSendingSourceInput,
  VerifyPendingSendingSourceMutation,
  VerifyPendingSendingSourceMutationVariables,
  cleanInputUpdate,
  cleanInputCreate,
  getReadId,
  getTableClientId,
  LooseObject,
  getClientId,
  OnCreateSendingSourceSubscription,
  OnUpdateSendingSourceSubscription,
  OnDeleteSendingSourceSubscription,
  onCreateSendingSource,
  onUpdateSendingSource,
  onDeleteSendingSource,
} from '@rentguru/commons-utils';
import { deleteAndHideEntityWithFetchBefore, list, mutation, useSubscriptions } from '@up2rent/fetch-utils';
import { useUser } from './UserContext';
import isNil from 'lodash/isNil';

export interface SendingSourceContext extends SendingSourceState {
  createSendingSource: (
    input: CreateSendingSourceInput | Omit<CreateSendingSourceInput, 'clientId' | 'readId'>
  ) => Promise<SendingSource>;
  getSendingSource: (id: string) => SendingSource | undefined;
  getSendingSourceByClientId: (id: string) => SendingSource | undefined;
  updateSendingSource: (updates: UpdateSendingSourceInput) => Promise<SendingSource>;
  deleteSendingSource: (sendingSource: SendingSource) => Promise<SendingSource | null>;
  setFetchSendingSources: () => void;
  fetchAndSetSendingSources: () => Promise<void>;
  verifyPendingSendingSource: (
    input: Omit<VerifyPendingSendingSourceInput, 'clientId' | 'userId'>
  ) => Promise<VerifyPendingSendingSourceMutation>;
}

interface SendingSourceState {
  sendingSources: SendingSource[] | null;
  sendingSourcesError: string | undefined;
  sendingSourcesLoading: boolean;
  shouldFetchSendingSource: boolean;
}

type Action =
  | {
      type: 'SHOULD_FETCH' | 'IS_FETCHING';
    }
  | {
      type: 'ADD_SENDING_SOURCE';
      payload: { sendingSource: SendingSource };
    }
  | {
      type: 'FETCHED';
      payload: { sendingSources: SendingSource[] };
    }
  | {
      type: 'UPDATE_SENDING_SOURCE';
      payload: { sendingSource: SendingSource };
    }
  | {
      type: 'DELETE_SENDING_SOURCE';
      payload: { id: string };
    };

const initialState: SendingSourceState = {
  sendingSourcesLoading: false,
  sendingSourcesError: undefined,
  sendingSources: null,
  shouldFetchSendingSource: false,
};

const sendingSourceReducer = (state: SendingSourceState, action: Action): SendingSourceState => {
  switch (action.type) {
    case 'SHOULD_FETCH':
      // Check //lastFetchForAgencyRates
      if (state.sendingSourcesLoading || state.shouldFetchSendingSource) {
        return state;
      }
      return {
        ...state,
        shouldFetchSendingSource: true,
      };
    case 'IS_FETCHING':
      return {
        ...state,
        sendingSourcesLoading: true,
      };
    case 'FETCHED':
      // Build List with merged object
      return {
        ...state,
        sendingSourcesLoading: false,
        shouldFetchSendingSource: false,
        sendingSources: action.payload.sendingSources,
      };
    case 'ADD_SENDING_SOURCE':
      return {
        ...state,
        sendingSources: [...(state.sendingSources ?? []), action.payload.sendingSource],
      };
    case 'UPDATE_SENDING_SOURCE':
      if (!state.sendingSources) {
        return state;
      }
      return {
        ...state,
        sendingSources: state.sendingSources.map((sendingSource) => {
          if (sendingSource.id === action.payload.sendingSource.id) {
            return action.payload.sendingSource;
          }
          return sendingSource;
        }),
      };
    case 'DELETE_SENDING_SOURCE':
      if (!state.sendingSources) {
        return state;
      }
      return {
        ...state,
        sendingSources: state.sendingSources.filter((sendingSource) => sendingSource.id !== action.payload.id),
      };
    default:
      return state;
  }
};

export const fetchSendingSources = async (
  clientId: string,
  additionalFilter?: LooseObject
): Promise<SendingSource[]> => {
  return await list<SendingSource>(syncSendingSources, 'clientId', clientId, additionalFilter);
};

export const SendingSourceContext = React.createContext<SendingSourceContext | null>(null);

export const SendingSourceContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<SendingSourceState, Action>>(sendingSourceReducer, initialState);
  const { clientId, userId, refreshEmails } = useUser();

  const setFetchSendingSources = () => {
    dispatch({ type: 'SHOULD_FETCH' });
  };

  useSubscriptions<
    OnCreateSendingSourceSubscription,
    OnUpdateSendingSourceSubscription,
    OnDeleteSendingSourceSubscription
  >(
    onCreateSendingSource,
    onUpdateSendingSource,
    onDeleteSendingSource,
    () => {},
    (data) => {
      const { issuesEmail, chargesEmail } = data.onUpdateSendingSource as SendingSource;
      refreshEmails(issuesEmail, chargesEmail);
    },
    () => {}
  );

  useEffect(() => {
    if (state.shouldFetchSendingSource) fetchAndSetSendingSources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetchSendingSource]);

  const createSendingSource = async (
    input: CreateSendingSourceInput | Omit<CreateSendingSourceInput, 'clientId' | 'readId'>
  ): Promise<SendingSource> => {
    let sendingSource: SendingSource | undefined;
    try {
      sendingSource = await mutation<SendingSource, CreateSendingSourceMutationVariables>(createMutation, {
        input: {
          ...(cleanInputCreate(input) as CreateSendingSourceInput),
          clientId: getTableClientId(clientId!, 'SendingSource'),
          readId: getReadId(clientId!, 'SendingSource'),
          createdById: userId,
        },
      });
      dispatch({ type: 'ADD_SENDING_SOURCE', payload: { sendingSource } });
    } catch (error) {
      state.sendingSourcesError = error as string;
    }
    return sendingSource!;
  };

  const updateSendingSource = async (updates: UpdateSendingSourceInput): Promise<SendingSource> => {
    let sendingSource: SendingSource | undefined;
    try {
      sendingSource = await mutation<SendingSource>(updateMutation, {
        input: { ...cleanInputUpdate(updates, false) },
      });
      dispatch({ type: 'UPDATE_SENDING_SOURCE', payload: { sendingSource } });
      dispatch({ type: 'SHOULD_FETCH' });
    } catch (error) {
      state.sendingSourcesError = error as string;
    }
    return sendingSource!;
  };

  const deleteSendingSource = async (sendingSource: SendingSource): Promise<SendingSource | null> => {
    let deletedSendingSource: SendingSource | null | undefined;
    try {
      deletedSendingSource = await deleteAndHideEntityWithFetchBefore<
        SendingSource,
        DeleteSendingSourceMutationVariables
      >(sendingSource, getSendingSourceQuery, deleteMutation, updateMutation);

      dispatch({ type: 'DELETE_SENDING_SOURCE', payload: { id: sendingSource.id } });
      return deletedSendingSource;
    } catch (error) {
      state.sendingSourcesError = error as string;
    }
    return deletedSendingSource!;
  };

  const getSendingSource = (id: string) => {
    const sendingSource = (state.sendingSources ?? []).find((sendingSourceElement) => sendingSourceElement.id === id);
    return sendingSource;
  };

  const getSendingSourceByClientId = (id: string) => {
    const sendingSource = (state.sendingSources ?? []).find(
      (sendingSourceElement) => getClientId(sendingSourceElement.clientId) === id
    );
    return sendingSource;
  };

  const fetchAndSetSendingSources = async () => {
    try {
      dispatch({ type: 'IS_FETCHING' });
      const sendingSources = await fetchSendingSources(getTableClientId(clientId!, 'SendingSource'));
      dispatch({ type: 'FETCHED', payload: { sendingSources } });
    } catch (error) {
      state.sendingSourcesError = error as string;
    }
  };

  const verifyPendingSendingSource = async (
    input: Omit<VerifyPendingSendingSourceInput, 'clientId' | 'userId'>
  ): Promise<VerifyPendingSendingSourceMutation> => {
    const response = await mutation<VerifyPendingSendingSourceMutation, VerifyPendingSendingSourceMutationVariables>(
      verifyPendingMutation,
      {
        input: {
          ...(cleanInputCreate(input) as VerifyPendingSendingSourceInput),
          clientId: clientId!,
          userId: userId!,
        },
      }
    );
    return response;
  };

  const values = useMemo(
    () => ({
      ...state,
      createSendingSource,
      getSendingSource,
      getSendingSourceByClientId,
      updateSendingSource,
      deleteSendingSource,
      setFetchSendingSources,
      fetchAndSetSendingSources,
      verifyPendingSendingSource,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <SendingSourceContext.Provider value={values}>{children}</SendingSourceContext.Provider>;
};

export const useSendingSources = (): SendingSourceContext => {
  const firstRender = useRef<boolean>(false);
  const context = useContext<SendingSourceContext | null>(SendingSourceContext);

  if (isNil(context)) {
    throw new Error('`useSendingSources` hook must be used within a `SendingSourceContext` component');
  }
  useEffect(() => {
    if (!firstRender.current && isNil(context.sendingSources)) {
      context.setFetchSendingSources();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  return context;
};
