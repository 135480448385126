import React from 'react';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { Colors, TicketStatus } from '@rentguru/commons-utils';

interface StatusBoxProps {
  status: TicketStatus;
}
const getColor = (status: TicketStatus) => {
  // eslint-disable-next-line default-case
  switch (status) {
    case TicketStatus.URGENT:
      return Colors.CARNATION_RED;
    case TicketStatus.PENDING:
      return Colors.CARROT_ORANGE;
    case TicketStatus.RESOLVED:
      return Colors.TURQUOISE_BLUE;
    case TicketStatus.ARCHIVED:
      return Colors.BLUE_GREY;
  }
};
const StatusBox: React.FC<StatusBoxProps> = ({ status }) => {
  const { formatMessage } = useIntl();
  return (
    <Grid
      style={{
        fontSize: 14,
        fontWeight: 'bold',
        color: getColor(status),
        textAlign: 'center',
        padding: '5px 8px',
        border: `1px solid ${getColor(status)}`,
        borderRadius: 4,
      }}
    >
      {formatMessage({ id: `enums.TicketStatus.${status}` })}
    </Grid>
  );
};

export default StatusBox;
