/* eslint-disable @typescript-eslint/no-shadow */
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { toNumber } from 'lodash';
/**
 * Get safe value of a field in an object. The field can contains . to go in sub object.
 * @param {*} object The object
 * @param {*} field The field name we want to have value
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getSafeValueInObject = <T>(object?: T | null, field?: keyof T | string | null): any => {
  if (isNil(object) || isEmpty(object) || isNil(field) || isEmpty(field)) return '';

  if ((field as string).indexOf('.') === -1) {
    if ((field as string).endsWith(']')) {
      const tableIndex = (field as string).indexOf('[');
      const fieldName = (field as string).substring(0, tableIndex);
      const indexToSearch = (field as string).substring(tableIndex + 1, (field as string).length - 1);
      if (isNil(object[fieldName as keyof T])) return '';
      // @ts-ignore
      return object[fieldName][toNumber(indexToSearch)];
    }
    return (isNil(object[field as keyof T]) ? '' : object[field as keyof T]) as string;
  }
  const fields = (field as string).split('.');
  if (fields[0].endsWith(']')) {
    const tableIndex = fields[0].indexOf('[');
    const field = fields[0].substring(0, tableIndex);
    const indexToSearch = fields[0].substring(tableIndex + 1, fields[0].length - 1);
    if (isNil(object[field as keyof T])) return '';
    // @ts-ignore
    return getSafeValueInObject(object[field][toNumber(indexToSearch)], fields.slice(1).join('.'));
  }
  return getSafeValueInObject(object[fields[0] as keyof T], fields.slice(1).join('.'));
};

/**
 * Get safe value of a field in an array containing the object.
 * @param {*} array The array
 * @param {Int} id The id of object inside the array
 * @param {*} field The field name we want to have value
 */
export const getSafeValueInArray = <T extends { id: string }>(
  array?: T[] | null,
  id?: string,
  field?: string | null
) => {
  if (isNil(array) || isEmpty(array) || isNil(field) || isEmpty(field)) return '';

  const object = find(array, (o) => o.id === id);
  if (isNil(object)) {
    return '';
  }
  return getSafeValueInObject(object, field);
};
