/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-shadow */
import { Divider, Grid, IconButton, makeStyles } from '@material-ui/core';
import Dialog from 'src/components/ui/Dialog';
import {
  Colors,
  COLORS,
  InventoryCustomItem,
  InventoryItemType,
  LeaseInventoryCheckedItem,
  LeaseInventoryEncoding,
} from '@rentguru/commons-utils';
import { Form, Formik } from 'formik';
import isNil from 'lodash/isNil';
import toUpper from 'lodash/toUpper';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ActionButton , ContentHeader, LoaderButton } from '@up2rent/ui';
import { classifyInventoryCheckedItems } from '../../../../hooks/LeaseInventoryContext';
import { v4 as uuidv4 } from 'uuid';
import CheckIcon from '@material-ui/icons/Check';
import { Add } from '@material-ui/icons';
import { SketchPicker } from 'react-color';
import { ExtendedLeaseInventoryEncoding } from '../../../../hooks/UnitInventoryContext';

const useStyles = makeStyles({
  addButton: {
    background: Colors.CLASSICAL_WHITE,
    color: Colors.DARK_SLATE_GREY,
    '&:hover': {
      background: Colors.PORCELAIN_GREY_2,
    },
  },
});

interface AddColorsDialogProps {
  open: boolean;
  encoding?: ExtendedLeaseInventoryEncoding;
  updateEncoding?: (
    updateFunction: (encoding: ExtendedLeaseInventoryEncoding) => ExtendedLeaseInventoryEncoding
  ) => void;
  customItems: InventoryCustomItem[];
  updateCustomItems: (updateFunction: (item: InventoryCustomItem[]) => InventoryCustomItem[]) => void;
  onClose: () => void;
}

interface FormikCheckedColors {
  checkedColors: CheckedColor[];
}

interface CheckedColor extends LeaseInventoryCheckedItem {
  checked: boolean;
}

interface ColorObject {
  r: number;
  g: number;
  b: number;
  a?: number;
}

const AddColorsDialog: React.FC<AddColorsDialogProps> = ({
  open,
  encoding,
  customItems,
  updateEncoding,
  updateCustomItems,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const [customColor, setCustomColor] = useState<ColorObject | null>(null);
  const [, colors] =
    !isNil(encoding) && !isNil(encoding.checkedItems)
      ? classifyInventoryCheckedItems(encoding.checkedItems)
      : [[], [], []];
  const customSection = !isNil(customColor);
  const customColors = customItems.filter((ci) => ci.type === InventoryItemType.COLOR);

  // unchecked color by default
  const initialValues: CheckedColor[] = COLORS.map((COLOR) => {
    return {
      id: `NEW-${uuidv4()}`,
      value: COLOR,
      custom: false,
      leaseInventoryEncodingId: !isNil(encoding) ? encoding.id : '',
      readId: '',
      type: InventoryItemType.COLOR,
      checked: false,
      clientId: '',
    };
  });

  customColors.forEach((customColor) => {
    initialValues.push({
      id: `NEW-${uuidv4()}`,
      custom: true,
      leaseInventoryEncodingId: !isNil(encoding) ? encoding.id : '',
      customItem: customColor,
      readId: '',
      type: InventoryItemType.COLOR,
      checked: false,
      clientId: '',
    });
  });

  // Add the custom ones and replaces the ones by default if real values exist
  colors.forEach((color) => {
    const checkedColor = { ...color, checked: true };
    let initialValueIndex: number;
    if (color.custom && !isNil(color.customItem)) {
      initialValueIndex = initialValues.findIndex(
        (initVal) => !isNil(initVal.customItem) && initVal.customItem.id === color.customItem!.id
      );
    } else {
      initialValueIndex = initialValues.findIndex((initVal) => initVal.value === color.value);
    }
    if (initialValueIndex > -1) {
      initialValues[initialValueIndex] = checkedColor;
    }
  });

  const handleSubmit = (values: FormikCheckedColors) => {
    const newColors = values.checkedColors.filter((color) => color.checked);
    if (!isNil(updateEncoding)) {
      updateEncoding((encoding: LeaseInventoryEncoding) => {
        const previousCheckedItems = encoding.checkedItems!;
        return {
          ...encoding,
          checkedItems: [...previousCheckedItems.filter((pci) => pci.type !== InventoryItemType.COLOR), ...newColors],
        };
      });
    }
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      scroll="body"
      PaperProps={{ style: { maxWidth: 'none', width: 400, borderRadius: 10 } }}
    >
      <Formik
        initialValues={{ checkedColors: initialValues } as FormikCheckedColors}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values, setFieldValue, status, isSubmitting }) => {
          return (
            <Form>
              <div
                style={{
                  display: 'flex',
                  justifyContent: customSection ? 'flex-start' : 'space-between',
                  alignItems: 'center',
                }}
              >
                <ContentHeader
                  title={
                    customSection
                      ? formatMessage({ id: 'leaseInventory.addColor.title' })
                      : formatMessage({ id: 'leaseInventory.room.structureDetails.colors' }, { value: 2 })
                  }
                  handleBack={customSection ? () => setCustomColor(null) : undefined}
                />
                {!customSection && (
                  <ActionButton
                    onClick={() => {
                      setCustomColor({
                        r: Math.round(Math.random() * 255),
                        g: Math.round(Math.random() * 255),
                        b: Math.round(Math.random() * 255),
                        a: 1,
                      });
                    }}
                    style={{
                      marginRight: 24,
                    }}
                    className={classes.addButton}
                  >
                    <Add />
                    {toUpper(formatMessage({ id: 'leaseInventory.addColor.customColor' }))}
                  </ActionButton>
                )}
              </div>
              <Divider />
              {!customSection && (
                <Grid container>
                  {values.checkedColors.map((checkedColor, index) => {
                    const updateColor = (checked: boolean) => {
                      const newColors = values.checkedColors.map((cm) => {
                        if (checkedColor.id === cm.id) cm.checked = checked;
                        return cm;
                      });
                      setFieldValue('checkedColors', newColors);
                    };
                    return (
                      <Grid
                        item
                        xs={3}
                        style={{ marginTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        key={`${checkedColor.id} ${index}`}
                      >
                        <IconButton onClick={() => updateColor(!checkedColor.checked)}>
                          <div
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: 40,
                              backgroundColor: checkedColor.custom
                                ? checkedColor.customItem!.value
                                : checkedColor.value ?? '',
                              border: checkedColor.checked ? `2px solid ${Colors.DODGER_BLUE}` : '1px solid gray',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {checkedColor.checked ? <CheckIcon style={{ fill: Colors.DODGER_BLUE }} /> : null}
                          </div>
                        </IconButton>
                      </Grid>
                    );
                  })}
                </Grid>
              )}

              {customSection && (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                  <SketchPicker color={customColor!} onChange={(color) => setCustomColor(color.rgb)} />
                </div>
              )}

              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <div
                style={{
                  marginRight: 30,
                  marginBottom: 20,
                  display: 'flex',
                  float: 'right',
                  alignItems: 'center',
                }}
              >
                <ActionButton
                  onClick={() => {
                    if (!isNil(onClose)) {
                      onClose();
                    }
                  }}
                  style={{
                    background: 'none',
                    color: Colors.DARK_SLATE_GREY,
                    marginRight: 20,
                  }}
                >
                  {toUpper(
                    formatMessage({
                      id: 'bankAccount.addBankAccount.cancelLabel',
                    })
                  )}
                </ActionButton>
                {customSection ? (
                  <ActionButton
                    style={{ minWidth: 120, height: 50, fontSize: 16, fontWeight: 800 }}
                    onClick={() => {
                      if (!isNil(customColor)) {
                        const newColor = {
                          id: `NEW-${uuidv4()}`,
                          value: `rgba(${customColor.r},${customColor.g},${customColor.b},${customColor.a})`,
                          type: InventoryItemType.COLOR,
                          clientId: '',
                          readId: '',
                        };
                        const newCheckedColor = {
                          id: `NEW-${uuidv4()}`,
                          custom: true,
                          leaseInventoryEncodingId: !isNil(encoding) ? encoding.id : '',
                          customItem: newColor,
                          readId: '',
                          type: InventoryItemType.COLOR,
                          checked: false,
                          clientId: '',
                        };
                        updateCustomItems((customItems) => {
                          return [...customItems, newColor];
                        });
                        setFieldValue('checkedColors', [...values.checkedColors, newCheckedColor]);
                      }
                      setCustomColor(null);
                    }}
                  >
                    {formatMessage({ id: 'add' })}
                  </ActionButton>
                ) : (
                  <LoaderButton loading={isSubmitting} success={status}>
                    {formatMessage({
                      id: 'bankAccount.addBankAccount.submitLabel',
                    })}
                  </LoaderButton>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default AddColorsDialog;
