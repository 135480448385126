import { isEmpty, isNil } from 'lodash';
import React, { useContext } from 'react';
import { useContextLoader } from '../ContextLoader';
import { useUser } from '../UserContext';
import { isSettingsOrganizationOn, UserRightAccessType } from '@rentguru/commons-utils';

const toCamel = (s: string) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

export type Permissions = {
  notificationsDetailsRead: boolean;
  notificationsDetailsWrite: boolean;
  notificationsDetailsDelete: boolean;
  communicationsDetailsRead: boolean;
  communicationsDetailsWrite: boolean;
  communicationsDetailsDelete: boolean;
  contactsDetailsRead: boolean;
  contactsDetailsWrite: boolean;
  contactsDetailsDelete: boolean;
  ticketsDetailsRead: boolean;
  ticketsDetailsWrite: boolean;
  ticketsDetailsDelete: boolean;
  advertisementsDetailsRead: boolean;
  advertisementsDetailsWrite: boolean;
  advertisementsDetailsDelete: boolean;

  signatureDetailsRead: boolean;
  signatureDetailsWrite: boolean;
  signatureDetailsDelete: boolean;

  templatesContractsRead: boolean;
  templatesContractsWrite: boolean;
  templatesCommunicationsRead: boolean;
  templatesCommunicationsWrite: boolean;
  financialTransactionsRead: boolean;
  financialTransactionsWrite: boolean;
  financialTransactionsDelete: boolean;
  financialMortgagesRead: boolean;
  financialMortgagesWrite: boolean;
  financialMortgagesDelete: boolean;
  financialValuationsAndCostsRead: boolean;
  financialValuationsAndCostsWrite: boolean;
  financialValuationsAndCostsDelete: boolean;
  leasesCreationRead: boolean;
  leasesCreationWrite: boolean;
  leasesCreationDelete: boolean;
  leasesSignatureWrite: boolean;
  leasesDetailsRead: boolean;
  leasesDetailsWrite: boolean;
  leasesDetailsDelete: boolean;
  inventoryOfFixturesCreationRead: boolean;
  inventoryOfFixturesCreationWrite: boolean;
  inventoryOfFixturesCreationDelete: boolean;
  furnituresInventoryCreationRead: boolean;
  furnituresInventoryCreationWrite: boolean;
  furnituresInventoryCreationDelete: boolean;
  buildingsUnitsDetailsRead: boolean;
  buildingsUnitsDetailsWrite: boolean;
  buildingsUnitsDetailsDelete: boolean;
  buildingsUnitsUtilityProvidersRead: boolean;
  buildingsUnitsUtilityProvidersWrite: boolean;
  buildingsUnitsUtilityProvidersDelete: boolean;
  buildingsUnitsTechnicsRead: boolean;
  buildingsUnitsTechnicsWrite: boolean;
  buildingsUnitsTechnicsDelete: boolean;
  agencyRateRead: boolean;
  agencyRateWrite: boolean;
  agencyRateDelete: boolean;
  settingsAutomationRead: boolean;
  settingsAutomationWrite: boolean;
  settingsManagementWrite: boolean;
  indexationCreationRead: boolean;
  indexationCreationWrite: boolean;
  indexationCreationDelete: boolean;
  documents: boolean;
};

export interface PermissionsContext extends Permissions {
  loading: boolean;
}

/** DEFAULTS */
const defaultOwnerPermissions: Permissions = {
  notificationsDetailsRead: false,
  notificationsDetailsWrite: false,
  notificationsDetailsDelete: false,
  communicationsDetailsRead: false,
  communicationsDetailsWrite: false,
  communicationsDetailsDelete: false,
  contactsDetailsRead: true,
  contactsDetailsWrite: false,
  contactsDetailsDelete: false,
  ticketsDetailsRead: true,
  ticketsDetailsWrite: true,
  ticketsDetailsDelete: false,
  advertisementsDetailsRead: true,
  advertisementsDetailsWrite: false,
  advertisementsDetailsDelete: false,

  signatureDetailsRead: true,
  signatureDetailsWrite: false,
  signatureDetailsDelete: false,

  templatesContractsRead: false,
  templatesContractsWrite: false,
  templatesCommunicationsRead: false,
  templatesCommunicationsWrite: false,
  financialTransactionsRead: false,
  financialTransactionsWrite: false,
  financialTransactionsDelete: false,
  financialMortgagesRead: true,
  financialMortgagesWrite: true,
  financialMortgagesDelete: true,
  financialValuationsAndCostsRead: true,
  financialValuationsAndCostsWrite: true,
  financialValuationsAndCostsDelete: true,
  leasesCreationRead: true,
  leasesCreationWrite: false,
  leasesCreationDelete: false,
  leasesSignatureWrite: true,
  leasesDetailsRead: true,
  leasesDetailsWrite: false,
  leasesDetailsDelete: false,
  inventoryOfFixturesCreationRead: true,
  inventoryOfFixturesCreationWrite: false,
  inventoryOfFixturesCreationDelete: false,
  furnituresInventoryCreationRead: true,
  furnituresInventoryCreationWrite: false,
  furnituresInventoryCreationDelete: false,
  buildingsUnitsDetailsRead: true,
  buildingsUnitsDetailsWrite: false,
  buildingsUnitsDetailsDelete: false,
  buildingsUnitsUtilityProvidersRead: true,
  buildingsUnitsUtilityProvidersWrite: true,
  buildingsUnitsUtilityProvidersDelete: false,
  buildingsUnitsTechnicsRead: true,
  buildingsUnitsTechnicsWrite: true,
  buildingsUnitsTechnicsDelete: false,
  agencyRateRead: false,
  agencyRateWrite: false,
  agencyRateDelete: false,
  settingsAutomationRead: false,
  settingsAutomationWrite: false,
  settingsManagementWrite: false,
  indexationCreationRead: false,
  indexationCreationWrite: false,
  indexationCreationDelete: false,

  documents: false,
};

export const defaultTenantPermissions: Permissions = {
  notificationsDetailsRead: false,
  notificationsDetailsWrite: false,
  notificationsDetailsDelete: false,
  communicationsDetailsRead: true,
  communicationsDetailsWrite: false,
  communicationsDetailsDelete: false,
  contactsDetailsRead: true,
  contactsDetailsWrite: false,
  contactsDetailsDelete: false,
  ticketsDetailsRead: true,
  ticketsDetailsWrite: true,
  ticketsDetailsDelete: false,
  advertisementsDetailsRead: false,
  advertisementsDetailsWrite: false,
  advertisementsDetailsDelete: false,

  signatureDetailsRead: true,
  signatureDetailsWrite: false,
  signatureDetailsDelete: false,

  templatesContractsRead: false,
  templatesContractsWrite: false,
  templatesCommunicationsRead: false,
  templatesCommunicationsWrite: false,
  financialTransactionsRead: true,
  financialTransactionsWrite: false,
  financialTransactionsDelete: false,
  financialMortgagesRead: false,
  financialMortgagesWrite: false,
  financialMortgagesDelete: false,
  financialValuationsAndCostsRead: false,
  financialValuationsAndCostsWrite: false,
  financialValuationsAndCostsDelete: false,
  leasesCreationRead: false,
  leasesCreationWrite: false,
  leasesCreationDelete: false,
  leasesSignatureWrite: false,
  leasesDetailsRead: true,
  leasesDetailsWrite: false,
  leasesDetailsDelete: false,
  inventoryOfFixturesCreationRead: false,
  inventoryOfFixturesCreationWrite: false,
  inventoryOfFixturesCreationDelete: false,
  furnituresInventoryCreationRead: false,
  furnituresInventoryCreationWrite: false,
  furnituresInventoryCreationDelete: false,
  buildingsUnitsDetailsRead: true,
  buildingsUnitsDetailsWrite: false,
  buildingsUnitsDetailsDelete: false,
  buildingsUnitsUtilityProvidersRead: true,
  buildingsUnitsUtilityProvidersWrite: false,
  buildingsUnitsUtilityProvidersDelete: false,
  buildingsUnitsTechnicsRead: true,
  buildingsUnitsTechnicsWrite: false,
  buildingsUnitsTechnicsDelete: false,
  agencyRateRead: false,
  agencyRateWrite: false,
  agencyRateDelete: false,
  settingsAutomationRead: false,
  settingsAutomationWrite: false,
  settingsManagementWrite: false,
  indexationCreationRead: false,
  indexationCreationWrite: false,
  indexationCreationDelete: false,

  documents: false,
};

export const hasEnoughRightsToDeepDeleteLease = (permissions: Permissions) => {
  const {
    leasesCreationDelete,
    inventoryOfFixturesCreationDelete,
    communicationsDetailsDelete,
    financialTransactionsDelete,
    furnituresInventoryCreationDelete,
  } = permissions;
  return (
    leasesCreationDelete &&
    inventoryOfFixturesCreationDelete &&
    communicationsDetailsDelete &&
    financialTransactionsDelete &&
    furnituresInventoryCreationDelete
  );
};

/** Context */
export const PermissionsReactContext = React.createContext<PermissionsContext | null>(null);
export const PermissionsContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const {
    User: { data: users, loading: usersLoading },
    UserRole: { data: roles, loading: userRolesLoading },
  } = useContextLoader();
  const { userId, isOwner, isFetchingUser, rootUser } = useUser();
  const loading = usersLoading || isFetchingUser || userRolesLoading;
  const values = React.useMemo(() => {
    const falsyPermissions = Object.keys(defaultOwnerPermissions).reduce(
      (res, key) => ({ ...res, [key]: false }),
      {}
    ) as Permissions;
    const trulyPermissions = Object.keys(defaultOwnerPermissions).reduce(
      (res, key) => ({ ...res, [key]: true }),
      {}
    ) as Permissions;
    if (rootUser) {
      // Root Account or full Admin
      return { ...trulyPermissions, loading };
    }
    if (loading) {
      return {
        ...falsyPermissions,
        contactsDetailsRead: true,
        loading,
      };
    }

    // Owner
    if (isOwner) {
      return { ...defaultOwnerPermissions, loading };
    }

    const currentUser = users.find((user) => user.id === userId);
    if (isNil(currentUser)) {
      return {
        ...falsyPermissions,
        loading,
      };
    }

    const currentUserRole = roles.find((role) => role.id === currentUser.userRoleId);

    if (
      currentUser.rootUser ||
      (!isNil(currentUserRole) &&
        !isNil(currentUserRole.userRights) &&
        isSettingsOrganizationOn(currentUserRole.userRights))
    ) {
      // Root Account or full Admin
      return { ...trulyPermissions, loading };
    }
    // Wrongly configured, should not happens
    if (isNil(currentUserRole) || isNil(currentUserRole.userRights)) {
      return {
        ...falsyPermissions,
        contactsDetailsRead: true,
        loading,
      };
    }

    // Fill in depending on userRight
    const flattenRights = currentUserRole.userRights.reduce((result, currentRight) => {
      if (isNil(currentRight.access) || isEmpty(currentRight.access)) {
        return result;
      }
      const rights = (currentRight.access as UserRightAccessType[]).reduce(
        (resultForSection, currentAccess) => ({
          ...resultForSection,
          [toCamel(`${currentRight.section}_${currentAccess}`.toLowerCase())]: true,
        }),
        {} as Partial<Permissions>
      );
      return { ...result, ...rights };
    }, falsyPermissions);
    return {
      loading,
      ...flattenRights,
      contactsDetailsRead: true,
    };
  }, [loading, isOwner, userId, users, roles, rootUser]);
  return <PermissionsReactContext.Provider value={values}>{children}</PermissionsReactContext.Provider>;
};

export const TenantPermissionsContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <PermissionsReactContext.Provider value={{ ...defaultOwnerPermissions, loading: false }}>
      {children}
    </PermissionsReactContext.Provider>
  );
};

export const usePermissions = (): PermissionsContext => {
  const context = useContext<PermissionsContext | null>(PermissionsReactContext);

  if (typeof context === 'undefined' || context === null) {
    throw new Error('`usePermissions` hook must be used within a `PermissionsContextProvider` component');
  }
  return context;
};
