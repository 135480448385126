/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import { ClickAwayListener, FilledInput, FormControl, Grid, InputLabel, makeStyles, Theme } from '@material-ui/core';
import React, { useState } from 'react';
import { useCustomizedComboStyles } from '../ComboBox/TextComboBox';
import { ReactComponent as DropDown } from '../../../icons/drop-down.svg';
import NestedPopper from './NestedPopper';
import { isNil, isEmpty } from 'lodash';
import Fuse from 'fuse.js';
import { DataTypes, SearchButtons } from 'src/components/Dashboard/DashboardTodos/DashboardDialogs/AddNotification';
import { Colors } from '@rentguru/commons-utils';

export interface NestedElement {
  primaryLabel: string;
  type: DataTypes;
  value: any; // Value to return if clicked on the element.
  secondaryLabel?: string;
  group?: number; // Tell component how to split element on the same level (where to put divider)
  children?: NestedElement[];
  selectable?: boolean;
  FrontIcon?: React.ReactNode;
  forceSelect?: 'true' | 'false'; // Needs to be string for FuseJS only works with strings.
}

interface NestedSelectorProps {
  datas: NestedElement[];
  selectorLabel: string;
  value: NestedElement | null;
  onChange: (value: any) => void;
  id?: string;
  searchButtons?: SearchButtons[];
  selectedSearchType?: DataTypes;
  setSelectedSearchType?: (value: DataTypes) => void;
}

export interface FuseOptions {
  isCaseSensitive?: boolean;
  includeScore?: boolean;
  shouldSort?: boolean;
  includeMatches?: boolean;
  findAllMatches?: boolean;
  minMatchCharLength?: number;
  location?: number;
  threshold?: number;
  distance?: number;
  useExtendedSearch?: boolean;
  ignoreLocation?: boolean;
  ignoreFieldNorm?: boolean;
  keys: Array<string | string[]>;
}

const localAnimationStyles = makeStyles((theme: Theme) => ({
  open: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  closed: {
    transform: 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
}));

const getDepth = (data: NestedElement[]): number => {
  return 1 + Math.max(0, ...data.map(({ children = [] }) => getDepth(children)));
};

const buildFuzeKeys = (dataDepth: number): Array<string | string[]> => {
  const keys: Array<string | string[]> = []; // either string or string[]
  let baseDepthString = 'children';
  for (let i = 0; i < dataDepth - 1; i++) {
    if (i === 0) {
      keys.push('primaryLabel', 'secondaryLabel', 'forceSelect');
    } /* if (i === 1) */ else {
      keys.push(
        `${baseDepthString}.primaryLabel`,
        `${baseDepthString}.secondaryLabel`,
        `${baseDepthString}.forceSelect`
      );
      baseDepthString += '.children';
    }
  }
  return keys;
};

const globalFilterOnDataAccordingToSearchValue = (
  datas: NestedElement[],
  dataDepth: number,
  searchValue: string,
  selectedSearchType: DataTypes | undefined
) => {
  const actualResult: NestedElement[] = [];
  return getFuseFilter(actualResult, dataDepth, datas, searchValue, selectedSearchType);
};

const getFuseFilter = (
  actualResult: NestedElement[],
  dataDepth: number,
  datas: NestedElement[],
  searchValue: string,
  selectedSearchType: DataTypes | undefined
): NestedElement[] => {
  if (dataDepth === -1) return [];
  if (isNil(datas) || isEmpty(datas)) return [];

  const options: FuseOptions = {
    isCaseSensitive: false,
    includeScore: true,
    shouldSort: true,
    includeMatches: false,
    findAllMatches: false,
    threshold: 0.2,
    ignoreLocation: true,
    useExtendedSearch: true,
    keys: buildFuzeKeys(dataDepth),
  };

  const fuse = new Fuse(datas, options);
  const dataToDisplay = searchValue
    ? fuse.search(searchValue).map((data) => {
        return data.item;
      })
    : datas;

  for (let i = 0; i < dataToDisplay.length; i++) {
    if (!isNil(selectedSearchType) && selectedSearchType !== dataToDisplay[i].type) {
      actualResult[i] = {
        ...dataToDisplay[i],
        children: getFuseFilter(
          [] as NestedElement[],
          dataDepth - 1,
          dataToDisplay[i].children as NestedElement[],
          searchValue,
          selectedSearchType
        ),
      };
    } else {
      actualResult[i] = dataToDisplay[i];
    }
  }

  return actualResult;
};

const NestedSelector: React.FC<NestedSelectorProps> = ({
  datas,
  onChange,
  selectorLabel,
  id,
  value,
  searchButtons,
  selectedSearchType,
  setSelectedSearchType,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const dataDepth = getDepth(datas);

  const onSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
      setAnchorEl(null);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const dataToDisplay: NestedElement[] = searchValue
    ? globalFilterOnDataAccordingToSearchValue(datas, dataDepth, searchValue, selectedSearchType)
    : datas;

  const open = Boolean(anchorEl);
  const classes = useCustomizedComboStyles();
  const animations = localAnimationStyles();

  return (
    <Grid>
      <FormControl fullWidth margin="dense">
        <InputLabel margin="dense" variant="filled" style={{ color: Colors.LIGHT_BLUE_GREY }}>
          {selectorLabel}
        </InputLabel>
        <FilledInput
          style={{ width: 260 }}
          id={id ?? 'nestedSelector'}
          readOnly
          classes={{ underline: classes.underline }}
          endAdornment={
            <DropDown style={{ fill: Colors.BLUEY }} className={open ? animations.open : animations.closed} />
          }
          onClick={handleClick}
          value={
            !isNil(value?.primaryLabel) && !isNil(value?.secondaryLabel)
              ? `${value!.primaryLabel} - ${value!.secondaryLabel}`
              : ''
          }
        />
      </FormControl>

      {!isNil(anchorEl) && open && (
        <ClickAwayListener
          onClickAway={() => {
            handleClose();
          }}
        >
          <Grid>
            <NestedPopper
              parentAnchorEl={anchorEl}
              handleClose={handleClose}
              onSearchChange={onSearchChange}
              searchValue={searchValue}
              searchBar={true}
              open={open}
              datas={dataToDisplay}
              placement="bottom"
              setFirstNodeValue={onChange}
              generalClosing={handleClose}
              width={340}
              SearchButtons={searchButtons}
              setSelectedSearchType={setSelectedSearchType}
              selectedSearchType={selectedSearchType}
            />
          </Grid>
        </ClickAwayListener>
      )}
    </Grid>
  );
};

export default NestedSelector;
