import React, { forwardRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import ExpandMore from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { Colors } from '@rentguru/commons-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
      cursor: 'pointer',
      display: 'inline-flex',
      justifyContent: 'flex-start',
      flexDirection: 'inherit',
      alignItems: 'center',
      fontFamily: 'Mulish',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '15px',
      color: Colors.BLUE_GREY,
      '&:hover': {
        color: Colors.SILVER,
      },
      '&:focus': {
        color: Colors.BLUE_GREY,
      },
    },
    active: {
      color: Colors.BLUE_GREY,
      '& $icon': {
        opacity: 1,
      },
    },
    icon: {
      height: 16,
      marginRight: 4,
      marginLeft: 4,
      opacity: 0,
      transition: theme.transitions.create(['opacity', 'transform'], {
        duration: theme.transitions.duration.shorter,
      }),
      userSelect: 'none',
      width: 16,
    },
  })
);
export interface FlatButtonProps {
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: React.ReactEventHandler<any>;
  hideExpandIcon?: boolean;
  active?: boolean;
  isOpen?: boolean;
  selected?: boolean;
  disabled?: boolean;
}

const rotatingIconStyle = (isOpen: boolean): object => ({
  transform: `rotate(${isOpen ? 180 : 0}deg)`,
});

// eslint-disable-next-line react/display-name
const FlatButton: React.FC<FlatButtonProps> = forwardRef<HTMLAnchorElement, FlatButtonProps>((props, ref) => {
  const classes = useStyles();
  const { active, children, hideExpandIcon, isOpen = false, selected, disabled, ...other } = props;

  return (
    <ButtonBase
      className={clsx(classes.root, { [classes.active]: active })}
      component="span"
      disableRipple
      disabled={disabled}
      ref={ref}
      {...other}
      style={{ fontWeight: selected ? 'bold' : 'normal' }}
    >
      {children}
      {hideExpandIcon || !active ? null : (
        <ExpandMore style={rotatingIconStyle(isOpen)} className={clsx(classes.icon)} />
      )}
    </ButtonBase>
  );
});

FlatButton.defaultProps = {
  active: true,
  hideExpandIcon: false,
  isOpen: false,
  selected: false,
};

export default FlatButton;
