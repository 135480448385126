import { Collapse, Grid, makeStyles, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import { Colors, roundAtSecondDecimal } from '@rentguru/commons-utils';
import { useFormikContext } from 'formik';
import { clamp, get, toNumber } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AddLeaseFormValues } from '../../../Leases/AddLease/AddLeaseForm';
import { resolveMainUnitAndSubUnitIndexAndPrefix } from '../../../Leases/AddLease/useAddEditLeaseUtils';
import CustomCheckBox from '../../CustomCheckBox';
import { useStyles } from '../../TextDetailEditable';
import TitledSwitch from '../../TitledSwitch';

export interface FurnishedRentalFormValues {
  furnishedRental: {
    furnishedRental: boolean;
    funrituresShouldBeCoveredByInsurance: boolean;
    percentageOfRentAssignedToFurnitures: number;
  };
}

export const useAdornmentStyles = makeStyles({
  tabStyle: {
    fontWeight: 400,
    textTransform: 'none', // Not in uppercase!!
    minWidth: 50,
  },
  adornedEndClass: {
    width: 200,
  },
});

interface FurnishedRentalProps {
  unitId: string;
}

const FurnishedRental: React.FC<FurnishedRentalProps> = ({ unitId }) => {
  const { values, setFieldValue, errors, touched } = useFormikContext<AddLeaseFormValues>();
  const classes = useStyles();
  const adornmentClasses = useAdornmentStyles();
  const { formatMessage } = useIntl();
  const [selectedTab, setSelectedTab] = useState<'%' | 'EUR'>('%');
  const [eurPortionOfRent, setEurPortionOfRent] = useState<number>(0);

  const { prefixFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(values, unitId);

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: '%' | 'EUR') => {
    setSelectedTab(newTab);
  };

  const isActive = get(values, `${prefixFieldName}furnishedRental.furnishedRental`, false);
  const currentUnitRentalPrice = get(values, `${prefixFieldName}rentalPrice`, 0);
  const currentPercentageOfRentAssignedToFurniture = get(
    values,
    `${prefixFieldName}furnishedRental.percentageOfRentAssignedToFurnitures`,
    0
  );

  const currentPercentageOfRentAssignedToFurnitureValue =
    currentPercentageOfRentAssignedToFurniture === 0 ? '' : currentPercentageOfRentAssignedToFurniture?.toString();

  useEffect(() => {
    setEurPortionOfRent(
      roundAtSecondDecimal((currentUnitRentalPrice * currentPercentageOfRentAssignedToFurniture) / 100) >
        currentUnitRentalPrice
        ? currentUnitRentalPrice
        : roundAtSecondDecimal((currentUnitRentalPrice * currentPercentageOfRentAssignedToFurniture) / 100)
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitId]);

  return (
    <Grid style={{ margin: '10px 0px 0px 30px', maxWidth: 580 }}>
      <Grid>
        <TitledSwitch
          fieldName={`${prefixFieldName}furnishedRental.furnishedRental`}
          titleMessageId="lease.addLease.furnishedRental"
          switchOnTextId="lease.addLease.includeInLease"
          switchOffTextId="lease.addLease.ignore"
        />
      </Grid>
      <Collapse in={isActive}>
        <Grid style={{ display: 'flex', alignItems: 'center' }}>
          <CustomCheckBox
            isChecked={get(values, `${prefixFieldName}furnishedRental.funrituresShouldBeCoveredByInsurance`, false)}
            onCheck={(_event, checked) =>
              setFieldValue(`${prefixFieldName}furnishedRental.funrituresShouldBeCoveredByInsurance`, checked)
            }
            name={`${prefixFieldName}furnishedRental.funrituresShouldBeCoveredByInsurance`}
          />
          <Typography style={{ marginLeft: 10, color: Colors.SLATE_GREY }}>
            {formatMessage({ id: 'lease.addLease.furnitureInsurance' })}
          </Typography>
        </Grid>
        <Grid style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
          <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
            {formatMessage({ id: 'lease.addLease.furniturePercentage' })}
          </Typography>
          <TextField
            label=""
            id="percentageOfRentAssignedToFurnitures"
            type="number"
            margin="dense"
            variant="filled"
            value={selectedTab === '%' ? currentPercentageOfRentAssignedToFurnitureValue : eurPortionOfRent?.toString()}
            onChange={(event) => {
              const correctValueEurValue = clamp(toNumber(event.target.value), 0, currentUnitRentalPrice);
              const correctPercentValue = clamp(correctValueEurValue, 0, 100);

              if (selectedTab === '%') {
                setFieldValue(
                  `${prefixFieldName}furnishedRental.percentageOfRentAssignedToFurnitures`,
                  correctPercentValue
                );
                setEurPortionOfRent(
                  roundAtSecondDecimal((currentUnitRentalPrice * correctValueEurValue) / 100) > currentUnitRentalPrice
                    ? currentUnitRentalPrice
                    : roundAtSecondDecimal((currentUnitRentalPrice * correctValueEurValue) / 100)
                );
              } else {
                setFieldValue(
                  `${prefixFieldName}furnishedRental.percentageOfRentAssignedToFurnitures`,
                  roundAtSecondDecimal((correctValueEurValue / currentUnitRentalPrice) * 100)
                );
                setEurPortionOfRent(roundAtSecondDecimal(correctValueEurValue));
              }
            }}
            InputProps={{
              endAdornment: (
                <Grid style={{ width: 110 }}>
                  <Tabs
                    TabIndicatorProps={{ style: { display: 'none' } }}
                    value={selectedTab}
                    onChange={handleTabChange}
                    variant="standard"
                  >
                    <Tab className={adornmentClasses.tabStyle} label="%" value="%" id="%Value" />
                    <Tab className={adornmentClasses.tabStyle} label="EUR" value="EUR" id="EURValue" />
                  </Tabs>
                </Grid>
              ),
              classes: { underline: classes.underline, input: classes.noEndArrow },
              style: { width: 580 },
            }}
            inputProps={{
              style: {
                paddingTop: 15,
                paddingBottom: 15,
              },
              step: 0,
              min: 0,
            }}
            InputLabelProps={{ className: classes.label }}
            error={Boolean(
              errors &&
                get(errors, `${prefixFieldName}furnishedRental.percentageOfRentAssignedToFurnitures`) &&
                touched &&
                get(touched, `${prefixFieldName}furnishedRental.percentageOfRentAssignedToFurnitures`)
            )}
            onWheel={(e) => {
              if (e.target instanceof HTMLElement) {
                e.target.blur();
              }
            }}
          />
        </Grid>
      </Collapse>
    </Grid>
  );
};

export default FurnishedRental;
