import { AdvertisementUnitSubType } from '@rentguru/commons-utils';

interface typesAndSubTypes {
  APARTMENT: string[];
  HOUSE: string[];
  LAND: string[];
  COMMERCIAL: string[];
  GARAGEPARKING: string[];
  SPECIAL: string[];
}

export const typesAndSubTypesArray: typesAndSubTypes = {
  APARTMENT: [
    AdvertisementUnitSubType.STUDIO,
    AdvertisementUnitSubType.DUPLEX,
    AdvertisementUnitSubType.TRIPLEX,
    AdvertisementUnitSubType.PENTHOUSE,
    AdvertisementUnitSubType.GROUNDFLOORFLAT,
    AdvertisementUnitSubType.LOFT,
    AdvertisementUnitSubType.STUDENTFLAT,
    AdvertisementUnitSubType.BUILDING,
    AdvertisementUnitSubType.FLAT,
    AdvertisementUnitSubType.APARTMENTSTANDARD,
  ],
  HOUSE: [
    AdvertisementUnitSubType.VILLA,
    AdvertisementUnitSubType.TERRACED,
    AdvertisementUnitSubType.FARM,
    AdvertisementUnitSubType.MANSION,
    AdvertisementUnitSubType.COTTAGE,
    AdvertisementUnitSubType.BUNGALOW,
    AdvertisementUnitSubType.CASTLE,
    AdvertisementUnitSubType.DETACHED,
    AdvertisementUnitSubType.SEMIDETACHED,
    AdvertisementUnitSubType.KANGAROOHOUSE,
    AdvertisementUnitSubType.PILEHOUSE,
    AdvertisementUnitSubType.HOUSEBOAT,
    AdvertisementUnitSubType.MOBILEHOME,
    AdvertisementUnitSubType.MIXED,
  ],
  LAND: [
    AdvertisementUnitSubType.BUILDINGLAND,
    AdvertisementUnitSubType.MEADOW,
    AdvertisementUnitSubType.WOOD,
    AdvertisementUnitSubType.INDUSTRIALLAND,
    AdvertisementUnitSubType.FARMLAND,
  ],
  COMMERCIAL: [
    AdvertisementUnitSubType.OFFICE,
    AdvertisementUnitSubType.OFFICEBUILDING,
    AdvertisementUnitSubType.WAREHOUSE,
    AdvertisementUnitSubType.SHOP,
    AdvertisementUnitSubType.RESTAURANT,
    AdvertisementUnitSubType.BAR,
    AdvertisementUnitSubType.HOTEL,
    AdvertisementUnitSubType.RETAILSPACE,
    AdvertisementUnitSubType.INDUSTRIAL,
    AdvertisementUnitSubType.BUSINESS,
  ],
  GARAGEPARKING: [
    AdvertisementUnitSubType.OPENAIRSPACE,
    AdvertisementUnitSubType.OUTSIDEBOX,
    AdvertisementUnitSubType.INSIDEBOX,
    AdvertisementUnitSubType.COVEREDSPACE,
    AdvertisementUnitSubType.CARPARK,
    AdvertisementUnitSubType.INDOORSPACE,
  ],
  SPECIAL: [],
};
