import React, { useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { isIdInList, Colors, ObjectWithId } from '@rentguru/commons-utils';
import { useFormikContext } from 'formik';
import CustomCheckBox from './CustomCheckBox';
import { useIntl } from 'react-intl';
import { useStyles } from 'src/components/Dashboard/DashboardTodos/SlicedContent';

const DEFAULT_DISPLAY_SECTION_SIZE = 20;

export interface CustomCheckBoxGroupItem extends ObjectWithId {
  name: string;
}

export interface CustomCheckBoxGroupProps<T extends CustomCheckBoxGroupItem> {
  items: T[];
  itemsFieldName: string;
  selectedItems: T[];
  isItemDisabled?: (item: T) => boolean;
  isItemWarning?: (item: T) => boolean;
  CustomCheckBoxGroupContentComponent?: React.FC<CustomCheckBoxGroupContentProps<T>>;
}

const CustomCheckBoxGroup = <T extends CustomCheckBoxGroupItem>({
  items,
  selectedItems,
  itemsFieldName,
  isItemDisabled,
  isItemWarning,
  CustomCheckBoxGroupContentComponent = CustomCheckBoxGroupDefaultContent,
}: CustomCheckBoxGroupProps<T>) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { setFieldValue } = useFormikContext<unknown>();
  const [maxItemsToDisplay, setMaxItemsToDisplay] = useState<number>(DEFAULT_DISPLAY_SECTION_SIZE);

  const slicedItems = items.slice(0, maxItemsToDisplay);
  const isLessItemsDisplayed = maxItemsToDisplay < items.length;
  const expansionText = formatMessage({ id: isLessItemsDisplayed ? 'dashboard.seeMore' : 'dashboard.seeLess' });
  const expansionAction = isLessItemsDisplayed
    ? () => setMaxItemsToDisplay(Math.min(items.length, maxItemsToDisplay + DEFAULT_DISPLAY_SECTION_SIZE))
    : () => setMaxItemsToDisplay(Math.min(DEFAULT_DISPLAY_SECTION_SIZE, items.length));

  const onCheck = (item: T) => {
    const isItemSelected = isIdInList(item, selectedItems);
    setFieldValue(
      itemsFieldName,
      isItemSelected ? selectedItems.filter((selectedItem) => selectedItem.id !== item.id) : [...selectedItems, item]
    );
  };

  return (
    <>
      <Grid container>
        {slicedItems.map((item) => {
          const isItemSelected = isIdInList(item, selectedItems);
          const displayWarning = isItemWarning ? isItemWarning(item) : false;
          const isDisabled = isItemDisabled ? isItemDisabled(item) : false;
          return (
            <CustomCheckBoxGroupContentComponent
              key={item.id}
              isWarning={displayWarning}
              isChecked={isItemSelected}
              isDisabled={isDisabled}
              onCheck={onCheck}
              item={item}
            />
          );
        })}
      </Grid>
      {items.length > DEFAULT_DISPLAY_SECTION_SIZE && (
        <Grid style={{ lineHeight: 2 }}>
          <Button onClick={expansionAction} className={classes.expansionText} size="small">
            {expansionText}
          </Button>
        </Grid>
      )}
    </>
  );
};

export interface CustomCheckBoxGroupContentProps<T extends CustomCheckBoxGroupItem> {
  isWarning: boolean;
  isChecked: boolean;
  isDisabled: boolean;
  onCheck: (item: T) => void;
  item: T;
}

const CustomCheckBoxGroupDefaultContent = <T extends CustomCheckBoxGroupItem>({
  isWarning,
  isChecked,
  isDisabled,
  onCheck,
  item,
}: CustomCheckBoxGroupContentProps<T>) => {
  return (
    <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
      <CustomCheckBox
        checkedColor={isWarning ? Colors.BURNING_ORANGE : Colors.DODGER_BLUE}
        isChecked={isChecked}
        disabled={isDisabled}
        onCheck={() => onCheck(item)}
      />
      <Typography style={{ fontSize: 12, fontWeight: 500, ...(isWarning && { color: Colors.BURNING_ORANGE }) }}>
        {item.name}
      </Typography>
    </Grid>
  );
};

export default CustomCheckBoxGroup;
