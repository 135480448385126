/* eslint-disable @typescript-eslint/no-shadow */
import { Button, Divider, Tab, Typography } from '@material-ui/core';
import Dialog from 'src/components/ui/Dialog';
import Add from '@material-ui/icons/Add';
import { isNaN, isNil } from 'lodash';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useAgencyRates } from 'src/hooks/AgencyRatesContext';
import { useSettings } from 'src/hooks/SettingsContext';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { AgencyRate, SETTING_VAT_AGENCY_RATE, formatNumber, Colors } from '@rentguru/commons-utils';
import { ExitableLocation, RouteDestination } from '../Routes/Routes';
import { ActionButton , ContentHeader, CustomizedTabs, ElevatedPaper } from '@up2rent/ui';
import EntityDetail from '../ui/EntityDetail/EntityDetail';
import AgencyRateBox from './AgencyRate/AgencyRateBox';
import AgencyRateIncompleteOwners from './AgencyRate/AgencyRateIncompleteOwners';
import DeleteAgencyRate from './AgencyRate/DeleteAgencyRate';
import AddEditAgencyRateForm from './AgencyRate/Details/AddEditAgencyRateForm';
import GeneralDetails from './AgencyRate/Details/GeneralDetails';
import { ReactComponent as Chevron } from '../../icons/chevron-right.svg';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import VATSelectorDialog from '../ui/Dialogs/VATSelectorDialog';

enum TabIndex {
  LIST_AGENCY_RATE,
  LIST_ORPHAN_OWNERS,
}

const AgencyRatesSettings: React.FC = () => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const { loading, error, agencyRates } = useAgencyRates();
  const { agencyRateWrite } = usePermissions();
  const { settings, settingsLoading, updateSetting } = useSettings();
  const [editVatDialogOpen, setEditVatDialogOpen] = useState<boolean>(false);
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<string | null>(null);
  const [tabIndex, setTabIndex] = useState<TabIndex>(TabIndex.LIST_AGENCY_RATE);

  // Nav
  const history = useHistory();
  const matchLocal = useRouteMatch<{ agencyRateId: string }>({
    path: `${RouteDestination.SETTINGS}/agencyRates/:agencyRateId?`,
  });
  const { state: exitParams } = useLocation<ExitableLocation>();
  const openDetail = matchLocal?.params?.agencyRateId;

  if (loading || !agencyRates || settingsLoading) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{formatMessage({ id: 'loading' })}</Typography>
      </ElevatedPaper>
    );
  }
  if (error) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{error}</Typography>
      </ElevatedPaper>
    );
  }

  const clientVatSetting = settings.find((s) => s.name === SETTING_VAT_AGENCY_RATE);

  const doAction = (action: 'EDIT' | 'DELETE' | 'DELETE_OWNER_FROM_RATE', id: string) => {
    if (action === 'EDIT') {
      history.push(`${RouteDestination.SETTINGS}/agencyRates/${id}/description`);
    } else if (action === 'DELETE') {
      setDeleteDialogOpen(id);
    }
  };

  const onCloseDialog = () => {
    setAddDialogOpen(false);
    setDeleteDialogOpen(null);
  };

  const handleCloseDetail = () => {
    if (exitParams?.goBackUrl) history.replace(exitParams.goBackUrl);
    else history.push(`${RouteDestination.SETTINGS}/agencyRates`);
  };

  const handleTabChange = (index: TabIndex) => {
    setTabIndex(index);
  };

  return (
    <>
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <ContentHeader title={formatMessage({ id: 'settings.agencyRates' })}>
          {agencyRateWrite && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!isNil(clientVatSetting) && (
                <Button onClick={() => setEditVatDialogOpen(true)}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      style={{
                        fontSize: 12,
                        color: Colors.DARK_SLATE_GREY,
                        textTransform: 'uppercase',
                        fontWeight: 700,
                      }}
                    >
                      {`${formatMessage({ id: 'accounting.reporting.fees.vatRate' })}: ${
                        !isNil(clientVatSetting.value) &&
                        clientVatSetting.value !== 'N/A' &&
                        !isNaN(Number(clientVatSetting.value))
                          ? formatNumber(Number(clientVatSetting.value), language)
                          : 'N/A'
                      }%`}
                    </Typography>
                    <Chevron style={{ transform: `rotate(90deg)`, height: 11, width: 11, marginLeft: 5 }} />
                  </div>
                </Button>
              )}
              <ActionButton onClick={() => setAddDialogOpen(true)}>{formatMessage({ id: 'add' })}</ActionButton>
            </div>
          )}
        </ContentHeader>
        <CustomizedTabs handleChange={(i: number) => handleTabChange(i)}>
          <Tab label={formatMessage({ id: 'settings.agencyRates' })} id={`${TabIndex.LIST_AGENCY_RATE}`} />
          <Tab
            label={formatMessage({ id: 'agencyRates.ownersWithoutRateCard' })}
            id={`${TabIndex.LIST_ORPHAN_OWNERS}`}
          />
        </CustomizedTabs>
        <Divider />
        <div
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 30,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          {tabIndex === TabIndex.LIST_AGENCY_RATE && (
            <>
              {agencyRates.map((agencyRate) =>
                agencyRate.custom ? null : (
                  <div style={{ marginBottom: 20 }} key={agencyRate.id}>
                    <AgencyRateBox agencyRate={agencyRate} doAction={doAction} />
                  </div>
                )
              )}
              {agencyRateWrite && (
                <div
                  onClick={() => setAddDialogOpen(true)}
                  style={{
                    width: 280,
                    height: 290,
                    border: `1px dashed ${Colors.SILVER}`,
                    borderRadius: 10,
                    cursor: 'pointer',
                    marginBottom: 20,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      paddingLeft: 20,
                      paddingRight: 20,
                    }}
                  >
                    <Add fill={Colors.SLATE_GREY} />
                    <Typography
                      style={{
                        fontSize: 12,
                        fontWeight: 'normal',
                        fontStyle: 'normal',
                        letterSpacing: 0,
                        color: Colors.SLATE_GREY,
                      }}
                    >
                      {formatMessage({ id: 'agencyRates.add' })}
                    </Typography>
                  </div>
                </div>
              )}
            </>
          )}
          {tabIndex === TabIndex.LIST_ORPHAN_OWNERS && <AgencyRateIncompleteOwners />}
        </div>
      </ElevatedPaper>
      <EntityDetail open={Boolean(openDetail)} onClose={handleCloseDetail}>
        <GeneralDetails agencyRateId={matchLocal?.params.agencyRateId || ''} />
      </EntityDetail>
      <Dialog
        open={addDialogOpen}
        onClose={onCloseDialog}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xl"
        PaperProps={{ style: { width: 640 } }}
      >
        <AddEditAgencyRateForm
          afterSubmit={(agencyRate?: AgencyRate) => {
            onCloseDialog();
            if (agencyRate) doAction('EDIT', agencyRate.id);
          }}
        />
      </Dialog>
      <Dialog
        open={!isNil(deleteDialogOpen)}
        onClose={onCloseDialog}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DeleteAgencyRate afterSubmit={onCloseDialog} id={deleteDialogOpen!} />
      </Dialog>
      {editVatDialogOpen && (
        <VATSelectorDialog
          originalVatSetting={clientVatSetting!}
          title={formatMessage({ id: 'accounting.vat.selectAgencyFees' })}
          open={editVatDialogOpen}
          onClose={() => setEditVatDialogOpen(false)}
          updateSetting={updateSetting}
        />
      )}
    </>
  );
};

export default AgencyRatesSettings;
