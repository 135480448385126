import React, { createContext, useState, useContext } from 'react';
import { CustomSimpleDialog } from '@up2rent/ui';
import { Grid, Typography } from '@material-ui/core';
import { IntlFormatters } from 'react-intl';
import { isNil } from 'lodash';

export enum ConfirmationDialogOptions {
  ACTION_BUTTON,
  THIRD_BUTTON,
}

interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  text: string;
  actionButtonLabel: string;
  thirdButtonLabel?: string;
  onConfirm: (option: ConfirmationDialogOptions) => void;
  onDismiss: () => void;
  formatMessage: IntlFormatters['formatMessage'];
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  text,
  actionButtonLabel,
  thirdButtonLabel,
  onConfirm,
  onDismiss,
  formatMessage,
}) => {
  return (
    <CustomSimpleDialog
      formatMessage={formatMessage}
      open={open}
      onClose={onDismiss}
      onActionButtonClick={() => onConfirm(ConfirmationDialogOptions.ACTION_BUTTON)}
      actionButtonLabel={actionButtonLabel}
      title={title}
      hideThirdButton={isNil(thirdButtonLabel)}
      thirdButtonLabel={thirdButtonLabel}
      onThirdButtonClick={() => onConfirm(ConfirmationDialogOptions.THIRD_BUTTON)}
    >
      <Grid>
        <Typography>{text}</Typography>
      </Grid>
    </CustomSimpleDialog>
  );
};

export interface DialogConfig {
  title: string;
  text: string;
  actionButtonLabel: string;
  thirdButtonLabel?: string;
  formatMessage: IntlFormatters['formatMessage'];
  actionCallback?: (confirmed: ConfirmationDialogOptions | undefined) => void;
}

export interface ConfirmationDialogContext {
  getConfirmation: (options: Omit<DialogConfig, 'actionCallback'>) => Promise<ConfirmationDialogOptions | undefined>;
}

// eslint-disable-next-line no-redeclare
export const ConfirmationDialogContext = createContext<ConfirmationDialogContext | null>(null);

export const ConfirmationDialogProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogConfig, setDialogConfig] = useState<Partial<DialogConfig>>({});

  const openDialog = (newDialogConfig: DialogConfig) => {
    setDialogOpen(true);
    setDialogConfig(newDialogConfig);
  };

  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig({});
  };

  const onConfirm = (confirmationDialogOptions: ConfirmationDialogOptions) => {
    resetDialog();
    dialogConfig.actionCallback?.(confirmationDialogOptions);
  };

  const onDismiss = () => {
    resetDialog();
    dialogConfig.actionCallback?.(undefined);
  };

  const getConfirmation = (options: Omit<DialogConfig, 'actionCallback'>) => {
    return new Promise<ConfirmationDialogOptions | undefined>((resolve) => {
      openDialog({ ...options, actionCallback: resolve });
    });
  };

  return (
    <ConfirmationDialogContext.Provider value={{ getConfirmation }}>
      <ConfirmationDialog
        open={dialogOpen}
        text={dialogConfig.text || ''}
        title={dialogConfig.title || ''}
        actionButtonLabel={dialogConfig.actionButtonLabel || ''}
        thirdButtonLabel={dialogConfig.thirdButtonLabel}
        onConfirm={(option) => onConfirm(option)}
        onDismiss={onDismiss}
        formatMessage={dialogConfig.formatMessage || (() => '')}
      />
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

export const useConfirmationDialog = (): ConfirmationDialogContext => {
  const context = useContext<ConfirmationDialogContext | null>(ConfirmationDialogContext);
  if (!context) {
    throw new Error('useConfirmationDialog must be used within a ConfirmationDialogProvider');
  }

  return context as ConfirmationDialogContext;
};
