import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import { Colors } from '@rentguru/commons-utils';

export default withStyles({
  root: {
    color: Colors.BLUE_GREY,
    '&$checked': {
      color: Colors.DODGER_BLUE,
      '&:hover': {
        backgroundColor: `${Colors.DODGER_BLUE}10`,
      },
    },
    '&:hover': {
      backgroundColor: `${Colors.DODGER_BLUE}10`,
    },
  },
  checked: {},
})(Radio);
