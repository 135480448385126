/* eslint-disable @typescript-eslint/no-shadow */
import { Badge, Grid, MenuList, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { LeaseExtended, UnitLease } from '@rentguru/commons-utils';
import { cloneDeep, isNil } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useLocation, useRouteMatch } from 'react-router-dom';
import ConditionalParentElement from 'src/components/RentalUnits/Details/Publication/EditPublication/ConditionalParentElement';
import { ExitableLocation, RouteDestination } from 'src/components/Routes/Routes';
import CustomizedMenuItem from 'src/components/ui/CustomizedMenuItem';
import { LeaseCardHeader } from 'src/components/Leases/Details/LeaseCardHeader';
import { LEASE_TO_PREFIX, LeaseUnitsDetailNavItem } from 'src/components/Leases/Details/leaseDetailRouteUtils';
import { LeaseUnitSubMenus } from './LeaseUnitSubMenus';

interface LeaseMenusProps {
  lease?: LeaseExtended | null;
  loading: boolean;
  leaseNavItems: LeaseUnitsDetailNavItem[];
  specificUnitNavItems: {
    unitLease: UnitLease;
    navItems: LeaseUnitsDetailNavItem[];
  }[];
  isTenant?: boolean;
}

const useLocalStyles = makeStyles({
  badge: {
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      top: '50%',
      right: 25,
    },
  },
});

const LeaseMenus: React.FC<LeaseMenusProps> = ({
  loading,
  lease,
  leaseNavItems,
  specificUnitNavItems,
  isTenant = false,
}) => {
  const localStyles = useLocalStyles();
  const { formatMessage } = useIntl();
  const match = useRouteMatch<{ id: string; detailName: string; nestedDetailName?: string }>({
    path: `${RouteDestination.LEASES}/${isTenant ? '' : 'detail/'}:id?/:detailName?/:nestedDetailName?`,
  });
  const { state: exitParams } = useLocation<ExitableLocation>();

  if (loading || !match) {
    return <Skeleton style={{ marginRight: 15, marginLeft: 15 }} />;
  }

  const mutableArray = cloneDeep(lease?.units);
  (mutableArray ?? []).sort((unitLease) => (unitLease && unitLease.mainUnit ? -1 : 1));

  const unitLeases =
    lease && lease.units && lease.units.length > 0
      ? (mutableArray ?? []).sort((unitLease) => (unitLease && unitLease.mainUnit ? -1 : 1))
      : null;

  return (
    <Grid style={{ marginRight: 15, marginLeft: 15 }}>
      {lease && !loading && <LeaseCardHeader lease={lease} isTenant={isTenant} />}
      <MenuList id="leaseDetailsSection">
        {leaseNavItems.map((m) => {
          if (!m.permission) {
            return null;
          }

          const toWithoutDetail = generatePath(
            `${RouteDestination.LEASES}/${isTenant ? '' : 'detail/'}:id/${m.to !== '' ? ':detailName?' : ''}`,
            {
              id: match.params.id,
              detailName: m.to,
            }
          );

          const selected =
            (!isNil(match.params.detailName) && match.params.detailName === m.to) ||
            (m.to === LEASE_TO_PREFIX && isNil(match.params.detailName));

          return (
            <Grid key={m.to}>
              <ConditionalParentElement
                parentElement={!isNil(m.badgeCount) && m.badgeCount !== 0}
                // eslint-disable-next-line react/no-unstable-nested-components
                wrapper={(child: React.ReactNode) => (
                  <Badge
                    color="secondary"
                    badgeContent={!isNil(m.badgeCount) ? m.badgeCount : 0}
                    id={m.labelId}
                    className={selected ? '' : localStyles.badge}
                    style={{ width: '100%' }}
                  >
                    {child}
                  </Badge>
                )}
              >
                <CustomizedMenuItem
                  to={toWithoutDetail}
                  selected={selected}
                  historyProps={{ state: exitParams }}
                  data-test={m.labelId}
                  isTenant={isTenant}
                >
                  <Grid item xs={11}>
                    {formatMessage({ id: m.labelId }, { value: m.value })}
                  </Grid>
                </CustomizedMenuItem>
              </ConditionalParentElement>
            </Grid>
          );
        })}
      </MenuList>

      {/* Unit menu */}
      {!isNil(unitLeases) && (
        <LeaseUnitSubMenus
          unitLeases={unitLeases}
          loading={loading}
          isTenant={isTenant}
          specificUnitNavItems={specificUnitNavItems}
          leaseNavItems={leaseNavItems}
        />
      )}
    </Grid>
  );
};

export default LeaseMenus;
