import { Typography } from '@material-ui/core';
import { EntityType } from '@rentguru/commons-utils';
import { CustomSimpleDialog, DeleteCheckField } from '@up2rent/ui';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';
import FormAlertBox from 'src/components/ui/FormAlertBox';
import { useFiles } from 'src/hooks/FilesContext';
import { useInvoices } from 'src/hooks/InvoicesContext';
import { useLeases } from 'src/hooks/LeasesContext';
import { useTechnics } from 'src/hooks/TechnicsContext';

interface DeleteLeaseDialogProps {
  open: boolean;
  onClose: () => void;
  leaseId: string;
}

const getLinkedElementsText = (linkedEntitiesStrings: string[], andWord: string) => {
  const lastElement = linkedEntitiesStrings.pop();
  if (isNil(lastElement) || isEmpty(linkedEntitiesStrings)) {
    return lastElement ?? '';
  }
  return `${linkedEntitiesStrings.join(', ')}${andWord} ${lastElement}`;
};

const DeleteLeaseDialog: React.FC<DeleteLeaseDialogProps> = ({ onClose, leaseId, open }) => {
  const { formatMessage } = useIntl();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isDeleteLabelTyped, setIsDeleteLabelTyped] = useState<boolean>(false);
  const { technics } = useTechnics();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { getLease, deepDeleteLease, leasesLoading } = useLeases();
  const { getInvoicesOfLease, loading: invoicesLoading } = useInvoices();
  const { getFiles, loading: filesLoading } = useFiles();

  const lease = getLease(leaseId);

  if (leasesLoading || isNil(lease) || invoicesLoading || filesLoading || !open) {
    return (
      <CustomSimpleDialog
        open={open}
        onClose={onClose}
        title={formatMessage({ id: 'lease.deleteLease.menu' })}
        dividerBelowTitle
        formatMessage={formatMessage}
        actionButtonLabel={formatMessage({ id: 'delete' })}
        actionButtonDisabled
        contentGridStyle={{ marginTop: 15 }}
      >
        <Typography>{formatMessage({ id: 'loading' })}</Typography>
      </CustomSimpleDialog>
    );
  }

  const invoices = getInvoicesOfLease(leaseId);
  const files = getFiles(EntityType.LEASE, [leaseId]);
  const technicsForLease = technics.filter((technic) => technic.leaseId === leaseId);

  const linkedEntitiesStrings: string[] = [];
  if (!isEmpty(technicsForLease)) {
    linkedEntitiesStrings.push(formatMessage({ id: 'rentalUnit.deleteUnit.technics' }).toLowerCase());
  }
  if (!isEmpty(invoices)) {
    linkedEntitiesStrings.push(formatMessage({ id: 'accounting.invoice.invoices' }).toLowerCase());
  }
  if (!isEmpty(files)) {
    linkedEntitiesStrings.push(formatMessage({ id: 'folders.files' }).toLowerCase());
  }

  const handleDeleteLease = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setIsDeleting(true);
    enqueueSnackbar(formatMessage({ id: 'contact.deleteContact.notificationWillBeSent' }), {
      persist: false,
      autoHideDuration: 10000,
      variant: 'info',
      preventDuplicate: true,
    });
    const mutationStatus = await deepDeleteLease(lease.id);
    if (mutationStatus && !mutationStatus.status) {
      enqueueSnackbar(formatMessage({ id: 'lease.deleteLease..deletionErrorWithName' }, { name: lease.name }), {
        persist: false,
        autoHideDuration: 5000,
        variant: 'error',
        preventDuplicate: true,
      });
    }
    setIsDeleting(false);
    onClose();
    history.push({ pathname: RouteDestination.LEASES, state: { goBackUrl: '/' } });
  };

  return (
    <CustomSimpleDialog
      title={formatMessage({ id: 'lease.deleteLease.menu' })}
      open={open}
      onClose={onClose}
      dividerBelowTitle
      onActionButtonClick={handleDeleteLease}
      actionButtonLabel={formatMessage({ id: 'delete' })}
      actionButtonLoading={isDeleting}
      actionButtonDisabled={!isDeleteLabelTyped}
      secondaryButtonLabel={formatMessage({ id: 'cancel' })}
      formatMessage={formatMessage}
      contentGridStyle={{ marginTop: 15 }}
    >
      <Typography style={{ fontSize: 14 }}>
        {formatMessage({ id: 'lease.deleteLease.title' }, { name: lease.name })}
      </Typography>
      {!isEmpty(linkedEntitiesStrings) && (
        <>
          <FormAlertBox
            message1={formatMessage(
              { id: 'lease.deleteLease.warning' },
              {
                links: getLinkedElementsText(
                  linkedEntitiesStrings,
                  formatMessage({
                    id: 'rentalUnit.deleteUnit.and',
                  })
                ),
              }
            )}
            removeLeftMargin
            width={'unset'}
            gridStyle={{ marginTop: 15 }}
          />
          <DeleteCheckField
            deleteLabel={formatMessage({ id: 'delete' }).toLowerCase()}
            setIsDeleteLabelTyped={setIsDeleteLabelTyped}
            formatMessage={formatMessage}
          />
        </>
      )}
    </CustomSimpleDialog>
  );
};

export default DeleteLeaseDialog;
