import { ButtonBase, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Colors, isArrayFullOfEmptyElements, isNilOrEmpty } from '@rentguru/commons-utils';
import React, { CSSProperties } from 'react';
import { useIntl } from 'react-intl';
import HelpIconPopover from '../HelpIconPopover';

const useStyles = makeStyles({
  button: {
    width: 175,
    minWidth: 175,
    maxWidth: 175,
    height: 100,
    minHeight: 100,
    maxHeight: 100,
    backgroundColor: Colors.CLASSICAL_WHITE,
    borderRadius: 10,
    fontWeight: 'bold',
    textTransform: 'none',
    outline: `1px solid ${Colors.SILVER}`,
    outlineOffset: '-1px',
    alignItems: 'flex-start',
  },
  buttonIcon: {
    height: '50%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  buttonText: {
    height: '50%',
  },
  buttonContainer: {
    height: '100%',
  },
});

interface QuickActionButtonProps {
  onClick?: (event?: React.MouseEvent) => void;
  selected?: boolean;
  disabled?: boolean;
  /**
   *
   * The first element of the array contains the label of every paragraph of the actual body.
   * The second element od the array contains the label of every list item on the bottom list
   *
   * @example
   * ```tsx
   * // Only paragraphs
   * <QuickActionButtonProps
   *  helperTextLabelIds={[...labels, undefined]}
   * />
   *
   * // Paragraphs and list
   * <QuickActionButtonProps
   *  helperTextLabelIds={[...labels, ...listLabels]}
   * />
   * ```
   */
  helperTextLabelIds?: [string[], string[] | undefined];
  textLabelId: string;
  // eslint-disable-next-line no-undef
  icon: JSX.Element;
  buttonStyle?: CSSProperties;
  buttonContentContainerStyle?: CSSProperties;
  iconContainerStyle?: CSSProperties;
  textStyle?: CSSProperties;
  dataTest?: string;
}

const QuickActionButton: React.FC<QuickActionButtonProps> = ({
  onClick,
  selected = false,
  disabled = false,
  helperTextLabelIds,
  textLabelId,
  icon,
  buttonStyle = {},
  textStyle = {},
  buttonContentContainerStyle = {},
  iconContainerStyle = {},
  dataTest,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  return (
    <ButtonBase
      className={classes.button}
      style={{
        marginRight: 15,
        ...(selected ? { outline: `2px solid ${Colors.CARNATION_RED}`, outlineOffset: '-2px' } : {}),
        ...buttonStyle,
      }}
      disabled={disabled}
      onClick={onClick}
      data-test={dataTest}
      {...rest}
    >
      {!isArrayFullOfEmptyElements((helperTextLabelIds ?? [])[0] ?? []) && (
        <HelpIconPopover
          iconStyle={{ position: 'absolute', right: 10, top: 10 }}
          HelperBody={
            <Grid style={{ maxWidth: 250 }}>
              <Typography variant="h6" style={{ margin: 10, textAlign: 'center' }}>
                {formatMessage({ id: textLabelId })}
              </Typography>
              {((helperTextLabelIds ?? [])[0] ?? [])?.map((helperText) => (
                <Typography style={{ margin: 10, textAlign: 'justify', marginBottom: 10 }} key={helperText}>
                  {formatMessage({ id: helperText })}
                </Typography>
              ))}
              {!isNilOrEmpty((helperTextLabelIds ?? [])[1]) && (
                <ul>
                  {(helperTextLabelIds ?? [])[1]?.map((listLabel) => (
                    <li style={{}} key={listLabel}>
                      <Typography style={{ position: 'relative', bottom: 3 }}>
                        {formatMessage({ id: listLabel })}
                      </Typography>
                    </li>
                  ))}
                </ul>
              )}
            </Grid>
          }
        />
      )}
      <Grid className={classes.buttonContainer} style={buttonContentContainerStyle}>
        <Grid className={classes.buttonIcon} style={iconContainerStyle}>
          {icon}
        </Grid>
        <Typography className={classes.buttonText} style={{ fontSize: 17, ...textStyle }}>
          {formatMessage({ id: textLabelId })}
        </Typography>
      </Grid>
    </ButtonBase>
  );
};

export default QuickActionButton;
