import { LeaseExtended, RemittanceType } from '@rentguru/commons-utils';
import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { AddCustomInvoiceValues, ReconciliationMethod } from './addCustomInvoiceDialogUtils';
import AddCustomInvoiceForm from './AddCustomInvoiceForm';

export interface AddCustomInvoiceDialogProps {
  open: boolean;
  onClose: () => void;
  lease: LeaseExtended;
}

const AddCustomInvoiceSchema = Yup.object().shape({
  history: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required(),
      accountLabel: Yup.object().required(),
      amount: Yup.number().positive().required(),
      dueDate: Yup.date().required(),
      label: Yup.string().required(),
      transactionType: Yup.string().required(),
      unitId: Yup.string().required(),
    })
  ),
  remittanceInformation: Yup.string(),
  remittanceInformationType: Yup.string(),
});

const AddCustomInvoiceDialog: React.FC<AddCustomInvoiceDialogProps> = ({ open, onClose, lease }) => {
  const unitId = lease.units?.find((unitLease) => unitLease.mainUnit)?.unitId ?? null;
  const initialValues: AddCustomInvoiceValues = {
    history: [
      {
        id: uuidv4(),
        label: null,
        amount: null,
        accountLabel: null,
        dueDate: new Date(),
        transactionType: null,
        unitId,
      },
    ],
    reconciliationMethod: ReconciliationMethod.NONE,
    remittanceInformation: '',
    remittanceInformationType: RemittanceType.unstructured,
    transactionToReconcile: null,
    creditNoteToReconcile: null,
    newTransactionOperationDate: new Date(),
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      validateOnBlur={false}
      validationSchema={AddCustomInvoiceSchema}
      validateOnChange={false}
    >
      <AddCustomInvoiceForm lease={lease} onClose={onClose} open={open} />
    </Formik>
  );
};

export default AddCustomInvoiceDialog;
