import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles as useKeyNumbersStyles } from '../DashboardMetrics/DashboardKeyFigures';

interface RankingKeyNumberProps {
  title: string;
  value: string;
}
const RankingKeyNumber: React.FC<RankingKeyNumberProps> = ({ title, value }) => {
  const classes = useKeyNumbersStyles();
  return (
    <Grid container className={classes.flexGrid} style={{ marginTop: 0 }}>
      <Grid item xs={6} className={classes.figureLegend}>
        <Typography>{title}</Typography>
        <Typography className={classes.figureValue}>{value}</Typography>
      </Grid>
    </Grid>
  );
};

export default RankingKeyNumber;
