/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
import { Grid, Tooltip, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ActionButtonSecond } from '@up2rent/ui';
import AddLeaseVariousOperationDialog, {
  getAddLeaseOperationDynamicSchema,
  isLeaseOperationVatValid,
} from 'src/components/ui/Dialogs/AddLeaseVariousOperationDialog';
import RentOperationsList from '../FormSection/RentOperationsList';
import { ReactComponent as AddIcon } from '../../../../icons/add.svg';
import { ReactComponent as InfoSvg } from '../../../../icons/info.svg';
import {
  Colors,
  LeaseVariousOperationType,
  LeasePaymentFrequency,
  LeasePaymentInvoicePeriod,
  VariousOperationType,
} from '@rentguru/commons-utils';
import { isEmpty, isNil } from 'lodash';
import { AdditionalUnit } from '../FormSection/AddAdditionalUnits';
import * as Yup from 'yup';
import { getPotentialErrorIndexInDiscounts } from '../../../Leases/AddLease/AddLeaseUtils';
import { RentDivider } from './RentFields';

export interface OperationFormValue {
  id?: string;
  operationType: LeaseVariousOperationType | string | null;
  vatRate?: string | number;
  label: string | null;
  amount: string | number | null;
  startDate: Date | string | null;
  changeDate?: Date | string | null;
  minStartDate?: Date | string | undefined;
  endDate: Date | string | null;
  maxEndDate?: Date | string | undefined;
  type: VariousOperationType | 'DURATION' | string | null;
  paymentFrequency: LeasePaymentFrequency | string | null;
  paymentInvoicePeriod: LeasePaymentInvoicePeriod | string | null;
  paymentInvoicePeriodCustomDay: number | null;
  separateInvoice: boolean;
  reason: string | null;
  unitId: string;
}
export interface AddOperationFormValue {
  leaseOperations: OperationFormValue[];
}

export const getArrayOfOperationsSchema = () => {
  return Yup.array()
    .of(getAddLeaseOperationDynamicSchema())
    .test(
      'do all operations have a VAT amount if asked?',
      "All operations do not have VAT amount even though it's been asked.",
      function (value) {
        if (isNil(value)) {
          return true;
        }

        /**
         * My Form looks in a very simplified way like
         * {
         * ...
         * leaseOperations: OperationFormValue[],
         * ...
         * subUnits: [
         *  {
         *    leaseOperations: OperationFormValue[]
         *  }
         * ]
         * ...
         * }
         * the idea of this.form is to get the arborescence of the parent elements.
         * this.form[0], if we are testing the subUnits's leaseOperations, will be the array of subUnits.
         * this.form[1], will be the rootValue.
         *
         * If we are testing the mainUnits's leaseOperations, this.from[0] will be the rootValue.
         * this.form[1] will be undefined!
         *
         *
         * this.from is not correctly typed yet therefore, it does not "exist" in TS.
         * See https://github.com/jquense/yup/issues/398#issuecomment-916693907
         * OR https://github.com/jquense/yup/issues/735#issuecomment-873828710
         */

        const rootValue = (this as any).from[1]?.value;

        const isMainUnit = isNil(rootValue);
        let subUnitIndex = -1;

        if (!isMainUnit) {
          const subUnits = rootValue.subUnits;
          subUnitIndex = subUnits.findIndex((subUnit: AdditionalUnit) => subUnit.id === this.parent.id);
        }

        const prefix = isMainUnit ? '' : `subUnits[${subUnitIndex}].`;

        const innerErrors = value?.reduce((acc, currentOperation, index) => {
          if (
            !isLeaseOperationVatValid(isMainUnit ? this.parent.applyVat : rootValue.applyVat, currentOperation.vatRate)
          ) {
            acc.push(
              this.createError({
                message: 'Vat amount should be set.',
                path: `${prefix}leaseOperations[${index}].operationType`,
              })
            );
          }

          return acc;
        }, [] as Yup.ValidationError[]);

        if (!isEmpty(innerErrors)) {
          const error = new Yup.ValidationError(
            'Some operations do not have VAT amount set.',
            value,
            `${prefix}leaseOperations`
          );
          error.inner = innerErrors;
          return error;
        }
        return true;
      }
    );
};

export const getArrayOfDiscountsSchema = () => {
  return Yup.array()
    .of(getAddLeaseOperationDynamicSchema())
    .test(
      'do all is the price of all discounts inferiors to rentalPrice?',
      'Price of all discounts should be less than rental price',
      function (value) {
        if (isNil(value)) {
          return true;
        }

        // Please check in ArrayOfOperationsSchema for more information about this.from
        const rootValue = (this as any).from[1]?.value;
        const isMainUnit = isNil(rootValue);
        let subUnitIndex = -1;
        if (!isMainUnit) {
          const subUnits = rootValue.subUnits;
          subUnitIndex = subUnits.findIndex((subUnit: AdditionalUnit) => subUnit.id === this.parent.id);
        }
        const prefix = isMainUnit ? '' : `subUnits[${subUnitIndex}].`;
        let hasError = false;
        const potentialErrorData = getPotentialErrorIndexInDiscounts(
          isMainUnit ? (this as any).from[0]?.value?.rentalPrice : rootValue.subUnits[subUnitIndex].rentalPrice,
          value as unknown as OperationFormValue[]
        );

        if (potentialErrorData.potentialErrorIndex !== -1) {
          hasError = true;
        }

        const innerErrors: Yup.ValidationError[] = [];
        if (hasError) {
          potentialErrorData.amountDistributedInMonths[potentialErrorData.potentialErrorIndex].indexes.forEach(
            (currentIndex) => {
              innerErrors.push(
                this.createError({
                  // eslint-disable-next-line max-len
                  message: `${value[currentIndex].label} operation is part of the to high total discount amount for one month`,
                  path: `${prefix}discounts[${currentIndex}].amount`,
                })
              );
            }
          );
        }

        if (!isEmpty(innerErrors)) {
          const error = new Yup.ValidationError(
            'Value of all discounts should be less than rental price',
            value,
            `${prefix}discounts`
          );
          error.inner = innerErrors;
          return error;
        }
        return true;
      }
    );
};
interface AddOperationProps {
  unitId: string | undefined;
}

const AddOperation: React.FC<AddOperationProps> = ({ unitId }) => {
  const [leaseVariousOperationOpen, setLeaseVariousOperationOpen] = useState<LeaseVariousOperationType | null>(null);
  const { formatMessage } = useIntl();

  if (isNil(unitId)) {
    return null;
  }

  return (
    <>
      <Grid
        style={{
          margin: 30,
          marginBottom: 0,
        }}
      >
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 15,
          }}
        >
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="subtitle2" style={{ fontWeight: 'bold', marginTop: 0 }}>
              {formatMessage({ id: 'lease.detail.variousOperations.charges' })}
            </Typography>
            <Tooltip title={formatMessage({ id: 'lease.detail.action.addChargeHelp' })} placement="bottom">
              <Grid>
                <InfoSvg style={{ marginLeft: 10, fill: Colors.SILVER }} />
              </Grid>
            </Tooltip>
          </Grid>
          <ActionButtonSecond
            onClick={() => {
              setLeaseVariousOperationOpen(LeaseVariousOperationType.CHARGE);
            }}
            style={{ textTransform: 'none' }}
            id="addChargesButton"
          >
            <AddIcon fill={Colors.BLUE_GREY} style={{ marginRight: 10 }} />{' '}
            {formatMessage({ id: 'lease.detail.action.addCharge' })}
          </ActionButtonSecond>
        </Grid>
        <RentOperationsList unitId={unitId} operationType={LeaseVariousOperationType.CHARGE} />
      </Grid>
      <RentDivider />
      <Grid
        style={{
          margin: 30,
          marginBottom: 0,
        }}
      >
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 15,
          }}
        >
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="subtitle2" style={{ fontWeight: 'bold', marginTop: 0 }}>
              {formatMessage({ id: 'lease.detail.variousOperations.services' })}
            </Typography>
            <Tooltip title={formatMessage({ id: 'lease.detail.action.addServiceHelp' })} placement="bottom">
              <Grid>
                <InfoSvg style={{ marginLeft: 10, fill: Colors.SILVER }} />
              </Grid>
            </Tooltip>
          </Grid>
          <ActionButtonSecond
            onClick={() => {
              setLeaseVariousOperationOpen(LeaseVariousOperationType.SERVICE);
            }}
            style={{ textTransform: 'none' }}
            id="addServicesButton"
          >
            <AddIcon fill={Colors.BLUE_GREY} style={{ marginRight: 10 }} />{' '}
            {formatMessage({ id: 'lease.detail.action.addService' })}
          </ActionButtonSecond>
        </Grid>
        <RentOperationsList unitId={unitId} operationType={LeaseVariousOperationType.SERVICE} />
      </Grid>
      <AddLeaseVariousOperationDialog
        operationType={leaseVariousOperationOpen ?? LeaseVariousOperationType.CHARGE}
        onClose={() => setLeaseVariousOperationOpen(null)}
        open={!isNil(leaseVariousOperationOpen)}
        unitId={unitId}
      />
    </>
  );
};

export default AddOperation;
