/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import { Layout } from 'react-grid-layout';
import isNil from 'lodash/isNil';
import { Typography, Collapse, CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Colors, DashboardBoxType } from '@rentguru/commons-utils';
import { useDashboardFilters } from 'src/hooks/DashboardFiltersContext';
import RankingLoader from 'src/components/Dashboard/DashboardLoader';
import { CustomIconButton , ElevatedPaper } from '@up2rent/ui';
import MetricBoxTimeFilters, { MetricBoxTimeFiltersProps } from './DashboardFilters/MetricBoxTimeFilter';
import { stopPropagation } from 'src/components/Dashboard/Dashboard';

const useStyles = makeStyles(() =>
  createStyles({
    flexDiv: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      paddingRight: 20,
    },
    title: {
      flex: 1,
      textAlign: 'left',
      color: Colors.CLASSICAL_BLACK,
      fontFamily: 'Mulish',
      fontSize: '16px',
      fontWeight: 'bold',
      marginLeft: 20,
    },
    expansionText: {
      color: Colors.BLUE_GREY,
      cursor: 'pointer',
      textTransform: 'none',
    },
  })
);

export interface MetricBoxLayoutProps {
  forceUpdateLayout?: (newLayout: Layout[]) => void;
}

interface MetricBoxProps extends MetricBoxLayoutProps {
  boxKey: string;
  title: string | React.ReactNode;
  timeFilterBundle?: MetricBoxTimeFiltersProps;
  loading?: boolean;
  onCollapseOpen?: () => void;
  onCollapseClose?: () => void;
  children?: React.ReactNode;
}

const MetricBox: React.FC<MetricBoxProps> = ({
  boxKey,
  title,
  timeFilterBundle,
  children,
  loading = false,
  onCollapseOpen,
  onCollapseClose,
  forceUpdateLayout,
}) => {
  const { getBoxCollapse, saveNewBoxCollapse } = useDashboardFilters();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const classes = useStyles();
  const expandIconStyle = (collapsed: boolean): object => ({
    transform: `rotate(${collapsed ? 0 : 180}deg)`,
    fill: Colors.DARK_SLATE_GREY,
  });

  const onExpandClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    if (collapsed && onCollapseOpen) {
      onCollapseOpen();
    }
    if (!collapsed && onCollapseClose) {
      onCollapseClose();
    }
    await saveNewBoxCollapse(boxKey, !collapsed, DashboardBoxType.METRIC, forceUpdateLayout);
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    const setBoxCollapsed = async () => {
      const collapsed = await getBoxCollapse(boxKey, DashboardBoxType.METRIC);
      setCollapsed(collapsed);
    };
    setBoxCollapsed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxKey]);

  if (loading) {
    return <RankingLoader width="100%" />;
  }
  return (
    <ElevatedPaper style={{ overflow: 'visible', paddingBottom: 32 }}>
      <div className={classes.flexDiv}>
        {typeof title === 'string' && <Typography className={classes.title}> {title} </Typography>}
        {typeof title !== 'string' && <>{title} </>}
        <div>
          <CustomIconButton
            onMouseDown={stopPropagation}
            style={{ fill: Colors.BLUEY }}
            size="small"
            onClick={onExpandClick}
            Icon={ExpandMore}
            iconStyle={expandIconStyle(collapsed)}
          />
        </div>
      </div>
      <Collapse in={!collapsed} mountOnEnter unmountOnExit>
        <div style={{ height: 360, display: 'grid' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingRight: 20,
            }}
            onMouseDown={stopPropagation}
          >
            {!isNil(timeFilterBundle) && <MetricBoxTimeFilters {...timeFilterBundle} />}
          </div>
          {loading && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress style={{ color: Colors.DODGER_BLUE }} size={20} />
            </div>
          )}
          {!loading && children}
        </div>
      </Collapse>
    </ElevatedPaper>
  );
};
export default MetricBox;
