import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import toUpper from 'lodash/toUpper';
import { ActionButton , LoaderButton } from '@up2rent/ui';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTechnics } from 'src/hooks/TechnicsContext';
import * as Yup from 'yup';
import FormikDatePicker from 'src/components/ui/FormikDatePicker';
import {
  HeatingType,
  LeaseStatus,
  Technic,
  TechnicType,
  Colors,
  LooseObject,
  CommunicationSettingsProfile,
} from '@rentguru/commons-utils';
import { createStyles, Divider, Grid, makeStyles, MenuItem, Typography } from '@material-ui/core';
import TextDetailEditable from 'src/components/ui/TextDetailEditable';
import CustomizedSelect from 'src/components/ui/CustomizedSelect';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { v4 as uuidv4 } from 'uuid';
import isNil from 'lodash/isNil';
import AddTechnicDialogComplementaryData, {
  AddTechnicDialogComplementaryDataFormValues,
} from 'src/components/Technics/AddTechnicDialogComplementaryData';
import { useLeases } from 'src/hooks/LeasesContext';
import { ChimneyInitialValuesType } from 'src/components/Technics/Chimneys/AddChimneyForm';
import { TankValues } from 'src/components/Technics/Tanks/AddTankForm';
import { UtilityProvidersFormValues } from 'src/components/ui/Forms/FormField/UtilityProvidersFields';
import { DetectorInitialValuesType } from 'src/components/Technics/Detectors/AddDetectorForm';
import { EPBInitialValuesType } from 'src/components/Technics/EPBs/AddEPBForm';
import useTechnicsUtils from 'src/components/Technics/useTechnicsUtils';
import { useHistory } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';
import { useCommunicationSettingsProfiles } from 'src/hooks/CommunicationSettingsProfilesContext';
import { isEmpty } from 'lodash';

export interface AddHeatingProps {
  id: string;
  entity: 'building' | 'unit' | 'leaseBuilding' | 'leaseUnit';
  afterSubmit: (technics?: Technic[]) => void;
  cancelSubmit: () => void;
  showTitle?: boolean;
  showComplementaryData?: boolean;
  shouldRedirectAfterCreate?: boolean;
}

const HeatingSchema = Yup.object().shape({
  heatingType: Yup.string().required(),
  lastControlDate: Yup.date().required(),
  brand: Yup.string().nullable(),
  model: Yup.string().nullable(),
  installationDate: Yup.date().nullable(),
});

interface AddHeatingValues extends AddTechnicDialogComplementaryDataFormValues {
  heatingType: HeatingType;
  lastControlDate: Date;
  brand: string;
  model: string;
  installationDate: Date | null;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& > *': {
        width: 280,
      },
    },
  })
);

export const getLinks = (
  values:
    | AddHeatingValues
    | ChimneyInitialValuesType
    | TankValues
    | UtilityProvidersFormValues
    | DetectorInitialValuesType
    | EPBInitialValuesType,
  id: string,
  entity: 'building' | 'unit' | 'leaseBuilding' | 'leaseUnit',
  buildingId: string
) => {
  return {
    // Building
    ...((entity === 'building' || entity === 'leaseBuilding') && values.onBuilding && { buildingId: id }),
    ...(entity === 'building' && !values.onBuilding && { unitId: values.unitId }),

    // Unit & lease
    ...((entity === 'unit' || entity === 'leaseUnit') && values.onBuilding && { buildingId }),
    ...((entity === 'unit' || entity === 'leaseUnit') && !values.onBuilding && { unitId: id }),
  };
};

const AddHeatingForm: React.FC<AddHeatingProps> = ({
  id,
  entity,
  afterSubmit,
  cancelSubmit,
  showTitle = true,
  showComplementaryData = false,
  shouldRedirectAfterCreate = false,
}) => {
  const { formatMessage } = useIntl();
  const { createTechnic } = useTechnics();
  const { getBuilding } = useBuildings();
  const { getUnit } = useUnits();
  const { getLease } = useLeases();
  const { getPropertyId } = useTechnicsUtils();
  const { getDefaultTechnicCommunicationSettingsProfiles, loading: communicationSettingsProfilesLoading } =
    useCommunicationSettingsProfiles();
  const history = useHistory();
  const classes = useStyles();
  const [technicCommunicationSettingsProfiles, setTechnicCommunicationSettingsProfiles] = useState<
    CommunicationSettingsProfile[]
  >([]);

  useEffect(() => {
    if (communicationSettingsProfilesLoading) {
      return;
    }
    const technicProfiles = getDefaultTechnicCommunicationSettingsProfiles();
    setTechnicCommunicationSettingsProfiles(technicProfiles);
  }, [getDefaultTechnicCommunicationSettingsProfiles, communicationSettingsProfilesLoading]);

  if (communicationSettingsProfilesLoading || isEmpty(technicCommunicationSettingsProfiles)) {
    return (
      <Grid container style={{ justifyContent: 'center' }}>
        <Typography style={{ margin: 20 }}>{formatMessage({ id: 'loading' })}</Typography>
      </Grid>
    );
  }

  // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
  const defaultTechnicProfile =
    !isEmpty(technicCommunicationSettingsProfiles) && technicCommunicationSettingsProfiles[0];
  const handleEdit = async (
    values: AddHeatingValues,
    { setSubmitting, setStatus }: FormikHelpers<AddHeatingValues>
  ): Promise<void> => {
    const unit = getUnit(values.unitId ?? '');
    const building = getBuilding(unit?.building?.id ?? '');
    const buildingId = building?.id;
    const leasesOfUnit = (unit?.leases ?? []).map((lease) => {
      return getLease(lease?.lease?.id ?? '');
    });
    let createdTechnics: Technic[] = [];
    const activeLease = leasesOfUnit.find((lease) => lease?.status === LeaseStatus.Active);
    const technics: Omit<Technic, 'id' | 'clientId' | 'readId'>[] = [
      {
        type: TechnicType.HEATING,
        heatingType: values.heatingType,
        heatingBrand: values.brand,
        heatingModel: values.model,
        heatingInstallationDate: !isNil(values.installationDate) ? values.installationDate.toISOString() : undefined,
        heatingLastControlDate: values.lastControlDate.toISOString(),
        // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
        communicationSettingsProfileId: defaultTechnicProfile ? defaultTechnicProfile.id : 'mockId',
        ...getLinks(values, id, entity, buildingId ?? ''),
      },
      ...(!values.onBuilding && !isNil(activeLease) && !entity.startsWith('lease')
        ? [
            {
              type: TechnicType.HEATING,
              heatingType: values.heatingType,
              heatingBrand: values.brand,
              heatingModel: values.model,
              heatingInstallationDate: !isNil(values.installationDate)
                ? values.installationDate.toISOString()
                : undefined,
              heatingLastControlDate: values.lastControlDate.toISOString(),
              leaseId: activeLease.id,
              unitId: unit?.id ?? '',
              // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
              communicationSettingsProfileId: defaultTechnicProfile ? defaultTechnicProfile.id : 'mockId',
            },
          ]
        : []),
    ];
    if (entity === 'unit' || entity === 'building') {
      createdTechnics = await Promise.all(technics.map((technic) => createTechnic(technic)));
    } else {
      technics.forEach((technic) => {
        (technic as LooseObject).id = `NEW-${uuidv4()}`;
      });
    }
    setStatus(true);
    setSubmitting(false);

    afterSubmit(technics as Technic[]);
    if (shouldRedirectAfterCreate) {
      const currentUnit = getUnit(values.onBuilding ? '' : values.unitId ?? '');
      const unitId = `unit-${currentUnit?.id}`;
      history.push({
        pathname: `${RouteDestination.PROPERTIES}/${getPropertyId()}/${!isNil(currentUnit) ? unitId : 'technics'}/${
          !isNil(currentUnit) ? 'technics' : ''
        }`,
        state: { id: createdTechnics?.[0].id ?? '', defaultTab: TechnicType.HEATING },
      });
    }
  };

  return (
    <Formik
      initialValues={
        {
          installationDate: new Date(),
          lastControlDate: new Date(),
          brand: '',
          model: '',
          heatingType: HeatingType.GAS,
          onBuilding: ['building', 'leaseBuilding'].includes(entity),
          unitId: ['unit', 'leaseUnit'].includes(entity) ? id : '',
        } as AddHeatingValues
      }
      validationSchema={HeatingSchema}
      onSubmit={handleEdit}
    >
      {({ values, errors, touched, isSubmitting, status }) => {
        return (
          <Form>
            {showTitle && (
              <div style={{ marginLeft: 20, marginRight: 20, marginTop: 25, marginBottom: 25 }}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: 20,
                    textAlign: 'left',
                  }}
                >
                  {formatMessage({ id: 'technic.heatingTab.add' })}
                </Typography>
              </div>
            )}
            <Divider style={{ marginBottom: 10 }} />
            <div
              style={{
                alignItems: 'baseline',
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              {showComplementaryData && (
                <AddTechnicDialogComplementaryData messageId="lease.detail.action.addDetectorExample" />
              )}
              <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                <CustomizedSelect
                  label={formatMessage({
                    id: 'technic.heatingTab.heatingType',
                  })}
                  value={values.heatingType}
                  FormControlProps={{ required: true }}
                  fieldName="heatingType"
                  className={classes.root}
                >
                  {Object.values(HeatingType).map((heatingType) => {
                    return (
                      <MenuItem id={heatingType} key={heatingType} value={heatingType}>
                        {formatMessage({ id: `rentalUnit.detail.energy.heating.${heatingType}` })}
                      </MenuItem>
                    );
                  })}
                </CustomizedSelect>
                <FormikDatePicker
                  name="installationDate"
                  label={formatMessage({ id: 'technic.heatingTab.heatingInstallationDate' })}
                  value={values.installationDate}
                  style={{ width: 280 }}
                  error={Boolean(errors.installationDate && touched.installationDate)}
                  required={false}
                />
              </div>
              <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                <TextDetailEditable
                  name="brand"
                  title={formatMessage({ id: 'technic.heatingTab.heatingBrand' })}
                  type="text"
                  editMode={true}
                />
                <TextDetailEditable
                  name="model"
                  title={formatMessage({ id: 'technic.heatingTab.heatingModel' })}
                  type="text"
                  editMode={true}
                />
              </div>
              <FormikDatePicker
                name="lastControlDate"
                label={formatMessage({ id: 'technic.heatingTab.heatingLastControlDate' })}
                value={values.lastControlDate}
                style={{ width: 600 }}
                error={Boolean(errors.lastControlDate && touched.lastControlDate)}
              />
            </div>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <div
              style={{
                marginBottom: 20,
                marginRight: 30,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <ActionButton
                onClick={cancelSubmit}
                style={{ background: 'none', color: Colors.DARK_SLATE_GREY, marginRight: 20 }}
              >
                {toUpper(formatMessage({ id: 'cancel' }))}
              </ActionButton>
              <LoaderButton loading={isSubmitting} success={status}>
                {formatMessage({
                  id: 'save',
                })}
              </LoaderButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddHeatingForm;
