import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import TextDetail from './TextDetail';
import { useIntl } from 'react-intl';
import ContactQuickCallMail from '../Contacts/ContactQuickCallMail';
import { isNil } from 'lodash';
import { formatStandardizedUTCDate, Contact } from '@rentguru/commons-utils';
import { dateLocaleMap, useLocale } from 'src/i18n/IntlProviderWrapper';
import { useLeases } from 'src/hooks/LeasesContext';

interface HorizontalPoppedComponentProps {
  id?: string;
  startDate: string;
  endDate?: string;
  type: string;
  note?: string | null;
  tenants?: Contact[];
}

const HorizontalPoppedComponent: React.FC<HorizontalPoppedComponentProps> = ({
  startDate,
  endDate,
  type,
  note,
  id,
  tenants,
}) => {
  const { formatMessage } = useIntl();
  const { getLeaseFromUnitLease } = useLeases();
  const { language } = useLocale();
  let tenantsComponents = null;
  if (type.startsWith('LEASE')) {
    const lease = getLeaseFromUnitLease(id ?? '');
    const tenantsOfLease: Contact[] | undefined = tenants ?? lease?.tenants;
    tenantsComponents =
      tenantsOfLease?.map((t) => (
        <div key={t.id} style={{ marginRight: '20px' }}>
          <ContactQuickCallMail key={t.id} {...t} />{' '}
        </div>
      )) ?? null;
  }
  let title = '';
  if (type.startsWith('LEASE')) {
    title = formatMessage({ id: 'event.leasePeriod' });
  } else if (type.startsWith('WORK')) {
    title = formatMessage({ id: 'event.workPeriod' });
  } else if (type.startsWith('OWN_USE')) {
    title = formatMessage({ id: 'event.ownUsePeriod' });
  }

  return (
    <>
      <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{title}</Typography>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <TextDetail
            title={formatMessage({ id: 'lease.detail.general.startDate' })}
            text={formatStandardizedUTCDate(new Date(startDate), 'dd/MM/yyyy', {
              locale: dateLocaleMap[language],
            })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextDetail
            title={formatMessage({ id: 'lease.detail.general.endDate' })}
            text={
              endDate
                ? formatStandardizedUTCDate(new Date(endDate), 'dd/MM/yyyy', {
                    locale: dateLocaleMap[language],
                  })
                : ''
            }
          />
        </Grid>
      </Grid>
      {type.startsWith('LEASE') && (
        <>
          <Typography style={{ marginTop: 30, fontWeight: 'bold', fontSize: 14 }}>
            {formatMessage({ id: 'lease.detail.general.tenants' })}
          </Typography>
          {tenantsComponents}
        </>
      )}
      {!isNil(note) && note !== '' && (
        <>
          <Typography style={{ marginTop: 30, fontWeight: 'bold', fontSize: 14 }}>
            {formatMessage({ id: 'mortgage.note' })}
          </Typography>
          <Typography style={{ marginTop: 5, fontSize: 14 }}>{note}</Typography>
        </>
      )}
    </>
  );
};

export default HorizontalPoppedComponent;
