/* eslint-disable @typescript-eslint/no-shadow */
import { Button, Collapse, Divider, ListItemAvatar, Typography, makeStyles } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import { isEmpty, isNil, toUpper } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  Colors,
  EmptyFile,
  EmptyFileWithUrl,
  File as FileModel,
  LeaseInventoryEncoding,
  S3Object,
  UnitInventoryStructure,
  getEmptyFile,
} from '@rentguru/commons-utils';
import { FilePreviewDialogBundle } from 'src/components/LeaseInventory/LeaseInventoryEncoding/InventoryEncodings';
import { ExtendedLeaseInventoryEncoding } from 'src/hooks/UnitInventoryContext';
import { ReactComponent as Delete } from 'src/icons/delete.svg';
import { fetchEncodingMediaFiles } from 'src/utils/leaseInventoryUtils';

import { ActionButton, CustomIconButton } from '@up2rent/ui';
import CustomAudioPlayer from 'src/components/ui/CustomAudioPlayer';
import FileItemRendering from 'src/components/ui/FileItemRendering';
import TextFieldArea from 'src/components/ui/TextFieldArea';

const useStyles = makeStyles({
  addButton: {
    background: Colors.PORCELAIN_GREY_1,
    color: Colors.DARK_SLATE_GREY,
    '&:hover': {
      background: Colors.PORCELAIN_GREY_2,
    },
  },
  removeButton: {
    background: Colors.PORCELAIN_GREY_1,
    color: Colors.CARNATION_RED,
    fontWeight: 600,
    '&:hover': {
      background: Colors.PORCELAIN_GREY_2,
    },
  },
});

interface FurnitureStructureEncodingExtraInfosProps {
  structure: UnitInventoryStructure;
  structureFieldName: string;
  encoding?: ExtendedLeaseInventoryEncoding;
  previewFileFunction: (fileBundle: FilePreviewDialogBundle) => void;
  updateEncoding: (
    updateFunction: (encoding: ExtendedLeaseInventoryEncoding) => ExtendedLeaseInventoryEncoding
  ) => void;
  removeStructure: (b: boolean) => void;
  setFileToDelete: (file: S3Object | EmptyFile) => void;
  readOnly: boolean;
}

const FurnitureStructureEncodingExtraInfos: React.FC<FurnitureStructureEncodingExtraInfosProps> = ({
  structure,
  structureFieldName,
  encoding,
  previewFileFunction,
  updateEncoding,
  removeStructure,
  setFileToDelete,
  readOnly,
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [audioFiles, setAudioFiles] = useState<(S3Object | EmptyFileWithUrl)[]>([]);
  const [pictureFiles, setPictureFiles] = useState<(S3Object | EmptyFileWithUrl)[]>([]);
  const [showNote, setShowNote] = useState<boolean>(!isNil(encoding) && !isNil(encoding.note));

  useEffect(() => {
    let unmounted = false;
    if (!isNil(encoding) && !isNil(encoding.medias)) {
      fetchEncodingMediaFiles(encoding.medias, unmounted, setAudioFiles, setPictureFiles);
    }
    return () => {
      unmounted = true;
    };
  }, [encoding]);

  const addSpeechToTextToNote = (speechToText: string) => {
    updateEncoding((encoding: ExtendedLeaseInventoryEncoding) => {
      const oldValue = !isNil(encoding) && !isNil(encoding.note) ? encoding.note : '';
      return {
        ...encoding,
        note: `${oldValue}${isEmpty(oldValue) ? '' : '\n'}${speechToText}`,
      };
    });
    if (!showNote) {
      setShowNote(true);
    }
  };

  return (
    <div style={{ backgroundColor: Colors.PORCELAIN_GREY_1, borderRadius: 5, padding: 20 }}>
      {!isNil(structure.brand) && structure.brand !== '' && (
        <>
          {readOnly && <Typography style={{ fontSize: 14, color: Colors.DARK }}>{structure.brand}</Typography>}
          {!readOnly && (
            <TextFieldArea
              name={`${structureFieldName}.brand`}
              rows={1}
              rowsMax={1}
              inputProps={{ style: { textAlign: 'left' } }}
              InputProps={{ style: { fontSize: 14, width: '100%' } }}
              onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
            />
          )}
          <Divider style={{ marginBottom: 15, marginTop: 15 }} />
        </>
      )}
      {!isEmpty(audioFiles) && (
        <>
          <Typography style={{ color: Colors.SLATE_GREY, fontSize: 10, textTransform: 'uppercase', letterSpacing: 1 }}>
            {formatMessage({ id: 'leaseInventory.room.structureDetails.audio' })}
          </Typography>
          {audioFiles.map((audioFile) => (
            <CustomAudioPlayer
              key={audioFile.id}
              audioFile={audioFile}
              deleteFunction={readOnly ? undefined : () => setFileToDelete(audioFile)}
              appendTextToEnd={addSpeechToTextToNote}
            />
          ))}
          <Divider style={{ marginBottom: 15, marginTop: 15 }} />
        </>
      )}
      {!isEmpty(pictureFiles) && (
        <div>
          <Typography
            style={{
              color: Colors.SLATE_GREY,
              fontSize: 10,
              textTransform: 'uppercase',
              marginBottom: 10,
              letterSpacing: 1,
            }}
          >
            {formatMessage({ id: 'rentalUnit.detail.advertisement.pictures' })}
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            {pictureFiles.map((pf, index) => {
              return (
                <Button
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${pf.id}-${index}`}
                  onClick={(event) => {
                    event.stopPropagation();
                    const replaceFileEncodingFunction = (oldFile: EmptyFileWithUrl | S3Object, newFile: File) => {
                      const emptyFile = getEmptyFile(newFile);
                      const emptyFileWithUrl = { ...emptyFile, url: URL.createObjectURL(newFile) };
                      updateEncoding((encoding: LeaseInventoryEncoding) => {
                        return {
                          ...encoding,
                          medias: [
                            ...encoding.medias!.filter((m) => m.id !== oldFile.id),
                            emptyFileWithUrl,
                          ] as FileModel[],
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        } as any;
                      });
                      previewFileFunction({ file: null, onUpdatedFile: undefined });
                    };
                    previewFileFunction({ file: pf, onUpdatedFile: replaceFileEncodingFunction });
                  }}
                  style={{ textDecoration: 'none' }}
                >
                  <ListItemAvatar>
                    <FileItemRendering
                      key={pf.id}
                      fileType={'file' in pf ? pf.file.type : pf.mimeType!}
                      fileUrl={pf.url}
                      fileName={'file' in pf ? pf.file.name : pf.fileName}
                      style={{ width: 45, height: 45 }}
                      deleteIconStyle={{ width: 18, height: 18 }}
                      removeFunction={readOnly ? undefined : () => setFileToDelete(pf)}
                    />
                  </ListItemAvatar>
                </Button>
              );
            })}
          </div>
          <Divider style={{ marginBottom: 15, marginTop: 15 }} />
        </div>
      )}
      <Collapse in={showNote}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
          <Typography style={{ color: Colors.SLATE_GREY, fontSize: 10, textTransform: 'uppercase', letterSpacing: 1 }}>
            {formatMessage(
              {
                id: 'leaseInventory.room.structureDetails.notes',
              },
              { value: 1 }
            )}
          </Typography>
          {!readOnly && (
            <CustomIconButton
              onClick={() => {
                updateEncoding((encoding: ExtendedLeaseInventoryEncoding) => {
                  return {
                    ...encoding,
                    note: undefined,
                  };
                });
                setShowNote(false);
              }}
              size="small"
              Icon={Delete}
              iconStyle={{ fill: Colors.BLUE_GREY }}
            />
          )}
        </div>
        <TextFieldArea
          value={!isNil(encoding) ? encoding.note : ''}
          onChange={(value: string) => {
            updateEncoding((encoding: ExtendedLeaseInventoryEncoding) => {
              return {
                ...encoding,
                note: value,
              };
            });
          }}
          rows={4}
          rowsMax={8}
          style={{ backgroundColor: Colors.CLASSICAL_WHITE, borderRadius: 5 }}
          disabled={readOnly}
        />
      </Collapse>

      {!readOnly && (
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          {!showNote && (
            <ActionButton onClick={() => setShowNote(true)} className={classes.addButton}>
              <Add />
              {toUpper(
                formatMessage(
                  {
                    id: 'leaseInventory.room.structureDetails.notes',
                  },
                  { value: 1 }
                )
              )}
            </ActionButton>
          )}
          <ActionButton
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.preventDefault();
              e.stopPropagation();
              removeStructure(true);
            }}
            style={{ marginLeft: showNote ? 0 : 20, marginTop: showNote ? 10 : 0 }}
            className={classes.removeButton}
          >
            {toUpper(formatMessage({ id: 'furnitureInventory.deleteItem' }))}
          </ActionButton>
        </div>
      )}
    </div>
  );
};

export default FurnitureStructureEncodingExtraInfos;
