import React from 'react';
import { CenteredGrid, ElevatedPaper } from '@up2rent/ui';
import MenuNotFound from './MenuNotFound';

const NotFound: React.FC = () => {
  return (
    <CenteredGrid>
      <ElevatedPaper>
        <MenuNotFound />
      </ElevatedPaper>
    </CenteredGrid>
  );
};
export default NotFound;
