/* eslint-disable @typescript-eslint/no-shadow */
import { Collapse, Grid, MenuItem, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { getTranslatedEnumFormatMessage } from 'src/utils/enumTypes';
import CustomizedSelect from 'src/components/ui/CustomizedSelect';
import * as Yup from 'yup';
import { format, isBefore, isToday } from 'date-fns';
import HelpIconPopover from 'src/components/ui/HelpIconPopover';
import { AddLeaseFormValues } from 'src/components/Leases/AddLease/AddLeaseForm';
import {
  getLocaleFromLanguage,
  getNumberOfMonthsFromFrequency,
  getDateOfNextInvoice,
  LeasePaymentFrequency,
  LeasePaymentInvoicePeriod,
} from '@rentguru/commons-utils';
import { get, range, toNumber } from 'lodash';
import PaymentDueDateNextPeriod, { formatData, getPeriodOptions } from 'src/components/ui/PaymentDueDateNextPeriod';
import FormAlertBox from 'src/components/ui/FormAlertBox';
import { getLastInvoiceDate, isLeaseAlreadyStarted } from 'src/components/Leases/AddLease/useAddEditLeaseUtils';

export interface PaymentDueDateFields {
  paymentDueDateFields: {
    paymentFrequency: string;
    paymentInvoicePeriod: string;
    paymentInvoicePeriodCustomDay: number | null;
    lastInvoiceDate: string | null;
  };
}

export const PaymentDueDateFieldsSchema = Yup.object().shape({
  paymentFrequency: Yup.string().required(),
  paymentInvoicePeriod: Yup.string().required(),
  paymentInvoicePeriodCustomDay: Yup.number().when('paymentInvoicePeriod', {
    is: 'CUSTOM_PERIOD',
    then: Yup.number().required(),
    otherwise: Yup.number().nullable(),
  }),
});

export const PaymentDueDateSchema = Yup.object().shape({
  paymentDueDateFields: PaymentDueDateFieldsSchema,
});

const getNextInvoiceDateFromValues = (values: AddLeaseFormValues) => {
  const leaseStartDate = new Date(get(values, `startDate`));
  const paymentFrequency = values.paymentDueDateFields.paymentFrequency;
  const paymentInvoicePeriod = values.paymentDueDateFields.paymentInvoicePeriod;
  const paymentInvoicePeriodCustomDay = values.paymentDueDateFields.paymentInvoicePeriodCustomDay;
  const lastInvoiceDate = getLastInvoiceDate(values);
  const nextInvoiceDate = getDateOfNextInvoice(
    leaseStartDate,
    lastInvoiceDate,
    paymentFrequency as LeasePaymentFrequency,
    paymentInvoicePeriod as LeasePaymentInvoicePeriod,
    paymentInvoicePeriodCustomDay
  );
  return nextInvoiceDate;
};

const isVariousOperationOrDiscountInConflictWithLeaseInvoice = (values: AddLeaseFormValues) => {
  const allOperations = [
    ...values.leaseOperations,
    ...values.subUnits.map((subUnit) => subUnit.leaseOperations).flat(),
  ];
  const allDiscounts = [...values.discounts, ...values.subUnits.map((subUnit) => subUnit.discounts).flat()];
  const mergedLists = [...allOperations, ...allDiscounts];

  const leaseStartDate = new Date(get(values, `startDate`));
  const nextInvoiceDate = getNextInvoiceDateFromValues(values);

  return mergedLists.some(
    (variousOperation) =>
      variousOperation &&
      variousOperation.startDate! &&
      isLeaseAlreadyStarted(leaseStartDate) &&
      isBefore(new Date(variousOperation.startDate!), nextInvoiceDate)
  );
};

// eslint-disable-next-line no-redeclare
const PaymentDueDateFields = () => {
  const { values, setFieldValue, errors, touched, handleBlur } = useFormikContext<AddLeaseFormValues>();
  const { formatMessage } = useIntl();
  const locale = getLocaleFromLanguage(values.language.toLocaleLowerCase());
  const amountOfMonthsOfFrequency = getNumberOfMonthsFromFrequency(
    values.paymentDueDateFields.paymentFrequency as LeasePaymentFrequency
  );
  const didLeaseStartInThePast = isBefore(values.startDate, new Date()) && !isToday(values.startDate);
  const [selectedPeriod, setSelectedPeriod] = useState<number>(0);

  const dataRaw = getPeriodOptions(
    values.startDate,
    values.endDate!,
    amountOfMonthsOfFrequency,
    didLeaseStartInThePast,
    values.paymentDueDateFields.paymentFrequency as LeasePaymentFrequency,
    values.paymentDueDateFields.paymentInvoicePeriod as LeasePaymentInvoicePeriod,
    values.paymentDueDateFields.paymentInvoicePeriodCustomDay!
  );
  const data = formatData(dataRaw, locale);

  useEffect(() => {
    if (didLeaseStartInThePast) {
      setFieldValue('paymentDueDateFields.lastInvoiceDate', data[selectedPeriod].value);
    } else {
      setFieldValue('paymentDueDateFields.lastInvoiceDate', null);
    }
    // I do not need it to re-execute itself to often.
    // eslint-disable-next-line
  }, [
    selectedPeriod,
    values.paymentDueDateFields.paymentFrequency,
    values.paymentDueDateFields.paymentInvoicePeriod,
    values.paymentDueDateFields.paymentInvoicePeriodCustomDay,
  ]);

  if (
    values.paymentDueDateFields.paymentFrequency === LeasePaymentFrequency.BI_MONTHLY &&
    values.paymentDueDateFields.paymentInvoicePeriod !== LeasePaymentInvoicePeriod.CALENDAR_PERIOD
  ) {
    setFieldValue('paymentDueDateFields.paymentInvoicePeriod', LeasePaymentInvoicePeriod.CALENDAR_PERIOD);
  }

  // Check if there is a date conflict with LeaseVariousOperation startDate and first invoice date
  const isOperationOrDiscountProblem = isVariousOperationOrDiscountInConflictWithLeaseInvoice(values);
  const nextInvoiceDate = getNextInvoiceDateFromValues(values);

  return (
    <>
      <Grid style={{ display: 'flex' }}>
        <Grid style={{ marginLeft: 30 }}>
          <CustomizedSelect
            label={formatMessage({
              id: 'lease.addLease.paymentFrequency',
            })}
            fieldName="paymentDueDateFields.paymentFrequency"
            value={values.paymentDueDateFields.paymentFrequency}
            error={Boolean(
              errors.paymentDueDateFields &&
                errors.paymentDueDateFields.paymentFrequency &&
                touched.paymentDueDateFields &&
                touched.paymentDueDateFields.paymentFrequency
            )}
            FormControlProps={{ required: true, style: { width: 265 } }}
            SelectProps={{
              onChange: (e) => {
                const type = e.target.value as string;
                setFieldValue('paymentDueDateFields.paymentFrequency', type);
              },
              onBlur: handleBlur,
            }}
          >
            {getTranslatedEnumFormatMessage(formatMessage, 'LeasePaymentFrequency').map((mi) => {
              return (
                <MenuItem value={mi.value} key={mi.value}>
                  {mi.label}
                </MenuItem>
              );
            })}
          </CustomizedSelect>
        </Grid>
        <Grid style={{ marginLeft: 20, display: 'flex', alignItems: 'center' }}>
          <CustomizedSelect
            label={formatMessage({
              id: 'lease.addLease.paymentDueDate',
            })}
            fieldName="paymentDueDateFields.paymentInvoicePeriod"
            value={values.paymentDueDateFields.paymentInvoicePeriod}
            error={Boolean(
              errors.paymentDueDateFields &&
                errors.paymentDueDateFields.paymentInvoicePeriod &&
                touched.paymentDueDateFields &&
                touched.paymentDueDateFields.paymentInvoicePeriod
            )}
            FormControlProps={{ required: true, style: { width: 270 } }}
            SelectProps={{
              onChange: (event) => {
                const type = event.target.value as string;
                setFieldValue('paymentDueDateFields.paymentInvoicePeriod', type);
              },
              onBlur: handleBlur,
            }}
            disabled={values.paymentDueDateFields.paymentFrequency === LeasePaymentFrequency.BI_MONTHLY}
          >
            {getTranslatedEnumFormatMessage(formatMessage, 'LeasePaymentInvoicePeriod').map((mi) => (
              <MenuItem value={mi.value} key={mi.value}>
                {mi.label}
              </MenuItem>
            ))}
          </CustomizedSelect>
          <HelpIconPopover
            helperText={formatMessage({
              id: `lease.addLease.${
                values.paymentDueDateFields.paymentInvoicePeriod === LeasePaymentInvoicePeriod.CALENDAR_PERIOD
                  ? values.paymentDueDateFields.paymentFrequency === LeasePaymentFrequency.BI_MONTHLY
                    ? 'biMonthlyPeriodSelected'
                    : 'calendarPeriodSelected'
                  : values.paymentDueDateFields.paymentInvoicePeriod === LeasePaymentInvoicePeriod.CONTRACT_PERIOD
                  ? 'leasePeriodSelected'
                  : 'customPeriodSelected'
              }`,
            })}
            paperStyle={{ maxWidth: 400 }}
            iconStyle={{ marginLeft: 10 }}
          />
        </Grid>
      </Grid>
      <Collapse
        in={values.paymentDueDateFields.paymentInvoicePeriod === LeasePaymentInvoicePeriod.CUSTOM_PERIOD}
        style={{ textAlign: 'left', marginLeft: 30 }}
      >
        <CustomizedSelect
          label={formatMessage({ id: 'lease.addLease.paymentDay' })}
          fieldName="paymentDueDateFields.paymentInvoicePeriodCustomDay"
          value={values.paymentDueDateFields.paymentInvoicePeriodCustomDay}
          error={Boolean(
            errors.paymentDueDateFields?.paymentInvoicePeriodCustomDay &&
              touched.paymentDueDateFields?.paymentInvoicePeriodCustomDay
          )}
          FormControlProps={{ style: { width: 555, maxWidth: 'none', marginRight: 20 } }}
          SelectProps={{
            onChange: (event) => {
              const type = event.target.value as string;
              setFieldValue('paymentDueDateFields.paymentInvoicePeriodCustomDay', type);
            },
            onBlur: handleBlur,
          }}
        >
          {range(1, 32).map((index) => {
            return (
              <MenuItem value={index} key={index}>
                {index}
              </MenuItem>
            );
          })}
        </CustomizedSelect>
      </Collapse>
      <Collapse in={didLeaseStartInThePast}>
        <Grid style={{ display: 'flex' }}>
          <Grid style={{ marginLeft: 30, display: 'flex', alignItems: 'center' }}>
            <CustomizedSelect
              label={formatMessage({ id: 'lease.addLease.selectFirstPaymentPeriod' })}
              value={selectedPeriod}
              error={Boolean(
                errors.paymentDueDateFields &&
                  errors.paymentDueDateFields.lastInvoiceDate &&
                  touched.paymentDueDateFields &&
                  touched.paymentDueDateFields.lastInvoiceDate
              )}
              FormControlProps={{ required: true, style: { width: 555 } }}
              onChange={(value) => {
                const index = toNumber(value);
                setFieldValue('paymentDueDateFields.lastInvoiceDate', data[index].value);
                setSelectedPeriod(toNumber(index));
              }}
              inputLabelShrink
            >
              {data.map((data, index) => (
                <MenuItem
                  value={index}
                  key={`${format(data.value, 'MM/dd/yyyy', { locale: getLocaleFromLanguage('en') })}`}
                >
                  <Typography>{data.primary}</Typography>
                </MenuItem>
              ))}
            </CustomizedSelect>
          </Grid>
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            <HelpIconPopover
              helperText={formatMessage({
                id: `lease.addLease.selectedPeriod`,
              })}
              paperStyle={{ maxWidth: 400 }}
              iconStyle={{ marginLeft: 10 }}
            />
          </Grid>
        </Grid>
        <Collapse in={isOperationOrDiscountProblem} style={{ marginTop: 10 }}>
          <FormAlertBox
            messagesGridStyle={{ marginRight: 30 }}
            message1={formatMessage(
              { id: 'lease.addLease.operationOrDiscountInvoiceDateProblem' },
              { firstInvoiceDate: format(nextInvoiceDate, 'dd/MM/yyyy', { locale }) }
            )}
          />
        </Collapse>
      </Collapse>
      <Grid style={{ display: 'flex', alignItems: 'center' }}>
        <PaymentDueDateNextPeriod
          startDate={values.startDate}
          endDate={values.endDate!}
          didLeaseStartInThePast={didLeaseStartInThePast}
          lastInvoiceDate={values.paymentDueDateFields.lastInvoiceDate}
          paymentFrequency={values.paymentDueDateFields.paymentFrequency as LeasePaymentFrequency}
          paymentInvoicePeriod={values.paymentDueDateFields.paymentInvoicePeriod as LeasePaymentInvoicePeriod}
          paymentInvoicePeriodCustomDay={values.paymentDueDateFields.paymentInvoicePeriodCustomDay!}
          doesLeaseAlreadyExist={false}
          style={{ marginTop: 20 }}
        />
      </Grid>
    </>
  );
};

export default PaymentDueDateFields;
