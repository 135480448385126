import React, { useState } from 'react';
import { useNotifications } from 'src/hooks/NotificationsContext';
import NotificationBox from './NotificationBox';
import SlicedContent from './SlicedContent';
import {
  Notification,
  NotificationStatus,
  NotificationType,
  Technic,
  TechnicType,
  MAINTENANCE_BOX_KEY,
} from '@rentguru/commons-utils';
import isNil from 'lodash/isNil';
import { compareAsc, isBefore } from 'date-fns';
import MaintenanceNotificationRow from './BoxCustomRows/MaintenanceRow';
import MaintenanceHeader from './BoxCustomHeaders/MaintenanceHeader';
import Dialog from 'src/components/ui/Dialog';
import AddEPBForm from 'src/components/Technics/EPBs/AddEPBForm';
import EditDetector from 'src/components/Technics/Detectors/EditDetectorForm';
import AddHeatingControl from 'src/components/Technics/Heatings/AddHeatingControlForm';
import AddChimneyControl from 'src/components/Technics/Chimneys/AddChimneySweepingForm';
import AddTankControl from 'src/components/Technics/Tanks/AddTankControlForm';
import SnoozeNotificationDialog from './DashboardDialogs/SnoozeNotificationDialog';
import { useIntl } from 'react-intl';
import { useFiles } from 'src/hooks/FilesContext';
import TodosLoading from './TodosLoading';
import { useTechnics } from 'src/hooks/TechnicsContext';

export const ROW_HEIGHT = 27;
const NOTIFICATION_TYPES_TO_DISPLAY = [
  NotificationType.ChimneySweeping,
  NotificationType.PebCertificate,
  NotificationType.SmokeDetectorBattery,
  NotificationType.HeatingCertificate,
  NotificationType.FuelTankCertificate,
];

const maintenanceNotificationSort = (notification1: Notification, notification2: Notification): number => {
  if (isNil(notification1.expirationDate)) return 1;
  if (isNil(notification2.expirationDate)) return -1;
  const expirationCmp = compareAsc(new Date(notification1.expirationDate), new Date(notification2.expirationDate));
  if (expirationCmp !== 0) return expirationCmp;

  if (isNil(notification1.type)) return 1;
  if (isNil(notification2.type)) return -1;
  return notification1.type.localeCompare(notification2.type);
};

const DashboardMaintenanceNotifications: React.FC = () => {
  const { notifications, notificationsLoading } = useNotifications();
  const { getTechnic } = useTechnics();
  const [technicToSolve, setTechnicToSolve] = useState<Technic | null>(null);
  const [notificationToSnooze, setNotificationToSnooze] = useState<Notification | null>(null);
  const { createFile } = useFiles();
  const { formatMessage } = useIntl();
  const handleTechnicClose = () => setTechnicToSolve(null);
  if (notificationsLoading) {
    return (
      <>
        <TodosLoading />
      </>
    );
  }

  const notificationsToDisplay = notifications
    .filter((notification) => {
      if (isNil(notification.technicId)) {
        return false;
      }
      const technic = getTechnic(notification.technicId);

      return (
        !isNil(technic) &&
        (!isNil(technic.building) || !isNil(technic.unit)) &&
        !isNil(notification.type) &&
        NOTIFICATION_TYPES_TO_DISPLAY.includes(notification.type) &&
        (notification.status === NotificationStatus.Active ||
          (notification.status === NotificationStatus.Snoozed &&
            isBefore(new Date(notification.popupDate), new Date())))
      );
    })
    .sort(maintenanceNotificationSort)
    .map((notification: Notification) => {
      return (
        <MaintenanceNotificationRow
          key={notification.id}
          notification={notification}
          setNotificationToSnooze={setNotificationToSnooze}
          setTechnicToSolve={setTechnicToSolve}
        />
      );
    });

  return (
    <>
      <NotificationBox
        boxKey={MAINTENANCE_BOX_KEY}
        title={`${formatMessage({ id: 'technic.maintenance' })} (${notificationsToDisplay.length})`}
      >
        <SlicedContent
          data={notificationsToDisplay}
          header={<MaintenanceHeader />}
          type="table"
          limit={5}
          boxKey={MAINTENANCE_BOX_KEY}
          rowHeight={ROW_HEIGHT}
        />
      </NotificationBox>
      {!isNil(technicToSolve) && (
        <Dialog
          open={!isNil(technicToSolve)}
          onClose={handleTechnicClose}
          scroll="paper"
          fullWidth
          PaperProps={{ style: { borderRadius: 10, maxWidth: 640 } }}
        >
          {technicToSolve!.type === TechnicType.PEB && (
            <AddEPBForm
              afterSubmit={handleTechnicClose}
              cancelSubmit={handleTechnicClose}
              id={
                !isNil(technicToSolve.building)
                  ? technicToSolve.building.id
                  : !isNil(technicToSolve.unit)
                  ? technicToSolve.unit!.id
                  : ''
              }
              entity={!isNil(technicToSolve.building) ? 'building' : 'unit'}
              createFile={createFile}
            />
          )}
          {technicToSolve!.type === TechnicType.DETECTOR && (
            <EditDetector
              afterSubmit={handleTechnicClose}
              cancelSubmit={handleTechnicClose}
              detector={technicToSolve!}
              entity={!isNil(technicToSolve.building) ? 'building' : 'unit'}
            />
          )}
          {technicToSolve!.type === TechnicType.HEATING && (
            <AddHeatingControl
              heating={technicToSolve!}
              afterSubmit={handleTechnicClose}
              cancelSubmit={handleTechnicClose}
              createFile={createFile}
            />
          )}
          {technicToSolve!.type === TechnicType.CHIMNEY && (
            <AddChimneyControl
              chimney={technicToSolve!}
              afterSubmit={handleTechnicClose}
              cancelSubmit={handleTechnicClose}
              createFile={createFile}
            />
          )}
          {technicToSolve!.type === TechnicType.FUELTANK && (
            <AddTankControl
              tank={technicToSolve!}
              afterSubmit={handleTechnicClose}
              cancelSubmit={handleTechnicClose}
              createFile={createFile}
            />
          )}
        </Dialog>
      )}
      {!isNil(notificationToSnooze) && (
        <SnoozeNotificationDialog notification={notificationToSnooze} onClose={() => setNotificationToSnooze(null)} />
      )}
    </>
  );
};

export default DashboardMaintenanceNotifications;
