/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Formik, FormikHelpers, Form } from 'formik';
import * as Yup from 'yup';

import { useUser } from 'src/hooks/UserContext';
import { Typography, TextField, Collapse, Link, Grid } from '@material-ui/core';
import { ElevatedPaper, LoaderButton } from '@up2rent/ui';
import { AuthProps, FormType } from 'src/App';
import { onChangeToLowerCaseFieldValue } from 'src/utils/formutils';
import { authStyles } from './authStyles';
import IconnedRemark from '../ui/IconnedRemark';
import { iconnedRemarkMargins } from '../Leases/Details/End/EndLeaseForm';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().min(1).required(),
  password: Yup.string(),
  confirmationCode: Yup.string(),
});

interface ForgotFormValues {
  email: string;
  password: string;
  confirmationCode: string;
  defaultErrorMessage?: string;
}

const initialValues: ForgotFormValues = {
  email: '',
  password: '',
  confirmationCode: '',
};

const ForgotPassword: React.FC<AuthProps> = ({ updateFormType }) => {
  const { formatMessage } = useIntl();
  const { forgotPassword, forgotPasswordSubmit } = useUser();
  const styles = authStyles();

  const [showConfirmation, setConfirmation] = useState(false);

  const handleSubmit = async (
    values: ForgotFormValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<ForgotFormValues>
  ) => {
    try {
      if (!showConfirmation) {
        await forgotPassword(values.email);
        setConfirmation(true);
      } else {
        // Confirmation
        await forgotPasswordSubmit(values.email, values.confirmationCode, values.password);
        updateFormType(FormType.SIGN_IN);
        setStatus(true);
      }
      setSubmitting(false);
    } catch (error) {
      console.error('Error during forgot password flow: ', error);
      const err: Error = error as Error;
      if (err.name === 'UserNotFoundException') {
        setConfirmation(true);
      } else if (err.name === 'NotAuthorizedException' || err.name === 'CodeMismatchException') {
        setErrors({ confirmationCode: err.name, defaultErrorMessage: err.message });
      } else {
        setErrors({ [`${!showConfirmation ? 'email' : 'password'}`]: err.name, defaultErrorMessage: err.message });
      }
      setSubmitting(false);
    }
  };

  return (
    <Grid alignContent="center" alignItems="center" container direction="column">
      <Formik initialValues={initialValues} validationSchema={ForgotPasswordSchema} onSubmit={handleSubmit}>
        {({ values, errors, touched, handleChange, handleBlur, isSubmitting, status, setFieldValue }) => (
          <ElevatedPaper className={styles.mainContainer}>
            <Form>
              <Typography variant="h5" paragraph component="h2" gutterBottom>
                {formatMessage({ id: 'forgot.reinitPassword' })}
              </Typography>

              <TextField
                label={formatMessage({ id: 'login.email' })}
                id="email"
                type="email"
                name="email"
                autoComplete="email"
                margin="normal"
                variant="filled"
                fullWidth
                value={values.email}
                onChange={(event) => {
                  onChangeToLowerCaseFieldValue(event, 'email', setFieldValue);
                }}
                onBlur={handleBlur}
                required
                error={Boolean(errors.email && touched.email)}
                helperText={
                  errors.email && touched.email
                    ? formatMessage({
                        id: `error.${errors.email}`,
                        defaultMessage: errors.defaultErrorMessage,
                      })
                    : ''
                }
              />
              <Collapse in={showConfirmation} style={{ marginBottom: 20 }}>
                <IconnedRemark
                  Icon={InfoSvg}
                  noFixWidth
                  style={iconnedRemarkMargins}
                  message={
                    <Typography style={{ textAlign: 'left' }}>
                      {formatMessage({ id: 'signup.confirmationCodeHelpLonger' })}
                    </Typography>
                  }
                />
                <TextField
                  label={formatMessage({ id: 'signup.confirmationCodeLabel' })}
                  id="confirmationCode"
                  type="tel"
                  name="confirmationCode"
                  autoComplete="tel"
                  margin="normal"
                  variant="filled"
                  fullWidth
                  value={values.confirmationCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={showConfirmation}
                  error={Boolean(errors.confirmationCode && touched.confirmationCode)}
                  helperText={
                    errors.confirmationCode && touched.confirmationCode
                      ? formatMessage({
                          id: `error.${errors.confirmationCode}`,
                          defaultMessage: errors.defaultErrorMessage,
                        })
                      : formatMessage({ id: 'signup.confirmationCodeHelp' })
                  }
                />
                <TextField
                  label={formatMessage({ id: 'login.password' })}
                  id="password"
                  type="password"
                  autoComplete="current-password"
                  margin="normal"
                  variant="filled"
                  fullWidth
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={showConfirmation}
                  error={Boolean(errors.password && touched.password)}
                  helperText={
                    errors.password && touched.password
                      ? formatMessage({
                          id: `error.${errors.password}`,
                          defaultMessage: errors.defaultErrorMessage,
                        })
                      : ''
                  }
                />
              </Collapse>

              <LoaderButton loading={isSubmitting} success={status}>
                {formatMessage({
                  id: 'forgot.reset',
                })}
              </LoaderButton>
            </Form>
          </ElevatedPaper>
        )}
      </Formik>
      <Link onClick={() => updateFormType(FormType.SIGN_IN)}>
        <Typography style={{ cursor: 'pointer' }}>{formatMessage({ id: 'login.connectWithMail' })}</Typography>
      </Link>
    </Grid>
  );
};

export default ForgotPassword;
