import React, { useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  EnhancedTableScrollable,
  PASS_FULL_OBJECT_TO_FUNCTION,
  TablePlaceHolder,
  EnhancedTableSharedContextProvider,
} from '@up2rent/ui';
import {
  AgencyRate,
  AgencyRateOwner,
  getContactNameFromObject,
  getNumberOfUnitsForOwner,
  compareContactsByName,
  getUnitsOfOwnerSync,
  Unit,
  Colors,
} from '@rentguru/commons-utils';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import AgencyRateOwnersRow from './AgencyRateOwnersRow';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { useContacts } from 'src/hooks/ContactsContext';
import { Typography } from '@material-ui/core';
import { useUnits } from 'src/hooks/UnitsContext';
import { isEmpty } from 'lodash';
import DeleteOwnerFromAgencyRate from './DeleteOwnerFromAgencyRate';
import { ReactComponent as InfoIcon } from 'src/icons/info.svg';
import { ReactComponent as NoResultIcon } from 'src/icons/noResult.svg';

const getOwnerName = (agencyRateOwner: AgencyRateOwner) => {
  return getContactNameFromObject(agencyRateOwner.owner);
};

interface AgencyRateOwnersProps {
  agencyRate: AgencyRate;
}

const AgencyRateOwners: React.FC<AgencyRateOwnersProps> = ({ agencyRate }) => {
  const [idToDelete, setIdToDelete] = useState<string | null>(null);
  const { formatMessage } = useIntl();
  const { agencyRateWrite } = usePermissions();
  const { buildings, buildingsLoading } = useBuildings();
  const { units, unitsLoading } = useUnits();
  const { getContact, contactsLoading } = useContacts();

  const { owners = [] } = agencyRate;

  const datasLoading = buildingsLoading || contactsLoading || unitsLoading;

  const ownersWithUnits = useMemo<(AgencyRateOwner & { units: Unit[] })[]>(() => {
    if (datasLoading || !agencyRate || !owners || isEmpty(owners)) {
      return [];
    }
    const completeOwners = owners.reduce((currentResult: (AgencyRateOwner & { units: Unit[] })[], agencyRateOwner) => {
      // Get complete
      const currentOwner = getContact(agencyRateOwner.owner!.id);
      if (!currentOwner) {
        return currentResult;
      }

      const ownerUnits = getUnitsOfOwnerSync(currentOwner, buildings, units);

      currentResult.push({ ...agencyRateOwner, owner: currentOwner, agencyRate, units: ownerUnits });
      return currentResult;
    }, []);

    return completeOwners.sort((a, b) => {
      if (!a.owner || !b.owner) {
        return 0;
      }

      return compareContactsByName(a.owner, b.owner);
    });
  }, [datasLoading, owners, getContact, agencyRate, units, buildings]);

  if (datasLoading) {
    return <Typography>{formatMessage({ id: 'loading' })}</Typography>;
  }

  // Delete owner
  const handleActionMenu = (_anchor: HTMLElement, id: string) => {
    setIdToDelete(id);
  };

  const handleClose = () => {
    setIdToDelete(null);
  };

  // Colummns
  const columns = [
    {
      id: `${PASS_FULL_OBJECT_TO_FUNCTION}name`,
      labelId: 'contact.columnHeader.name',
      numeric: false,
      disablePadding: false,
      sortable: true,
      displayFunction: getOwnerName,
    },
    {
      id: `${PASS_FULL_OBJECT_TO_FUNCTION}units`,
      labelId: 'agencyRates.units',
      numeric: false,
      disablePadding: false,
      sortable: true,
      displayFunction: (agencyRateOwner: AgencyRateOwner) => {
        const contact = agencyRateOwner.owner ? getContact(agencyRateOwner.owner.id) : undefined;
        if (!contact) {
          return 0;
        }
        return getNumberOfUnitsForOwner(contact, buildings, units);
      },
    },
    {
      id: `fees`,
      labelId: 'agencyRates.detail.expectedFees',
      numeric: false,
      disablePadding: false,
      sortable: false,
      infoTextMessageId: 'agencyRates.detail.expectedFeesInfo',
    },
    {
      id: 'actions',
      labelId: 'communications.list.actions',
      numeric: true,
      disablePadding: false,
      sortable: false,
    },
  ];

  return (
    <>
      <EnhancedTableSharedContextProvider
        value={{ orderBy: `${PASS_FULL_OBJECT_TO_FUNCTION}name` }}
        orderFirst="asc"
        datas={ownersWithUnits}
        handleActionMenu={handleActionMenu}
      >
        <EnhancedTableScrollable
          InfoIcon={<InfoIcon style={{ fill: Colors.SILVER }} />}
          NoResultIcon={<NoResultIcon />}
          formatMessage={formatMessage}
          datas={ownersWithUnits}
          noMinWidth
          columns={agencyRateWrite ? columns : columns.filter((c) => c.id !== 'action')}
          defaultOrderBy={`${PASS_FULL_OBJECT_TO_FUNCTION}name`}
          rowComponent={AgencyRateOwnersRow}
          placeHolder={
            <TablePlaceHolder
              mainText={formatMessage({ id: 'emptySectionTitle' })}
              subText={formatMessage({ id: 'emptySectionText' })}
            />
          }
          menuShown={false}
        />
      </EnhancedTableSharedContextProvider>
      {idToDelete && <DeleteOwnerFromAgencyRate onClose={handleClose} id={idToDelete} />}
    </>
  );
};

export default AgencyRateOwners;
