import { Grid, Typography } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import { useIntl } from 'react-intl';
import TextDetailEditable from 'src/components/ui/TextDetailEditable';
import {
  OptionalSettings,
  SettableCommunicationType,
} from '../CommunicationSettingsProfileUtils/CommunicationSettingsCommonsUtils';

interface CommunicationNumberOfDaysFormItemProps {
  communicationType: SettableCommunicationType;
  settingType: OptionalSettings.DAYS_BEFORE_DUE_DATE | OptionalSettings.DAYS_OVERDUE;
  error: boolean;
  disabled: boolean;
  numberOfDays: number | false;
  onChange?: (value: number) => void;
}

const CommunicationNumberOfDaysFormItem: React.FC<CommunicationNumberOfDaysFormItemProps> = ({
  communicationType,
  settingType,
  error,
  disabled,
  numberOfDays,
  onChange,
}) => {
  const { formatMessage } = useIntl();
  const previewMode = !(numberOfDays === false);
  return (
    <Grid container style={{ alignItems: 'center', paddingTop: previewMode ? 10 : 0 }}>
      <Grid item>
        {!previewMode && (
          <TextDetailEditable
            name={`${communicationType}.${settingType}`}
            title=""
            type="number"
            style={{ width: 80 }}
            inputProps={{ style: { fontSize: 12 } }}
            onChangeObserver={onChange}
            editMode
            error={error}
            disabled={disabled}
          />
        )}
      </Grid>
      {previewMode && (
        <Grid item>
          <Typography variant="subtitle2" style={{ color: Colors.SLATE_GREY }}>
            {numberOfDays.toString()}&nbsp;
          </Typography>
        </Grid>
      )}

      <Grid item style={{ marginLeft: previewMode ? 0 : 10 }}>
        <Typography variant="subtitle2" style={{ color: Colors.SLATE_GREY }}>
          {formatMessage({
            id: `settings.communications.${settingType}${
              settingType === OptionalSettings.DAYS_OVERDUE ? `.${communicationType}` : ''
            }`,
          })}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CommunicationNumberOfDaysFormItem;
