import { Grid, TableCell, TextField } from '@material-ui/core';
import { Colors, ModelWithVersion, UpdateSendingSourceInput, SendingSource } from '@rentguru/commons-utils';
import { Formik, FormikHelpers } from 'formik';
import { motion } from 'framer-motion';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { IntlFormatters, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { OverflowableTypography , CustomIconButton } from '@up2rent/ui';
import { useSendingSources } from 'src/hooks/SendingSourceContext';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { verifyAddressPrefix } from './AddDomainDialog';
import { SaveOutlined } from '@material-ui/icons';

export enum AddressType {
  NO_REPLY = 'NoReply',
  ISSUES = 'Issues',
  CHARGES = 'Charges',
}

interface EmailRowProps {
  addressType: AddressType;
  backgroundColor: string;
  id: string;
}

interface UpdateEmailValues {
  addressPrefix: string;
}

const UpdateEmailSchema = (formatMessage: IntlFormatters['formatMessage']) => {
  return Yup.object().shape({
    addressPrefix: Yup.string()
      .required(formatMessage({ id: 'mandatoryField' }))
      .test(
        'Checking charges address validity',
        formatMessage({ id: 'settings.sendingSources.invalidEmail' }),
        (value) => {
          if (isNil(value)) return false;
          return verifyAddressPrefix(value, formatMessage);
        }
      ),
  });
};

const getAddressName = (addressType: AddressType, formatMessage: IntlFormatters['formatMessage']) => {
  if (addressType === AddressType.ISSUES) {
    return formatMessage({ id: `settings.sendingSources.supportEmail` });
  }
  if (addressType === AddressType.CHARGES) {
    return formatMessage({ id: `settings.sendingSources.chargesEmail` });
  }
  return formatMessage({ id: `settings.sendingSources.sendingEmail` });
};

const getEmailAddress = (addressType: AddressType, sendingSource: SendingSource) => {
  if (addressType === AddressType.ISSUES) {
    return sendingSource.issuesEmail;
  }
  if (addressType === AddressType.CHARGES) {
    return sendingSource.chargesEmail;
  }
  return sendingSource.emailAddress;
};

const AddressRow: React.FC<EmailRowProps> = ({ addressType, backgroundColor, id }) => {
  const { formatMessage } = useIntl();
  const { updateSendingSource, getSendingSource } = useSendingSources();
  const { settingsAutomationWrite } = usePermissions();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isInError, setIsInError] = useState<boolean>(false);

  const sendingSource = getSendingSource(id)! as ModelWithVersion<SendingSource>;
  let emailAddress = getEmailAddress(addressType, sendingSource);

  const message = getAddressName(addressType, formatMessage);
  const [addressPrefix, domain] = emailAddress.split('@');
  const baseStyle = addressType !== AddressType.CHARGES ? { borderBottom: 'none', paddingBottom: 0 } : {};
  const isSaveDisabled = (newValue: string) => newValue === addressPrefix;

  const initialValues: UpdateEmailValues = {
    addressPrefix,
  };

  const customHandleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleChange: (event: React.ChangeEvent<any>) => void
  ) => {
    if (isInError) {
      setIsInError(false);
    }
    handleChange(event);
  };

  const handleUpdate = async (
    values: UpdateEmailValues,
    { setSubmitting, setStatus }: FormikHelpers<UpdateEmailValues>
  ) => {
    setSubmitting(true);

    try {
      UpdateEmailSchema(formatMessage).validateSync(values);
    } catch (err) {
      setErrorMessage((err as Yup.ValidationError).message);
      setIsInError(true);
      setSubmitting(false);
      return;
    }

    let sendingSourceToUpdate: UpdateSendingSourceInput = {
      id,
      _version: sendingSource._version,
    };
    const newAddress = `${values.addressPrefix}@${domain}`;

    if (addressType === AddressType.NO_REPLY) {
      sendingSourceToUpdate = { ...sendingSourceToUpdate, emailAddress: newAddress };
    }
    if (addressType === AddressType.ISSUES) {
      sendingSourceToUpdate = { ...sendingSourceToUpdate, issuesEmail: newAddress };
    }
    if (addressType === AddressType.CHARGES) {
      sendingSourceToUpdate = { ...sendingSourceToUpdate, chargesEmail: newAddress };
    }

    const updatedSendingSource = await updateSendingSource(sendingSourceToUpdate);
    setStatus(true);
    setSubmitting(false);

    if (updatedSendingSource) {
      emailAddress = newAddress;
    }
  };

  return (
    <motion.tr
      style={{
        backgroundColor,
        height: 60,
      }}
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: 60, opacity: 1 }}
      transition={{
        ease: 'easeIn',
      }}
    >
      <TableCell style={{ padding: 0, ...baseStyle }} />
      <Formik initialValues={initialValues} validationSchema={UpdateEmailSchema(formatMessage)} onSubmit={handleUpdate}>
        {({ values, handleChange, isSubmitting, setSubmitting, setStatus }) => (
          <>
            <TableCell style={{ paddingRight: 0, ...baseStyle }}>
              <TextField
                label={message}
                name="addressPrefix"
                style={{ maxWidth: 215 }}
                type="text"
                onChange={(e) => customHandleChange(e, handleChange)}
                size="small"
                placeholder={addressPrefix}
                helperText={isInError ? errorMessage : ''}
                error={isInError}
                variant="outlined"
                value={values.addressPrefix}
              />
            </TableCell>
            <TableCell style={{ paddingLeft: 0, ...baseStyle }}>
              <Grid style={{ display: 'flex', alignItems: 'center', marginBottom: isInError ? 40 : 0 }}>
                <OverflowableTypography
                  text={`@${domain}`}
                  style={{ color: Colors.SLATE_GREY, width: 214, paddingTop: 16, paddingBottom: 16 }}
                />
              </Grid>
            </TableCell>
            <TableCell style={{ ...baseStyle, paddingLeft: 0 }}>
              {settingsAutomationWrite && (
                <CustomIconButton
                  style={{ padding: 0 }}
                  disabled={isSubmitting || isSaveDisabled(values.addressPrefix)}
                  onClick={(event) => {
                    handleUpdate(values, { setSubmitting, setStatus } as FormikHelpers<UpdateEmailValues>);
                    event.stopPropagation();
                  }}
                  Icon={SaveOutlined}
                />
              )}
            </TableCell>
          </>
        )}
      </Formik>
    </motion.tr>
  );
};
export default AddressRow;
