import React from 'react';
import { useFormikContext, FormikProps } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { UnitInventory } from '@rentguru/commons-utils';
import ComboBox, { CustomMenuItemType } from '../../ComboBox/TextComboBox';
import isNil from 'lodash/isNil';

export interface UnitInventorySelectorFormValues {
  unitInventoryId: string;
}

export const UnitInventorySelectorSchema = Yup.object()
  .shape({
    unitInventoryId: Yup.string().min(1).required(),
  })
  .required();

interface UnitInventorySelectorFieldProps {
  unitsInventory: UnitInventory[];
  fieldName: string;
  initialValue?: string | null;
  readonly?: boolean;
  onChange?: (event: string) => void;
  inputStyle?: React.CSSProperties;
  required?: boolean;
}

const UnitInventorySelectorField: React.FC<UnitInventorySelectorFieldProps> = ({
  unitsInventory,
  fieldName,
  initialValue: initialUnitInventoryId,
  readonly = false,
  onChange,
  inputStyle,
  required = false,
}) => {
  const { errors, touched, setFieldValue }: FormikProps<UnitInventorySelectorFormValues> = useFormikContext();
  const { formatMessage } = useIntl();

  const unitsInventoryMenu = unitsInventory.map((u) => {
    return {
      value: u.id,
      primary: formatMessage({ id: `leaseInventory.roomType.${u.roomType.toLowerCase()}` }),
      secondary: u.roomName,
    };
  });

  const initialUnit = unitsInventory.find((u) => u.id === initialUnitInventoryId);
  let initialValue;
  if (!isNil(initialUnit)) {
    initialValue = {
      primary: formatMessage({ id: `leaseInventory.roomType.${initialUnit.roomType.toLowerCase()}` }),
      secondary: initialUnit.roomName,
      value: initialUnit.id,
    };
  }
  return (
    <div
      style={{
        alignItems: 'baseline',
        display: 'inline-flex',
      }}
    >
      <ComboBox
        label={
          formatMessage(
            {
              id: 'technic.detectorTab.unitInventory',
            },
            { value: 1 }
          ) +
          (required
            ? ''
            : ` ${formatMessage({
                id: 'optional',
              })}`)
        }
        data={unitsInventoryMenu}
        onChange={(_e: unknown, value: CustomMenuItemType | null) => {
          const safeValue = isNil(value) ? null : (value as CustomMenuItemType).value;
          if (!isNil(onChange)) {
            onChange(safeValue);
          }
          setFieldValue(fieldName, safeValue);
        }}
        initialValue={initialValue}
        inputStyle={inputStyle}
        noOptionsText={formatMessage({ id: 'comboBox.noUnitsInventory' })}
        error={Boolean(errors.unitInventoryId && touched.unitInventoryId)}
        disabled={readonly}
        required={required}
      />
    </div>
  );
};

export default UnitInventorySelectorField;
