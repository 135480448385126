import { InputAdornment, ListItem, TextField } from '@material-ui/core';
import { isNil, toNumber } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { FieldFilterSelector } from 'src/components/ui/FieldFilterSelector';

interface LeasesFiltersBalanceProps {
  balanceLatePaymentMonth?: number;
  balanceDept: number;
  onChange: (fieldName: string, value: number) => void;
  onReset: (filterName: string, fieldName: string) => void;
}

const LeasesFiltersBalance: React.FC<LeasesFiltersBalanceProps> = ({
  balanceLatePaymentMonth,
  balanceDept,
  onChange,
  onReset,
}) => {
  const { formatMessage } = useIntl();

  // Selected?
  const balanceFilterSelected = balanceDept !== 0 || (!isNil(balanceLatePaymentMonth) && balanceLatePaymentMonth !== 0);

  return (
    <FieldFilterSelector
      label={formatMessage({ id: 'rentalUnit.columnHeader.balance' })}
      showResetButton={false}
      showSearchField={false}
      filterName="balance"
      fieldName="balance"
      textReset={formatMessage({ id: 'reinitFilter' })}
      textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
      textSearchField={formatMessage({ id: 'search' })}
      textSelectAll={formatMessage({ id: 'selectAll' })}
      onReset={onReset}
      onChange={() => {}}
      minSelectorHeight={700}
      minSelectorWidth={1500}
      selected={balanceFilterSelected}
    >
      {!isNil(balanceLatePaymentMonth) && (
        <ListItem key="latePayment" style={{ paddingTop: 0, paddingBottom: 0 }}>
          <TextField
            id="latePayment"
            label={formatMessage({
              id: 'rentalUnit.filter.latePayment',
            })}
            type="number"
            name="latePayment"
            margin="normal"
            variant="filled"
            value={balanceLatePaymentMonth.toString()}
            onChange={(event) => onChange('balanceLatePaymentMonth', toNumber(event.target.value))}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {formatMessage({
                    id: 'rentalUnit.filter.month',
                  })}
                </InputAdornment>
              ),
            }}
          />
        </ListItem>
      )}
      <ListItem key="dept" style={{ paddingTop: 0, paddingBottom: 0 }}>
        <TextField
          id="dept"
          label={formatMessage({
            id: 'rentalUnit.filter.dept',
          })}
          type="number"
          name="dept"
          margin="normal"
          variant="filled"
          value={balanceDept.toString()}
          onChange={(event) => onChange('balanceDept', toNumber(event.target.value))}
          InputProps={{
            endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
          }}
        />
      </ListItem>
    </FieldFilterSelector>
  );
};

export default LeasesFiltersBalance;
