import React, { useState } from 'react';
import ContentHeaderTabDetails from '../../ui/ContentHeaderTabDetails';
import { ReactComponent as MoreSvg } from '../../../icons/more.svg';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteTicket from '../DeleteTicket';
import Dialog from 'src/components/ui/Dialog';
import { useIntl } from 'react-intl';
import { stripHTML } from '../../../utils/stripHTML';
import { useHistory } from 'react-router-dom';
import isNil from 'lodash/isNil';
import { getContactNameFromObject, Ticket } from '@rentguru/commons-utils';
import { MenuItemText } from '@up2rent/ui';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';

interface TicketDetailsTabProps {
  ticket?: Ticket;
  deletable?: boolean;
}

const TicketDetailTabHeader: React.FC<TicketDetailsTabProps> = ({ ticket, deletable = false }) => {
  const { ticketsDetailsDelete } = usePermissions();
  const { formatMessage } = useIntl();
  const [dialogDeleteTicketOpen, setDialogDeleteTicketOpen] = useState(false);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleDeleteTicketClickOpen = () => {
    setDialogDeleteTicketOpen(true);
    setAnchorEl(null);
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDialogs = () => {
    setDialogDeleteTicketOpen(false);
    handleCloseMenu();
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const clientName = !isNil(ticket)
    ? getContactNameFromObject(ticket.contact)
    : formatMessage({ id: 'tickets.addTicket.return' });
  const clientSubject = !isNil(ticket) ? ticket.subject : '';

  return (
    <ContentHeaderTabDetails
      goBackFunction={() => history.goBack()}
      mainText={clientName}
      subText={stripHTML(clientSubject)}
    >
      {ticketsDetailsDelete && deletable && !isNil(ticket) && (
        <>
          <Button onClick={handleClickMenu}>
            <MoreSvg />
          </Button>

          <Menu id="action-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
            <MenuItem onClick={handleDeleteTicketClickOpen}>
              <MenuItemText primary={formatMessage({ id: 'delete' })} deleteOption />
            </MenuItem>
          </Menu>
          <Dialog
            open={dialogDeleteTicketOpen}
            onClose={handleCloseDialogs}
            scroll="body"
            disableBackdropClick
            disableEscapeKeyDown
          >
            <DeleteTicket afterSubmit={handleCloseDialogs} ticket={ticket} />
          </Dialog>
        </>
      )}
    </ContentHeaderTabDetails>
  );
};

export default TicketDetailTabHeader;
