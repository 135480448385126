import React, { useEffect, useRef, useState } from 'react';
import ReactMapboxGl, { ZoomControl } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import './mapbox.css';
import { isNil } from 'lodash';
import { getContactNameFromObject, Building } from '@rentguru/commons-utils';
import { DomEvent } from 'leaflet';
import MapPopup, { PopupData } from './MapPopup';
import MapCluster from './MapCluster';
import { stopPropagation } from 'src/components/Dashboard/Dashboard';

const ACCESS_TOKEN_MAPBOX = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;
mapboxgl.accessToken = ACCESS_TOKEN_MAPBOX;
interface BuildingsMapProps {
  buildings: Building[];
  zoomStart: [number];
  centerStart: [number, number];
}

export interface UnitTypeRepartition {
  commerce?: number;
  residential?: number;
  parking?: number;
  office?: number;
  student?: number;
  other?: number;
}

const Map = ReactMapboxGl({
  accessToken: ACCESS_TOKEN_MAPBOX,
  attributionControl: false,
});

const height = 360;
const width = '100%';

const BuildingsMap: React.FC<BuildingsMapProps> = ({ buildings, zoomStart, centerStart }) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [popup, setPopup] = useState<PopupData | undefined>(undefined);
  const [mapCenter, setMapCenter] = useState<[number, number]>(centerStart);

  useEffect(() => {
    if (!wrapperRef || !wrapperRef.current) return;
    DomEvent.disableClickPropagation(wrapperRef.current);
  }, []);

  const displayPopup = (building: Building, unitTypeRepartition: UnitTypeRepartition) => {
    const keys: (keyof UnitTypeRepartition)[] = Object.keys(unitTypeRepartition) as (keyof UnitTypeRepartition)[];
    const unitsSum = keys.reduce((sum, key: keyof UnitTypeRepartition) => {
      if (isNil(unitTypeRepartition) || isNil(unitTypeRepartition[key])) {
        return sum;
      }

      return sum + unitTypeRepartition[key]!;
    }, 0);
    const percentageRepartition = keys.reduce((acc: UnitTypeRepartition, key) => {
      acc[key] = (unitTypeRepartition[key]! / unitsSum) * 100;
      return acc;
    }, {});
    setMapCenter([building.address!.longitude!, building.address!.latitude!]);
    setPopup({
      coordinates: [building.address!.longitude!, building.address!.latitude!],
      name: building.name,
      id: building.id,
      address: `${building.address!.street}, ${!isNil(building.address!.number) ? building.address!.number : ''}  ${
        !isNil(building.address!.postalCode) ? building.address!.postalCode : ''
      } ${!isNil(building.address!.city) ? building.address!.city : ''}`,
      owners: building.owners?.map((owner) => getContactNameFromObject(owner.owner)) ?? [''],
      unitTypeRepartition: percentageRepartition,
    });
  };

  return (
    <div ref={wrapperRef} style={{ height: '100%' }} onMouseDown={stopPropagation}>
      <Map
        style="mapbox://styles/mapbox/light-v10"
        containerStyle={{
          height,
          width,
        }}
        center={mapCenter}
        zoom={zoomStart}
        renderChildrenInPortal={true}
        onDrag={() => {
          setPopup(undefined);
        }}
      >
        <MapCluster buildings={buildings} displayPopup={displayPopup} />

        <ZoomControl />
        <MapPopup popup={popup} />
      </Map>
    </div>
  );
};

export default BuildingsMap;
