import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import { formatDistanceToNow } from 'date-fns';
import { isNil } from 'lodash';
import { Grid, ListItem, Paper, Typography } from '@material-ui/core';
import { Colors, getLocaleFromLanguage, CustomSubscription, Charge } from '@rentguru/commons-utils';
import { useCustomSubscriptions } from 'src/hooks/CustomSubscriptionContext';
import { useUser } from 'src/hooks/UserContext';
import { ReactComponent as OcrIcon } from 'src/icons/ocr.svg';
import NotificationDeleteIconButton from 'src/components/ui/CustomIconButtons/NotificiationDeleteIconButton';
import { useNotificationListStyle } from './NotificationsList';
import { Link } from 'react-router-dom';
import { RouteDestination } from '../Routes/Routes';
import { useCharges } from 'src/hooks/ChargesContext';

interface NotificationsOcrItemProps {
  customSubscription: CustomSubscription;
  chargeIds: string[];
  isFailure?: boolean;
}

const getChargesNavigation = (
  chargesToShow: (Charge | null | undefined)[],
  allCharges: (Charge | null | undefined)[]
) => (
  <>
    {chargesToShow.map(
      (currentCharge, index) =>
        currentCharge &&
        currentCharge.document && (
          <Fragment key={`charge-link-${currentCharge.id}`}>
            <Link
              to={{
                pathname: RouteDestination.ADD_CHARGE,
                state: { id: currentCharge.id },
              }}
              style={{ color: Colors.DODGER_BLUE }}
            >
              {currentCharge.document.key.split('/').pop()?.slice(37)}
            </Link>
            {index !== chargesToShow.length - 1 && <>, </>}
          </Fragment>
        )
    )}
    {chargesToShow.length !== allCharges.length && <>, ...</>}
  </>
);

const getTooltipChargesLinks = (allCharges: (Charge | null | undefined)[]) =>
  allCharges.map(
    (currentCharge) =>
      currentCharge &&
      currentCharge.document && (
        <Typography key={`tooltip-charge-link-${currentCharge.id}`}>
          <span style={{ marginRight: 2 }}>• </span>
          <Link
            to={{
              pathname: RouteDestination.ADD_CHARGE,
              state: { id: currentCharge.id },
            }}
            style={{ color: Colors.DODGER_BLUE }}
          >
            {currentCharge.document.key.split('/').pop()?.slice(37)}
          </Link>
        </Typography>
      )
  );

export const NotificationsOcrItem: React.FC<NotificationsOcrItemProps> = ({
  customSubscription,
  chargeIds,
  isFailure,
}) => {
  const { formatMessage } = useIntl();
  const localStyles = useNotificationListStyle();
  const { language } = useUser();
  const { deleteCustomSubscriptions } = useCustomSubscriptions();
  const { getCharge } = useCharges();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const chargesCount = chargeIds.length;
  const allCharges = chargeIds.map((chargeId) => getCharge(chargeId));
  const chargesToShow = allCharges.slice(0, 5);

  return (
    <ListItem className={localStyles.listItem}>
      <Grid>
        <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            <OcrIcon
              style={{
                width: 45,
                height: 45,
                transform: 'scale(0.5)',
              }}
            />
            <Typography variant="h6" style={{ color: Colors.CLASSICAL_BLACK }}>
              {formatMessage({ id: 'settings.communications.ocrTitle' })}
            </Typography>
          </Grid>
          <NotificationDeleteIconButton
            onClick={() => {
              deleteCustomSubscriptions([customSubscription]);
            }}
          />
        </Grid>
        {!isNil(customSubscription.createdAt) && (
          <Typography style={{ color: Colors.BLUEY, paddingBottom: 5 }}>
            {formatDistanceToNow(new Date(customSubscription.createdAt!), {
              locale: getLocaleFromLanguage(language || 'en'),
            })}
          </Typography>
        )}
        {customSubscription && (
          <>
            <Typography style={{ color: Colors.SLATE_GREY }}>
              {isFailure
                ? formatMessage({ id: 'settings.communications.ocrFailure' }, { chargesCount })
                : formatMessage({ id: 'settings.communications.ocrSuccess' }, { chargesCount })}
            </Typography>
            <Typography onMouseEnter={() => setIsTooltipOpen(true)} onMouseLeave={() => setIsTooltipOpen(false)}>
              {getChargesNavigation(chargesToShow, allCharges)}
            </Typography>
            {isTooltipOpen && (
              <Paper
                elevation={8}
                style={{
                  position: 'absolute',
                  marginBottom: 20,
                  zIndex: 100,
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 10,
                  right: 0,
                }}
                onMouseEnter={() => setIsTooltipOpen(true)}
                onMouseLeave={() => setIsTooltipOpen(false)}
                onClick={(e) => e.stopPropagation()}
              >
                <Typography
                  style={{ color: Colors.CLASSICAL_BLACK, paddingBottom: 5, fontSize: 14, fontWeight: 'bold' }}
                >
                  {formatMessage({ id: 'settings.communications.concernedCharges' })}
                </Typography>
                {getTooltipChargesLinks(allCharges)}
              </Paper>
            )}
          </>
        )}
      </Grid>
    </ListItem>
  );
};
