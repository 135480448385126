import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Tooltip, TableRow, Grid } from '@material-ui/core';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import {
  Colors,
  formatNumber,
  formatStandardizedUTCDate,
  StatementSource,
  CustomValue,
  Statement,
  S3Object,
} from '@rentguru/commons-utils';
import { dateLocaleMap, useLocale } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as ReceiptIcon } from 'src/icons/receipt.svg';
import { RouteDestination } from 'src/components/Routes/Routes';
import { CustomTableCell } from '@up2rent/ui';
import TenantStatementsToValidateAmountPopper from 'src/components/Accounting/TenantStatements/TenantStatementsToValidate/TenantStatementsToValidateAmountPopper';
import DocumentIconButton from 'src/components/ui/CustomIconButtons/DocumentIconButton';

interface TenantStatementsArchivedRowProps extends Statement {
  tenantsNames: string;
  unitsNames: string;
  totalAmount: number;
  amounts: CustomValue[];
  statementDetail: S3Object | undefined;
  statementReceipt: S3Object | undefined;
  isSelected: boolean;
  actionMenu: (e: React.MouseEvent, id: string, file: S3Object) => void;
}

const TenantStatementsArchivedRow: React.FC<TenantStatementsArchivedRowProps> = ({
  id,
  periodFrom,
  periodTo,
  tenantsNames,
  unitsNames,
  source,
  sentDate,
  amounts,
  statementDetail,
  statementReceipt,
  totalAmount,
  actionMenu,
  isSelected,
}) => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const [amountDescriptionAnchorEl, setAmountDescriptionAnchorEl] = useState<HTMLElement | null>(null);

  const closeAmountDescriptionPopper = () => {
    setAmountDescriptionAnchorEl(null);
  };

  const amountColor = totalAmount < 0 ? Colors.CARNATION_RED : Colors.TURQUOISE_BLUE;

  return (
    <TableRow
      hover
      onClick={() => {
        history.push({
          pathname: RouteDestination.VALIDATE_TENANT_STATEMENT,
          state: { statementId: id },
        });
      }}
      aria-checked={isSelected}
      tabIndex={-1}
      key={id}
      selected={isSelected}
      style={{ paddingTop: 10, cursor: 'pointer' }}
    >
      <CustomTableCell scope="row" padding="none" style={{ paddingLeft: 20 }}>
        <Typography style={{ fontSize: 14, color: Colors.SLATE_GREY, display: 'inline' }}>
          {formatStandardizedUTCDate(new Date(periodFrom), 'dd MMMM yyyy', { locale: dateLocaleMap[language] })}
        </Typography>
        <Typography
          style={{ fontSize: 14, color: Colors.SLATE_GREY, display: 'inline', marginLeft: 5, marginRight: 5 }}
        >
          {'\u2192'}
        </Typography>
        <Typography style={{ fontSize: 14, color: Colors.SLATE_GREY, display: 'inline', textAlign: 'right' }}>
          {formatStandardizedUTCDate(new Date(periodTo), 'dd MMMM yyyy', { locale: dateLocaleMap[language] })}
        </Typography>
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14 }}>
          {format(new Date(sentDate!), 'dd MMMM yyyy', { locale: dateLocaleMap[language] })}
        </Typography>
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        <Typography style={{ color: Colors.CLASSICAL_BLACK, fontSize: 14, fontWeight: 600 }}>{tenantsNames}</Typography>
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        <Typography style={{ color: Colors.CLASSICAL_BLACK, fontSize: 14 }}>{unitsNames}</Typography>
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14, lineHeight: '15px' }}>
          {source === StatementSource.AUTOMATIC
            ? formatMessage({ id: 'settings.communications.automatic' })
            : formatMessage({ id: 'settings.communications.manual' })}
        </Typography>
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        {!isNil(totalAmount) && (
          <Grid
            style={{
              borderRadius: 4,
              border: `1px solid ${amountColor}`,
              color: amountColor,
              fontWeight: 'bold',
              height: 30,
              marginRight: 20,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: '100px',
              overflow: 'hidden',
            }}
            onMouseEnter={(e) => {
              setAmountDescriptionAnchorEl(e.currentTarget);
            }}
            onMouseLeave={closeAmountDescriptionPopper}
          >
            {formatNumber(totalAmount, language, { style: 'currency', currency: 'EUR' })}
          </Grid>
        )}
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 20, alignItems: 'center' }}>
          <Tooltip title={formatMessage({ id: 'accounting.statement.archivedSection.fileDetailTooltip' })}>
            <DocumentIconButton
              disabled={!statementDetail}
              onClick={(e) => {
                e.stopPropagation();
                if (statementDetail) actionMenu(e, statementDetail.id, statementDetail);
              }}
            />
          </Tooltip>
          <Tooltip title={formatMessage({ id: 'accounting.statement.archivedSection.receiptDetailTooltip' })}>
            <DocumentIconButton
              disabled={!statementReceipt}
              onClick={(e) => {
                e.stopPropagation();
                if (statementReceipt) actionMenu(e, statementReceipt.id, statementReceipt);
              }}
              Icon={ReceiptIcon}
            />
          </Tooltip>
        </div>
      </CustomTableCell>
      {amountDescriptionAnchorEl && (
        <TenantStatementsToValidateAmountPopper
          statementAmounts={amounts}
          anchorEl={amountDescriptionAnchorEl}
          open={!isNil(amountDescriptionAnchorEl)}
          handleClose={closeAmountDescriptionPopper}
        />
      )}
    </TableRow>
  );
};

export default TenantStatementsArchivedRow;
