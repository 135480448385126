import { Collapse, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { FormikProps, useFormikContext } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { UnitType, Colors } from '@rentguru/commons-utils';
import { AddEditAgencyRateValues } from '../AddEditAgencyRateForm';
import CustomCheckBox from 'src/components/ui/CustomCheckBox';
import {
  handleCheckedAllItemsInList,
  handleCheckedItemsInList,
  isAllItemCheckedInList,
  isItemCheckedInList,
} from './utils';
import { Warning } from '@material-ui/icons';

const AgencyRateUnitTypeField = () => {
  const { values, setFieldValue, errors }: FormikProps<AddEditAgencyRateValues> = useFormikContext();
  const { formatMessage } = useIntl();

  const allUnitTypes = Object.keys(UnitType);

  return (
    <>
      <Typography
        style={{
          fontSize: 14,
          fontWeight: 'bold',
          fontStyle: 'normal',
          letterSpacing: 0,
          color: Colors.CLASSICAL_BLACK,
        }}
      >
        {formatMessage({ id: 'agencyRates.detail.unitType' })}
      </Typography>
      <Grid container>
        {Object.keys(UnitType).map((element) => {
          // If this unit type is not in selectable unit type for new creation (for owner), disable it.
          const disableUnitTypeChoice = !values.selectableUnitType.some((unitType) => unitType === element);
          return (
            <Grid item xs={6} key={element}>
              <FormControlLabel
                control={
                  <CustomCheckBox
                    isChecked={isItemCheckedInList('unitType', values, element)}
                    onCheck={(_e) => {
                      handleCheckedItemsInList(setFieldValue, 'unitType', values, element);
                    }}
                    secondary
                    name="unitType"
                    disabled={disableUnitTypeChoice}
                  />
                }
                label={formatMessage({ id: `enums.UnitType.${element}` })}
              />
            </Grid>
          );
        })}
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <CustomCheckBox
                isChecked={isAllItemCheckedInList('unitType', values, UnitType)}
                onCheck={(_e, checked) => {
                  handleCheckedAllItemsInList(setFieldValue, 'unitType', UnitType, checked);
                }}
                secondary
                name="unitType"
                disabled={allUnitTypes.length !== values.selectableUnitType.length}
              />
            }
            label={formatMessage({ id: `agencyRates.detail.unitTypeAll` })}
          />
        </Grid>
      </Grid>
      <Collapse in={Boolean(errors.unitType)}>
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Warning style={{ color: Colors.SUN_YELLOW, marginRight: 10 }} />
          <Typography style={{ color: Colors.SUN_YELLOW }}>{errors.unitType}</Typography>
        </div>
      </Collapse>
    </>
  );
};

export default AgencyRateUnitTypeField;
