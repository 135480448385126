import { isEmpty, isNil } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { FilterEntity, LeasesDraftsFilters, useFilters } from 'src/hooks/FiltersContext';
import { Lease } from '@rentguru/commons-utils';
import { FieldFilterSelector, SelectorChildrenProps } from '../../ui/FieldFilterSelector';
import { LeasesTableProps } from '../Leases';
import FieldFilterResetButton from '../../ui/FieldFilterResetButton';
import LeasesFiltersCommons from './LeasesFiltersCommons';

const LeasesFiltersDrafts: React.FC<LeasesTableProps> = ({ leasesDrafts }) => {
  const { formatMessage } = useIntl();

  const {
    leasesDraftsFilters: { filters },
    updateFilters,
    resetFilters,
  } = useFilters();

  const handleFilterChange = (
    selectedItems: SelectorChildrenProps[],
    filterName: string,
    fieldName: string,
    menuClosed: boolean
  ) => {
    if (menuClosed) {
      return; // Don't set filter again
    }

    const filter = { name: filterName, field: fieldName, items: selectedItems.map((i) => i.value) };
    let newFilters = filters;

    const index = newFilters.findIndex((f) => f.name === filterName);
    if (index === -1) {
      newFilters = [...newFilters, filter];
    } else {
      newFilters = [...newFilters.filter((f) => f.name !== filterName), filter];
    }
    updateFilters<LeasesDraftsFilters>(FilterEntity.LEASES_DRAFTS, {
      filters: newFilters,
    });
  };
  // Status
  const statusNames = leasesDrafts
    .reduce((currentList: string[], currentObject: Lease) => {
      if (!isNil(currentObject.status) && !currentList.includes(currentObject.status)) {
        currentList.push(currentObject.status);
      }
      return currentList;
    }, [])
    .map((status) => ({ value: status, label: formatMessage({ id: `enums.LeaseStatus.${status}` }) }));

  // Selected?
  const initialStatusSelectedItems = filters.filter((f) => f.name === 'status');
  const initialStatusSelectedItemsOrNull = isEmpty(initialStatusSelectedItems)
    ? null
    : initialStatusSelectedItems[0].items.map((i) => ({ value: i }));

  return (
    <div
      style={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 15,
        marginRight: 24,
      }}
    >
      <LeasesFiltersCommons filters={filters} handleFilterChange={handleFilterChange} leases={leasesDrafts} />
      <FieldFilterSelector
        label={formatMessage({ id: 'rentalUnit.columnHeader.status' })}
        multipleSelection
        showResetButton
        showSearchField={false}
        filterName="status"
        fieldName="status"
        textReset={formatMessage({ id: 'reinitFilter' })}
        textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
        textSearchField={formatMessage({ id: 'search' })}
        textSelectAll={formatMessage({ id: 'selectAll' })}
        onChange={handleFilterChange}
        initialSelectedItems={initialStatusSelectedItemsOrNull}
      >
        {statusNames}
      </FieldFilterSelector>
      <FieldFilterResetButton resetFilterValues={() => resetFilters(FilterEntity.LEASES_DRAFTS)} />
    </div>
  );
};

export default LeasesFiltersDrafts;
