import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import isNil from 'lodash/isNil';
import { Grid, TableCell, TableRow, Tooltip, Typography } from '@material-ui/core';
import {
  Colors,
  getTenantsName,
  LeaseInventory,
  LeaseStatus,
  SignatureDocumentStatus,
  LeaseAction,
  isNilOrEmpty,
  LeaseInventoryType,
} from '@rentguru/commons-utils';

import { dateLocaleMap, dateLocaleMapType, useLocale } from 'src/i18n/IntlProviderWrapper';
import { useUser } from 'src/hooks/UserContext';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { useSignatureDocuments } from 'src/hooks/SignatureDocumentContext';
import { LeaseExtended, useLeases } from 'src/hooks/LeasesContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { ReactComponent as CalendarIcon } from 'src/icons/calendar.svg';
import { ReactComponent as DeleteIcon } from 'src/icons/delete.svg';
import { ReactComponent as ExternalIcon } from 'src/icons/external.svg';
import { RouteDestination } from 'src/components/Routes/Routes';
import { ROW_HEIGHT } from 'src/components/Dashboard/DashboardTodos/DashboardLeaseOperations';
import { fromStatusToColor } from 'src/components/Leases/Details/LeaseDetail';
import { CustomIconButton, useOverflowTooltipStyles } from '@up2rent/ui';
import { stopPropagation } from 'src/components/Dashboard/Dashboard';
import LeaseSignaturePopper from 'src/components/Dashboard/DashboardTodos/DashboardDialogs/LeaseSignaturePopper';
import LeaseMainUnitColumn from 'src/components/Leases/Tables/LeaseMainUnitColumn';

interface LeaseOperationRowProps {
  entity: LeaseExtended | LeaseInventory;
  type: 'lease' | 'inventory';
  setLeaseAction: (action: { lease: LeaseExtended; action?: LeaseAction }) => void;
}

const LeaseOperationRow: React.FC<LeaseOperationRowProps> = ({ entity, type, setLeaseAction }) => {
  const { leasesDetailsWrite, leasesSignatureWrite } = usePermissions();
  const { formatMessage } = useIntl();
  const { getUnit } = useUnits();
  const { memberId, ownerId, isOwner } = useUser();
  const { getLease } = useLeases();
  const { getSignatureDocumentsForEntity } = useSignatureDocuments();
  const { language } = useLocale();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [leaseSignatureDescriptionAnchorEl, setLeaseSignatureDescriptionAnchorEl] = useState<HTMLElement | null>(null);
  const [unitListAnchorEl, setUnitListAnchorEl] = useState<HTMLElement | null>(null);

  const closeLeaseSignatureDescriptionPopper = () => {
    setLeaseSignatureDescriptionAnchorEl(null);
  };

  const closeUnitListPopper = () => {
    setUnitListAnchorEl(null);
  };

  const history = useHistory();
  const classes = useOverflowTooltipStyles();

  const lease = type === 'lease' ? (entity as LeaseExtended) : getLease((entity as LeaseInventory).leaseId);
  if (isNil(lease)) {
    return (
      <TableRow style={{ minHeight: ROW_HEIGHT, height: ROW_HEIGHT }}>
        <TableCell>
          <Typography>{formatMessage({ id: 'loading' })}</Typography>
        </TableCell>
      </TableRow>
    );
  }
  const leaseUnit = getUnit(lease.units!.find((unit) => unit.mainUnit)!.unit!.id);
  if (isNil(leaseUnit)) {
    return (
      <TableRow style={{ minHeight: ROW_HEIGHT, height: ROW_HEIGHT }}>
        <TableCell>
          <Typography>{formatMessage({ id: 'loading' })}</Typography>
        </TableCell>
      </TableRow>
    );
  }

  const destination = `${RouteDestination.LEASES}/detail/${lease.id}`;
  const statusColor = fromStatusToColor(entity.status as LeaseStatus);
  let timeframe: string | null = null;
  if (entity.status === LeaseStatus.OutForSignature) {
    timeframe = formatDistance(new Date(lease.startDate), new Date(), {
      addSuffix: true,
      locale: (dateLocaleMap as dateLocaleMapType)[language],
    });
  } else if (entity.status === LeaseStatus.Active && type === 'lease') {
    timeframe = formatDistance(new Date(lease.endDate), new Date(), {
      addSuffix: true,
      locale: (dateLocaleMap as dateLocaleMapType)[language],
    });
  }
  const currentContactId = isOwner ? ownerId : memberId;
  const currentLeaseContact = lease.contacts!.find((contact) => contact.contact!.id === currentContactId);
  const tenantText = getTenantsName(lease.tenants);
  const signatureDocuments = getSignatureDocumentsForEntity(
    type === 'lease' ? 'Lease' : 'LeaseInventory',
    entity.id,
    SignatureDocumentStatus.PENDING
  );

  const signatureResult = signatureDocuments?.[0]?.signatureResults.find(
    (result) => result.contactId === currentLeaseContact?.contact?.id
  );

  // !isNil(signatureResult) condition is to make sure the user has the right to sign the document
  // signatureResult.signatureLink && !signatureResult.signed confirms the document hasn't been signed yet
  const isDocumentInSignableState =
    entity.status === LeaseStatus.OutForSignature &&
    leasesSignatureWrite &&
    !isNil(signatureResult) &&
    signatureResult.signatureLink &&
    !signatureResult.signed;

  const onSignClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    window.open(signatureResult ? (signatureResult.signatureLink as string) : '#');
  };

  return (
    <TableRow
      hover
      style={{ cursor: 'pointer', textDecoration: 'none', minHeight: ROW_HEIGHT, height: ROW_HEIGHT }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        history.push({
          pathname: destination,
          state: { goBackUrl: history.location },
        });
      }}
    >
      <TableCell align="left" padding="none" style={{ paddingLeft: 20 }}>
        <Typography style={{ color: Colors.CLASSICAL_BLACK, fontSize: 14, fontWeight: 700 }}>{lease.name}</Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        <Grid style={{ display: 'flex' }} onClick={isDocumentInSignableState ? onSignClick : () => {}}>
          <Typography
            style={{ color: statusColor, fontSize: 14, fontWeight: 700 }}
            onMouseEnter={(e) => setLeaseSignatureDescriptionAnchorEl(e.currentTarget)}
            onMouseLeave={closeLeaseSignatureDescriptionPopper}
          >
            {entity.status === LeaseStatus.OutForSignature
              ? formatMessage({ id: 'lease.detail.action.toBeSigned' })
              : entity.status === LeaseStatus.Active
              ? formatMessage({ id: 'lease.detail.action.endingSoon' })
              : formatMessage({ id: `enums.LeaseStatus.${entity.status}` })}
          </Typography>
          {isDocumentInSignableState && (
            <Tooltip title={formatMessage({ id: 'lease.detail.action.signOnline' })} placement="top">
              <CustomIconButton
                onMouseDown={stopPropagation}
                size="small"
                Icon={ExternalIcon}
                iconStyle={{ fill: Colors.DODGER_BLUE, width: 20, height: 16 }}
              />
            </Tooltip>
          )}
        </Grid>
      </TableCell>
      <TableCell
        align="left"
        padding="none"
        onMouseEnter={(e) => setUnitListAnchorEl(e.currentTarget)}
        onMouseLeave={closeUnitListPopper}
      >
        <LeaseMainUnitColumn
          unitLeases={lease.units ?? []}
          anchorEl={unitListAnchorEl}
          open={!isNil(unitListAnchorEl)}
          handleClose={closeUnitListPopper}
          pictoContainerStyle={{ height: 20 }}
        />
      </TableCell>
      <TableCell align="left" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14, fontWeight: 500 }}>
          {type === 'lease'
            ? formatMessage({ id: `enums.LeaseType.${lease.type}` })
            : formatMessage({
                id: `leaseInventory.${
                  (entity as LeaseInventory).inventoryType === LeaseInventoryType.FIXTURES_ENTRANCE
                    ? 'entranceInventory'
                    : 'exitInventory'
                }`,
              })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        <Tooltip
          open={tooltipOpen}
          onClose={() => setTooltipOpen(false)}
          onOpen={() => {
            if (lease.tenants.length > 1) {
              setTooltipOpen(true);
            }
          }}
          title={tenantText}
          placement="top"
          classes={{ tooltipPlacementTop: classes.tooltipPlacement }}
        >
          <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14, fontWeight: 500 }}>
            {lease.tenants.length > 1
              ? `${lease.tenants.length} ${formatMessage({ id: 'lease.detail.general.tenants' })}`
              : tenantText}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14, fontWeight: 500 }}>{timeframe}</Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        {type === 'lease' && entity.status === LeaseStatus.Active && leasesDetailsWrite && (
          <Tooltip title={formatMessage({ id: 'lease.detail.action.extend' })} placement="top">
            <CustomIconButton
              onMouseDown={stopPropagation}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setLeaseAction({ lease, action: LeaseAction.EXTEND_LEASE });
              }}
              size="small"
              Icon={CalendarIcon}
              iconStyle={{ fill: Colors.DODGER_BLUE, width: 16 }}
            />
          </Tooltip>
        )}
      </TableCell>
      <TableCell align="left" padding="none">
        {type === 'lease' && entity.status === LeaseStatus.Active && leasesDetailsWrite && (
          <Tooltip title={formatMessage({ id: 'ignore' })} placement="top">
            <CustomIconButton
              onMouseDown={stopPropagation}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                setLeaseAction({ lease }); // No action = ignore
              }}
              size="small"
              Icon={DeleteIcon}
              iconStyle={{ fill: Colors.BLUEY, width: 16 }}
            />
          </Tooltip>
        )}
      </TableCell>
      {!isNilOrEmpty(signatureDocuments) && (
        <LeaseSignaturePopper
          signatureDocument={signatureDocuments[0]}
          anchorEl={leaseSignatureDescriptionAnchorEl}
          open={!isNil(leaseSignatureDescriptionAnchorEl)}
          handleClose={closeLeaseSignatureDescriptionPopper}
        />
      )}
    </TableRow>
  );
};

export default LeaseOperationRow;
