/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-undef */
import { isNil } from 'lodash';
import React from 'react';
import { Cluster, Marker } from 'react-mapbox-gl';
import { UnitTypeRepartition } from './BuildingsMap';
import { ReactComponent as Pointer } from '../../../icons/location.svg';
import { ReactComponent as ResidentialPointer } from '../../../icons/marker-house.svg';
import { ReactComponent as StudentPointer } from '../../../icons/marker-edu.svg';
import { ReactComponent as ParkingPointer } from '../../../icons/marker-parking.svg';
import { ReactComponent as CommercialPointer } from '../../../icons/marker-store.svg';
import { ReactComponent as OfficePointer } from '../../../icons/marker-house-copy-2.svg';
import { ReactComponent as Oval } from '../../../icons/oval.svg';
import { Typography } from '@material-ui/core';
import { Colors, Unit, Building, UnitType } from '@rentguru/commons-utils';

interface MapClusterProps {
  buildings: Building[];
  displayPopup: (building: Building, unitTypeRepartition: UnitTypeRepartition) => void;
}

const MapCluster: React.FC<MapClusterProps> = ({ buildings, displayPopup }) => {
  const clusterMarker = (coordinates: number[], pointCount: number) => (
    <Marker coordinates={coordinates} anchor="center" key={`${coordinates.toString()}-${pointCount}`}>
      <>
        <Oval />
        <Typography
          style={{
            transform: 'translateY(-39px)',
            fontFamily: 'Mulish',
            fontSize: 14,
            fontWeight: 'bold',
            color: Colors.CARNATION_RED,
          }}
        >
          {pointCount.toString()}
        </Typography>
      </>
    </Marker>
  );

  return (
    <Cluster ClusterMarkerFactory={clusterMarker} zoomOnClick={true} zoomOnClickPadding={100}>
      {buildings.reduce((acc: JSX.Element[], building) => {
        if (isNil(building.address) || isNil(building.address.longitude) || isNil(building.address.latitude)) {
          return acc;
        }
        const longitude = building.address.longitude;
        const latitude = building.address.latitude;
        const unitTypeRepartition = building.units
          ? building.units.reduce((acc: UnitTypeRepartition, unit: Unit) => {
              if (unit.type === UnitType.RESIDENTIAL) {
                acc.residential = acc.residential ? acc.residential + 1 : 1;
              } else if (unit.type === UnitType.PARKING) {
                acc.parking = acc.parking ? acc.parking + 1 : 1;
              } else if (unit.type === UnitType.OFFICE) {
                acc.office = acc.office ? acc.office + 1 : 1;
              } else if (unit.type === UnitType.STUDENT) {
                acc.student = acc.student ? acc.student + 1 : 1;
              } else if (unit.type === UnitType.COMMERCIAL) {
                acc.commerce = acc.commerce ? acc.commerce + 1 : 1;
              } else {
                acc.other = acc.other ? acc.other + 1 : 1;
              }
              return acc;
            }, {})
          : {};
        const unitTypes = Object.keys(unitTypeRepartition);
        const pointer =
          unitTypes.length === 1 ? (
            unitTypes[0] === 'residential' ? (
              <ResidentialPointer />
            ) : unitTypes[0] === 'parking' ? (
              <ParkingPointer />
            ) : unitTypes[0] === 'office' ? (
              <OfficePointer />
            ) : unitTypes[0] === 'student' ? (
              <StudentPointer />
            ) : unitTypes[0] === 'commerce' ? (
              <CommercialPointer />
            ) : (
              <Pointer />
            )
          ) : (
            <Pointer />
          );
        acc.push(
          <Marker
            coordinates={[longitude, latitude]}
            anchor="bottom"
            key={building.id}
            onClick={() => displayPopup(building, unitTypeRepartition)}
            offset={[0, 15]}
          >
            {pointer}
          </Marker>
        );

        return acc;
      }, [])}
    </Cluster>
  );
};

export default MapCluster;
