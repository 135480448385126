import React from 'react';
import { useIntl } from 'react-intl';
import { formatDistance, parseISO } from 'date-fns';
import { Chip, Collapse, Grid, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  Colors,
  integrationPrices,
  IntegrationPrice,
  IntegrationName,
  IntegrationStatus,
  IntegrationPriceType,
} from '@rentguru/commons-utils';
import { dateLocaleMapType, useLocale, dateLocaleMap } from 'src/i18n/IntlProviderWrapper';
import { useIntegrations } from 'src/hooks/IntegrationsContext';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { ReactComponent as SettingsIcon } from 'src/icons/cog.svg';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import IconnedRemark from 'src/components/ui/IconnedRemark';
import { CustomIconButton } from '@up2rent/ui';
import IntegrationLogo from './IntegrationLogo';

export const useIntegrationStyles = makeStyles(() => ({
  integrationName: {
    fontFamily: 'Mulish',
    fontSize: 16,
    fontWeight: 'bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: Colors.CLASSICAL_BLACK,
  },
}));

const useStyles = makeStyles(() => ({
  box: {
    width: 580,
    minHeight: 120,
    borderRadius: 10,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: Colors.SILVER,
    padding: 15,
    marginBottom: 15,
  },
  integrationDescription: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: Colors.CHARCOAL_GREY,
    textAlign: 'left',
  },
  priceChip: {
    marginLeft: 10,
    color: Colors.LIGHT_BLUE_GREY,
    fontFamily: 'Mulish',
    fontSize: 14,
    lineHeight: 18,
    fontWeight: 600,
  },
  errorText: {
    fontFamily: 'Mulish',
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: Colors.SLATE_GREY,
    textAlign: 'left',
  },
}));

export const getDisplayPrice = (price: number, frequency: string) => `${price}€/${frequency}`;

interface IntegrationBoxProps {
  id: string;
  onClick: (id: string, name: IntegrationName) => void;
}

const IntegrationBox: React.FC<IntegrationBoxProps> = ({ id, onClick }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const integrationClasses = useIntegrationStyles();
  const { language } = useLocale();
  const { settingsAutomationWrite } = usePermissions();
  const { getIntegration, loading, error } = useIntegrations();
  const integration = getIntegration(id);

  if (loading || error) {
    return null;
  }

  const name = (integration?.name as IntegrationName) ?? IntegrationName.PONTO;
  const integrationName = formatMessage({ id: `settings.integrations.IntegrationName.${name}` });
  const hasError = integration?.status === IntegrationStatus.ERROR;
  const errorMessage = integration?.error ?? '';
  const alreadyRun = Boolean(integration?.lastRun);
  const lastSyncDistance = alreadyRun
    ? formatDistance(parseISO(integration!.lastRun!), new Date(), {
        addSuffix: true,
        locale: (dateLocaleMap as dateLocaleMapType)[language],
      })
    : '';

  const integrationPrice = integrationPrices.find((price: IntegrationPrice) => price.name === name);
  const isSubscription = Boolean(integrationPrice?.type === IntegrationPriceType.SUBSCRIPTION);
  return (
    <Grid container className={classes.box}>
      <Grid
        item
        xs={3}
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <IntegrationLogo name={name} />
      </Grid>
      <Grid item xs={9}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}
        >
          <Grid container style={{ marginBottom: 15 }} alignItems="flex-start">
            <Grid item xs={10} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Typography className={integrationClasses.integrationName}>{integrationName}</Typography>
              {alreadyRun && (
                <Tooltip
                  title={`${formatMessage(
                    { id: 'settings.integrations.lastSync' },
                    { name: integrationName }
                  )} ${lastSyncDistance}${
                    hasError ? ` ${formatMessage({ id: 'settings.integrations.lastSyncError' })}` : ''
                  }`}
                  placement="bottom"
                >
                  <Grid>
                    <InfoSvg style={{ marginLeft: 10, fill: hasError ? Colors.CARNATION_RED : Colors.SILVER }} />
                  </Grid>
                </Tooltip>
              )}
              {isSubscription && (
                <Chip
                  label={getDisplayPrice(
                    integrationPrice!.price!,
                    formatMessage({ id: `settings.integrations.frequency.${integrationPrice!.frequency}` })
                  )}
                  className={classes.priceChip}
                />
              )}
            </Grid>
            <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {settingsAutomationWrite && (
                <CustomIconButton
                  onClick={() => onClick(id, name)}
                  size="small"
                  Icon={SettingsIcon}
                  iconStyle={{ fill: Colors.SLATE_GREY, width: 20, height: 20 }}
                />
              )}
            </Grid>
          </Grid>
          <Typography className={classes.integrationDescription}>
            {formatMessage({ id: `settings.integrations.description.${name}` })}
          </Typography>
          <Collapse in={hasError}>
            <IconnedRemark
              Icon={InfoSvg}
              noFixWidth
              style={{ marginRight: 30, marginTop: 10, marginBottom: 10, marginLeft: 0 }}
              message={<Typography className={classes.errorText}>{errorMessage}</Typography>}
            />
          </Collapse>
        </div>
      </Grid>
    </Grid>
  );
};

export default IntegrationBox;
