import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { EndLeaseFormValues, iconnedRemarkMargins, useEndLeaseStyles } from './EndLeaseForm';
import { addDays, differenceInYears, isBefore, isSameDay } from 'date-fns';
import EndLeaseIndemnityField, { IndemnityFieldProps } from './EndLeaseIndemnityField';
import EndLeaseCommentToTenantField from './EndLeaseCommentToTenantField';
import {
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  RadioGroup,
  Tooltip,
  Typography,
} from '@material-ui/core';
import StyledRadio from 'src/components/ui/StyledRadio';
import {
  Colors,
  getMainUnit,
  LeaseExtended,
  CommunicationChannel,
  LeaseActionEndInitiator,
  LeaseActionEndReason,
  Language,
  TemplateType,
  TemplateSubType,
  LeaseActionHistory,
  LeaseAction,
  Unit,
  LeaseType,
  Template,
  isDateValid,
} from '@rentguru/commons-utils';
import FormikDatePicker from 'src/components/ui/FormikDatePicker';
import { CustomizedSelect } from 'src/components/ui/CustomizedSelect';
import { ReactComponent as InfoSvg } from '../../../../icons/info.svg';
import IconnedRemark from 'src/components/ui/IconnedRemark';
import FormAlertBox from 'src/components/ui/FormAlertBox';
import { ActionButtonSecond, actionButtonSecondStyles } from '@up2rent/ui';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useTemplates } from 'src/hooks/TemplatesContext';
import { Skeleton } from '@material-ui/lab';
import TemplatePreviewFilledDialog from 'src/components/Templates/Dialogs/TemplatePreviewFilledDialog';
import { useUsers } from 'src/hooks/UsersContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { useBuildings } from 'src/hooks/BuildingsContext';
import TextDetailEditable from 'src/components/ui/TextDetailEditable';
import EndLeaseFileField from './EndLeaseFileField';
import { useContacts } from 'src/hooks/ContactsContext';
import {
  getNoticeDateForLessorEnd,
  isAddressConfiguredForRegisteredLetter,
  isInFlanders,
  isLongTermsOrCommercialLease,
} from './utils';

const EndLeaseLessorFields = ({
  leaseType,
  leaseStartDate,
  leaseEndDate,
  region,
  language,
  lease,
  ...props
}: IndemnityFieldProps & { leaseStartDate: Date; leaseEndDate: Date; language: Language; lease: LeaseExtended }) => {
  const actionButtonClasses = actionButtonSecondStyles();

  const { formatMessage } = useIntl();
  const { values, setFieldValue, setValues, errors, touched, handleBlur } = useFormikContext<EndLeaseFormValues>();
  const classes = useEndLeaseStyles();
  const [openPreview, setOpenPreview] = useState<boolean>(false);

  const { templatesLoading, getTemplateOrDefault } = useTemplates();
  const { getClientContact } = useUsers();
  const { getUnit, unitsLoading } = useUnits();
  const { getBuilding, buildingsLoading } = useBuildings();
  const { getContact, contactsLoading } = useContacts();

  if (templatesLoading || unitsLoading || buildingsLoading || contactsLoading) {
    return <Skeleton />;
  }

  const newEndDate = new Date(values.endDate);
  const newEndDateIsValid = isDateValid(newEndDate);
  const isEndSameDay = isSameDay(leaseEndDate, newEndDate);
  const isStudent = leaseType === LeaseType.STUDENT;
  const isShortTerms = leaseType === LeaseType.MAIN_RESIDENCE_SHORT;
  const isLongTermsOrCommercial = isLongTermsOrCommercialLease(leaseType);
  const isFlanders = isInFlanders(region);
  const clientContact = getClientContact();

  const noticeDateForLessorEnd = getNoticeDateForLessorEnd(newEndDate, leaseType);
  const areAddressesWellConfigured = isAddressConfiguredForRegisteredLetter(lease, clientContact, getContact);
  const isNoticeTooLate = isBefore(noticeDateForLessorEnd, new Date());
  const theDayAfterTheNewEndDate = addDays(newEndDate, 1);

  const shouldShowShortTermsBadReasonWarning =
    !isEndSameDay &&
    isShortTerms &&
    (values.lessorEndNotice.endReason !== LeaseActionEndReason.OWN_USE ||
      differenceInYears(theDayAfterTheNewEndDate, leaseStartDate) < 1);
  const shouldShowLongTermFlandersWarning =
    !isEndSameDay &&
    isLongTermsOrCommercial &&
    isFlanders &&
    values.lessorEndNotice.endReason === LeaseActionEndReason.OWN_USE &&
    differenceInYears(theDayAfterTheNewEndDate, leaseStartDate) < 3;
  const needsToBeJustified = !isEndSameDay && (isLongTermsOrCommercial || isShortTerms);

  const maxNoticeEndDate = getNoticeDateForLessorEnd(newEndDate, leaseType);

  // Get the right registered letter template
  const registeredLetterTemplate =
    values.template ?? getTemplateOrDefault(TemplateType.LETTER, TemplateSubType.LEASE_TERMINATION, language);
  const onClose = () => setOpenPreview(false);
  const mainUnit = getMainUnit(lease.units ?? []);
  const completeUnit = getUnit(mainUnit?.id ?? '');
  const completeBuilding = getBuilding(completeUnit?.building?.id ?? '');
  const unitLeasesWithCompleteUnit =
    lease.units?.map((unitLease) =>
      unitLease?.unit?.id === completeUnit?.id
        ? { ...unitLease, unit: { ...completeUnit, building: completeBuilding } as Unit }
        : unitLease
    ) ?? [];
  const units = unitLeasesWithCompleteUnit.map((unitLease) => unitLease.unit);
  const leaseWithNewEnd = { ...lease, units: unitLeasesWithCompleteUnit, endDate: values.endDate };
  const draftLeaseActionHistory: LeaseActionHistory = {
    action: LeaseAction.END_LEASE,
    clientId: '',
    contactId: '',
    historyDetails: [],
    id: '',
    readId: '',
    comment: values.comment,
    endReason: values.lessorEndNotice.endReason,
    endReasonOwnUseName: values.lessorEndNotice.endReasonOwnUseName,
    endReasonOwnUseRelation: values.lessorEndNotice.endReasonOwnUseRelation,
    initiator: (values.initiator as LeaseActionEndInitiator) ?? LeaseActionEndInitiator.LESSOR,
    lease: leaseWithNewEnd,
    leaseId: leaseWithNewEnd.id,
    totalAmount: values.amountDue.totalAmount,
    totalAmountLabel: values.amountDue.totalAmountLabel,
  };

  return (
    <>
      <Collapse in={needsToBeJustified}>
        <Typography
          className={classes.informationFillInTitle}
          style={{
            marginTop: 20,
          }}
        >
          {formatMessage({ id: `lease.endExtendLease.reason` })}
        </Typography>
        <CustomizedSelect
          label={formatMessage({ id: 'lease.endExtendLease.reason' })}
          fieldName="lessorEndNotice.endReason"
          value={values.lessorEndNotice.endReason}
          error={Boolean(errors.lessorEndNotice?.endReason && touched.lessorEndNotice?.endReason)}
          FormControlProps={{ style: { width: 540, maxWidth: 'none', marginRight: 20 } }}
          SelectProps={{
            onChange: (event) => {
              const type = event.target.value as string;
              setFieldValue('lessorEndNotice.endReason', type);
            },
            onBlur: handleBlur,
          }}
        >
          {Object.keys(LeaseActionEndReason).map((endReason) => (
            <MenuItem value={endReason} key={endReason}>
              {formatMessage({ id: `lease.endExtendLease.LeaseActionEndReason.${endReason}` })}
            </MenuItem>
          ))}
        </CustomizedSelect>
        <Collapse in={values.lessorEndNotice.endReason === LeaseActionEndReason.OWN_USE}>
          <TextDetailEditable
            editMode
            title={formatMessage({
              id: `lease.endExtendLease.endReasonOwnUseName`,
            })}
            name="lessorEndNotice.endReasonOwnUseName"
            style={{ width: 260, maxWidth: 'none' }}
          />
          <TextDetailEditable
            editMode
            title={formatMessage({
              id: `lease.endExtendLease.endReasonOwnUseRelation`,
            })}
            name="lessorEndNotice.endReasonOwnUseRelation"
            style={{ width: 260, maxWidth: 'none', marginLeft: 20 }}
          />
        </Collapse>
        <Collapse in={values.lessorEndNotice.endReason === LeaseActionEndReason.RENOVATION}>
          <EndLeaseFileField forRenovation={true} />
        </Collapse>
        {shouldShowShortTermsBadReasonWarning && (
          <div className={classes.alertBoxDiv}>
            <FormAlertBox
              removeLeftMargin
              messagesGridStyle={{ marginRight: 30, fontSize: 14 }}
              width={550}
              message1={formatMessage({ id: 'lease.endExtendLease.warningShortTermsEndByLessorBadReason' })}
            />
          </div>
        )}
        {shouldShowLongTermFlandersWarning && (
          <div className={classes.alertBoxDiv}>
            <FormAlertBox
              removeLeftMargin
              messagesGridStyle={{ marginRight: 30, fontSize: 14 }}
              width={550}
              message1={formatMessage({ id: 'lease.endExtendLease.warningLongTermsFlandersEndByLessor' })}
            />
          </div>
        )}
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
          <Typography className={classes.informationFillInTitle}>
            {formatMessage({ id: `lease.endExtendLease.compensation` })}
          </Typography>
          <Tooltip title={formatMessage({ id: 'lease.endExtendLease.compensationInfo' })} placement="right">
            <Grid>
              <InfoSvg style={{ marginLeft: 10, fill: Colors.SILVER }} />
            </Grid>
          </Tooltip>
        </div>
        <EndLeaseIndemnityField {...props} leaseType={leaseType} region={region} />
      </Collapse>

      <EndLeaseCommentToTenantField />

      <Collapse in={isEndSameDay || !isStudent}>
        <Typography
          className={classes.informationFillInTitle}
          style={{
            marginTop: 20,
          }}
        >
          {formatMessage({ id: `lease.endExtendLease.registeredLetterOptions` })}
        </Typography>
        <FormControl component="fieldset" style={{ paddingTop: 10, width: '100%' }}>
          <RadioGroup
            value={values.lessorEndNotice.responsible}
            onChange={(_e, value) => {
              const typedValue = value as 'LESSOR' | 'UP2RENT';
              setValues({
                ...values,
                lessorEndNotice: {
                  ...values.lessorEndNotice,
                  responsible: typedValue,
                  channel: CommunicationChannel.REGISTERED_LETTER,
                },
              });
            }}
          >
            <FormControlLabel
              value="LESSOR"
              control={<StyledRadio style={{ paddingTop: 2, paddingBottom: 2 }} />}
              label={
                <Typography className={classes.actionRadioText}>
                  {formatMessage({ id: `lease.endExtendLease.ownRegisteredLetter${isNoticeTooLate ? 'Past' : ''}` })}
                </Typography>
              }
            />
            <FormControlLabel
              value="UP2RENT"
              control={<StyledRadio style={{ paddingTop: 2, paddingBottom: 2 }} />}
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className={classes.actionRadioText}>
                    {formatMessage({ id: `lease.endExtendLease.sendRegisteredLetter` })}
                  </Typography>
                  {registeredLetterTemplate && (
                    <ActionButtonSecond
                      className={actionButtonClasses.buttonWithMargin}
                      startIcon={<VisibilityIcon />}
                      onClick={() => {
                        setOpenPreview(true);
                      }}
                      style={{ marginLeft: 10 }}
                      disabled={isNoticeTooLate || !areAddressesWellConfigured || !newEndDateIsValid}
                    >
                      {formatMessage({ id: 'lease.detail.action.preview' })}
                    </ActionButtonSecond>
                  )}
                  {(!areAddressesWellConfigured || !newEndDateIsValid) && (
                    <Tooltip
                      title={formatMessage({
                        id: `lease.endExtendLease.${
                          !areAddressesWellConfigured ? 'noAddressWarning' : 'invalidEndDateWarning'
                        }`,
                      })}
                      placement="top"
                    >
                      <Grid>
                        <InfoSvg style={{ fill: Colors.CARNATION_RED }} />
                      </Grid>
                    </Tooltip>
                  )}
                </div>
              }
              disabled={isNoticeTooLate || !areAddressesWellConfigured || !newEndDateIsValid}
            />
          </RadioGroup>
        </FormControl>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
          <Typography
            className={classes.infoMessageText}
            style={{
              marginRight: 10,
            }}
          >
            {formatMessage({ id: `lease.endExtendLease.on` })}
          </Typography>
          <FormikDatePicker
            name="lessorEndNotice.sendDate"
            label={formatMessage({ id: 'lease.endExtendLease.noticePeriod' })}
            value={values.lessorEndNotice.sendDate}
            style={{ width: 260, maxWidth: 260 }}
            error={Boolean(errors?.lessorEndNotice?.sendDate && touched?.lessorEndNotice?.sendDate)}
            views={['year', 'month', 'date']}
            minDate={new Date()}
            maxDate={maxNoticeEndDate}
          />
        </div>
      </Collapse>

      {values.lessorEndNotice.responsible !== 'UP2RENT' && (
        <IconnedRemark
          Icon={InfoSvg}
          noFixWidth
          style={iconnedRemarkMargins}
          message={
            <Typography className={classes.infoMessageText}>
              {formatMessage({ id: 'settings.addVariousOperation.confirmationEmail' })}
            </Typography>
          }
        />
      )}

      {registeredLetterTemplate && openPreview && (
        <TemplatePreviewFilledDialog
          open={openPreview}
          onClose={onClose}
          template={registeredLetterTemplate}
          data={{
            lease: leaseWithNewEnd,
            language: lease.language,
            client: clientContact,
            units,
            leaseActionHistory: draftLeaseActionHistory,
          }}
          setTemplate={(template: Template) => setValues((prev) => ({ ...prev, template }))}
        />
      )}
    </>
  );
};

export default EndLeaseLessorFields;
