import { Chip, Grid, Typography } from '@material-ui/core';
import { displayAddressInLanguage } from '@rentguru/commons-utils';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import TextDetail from 'src/components/ui/TextDetail';
import { useIntl } from 'react-intl';
import EditAddressDialog from 'src/components/ui/Dialogs/EditAddressDialog';
import { useState } from 'react';
import { useCommunications } from 'src/hooks/CommunicationsContext';
import { ReactComponent as EditIcon } from 'src/icons/edit.svg';

interface CommunicationDetailContentAddressProps {
  communicationId: string;
  editable: boolean;
}

const CommunicationDetailsContentAddress: React.FC<CommunicationDetailContentAddressProps> = ({
  communicationId,
  editable,
}) => {
  const { language } = useLocale();
  const { formatMessage } = useIntl();
  const [isEditAddressDialogOpen, setIsEditAddressDialogOpen] = useState<boolean>(false);
  const { getCommunication } = useCommunications();

  const communication = getCommunication(communicationId);
  const addressString = displayAddressInLanguage(communication?.address, language);

  return (
    <Grid>
      <TextDetail
        title={formatMessage({ id: `communications.detail.recipient.recipientAddress` })}
        style={{ paddingBottom: 12 }}
      />
      {editable ? (
        <Chip
          key={'addressChip'}
          variant="outlined"
          label={
            <Grid style={{ display: 'flex', alignItems: 'center' }}>
              <Typography style={{ fontSize: 14, marginRight: 5 }}>{addressString}</Typography>
              <EditIcon />
            </Grid>
          }
          clickable
          onClick={() => setIsEditAddressDialogOpen(true)}
        />
      ) : (
        <Typography style={{ fontSize: 14 }}>{addressString}</Typography>
      )}
      <EditAddressDialog
        open={isEditAddressDialogOpen}
        communication={communication!}
        onClose={() => setIsEditAddressDialogOpen(false)}
      />
    </Grid>
  );
};

export default CommunicationDetailsContentAddress;
