import { useUsers } from 'src/hooks/UsersContext';
import AccountTypeForm from './General/AccountTypeForm';
import CompanyContactForm from './General/CompanyContactForm';
import CompanyLogoForm from './General/CompanyLogoForm';

const GeneralSettings = () => {
  const { isAccountPrivate, loading } = useUsers();
  return (
    <>
      <CompanyLogoForm />
      {!loading && !isAccountPrivate() && <CompanyContactForm />}
      <AccountTypeForm />
    </>
  );
};

export default GeneralSettings;
