import { Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import TextFieldArea from 'src/components/ui/TextFieldArea';
import { EndLeaseFormValues, useEndLeaseStyles } from './EndLeaseForm';
import { useIntl } from 'react-intl';

const EndLeaseCommentToTenantField = () => {
  const { formatMessage } = useIntl();
  const { values } = useFormikContext<EndLeaseFormValues>();
  const classes = useEndLeaseStyles();

  return (
    <>
      <Typography
        className={classes.informationFillInTitle}
        style={{
          marginTop: 20,
        }}
      >
        {formatMessage({ id: `lease.endExtendLease.commentToTenant` })}
      </Typography>
      <TextFieldArea
        name="comment"
        rows={3}
        rowsMax={3}
        placeholder={formatMessage({
          id: `lease.endExtendLease.comment`,
        })}
        inputProps={{ style: { textAlign: 'left', bottom: 0, padding: 20 } }}
        className={classes.textField}
        value={values.comment}
        onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
      />
    </>
  );
};

export default EndLeaseCommentToTenantField;
