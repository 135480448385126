import { isEmpty, isNil } from 'lodash';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDashboardFilters } from '../../../hooks/DashboardFiltersContext';
import { BoxSwitchFiltersProps } from '../Filters/BoxSwitchFilters';
import RankingBox, { RankingBoxLayoutProps } from './RankingBox';
import {
  getTopElementsBasedOnProperty,
  formatNumber,
  TOP_UNITS_RENTAL_YIELD_BOX_KEY,
  UnitType,
} from '@rentguru/commons-utils';
import { useLocale } from '../../../i18n/IntlProviderWrapper';
import { useUnits } from '../../../hooks/UnitsContext';
import { endOfYear, startOfYear } from 'date-fns';
import { calculateGrossRentalYield } from '../DashboardMetrics/utils/DashboardKeyfiguresUtils';
import { useLeasePriceHistories } from 'src/hooks/LeasePriceHistoriesContext';
import { useValuations } from 'src/hooks/ValuationsContext';
import { Typography } from '@material-ui/core';
import { useLeaseVariousOperations } from 'src/hooks/LeaseVariousOperationsContext';

const DashboardRankingTopUnitsRentalYield: React.FC<RankingBoxLayoutProps> = ({ forceUpdateLayout }) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const {
    switchFilters: { topUnitsRentalYield: topUnitsFilter },
  } = useDashboardFilters();
  const { units, unitsLoading } = useUnits();
  const { leasePriceHistories, loading: leasesIndexationsLoading } = useLeasePriceHistories();
  const { discounts, loading: leaseVariousOperationsLoading } = useLeaseVariousOperations();
  const { getUnitValuations, getBuildingValuations, loading: valuationsLoading } = useValuations(true);

  const loading = unitsLoading || leasesIndexationsLoading || valuationsLoading || leaseVariousOperationsLoading;

  const switchs: BoxSwitchFiltersProps = {
    name: 'topUnitsRentalYield',
    filters: [
      {
        label: formatMessage({ id: 'dashboard.rankingFilters.all' }),
        value: 'all',
      },
      {
        label: formatMessage({ id: 'dashboard.rankingFilters.unitType' }),
        menuItems: Object.keys(UnitType).map((unitType) => ({
          label: formatMessage({ id: `enums.UnitType.${unitType}` }),
          value: unitType,
        })),
      },
    ],
  };

  const datas = useMemo(() => {
    // Based on Last 1 years
    const today = new Date();
    const start = startOfYear(today);
    const end = endOfYear(today);
    const timeWindow = { start, end };
    const unitsWithRentalYield = units.reduce((result, currentUnit) => {
      if (isNil(currentUnit.owners) || isEmpty(currentUnit.owners)) {
        return result;
      }
      if (!isNil(topUnitsFilter) && topUnitsFilter.value !== 'all') {
        if (topUnitsFilter.value !== currentUnit.type) {
          return result;
        }
      }
      const currentUnitLast5YearRentalYield = calculateGrossRentalYield(
        [currentUnit],
        [],
        timeWindow,
        getUnitValuations,
        getBuildingValuations,
        leasePriceHistories ?? [],
        discounts ?? []
      );

      result.push({ unit: currentUnit.name, rentalYield: currentUnitLast5YearRentalYield });
      return result;
    }, [] as { unit: string; rentalYield: number }[]);

    const top5Unit = getTopElementsBasedOnProperty<{ unit: string; rentalYield: number }>(
      unitsWithRentalYield,
      'rentalYield',
      5
    );
    return top5Unit.map((unit) => ({
      name: unit.unit,
      primaryText: formatNumber(unit.rentalYield, language, {
        style: 'percent',
        maximumSignificantDigits: 3,
      }),
    }));
  }, [units, topUnitsFilter, language, leasePriceHistories, discounts, getUnitValuations, getBuildingValuations]);

  return (
    <RankingBox
      boxKey={TOP_UNITS_RENTAL_YIELD_BOX_KEY}
      title={formatMessage({ id: 'dashboard.topUnitsRentalYield.title' })}
      infoContent={
        <>
          <Typography style={{ fontSize: 12 }}>
            {formatMessage({ id: 'dashboard.topUnitsRentalYield.info' }, { numberOfYears: 5 })}
          </Typography>
          <Typography style={{ fontSize: 12 }}>
            {formatMessage({ id: 'dashboard.topUnitsRentalYield.warning' })}
          </Typography>
          <Typography style={{ fontWeight: 700 }} display="inline">
            {`${formatMessage({
              id: 'dashboard.keyFigures.rentalYield',
            })} `}
          </Typography>
          <Typography display="inline">{`${formatMessage({
            id: 'dashboard.keyFigures.rentalYieldHelper1',
          })} `}</Typography>
          <Typography style={{ fontWeight: 700 }} display="inline">
            {`${formatMessage({
              id: 'dashboard.keyFigures.expectedRentalIncome',
            }).toLowerCase()}`}
          </Typography>
          <Typography display="inline">{` ${formatMessage({
            id: 'dashboard.keyFigures.rentalYieldHelper2',
          })} `}</Typography>
          <Typography display="inline" style={{ fontWeight: 700 }}>
            {`${formatMessage({
              id: 'dashboard.keyFigures.propertyValue',
            })}`}
          </Typography>
          <Typography display="inline">{` ${formatMessage({
            id: 'dashboard.keyFigures.rentalYieldHelper3',
          })}`}</Typography>
          <Typography style={{ marginTop: 5 }}>
            {`${formatMessage({
              id: 'dashboard.keyFigures.rentalYieldHelper4',
            })}`}
          </Typography>
          <Typography>
            {`${formatMessage({
              id: 'dashboard.keyFigures.rentalYieldHelper5',
            })}`}
          </Typography>
        </>
      }
      filtersBundle={switchs}
      datas={datas}
      forceUpdateLayout={forceUpdateLayout}
      loading={loading}
    />
  );
};

export default DashboardRankingTopUnitsRentalYield;
