import { LeaseInventory, LeaseInventoryType, DocumentType } from '@rentguru/commons-utils';

export const getLeaseInventoryDocumentType = (leaseInventory: LeaseInventory): DocumentType => {
  switch (leaseInventory.inventoryType) {
    case LeaseInventoryType.FIXTURES_ENTRANCE:
      return DocumentType.EntranceInventory;
    case LeaseInventoryType.FIXTURES_EXIT:
      return DocumentType.ExitInventory;
    case LeaseInventoryType.FURNITURES_ENTRANCE:
      return DocumentType.EntranceFurniture;
    default:
      return DocumentType.ExitFurniture;
  }
};
