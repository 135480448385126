import React from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import { Colors, getContactName, User } from '@rentguru/commons-utils';
import { useTeams } from 'src/hooks/TeamsContext';
import { ReactComponent as TeamSvg } from '../../../../icons/team.svg';

interface RoleMemberRowProps {
  user: User;
  divider?: boolean;
}

const RoleMemberRow: React.FC<RoleMemberRowProps> = ({ user, divider }) => {
  const { getUserTeams } = useTeams();
  const userTeams = getUserTeams(user.id);

  const memberName = user.contact
    ? getContactName(user.contact.firstName, user.contact.lastName)
    : getContactName(user.firstName, user.lastName);

  return (
    <>
      <Grid container style={{ marginTop: 15, marginBottom: 15, alignItems: 'center' }}>
        <Grid item xs={4}>
          <Typography style={{ fontWeight: 600, fontSize: 14 }}>{memberName}</Typography>
        </Grid>

        <Grid item xs={3} />
        <Grid
          item
          xs={3}
          style={{ color: Colors.SLATE_GREY, fontSize: 14, display: 'flex', justifyContent: 'flex-end' }}
        >
          <Typography>{user.email}</Typography>
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            color: Colors.SLATE_GREY,
            fontSize: 14,
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: 20,
          }}
        >
          <TeamSvg style={{ width: 14, height: 14, fill: Colors.TOWER_GREY, marginRight: 10 }} />
          <Typography>{userTeams.length}</Typography>
        </Grid>
      </Grid>
      {divider && <Divider />}
    </>
  );
};

export default RoleMemberRow;
