import { Collapse, createStyles, Divider, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import { Colors, isNilOrEmpty, LeaseInventoryType, LeaseStatus, Unit } from '@rentguru/commons-utils';
import { ActionButton, LoaderButton } from '@up2rent/ui';
import { isAfter } from 'date-fns';
import { Form, Formik, FormikHelpers } from 'formik';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import toUpper from 'lodash/toUpper';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';
import CustomCheckBox from 'src/components/ui/CustomCheckBox';
import LeaseSelectorField from 'src/components/ui/Forms/FormField/LeaseSelectorField';
import UnitSelectorField from 'src/components/ui/Forms/FormField/UnitSelectorField';
import { useFiles } from 'src/hooks/FilesContext';
import { useLeaseInventories } from 'src/hooks/LeaseInventoryContext';
import { useLeases } from 'src/hooks/LeasesContext';
import { useUnitInventories } from 'src/hooks/UnitInventoryContext';
import { copyLastUnitInventories, CustomItemIdMapping } from 'src/utils/unitInventoryUtils';
import * as Yup from 'yup';
import { LeaseInventoryExtended } from './Inventory';

export interface AddInventoryProps {
  units: Unit[];
  leaseInventories: LeaseInventoryExtended[];
  afterSubmit: () => void;
  cancelSubmit: () => void;
}

export const InventorySchema = Yup.object().shape({
  leaseId: Yup.string().required('A lease must be selected'),
  inventoryType: Yup.string().required('Select an inventory type'),
  copyLast: Yup.boolean().required(),
});

export interface InventoryValues {
  leaseId?: string;
  inventoryType: LeaseInventoryType | null;
  copyLast: boolean;
  unit: string | undefined;
}

export const useStyles = makeStyles(() =>
  createStyles({
    customTab: {
      textTransform: 'capitalize',
      letterSpacing: 'normal',
      backgroundColor: Colors.PORCELAIN_GREY_3,
      borderRadius: 20,
    },
    customSelectedTab: {
      backgroundColor: Colors.CLASSICAL_WHITE,
      borderRadius: 20,
      border: '2px solid rgba(38, 50, 56, 0.1)',
    },
    indicatorStyle: {
      display: 'none',
    },
    wrapperSelected: {
      fontSize: 14,
      fontWeight: 700,
      color: Colors.CARNATION_RED,
    },
    wrapperNotSelected: {
      fontSize: 14,
      fontWeight: 400,
      color: Colors.SLATE_GREY,
    },
  })
);

const AddInventoryForm: React.FC<AddInventoryProps> = ({ units, leaseInventories, afterSubmit, cancelSubmit }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const classes = useStyles();
  const { getLease, leases } = useLeases();
  const { createFile, deleteFile } = useFiles();
  const {
    createLeaseInventory,
    createLeaseInventoryCheckedItem,
    createLeaseInventoryEncoding,
    updateLeaseInventoryCheckedItem,
    updateLeaseInventoryEncoding,
    deleteLeaseInventoryCheckedItem,
    updateLeaseInventory,
  } = useLeaseInventories();
  const {
    createUnitInventory,
    createUnitInventoryStructure,
    updateUnitInventoryStructure,
    deleteUnitInventoryStructure,
    buildCompleteInventoriesForUnitAndLeaseInventory,
  } = useUnitInventories();

  const handleAfterSubmit = () => {
    if (!isNil(afterSubmit)) {
      afterSubmit();
    }
  };
  const handleCancel = () => {
    if (!isNil(cancelSubmit)) {
      cancelSubmit();
    }
  };

  const handleInventoryCreate = async (
    values: InventoryValues,
    { setSubmitting, setStatus }: FormikHelpers<InventoryValues>
  ) => {
    if (!isNil(values.inventoryType)) {
      const input = {
        status: LeaseStatus.Draft,
        inventoryType: values.inventoryType,
        leaseId: values.leaseId!,
        createdAt: new Date().toISOString(),
      };
      const newLeaseInventoryResult = await createLeaseInventory(input);
      if (values.copyLast && !isEmpty(leaseInventories)) {
        const latestLeaseInventory = leaseInventories.reduce((latest, li) => {
          if (isAfter(new Date(li.createdAt!), new Date(latest.createdAt!))) {
            return li;
          }
          return latest;
        });
        const latestUnitInventories = await buildCompleteInventoriesForUnitAndLeaseInventory(
          values.unit ?? '',
          latestLeaseInventory.id,
          new Date(),
          'fixtures'
        );

        await copyLastUnitInventories(
          newLeaseInventoryResult,
          latestUnitInventories,
          {
            createUnitInventory,
            createUnitInventoryStructure,
            updateUnitInventoryStructure,
            deleteUnitInventoryStructure: (id: string) => deleteUnitInventoryStructure(id),
            createLeaseInventoryEncoding,
            updateLeaseInventoryEncoding,
            createLeaseInventoryCheckedItem,
            updateLeaseInventoryCheckedItem,
            deleteLeaseInventoryCheckedItem,
            createFile,
            deleteFile,
          },
          [] as CustomItemIdMapping[]
        );

        if (!isNil(latestLeaseInventory.keys) || !isNil(latestLeaseInventory.connectionPlugs)) {
          await updateLeaseInventory(newLeaseInventoryResult, {
            ...(!isNil(latestLeaseInventory.keys) && { keys: latestLeaseInventory.keys }),
            ...(!isNil(latestLeaseInventory.connectionPlugs) && {
              connectionPlugs: latestLeaseInventory.connectionPlugs,
            }),
          });
        }
      }
      setStatus(true);
      setSubmitting(false);
      history.push({
        pathname: RouteDestination.EDIT_INVENTORY,
        state: {
          goBackUrl: history.location,
          leaseInventory: newLeaseInventoryResult,
          unitId: values.unit ?? '',
        },
      });
    }

    setStatus(true);
    setSubmitting(false);
    handleAfterSubmit();
  };

  return (
    <Formik
      initialValues={{
        inventoryType: LeaseInventoryType.FIXTURES_ENTRANCE,
        copyLast: true,
        unit: units[0].id,
      }}
      validationSchema={InventorySchema}
      onSubmit={handleInventoryCreate}
    >
      {({ values, isSubmitting, status, setFieldValue }) => {
        let copyAvailable = false;
        let disabled = false;
        if (!isNil(values.leaseId)) {
          const lease = getLease(values.leaseId);
          if (!isNil(lease)) {
            const inventories = lease.leaseInventories;
            if (!isNil(inventories) && !isEmpty(inventories)) {
              const entranceInventories = inventories.filter(
                (i) => i.inventoryType === LeaseInventoryType.FIXTURES_ENTRANCE
              );
              const exitInventories = inventories.filter((i) => i.inventoryType === LeaseInventoryType.FIXTURES_EXIT);
              copyAvailable = !isEmpty(entranceInventories) || !isEmpty(exitInventories);
              if (isEmpty(entranceInventories) && !isEmpty(exitInventories)) {
                values.inventoryType = LeaseInventoryType.FIXTURES_ENTRANCE;
                disabled = true;
              } else if (isEmpty(exitInventories) && !isEmpty(entranceInventories)) {
                values.inventoryType = LeaseInventoryType.FIXTURES_EXIT;
                disabled = true;
              } else if (!isEmpty(exitInventories) && !isEmpty(entranceInventories)) {
                values.inventoryType = null;
                disabled = true;
              }
            }
          }
        }

        const filteredLeases = leases.filter((lease) =>
          isNil(lease.units)
            ? false
            : [LeaseStatus.Active, LeaseStatus.Ended].includes(lease.status) &&
              lease.units.some((unitLease) => values.unit === unitLease.unitId)
        );

        const onChange = (event: string) => {
          setFieldValue('unit', event);
          setFieldValue('leaseId', filteredLeases[0]);
        };

        return (
          <Form style={{ width: 640, maxWidth: 640 }}>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginRight: 30,
              }}
            >
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: 20,
                  marginBottom: 10,
                  marginTop: 30,
                  marginLeft: 30,
                  textAlign: 'left',
                }}
              >
                {formatMessage({ id: 'rentalUnit.detail.inventory.addInventory' })}
              </Typography>
            </div>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <div style={{ marginRight: 30, marginLeft: 30 }}>
              <UnitSelectorField units={units} fieldName="unit" initialValue={values.unit} onChange={onChange} />
              <Collapse in={!isNil(values.unit)}>
                <LeaseSelectorField
                  leases={filteredLeases}
                  fieldName="leaseId"
                  initialValue={!isNilOrEmpty(filteredLeases) ? filteredLeases[0].id : undefined}
                />
              </Collapse>
            </div>

            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <div style={{ marginRight: 30, marginLeft: 30 }}>
              <div style={{ backgroundColor: Colors.PORCELAIN_GREY_3, borderRadius: 20, marginBottom: 30 }}>
                <Tabs
                  value={!isNil(values.inventoryType) ? values.inventoryType : false}
                  onChange={(e, newValue) => {
                    setFieldValue('inventoryType', newValue);
                  }}
                  variant="fullWidth"
                  centered
                  classes={{ indicator: classes.indicatorStyle }}
                  disabled={disabled}
                >
                  <Tab
                    value={LeaseInventoryType.FIXTURES_ENTRANCE}
                    classes={{
                      root: classes.customTab,
                      selected: classes.customSelectedTab,
                      wrapper:
                        values.inventoryType === LeaseInventoryType.FIXTURES_ENTRANCE
                          ? classes.wrapperSelected
                          : classes.wrapperNotSelected,
                    }}
                    label={formatMessage({ id: 'rentalUnit.detail.inventory.entrance' })}
                  />
                  <Tab
                    value={LeaseInventoryType.FIXTURES_EXIT}
                    classes={{
                      root: classes.customTab,
                      selected: classes.customSelectedTab,
                      wrapper:
                        values.inventoryType === LeaseInventoryType.FIXTURES_EXIT
                          ? classes.wrapperSelected
                          : classes.wrapperNotSelected,
                    }}
                    label={formatMessage({ id: 'rentalUnit.detail.inventory.exit' })}
                  />
                </Tabs>
              </div>
              {copyAvailable && (
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <CustomCheckBox
                    checkedColor={Colors.DODGER_BLUE}
                    isChecked={values.copyLast}
                    onCheck={(e, checked) => setFieldValue('copyLast', checked)}
                  />
                  <Typography style={{ marginLeft: 20, fontWeight: 'bold', fontSize: 14 }}>
                    {formatMessage({ id: 'rentalUnit.detail.inventory.copyMessage' })}
                  </Typography>
                </div>
              )}
            </div>

            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <div
              style={{
                marginBottom: 20,
                marginRight: 30,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <ActionButton
                onClick={() => {
                  handleCancel();
                }}
                style={{ background: 'none', color: Colors.DARK_SLATE_GREY, marginRight: 20 }}
              >
                {toUpper(formatMessage({ id: 'cancel' }))}
              </ActionButton>
              <LoaderButton loading={isSubmitting} success={status}>
                {formatMessage({
                  id: 'save',
                })}
              </LoaderButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddInventoryForm;
