import { Grid } from '@material-ui/core';
import React from 'react';
import TitledSwitch from '../../TitledSwitch';
import * as Yup from 'yup';

export interface VatManagementValues {
  applyVat: boolean;
}

export const VatManagementSchema = Yup.object().shape({
  applyVat: Yup.boolean(),
});

const VatManagementFields = () => {
  return (
    <Grid style={{ margin: '10px 0px 0px 30px', maxWidth: 580 }}>
      <Grid>
        <TitledSwitch
          fieldName="applyVat"
          titleMessageId="accounting.vat.applyVatOnLease"
          switchOnTextId="lease.addLease.includeInLease"
          switchOffTextId="lease.addLease.ignore"
        />
      </Grid>
    </Grid>
  );
};

export default VatManagementFields;
