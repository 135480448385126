import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const minWidth = 1100;
const maxLeftPadding = 220;
const maxRightPadding = 120;
const minLeftPadding = 100;
const minRightPadding = 0;

export const CenteredGrid: React.FC<{ children?: React.ReactNode; fullHeigth?: boolean }> = ({
  children,
  fullHeigth,
}) => {
  const classes = useStyles();
  const fullWidth = window.innerWidth;
  let initLeftPadding = maxLeftPadding;
  let initRightPadding = maxRightPadding;
  if (fullWidth - minWidth < maxLeftPadding + maxRightPadding) {
    const remainder = fullWidth - (minWidth + minLeftPadding + minRightPadding);
    initLeftPadding = minLeftPadding + Math.max(0, remainder / 2);
    initRightPadding = minRightPadding + Math.max(0, remainder / 2);
  }
  const [leftPadding, setLeftPadding] = React.useState(initLeftPadding);
  const [rightPadding, setRightPadding] = React.useState(initRightPadding);

  window.addEventListener('resize', () => {
    const newFullWidth = window.innerWidth;
    let newLeftPadding = maxLeftPadding;
    let newRightPadding = maxRightPadding;
    if (newFullWidth - minWidth < maxLeftPadding + maxRightPadding) {
      const remainder = newFullWidth - (minWidth + minLeftPadding + minRightPadding);
      newLeftPadding = minLeftPadding + Math.max(30, remainder / 2);
      newRightPadding = minRightPadding + Math.max(30, remainder / 2);
    }
    setLeftPadding(newLeftPadding);
    setRightPadding(newRightPadding);
  });
  return (
    <Grid style={{ flexGrow: 1, height: fullHeigth ? '100vh' : 'auto' }}>
      <Grid
        container
        style={{
          paddingRight: rightPadding,
          paddingLeft: leftPadding,
          paddingTop: 30,
        }}
        classes={classes}
      >
        <Grid item style={{ minWidth }} xs={12} classes={classes}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};
