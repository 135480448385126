import React from 'react';
import { Grid, Chip, Collapse } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/styles';
import {
  Colors,
  integrationPrices,
  IntegrationPrice,
  IntegrationName,
  IntegrationStatus,
} from '@rentguru/commons-utils';
import { useIntl } from 'react-intl';
import IconnedRemark from 'src/components/ui/IconnedRemark';
import { ReactComponent as Bulb } from 'src/icons/bulb.svg';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import { getDisplayPrice } from './IntegrationBox';
import IntegrationLogo from './IntegrationLogo';
import { useIntegrations } from 'src/hooks/IntegrationsContext';
import { formatDistance, parseISO } from 'date-fns';
import { dateLocaleMap, dateLocaleMapType, useLocale } from 'src/i18n/IntlProviderWrapper';
import Dialog from 'src/components/ui/Dialog';
import { ActionButton, useCustomSimpleDialogStyles } from '@up2rent/ui';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: Colors.PORCELAIN_GREY_1,
    padding: 15,
    paddingLeft: 30,
  },
  paragraph: {
    fontSize: '14px',
    lineHeight: '20px',
    color: Colors.CHARCOAL_GREY,
    textAlign: 'left',
  },
  text: {
    fontFamily: 'Mulish',
    fontSize: 20,
    fontWeight: 'bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: Colors.CLASSICAL_BLACK,
  },
  errorText: {
    fontFamily: 'Mulish',
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: Colors.SLATE_GREY,
    textAlign: 'left',
  },
});

interface MyBanxIntegrationDialogProps {
  open: boolean;
  onClose: () => void;
  id?: string | null;
}

const MyBanxIntegrationDialog: React.FC<MyBanxIntegrationDialogProps> = ({ open, onClose, id }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const customDialogClasses = useCustomSimpleDialogStyles();
  const { getIntegration, loading, error } = useIntegrations();
  const integration = getIntegration(id ?? '');

  if (loading || error) {
    return null;
  }

  const integrationPrice = integrationPrices.find((price: IntegrationPrice) => price.name === IntegrationName.MYBANX);
  const price = integrationPrice
    ? getDisplayPrice(
        integrationPrice.price!,
        formatMessage({ id: `settings.integrations.frequency.${integrationPrice.frequency}` })
      )
    : formatMessage({ id: 'settings.integrations.free' });

  const hasError = integration?.status === IntegrationStatus.ERROR;
  const errorMessage = integration?.error ?? '';
  const alreadyRun = Boolean(integration?.lastRun);
  const lastSyncDistance = alreadyRun
    ? formatDistance(parseISO(integration!.lastRun!), new Date(), {
        addSuffix: true,
        locale: (dateLocaleMap as dateLocaleMapType)[language],
      })
    : '';
  const integrationName = formatMessage({ id: `settings.integrations.IntegrationName.${IntegrationName.MYBANX}` });

  return (
    <Dialog open={open} onClose={onClose} scroll="body">
      <div className={classes.root}>
        <IntegrationLogo name={IntegrationName.MYBANX} height={30} />
      </div>
      <Grid
        container
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingRight: 30,
          paddingLeft: 30,
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <Grid item>
          <Typography className={classes.text}>{integrationName}</Typography>
        </Grid>
        <Grid item style={{ marginLeft: 10, display: 'flex', justifyContent: 'flex-end' }}>
          <Chip
            label={price}
            style={{
              color: Colors.LIGHT_BLUE_GREY,
              fontFamily: 'Mulish',
              fontSize: 14,
              lineHeight: 18,
              fontWeight: 600,
            }}
          />
        </Grid>
      </Grid>
      <Grid style={{ paddingLeft: 30, paddingRight: 30 }}>
        <Typography paragraph classes={{ root: classes.paragraph }}>
          {formatMessage({ id: `settings.integrations.description.${IntegrationName.MYBANX}` })}
        </Typography>

        {alreadyRun && (
          <>
            <IconnedRemark
              Icon={InfoSvg}
              noFixWidth
              style={{ marginLeft: 0 }}
              message={
                <Typography classes={{ root: classes.paragraph }}>
                  {`${formatMessage(
                    { id: 'settings.integrations.lastSync' },
                    { name: IntegrationName.MYBANX }
                  )} ${lastSyncDistance}${
                    hasError ? ` ${formatMessage({ id: 'settings.integrations.lastSyncError' })}` : ''
                  }`}
                </Typography>
              }
            />
            <Collapse in={hasError}>
              <IconnedRemark
                Icon={InfoSvg}
                noFixWidth
                style={{ marginTop: 10, marginBottom: 10, marginLeft: 0 }}
                message={<Typography className={classes.errorText}>{errorMessage}</Typography>}
              />
            </Collapse>
          </>
        )}

        <Collapse in={!alreadyRun}>
          <Typography paragraph className={classes.paragraph} style={{ marginTop: 20 }}>
            {formatMessage({ id: 'settings.integrations.activateIntegration' })}
          </Typography>
          <ul style={{ color: Colors.CHARCOAL_GREY, lineHeight: '20px', paddingRight: 30 }}>
            <li>
              <Typography className={classes.paragraph}>
                {formatMessage({ id: 'settings.integrations.myBanxSteps.first' })}
                <Link href="https://aloalto.mybanx.io">https://aloalto.mybanx.io</Link>
              </Typography>
            </li>
            <li>
              <Typography className={classes.paragraph}>
                {formatMessage({ id: 'settings.integrations.myBanxSteps.second' })}
              </Typography>
            </li>
            <li>
              <Typography className={classes.paragraph}>
                {formatMessage({ id: 'settings.integrations.myBanxSteps.third' })}
              </Typography>
            </li>
            <li>
              <Typography className={classes.paragraph}>
                {formatMessage({ id: 'settings.integrations.myBanxSteps.fourth' })}
              </Typography>
            </li>
          </ul>
        </Collapse>
      </Grid>
      <IconnedRemark
        Icon={Bulb}
        noFixWidth
        style={{ marginRight: 30 }}
        message={
          <Grid style={{ textAlign: 'left', color: Colors.SLATE_GREY }}>
            <Typography>
              {formatMessage({ id: 'settings.integrations.additionalInformation1' }, { integrationName })}
            </Typography>
            <Typography>{formatMessage({ id: 'settings.integrations.additionalInformation2' })}</Typography>
            <Typography>{formatMessage({ id: 'settings.integrations.additionalInformation3' })}</Typography>
          </Grid>
        }
      />
      <Grid
        style={{
          paddingRight: 30,
          paddingLeft: 30,
          marginBottom: 20,
          marginTop: 20,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <ActionButton onClick={onClose} className={customDialogClasses.cancelButton}>
          {formatMessage({
            id: 'cancel',
          })}
        </ActionButton>
      </Grid>
    </Dialog>
  );
};

export default MyBanxIntegrationDialog;
