import { Divider, Grid, Tab } from '@material-ui/core';
import Dialog from 'src/components/ui/Dialog';
import Typography from '@material-ui/core/Typography';
import { CommunicationSettingsProfile, CommunicationSettingsProfileReferent } from '@rentguru/commons-utils';
import { isEmpty, isNil, sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useRouteMatch } from 'react-router-dom';
import { ContentHeader, CustomizedTabs, ElevatedPaper, ActionButton } from '@up2rent/ui';
import { useCommunicationSettingsProfiles } from 'src/hooks/CommunicationSettingsProfilesContext';
import { useUser } from 'src/hooks/UserContext';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { RouteDestination } from '../Routes/Routes';
import AddLeaseCommunicationSettingsProfileDialog from './CommunicationSettings/AddCommunicationSettingsProfileDialog/AddLeaseCommunicationSettingsProfileDialog';
import AddOwnerCommunicationSettingsProfileDialog from './CommunicationSettings/AddCommunicationSettingsProfileDialog/AddOwnerCommunicationSettingsProfileDialog';
import LeaseCommunicationsSettingsProfileDetails from './CommunicationSettings/CommunicationSettingsProfileDetails/LeaseCommunicationSettingsProfileDetails';
import OwnerCommunicationsSettingsProfileDetails from './CommunicationSettings/CommunicationSettingsProfileDetails/OwnerCommunicationSettingsProfileDetails';
import DeleteCommunicationSettingsProfileDialog from './CommunicationSettings/DeleteCommunicationSettingsProfileDialog';
import { SettingsRouteDestination } from './Settings';

const CommunicationsSettings = () => {
  const { formatMessage } = useIntl();
  const { settingsAutomationWrite } = usePermissions();
  const {
    loading: communicationSettingsProfilesLoading,
    error: communicationSettingsProfilesError,
    getDefaultLeaseCommunicationSettingsProfiles,
    getDefaultOwnerCommunicationSettingsProfiles,
  } = useCommunicationSettingsProfiles();
  const { isAgency } = useUser();
  const [addProfileDialogOpen, setAddProfileDialogOpen] = useState<boolean>(false);
  const [deleteProfileDialog, setDeleteProfileDialog] = useState<{
    dialogOpen: boolean;
    idToDelete?: string;
    referent?: CommunicationSettingsProfileReferent;
  }>({
    dialogOpen: false,
  });
  const [leaseCommunicationSettingsProfiles, setLeaseCommunicationSettingsProfiles] = useState<
    CommunicationSettingsProfile[]
  >([]);
  const [ownerCommunicationSettingsProfiles, setOwnerCommunicationSettingsProfiles] = useState<
    CommunicationSettingsProfile[]
  >([]);
  const match = useRouteMatch<{ tabName: string }>({
    path: `${RouteDestination.SETTINGS}/${SettingsRouteDestination.COMMUNICATION_ROUTE}/:tabName?`,
  });

  useEffect(() => {
    if (communicationSettingsProfilesLoading) {
      return;
    }
    const leaseProfiles = getDefaultLeaseCommunicationSettingsProfiles();
    const ownerProfiles = getDefaultOwnerCommunicationSettingsProfiles();

    setLeaseCommunicationSettingsProfiles(sortBy(leaseProfiles, 'defaultName'));
    setOwnerCommunicationSettingsProfiles(sortBy(ownerProfiles, 'defaultName'));
  }, [
    getDefaultLeaseCommunicationSettingsProfiles,
    getDefaultOwnerCommunicationSettingsProfiles,
    communicationSettingsProfilesLoading,
  ]);

  if (
    communicationSettingsProfilesLoading ||
    isEmpty(leaseCommunicationSettingsProfiles) ||
    isEmpty(ownerCommunicationSettingsProfiles)
  ) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{formatMessage({ id: 'loading' })}</Typography>
      </ElevatedPaper>
    );
  }

  if (communicationSettingsProfilesError || isNil(match)) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{communicationSettingsProfilesError}</Typography>
      </ElevatedPaper>
    );
  }

  const onlyOneLeaseProfile = leaseCommunicationSettingsProfiles.length === 1;
  const onlyOneOwnerProfile = ownerCommunicationSettingsProfiles.length === 1;

  const getProfilesForDeleteDialog = () => {
    const { idToDelete, referent } = deleteProfileDialog;
    const allProfiles =
      referent === CommunicationSettingsProfileReferent.OWNER
        ? ownerCommunicationSettingsProfiles
        : leaseCommunicationSettingsProfiles;
    const profileToDelete = allProfiles.find((profile) => profile.id === idToDelete)!;
    const profilesForReplacement = allProfiles.filter((profile) => profile.id !== idToDelete);

    return { profileToDelete, profilesForReplacement };
  };

  const TO_PREFIX = '';
  const communicationSettingsNavItems = [
    {
      to: `${TO_PREFIX}`,
      labelId: 'settings.communications.menu.lease',
      component: LeaseCommunicationsSettingsProfileDetails,
    },
    isAgency && {
      to: `${TO_PREFIX}owner`,
      labelId: 'settings.communications.menu.owner',
      component: OwnerCommunicationsSettingsProfileDetails,
    },
  ];

  const tabIsLease = isNil(match.params.tabName);
  const tabIsOwner = match.params.tabName === 'owner';

  return (
    <>
      <ElevatedPaper style={{ margin: 20, maxWidth: 640, paddingBottom: 0 }}>
        <ContentHeader title={formatMessage({ id: 'settings.communicationsMenu' })}>
          {settingsAutomationWrite && (
            <ActionButton onClick={() => setAddProfileDialogOpen(true)} style={{ textTransform: 'none' }}>
              {formatMessage({ id: 'settings.communications.addProfile.title' })}
            </ActionButton>
          )}
        </ContentHeader>

        <CustomizedTabs
          value={!isNil(match?.params.tabName) ? match.url : match.url + (match.url.endsWith('/') ? '' : '/')}
        >
          {communicationSettingsNavItems.map((navItem) => {
            if (!navItem) {
              return null;
            }
            const to = !isNil(match?.params.tabName)
              ? match.url.replace(match.params.tabName, navItem.to)
              : match.url + (match.url.endsWith('/') ? '' : '/') + navItem.to;

            return (
              <Tab
                label={formatMessage({ id: navItem.labelId })}
                component={Link}
                key={navItem.to}
                to={to}
                value={to}
              />
            );
          })}
        </CustomizedTabs>
        <Divider />
        <Grid style={{ marginTop: 20, marginBottom: 20 }}>
          {tabIsLease &&
            leaseCommunicationSettingsProfiles.map((leaseCommunicationSettingsProfile) => (
              <LeaseCommunicationsSettingsProfileDetails
                key={leaseCommunicationSettingsProfile.id}
                leaseCommunicationSettingsProfile={leaseCommunicationSettingsProfile}
                isLastProfile={onlyOneLeaseProfile}
                setDeleteProfileDialog={setDeleteProfileDialog}
              />
            ))}
          {tabIsOwner &&
            ownerCommunicationSettingsProfiles.map((ownerCommunicationSettingsProfile) => (
              <OwnerCommunicationsSettingsProfileDetails
                key={ownerCommunicationSettingsProfile.id}
                ownerCommunicationSettingsProfile={ownerCommunicationSettingsProfile}
                isLastProfile={onlyOneOwnerProfile}
                setDeleteProfileDialog={setDeleteProfileDialog}
              />
            ))}
        </Grid>
      </ElevatedPaper>
      {addProfileDialogOpen && (
        <Dialog open={addProfileDialogOpen} onClose={() => setAddProfileDialogOpen(false)}>
          {tabIsLease && (
            <AddLeaseCommunicationSettingsProfileDialog closeDialog={() => setAddProfileDialogOpen(false)} />
          )}
          {tabIsOwner && (
            <AddOwnerCommunicationSettingsProfileDialog closeDialog={() => setAddProfileDialogOpen(false)} />
          )}
        </Dialog>
      )}
      {deleteProfileDialog.dialogOpen && (
        <Dialog
          open={deleteProfileDialog.dialogOpen}
          onClose={() => setDeleteProfileDialog({ dialogOpen: false, idToDelete: undefined })}
          scroll="body"
        >
          <DeleteCommunicationSettingsProfileDialog
            closeDialog={() => setDeleteProfileDialog({ dialogOpen: false, idToDelete: undefined })}
            profiles={getProfilesForDeleteDialog()}
          />
        </Dialog>
      )}
    </>
  );
};

export default CommunicationsSettings;
