import { Divider, Grid, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Colors } from '@rentguru/commons-utils';
import { FormikProps, useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import FormikDatePicker from 'src/components/ui/FormikDatePicker';
import CustomizedSelect from '../CustomizedSelectWithImportance';
import { EditAdvertisementFormValues } from '../EditPublicationForm';
import TextDetailEditable from '../TextDetailEditableWithImportance';

const useLocalStyles = makeStyles({
  rootSelect: {
    '& > *': {
      width: 280,
    },
  },
  h6: {
    flex: 1,
    textAlign: 'left' as const,
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Mulish',
    fontSize: '16px',
    fontWeight: 700,
    margin: '20px 0 15px 30px',
  },
});

const RentalInformations = () => {
  const { values, errors, touched }: FormikProps<EditAdvertisementFormValues> = useFormikContext();
  const classes = useLocalStyles();
  const { formatMessage } = useIntl();

  return (
    <>
      <Typography variant="h6" color="inherit" className={classes.h6}>
        {formatMessage({ id: 'rentalUnit.editUnit.advertisement.general.rentalInformation' })}
      </Typography>

      <Grid container style={{ justifyContent: 'space-evenly' }}>
        <Grid item>
          <FormikDatePicker
            id="startingDate"
            name="advertisement.availabilityDate"
            label={formatMessage({ id: 'rentalUnit.editUnit.advertisement.general.startDate' })}
            value={values.advertisement.availabilityDate ? values.advertisement.availabilityDate : null}
            style={{ width: 280 }}
            type="string"
            importance="recommended"
            error={Boolean(
              errors.advertisement &&
                errors.advertisement.availabilityDate &&
                touched.advertisement &&
                touched.advertisement.availabilityDate
            )}
          />
        </Grid>
        <Grid item>
          <TextDetailEditable
            style={{ width: 280 }}
            title={formatMessage({ id: 'rentalUnit.editUnit.advertisement.general.monthlyRent' })}
            editMode={true}
            name="unit.advertisedRentalPrice"
            endAdornment="€"
            importance="mandatory"
            error={Boolean(
              errors.unit && errors.unit.advertisedRentalPrice && touched.unit && touched.unit.advertisedRentalPrice
            )}
          />
        </Grid>
      </Grid>
      <Grid container style={{ margin: '5px 0 0 0', justifyContent: 'space-evenly' }}>
        <Grid item>
          <TextDetailEditable
            style={{ width: 280 }}
            title={formatMessage({ id: 'rentalUnit.editUnit.advertisement.general.monthlyCharges' })}
            editMode={true}
            name="unit.advertisedMonthlyCharges"
            endAdornment="€"
            importance="recommended"
            onChangeObserver={() => {}}
            error={Boolean(
              errors.unit &&
                errors.unit.advertisedMonthlyCharges &&
                touched.unit &&
                touched.unit.advertisedMonthlyCharges
            )}
          />
        </Grid>
        <Grid item>
          <CustomizedSelect
            label={formatMessage({ id: 'rentalUnit.editUnit.advertisement.general.furnished' })}
            fieldName="advertisement.furnished"
            value={values.advertisement.furnished}
            className={classes.rootSelect}
            error={false}
          >
            <MenuItem value="none">
              {formatMessage({ id: 'rentalUnit.editUnit.advertisement.general.availability.none' })}
            </MenuItem>
            <MenuItem value="true">{formatMessage({ id: 'rentalUnit.detail.general.yes' })}</MenuItem>
            <MenuItem value="false">{formatMessage({ id: 'rentalUnit.detail.general.no' })}</MenuItem>
          </CustomizedSelect>
        </Grid>
      </Grid>
      <Grid container style={{ margin: '5px 0 30px 0', justifyContent: 'space-evenly' }}>
        <Grid item>
          <CustomizedSelect
            label={formatMessage({ id: 'rentalUnit.editUnit.advertisement.general.firstOccupancy' })}
            fieldName="advertisement.firstOccupancy"
            value={values.advertisement.firstOccupancy}
            className={classes.rootSelect}
            error={false}
            margin="dense"
          >
            <MenuItem value="none">
              {formatMessage({ id: 'rentalUnit.editUnit.advertisement.general.availability.none' })}
            </MenuItem>
            <MenuItem value="true">{formatMessage({ id: 'rentalUnit.detail.general.yes' })}</MenuItem>
            <MenuItem value="false">{formatMessage({ id: 'rentalUnit.detail.general.no' })}</MenuItem>
          </CustomizedSelect>
        </Grid>
        <Grid item style={{ width: 280 }} />
      </Grid>
      <Divider />
    </>
  );
};

export default RentalInformations;
