import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import { CircularProgress, Divider, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { Colors, FileCategory } from '@rentguru/commons-utils';
import { useContacts } from 'src/hooks/ContactsContext';
import { FilesGroupedByCategory } from 'src/hooks/FilesContext';
import { FileCategoryTypeForm as FileFieldsCategory } from 'src/hooks/TechnicsContext';
import { ReactComponent as Bulb } from 'src/icons/bulb.svg';
import FormHeader from 'src/components/ui/FormHeader';
import { ElevatedPaperTable, CustomIconButton } from '@up2rent/ui';
import ResetIcon from 'src/components/ui/ResetIcon';
import ConditionalParentElement from 'src/components/RentalUnits/Details/Publication/EditPublication/ConditionalParentElement';
import { FileFormValues } from 'src/components/ui/Forms/FormField/FileByCategoryFields';
export { FileSchema } from 'src/components/ui/Forms/FormField/FileByCategoryFields';
import { resolveMainUnitAndSubUnitIndexAndPrefix } from 'src/components/Leases/AddLease/useAddEditLeaseUtils';
import PebFormHeader from './LeasePEBFormHeader';
import PebRow from './LeasePEBFormRow';
import IconnedRemark from 'src/components/ui/IconnedRemark';

const useLocalStyles = makeStyles({
  title: {
    flex: 1,
    textAlign: 'left',
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Mulish',
    fontSize: '16px',
    fontWeight: 700,
    margin: '10px 0 15px 30px',
  },
});

interface LeasePEBFormProps {
  parentElement?: boolean;
  unitId?: string;
}

const LeasePEBForm: React.FC<LeasePEBFormProps> = ({ parentElement = true, unitId }) => {
  const localClasses = useLocalStyles();
  const { formatMessage } = useIntl();

  const { values, setFieldValue } = useFormikContext<FileFormValues>();
  const { prefixFieldName, isMainUnit } = resolveMainUnitAndSubUnitIndexAndPrefix(values, unitId);

  const initialPebIndex = (
    (get(values, `${prefixFieldName}filesByCategories`) ?? []) as FileFieldsCategory[]
  ).findIndex(
    (fileFieldsCategory) =>
      fileFieldsCategory.category.fileCategory === FileCategory.PEB && fileFieldsCategory.unitId === unitId
  );

  const initialPebIndexForProperty = (
    (get(values, `${prefixFieldName}filesByCategories`) ?? []) as FileFieldsCategory[]
  ).findIndex(
    (fileFieldsCategory) =>
      fileFieldsCategory.category?.fileCategory === FileCategory.PEB && fileFieldsCategory.unitId === null
  );

  const [pebIndex, setPebIndex] = useState<number>(initialPebIndex);
  const [pebIndexForProperty, setPebIndexForProperty] = useState<number>(initialPebIndexForProperty);

  const originalPEBIndex = (
    (get(values, `${prefixFieldName}originalFilesByCategories`) ?? []) as FileFieldsCategory[]
  ).findIndex(
    (fileFieldsCategory) =>
      fileFieldsCategory.category?.fileCategory === FileCategory.PEB && fileFieldsCategory.unitId === unitId
  );

  const originalPebIndexForProperty = (
    (get(values, `${prefixFieldName}originalFilesByCategories`) ?? []) as FileFieldsCategory[]
  ).findIndex(
    (fileFieldsCategory) =>
      fileFieldsCategory.category?.fileCategory === FileCategory.PEB && fileFieldsCategory.unitId === null
  );

  const { contactsLoading } = useContacts();

  if (contactsLoading) {
    return <CircularProgress />;
  }

  const handleResetIconClick = () => {
    const originalFilesByCategories = (
      (get(values, `${prefixFieldName}filesByCategories`) ?? []) as FilesGroupedByCategory[]
    ).map((filesGroupedByCategory, index) => {
      if (index === pebIndex) {
        return get(values, `${prefixFieldName}originalFilesByCategories[${originalPEBIndex}]`);
      }
      if (index === pebIndexForProperty) {
        return get(values, `${prefixFieldName}originalFilesByCategories[${originalPebIndexForProperty}]`);
      }
      return filesGroupedByCategory;
    });

    setFieldValue(`${prefixFieldName}filesByCategories`, originalFilesByCategories);
  };

  return (
    <>
      <ConditionalParentElement
        parentElement={parentElement}
        //eslint-disable-next-line react/no-unstable-nested-components
        wrapper={(child: React.ReactNode) => <ElevatedPaperTable>{child}</ElevatedPaperTable>}
      >
        {parentElement ? (
          <FormHeader
            title={formatMessage({
              id: `lease.addLease.epbCertificate`,
            })}
            toolbarStyle={{ marginTop: 20 }}
          >
            {originalPEBIndex !== -1 && (
              <Tooltip title={formatMessage({ id: 'backToDefault' })}>
                <CustomIconButton
                  onClick={handleResetIconClick}
                  style={{ marginLeft: '25%' }}
                  Icon={ResetIcon}
                  iconStyle={{ fill: Colors.BLUE_GREY }}
                />
              </Tooltip>
            )}
          </FormHeader>
        ) : (
          <Grid container>
            <Grid item xs={10}>
              <Typography variant="h6" className={localClasses.title}>
                {formatMessage({ id: 'rentalUnit.detail.energy.epbCertificate.epbCertificate' })}
              </Typography>
            </Grid>

            <Grid item xs={2} style={{ display: 'flex', marginTop: 10 }}>
              {originalPEBIndex !== -1 && (
                <Tooltip title={formatMessage({ id: 'backToDefault' })}>
                  <CustomIconButton
                    onClick={handleResetIconClick}
                    style={{ marginLeft: '25%' }}
                    Icon={ResetIcon}
                    iconStyle={{ fill: Colors.BLUE_GREY }}
                  />
                </Tooltip>
              )}
            </Grid>
          </Grid>
        )}
        {parentElement && <Divider style={{ marginBottom: 10 }} />}
        <PebFormHeader />
        {isMainUnit && (
          <PebRow
            isUnit={false}
            prefixFieldName={prefixFieldName}
            pebIndex={pebIndexForProperty}
            setPebIndex={setPebIndexForProperty}
            unitId={unitId}
          />
        )}
        <PebRow
          isUnit={true}
          prefixFieldName={prefixFieldName}
          pebIndex={pebIndex}
          setPebIndex={setPebIndex}
          unitId={unitId}
        />

        <IconnedRemark
          Icon={Bulb}
          iconStyles={{ fill: Colors.BLUE_GREY, marginRight: 10 }}
          message={
            <Typography variant="subtitle1" style={{ color: Colors.SLATE_GREY, fontSize: 14 }}>
              {formatMessage({
                id: 'lease.addLease.epbInfo',
              })}
            </Typography>
          }
        />
      </ConditionalParentElement>
      <div style={{ marginBottom: 10 }} />
      {!parentElement && <Divider style={{ marginBottom: 10 }} />}
    </>
  );
};

export default LeasePEBForm;
