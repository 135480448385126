import { Divider, Grid, Typography, makeStyles } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { LoaderButton, ActionButtonSecond, CustomIconButton, OverflowableTypography } from '@up2rent/ui';
import {
  CommunicationSettingsFormValues,
  getCommunicationSettingsFromValues,
  getCommunicationSettingsProfileSchema,
} from '../CommunicationSettingsProfileUtils/CommunicationSettingsCommonsUtils';
import { Colors, CommunicationSettingsProfile, CommunicationSettingsProfileReferent } from '@rentguru/commons-utils';
import { ReactComponent as DeleteIcon } from 'src/icons/trash.svg';
import { ReactComponent as EditIcon } from 'src/icons/edit.svg';
import { Dispatch, SetStateAction, useState } from 'react';
import TextDetailEditable from 'src/components/ui/TextDetailEditable';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { useSnackbar } from 'notistack';
import { useCommunicationSettingsProfiles } from 'src/hooks/CommunicationSettingsProfilesContext';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    padding: 16,
    textAlign: 'left',
    height: 60,
  },
  headerActionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 8,
    width: 180,
  },
});

type CommunicationSettingsProfileProps<CommunicationSettingsFormValues> = {
  initialValues: CommunicationSettingsFormValues;
  CustomForm: React.FC;
  profile: CommunicationSettingsProfile;
  isLastProfile: boolean;
  setDeleteProfileDialog: Dispatch<
    SetStateAction<{
      dialogOpen: boolean;
      idToDelete?: string;
      referent?: CommunicationSettingsProfileReferent;
    }>
  >;
};

const CommunicationSettingsProfileDetails = <T extends CommunicationSettingsFormValues>({
  initialValues,
  CustomForm,
  profile,
  isLastProfile,
  setDeleteProfileDialog,
}: CommunicationSettingsProfileProps<T>) => {
  const { formatMessage } = useIntl();
  const [inEditMode, setInEditMode] = useState<boolean>(false);
  const { settingsAutomationWrite } = usePermissions();
  const schema = getCommunicationSettingsProfileSchema(profile.referent);
  const { enqueueSnackbar } = useSnackbar();
  const { updateCommunicationSettingsProfile } = useCommunicationSettingsProfiles();

  const handleSave = async (values: T, { setSubmitting, setStatus }: FormikHelpers<T>) => {
    setSubmitting(true);
    const newSettings = getCommunicationSettingsFromValues(values, profile.referent);
    await updateCommunicationSettingsProfile(profile, {
      defaultName: values.defaultName,
      communicationsSettings: newSettings,
    });

    setStatus(true);
    setSubmitting(false);
    enqueueSnackbar(formatMessage({ id: 'settings.changesSaved' }), { variant: 'success' });
  };

  const classes = useStyles();

  return (
    <Grid
      style={{
        border: `1px solid ${Colors.GEYSER_GREY}`,
        width: 592,
        marginLeft: 23,
        marginRight: 23,
        marginTop: 8,
        marginBottom: 8,
        borderRadius: 8,
      }}
    >
      <Grid>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values, formikHelpers) => {
            handleSave(values, formikHelpers);
            setInEditMode(false);
          }}
        >
          {({ isSubmitting, status, errors, touched }) => (
            <Grid>
              {!inEditMode && (
                <Grid className={classes.header}>
                  <OverflowableTypography
                    text={profile.defaultName!}
                    style={{ color: Colors.SLATE_GREY, fontWeight: 700, fontSize: 14, width: 200 }}
                  />

                  <Typography style={{ color: Colors.SLATE_GREY, fontWeight: 400, fontSize: 14, width: 150 }}>
                    {formatMessage({ id: `settings.communications.menu.${profile.referent.toLowerCase()}` })}
                  </Typography>

                  <Grid className={classes.headerActionButtons}>
                    <CustomIconButton
                      onClick={() => {
                        setInEditMode(true);
                      }}
                      style={{ fill: Colors.LIGHT_BLUE_GREY }}
                      iconTransform="scale(1.25)"
                      Icon={EditIcon}
                    />
                    {!isLastProfile && (
                      <CustomIconButton
                        onClick={() =>
                          setDeleteProfileDialog({
                            dialogOpen: true,
                            idToDelete: profile.id,
                            referent: profile.referent,
                          })
                        }
                        size="small"
                        style={{ fill: Colors.CARNATION_RED }}
                        Icon={DeleteIcon}
                      />
                    )}
                  </Grid>
                </Grid>
              )}
              {inEditMode && (
                <Form>
                  <Grid className={classes.header}>
                    <TextDetailEditable
                      editMode
                      title={formatMessage({ id: 'name' })}
                      disabled={!settingsAutomationWrite}
                      name="defaultName"
                      type="string"
                      style={{ width: 300, fontSize: 12 }}
                      error={Boolean(errors.defaultName && touched.defaultName)}
                    />

                    <Grid className={classes.headerActionButtons}>
                      <ActionButtonSecond onClick={() => setInEditMode(false)}>
                        {formatMessage({ id: 'rentalUnit.deleteUnit.cancelLabel' })}
                      </ActionButtonSecond>
                      <LoaderButton smallVersion loading={isSubmitting} success={status}>
                        {formatMessage({ id: 'save' })}
                      </LoaderButton>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid style={{ marginLeft: 24, marginRight: 24, marginBottom: 20 }}>
                    <CustomForm />
                  </Grid>
                </Form>
              )}
            </Grid>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default CommunicationSettingsProfileDetails;
