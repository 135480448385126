/* eslint-disable default-case */
/* eslint-disable no-loss-of-precision */
/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-param-last */
import React, { useContext, useState, useMemo, useEffect } from 'react';
import { startOfDay, subMonths, addMonths, endOfYear, startOfYear } from 'date-fns';
import { useUnits } from './UnitsContext';
import { useBuildings } from './BuildingsContext';
import { useContacts } from './ContactsContext';
import { getDashboardFiltersValues } from 'src/components/Dashboard/DashboardMetrics/utils/DashboardFilterUtils';
import { isNil } from 'lodash';
import {
  Building,
  Contact,
  Unit,
  TRANSACTION_BOX_KEY,
  MAP_BOX_KEY,
  AREAS_BOX_KEY,
  DRAFT_BOX_KEY,
  TICKETS_METRICS_BOX_KEY,
  FIGURES_BOX_KEY,
  TICKET_TO_DO_BOX_KEY,
  INDEXAION_BOX_KEY,
  OCCUPANCY_BOX_KEY,
  TENANT_STATEMENTS_TO_VALIDATE_KEY,
  TOP_UNITS_BOX_KEY,
  TOP_OWNERS_BOX_KEY,
  MAINTENANCE_BOX_KEY,
  COMMUNICATION_BOX_KEY,
  RENTAL_INCOME_BOX_KEY,
  TOP_CREDITORS_BOX_KEY,
  LEASE_OPERATION_BOX_KEY,
  TOP_TENANTS_INCOME_BOX_KEY,
  CUSTOM_NOTIFICATION_BOX_KEY,
  WAITING_FOR_SIGNATURE_BOX_KEY,
  TOP_UNITS_RENTAL_YIELD_BOX_KEY,
  TOP_BUILDINGS_RENTAL_YIELD_BOX_KEY,
  DashboardBoxType,
  OWNER_STATEMENTS_TO_VALIDATE_KEY,
  LEASE_CHARGE_PROVISION_ADJUSTMENT_KEY,
} from '@rentguru/commons-utils';
import { Layout } from 'react-grid-layout';
import { getCacheItem, setCacheItem } from 'src/utils/cacheUtils';

export interface SavedBoxItem extends Layout {
  collapse: boolean;
}

export interface Filter {
  name: string;
  field: string;
  items: string[];
}
interface FilterFromTo {
  from: string;
  to: string;
}

export interface FilterSwitch {
  name: string;
  value: string;
}

export interface CommonFilter {
  filters: Filter[];
}

export interface DashboardFilters extends CommonFilter {
  occupancy: FilterFromTo;
  tickets: FilterFromTo;
  latePayments: FilterFromTo;
  rentalIncome: FilterFromTo;
  areas: FilterFromTo;
  keyNumbers: FilterFromTo;
  potentialUpside: FilterFromTo;
  /* Add your other filters here */
}

export interface DashboardSwitchFilters extends CommonFilter {
  topTenants: FilterSwitch | null;
  topOwners: FilterSwitch | null;
  topUnits: FilterSwitch | null;
  topCreditors: FilterSwitch | null;
  topUnitsRentalYield: FilterSwitch | null;
  topTenantsIncome: FilterSwitch | null;
}

export interface BoxSetup {
  key: string;
  open: boolean;
}

export interface DashboardSetup {
  metricFilters: Filter[];
  metricBoxOrder: BoxSetup[];
  rankingBoxOrder: BoxSetup[];
  toDoBoxOrder: BoxSetup[];
}

export interface DashboardFiltersContext {
  filteredUnits: Unit[];
  filteredBuildings: Building[];
  filteredOwners: Contact[];
  dashboardFilters: DashboardFilters;
  updateDashboardFilters(variables: DashboardFilters): Promise<void>;
  resetDashboardFilters(name?: keyof DashboardFilters): Promise<void>;
  switchFilters: DashboardSwitchFilters;
  updateSwitchFilters(variables: DashboardSwitchFilters): void;
  resetSwitchFilters(name?: keyof DashboardSwitchFilters): void;
  getBoxOrder(dashboardType: DashboardBoxType): Promise<BoxSetup[] | Layout[]>;
  saveNewOrder(
    elements: {
      element: React.ReactNode;
      key: React.ReactText;
    }[],
    dashboardType: DashboardBoxType
  ): Promise<void>;
  saveNewLayout(newLayout: Layout[], dashboardType: DashboardBoxType): Promise<void>;
  getBoxCollapse(key: string, boxType: DashboardBoxType): Promise<boolean>;
  saveNewBoxCollapse(
    key: string,
    open: boolean,
    boxType: DashboardBoxType,
    forceUpdateLayout?: (layout: Layout[]) => void,
    forcedHeight?: number
  ): Promise<void>;
  setLayoutToDoDidMount: (didMount: boolean) => void;
  layoutToDo: SavedBoxItem[];
  saveNewBoxToDoLayout: (key: string, changeCollapse?: boolean, forcedHeight?: number) => void;
  setLayoutOnLayoutChange: (currentLayout: Layout[]) => void;
}

const LAYOUT_BOX_METRIC_OPEN_HEIGHT = 5;
const LAYOUT_BOX_RANKING_OPEN_HEIGHT = 5;
// It's the size of the loader 175px approximatly translated into grid units.
// eslint-disable-next-line @typescript-eslint/no-loss-of-precision
const LOADER_HEIGHT = 15.909090909090909090909;
const LAYOUT_BOX_CLOSE_HEIGHT = 1;

export const DashboardFiltersContext = React.createContext<DashboardFiltersContext | null>(null);

export const defaultMetricBoxOrder = [
  { i: FIGURES_BOX_KEY, x: 0, y: 0, w: 1, h: LAYOUT_BOX_METRIC_OPEN_HEIGHT },
  { i: OCCUPANCY_BOX_KEY, x: 1, y: 0, w: 1, h: LAYOUT_BOX_METRIC_OPEN_HEIGHT },
  { i: RENTAL_INCOME_BOX_KEY, x: 0, y: 1, w: 1, h: LAYOUT_BOX_METRIC_OPEN_HEIGHT },
  { i: MAP_BOX_KEY, x: 1, y: 1, w: 1, h: LAYOUT_BOX_METRIC_OPEN_HEIGHT },
  { i: TICKETS_METRICS_BOX_KEY, x: 0, y: 2, w: 1, h: LAYOUT_BOX_METRIC_OPEN_HEIGHT },
  { i: AREAS_BOX_KEY, x: 1, y: 2, w: 1, h: LAYOUT_BOX_METRIC_OPEN_HEIGHT },
];

export const defaultRankingBoxOrder = [
  { i: TOP_CREDITORS_BOX_KEY, x: 0, y: 0, w: 1, h: LAYOUT_BOX_RANKING_OPEN_HEIGHT },
  { i: TOP_UNITS_BOX_KEY, x: 1, y: 0, w: 1, h: LAYOUT_BOX_RANKING_OPEN_HEIGHT },
  { i: TOP_BUILDINGS_RENTAL_YIELD_BOX_KEY, x: 0, y: 1, w: 1, h: LAYOUT_BOX_RANKING_OPEN_HEIGHT },
  { i: TOP_UNITS_RENTAL_YIELD_BOX_KEY, x: 1, y: 1, w: 1, h: LAYOUT_BOX_RANKING_OPEN_HEIGHT },
  { i: TOP_TENANTS_INCOME_BOX_KEY, x: 0, y: 2, w: 1, h: LAYOUT_BOX_RANKING_OPEN_HEIGHT },
  { i: TOP_OWNERS_BOX_KEY, x: 1, y: 2, w: 1, h: LAYOUT_BOX_RANKING_OPEN_HEIGHT },
];

// h is being set dynamically set
const defaultToDoBoxOrder = [
  { i: CUSTOM_NOTIFICATION_BOX_KEY, x: 0, y: 0, w: 1, h: LOADER_HEIGHT, collapse: false },
  { i: TRANSACTION_BOX_KEY, x: 0, y: 1, w: 1, h: LOADER_HEIGHT, collapse: false },
  { i: COMMUNICATION_BOX_KEY, x: 0, y: 2, w: 1, h: LOADER_HEIGHT, collapse: false },
  { i: MAINTENANCE_BOX_KEY, x: 0, y: 3, w: 1, h: LOADER_HEIGHT, collapse: false },
  { i: TICKET_TO_DO_BOX_KEY, x: 0, y: 4, w: 1, h: LOADER_HEIGHT, collapse: false },
  { i: DRAFT_BOX_KEY, x: 0, y: 5, w: 1, h: LOADER_HEIGHT, collapse: false },
  { i: INDEXAION_BOX_KEY, x: 0, y: 6, w: 1, h: LOADER_HEIGHT, collapse: false },
  { i: LEASE_OPERATION_BOX_KEY, x: 0, y: 7, w: 1, h: LOADER_HEIGHT, collapse: false },
  { i: WAITING_FOR_SIGNATURE_BOX_KEY, x: 0, y: 8, w: 1, h: LOADER_HEIGHT, collapse: false },
  { i: TENANT_STATEMENTS_TO_VALIDATE_KEY, x: 0, y: 9, w: 1, h: LOADER_HEIGHT, collapse: false },
  { i: OWNER_STATEMENTS_TO_VALIDATE_KEY, x: 0, y: 10, w: 1, h: LOADER_HEIGHT, collapse: false },
  { i: LEASE_CHARGE_PROVISION_ADJUSTMENT_KEY, x: 0, y: 11, w: 1, h: LOADER_HEIGHT, collapse: false },
];

export const DashboardFiltersContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { getUnit, units, unitsLoading } = useUnits();
  const { getBuilding, buildings, buildingsLoading } = useBuildings();
  const { getContact, owners: singleOwners, jointOwners, contactsLoading } = useContacts();
  const today = startOfDay(new Date());
  const today3MonthsBefore = subMonths(today, 3);
  const today6MonthsBefore = subMonths(today, 6);
  const today6MonthsAfter = addMonths(today, 6);
  const firstDayOfThisYear = startOfYear(today);
  const lastDayOfThisYear = endOfYear(today);
  const defaultOccupancyFilter = { from: today6MonthsBefore.toISOString(), to: today6MonthsAfter.toISOString() };
  const defaultTicketsMetricFilter = { from: today3MonthsBefore.toISOString(), to: today.toISOString() };
  const defaultLatePaymentsFilter = { from: today3MonthsBefore.toISOString(), to: today.toISOString() };
  const defaultRentalIncomeFilter = { from: today6MonthsBefore.toISOString(), to: today6MonthsAfter.toISOString() };
  const defaultAreasFilter = { from: today6MonthsBefore.toISOString(), to: today6MonthsAfter.toISOString() };
  const defaultKeyNumbersFilter = { from: firstDayOfThisYear.toISOString(), to: lastDayOfThisYear.toISOString() };
  const defaultPotentialUpsideFilter = { from: today3MonthsBefore.toISOString(), to: today.toISOString() };

  const [layoutToDo, setLayoutToDo] = useState<SavedBoxItem[]>([]);
  const [layoutToDoDidMount, setLayoutToDoDidMount] = useState<boolean>(false);
  const owners = [...singleOwners, ...jointOwners];

  const defaultDashboardFilters = {
    filters: [],
    occupancy: defaultOccupancyFilter,
    tickets: defaultTicketsMetricFilter,
    latePayments: defaultLatePaymentsFilter,
    rentalIncome: defaultRentalIncomeFilter,
    areas: defaultAreasFilter,
    keyNumbers: defaultKeyNumbersFilter,
    potentialUpside: defaultPotentialUpsideFilter,
  };
  const defaultSwitchFilters = {
    filters: [],
    topTenants: null,
    topOwners: null,
    topUnits: null,
    topCreditors: null,
    topUnitsRentalYield: null,
    topTenantsIncome: null,
  };
  const [dashboardFilters, setDashboardFilters] = useState<DashboardFilters>(defaultDashboardFilters);
  const [switchFilters, setSwitchFilters] = useState<DashboardSwitchFilters>(defaultSwitchFilters);

  useEffect(() => {
    const initializeFilters = async () => {
      const cachedDashboardFilters = await getCacheItem('dashboardFilters');
      const cachedSwitchFilters = await getCacheItem('switchFilters');
      setDashboardFilters(
        cachedDashboardFilters ? { ...cachedDashboardFilters, filters: [] } : defaultDashboardFilters
      );
      setSwitchFilters(cachedSwitchFilters ? { ...cachedSwitchFilters, filters: [] } : defaultSwitchFilters);
    };
    initializeFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const saveLayout = async () => {
      await setCacheItem(DashboardBoxType.TO_DO, layoutToDo);
    };
    saveLayout();
  }, [layoutToDo]);

  useEffect(() => {
    const setLayoutOnMount = async () => {
      if (layoutToDoDidMount) return;
      const toDoBoxOrder = (await getBoxOrder(DashboardBoxType.TO_DO)) as SavedBoxItem[];
      setLayoutToDo(toDoBoxOrder);
    };
    setLayoutOnMount();
  }, [layoutToDoDidMount]);

  const updateDashboardFilters = async (variables: DashboardFilters) => {
    setDashboardFilters(variables);
    await setCacheItem('dashboardFilters', variables);
  };

  const updateSwitchFilters = async (variables: DashboardSwitchFilters) => {
    setSwitchFilters(variables);
    await setCacheItem('switchFilters', variables);
  };

  const resetDashboardFilters = async (name?: keyof DashboardFilters) => {
    let defaultValue: DashboardFilters | null = null;
    switch (name) {
      case 'tickets':
        defaultValue = { ...dashboardFilters, tickets: defaultTicketsMetricFilter };
        break;
      case 'occupancy':
        defaultValue = { ...dashboardFilters, occupancy: defaultOccupancyFilter };
        break;
      case 'latePayments':
        defaultValue = { ...dashboardFilters, latePayments: defaultLatePaymentsFilter };
        break;
      case 'rentalIncome':
        defaultValue = { ...dashboardFilters, rentalIncome: defaultRentalIncomeFilter };
        break;
      case 'keyNumbers':
        defaultValue = { ...dashboardFilters, keyNumbers: defaultKeyNumbersFilter };
        break;
      case 'filters':
        defaultValue = { ...dashboardFilters, filters: [] };
        break;
      case 'potentialUpside':
        defaultValue = { ...dashboardFilters, rentalIncome: defaultPotentialUpsideFilter };
        break;
      default:
        defaultValue = defaultDashboardFilters;
        break;
    }
    await updateDashboardFilters(defaultValue);
  };

  function resetSwitchFilters(name?: keyof DashboardSwitchFilters) {
    let defaultValue: DashboardSwitchFilters | null = null;
    switch (name) {
      default:
        defaultValue = defaultSwitchFilters;
        break;
    }
    updateSwitchFilters(defaultValue);
  }

  const filteredEntities = useMemo(() => {
    if (buildingsLoading || unitsLoading || contactsLoading)
      return { filteredUnits: [], filteredBuildings: [], filteredOwners: [] };
    return getDashboardFiltersValues(
      dashboardFilters?.filters ?? [],
      units,
      getUnit,
      buildings,
      getBuilding,
      owners,
      getContact
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardFilters, units, buildings, owners, unitsLoading, buildingsLoading, contactsLoading]);

  const saveNewOrder = async (elements: { key: React.ReactText }[], dashboardType: DashboardBoxType) => {
    const initialOrder = (await getBoxOrder(dashboardType)) as BoxSetup[];

    const newOrder = elements.map((e) => {
      const box = initialOrder.find((b) => b.key === e.key);
      return box;
    });
    await setCacheItem(dashboardType, newOrder);
  };

  const saveNewLayout = async (newLayout: Layout[] | SavedBoxItem[], dashboardType: DashboardBoxType) => {
    await setCacheItem(dashboardType, newLayout);
  };

  const getBoxOrder = async (dashboardType: DashboardBoxType): Promise<BoxSetup[] | Layout[]> => {
    const boxOrder = await getCacheItem(dashboardType);

    if (!isNil(boxOrder) && boxOrder.length > 0) {
      return boxOrder;
    }
    if (dashboardType === DashboardBoxType.METRIC) {
      return defaultMetricBoxOrder;
    }
    if (dashboardType === DashboardBoxType.RANKING) {
      return defaultRankingBoxOrder;
    }
    if (dashboardType === DashboardBoxType.TO_DO) {
      return defaultToDoBoxOrder;
    }
    return defaultMetricBoxOrder;
  };

  const getBoxCollapse = async (key: string, boxType: DashboardBoxType) => {
    const boxOrder = await getBoxOrder(boxType);
    if (boxType === DashboardBoxType.METRIC || boxType === DashboardBoxType.RANKING) {
      const box = (boxOrder as Layout[]).find((box) => box.i === key);
      if (isNil(box)) {
        return false;
      }
      return box.h === LAYOUT_BOX_CLOSE_HEIGHT;
    }
    const box = (boxOrder as SavedBoxItem[]).find((box) => box.i === key);
    if (isNil(box)) {
      return false;
    }
    return box.collapse;
  };

  const getHeight = (boxType: DashboardBoxType, collapsed: boolean) => {
    if (collapsed) {
      switch (boxType) {
        case DashboardBoxType.METRIC:
        case DashboardBoxType.RANKING:
          return LAYOUT_BOX_CLOSE_HEIGHT;
        case DashboardBoxType.TO_DO:
          return 6.5;
      }
    }

    switch (boxType) {
      case DashboardBoxType.METRIC:
        return LAYOUT_BOX_METRIC_OPEN_HEIGHT;
      case DashboardBoxType.RANKING:
        return LAYOUT_BOX_RANKING_OPEN_HEIGHT;
      case DashboardBoxType.TO_DO:
        return 30;
    }
  };

  const saveNewBoxCollapse = async (
    key: string,
    collapse: boolean,
    boxType: DashboardBoxType,
    forceUpdateLayout?: (layout: Layout[]) => void,
    forcedHeight?: number
  ) => {
    const boxOrder = await getBoxOrder(boxType);
    const newLayout = [...(boxOrder as Layout[])];
    const elementIndex = newLayout.findIndex((element) => element.i === key);
    newLayout[elementIndex] = {
      ...newLayout[elementIndex],
      ...(boxType === DashboardBoxType.TO_DO ? { collapse } : {}),
      h: !isNil(forcedHeight) ? forcedHeight : getHeight(boxType, collapse),
    };
    if (forceUpdateLayout) {
      forceUpdateLayout(newLayout);
    }

    await setCacheItem(boxType, newLayout);
  };

  const saveNewBoxToDoLayout = (key: string, changeCollapse?: boolean, forcedHeight?: number) => {
    const box = layoutToDo.find((box) => box.i === key);
    const currentCollapse = Boolean(box?.collapse);
    const collapsed = changeCollapse ? !currentCollapse : currentCollapse;
    const newLayout = [...layoutToDo];
    const elementIndex = newLayout.findIndex((element) => element.i === key);
    const updateElement = (newLayout[elementIndex] = {
      ...newLayout[elementIndex],
      ...{ collapse: collapsed },
      h: collapsed
        ? getHeight(DashboardBoxType.TO_DO, collapsed)
        : !isNil(forcedHeight)
        ? forcedHeight
        : getHeight(DashboardBoxType.TO_DO, collapsed),
    });
    setLayoutToDo((prevLayout) => {
      const newLayout = [...prevLayout];
      newLayout[elementIndex] = updateElement;
      return newLayout;
    });
  };

  const setLayoutOnLayoutChange = (currentLayout: Layout[]) => {
    setLayoutToDo((prevLayout) => {
      const adaptedLayout = currentLayout.map((item, index) => {
        return {
          ...item,
          collapse: prevLayout[index].collapse,
        } as SavedBoxItem;
      });
      return adaptedLayout;
    });
  };

  const values = useMemo(
    () => ({
      dashboardFilters,
      updateDashboardFilters,
      resetDashboardFilters,
      switchFilters,
      updateSwitchFilters,
      resetSwitchFilters,
      getBoxOrder,
      saveNewOrder,
      saveNewLayout,
      getBoxCollapse,
      saveNewBoxCollapse,
      ...filteredEntities,
      layoutToDo,
      saveNewBoxToDoLayout,
      setLayoutToDoDidMount,
      setLayoutOnLayoutChange,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dashboardFilters, switchFilters, filteredEntities]
  );

  return <DashboardFiltersContext.Provider value={values}>{children}</DashboardFiltersContext.Provider>;
};

export const useDashboardFilters = (): DashboardFiltersContext => {
  const context = useContext<DashboardFiltersContext | null>(DashboardFiltersContext);

  if (context === undefined) {
    throw new Error('`useFilters` hook must be used within a `FiltersContextProvider` component');
  }
  return context as DashboardFiltersContext;
};
