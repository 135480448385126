import { Colors, CommunicationSettingsProfile } from '@rentguru/commons-utils';
import { useIntl } from 'react-intl';
import { Divider, Grid, MenuItem, Typography } from '@material-ui/core';
import { ActionButton , LoaderButton, ContentHeader } from '@up2rent/ui';
import { toUpper } from 'lodash';
import { useSnackbar } from 'notistack';

import { useState } from 'react';
import { CustomizedSelect } from 'src/components/ui/CustomizedSelect';
import { useCommunicationSettingsProfiles } from 'src/hooks/CommunicationSettingsProfilesContext';

type DeleteCommunicationSettingsProfileDialogProps = {
  profiles: { profileToDelete: CommunicationSettingsProfile; profilesForReplacement: CommunicationSettingsProfile[] };
  closeDialog: () => void;
};

const DeleteCommunicationSettingsProfileDialog: React.FC<DeleteCommunicationSettingsProfileDialogProps> = ({
  profiles,
  closeDialog,
}) => {
  const { formatMessage } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { deleteCommunicationSettingsProfile } = useCommunicationSettingsProfiles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedReplacementProfileId, setSelectedReplacementProfileId] = useState<string>(
    profiles.profilesForReplacement[0].id
  );

  const { profileToDelete, profilesForReplacement } = profiles;

  const handleDelete = async () => {
    setIsLoading(true);
    await deleteCommunicationSettingsProfile(profileToDelete, selectedReplacementProfileId);
    setIsLoading(false);
    closeDialog();
    enqueueSnackbar(formatMessage({ id: 'settings.changesSaved' }), { variant: 'success' });
  };

  const referentToDisplay = profileToDelete.referent.toLocaleLowerCase();
  const deletedProfileNameToDisplay = <b>{profileToDelete.defaultName}</b>;
  const replacementProfileNameToDisplay = (
    <b>{profilesForReplacement.find((profile) => profile.id === selectedReplacementProfileId)!.defaultName}</b>
  );

  return (
    <Grid style={{ width: 600 }}>
      <ContentHeader title={formatMessage({ id: 'settings.communications.deleteProfile.title' })} />
      <Divider style={{ marginBottom: 20 }} />
      <Grid style={{ marginLeft: 30, marginRight: 30 }}>
        <Typography>
          {
            formatMessage(
              {
                id: 'settings.communications.deleteProfile.info1',
              },
              {
                deletedProfileName: deletedProfileNameToDisplay,
              }
            ) as React.ReactNode
          }
        </Typography>
        <Typography>
          {
            formatMessage(
              {
                id: 'settings.communications.deleteProfile.info2',
              },
              {
                referent: referentToDisplay,
                deletedProfileName: deletedProfileNameToDisplay,
              }
            ) as React.ReactNode
          }
        </Typography>
        <CustomizedSelect
          value={selectedReplacementProfileId}
          handleChange={(value) => {
            setSelectedReplacementProfileId(value.target.value);
          }}
          FormControlProps={{ style: { width: '100%' } }}
        >
          {profilesForReplacement.map((profile) => (
            <MenuItem key={profile.id} value={profile.id}>
              {profile.defaultName}
            </MenuItem>
          ))}
        </CustomizedSelect>
        <Typography>
          {
            formatMessage(
              {
                id: 'settings.communications.deleteProfile.info3',
              },
              {
                referent: profileToDelete!.referent.toLocaleLowerCase(),
                deletedProfileName: deletedProfileNameToDisplay,
                replacementProfileName: replacementProfileNameToDisplay,
              }
            ) as React.ReactNode
          }
        </Typography>
      </Grid>
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      <Grid
        style={{
          marginRight: 30,
          marginBottom: 20,
          display: 'flex',
          float: 'right',
          alignItems: 'center',
        }}
      >
        <ActionButton
          onClick={closeDialog}
          style={{
            background: 'none',
            color: Colors.DARK_SLATE_GREY,
            marginRight: 20,
          }}
        >
          {toUpper(
            formatMessage({
              id: 'cancel',
            })
          )}
        </ActionButton>
        <LoaderButton onClick={() => handleDelete()} loading={isLoading}>
          {formatMessage({
            id: 'settings.communications.deleteProfile.title',
          })}
        </LoaderButton>
      </Grid>
    </Grid>
  );
};

export default DeleteCommunicationSettingsProfileDialog;
