import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Colors, getTotalCreditsBalance } from '@rentguru/commons-utils';
import { ColoredBox } from '@up2rent/ui';
import { useIntl } from 'react-intl';
import SkeletonComponent from 'src/components/ui/SkeletonComponent';
import { useClient } from 'src/hooks/ClientContext';
import { ReactComponent as Credits } from 'src/icons/credits.svg';

const useStyles = makeStyles({
  creditsTitle: {
    color: Colors.CLASSICAL_BLACK,
    fontSize: 15,
    fontWeight: 600,
  },
  creditsDetails: {
    fontSize: 14,
    fontWeight: 400,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'right',
    color: Colors.SLATE_GREY,
  },
});

const CreditsBalance = () => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { applicationSubscription, loading } = useClient();

  if (loading || !applicationSubscription) {
    return <SkeletonComponent loading />;
  }

  return (
    <Grid container alignItems="center" style={{ paddingLeft: 24 }}>
      <Grid item>
        <Typography className={classes.creditsTitle}>
          {formatMessage({ id: 'settings.subscription.creditsBalance' })}
        </Typography>
      </Grid>
      <Grid item style={{ marginLeft: 10 }}>
        <ColoredBox boxColor={Colors.SILVER} boxStyle={{ display: 'flex', width: 70 }}>
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            <Credits style={{ fill: Colors.DODGER_BLUE, width: 16, height: 16, marginRight: 10 }} />
            <Typography className={classes.creditsDetails}>
              {getTotalCreditsBalance(applicationSubscription)}
            </Typography>
          </Grid>
        </ColoredBox>
      </Grid>
    </Grid>
  );
};

export default CreditsBalance;
