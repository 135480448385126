import React from 'react';
import { useIntl } from 'react-intl';
import { Formik, FormikHelpers, Form } from 'formik';
import * as Yup from 'yup';

import { useUser } from 'src/hooks/UserContext';
import { Typography, TextField, Grid } from '@material-ui/core';
import { ElevatedPaper, LoaderButton } from '@up2rent/ui';
import { parseInvalidPasswordException, parseInvalidParameterException } from './Signup';
import { authStyles } from './authStyles';

const NewPasswordSchema = Yup.object().shape({
  password: Yup.string().min(8).required(),
});

interface NewPasswordValues {
  password: string;
  defaultErrorMessage?: string;
}

const initialValues: NewPasswordValues = {
  password: '',
};

const NewPassword: React.FC = () => {
  const { formatMessage } = useIntl();
  const { completeNewPassword } = useUser();
  const styles = authStyles();

  const handleSubmit = async (
    values: NewPasswordValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<NewPasswordValues>
  ) => {
    try {
      // Confirmation
      await completeNewPassword(values.password);
      setStatus(true);
      setSubmitting(false);
    } catch (error) {
      const err: Error = error as Error;
      console.error('err', err);

      if (err.name === 'InvalidPasswordException') {
        setErrors({ password: parseInvalidPasswordException(err), defaultErrorMessage: err.message });
      } else if (err.name === 'InvalidParameterException') {
        setErrors({ password: parseInvalidParameterException(err), defaultErrorMessage: err.message });
      } else {
        setErrors({ password: err.name, defaultErrorMessage: err.message });
      }
      setStatus(true);
      setSubmitting(false);
    }
  };

  return (
    <Grid alignContent="center" alignItems="center" container direction="column">
      <Formik
        initialValues={initialValues}
        validationSchema={NewPasswordSchema}
        onSubmit={handleSubmit}
        validateOnChange
      >
        {({ values, errors, touched, handleChange, handleBlur, isSubmitting, status }) => (
          <ElevatedPaper className={styles.mainContainer}>
            <Form>
              <Typography variant="h5" paragraph component="h2" gutterBottom>
                {formatMessage({ id: 'login.password' })}
              </Typography>

              <TextField
                label={formatMessage({ id: 'signup.passwordLabel' })}
                id="password"
                type="password"
                autoComplete="current-password"
                margin="normal"
                variant="filled"
                fullWidth
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                required={true}
                error={Boolean(errors.password && touched.password)}
                helperText={
                  errors.password && touched.password
                    ? formatMessage({
                        id: `error.${errors.password}`,
                        defaultMessage: errors.defaultErrorMessage
                          ? errors.defaultErrorMessage
                          : formatMessage({ id: 'signup.passwordHelp' }),
                      })
                    : formatMessage({ id: 'signup.passwordHelp' })
                }
              />

              <LoaderButton loading={isSubmitting} success={status}>
                {formatMessage({
                  id: 'save',
                })}
              </LoaderButton>
            </Form>
          </ElevatedPaper>
        )}
      </Formik>
    </Grid>
  );
};

export default NewPassword;
