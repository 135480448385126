import { Typography, Divider } from '@material-ui/core';
import { Colors, Technic } from '@rentguru/commons-utils';
import { Form, Formik, FormikHelpers } from 'formik';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import toUpper from 'lodash/toUpper';
import React from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { ActionButton , LoaderButton } from '@up2rent/ui';
import SimpleTechnicSelector from '../SimpleSelectors/SimpleTechnicSelector';
import Dialog from 'src/components/ui/Dialog';

const SelectTechnicSchema = Yup.object().shape({
  technic: Yup.object().required(),
});

interface SelectTechnicValue {
  technic: Technic | null;
}

interface TechnicSelectorDialogProps {
  technics: Technic[];
  open: boolean;
  title?: string;
  onClose: () => void;
  afterSubmit: (technic: Technic) => void;
}
const TechnicSelectorDialog: React.FC<TechnicSelectorDialogProps> = ({
  technics,
  open,
  onClose,
  afterSubmit,
  title,
}) => {
  const { formatMessage } = useIntl();
  const handleSubmit = (
    values: SelectTechnicValue,
    { setSubmitting, setStatus }: FormikHelpers<SelectTechnicValue>
  ): void => {
    afterSubmit(values.technic!);
    setStatus(true);
    setSubmitting(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} scroll="paper" fullWidth PaperProps={{ style: { borderRadius: 10 } }}>
      <Formik
        initialValues={
          {
            technic: null,
          } as SelectTechnicValue
        }
        validationSchema={SelectTechnicSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, status }) => {
          return (
            <Form>
              <div style={{ marginLeft: 20, marginRight: 20, marginTop: 25, marginBottom: 25 }}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: 20,
                    textAlign: 'left',
                  }}
                >
                  {!isNil(title) ? title : 'Select your technic'}
                </Typography>
              </div>
              <Divider style={{ marginBottom: 10 }} />

              <SimpleTechnicSelector
                fieldName="technic"
                technics={technics}
                disabled={isEmpty(technics)}
                style={{ marginLeft: 20 }}
                inputWidth={560}
              />

              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <div
                style={{
                  marginBottom: 20,
                  marginRight: 30,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <ActionButton
                  onClick={() => {
                    onClose();
                  }}
                  style={{ background: 'none', color: Colors.DARK_SLATE_GREY, marginRight: 20 }}
                >
                  {toUpper(formatMessage({ id: 'cancel' }))}
                </ActionButton>
                <LoaderButton loading={isSubmitting} success={status}>
                  {formatMessage({
                    id: 'save',
                  })}
                </LoaderButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default TechnicSelectorDialog;
