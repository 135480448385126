/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { isNil, get, isString } from 'lodash';
import { friendlyFormatIBAN } from 'ibantools';
import { useFormikContext } from 'formik';
import {
  Colors,
  contactContainsType,
  getContactNameFromObject,
  isNilOrEmpty,
  stringToColor,
  BankAccount,
  ContactType,
} from '@rentguru/commons-utils';
import { useBankAccounts } from 'src/hooks/BankAccountsContext';
import { useContacts } from 'src/hooks/ContactsContext';
import { ReactComponent as BankAccountIcon } from 'src/icons/bank.svg';
import { getSafeValueInObject } from 'src/utils/object';
import { CustomInputItemType } from 'src/components/ui/ComboBox/FullCustomComboBox';
import CustomInputComboBox from 'src/components/ui/ComboBox/FormikFullCustomComboBox';
import { useCustomizedComboStyles } from 'src/components/ui/ComboBox/TextComboBox';
import { AddEditBankAccountDialog } from 'src/components/ui/Dialogs/AddEditBankAccountDialog';
import { AddLeaseFormValues } from 'src/components/Leases/AddLease/AddLeaseForm';
import EditOrAddIconButton from './utils/EditOrAddIconButton';

interface SimpleBankAccountSelectorProps {
  fieldLabel: string;
  groupValues?: boolean;
  name?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  inputStyle?: React.CSSProperties;
  inputWidth?: number;
  ownerId?: string;
}

const getDefaultBankAccount = (ownerAccounts: BankAccount[], activeBankAccounts: BankAccount[]) => {
  if (isNilOrEmpty(ownerAccounts) && activeBankAccounts.length === 1) {
    return activeBankAccounts[0];
  }
  if (!isNilOrEmpty(ownerAccounts) && ownerAccounts.length === 1) {
    return ownerAccounts[0];
  }
  return undefined;
};

const SimpleBankAccountSelector: React.FC<SimpleBankAccountSelectorProps> = ({
  inputStyle,
  groupValues = false,
  fieldLabel,
  inputWidth = 560,
  disabled = false,
  name = 'bankAccountId',
  style = {},
  ownerId = '',
}) => {
  const comboBoxClasses = useCustomizedComboStyles();
  const { values, errors, touched, setFieldValue } = useFormikContext<AddLeaseFormValues>();
  const [dialogAddOpen, setDialogAddOpen] = useState(false);
  const { loading, bankAccounts } = useBankAccounts();
  const { formatMessage } = useIntl();
  const { getContact, contactsLoading } = useContacts();

  const handleAddDialogOpen = () => {
    setDialogAddOpen(true);
  };

  const handleAddDialogClose = (bankAccountId: string) => {
    setDialogAddOpen(false);
    if (bankAccountId && isString(bankAccountId)) {
      setFieldValue(name, bankAccountId);
    }
  };

  if (loading || contactsLoading) {
    return <Typography>{formatMessage({ id: 'loading' })}</Typography>;
  }

  let ownerAccounts: BankAccount[] = [];
  if (!isNilOrEmpty(ownerId)) {
    const ownerIds = [ownerId];
    const owner = getContact(ownerId);
    const isContactJointOwnership = contactContainsType(owner!, ContactType.JOINT_OWNERSHIP);
    if (isContactJointOwnership) {
      ownerIds.push(...(owner?.jointOwners?.map((owner) => owner.contactId) ?? []));
    }
    ownerAccounts = bankAccounts.filter((bankAccount) => {
      const contact = getContact(bankAccount.contactId);
      return (
        contact &&
        (ownerIds.includes(contact!.id) || contactContainsType(contact!, ContactType.CLIENT)) &&
        !bankAccount.disabled
      );
    });
  }

  const activeBankAccounts = bankAccounts.filter((bankAccount) => {
    return !bankAccount.disabled;
  });

  const bankAccountToCustomComboItem = (bankAccount: BankAccount) => {
    const primary = bankAccount.contact ? getContactNameFromObject(bankAccount.contact) : '';
    const secondary = `${friendlyFormatIBAN(bankAccount.number, ' ')}${
      bankAccount.bankName ? ` - ${bankAccount.bankName}` : ''
    }`;
    const color = primary !== '' ? stringToColor(primary) : stringToColor(secondary);
    const contact = getContact(bankAccount.contactId);
    return {
      primary,
      secondary,
      color,
      value: bankAccount.id,
      type: groupValues
        ? contactContainsType(contact!, ContactType.CLIENT)
          ? formatMessage({ id: 'lease.addLease.organization' })
          : formatMessage({ id: 'lease.addLease.unitOwner' })
        : '',
    };
  };

  const defaultBankAccount = getDefaultBankAccount(ownerAccounts, activeBankAccounts);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (defaultBankAccount && isNilOrEmpty(get(values, name))) {
      setFieldValue(name, defaultBankAccount.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultBankAccount]);

  const emptyContact = !isNil(defaultBankAccount)
    ? bankAccountToCustomComboItem(defaultBankAccount)
    : {
        primary: '',
        secondary: '',
        color: null,
        value: null,
      };
  const bankAccountIdSelected: string = getSafeValueInObject(values, name);
  const bankAccountSelected = !isNil(bankAccountIdSelected)
    ? bankAccounts.find((c) => c.id === bankAccountIdSelected)
    : undefined;
  const options = isNilOrEmpty(ownerAccounts)
    ? activeBankAccounts.map((b) => bankAccountToCustomComboItem(b))
    : ownerAccounts.map((b) => bankAccountToCustomComboItem(b));

  return (
    <Grid
      style={{
        alignItems: 'baseline',
        display: 'flex',
        ...style,
      }}
    >
      <CustomInputComboBox
        name={name}
        value={!isNilOrEmpty(bankAccountSelected) ? bankAccountToCustomComboItem(bankAccountSelected) : emptyContact}
        label={fieldLabel}
        options={options.sort((a, b) => (a.type > b.type ? 1 : -1))}
        fieldToReturn="value"
        id="bankAccountId"
        emptyValue={emptyContact}
        inputStyle={{ width: inputWidth, ...inputStyle }}
        renderOption={(value: CustomInputItemType) => {
          return (
            <Grid container style={{ marginBottom: '5px', marginTop: '5px', alignItems: 'center' }}>
              <Grid item xs={6} style={{ display: 'flex' }}>
                <BankAccountIcon
                  style={{
                    width: 15,
                    height: 15,
                    marginRight: 20,
                    fill: Colors.TOWER_GREY,
                  }}
                />
                <Typography className={comboBoxClasses.primaryText}>{value.primary}</Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                {!isNil(value.secondary) && (
                  <Typography className={comboBoxClasses.secondaryText}>{value.secondary}</Typography>
                )}
              </Grid>
            </Grid>
          );
        }}
        renderInput={(value: CustomInputItemType) => {
          return (
            <>
              <BankAccountIcon
                style={{
                  width: 15,
                  height: 15,
                  marginRight: 20,
                  fill: Colors.TOWER_GREY,
                }}
              />
              <Typography
                style={{
                  fontSize: 12,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  maxWidth: `calc(${inputWidth}px - 85px)`,
                }}
              >{`${value.primary} - ${value.secondary}`}</Typography>
            </>
          );
        }}
        groupBy="type"
        inputAdornment={
          <EditOrAddIconButton disabled={disabled} onClick={() => handleAddDialogOpen()} isSelected={false} />
        }
        stringToCompare={(value: CustomInputItemType) => `${value.primary}${value.secondary}`}
        error={Boolean(get(errors, name) && get(touched, name))}
        noOptionsText={formatMessage({ id: 'comboBox.noBankAccounts' })}
        disabled={disabled}
      />
      {dialogAddOpen && (
        <AddEditBankAccountDialog
          afterSubmit={handleAddDialogClose}
          onClose={() => setDialogAddOpen(false)}
          open={dialogAddOpen}
        />
      )}
    </Grid>
  );
};

export default SimpleBankAccountSelector;
