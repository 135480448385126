import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { Avatar, MenuItem, Menu, Typography } from '@material-ui/core';
import {
  AgencyRate,
  AgencyRateAmountDetailType,
  Colors,
  formatNumber,
  getFirstLettersUppercase,
  stringToColor,
} from '@rentguru/commons-utils';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as Euro } from 'src/icons/euros.svg';
import { ReactComponent as Percent } from 'src/icons/percent.svg';
import { MenuItemText } from '@up2rent/ui';
import MoreHorizontalIconButton from 'src/components/ui/CustomIconButtons/MoreHorizontalIconButton';
import SettingBoxDetailLine from 'src/components/Settings/SettingBoxDetailLine';

interface AgencyRateBoxProps {
  agencyRate: AgencyRate;
  doAction: (action: 'EDIT' | 'DELETE' | 'DELETE_OWNER_FROM_RATE', id: string) => void;
  allowToUnasign?: boolean;
}
const AgencyRateBox: React.FC<AgencyRateBoxProps> = ({ agencyRate, doAction, allowToUnasign }) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const { agencyRateWrite, agencyRateDelete } = usePermissions();
  const [anchorMore, setAnchorMore] = useState<HTMLElement | null>(null);

  const { id, name, owners, amountDetails, amountMinimumFees } = agencyRate;
  const color = stringToColor(name);
  const canDelete = agencyRateDelete && (!owners || isEmpty(owners) || agencyRate.custom);

  return (
    <div
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        doAction('EDIT', id);
      }}
      style={{
        width: 280,
        height: 300,
        border: `1px solid ${Colors.SILVER}`,
        borderRadius: 10,
        cursor: 'pointer',
        backgroundColor: 'white',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 10,
          paddingTop: 10,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignSelf: agencyRate.custom ? 'normal' : 'flex-end',
          }}
        >
          {agencyRate.custom && (
            <div
              style={{
                borderRadius: 4,
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: Colors.LIGHT_BLUE_GREY,
                padding: 8,
              }}
            >
              <Typography
                style={{
                  fontSize: 10,
                  fontWeight: 800,
                  fontStyle: 'normal',
                  letterSpacing: 0,
                  color: Colors.BLUEY,
                  textTransform: 'uppercase',
                }}
              >
                {formatMessage({ id: 'agencyRates.custom' })}
              </Typography>
            </div>
          )}
          {agencyRateWrite && (
            <>
              <MoreHorizontalIconButton
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  setAnchorMore(event.currentTarget);
                }}
              />
              <Menu
                id="more-menu"
                anchorEl={anchorMore}
                keepMounted
                open={Boolean(anchorMore)}
                onClose={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  event.stopPropagation();
                  event.preventDefault();
                  setAnchorMore(null);
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setAnchorMore(null);
                    doAction('EDIT', id);
                  }}
                >
                  <MenuItemText primary={formatMessage({ id: 'edit' })} />
                </MenuItem>
                {allowToUnasign && !agencyRate.custom && (
                  <MenuItem
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      setAnchorMore(null);
                      doAction('DELETE_OWNER_FROM_RATE', id);
                    }}
                  >
                    <MenuItemText primary={formatMessage({ id: 'agencyRates.deleteAgencyRateOwner.title' })} />
                  </MenuItem>
                )}
                {canDelete && (
                  <MenuItem
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      setAnchorMore(null);
                      doAction('DELETE', id);
                    }}
                  >
                    <MenuItemText primary={formatMessage({ id: 'delete' })} deleteOption />
                  </MenuItem>
                )}
              </Menu>
            </>
          )}
        </div>
        <Avatar
          style={{
            color: `${color}`,
            backgroundColor: Colors.PORCELAIN_GREY_3,
            width: 50,
            height: 50,
            fontSize: 17,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {getFirstLettersUppercase(name)}
        </Avatar>
        <div style={{ display: 'flex', marginTop: 15, justifyContent: 'center' }}>
          <Typography style={{ fontWeight: 600, fontSize: 16, textAlign: 'left' }}>{name}</Typography>
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: 20,
            marginBottom: 10,
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <SettingBoxDetailLine
            text={formatMessage({ id: 'agencyRates.calculation' })}
            IconValue={amountDetails[0].amountType === AgencyRateAmountDetailType.PRICE ? Euro : Percent}
          />
          <SettingBoxDetailLine text={formatMessage({ id: 'agencyRates.owners' })} value={`${owners?.length || 0}`} />
          <SettingBoxDetailLine
            text={formatMessage({ id: 'agencyRates.fees' })}
            value={formatNumber(amountMinimumFees || amountDetails[0].feeApplied, language, {
              style: 'currency',
              currency: 'EUR',
              maximumSignificantDigits: 1,
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default AgencyRateBox;
