/* eslint-disable @typescript-eslint/no-shadow */
import { Divider, Grid, Typography } from '@material-ui/core';
import {
  ACCOUNT_LEASE_TOPCLASS,
  ACCOUNT_TURNOVER_FIXED_CHARGES_CLASS,
  ACCOUNT_TURNOVER_FURNISHED_RENT_CLASS,
  ACCOUNT_TURNOVER_RENT_CLASS,
  Invoice,
  InvoiceType,
  InvoiceWithPostings,
  LeaseExtended,
  PostingType,
  calculateAmountLeftToPayOfInvoice,
  endOfUTCMonth,
  getExcludedVatAmountFromIncludedVatAmount,
  getPostingAmount,
  postingNeedsToBePaid,
  roundAtSecondDecimal,
  startOfUTCMonth,
} from '@rentguru/commons-utils';
import { CustomSimpleDialog } from '@up2rent/ui';
import { compareDesc, isAfter, subYears } from 'date-fns';
import { Form, Formik, FormikHelpers } from 'formik';
import { isEmpty, isNil, minBy, sumBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { PostingWithAmountSelected } from 'src/components/Accounting/Transactions/Reconciliation/LeaseTransactionsReconcilation';
import { linkInvoicesWithCreditNotes } from 'src/components/Accounting/Transactions/Reconciliation/reconciliationsUtils';
import { useInvoices } from 'src/hooks/InvoicesContext';
import { fetchPostings, useTransactions } from 'src/hooks/TransactionsContext';
import * as Yup from 'yup';
import InvoiceAndPostingsCheckBoxes, { InvoicePosting } from '../Forms/FormField/InvoiceAndPostingsCheckBoxes';
import TextDetailEditable from '../TextDetailEditable';

interface AddCreditNoteDialogProps {
  open: boolean;
  lease: LeaseExtended;
  onClose: () => void;
  defaultInvoices?: InvoiceWithPostings[];
}

const AddCreditNoteDialogSchema = Yup.object().shape({
  label: Yup.string().min(1).required(),
  invoicePostings: Yup.array().of(
    Yup.object().shape({
      amount: Yup.number().min(1).required(),
      posting: Yup.object().required(),
    })
  ),
});

interface AddCreditNoteDialogValues {
  label: string;
  invoicePostings: InvoicePosting[];
}

const getOldestPeriodFrom = (invoiceWithPostings: InvoiceWithPostings) =>
  minBy(invoiceWithPostings.postings, 'periodFrom')?.periodFrom ?? '';

const getComparableDate = (invoiceWithPostings: InvoiceWithPostings) =>
  new Date(
    invoiceWithPostings.type === 'RENT' ? getOldestPeriodFrom(invoiceWithPostings) : invoiceWithPostings.invoiceDate
  );

const AddCreditNoteDialog: React.FC<AddCreditNoteDialogProps> = ({ lease, open, onClose, defaultInvoices }) => {
  const { formatMessage } = useIntl();
  // Keep only the invoices of one year ago
  const oneYearAgo = subYears(new Date(), 1);
  const { getInvoicesOfLease, loading: invoicesLoading, createInvoice, updateInvoice } = useInvoices();
  const {
    createTransaction,
    updateTransaction,
    createPosting,
    getAccountLabelForLease,
    getAccountLabelForBankAccountOrInsert,
  } = useTransactions();
  const [invoicesWithPostings, setInvoicesWithPostings] = useState<InvoiceWithPostings[]>([]);

  const invoicesOfLease = getInvoicesOfLease(lease.id);
  const leaseInvoicesOfLatestYearWithoutCreditNote = invoicesOfLease.filter(
    (i) => !i.creditNote && isAfter(new Date(i.createdAt ?? ''), oneYearAgo)
  );

  useEffect(() => {
    const buildInvoicesWithPostings = async () => {
      if (defaultInvoices) {
        const filteredInvoicesWithPostings: InvoiceWithPostings[] = defaultInvoices.map((currentInvoice) => {
          const filteredPostings = currentInvoice.postings.filter((currentPosting) =>
            postingNeedsToBePaid(currentPosting)
          );
          return { ...currentInvoice, postings: filteredPostings };
        });
        setInvoicesWithPostings(filteredInvoicesWithPostings);
        return;
      }
      const invoicesWithPostings = await Promise.all(
        leaseInvoicesOfLatestYearWithoutCreditNote.map(async (invoice) => {
          const invoicesPostings = await fetchPostings('byInvoice', invoice.id);
          const unpaidInvoicesPostings = invoicesPostings.filter((p) => postingNeedsToBePaid(p));
          return { ...invoice, postings: unpaidInvoicesPostings };
        })
      );
      setInvoicesWithPostings(invoicesWithPostings);
    };

    if (
      !invoicesLoading &&
      leaseInvoicesOfLatestYearWithoutCreditNote &&
      !isEmpty(leaseInvoicesOfLatestYearWithoutCreditNote)
    ) {
      buildInvoicesWithPostings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesLoading]);

  const handleSubmit = async (
    values: AddCreditNoteDialogValues,
    { setSubmitting, setStatus }: FormikHelpers<AddCreditNoteDialogValues>
  ): Promise<void> => {
    setSubmitting(true);
    const { label, invoicePostings } = values;
    const today = new Date();
    const invoice = invoicePostings[0].posting.invoice!;
    const totalAmount = sumBy(invoicePostings, 'amount');
    const periodFrom = startOfUTCMonth(new Date()).toISOString();
    const periodTo = endOfUTCMonth(new Date()).toISOString();
    const allPostings = await fetchPostings('byInvoice', invoice.id);
    const amountLeftToPay = calculateAmountLeftToPayOfInvoice(invoice, allPostings);

    const isAmountExceeded = invoicePostings.some((invoicePosting) => {
      const targetPostingId = invoicePosting.posting.id;
      const amountPaid = allPostings.reduce((sum, currentPosting) => {
        if (currentPosting.invoicePostingId === targetPostingId) {
          sum += getPostingAmount(currentPosting.totalAmount, currentPosting.type);
        }
        return sum;
      }, 0);
      const newTotal = amountPaid + invoicePosting.amount;
      return newTotal > invoicePosting.posting.totalAmount;
    });

    const needToReconcile = !invoice.paid && !isAmountExceeded;

    const creditNote = await createInvoice({
      amount: totalAmount,
      createdAt: today.toISOString(),
      invoiceDate: today.toISOString(),
      dueDate: today.toISOString(),
      bankAccountId: invoice.bankAccountId,
      leaseId: lease.id,
      statementId: (invoice as Invoice).statementId,
      type: invoice.type as InvoiceType,
      paid: needToReconcile,
      creditNote: true,
      creditNoteLabel: label,
    });
    const postingPromises = invoicePostings.map((invoicePosting) => {
      const { posting, amount } = invoicePosting;
      let vatRate;
      let vatAmount = 0;
      let amountVatExcluded = amount;
      if (
        (posting.class === ACCOUNT_TURNOVER_RENT_CLASS || posting.class === ACCOUNT_TURNOVER_FURNISHED_RENT_CLASS) &&
        !isNil(lease.vatRateRent)
      ) {
        vatRate = lease.vatRateRent;
        amountVatExcluded = getExcludedVatAmountFromIncludedVatAmount(amount, vatRate);
        vatAmount = roundAtSecondDecimal(amount - amountVatExcluded);
      } else if (posting.class === ACCOUNT_TURNOVER_FIXED_CHARGES_CLASS && !isNil(lease.vatRateCharge)) {
        vatRate = lease.vatRateCharge;
        amountVatExcluded = getExcludedVatAmountFromIncludedVatAmount(amount, vatRate);
        vatAmount = roundAtSecondDecimal(amount - amountVatExcluded);
      }

      return createPosting({
        class: posting.class,
        topClass: posting.topClass,
        periodFrom: posting.periodFrom,
        periodTo: posting.periodTo,
        leaseVariousOperationId: posting.leaseVariousOperationId,
        accountLabelId: posting.accountLabelId,
        bankAccountId: posting.bankAccountId,
        leaseId: lease.id,
        unitId: posting.unitId,
        invoiceId: creditNote.id,
        totalAmount: amount,
        vatRate,
        vatAmount,
        amountVatExcluded,
        type: PostingType.DEBIT,
      });
    });
    const leaseAccountLabel = getAccountLabelForLease();
    postingPromises.push(
      createPosting({
        class: ACCOUNT_LEASE_TOPCLASS,
        topClass: ACCOUNT_LEASE_TOPCLASS,
        periodFrom,
        periodTo,
        accountLabelId: leaseAccountLabel.id,
        leaseId: lease.id,
        invoiceId: creditNote.id,
        totalAmount,
        type: PostingType.CREDIT,
      })
    );
    const allNewPostings = await Promise.all(postingPromises);
    // Reconcile the credit note with corresponding postings
    if (needToReconcile) {
      const operationsBundle = {
        createPosting,
        createTransaction,
        getAccountLabelForBankAccountOrInsert,
        getAccountLabelForLease,
        updateInvoice,
        updateTransaction,
      };

      const postingWithAmountSelected = invoicePostings.map((invoicePosting) => ({
        ...invoicePosting.posting,
        amountSelected: invoicePosting.amount,
      }));
      const newPostingWithAmountSelected = allNewPostings.reduce((acc, posting) => {
        if (posting.type === PostingType.CREDIT) return acc;
        acc.push({ ...posting, amountSelected: posting.totalAmount });
        return acc;
      }, [] as PostingWithAmountSelected[]);
      const allPostingWithAmountSelected = postingWithAmountSelected.concat(newPostingWithAmountSelected);
      await linkInvoicesWithCreditNotes(
        invoice.bankAccountId,
        allPostingWithAmountSelected,
        lease.id,
        [{ ...invoice, postings: allPostings }],
        operationsBundle
      );
      // Update invoice paid status if needed
      if (amountLeftToPay === totalAmount) {
        await updateInvoice({ id: invoice.id, paid: true });
      }
    }

    setStatus(true);
    setSubmitting(false);
    onClose();
  };

  const initialValues: AddCreditNoteDialogValues = {
    label: '',
    invoicePostings: [],
  };

  if (invoicesWithPostings) {
    invoicesWithPostings.sort((i1, i2) => compareDesc(getComparableDate(i1), getComparableDate(i2)));
  }

  return (
    <Formik initialValues={initialValues} validationSchema={AddCreditNoteDialogSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, errors, touched, handleSubmit }) => {
        return (
          <Form>
            <CustomSimpleDialog
              open={open}
              title={formatMessage({ id: 'accounting.creditNote.createCreditNote' })}
              onClose={onClose}
              onActionButtonClick={handleSubmit}
              actionButtonLabel={formatMessage({ id: 'create' })}
              actionButtonDisabled={isNil(invoicesWithPostings) || isEmpty(invoicesWithPostings)}
              actionButtonLoading={isSubmitting}
              dividerBelowTitle
              formatMessage={formatMessage}
            >
              <Grid style={{ marginTop: 20 }}>
                <TextDetailEditable
                  name="label"
                  title={formatMessage({ id: 'technic.utilityTab.reason' })}
                  type="text"
                  editMode
                  style={{ maxWidth: 580 }}
                  error={Boolean(errors.label && touched.label)}
                />
                <Divider style={{ margin: '20px -24px' }} />
                {!invoicesLoading && isEmpty(invoicesWithPostings) && (
                  <>
                    <Typography style={{ fontSize: 14, fontWeight: 700, textAlign: 'center' }}>
                      {formatMessage({ id: 'transactions.lettering.letteringPlaceHolderMainText' })}
                    </Typography>
                    <Typography style={{ fontSize: 14, textAlign: 'center' }}>
                      {formatMessage({ id: 'accounting.creditNote.noInvoiceHelperSubText' })}
                    </Typography>
                  </>
                )}
                {!isEmpty(invoicesWithPostings) && (
                  <InvoiceAndPostingsCheckBoxes
                    lease={lease}
                    allInvoicesWithPostings={invoicesWithPostings}
                    invoicePostingsName="invoicePostings"
                  />
                )}
              </Grid>
            </CustomSimpleDialog>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddCreditNoteDialog;
