import React from 'react';
import { Typography, PopoverOrigin } from '@material-ui/core';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import { Colors, isNilOrEmpty } from '@rentguru/commons-utils';
import { CustomPopover } from '@up2rent/ui';

interface HelpIconPopoverProps {
  helperText?: string;
  HelperBody?: React.ReactNode;
  anchorOrigin?: PopoverOrigin;
  iconStyle?: React.CSSProperties;
  paperStyle?: React.CSSProperties;
  popoverStyle?: React.CSSProperties;
  transformOrigin?: PopoverOrigin;
  children?: React.ReactNode;
}

const HelpIconPopover: React.FC<HelpIconPopoverProps> = ({
  helperText,
  HelperBody,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  iconStyle = {},
  paperStyle = {},
  popoverStyle = {},
  transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
  children,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <>
      <InfoSvg
        style={{ fill: Colors.SILVER, cursor: 'default', ...iconStyle }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <CustomPopover
        style={popoverStyle}
        PaperProps={{ style: paperStyle }}
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={handlePopoverClose}
        disableRestoreFocus
        paperWidth={'unset'}
      >
        {helperText && isNilOrEmpty(children) && (
          <Typography style={{ whiteSpace: 'pre-line' }}>{helperText}</Typography>
        )}
        {HelperBody && isNilOrEmpty(children) && <>{HelperBody}</>}
        {!isNilOrEmpty(children) && children}
      </CustomPopover>
    </>
  );
};
export default HelpIconPopover;
