import { Divider, Grid, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Colors } from '@rentguru/commons-utils';
import React from 'react';
import { useIntl } from 'react-intl';

const useLocalStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  h6: {
    position: 'relative',
    flex: 1,
    textAlign: 'left' as const,
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Mulish',
    fontSize: '16px',
    fontWeight: 700,
    left: 5,
  },
  greenDot: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: Colors.TURQUOISE_BLUE,
  },
  redDot: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: Colors.CARNATION_RED,
  },
  dotContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dotText: {
    color: Colors.SLATE_GREY,
    fontSize: 12,
    margin: '0 30px 0 10px',
    fontFamily: 'Mulish',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
  },
});

interface FormHeaderColorDotsProps {
  title: string;
  recommendedDot?: boolean;
  mandatoryDot?: boolean;
}

const FormHeaderColorDots: React.FC<FormHeaderColorDotsProps> = ({
  title,
  recommendedDot = true,
  mandatoryDot = true,
}) => {
  const classes = useLocalStyles();
  const { formatMessage } = useIntl();

  return (
    <div className={classes.root}>
      <Toolbar>
        <Grid container direction="row">
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
            <Grid item xs={7}>
              <Typography variant="h6" color="inherit" className={classes.h6}>
                {title}
              </Typography>
            </Grid>
            <Grid item xs={5} style={{ justifyContent: 'end' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                {recommendedDot && (
                  <div className={classes.dotContainer}>
                    <div className={classes.greenDot} />
                    <span className={classes.dotText}>
                      {formatMessage({ id: 'rentalUnit.detail.coloredDots.recommended' })}
                    </span>
                  </div>
                )}

                {mandatoryDot && (
                  <div className={classes.dotContainer}>
                    <div className={classes.redDot} />
                    <span className={classes.dotText}>
                      {formatMessage({ id: 'rentalUnit.detail.coloredDots.mandatory' })}
                    </span>
                  </div>
                )}
              </div>
            </Grid>
          </div>
        </Grid>
      </Toolbar>
      <Divider style={{ marginBottom: 10 }} />
    </div>
  );
};

export default FormHeaderColorDots;
