/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import React, { useRef, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Grid, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { ReactComponent as Maps } from '../../../../icons/maps.svg';
import { useCustomizedComboStyles } from '../../ComboBox/TextComboBox';
import { isNil } from 'lodash';
import { Address } from './AddressFields';
import { CountryCode } from '../../CountrySelector';
import { LooseObject } from '@rentguru/commons-utils';
import { useLocale } from '../../../../i18n/IntlProviderWrapper';
import { useIntl } from 'react-intl';

const GOOGLE_MAPS_TOKEN = import.meta.env.VITE_GOOGLE_MAPS_TOKEN;

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  (window as any).initMap = () => {
    // JS API is loaded and available
  };

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null, place: null };

const useStyles = makeStyles(() => ({
  popupIndicatorOpen: {
    transform: 'none',
  },
}));

interface PlaceType {
  place_id: string;
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      }
    ];
  };
}

interface PlaceDetail {
  address_components: {
    long_name: string;
    short_name: string;
    types: string[];
  }[];
}

const googleToAddressMatch: LooseObject = {
  street_number: 'number',
  route: 'street',
  locality: 'city',
  country: 'country',
  postal_code: 'postalCode',
};

interface PlaceAutocompleteFieldProps {
  inputStyle?: React.CSSProperties;
  inputMargin?: 'dense' | 'normal';
  onChange?: (address: Address) => void;
}

const PlaceAutocompleteField: React.FC<PlaceAutocompleteFieldProps> = ({
  inputStyle = { width: 580 },
  onChange,
  inputMargin = 'dense',
}) => {
  const { language } = useLocale();
  const { formatMessage } = useIntl();
  const localClasses = useStyles();
  const classes = useCustomizedComboStyles();
  const [value, setValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<PlaceType[]>([]);
  const loaded = useRef(false);
  const predictionsRef = useRef(null);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        // eslint-disable-next-line max-len
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_TOKEN}&language=${language}&libraries=places&callback=initMap`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request: { input: string }, callback: (results?: PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(
          {
            ...request,
            types: ['address'],
            componentRestrictions: { country: ['be', 'fr', 'nl', 'lu', 'de', 'es', 'pt'] },
          },
          callback
        );
      }, 200),
    []
  );

  const handleAutoCompletePlaceSelected = (placeId: string) => {
    if ((window as any).google) {
      const PlacesService = new (window as any).google.maps.places.PlacesService(predictionsRef.current);
      try {
        PlacesService.getDetails(
          {
            placeId,
            fields: ['address_components'],
          },
          (place: PlaceDetail) => {
            const address: Address = {
              box: '',
              city: '',
              country: 'BE',
              number: '',
              postalCode: '',
              street: '',
            };
            for (let i = 0; i < place.address_components.length; i++) {
              const addressType = place.address_components[i].types[0];
              if (googleToAddressMatch.hasOwnProperty(addressType)) {
                const addressField: keyof Address = googleToAddressMatch[addressType] as keyof Address;
                if (addressField === 'country') {
                  const val: CountryCode = place.address_components[i].short_name as CountryCode;
                  address[addressField] = val;
                } else {
                  const val = place.address_components[i].long_name;
                  address[addressField] = val;
                }
              }
            }
            // Call on change
            if (!isNil(onChange)) {
              onChange(address);
            }
          }
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
      autocompleteService.place = new (window as any).google.maps.places.PlacesService('place-details');
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: PlaceType[]) => {
      if (active) {
        let newOptions = [] as PlaceType[];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <>
      <div ref={predictionsRef} />
      <Autocomplete
        id="google-map-autocomplete"
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        classes={{
          option: classes.option,
          popupIndicatorOpen: localClasses.popupIndicatorOpen,
        }}
        popupIcon={<Maps />}
        onChange={(_event: any, newValue: PlaceType | null) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          if (!isNil(newValue)) {
            handleAutoCompletePlaceSelected(newValue.place_id);
          }
        }}
        onInputChange={(_event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        clearOnBlur={false}
        clearOnEscape={false}
        renderInput={(params) => (
          <TextField
            {...params}
            label={formatMessage({
              id: 'contact.addContact.enterAddress',
            })}
            margin={inputMargin}
            variant="filled"
            style={{ ...inputStyle }}
            inputProps={{
              ...params.inputProps,
            }}
            InputProps={{ ...params.InputProps, classes: { underline: classes.underline } }}
            InputLabelProps={{ className: classes.label }}
            SelectProps={{ classes: { select: classes.select } }}
          />
        )}
        renderOption={(option) => {
          const matches = option.structured_formatting.main_text_matched_substrings ?? [];
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [match.offset, match.offset + match.length])
          );

          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts &&
                  parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
    </>
  );
};

export default PlaceAutocompleteField;
