import { CommunicationSettingsProfile, CommunicationSettingsProfileReferent } from '@rentguru/commons-utils';
import LeaseCommunicationSettingsForm from '../CommunicationSettingsProfileForm/LeaseCommunicationSettingsProfileForm';
import CommunicationSettingsProfileDetails from './CommunicationSettingsProfileDetails';
import { Dispatch, SetStateAction } from 'react';
import { getFormValuesFromProfile } from '../CommunicationSettingsProfileUtils/LeaseCommunicationSettingsUtils';

type LeaseCommunicationSettingsProfilesDetailsProps = {
  leaseCommunicationSettingsProfile: CommunicationSettingsProfile;
  isLastProfile: boolean;
  setDeleteProfileDialog: Dispatch<
    SetStateAction<{
      dialogOpen: boolean;
      idToDelete?: string;
      referent?: CommunicationSettingsProfileReferent;
    }>
  >;
};

const LeaseCommunicationsSettingsProfileDetails: React.FC<LeaseCommunicationSettingsProfilesDetailsProps> = ({
  leaseCommunicationSettingsProfile,
  isLastProfile,
  setDeleteProfileDialog,
}) => {
  const initialValues = getFormValuesFromProfile(leaseCommunicationSettingsProfile);
  return (
    <CommunicationSettingsProfileDetails
      initialValues={initialValues}
      CustomForm={LeaseCommunicationSettingsForm}
      profile={leaseCommunicationSettingsProfile}
      isLastProfile={isLastProfile}
      setDeleteProfileDialog={setDeleteProfileDialog}
    />
  );
};

export default LeaseCommunicationsSettingsProfileDetails;
