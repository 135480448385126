import { isNilOrEmpty, uniquePush, Contact, Building, Unit } from '@rentguru/commons-utils';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { Filter } from 'src/hooks/FiltersContext';
import { BuildingContext } from 'src/hooks/BuildingsContext';
import { FilterProposedValues } from '../DashboardFilters/DashboardFilters';
import { ContactContext } from 'src/hooks/ContactsContext';
import { UnitContext } from 'src/hooks/UnitsContext';

type PossibleDashboardFilters = 'building' | 'unit' | 'owner' | 'unitType' | 'city';

export const getDashboardFiltersValues = (
  filtersData: Filter[],
  units: Unit[],
  getUnit: UnitContext['getUnit'],
  buildings: Building[],
  getBuilding: BuildingContext['getBuilding'],
  owners: Contact[],
  getContact: ContactContext['getContact']
) => {
  let filteredBuildings: Building[] = [];
  let filteredUnits: Unit[] = [];
  let filteredOwners: Contact[] = [];
  const initialUnitTypes: string[] = [];
  units.forEach((unit) => {
    uniquePush(initialUnitTypes, unit.type);
  });
  const initialCity: string[] = [];
  buildings.forEach((building) => {
    if (building.address?.city) {
      uniquePush(initialCity, building.address.city);
    }
  });
  const proposedValues: FilterProposedValues = {
    building: buildings,
    unit: units,
    owner: owners,
    unitType: initialUnitTypes,
    city: initialCity,
  };
  const {
    building: proposedBuildings,
    unit: proposedUnits,
    owner: proposedOwners,
  } = filterProposedValuesBetweenEachOthers(proposedValues, filtersData, getBuilding, getContact, getUnit);
  /* If the filter is selected return its values, else returns its propositions */
  const filterBuilding = filtersData.find((f) => f.name === 'building');
  if (!isNil(filterBuilding)) {
    filteredBuildings = filterBuilding.items.reduce((acc: Building[], id: string) => {
      const building = getBuilding(id);
      if (!isNil(building)) acc.push(building);
      return acc;
    }, []);
  } else {
    filteredBuildings = proposedBuildings;
  }
  const filterUnits = filtersData.find((f) => f.name === 'unit');
  if (!isNil(filterUnits)) {
    filteredUnits = filterUnits.items.reduce((acc: Unit[], id: string) => {
      const unit = getUnit(id);
      if (!isNil(unit)) acc.push(unit);
      return acc;
    }, []);
  } else {
    filteredUnits = proposedUnits;
  }
  const filterOwners = filtersData.find((f) => f.name === 'owner');
  if (!isNil(filterOwners)) {
    filteredOwners = filterOwners.items.reduce((acc: Contact[], id: string) => {
      const owner = getContact(id);
      if (!isNil(owner)) acc.push(owner);
      return acc;
    }, []);
  } else {
    filteredOwners = proposedOwners;
  }
  return { filteredBuildings, filteredUnits, filteredOwners };
};

export const filterProposedValuesBetweenEachOthers = (
  proposedValues: FilterProposedValues,
  filtersData: Filter[],
  getBuilding: BuildingContext['getBuilding'],
  getContact: ContactContext['getContact'],
  getUnit: UnitContext['getUnit']
): FilterProposedValues => {
  if (isEmpty(filtersData)) {
    return proposedValues;
  }

  // We copy the proposedVlaues as we will mutate the object in the iterations
  const proposedValuesToReturn = { ...proposedValues };
  // All values can be filtered at first
  let valueNamesToReplace: PossibleDashboardFilters[] = ['building', 'unit', 'owner', 'unitType', 'city'];
  // Let's go through each filter in order!
  for (const filterData of filtersData) {
    const { name: filterName, items: filterSelectedItems } = filterData;
    /* At each iteration we will remove the current filter from the valueToReplace array. 
        This allows to keep an order between filter. Once a value has been explicitly selected by the user
         it won't be filtered anymore. */
    valueNamesToReplace = valueNamesToReplace.filter((val) => val !== filterName);
    /* Filter all the values that were selected AFTER this filter */
    for (const valueNameToReplace of valueNamesToReplace) {
      // Get the value to filter
      const valuesToReplace = proposedValuesToReturn[valueNameToReplace];
      // Filter it
      const filteredValues = filterEntityByEntity(
        filterName as PossibleDashboardFilters,
        filterSelectedItems,
        valueNameToReplace,
        valuesToReplace,
        getBuilding,
        getContact,
        getUnit
      );
      // And save it for next iteration of the filtersData loop
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      proposedValuesToReturn[valueNameToReplace] = filteredValues as any[];
    }
  }
  return proposedValuesToReturn;
};

const filterEntityByEntity = (
  filteringEntity: PossibleDashboardFilters, // The filter selected
  filteringValues: string[], // Array of id (or types for unitTypes)
  filteredEntity: PossibleDashboardFilters,
  valuesToBeFiltered: Building[] | Unit[] | Contact[] | string[],
  getBuilding: BuildingContext['getBuilding'],
  getContact: ContactContext['getContact'],
  getUnit: UnitContext['getUnit']
): Building[] | Unit[] | Contact[] | string[] => {
  if (filteringEntity === 'building') {
    if (filteredEntity === 'unit') {
      return filterUnitByBuilding(valuesToBeFiltered as Unit[], filteringValues);
    }
    if (filteredEntity === 'owner') {
      return filterOwnersByBuildings(valuesToBeFiltered as Contact[], filteringValues, getUnit);
    }
    if (filteredEntity === 'unitType') {
      return filterUnitTypesByBuilding(filteringValues, getBuilding);
    }
    if (filteredEntity === 'city') {
      return filterCitiesByBuilding(filteringValues, getBuilding);
    }
  } else if (filteringEntity === 'unit') {
    if (filteredEntity === 'owner') {
      return filterOwnersByUnits(valuesToBeFiltered as Contact[], filteringValues, getUnit);
    }
    if (filteredEntity === 'building') {
      return filterBuildingsByUnit(valuesToBeFiltered as Building[], filteringValues);
    }
    if (filteredEntity === 'unitType') {
      return filterUnitTypesByUnit(filteringValues, getUnit);
    }
    if (filteredEntity === 'city') {
      return filterCitiesByUnit(filteringValues, getUnit, getBuilding);
    }
  } else if (filteringEntity === 'owner') {
    if (filteredEntity === 'building') {
      return filterBuildingsByOwners(valuesToBeFiltered as Building[], filteringValues, getUnit);
    }
    if (filteredEntity === 'unit') {
      return filterUnitsByOwners(valuesToBeFiltered as Unit[], filteringValues, getBuilding);
    }
    if (filteredEntity === 'unitType') {
      return filterUnitTypesByOwners(filteringValues, getContact, getBuilding);
    }
    if (filteredEntity === 'city') {
      return filterCitiesByOwners(filteringValues, getContact, getBuilding);
    }
  } else if (filteringEntity === 'unitType') {
    if (filteredEntity === 'building') {
      return filterBuildingsByUnitType(valuesToBeFiltered as Building[], filteringValues);
    }
    if (filteredEntity === 'unit') {
      return filterUnitByUnitType(valuesToBeFiltered as Unit[], filteringValues);
    }
    if (filteredEntity === 'owner') {
      return filterOwnerByUnitType(valuesToBeFiltered as Contact[], filteringValues, getBuilding);
    }
    if (filteredEntity === 'city') {
      // Not possible to filter city based on unitType
    }
  } else {
    // = city
    if (filteredEntity === 'building') {
      return filterBuildingsByCities(valuesToBeFiltered as Building[], filteringValues);
    }
    if (filteredEntity === 'unit') {
      return filterUnitByCities(valuesToBeFiltered as Unit[], filteringValues, getBuilding);
    }
    if (filteredEntity === 'owner') {
      return filterOwnerByCities(valuesToBeFiltered as Contact[], filteringValues, getUnit, getBuilding);
    }
    if (filteredEntity === 'unitType') {
      // Not possible to filter unitType based on city
    }
  }
  // Add other dimension too
  return valuesToBeFiltered;
};

const isUnitOwnedByOneOwner = (unit: Unit, ownerIds: string[]) => {
  return (
    unit.owners!.some((uo) => ownerIds.includes(uo.owner!.id)) ||
    (unit.jointOwnershipId && ownerIds.includes(unit.jointOwnershipId))
  );
};

const isBuildingOwnedByOneOwner = (building: Building, ownerIds: string[]) => {
  return (
    building.owners!.some((bo) => ownerIds.includes(bo.owner!.id)) ||
    (building.jointOwnershipId && ownerIds.includes(building.jointOwnershipId))
  );
};

const doesOwnerOwnsOneBuilding = (owner: Contact, buildingIds: string[]) => {
  return owner.buildings!.some((bo) => buildingIds.includes(bo.building!.id));
};

const doesOwnerOwnsOneUnit = (owner: Contact, unitIds: string[]) => {
  return owner.units!.some((uo) => unitIds.includes(uo.unit!.id));
};

const unitHasCorrectType = (unit: Unit, validUnitTypes: string[]) => {
  return validUnitTypes.includes(unit.type);
};

const buildingHasUnitOfCorrectType = (building: Building, validUnitTypes: string[]) => {
  return building.units!.some((u) => unitHasCorrectType(u, validUnitTypes));
};

const buildingHasCorrectCity = (building: Building, cities: string[]) => {
  return building.address && building.address.city && cities.includes(building.address.city);
};

/* Keep the buildings that are owned by the owners or that have one of their unit owned by the owners */
const filterBuildingsByOwners = (buildings: Building[], ownerIds: string[], getUnit: UnitContext['getUnit']) => {
  return buildings.filter((building) => {
    const buildingIsOwnedByOneOwner = isBuildingOwnedByOneOwner(building, ownerIds);
    if (buildingIsOwnedByOneOwner) return true;

    const buildingHasOneUnitOwnedByOwner = building.units!.some((u) => {
      const completeUnit = getUnit(u.id);
      return !isNil(completeUnit) && isUnitOwnedByOneOwner(completeUnit, ownerIds);
    });
    return buildingHasOneUnitOwnedByOwner;
  });
};

/* Keep the units that are owned by the owners or that have their building owned by the owners */
const filterUnitsByOwners = (units: Unit[], ownerIds: string[], getBuilding: BuildingContext['getBuilding']) => {
  return units.filter((unit) => {
    const unitIsOwnedByOneOwner = isUnitOwnedByOneOwner(unit, ownerIds);
    if (unitIsOwnedByOneOwner) return true;

    const completeBuilding = getBuilding(unit.building!.id);
    const unitHasItsBuildingOwnedByOwner =
      !isNil(completeBuilding) && isBuildingOwnedByOneOwner(completeBuilding, ownerIds);
    return unitHasItsBuildingOwnedByOwner;
  });
};

/* Get all the buildings and units owned by the owner and add their units types */
const filterUnitTypesByOwners = (
  ownerIds: string[],
  getContact: ContactContext['getContact'],
  getBuilding: BuildingContext['getBuilding']
) => {
  const unitTypes: string[] = [];
  for (const ownerId of ownerIds) {
    const completeOwner = getContact(ownerId);
    if (!isNil(completeOwner)) {
      const ownerBuildings = completeOwner.buildings!.map((bo) => bo.building!);
      const ownerUnits = completeOwner.units!.map((uo) => uo.unit!);
      for (const building of ownerBuildings) {
        const completeBuilding = getBuilding(building.id);
        if (!isNil(completeBuilding)) {
          completeBuilding.units!.forEach((u) => {
            uniquePush(unitTypes, u.type);
          });
        }
      }
      for (const unit of ownerUnits) {
        uniquePush(unitTypes, unit.type);
      }
    }
  }
  return unitTypes;
};

/* Get all the buildings and units owned by the owner and add their city */
const filterCitiesByOwners = (
  ownerIds: string[],
  getContact: ContactContext['getContact'],
  getBuilding: BuildingContext['getBuilding']
) => {
  const cities: string[] = [];
  for (const ownerId of ownerIds) {
    const completeOwner = getContact(ownerId);
    if (!isNil(completeOwner)) {
      const ownerBuildings = completeOwner.buildings!.map((bo) => bo.building!);
      const ownerUnits = completeOwner.units!.map((uo) => uo.unit!);
      for (const building of ownerBuildings) {
        const completeBuilding = getBuilding(building.id);
        if (!isNilOrEmpty(completeBuilding?.address?.city)) {
          uniquePush(cities, completeBuilding!.address!.city);
        }
      }
      for (const unit of ownerUnits) {
        const completeBuilding = getBuilding(unit.building?.id ?? '');
        if (!isNilOrEmpty(completeBuilding?.address?.city)) {
          uniquePush(cities, completeBuilding!.address!.city);
        }
      }
    }
  }
  return cities;
};

/* Keep the owner if it owns one of the building or if it owns of of the units of the building */
const filterOwnersByBuildings = (owners: Contact[], buildingsIds: string[], getUnit: UnitContext['getUnit']) => {
  return owners.filter((owner) => {
    const ownerOwnsABuilding = doesOwnerOwnsOneBuilding(owner, buildingsIds);
    if (ownerOwnsABuilding) return true;

    const ownerOwnsOneUnitOfTheBuilding = owner.units!.some((uo) => {
      const completeUnit = getUnit(uo.unit!.id);
      return !isNil(completeUnit) && buildingsIds.includes(completeUnit.building!.id);
    });
    return ownerOwnsOneUnitOfTheBuilding;
  });
};

/* Keep the owner if it owns one of the units or if it owns the building of one of the unit */
const filterOwnersByUnits = (owners: Contact[], unitIds: string[], getUnit: UnitContext['getUnit']) => {
  return owners.filter((owner) => {
    const ownerOwnsAnUnit = doesOwnerOwnsOneUnit(owner, unitIds);
    if (ownerOwnsAnUnit) return true;

    const buildingsUnitIds: string[] = [];
    unitIds.forEach((uId) => {
      const completeUnit = getUnit(uId);
      if (!isNil(completeUnit)) {
        buildingsUnitIds.push(completeUnit.building!.id);
      }
    });
    const ownerOwnsBuildingOfUnit = doesOwnerOwnsOneBuilding(owner, buildingsUnitIds);
    return ownerOwnsBuildingOfUnit;
  });
};

/* Keep the owner if it owns a unit of the correct type or if it owns 
a buildings that has an unit of the correct type */
const filterOwnerByUnitType = (owners: Contact[], unitTypes: string[], getBuilding: BuildingContext['getBuilding']) => {
  return owners.filter((owner) => {
    const unitsOwnedByOwner = owner.units!.map((uo) => uo.unit!);
    const oneOfTheUnitsOwnedHasCorrectType = unitsOwnedByOwner.some((u) => unitHasCorrectType(u, unitTypes));
    if (oneOfTheUnitsOwnedHasCorrectType) return true;

    const buildingsOwnedByOwner = owner.buildings!.map((bo) => bo.building!);
    const oneTheBuildingsHasAUnitOfCorrectType = buildingsOwnedByOwner.some((b) => {
      const completeBuilding = getBuilding(b.id);
      return !isNil(completeBuilding) && buildingHasUnitOfCorrectType(completeBuilding, unitTypes);
    });
    return oneTheBuildingsHasAUnitOfCorrectType;
  });
};

/* Keep the owner if it owns a building or unit with the correct city */
const filterOwnerByCities = (
  owners: Contact[],
  cities: string[],
  getUnit: UnitContext['getUnit'],
  getBuilding: BuildingContext['getBuilding']
) => {
  return owners.filter((owner) => {
    const unitsOwnedByOwner = owner.units!.map((uo) => uo.unit!);
    const oneOfTheUnitsOwnedHasCorrectCity = unitsOwnedByOwner.some((unit) => {
      const completeUnit = getUnit(unit.id);
      const completeBuilding = getBuilding(completeUnit?.building?.id ?? '');
      return completeBuilding && buildingHasCorrectCity(completeBuilding, cities);
    });
    if (oneOfTheUnitsOwnedHasCorrectCity) return true;

    const buildingsOwnedByOwner = owner.buildings!.map((bo) => bo.building!);
    const oneTheBuildingsHasAUnitOfCorrectCity = buildingsOwnedByOwner.some((b) => {
      const completeBuilding = getBuilding(b.id);
      return !isNil(completeBuilding) && buildingHasCorrectCity(completeBuilding, cities);
    });
    return oneTheBuildingsHasAUnitOfCorrectCity;
  });
};

/* Keep the unit of the correct type */
const filterUnitByUnitType = (units: Unit[], unitTypes: string[]) => {
  return units.filter((u) => unitHasCorrectType(u, unitTypes));
};

/* Keep the unit of the correct city */
const filterUnitByCities = (units: Unit[], cities: string[], getBuilding: BuildingContext['getBuilding']) => {
  return units.filter((unit) => {
    const completeBuilding = getBuilding(unit.building?.id ?? '');
    return completeBuilding && buildingHasCorrectCity(completeBuilding, cities);
  });
};

/* Keep the types of the units */
const filterUnitTypesByUnit = (unitIds: string[], getUnit: UnitContext['getUnit']) => {
  const unitTypes: string[] = [];
  unitIds.forEach((uId) => {
    const completeUnit = getUnit(uId);
    if (!isNil(completeUnit)) {
      uniquePush(unitTypes, completeUnit.type);
    }
  });
  return unitTypes;
};

/* Keep the cities of the unit's building */
const filterCitiesByUnit = (
  unitIds: string[],
  getUnit: UnitContext['getUnit'],
  getBuilding: BuildingContext['getBuilding']
) => {
  const cities: string[] = [];
  unitIds.forEach((uId) => {
    const completeUnit = getUnit(uId);
    const completeBuilding = getBuilding(completeUnit?.building?.id ?? '');
    if (!isNilOrEmpty(completeBuilding?.address?.city)) {
      uniquePush(cities, completeBuilding!.address!.city);
    }
  });
  return cities;
};

/* Keep the buildings that have one of their units of correct type */
const filterBuildingsByUnitType = (buildings: Building[], unitTypes: string[]) => {
  return buildings.filter((b) => buildingHasUnitOfCorrectType(b, unitTypes));
};

/* Keep the buildings that have address in city */
const filterBuildingsByCities = (buildings: Building[], cities: string[]) => {
  return buildings.filter((building) => buildingHasCorrectCity(building, cities));
};

/* Keep the types of the building's units */
const filterUnitTypesByBuilding = (buildingIds: string[], getBuilding: BuildingContext['getBuilding']) => {
  const unitTypes: string[] = [];
  buildingIds.forEach((bId) => {
    const completeBuilding = getBuilding(bId);
    if (!isNil(completeBuilding)) {
      const buildingUnits = completeBuilding.units!;
      buildingUnits.forEach((u) => {
        uniquePush(unitTypes, u.type);
      });
    }
  });
  return unitTypes;
};

/* Keep the city of the building's */
const filterCitiesByBuilding = (buildingIds: string[], getBuilding: BuildingContext['getBuilding']) => {
  const cities: string[] = [];
  buildingIds.forEach((bId) => {
    const completeBuilding = getBuilding(bId);
    if (!isNilOrEmpty(completeBuilding?.address?.city)) {
      uniquePush(cities, completeBuilding!.address!.city);
    }
  });
  return cities;
};

/* Keep the buildings that have one of their units in the unit list */
const filterBuildingsByUnit = (buildings: Building[], unitIds: string[]) => {
  return buildings.filter((building) => {
    const buildingUnits = building.units!;
    return buildingUnits.some((u) => unitIds.includes(u.id));
  });
};

/* Keep the units that have their building in the building list */
const filterUnitByBuilding = (units: Unit[], buildingIds: string[]) => {
  return units.filter((unit) => {
    const unitBuilding = unit.building!;
    return buildingIds.includes(unitBuilding.id);
  });
};
