import { Grid, Menu, MenuItem, Typography } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Skeleton } from '@material-ui/lab';
import {
  Colors,
  Communication,
  CommunicationChannel,
  CommunicationStatus,
  CommunicationType,
  EntityType,
  File,
  FileCategory,
  NewFile,
  S3Object,
  SignatureDocumentStatus,
  isNilOrEmpty,
} from '@rentguru/commons-utils';
import {
  ActionButton,
  ActionButtonSecond,
  ConfirmDialog,
  CustomIconButton,
  actionButtonSecondStyles,
} from '@up2rent/ui';
import { isSameDay } from 'date-fns';
import { ContentState, EditorState, convertFromHTML } from 'draft-js';
import { isEmpty, isNil } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import ConfirmDeleteLetterDialog from 'src/components/Leases/Details/End/ConfirmDeleteLetterDialog';
import { CustomMenuItemType } from 'src/components/ui/ComboBox/TextComboBox';
import ContentDetailHeader from 'src/components/ui/ContentDetailHeader';
import MoreHorizontalIconButton from 'src/components/ui/CustomIconButtons/MoreHorizontalIconButton';
import RouteDiscardChanges from 'src/components/ui/Dialogs/DiscardChanges/RouteDiscardChanges';
import ResetIcon from 'src/components/ui/ResetIcon';
import { useCommunications } from 'src/hooks/CommunicationsContext';
import { useDocumentCategory } from 'src/hooks/FileCategoryContext';
import { useFiles } from 'src/hooks/FilesContext';
import { useSignatureDocuments } from 'src/hooks/SignatureDocumentContext';
import { useTemplates } from 'src/hooks/TemplatesContext';
import { useUser } from 'src/hooks/UserContext';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { ReactComponent as CommunicationSvg } from 'src/icons/communication.svg';
import { ReactComponent as DownloadSvg } from 'src/icons/download.svg';
import {
  addTemplateToCommunication,
  getValidEmailSchema,
  isLetterCommunication,
  stringifyEditorStateBody,
  stringifyEditorStateTitle,
} from 'src/utils/communicationUtils';
import { convertNewLinesFromHtmlBody } from 'src/utils/templateutils';
import CommunicationDetailContent from './CommunicationDetailsContent';
import UnblockCommunicationDialog from './UnblockCommunicationDialog';
import { useGenerateAndDownloadCommunication } from './useGenerateAndDownloadCommunication';

interface CommunicationDetailProps {
  id: string;
  onClose: () => void;
  onSend?: () => void;
  editable?: boolean;
}

export const getStateFromHTML = (htmlContent: string) => {
  const correctedBody = convertNewLinesFromHtmlBody(htmlContent);
  const blocksFromHTML = convertFromHTML(correctedBody);

  const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
  return state;
};

export const getRecipientsArray = (recipientsArray: (string | CustomMenuItemType)[]) =>
  recipientsArray.map((currentRecipient) =>
    typeof currentRecipient === 'string' ? currentRecipient : (currentRecipient.value as string)
  );

const CommunicationDetail: React.FC<CommunicationDetailProps> = ({
  onClose,
  onSend = () => {},
  id,
  editable = false,
}) => {
  const actionButtonClasses = actionButtonSecondStyles();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { generateDownloadCommunication, generateDownloadCommunicationLoading } =
    useGenerateAndDownloadCommunication(id);
  const { refreshToken } = useUser();
  const { updateCommunication, getCommunication, communicationsLoading } = useCommunications();
  const { templates, templatesLoading } = useTemplates();
  const { getSignatureDocument, loading: signatureDocumentsLoading } = useSignatureDocuments();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { communicationsDetailsWrite } = usePermissions();
  const { createFile, getFiles, deleteFile } = useFiles();
  const { getDocumentCategoryByFileCategory } = useDocumentCategory();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [titleEditor, setTitle] = useState<EditorState>(EditorState.createEmpty());
  const [emailError, setEmailError] = useState<string | null>(null);
  const [bodyEditor, setBody] = useState<EditorState>(EditorState.createEmpty());
  const [recipients, setRecipients] = useState<(CustomMenuItemType | string)[]>([]);
  const [recipientsInCc, setRecipientsInCc] = useState<(CustomMenuItemType | string)[]>([]);
  const [recipientsInBcc, setRecipientsInBcc] = useState<(CustomMenuItemType | string)[]>([]);
  const [files, setFiles] = useState<(S3Object | NewFile)[]>([]);
  const [openDeleteLetterDialog, setOpenDeleteLetterDialog] = useState<boolean>(false);
  const [unblockCommunicationDialogOpen, setUnblockCommunicationDialogOpen] = useState<boolean>(false);
  const [sendOutdatedCommunicationDialogOpen, setSendOutdatedCommunicationDialogOpen] = useState<boolean>(false);
  const [communicationFromDb, setCommunicationFromDb] = useState<Communication>();

  useEffect(() => {
    if (!communicationsLoading && !editMode && !unblockCommunicationDialogOpen) {
      const fetchedCommunication = getCommunication(id);
      setCommunicationFromDb(fetchedCommunication);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communicationsLoading, editMode, unblockCommunicationDialogOpen, id]);

  useEffect(() => {
    if (communicationFromDb) {
      const title = !isNil(communicationFromDb.subject)
        ? EditorState.createWithContent(getStateFromHTML(communicationFromDb.subject))
        : EditorState.createEmpty();
      setTitle(title);
      const body = !isNil(communicationFromDb.body)
        ? EditorState.createWithContent(getStateFromHTML(communicationFromDb.body))
        : EditorState.createEmpty();
      setBody(body);

      const emailRecipients = communicationFromDb?.recipient?.split(',') ?? [];
      setRecipients(emailRecipients);
      const emailRecipientsInCc = communicationFromDb?.recipientInCc?.split(',') ?? [];
      setRecipientsInCc(emailRecipientsInCc);
      const emailRecipientsInBcc = communicationFromDb?.recipientInBcc?.split(',') ?? [];
      setRecipientsInBcc(emailRecipientsInBcc);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communicationFromDb, id]);

  if (communicationsLoading || templatesLoading || signatureDocumentsLoading || !communicationFromDb) {
    return <Skeleton />;
  }

  if (isNil(id)) {
    return null;
  }

  const getCommunicationHeader = (
    isLetter: boolean,
    communicationSubject: string,
    communicationType?: CommunicationType
  ) => {
    if (!isLetter) {
      return communicationSubject;
    }
    if (communicationType) {
      return formatMessage({ id: `settings.communications.communicationTypes.${communicationType}` });
    }
    return '';
  };

  const communication = addTemplateToCommunication(communicationFromDb, templates);
  const signatureDocument = getSignatureDocument(communication.signatureDocumentId ?? '');

  const {
    subject: communicationSubject,
    sentAt,
    recipient,
    recipientInBcc,
    recipientInCc,
    channel,
    type,
    status: communicationStatus,
  } = communication;
  const communicationIsBouncing = communication.status === CommunicationStatus.BOUNCE_ERROR;
  const isLetter = isLetterCommunication(channel as CommunicationChannel);
  const subject = getCommunicationHeader(isLetter, communicationSubject, type);
  const isSignatureDocumentInDraftOrRejectedOrError =
    signatureDocument &&
    [SignatureDocumentStatus.DRAFT, SignatureDocumentStatus.ERROR, SignatureDocumentStatus.REJECTED].includes(
      signatureDocument.status as SignatureDocumentStatus
    );
  const canDeleteLetter =
    isLetter &&
    signatureDocument &&
    isSignatureDocumentInDraftOrRejectedOrError &&
    communicationsDetailsWrite &&
    !isSameDay(new Date(), new Date(signatureDocument.expirationDate ?? '')) &&
    !editMode;

  const isCommunicationOutdated = communicationStatus === CommunicationStatus.OUTDATED;
  const ValidEmailSchema = getValidEmailSchema(formatMessage);

  const handleSend = async () => {
    await updateCommunication(communication, {
      toSend: true,
    });
    enqueueSnackbar(formatMessage({ id: 'communications.placeholder.sendingLoading' }), {
      variant: 'info',
    });
  };

  const handleSendRegisteredLetterAsap = async () => {
    if (!signatureDocument) {
      return;
    }
    await updateCommunication(communication, {
      toSend: true,
    });

    enqueueSnackbar(
      formatMessage({
        id: `communications.placeholder.${
          communication.channel === CommunicationChannel.REGISTERED_LETTER
            ? 'sendingRegisteredLetterInformation'
            : 'sendingLetterInformation'
        }`,
      }),
      {
        variant: 'success',
      }
    );
  };

  const handleFilesUpdates = async () => {
    const originalCommunicationFiles = getFiles(EntityType.COMMUNICATION, [communication.id]);
    const deleteFilesPromises = originalCommunicationFiles.reduce((acc, originalFile) => {
      if (!files.some((file) => 'id' in file && file.id === originalFile.id)) {
        acc.push(deleteFile(originalFile));
      }
      return acc;
    }, [] as Promise<File>[]);

    const documentCategoryId = getDocumentCategoryByFileCategory(FileCategory.COMMUNICATION).id;

    const newFilesPromises = files.reduce((acc, file) => {
      if (!file.hasOwnProperty('id')) {
        acc.push(createFile((file as NewFile).file, EntityType.COMMUNICATION, communication.id, documentCategoryId));
      }
      return acc;
    }, [] as Promise<File | null>[]);

    await Promise.all(deleteFilesPromises);
    await Promise.all(newFilesPromises);
  };

  const handleContentUpdates = async () => {
    const recipientArray = getRecipientsArray(recipients);
    const recipientInCcArray = getRecipientsArray(recipientsInCc);
    const recipientInBccArray = getRecipientsArray(recipientsInBcc);
    try {
      if (!isLetter) {
        !isEmpty(recipientArray) && (await ValidEmailSchema.validate(recipientArray));
        !isEmpty(recipientInCcArray) && (await ValidEmailSchema.validate(recipientInCcArray));
        !isEmpty(recipientInBccArray) && (await ValidEmailSchema.validate(recipientInBccArray));
      }
      const newRecipient = getRecipientsArray(recipients).join(',');
      const newRecipientInCc = getRecipientsArray(recipientsInCc).join(',');
      const newRecipientInBcc = getRecipientsArray(recipientsInBcc).join(',');
      const newSubject = stringifyEditorStateTitle(titleEditor);
      const newBody = stringifyEditorStateBody(bodyEditor);
      const oldBody = communicationFromDb.body.replace(/>\s*</g, '><').trim();
      if (
        newRecipient !== communicationFromDb.recipient ||
        newRecipientInCc !== communicationFromDb.recipientInCc ||
        newRecipientInBcc !== communicationFromDb.recipientInBcc ||
        newSubject !== communicationFromDb.subject ||
        newBody !== oldBody
      ) {
        await updateCommunication(communication, {
          recipient: newRecipient,
          recipientInCc: newRecipientInCc,
          recipientInBcc: newRecipientInBcc,
          subject: newSubject,
          body: newBody,
        });
      }
    } catch (error) {
      const err = error as Error;
      console.error('error', err);
      if (!isNil(err.message)) setEmailError(err.message);
      else setEmailError(err as unknown as string);
    }
  };

  const handleEdit = async () => {
    await handleFilesUpdates();
    await handleContentUpdates();
    setEditMode(false);
    setEmailError(null);
    if (isLetter) {
      enqueueSnackbar(formatMessage({ id: 'pdfCommunicationRefresh' }), {
        persist: true,
        variant: 'warning',
        key: 'Refresh_Token',
        action: (
          <ResetIcon
            onClick={async () => {
              await refreshToken();
              closeSnackbar('Refresh_Token');
              window.location.reload();
            }}
            style={{ cursor: 'pointer' }}
          />
        ),
      });
    }
  };

  const handleConfirm = () => {
    handleSend();
    onSend();
  };

  const headerActions: React.ReactNode[] = [];
  const isCommunicationDetailEditable =
    editable && (!isLetter || signatureDocument?.status === SignatureDocumentStatus.DRAFT);

  if (
    isLetter &&
    signatureDocument?.status === SignatureDocumentStatus.DRAFT &&
    communicationsDetailsWrite &&
    !isSameDay(new Date(), new Date(signatureDocument.expirationDate ?? '')) &&
    !editMode
  ) {
    headerActions.push(
      <ActionButton
        key="send"
        onClick={() => {
          handleSendRegisteredLetterAsap();
          onSend();
        }}
        style={{ marginRight: 20, textTransform: 'none' }}
      >
        {formatMessage({
          id: 'communications.action.sendAsap',
        })}
      </ActionButton>
    );
  }

  if (
    !isLetter &&
    isNil(sentAt) &&
    !(isNilOrEmpty(recipient) && isNilOrEmpty(recipientInCc) && isNilOrEmpty(recipientInBcc)) &&
    !editMode &&
    communicationsDetailsWrite &&
    !communicationIsBouncing
  ) {
    headerActions.push(
      <ActionButton
        key="send"
        onClick={() => {
          if (isCommunicationOutdated) {
            return setSendOutdatedCommunicationDialogOpen(true);
          }
          handleConfirm();
        }}
        style={{ marginRight: 20 }}
      >
        {formatMessage({
          id: 'send',
        })}
      </ActionButton>
    );
  }

  if (isNil(sentAt) && !editMode && communicationsDetailsWrite && isCommunicationDetailEditable) {
    headerActions.push(
      <ActionButtonSecond key="edit" onClick={() => setEditMode(true)} className={actionButtonClasses.buttonWithMargin}>
        {formatMessage({ id: 'edit' })}
      </ActionButtonSecond>
    );
    if (communicationIsBouncing) {
      headerActions.push(
        <ActionButton
          key="unblock"
          onClick={() => setUnblockCommunicationDialogOpen(true)}
          style={{ textTransform: 'none', marginRight: 20 }}
        >
          {formatMessage({ id: 'communications.detail.unblockCommunication' })}
        </ActionButton>
      );
    }
  }
  if (editMode) {
    headerActions.push(
      <ActionButton key="save" onClick={handleEdit} style={{ marginRight: 20 }}>
        {formatMessage({ id: 'save' })}
      </ActionButton>
    );
  }
  if ((!editMode && !isLetter) || canDeleteLetter) {
    headerActions.push(
      <MoreHorizontalIconButton
        key="more"
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          if (!isNil(anchorEl)) setAnchorEl(null);
          else setAnchorEl(e.currentTarget);
        }}
        style={{ marginRight: 20 }}
      />
    );
  }

  return (
    <>
      <Grid container style={{ flexGrow: 1, overflow: 'auto', height: '100%' }}>
        <Grid
          item
          style={{
            height: '100%',
            width: 60,
            overflow: 'visible',
            marginLeft: 15,
            marginRight: 15,
          }}
        >
          <CustomIconButton
            style={{ marginTop: 30, marginBottom: 70 }}
            iconStyle={{ fill: Colors.BLUE_GREY }}
            aria-label="Menu"
            onClick={onClose}
            Icon={ArrowBack}
          />
        </Grid>
        <Grid
          style={{
            flexGrow: 1,
            width: 'calc(100% - 100px)',
          }}
        >
          <ContentDetailHeader title={subject || ''} EntityIcon={CommunicationSvg} actions={headerActions} />
          <Grid
            style={{
              backgroundColor: 'white',
              borderRadius: '10px',
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 24,
              paddingBottom: 24,
              minHeight: 'calc(100% - 168px)',
            }}
          >
            <CommunicationDetailContent
              communication={communication}
              recipients={recipients}
              setRecipients={setRecipients}
              recipientsInCc={recipientsInCc}
              setRecipientsInCc={setRecipientsInCc}
              recipientsInBcc={recipientsInBcc}
              setRecipientsInBcc={setRecipientsInBcc}
              files={files}
              setFiles={setFiles}
              bodyEditor={bodyEditor}
              setBody={setBody}
              titleEditor={titleEditor}
              setTitle={setTitle}
              editable={editMode && isCommunicationDetailEditable}
              emailError={emailError}
            />
          </Grid>
        </Grid>
      </Grid>
      <RouteDiscardChanges
        when={editMode}
        history={history}
        onConfirm={() => {
          setEditMode(false);
          onClose();
        }}
        shouldBlockNavigation={() => {
          return true;
        }}
      />
      {!isNil(anchorEl) && (
        <Menu
          id="action-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={(e: React.MouseEvent) => {
            e.stopPropagation();
            setAnchorEl(null);
          }}
          style={{ transform: 'translateY(40px)' }}
        >
          {!isLetter && generateDownloadCommunicationLoading && <Skeleton style={{ width: '150px' }} />}
          {!isLetter && !generateDownloadCommunicationLoading && (
            <MenuItem
              onClick={async (e) => {
                e.stopPropagation();
                await generateDownloadCommunication();
                setAnchorEl(null);
              }}
            >
              <div style={{ display: 'flex' }}>
                <DownloadSvg style={{ fill: Colors.SLATE_GREY }} />
                <Typography style={{ fontSize: 14, color: Colors.SLATE_GREY, marginLeft: 10 }}>
                  {formatMessage({ id: 'download' })}
                </Typography>
              </div>
            </MenuItem>
          )}
          {isLetter && canDeleteLetter && (
            <MenuItem
              onClick={async (e) => {
                e.stopPropagation();
                setOpenDeleteLetterDialog(true);
                setAnchorEl(null);
              }}
            >
              <Typography style={{ fontSize: 14, color: Colors.SLATE_GREY }}>
                {formatMessage({ id: 'cancel' })}
              </Typography>
            </MenuItem>
          )}
        </Menu>
      )}
      {signatureDocument && openDeleteLetterDialog && (
        <ConfirmDeleteLetterDialog
          open={openDeleteLetterDialog}
          afterSubmit={(validate: boolean) => {
            setOpenDeleteLetterDialog(false);
            if (validate) {
              onClose();
            }
          }}
          signatureDocument={signatureDocument}
        />
      )}
      {unblockCommunicationDialogOpen && (
        <UnblockCommunicationDialog
          unblockCommunicationDialogOpen={unblockCommunicationDialogOpen}
          onClose={() => setUnblockCommunicationDialogOpen(false)}
          communication={communication}
        />
      )}
      <ConfirmDialog
        open={sendOutdatedCommunicationDialogOpen}
        confirmText={formatMessage({ id: 'send' })}
        confirmAction={() => {
          handleConfirm();
          setSendOutdatedCommunicationDialogOpen(false);
        }}
        cancelAction={() => {
          setSendOutdatedCommunicationDialogOpen(false);
        }}
        mainText={formatMessage({ id: 'communications.detail.outdatedWarning' })}
        autofocus={false}
        formatMessage={formatMessage}
      />
    </>
  );
};

export default CommunicationDetail;
