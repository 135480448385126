import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import toUpper from 'lodash/toUpper';
import { ActionButton , LoaderButton } from '@up2rent/ui';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTechnics } from '../../../hooks/TechnicsContext';
import { useBuildings } from '../../../hooks/BuildingsContext';
import { useUnits } from '../../../hooks/UnitsContext';
import * as Yup from 'yup';
import FormikDatePicker from '../../ui/FormikDatePicker';
import {
  LeaseStatus,
  Technic,
  TechnicType,
  UnitInventory,
  Colors,
  LooseObject,
  CommunicationSettingsProfile,
} from '@rentguru/commons-utils';
import { Divider, Grid, Typography } from '@material-ui/core';
import UnitInventorySelectorField from '../../ui/Forms/FormField/UnitInventorySelectorField';
import { useUnitInventories } from '../../../hooks/UnitInventoryContext';
import isNil from 'lodash/isNil';
import { v4 as uuidv4 } from 'uuid';
import AddTechnicDialogComplementaryData, {
  AddTechnicDialogComplementaryDataFormValues,
} from '../AddTechnicDialogComplementaryData';
import { useLeases } from 'src/hooks/LeasesContext';
import { getLinks } from '../Heatings/AddHeatingForm';
import { useHistory } from 'react-router-dom';
import useTechnicsUtils from '../useTechnicsUtils';
import { RouteDestination } from 'src/components/Routes/Routes';
import { useCommunicationSettingsProfiles } from 'src/hooks/CommunicationSettingsProfilesContext';
import { isEmpty } from 'lodash';

export interface AddChimneyProps {
  id: string;
  entity: 'building' | 'unit' | 'leaseBuilding' | 'leaseUnit';
  unitInventories?: UnitInventory[];
  afterSubmit: (technics?: Technic[]) => void;
  cancelSubmit: () => void;
  showTitle?: boolean;
  showComplementaryData?: boolean;
  shouldRedirectAfterCreate?: boolean;
}

const ChimneySchema = Yup.object().shape({
  chimneyLastControlDate: Yup.date().required(),
  chimneyUnitInventoryId: Yup.string().nullable(),
});
export interface ChimneyInitialValuesType extends AddTechnicDialogComplementaryDataFormValues {
  chimneyLastControlDate: Date;
  chimneyUnitInventoryId: string;
}

const AddChimneyForm: React.FC<AddChimneyProps> = ({
  id,
  entity,
  unitInventories,
  afterSubmit,
  cancelSubmit,
  showTitle = true,
  showComplementaryData = false,
  shouldRedirectAfterCreate = false,
}) => {
  const { formatMessage } = useIntl();
  const { createTechnic } = useTechnics();
  const { getBuilding } = useBuildings();
  const { getLease } = useLeases();
  const { getUnit } = useUnits();
  const { getUnitInventoriesFor } = useUnitInventories();
  const { getPropertyId } = useTechnicsUtils();
  const { getDefaultTechnicCommunicationSettingsProfiles, loading: communicationSettingsProfilesLoading } =
    useCommunicationSettingsProfiles();
  const history = useHistory();

  const [technicCommunicationSettingsProfiles, setTechnicCommunicationSettingsProfiles] = useState<
    CommunicationSettingsProfile[]
  >([]);

  useEffect(() => {
    if (communicationSettingsProfilesLoading) {
      return;
    }
    const technicProfiles = getDefaultTechnicCommunicationSettingsProfiles();
    setTechnicCommunicationSettingsProfiles(technicProfiles);
  }, [getDefaultTechnicCommunicationSettingsProfiles, communicationSettingsProfilesLoading]);

  if (communicationSettingsProfilesLoading || isEmpty(technicCommunicationSettingsProfiles)) {
    return (
      <Grid container style={{ justifyContent: 'center' }}>
        <Typography style={{ margin: 20 }}>{formatMessage({ id: 'loading' })}</Typography>
      </Grid>
    );
  }

  // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
  const defaultTechnicProfile =
    !isEmpty(technicCommunicationSettingsProfiles) && technicCommunicationSettingsProfiles[0];

  const handleChimneyCreate = async (
    values: ChimneyInitialValuesType,
    { setSubmitting, setStatus }: FormikHelpers<ChimneyInitialValuesType>
  ) => {
    const unit = getUnit(values.unitId ?? '');
    const building = getBuilding(unit?.building?.id ?? '');
    const buildingId = building?.id;
    const leasesOfUnit = (unit?.leases ?? []).map((lease) => {
      return getLease(lease?.lease?.id ?? '');
    });
    let createdTechnics: Technic[] = [];
    const activeLease = leasesOfUnit.find((lease) => lease?.status === LeaseStatus.Active);
    const chimneyLastControlDate = values.chimneyLastControlDate?.toJSON();
    const chimneyUnitInventoryId = values.chimneyUnitInventoryId === '' ? undefined : values.chimneyUnitInventoryId;
    const technics: Omit<Technic, 'id' | 'clientId' | 'readId'>[] = [
      {
        type: TechnicType.CHIMNEY,
        chimneyLastControlDate,
        chimneyUnitInventoryId,
        // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
        communicationSettingsProfileId: defaultTechnicProfile ? defaultTechnicProfile.id : 'mockId',
        ...getLinks(values, id, entity, buildingId ?? ''),
      },
      ...(!values.onBuilding && !isNil(activeLease) && !entity.startsWith('lease')
        ? [
            {
              type: TechnicType.CHIMNEY,
              chimneyLastControlDate,
              chimneyUnitInventoryId,
              leaseId: activeLease.id,
              unitId: unit?.id ?? '',
              // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
              communicationSettingsProfileId: defaultTechnicProfile ? defaultTechnicProfile.id : 'mockId',
            },
          ]
        : []),
    ];
    if (entity === 'unit' || entity === 'building') {
      createdTechnics = await Promise.all(technics.map((technic) => createTechnic(technic)));
    } else {
      technics.forEach((technic) => {
        (technic as LooseObject).id = `NEW-${uuidv4()}`;
      });
    }
    setStatus(true);
    afterSubmit(technics as Technic[]);
    setSubmitting(false);
    if (shouldRedirectAfterCreate) {
      const currentUnit = getUnit(values.onBuilding ? '' : values.unitId ?? '');
      const unitId = `unit-${currentUnit?.id}`;
      history.push({
        pathname: `${RouteDestination.PROPERTIES}/${getPropertyId()}/${!isNil(currentUnit) ? unitId : 'technics'}/${
          !isNil(currentUnit) ? 'technics' : ''
        }`,
        state: {
          id: createdTechnics?.[0].id ?? '',
          defaultTab: TechnicType.CHIMNEY,
        },
      });
    }
  };

  return (
    <Formik
      initialValues={
        {
          chimneyLastControlDate: new Date(),
          chimneyUnitInventoryId: '',
          onBuilding: ['building', 'leaseBuilding'].includes(entity),
          unitId: ['unit', 'leaseUnit'].includes(entity) ? id : '',
        } as ChimneyInitialValuesType
      }
      validationSchema={ChimneySchema}
      onSubmit={handleChimneyCreate}
    >
      {({ values, errors, touched, isSubmitting, status }) => {
        let unitInventoryArray: UnitInventory[] = [];
        if ((id && entity === 'unit') || !values.onBuilding)
          unitInventoryArray = getUnitInventoriesFor(values.unitId ?? '', new Date());
        else if (!isNil(unitInventories) && entity.startsWith('lease')) unitInventoryArray = unitInventories;
        return (
          <Form>
            {showTitle && (
              <div style={{ marginLeft: 20, marginRight: 20, marginTop: 25, marginBottom: 25 }}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: 20,
                    textAlign: 'left',
                  }}
                >
                  {formatMessage({ id: 'technic.chimneyTab.addChimney' })}
                </Typography>
              </div>
            )}
            <Divider style={{ marginBottom: 10 }} />
            <div
              style={{
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              {showComplementaryData && (
                <AddTechnicDialogComplementaryData messageId="lease.detail.action.addDetectorExample" />
              )}
              <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                <FormikDatePicker
                  name="chimneyLastControlDate"
                  label={formatMessage({ id: 'technic.chimneyTab.chimneyLastControlDate' })}
                  value={values.chimneyLastControlDate}
                  style={{ width: 280 }}
                  error={Boolean(errors.chimneyLastControlDate && touched.chimneyLastControlDate)}
                />
                <UnitInventorySelectorField
                  unitsInventory={unitInventoryArray}
                  fieldName="chimneyUnitInventoryId"
                  inputStyle={{ width: 280 }}
                />
              </Grid>
            </div>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <div
              style={{
                marginBottom: 20,
                marginRight: 30,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <ActionButton
                onClick={cancelSubmit}
                style={{ background: 'none', color: Colors.DARK_SLATE_GREY, marginRight: 20 }}
              >
                {toUpper(formatMessage({ id: 'cancel' }))}
              </ActionButton>
              <LoaderButton loading={isSubmitting} success={status}>
                {formatMessage({
                  id: 'save',
                })}
              </LoaderButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddChimneyForm;
