import { Typography } from '@material-ui/core';
import { Colors, User, UserStatus } from '@rentguru/commons-utils';
import React from 'react';
import { useIntl } from 'react-intl';
import { ColoredBox } from '@up2rent/ui';

interface UserRoleFieldProps {
  user: User;
}

const UserRoleField: React.FC<UserRoleFieldProps> = ({ user }) => {
  const { formatMessage } = useIntl();

  const isPending = user.status === UserStatus.PENDING;

  if (isPending) {
    return (
      <ColoredBox boxColor={Colors.CARROT_ORANGE}>{formatMessage({ id: 'settings.addMember.pending' })}</ColoredBox>
    );
  }

  if (user.rootUser) {
    return <ColoredBox boxColor={Colors.TURQUOISE_BLUE}>{formatMessage({ id: 'settings.admin' })}</ColoredBox>;
  }

  if (user.status === UserStatus.DISABLED) {
    return <ColoredBox boxColor={Colors.TOWER_GREY}>{formatMessage({ id: 'enums.UserStatus.DISABLED' })}</ColoredBox>;
  }

  if (!user.userRole) {
    return null;
  }

  return (
    <Typography variant="subtitle1" style={{ fontSize: 14, color: Colors.SLATE_GREY }}>
      {user.userRole.name}
    </Typography>
  );
};

export default UserRoleField;
