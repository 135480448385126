import React from 'react';
import { FieldFilterSelector } from 'src/components/ui/FieldFilterSelector';
import isNil from 'lodash/isNil';
import { useLocation } from 'react-router-dom';
import {
  setSeconds,
  setMinutes,
  setHours,
  areIntervalsOverlapping,
  maxTime,
  minTime,
  isWithinInterval,
} from 'date-fns';
import FieldFilterResetButton from '../../ui/FieldFilterResetButton';
import { useIntl } from 'react-intl';
import { useFilters, FilterEntity, StatementsFilters } from '../../../hooks/FiltersContext';
import { getContactNameFromObject, Statement, StatementType } from '@rentguru/commons-utils';
import { StatementDateFilter } from '../StatementDateFilter';
import { extendOwnerStatement, OwnerStatementExtended } from './OwnerStatements';
import { isEmpty } from 'lodash';
import { useUnits } from 'src/hooks/UnitsContext';

export const filterOwnerStatements = (statements: OwnerStatementExtended[], statementsFilters: StatementsFilters) => {
  const { filters, statementsPeriod, statementsSentDate } = statementsFilters;
  return statements.filter((statement) => {
    const unitsIdsSelected = filters.find((f) => f.name === 'unit')?.items ?? [];
    const isUnitFitlerSelected = !isEmpty(unitsIdsSelected);

    if (isUnitFitlerSelected) {
      // If no units that is in the selected id => return false
      if (!statement.units.some((u) => unitsIdsSelected.includes(u.id))) return false;
    }

    const ownersIdsSelected = filters.find((f) => f.name === 'owner')?.items ?? [];
    const isOwnerFilterSelected = !isEmpty(ownersIdsSelected);
    if (isOwnerFilterSelected) {
      // If no owner that is in the selected id => return false
      if (!statement.owner || !ownersIdsSelected.includes(statement.owner.id)) return false;
    }

    const typesSelected = filters.find((f) => f.name === 'type')?.items ?? [];
    const isTypeFilterSelected = !isEmpty(typesSelected);
    if (isTypeFilterSelected) {
      // If the statmeent has no types that is selected => return false
      if (!statement.types || !(statement.types as StatementType[]).some((type) => typesSelected.includes(type)))
        return false;
    }

    if (statementsPeriod) {
      const fromDate = new Date(statement.periodFrom);
      const toDate = new Date(statement.periodTo);
      const { from, to } = statementsPeriod;
      const fromDateFilter = from ? new Date(from) : new Date(minTime);
      const toDateFilter = to ? new Date(to) : new Date(maxTime);
      if (!areIntervalsOverlapping({ start: fromDate, end: toDate }, { start: fromDateFilter, end: toDateFilter }))
        return false;
    }

    if (statementsSentDate) {
      if (!statement.sentDate) return false;
      const sentDate = new Date(statement.sentDate);
      const { from, to } = statementsSentDate;
      const fromDateFilter = from ? new Date(from) : new Date(minTime);
      const toDateFilter = to ? new Date(to) : new Date(maxTime);
      if (!isWithinInterval(sentDate, { start: fromDateFilter, end: toDateFilter })) return false;
    }
    return true;
  });
};

interface OwnerStatementsFiltersComponentProps {
  ownerStatementsToCheck: Statement[];
  ownerStatementsArchived: Statement[];
}

const OwnerStatementsFiltersComponent: React.FC<OwnerStatementsFiltersComponentProps> = ({
  ownerStatementsToCheck,
  ownerStatementsArchived,
}) => {
  const {
    ownerStatementsFilters: { filters, statementsPeriod, statementsSentDate },
    updateFilters,
    resetFilters,
  } = useFilters();
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();
  const { unitsLoading, getUnit } = useUnits();
  let ownerStatements = ownerStatementsToCheck;
  if (pathname.includes('toValidate')) {
    ownerStatements = ownerStatementsToCheck;
  } else if (pathname.includes('archived')) {
    ownerStatements = ownerStatementsArchived;
  }

  if (unitsLoading) return null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFilterChange = (selectedItems: any[], filterName: string, fieldName: string, menuClosed: boolean) => {
    if (menuClosed) {
      return; // Don't set filter again
    }
    if (filterName !== 'statementPeriod' && filterName !== 'statementSendDate') {
      const filter = { name: filterName, field: fieldName, items: selectedItems.map((i) => i.value) };
      let newFilters = filters;

      const index = newFilters.findIndex((f) => f.name === filterName);
      if (index === -1) {
        newFilters = [...newFilters, filter];
      } else {
        newFilters = [...newFilters.filter((f) => f.name !== filterName), filter];
      }
      updateFilters<StatementsFilters>(FilterEntity.OWNER_STATEMENTS, {
        filters: newFilters,
        statementsPeriod,
        statementsSentDate,
      });
    }
  };

  const handleFilterReset = (filterName: string, _fieldName: string) => {
    if (filterName === 'statementPeriod') {
      updateFilters<StatementsFilters>(FilterEntity.OWNER_STATEMENTS, {
        filters,
        statementsPeriod: null,
        statementsSentDate,
      });
    } else if (filterName === 'statementSendDate') {
      updateFilters<StatementsFilters>(FilterEntity.OWNER_STATEMENTS, {
        filters,
        statementsPeriod,
        statementsSentDate: null,
      });
    }
  };

  const handleFilterDateFromTo = (
    from: Date | null,
    to: Date | null,
    filterName: 'statementPeriod' | 'statementSendDate'
  ) => {
    const oldFromTo = filterName === 'statementPeriod' ? statementsPeriod : statementsSentDate;
    let newFrom = null;
    let newTo = null;
    if (isNil(from)) {
      newFrom = isNil(oldFromTo) ? null : oldFromTo.from;
    } else {
      from = setSeconds(from, 0);
      from = setMinutes(from, 0);
      from = setHours(from, 0);
      newFrom = from.toISOString();
    }
    if (isNil(to)) {
      newTo = isNil(oldFromTo) ? null : oldFromTo.to;
    } else {
      to = setSeconds(to, 0);
      to = setMinutes(to, 0);
      to = setHours(to, 0);
      newTo = to.toISOString();
    }
    // Reset
    if (isNil(from) && isNil(to)) {
      updateFilters<StatementsFilters>(FilterEntity.OWNER_STATEMENTS, {
        filters,
        statementsPeriod: filterName === 'statementPeriod' ? null : statementsPeriod,
        statementsSentDate: filterName === 'statementSendDate' ? null : statementsSentDate,
      });
    } else {
      updateFilters<StatementsFilters>(FilterEntity.OWNER_STATEMENTS, {
        filters,
        statementsPeriod: filterName === 'statementPeriod' ? { from: newFrom!, to: newTo! } : statementsPeriod,
        statementsSentDate: filterName === 'statementSendDate' ? { from: newFrom!, to: newTo! } : statementsSentDate,
      });
    }
  };

  const ownerStatementsExtended = ownerStatements.map((ts) => extendOwnerStatement(ts, getUnit));

  // Render the filters
  const ownerNames = ownerStatementsExtended.reduce((acc: { label: string; value: string }[], statement) => {
    if (statement.owner) {
      const ownerAlreadyInList = acc.some((element) => element.value === statement.owner!.id);
      if (!ownerAlreadyInList)
        acc.push({
          value: statement.owner.id,
          label: getContactNameFromObject(statement.owner),
        });
    }
    return acc;
  }, []);
  const unitNames = ownerStatementsExtended.reduce((acc: { label: string; value: string }[], statement) => {
    const units = statement.units;
    for (const unit of units) {
      const recipientAlreadyInList = acc.some((c) => c.value === unit.id);
      if (!recipientAlreadyInList) {
        acc.push({
          value: unit.id,
          label: unit.name,
        });
      }
    }
    return acc;
  }, []);
  const typeNames = ownerStatementsExtended
    .reduce((acc: string[], statement) => {
      if (statement.types) {
        for (const type of statement.types) {
          if (!acc.includes(type)) acc.push(type);
        }
      }
      return acc;
    }, [])
    .map((type) => ({ value: type, label: formatMessage({ id: `enums.StatementType.${type}` }) }));

  const initialUnitSelectedItems = filters.find((f) => f.name === 'unit');
  const initialOwnerSelectedItems = filters.find((f) => f.name === 'owner');
  const initialTypesSelectedItems = filters.find((f) => f.name === 'type');

  return (
    <div
      style={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 15,
        marginRight: 24,
      }}
    >
      <FieldFilterSelector
        label={formatMessage({ id: 'financial.period' })}
        showResetButton
        showSearchField={false}
        filterName="statementPeriod"
        fieldName="period"
        textReset={formatMessage({ id: 'reinitFilter' })}
        textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
        textSearchField={formatMessage({ id: 'search' })}
        textSelectAll={formatMessage({ id: 'selectAll' })}
        onChange={handleFilterChange}
        onReset={handleFilterReset}
        minSelectorHeight={700}
        minSelectorWidth={1500}
        selected={!isNil(statementsPeriod)}
      >
        <StatementDateFilter
          dateFromTo={statementsPeriod}
          type="statementPeriod"
          handleFilterDateFromTo={handleFilterDateFromTo}
        />
      </FieldFilterSelector>

      {pathname.includes('archived') && (
        <FieldFilterSelector
          label={formatMessage({ id: 'accounting.statement.archivedSection.sentDate' })}
          showResetButton
          showSearchField={false}
          filterName="statementSendDate"
          fieldName="sendDate"
          textReset={formatMessage({ id: 'reinitFilter' })}
          textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
          textSearchField={formatMessage({ id: 'search' })}
          textSelectAll={formatMessage({ id: 'selectAll' })}
          onChange={handleFilterChange}
          onReset={handleFilterReset}
          minSelectorHeight={700}
          minSelectorWidth={1500}
          selected={!isNil(statementsSentDate)}
        >
          <StatementDateFilter
            dateFromTo={statementsSentDate}
            type="statementSendDate"
            handleFilterDateFromTo={handleFilterDateFromTo}
          />
        </FieldFilterSelector>
      )}
      <FieldFilterSelector
        label={formatMessage({ id: 'lease.addLease.unit' })}
        multipleSelection
        showResetButton
        filterName="unit"
        fieldName="units.unitId"
        textReset={formatMessage({ id: 'reinitFilter' })}
        textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
        textSearchField={formatMessage({ id: 'search' })}
        textSelectAll={formatMessage({ id: 'selectAll' })}
        onChange={handleFilterChange}
        initialSelectedItems={
          initialUnitSelectedItems ? initialUnitSelectedItems.items.map((u) => ({ value: u })) : null
        }
        scrollable
      >
        {unitNames}
      </FieldFilterSelector>
      <FieldFilterSelector
        label={formatMessage({ id: 'building.detail.general.owner' })}
        multipleSelection
        showResetButton
        filterName="owner"
        fieldName="ownerId"
        textReset={formatMessage({ id: 'reinitFilter' })}
        textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
        textSearchField={formatMessage({ id: 'search' })}
        textSelectAll={formatMessage({ id: 'selectAll' })}
        onChange={handleFilterChange}
        initialSelectedItems={
          initialOwnerSelectedItems ? initialOwnerSelectedItems.items.map((t) => ({ value: t })) : null
        }
        scrollable
      >
        {ownerNames}
      </FieldFilterSelector>
      <FieldFilterSelector
        label={formatMessage({ id: 'rentalUnit.columnHeader.type' })}
        multipleSelection
        showResetButton
        filterName="type"
        fieldName="type"
        textReset={formatMessage({ id: 'reinitFilter' })}
        textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
        textSearchField={formatMessage({ id: 'search' })}
        textSelectAll={formatMessage({ id: 'selectAll' })}
        onChange={handleFilterChange}
        initialSelectedItems={
          initialTypesSelectedItems ? initialTypesSelectedItems.items.map((t) => ({ value: t })) : null
        }
        scrollable
      >
        {typeNames}
      </FieldFilterSelector>
      <FieldFilterResetButton resetFilterValues={() => resetFilters(FilterEntity.OWNER_STATEMENTS)} />
    </div>
  );
};

export default OwnerStatementsFiltersComponent;
