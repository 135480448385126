/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useRef } from 'react';
import useResizeObserver from './utils/useResizeObserver';
import { scaleLinear, select, axisLeft, axisBottom, line, curveCardinal, curveLinear, pointer } from 'd3';
import { isNil } from 'lodash';
import { distance } from '../ReordableList/utils';
import { Colors } from '@rentguru/commons-utils';

interface Point2D {
  x: number;
  y: number;
}

interface LineChartLegend {
  x: number;
  y: number;
  category: string;
  color: string;
}

interface XAxisOptions {
  minX: number;
  maxX: number;
  numberOfXTicks?: number;
  xTickValues?: number[];
  xTickFormat?: (x: number) => string;
  xTooltipFormat: (x: number) => string;
}

interface YAxisOptions {
  minY: number;
  maxY: number;
  numberOfYTicks?: number;
  yTickFormat?: (y: number) => string;
  yTooltipFormat: (y: number) => string;
}

interface LineChartProps {
  datas: Point2D[][];
  xOptions: XAxisOptions;
  yOptions: YAxisOptions;
  curved?: boolean;
  lineNames: string[];
}

const margin = { top: 30, right: 30, bottom: 30, left: 30, text: 10 };
const colors = [
  Colors.SILVER,
  Colors.BLUEY,
  Colors.SUN_YELLOW,
  Colors.CARROT_ORANGE,
  Colors.CARNATION_RED,
  Colors.DARK_SLATE_GREY,
];

const LineChart: React.FC<LineChartProps> = ({ datas, xOptions, yOptions, curved = true, lineNames }) => {
  const { minX, maxX, numberOfXTicks, xTickValues, xTickFormat, xTooltipFormat } = xOptions;
  const { minY, maxY, numberOfYTicks, yTickFormat, yTooltipFormat } = yOptions;
  const svgRef = useRef<SVGSVGElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const dimensions = useResizeObserver(wrapperRef);
  const startOfXAxisLeftPadding = 40;
  useEffect(() => {
    if (!wrapperRef || !wrapperRef.current) return;
    const svg = select(svgRef.current);
    const { width, height } = dimensions || wrapperRef.current.getBoundingClientRect();
    // Scales
    const xScale = scaleLinear().domain([minX, maxX]).range([startOfXAxisLeftPadding, width]);

    const yScale = scaleLinear()
      .domain([minY, maxY])
      .range([height - (margin.bottom + margin.text), margin.text]);

    const myLine = line<Point2D>()
      .x((value) => xScale(value.x))
      .y((value) => yScale(value.y))
      .curve(curved ? curveCardinal : curveLinear);

    datas.forEach((data, i) => {
      svg
        .selectAll(`.line${i}`)
        .data([data.sort((point1, point2) => +(point1.x > point2.x) * 2 - 1)])
        .join('path')
        .attr('class', `line${i}`)
        .attr('d', myLine)
        .transition()
        .attr('fill', 'none')
        .attr('stroke', colors[i % 6])
        .attr('stroke-width', '2px');
    });

    /* Tooltip */
    const formatTooltipHtml = (datas: Point2D[][], pointerX: number) => {
      const legend = datas.reduce((acc: LineChartLegend[], data, i) => {
        const res = data.reduce((closestData: Point2D | null, currentData) => {
          if (isNil(closestData)) {
            return currentData;
          }
          const pointOfCurrentData = xScale(currentData.x) || 0;
          const pointOfLastestData = xScale(closestData.x) || 0;
          if (distance(pointOfCurrentData, pointerX) < distance(pointOfLastestData, pointerX)) {
            return currentData;
          }

          return closestData;
        }, null);
        if (!isNil(res)) {
          acc.push({ x: res.x, y: res.y, category: lineNames[i], color: colors[i % 6] });
          return acc;
        }
        return acc;
      }, []);
      legend.sort((a, b) => b.y - a.y);
      const content = legend
        .map((l) => {
          return `<div style="text-align: left;"> <svg height="10" width="10">
      <circle cx="5" cy="5" r="3" fill="${l.color}" />
      </svg> ${l.category}: ${yTooltipFormat(l.y)}  </div>`;
        })
        .join('');
      return `<div style="text-align: left; font-weight:700;">${xTooltipFormat(legend[0].x)}</div>${content}`;
    };
    const divTooltip = select(wrapperRef.current).select<HTMLDivElement>('.toolTip').style('opacity', 0);
    const focus = svg.append('g').attr('class', 'focus');
    const mousePerLine = focus
      .selectAll('.mouse-per-line')
      .data(datas)
      .enter()
      .append('g')
      .attr('class', 'mouse-per-line');

    mousePerLine
      .append('circle')
      .attr('r', 4)
      .style('stroke', (d, i) => {
        return colors[i];
      })
      .style('fill', 'none')
      .style('stroke-width', 2)
      .style('opacity', '0');

    svg
      .append('rect')
      .attr('class', 'overlay')
      .attr('width', width)
      .attr('height', height)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('mouseover', () => {
        divTooltip.transition().duration(10).style('opacity', 0.9);
        mousePerLine.selectAll('circle').style('opacity', '1');
      })
      .on('mousemove', (event) => {
        const [pointerX, pointerY] = pointer(event);
        divTooltip.style('top', `${pointerY + 50}px`).style('left', `${pointerX + 50}px`);
        divTooltip.html(formatTooltipHtml(datas, pointerX));
        mousePerLine.attr('transform', (d) => {
          const res = d.reduce((closestData: Point2D | null, currentData) => {
            if (isNil(closestData)) {
              return currentData;
            }
            const pointOfCurrentData = xScale(currentData.x) || 0;
            const pointOfLastestData = xScale(closestData.x) || 0;
            if (distance(pointOfCurrentData, pointerX) < distance(pointOfLastestData, pointerX)) {
              return currentData;
            }

            return closestData;
          }, null);
          if (!isNil(res)) {
            return `translate(${xScale(res.x)},${yScale(res.y)})`;
          }
          return 'none';
        });
      })
      .on('mouseout', () => {
        divTooltip.transition().duration(10).style('opacity', 0);
        mousePerLine.selectAll('circle').style('opacity', '0');
      });

    // axes
    let xAxis = axisBottom(xScale).tickSizeOuter(0).tickSizeInner(0);
    if (!isNil(numberOfXTicks)) {
      xAxis = xAxis.ticks(numberOfXTicks);
    }
    if (!isNil(xTickValues)) {
      xAxis = xAxis.tickValues(xTickValues);
    }
    if (!isNil(xTickFormat)) {
      xAxis = xAxis.tickFormat((value) => xTickFormat(value.valueOf()));
    }
    let yAxis = axisLeft(yScale).tickSize(-width);
    if (!isNil(numberOfYTicks)) {
      yAxis = yAxis.ticks(numberOfYTicks);
    }
    if (!isNil(yTickFormat)) {
      yAxis = yAxis.tickFormat((value) => yTickFormat(value.valueOf()));
    }

    svg
      .select<SVGGElement>('.x-axis')
      .attr('transform', `translate(0, ${yScale(0)})`)
      .call(xAxis)
      .call((g) => g.select('.domain').attr('stroke', Colors.GEYSER_GREY).attr('stroke-width', '1'))
      .call((g) => g.select('.tick line').attr('stroke', 'none'))
      .selectAll('text')
      .attr('transform', `translate(0, 10)`)
      .style('font-size', '12px')
      .style('font-family', 'Mulish')
      .style('font-weight', '500')
      .style('font-stretch', 'normal')
      .style('font-style', 'normal')
      .style('line-height', 'normal')
      .style('letter-spacing', 'normal')
      .style('text-align', 'center')
      .style('fill', Colors.BLUEY);

    svg
      .select<SVGGElement>('.y-axis')
      .call(yAxis)
      .call((g) => g.select('.domain').remove())
      .selectAll('text')
      .style('font-size', '12px')
      .style('transform', 'translateY(-10px)')
      .style('font-family', 'Mulish')
      .style('font-weight', '500')
      .style('font-stretch', 'normal')
      .style('font-style', 'normal')
      .style('line-height', 'normal')
      .style('letter-spacing', 'normal')
      .style('text-anchor', 'start')
      .style('fill', Colors.BLUEY);
    svg
      .select<SVGGElement>('.y-axis')
      .selectAll('.tick line')
      .attr('stroke', Colors.GEYSER_GREY)
      .attr('stroke-width', '1');
  }, [
    datas,
    dimensions,
    minX,
    maxX,
    minY,
    maxY,
    curved,
    xTickFormat,
    yTickFormat,
    xTooltipFormat,
    yTooltipFormat,
    numberOfXTicks,
    xTickValues,
    numberOfYTicks,
    lineNames,
  ]);

  return (
    <>
      <div ref={wrapperRef} style={{ margin: 30 }}>
        <svg ref={svgRef} style={{ display: 'block', width: '100%', height: 260, overflow: 'visible' }}>
          <defs>
            <linearGradient id="defaultColor" x1="1" y1="1">
              <stop stopColor={Colors.BURNING_ORANGE} />
              <stop offset="1" stopColor={Colors.STRONG_PINK} />
            </linearGradient>
          </defs>
          <g className="x-axis" />
          <g className="y-axis" />
        </svg>

        <div
          className="toolTip"
          style={{
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            fontFamily: 'Mulish',
            fontSize: 12,
            fontWeight: 500,
            color: Colors.BLUEY,
            maxWidth: 320,
            display: 'block',
            zIndex: 999,
            backgroundColor: Colors.CLASSICAL_WHITE,
            borderRadius: 4,
          }}
        />
      </div>
    </>
  );
};

export default LineChart;
