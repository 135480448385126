import { Grid, MenuItem, Typography } from '@material-ui/core';
import { CustomLabel } from '@rentguru/commons-utils';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import CustomizedSelect from 'src/components/ui/CustomizedSelect';
import { translateText } from 'src/utils/translationUtils';
import { CustomSimpleDialog } from '@up2rent/ui';

interface TranslateFormValues {
  sourceLanguage: string;
  destinationLanguages: string[];
}

export interface ImportAndTranslateDialogProps {
  texts: CustomLabel[];
  entity: string; // Title text
  theEntity: string; // Helper text
  ofTheEntity: string; // Warning text
  defaultSourceLanguage?: string;
  sourceLanguageDisabled?: boolean;
  defaultDestinationLanguages?: string[];
  destinationLanguageDisabled?: boolean;
  open: boolean;
  onClose: () => void;
  afterSubmit?: (texts: CustomLabel[]) => void;
}

const TranslateDialog: React.FC<ImportAndTranslateDialogProps> = ({
  texts,
  entity,
  theEntity,
  ofTheEntity,
  defaultSourceLanguage,
  sourceLanguageDisabled,
  defaultDestinationLanguages,
  destinationLanguageDisabled,
  open,
  onClose,
  afterSubmit,
}) => {
  const { formatMessage } = useIntl();
  const allLanguages: string[] = texts.map((t) => t.language);

  const handleSubmit = async (values: TranslateFormValues, { setSubmitting }: FormikHelpers<TranslateFormValues>) => {
    const { sourceLanguage, destinationLanguages } = values;
    const textOfSourceLanguage = texts.find((text) => text.language === sourceLanguage);
    const newTexts = [...texts];
    let translateTextPromises: Promise<void>[] = [];
    if (textOfSourceLanguage) {
      translateTextPromises = destinationLanguages.map(async (destinationLanguage) => {
        const newText = await translateText(textOfSourceLanguage.label, sourceLanguage, destinationLanguage);
        const newTextIndex = newTexts.findIndex((text) => text.language === destinationLanguage);
        newTexts[newTextIndex] = { label: newText, language: destinationLanguage };
      });
    }
    await Promise.all(translateTextPromises);
    if (afterSubmit) afterSubmit(newTexts);
    onClose();
    setSubmitting(false);
  };
  const initDestinationsLanguages =
    defaultDestinationLanguages ||
    texts.reduce((acc: string[], text) => {
      if (text.label === '') acc.push(text.language);
      return acc;
    }, []);
  const firstLanguageDifferentFromDestination = allLanguages.find((l) => !initDestinationsLanguages.includes(l));
  const initSourceLanguage =
    defaultSourceLanguage || firstLanguageDifferentFromDestination || initDestinationsLanguages[0];

  return (
    <Formik
      initialValues={
        {
          sourceLanguage: initSourceLanguage,
          destinationLanguages: initDestinationsLanguages,
        } as TranslateFormValues
      }
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, handleSubmit: onActionButtonClick }) => {
        const { sourceLanguage, destinationLanguages } = values;
        const possibleSourceLanguages = allLanguages.filter(
          (l) => !destinationLanguages.includes(l) || l === sourceLanguage
        );
        const possibleDestinationLanguages = allLanguages.filter((l) => l !== sourceLanguage);
        return (
          <Form>
            <CustomSimpleDialog
              open={open}
              onClose={onClose}
              onActionButtonClick={onActionButtonClick}
              actionButtonLabel={formatMessage({ id: 'templates.translate.translate' })}
              actionButtonLoading={isSubmitting}
              actionButtonId="validationButton"
              dividerBelowTitle
              title={formatMessage(
                { id: 'templates.translate.dialogTitle' },
                {
                  subject: entity,
                }
              )}
              formatMessage={formatMessage}
            >
              <Typography style={{ fontSize: 16, fontWeight: 600, marginTop: 10 }}>
                {formatMessage({ id: 'templates.translate.languageSelection' }, { entity: ofTheEntity })}
              </Typography>
              <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <CustomizedSelect
                  label={formatMessage({ id: 'templates.translate.sourceLanguage' })}
                  fieldName="sourceLanguage"
                  disabled={sourceLanguageDisabled}
                  value={values.sourceLanguage}
                  FormControlProps={{ required: false, style: { width: 250 } }}
                >
                  {possibleSourceLanguages.map((language) => {
                    return (
                      <MenuItem key={language} value={language}>
                        {formatMessage({
                          id: `enums.AdvertisementLanguages.${language.toLowerCase()}`,
                        })}
                      </MenuItem>
                    );
                  })}
                </CustomizedSelect>
                <Typography style={{ fontSize: 16, fontWeight: 700 }} id="arrowIcon">
                  {'→'}
                </Typography>
                <CustomizedSelect
                  label={formatMessage({ id: 'templates.translate.destinationLanguage' })}
                  fieldName="destinationLanguages"
                  value={values.destinationLanguages}
                  disabled={destinationLanguageDisabled}
                  multiple={true}
                  FormControlProps={{ required: false, style: { width: 250 } }}
                >
                  {possibleDestinationLanguages.map((language) => {
                    return (
                      <MenuItem key={language} value={language}>
                        {formatMessage({
                          id: `enums.AdvertisementLanguages.${language.toLowerCase()}`,
                        })}
                      </MenuItem>
                    );
                  })}
                </CustomizedSelect>
              </Grid>
              <Typography style={{ fontSize: 12, fontStyle: 'italic' }}>
                {formatMessage({ id: 'templates.translate.translationWarning' }, { entity: theEntity })}
              </Typography>
            </CustomSimpleDialog>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TranslateDialog;
