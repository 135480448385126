import { CircularProgress, Grid } from '@material-ui/core';
import { isNil } from 'lodash';
import { PDFDocumentProxy, getDocument } from 'pdfjs-dist';
import { CSSProperties, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

interface PDFViewerProps {
  url: string;
  pageToShow?: number;
  width?: number | string;
  onLoadSuccess?: (document: PDFDocumentProxy) => void;
  gridStyle?: CSSProperties;
  shouldShowLoading?: boolean;
}

const PDFViewer: React.FC<PDFViewerProps> = ({
  url,
  pageToShow = 1,
  width = 60,
  onLoadSuccess,
  gridStyle,
  shouldShowLoading = true,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [pdfRef, setPdfRef] = useState<PDFDocumentProxy | undefined>();
  const [htmlCanvas, setHtmlCanvas] = useState<ReactNode | null>(null);

  const renderPage = useCallback(
    async (pageNum: number, pdf = pdfRef) => {
      if (!pdf) return;
      const page = await pdf.getPage(pageNum);
      const viewport = page.getViewport({ scale: 1.5, dontFlip: false });
      const canvas = canvasRef.current;
      const context = canvas?.getContext('2d');
      if (isNil(context)) return;
      canvas!.height = viewport.height;
      canvas!.width = viewport.width;
      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      page.render(renderContext);
    },
    [pdfRef]
  );

  useEffect(() => {
    if (!pdfRef) return;
    renderPage(pageToShow, pdfRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfRef, pageToShow, renderPage]);

  useEffect(() => {
    //This useEffect is only used to avoid multiple render with the same canvas
    if (!pdfRef) return;
    setHtmlCanvas(
      <canvas ref={canvasRef} key={'pdf-canvas-' + uuid()} style={{ width, aspectRatio: 'auto 744 / 1053' }} />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfRef, renderPage]);

  useEffect(() => {
    if (pdfRef) return;
    let unmounted = false;
    const getPdf = async () => {
      const loadingTask = getDocument(url);
      const pdf = await loadingTask.promise;
      if (onLoadSuccess) {
        onLoadSuccess(pdf);
      }
      setPdfRef(pdf);
    };

    setHtmlCanvas(
      <canvas ref={canvasRef} key={'pdf-canvas-' + uuid()} style={{ width, aspectRatio: 'auto 744 / 1053' }} />
    );
    if (!unmounted) {
      getPdf();
    }
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  if (isNil(htmlCanvas) && shouldShowLoading) {
    return (
      <Grid style={{ ...gridStyle, minHeight: 50, display: 'flex', alignItems: 'center' }}>
        <CircularProgress size={24} />
      </Grid>
    );
  }

  return <Grid style={gridStyle}>{htmlCanvas}</Grid>;
};

export default PDFViewer;
