import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Colors,
  FIGURES_BOX_KEY,
  LeasePriceHistory,
  LeaseVariousOperation,
  Valuation,
  formatNumber,
} from '@rentguru/commons-utils';
import isNil from 'lodash/isNil';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { CustomPopover } from '@up2rent/ui';
import { useDashboardFilters } from 'src/hooks/DashboardFiltersContext';
import { useLeasePriceHistories } from 'src/hooks/LeasePriceHistoriesContext';
import { useLeaseVariousOperations } from 'src/hooks/LeaseVariousOperationsContext';
import { useLeases } from 'src/hooks/LeasesContext';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { useValuations } from 'src/hooks/ValuationsContext';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import MetricBox, { MetricBoxLayoutProps } from './MetricBox';
import {
  calculateAverageDaysOfRentalVacancy,
  calculateAverageRent,
  calculateGrossPurchasePrice,
  calculateGrossRentalYield,
  calculateNetPurchasePrice,
  calculateNumberOfUnits,
  calculateTotalPropertyValue,
  getAcquiredUnitsAndBuildingAtDate,
} from './utils/DashboardKeyfiguresUtils';
import { HelperType, getKeyFigureHelperText } from './utils/KeyFiguresHelpers';

export const useStyles = makeStyles(() => ({
  flexGrid: {
    display: 'flex',
    align: 'left',
    marginLeft: 20,
    marginTop: 20,
    marginBottom: 20,
  },
  contentHeader: {
    textAlign: 'left',
    marginBottom: 40,
    display: 'flex',
    flexDirection: 'row',
    marginRight: 30,
  },
  figureTitle: {
    flex: 1,
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Mulish',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  figureLegend: {
    textAlign: 'left',
    color: Colors.LIGHT_BLUE_GREY,
    fontSize: '12px',
    lineHeight: '12px',
    letterSpacing: '0px',
  },
  figureValue: {
    textAlign: 'left',
    flex: 1,
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Mulish',
    fontSize: '24px',
    fontWeight: 'bold',
  },
}));

interface KeyFigureHelperBundle {
  anchor: SVGSVGElement | null;
  body: React.ReactNode;
}

const DashboardKeyFigures: React.FC<MetricBoxLayoutProps> = ({ forceUpdateLayout }) => {
  const { formatMessage } = useIntl();
  const [keyFigureHelper, setKeyFigureHelper] = useState<KeyFigureHelperBundle>({ anchor: null, body: null });
  const { unitsLoading } = useUnits();
  const { buildingsLoading } = useBuildings();
  const { leasesLoading } = useLeases();
  const { leasePriceHistories, loading: leaseIndexationsLoading } = useLeasePriceHistories();
  const { discounts, loading: leaseVariousOperationsLoading } = useLeaseVariousOperations();
  const { loading: valuationsLoading, getUnitValuations, getBuildingValuations } = useValuations(true);

  const handlePopoverOpen = (event: React.MouseEvent<SVGSVGElement, MouseEvent>, type: HelperType) => {
    setKeyFigureHelper({ anchor: event.currentTarget, body: getKeyFigureHelperText(type, formatMessage) });
  };

  const handlePopoverClose = () => {
    setKeyFigureHelper({ anchor: null, body: null });
  };

  return (
    <MetricBox
      boxKey={FIGURES_BOX_KEY}
      title={
        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'start' }}>
          <Typography
            style={{
              flex: 1,
              textAlign: 'left',
              color: Colors.CLASSICAL_BLACK,
              fontFamily: 'Mulish',
              fontSize: '16px',
              fontWeight: 'bold',
              marginLeft: 20,
            }}
          >
            {formatMessage({ id: 'dashboard.cellNames.keyNumbers' })}
          </Typography>
          <InfoSvg
            style={{ fill: Colors.SILVER, marginLeft: 10, cursor: 'default' }}
            onMouseEnter={(e) => handlePopoverOpen(e, 'title')}
            onMouseLeave={handlePopoverClose}
          />
        </div>
      }
      timeFilterBundle={{ name: 'keyNumbers', filters: [], customYearFilter: true }}
      forceUpdateLayout={forceUpdateLayout}
      loading={unitsLoading || buildingsLoading || leasesLoading || valuationsLoading || leaseIndexationsLoading}
    >
      <MetricBoxContent
        handlePopoverClose={handlePopoverClose}
        handlePopoverOpen={handlePopoverOpen}
        leaseIndexations={leasePriceHistories ?? []}
        leaseIndexationsLoading={leaseIndexationsLoading}
        discounts={discounts ?? []}
        leaseVariousOperationsLoading={leaseVariousOperationsLoading}
        valuationsLoading={valuationsLoading}
        getUnitValuations={getUnitValuations}
        getBuildingValuations={getBuildingValuations}
      />
      <CustomPopover
        open={!isNil(keyFigureHelper.anchor)}
        anchorEl={keyFigureHelper.anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        paperBorderRadius={10}
      >
        {keyFigureHelper.body}
      </CustomPopover>
    </MetricBox>
  );
};

const MetricBoxContent: React.FC<{
  handlePopoverOpen: (event: React.MouseEvent<SVGSVGElement, MouseEvent>, type: HelperType) => void;
  handlePopoverClose: () => void;
  leaseIndexations: LeasePriceHistory[];
  leaseIndexationsLoading: boolean;
  discounts: LeaseVariousOperation[];
  leaseVariousOperationsLoading: boolean;
  getUnitValuations: (unitId: string) => Valuation[];
  getBuildingValuations: (buildingId: string) => Valuation[];
  valuationsLoading: boolean;
}> = ({
  handlePopoverOpen,
  handlePopoverClose,
  leaseIndexations,
  leaseIndexationsLoading,
  discounts,
  leaseVariousOperationsLoading,
  getUnitValuations,
  getBuildingValuations,
  valuationsLoading,
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const {
    dashboardFilters: { keyNumbers: keyNumbersFilter },
  } = useDashboardFilters();
  const { language } = useLocale();
  const { filteredBuildings, filteredUnits } = useDashboardFilters();
  const { unitsLoading } = useUnits();
  const { buildingsLoading } = useBuildings();
  const { leasesLoading } = useLeases();

  const {
    netPurchasedPrice,
    grossPurchasedPrice,
    totalPropertyValue,
    grossRentalYield,
    averageRent,
    totalUnits,
    rentalVacancy,
  } = useMemo(() => {
    if (
      unitsLoading ||
      buildingsLoading ||
      leasesLoading ||
      valuationsLoading ||
      leaseIndexationsLoading ||
      leaseVariousOperationsLoading
    ) {
      return {
        grossRentalYield: 0,
        averageRent: 0,
        totalUnits: 0,
        netPurchasedPrice: 0,
        grossPurchasedPrice: 0,
        rentalVacancy: 0,
      };
    }

    const { from, to } = keyNumbersFilter;
    const timeWindow = { start: new Date(from), end: new Date(to) };

    // Keep only the units and buildings that were acquired at the time.
    const { units: acquiredUnits, buildings: acquiredBuildings } = getAcquiredUnitsAndBuildingAtDate(
      filteredUnits,
      filteredBuildings,
      timeWindow.end
    );

    return {
      totalPropertyValue: calculateTotalPropertyValue(
        acquiredUnits,
        acquiredBuildings,
        timeWindow.end,
        getUnitValuations,
        getBuildingValuations
      ),
      grossRentalYield: calculateGrossRentalYield(
        acquiredUnits,
        acquiredBuildings,
        timeWindow,
        getUnitValuations,
        getBuildingValuations,
        leaseIndexations,
        discounts
      ),
      averageRent: calculateAverageRent(acquiredUnits, leaseIndexations, timeWindow),
      totalUnits: calculateNumberOfUnits(acquiredUnits),
      netPurchasedPrice: calculateNetPurchasePrice(acquiredUnits, acquiredBuildings),
      grossPurchasedPrice: calculateGrossPurchasePrice(acquiredUnits, acquiredBuildings),
      rentalVacancy: calculateAverageDaysOfRentalVacancy(acquiredUnits, timeWindow),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filteredUnits,
    filteredBuildings,
    unitsLoading,
    buildingsLoading,
    leaseIndexationsLoading,
    keyNumbersFilter,
    leaseVariousOperationsLoading,
  ]);
  return (
    <>
      <Grid container className={classes.flexGrid}>
        <Grid item xs={6} className={classes.figureLegend}>
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'start' }}>
            <Typography>{formatMessage({ id: 'dashboard.keyFigures.grossPurchasedPrice' })}</Typography>
            <InfoSvg
              style={{ fill: Colors.SILVER, marginLeft: 5 }}
              onMouseEnter={(e) => handlePopoverOpen(e, 'grossPurchasedPrice')}
              onMouseLeave={handlePopoverClose}
            />
          </div>
          <Typography className={classes.figureValue}>
            {formatNumber(grossPurchasedPrice as number, language, { style: 'currency', currency: 'EUR' })}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.figureLegend}>
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'start' }}>
            <Typography>{formatMessage({ id: 'dashboard.keyFigures.netPurchasedPrice' })}</Typography>
            <InfoSvg
              style={{ fill: Colors.SILVER, marginLeft: 5 }}
              onMouseEnter={(e) => handlePopoverOpen(e, 'netPurchasedPrice')}
              onMouseLeave={handlePopoverClose}
            />
          </div>
          <Typography className={classes.figureValue}>
            {formatNumber(netPurchasedPrice as number, language, { style: 'currency', currency: 'EUR' })}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'start' }}>
            <Typography className={classes.figureLegend}>
              {formatMessage({ id: 'dashboard.overview.totalPropertyValue' })}
            </Typography>
            <InfoSvg
              style={{ fill: Colors.SILVER, marginLeft: 5 }}
              onMouseEnter={(e) => handlePopoverOpen(e, 'propertyValue')}
              onMouseLeave={handlePopoverClose}
            />
          </div>
          <Typography className={classes.figureValue}>
            {formatNumber(totalPropertyValue as number, language, { style: 'currency', currency: 'EUR' })}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.figureLegend}>
          <Typography>{formatMessage({ id: 'dashboard.overview.totalUnits' })}</Typography>
          <Typography className={classes.figureValue}>{formatNumber(totalUnits as number, language)}</Typography>
        </Grid>
        <Grid item xs={6} className={classes.figureLegend}>
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'start' }}>
            <Typography>{formatMessage({ id: 'dashboard.keyFigures.rentalYield' })}</Typography>
            <InfoSvg
              style={{ fill: Colors.SILVER, marginLeft: 5 }}
              onMouseEnter={(e) => handlePopoverOpen(e, 'rentalYield')}
              onMouseLeave={handlePopoverClose}
            />
          </div>
          <Typography className={classes.figureValue}>
            {formatNumber(grossRentalYield, language, {
              style: 'percent',
              maximumSignificantDigits: 3,
            })}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.figureLegend}>
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'start' }}>
            <Typography>{formatMessage({ id: 'dashboard.keyFigures.daysOfRentalVacancy' })}</Typography>
            <InfoSvg
              style={{ fill: Colors.SILVER, marginLeft: 5 }}
              onMouseEnter={(e) => handlePopoverOpen(e, 'averageLease')}
              onMouseLeave={handlePopoverClose}
            />
          </div>
          <Typography className={classes.figureValue}>
            {formatNumber(Math.round(rentalVacancy as number), language)}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.figureLegend}>
          <Typography>{formatMessage({ id: 'dashboard.overview.averageRent' })}</Typography>
          <Typography className={classes.figureValue}>
            {formatNumber(averageRent as number, language, { style: 'currency', currency: 'EUR' })}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardKeyFigures;
