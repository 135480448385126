import { Divider, Grid, MenuItem, Typography } from '@material-ui/core';
import {
  Colors,
  CommunicationSettingsProfile,
  Contact,
  ContactType,
  DocumentCategory,
  EnergeticPerformance,
  EntityType,
  FileCategory,
  LeaseAmountUpdateStatus,
  LeaseExtended,
  LeasePriceHistoryType,
  LeaseStatus,
  LeaseType,
  NewFile,
  ObjectWithId,
  Technic,
  TechnicType,
  getCurrentLeases,
  getFutureLeases,
  isDateValid,
  isNilOrEmpty,
} from '@rentguru/commons-utils';
import { ActionButton, LoaderButton } from '@up2rent/ui';
import { isAfter } from 'date-fns';
import { Form, Formik, FormikHelpers } from 'formik';
import { get, isEmpty } from 'lodash';
import isNil from 'lodash/isNil';
import toUpper from 'lodash/toUpper';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';
import { includeComplementaryDataSchema } from 'src/components/Technics/AddTechnicDialog';
import AddTechnicDialogComplementaryData, {
  AddTechnicDialogComplementaryDataFormValues,
} from 'src/components/Technics/AddTechnicDialogComplementaryData';
import { getLinks } from 'src/components/Technics/Heatings/AddHeatingForm';
import useTechnicsUtils from 'src/components/Technics/useTechnicsUtils';
import CustomCheckBox from 'src/components/ui/CustomCheckBox';
import CustomCheckBoxGroup from 'src/components/ui/CustomCheckBoxGroup';
import CustomizedSelect from 'src/components/ui/CustomizedSelect';
import FormikDatePicker from 'src/components/ui/FormikDatePicker';
import FileFields from 'src/components/ui/Forms/FormField/FileByCategoryFields';
import ContactSelectForm from 'src/components/ui/Forms/FormSection/ContactSelectForm';
import TextDetailEditable from 'src/components/ui/TextDetailEditable';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { useCommunicationSettingsProfiles } from 'src/hooks/CommunicationSettingsProfilesContext';
import { useContacts } from 'src/hooks/ContactsContext';
import { useDocumentCategory } from 'src/hooks/FileCategoryContext';
import { FilesContext } from 'src/hooks/FilesContext';
import { useLeasePriceHistories } from 'src/hooks/LeasePriceHistoriesContext';
import { useLeases } from 'src/hooks/LeasesContext';
import { FileCategoryTypeForm, useTechnics } from 'src/hooks/TechnicsContext';
import { useUnits } from 'src/hooks/UnitsContext';
import * as Yup from 'yup';
import EPBOverwriteWarningDialog from './EPBOverwriteWarningDialog';

const SELECTED_LEASES_FIELD_NAME = 'selectedBuildingLeases';
const VALIDITY_DATE_FIELD_NAME = 'EpbValidityDate';

interface AddEPBFormProps {
  id: string;
  entity: 'building' | 'unit' | 'leaseBuilding' | 'leaseUnit';
  createFile: FilesContext['createFile'];
  afterSubmit: (id: string) => void;
  cancelSubmit: () => void;
  showTitle?: boolean;
  shouldRedirectAfterCreate?: boolean;
  showComplementaryData?: boolean;
}

const EPBSchema = Yup.object().shape({
  contractNumber: Yup.string().nullable(),
  EpbValidityDate: Yup.date().required(),
  EpbIssueDate: Yup.date().required(),
  EpbReportNumber: Yup.string().required(),
  EpbEnergeticPerformance: Yup.string().required(),
});

export interface EPBInitialValuesType extends AddTechnicDialogComplementaryDataFormValues {
  contractNumber: string;
  EpbValidityDate?: Date;
  EpbIssueDate?: Date;
  EpbEnergeticPerformance: EnergeticPerformance | '';
  EpbReportNumber?: string;
  contact: ObjectWithId[];
  filesByCategories: FileCategoryTypeForm[];
  communicationSettingsProfileId: string;
  buildingLeases: LeaseExtended[];
  selectedBuildingLeases: LeaseExtended[];
  overwriteCurrentUnitLease: boolean;
  overwriteFutureAndDraftsUnitLease: boolean;
}

const AddEPBForm: React.FC<AddEPBFormProps> = ({
  id,
  entity,
  createFile,
  afterSubmit,
  cancelSubmit,
  showTitle = true,
  shouldRedirectAfterCreate = false,
  showComplementaryData = false,
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { createTechnic, getTechnicsFor } = useTechnics();
  const { getBuilding } = useBuildings();
  const { getLease } = useLeases();
  const { getLeasePriceHistoriesOfLease } = useLeasePriceHistories();
  const { getUnit } = useUnits();
  const { getContact } = useContacts();
  const { getPropertyId } = useTechnicsUtils();
  const { getDefaultTechnicCommunicationSettingsProfiles, loading: communicationSettingsProfilesLoading } =
    useCommunicationSettingsProfiles();
  const { enqueueSnackbar } = useSnackbar();
  const { getDocumentCategoryByFileCategory } = useDocumentCategory();

  const [technicCommunicationSettingsProfiles, setTechnicCommunicationSettingsProfiles] = useState<
    CommunicationSettingsProfile[]
  >([]);
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState<boolean>(false);
  const [showLeaseList, setShowLeaseList] = useState<boolean>(false);

  const epbDocumentCategory = getDocumentCategoryByFileCategory(FileCategory.PEB) as DocumentCategory;
  const completeYupSchema = includeComplementaryDataSchema(EPBSchema, showComplementaryData);
  // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
  const defaultTechnicProfile =
    !isEmpty(technicCommunicationSettingsProfiles) && technicCommunicationSettingsProfiles[0];

  useEffect(() => {
    if (communicationSettingsProfilesLoading) {
      return;
    }
    const technicProfiles = getDefaultTechnicCommunicationSettingsProfiles();
    setTechnicCommunicationSettingsProfiles(technicProfiles);
  }, [getDefaultTechnicCommunicationSettingsProfiles, communicationSettingsProfilesLoading]);

  if (communicationSettingsProfilesLoading || isEmpty(technicCommunicationSettingsProfiles)) {
    return (
      <Grid container style={{ justifyContent: 'center' }}>
        <Typography style={{ margin: 20 }}>{formatMessage({ id: 'loading' })}</Typography>
      </Grid>
    );
  }

  const createSnackBarForDraftIndexation = (activeLease: LeaseExtended) => {
    const leasePriceHistories = getLeasePriceHistoriesOfLease(activeLease.id);
    if (leasePriceHistories) {
      const leasePriceHistoryDraft = leasePriceHistories.find(
        (leasePriceHistory) =>
          leasePriceHistory?.type === LeasePriceHistoryType.INDEXATION &&
          leasePriceHistory?.status === LeaseAmountUpdateStatus.DRAFT
      );
      if (leasePriceHistoryDraft) {
        enqueueSnackbar(
          formatMessage(
            { id: 'lease.detail.indexationGeneral.updatingIndexation' },
            {
              leaseName: activeLease.name,
            }
          ),
          {
            persist: false,
            autoHideDuration: 8000,
            variant: 'info',
            preventDuplicate: true,
            key: 'Updating Lease Price History',
          }
        );
      }
    }
  };

  const createFiles = async (
    values: EPBInitialValuesType,
    newTechnic: Technic,
    technicConnectedToLease: Technic | null
  ) => {
    // Files
    const fileEpb = values.filesByCategories.find(
      (fileCategory) => fileCategory.category.fileCategory === FileCategory.PEB
    );
    if (!isNil(fileEpb)) {
      // Compare with originalFile
      const attachmentsPromises = fileEpb.files.map((fileItem) => [
        createFile((fileItem as NewFile).file, EntityType.TECHNIC, newTechnic.id, epbDocumentCategory?.id),
        ...(technicConnectedToLease
          ? [
              createFile(
                (fileItem as NewFile).file,
                EntityType.TECHNIC,
                technicConnectedToLease.id,
                epbDocumentCategory?.id
              ),
            ]
          : []),
      ]);

      const promisesToAwait = attachmentsPromises.flat();

      await Promise.all(promisesToAwait);
    }
  };

  const getBuildingLeases = (buildingId: string) => {
    const building = getBuilding(buildingId);
    if (!building?.units) {
      return [];
    }

    // get all the LeaseExtended for all units in building.
    return building.units.reduce((acc, unitOfBuilding) => {
      const unit = getUnit(unitOfBuilding.id ?? '');
      unit?.leases?.forEach((unitLease) => {
        const lease = unitLease.lease;
        if (lease) {
          const leaseExtended = getLease(lease.id);
          leaseExtended && acc.push(leaseExtended);
        }
      });
      return acc;
    }, [] as LeaseExtended[]);
  };

  const getActiveAndFutureLeases = (leases: LeaseExtended[]) => {
    const currentLeases = getCurrentLeases(leases) as LeaseExtended[] | null;
    const activeLeases = currentLeases?.filter((lease) => lease.status === LeaseStatus.Active) ?? [];
    const futureLeasesAndDrafts = (getFutureLeases(leases) as LeaseExtended[] | null) ?? [];

    return [...activeLeases, ...futureLeasesAndDrafts];
  };

  const getActiveAndFutureBuildingLeases = (buildingId: string) => {
    const leases = getBuildingLeases(buildingId);
    const activeAndFutureLeases = getActiveAndFutureLeases(leases);
    const noParkingLeases = activeAndFutureLeases.filter(
      (lease) => !isNil(lease) && lease.type !== LeaseType.PARKING
    ) as LeaseExtended[];

    return noParkingLeases;
  };

  const getFutureEPBCertificates = (lease: LeaseExtended, validityDate: Date) => {
    const technics = getTechnicsFor(lease.id);
    return technics.filter((technic) => {
      if (!technic.PebValidityDate || technic.type !== TechnicType.PEB) {
        return false;
      }
      const technicValidityDate = new Date(technic.PebValidityDate);
      return isDateValid(technicValidityDate) && isAfter(technicValidityDate, validityDate);
    });
  };

  const getDefaultLeasesForSelector = (leases: LeaseExtended[], validityDate: Date) =>
    leases.filter(
      (lease) => lease.type !== LeaseType.PARKING && isEmpty(getFutureEPBCertificates(lease, validityDate))
    );

  const handleEPBCreationForUnitLeases = async (
    values: EPBInitialValuesType,
    technic: Technic,
    contact: Contact | undefined
  ) => {
    const unit = getUnit(values.unitId ?? '');
    const leasesOfUnit = (unit?.leases ?? []).map((lease) => getLease(lease?.lease?.id ?? ''));
    const activeLeaseFromUnit = values.overwriteCurrentUnitLease
      ? leasesOfUnit.find((lease) => lease?.status === LeaseStatus.Active)
      : undefined;
    const futureLeasesAndDraftsFromUnit = values.overwriteFutureAndDraftsUnitLease
      ? leasesOfUnit ?? getFutureLeases(leasesOfUnit)
      : [];
    const allLeaseFromUnit = [activeLeaseFromUnit, ...futureLeasesAndDraftsFromUnit].filter((lease) => !isNil(lease));

    await Promise.all(
      allLeaseFromUnit.map(async (lease) => {
        let technicConnectedToLease: Technic | null = null;

        technicConnectedToLease = await createTechnic({
          // Generic for technics. code has to make sure it creates a complete technic type
          type: TechnicType.PEB,
          contractNumber: values.contractNumber,
          PebValidityDate: values.EpbValidityDate?.toJSON(),
          PebEnergeticPerformance: toUpper(values.EpbEnergeticPerformance),
          PebReportNumber: values.EpbReportNumber,
          PebIssueDate: values.EpbIssueDate?.toJSON(),
          contactId: contact?.id,
          leaseId: lease.id,
          unitId: unit?.id ?? '',
          communicationSettingsProfileId: values.communicationSettingsProfileId,
          originalTechnicId: technic.id,
        });

        createSnackBarForDraftIndexation(lease);

        return createFiles(values, technic, technicConnectedToLease);
      })
    );
  };

  const handleEPBCreationForBuildingLeases = async (
    values: EPBInitialValuesType,
    technic: Technic,
    contact: Contact | undefined
  ) => {
    if (isNilOrEmpty(values.selectedBuildingLeases)) {
      return await createFiles(values, technic, null);
    }

    await Promise.all(
      values.selectedBuildingLeases.map(async (lease) => {
        const leaseId = lease.id;
        let technicConnectedToLease: Technic | null = null;
        technicConnectedToLease = await createTechnic({
          // Generic for technics. code has to make sure it creates a complete technic type
          type: TechnicType.PEB,
          contractNumber: values.contractNumber,
          PebValidityDate: values.EpbValidityDate?.toJSON(),
          PebEnergeticPerformance: toUpper(values.EpbEnergeticPerformance),
          PebReportNumber: values.EpbReportNumber,
          PebIssueDate: values.EpbIssueDate?.toJSON(),
          contactId: contact?.id,
          leaseId: leaseId,
          buildingId: values.buildingId,
          communicationSettingsProfileId: values.communicationSettingsProfileId,
          originalTechnicId: technic.id,
        });
        return createFiles(values, technic, technicConnectedToLease);
      })
    );
  };

  const handleAfterSubmit = () => {
    if (!isNil(afterSubmit)) {
      afterSubmit(id);
    }
  };
  const handleCancel = () => {
    if (!isNil(cancelSubmit)) {
      cancelSubmit();
    }
  };

  const handleEPBCreation = async (
    values: EPBInitialValuesType,
    { setSubmitting, setStatus }: FormikHelpers<EPBInitialValuesType>
  ) => {
    setSubmitting(true);
    const unit = getUnit(values.unitId ?? '');
    const building = getBuilding(unit?.building?.id ?? values.buildingId ?? '');
    const buildingId = building?.id;
    const contact = getContact(values.contact[0].id);
    const technic = await createTechnic({
      // Generic for technics. code has to make sure it creates a complete technic type
      type: TechnicType.PEB,
      contractNumber: values.contractNumber,
      PebValidityDate: values.EpbValidityDate?.toJSON(),
      PebEnergeticPerformance: toUpper(values.EpbEnergeticPerformance),
      PebReportNumber: values.EpbReportNumber,
      PebIssueDate: values.EpbIssueDate?.toJSON(),
      contactId: contact?.id,
      communicationSettingsProfileId: values.communicationSettingsProfileId,
      ...getLinks(values, id, entity, buildingId ?? ''),
    });

    if (values.onBuilding && values.buildingId) {
      await handleEPBCreationForBuildingLeases(values, technic, contact);
    } else {
      await handleEPBCreationForUnitLeases(values, technic, contact);
    }

    setStatus(true);
    setSubmitting(false);
    handleAfterSubmit();
    if (shouldRedirectAfterCreate) {
      const currentUnit = getUnit(values.onBuilding ? '' : values.unitId ?? '');
      const unitId = `unit-${currentUnit?.id}`;
      history.push({
        pathname: `${RouteDestination.PROPERTIES}/${getPropertyId()}/${!isNil(currentUnit) ? unitId : 'technics'}/${
          !isNil(currentUnit) ? 'technics' : ''
        }`,
        state: { defaultTab: TechnicType.PEB },
      });
    }
  };

  const handleSubmit = async (values: EPBInitialValuesType, formikHelpers: FormikHelpers<EPBInitialValuesType>) => {
    const unit = getUnit(values.unitId ?? '');
    const leases = (
      values.onBuilding ? values.selectedBuildingLeases : unit?.leases?.map((unitLease) => unitLease.lease)
    )?.filter((lease) => !isNil(lease)) as LeaseExtended[];

    if (!leases) {
      return await handleEPBCreation(values, formikHelpers);
    }

    // Check if there are more recent EPB certificate, show a warning dialog if yes.
    const allLeases = getActiveAndFutureLeases(leases);
    const allFutureEpbCertificate = allLeases.reduce(
      (acc, lease) => [...acc, ...getFutureEPBCertificates(lease, values.EpbValidityDate!)],
      [] as Technic[]
    );
    if (!isEmpty(allFutureEpbCertificate)) {
      return setIsWarningDialogOpen(true);
    }

    return await handleEPBCreation(values, formikHelpers);
  };

  const defaultBuildingLeases = ['building'].includes(entity) ? getActiveAndFutureBuildingLeases(id) : [];

  return (
    <Formik
      initialValues={{
        contractNumber: '',
        EpbValidityDate: undefined,
        EpbEnergeticPerformance: '',
        EpbReportNumber: undefined,
        EpbIssueDate: undefined,
        contact: [{ id: '' }],
        filesByCategories: [{ category: epbDocumentCategory, files: [] }],
        onBuilding: false,
        unitId: ['unit', 'leaseUnit'].includes(entity) ? id : '',
        buildingId: ['building'].includes(entity) ? id : '',
        buildingLeases: defaultBuildingLeases,
        selectedBuildingLeases: [],
        // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
        communicationSettingsProfileId: defaultTechnicProfile ? defaultTechnicProfile.id : 'mockId',
        overwriteCurrentUnitLease: true,
        overwriteFutureAndDraftsUnitLease: true,
      }}
      validationSchema={completeYupSchema}
      onSubmit={handleSubmit}
    >
      {(formikHelpers) => {
        const { errors, touched, isSubmitting, status, values, setFieldValue, handleChange, handleBlur } =
          formikHelpers;

        const onValidityDateChange = (date: Date | null) => {
          if (date && isDateValid(date)) {
            const defaultValuesForLeaseSelector = getDefaultLeasesForSelector(
              defaultBuildingLeases?.filter((lease) => !isNil(lease)) ?? [],
              date
            );
            setFieldValue(SELECTED_LEASES_FIELD_NAME, defaultValuesForLeaseSelector);
            setFieldValue(VALIDITY_DATE_FIELD_NAME, date);
            setShowLeaseList(true);
            return;
          }
          setShowLeaseList(false);
        };

        const isLeaseWarning = (lease: LeaseExtended) => {
          const futureEPBCertificates = values.EpbValidityDate
            ? getFutureEPBCertificates(lease, values.EpbValidityDate)
            : [];

          return values.selectedBuildingLeases.includes(lease) && !isEmpty(futureEPBCertificates);
        };

        return (
          <>
            <EPBOverwriteWarningDialog
              open={isWarningDialogOpen}
              onClose={() => setIsWarningDialogOpen(false)}
              onActionButtonClick={async () => await handleEPBCreation(values, formikHelpers)}
              formatMessage={formatMessage}
            />
            <Form>
              <Grid container>
                {showTitle && (
                  <div style={{ marginLeft: 20, marginRight: 20, marginTop: 25, marginBottom: 25 }}>
                    <Typography
                      style={{
                        fontWeight: 'bold',
                        fontSize: 20,
                        textAlign: 'left',
                      }}
                    >
                      {formatMessage({ id: 'technic.epbTab.addEpb' })}
                    </Typography>
                  </div>
                )}
                <Grid item xs={12}>
                  <Divider style={{ marginBottom: 10 }} />
                </Grid>
                {showComplementaryData && (
                  <Grid style={{ marginLeft: 20 }}>
                    <AddTechnicDialogComplementaryData messageId="lease.detail.action.addEpbExample" />
                  </Grid>
                )}
                <Grid item xs={12} style={{ alignItems: 'baseline', marginLeft: 20, marginRight: 20 }}>
                  <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <FormikDatePicker
                      name="EpbIssueDate"
                      label={formatMessage({ id: 'technic.epbTab.epbIssueDate' })}
                      value={values.EpbIssueDate ?? null}
                      style={{ width: 180 }}
                      error={Boolean(errors.EpbIssueDate && touched.EpbIssueDate)}
                    />
                    <FormikDatePicker
                      name={VALIDITY_DATE_FIELD_NAME}
                      label={formatMessage({ id: 'technic.epbTab.epbValidityDate' })}
                      value={values.EpbValidityDate ?? null}
                      onDateChange={onValidityDateChange}
                      style={{ width: 180 }}
                      error={Boolean(errors.EpbValidityDate && touched.EpbValidityDate)}
                    />
                    <CustomizedSelect
                      id="EpbEnergeticPerformance"
                      fieldName="EpbEnergeticPerformance"
                      value={values.EpbEnergeticPerformance} // assuming 'values' holds state
                      onChange={(value) => setFieldValue('EpbEnergeticPerformance', value)}
                      onBlur={handleBlur}
                      error={Boolean(errors.EpbEnergeticPerformance && touched.EpbEnergeticPerformance)}
                      label={`${formatMessage({ id: 'technic.epbTab.energyPerformance' })}`}
                      style={{ width: 180 }}
                      dataTest="peb-energetic-performance-select"
                    >
                      {Object.keys(EnergeticPerformance).map((key) => (
                        <MenuItem
                          id={EnergeticPerformance[key as keyof typeof EnergeticPerformance]}
                          value={EnergeticPerformance[key as keyof typeof EnergeticPerformance]}
                          key={key}
                        >
                          {EnergeticPerformance[key as keyof typeof EnergeticPerformance]}
                        </MenuItem>
                      ))}
                    </CustomizedSelect>
                  </Grid>
                  <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid item xs={6}>
                      <TextDetailEditable
                        title={formatMessage({ id: 'rentalUnit.detail.energy.epbCertificate.epbReportNumber' })}
                        editMode={true}
                        name="EpbReportNumber"
                        type="text"
                        style={{ width: 280, maxWidth: 'none' }}
                        error={Boolean(get(errors, `EpbReportNumber`) && get(touched, `filesByCategories`))}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ContactSelectForm
                        values={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        fieldStyle={{ marginLeft: 0 }}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        fieldLabel={`${formatMessage({
                          id: 'technic.contractorName',
                        })} ${formatMessage({
                          id: 'optional',
                        })}`}
                        addLabel={formatMessage({
                          id: 'technic.contractorName',
                        })}
                        type={ContactType.CONTRACTOR}
                        fieldName="contact"
                        showPercent={false}
                        multiple={false}
                        checkAdress={false}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                </Grid>
                <Grid item xs={12}>
                  <FileFields
                    documentCategory={epbDocumentCategory}
                    categoryType="noHeader"
                    centerSelectedFile
                    maxFiles={1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                </Grid>
                <Grid item xs={12}>
                  {showLeaseList && values.onBuilding && (
                    <Grid style={{ marginLeft: 20, paddingBottom: 20 }}>
                      <Typography style={{ fontSize: 15, fontWeight: 500, paddingLeft: 5 }}>
                        {formatMessage({ id: 'lease.detail.action.selectActiveLeases' })}
                      </Typography>
                      <CustomCheckBoxGroup
                        items={values.buildingLeases}
                        selectedItems={values.selectedBuildingLeases}
                        itemsFieldName={SELECTED_LEASES_FIELD_NAME}
                        isItemWarning={isLeaseWarning}
                      />
                    </Grid>
                  )}
                  {showLeaseList && !values.onBuilding && (
                    <Grid container direction="column">
                      <Grid item>
                        <Typography style={{ fontSize: 15, fontWeight: 500, paddingLeft: 5 }}>
                          <CustomCheckBox
                            isChecked={values.overwriteCurrentUnitLease}
                            checkedColor={Colors.DODGER_BLUE}
                            onCheck={() =>
                              setFieldValue('overwriteCurrentUnitLease', !values.overwriteCurrentUnitLease)
                            }
                          />
                          {formatMessage({ id: 'technic.addTechnicDialog.attachEpbToCurrentLease' })}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography style={{ fontSize: 15, fontWeight: 500, paddingLeft: 5 }}>
                          <CustomCheckBox
                            isChecked={values.overwriteFutureAndDraftsUnitLease}
                            checkedColor={Colors.DODGER_BLUE}
                            onCheck={() =>
                              setFieldValue(
                                'overwriteFutureAndDraftsUnitLease',
                                !values.overwriteFutureAndDraftsUnitLease
                              )
                            }
                          />
                          {formatMessage({ id: 'technic.addTechnicDialog.attachEpbToFutureLeases' })}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginBottom: 20,
                    marginRight: 30,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <ActionButton
                    onClick={() => {
                      handleCancel();
                    }}
                    style={{ background: 'none', color: Colors.DARK_SLATE_GREY, marginRight: 20 }}
                  >
                    {formatMessage({ id: 'cancel' })}
                  </ActionButton>
                  <LoaderButton loading={isSubmitting} success={status}>
                    {formatMessage({
                      id: 'save',
                    })}
                  </LoaderButton>
                </Grid>
              </Grid>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default AddEPBForm;
