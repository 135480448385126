import { Tooltip, Typography } from '@material-ui/core';
import { Colors, IntegrationStatus, IntegrationName } from '@rentguru/commons-utils';
import { formatDistance, parseISO } from 'date-fns';
import React from 'react';
import { useIntl } from 'react-intl';
import { useIntegrations } from 'src/hooks/IntegrationsContext';
import { dateLocaleMap, dateLocaleMapType, useLocale } from 'src/i18n/IntlProviderWrapper';

interface IntegrationColumnProps {
  integrationName: IntegrationName;
}

const getLabelFromIntegrationName = (integrationName: IntegrationName) => {
  if (integrationName === IntegrationName.PONTO) return 'Ponto';
  if (integrationName === IntegrationName.MYBANX) return 'myBanx.io';
  return '';
};

export const IntegrationColumn: React.FC<IntegrationColumnProps> = ({ integrationName }) => {
  const { formatMessage } = useIntl();
  const { integrations } = useIntegrations();
  const { language } = useLocale();

  const integration = integrations?.find((i) => i.name === integrationName);
  const alreadyRun = Boolean(integration?.lastRun);
  const hasError = integration?.status === IntegrationStatus.ERROR;
  const lastSyncDistance = alreadyRun
    ? formatDistance(parseISO(integration!.lastRun!), new Date(), {
        addSuffix: true,
        locale: (dateLocaleMap as dateLocaleMapType)[language],
      })
    : '';
  const integrationLabel = getLabelFromIntegrationName(integrationName);

  return (
    <Tooltip
      title={`${formatMessage(
        { id: 'settings.integrations.lastSync' },
        { name: integrationLabel }
      )} ${lastSyncDistance}${hasError ? ` ${formatMessage({ id: 'settings.integrations.lastSyncError' })}` : ''}`}
    >
      <Typography variant="subtitle1" style={{ fontSize: 14, color: Colors.SLATE_GREY }}>
        {integrationLabel}
      </Typography>
    </Tooltip>
  );
};
