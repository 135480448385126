/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import isNil from 'lodash/isNil';
// eslint-disable-next-line import/named
import { LooseObjectWithId } from '@rentguru/commons-utils';
import { EnhanceTableColumn } from './EnhancedTable';
import { COLUMN_FIELD_TYPE_NUMBER, COLUMN_FIELD_TYPE_STRING } from '../../utils';

interface EnhancedTableSharedContext {
  createSortHandler: (column: EnhanceTableColumn) => void;
  handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleUnselect: (ids: string[]) => void;
  handleClick: (_event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  order: 'asc' | 'desc';
  orderBy: string;
  orderBySortField?: string | null | undefined;
  orderByColumnType: string;
  numSelected: number;
  handleDetail: (id: string, ...args: string[]) => void;
  handleActionMenu: (event: React.ChangeEvent<HTMLInputElement>, id: string, ...args: string[]) => void;
  selected: string[];
}

// eslint-disable-next-line no-redeclare
const EnhancedTableSharedContext = React.createContext<EnhancedTableSharedContext>({
  selected: [],
  order: 'asc',
  orderBy: '',
  orderBySortField: null,
  orderByColumnType: COLUMN_FIELD_TYPE_STRING,
  numSelected: 0,
  createSortHandler: (_column: EnhanceTableColumn) => {},
  handleSelectAllClick: (_event: React.ChangeEvent<HTMLInputElement>) => {},
  handleUnselect: (_ids: string[]) => {},
  handleClick: (_event: React.ChangeEvent<HTMLInputElement>, _id: string) => {},
  handleActionMenu: (_event: React.ChangeEvent<HTMLInputElement>, _id: string) => {},
  handleDetail: (_id: string) => {},
});

interface EnhancedTableSharedContextProviderProps {
  orderFirst?: 'asc' | 'desc';
  datas: LooseObjectWithId[];
  handleDetail?: (id: string, ...args: string[]) => void;
  handleActionMenu?: (
    target: EventTarget &
      HTMLInputElement &
      HTMLElement &
      React.MouseEvent &
      React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string | any,
    ...args: any[]
  ) => void;
  value?: { orderBy: string };
  children?: React.ReactNode;
}
export const EnhancedTableSharedContextProvider: React.FC<EnhancedTableSharedContextProviderProps> = ({
  children,
  orderFirst = 'asc',
  datas,
  handleActionMenu,
  handleDetail,
  value,
}) => {
  const [state, setState] = useState<{
    order: 'asc' | 'desc';
    orderBy: string;
    orderBySortField?: string | null;
    orderByColumnType: string;
  }>({
    order: orderFirst,
    orderBy: value?.orderBy ?? '',
    orderBySortField: null,
    orderByColumnType: COLUMN_FIELD_TYPE_STRING,
  });
  const [selected, setSelected] = useState<string[]>([]);

  const createSortHandler = (column: EnhanceTableColumn) => {
    const orderBy = column.id;
    let order: 'asc' | 'desc' = 'desc';

    if (state.orderBy === column.id && state.order === 'desc') {
      order = 'asc';
    }

    setState({
      order,
      orderBy,
      orderBySortField: column.sortField,
      orderByColumnType: column.numeric ? COLUMN_FIELD_TYPE_NUMBER : COLUMN_FIELD_TYPE_STRING,
    });
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(datas.map((n) => n.id));
      return;
    }
    setSelected([]);
  };

  const handleUnselect = (ids: string[]) => {
    const newSelection = selected.filter((id) => !ids.includes(id));
    setSelected(newSelection);
  };

  const handleClick = (_event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const onHandleDetail = (id: string, ...args: string[]) => {
    if (!isNil(handleDetail)) {
      handleDetail(id, ...args);
    }
  };

  const onHandleActionMenu = (event: React.ChangeEvent<HTMLInputElement>, id: string, ...args: string[]) => {
    if (!isNil(handleActionMenu)) {
      handleActionMenu(event.currentTarget as any, id, ...args);
    }
  };

  return (
    <EnhancedTableSharedContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        createSortHandler,
        handleSelectAllClick,
        handleUnselect,
        handleClick,
        ...state,
        selected,
        numSelected: selected.length,
        handleDetail: onHandleDetail,
        handleActionMenu: onHandleActionMenu,
      }}
    >
      {children}
    </EnhancedTableSharedContext.Provider>
  );
};

export const EnhancedTableSharedContextConsumer = EnhancedTableSharedContext.Consumer;
