import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import TextDetail from 'src/components/ui/TextDetail';
import { getPrintableDate } from 'src/utils/dates';
import { useIntl } from 'react-intl';
import { useTransactions } from 'src/hooks/TransactionsContext';
import { useInvoices } from 'src/hooks/InvoicesContext';
import { Colors, formatNumber, Invoice, Transaction } from '@rentguru/commons-utils';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { ColoredBox , OverflowableTypography } from '@up2rent/ui';

interface LeaseBalancePopoverLineProps {
  title: string;
  date: string;
  amount: number;
  amountColor: string;
  language: string;
}

const LeaseBalancePopoverLine: React.FC<LeaseBalancePopoverLineProps> = ({
  title,
  date,
  amount,
  amountColor,
  language,
}) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={4}>
        <OverflowableTypography
          text={title}
          style={{ fontSize: 16, fontWeight: 'normal', fontStyle: 'normal', letterSpacing: 0 }}
        />
      </Grid>
      <Grid item xs={4}>
        <Typography style={{ fontSize: 16, fontWeight: 'normal', fontStyle: 'normal', letterSpacing: 0 }}>
          {getPrintableDate(date)}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <ColoredBox boxColor={amountColor}>
          {formatNumber(amount, language, { style: 'currency', currency: 'EUR' })}
        </ColoredBox>
      </Grid>
    </Grid>
  );
};

const getTransactionColor = (amount: number, isCreditNote: boolean | null = false) => {
  if (isCreditNote || amount > 0) {
    return Colors.TURQUOISE_BLUE;
  }
  if (amount < 0) {
    return Colors.CARNATION_RED;
  }
  return Colors.SILVER;
};

const getRowLabelId = (invoiceOrTransaction: Invoice | Transaction) => {
  const isInvoice = invoiceOrTransaction.hasOwnProperty('invoiceDate');
  if (!isInvoice) {
    return `enums.TransactionType.TRANSACTION`;
  }

  const invoice: Invoice = invoiceOrTransaction as Invoice;
  if (invoice.creditNote) {
    return `enums.CreditNoteInvoiceType.${invoice.type}`;
  }

  return `enums.InvoiceType.${invoice.type}`;
};
interface LeaseBalancePopoverColumnProps {
  leaseId: string;
}
const LeaseBalancePopoverColumn: React.FC<LeaseBalancePopoverColumnProps> = ({ leaseId }) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const { transactionsLoading, transactionsError, getTransactionsForLease } = useTransactions();
  const { getInvoicesOfLease, loading: invoicesLoading } = useInvoices();

  const transactions = getTransactionsForLease(leaseId, true);
  const invoices = getInvoicesOfLease(leaseId);

  const sortedInvoicesAndTransactions = [...transactions, ...invoices].sort((a, b) => {
    const aDate: string | null | undefined = (a as Invoice).invoiceDate || (a as Transaction).operationDate;
    const bDate: string | null | undefined = (b as Invoice).invoiceDate || (b as Transaction).operationDate;
    if (aDate && !bDate) {
      return -1;
    }
    if (!aDate && bDate) {
      return 1;
    }
    if (aDate && bDate) {
      return new Date(bDate).getTime() - new Date(aDate).getTime();
    }
    return 0;
  });

  const loading = transactionsLoading || invoicesLoading;
  const error = transactionsError;

  return (
    <>
      <Typography style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 16 }}>
        {formatMessage({
          id: 'menu.transactions',
        })}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <TextDetail title={formatMessage({ id: 'communications.filter.type' })} />
        </Grid>
        <Grid item xs={4}>
          <TextDetail title={formatMessage({ id: 'transactions.list.date' })} />
        </Grid>
        <Grid item xs={4}>
          <TextDetail title={formatMessage({ id: 'transactions.list.amount' })} />
        </Grid>
      </Grid>
      {loading && <Typography>{formatMessage({ id: 'loading' })}</Typography>}
      {error && <Typography>{`${transactionsError}`}</Typography>}

      {!loading &&
        !error &&
        sortedInvoicesAndTransactions &&
        sortedInvoicesAndTransactions.slice(0, 6).map((invoiceOrTransaction) => {
          const isInvoice = invoiceOrTransaction.hasOwnProperty('invoiceDate');
          const amountColor = isInvoice
            ? getTransactionColor(-invoiceOrTransaction.amount, (invoiceOrTransaction as Invoice).creditNote)
            : getTransactionColor(invoiceOrTransaction.amount);
          const invoiceOrTransactionDate =
            (invoiceOrTransaction as Transaction).operationDate || (invoiceOrTransaction as Invoice).invoiceDate;

          const invoiceOrTransactionLabelId = getRowLabelId(invoiceOrTransaction);

          return (
            <LeaseBalancePopoverLine
              key={invoiceOrTransaction.id}
              title={formatMessage({ id: invoiceOrTransactionLabelId })}
              language={language}
              date={invoiceOrTransactionDate}
              amount={invoiceOrTransaction.amount}
              amountColor={amountColor}
            />
          );
        })}
    </>
  );
};

export default LeaseBalancePopoverColumn;
