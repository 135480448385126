import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Typography, Grid, Divider, Menu, MenuItem } from '@material-ui/core';
import Dialog from 'src/components/ui/Dialog';
import { UserRole, Colors } from '@rentguru/commons-utils';
import { useUserRoles } from 'src/hooks/UserRolesContext';
import { ReactComponent as Team } from 'src/icons/team.svg';
import { MenuItemText } from '@up2rent/ui';
import EditRole from './EditRole';
import DeleteRole from './DeleteRole';
import MoreHorizontalIconButton from 'src/components/ui/CustomIconButtons/MoreHorizontalIconButton';

interface RoleRowProps {
  userRole: UserRole;
  divider: boolean;
  onClick: (userRole: UserRole) => void;
}
const RoleRow: React.FC<RoleRowProps> = ({ userRole, divider = true, onClick }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const { getUsersWithRole } = useUserRoles();

  const users = getUsersWithRole(userRole.id);

  const canDelete = users.length === 0;

  const handleCloseDialogs = () => {
    setDeleteDialogOpen(false);
    setEditDialogOpen(false);
  };

  return (
    <>
      <Grid container style={{ marginTop: 15, marginBottom: 15, cursor: 'pointer' }} alignItems="center">
        <Grid item xs={10} onClick={() => onClick(userRole)}>
          <Typography
            data-test={`role-${userRole.name}`}
            variant="h6"
            style={{ fontSize: 14, fontWeight: 600, textAlign: 'left' }}
          >
            {userRole.name}
          </Typography>
        </Grid>
        <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
          <Team />
          <Typography variant="subtitle1" style={{ marginLeft: 8, fontSize: 14, color: Colors.SLATE_GREY }}>
            {users ? users.length : 0}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <MoreHorizontalIconButton
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setAnchorEl(event.currentTarget);
            }}
            size="small"
            iconStyle={{ verticalAlign: 'middle', fill: Colors.SLATE_GREY }}
          />
        </Grid>
        <Menu
          id="action-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          style={{ transform: 'translateY(40px)' }}
        >
          <MenuItem
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setEditDialogOpen(true);
              setAnchorEl(null);
            }}
          >
            <MenuItemText primary={formatMessage({ id: 'rename' })} />
          </MenuItem>
          {canDelete && (
            <MenuItem
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setDeleteDialogOpen(true);
                setAnchorEl(null);
              }}
            >
              <MenuItemText primary={formatMessage({ id: 'delete' })} deleteOption />
            </MenuItem>
          )}
        </Menu>
      </Grid>
      {divider && <Divider />}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDialogs}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DeleteRole afterSubmit={handleCloseDialogs} id={userRole.id} />
      </Dialog>
      <Dialog
        open={editDialogOpen}
        onClose={handleCloseDialogs}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <EditRole afterSubmit={handleCloseDialogs} id={userRole.id} />
      </Dialog>
    </>
  );
};

export default RoleRow;
