import React from 'react';
import { FieldFilterSelector } from 'src/components/ui/FieldFilterSelector';
import isNil from 'lodash/isNil';
import { useLocation } from 'react-router-dom';
import {
  setSeconds,
  setMinutes,
  setHours,
  areIntervalsOverlapping,
  maxTime,
  minTime,
  isWithinInterval,
} from 'date-fns';
import FieldFilterResetButton from '../../ui/FieldFilterResetButton';
import { useIntl } from 'react-intl';
import { useFilters, FilterEntity, StatementsFilters } from '../../../hooks/FiltersContext';
import {
  getContactNameFromObject,
  LeasePriceHistory,
  Statement,
  LeaseAmountUpdateStatus,
} from '@rentguru/commons-utils';
import { StatementDateFilter } from '../StatementDateFilter';
import { LeaseChargeAdjustementExtended, extendTenantStatement, TenantStatementExtended } from './TenantStatements';
import { useLeases } from 'src/hooks/LeasesContext';
import { isEmpty } from 'lodash';

export const filterTenantStatements = (statements: TenantStatementExtended[], statementsFilters: StatementsFilters) => {
  const { filters, statementsPeriod, statementsSentDate } = statementsFilters;
  return statements.filter((statement) => {
    const unitsIdsSelected = filters.find((f) => f.name === 'unit')?.items ?? [];
    const isUnitFitlerSelected = !isEmpty(unitsIdsSelected);

    if (isUnitFitlerSelected) {
      // If no units that is in the selected id => return false
      if (!statement.units.some((u) => unitsIdsSelected.includes(u.id))) return false;
    }

    const tenantsIdsSelected = filters.find((f) => f.name === 'tenant')?.items ?? [];
    const isTenantFilterSelected = !isEmpty(tenantsIdsSelected);
    if (isTenantFilterSelected) {
      // If no units that is in the selected id => return false
      if (!statement.tenants.some((u) => tenantsIdsSelected.includes(u.id))) return false;
    }

    if (statementsPeriod) {
      const fromDate = new Date(statement.periodFrom);
      const toDate = new Date(statement.periodTo);
      const { from, to } = statementsPeriod;
      const fromDateFilter = from ? new Date(from) : new Date(minTime);
      const toDateFilter = to ? new Date(to) : new Date(maxTime);
      if (!areIntervalsOverlapping({ start: fromDate, end: toDate }, { start: fromDateFilter, end: toDateFilter }))
        return false;
    }

    if (statementsSentDate) {
      if (!statement.sentDate) return false;
      const sentDate = new Date(statement.sentDate);
      const { from, to } = statementsSentDate;
      const fromDateFilter = from ? new Date(from) : new Date(minTime);
      const toDateFilter = to ? new Date(to) : new Date(maxTime);
      if (!isWithinInterval(sentDate, { start: fromDateFilter, end: toDateFilter })) return false;
    }
    return true;
  });
};

export const filterChargesAdjustments = (
  chargesAdjustments: LeaseChargeAdjustementExtended[],
  statementsFilters: StatementsFilters
) => {
  const filteredChargeAdjustment = chargesAdjustments.filter(
    (chargeAdjustment) =>
      chargeAdjustment.status === LeaseAmountUpdateStatus.DRAFT ||
      chargeAdjustment.status === LeaseAmountUpdateStatus.ERROR
  );
  const { filters, statementsPeriod } = statementsFilters;
  return filteredChargeAdjustment.filter((chargesAdjustment) => {
    const unitsIdsSelected = filters.find((f) => f.name === 'unit')?.items ?? [];
    const isUnitFitlerSelected = !isEmpty(unitsIdsSelected);

    if (isUnitFitlerSelected) {
      // If no units that is in the selected id => return false
      if (
        chargesAdjustment.amountDetails![0].unitId &&
        !unitsIdsSelected.includes(chargesAdjustment.amountDetails![0].unitId)
      )
        return false;
    }

    const tenantsIdsSelected = filters.find((f) => f.name === 'tenant')?.items ?? [];
    const isTenantFilterSelected = !isEmpty(tenantsIdsSelected);
    if (isTenantFilterSelected) {
      // If no units that is in the selected id => return false
      if (!chargesAdjustment.tenants.some((u) => tenantsIdsSelected.includes(u.id))) return false;
    }

    if (statementsPeriod) {
      const { from, to } = statementsPeriod;
      const fromDateFilter = from ? new Date(from) : new Date(minTime);
      const toDateFilter = to ? new Date(to) : new Date(maxTime);
      if (!isWithinInterval(new Date(chargesAdjustment.applicationDate), { start: fromDateFilter, end: toDateFilter }))
        return false;
    }

    return true;
  });
};

interface TenantStatementsFiltersComponentProps {
  tenantStatementsToCheck: Statement[];
  tenantStatementsArchived: Statement[];
  leaseChargeAdjustmentsTodos: LeasePriceHistory[];
}

const TenantStatementsFiltersComponent: React.FC<TenantStatementsFiltersComponentProps> = ({
  tenantStatementsToCheck,
  tenantStatementsArchived,
  leaseChargeAdjustmentsTodos,
}) => {
  const {
    tenantStatementsFilters: { filters, statementsPeriod, statementsSentDate },
    updateFilters,
    resetFilters,
  } = useFilters();
  const { getLease, leasesLoading } = useLeases();
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();
  let tenantStatements = tenantStatementsToCheck;
  if (pathname.includes('toValidate')) {
    tenantStatements = tenantStatementsToCheck;
  } else if (pathname.includes('archived')) {
    tenantStatements = tenantStatementsArchived;
  }

  if (leasesLoading) return null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFilterChange = (selectedItems: any[], filterName: string, fieldName: string, menuClosed: boolean) => {
    if (menuClosed) {
      return; // Don't set filter again
    }
    if (filterName !== 'statementPeriod' && filterName !== 'statementSendDate') {
      const filter = { name: filterName, field: fieldName, items: selectedItems.map((i) => i.value) };
      let newFilters = filters;

      const index = newFilters.findIndex((f) => f.name === filterName);
      if (index === -1) {
        newFilters = [...newFilters, filter];
      } else {
        newFilters = [...newFilters.filter((f) => f.name !== filterName), filter];
      }
      updateFilters<StatementsFilters>(FilterEntity.TENANT_STATEMENTS, {
        filters: newFilters,
        statementsPeriod,
        statementsSentDate,
      });
    }
  };

  const handleFilterReset = (filterName: string, _fieldName: string) => {
    if (filterName === 'statementPeriod') {
      updateFilters<StatementsFilters>(FilterEntity.TENANT_STATEMENTS, {
        filters,
        statementsPeriod: null,
        statementsSentDate,
      });
    } else if (filterName === 'statementSendDate') {
      updateFilters<StatementsFilters>(FilterEntity.TENANT_STATEMENTS, {
        filters,
        statementsPeriod,
        statementsSentDate: null,
      });
    }
  };

  const handleFilterDateFromTo = (
    from: Date | null,
    to: Date | null,
    filterName: 'statementPeriod' | 'statementSendDate'
  ) => {
    const oldFromTo = filterName === 'statementPeriod' ? statementsPeriod : statementsSentDate;
    let newFrom = null;
    let newTo = null;
    if (isNil(from)) {
      newFrom = isNil(oldFromTo) ? null : oldFromTo.from;
    } else {
      from = setSeconds(from, 0);
      from = setMinutes(from, 0);
      from = setHours(from, 0);
      newFrom = from.toISOString();
    }
    if (isNil(to)) {
      newTo = isNil(oldFromTo) ? null : oldFromTo.to;
    } else {
      to = setSeconds(to, 0);
      to = setMinutes(to, 0);
      to = setHours(to, 0);
      newTo = to.toISOString();
    }
    // Reset
    if (isNil(from) && isNil(to)) {
      updateFilters<StatementsFilters>(FilterEntity.TENANT_STATEMENTS, {
        filters,
        statementsPeriod: filterName === 'statementPeriod' ? null : statementsPeriod,
        statementsSentDate: filterName === 'statementSendDate' ? null : statementsSentDate,
      });
    } else {
      updateFilters<StatementsFilters>(FilterEntity.TENANT_STATEMENTS, {
        filters,
        statementsPeriod: filterName === 'statementPeriod' ? { from: newFrom!, to: newTo! } : statementsPeriod,
        statementsSentDate: filterName === 'statementSendDate' ? { from: newFrom!, to: newTo! } : statementsSentDate,
      });
    }
  };

  const tenantStatementsExtended = tenantStatements.map((ts) => extendTenantStatement(ts, getLease));
  let tenantNames: { label: string; value: string }[];
  let unitNames: { label: string; value: string }[];

  if (pathname.includes('toValidate') || pathname.includes('archived')) {
    tenantNames = tenantStatementsExtended.reduce((acc: { label: string; value: string }[], statement) => {
      const tenants = statement.tenants;
      for (const tenant of tenants) {
        const tenantAlreadyInList = acc.some((c) => c.value === tenant.id);
        if (!tenantAlreadyInList) {
          acc.push({
            value: tenant.id,
            label: getContactNameFromObject(tenant),
          });
        }
      }
      return acc;
    }, []);
    unitNames = tenantStatementsExtended.reduce((acc: { label: string; value: string }[], statement) => {
      const units = statement.units;
      for (const unit of units) {
        const recipientAlreadyInList = acc.some((c) => c.value === unit.id);
        if (!recipientAlreadyInList) {
          acc.push({
            value: unit.id,
            label: unit.name,
          });
        }
      }
      return acc;
    }, []);
  } else {
    tenantNames = leaseChargeAdjustmentsTodos.reduce((acc: { label: string; value: string }[], adjustment) => {
      const completeLease = adjustment.lease ? getLease(adjustment.lease.id) : undefined;
      if (completeLease) {
        for (const tenant of completeLease.tenants) {
          const tenantAlreadyInList = acc.some((c) => c.value === tenant.id);
          if (!tenantAlreadyInList) {
            acc.push({
              value: tenant.id,
              label: getContactNameFromObject(tenant),
            });
          }
        }
      }
      return acc;
    }, []);
    unitNames = leaseChargeAdjustmentsTodos.reduce((acc: { label: string; value: string }[], adjustment) => {
      const unitId = (adjustment.amountDetails ?? [])[0]?.unitId ?? '';
      if (unitId) {
        const recipientAlreadyInList = acc.some((c) => c.value === unitId);
        if (!recipientAlreadyInList) {
          acc.push({
            value: unitId,
            label: unitId,
          });
        }
      }
      return acc;
    }, []);
  }

  const initialUnitSelectedItems = filters.find((f) => f.name === 'unit');
  const initialTenantSelectedItems = filters.find((f) => f.name === 'tenant');

  return (
    <div
      style={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 15,
        marginRight: 24,
      }}
    >
      <FieldFilterSelector
        label={formatMessage({ id: 'financial.period' })}
        showResetButton
        showSearchField={false}
        filterName="statementPeriod"
        fieldName="period"
        textReset={formatMessage({ id: 'reinitFilter' })}
        textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
        textSearchField={formatMessage({ id: 'search' })}
        textSelectAll={formatMessage({ id: 'selectAll' })}
        onChange={handleFilterChange}
        onReset={handleFilterReset}
        minSelectorHeight={700}
        minSelectorWidth={1500}
        selected={!isNil(statementsPeriod)}
      >
        <StatementDateFilter
          dateFromTo={statementsPeriod}
          type="statementPeriod"
          handleFilterDateFromTo={handleFilterDateFromTo}
        />
      </FieldFilterSelector>

      {pathname.includes('archived') && (
        <FieldFilterSelector
          label={formatMessage({ id: 'accounting.statement.archivedSection.sentDate' })}
          showResetButton
          showSearchField={false}
          filterName="statementSendDate"
          fieldName="sendDate"
          textReset={formatMessage({ id: 'reinitFilter' })}
          textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
          textSearchField={formatMessage({ id: 'search' })}
          textSelectAll={formatMessage({ id: 'selectAll' })}
          onChange={handleFilterChange}
          onReset={handleFilterReset}
          minSelectorHeight={700}
          minSelectorWidth={1500}
          selected={!isNil(statementsSentDate)}
        >
          <StatementDateFilter
            dateFromTo={statementsSentDate}
            type="statementSendDate"
            handleFilterDateFromTo={handleFilterDateFromTo}
          />
        </FieldFilterSelector>
      )}
      <FieldFilterSelector
        label={formatMessage({ id: 'lease.addLease.unit' })}
        multipleSelection
        showResetButton
        filterName="unit"
        fieldName="units.unitId"
        textReset={formatMessage({ id: 'reinitFilter' })}
        textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
        textSearchField={formatMessage({ id: 'search' })}
        textSelectAll={formatMessage({ id: 'selectAll' })}
        onChange={handleFilterChange}
        initialSelectedItems={
          initialUnitSelectedItems ? initialUnitSelectedItems.items.map((u) => ({ value: u })) : null
        }
        scrollable
      >
        {unitNames}
      </FieldFilterSelector>
      <FieldFilterSelector
        label={formatMessage({ id: 'lease.addLease.tenant' })}
        multipleSelection
        showResetButton
        filterName="tenant"
        fieldName="contactId"
        textReset={formatMessage({ id: 'reinitFilter' })}
        textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
        textSearchField={formatMessage({ id: 'search' })}
        textSelectAll={formatMessage({ id: 'selectAll' })}
        onChange={handleFilterChange}
        initialSelectedItems={
          initialTenantSelectedItems ? initialTenantSelectedItems.items.map((t) => ({ value: t })) : null
        }
        scrollable
      >
        {tenantNames}
      </FieldFilterSelector>
      <FieldFilterResetButton resetFilterValues={() => resetFilters(FilterEntity.TENANT_STATEMENTS)} />
    </div>
  );
};

export default TenantStatementsFiltersComponent;
