import { Divider, Grid, Typography } from '@material-ui/core';
import { Colors, SendingSourceStatus } from '@rentguru/commons-utils';
import { toUpper } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ActionButton , ContentHeader } from '@up2rent/ui';
import { useSendingSources } from 'src/hooks/SendingSourceContext';
import ErrorDialog from './ErrorDomainDialog';
import PendingDomainDialog from './PendingDomainDialog';
import VerifiedDomainDialog from './VerifiedDomainDialog';

interface VerifyDomainDialogProps {
  id: string;
  closeAction: () => void;
}

const VerifyDomainDialog: React.FC<VerifyDomainDialogProps> = ({ id, closeAction }) => {
  const { formatMessage } = useIntl();
  const { getSendingSource, fetchAndSetSendingSources, verifyPendingSendingSource } = useSendingSources();
  const [loading, setLoading] = useState<boolean>(true);

  const verifyPendingAndFetchDomains = async () => {
    await verifyPendingSendingSource({ id });
    await fetchAndSetSendingSources();
    setLoading(false);
  };

  const handleVerifyAgainButton = async () => {
    setLoading(true);
    await verifyPendingAndFetchDomains();
  };

  useEffect(() => {
    verifyPendingAndFetchDomains();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const sendingSource = getSendingSource(id)!;
  if (loading) {
    return (
      <Grid>
        <ContentHeader title={formatMessage({ id: 'settings.sendingSources.domainVerification' })} />
        <Divider style={{ marginBottom: 20 }} />
        <Typography style={{ textAlign: 'center', width: 600 }}>{formatMessage({ id: 'loading' })}</Typography>
        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
        <Grid style={{ float: 'right' }}>
          <ActionButton
            onClick={closeAction}
            style={{
              background: 'none',
              color: Colors.DARK_SLATE_GREY,
              marginRight: 30,
              marginBottom: 20,
            }}
          >
            {toUpper(
              formatMessage({
                id: 'close',
              })
            )}
          </ActionButton>
        </Grid>
      </Grid>
    );
  }
  return (
    <>
      {sendingSource.status === SendingSourceStatus.VERIFIED && (
        <VerifiedDomainDialog id={id} closeAction={closeAction} />
      )}
      {sendingSource.status === SendingSourceStatus.ERROR && <ErrorDialog id={id} closeAction={closeAction} />}
      {sendingSource.status === SendingSourceStatus.PENDING && (
        <PendingDomainDialog
          sendingSource={sendingSource}
          closeAction={closeAction}
          handleVerifyAgainButton={handleVerifyAgainButton}
        />
      )}
    </>
  );
};

export default VerifyDomainDialog;
