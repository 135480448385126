import { Typography } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

interface CustomRouterLinkProps {
  to: string;
  text: string;
  textStyle?: CSSProperties;
}

const CustomRouterLink: React.FC<CustomRouterLinkProps> = ({ to, text, textStyle }) => (
  <Link to={to} style={{ color: Colors.DODGER_BLUE, textDecoration: 'underline', cursor: 'pointer' }}>
    <Typography style={textStyle}>{text}</Typography>
  </Link>
);

export default CustomRouterLink;
