import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import { useAgencyRates } from 'src/hooks/AgencyRatesContext';

interface DeleteAgencyRateProps {
  afterSubmit: () => void;
  id: string;
}
const DeleteAgencyRate: React.FC<DeleteAgencyRateProps> = ({ afterSubmit, id }) => {
  const { formatMessage } = useIntl();
  const { getAgencyRate, loading, error, deleteAgencyRate, deleteAgencyRateOwner } = useAgencyRates();

  const handleClose = () => {
    afterSubmit();
  };
  const agencyRate = getAgencyRate(id);
  if (loading || isNil(agencyRate)) {
    return (
      <>
        <DialogContent>
          <Typography>{formatMessage({ id: 'loading' })}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {formatMessage({ id: 'cancel' })}
          </Button>
        </DialogActions>
      </>
    );
  }
  if (error) {
    return <Typography>{error}</Typography>;
  }

  return (
    <>
      <DialogTitle id="alert-dialog-slide-title">
        {formatMessage({ id: 'agencyRates.deleteAgencyRate.menu' })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description" style={{ color: 'red' }}>
          {formatMessage({ id: 'agencyRates.deleteAgencyRate.title' }, { name: agencyRate.name })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {formatMessage({ id: 'cancel' })}
        </Button>
        <Button
          onClick={async (e) => {
            e.preventDefault();
            if (agencyRate.custom && agencyRate.owners) {
              await Promise.all(agencyRate.owners.map((agencyRateOwner) => deleteAgencyRateOwner(agencyRateOwner)));
            }
            await deleteAgencyRate(agencyRate);
            handleClose();
          }}
          color="primary"
        >
          {formatMessage({ id: 'delete' })}
        </Button>
      </DialogActions>
    </>
  );
};

export default DeleteAgencyRate;
