export const schema = {
    "models": {
        "AccountLabel": {
            "name": "AccountLabel",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "labels": {
                    "name": "labels",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomLabel"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "bankAccountId": {
                    "name": "bankAccountId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "bankAccount": {
                    "name": "bankAccount",
                    "isArray": false,
                    "type": {
                        "model": "BankAccount"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "bankAccountId"
                    }
                },
                "contactId": {
                    "name": "contactId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "contact": {
                    "name": "contact",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "contactId"
                    }
                },
                "class": {
                    "name": "class",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "topClass": {
                    "name": "topClass",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "customUsage": {
                    "name": "customUsage",
                    "isArray": false,
                    "type": {
                        "enum": "AccountLabelCustomUsage"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "disabled": {
                    "name": "disabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "AccountLabels",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Address": {
            "name": "Address",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "AddressStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "street": {
                    "name": "street",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "number": {
                    "name": "number",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "box": {
                    "name": "box",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "postalCode": {
                    "name": "postalCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "city": {
                    "name": "city",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "region": {
                    "name": "region",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "country": {
                    "name": "country",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "customValues": {
                    "name": "customValues",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomValue"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "latitude": {
                    "name": "latitude",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "longitude": {
                    "name": "longitude",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "readers": {
                    "name": "readers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Addresses",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "id",
                                "operations": [
                                    "update"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readers",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Advertisement": {
            "name": "Advertisement",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "AdvertisementUnitType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "subType": {
                    "name": "subType",
                    "isArray": false,
                    "type": {
                        "enum": "AdvertisementUnitSubType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "amenities": {
                    "name": "amenities",
                    "isArray": true,
                    "type": {
                        "enum": "AmenitiesType"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "amountOfFacades": {
                    "name": "amountOfFacades",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "availabilityDate": {
                    "name": "availabilityDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "certificateOfConformity": {
                    "name": "certificateOfConformity",
                    "isArray": false,
                    "type": {
                        "enum": "CertificateOfConformityType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "citationForPlanningOffence": {
                    "name": "citationForPlanningOffence",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "descriptions": {
                    "name": "descriptions",
                    "isArray": true,
                    "type": {
                        "nonModel": "AdvertisementDescription"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "delimitedZone": {
                    "name": "delimitedZone",
                    "isArray": false,
                    "type": {
                        "enum": "DelimitedZoneType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "floodProneArea": {
                    "name": "floodProneArea",
                    "isArray": false,
                    "type": {
                        "enum": "FloodProneAreaType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "furnished": {
                    "name": "furnished",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "firstOccupancy": {
                    "name": "firstOccupancy",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "gardenSurface": {
                    "name": "gardenSurface",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "heatingAmenities": {
                    "name": "heatingAmenities",
                    "isArray": true,
                    "type": {
                        "enum": "HeatingAmenities"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "heatingType": {
                    "name": "heatingType",
                    "isArray": false,
                    "type": {
                        "enum": "HeatingType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "landTerrain": {
                    "name": "landTerrain",
                    "isArray": true,
                    "type": {
                        "enum": "LandTerrainType"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "landArea": {
                    "name": "landArea",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "length": {
                    "name": "length",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "nearbyComodities": {
                    "name": "nearbyComodities",
                    "isArray": true,
                    "type": {
                        "nonModel": "NearbyComodity"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "publishedPlatforms": {
                    "name": "publishedPlatforms",
                    "isArray": true,
                    "type": {
                        "nonModel": "PublishedPlatform"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "planningPermission": {
                    "name": "planningPermission",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "publishCadastralIncome": {
                    "name": "publishCadastralIncome",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "resort": {
                    "name": "resort",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "rightOfPreemption": {
                    "name": "rightOfPreemption",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "subdivisionAuthorization": {
                    "name": "subdivisionAuthorization",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "wheelChairAccessible": {
                    "name": "wheelChairAccessible",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "width": {
                    "name": "width",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "terraceSurface": {
                    "name": "terraceSurface",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Advertisements",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "AgencyRate": {
            "name": "AgencyRate",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "amountDetails": {
                    "name": "amountDetails",
                    "isArray": true,
                    "type": {
                        "nonModel": "AgencyRateAmountDetail"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "amountIfVacantUnit": {
                    "name": "amountIfVacantUnit",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "amountMinimumFees": {
                    "name": "amountMinimumFees",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "custom": {
                    "name": "custom",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "feesAppliedTo": {
                    "name": "feesAppliedTo",
                    "isArray": false,
                    "type": {
                        "enum": "AgencyRateFeesType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "includedInAmount": {
                    "name": "includedInAmount",
                    "isArray": true,
                    "type": {
                        "enum": "AgencyRateIncludedAmount"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "owners": {
                    "name": "owners",
                    "isArray": true,
                    "type": {
                        "model": "AgencyRateOwner"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "agencyRate"
                    }
                },
                "repartitionType": {
                    "name": "repartitionType",
                    "isArray": false,
                    "type": {
                        "enum": "AgencyRateRepartitionType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "AgencyRateType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "unitType": {
                    "name": "unitType",
                    "isArray": true,
                    "type": {
                        "enum": "UnitType"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "AgencyRates",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "AgencyRateOwner": {
            "name": "AgencyRateOwner",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "contactId": {
                    "name": "contactId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "agencyRate": {
                    "name": "agencyRate",
                    "isArray": false,
                    "type": {
                        "model": "AgencyRate"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "agencyRateId"
                    }
                },
                "owner": {
                    "name": "owner",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "contactId"
                    }
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "AgencyRateOwners",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byAgency",
                        "fields": [
                            "agencyRateId",
                            "contactId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byContact",
                        "fields": [
                            "contactId",
                            "agencyRateId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "AgencyRateStatement": {
            "name": "AgencyRateStatement",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "agencyRate": {
                    "name": "agencyRate",
                    "isArray": false,
                    "type": {
                        "model": "AgencyRate"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "agencyRateId"
                    }
                },
                "agencyRateId": {
                    "name": "agencyRateId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "statement": {
                    "name": "statement",
                    "isArray": false,
                    "type": {
                        "model": "Statement"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "statementId"
                    }
                },
                "unitAmounts": {
                    "name": "unitAmounts",
                    "isArray": true,
                    "type": {
                        "nonModel": "AgencyRateStatementAmount"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "totalAmountRateable": {
                    "name": "totalAmountRateable",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "totalFixedAmount": {
                    "name": "totalFixedAmount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "AgencyRateStatements",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byAgencyRate",
                        "fields": [
                            "agencyRateId",
                            "statementId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStatement",
                        "fields": [
                            "statementId",
                            "agencyRateId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update",
                                    "delete"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "BankAccount": {
            "name": "BankAccount",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "contactId": {
                    "name": "contactId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "contact": {
                    "name": "contact",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "contactId"
                    }
                },
                "number": {
                    "name": "number",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "bicNumber": {
                    "name": "bicNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "bankName": {
                    "name": "bankName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "customValues": {
                    "name": "customValues",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomValue"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "disabled": {
                    "name": "disabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "integrations": {
                    "name": "integrations",
                    "isArray": true,
                    "type": {
                        "enum": "IntegrationName"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readers": {
                    "name": "readers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "BankAccounts",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readers",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Building": {
            "name": "Building",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "addressId": {
                    "name": "addressId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "address": {
                    "name": "address",
                    "isArray": false,
                    "type": {
                        "model": "Address"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "addressId"
                    }
                },
                "acquisitionValue": {
                    "name": "acquisitionValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "acquisitionAdditionalCostsValue": {
                    "name": "acquisitionAdditionalCostsValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "acquisitionAdvertisedValue": {
                    "name": "acquisitionAdvertisedValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "amountOfFacades": {
                    "name": "amountOfFacades",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "amountOfFloors": {
                    "name": "amountOfFloors",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "amountOfHousings": {
                    "name": "amountOfHousings",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "buildingState": {
                    "name": "buildingState",
                    "isArray": false,
                    "type": {
                        "enum": "BuildingState"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "cadastralIncome": {
                    "name": "cadastralIncome",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "constructionYear": {
                    "name": "constructionYear",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "customValues": {
                    "name": "customValues",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomValue"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "janitor": {
                    "name": "janitor",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "jointOwnershipId": {
                    "name": "jointOwnershipId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "owners": {
                    "name": "owners",
                    "isArray": true,
                    "type": {
                        "model": "BuildingOwner"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "building"
                    }
                },
                "propertyTax": {
                    "name": "propertyTax",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "residenceName": {
                    "name": "residenceName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "units": {
                    "name": "units",
                    "isArray": true,
                    "type": {
                        "model": "Unit"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "building"
                    }
                },
                "events": {
                    "name": "events",
                    "isArray": true,
                    "type": {
                        "model": "BuildingEvent"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "buildingId"
                    }
                },
                "readers": {
                    "name": "readers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "teams": {
                    "name": "teams",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Buildings",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readers",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "teams",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "BuildingEvent": {
            "name": "BuildingEvent",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "buildingId": {
                    "name": "buildingId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "startDate": {
                    "name": "startDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "endDate": {
                    "name": "endDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "totalCost": {
                    "name": "totalCost",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "note": {
                    "name": "note",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "writers": {
                    "name": "writers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "BuildingEvents",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byBuilding",
                        "fields": [
                            "buildingId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "CreateBuildingEvent"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "writers",
                                "operations": [
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "BuildingOwner": {
            "name": "BuildingOwner",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "building": {
                    "name": "building",
                    "isArray": false,
                    "type": {
                        "model": "Building"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "buildingId"
                    }
                },
                "disabled": {
                    "name": "disabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "owner": {
                    "name": "owner",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "contactId"
                    }
                },
                "periodFrom": {
                    "name": "periodFrom",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "periodTo": {
                    "name": "periodTo",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "stake": {
                    "name": "stake",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "readers": {
                    "name": "readers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "BuildingOwners",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byBuilding",
                        "fields": [
                            "buildingId",
                            "contactId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byContact",
                        "fields": [
                            "contactId",
                            "buildingId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readers",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Charge": {
            "name": "Charge",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "ChargeStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "invoiceDate": {
                    "name": "invoiceDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "paymentDueDate": {
                    "name": "paymentDueDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "archivedDate": {
                    "name": "archivedDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "periodFrom": {
                    "name": "periodFrom",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "periodTo": {
                    "name": "periodTo",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "totalAmount": {
                    "name": "totalAmount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "source": {
                    "name": "source",
                    "isArray": false,
                    "type": {
                        "enum": "ChargeSource"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "draftFromOcr": {
                    "name": "draftFromOcr",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "accountingDocumentId": {
                    "name": "accountingDocumentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "paid": {
                    "name": "paid",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "remittanceInformation": {
                    "name": "remittanceInformation",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "note": {
                    "name": "note",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "creatorEmail": {
                    "name": "creatorEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdById": {
                    "name": "createdById",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "createdById"
                    }
                },
                "contractorId": {
                    "name": "contractorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "contractor": {
                    "name": "contractor",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "contractorId"
                    }
                },
                "proposedContractors": {
                    "name": "proposedContractors",
                    "isArray": true,
                    "type": {
                        "nonModel": "ProposedValue"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "recipientId": {
                    "name": "recipientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "recipient": {
                    "name": "recipient",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "recipientId"
                    }
                },
                "proposedRecipients": {
                    "name": "proposedRecipients",
                    "isArray": true,
                    "type": {
                        "nonModel": "ProposedValue"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "documentId": {
                    "name": "documentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "document": {
                    "name": "document",
                    "isArray": false,
                    "type": {
                        "model": "File"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "documentId"
                    }
                },
                "postings": {
                    "name": "postings",
                    "isArray": true,
                    "type": {
                        "model": "Posting"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "charge"
                    }
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Charges",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ChargeStatement": {
            "name": "ChargeStatement",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "charge": {
                    "name": "charge",
                    "isArray": false,
                    "type": {
                        "model": "Charge"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "chargeId"
                    }
                },
                "chargeId": {
                    "name": "chargeId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "statement": {
                    "name": "statement",
                    "isArray": false,
                    "type": {
                        "model": "Statement"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "statementId"
                    }
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "ChargeStatements",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCharge",
                        "fields": [
                            "chargeId",
                            "statementId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStatement",
                        "fields": [
                            "statementId",
                            "chargeId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update",
                                    "delete"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Client": {
            "name": "Client",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "accountType": {
                    "name": "accountType",
                    "isArray": false,
                    "type": {
                        "enum": "AccountType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "contactId": {
                    "name": "contactId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "contact": {
                    "name": "contact",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "contactId"
                    }
                },
                "generalConditionsAcceptDate": {
                    "name": "generalConditionsAcceptDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "lastGeneralConditionsAcceptedVersion": {
                    "name": "lastGeneralConditionsAcceptedVersion",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "GDPRAcceptDate": {
                    "name": "GDPRAcceptDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "lastGDPRAcceptedVersion": {
                    "name": "lastGDPRAcceptedVersion",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "chargeEmail": {
                    "name": "chargeEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "issueEmail": {
                    "name": "issueEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "chargeEmailFromSendingSource": {
                    "name": "chargeEmailFromSendingSource",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "issueEmailFromSendingSource": {
                    "name": "issueEmailFromSendingSource",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Clients",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": {
                            "level": "off"
                        }
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "custom",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "id",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Communication": {
            "name": "Communication",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "addressId": {
                    "name": "addressId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "address": {
                    "name": "address",
                    "isArray": false,
                    "type": {
                        "model": "Address"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "addressId"
                    }
                },
                "blockedEmails": {
                    "name": "blockedEmails",
                    "isArray": true,
                    "type": {
                        "nonModel": "BlockedEmail"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "body": {
                    "name": "body",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "buildingId": {
                    "name": "buildingId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "channel": {
                    "name": "channel",
                    "isArray": false,
                    "type": {
                        "enum": "CommunicationChannel"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "communicationTemplateId": {
                    "name": "communicationTemplateId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "contact": {
                    "name": "contact",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "contactId"
                    }
                },
                "contactId": {
                    "name": "contactId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "expirationDate": {
                    "name": "expirationDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "files": {
                    "name": "files",
                    "isArray": true,
                    "type": {
                        "model": "File"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "foreignKey"
                    }
                },
                "invoice": {
                    "name": "invoice",
                    "isArray": false,
                    "type": {
                        "model": "Invoice"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "communication",
                        "targetName": "invoiceId"
                    }
                },
                "invoiceId": {
                    "name": "invoiceId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "lease": {
                    "name": "lease",
                    "isArray": false,
                    "type": {
                        "model": "Lease"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "leaseId"
                    }
                },
                "leaseId": {
                    "name": "leaseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "leasePriceHistoryId": {
                    "name": "leasePriceHistoryId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "leasePriceHistory": {
                    "name": "leasePriceHistory",
                    "isArray": false,
                    "type": {
                        "model": "LeasePriceHistory"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "communication",
                        "targetName": "leasePriceHistoryId"
                    }
                },
                "linkedCommunicationsIds": {
                    "name": "linkedCommunicationsIds",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "recipient": {
                    "name": "recipient",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "recipientInBcc": {
                    "name": "recipientInBcc",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "recipientInCc": {
                    "name": "recipientInCc",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "sender": {
                    "name": "sender",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "sentAt": {
                    "name": "sentAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "externalId": {
                    "name": "externalId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "signatureDocumentId": {
                    "name": "signatureDocumentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "signatureDocument": {
                    "name": "signatureDocument",
                    "isArray": false,
                    "type": {
                        "model": "SignatureDocument"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "signatureDocumentId"
                    }
                },
                "snoozedDate": {
                    "name": "snoozedDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "statement": {
                    "name": "statement",
                    "isArray": false,
                    "type": {
                        "model": "Statement"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "statementId"
                    }
                },
                "statementId": {
                    "name": "statementId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "CommunicationStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "subject": {
                    "name": "subject",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "toSend": {
                    "name": "toSend",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "CommunicationType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "_ttl": {
                    "name": "_ttl",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "unit": {
                    "name": "unit",
                    "isArray": false,
                    "type": {
                        "model": "Unit"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "unitId"
                    }
                },
                "unitId": {
                    "name": "unitId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "readers": {
                    "name": "readers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Communications",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byContact",
                        "fields": [
                            "contactId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byInvoice",
                        "fields": [
                            "invoiceId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byLease",
                        "fields": [
                            "leaseId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byExternalId",
                        "fields": [
                            "externalId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readers",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update",
                                    "delete"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CommunicationSettingsProfile": {
            "name": "CommunicationSettingsProfile",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "communicationsSettings": {
                    "name": "communicationsSettings",
                    "isArray": true,
                    "type": {
                        "nonModel": "CommunicationSettings"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "defaultName": {
                    "name": "defaultName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "isDefaultSetting": {
                    "name": "isDefaultSetting",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "referent": {
                    "name": "referent",
                    "isArray": false,
                    "type": {
                        "enum": "CommunicationSettingsProfileReferent"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "replacementId": {
                    "name": "replacementId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "readers": {
                    "name": "readers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "CommunicationSettingsProfiles",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readers",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update",
                                    "delete"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Contact": {
            "name": "Contact",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "firstName": {
                    "name": "firstName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "lastName": {
                    "name": "lastName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "companyName": {
                    "name": "companyName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "commercialName": {
                    "name": "commercialName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "communicationSettingsProfileId": {
                    "name": "communicationSettingsProfileId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "customValues": {
                    "name": "customValues",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomValue"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "businessNumber": {
                    "name": "businessNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "types": {
                    "name": "types",
                    "isArray": true,
                    "type": {
                        "enum": "ContactType"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "vat": {
                    "name": "vat",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "birthDate": {
                    "name": "birthDate",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "birthPlace": {
                    "name": "birthPlace",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "nationalRegister": {
                    "name": "nationalRegister",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "civility": {
                    "name": "civility",
                    "isArray": false,
                    "type": {
                        "enum": "CivilityType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "addressId": {
                    "name": "addressId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "address": {
                    "name": "address",
                    "isArray": false,
                    "type": {
                        "model": "Address"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "addressId"
                    }
                },
                "phoneNumbers": {
                    "name": "phoneNumbers",
                    "isArray": true,
                    "type": {
                        "nonModel": "PhoneNumber"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "language": {
                    "name": "language",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "jointOwners": {
                    "name": "jointOwners",
                    "isArray": true,
                    "type": {
                        "nonModel": "JointOwner"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "agencyRateDisabled": {
                    "name": "agencyRateDisabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "ContactStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "leases": {
                    "name": "leases",
                    "isArray": true,
                    "type": {
                        "model": "LeaseContact"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "contact"
                    }
                },
                "buildings": {
                    "name": "buildings",
                    "isArray": true,
                    "type": {
                        "model": "BuildingOwner"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "owner"
                    }
                },
                "units": {
                    "name": "units",
                    "isArray": true,
                    "type": {
                        "model": "UnitOwner"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "owner"
                    }
                },
                "sendInvitation": {
                    "name": "sendInvitation",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "readers": {
                    "name": "readers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Contacts",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "custom",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "id",
                                "operations": [
                                    "update"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readers",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CustomField": {
            "name": "CustomField",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "entity": {
                    "name": "entity",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "fieldName": {
                    "name": "fieldName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "fieldType": {
                    "name": "fieldType",
                    "isArray": false,
                    "type": {
                        "enum": "CustomFieldType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "CustomFields",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byEntity",
                        "fields": [
                            "entity",
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CustomSubscription": {
            "name": "CustomSubscription",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": {
                        "enum": "CustomSubscriptionType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "userId": {
                    "name": "userId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "_ttl": {
                    "name": "_ttl",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "CustomSubscriptions",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "userId",
                                "allow": "owner",
                                "identityClaim": "custom:userId",
                                "operations": [
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "operations": [
                                    "read",
                                    "update",
                                    "delete",
                                    "create"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "DocumentCategory": {
            "name": "DocumentCategory",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "entity": {
                    "name": "entity",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "fileCategory": {
                    "name": "fileCategory",
                    "isArray": false,
                    "type": {
                        "enum": "FileCategory"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "labels": {
                    "name": "labels",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomLabel"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "parentId": {
                    "name": "parentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "readers": {
                    "name": "readers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "DocumentCategories",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byEntity",
                        "fields": [
                            "entity",
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byParent",
                        "fields": [
                            "parentId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readers",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "File": {
            "name": "File",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "bucket": {
                    "name": "bucket",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "category": {
                    "name": "category",
                    "isArray": false,
                    "type": {
                        "model": "DocumentCategory"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "categoryId"
                    }
                },
                "categoryId": {
                    "name": "categoryId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "foreignKey": {
                    "name": "foreignKey",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "foreignTableName": {
                    "name": "foreignTableName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mimeType": {
                    "name": "mimeType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "region": {
                    "name": "region",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "signaturePositions": {
                    "name": "signaturePositions",
                    "isArray": true,
                    "type": {
                        "nonModel": "SignaturePosition"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "size": {
                    "name": "size",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "readers": {
                    "name": "readers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Files",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCategory",
                        "fields": [
                            "categoryId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byForeignKey",
                        "fields": [
                            "foreignKey",
                            "foreignTableName"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId",
                            "foreignTableName"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "foreignTableName",
                                "operations": [
                                    "create"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readers",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Integration": {
            "name": "Integration",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "configurations": {
                    "name": "configurations",
                    "isArray": true,
                    "type": {
                        "nonModel": "IntegrationConfiguration"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "error": {
                    "name": "error",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "lastRun": {
                    "name": "lastRun",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": {
                        "enum": "IntegrationName"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "IntegrationStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Integrations",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byName",
                        "fields": [
                            "name"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "InventoryCustomItem": {
            "name": "InventoryCustomItem",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "InventoryItemType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "InventoryCustomItems",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Invoice": {
            "name": "Invoice",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "amount": {
                    "name": "amount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "bankAccountId": {
                    "name": "bankAccountId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "bankAccount": {
                    "name": "bankAccount",
                    "isArray": false,
                    "type": {
                        "model": "BankAccount"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "bankAccountId"
                    }
                },
                "communication": {
                    "name": "communication",
                    "isArray": false,
                    "type": {
                        "model": "Communication"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "communicationId"
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "creditNote": {
                    "name": "creditNote",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "creditNoteLabel": {
                    "name": "creditNoteLabel",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "dueDate": {
                    "name": "dueDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "invoiceDate": {
                    "name": "invoiceDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "invoiceNumber": {
                    "name": "invoiceNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "leaseId": {
                    "name": "leaseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "lease": {
                    "name": "lease",
                    "isArray": false,
                    "type": {
                        "model": "Lease"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "leaseId"
                    }
                },
                "paid": {
                    "name": "paid",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "receiptNumber": {
                    "name": "receiptNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "reminderDates": {
                    "name": "reminderDates",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "remittanceInformation": {
                    "name": "remittanceInformation",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "statementId": {
                    "name": "statementId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "statement": {
                    "name": "statement",
                    "isArray": false,
                    "type": {
                        "model": "Statement"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "statementId"
                    }
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "InvoiceType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "unitId": {
                    "name": "unitId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "unit": {
                    "name": "unit",
                    "isArray": false,
                    "type": {
                        "model": "Unit"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "unitId"
                    }
                },
                "readers": {
                    "name": "readers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Invoices",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byBankAccount",
                        "fields": [
                            "bankAccountId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byLease",
                        "fields": [
                            "leaseId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStatement",
                        "fields": [
                            "statementId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byType",
                        "fields": [
                            "type",
                            "invoiceDate"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readers",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "InvoiceSetting": {
            "name": "InvoiceSetting",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "creditNoteFormat": {
                    "name": "creditNoteFormat",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "creditNoteLastUpdate": {
                    "name": "creditNoteLastUpdate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "creditNoteIncrementNumber": {
                    "name": "creditNoteIncrementNumber",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "invoiceFormat": {
                    "name": "invoiceFormat",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "invoiceLastUpdate": {
                    "name": "invoiceLastUpdate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "invoiceIncrementNumber": {
                    "name": "invoiceIncrementNumber",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "paymentRequestFormat": {
                    "name": "paymentRequestFormat",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "paymentRequestLastUpdate": {
                    "name": "paymentRequestLastUpdate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "paymentRequestIncrementNumber": {
                    "name": "paymentRequestIncrementNumber",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "receiptFormat": {
                    "name": "receiptFormat",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "receiptLastUpdate": {
                    "name": "receiptLastUpdate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "receiptIncrementNumber": {
                    "name": "receiptIncrementNumber",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "ownerId": {
                    "name": "ownerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "owner": {
                    "name": "owner",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "ownerId"
                    }
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "InvoiceSettings",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOwnerId",
                        "fields": [
                            "ownerId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Lease": {
            "name": "Lease",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "balance": {
                    "name": "balance",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "canBeExtended": {
                    "name": "canBeExtended",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "communicationSettingsProfileId": {
                    "name": "communicationSettingsProfileId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "endDate": {
                    "name": "endDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "includeRealEstateInLeaseContract": {
                    "name": "includeRealEstateInLeaseContract",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "invoiceSenderId": {
                    "name": "invoiceSenderId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "invoiceSender": {
                    "name": "invoiceSender",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "invoiceSenderId"
                    }
                },
                "inventoryOfFixturesExpert": {
                    "name": "inventoryOfFixturesExpert",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "inventoryOfFixturesMethod": {
                    "name": "inventoryOfFixturesMethod",
                    "isArray": false,
                    "type": {
                        "enum": "InventoryOfFixturesMethod"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "inventoryOfFixturesType": {
                    "name": "inventoryOfFixturesType",
                    "isArray": false,
                    "type": {
                        "enum": "InventoryOfFixturesType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ipiNumber": {
                    "name": "ipiNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "language": {
                    "name": "language",
                    "isArray": false,
                    "type": {
                        "enum": "Language"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "lastIndexationProcessDate": {
                    "name": "lastIndexationProcessDate",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "lastInvoiceDate": {
                    "name": "lastInvoiceDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "legalCompetentAuthority": {
                    "name": "legalCompetentAuthority",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "paymentFrequency": {
                    "name": "paymentFrequency",
                    "isArray": false,
                    "type": {
                        "enum": "LeasePaymentFrequency"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "paymentInvoicePeriod": {
                    "name": "paymentInvoicePeriod",
                    "isArray": false,
                    "type": {
                        "enum": "LeasePaymentInvoicePeriod"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "paymentInvoicePeriodCustomDay": {
                    "name": "paymentInvoicePeriodCustomDay",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "realEstateAgency": {
                    "name": "realEstateAgency",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "realEstateTax": {
                    "name": "realEstateTax",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "remittanceInformation": {
                    "name": "remittanceInformation",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "rentalGuarantyAmount": {
                    "name": "rentalGuarantyAmount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "rentalGuarantyFinancialInstitution": {
                    "name": "rentalGuarantyFinancialInstitution",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "rentalGuarantyType": {
                    "name": "rentalGuarantyType",
                    "isArray": false,
                    "type": {
                        "enum": "LeaseRentalGuarantyType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "signatureDate": {
                    "name": "signatureDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "startDate": {
                    "name": "startDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "LeaseStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "technicCommunicationSettingsProfileId": {
                    "name": "technicCommunicationSettingsProfileId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "totalInitialMonthlyCharges": {
                    "name": "totalInitialMonthlyCharges",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "totalInitialRentalPrice": {
                    "name": "totalInitialRentalPrice",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "totalMonthlyCharges": {
                    "name": "totalMonthlyCharges",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "totalRentalPrice": {
                    "name": "totalRentalPrice",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "LeaseType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "vatRateCharge": {
                    "name": "vatRateCharge",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "vatRateRent": {
                    "name": "vatRateRent",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "additionalClauses": {
                    "name": "additionalClauses",
                    "isArray": true,
                    "type": {
                        "model": "LeaseAdditionalClause"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "lease"
                    }
                },
                "bankAccountId": {
                    "name": "bankAccountId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "bankAccount": {
                    "name": "bankAccount",
                    "isArray": false,
                    "type": {
                        "model": "BankAccount"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "bankAccountId"
                    }
                },
                "contacts": {
                    "name": "contacts",
                    "isArray": true,
                    "type": {
                        "model": "LeaseContact"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "lease"
                    }
                },
                "files": {
                    "name": "files",
                    "isArray": true,
                    "type": {
                        "model": "File"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "foreignKey"
                    }
                },
                "leaseActionHistories": {
                    "name": "leaseActionHistories",
                    "isArray": true,
                    "type": {
                        "model": "LeaseActionHistory"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "lease"
                    }
                },
                "leaseInventories": {
                    "name": "leaseInventories",
                    "isArray": true,
                    "type": {
                        "model": "LeaseInventory"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "leaseId"
                    }
                },
                "leasePriceHistories": {
                    "name": "leasePriceHistories",
                    "isArray": true,
                    "type": {
                        "model": "LeasePriceHistory"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "lease"
                    }
                },
                "signatureDocuments": {
                    "name": "signatureDocuments",
                    "isArray": true,
                    "type": {
                        "model": "SignatureDocument"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "foreignKey"
                    }
                },
                "units": {
                    "name": "units",
                    "isArray": true,
                    "type": {
                        "model": "UnitLease"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "lease"
                    }
                },
                "variousOperations": {
                    "name": "variousOperations",
                    "isArray": true,
                    "type": {
                        "model": "LeaseVariousOperation"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "lease"
                    }
                },
                "readers": {
                    "name": "readers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Leases",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readers",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "LeaseActionHistory": {
            "name": "LeaseActionHistory",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "action": {
                    "name": "action",
                    "isArray": false,
                    "type": {
                        "enum": "LeaseAction"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "comment": {
                    "name": "comment",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "contactId": {
                    "name": "contactId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "contactId"
                    }
                },
                "historyDetails": {
                    "name": "historyDetails",
                    "isArray": true,
                    "type": {
                        "nonModel": "LeaseActionHistoryDetail"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "lease": {
                    "name": "lease",
                    "isArray": false,
                    "type": {
                        "model": "Lease"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "leaseId"
                    }
                },
                "signatureDocumentId": {
                    "name": "signatureDocumentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "signatureDocument": {
                    "name": "signatureDocument",
                    "isArray": false,
                    "type": {
                        "model": "SignatureDocument"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "signatureDocumentId"
                    }
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "LeaseActionHistoryStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "communicationChannel": {
                    "name": "communicationChannel",
                    "isArray": false,
                    "type": {
                        "enum": "CommunicationChannel"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "communicationSendDate": {
                    "name": "communicationSendDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "communicationByPlatform": {
                    "name": "communicationByPlatform",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "endReason": {
                    "name": "endReason",
                    "isArray": false,
                    "type": {
                        "enum": "LeaseActionEndReason"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "endReasonOwnUseRelation": {
                    "name": "endReasonOwnUseRelation",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "endReasonOwnUseName": {
                    "name": "endReasonOwnUseName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "initiator": {
                    "name": "initiator",
                    "isArray": false,
                    "type": {
                        "enum": "LeaseActionEndInitiator"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "totalAmount": {
                    "name": "totalAmount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "totalAmountLabel": {
                    "name": "totalAmountLabel",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "automatic": {
                    "name": "automatic",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "hasLeaseTypeChanged": {
                    "name": "hasLeaseTypeChanged",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "readers": {
                    "name": "readers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "LeaseActionHistories",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byAction",
                        "fields": [
                            "action",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byLease",
                        "fields": [
                            "leaseId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readers",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "LeaseAdditionalClause": {
            "name": "LeaseAdditionalClause",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "lease": {
                    "name": "lease",
                    "isArray": false,
                    "type": {
                        "model": "Lease"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "leaseId"
                    }
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "body": {
                    "name": "body",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "LeaseAdditionalClauses",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byLease",
                        "fields": [
                            "leaseId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "LeaseContact": {
            "name": "LeaseContact",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "lease": {
                    "name": "lease",
                    "isArray": false,
                    "type": {
                        "model": "Lease"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "leaseId"
                    }
                },
                "contact": {
                    "name": "contact",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "contactId"
                    }
                },
                "contactRole": {
                    "name": "contactRole",
                    "isArray": false,
                    "type": {
                        "enum": "ContactType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "readers": {
                    "name": "readers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "LeaseContacts",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byLease",
                        "fields": [
                            "leaseId",
                            "contactId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byContact",
                        "fields": [
                            "contactId",
                            "leaseId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readers",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "LeasePriceHistory": {
            "name": "LeasePriceHistory",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "previousTotalRentalPrice": {
                    "name": "previousTotalRentalPrice",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "previousTotalMonthlyCharge": {
                    "name": "previousTotalMonthlyCharge",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "totalRentalPrice": {
                    "name": "totalRentalPrice",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "optimalTotalRentalPrice": {
                    "name": "optimalTotalRentalPrice",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "totalMonthlyCharges": {
                    "name": "totalMonthlyCharges",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "optimalTotalMonthlyCharges": {
                    "name": "optimalTotalMonthlyCharges",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "applicationDate": {
                    "name": "applicationDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "LeaseAmountUpdateStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "comment": {
                    "name": "comment",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "amountDetails": {
                    "name": "amountDetails",
                    "isArray": true,
                    "type": {
                        "nonModel": "LeasePriceHistoryDetail"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "LeasePriceHistoryType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "communication": {
                    "name": "communication",
                    "isArray": false,
                    "type": {
                        "model": "Communication"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "communicationId"
                    }
                },
                "lease": {
                    "name": "lease",
                    "isArray": false,
                    "type": {
                        "model": "Lease"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "leaseId"
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "readers": {
                    "name": "readers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "LeasePriceHistories",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byLease",
                        "fields": [
                            "leaseId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readers",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "LeaseInventory": {
            "name": "LeaseInventory",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "leaseId": {
                    "name": "leaseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "inventoryType": {
                    "name": "inventoryType",
                    "isArray": false,
                    "type": {
                        "enum": "LeaseInventoryType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "LeaseStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "signatureDate": {
                    "name": "signatureDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "note": {
                    "name": "note",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "encodings": {
                    "name": "encodings",
                    "isArray": true,
                    "type": {
                        "model": "LeaseInventoryEncoding"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "leaseInventoryId"
                    }
                },
                "fileId": {
                    "name": "fileId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "file": {
                    "name": "file",
                    "isArray": false,
                    "type": {
                        "model": "File"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "fileId"
                    }
                },
                "signatureMethods": {
                    "name": "signatureMethods",
                    "isArray": true,
                    "type": {
                        "enum": "SignatureType"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "additionalMessage": {
                    "name": "additionalMessage",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "connectionPlugs": {
                    "name": "connectionPlugs",
                    "isArray": true,
                    "type": {
                        "enum": "ConnectionPlugsType"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "keys": {
                    "name": "keys",
                    "isArray": true,
                    "type": {
                        "nonModel": "LeaseInventoryUnitKey"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readers": {
                    "name": "readers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "LeaseInventories",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byLease",
                        "fields": [
                            "leaseId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readers",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "LeaseInventoryCheckedItem": {
            "name": "LeaseInventoryCheckedItem",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "custom": {
                    "name": "custom",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "leaseInventoryEncodingId": {
                    "name": "leaseInventoryEncodingId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "totalDefects": {
                    "name": "totalDefects",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "customItemId": {
                    "name": "customItemId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "customItem": {
                    "name": "customItem",
                    "isArray": false,
                    "type": {
                        "model": "InventoryCustomItem"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "customItemId"
                    }
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "InventoryItemType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "LeaseInventoryCheckedItems",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byLeaseInventoryEncoding",
                        "fields": [
                            "leaseInventoryEncodingId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "LeaseInventoryEncoding": {
            "name": "LeaseInventoryEncoding",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "leaseInventoryId": {
                    "name": "leaseInventoryId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "structureId": {
                    "name": "structureId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "state": {
                    "name": "state",
                    "isArray": false,
                    "type": {
                        "enum": "LeaseInventoryStructureStateType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "note": {
                    "name": "note",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "checkedItems": {
                    "name": "checkedItems",
                    "isArray": true,
                    "type": {
                        "model": "LeaseInventoryCheckedItem"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "leaseInventoryEncodingId"
                    }
                },
                "furnitureQuantity": {
                    "name": "furnitureQuantity",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "furniturePrice": {
                    "name": "furniturePrice",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "furnitureExitIssues": {
                    "name": "furnitureExitIssues",
                    "isArray": true,
                    "type": {
                        "nonModel": "LeaseInventoryFurnitureExitIssue"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "medias": {
                    "name": "medias",
                    "isArray": true,
                    "type": {
                        "model": "File"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "foreignKey"
                    }
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "LeaseInventoryEncodings",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byLeaseInventory",
                        "fields": [
                            "leaseInventoryId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStructure",
                        "fields": [
                            "structureId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "LeaseVariousOperation": {
            "name": "LeaseVariousOperation",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "amount": {
                    "name": "amount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "contactId": {
                    "name": "contactId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "contactId"
                    }
                },
                "endDate": {
                    "name": "endDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "label": {
                    "name": "label",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "lastInvoiceDate": {
                    "name": "lastInvoiceDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "lease": {
                    "name": "lease",
                    "isArray": false,
                    "type": {
                        "model": "Lease"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "leaseId"
                    }
                },
                "leaseVariousOperationHistories": {
                    "name": "leaseVariousOperationHistories",
                    "isArray": true,
                    "type": {
                        "nonModel": "LeaseVariousOperationHistory"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "operationType": {
                    "name": "operationType",
                    "isArray": false,
                    "type": {
                        "enum": "LeaseVariousOperationType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "paymentFrequency": {
                    "name": "paymentFrequency",
                    "isArray": false,
                    "type": {
                        "enum": "LeasePaymentFrequency"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "paymentInvoicePeriod": {
                    "name": "paymentInvoicePeriod",
                    "isArray": false,
                    "type": {
                        "enum": "LeasePaymentInvoicePeriod"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "paymentInvoicePeriodCustomDay": {
                    "name": "paymentInvoicePeriodCustomDay",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "reason": {
                    "name": "reason",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "separateInvoice": {
                    "name": "separateInvoice",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "startDate": {
                    "name": "startDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "VariousOperationType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "unit": {
                    "name": "unit",
                    "isArray": false,
                    "type": {
                        "model": "Unit"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "unitId"
                    }
                },
                "unitId": {
                    "name": "unitId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "vatRate": {
                    "name": "vatRate",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "readers": {
                    "name": "readers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "LeaseVariousOperations",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byLease",
                        "fields": [
                            "leaseId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readers",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Mortgage": {
            "name": "Mortgage",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "lender": {
                    "name": "lender",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "durationInYears": {
                    "name": "durationInYears",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "rateType": {
                    "name": "rateType",
                    "isArray": false,
                    "type": {
                        "enum": "MortgageRateType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "interestRate": {
                    "name": "interestRate",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "aprRate": {
                    "name": "aprRate",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "startDate": {
                    "name": "startDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "amount": {
                    "name": "amount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "monthlyPayment": {
                    "name": "monthlyPayment",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "unitId": {
                    "name": "unitId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "unit": {
                    "name": "unit",
                    "isArray": false,
                    "type": {
                        "model": "Unit"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "unitId"
                    }
                },
                "buildingId": {
                    "name": "buildingId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "building": {
                    "name": "building",
                    "isArray": false,
                    "type": {
                        "model": "Building"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "buildingId"
                    }
                },
                "writers": {
                    "name": "writers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Mortgages",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "CreateMortgage"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "writers",
                                "operations": [
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Notification": {
            "name": "Notification",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": {
                        "enum": "NotificationCreation"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "NotificationType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "message": {
                    "name": "message",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "popupDate": {
                    "name": "popupDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "expirationDate": {
                    "name": "expirationDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "NotificationStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "buildingId": {
                    "name": "buildingId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "building": {
                    "name": "building",
                    "isArray": false,
                    "type": {
                        "model": "Building"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "buildingId"
                    }
                },
                "unitId": {
                    "name": "unitId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "unit": {
                    "name": "unit",
                    "isArray": false,
                    "type": {
                        "model": "Unit"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "unitId"
                    }
                },
                "leaseId": {
                    "name": "leaseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "lease": {
                    "name": "lease",
                    "isArray": false,
                    "type": {
                        "model": "Lease"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "leaseId"
                    }
                },
                "technicId": {
                    "name": "technicId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "technic": {
                    "name": "technic",
                    "isArray": false,
                    "type": {
                        "model": "Technic"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "technicId"
                    }
                },
                "ticketId": {
                    "name": "ticketId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "ticket": {
                    "name": "ticket",
                    "isArray": false,
                    "type": {
                        "model": "Ticket"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "ticketId"
                    }
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Notifications",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update",
                                    "delete"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Posting": {
            "name": "Posting",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "class": {
                    "name": "class",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "customLabel": {
                    "name": "customLabel",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "topClass": {
                    "name": "topClass",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "PostingType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "totalAmount": {
                    "name": "totalAmount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "periodFrom": {
                    "name": "periodFrom",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "periodTo": {
                    "name": "periodTo",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "buildingId": {
                    "name": "buildingId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "building": {
                    "name": "building",
                    "isArray": false,
                    "type": {
                        "model": "Building"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "buildingId"
                    }
                },
                "unitId": {
                    "name": "unitId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "unit": {
                    "name": "unit",
                    "isArray": false,
                    "type": {
                        "model": "Unit"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "unitId"
                    }
                },
                "invoiceId": {
                    "name": "invoiceId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "invoice": {
                    "name": "invoice",
                    "isArray": false,
                    "type": {
                        "model": "Invoice"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "invoiceId"
                    }
                },
                "charge": {
                    "name": "charge",
                    "isArray": false,
                    "type": {
                        "model": "Charge"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "chargeId"
                    }
                },
                "transaction": {
                    "name": "transaction",
                    "isArray": false,
                    "type": {
                        "model": "Transaction"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "transactionId"
                    }
                },
                "leaseId": {
                    "name": "leaseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "lease": {
                    "name": "lease",
                    "isArray": false,
                    "type": {
                        "model": "Lease"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "leaseId"
                    }
                },
                "bankAccountId": {
                    "name": "bankAccountId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "bankAccount": {
                    "name": "bankAccount",
                    "isArray": false,
                    "type": {
                        "model": "BankAccount"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "bankAccountId"
                    }
                },
                "accountLabelId": {
                    "name": "accountLabelId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "accountLabel": {
                    "name": "accountLabel",
                    "isArray": false,
                    "type": {
                        "model": "AccountLabel"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "accountLabelId"
                    }
                },
                "contactId": {
                    "name": "contactId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "contact": {
                    "name": "contact",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "contactId"
                    }
                },
                "leaseVariousOperationId": {
                    "name": "leaseVariousOperationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "leaseVariousOperation": {
                    "name": "leaseVariousOperation",
                    "isArray": false,
                    "type": {
                        "model": "LeaseVariousOperation"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "leaseVariousOperationId"
                    }
                },
                "statementId": {
                    "name": "statementId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "amountVatExcluded": {
                    "name": "amountVatExcluded",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "vatAmount": {
                    "name": "vatAmount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "vatRate": {
                    "name": "vatRate",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "foreignId": {
                    "name": "foreignId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "foreignType": {
                    "name": "foreignType",
                    "isArray": false,
                    "type": {
                        "enum": "PostingForeignType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "invoicePostingId": {
                    "name": "invoicePostingId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "reconciledAt": {
                    "name": "reconciledAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "PostingStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "chargeOwnerRepartition": {
                    "name": "chargeOwnerRepartition",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "chargeRepartitionMetric": {
                    "name": "chargeRepartitionMetric",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "chargeRepartitionValue": {
                    "name": "chargeRepartitionValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "chargeSplitNumber": {
                    "name": "chargeSplitNumber",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "chargeType": {
                    "name": "chargeType",
                    "isArray": false,
                    "type": {
                        "enum": "PostingChargeType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "readers": {
                    "name": "readers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Postings",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byBuilding",
                        "fields": [
                            "buildingId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUnit",
                        "fields": [
                            "unitId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byInvoice",
                        "fields": [
                            "invoiceId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCharge",
                        "fields": [
                            "chargeId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTransaction",
                        "fields": [
                            "transactionId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byLease",
                        "fields": [
                            "leaseId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byBankAccount",
                        "fields": [
                            "bankAccountId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byAccountLabel",
                        "fields": [
                            "accountLabelId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byContact",
                        "fields": [
                            "contactId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byForeign",
                        "fields": [
                            "foreignId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readers",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update",
                                    "delete"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "RepartitionKey": {
            "name": "RepartitionKey",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "RepartitionKeyType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "accountLabelId": {
                    "name": "accountLabelId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "accountLabel": {
                    "name": "accountLabel",
                    "isArray": false,
                    "type": {
                        "model": "AccountLabel"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "accountLabelId"
                    }
                },
                "buildingId": {
                    "name": "buildingId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "building": {
                    "name": "building",
                    "isArray": false,
                    "type": {
                        "model": "Building"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "buildingId"
                    }
                },
                "unitRepartition": {
                    "name": "unitRepartition",
                    "isArray": true,
                    "type": {
                        "nonModel": "RepartitionValues"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "ownerRepartition": {
                    "name": "ownerRepartition",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantRepartition": {
                    "name": "tenantRepartition",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "repartitionMetric": {
                    "name": "repartitionMetric",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "technics": {
                    "name": "technics",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "RepartitionKeys",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "RepaymentStatement": {
            "name": "RepaymentStatement",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "delaysCleared": {
                    "name": "delaysCleared",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "expectedRent": {
                    "name": "expectedRent",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "lease": {
                    "name": "lease",
                    "isArray": false,
                    "type": {
                        "model": "Lease"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "leaseId"
                    }
                },
                "leaseId": {
                    "name": "leaseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "rentsCollected": {
                    "name": "rentsCollected",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "repaymentStatementDetails": {
                    "name": "repaymentStatementDetails",
                    "isArray": true,
                    "type": {
                        "nonModel": "RepaymentStatementDetail"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "statement": {
                    "name": "statement",
                    "isArray": false,
                    "type": {
                        "model": "Statement"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "statementId"
                    }
                },
                "totalToRepay": {
                    "name": "totalToRepay",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "unpaidRents": {
                    "name": "unpaidRents",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "RepaymentStatements",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStatement",
                        "fields": [
                            "statementId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update",
                                    "delete"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "SendingSource": {
            "name": "SendingSource",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "createdById": {
                    "name": "createdById",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "createdById"
                    }
                },
                "dnsRecords": {
                    "name": "dnsRecords",
                    "isArray": true,
                    "type": {
                        "nonModel": "DNSRecord"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "emailAddress": {
                    "name": "emailAddress",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "issuesEmail": {
                    "name": "issuesEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "chargesEmail": {
                    "name": "chargesEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "errorMessage": {
                    "name": "errorMessage",
                    "isArray": false,
                    "type": {
                        "enum": "SendingSourceErrorType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "lastReminderDate": {
                    "name": "lastReminderDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "SendingSourceStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "statusLastVerification": {
                    "name": "statusLastVerification",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "verifiedType": {
                    "name": "verifiedType",
                    "isArray": false,
                    "type": {
                        "enum": "SendingSourceVerifiedType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "SendingSources",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byIssuesEmail",
                        "fields": [
                            "issuesEmail"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStatus",
                        "fields": [
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Setting": {
            "name": "Setting",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Settings",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "SignatureDocument": {
            "name": "SignatureDocument",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "additionalMessage": {
                    "name": "additionalMessage",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "communicationBody": {
                    "name": "communicationBody",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "expirationDate": {
                    "name": "expirationDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "fileIds": {
                    "name": "fileIds",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "foreignKey": {
                    "name": "foreignKey",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "foreignTableName": {
                    "name": "foreignTableName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "sentAt": {
                    "name": "sentAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "signatureMethods": {
                    "name": "signatureMethods",
                    "isArray": true,
                    "type": {
                        "enum": "SignatureType"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "signatureResults": {
                    "name": "signatureResults",
                    "isArray": true,
                    "type": {
                        "nonModel": "SignatureDocumentResult"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "SignatureDocumentStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "externalStatus": {
                    "name": "externalStatus",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "SignatureDocumentType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "readers": {
                    "name": "readers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "SignatureDocuments",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byForeignKey",
                        "fields": [
                            "foreignKey",
                            "foreignTableName"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readers",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Statement": {
            "name": "Statement",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "amounts": {
                    "name": "amounts",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomValue"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "chargeStatements": {
                    "name": "chargeStatements",
                    "isArray": true,
                    "type": {
                        "model": "ChargeStatement"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "statement"
                    }
                },
                "closingStatement": {
                    "name": "closingStatement",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "periodFrom": {
                    "name": "periodFrom",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "periodTo": {
                    "name": "periodTo",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "source": {
                    "name": "source",
                    "isArray": false,
                    "type": {
                        "enum": "StatementSource"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "StatementStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "sentDate": {
                    "name": "sentDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "leaseId": {
                    "name": "leaseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "lease": {
                    "name": "lease",
                    "isArray": false,
                    "type": {
                        "model": "Lease"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "leaseId"
                    }
                },
                "agencyRateStatements": {
                    "name": "agencyRateStatements",
                    "isArray": true,
                    "type": {
                        "model": "AgencyRateStatement"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "statement"
                    }
                },
                "ownerId": {
                    "name": "ownerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "owner": {
                    "name": "owner",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "ownerId"
                    }
                },
                "repaymentStatements": {
                    "name": "repaymentStatements",
                    "isArray": true,
                    "type": {
                        "model": "RepaymentStatement"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "statement"
                    }
                },
                "types": {
                    "name": "types",
                    "isArray": true,
                    "type": {
                        "enum": "StatementType"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "unitIds": {
                    "name": "unitIds",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Statements",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byLeaseId",
                        "fields": [
                            "leaseId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOwnerId",
                        "fields": [
                            "ownerId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "StatementSetting": {
            "name": "StatementSetting",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "StatementSettingType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "custom": {
                    "name": "custom",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "disabled": {
                    "name": "disabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "monthFrequency": {
                    "name": "monthFrequency",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "startMonth": {
                    "name": "startMonth",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "leaseId": {
                    "name": "leaseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "lease": {
                    "name": "lease",
                    "isArray": false,
                    "type": {
                        "model": "Lease"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "leaseId"
                    }
                },
                "ownerId": {
                    "name": "ownerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "owner": {
                    "name": "owner",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "ownerId"
                    }
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "StatementSettings",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byLeaseId",
                        "fields": [
                            "leaseId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOwnerId",
                        "fields": [
                            "ownerId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Team": {
            "name": "Team",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "parentId": {
                    "name": "parentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "children": {
                    "name": "children",
                    "isArray": true,
                    "type": {
                        "model": "Team"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "parentId"
                    }
                },
                "users": {
                    "name": "users",
                    "isArray": true,
                    "type": {
                        "model": "UserTeam"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "team"
                    }
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Teams",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byParent",
                        "fields": [
                            "parentId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Technic": {
            "name": "Technic",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "TechnicType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "contractNumber": {
                    "name": "contractNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "communicationSettingsProfileId": {
                    "name": "communicationSettingsProfileId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "maintenanceHistory": {
                    "name": "maintenanceHistory",
                    "isArray": true,
                    "type": {
                        "nonModel": "TechnicMaintenanceHistory"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "utilityType": {
                    "name": "utilityType",
                    "isArray": false,
                    "type": {
                        "enum": "UtilityType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "utilityEanCode": {
                    "name": "utilityEanCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "utilityMeterNumber": {
                    "name": "utilityMeterNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "utilityDistributorId": {
                    "name": "utilityDistributorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "utilityDistributor": {
                    "name": "utilityDistributor",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "utilityDistributorId"
                    }
                },
                "utilityHistory": {
                    "name": "utilityHistory",
                    "isArray": true,
                    "type": {
                        "nonModel": "UtilityHistory"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "PebValidityDate": {
                    "name": "PebValidityDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "PebEnergeticPerformance": {
                    "name": "PebEnergeticPerformance",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "PebReportNumber": {
                    "name": "PebReportNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "PebIssueDate": {
                    "name": "PebIssueDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "detectorType": {
                    "name": "detectorType",
                    "isArray": false,
                    "type": {
                        "enum": "DetectorType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "detectorSerialNumber": {
                    "name": "detectorSerialNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "detectorBrand": {
                    "name": "detectorBrand",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "detectorLastControlDate": {
                    "name": "detectorLastControlDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "detectorUnitInventoryId": {
                    "name": "detectorUnitInventoryId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "heatingBrand": {
                    "name": "heatingBrand",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "heatingModel": {
                    "name": "heatingModel",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "heatingInstallationDate": {
                    "name": "heatingInstallationDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "heatingType": {
                    "name": "heatingType",
                    "isArray": false,
                    "type": {
                        "enum": "HeatingType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "heatingLastControlDate": {
                    "name": "heatingLastControlDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "tankType": {
                    "name": "tankType",
                    "isArray": false,
                    "type": {
                        "enum": "TankType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "tankCapacity": {
                    "name": "tankCapacity",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "tankState": {
                    "name": "tankState",
                    "isArray": false,
                    "type": {
                        "enum": "TankState"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "tankLastControlDate": {
                    "name": "tankLastControlDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "tankLeakDetection": {
                    "name": "tankLeakDetection",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "tankEncuvement": {
                    "name": "tankEncuvement",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "tankWallType": {
                    "name": "tankWallType",
                    "isArray": false,
                    "type": {
                        "enum": "TankWallType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "tankInstallationDate": {
                    "name": "tankInstallationDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "chimneyLastControlDate": {
                    "name": "chimneyLastControlDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "chimneyUnitInventoryId": {
                    "name": "chimneyUnitInventoryId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "files": {
                    "name": "files",
                    "isArray": true,
                    "type": {
                        "model": "File"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "foreignKey"
                    }
                },
                "buildingId": {
                    "name": "buildingId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "building": {
                    "name": "building",
                    "isArray": false,
                    "type": {
                        "model": "Building"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "buildingId"
                    }
                },
                "unitId": {
                    "name": "unitId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "unit": {
                    "name": "unit",
                    "isArray": false,
                    "type": {
                        "model": "Unit"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "unitId"
                    }
                },
                "leaseId": {
                    "name": "leaseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "lease": {
                    "name": "lease",
                    "isArray": false,
                    "type": {
                        "model": "Lease"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "leaseId"
                    }
                },
                "contactId": {
                    "name": "contactId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "contact": {
                    "name": "contact",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "contactId"
                    }
                },
                "customValues": {
                    "name": "customValues",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomValue"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "originalTechnicId": {
                    "name": "originalTechnicId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "readers": {
                    "name": "readers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "writers": {
                    "name": "writers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Technics",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUnitId",
                        "fields": [
                            "unitId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byLeaseId",
                        "fields": [
                            "leaseId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "custom",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Template": {
            "name": "Template",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "language": {
                    "name": "language",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "subject": {
                    "name": "subject",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "subjectRawFormat": {
                    "name": "subjectRawFormat",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "body": {
                    "name": "body",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "bodyRawFormat": {
                    "name": "bodyRawFormat",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "TemplateType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "subType": {
                    "name": "subType",
                    "isArray": false,
                    "type": {
                        "enum": "TemplateSubType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Templates",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Ticket": {
            "name": "Ticket",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "assignedAt": {
                    "name": "assignedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "assignedTo": {
                    "name": "assignedTo",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "assignedToId"
                    }
                },
                "assignedToId": {
                    "name": "assignedToId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "attachment": {
                    "name": "attachment",
                    "isArray": true,
                    "type": {
                        "model": "File"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "foreignKey"
                    }
                },
                "building": {
                    "name": "building",
                    "isArray": false,
                    "type": {
                        "model": "Building"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "buildingId"
                    }
                },
                "buildingId": {
                    "name": "buildingId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "closedAt": {
                    "name": "closedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "contact": {
                    "name": "contact",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "contactId"
                    }
                },
                "contactId": {
                    "name": "contactId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "content": {
                    "name": "content",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "conversations": {
                    "name": "conversations",
                    "isArray": true,
                    "type": {
                        "model": "Ticket"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "parentId"
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "emailMessageId": {
                    "name": "emailMessageId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "emailReferences": {
                    "name": "emailReferences",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "parentId": {
                    "name": "parentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "plannedAt": {
                    "name": "plannedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "referenceCode": {
                    "name": "referenceCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "sourceType": {
                    "name": "sourceType",
                    "isArray": false,
                    "type": {
                        "enum": "TicketSourceType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "TicketStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "subject": {
                    "name": "subject",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "unit": {
                    "name": "unit",
                    "isArray": false,
                    "type": {
                        "model": "Unit"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "unitId"
                    }
                },
                "unitId": {
                    "name": "unitId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "unread": {
                    "name": "unread",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "readers": {
                    "name": "readers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Tickets",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "id"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byParent",
                        "fields": [
                            "parentId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "CreateTicket"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readers",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Transaction": {
            "name": "Transaction",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "TransactionStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "sequence": {
                    "name": "sequence",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "statementDate": {
                    "name": "statementDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "operationDate": {
                    "name": "operationDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "remittanceInformationType": {
                    "name": "remittanceInformationType",
                    "isArray": false,
                    "type": {
                        "enum": "RemittanceType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "remittanceInformation": {
                    "name": "remittanceInformation",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "comment": {
                    "name": "comment",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "amount": {
                    "name": "amount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "bankAccountId": {
                    "name": "bankAccountId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "bankAccount": {
                    "name": "bankAccount",
                    "isArray": false,
                    "type": {
                        "model": "BankAccount"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "bankAccountId"
                    }
                },
                "counterpartName": {
                    "name": "counterpartName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "counterpartAccountNumber": {
                    "name": "counterpartAccountNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "manualEntry": {
                    "name": "manualEntry",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "proposedLeaseId": {
                    "name": "proposedLeaseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "proposedUnitId": {
                    "name": "proposedUnitId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "proposedContractorId": {
                    "name": "proposedContractorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "proposedMatch": {
                    "name": "proposedMatch",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "links": {
                    "name": "links",
                    "isArray": true,
                    "type": {
                        "nonModel": "TransactionLink"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "postings": {
                    "name": "postings",
                    "isArray": true,
                    "type": {
                        "model": "Posting"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "transaction"
                    }
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "readers": {
                    "name": "readers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Transactions",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byBankAccount",
                        "fields": [
                            "bankAccountId",
                            "updatedAt"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readers",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "TransactionRule": {
            "name": "TransactionRule",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "remittanceInformation": {
                    "name": "remittanceInformation",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "counterpartName": {
                    "name": "counterpartName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "counterpartAccountNumber": {
                    "name": "counterpartAccountNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "usedFields": {
                    "name": "usedFields",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "exclude": {
                    "name": "exclude",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "leaseId": {
                    "name": "leaseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "lease": {
                    "name": "lease",
                    "isArray": false,
                    "type": {
                        "model": "Lease"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "leaseId"
                    }
                },
                "unitId": {
                    "name": "unitId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "unit": {
                    "name": "unit",
                    "isArray": false,
                    "type": {
                        "model": "Unit"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "unitId"
                    }
                },
                "contractorId": {
                    "name": "contractorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "contractor": {
                    "name": "contractor",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "contractorId"
                    }
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "TransactionRules",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Unit": {
            "name": "Unit",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "acquisitionAdditionalCostsValue": {
                    "name": "acquisitionAdditionalCostsValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "acquisitionAdvertisedValue": {
                    "name": "acquisitionAdvertisedValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "acquisitionValue": {
                    "name": "acquisitionValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "advertisedMonthlyCharges": {
                    "name": "advertisedMonthlyCharges",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "advertisedRentalPrice": {
                    "name": "advertisedRentalPrice",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "cadastralIncome": {
                    "name": "cadastralIncome",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "customValues": {
                    "name": "customValues",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomValue"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "floor": {
                    "name": "floor",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "jointOwnershipId": {
                    "name": "jointOwnershipId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "managementDate": {
                    "name": "managementDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "predictedPrice": {
                    "name": "predictedPrice",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "propertyTax": {
                    "name": "propertyTax",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "publishAddress": {
                    "name": "publishAddress",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "surface": {
                    "name": "surface",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "UnitType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "building": {
                    "name": "building",
                    "isArray": false,
                    "type": {
                        "model": "Building"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "buildingId"
                    }
                },
                "owners": {
                    "name": "owners",
                    "isArray": true,
                    "type": {
                        "model": "UnitOwner"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "unit"
                    }
                },
                "leases": {
                    "name": "leases",
                    "isArray": true,
                    "type": {
                        "model": "UnitLease"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "unit"
                    }
                },
                "events": {
                    "name": "events",
                    "isArray": true,
                    "type": {
                        "model": "UnitEvent"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "unitId"
                    }
                },
                "unitAdvertisementId": {
                    "name": "unitAdvertisementId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "unitInventories": {
                    "name": "unitInventories",
                    "isArray": true,
                    "type": {
                        "model": "UnitInventory"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "unitId"
                    }
                },
                "readers": {
                    "name": "readers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Units",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byBuilding",
                        "fields": [
                            "buildingId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "custom",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readers",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update",
                                    "delete"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "UnitEvent": {
            "name": "UnitEvent",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "unitId": {
                    "name": "unitId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "UnitEventType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "startDate": {
                    "name": "startDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "endDate": {
                    "name": "endDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "totalCost": {
                    "name": "totalCost",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "note": {
                    "name": "note",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "writers": {
                    "name": "writers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "UnitEvents",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUnit",
                        "fields": [
                            "unitId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "CreateUnitEvent"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "writers",
                                "operations": [
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "UnitInventory": {
            "name": "UnitInventory",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "privateBatthRoom": {
                    "name": "privateBatthRoom",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "deletedAt": {
                    "name": "deletedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "kitchenEquipment": {
                    "name": "kitchenEquipment",
                    "isArray": false,
                    "type": {
                        "enum": "KitchenEquipment"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "roomName": {
                    "name": "roomName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "roomType": {
                    "name": "roomType",
                    "isArray": false,
                    "type": {
                        "enum": "UnitInventoryRoomType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "surface": {
                    "name": "surface",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "showSurface": {
                    "name": "showSurface",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "unitId": {
                    "name": "unitId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "inside": {
                    "name": "inside",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "structures": {
                    "name": "structures",
                    "isArray": true,
                    "type": {
                        "model": "UnitInventoryStructure"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "unitInventoryId"
                    }
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "UnitInventories",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUnit",
                        "fields": [
                            "unitId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "UnitInventoryStructure": {
            "name": "UnitInventoryStructure",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "deletedAt": {
                    "name": "deletedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "unitInventoryId": {
                    "name": "unitInventoryId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "encodings": {
                    "name": "encodings",
                    "isArray": true,
                    "type": {
                        "model": "LeaseInventoryEncoding"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "structureId"
                    }
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "UnitInventoryStructureType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "brand": {
                    "name": "brand",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "UnitInventoryStructures",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUnitInventory",
                        "fields": [
                            "unitInventoryId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "UnitLease": {
            "name": "UnitLease",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "allowChargeAdjustment": {
                    "name": "allowChargeAdjustment",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "authorizeProfessionalActivity": {
                    "name": "authorizeProfessionalActivity",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "chargesIndexation": {
                    "name": "chargesIndexation",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "endDate": {
                    "name": "endDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "furnishedRental": {
                    "name": "furnishedRental",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "furnishedRentalInsuranceByTenant": {
                    "name": "furnishedRentalInsuranceByTenant",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "furnishedRentalRentalPricePercentage": {
                    "name": "furnishedRentalRentalPricePercentage",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "initialMonthlyCharges": {
                    "name": "initialMonthlyCharges",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "initialRentalPrice": {
                    "name": "initialRentalPrice",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "indexation": {
                    "name": "indexation",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "mainUnit": {
                    "name": "mainUnit",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "monthlyChargesType": {
                    "name": "monthlyChargesType",
                    "isArray": false,
                    "type": {
                        "enum": "LeaseMonthlyChargesType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "monthlyCharges": {
                    "name": "monthlyCharges",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "rentalPrice": {
                    "name": "rentalPrice",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "signatureDate": {
                    "name": "signatureDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "startDate": {
                    "name": "startDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "unit": {
                    "name": "unit",
                    "isArray": false,
                    "type": {
                        "model": "Unit"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "unitId"
                    }
                },
                "lease": {
                    "name": "lease",
                    "isArray": false,
                    "type": {
                        "model": "Lease"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "leaseId"
                    }
                },
                "readers": {
                    "name": "readers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "UnitLeases",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byLease",
                        "fields": [
                            "leaseId",
                            "unitId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUnit",
                        "fields": [
                            "unitId",
                            "leaseId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readers",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "UnitOwner": {
            "name": "UnitOwner",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "disabled": {
                    "name": "disabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "owner": {
                    "name": "owner",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "ownerId"
                    }
                },
                "periodFrom": {
                    "name": "periodFrom",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "periodTo": {
                    "name": "periodTo",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "stake": {
                    "name": "stake",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "unit": {
                    "name": "unit",
                    "isArray": false,
                    "type": {
                        "model": "Unit"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "unitId"
                    }
                },
                "readers": {
                    "name": "readers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "UnitOwners",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOwner",
                        "fields": [
                            "ownerId",
                            "unitId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUnit",
                        "fields": [
                            "unitId",
                            "ownerId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readers",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "User": {
            "name": "User",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "firstName": {
                    "name": "firstName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "lastName": {
                    "name": "lastName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "cognitoSub": {
                    "name": "cognitoSub",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "googleSub": {
                    "name": "googleSub",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "facebookSub": {
                    "name": "facebookSub",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "rootUser": {
                    "name": "rootUser",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "refreshToken": {
                    "name": "refreshToken",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "teams": {
                    "name": "teams",
                    "isArray": true,
                    "type": {
                        "model": "UserTeam"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "user"
                    }
                },
                "userRoleId": {
                    "name": "userRoleId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "userRole": {
                    "name": "userRole",
                    "isArray": false,
                    "type": {
                        "model": "UserRole"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "userRoleId"
                    }
                },
                "contactId": {
                    "name": "contactId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "contact": {
                    "name": "contact",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "contactId"
                    }
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "UserStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Users",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byEmail",
                        "fields": [
                            "email"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byContact",
                        "fields": [
                            "contactId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "custom",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "id",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "UserRole": {
            "name": "UserRole",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "userRights": {
                    "name": "userRights",
                    "isArray": true,
                    "type": {
                        "nonModel": "UserRight"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "UserRoles",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "id",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "UserTeam": {
            "name": "UserTeam",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "team": {
                    "name": "team",
                    "isArray": false,
                    "type": {
                        "model": "Team"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "teamId"
                    }
                },
                "user": {
                    "name": "user",
                    "isArray": false,
                    "type": {
                        "model": "User"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "userId"
                    }
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "UserTeams",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUser",
                        "fields": [
                            "userId",
                            "teamId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTeam",
                        "fields": [
                            "teamId",
                            "userId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Valuation": {
            "name": "Valuation",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "sourceType": {
                    "name": "sourceType",
                    "isArray": false,
                    "type": {
                        "enum": "ValuationSourceType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "valorization": {
                    "name": "valorization",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "date": {
                    "name": "date",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "unitId": {
                    "name": "unitId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "unit": {
                    "name": "unit",
                    "isArray": false,
                    "type": {
                        "model": "Unit"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "unitId"
                    }
                },
                "buildingId": {
                    "name": "buildingId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "building": {
                    "name": "building",
                    "isArray": false,
                    "type": {
                        "model": "Building"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "buildingId"
                    }
                },
                "writers": {
                    "name": "writers",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Valuations",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "CreateValuation"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "writers",
                                "operations": [
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "VariousOperation": {
            "name": "VariousOperation",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "amount": {
                    "name": "amount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "labels": {
                    "name": "labels",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomLabel"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "numberOfMonths": {
                    "name": "numberOfMonths",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "operationType": {
                    "name": "operationType",
                    "isArray": false,
                    "type": {
                        "enum": "LeaseVariousOperationType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "paymentFrequency": {
                    "name": "paymentFrequency",
                    "isArray": false,
                    "type": {
                        "enum": "LeasePaymentFrequency"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "VariousOperationType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "VariousOperations",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "VatRate": {
            "name": "VatRate",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "country": {
                    "name": "country",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "label": {
                    "name": "label",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "readId": {
                    "name": "readId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "VatRates",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClientId",
                        "fields": [
                            "clientId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readId",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "clientId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        }
    },
    "enums": {
        "AccountLabelCustomUsage": {
            "name": "AccountLabelCustomUsage",
            "values": [
                "INDEXATION_REGULARIZATION"
            ]
        },
        "AccountType": {
            "name": "AccountType",
            "values": [
                "OWNER",
                "AGENCY",
                "FUND"
            ]
        },
        "AddressStatus": {
            "name": "AddressStatus",
            "values": [
                "NEW",
                "DRAFT_OCR",
                "ACTIVE_GEOCODED",
                "ERROR"
            ]
        },
        "AdvertisementPublishingPlatformActions": {
            "name": "AdvertisementPublishingPlatformActions",
            "values": [
                "PUBLISH",
                "DELETE",
                "UPDATE"
            ]
        },
        "AdvertisementUnitType": {
            "name": "AdvertisementUnitType",
            "values": [
                "APARTMENT",
                "HOUSE",
                "LAND",
                "COMMERCIAL",
                "GARAGEPARKING",
                "SPECIAL"
            ]
        },
        "AdvertisementUnitSubType": {
            "name": "AdvertisementUnitSubType",
            "values": [
                "STUDIO",
                "DUPLEX",
                "TRIPLEX",
                "PENTHOUSE",
                "GROUNDFLOORFLAT",
                "LOFT",
                "STUDENTFLAT",
                "BUILDING",
                "FLAT",
                "APARTMENTSTANDARD",
                "VILLA",
                "TERRACED",
                "FARM",
                "MANSION",
                "COTTAGE",
                "BUNGALOW",
                "CASTLE",
                "DETACHED",
                "SEMIDETACHED",
                "KANGAROOHOUSE",
                "PILEHOUSE",
                "HOUSEBOAT",
                "MOBILEHOME",
                "MIXED",
                "BUILDINGLAND",
                "MEADOW",
                "WOOD",
                "INDUSTRIALLAND",
                "FARMLAND",
                "OFFICE",
                "OFFICEBUILDING",
                "WAREHOUSE",
                "SHOP",
                "RESTAURANT",
                "BAR",
                "HOTEL",
                "RETAILSPACE",
                "INDUSTRIAL",
                "BUSINESS",
                "OPENAIRSPACE",
                "OUTSIDEBOX",
                "INSIDEBOX",
                "COVEREDSPACE",
                "CARPARK",
                "INDOORSPACE"
            ]
        },
        "AgencyRateAmountDetailType": {
            "name": "AgencyRateAmountDetailType",
            "values": [
                "PRICE",
                "PERCENTAGE"
            ]
        },
        "AgencyRateFeesType": {
            "name": "AgencyRateFeesType",
            "values": [
                "AMOUNT_COLLECTED",
                "EXPECTED_RENT"
            ]
        },
        "AgencyRateIncludedAmount": {
            "name": "AgencyRateIncludedAmount",
            "values": [
                "RENT_WITHOUT_CHARGES",
                "PROVISIONED_CHARGES",
                "FIXED_CHARGES",
                "RENTAL_DISCOUNT",
                "INDEXED_PRICE",
                "VARIOUS_OPERATION_CHARGE",
                "VARIOUS_OPERATION_SERVICE"
            ]
        },
        "AgencyRateRepartitionType": {
            "name": "AgencyRateRepartitionType",
            "values": [
                "BY_UNIT",
                "ON_PROPERTY"
            ]
        },
        "AgencyRateType": {
            "name": "AgencyRateType",
            "values": [
                "FIXED",
                "DECREASING"
            ]
        },
        "AmenitiesType": {
            "name": "AmenitiesType",
            "values": [
                "ALARM",
                "ARMOURED_DOOR",
                "DOOR_PHONE",
                "FIRE_PLACE",
                "JACUZZI",
                "LARGE_PETS_ALLOWED",
                "SAUNA",
                "SMALL_PETS_ALLOWED",
                "TV_CONNECTION"
            ]
        },
        "BatchChangeCommunicationsAction": {
            "name": "BatchChangeCommunicationsAction",
            "values": [
                "SEND",
                "RESEND",
                "DELETE"
            ]
        },
        "BounceType": {
            "name": "BounceType",
            "values": [
                "MAILBOX_DOES_NOT_EXIST",
                "MESSAGE_TOO_LARGE",
                "MAILBOX_FULL",
                "CONTENT_NOT_ACCEPTED",
                "UNKNOWN",
                "TEMPORARY_FAILURE"
            ]
        },
        "BuildingState": {
            "name": "BuildingState",
            "values": [
                "VERY_GOOD",
                "GOOD",
                "BAD",
                "VERY_BAD"
            ]
        },
        "CertificateOfConformityType": {
            "name": "CertificateOfConformityType",
            "values": [
                "YES_CONFORM",
                "YES_NOT_CONFORM",
                "NO"
            ]
        },
        "ChargeSource": {
            "name": "ChargeSource",
            "values": [
                "MAIL",
                "WEB",
                "MANUAL"
            ]
        },
        "ChargeStatus": {
            "name": "ChargeStatus",
            "values": [
                "TO_CHECK",
                "TO_AFFECT",
                "AFFECTED"
            ]
        },
        "CivilityType": {
            "name": "CivilityType",
            "values": [
                "MAN",
                "WOMAN",
                "OTHER"
            ]
        },
        "CommunicationChannel": {
            "name": "CommunicationChannel",
            "values": [
                "EMAIL",
                "IN_APP",
                "LETTER",
                "REGISTERED_LETTER",
                "SMS",
                "WHATSAPP"
            ]
        },
        "CommunicationFrequencySettingPeriodDateFrom": {
            "name": "CommunicationFrequencySettingPeriodDateFrom",
            "values": [
                "ANNIVERSARY",
                "MAINTENANCE_DAY"
            ]
        },
        "CommunicationFrequencySettingPeriodType": {
            "name": "CommunicationFrequencySettingPeriodType",
            "values": [
                "DAYS",
                "WEEKS",
                "MONTHS",
                "YEARS"
            ]
        },
        "CommunicationSendingRule": {
            "name": "CommunicationSendingRule",
            "values": [
                "AUTOMATIC",
                "DISABLE",
                "MANUAL"
            ]
        },
        "CommunicationSettingsProfileReferent": {
            "name": "CommunicationSettingsProfileReferent",
            "values": [
                "LEASE",
                "OWNER",
                "TECHNIC"
            ]
        },
        "CommunicationSettingRecipient": {
            "name": "CommunicationSettingRecipient",
            "values": [
                "GUARANTOR",
                "OWNER",
                "TENANT"
            ]
        },
        "CommunicationStatus": {
            "name": "CommunicationStatus",
            "values": [
                "TO_SEND",
                "SENT",
                "SNOOZED",
                "TO_DELETE",
                "BOUNCE_ERROR",
                "OUTDATED"
            ]
        },
        "CommunicationTimeFrameSetting": {
            "name": "CommunicationTimeFrameSetting",
            "values": [
                "MORNING",
                "AFTERNOON",
                "EVENING",
                "NIGHT"
            ]
        },
        "CommunicationType": {
            "name": "CommunicationType",
            "values": [
                "CUSTOM",
                "DOCUMENT_SIGNATURE_REMINDER",
                "DOCUMENT_SIGNED",
                "LEASE_ALLOW_PROFESSIONAL_ACTIVITY_ON",
                "LEASE_BANKING_INFO_UPDATE",
                "LEASE_CHARGES_ADJUSTMENT",
                "LEASE_CONTRACT_EXTENDED",
                "LEASE_CONTRACT_REJECTED",
                "LEASE_CONTRACT_RETRACTED",
                "LEASE_CONTRACT_REVOKED",
                "LEASE_CONTRACT_SIGNATURE_REMINDER",
                "LEASE_CONTRACT_SIGNED",
                "LEASE_CREDIT_NOTE",
                "LEASE_DISCOUNT_CREATED",
                "LEASE_DISCOUNT_DELETED",
                "LEASE_DISCOUNT_UPDATED",
                "LEASE_OPERATION_CREATED",
                "LEASE_OPERATION_DELETED",
                "LEASE_OPERATION_PAYMENT_REQUEST",
                "LEASE_OPERATION_UPDATED",
                "LEASE_PAYMENT_RECEIPT",
                "LEASE_PAYMENT_REQUEST",
                "LEASE_RENT_INDEXATION",
                "LEASE_RENT_INDEXATION_SKIPPED",
                "LEASE_RENTAL_ADAPTATION",
                "LEASE_TENANT_STATEMENT",
                "LEASE_TERMINATION",
                "LEASE_UNPAID_AMOUNT_FORMAL_NOTICE",
                "LEASE_UNPAID_AMOUNT_REMINDER",
                "LEASE_UNPAID_AMOUNT_SECOND_REMINDER",
                "OWNER_STATEMENT",
                "SYSTEM"
            ]
        },
        "ConnectionPlugsType": {
            "name": "ConnectionPlugsType",
            "values": [
                "TELEVISION",
                "INTERNET",
                "TELEPHONE"
            ]
        },
        "ContactStatus": {
            "name": "ContactStatus",
            "values": [
                "DRAFT_OCR",
                "ARCHIVED",
                "ERROR"
            ]
        },
        "ContactType": {
            "name": "ContactType",
            "values": [
                "OWNER",
                "TENANT",
                "GUARANTOR",
                "MEMBER",
                "CONTRACTOR",
                "JOINT_OWNERSHIP",
                "CLIENT"
            ]
        },
        "CustomFieldType": {
            "name": "CustomFieldType",
            "values": [
                "STRING",
                "NUMBER",
                "FLOAT",
                "DATE",
                "BOOLEAN"
            ]
        },
        "CustomSubscriptionType": {
            "name": "CustomSubscriptionType",
            "values": [
                "BLOCKED_COMMUNICATIONS",
                "COMMUNICATION",
                "CREDITS",
                "ENTITY_DELETION",
                "INTEGRATION",
                "REFETCH",
                "REPORT",
                "SENDING_SOURCE",
                "SIGNATURE_DOCUMENT",
                "TACIT_RENEWAL",
                "TACIT_WARNING",
                "OCR_SUCCESS",
                "OCR_FAILURE"
            ]
        },
        "DeepDeletableEntityType": {
            "name": "DeepDeletableEntityType",
            "values": [
                "BUILDING",
                "CONTACT",
                "LEASE",
                "UNIT"
            ]
        },
        "DelimitedZoneType": {
            "name": "DelimitedZoneType",
            "values": [
                "DELIMITED_FLOOD_ZONE",
                "DELIMITED_REPARIAN_ZONE",
                "NOT_COMMUNICATED"
            ]
        },
        "DetectorType": {
            "name": "DetectorType",
            "values": [
                "CO2",
                "SMOKE"
            ]
        },
        "DocumentType": {
            "name": "DocumentType",
            "values": [
                "EntranceFurniture",
                "EntranceInventory",
                "ExitFurniture",
                "ExitInventory",
                "Invoice",
                "Lease",
                "StatementDetail",
                "StatementReceipt",
                "LeasePriceHistoryIndexationAccepted",
                "LeaseTerminationFromLessor",
                "Receipt",
                "Letter"
            ]
        },
        "FileCategory": {
            "name": "FileCategory",
            "values": [
                "LEASE_CONTRACT",
                "LEASE_CONTRACT_END",
                "LEASE_INVENTORY_CONTRACT",
                "CHARGE_INVOICE",
                "INVOICE",
                "PEB",
                "CO_OWNERSHIP_REGULATIONS",
                "RULES_OF_PROCEDURE",
                "HYDROCARBONTANK_CERTIFICATE",
                "FURNITURE_INVENTORY",
                "HEATING",
                "CHIMNEY",
                "UTILITY",
                "DETECTOR",
                "INVENTORY_OF_FIXTURES",
                "COMMUNICATION",
                "ADVERTISEMENT_PICTURE",
                "CLIENT_LOGO",
                "BASIC_DEED",
                "CONTRACT",
                "STATEMENT_DETAIL",
                "STATEMENT_RECEIPT",
                "CONTRACT_AMENDMENT",
                "LEASE_PRICE_HISTORY_DETAIL",
                "CUSTOM",
                "INVOICE_RECEIPT",
                "TICKET",
                "LEASE_INVENTORY_ENCODING",
                "LETTER",
                "TEMPORARY"
            ]
        },
        "FloodProneAreaType": {
            "name": "FloodProneAreaType",
            "values": [
                "RECOGNIZED_FLOOD_ZONE",
                "POTENTIAL_FLOOD_ZONE",
                "NON_FLOODABLE_ZONE",
                "NOT_COMMUNICATED"
            ]
        },
        "GenerateReportErrorCode": {
            "name": "GenerateReportErrorCode",
            "values": [
                "WRONG_PARAMS",
                "WRONG_FORMAT",
                "UNAUTHORIZED",
                "GENERATION_ERROR"
            ]
        },
        "GenerateReportOutputFormat": {
            "name": "GenerateReportOutputFormat",
            "values": [
                "PDF",
                "CSV"
            ]
        },
        "GenerateReportOutputResult": {
            "name": "GenerateReportOutputResult",
            "values": [
                "CUSTOM_SUBSCRIPTION",
                "EMAIL"
            ]
        },
        "GenerateReportType": {
            "name": "GenerateReportType",
            "values": [
                "OPERATIONAL_PERFORMANCE",
                "CHARGES",
                "TURNOVER",
                "FEES",
                "TENANTS_AGING_BALANCE",
                "SUPPLIER_DEBTS"
            ]
        },
        "HeatingAmenities": {
            "name": "HeatingAmenities",
            "values": [
                "COLLECTIVE_HEATING",
                "COLLECTIVE_WATER",
                "SIMPLE_GLAZING",
                "DOUBLE_GLAZING",
                "TRIPLE_GLAZING",
                "QUADRUPLE_GLAZING",
                "AIR_CONDITIONNING"
            ]
        },
        "HeatingType": {
            "name": "HeatingType",
            "values": [
                "GAS",
                "FUEL_OIL",
                "ELECTRICITY",
                "HEATING_PUMP",
                "WOOD",
                "GEOTHERMAL",
                "SOLAR",
                "WIND"
            ]
        },
        "IntegrationName": {
            "name": "IntegrationName",
            "values": [
                "SAGE_BOB50",
                "PONTO",
                "MYBANX",
                "OCR"
            ]
        },
        "IntegrationStatus": {
            "name": "IntegrationStatus",
            "values": [
                "ACTIVE",
                "INACTIVE",
                "ERROR"
            ]
        },
        "InventoryItemType": {
            "name": "InventoryItemType",
            "values": [
                "COLOR",
                "MATERIAL",
                "DEFECT"
            ]
        },
        "InventoryOfFixturesMethod": {
            "name": "InventoryOfFixturesMethod",
            "values": [
                "EXPERT",
                "BETWEEN_PARTIES"
            ]
        },
        "InventoryOfFixturesType": {
            "name": "InventoryOfFixturesType",
            "values": [
                "FIRST_MONTH",
                "BEFORE_OCCUPATION"
            ]
        },
        "InviteContactToClientAccountType": {
            "name": "InviteContactToClientAccountType",
            "values": [
                "SIGN_LEASE",
                "SIGN_DOCUMENT",
                "INVITE_TENANT",
                "INVITE_OWNER"
            ]
        },
        "InvoiceType": {
            "name": "InvoiceType",
            "values": [
                "RENT",
                "STATEMENT",
                "LEASE_VARIOUS_OPERATION"
            ]
        },
        "KitchenEquipment": {
            "name": "KitchenEquipment",
            "values": [
                "FULLY_EQUIPPED",
                "EQUIPPED",
                "PARTIALLY_EQUIPPED",
                "UNEQUIPPED",
                "AMERICAN_FULLY_EQUIPPED",
                "AMERICAN_EQUIPPED",
                "AMERICAN_PARTIALLY_EQUIPPED",
                "AMERICAN_UNEQUIPPED"
            ]
        },
        "LandTerrainType": {
            "name": "LandTerrainType",
            "values": [
                "STREET_FRONT_LAND",
                "STREET_BACK_LAND",
                "FLAT_LAND"
            ]
        },
        "Language": {
            "name": "Language",
            "values": [
                "FR",
                "NL",
                "EN"
            ]
        },
        "LeaseAction": {
            "name": "LeaseAction",
            "values": [
                "EXTEND_LEASE",
                "END_LEASE",
                "ADD_UNIT",
                "CANCELED_END_LEASE",
                "REMOVE_UNIT",
                "INITIAL_DATE"
            ]
        },
        "LeaseActionEndInitiator": {
            "name": "LeaseActionEndInitiator",
            "values": [
                "LESSOR",
                "TENANT",
                "MUTUAL",
                "COURT_DECISION",
                "NOTARIAL_ACT"
            ]
        },
        "LeaseActionEndReason": {
            "name": "LeaseActionEndReason",
            "values": [
                "OWN_USE",
                "RENOVATION",
                "NONE"
            ]
        },
        "LeaseActionHistoryStatus": {
            "name": "LeaseActionHistoryStatus",
            "values": [
                "TO_PROCESS",
                "PROCESSED"
            ]
        },
        "LeaseAmountUpdateStatus": {
            "name": "LeaseAmountUpdateStatus",
            "values": [
                "DRAFT",
                "TO_PROCESS_CLIENT_ACCEPTED",
                "TO_PROCESS_CLIENT_REFUSED",
                "ACCEPTED_PROCESSED_NEED_TO_APPLY",
                "APPLIED",
                "REFUSED",
                "OUTDATED",
                "ERROR"
            ]
        },
        "LeasePriceHistoryType": {
            "name": "LeasePriceHistoryType",
            "values": [
                "INDEXATION",
                "ADJUSTMENT",
                "INITIAL_PRICE",
                "TERMS_CHANGE"
            ]
        },
        "LeasePriceHistoryStatus": {
            "name": "LeasePriceHistoryStatus",
            "values": [
                "INDEXED",
                "NON_INDEXED",
                "INDEXED_WITH_CUSTOM_AMOUNT",
                "NOT_APPLICABLE"
            ]
        },
        "LeaseInventoryFurnitureExitIssueAction": {
            "name": "LeaseInventoryFurnitureExitIssueAction",
            "values": [
                "TO_BE_REPARED",
                "TO_BE_REPLACED",
                "NOTHING_TO_DO"
            ]
        },
        "LeaseInventoryFurnitureExitIssueType": {
            "name": "LeaseInventoryFurnitureExitIssueType",
            "values": [
                "MISSING",
                "DAMAGED",
                "REPLACED",
                "OUT_OF_ORDER"
            ]
        },
        "LeaseInventoryUnitKeyType": {
            "name": "LeaseInventoryUnitKeyType",
            "values": [
                "APARTMENT_KEY",
                "BUILDING_KEY",
                "HOUSE_KEY",
                "BASEMENT_KEY",
                "ROOM_KEY",
                "APARTMENT_BADGE",
                "BUILDING_BADGE",
                "HOUSE_BADGE",
                "BASEMENT_BADGE",
                "ROOM_BADGE"
            ]
        },
        "LeaseInventoryStructureStateType": {
            "name": "LeaseInventoryStructureStateType",
            "values": [
                "VERY_GOOD",
                "GOOD",
                "BAD",
                "VERY_BAD"
            ]
        },
        "LeaseInventoryType": {
            "name": "LeaseInventoryType",
            "values": [
                "FIXTURES_ENTRANCE",
                "FIXTURES_EXIT",
                "FURNITURES_ENTRANCE",
                "FURNITURES_EXIT"
            ]
        },
        "LeaseMonthlyChargesType": {
            "name": "LeaseMonthlyChargesType",
            "values": [
                "FixedPrice",
                "MonthlyProvisioned"
            ]
        },
        "LeasePaymentFrequency": {
            "name": "LeasePaymentFrequency",
            "values": [
                "MONTHLY",
                "BI_MONTHLY",
                "QUARTERLY",
                "BI_ANNUAL",
                "YEARLY"
            ]
        },
        "LeasePaymentInvoicePeriod": {
            "name": "LeasePaymentInvoicePeriod",
            "values": [
                "CONTRACT_PERIOD",
                "CALENDAR_PERIOD",
                "CUSTOM_PERIOD"
            ]
        },
        "LeaseRentalGuarantyType": {
            "name": "LeaseRentalGuarantyType",
            "values": [
                "CASH",
                "BANK",
                "CPAS",
                "BANK_BLOCKED"
            ]
        },
        "LeaseStatus": {
            "name": "LeaseStatus",
            "values": [
                "Draft",
                "OutForSignature",
                "Active",
                "Rejected",
                "Ended",
                "Cancelled"
            ]
        },
        "LeaseType": {
            "name": "LeaseType",
            "values": [
                "MAIN_RESIDENCE_9_YEARS",
                "MAIN_RESIDENCE_SHORT",
                "MAIN_RESIDENCE_LONG",
                "MAIN_RESIDENCE_INFINITE",
                "COMMERCIAL",
                "STUDENT",
                "FLATSHARING",
                "PARKING"
            ]
        },
        "LeaseVariousOperationType": {
            "name": "LeaseVariousOperationType",
            "values": [
                "DISCOUNT",
                "CHARGE",
                "SERVICE"
            ]
        },
        "MortgageRateType": {
            "name": "MortgageRateType",
            "values": [
                "Fixed",
                "Variable"
            ]
        },
        "MutationUserInputUpdatedBy": {
            "name": "MutationUserInputUpdatedBy",
            "values": [
                "OWNER"
            ]
        },
        "NearbyCommoditiesSubTypes": {
            "name": "NearbyCommoditiesSubTypes",
            "values": [
                "SCHOOL",
                "PRIMARY_SCHOOL",
                "SECONDARY_SCHOOL",
                "UNIVERSITY",
                "BUS_STATION",
                "SUBWAY_STATION",
                "TRAIN_STATION",
                "LIGHT_RAIL_STATION",
                "SUPERMARKET",
                "RESTAURANT"
            ]
        },
        "NearbyCommoditiesTypes": {
            "name": "NearbyCommoditiesTypes",
            "values": [
                "RESTAURANT",
                "SUPERMARKET",
                "TRANSPORTS",
                "SCHOOL",
                "HEALTHCARE"
            ]
        },
        "NotificationCreation": {
            "name": "NotificationCreation",
            "values": [
                "Automatic",
                "Manual"
            ]
        },
        "NotificationStatus": {
            "name": "NotificationStatus",
            "values": [
                "Active",
                "Snoozed",
                "Ignored",
                "Done"
            ]
        },
        "NotificationType": {
            "name": "NotificationType",
            "values": [
                "PebCertificate",
                "SmokeDetectorBattery",
                "FuelTankCertificate",
                "HeatingCertificate",
                "ChimneySweeping",
                "Custom"
            ]
        },
        "PostingChargeType": {
            "name": "PostingChargeType",
            "values": [
                "OWNER",
                "TENANT",
                "OWNER_VACANCY"
            ]
        },
        "PostingForeignType": {
            "name": "PostingForeignType",
            "values": [
                "TRANSACTION_RECONCILIATION"
            ]
        },
        "PostingStatus": {
            "name": "PostingStatus",
            "values": [
                "PAID_BY_OWNER",
                "TO_BE_PAID_BY_TENANT",
                "TO_BE_PAID_BY_OWNER"
            ]
        },
        "PostingType": {
            "name": "PostingType",
            "values": [
                "CREDIT",
                "DEBIT"
            ]
        },
        "PublishingPlatform": {
            "name": "PublishingPlatform",
            "values": [
                "LOGIC_IMMO"
            ]
        },
        "RemittanceType": {
            "name": "RemittanceType",
            "values": [
                "structured",
                "unstructured"
            ]
        },
        "RepartitionKeyType": {
            "name": "RepartitionKeyType",
            "values": [
                "CONSUMPTION_BASED_ON_SHARES",
                "CONSUMPTION_BASED_ON_USAGE",
                "SHARES",
                "SURFACE",
                "CUSTOM"
            ]
        },
        "SendingSourceErrorType": {
            "name": "SendingSourceErrorType",
            "values": [
                "ALREADY_EXISTS",
                "CRASH",
                "TIME",
                "SES",
                "OTHER"
            ]
        },
        "SendingSourceStatus": {
            "name": "SendingSourceStatus",
            "values": [
                "ERROR",
                "NONE",
                "PENDING",
                "VERIFIED"
            ]
        },
        "SendingSourceVerifiedType": {
            "name": "SendingSourceVerifiedType",
            "values": [
                "DEFAULT",
                "NONE"
            ]
        },
        "SignatureDocumentStatus": {
            "name": "SignatureDocumentStatus",
            "values": [
                "DRAFT",
                "PENDING",
                "REJECTED",
                "SIGNED",
                "ERROR"
            ]
        },
        "SignatureDocumentType": {
            "name": "SignatureDocumentType",
            "values": [
                "DOCUMENT_TO_SIGN",
                "REGISTERED_LETTER",
                "LETTER"
            ]
        },
        "SignatureType": {
            "name": "SignatureType",
            "values": [
                "EID",
                "ITSME",
                "SMSOTP",
                "EMAILOTP",
                "MANUAL"
            ]
        },
        "StatementSettingType": {
            "name": "StatementSettingType",
            "values": [
                "OWNER",
                "LEASE"
            ]
        },
        "StatementSource": {
            "name": "StatementSource",
            "values": [
                "AUTOMATIC",
                "MANUAL"
            ]
        },
        "StatementStatus": {
            "name": "StatementStatus",
            "values": [
                "TO_VALIDATE",
                "DONE"
            ]
        },
        "StatementType": {
            "name": "StatementType",
            "values": [
                "CHARGES",
                "FEES",
                "RE_PAYMENT"
            ]
        },
        "TankState": {
            "name": "TankState",
            "values": [
                "NEW",
                "EXISTING"
            ]
        },
        "TankType": {
            "name": "TankType",
            "values": [
                "AERIAL",
                "UNDERGROUND"
            ]
        },
        "TankWallType": {
            "name": "TankWallType",
            "values": [
                "SIMPLE",
                "DOUBLE"
            ]
        },
        "TechnicType": {
            "name": "TechnicType",
            "values": [
                "UTILITY_PROVIDER",
                "PEB",
                "DETECTOR",
                "HEATING",
                "CHIMNEY",
                "FUELTANK",
                "LIFT"
            ]
        },
        "TemplateSubType": {
            "name": "TemplateSubType",
            "values": [
                "ACCOUNTING_EXPORT",
                "FIXTURES_ENTRANCE",
                "FIXTURES_EXIT",
                "FOOTER",
                "FURNITURES_ENTRANCE",
                "FURNITURES_EXIT",
                "HEADER",
                "MAIN_RESIDENCE_9_YEARS",
                "REPORT",
                "DOCUMENT_SIGNATURE_REMINDER",
                "DOCUMENT_SIGNED",
                "LEASE_ALLOW_PROFESSIONAL_ACTIVITY_ON",
                "LEASE_BANKING_INFO_UPDATE",
                "LEASE_CHARGES_ADJUSTMENT",
                "LEASE_CONTRACT_EXTENDED",
                "LEASE_CONTRACT_REJECTED",
                "LEASE_CONTRACT_RETRACTED",
                "LEASE_CONTRACT_REVOKED",
                "LEASE_CONTRACT_SIGNATURE_REMINDER",
                "LEASE_CONTRACT_SIGNED",
                "LEASE_CREDIT_NOTE",
                "LEASE_DISCOUNT_CREATED",
                "LEASE_DISCOUNT_DELETED",
                "LEASE_DISCOUNT_UPDATED",
                "LEASE_OPERATION_CREATED",
                "LEASE_OPERATION_DELETED",
                "LEASE_OPERATION_PAYMENT_REQUEST",
                "LEASE_OPERATION_UPDATED",
                "LEASE_PAYMENT_RECEIPT",
                "LEASE_PAYMENT_REQUEST",
                "LEASE_RENT_INDEXATION",
                "LEASE_RENT_INDEXATION_SKIPPED",
                "LEASE_RENTAL_ADAPTATION",
                "LEASE_TENANT_STATEMENT",
                "LEASE_UNPAID_AMOUNT_FORMAL_NOTICE",
                "LEASE_UNPAID_AMOUNT_REMINDER",
                "LEASE_UNPAID_AMOUNT_SECOND_REMINDER",
                "OWNER_STATEMENT",
                "LEASE_TERMINATION"
            ]
        },
        "TemplateType": {
            "name": "TemplateType",
            "values": [
                "MAIL",
                "CONTRACT",
                "CONTRACT_DOCUMENT",
                "LETTER",
                "SMS",
                "WHATSAPP"
            ]
        },
        "TicketSourceType": {
            "name": "TicketSourceType",
            "values": [
                "APP",
                "MOBILE",
                "MAIL",
                "WEB"
            ]
        },
        "TicketStatus": {
            "name": "TicketStatus",
            "values": [
                "URGENT",
                "PENDING",
                "RESOLVED",
                "ARCHIVED"
            ]
        },
        "TrackingProvider": {
            "name": "TrackingProvider",
            "values": [
                "BPOST"
            ]
        },
        "TransactionLinkType": {
            "name": "TransactionLinkType",
            "values": [
                "CONTACT",
                "LEASE"
            ]
        },
        "TransactionStatus": {
            "name": "TransactionStatus",
            "values": [
                "TO_RECONCILE",
                "PARTIALLY_RECONCILED",
                "RECONCILED",
                "EXCLUDED",
                "IGNORE"
            ]
        },
        "UnitEventType": {
            "name": "UnitEventType",
            "values": [
                "OWN_USE",
                "WORK"
            ]
        },
        "UnitInventoryRoomType": {
            "name": "UnitInventoryRoomType",
            "values": [
                "ENTRANCE",
                "LIVING",
                "DINING",
                "KITCHEN",
                "LIVING_WITH_OPEN_KITCHEN",
                "OFFICE",
                "BEDROOM",
                "BATHROOM",
                "BALCONY",
                "SHOWER",
                "TOILET",
                "CELLAR",
                "ATTIC",
                "GARDEN",
                "TERRACE",
                "PATIO",
                "GARAGE",
                "PARKING",
                "OTHER",
                "REUNION",
                "TECHNICAL",
                "INDIVIDUAL_OFFICE",
                "OPENSPACE",
                "WAREHOUSE"
            ]
        },
        "UnitInventoryStructureType": {
            "name": "UnitInventoryStructureType",
            "values": [
                "GENERAL",
                "EQUIPMENT",
                "FURNITURE",
                "OTHER"
            ]
        },
        "UnitType": {
            "name": "UnitType",
            "values": [
                "RESIDENTIAL",
                "STUDENT",
                "COMMERCIAL",
                "OFFICE",
                "PARKING",
                "OTHER"
            ]
        },
        "UserRightAccessType": {
            "name": "UserRightAccessType",
            "values": [
                "READ",
                "WRITE",
                "DELETE"
            ]
        },
        "UserRightSection": {
            "name": "UserRightSection",
            "values": [
                "NOTIFICATIONS_DETAILS",
                "COMMUNICATIONS_DETAILS",
                "CONTACTS_DETAILS",
                "TICKETS_DETAILS",
                "ADVERTISEMENTS_DETAILS",
                "TEMPLATES_CONTRACTS",
                "TEMPLATES_COMMUNICATIONS",
                "FINANCIAL_TRANSACTIONS",
                "FINANCIAL_OCR",
                "FINANCIAL_MORTGAGES",
                "FINANCIAL_VALUATIONS_AND_COSTS",
                "LEASES_CREATION",
                "LEASES_SIGNATURE",
                "LEASES_DETAILS",
                "INVENTORY_OF_FIXTURES_CREATION",
                "FURNITURES_INVENTORY_CREATION",
                "BUILDINGS_UNITS_DETAILS",
                "BUILDINGS_UNITS_UTILITY_PROVIDERS",
                "BUILDINGS_UNITS_TECHNICS",
                "AGENCY_RATE",
                "SETTINGS_AUTOMATION",
                "SETTINGS_MANAGEMENT"
            ]
        },
        "UserRightChangeEvent": {
            "name": "UserRightChangeEvent",
            "values": [
                "DELETE_TENANT_LEASE_ACCESS",
                "DELETE_OWNER_UNIT_ACCESS",
                "DELETE_OWNER_BUILDING_ACCESS"
            ]
        },
        "UserStatus": {
            "name": "UserStatus",
            "values": [
                "DISABLED",
                "JOINED",
                "NOT_INVITED",
                "PENDING"
            ]
        },
        "UtilityReadingReason": {
            "name": "UtilityReadingReason",
            "values": [
                "LEASE_START",
                "LEASE_END",
                "NEW_SUPPLIER",
                "CONSUMPTION_TRACKING",
                "MONTHLY_READING",
                "QUARTERLY_READING",
                "YEARLY_READING",
                "RENOVATION",
                "OWN_USE_START",
                "OWN_USE_END",
                "OTHER"
            ]
        },
        "UtilityType": {
            "name": "UtilityType",
            "values": [
                "GAS",
                "WATER",
                "ELECTRICITY"
            ]
        },
        "ValuationSourceType": {
            "name": "ValuationSourceType",
            "values": [
                "MANUAL",
                "PROFESSIONAL"
            ]
        },
        "VariousOperationType": {
            "name": "VariousOperationType",
            "values": [
                "ONE_TIME",
                "END_WITH_LEASE",
                "RECURRING"
            ]
        }
    },
    "nonModels": {
        "AdvertisementDescription": {
            "name": "AdvertisementDescription",
            "fields": {
                "language": {
                    "name": "language",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "AdvertisementPostingMutationStatus": {
            "name": "AdvertisementPostingMutationStatus",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "statusList": {
                    "name": "statusList",
                    "isArray": true,
                    "type": {
                        "nonModel": "AdvertisementPostingStatus"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "errorMessage": {
                    "name": "errorMessage",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "AdvertisementPostingStatus": {
            "name": "AdvertisementPostingStatus",
            "fields": {
                "publishingPlatform": {
                    "name": "publishingPlatform",
                    "isArray": false,
                    "type": {
                        "enum": "PublishingPlatform"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "successCode": {
                    "name": "successCode",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "AgencyRateAmountDetail": {
            "name": "AgencyRateAmountDetail",
            "fields": {
                "amountType": {
                    "name": "amountType",
                    "isArray": false,
                    "type": {
                        "enum": "AgencyRateAmountDetailType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "endAmount": {
                    "name": "endAmount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "feeApplied": {
                    "name": "feeApplied",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "startAmount": {
                    "name": "startAmount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "AgencyRateStatementAmount": {
            "name": "AgencyRateStatementAmount",
            "fields": {
                "unitId": {
                    "name": "unitId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "amountRateable": {
                    "name": "amountRateable",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "fixedAmount": {
                    "name": "fixedAmount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "BlockedEmail": {
            "name": "BlockedEmail",
            "fields": {
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "bounceType": {
                    "name": "bounceType",
                    "isArray": false,
                    "type": {
                        "enum": "BounceType"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "CommunicationFrequencySetting": {
            "name": "CommunicationFrequencySetting",
            "fields": {
                "dateFrom": {
                    "name": "dateFrom",
                    "isArray": false,
                    "type": {
                        "enum": "CommunicationFrequencySettingPeriodDateFrom"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "periodInterval": {
                    "name": "periodInterval",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "periodType": {
                    "name": "periodType",
                    "isArray": false,
                    "type": {
                        "enum": "CommunicationFrequencySettingPeriodType"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "CommunicationSettings": {
            "name": "CommunicationSettings",
            "fields": {
                "channel": {
                    "name": "channel",
                    "isArray": false,
                    "type": {
                        "enum": "CommunicationChannel"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "communicationType": {
                    "name": "communicationType",
                    "isArray": false,
                    "type": {
                        "enum": "CommunicationType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "daysBeforeDueDate": {
                    "name": "daysBeforeDueDate",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "daysOverdue": {
                    "name": "daysOverdue",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "frequency": {
                    "name": "frequency",
                    "isArray": false,
                    "type": {
                        "nonModel": "CommunicationFrequencySetting"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "sendingRule": {
                    "name": "sendingRule",
                    "isArray": false,
                    "type": {
                        "enum": "CommunicationSendingRule"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "recipients": {
                    "name": "recipients",
                    "isArray": true,
                    "type": {
                        "enum": "CommunicationSettingRecipient"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "recipientsInBcc": {
                    "name": "recipientsInBcc",
                    "isArray": true,
                    "type": {
                        "enum": "CommunicationSettingRecipient"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "recipientsInCopy": {
                    "name": "recipientsInCopy",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "timeFrame": {
                    "name": "timeFrame",
                    "isArray": true,
                    "type": {
                        "enum": "CommunicationTimeFrameSetting"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "CustomLabel": {
            "name": "CustomLabel",
            "fields": {
                "language": {
                    "name": "language",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "label": {
                    "name": "label",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "CustomValue": {
            "name": "CustomValue",
            "fields": {
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "DNSRecord": {
            "name": "DNSRecord",
            "fields": {
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "GenerateCommunicationPdfResult": {
            "name": "GenerateCommunicationPdfResult",
            "fields": {
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "GenerateReportResult": {
            "name": "GenerateReportResult",
            "fields": {
                "errorCode": {
                    "name": "errorCode",
                    "isArray": false,
                    "type": {
                        "enum": "GenerateReportErrorCode"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "IntegrationConfiguration": {
            "name": "IntegrationConfiguration",
            "fields": {
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "JointOwner": {
            "name": "JointOwner",
            "fields": {
                "contactId": {
                    "name": "contactId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "contactPerson": {
                    "name": "contactPerson",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "LeaseActionHistoryDetail": {
            "name": "LeaseActionHistoryDetail",
            "fields": {
                "unitId": {
                    "name": "unitId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "newEndDate": {
                    "name": "newEndDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "newStartDate": {
                    "name": "newStartDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "previousEndDate": {
                    "name": "previousEndDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "previousStartDate": {
                    "name": "previousStartDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "LeasePriceHistoryDetail": {
            "name": "LeasePriceHistoryDetail",
            "fields": {
                "unitId": {
                    "name": "unitId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "indexationBaseIndex": {
                    "name": "indexationBaseIndex",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "indexationNewIndex": {
                    "name": "indexationNewIndex",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "previousRentalPrice": {
                    "name": "previousRentalPrice",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "newRentalPrice": {
                    "name": "newRentalPrice",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "rentalStatus": {
                    "name": "rentalStatus",
                    "isArray": false,
                    "type": {
                        "enum": "LeasePriceHistoryStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "previousMonthlyChargesPrice": {
                    "name": "previousMonthlyChargesPrice",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "newMonthlyChargesPrice": {
                    "name": "newMonthlyChargesPrice",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "monthlyChargesStatus": {
                    "name": "monthlyChargesStatus",
                    "isArray": false,
                    "type": {
                        "enum": "LeasePriceHistoryStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "monthlyChargesType": {
                    "name": "monthlyChargesType",
                    "isArray": false,
                    "type": {
                        "enum": "LeaseMonthlyChargesType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "optimalNewRentalPrice": {
                    "name": "optimalNewRentalPrice",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "optimalNewMonthlyChargesPrice": {
                    "name": "optimalNewMonthlyChargesPrice",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "epb": {
                    "name": "epb",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "epbIssueDate": {
                    "name": "epbIssueDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "oldestIndexForCorrectionFactorCalculation": {
                    "name": "oldestIndexForCorrectionFactorCalculation",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "newestIndexForCorrectionFactorCalculation": {
                    "name": "newestIndexForCorrectionFactorCalculation",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "rentIndexedPriceAfterNewEpbReduction": {
                    "name": "rentIndexedPriceAfterNewEpbReduction",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "chargesIndexedPriceAfterNewEpbReduction": {
                    "name": "chargesIndexedPriceAfterNewEpbReduction",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "correctionFactor": {
                    "name": "correctionFactor",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "reductionPercentage": {
                    "name": "reductionPercentage",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "rentDifference": {
                    "name": "rentDifference",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "rentReduction": {
                    "name": "rentReduction",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "indexedRentAfterReduction": {
                    "name": "indexedRentAfterReduction",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "chargesDifference": {
                    "name": "chargesDifference",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "chargesReduction": {
                    "name": "chargesReduction",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "indexedChargesAfterReduction": {
                    "name": "indexedChargesAfterReduction",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "previousYearIndexationNewIndex": {
                    "name": "previousYearIndexationNewIndex",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "previousYearIndexedRent": {
                    "name": "previousYearIndexedRent",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "previousYearIndexedCharges": {
                    "name": "previousYearIndexedCharges",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "LeaseInventoryFurnitureExitIssue": {
            "name": "LeaseInventoryFurnitureExitIssue",
            "fields": {
                "quantity": {
                    "name": "quantity",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "issue": {
                    "name": "issue",
                    "isArray": false,
                    "type": {
                        "enum": "LeaseInventoryFurnitureExitIssueType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "action": {
                    "name": "action",
                    "isArray": false,
                    "type": {
                        "enum": "LeaseInventoryFurnitureExitIssueAction"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "penalty": {
                    "name": "penalty",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "LeaseInventoryUnitKey": {
            "name": "LeaseInventoryUnitKey",
            "fields": {
                "keyType": {
                    "name": "keyType",
                    "isArray": false,
                    "type": {
                        "enum": "LeaseInventoryUnitKeyType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "keyId": {
                    "name": "keyId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "LeaseVariousOperationHistory": {
            "name": "LeaseVariousOperationHistory",
            "fields": {
                "periodFrom": {
                    "name": "periodFrom",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "periodTo": {
                    "name": "periodTo",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "amount": {
                    "name": "amount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "MutationStatus": {
            "name": "MutationStatus",
            "fields": {
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "NearbyComodity": {
            "name": "NearbyComodity",
            "fields": {
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "NearbyCommoditiesTypes"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "distance": {
                    "name": "distance",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "OwnerFinancialInformation": {
            "name": "OwnerFinancialInformation",
            "fields": {
                "rentReceived": {
                    "name": "rentReceived",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "chargesReceived": {
                    "name": "chargesReceived",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "turnover": {
                    "name": "turnover",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "claimOfTenants": {
                    "name": "claimOfTenants",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "occupancyRate": {
                    "name": "occupancyRate",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "averagePaymentTimes": {
                    "name": "averagePaymentTimes",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "averageRent": {
                    "name": "averageRent",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "tenantWithBiggestRent": {
                    "name": "tenantWithBiggestRent",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "grossProfitability": {
                    "name": "grossProfitability",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "PhoneNumber": {
            "name": "PhoneNumber",
            "fields": {
                "countryCode": {
                    "name": "countryCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "number": {
                    "name": "number",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ProposedValue": {
            "name": "ProposedValue",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "confidence": {
                    "name": "confidence",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "PublishedPlatform": {
            "name": "PublishedPlatform",
            "fields": {
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": {
                        "enum": "PublishingPlatform"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "publishedDate": {
                    "name": "publishedDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "RepartitionValues": {
            "name": "RepartitionValues",
            "fields": {
                "unitId": {
                    "name": "unitId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "RepaymentStatementDetail": {
            "name": "RepaymentStatementDetail",
            "fields": {
                "invoiceId": {
                    "name": "invoiceId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "invoicePostingId": {
                    "name": "invoicePostingId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "transactionId": {
                    "name": "transactionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "periodFrom": {
                    "name": "periodFrom",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "periodTo": {
                    "name": "periodTo",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "class": {
                    "name": "class",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "totalAmount": {
                    "name": "totalAmount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "SignatureDocumentResult": {
            "name": "SignatureDocumentResult",
            "fields": {
                "openedDate": {
                    "name": "openedDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "signedDate": {
                    "name": "signedDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "signed": {
                    "name": "signed",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "signatureLink": {
                    "name": "signatureLink",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "rejectReason": {
                    "name": "rejectReason",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "lastSignInviteSentDate": {
                    "name": "lastSignInviteSentDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "trackingProvider": {
                    "name": "trackingProvider",
                    "isArray": false,
                    "type": {
                        "enum": "TrackingProvider"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "trackingCode": {
                    "name": "trackingCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "contactId": {
                    "name": "contactId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "SignaturePosition": {
            "name": "SignaturePosition",
            "fields": {
                "top": {
                    "name": "top",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "left": {
                    "name": "left",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "page": {
                    "name": "page",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "width": {
                    "name": "width",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "height": {
                    "name": "height",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "contactId": {
                    "name": "contactId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "StatusResult": {
            "name": "StatusResult",
            "fields": {
                "success": {
                    "name": "success",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "message": {
                    "name": "message",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TechnicMaintenanceHistory": {
            "name": "TechnicMaintenanceHistory",
            "fields": {
                "date": {
                    "name": "date",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "contactId": {
                    "name": "contactId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "fileId": {
                    "name": "fileId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TransactionLink": {
            "name": "TransactionLink",
            "fields": {
                "amount": {
                    "name": "amount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "linkId": {
                    "name": "linkId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "linkType": {
                    "name": "linkType",
                    "isArray": false,
                    "type": {
                        "enum": "TransactionLinkType"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "UserRight": {
            "name": "UserRight",
            "fields": {
                "access": {
                    "name": "access",
                    "isArray": true,
                    "type": {
                        "enum": "UserRightAccessType"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "section": {
                    "name": "section",
                    "isArray": false,
                    "type": {
                        "enum": "UserRightSection"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "UtilityHistory": {
            "name": "UtilityHistory",
            "fields": {
                "reading": {
                    "name": "reading",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "date": {
                    "name": "date",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "reason": {
                    "name": "reason",
                    "isArray": false,
                    "type": {
                        "enum": "UtilityReadingReason"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        }
    },
    "codegenVersion": "3.4.4",
    "version": "21ab6cf71c914232d5a35e64aa1150d9"
};