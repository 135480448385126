/* eslint-disable no-promise-executor-return */
/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-param-last */
import {
  ChangeProfessionalActivityForLeaseInput,
  ChangeProfessionalActivityForLeaseMutation,
  ChangeProfessionalActivityForLeaseMutationVariables,
  Contact,
  ContactType,
  CreateLeaseInput as CreateInput,
  CreateLeaseAdditionalClauseInput,
  CreateLeaseAdditionalClauseMutationVariables,
  CreateLeaseMutationVariables as CreateMutationVariables,
  DeepDeletableEntityType,
  DeleteEntityByIdMutationVariables,
  DeleteLeaseMutationVariables as DeleteMutationVariables,
  File as FileModel,
  Lease,
  LeaseAdditionalClause,
  LeaseContact,
  LeaseInventory,
  Model,
  MutationStatus,
  OnCreateLeaseSubscription,
  OnDeleteLeaseSubscription,
  OnUpdateLeaseSubscription,
  UnitLease,
  UpdateLeaseAdditionalClauseInput,
  changeProfessionalActivityForLease as changeProfessionalActivityForLeaseMutation,
  cleanInputCreate,
  cleanInputUpdate,
  createLeaseAdditionalClause as createLeaseAdditionalClauseMutation,
  createLease as createMutation,
  deleteEntityById as deleteEntityByIdMutation,
  deleteLeaseAdditionalClause as deleteLeaseAdditionalClauseMutation,
  deleteLease as deleteMutation,
  getLeaseAdditionalClause as getLeaseAdditionalClauseQuery,
  getLease as getQuery,
  getReadId,
  getTableClientId,
  isNilOrEmpty,
  onCreateLease,
  onDeleteLease,
  onUpdateLease,
  resolveManyToMany,
  resolveOneToMany,
  syncLeaseAdditionalClauses,
  syncLeases as syncQuery,
  uniqueObjectWithIdAppend,
  updateLeaseAdditionalClause as updateLeaseAdditionalClauseMutation,
  updateLease as updateMutation,
} from '@rentguru/commons-utils';
import {
  cleanSyncQueries,
  deleteAndHideEntityWithFetchBefore,
  deleteEntityWithFetchBefore,
  get,
  getFilterFieldNameForIndex,
  list,
  mutation,
  recordWasUpdated,
  useSubscriptions,
} from '@up2rent/fetch-utils';
import isNil from 'lodash/isNil';
import React, { useContext, useEffect, useMemo } from 'react';
import { useBuildings } from './BuildingsContext';
import { ContextLoaderAction, ContextLoaderStore, useContextLoader } from './ContextLoader';
import { LeaseContactContext, useLeaseContacts } from './LeaseContactContext';
import { UnitLeaseContext, useUnitLeases } from './UnitLeaseContext';
import { useUnits } from './UnitsContext';
import { useUser } from './UserContext';
import { usePermissions } from './utils/PermissionsContext';

const ENTITY_MODEL_NAME: Model = 'Lease';

export type ActionLease =
  | {
      type: 'SHOULD_FETCH_LEASE' | 'IS_FETCHING_LEASE';
    }
  | {
      type: 'ADD_LEASE';
      payload: { lease: Lease };
    }
  | {
      type: 'FETCHED_LEASE';
      payload: { leases: Lease[] };
    }
  | {
      type: 'UPDATE_LEASE';
      payload: { lease: Lease };
    }
  | {
      type: 'DELETE_LEASE';
      payload: { id: string };
    };

export interface LeaseContext {
  leases: LeaseExtended[];
  getLeaseFilesAndLease: (leaseId: string) => Promise<{ files: FileModel[]; lease: Lease }>;
  getLease: (id: string) => LeaseExtended | null | undefined;
  getLeaseFromUnitLease: (unitLeaseId: string) => LeaseExtended | null | undefined;
  createLease: (input: Omit<Lease, 'clientId' | 'readId'>) => Promise<Lease>;
  updateLease: (original: Lease, updates: Partial<Lease>) => Promise<Lease>;
  deleteLease: (id: string) => Promise<Lease | null>;
  createLeaseContact: LeaseContactContext['createLeaseContact'];
  updateLeaseContact: LeaseContactContext['updateLeaseContact'];
  deleteLeaseContact: LeaseContactContext['deleteLeaseContact'];
  createAdditionalClause: (
    input: Omit<LeaseAdditionalClause, 'id' | 'clientId' | 'readId'>
  ) => Promise<LeaseAdditionalClause>;
  getLeaseAdditionalClauses: (leaseId: string) => Promise<LeaseAdditionalClause[]>;
  updateAdditionalClause: (updates: UpdateLeaseAdditionalClauseInput) => Promise<LeaseAdditionalClause>;
  deleteAdditionalClause: (additionalClause: LeaseAdditionalClause) => Promise<LeaseAdditionalClause | null>;
  getLeaseInventory: (leaseInventoryId: string) => LeaseInventory | undefined;
  createUnitLease: UnitLeaseContext['createUnitLease'];
  updateUnitLease: UnitLeaseContext['updateUnitLease'];
  deleteUnitLease: UnitLeaseContext['deleteUnitLease'];
  getAllContactsOwnersFromLease: (leaseId: string) => Contact[];
  getUnitsOfLease: (leaseId: string) => UnitLease[];
  deepDeleteLease: (id: string) => Promise<MutationStatus | undefined>;
  changeProfessionalActivityForLease: (
    input: Omit<ChangeProfessionalActivityForLeaseInput, 'clientId' | 'userId'>
  ) => Promise<ChangeProfessionalActivityForLeaseMutation>;
  leasesLoading: boolean | undefined;
  leasesError: string | undefined;
  setFetchLease: () => void;
}

export interface LeaseExtended extends Lease {
  tenants: Contact[];
  guarantors: Contact[];
  lessors: Contact[];
}
export type LeaseExtendedWithOwner = LeaseExtended & { owner: Contact };

export const leaseReducerDelegation = (state: ContextLoaderStore, action: ContextLoaderAction): ContextLoaderStore => {
  switch (action.type) {
    case 'SHOULD_FETCH_LEASE':
      if (state.Lease.loading || state.Lease.shouldFetch) {
        return state;
      }
      return {
        ...state,
        Lease: { ...state.Lease, shouldFetch: true },
      };
    case 'IS_FETCHING_LEASE':
      if (state.Lease.loading) {
        return state;
      }
      return {
        ...state,
        Lease: { ...state.Lease, loading: true },
      };
    case 'FETCHED_LEASE':
      return {
        ...state,
        Lease: {
          ...state.Lease,
          data: action.payload.leases,
          loading: false,
          shouldFetch: false,
          lastFetch: new Date(),
        },
      };
    case 'ADD_LEASE':
      // Check if already present - If already added by this user or coming from another user
      if (state.Lease.data?.find((object) => object.id === action.payload.lease.id)) {
        return state;
      }

      return {
        ...state,
        Lease: {
          ...state.Lease,
          data: [...state.Lease.data, action.payload.lease],
        },
      };
    case 'UPDATE_LEASE':
      // No data
      if (isNilOrEmpty(state.Lease.data)) {
        return state;
      }

      // Already present and same object
      const currentObject = state.Lease.data?.find((object) => object.id === action.payload.lease.id);
      if (!currentObject) {
        return {
          ...state,
          Lease: {
            ...state.Lease,
            data: [...state.Lease.data, action.payload.lease],
          },
        };
      }
      if (!recordWasUpdated(currentObject, action.payload.lease)) {
        return state;
      }

      // Update
      return {
        ...state,
        Lease: {
          ...state.Lease,
          data: state.Lease.data.map((object) => {
            if (object.id === action.payload.lease.id) {
              return action.payload.lease;
            }
            return object;
          }),
        },
      };
    case 'DELETE_LEASE':
      if (isNilOrEmpty(state.Lease.data)) {
        return state;
      }
      return {
        ...state,
        Lease: {
          ...state.Lease,
          data: state.Lease.data.filter((object) => object.id !== action.payload.id),
        },
      };

    default:
      return state;
  }
};

const fetchLeases = async (clientId: string, additionalFilter?: object): Promise<Lease[]> => {
  return await list<Lease>(
    cleanSyncQueries(syncQuery),
    getFilterFieldNameForIndex('byClientId'),
    getTableClientId(clientId, ENTITY_MODEL_NAME),
    additionalFilter
  );
};

export const LeaseContext = React.createContext<LeaseContext | null>(null);

export const LeaseContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const {
    Lease: { data: leasesLoader, loading: leasesLoading, shouldFetch },
    LeaseContact: { data: leaseContactsLoader, loading: leaseContactsLoading },
    LeaseInventory: { data: leaseInventoriesLoader, loading: leaseInventoriesLoading },
    UnitLease: { data: unitLeases, loading: unitLeasesLoading },
    BankAccount: { data: bankAccounts, loading: bankAccountsLoading },
    dispatch: contextDispatch,
  } = useContextLoader();
  const { createLeaseContact, updateLeaseContact, deleteLeaseContact } = useLeaseContacts();
  const { createUnitLease, updateUnitLease, deleteUnitLease } = useUnitLeases();
  const { leasesCreationDelete } = usePermissions();
  const { units } = useUnits();
  const { buildings } = useBuildings();
  const { clientId, userId } = useUser();
  const loading =
    leasesLoading || leaseContactsLoading || leaseInventoriesLoading || unitLeasesLoading || bankAccountsLoading;

  const setFetchLease = () => {
    contextDispatch({ type: 'SHOULD_FETCH_LEASE' });
  };

  const getLeaseFilesAndLease = async (leaseId: string): Promise<{ files: FileModel[]; lease: Lease }> => {
    const leaseResult = await get<Lease>(getQuery, leaseId);

    return {
      files: leaseResult.files ?? [],
      lease: leaseResult,
    };
  };

  const leases = useMemo<LeaseExtended[]>(() => {
    if (loading) {
      return [];
    }
    const leasesReduced = leasesLoader.reduce((acc: LeaseExtended[], lease: Lease) => {
      const leaseContacts: LeaseContact[] = resolveManyToMany(lease, 'lease', leaseContactsLoader);
      const leaseContactsSplit = leaseContacts.reduce(
        (result: { tenants: Contact[]; guarantors: Contact[]; lessors: Contact[] }, leaseContact) => {
          if (typeof leaseContact.contact === 'undefined') {
            return result;
          }
          if (leaseContact.contactRole === ContactType.TENANT) {
            result.tenants.push(leaseContact.contact!);
          } else if (leaseContact.contactRole === ContactType.GUARANTOR) {
            result.guarantors.push(leaseContact.contact!);
          } else if (
            leaseContact.contactRole === ContactType.OWNER ||
            leaseContact.contactRole === ContactType.MEMBER
          ) {
            result.lessors.push(leaseContact.contact!);
          }
          return result;
        },
        { tenants: [], guarantors: [], lessors: [] }
      ) as { tenants: Contact[]; guarantors: Contact[]; lessors: Contact[] };
      const extendedLease = {
        ...lease,
        bankAccount: bankAccounts.find((b) => b.id === lease.bankAccountId),
        units: resolveManyToMany(lease, 'lease', unitLeases),
        leaseInventories: resolveOneToMany(lease.id, leaseInventoriesLoader, 'leaseId'),
        contacts: leaseContacts,
        tenants: leaseContactsSplit.tenants,
        guarantors: leaseContactsSplit.guarantors,
        lessors: leaseContactsSplit.lessors,
      };
      if (isNilOrEmpty(extendedLease.units)) {
        return acc;
      }
      acc.push(extendedLease);
      return acc;
    }, []);
    return leasesReduced;
  }, [leasesLoader, unitLeases, leaseContactsLoader, leaseInventoriesLoader, bankAccounts, loading]);

  useEffect(() => {
    const fetchAndSet = async () => {
      contextDispatch({ type: 'IS_FETCHING_LEASE' });
      const result = await fetchLeases(clientId!);
      contextDispatch({ type: 'FETCHED_LEASE', payload: { leases: result } });
    };
    if (shouldFetch) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch]);

  useSubscriptions<OnCreateLeaseSubscription, OnUpdateLeaseSubscription, OnDeleteLeaseSubscription>(
    onCreateLease,
    onUpdateLease,
    onDeleteLease,
    (data) => {
      contextDispatch({
        type: 'ADD_LEASE',
        payload: { lease: data.onCreateLease as Lease },
      });
    },
    (data) => {
      contextDispatch({
        type: 'UPDATE_LEASE',
        payload: { lease: data.onUpdateLease as Lease },
      });
    },
    (data) => {
      const { id } = data.onDeleteLease as Lease;
      contextDispatch({
        type: 'DELETE_LEASE',
        payload: { id },
      });
    }
  );

  const getLease = (id: string) => {
    if (leasesLoading) {
      return null;
    }
    return leases.find((lease) => lease.id === id);
  };

  const getLeaseFromUnitLease = (unitLeaseId: string) => {
    if (leasesLoading) {
      return null;
    }
    return leases.find((lease) => lease.units?.some((unitLease) => unitLease.id === unitLeaseId));
  };

  const createLease = async (input: Omit<Lease, 'clientId' | 'readId'>) => {
    const lease = await mutation<Lease, CreateMutationVariables>(createMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateInput),
        clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME),
        readId: getReadId(clientId!, ENTITY_MODEL_NAME),
      },
    });
    contextDispatch({ type: 'ADD_LEASE', payload: { lease } });
    return lease;
  };

  const updateLease = async (original: Lease, updates: Partial<Lease>) => {
    const result = await mutation<Lease>(updateMutation, {
      input: { ...cleanInputUpdate({ id: original.id, _version: original._version, ...updates }, false) },
    });
    contextDispatch({ type: 'UPDATE_LEASE', payload: { lease: result } });
    return result;
  };

  const deleteLease = async (id: string) => {
    const lease = getLease(id)!;
    if (!leasesCreationDelete) {
      return null;
    }
    await deleteEntityWithFetchBefore<Pick<Lease, 'id'>, DeleteMutationVariables>({ id }, getQuery, deleteMutation);
    contextDispatch({ type: 'DELETE_LEASE', payload: { id } });
    return lease;
  };

  const createAdditionalClause = async (
    input: Omit<LeaseAdditionalClause, 'id' | 'clientId' | 'readId'>
  ): Promise<LeaseAdditionalClause> => {
    const { lease, ...rest } = input;
    const leaseAdditionalClause = await mutation<LeaseAdditionalClause, CreateLeaseAdditionalClauseMutationVariables>(
      createLeaseAdditionalClauseMutation,
      {
        input: {
          ...(cleanInputCreate(rest) as CreateLeaseAdditionalClauseInput),
          readId: getReadId(clientId!, 'LeaseAdditionalClause'),
          clientId: getTableClientId(clientId!, 'LeaseAdditionalClause'),
          leaseId: lease!.id,
        },
      }
    );
    return leaseAdditionalClause;
  };

  const updateAdditionalClause = async (updates: UpdateLeaseAdditionalClauseInput): Promise<LeaseAdditionalClause> => {
    const result = await mutation<LeaseAdditionalClause>(updateLeaseAdditionalClauseMutation, {
      input: { ...cleanInputUpdate(updates) },
    });
    return result;
  };

  const deleteAdditionalClause = async (
    additionalClause: LeaseAdditionalClause
  ): Promise<LeaseAdditionalClause | null> => {
    if (!leasesCreationDelete) {
      return null;
    }
    const result = await deleteAndHideEntityWithFetchBefore<LeaseAdditionalClause>(
      additionalClause,
      getLeaseAdditionalClauseQuery,
      deleteLeaseAdditionalClauseMutation,
      updateLeaseAdditionalClauseMutation
    );
    return result;
  };

  const getLeaseAdditionalClauses = async (leaseId: string): Promise<LeaseAdditionalClause[]> => {
    return await list<LeaseAdditionalClause>(syncLeaseAdditionalClauses, 'leaseId', leaseId);
  };

  const getUnitsOfLease = (leaseId: string) => {
    const currentLease = leases.find((lease) => lease.id === leaseId);
    return currentLease?.units ?? [];
  };

  const getAllContactsOwnersFromLease = (leaseId: string): Contact[] => {
    const lease = leases.find((l) => l.id === leaseId);
    if (isNil(lease)) return [];
    let leaseContacts: Contact[] = [];
    lease.units!.forEach((leaseUnit: UnitLease) => {
      const completeUnit = units.find((u) => u.id === leaseUnit.unit!.id);
      if (!isNil(completeUnit)) {
        // Add the owners of the units to the contact list
        leaseContacts = uniqueObjectWithIdAppend(
          leaseContacts,
          completeUnit.owners!.map((uo) => uo.owner!)
        );
        const completeBuilding = buildings.find((b) => b.id === completeUnit.building!.id);
        if (!isNil(completeBuilding)) {
          // Add the owners of the unit's buildings to the contact list
          leaseContacts = uniqueObjectWithIdAppend(
            leaseContacts,
            completeBuilding.owners!.map((bo) => bo.owner!)
          );
        }
      }
    });
    return leaseContacts;
  };

  const getLeaseInventory = (inventoryId: string) => {
    return leaseInventoriesLoader.find((li) => li.id === inventoryId);
  };

  const deepDeleteLease = async (id: string) => {
    if (!leasesCreationDelete) {
      return;
    }
    return await mutation<MutationStatus, DeleteEntityByIdMutationVariables>(deleteEntityByIdMutation, {
      input: { entityId: id, entity: DeepDeletableEntityType.LEASE },
    });
  };

  const changeProfessionalActivityForLease = async (
    input: Omit<ChangeProfessionalActivityForLeaseInput, 'clientId' | 'userId'>
  ): Promise<ChangeProfessionalActivityForLeaseMutation> => {
    const response = await mutation<
      ChangeProfessionalActivityForLeaseMutation,
      ChangeProfessionalActivityForLeaseMutationVariables
    >(changeProfessionalActivityForLeaseMutation, {
      input: {
        ...(cleanInputCreate(input) as ChangeProfessionalActivityForLeaseInput),
        clientId: clientId!,
        userId: userId!,
      },
    });
    return response;
  };

  const values = useMemo(
    () => ({
      leases,
      getUnitsOfLease,
      getLeaseFilesAndLease,
      getLease,
      getLeaseFromUnitLease,
      createLease,
      updateLease,
      deleteLease,
      createLeaseContact,
      updateLeaseContact,
      deleteLeaseContact,
      createAdditionalClause,
      getLeaseAdditionalClauses,
      updateAdditionalClause,
      deleteAdditionalClause,
      createUnitLease,
      deleteUnitLease,
      getAllContactsOwnersFromLease,
      getLeaseInventory,
      deepDeleteLease,
      leasesError: undefined,
      updateUnitLease,
      changeProfessionalActivityForLease,
      leasesLoading: loading,
      setFetchLease,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [leases, loading]
  );

  return <LeaseContext.Provider value={values}>{children}</LeaseContext.Provider>;
};

export const useLeases = (): LeaseContext => {
  const context = useContext<LeaseContext | null>(LeaseContext);

  if (context === undefined) {
    throw new Error('`useLeases` hook must be used within a `LeaseContextProvider` component');
  }
  return context as LeaseContext;
};
