import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { formatDistance, isAfter } from 'date-fns';
import isNil from 'lodash/isNil';
import { TableCell, TableRow, Tooltip, Typography } from '@material-ui/core';
import {
  Contact,
  Notification,
  NotificationStatus,
  Technic,
  TechnicMaintenanceHistory,
  TechnicType,
  Colors,
  getContactNameFromObject,
  isNilOrEmpty,
} from '@rentguru/commons-utils';
import { useTechnics } from 'src/hooks/TechnicsContext';
import { useContacts } from 'src/hooks/ContactsContext';
import { useNotifications } from 'src/hooks/NotificationsContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { useLocale, dateLocaleMap, dateLocaleMapType } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as HistoryIcon } from 'src/icons/history.svg';
import { ReactComponent as TicketIcon } from 'src/icons/ticket.svg';
import { ReactComponent as CheckIcon } from 'src/icons/check.svg';
import { RouteDestination } from 'src/components/Routes/Routes';
import { CustomIconButton } from '@up2rent/ui';
import SnoozeMenu from 'src/components/Dashboard/DashboardTodos/DashboardDialogs/SnoozeMenu';
import { ROW_HEIGHT } from 'src/components/Dashboard/DashboardTodos/DashboardMaintenance';
import { stopPropagation } from 'src/components/Dashboard/Dashboard';

interface MaintenanceNotificationRowProps {
  notification: Notification;
  setNotificationToSnooze: (notification: Notification | null) => void;
  setTechnicToSolve: (technic: Technic | null) => void;
}

export const getTechnicContractorName = (technic: Technic, getContact: (id: string) => Contact | undefined) => {
  switch (technic.type) {
    case TechnicType.CHIMNEY:
    case TechnicType.FUELTANK:
    case TechnicType.HEATING:
      if (isNilOrEmpty(technic.maintenanceHistory)) return '';
      const latestMaintenanceWithContractor = technic.maintenanceHistory.reduce(
        (acc: TechnicMaintenanceHistory | null, history: TechnicMaintenanceHistory) => {
          if (!isNil(history.contactId)) {
            if (isNil(acc)) return history;
            return isAfter(new Date(history.date), new Date(acc.date)) ? history : acc;
          }
          return acc;
        },
        null
      );
      if (isNil(latestMaintenanceWithContractor)) return '';
      const contact = getContact(latestMaintenanceWithContractor.contactId!);
      return !isNil(contact) ? getContactNameFromObject(contact) : '';
    case TechnicType.DETECTOR:
    case TechnicType.PEB:
    case TechnicType.UTILITY_PROVIDER:
      return !isNil(technic.contact) ? getContactNameFromObject(technic.contact) : '';
    default:
      return '';
  }
};

const MaintenanceNotificationRow: React.FC<MaintenanceNotificationRowProps> = ({
  notification,
  setNotificationToSnooze,
  setTechnicToSolve,
}) => {
  const { ticketsDetailsWrite, buildingsUnitsTechnicsWrite, notificationsDetailsWrite } = usePermissions();
  const { formatMessage } = useIntl();
  const { getTechnic, technicsLoading } = useTechnics();
  const { getContact, contactsLoading } = useContacts();
  const { getUnit } = useUnits();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { updateNotification } = useNotifications();
  const history = useHistory();
  const { language } = useLocale();

  const technic = getTechnic(notification.technicId!);
  if (isNil(technic) || technicsLoading || contactsLoading) {
    return (
      <TableRow style={{ minHeight: ROW_HEIGHT, height: ROW_HEIGHT }}>
        <TableCell>
          <Typography>{formatMessage({ id: 'loading' })}</Typography>
        </TableCell>
      </TableRow>
    );
  }
  let destination: string = '';
  if (!isNil(technic.building)) {
    destination = `${RouteDestination.PROPERTIES}/${technic.building.id}/technics`;
  } else if (!isNil(technic.unit)) {
    const unit = getUnit(technic.unit.id);
    destination = `${RouteDestination.PROPERTIES}/${unit?.building?.id}/unit-${technic.unit.id}/technics`;
  }

  const onTicketIconClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    history.push({
      pathname: !isNil(notification.ticket)
        ? `${RouteDestination.TICKETS_DETAILS}/${notification.ticket.id}`
        : RouteDestination.ADD_TICKET,
      state: {
        goBackUrl: history.location,
        ...(!isNil(technic.building) && { buildingId: technic.building.id }),
        ...(!isNil(technic.unit) && { unitId: technic.unit.id }),
        notificationId: notification.id,
      },
    });
  };

  return (
    <TableRow
      hover
      style={{ cursor: 'pointer', textDecoration: 'none', minHeight: ROW_HEIGHT, height: ROW_HEIGHT }}
      onClick={() =>
        history.push({ pathname: destination, state: { goBackUrl: history.location, defaultTab: technic.type } })
      }
    >
      <TableCell align="left" padding="none" style={{ paddingLeft: 20 }}>
        <Typography style={{ color: Colors.CLASSICAL_BLACK, fontSize: 14, fontWeight: 700 }}>
          {formatMessage({ id: `enums.TechnicType.${technic.type}` })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14, fontWeight: 500 }}>
          {!isNil(technic.building)
            ? formatMessage({ id: 'rentalUnit.detail.general.building' })
            : formatMessage({ id: 'communications.list.rentalUnit' })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14, fontWeight: 500 }}>
          {!isNil(technic.building) ? technic.building.name : technic.unit!.name}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14, fontWeight: 500 }}>
          {getTechnicContractorName(technic, getContact)}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14, fontWeight: 500 }}>
          {!isNil(notification.expirationDate)
            ? formatDistance(new Date(notification.expirationDate), new Date(), {
                addSuffix: true,
                locale: (dateLocaleMap as dateLocaleMapType)[language],
              })
            : null}
        </Typography>
      </TableCell>
      {ticketsDetailsWrite && (
        <TableCell align="left" padding="none">
          <Tooltip
            title={
              !isNil(notification.ticket)
                ? formatMessage({ id: 'tickets.detail.contact' })
                : formatMessage({ id: 'dashboard.ticket.createTicket' })
            }
            placement="top"
          >
            <CustomIconButton
              onMouseDown={stopPropagation}
              onClick={onTicketIconClick}
              size="small"
              Icon={TicketIcon}
              iconStyle={{ fill: !isNil(notification.ticket) ? Colors.DODGER_BLUE : Colors.TOWER_GREY }}
            />
          </Tooltip>
        </TableCell>
      )}
      {notificationsDetailsWrite && (
        <TableCell align="left" padding="none">
          <Tooltip title={formatMessage({ id: 'dashboard.notifications.snooze' })} placement="top">
            <CustomIconButton
              onMouseDown={stopPropagation}
              onClick={(event) => {
                event.stopPropagation();
                setAnchorEl(event.currentTarget);
              }}
              size="small"
              Icon={HistoryIcon}
              iconStyle={{ fill: Colors.BLUEY }}
            />
          </Tooltip>
        </TableCell>
      )}
      {notificationsDetailsWrite && buildingsUnitsTechnicsWrite && (
        <TableCell align="left" padding="none">
          <Tooltip title={formatMessage({ id: 'dashboard.notifications.solve' })} placement="top">
            <CustomIconButton
              onMouseDown={stopPropagation}
              onClick={async (event) => {
                event.stopPropagation();
                setTechnicToSolve(technic);
              }}
              size="small"
              Icon={CheckIcon}
              iconStyle={{ fill: Colors.DODGER_BLUE }}
            />
          </Tooltip>
        </TableCell>
      )}
      <SnoozeMenu
        anchorEl={anchorEl}
        snoozeAction={async (snoozedDate: Date) => {
          await updateNotification(notification!, {
            popupDate: snoozedDate.toISOString(),
            status: NotificationStatus.Snoozed,
          });
        }}
        customSnooze={() => setNotificationToSnooze(notification)}
        onClose={() => setAnchorEl(null)}
      />
    </TableRow>
  );
};

export default MaintenanceNotificationRow;
