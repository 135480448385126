import { MessagesEN as messages } from '@rentguru/commons-utils';
import isNil from 'lodash/isNil';
import { IntlFormatters } from 'react-intl';

export const getTranslatedEnumFormatMessage = (formatMessage: IntlFormatters['formatMessage'], enumName?: string) => {
  if (isNil(formatMessage) || isNil(enumName)) {
    return [];
  }
  // @ts-ignore
  return Object.keys(messages.enums[enumName]).map((o) => {
    const value = o;
    const label = formatMessage({ id: `enums.${enumName}.${o}` });
    return { label, value };
  });
};
