import React, { useState } from 'react';
import { ElevatedPaper, ContentHeader , ActionButton } from '@up2rent/ui';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import { useUserRoles } from 'src/hooks/UserRolesContext';
import RoleRow from './Roles/RoleRow';
import AddUserRoleForm from './Roles/AddUserRoleForm';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { isNil } from 'lodash';
import EntityDetail from '../ui/EntityDetail/EntityDetail';
import { UserRole } from '@rentguru/commons-utils';
import GeneralDetails from './Roles/Details/GeneralDetails';
import { ExitableLocation, RouteDestination } from '../Routes/Routes';
import Dialog from 'src/components/ui/Dialog';

const RolesSettings: React.FC = () => {
  const { formatMessage } = useIntl();
  const { loading, error, userRoles } = useUserRoles();
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
  const history = useHistory();
  const matchLocal = useRouteMatch<{ roleId: string }>({
    path: `${RouteDestination.SETTINGS}/roles/:roleId?`,
  });
  const { state: exitParams } = useLocation<ExitableLocation>();

  const openDetail = !isNil(matchLocal) && !isNil(matchLocal.params) && !isNil(matchLocal.params.roleId);

  if (loading) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{formatMessage({ id: 'loading' })}</Typography>
      </ElevatedPaper>
    );
  }
  if (error) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{error}</Typography>
      </ElevatedPaper>
    );
  }

  const onCloseDialog = (userRole?: UserRole) => {
    setAddDialogOpen(false);
    if (!isNil(userRole)) {
      history.push(`${RouteDestination.SETTINGS}/roles/${userRole.id}/permissions`);
    }
  };

  const onRowClick = (userRole: UserRole) => {
    history.push(`${RouteDestination.SETTINGS}/roles/${userRole.id}/permissions`);
  };

  const handleCloseDetail = () => {
    if (!isNil(exitParams) && !isNil(exitParams.goBackUrl)) history.replace(exitParams.goBackUrl);
    else history.push(`${RouteDestination.SETTINGS}/roles`);
  };

  return (
    <>
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <ContentHeader title={formatMessage({ id: 'settings.roles.title' })}>
          <ActionButton onClick={() => setAddDialogOpen(true)}>{formatMessage({ id: 'add' })}</ActionButton>
        </ContentHeader>
        <div style={{ paddingLeft: 30, paddingRight: 30 }}>
          {userRoles.map((userRole, index) => (
            <RoleRow
              key={userRole.id}
              userRole={userRole}
              divider={index !== userRoles.length - 1}
              onClick={onRowClick}
            />
          ))}
        </div>
      </ElevatedPaper>
      <Dialog open={addDialogOpen} onClose={() => onCloseDialog()} scroll="body">
        <AddUserRoleForm onClose={onCloseDialog} />
      </Dialog>
      <EntityDetail open={openDetail} onClose={handleCloseDetail}>
        <GeneralDetails userRoleId={!isNil(matchLocal) ? matchLocal.params.roleId : ''} />
      </EntityDetail>
    </>
  );
};

export default RolesSettings;
