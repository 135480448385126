/* eslint-disable no-redeclare */
import React, { useContext, useEffect, useMemo } from 'react';
import { useUser } from './UserContext';
import {
  syncBankAccounts as syncQuery,
  getBankAccount as getQuery,
  createBankAccount as createMutation,
  updateBankAccount as updateMutation,
  deleteBankAccount as deleteMutation,
  CreateBankAccountMutationVariables as CreateMutationVariables,
  DeleteBankAccountMutationVariables as DeleteMutationVariables,
  CreateBankAccountInput as CreateInput,
  cleanInputCreate,
  cleanInputUpdate,
  getReadId,
  getTableClientId,
  BankAccount,
  isNilOrEmpty,
  Model,
  OnCreateBankAccountSubscription,
  OnUpdateBankAccountSubscription,
  OnDeleteBankAccountSubscription,
  onCreateBankAccount,
  onUpdateBankAccount,
  onDeleteBankAccount,
  getCleanBankAccountNumber,
} from '@rentguru/commons-utils';
import { ContextLoaderAction, ContextLoaderStore, useContextLoader } from './ContextLoader';
import { usePermissions } from './utils/PermissionsContext';
import {
  deleteEntityWithFetchBefore,
  getFilterFieldNameForIndex,
  list,
  mutation,
  recordWasUpdated,
  useSubscriptions,
} from '@up2rent/fetch-utils';

const ENTITY_MODEL_NAME: Model = 'BankAccount';

export type ActionBankAccount =
  | {
      type: 'SHOULD_FETCH_BANK_ACCOUNT' | 'IS_FETCHING_BANK_ACCOUNT';
    }
  | {
      type: 'ADD_BANK_ACCOUNT';
      payload: { bankAccount: BankAccount };
    }
  | {
      type: 'FETCHED_BANK_ACCOUNT';
      payload: { bankAccounts: BankAccount[] };
    }
  | {
      type: 'UPDATE_BANK_ACCOUNT';
      payload: { bankAccount: BankAccount };
    }
  | {
      type: 'DELETE_BANK_ACCOUNT';
      payload: { id: string };
    };

export interface BankAccountContext extends BankAccountState {
  createBankAccount: (input: Omit<BankAccount, 'id' | 'clientId' | 'readId'>) => Promise<BankAccount | undefined>;
  getBankAccount: (id: string) => BankAccount | null | undefined;
  updateBankAccount: (original: BankAccount, updates: Partial<BankAccount>) => Promise<BankAccount>;
  deleteBankAccount: (id: string) => Promise<BankAccount>;
  setFetchBankAccount: () => void;
}

interface BankAccountState {
  bankAccounts: BankAccount[];
  loading: boolean;
  shouldFetchBankAccount: boolean;
  error: string | null;
}

export const bankAccountReducerDelegation = (
  state: ContextLoaderStore,
  action: ContextLoaderAction
): ContextLoaderStore => {
  switch (action.type) {
    case 'SHOULD_FETCH_BANK_ACCOUNT':
      if (state.BankAccount.loading || state.BankAccount.shouldFetch) {
        return state;
      }
      return {
        ...state,
        BankAccount: { ...state.BankAccount, shouldFetch: true },
      };
    case 'IS_FETCHING_BANK_ACCOUNT':
      if (state.BankAccount.loading) {
        return state;
      }
      return {
        ...state,
        BankAccount: { ...state.BankAccount, loading: true },
      };
    case 'FETCHED_BANK_ACCOUNT':
      return {
        ...state,
        BankAccount: {
          ...state.BankAccount,
          data: action.payload.bankAccounts,
          loading: false,
          shouldFetch: false,
          lastFetch: new Date(),
        },
      };
    case 'ADD_BANK_ACCOUNT':
      // Check if already present - If already added by this user or coming from another user
      if (state.BankAccount.data?.find((object) => object.id === action.payload.bankAccount.id)) {
        return state;
      }

      return {
        ...state,
        BankAccount: {
          ...state.BankAccount,
          data: [...state.BankAccount.data, action.payload.bankAccount],
        },
      };
    case 'UPDATE_BANK_ACCOUNT':
      // No data
      if (isNilOrEmpty(state.BankAccount.data)) {
        return state;
      }

      // Already present and same object
      const currentObject = state.BankAccount.data?.find((object) => object.id === action.payload.bankAccount.id);
      if (!currentObject) {
        return {
          ...state,
          BankAccount: {
            ...state.BankAccount,
            data: [...state.BankAccount.data, action.payload.bankAccount],
          },
        };
      }
      if (!recordWasUpdated(currentObject, action.payload.bankAccount)) {
        return state;
      }

      // Update
      return {
        ...state,
        BankAccount: {
          ...state.BankAccount,
          data: state.BankAccount.data.map((object) => {
            if (object.id === action.payload.bankAccount.id) {
              return action.payload.bankAccount;
            }
            return object;
          }),
        },
      };
    case 'DELETE_BANK_ACCOUNT':
      if (isNilOrEmpty(state.BankAccount.data)) {
        return state;
      }
      return {
        ...state,
        BankAccount: {
          ...state.BankAccount,
          data: state.BankAccount.data.filter((object) => object.id !== action.payload.id),
        },
      };

    default:
      return state;
  }
};

const fetchBankAccounts = async (clientId: string, additionalFilter?: object): Promise<BankAccount[]> => {
  return await list<BankAccount>(
    syncQuery,
    getFilterFieldNameForIndex('byClientId'),
    getTableClientId(clientId, ENTITY_MODEL_NAME),
    additionalFilter
  );
};

export const BankAccountContext = React.createContext<BankAccountContext | null>(null);

export const BankAccountContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { clientId } = useUser();
  const {
    BankAccount: { data: bankAccountsLoader, loading: bankAccountsLoading, shouldFetch },
    Contact: { data: contactsLoader, loading: contactsLoading },
    dispatch: contextDispatch,
  } = useContextLoader();
  const { financialTransactionsDelete } = usePermissions();

  const setFetchBankAccount = () => {
    contextDispatch({ type: 'SHOULD_FETCH_BANK_ACCOUNT' });
  };

  const loading = bankAccountsLoading || contactsLoading;

  const bankAccounts = useMemo<BankAccount[]>(() => {
    if (loading) {
      return [];
    }

    const bankAccountsWithContact = bankAccountsLoader.map((bankAccount) => {
      const contact = contactsLoader.find((bankContact) => bankContact.id === bankAccount.contactId);
      return { ...bankAccount, contact };
    });
    return bankAccountsWithContact;
  }, [loading, bankAccountsLoader, contactsLoader]);

  useEffect(() => {
    const fetchAndSet = async () => {
      contextDispatch({ type: 'IS_FETCHING_BANK_ACCOUNT' });
      const result = await fetchBankAccounts(clientId!);
      contextDispatch({ type: 'FETCHED_BANK_ACCOUNT', payload: { bankAccounts: result } });
    };
    if (shouldFetch) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch]);

  const createBankAccount = async (
    input: Omit<BankAccount, 'id' | 'clientId' | 'readId'>
  ): Promise<BankAccount | undefined> => {
    const cleanNumber = getCleanBankAccountNumber(input.number);
    const bankAccountAlreadyExists = bankAccounts.some(
      (currentBankAccount) => getCleanBankAccountNumber(currentBankAccount.number) === cleanNumber
    );
    if (bankAccountAlreadyExists) return undefined;

    const bankAccount = await mutation<BankAccount, CreateMutationVariables>(createMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateInput),
        clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME),
        readId: getReadId(clientId!, ENTITY_MODEL_NAME),
      },
    });
    contextDispatch({ type: 'ADD_BANK_ACCOUNT', payload: { bankAccount } });
    return bankAccount;
  };

  useSubscriptions<OnCreateBankAccountSubscription, OnUpdateBankAccountSubscription, OnDeleteBankAccountSubscription>(
    onCreateBankAccount,
    onUpdateBankAccount,
    onDeleteBankAccount,
    (data) => {
      contextDispatch({
        type: 'ADD_BANK_ACCOUNT',
        payload: { bankAccount: data.onCreateBankAccount as BankAccount },
      });
    },
    (data) => {
      contextDispatch({
        type: 'UPDATE_BANK_ACCOUNT',
        payload: { bankAccount: data.onUpdateBankAccount as BankAccount },
      });
    },
    (data) => {
      const { id } = data.onDeleteBankAccount as BankAccount;
      contextDispatch({
        type: 'DELETE_BANK_ACCOUNT',
        payload: { id },
      });
    }
  );

  const getBankAccount = (id: string) => {
    if (loading) {
      return null;
    }
    return bankAccounts.find((stateObject) => stateObject.id === id);
  };

  const updateBankAccount = async (original: BankAccount, updates: Partial<BankAccount>): Promise<BankAccount> => {
    const result = await mutation<BankAccount>(updateMutation, {
      input: { ...cleanInputUpdate({ id: original.id, _version: original._version, ...updates }, false) },
    });
    contextDispatch({ type: 'UPDATE_BANK_ACCOUNT', payload: { bankAccount: result } });
    return result;
  };

  const deleteBankAccount = async (id: string): Promise<BankAccount> => {
    const bankAccount = getBankAccount(id)!;
    if (!financialTransactionsDelete) {
      return bankAccount;
    }

    await deleteEntityWithFetchBefore<Pick<BankAccount, 'id'>, DeleteMutationVariables>(
      { id },
      getQuery,
      deleteMutation
    );

    contextDispatch({ type: 'DELETE_BANK_ACCOUNT', payload: { id } });
    return bankAccount;
  };

  const values = useMemo(
    () => ({
      bankAccounts: bankAccounts as BankAccount[],
      createBankAccount,
      getBankAccount,
      updateBankAccount,
      deleteBankAccount,
      setFetchBankAccount,
      error: null,
      loading,
      shouldFetchBankAccount: shouldFetch,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bankAccounts, loading, shouldFetch]
  );

  return <BankAccountContext.Provider value={values}>{children}</BankAccountContext.Provider>;
};

export const useBankAccounts = (): BankAccountContext => {
  const context = useContext<BankAccountContext | null>(BankAccountContext);

  if (context === undefined) {
    throw new Error('`useBankAccounts` hook must be used within a `BankAccountContextProvider` component');
  }
  return context as BankAccountContext;
};
