/* eslint-disable @typescript-eslint/no-shadow */
import { Grid, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import isNil from 'lodash/isNil';
import React from 'react';
import { useIntl } from 'react-intl';
import { Lease, Colors } from '@rentguru/commons-utils';
import FullCustomComboBox, { CustomInputItemType } from '../ComboBox/FullCustomComboBox';
import { useCustomizedComboStyles } from '../ComboBox/TextComboBox';
import FormikComboBox from '../ComboBox/FormikFullCustomComboBox';
import { getSafeValueInObject } from 'src/utils/object';
import isEmpty from 'lodash/isEmpty';
import { format } from 'date-fns';
import { dateLocaleMap, useLocale } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as LeaseIcon } from '../../../icons/contract.svg';

interface SimpleLeaseSelectorProps {
  leases: Lease[];
  type?: 'controlled' | 'uncontrolled';
  onChange?: (lease: Lease | null) => void;
  defaultValue?: Lease;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  fieldName: string;
  inputWidth?: number;
  paperClass?: string;
  disabled?: boolean;
}

const SimpleLeaseSelector: React.FC<SimpleLeaseSelectorProps> = ({
  leases,
  type = 'uncontrolled',
  onChange,
  defaultValue,
  style,
  inputStyle,
  fieldName,
  inputWidth = 560,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const comboBoxClasses = useCustomizedComboStyles();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { values, errors, touched } = useFormikContext<any>();

  const leaseToCustomComboItem = (lease: Lease) => {
    return {
      primary: lease.name,
      secondary: format(new Date(lease.startDate), 'd/MM/yyyy', {
        locale: dateLocaleMap[language],
      }),
      value: lease,
    };
  };
  const emptyObject = !isNil(defaultValue)
    ? leaseToCustomComboItem(defaultValue)
    : { primary: '', secondary: '', value: null };
  const leaseSelected = getSafeValueInObject(values, fieldName);

  const value = !isNil(leaseSelected) && !isEmpty(leaseSelected) ? leaseToCustomComboItem(leaseSelected) : emptyObject;
  const options = leases.map((l) => leaseToCustomComboItem(l));
  const renderOption = (value: CustomInputItemType) => {
    return (
      <Grid container alignContent="space-between" style={{ marginBottom: '5px', marginTop: '5px' }}>
        <Grid item xs={2}>
          <LeaseIcon
            style={{
              width: 15,
              height: 15,
              marginRight: 10,
              fill: Colors.LIGHT_BLUE_GREY,
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <Typography className={comboBoxClasses.primaryText}>{value.primary}</Typography>
        </Grid>
        <Grid item xs={5} style={{ textAlign: 'right' }}>
          {!isNil(value.secondary) && (
            <Typography className={comboBoxClasses.secondaryText}>{value.secondary}</Typography>
          )}
        </Grid>
      </Grid>
    );
  };
  const renderInput = (value: CustomInputItemType) => {
    return (
      <>
        <LeaseIcon
          style={{
            width: 15,
            height: 15,
            marginRight: 10,
            fill: Colors.LIGHT_BLUE_GREY,
          }}
        />
        <Typography
          style={{
            fontSize: 12,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: `calc(${inputWidth}px - 85px)`,
          }}
        >{`${value.primary} - ${value.secondary}`}</Typography>
      </>
    );
  };
  if (type === 'controlled' && onChange) {
    return (
      <div
        style={{
          alignItems: 'baseline',
          display: 'flex',
          ...style,
        }}
      >
        <FullCustomComboBox
          onChange={(_event, values: CustomInputItemType[] | null) => {
            let lease: Lease | null;
            if (isNil(values) || isEmpty(values)) {
              lease = null;
            } else if (values.length === 1) lease = values[0].value;
            else lease = values[1].value;
            onChange(lease);
          }}
          value={value}
          label={formatMessage({ id: 'lease.title' })}
          options={options}
          inputStyle={{ width: inputWidth, ...inputStyle }}
          renderOption={renderOption}
          renderInput={renderInput}
          error={Boolean(getSafeValueInObject(errors, fieldName) && getSafeValueInObject(touched, fieldName))}
          noOptionsText={formatMessage({ id: 'comboBox.noLeases' })}
          stringToCompare={(value: CustomInputItemType) => `${value.primary} - ${value.secondary}`}
          {...rest}
        />
      </div>
    );
  }
  return (
    <div
      style={{
        alignItems: 'baseline',
        display: 'flex',
        ...style,
      }}
    >
      <FormikComboBox
        name={fieldName}
        value={value}
        label={formatMessage({ id: 'lease.title' })}
        options={options}
        fieldToReturn="value"
        emptyValue={emptyObject}
        inputStyle={{ width: inputWidth, ...inputStyle }}
        renderOption={renderOption}
        renderInput={renderInput}
        error={Boolean(getSafeValueInObject(errors, fieldName) && getSafeValueInObject(touched, fieldName))}
        noOptionsText={formatMessage({ id: 'comboBox.noLeases' })}
        stringToCompare={(value: CustomInputItemType) => `${value.primary} - ${value.secondary}`}
        {...rest}
      />
    </div>
  );
};

export default SimpleLeaseSelector;
