import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import isNil from 'lodash/isNil';
import DiscardChangesDialog from './DiscardChangesDialog';

interface DiscardProps {
  when: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  history: any;
  onConfirm: () => void;
  onClose?: () => void;
  shouldBlockNavigation: (l: Location) => boolean;
}

const RouteDiscardChanges: React.FC<DiscardProps> = ({ when, history, onConfirm, onClose, shouldBlockNavigation }) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState<boolean>(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);

  const showModal = (location: Location) => {
    setModalVisible(true);
    setLastLocation(location);
  };

  const closeModal = () => {
    if (onClose) {
      onClose();
    }
    setModalVisible(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleBlockedNavigation = (nextLocation: any) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    closeModal();
    if (lastLocation) {
      if (!isNil(onConfirm)) {
        onConfirm();
      }
      setConfirmedNavigation(true);
    }
  };
  useEffect(() => {
    if (confirmedNavigation && !isNil(lastLocation)) {
      history.push(lastLocation.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <DiscardChangesDialog onConfirm={handleConfirmNavigationClick} onClose={closeModal} open={modalVisible} />
    </>
  );
};

export default RouteDiscardChanges;
