/* eslint-disable import/named */
/* eslint-disable react/jsx-props-no-spreading */
import { Grid } from '@material-ui/core';
import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '@rentguru/commons-utils';
import { isNil } from 'lodash';
import { BOX_SHADOW } from '../../styles';
import { stopPropagation } from '../../utils';

interface LoaderButtonStyleProps {
  smallVersion: boolean;
  invertedColors: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useStyles = makeStyles<Theme, LoaderButtonStyleProps>(() => {
  const PRIMARY_COLOR = Colors.CLASSICAL_WHITE;
  const SECONDARY_COLOR = Colors.CARNATION_RED;

  const commonButtonParams = (smallVersion: boolean) => ({
    height: smallVersion ? '25px' : '50px',
    minWidth: smallVersion ? '' : '120px',
    borderRadius: '25px',
    boxShadow: BOX_SHADOW,
    justifyContent: 'center',
    alignItems: 'center',
  });

  return {
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      width: '100%',
    },
    buttonRoot: (props) => ({
      ...commonButtonParams(props.smallVersion),
      paddingLeft: props.smallVersion ? '15px' : '',
      paddingRight: props.smallVersion ? '15px' : '',
      backgroundColor: props.invertedColors ? PRIMARY_COLOR : SECONDARY_COLOR,
      '&:hover': {
        backgroundColor: props.invertedColors ? PRIMARY_COLOR : SECONDARY_COLOR,
      },
    }),
    disabledRoot: (props) => ({
      ...commonButtonParams(props.smallVersion),
      backgroundColor: props.invertedColors ? PRIMARY_COLOR : SECONDARY_COLOR,
      opacity: 0.25,
    }),
    label: (props) => ({
      color: props.invertedColors ? SECONDARY_COLOR : PRIMARY_COLOR,
      fontSize: props.smallVersion ? '12px' : '16px',
      fontWeight: props.smallVersion ? 'bold' : 800,
      lineHeight: '20px',
      textAlign: 'center',
      textTransform: 'none',
    }),
    buttonSuccess: (props) => ({
      ...commonButtonParams(props.smallVersion),
      backgroundColor: Colors.MALACHITE_GREEN,
    }),
    buttonProgress: (props) => ({
      color: props.invertedColors ? SECONDARY_COLOR : PRIMARY_COLOR,
    }),
  };
});

export interface LoaderButtonProps extends ButtonProps {
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: React.ReactEventHandler<any>;
  success?: boolean;
  loading?: boolean;
  type?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  style?: React.CSSProperties;
  id?: string;
  buttonStyle?: React.CSSProperties;
  href?: string;
  dataTest?: string;
  smallVersion?: boolean;
  invertedColors?: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const LoaderButton: React.FC<LoaderButtonProps> = ({
  children,
  onClick = () => {},
  success = false,
  loading = false,
  type = 'submit',
  disabled = false,
  style,
  buttonStyle,
  href,
  dataTest,
  smallVersion = false,
  invertedColors = false,
  ...rest
}) => {
  const props = { smallVersion, invertedColors };
  const classes = useStyles(props);

  return (
    <div key="loaderButtonContainer" className={classes.root} style={style}>
      <div key="loaderButtonWrapper" className={classes.wrapper}>
        <Button
          onMouseDown={stopPropagation}
          classes={{
            root: success ? classes.buttonSuccess : classes.buttonRoot,
            label: classes.label,
            disabled: classes.disabledRoot,
          }}
          style={buttonStyle}
          disabled={loading || disabled}
          onClick={onClick}
          type={type}
          fullWidth
          href={href}
          data-test={dataTest}
          {...rest}
          // put there as a bugfix for https://github.com/mui/material-ui/issues/30953
          // if it's not working fully, replace with disableRipple.
          // TODO: remove when upgrading to MUI V5
          disableFocusRipple
        >
          <Grid key="loaderButtonContent">
            {!loading && !isNil(children) && children}
            {loading && <CircularProgress size={smallVersion ? 15 : 24} className={classes.buttonProgress} />}
          </Grid>
        </Button>
      </div>
    </div>
  );
};
