import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';
import isNil from 'lodash/isNil';
import { Typography, Tooltip, TableRow, Grid } from '@material-ui/core';
import {
  Colors,
  formatNumber,
  formatStandardizedUTCDate,
  StatementSource,
  CustomValue,
  Statement,
  StatementType,
  Unit,
  S3Object,
} from '@rentguru/commons-utils';
import { dateLocaleMap, useLocale } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as HomeSvg } from 'src/icons/home.svg';
import { ReactComponent as FeeIcon } from 'src/icons/fee.svg';
import { ReactComponent as ChargeIcon } from 'src/icons/charges.svg';
import { ReactComponent as RepaymentIcon } from 'src/icons/moneytransfer.svg';
import { RouteDestination } from 'src/components/Routes/Routes';
import { CustomTableCell } from '@up2rent/ui';
import OwnerStatementUnitsPopper from 'src/components/Accounting/OwnerStatements/OwnerStatementsToValidate/OwnerStatementUnitsPopper';
import OwnerStatementsToValidatePopper from 'src/components/Accounting/OwnerStatements/OwnerStatementsToValidate/OwnerStatementsToValidatePopper';
import DocumentIconButton from 'src/components/ui/CustomIconButtons/DocumentIconButton';

interface OwnerStatementsArchivedRowProps extends Statement {
  numberOfUnits: number;
  totalAmount: number | undefined;
  amounts: CustomValue[];
  ownerName: string;
  units: Unit[];
  statementDetail: S3Object | undefined;
  isSelected: boolean;
  actionMenu: (e: React.MouseEvent, id: string, file: S3Object) => void;
}

const OwnerStatementsArchivedRow: React.FC<OwnerStatementsArchivedRowProps> = ({
  id,
  periodFrom,
  periodTo,
  numberOfUnits,
  ownerName,
  source,
  amounts,
  units,
  types,
  totalAmount,
  sentDate,
  statementDetail,
  actionMenu,
  isSelected,
}) => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const [amountDescriptionAnchorEl, setAmountDescriptionAnchorEl] = useState<HTMLElement | null>(null);
  const [unitDescriptionAnchorEl, setUnitDescriptionAnchorEl] = useState<HTMLElement | null>(null);

  const closeAmountDescriptionPopper = () => {
    setAmountDescriptionAnchorEl(null);
  };

  const closeUnitDescriptionPopper = () => {
    setUnitDescriptionAnchorEl(null);
  };

  const repayementsSelected = types ? (types as StatementType[]).includes(StatementType.RE_PAYMENT) : false;
  const feesSelected = types ? (types as StatementType[]).includes(StatementType.FEES) : false;
  const chargeSelected = types ? (types as StatementType[]).includes(StatementType.CHARGES) : false;
  const amountColor = !isNil(totalAmount) && totalAmount < 0 ? Colors.CARNATION_RED : Colors.TURQUOISE_BLUE;
  return (
    <TableRow
      hover
      onClick={() => {
        history.push({
          pathname: RouteDestination.VALIDATE_OWNER_STATEMENT,
          state: { statementId: id },
        });
      }}
      aria-checked={isSelected}
      tabIndex={-1}
      key={id}
      selected={isSelected}
      style={{ paddingTop: 10, cursor: 'pointer' }}
    >
      <CustomTableCell scope="row" padding="none" style={{ paddingLeft: 20, width: 300 }}>
        <Typography style={{ fontSize: 14, color: Colors.SLATE_GREY, display: 'inline' }}>
          {formatStandardizedUTCDate(new Date(periodFrom), 'dd MMMM yyyy', { locale: dateLocaleMap[language] })}
        </Typography>
        <Typography
          style={{ fontSize: 14, color: Colors.SLATE_GREY, display: 'inline', marginLeft: 5, marginRight: 5 }}
        >
          {'\u2192'}
        </Typography>
        <Typography style={{ fontSize: 14, color: Colors.SLATE_GREY, display: 'inline', textAlign: 'right' }}>
          {formatStandardizedUTCDate(new Date(periodTo), 'dd MMMM yyyy', { locale: dateLocaleMap[language] })}
        </Typography>
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14 }}>
          {format(new Date(sentDate!), 'dd MMMM yyyy', { locale: dateLocaleMap[language] })}
        </Typography>
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none" style={{ width: 150 }}>
        <Typography style={{ color: Colors.CLASSICAL_BLACK, fontSize: 14, fontWeight: 600 }}>{ownerName}</Typography>
      </CustomTableCell>
      <CustomTableCell
        scope="row"
        padding="none"
        onMouseEnter={(e) => {
          setUnitDescriptionAnchorEl(e.currentTarget);
        }}
        onMouseLeave={closeUnitDescriptionPopper}
      >
        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-start' }}>
          <HomeSvg style={{ fill: Colors.TOWER_GREY, width: 14, height: 14, marginRight: 5 }} />
          <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14 }}>{numberOfUnits}</Typography>
        </div>
        {unitDescriptionAnchorEl && <OwnerStatementUnitsPopper units={units} />}
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14, lineHeight: '15px' }}>
          {source === StatementSource.AUTOMATIC
            ? formatMessage({ id: 'settings.communications.automatic' })
            : formatMessage({ id: 'settings.communications.manual' })}
        </Typography>
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        <div style={{ display: 'flex', justifyContent: 'space-around', maxWidth: 150 }}>
          <Tooltip title={formatMessage({ id: 'enums.StatementType.RE_PAYMENT' })}>
            <Grid>
              <RepaymentIcon
                style={{
                  fill: repayementsSelected ? Colors.DODGER_BLUE : Colors.SILVER,
                  ...(repayementsSelected && { borderBottom: `2px solid ${Colors.DODGER_BLUE}`, paddingBottom: 5 }),
                }}
              />
            </Grid>
          </Tooltip>
          <Tooltip title={formatMessage({ id: 'enums.StatementType.FEES' })}>
            <Grid>
              <FeeIcon
                style={{
                  fill: feesSelected ? Colors.DODGER_BLUE : Colors.SILVER,
                  ...(feesSelected && { borderBottom: `2px solid ${Colors.DODGER_BLUE}`, paddingBottom: 5 }),
                }}
              />
            </Grid>
          </Tooltip>
          <Tooltip title={formatMessage({ id: 'enums.StatementType.CHARGES' })}>
            <Grid>
              <ChargeIcon
                style={{
                  fill: chargeSelected ? Colors.DODGER_BLUE : Colors.SILVER,
                  ...(chargeSelected && { borderBottom: `2px solid ${Colors.DODGER_BLUE}`, paddingBottom: 5 }),
                }}
              />
            </Grid>
          </Tooltip>
        </div>
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        {!isNil(totalAmount) && (
          <div
            style={{
              borderRadius: 4,
              border: `1px solid ${amountColor}`,
              color: amountColor,
              fontWeight: 'bold',
              height: 30,
              justifyContent: 'center',
              marginRight: 20,
              alignItems: 'center',
              display: 'flex',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: '100px',
              overflow: 'hidden',
            }}
            onMouseEnter={(e) => {
              setAmountDescriptionAnchorEl(e.currentTarget);
            }}
            onMouseLeave={closeAmountDescriptionPopper}
          >
            {formatNumber(totalAmount, language, { style: 'currency', currency: 'EUR' })}
          </div>
        )}
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 20, alignItems: 'center' }}>
          <Tooltip title={formatMessage({ id: 'accounting.statement.archivedSection.fileDetailTooltip' })}>
            <DocumentIconButton
              disabled={!statementDetail}
              onClick={(e) => {
                e.stopPropagation();
                if (statementDetail) {
                  actionMenu(e, statementDetail.id, statementDetail);
                }
              }}
            />
          </Tooltip>
        </div>
      </CustomTableCell>
      {amountDescriptionAnchorEl && (
        <OwnerStatementsToValidatePopper
          statementAmounts={amounts}
          anchorEl={amountDescriptionAnchorEl}
          open={!isNil(amountDescriptionAnchorEl)}
          handleClose={closeAmountDescriptionPopper}
        />
      )}
    </TableRow>
  );
};

export default OwnerStatementsArchivedRow;
