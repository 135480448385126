import React from 'react';
import CountrySelector, { CountrySelectorProps } from './index';
import { CountryObject } from '@rentguru/commons-utils';
import { useFormikContext, FormikProps } from 'formik';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

interface AdditionalFormikProps {
  name: string;
  fieldToReturn?: keyof CountryObject;
  helperText?: string;
}

type FormikCountrySelectorBox = AdditionalFormikProps & Omit<CountrySelectorProps, 'onChange'>;

// eslint-disable-next-line no-redeclare
const FormikCountrySelectorBox: React.FC<FormikCountrySelectorBox> = ({
  name,
  fieldToReturn = 'code',
  helperText: _helper,
  ...rest
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { setFieldValue }: FormikProps<any> = useFormikContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (_event: any, values: CountryObject[] | null) => {
    let valueToReturn: string | null;
    if (isNil(values) || isEmpty(values)) valueToReturn = '';
    else if (values.length === 1) valueToReturn = values[0][fieldToReturn];
    else valueToReturn = values[1][fieldToReturn];
    setFieldValue(name, valueToReturn);
  };

  return <CountrySelector {...rest} onChange={onChange} />;
};
export default FormikCountrySelectorBox;
