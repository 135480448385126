import React from 'react';
import { LeaseExtended } from '@rentguru/commons-utils';
import IntermediateDetailActionsMenu from './IntermediateDetailActionsMenu';
import useLeaseActionsMenuUtils from './useLeaseActionsMenuUtils';

interface LeaseDetailActionsMenuProps {
  lease: LeaseExtended;
}
const LeaseDetailActionsMenu: React.FC<LeaseDetailActionsMenuProps> = ({ lease }) => {
  const { actions, leaseContract, editLease, setLeaseToDraft, signatureResult, generatePdfForLease } =
    useLeaseActionsMenuUtils(lease);
  return (
    <IntermediateDetailActionsMenu
      lease={lease}
      actions={actions}
      leaseContract={leaseContract}
      generatePDF={generatePdfForLease}
      signatureResult={signatureResult}
      editLease={editLease}
      setLeaseToDraft={setLeaseToDraft}
    />
  );
};

export default LeaseDetailActionsMenu;
