import React from 'react';
import EndLeaseIndemnityField, { IndemnityFieldProps } from './EndLeaseIndemnityField';
import IconnedRemark from 'src/components/ui/IconnedRemark';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import { useIntl } from 'react-intl';
import { iconnedRemarkMargins, useEndLeaseStyles } from './EndLeaseForm';
import { ReactComponent as InfoSvg } from '../../../../icons/info.svg';
import EndLeaseCommentToTenantField from './EndLeaseCommentToTenantField';

const EndLeaseMutualFields = (props: IndemnityFieldProps) => {
  const { formatMessage } = useIntl();
  const classes = useEndLeaseStyles();

  return (
    <>
      <EndLeaseCommentToTenantField />
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
        <Typography className={classes.informationFillInTitle}>
          {formatMessage({ id: `lease.endExtendLease.compensation` })}
        </Typography>
        <Tooltip title={formatMessage({ id: 'lease.endExtendLease.compensationInfo' })} placement="right">
          <Grid>
            <InfoSvg style={{ marginLeft: 10, fill: Colors.SILVER }} />
          </Grid>
        </Tooltip>
      </div>

      <EndLeaseIndemnityField {...props} />

      <IconnedRemark
        Icon={InfoSvg}
        noFixWidth
        style={iconnedRemarkMargins}
        message={
          <Typography className={classes.infoMessageText}>
            {formatMessage({ id: 'settings.addVariousOperation.confirmationEmail' })}
          </Typography>
        }
      />
    </>
  );
};

export default EndLeaseMutualFields;
