/* eslint-disable no-redeclare */
/* eslint-disable no-undef */
/* eslint-disable default-case */
/* eslint-disable @typescript-eslint/no-shadow */
/*

This file is capital.
This context feeds data to the whole application. Take this away and nothing works anymore.

*/

// Imports. nothing to worry about here.
import React, { useEffect, useReducer, Reducer, useMemo } from 'react';
import {
  Building,
  Unit,
  Contact,
  Lease,
  BuildingOwner,
  UnitLease,
  UnitOwner,
  LeaseContact,
  User,
  LeaseInventory,
  Technic,
  Ticket,
  Team,
  UserTeam,
  Address,
  BankAccount,
  UserRole,
  Notification,
} from '@rentguru/commons-utils';
import { useUser } from './UserContext';
import { filterContextLoaderBundleByTeam } from './utils';
import { ActionAddress, addressReducerDelegation } from './AddressContext';
import { ActionBankAccount, bankAccountReducerDelegation } from './BankAccountsContext';
import { ActionBuilding, buildingReducerDelegation } from './BuildingsContext';
import {
  ActionBuildingOwner,
  ActionContact,
  ActionUnitOwner,
  buildingOwnerReducerDelegation,
  contactReducerDelegation,
  unitOwnerReducerDelegation,
} from './ContactsContext';
import { ActionUserRole, userRoleReducerDelegation } from './UserRolesContext';
import { ActionTicket, ticketReducerDelegation } from './TicketsContext';
import { ActionUser, ActionUserTeam, userReducerDelegation, userTeamReducerDelegation } from './UsersContext';
import { ActionTeam, teamReducerDelegation } from './TeamsContext';
import { ActionTechnic, technicReducerDelegation } from './TechnicsContext';
import { ActionNotification, notificationReducerDelegation } from './NotificationsContext';
import { ActionUnit, unitReducerDelegation } from './UnitsContext';
import { ActionLeaseContact, leaseContactReducerDelegation } from './LeaseContactContext';
import { ActionUnitLease, unitLeaseReducerDelegation } from './UnitLeaseContext';
import { ActionLease, leaseReducerDelegation } from './LeasesContext';
import { ActionLeaseInventory, leaseInventoryReducerDelegation } from './LeaseInventoryContext';
import { LoadingScreen } from '@up2rent/ui';

/*
  This interface is essential to te well being of this context.
  the idea is to hold one and only state that will keep all the subscribed tables.
  This is going to get updated and rerender the whole application when one of those tables gets 
  an update (insert update or delete of a record)

  Address
  BankAccount
  Building
  ...
  ...
  UserTeam
  CustomSubscription

  are going to belocally stored (in localstorage). This makes it way faster to use and handle data
*/
export interface ContextLoaderStore {
  Address: { data: Address[]; loading: boolean; shouldFetch: boolean; lastFetch: Date | null };
  BankAccount: { data: BankAccount[]; loading: boolean; shouldFetch: boolean; lastFetch: Date | null };
  Building: { data: Building[]; loading: boolean; shouldFetch: boolean; lastFetch: Date | null };
  BuildingOwner: { data: BuildingOwner[]; loading: boolean; shouldFetch: boolean; lastFetch: Date | null };
  Contact: { data: Contact[]; loading: boolean; shouldFetch: boolean; lastFetch: Date | null };
  Lease: { data: Lease[]; loading: boolean; shouldFetch: boolean; lastFetch: Date | null };
  LeaseContact: { data: LeaseContact[]; loading: boolean; shouldFetch: boolean; lastFetch: Date | null };
  LeaseInventory: { data: LeaseInventory[]; loading: boolean; shouldFetch: boolean; lastFetch: Date | null };
  Notification: { data: Notification[]; loading: boolean; shouldFetch: boolean; lastFetch: Date | null };
  Team: { data: Team[]; loading: boolean; shouldFetch: boolean; lastFetch: Date | null };
  Technic: { data: Technic[]; loading: boolean; shouldFetch: boolean; lastFetch: Date | null };
  Ticket: { data: Ticket[]; loading: boolean; shouldFetch: boolean; lastFetch: Date | null };
  Unit: { data: Unit[]; loading: boolean; shouldFetch: boolean; lastFetch: Date | null };
  UnitLease: { data: UnitLease[]; loading: boolean; shouldFetch: boolean; lastFetch: Date | null };
  UnitOwner: { data: UnitOwner[]; loading: boolean; shouldFetch: boolean; lastFetch: Date | null };
  User: { data: User[]; loading: boolean; shouldFetch: boolean; lastFetch: Date | null };
  UserRole: { data: UserRole[]; loading: boolean; shouldFetch: boolean; lastFetch: Date | null };
  UserTeam: { data: UserTeam[]; loading: boolean; shouldFetch: boolean; lastFetch: Date | null };
}

export type ContextLoaderAction =
  | { type: 'SHOULD_FETCH_ALL' }
  | ActionAddress
  | ActionBankAccount
  | ActionBuilding
  | ActionContact
  | ActionBuildingOwner
  | ActionLease
  | ActionLeaseContact
  | ActionLeaseInventory
  | ActionNotification
  | ActionUnitOwner
  | ActionTeam
  | ActionTechnic
  | ActionTicket
  | ActionUnit
  | ActionUnitLease
  | ActionUser
  | ActionUserRole
  | ActionUserTeam;

const initialStore: ContextLoaderStore = {
  Address: { data: [], loading: false, shouldFetch: false, lastFetch: null },
  BankAccount: { data: [], loading: false, shouldFetch: false, lastFetch: null },
  Building: { data: [], loading: false, shouldFetch: false, lastFetch: null },
  BuildingOwner: { data: [], loading: false, shouldFetch: false, lastFetch: null },
  Contact: { data: [], loading: false, shouldFetch: false, lastFetch: null },
  Lease: { data: [], loading: false, shouldFetch: false, lastFetch: null },
  LeaseContact: { data: [], loading: false, shouldFetch: false, lastFetch: null },
  LeaseInventory: { data: [], loading: false, shouldFetch: false, lastFetch: null },
  Notification: { data: [], loading: false, shouldFetch: false, lastFetch: null },
  Team: { data: [], loading: false, shouldFetch: false, lastFetch: null },
  Technic: { data: [], loading: false, shouldFetch: false, lastFetch: null },
  Ticket: { data: [], loading: false, shouldFetch: false, lastFetch: null },
  Unit: { data: [], loading: false, shouldFetch: false, lastFetch: null },
  UnitLease: { data: [], loading: false, shouldFetch: false, lastFetch: null },
  UnitOwner: { data: [], loading: false, shouldFetch: false, lastFetch: null },
  User: { data: [], loading: false, shouldFetch: false, lastFetch: null },
  UserRole: { data: [], loading: false, shouldFetch: false, lastFetch: null },
  UserTeam: { data: [], loading: false, shouldFetch: false, lastFetch: null },
};

const contextLoaderReducer = (state: ContextLoaderStore, action: ContextLoaderAction): ContextLoaderStore => {
  if (action.type === 'SHOULD_FETCH_ALL') {
    return Object.fromEntries(
      Object.entries(state).map(([key, value]) => [key, { ...value, shouldFetch: true }])
    ) as ContextLoaderStore;
  }

  const addressState = addressReducerDelegation(state, action);
  const bankAccountState = bankAccountReducerDelegation(addressState, action);
  const buildingState = buildingReducerDelegation(bankAccountState, action);
  const contactState = contactReducerDelegation(buildingState, action);
  const leaseContactState = leaseContactReducerDelegation(contactState, action);
  const leaseInventoryState = leaseInventoryReducerDelegation(leaseContactState, action);
  const buildingOwnerState = buildingOwnerReducerDelegation(leaseInventoryState, action);
  const notificationState = notificationReducerDelegation(buildingOwnerState, action);
  const unitOwnerState = unitOwnerReducerDelegation(notificationState, action);
  const teamState = teamReducerDelegation(unitOwnerState, action);
  const technicState = technicReducerDelegation(teamState, action);
  const ticketState = ticketReducerDelegation(technicState, action);
  const unitState = unitReducerDelegation(ticketState, action);
  const unitLeaseState = unitLeaseReducerDelegation(unitState, action);
  const userState = userReducerDelegation(unitLeaseState, action);
  const userRoleState = userRoleReducerDelegation(userState, action);
  const userTeamState = userTeamReducerDelegation(userRoleState, action);
  const leaseState = leaseReducerDelegation(userTeamState, action);

  const finalState = leaseState;
  return finalState;
};

interface ContextBundle extends ContextLoaderStore {
  dispatch: React.Dispatch<ContextLoaderAction>;
  loading: boolean;
}

// We create the actual context here. All the children of this component
// will be able to access the values of this context.
export const ContextLoader = React.createContext<ContextBundle | null>(null);

// We create the actual component here.
export const ContextLoaderProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { clientId, isFetchingUser, rootUser, userId, isOwner, isTenant } = useUser();

  const [state, dispatch] = useReducer<Reducer<ContextLoaderStore, ContextLoaderAction>>(
    contextLoaderReducer,
    initialStore
  );

  const finishedLoading =
    !isFetchingUser && Object.keys(state).every((key) => !state[key as keyof ContextLoaderStore].loading);

  useEffect(() => {
    if (clientId) {
      dispatch({ type: 'SHOULD_FETCH_ALL' });
    }
  }, [clientId]);

  const values = useMemo<ContextBundle | null>(
    () => {
      if (!rootUser && !finishedLoading) {
        return null;
      }

      const contextLoaderValues = state;
      (rootUser || isOwner || isTenant
        ? state
        : filterContextLoaderBundleByTeam(state, userId!)) as unknown as ContextBundle;

      return {
        ...contextLoaderValues,
        loading: !finishedLoading,
        dispatch,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state, finishedLoading]
  );

  if (!rootUser && !finishedLoading) {
    return <LoadingScreen />;
  }

  return <ContextLoader.Provider value={values}>{children}</ContextLoader.Provider>;
};

export const useContextLoader = (): ContextBundle => {
  const context = React.useContext<ContextBundle | null>(ContextLoader);

  if (context === undefined) {
    throw new Error('`useContextLoader` hook must be used within a `ContextLoaderProvider` component');
  }
  return context as ContextBundle;
};

export default ContextLoader;
