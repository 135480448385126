import React from 'react';
import * as Yup from 'yup';
import { FormikHelpers, Formik, Form } from 'formik';
import isNil from 'lodash/isNil';
import { useIntl } from 'react-intl';
import { Divider } from '@material-ui/core';
import { ContentHeader, LoaderButton , ActionButton } from '@up2rent/ui';
import toUpper from 'lodash/toUpper';
import { useTenants } from '../../../tenantHooks/TenantContext';
import TextFieldArea from 'src/components/ui/TextFieldArea';
import { Colors, DocumentType } from '@rentguru/commons-utils';

interface RejectLeaseFormFormValues {
  rejectReason: string;
}

const RejectLeaseFormSchema = Yup.object()
  .shape({
    rejectReason: Yup.string().required(),
  })
  .required();

interface RejectLeaseFormFormProps {
  onClose?: () => void;
  documentId: string;
  contactId: string;
  type: DocumentType;
}

const initialValues: RejectLeaseFormFormValues = {
  rejectReason: '',
};

const RejectLeaseForm: React.FC<RejectLeaseFormFormProps> = ({ onClose, documentId, contactId, type }) => {
  const { formatMessage } = useIntl();
  const { rejectDocument } = useTenants();

  const handleReject = async (
    values: RejectLeaseFormFormValues,
    { setSubmitting, setStatus }: FormikHelpers<RejectLeaseFormFormValues>
  ) => {
    await rejectDocument(documentId, contactId, type, values.rejectReason);
    setStatus(true);
    setSubmitting(false);
    if (!isNil(onClose)) {
      onClose();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RejectLeaseFormSchema}
      onSubmit={handleReject}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ errors, touched, status, isSubmitting }) => (
        <Form>
          <ContentHeader title={formatMessage({ id: 'lease.detail.action.rejectVersion' })} />
          <div style={{ marginLeft: 30, marginRight: 30 }}>
            <div style={{ alignItems: 'baseline' }}>
              <TextFieldArea
                label={formatMessage({ id: 'lease.detail.action.rejectReason' })}
                name="rejectReason"
                type="text"
                rows={10}
                variant="filled"
                margin="dense"
                style={{ width: 540, maxWidth: 'none' }}
                error={Boolean(errors.rejectReason && touched.rejectReason)}
              />
            </div>
          </div>
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          <div
            style={{
              marginRight: 30,
              marginBottom: 20,
              display: 'flex',
              float: 'right',
              alignItems: 'center',
            }}
          >
            <ActionButton
              onClick={() => {
                if (!isNil(onClose)) {
                  onClose();
                }
              }}
              style={{
                background: 'none',
                color: Colors.DARK_SLATE_GREY,
                marginRight: 20,
              }}
            >
              {toUpper(
                formatMessage({
                  id: 'cancel',
                })
              )}
            </ActionButton>
            <LoaderButton loading={isSubmitting} success={status}>
              {formatMessage({
                id: 'send',
              })}
            </LoaderButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RejectLeaseForm;
