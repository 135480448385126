import { Divider, Grid, Tab } from '@material-ui/core';
import { ActionButton, ContentHeader, CustomizedTabs, ElevatedPaper } from '@up2rent/ui';
import { isNil } from 'lodash';
import { useIntl } from 'react-intl';
import { Link, useRouteMatch } from 'react-router-dom';
import { RouteDestination } from '../Routes/Routes';
import SkeletonComponent from '../ui/SkeletonComponent';
import { SettingsRouteDestination } from './Settings';
import CreditsBalance from './Subscription/CreditsBalance';
import SubscriptionInvoices from './Subscription/SubscriptionInvoices';
import SubscriptionPlan from './Subscription/SubscriptionPlan';
import SubscriptionProblemWarning from './Subscription/SubscriptionProblemWarning';

export const SUBSCRIPTION_PLAN_TAB_ROUTE = 'plan';

export const CAN_BUY_CREDITS = false; // TODO SS -> Remove when activating credits purchase

const SUBSCRIPTION_NAV_ITEMS = [
  {
    to: ``,
    labelId: 'settings.subscription.invoices',
  },
  {
    to: SUBSCRIPTION_PLAN_TAB_ROUTE,
    labelId: 'settings.subscription.plan',
  },
];

const SubscriptionSettings = () => {
  const { formatMessage } = useIntl();

  const match = useRouteMatch<{ tabName: string }>({
    path: `${RouteDestination.SETTINGS}/${SettingsRouteDestination.SUBSCRIPTION_ROUTE}/:tabName?`,
  });

  if (isNil(match)) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <SkeletonComponent loading />
      </ElevatedPaper>
    );
  }

  const tabIsInvoice = isNil(match.params.tabName);
  const tabIsPlan = match.params.tabName === SUBSCRIPTION_PLAN_TAB_ROUTE;

  return (
    <>
      <ElevatedPaper style={{ margin: 20, maxWidth: 640, paddingBottom: 0 }}>
        <ContentHeader title={formatMessage({ id: 'settings.subscription.name' })}>
          <SubscriptionProblemWarning />
          {CAN_BUY_CREDITS && (
            <ActionButton onClick={() => {}} style={{ textTransform: 'none' }}>
              {formatMessage({ id: 'settings.subscription.addCredits' })}
            </ActionButton>
          )}
        </ContentHeader>
        <CreditsBalance />
        <CustomizedTabs
          value={!isNil(match?.params.tabName) ? match.url : match.url + (match.url.endsWith('/') ? '' : '/')}
          dynamicDefaultTab
        >
          {SUBSCRIPTION_NAV_ITEMS.map((navItem) => {
            if (!navItem) {
              return null;
            }
            const to = !isNil(match?.params.tabName)
              ? match.url.replace(match.params.tabName, navItem.to)
              : match.url + (match.url.endsWith('/') ? '' : '/') + navItem.to;

            return (
              <Tab
                label={formatMessage({ id: navItem.labelId })}
                component={Link}
                key={navItem.to}
                to={to}
                value={to}
              />
            );
          })}
        </CustomizedTabs>
        <Divider />
        <Grid style={{ marginTop: 20, marginBottom: 20 }}>
          {tabIsInvoice && <SubscriptionInvoices />}
          {tabIsPlan && <SubscriptionPlan />}
        </Grid>
      </ElevatedPaper>
    </>
  );
};

export default SubscriptionSettings;
