import React, { useState, useEffect } from 'react';
import CustomFilterMenu from './DashboardMetricsDialogs/CustomFilterMenu';
import DashboardMetricTickets from './DashboardMetricTickets';
import DashboardMetricOccupancy from './DashboardMetricOccupancy';
import { MetricBoxTimeFilterName } from './DashboardFilters/MetricBoxTimeFilter';
import DashboardMetricMap from './DashboardMetricMap';
import DashboardMetricRentalIncome from './DashboardMetricRentalIncome';
import DashboardMetricAreas from './DashboardMetricAreas';
import DashboardKeyFigures from './DashboardKeyFigures';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { useUser } from 'src/hooks/UserContext';
import { defaultMetricBoxOrder, useDashboardFilters } from 'src/hooks/DashboardFiltersContext';
import {
  OCCUPANCY_BOX_KEY,
  MAP_BOX_KEY,
  RENTAL_INCOME_BOX_KEY,
  FIGURES_BOX_KEY,
  AREAS_BOX_KEY,
  TICKETS_METRICS_BOX_KEY,
  DashboardBoxType,
  LooseObject,
} from '@rentguru/commons-utils';
import { WidthProvider, Responsive, Layout } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import './utils/dashboardLayout.css';
import { isEmpty } from 'lodash';
import RankingLoader from '../DashboardLoader';
import { Grid } from '@material-ui/core';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export interface CustomFilterMenuBundle {
  anchor: HTMLElement | null;
  name: MetricBoxTimeFilterName;
}

const DashboardMetrics: React.FC = () => {
  const {
    leasesDetailsRead,
    ticketsDetailsRead,
    buildingsUnitsDetailsRead,
    financialValuationsAndCostsRead,
    financialMortgagesRead,
    financialTransactionsRead,
    loading: permissionsLoading,
  } = usePermissions();
  const { getBoxOrder, saveNewLayout } = useDashboardFilters();

  const [layout, setLayout] = useState<Layout[]>([]);
  const { isOwner } = useUser();
  const [customFilterMenuBundle, setCustomFilterMenuBundle] = useState<CustomFilterMenuBundle>({
    anchor: null,
    name: 'tickets',
  });

  const permissions: LooseObject = {
    [OCCUPANCY_BOX_KEY]: leasesDetailsRead && buildingsUnitsDetailsRead,
    [TICKETS_METRICS_BOX_KEY]: ticketsDetailsRead && !isOwner,
    [AREAS_BOX_KEY]: buildingsUnitsDetailsRead,
    [FIGURES_BOX_KEY]:
      financialMortgagesRead && financialTransactionsRead && financialValuationsAndCostsRead && leasesDetailsRead,
    [MAP_BOX_KEY]: buildingsUnitsDetailsRead,
    [RENTAL_INCOME_BOX_KEY]: leasesDetailsRead && financialTransactionsRead && !isOwner,
  };

  const components: LooseObject = {
    [OCCUPANCY_BOX_KEY]: (
      <DashboardMetricOccupancy openCustomFilterMenu={setCustomFilterMenuBundle} forceUpdateLayout={setLayout} />
    ),
    [TICKETS_METRICS_BOX_KEY]: (
      <DashboardMetricTickets openCustomFilterMenu={setCustomFilterMenuBundle} forceUpdateLayout={setLayout} />
    ),
    [AREAS_BOX_KEY]: <DashboardMetricAreas forceUpdateLayout={setLayout} />,
    [FIGURES_BOX_KEY]: <DashboardKeyFigures forceUpdateLayout={setLayout} />,
    [MAP_BOX_KEY]: <DashboardMetricMap forceUpdateLayout={setLayout} />,
    [RENTAL_INCOME_BOX_KEY]: (
      <DashboardMetricRentalIncome openCustomFilterMenu={setCustomFilterMenuBundle} forceUpdateLayout={setLayout} />
    ),
  };

  const closeCustomMenu = () => {
    setCustomFilterMenuBundle((previousBundle) => {
      return { ...previousBundle, anchor: null };
    });
  };

  useEffect(() => {
    const getSavedBoxOrder = async () => {
      // Get the order saved in cache
      let boxLayouts = (await getBoxOrder(DashboardBoxType.METRIC)) as Layout[];

      // Check which boxes, he's allowed to see
      const allowedBoxes = Object.entries(permissions).reduce((acc: string[], boxAndPermission) => {
        const [boxKey, permission] = boxAndPermission;
        if (permission) {
          acc.push(boxKey);
        }
        return acc;
      }, []);

      // If a box is allowed but is not in cache, it means that the rights were updated or we switched accounts
      // => Default view !
      const boxAbsent = allowedBoxes.some((allowKey) => !boxLayouts.some((boxLayout) => boxLayout.i === allowKey));
      if (boxAbsent) {
        boxLayouts = defaultMetricBoxOrder;
      }

      // Show only the allowed boxes => filter boxes based on permissions
      const filteredBoxes = boxLayouts.filter((boxLayout) => {
        const boxKey = boxLayout.i;
        return allowedBoxes.includes(boxKey);
      });
      if (boxLayouts.length !== filteredBoxes.length) {
        // We need to reorder the boxes
        const reorderedBoxes = filteredBoxes.map((box, index) => {
          return { ...box, x: index % 2, y: Math.floor(index / 2) * 5 };
        });
        setLayout(reorderedBoxes);
        return;
      }
      setLayout(filteredBoxes);
    };
    if (!permissionsLoading) {
      getSavedBoxOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionsLoading]);

  if (permissionsLoading) {
    return (
      <Grid style={{ display: 'flex', flexWrap: 'wrap' }}>
        {Object.keys(components).map((key) => {
          return <RankingLoader width="100%" key={key} />;
        })}
      </Grid>
    );
  }

  if (isEmpty(layout)) {
    return null;
  }

  return (
    <>
      {/* @ts-ignore */}
      <ResponsiveReactGridLayout
        className="layout"
        breakpoints={{ lg: 1200 }}
        cols={{ lg: 2 }}
        layouts={{ lg: layout }}
        rowHeight={80}
        onLayoutChange={async (currentLayout: Layout[]) => {
          await saveNewLayout(currentLayout, DashboardBoxType.METRIC);
        }}
        isResizable={false}
      >
        {layout.map(({ i }) => (
          <div key={i} data-test={i}>
            <CustomGridItemComponent>{components[i]}</CustomGridItemComponent>
          </div>
        ))}
      </ResponsiveReactGridLayout>
      <CustomFilterMenu {...customFilterMenuBundle} onClose={closeCustomMenu} />
    </>
  );
};

// eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
export const CustomGridItemComponent = React.forwardRef(({ style, className, children }: any, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <div style={{ ...style }} className={className} ref={ref as any}>
      {children}
    </div>
  );
});

export default DashboardMetrics;
