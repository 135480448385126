import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Typography, Grid, Divider, Menu, MenuItem } from '@material-ui/core';
import { AccountLabel, Colors, getAccountLanguageByLanguage } from '@rentguru/commons-utils';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { MenuItemText } from '@up2rent/ui';
import MoreHorizontalIconButton from 'src/components/ui/CustomIconButtons/MoreHorizontalIconButton';

interface AccountLabelRowProps {
  accountLabel: AccountLabel;
  openDisableDialog: (accountLabel: AccountLabel) => void;
  openEditDialog: (accountLabel: AccountLabel) => void;
  divider: boolean;
  disabled?: boolean;
}
const AccountLabelRow: React.FC<AccountLabelRowProps> = ({
  accountLabel,
  openDisableDialog,
  openEditDialog,
  disabled,
  divider = true,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const labelText = getAccountLanguageByLanguage(accountLabel, language);

  return (
    <div style={{ backgroundColor: disabled ? Colors.PORCELAIN_GREY_3 : Colors.CLASSICAL_WHITE }}>
      <Grid container style={{ marginTop: disabled ? 0 : 15, marginBottom: disabled ? 0 : 15 }} alignItems="center">
        <Grid item xs={2}>
          <Typography variant="h6" style={{ fontSize: 14, fontWeight: 600, textAlign: 'left' }}>
            {accountLabel.class}
          </Typography>
        </Grid>
        <Grid item xs={9} style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Typography variant="subtitle1" style={{ fontSize: 14, color: Colors.SLATE_GREY }}>
            {labelText}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          {!disabled && (
            <MoreHorizontalIconButton
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setAnchorEl(event.currentTarget);
              }}
              size="small"
              iconStyle={{ verticalAlign: 'middle', fill: Colors.SLATE_GREY }}
            />
          )}
        </Grid>
        <Menu
          id="action-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          style={{ transform: 'translateY(40px)' }}
        >
          <MenuItem
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              openEditDialog(accountLabel);
              setAnchorEl(null);
            }}
          >
            <MenuItemText primary={formatMessage({ id: 'edit' })} />
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              openDisableDialog(accountLabel);
              setAnchorEl(null);
            }}
          >
            <MenuItemText primary={formatMessage({ id: 'disable' })} deleteOption />
          </MenuItem>
        </Menu>
      </Grid>
      {divider && <Divider />}
    </div>
  );
};

export default AccountLabelRow;
