import React, { CSSProperties } from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import { Grid, Typography } from '@material-ui/core';
import { Colors, getContactNameFromObject, SignatureDocument } from '@rentguru/commons-utils';
import { useContacts } from 'src/hooks/ContactsContext';
import { ReactComponent as CheckIcon } from 'src/icons/check.svg';
import { ReactComponent as CloseIcon } from 'src/icons/close.svg';
import { ReactComponent as HistoryIcon } from 'src/icons/history.svg';
import { CustomPopover } from '@up2rent/ui';

interface LeaseSignaturePopperProps {
  anchorEl: HTMLElement | null | undefined;
  handleClose: () => void;
  open: boolean;
  signatureDocument: SignatureDocument;
  style?: CSSProperties;
}

const LeaseSignaturePopper: React.FC<LeaseSignaturePopperProps> = ({
  open,
  signatureDocument,
  anchorEl,
  handleClose,
}) => {
  const { formatMessage } = useIntl();
  const { getContact } = useContacts();

  return (
    <CustomPopover
      id="lease-signature-popover"
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handleClose}
      disableRestoreFocus
      paperBorderRadius={10}
      paperWidth={'auto'}
      paperPadding={'10px 20px'}
    >
      <Grid container style={{ marginBottom: 5 }}>
        <Grid item xs={4}>
          <Typography style={{ fontSize: 12, color: Colors.BLUE_GREY }}>
            {formatMessage({ id: 'lease.detail.progress.name' })}
          </Typography>
        </Grid>
        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography style={{ fontSize: 12, color: Colors.BLUE_GREY }}>
            {formatMessage({ id: 'lease.detail.progress.opened' })}
          </Typography>
        </Grid>
        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography style={{ fontSize: 12, color: Colors.BLUE_GREY }}>
            {formatMessage({ id: 'lease.detail.progress.signed' })}
          </Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={1} />
      </Grid>
      {signatureDocument.signatureResults.map((signatureResult) => {
        const contact = getContact(signatureResult.contactId);
        return (
          <Grid key={signatureResult.contactId} container style={{ marginTop: 10, alignItems: 'center' }}>
            <Grid item xs={4}>
              <Typography
                style={{
                  fontSize: 16,
                  whiteSpace: 'nowrap',
                  fontWeight: 600,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {getContactNameFromObject(contact!)}
              </Typography>
            </Grid>
            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
              {!isNil(signatureResult.openedDate) ? (
                <CheckIcon style={{ fill: Colors.TURQUOISE_BLUE }} />
              ) : (
                <HistoryIcon />
              )}
            </Grid>
            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
              {!isNil(signatureResult.signed) ? (
                signatureResult.signed ? (
                  <CheckIcon style={{ fill: Colors.TURQUOISE_BLUE }} />
                ) : (
                  <CloseIcon style={{ fill: Colors.BURNING_ORANGE }} />
                )
              ) : (
                <HistoryIcon />
              )}
            </Grid>
          </Grid>
        );
      })}
    </CustomPopover>
  );
};

export default LeaseSignaturePopper;
