import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ElevatedPaper, ContentHeader, ActionButton, TablePlaceHolder } from '@up2rent/ui';
import { IntegrationName } from '@rentguru/commons-utils';
import { Typography } from '@material-ui/core';
import { ExitableLocation, RouteDestination } from '../Routes/Routes';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import EntityDetail from '../ui/EntityDetail/EntityDetail';
import { useIntegrations } from 'src/hooks/IntegrationsContext';
import { isEmpty } from 'lodash';
import { ReactComponent as NoIntegration } from 'src/icons/icon-bigones-integrations.svg';
import IntegrationBox from './Integrations/IntegrationBox';
import IntegrationsDetails from './Integrations/IntegrationsDetails';
import IntegrationDialogs from './Integrations/IntegrationDialogs';

const IntegrationsSettings: React.FC = () => {
  const { formatMessage } = useIntl();
  const [openIntegration, setOpenIntegration] = useState<{ name: IntegrationName; id: string | null } | null>(null);
  const history = useHistory();
  const matchLocal = useRouteMatch({
    path: `${RouteDestination.SETTINGS}/integrations/details`,
  });
  const { state: locationState } = useLocation<ExitableLocation | { from: 'ponto' }>();
  const { loading, error, integrations } = useIntegrations();
  const openDetail = matchLocal;

  useEffect(() => {
    if (!openDetail && locationState && locationState.hasOwnProperty('from')) {
      setOpenIntegration({
        name: IntegrationName.PONTO,
        id: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState]);

  if (loading || !integrations) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{formatMessage({ id: 'loading' })}</Typography>
      </ElevatedPaper>
    );
  }
  if (error) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{error}</Typography>
      </ElevatedPaper>
    );
  }

  const showDetail = () => {
    history.push(`${RouteDestination.SETTINGS}/integrations/details`);
  };

  const hideDetail = () => {
    if (locationState?.hasOwnProperty('goBackUrl') && (locationState as ExitableLocation)?.goBackUrl) {
      history.replace((locationState as ExitableLocation).goBackUrl);
    } else {
      history.push(`${RouteDestination.SETTINGS}/integrations`);
    }
  };

  const onIntegrationClick = (id: string | null, name: IntegrationName) => {
    setOpenIntegration({ name, id });
  };

  return (
    <>
      <ElevatedPaper style={{ margin: 20, maxWidth: 640, paddingBottom: 0 }}>
        <ContentHeader title={formatMessage({ id: 'settings.integrationsMenu' })}>
          <ActionButton onClick={showDetail}>{formatMessage({ id: 'settings.integrations.add' })}</ActionButton>
        </ContentHeader>
        <div style={{ paddingLeft: 30, paddingRight: 30, marginBottom: 30, paddingTop: 30 }}>
          {isEmpty(integrations) && (
            <TablePlaceHolder
              Icon={<NoIntegration width={72} />}
              mainText={formatMessage({ id: 'settings.integrations.noIntegrations' })}
              subText=""
              mainTextStyle={{ maxWidth: 450 }}
              actionButton={
                <ActionButton onClick={showDetail}>
                  {formatMessage({ id: 'settings.integrations.discoverIntegrations' })}
                </ActionButton>
              }
            />
          )}
          {integrations.map((integration) => (
            <IntegrationBox key={integration.id} id={integration.id} onClick={onIntegrationClick} />
          ))}
        </div>
      </ElevatedPaper>
      <IntegrationDialogs
        onClose={() => setOpenIntegration(null)}
        id={openIntegration?.id}
        name={openIntegration?.name}
      />

      <EntityDetail open={Boolean(openDetail)} onClose={hideDetail}>
        <IntegrationsDetails
          onIntegrationClick={onIntegrationClick}
          onClose={() => history.push(`${RouteDestination.SETTINGS}/integrations`)}
        />
      </EntityDetail>
    </>
  );
};

export default IntegrationsSettings;
