/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-lonely-if */
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import { AdditionalClause } from 'src/components/ui/Forms/FormField/AdditionalClausesFields';
import { getDefaultMaintenance } from 'src/components/ui/Forms/FormField/MaintenanceFields';
import { UnitStructure } from 'src/components/ui/Forms/FormField/UnitStructureFields';
import {
  fetchFilesAndGetS3ObjectUrls,
  FilesContext,
  getFileBlobFromUrl,
  getS3ObjectUrls,
} from 'src/hooks/FilesContext';
import {
  Building,
  Contact,
  ContactType,
  File as FileModel,
  Lease,
  LeaseAdditionalClause,
  LeaseContact,
  LeaseInventoryEncoding,
  Technic,
  TechnicMaintenanceHistory,
  TechnicType,
  Unit,
  UnitInventory,
  UnitInventoryRoomType,
  FileCategory,
  EntityType,
  DocumentCategory,
  S3Object,
} from '@rentguru/commons-utils';
import {
  attachFilesToTechnic,
  FileCategoryTypeForm as FilesByCategories,
  fromFileCategoryToTechnic,
  fromTechnicTypeToFileCategory,
  TechnicsContext,
} from '../../../hooks/TechnicsContext';
import { OperationFormValue } from 'src/components/ui/Forms/FormField/AddOperation';
import { eachMonthOfInterval } from 'date-fns';
import { isError, toNumber } from 'lodash';
import { v4 as uuid } from 'uuid';
import {
  isEpbEnergeticPerformanceValid,
  isEpbIssueDateValid,
  isEpbValidityDateValid,
} from 'src/components/ui/Forms/FormField/FileByCategoryFields';
import { AddressContext } from 'src/hooks/AddressContext';
import { FileCategoryContext } from 'src/hooks/FileCategoryContext';

export const handleBuildingAddressUpdates = async (
  originalBuilding: Building,
  formBuilding: Building,
  updateAddress: AddressContext['updateAddress']
) => {
  if (!isNil(originalBuilding.address) && !isNil(originalBuilding.address.region)) {
    // Check if we need to update
    if (
      !isNil(formBuilding.address) &&
      !isNil(formBuilding.address.region) &&
      !isEmpty(formBuilding.address.region) &&
      originalBuilding.address.region !== formBuilding.address.region
    ) {
      await updateAddress(originalBuilding.address, { region: formBuilding.address.region });
    }
  } else if (!isNil(formBuilding.address) && !isEmpty(formBuilding.address.region)) {
    // Create region
    await updateAddress(originalBuilding.address!, { region: formBuilding.address.region });
  }
};

export const createLeaseContacts = (
  contacts: Contact[],
  role: ContactType,
  lease: Lease,
  getContact: (id: string) => Contact | undefined,
  createLeaseContact: (input: Omit<LeaseContact, 'id' | 'clientId' | 'readId'>) => Promise<LeaseContact>
) => {
  let tenantPromises: Promise<LeaseContact>[] = [];
  if (!isNil(contacts) && !isEmpty(contacts) && !isEmpty(contacts[0].id)) {
    tenantPromises = contacts.map((t) => {
      const contact = getContact(t.id);
      return createLeaseContact({ leaseId: lease.id, contactId: contact!.id, contactRole: role });
    });
  }
  return tenantPromises;
};

/**
 * Given an unit structure, this method creates all its new unit inventories.
 * A mapping is made between the old/existing ids and the new ones
 */
const createNewUnitInventoriesOfStructure = async (
  unitStructure: UnitStructure,
  unitInventoriesIdsDic: { [key: string]: string },
  createUnitInventory: (
    input: Omit<UnitInventory, 'id' | 'clientId' | 'readId'>,
    createGeneralStructure?: boolean
  ) => Promise<UnitInventory>
) => {
  await Promise.all(
    unitStructure.unitInventories.map(async (unitInventory) => {
      if (isNil(unitInventory.id) || unitInventory.id.startsWith('NEW-')) {
        // If it's a new one, create it
        const newUnitInventory = await createUnitInventory({
          inside: unitInventory.inside,
          roomType: unitInventory.roomType,
          unitId: unitInventory.unitId,
          roomName: unitInventory.roomName && unitInventory.roomName !== '' ? unitInventory.roomName : undefined,
          showSurface: !isNil(unitInventory.showSurface) ? unitInventory.showSurface : undefined,
          surface: !isNil(unitInventory.surface) ? parseInt(`${unitInventory.surface}`, 10) : undefined,
        });
        unitInventoriesIdsDic[unitInventory.id] = newUnitInventory.id;
      } else {
        unitInventoriesIdsDic[unitInventory.id] = unitInventory.id;
      }
    })
  );
};

export const handleCreationAndDeletionOfUnitInventories = async (
  lease: Lease,
  unitStructures: UnitStructure[],
  originalUnitInventories: UnitInventory[] | undefined,
  createUnitInventory: (
    input: Omit<UnitInventory, 'id' | 'clientId' | 'readId'>,
    createGeneralStructure?: boolean
  ) => Promise<UnitInventory>,
  deleteUnitInventory: (
    id: string,
    deepDeleteLeaseInventoryEncoding: (
      leaseInventoryEncoding: LeaseInventoryEncoding,
      encodingsFiles: FileModel[]
    ) => Promise<void>,
    flaggedDate?: Date
  ) => Promise<void>,
  deepDeleteLeaseInventoryEncoding: (
    leaseInventoryEncoding: LeaseInventoryEncoding,
    encodingsFiles: FileModel[]
  ) => Promise<void>
) => {
  const deletionDate = new Date(new Date(lease.createdAt!).getTime() - 1);
  return handleCreationAndUpdateAndDeletionOfUnitInventories(
    deletionDate,
    unitStructures,
    originalUnitInventories,
    createUnitInventory,
    deleteUnitInventory,
    deepDeleteLeaseInventoryEncoding
  );
};

export const handleCreationAndUpdateAndDeletionOfUnitInventories = async (
  deletionDate: Date,
  unitStructures: UnitStructure[],
  originalUnitInventories: UnitInventory[] | undefined,
  createUnitInventory: (
    input: Omit<UnitInventory, 'id' | 'clientId' | 'readId'>,
    createGeneralStructure?: boolean
  ) => Promise<UnitInventory>,
  deleteUnitInventory: (
    id: string,
    deepDeleteLeaseInventoryEncoding: (
      leaseInventoryEncoding: LeaseInventoryEncoding,
      encodingsFiles: FileModel[]
    ) => Promise<void>,
    flaggedDate?: Date
  ) => Promise<void>,
  deepDeleteLeaseInventoryEncoding: (
    leaseInventoryEncoding: LeaseInventoryEncoding,
    encodingsFiles: FileModel[]
  ) => Promise<void>,
  updateUnitInventory?: (original: UnitInventory, updates: Partial<UnitInventory>) => Promise<UnitInventory>
) => {
  // Mapping between formik temp ids and ids that were created in database
  const unitInventoriesIdsDic: { [key: string]: string } = {};
  const createUnitInventoriesPromises = unitStructures.reduce((acc: Promise<void>[], unitStructure) => {
    const unitInventoriesPromise = createNewUnitInventoriesOfStructure(
      unitStructure,
      unitInventoriesIdsDic,
      createUnitInventory
    );
    acc.push(unitInventoriesPromise);
    return acc;
  }, []);
  const deleteUnitInventoriesPromises: Promise<void | UnitInventory>[] = [];
  // Inventories no more there, delete them
  if (!isNil(originalUnitInventories)) {
    originalUnitInventories.forEach((originalUnitInventory) => {
      const formUnitStructure = unitStructures.find(
        (unitStructure) => unitStructure.roomType === originalUnitInventory.roomType
      );
      if (!isNil(formUnitStructure)) {
        const formUnitInventory = formUnitStructure.unitInventories.find(
          (unitInventory) => unitInventory.id === originalUnitInventory.id
        );
        if (isNil(formUnitInventory)) {
          deleteUnitInventoriesPromises.push(
            deleteUnitInventory(originalUnitInventory.id, deepDeleteLeaseInventoryEncoding, deletionDate)
          );
        } else {
          // Check if needs update
          if (updateUnitInventory && !isEqual(formUnitInventory, originalUnitInventory)) {
            deleteUnitInventoriesPromises.push(
              updateUnitInventory(originalUnitInventory, {
                roomName: !isNil(formUnitInventory.roomName) ? formUnitInventory.roomName : undefined,
                showSurface: !isNil(formUnitInventory.showSurface) ? formUnitInventory.showSurface : undefined,
                surface:
                  !isNil(formUnitInventory.surface) && parseInt(`${formUnitInventory.surface}`, 10) !== 0
                    ? parseInt(`${formUnitInventory.surface}`, 10)
                    : undefined,
                kitchenEquipment: !isNil(formUnitInventory.kitchenEquipment)
                  ? formUnitInventory.kitchenEquipment
                  : undefined,
                privateBatthRoom:
                  (formUnitInventory.roomType === UnitInventoryRoomType.BATHROOM ||
                    formUnitInventory.roomType === UnitInventoryRoomType.SHOWER) &&
                  !isNil(formUnitInventory.privateBatthRoom)
                    ? formUnitInventory.privateBatthRoom
                    : false,
              })
            );
          }
        }
      }
    });
  }
  await Promise.all([...createUnitInventoriesPromises, ...deleteUnitInventoriesPromises]);
  return unitInventoriesIdsDic;
};

export const createAdditionalClauses = (
  additionalClauses: AdditionalClause[],
  lease: Lease,
  createAdditionalClause: (
    input: Omit<LeaseAdditionalClause, 'id' | 'clientId' | 'readId'>
  ) => Promise<LeaseAdditionalClause>
) => {
  let additionalClausesPromises: Promise<LeaseAdditionalClause>[] = [];
  if (!isEmpty(additionalClauses)) {
    additionalClausesPromises = additionalClauses.map((additionalClause) =>
      createAdditionalClause({ leaseId: lease.id, title: additionalClause.title!, body: additionalClause.body })
    );
  }
  return additionalClausesPromises;
};

const isValidEpbFileCategory = (fileCategory: FilesByCategories) =>
  fileCategory.category.fileCategory === FileCategory.PEB &&
  isEpbEnergeticPerformanceValid(fileCategory.PebEnergeticPerformance) &&
  isEpbValidityDateValid(fileCategory.PebValidityDate) &&
  isEpbIssueDateValid(fileCategory.PebIssueDate);

export const handleEditFilesByCategory = async (
  filesByCategories: FilesByCategories[],
  originalFilesByCategories: FilesByCategories[],
  originalUnit: Unit,
  originalBuilding: Building,
  lease: Lease,
  communicationSettingsProfileId: string,
  createTechnic: TechnicsContext['createTechnic'],
  deleteTechnic: TechnicsContext['deleteTechnic'],
  createFile: FilesContext['createFile'],
  updateFile: FilesContext['updateFile'],
  deleteFile: FilesContext['deleteFile']
) => {
  // For the files without technics
  const remainingClassicFiles: S3Object[] = [];
  let originalClassicFiles: S3Object[] = [];
  const allOperationPromises: Promise<Technic | FileModel | void | null>[] = [];

  // First let us push all the original classic files
  originalFilesByCategories.forEach((originalFileByCategory) => {
    if (isNil(fromFileCategoryToTechnic(originalFileByCategory.category.fileCategory))) {
      originalClassicFiles = [...originalClassicFiles, ...(originalFileByCategory.files as S3Object[])];
    }
  });

  // Then let's handle the non-classic files i.e. the technical files
  filesByCategories
    .filter(
      (fileCategory) =>
        isValidEpbFileCategory(fileCategory) || (!isNil(fileCategory.files) && fileCategory.ignore === false)
    )
    .forEach((fileCategory) => {
      const { category, files, ignore: _ignore, ...rest } = fileCategory;
      const originalFilesByCategory = originalFilesByCategories.find((original) => original.category === category);
      const {
        unit: _unit,
        building: _building,
        lease: _lease,
        id: _id,
        createdAt: _createdAt,
        updatedAt: _updatedAt,
        _version,
        leaseId: _leaseId,
        ...restWithoutLink
      } = rest;
      const technicCategory = fromFileCategoryToTechnic(category.fileCategory);
      const filesModified = !isNil(originalFilesByCategory) && !isEqual(files, originalFilesByCategory.files);
      // The file category belongs to a technic category => create technic and link files to it
      if (!isNil(technicCategory)) {
        const { PebEnergeticPerformance, PebIssueDate, PebReportNumber, PebValidityDate, contactId, unitId } =
          fileCategory;
        const {
          PebEnergeticPerformance: originalPebEnergeticPerformance,
          PebIssueDate: originalPebIssueDate,
          PebReportNumber: originalPebReportNumber,
          PebValidityDate: originalPebValidityDate,
          contactId: originalContactId,
          unitId: originalUnitId,
        } = originalFilesByCategory ?? {
          PebEnergeticPerformance: null,
          PebIssueDate: null,
          PebReportNumber: null,
          PebValidityDate: null,
          contactId,
          unitId: null,
        };
        const epbModified =
          isValidEpbFileCategory(fileCategory) &&
          !isEqual(
            { PebEnergeticPerformance, PebIssueDate, PebReportNumber, PebValidityDate, contactId, unitId },
            {
              PebEnergeticPerformance: originalPebEnergeticPerformance,
              PebIssueDate: originalPebIssueDate,
              PebReportNumber: originalPebReportNumber,
              PebValidityDate: originalPebValidityDate,
              contactId: originalContactId,
              unitId: originalUnitId,
            }
          );
        const technicPromises: Promise<Technic | void>[] = [];
        // There are files now && (they are different from those in the past || simply no files in the past)
        if (epbModified || (!isEmpty(files) && (isNil(originalFilesByCategory) || filesModified))) {
          technicPromises.push(
            createTechnic({
              ...restWithoutLink,
              leaseId: lease.id,
              unitId: originalUnit.id,
              communicationSettingsProfileId,
              type: technicCategory,
            })
          );

          if (!unitId) {
            technicPromises.push(
              createTechnic({
                ...restWithoutLink,
                buildingId: originalBuilding.id,
                communicationSettingsProfileId,
                type: TechnicType.PEB,
              })
            );
          } else {
            technicPromises.push(
              createTechnic({
                ...restWithoutLink,
                unitId: originalUnit.id,
                communicationSettingsProfileId,
                type: technicCategory,
              })
            );
          }
        }
        // The files have been modified => delete previous technic and create new ones if there are new files
        if ((epbModified && originalFilesByCategory) || filesModified) {
          technicPromises.push(deleteTechnic(rest.id!));
        }

        const createTechnicsAndHandleFilesPromise = Promise.all(technicPromises).then((technicResult) => {
          for (const file of files) {
            if (!('url' in file)) {
              // technics were created because of the new file, so use their ids
              const technicLeaseId = (technicResult[0] as Technic).id;
              const technicUnitId = (technicResult[1] as Technic).id;
              // The file is a brand new one added by the User. So create it and link it the new lease and technic
              createFile(file.file, EntityType.TECHNIC, technicUnitId, category.id);
              createFile(file.file, EntityType.TECHNIC, technicLeaseId, category.id);
            }
          }
          if (!isNil(originalFilesByCategory)) {
            for (const originalFile of originalFilesByCategory.files) {
              // The original file is not in the list of files anymore => it should be deleted
              const fileShouldBeDeleted = isNil(files.find((f) => isEqual(f, originalFile)));
              if (fileShouldBeDeleted) {
                deleteFile(originalFile as S3Object);
              }
            }
          }
        });
        allOperationPromises.push(createTechnicsAndHandleFilesPromise);
      } else {
        // Create the non-technic files i.e. the classic files
        for (const file of files) {
          if (!('id' in file)) {
            // We assume that the category is allready existing
            allOperationPromises.push(createFile(file.file!, EntityType.LEASE, lease.id, fileCategory.category.id));
            if (fileCategory.category.fileCategory === FileCategory.FURNITURE_INVENTORY) {
              allOperationPromises.push(
                createFile(file.file!, EntityType.UNIT, originalUnit.id, fileCategory.category.id)
              );
            }
          } else {
            remainingClassicFiles.push({
              ...file,
              categoryId: file.categoryId,
            });
          }
        }
      }
    });
  // Last thing to do is to handle the deletion of the classic files
  for (const originalFile of originalClassicFiles) {
    const formFile = remainingClassicFiles.find((f) => f.id === originalFile.id);
    // The original file is not in the remaining old files => delete
    if (isNil(formFile)) {
      allOperationPromises.push(deleteFile(originalFile));
    }
    // the original file is still in the remaining files => check if category has not been changed
    else {
      if (formFile.category?.id !== originalFile.category?.id) {
        allOperationPromises.push(updateFile(originalFile, { categoryId: formFile.category?.id }));
      }
    }
  }

  await Promise.all(allOperationPromises);
};

export const handleFilesByCategory = (
  filesByCategories: FilesByCategories[],
  originalFilesByCategories: FilesByCategories[],
  originalUnit: Unit,
  currentBuilding: Building,
  lease: Lease,
  communicationSettingsProfileId: string,
  createTechnic: TechnicsContext['createTechnic'],
  createFile: FilesContext['createFile']
) => {
  return filesByCategories
    .filter(
      (fileCategory) =>
        isValidEpbFileCategory(fileCategory) ||
        (!isNil(fileCategory.files) && !isEmpty(fileCategory.files) && fileCategory.ignore === false)
    )
    .map(async (fileCategory) => {
      const fileCreationPromises: Promise<FileModel | null>[] = [];
      const { category, files, ignore: _ignore, ...rest } = fileCategory;
      const {
        unit: _unit,
        building: _builidng,
        contact: _contact,
        id: _id,
        createdAt: _createdAt,
        updatedAt: _updatedAt,
        _version,
        ...restWithoutLink
      } = rest;
      const technicCategory = fromFileCategoryToTechnic(category.fileCategory);
      // The file category belongs to a technic category => create technic and link files to it
      if (!isNil(technicCategory)) {
        const technicPromises: Promise<Technic>[] = [];
        // Create the technic for the lease
        technicPromises.push(
          createTechnic({
            ...restWithoutLink,
            communicationSettingsProfileId,
            buildingId: restWithoutLink.unitId ? undefined : currentBuilding.id,
            leaseId: lease.id,
            type: technicCategory,
          })
        );
        // Is the technic a new one or did the user just used the proposed value?
        const brandNewTechnic = isNil(originalFilesByCategories.find((ofb) => isEqual(ofb, fileCategory)));
        // If it is a new technic create the technic for the unit as well
        if (brandNewTechnic) {
          if (technicCategory === TechnicType.PEB && !fileCategory.unitId) {
            technicPromises.push(
              createTechnic({
                ...restWithoutLink,
                communicationSettingsProfileId,
                buildingId: currentBuilding.id,
                type: TechnicType.PEB,
              })
            );
          } else {
            technicPromises.push(
              createTechnic({
                ...restWithoutLink,
                communicationSettingsProfileId,
                unitId: originalUnit.id,
                type: technicCategory,
              })
            );
          }
        }
        const technicResults = await Promise.all(technicPromises);
        for (const file of files) {
          let convertedFile: File;
          // Already existing file proposed by the unit technics => copy it and link it to the lease
          if ('url' in file) {
            const fileFromUrl = await getFileBlobFromUrl(file.url);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            convertedFile = new File([fileFromUrl as any], file.fileName, {
              type: file.mimeType!,
            });
          }
          // The file does not exist, it was added in the form
          else {
            convertedFile = file.file;
          }
          const technicLeaseId = technicResults[0].id;
          // Create the file for the lease technic
          fileCreationPromises.push(
            createFile(convertedFile, EntityType.TECHNIC, technicLeaseId, fileCategory.category.id)
          );
          // If it is a new technic create the files for the technic unit as well
          if (brandNewTechnic) {
            const technicUnitId = technicResults[1].id;
            fileCreationPromises.push(
              createFile(convertedFile, EntityType.TECHNIC, technicUnitId, fileCategory.category.id)
            );
          }
        }
      } else {
        // No technic just add files to the lease
        for (const file of files) {
          let brandNewFile: boolean;
          // Already existing file proposed by the unit => copy it and link it to the lease
          let convertedFile: File;
          // Already existing file proposed by the unit technics => copy it and link it to the lease
          if ('url' in file) {
            brandNewFile = false;
            const fileFromUrl = await getFileBlobFromUrl(file.url);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            convertedFile = new File([fileFromUrl as any], file.fileName, {
              type: file.mimeType!,
            });
          }
          // The file does not exist, it was added in the form
          else {
            brandNewFile = true;
            convertedFile = file.file;
          }

          fileCreationPromises.push(createFile(convertedFile, EntityType.LEASE, lease.id, fileCategory.category.id));
          // If it is a brand new file create the files for the unit as well
          if (brandNewFile && category.fileCategory === FileCategory.FURNITURE_INVENTORY) {
            fileCreationPromises.push(createFile(convertedFile, EntityType.UNIT, originalUnit!.id, category.id));
          }
        }
      }
      await Promise.all(fileCreationPromises);
    });
};

export const handleAddEditSmokeDetectors = async (
  smokeDetectors: Technic[],
  originalSmokeDetectors: Technic[],
  lease: Lease,
  currentUnit: Unit,
  unitInventoriesIdsDic: {
    [key: string]: string;
  },
  createTechnic: TechnicsContext['createTechnic'],
  deleteTechnic: TechnicsContext['deleteTechnic']
) => {
  const detectorsPromises: Promise<Technic>[] = [];
  /* Smoke detectors */
  for (const smokeDetector of smokeDetectors) {
    const notOriginalDetector = isNil(originalSmokeDetectors.find((osd) => isEqual(osd, smokeDetector)));
    if (notOriginalDetector || (!notOriginalDetector && isNil(smokeDetector.leaseId))) {
      // Not yet there => New detector
      const brandNewDetector = !('id' in smokeDetector && !isEmpty(smokeDetector.id));
      const newDetectorId = uuid();

      const {
        building: _building,
        unit: _unit,
        lease: _lease,
        detectorUnitInventoryId,
        id: _id,
        createdAt: _createdAt,
        updatedAt: _updatedAt,
        _version,
        ...smokeDetectorWithoutLink
      } = smokeDetector;
      let smokeDetectorUnitInventoryId: string | undefined;
      if (!isNil(detectorUnitInventoryId)) {
        smokeDetectorUnitInventoryId = unitInventoriesIdsDic[detectorUnitInventoryId];
      }
      const createSmokeTechnicForLeasePromise = createTechnic({
        ...smokeDetectorWithoutLink,
        ...(!isNil(smokeDetectorUnitInventoryId) && { detectorUnitInventoryId: smokeDetectorUnitInventoryId }),
        leaseId: lease.id,
        unitId: currentUnit.id,
        type: TechnicType.DETECTOR,
        originalTechnicId: newDetectorId,
      });
      detectorsPromises.push(createSmokeTechnicForLeasePromise);
      if (brandNewDetector) {
        const createSmokeTechnicForUnitPromise = createTechnic({
          ...smokeDetectorWithoutLink,
          id: newDetectorId,
          ...(!isNil(smokeDetectorUnitInventoryId) && { detectorUnitInventoryId: smokeDetectorUnitInventoryId }),
          type: TechnicType.DETECTOR,
          unitId: currentUnit.id,
        });
        detectorsPromises.push(createSmokeTechnicForUnitPromise);
      }
    }
  }
  for (const originalSmokeDetector of originalSmokeDetectors) {
    const mustDeleteSmokeDetector = isNil(smokeDetectors.find((sm) => sm.id === originalSmokeDetector.id));
    if (mustDeleteSmokeDetector && originalSmokeDetector.leaseId) {
      detectorsPromises.push(deleteTechnic(originalSmokeDetector.id));
    }
  }
};

export const createSmokeDetectors = (
  smokeDetectors: Technic[],
  originalSmokeDetectors: Technic[],
  lease: Lease,
  originalUnit: Unit,
  createTechnic: (input: Omit<Technic, 'id' | 'clientId' | 'readId'>) => Promise<Technic>,
  unitInventoriesIdsDic: {
    [key: string]: string;
  }
) => {
  const detectorsPromises: Promise<Technic>[] = [];
  for (const smokeDetector of smokeDetectors) {
    const {
      unit: _unit,
      building: _building,
      detectorUnitInventoryId,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      _version,
      ...smokeDetectorWithoutLink
    } = smokeDetector;
    let smokeDetectorUnitInventoryId: string | undefined;
    if (!isNil(detectorUnitInventoryId)) {
      smokeDetectorUnitInventoryId = unitInventoriesIdsDic[detectorUnitInventoryId];
    }

    const createSmokeTechnicForLeasePromise = createTechnic({
      ...smokeDetectorWithoutLink,
      ...(!isNil(smokeDetectorUnitInventoryId) && { detectorUnitInventoryId: smokeDetectorUnitInventoryId }),
      leaseId: lease.id,
      type: TechnicType.DETECTOR,
    });
    detectorsPromises.push(createSmokeTechnicForLeasePromise);
    // Is the technic a new one or did the user just used the proposed value?
    const brandNewDetector = isNil(originalSmokeDetectors.find((osd) => isEqual(osd, smokeDetector)));
    if (brandNewDetector) {
      const createSmokeTechnicForUnitPromise = createTechnic({
        ...smokeDetectorWithoutLink,
        ...(!isNil(smokeDetectorUnitInventoryId) && { detectorUnitInventoryId: smokeDetectorUnitInventoryId }),
        unitId: originalUnit.id,
        type: TechnicType.DETECTOR,
      });
      detectorsPromises.push(createSmokeTechnicForUnitPromise);
    }
  }
  return detectorsPromises;
};

const deepDeleteTechnicIfOnLeaseId = async (
  technic: Technic,
  deepDeleteTechnic: TechnicsContext['deepDeleteTechnic']
) => {
  if (technic.leaseId) {
    await deepDeleteTechnic(technic, false);
  }
};

export const handleAddEditTechnicWithMaintenance = (
  technics: Technic[],
  originalTechnics: Technic[],
  lease: Lease,
  includeChimney: boolean,
  includeFuelTank: boolean,
  includeHeating: boolean,
  createFile: FilesContext['createFile'],
  createTechnic: TechnicsContext['createTechnic'],
  updateTechnic: TechnicsContext['updateTechnic'],
  getDocumentCategory: FileCategoryContext['getDocumentCategoryByFileCategory'],
  unitInventoriesIdsDic: {
    [key: string]: string;
  },
  deepDeleteTechnic: TechnicsContext['deepDeleteTechnic']
) => {
  const technicsCreationAndUpdatePromises = technics.map(async (technic) => {
    const ignoreTechnic = technicShouldBeIgnored(technic, includeChimney, includeFuelTank, includeHeating);
    if (ignoreTechnic) {
      // Ignored = deep delete of the lease technic

      return await deepDeleteTechnicIfOnLeaseId(technic, deepDeleteTechnic);
    }

    const originalTechnic = originalTechnics.find((ot) => ot.id === technic.id);
    const technicAlreadyExisted = !isNil(originalTechnic);
    if (!technicAlreadyExisted || (technicAlreadyExisted && isNil(technic.leaseId))) {
      // New technic = Complete creation (on unit/building and on lease)
      // OR Not yet on lease, handleTechnicCompleteCreation will take care of non lease technic and won't create it

      return await handleTechnicCompleteCreation(
        technic,
        lease,
        createFile,
        createTechnic,
        updateTechnic,
        getDocumentCategory,
        unitInventoriesIdsDic
      );
    }
    // The technic already existed
    const defaultMaintenance = getDefaultMaintenance(technic);
    if (!isNil(defaultMaintenance) && !isNil(defaultMaintenance.date)) {
      // it still has a valid maintenance
      if (!isNil(defaultMaintenance.id)) {
        // It's a new maintenance
        const { id: _id, file: _file, ...maintenance } = defaultMaintenance;

        // Add the maintenance on the lease technic (with its file)
        return await addMaintenanceOnLeaseTechnic(
          technic,
          maintenance,
          createFile,
          updateTechnic,
          getDocumentCategory,
          !isNil(defaultMaintenance.file) ? defaultMaintenance.file.file : undefined
        );
      }
      return;
    }

    // Maintenance is no more valid so we are deleting it on lease
    return await removeMaintenanceOnLeaseTechnic(technic, updateTechnic);
  });
  const technicsDeletionPromises = originalTechnics.map(async (originalTechnic) => {
    const mustDeleteTechnic = !technics.some((t) => t.id === originalTechnic.id);
    if (mustDeleteTechnic) {
      return await deepDeleteTechnicIfOnLeaseId(originalTechnic, deepDeleteTechnic);
    }
  });
  return [...technicsCreationAndUpdatePromises, ...technicsDeletionPromises];
};

export const handleAddUtilityProvidersToLeaseId = (
  utilityProviders: Technic[],
  lease: Lease,
  createTechnic: TechnicsContext['createTechnic']
) => {
  return utilityProviders.map(async (utilityProvider) => {
    if (isNil(utilityProvider.leaseId) && !isNil(utilityProvider.unitId)) {
      return await createLeaseTechnic(
        { ...utilityProvider, contractNumber: null, utilityHistory: [], contactId: null },
        lease,
        utilityProvider.id,
        {},
        createTechnic
      );
    }
  });
};

export const technicShouldBeIgnored = (
  technic: Technic,
  includeChimney: boolean,
  includeFuelTank: boolean,
  includeHeating: boolean
) => {
  if (technic.type === TechnicType.CHIMNEY) {
    return !includeChimney;
  }
  if (technic.type === TechnicType.FUELTANK) {
    return !includeFuelTank;
  }
  if (technic.type === TechnicType.HEATING) {
    return !includeHeating;
  }
  return true;
};

export const createTechnicIfNotExists = async (
  technic: Technic,
  unitInventoriesIdsDic: {
    [key: string]: string;
  },
  createTechnic: TechnicsContext['createTechnic']
) => {
  const {
    id,
    maintenanceHistory: _maintenanceHistory,
    chimneyUnitInventoryId,
    building,
    unit,
    id: _id,
    createdAt: _createdAt,
    updatedAt: _updatedAt,
    _version,
    ...restWithoutLinks
  } = technic;
  if (id.startsWith('NEW-')) {
    if (technic.type === TechnicType.CHIMNEY && !isNil(chimneyUnitInventoryId)) {
      const newChimneyUnitInventoryId = unitInventoriesIdsDic[chimneyUnitInventoryId];
      return await createTechnic({
        ...restWithoutLinks,
        chimneyUnitInventoryId: newChimneyUnitInventoryId,
        buildingId: building?.id,
        unitId: unit?.id,
      });
    }
    return await createTechnic({ ...restWithoutLinks, buildingId: building?.id, unitId: unit?.id });
  }
  return technic;
};

export const createLeaseTechnic = async (
  technic: Technic,
  lease: Lease,
  originalTechnicId: string,
  unitInventoriesIdsDic: {
    [key: string]: string;
  },
  createTechnic: TechnicsContext['createTechnic']
) => {
  const {
    id: _id,
    maintenanceHistory: _,
    chimneyUnitInventoryId,
    building,
    unit,
    files: _files,
    createdAt: _createdAt,
    updatedAt: _updatedAt,
    _version,
    ...restWithoutLinks
  } = technic;
  if (technic.type === TechnicType.CHIMNEY && !isNil(chimneyUnitInventoryId)) {
    const newChimneyUnitInventoryId = unitInventoriesIdsDic[chimneyUnitInventoryId];
    return await createTechnic({
      ...restWithoutLinks,
      chimneyUnitInventoryId: newChimneyUnitInventoryId,
      originalTechnicId,
      leaseId: lease.id,
      buildingId: building?.id,
      unitId: unit?.id,
    });
  }
  return await createTechnic({ ...restWithoutLinks, leaseId: lease.id, originalTechnicId });
};

/**
 * Create the maintenance file thanks to the file in argument.
 * If the file is a FileModel we first fetch it in the database
 */
export const addMaintenanceOnLeaseTechnic = async (
  technicLease: Technic,
  maintenance: TechnicMaintenanceHistory,
  createFile: FilesContext['createFile'],
  updateTechnic: TechnicsContext['updateTechnic'],
  getDocumentCategory: FileCategoryContext['getDocumentCategoryByFileCategory'],
  file?: File | FileModel
) => {
  /* Create the maintenance file (copy of the original) of the technic */
  let fileToCreate: File | undefined; // The original File
  if (!isNil(file)) {
    if ('bucket' in file) {
      // Fetch the file on S3
      const S3File = (await getS3ObjectUrls([file]))[0];
      if (!isNil(S3File)) {
        // And convert it to a File
        const fileBlob = await getFileBlobFromUrl(S3File.url);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        fileToCreate = new File([fileBlob as any], S3File.fileName, {
          type: S3File.mimeType!,
        });
      }
    } else {
      fileToCreate = file; // We already have the file
    }
  }
  let fileId: string | undefined;
  if (!isNil(fileToCreate)) {
    const fileCategory = fromTechnicTypeToFileCategory(technicLease.type) as FileCategory;
    const documentCategory = getDocumentCategory(fileCategory);
    const file = await createFile(fileToCreate, EntityType.TECHNIC, technicLease.id, documentCategory?.id);
    if (!isNil(file)) fileId = file.id;
  }
  // Update the lease technic with the correct maintenance with correct fileId
  await updateTechnic(technicLease, { maintenanceHistory: [{ ...maintenance, fileId }] });
};

const removeMaintenanceOnLeaseTechnic = async (
  technicLease: Technic,
  updateTechnic: TechnicsContext['updateTechnic']
) => {
  // Update the lease technic with an empty
  await updateTechnic(technicLease, { maintenanceHistory: [] });
};

export const handleTechnicCompleteCreation = async (
  technic: Technic,
  lease: Lease,
  createFile: FilesContext['createFile'],
  createTechnic: TechnicsContext['createTechnic'],
  updateTechnic: TechnicsContext['updateTechnic'],
  getDocumentCategory: FileCategoryContext['getDocumentCategoryByFileCategory'],
  unitInventoriesIdsDic: {
    [key: string]: string;
  }
) => {
  const createdTechnic = await createTechnicIfNotExists(technic, unitInventoriesIdsDic, createTechnic);

  // Create the technic that will go on the lease => Necessary because the file will be linked to it
  const technicLease = await createLeaseTechnic(
    technic,
    lease,
    createdTechnic.id,
    unitInventoriesIdsDic,
    createTechnic
  );

  const defaultMaintenance = getDefaultMaintenance(technic);
  // Check if there is at least one non empty maintenance
  if (!isNil(defaultMaintenance) && !isNil(defaultMaintenance.date)) {
    /* New maintenance => first handle creation on unit and building */
    if (!isNil(defaultMaintenance.id)) {
      // First step is to create its file
      let fileId: string | undefined;
      if (!isNil(defaultMaintenance.file)) {
        const fileCategory = fromTechnicTypeToFileCategory(createdTechnic.type) as FileCategory;
        const documentCategory = getDocumentCategory(fileCategory);
        const file = await createFile(
          defaultMaintenance.file.file,
          EntityType.TECHNIC,
          createdTechnic.id,
          documentCategory?.id
        );
        if (!isNil(file)) fileId = file.id;
      }
      const newMaintenance = { date: defaultMaintenance.date, contactId: defaultMaintenance.contactId, fileId };
      const newMaintenanceHistory = [
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...technic.maintenanceHistory!.filter((m) => isNil((m as any).id)),
        newMaintenance,
      ];
      // Update technic with new maintenance history
      await updateTechnic(createdTechnic, { maintenanceHistory: newMaintenanceHistory });
    }

    let maintenanceFile: File | FileModel | undefined;
    if (!isNil(defaultMaintenance.file)) {
      maintenanceFile = defaultMaintenance.file.file;
    } else if (!isNil(technic.files) && !isNil(defaultMaintenance.fileId)) {
      maintenanceFile = technic.files!.find((t) => t.id === defaultMaintenance.fileId);
    }
    // Add the maintenance on the lease technic (with its file)
    const { id: _id, file: _file, ...maintenance } = defaultMaintenance;
    await addMaintenanceOnLeaseTechnic(
      technicLease,
      maintenance,
      createFile,
      updateTechnic,
      getDocumentCategory,
      maintenanceFile
    );
  }
};

export const fetchEpbFiles = async (
  epb: Technic | undefined,
  getDocumentCategory: FileCategoryContext['getDocumentCategoryByFileCategory']
): Promise<FilesByCategories | undefined> => {
  if (isNil(epb)) {
    return undefined;
  }
  const epbWithFiles = await attachFilesToTechnic([epb]);

  const category = getDocumentCategory(FileCategory.PEB);

  return {
    category: category as DocumentCategory,
    ...epb,
    files: (epbWithFiles[0].files as S3Object[]) ?? ([] as S3Object[]),
    ignore: false,
  };
};

export const fetchLeaseS3Files = async (leaseId?: string) => {
  if (!leaseId) {
    return [];
  }
  const leaseFiles = await fetchFilesAndGetS3ObjectUrls(EntityType.LEASE, leaseId);
  if (isError(leaseFiles)) {
    return [];
  }

  return leaseFiles.reduce((acc: FilesByCategories[], s3Object: S3Object) => {
    const alreadyExistingCategory = acc.find((fileCategory) => fileCategory.category === s3Object.category);
    if (isNil(alreadyExistingCategory)) {
      if (!isNil(s3Object.category))
        acc.push({
          category: s3Object.category,
          files: [s3Object],
          ignore: false,
        });
    } else {
      alreadyExistingCategory.files.push(s3Object);
    }
    return acc;
  }, []);
};

export const getPotentialErrorIndexInDiscounts = (rentalPrice: number, listOfDiscounts?: OperationFormValue[]) => {
  const amountDistributedInMonths: {
    year: number;
    month: number;
    amount: number;
    indexes: number[];
  }[] = [];
  (listOfDiscounts ?? []).forEach((operation, index) => {
    const allMonths = eachMonthOfInterval({
      start: new Date(operation.startDate!),
      end: new Date(operation.endDate!),
    });
    allMonths.forEach((monthDate) => {
      const potentialIndex = amountDistributedInMonths.findIndex(
        (object) => object.year === monthDate.getFullYear() && object.month === monthDate.getMonth()
      );
      if (potentialIndex !== -1) {
        amountDistributedInMonths[potentialIndex].amount += toNumber(operation.amount);
        amountDistributedInMonths[potentialIndex].indexes.push(index);
      } else {
        amountDistributedInMonths.push({
          amount: toNumber(operation.amount),
          indexes: [index],
          month: monthDate.getMonth(),
          year: monthDate.getFullYear(),
        });
      }
    });
  });

  const potentialErrorIndex = amountDistributedInMonths.findIndex((object) => object.amount > rentalPrice);

  return { potentialErrorIndex, amountDistributedInMonths };
};
