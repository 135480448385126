import React from 'react';
import { useIntl } from 'react-intl';
import { getContactNameFromObject, Contact } from '@rentguru/commons-utils';
import { FieldFilterSelector } from 'src/components/ui/FieldFilterSelector';
import { ContactsFilters, FilterEntity, useFilters } from 'src/hooks/FiltersContext';
import FieldFilterResetButton from '../ui/FieldFilterResetButton';

interface ContactsFilterProps {
  contacts: Contact[];
  resetAllFilters: () => void;
  type: FilterEntity;
  containerStyle?: React.CSSProperties;
}
const ContactsFilter: React.FC<ContactsFilterProps> = ({
  contacts,
  resetAllFilters,
  type,
  containerStyle = {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 15,
    marginBottom: 15,
    marginRight: 24,
  },
}) => {
  const { formatMessage } = useIntl();
  const {
    contactsFilters: { filters },
    updateFilters,
    resetFilters,
  } = useFilters();

  const initialContactsSelectedItems = filters.find((f) => f.name === 'name');
  const initialContactsSelectedItemsOrNull = initialContactsSelectedItems
    ? initialContactsSelectedItems.items.map((b) => ({ value: b }))
    : null;

  // Filter menu elements
  const contactNames = contacts.map((contact) => ({
    value: contact.id,
    label: getContactNameFromObject(contact),
  }));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFilterChange = (selectedItems: any[], filterName: string, fieldName: string, menuClosed: boolean) => {
    if (menuClosed) {
      return; // Don't set filter again
    }
    const filter = { name: filterName, field: fieldName, items: selectedItems.map((i) => i.value) };
    let newFilters = filters;

    const index = newFilters.findIndex((f) => f.name === filterName);
    if (index === -1) {
      newFilters = [...newFilters, filter];
    } else {
      newFilters = [...newFilters.filter((f) => f.name !== filterName), filter];
    }
    updateFilters<ContactsFilters>(type, {
      filters: newFilters,
    });
  };

  return (
    <div style={containerStyle}>
      <FieldFilterSelector
        label={formatMessage({ id: 'building.columnHeader.name' })}
        multipleSelection
        showResetButton
        filterName="name"
        fieldName="id"
        textReset={formatMessage({ id: 'reinitFilter' })}
        textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
        textSearchField={formatMessage({ id: 'search' })}
        textSelectAll={formatMessage({ id: 'selectAll' })}
        onChange={handleFilterChange}
        onReset={() => resetFilters(type)}
        initialSelectedItems={initialContactsSelectedItemsOrNull}
        scrollable
      >
        {contactNames}
      </FieldFilterSelector>
      <FieldFilterResetButton resetFilterValues={resetAllFilters} />
    </div>
  );
};

export default ContactsFilter;
