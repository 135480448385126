import { max } from 'd3';
import {
  differenceInDays,
  differenceInYears,
  eachDayOfInterval,
  eachMonthOfInterval,
  eachYearOfInterval,
} from 'date-fns';
import isNil from 'lodash/isNil';

export const getTicksWithFormatForTimeWindow = (fromDate: Date, toDate: Date) => {
  let tickValues: number[] = [];
  let tickFormatString: string;
  let tooltipFormat: string;
  const differenceInYearsValue = differenceInYears(toDate, fromDate);
  if (differenceInYearsValue > 0) {
    tickValues = eachYearOfInterval({ start: fromDate, end: toDate }).map((d) => d.getTime());
    tickFormatString = 'yyyy';
    tooltipFormat = 'yyyy';
  } else {
    const differenceInDaysValue = differenceInDays(toDate, fromDate);
    if (differenceInDaysValue > 10) {
      tickValues = eachMonthOfInterval({ start: fromDate, end: toDate }).map((d) => d.getTime());
      tickFormatString = 'MMM';
      tooltipFormat = 'LLLL yyyy';
    } else {
      tickValues = eachDayOfInterval({ start: fromDate, end: toDate }).map((d) => d.getTime());
      tickFormatString = 'd/LL';
      tooltipFormat = 'dd LLLL yyyy';
    }
  }
  // Now force the first tick and last tick to be the filter interval
  tickValues[0] = fromDate.getTime();
  return { tickValues, tickFormatString, tooltipFormat };
};

export const getMaxYInBase10 = (datas: { x: number; y: number }[][]) => {
  const maxY = max(datas, (data) => max(data, (d) => d.y));
  if (isNil(maxY)) return 100;
  return Math.ceil(maxY / 10) * 10;
};
