import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ButtonGroup, IconButton } from '@material-ui/core';
import { ReactComponent as Attention } from '../../icons/attention.svg';
import { ReactComponent as Check } from '../../icons/checkSmall.svg';
import { withStyles } from '@material-ui/styles';
import { Colors } from '@rentguru/commons-utils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  })
);

const ColorizedIconButton = withStyles(() => ({
  root: {
    backgroundColor: Colors.CLASSICAL_WHITE,
    padding: 0,
    border: 'solid 2px rgba(38, 50, 56, 0.1)',
    width: 30,
    minWidth: 0,
  },
}))(IconButton);

interface CheckButtonGroupProps {
  onCheck: () => void;
  onUncheck: () => void;
  checked?: boolean;
  style?: React.CSSProperties;
}

const CheckButtonGroup: React.FC<CheckButtonGroupProps> = ({ onCheck, onUncheck, checked, style = {} }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={style}>
      <ButtonGroup
        size="small"
        style={{ height: 25 }}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
        }}
      >
        <ColorizedIconButton
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            onUncheck();
          }}
          size="small"
          style={{ backgroundColor: checked === false ? Colors.CARNATION_RED : Colors.CLASSICAL_WHITE }}
        >
          <Attention
            style={{ fill: checked === false ? Colors.CLASSICAL_WHITE : Colors.BLUE_GREY, objectFit: 'contain' }}
          />
        </ColorizedIconButton>
        <ColorizedIconButton
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            onCheck();
          }}
          size="small"
          style={{ backgroundColor: checked === true ? Colors.TURQUOISE_BLUE : Colors.CLASSICAL_WHITE }}
        >
          <Check style={{ fill: checked === true ? Colors.CLASSICAL_WHITE : Colors.BLUE_GREY, objectFit: 'contain' }} />
        </ColorizedIconButton>
      </ButtonGroup>
    </div>
  );
};

export default CheckButtonGroup;
