import { LeaseExtended, LeaseActionHistory, SignatureDocumentResult, S3Object } from '@rentguru/commons-utils';
import { useUser } from 'src/hooks/UserContext';
import { PermissionsContext, usePermissions } from 'src/hooks/utils/PermissionsContext';
import { QuickAction } from './useLeaseActionsMenuUtils';

const useBaseLeaseActionMenuUtils = (
  lease: LeaseExtended,
  getQuickActionsForStatusAndRights: (
    // eslint-disable-next-line no-shadow
    lease: LeaseExtended,
    permissions: PermissionsContext,
    features: string[],
    isOwner: boolean,
    leaseContract: S3Object | null | undefined,
    userSignatureResult: SignatureDocumentResult | null | undefined,
    isTenant?: boolean,
    leaseActionHistories?: LeaseActionHistory[]
  ) => QuickAction[],
  currentSignatureResult?: SignatureDocumentResult | null | undefined,
  isTenant = false,
  leaseActionHistories: LeaseActionHistory[] = [],
  leaseContract: S3Object | null | undefined = undefined
) => {
  const { isOwner, ownerId, memberId, features } = useUser(); // Owner has less lease actions than admin
  const permissions = usePermissions();

  const actions = getQuickActionsForStatusAndRights(
    lease,
    permissions,
    features,
    isOwner,
    leaseContract,
    currentSignatureResult,
    isTenant,
    leaseActionHistories
  );
  const sortedActions = actions.sort((a, b) => a.order - b.order);

  return {
    actions: sortedActions,
    leaseContract,
    isOwner,
    ownerId,
    memberId,
  };
};

export default useBaseLeaseActionMenuUtils;
