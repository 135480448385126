import React, { useEffect, useState } from 'react';
import Divider from '@material-ui/core/Divider';
import { useIntl } from 'react-intl';
import { useSendingSources } from 'src/hooks/SendingSourceContext';
import { isNil, toUpper } from 'lodash';
import { Grid, Typography } from '@material-ui/core';
import { SendingSource, SendingSourceStatus, Colors } from '@rentguru/commons-utils';
import DnsRecordsList from 'src/components/Settings/SendingSource/DnsRecordsList';
import { ContentHeader, LoaderButton , ActionButton } from '@up2rent/ui';
import ErrorDialog from './ErrorDomainDialog';
import VerifiedDomainDialog from './VerifiedDomainDialog';

interface DnsDialogContentProps {
  loading: boolean;
  loadingBeforeDisplay: boolean;
  sendingSource?: SendingSource;
}

const DnsDialogContent: React.FC<DnsDialogContentProps> = ({ loading, sendingSource, loadingBeforeDisplay }) => {
  const { formatMessage } = useIntl();
  if (loading || isNil(sendingSource)) {
    return <Typography style={{ textAlign: 'center', width: 600 }}>{formatMessage({ id: 'loading' })}</Typography>;
  }
  if (sendingSource.status === SendingSourceStatus.PENDING) {
    return <DnsRecordsList sendingSource={sendingSource} hideLastVerificationTime={loadingBeforeDisplay} />;
  }
  if (sendingSource.status === SendingSourceStatus.NONE) {
    return (
      <Typography style={{ marginLeft: 30 }}>
        {formatMessage({ id: 'settings.sendingSources.domainStillNone' })}
      </Typography>
    );
  }
  return <Typography style={{ textAlign: 'center', width: 600 }}>{sendingSource.status}</Typography>;
};

interface DnsDialogProps {
  id: string;
  verifyAction: (sendingSourceId: string) => void;
  closeAction: () => void;
  loadingBeforeDisplay?: boolean;
}

const DnsDialog: React.FC<DnsDialogProps> = ({ id, loadingBeforeDisplay, closeAction, verifyAction }) => {
  const { formatMessage } = useIntl();
  const { getSendingSource, fetchAndSetSendingSources, sendingSourcesLoading } = useSendingSources();
  const [loading, setLoading] = useState<boolean>(true);

  const fetchSendingSourcesAfterLoading = (seconds: number) => {
    // let time to SES to add the domain and generate the DNS records before fetching
    setTimeout(async () => {
      await fetchAndSetSendingSources();
      setLoading(false);
    }, seconds * 1000);
  };

  const handleVerifyButton = async () => {
    if (sendingSource.status === SendingSourceStatus.NONE) {
      setLoading(true);
      fetchSendingSourcesAfterLoading(5);
    }
    if (sendingSource.status === SendingSourceStatus.PENDING) {
      verifyAction(id);
    }
  };

  useEffect(() => {
    if (loadingBeforeDisplay) {
      fetchSendingSourcesAfterLoading(10);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendingSource = getSendingSource(id)!;
  const displayVerifyButton =
    sendingSource?.status === SendingSourceStatus.PENDING || sendingSource?.status === SendingSourceStatus.NONE;
  const displayVerifyButtonHeader = sendingSource?.status === SendingSourceStatus.PENDING;

  if (sendingSource?.status === SendingSourceStatus.ERROR) {
    return <ErrorDialog id={id} closeAction={closeAction} />;
  }

  if (sendingSource?.status === SendingSourceStatus.VERIFIED) {
    return <VerifiedDomainDialog id={id} closeAction={closeAction} />;
  }

  return (
    <>
      <ContentHeader title={formatMessage({ id: 'settings.sendingSources.verifyDomainTitle' })}>
        {displayVerifyButtonHeader && (
          <LoaderButton
            style={{ marginTop: 30, marginRight: 30 }}
            onClick={handleVerifyButton}
            loading={loading && sendingSourcesLoading}
          >
            {formatMessage({
              id: 'settings.sendingSources.verifyDomain',
            })}
          </LoaderButton>
        )}
      </ContentHeader>

      <Divider style={{ marginTop: 20, marginBottom: 20 }} />

      <DnsDialogContent
        sendingSource={sendingSource}
        loading={loading || sendingSourcesLoading}
        loadingBeforeDisplay={loadingBeforeDisplay!}
      />

      <Divider style={{ marginTop: 20, marginBottom: 20 }} />

      <Grid
        style={{
          marginRight: 30,
          marginBottom: 20,
          display: 'flex',
          float: 'right',
          alignItems: 'center',
        }}
      >
        <ActionButton
          onClick={closeAction}
          style={{
            background: 'none',
            color: Colors.DARK_SLATE_GREY,
            marginRight: 20,
          }}
        >
          {toUpper(
            formatMessage({
              id: 'close',
            })
          )}
        </ActionButton>
        {displayVerifyButton && (
          <LoaderButton onClick={handleVerifyButton} loading={loading || sendingSourcesLoading}>
            {formatMessage({
              id: 'settings.sendingSources.verifyDomain',
            })}
          </LoaderButton>
        )}
      </Grid>
    </>
  );
};

export default DnsDialog;
