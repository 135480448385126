/* eslint-disable @typescript-eslint/no-shadow */
import { Collapse, Grid, IconButton, Typography } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {
  Colors,
  EmptyFile,
  File as FileModel,
  LeaseInventory,
  LeaseInventoryStructureStateType,
  LeaseInventoryType,
  S3Object,
  UnitInventoryStructureType,
  getEmptyFile,
  isNilOrEmpty,
} from '@rentguru/commons-utils';
import { ConfirmDialog, CustomIconButton } from '@up2rent/ui';
import { useFormikContext } from 'formik';
import { isEmpty, isNil } from 'lodash';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import AudioRecorder from 'src/components/ui/AudioRecorder';
import TextFieldArea from 'src/components/ui/TextFieldArea';
import {
  ExtendedLeaseInventoryEncoding,
  ExtendedUnitInventory,
  ExtendedUnitInventoryStructure,
} from 'src/hooks/UnitInventoryContext';
import { ReactComponent as ClipIcon } from 'src/icons/paperclip.svg';
import { v4 as uuidv4 } from 'uuid';
import { InventoryOfFixturesFormik } from '../EditInventory/EditInventory';
import EncodingStateSlider, { convertNumberToState } from './EncodingStateSlider';
import { AddCheckedItemDialogBundle, FilePreviewDialogBundle } from './InventoryEncodings';
import InventoryStructureEncodingExtraInfos from './InventoryStructureEncodingExtraInfos';

interface InventoryStructureEncodingProps {
  leaseInventory: LeaseInventory;
  completeUnitInventory: ExtendedUnitInventory;
  inventoryStructure: ExtendedUnitInventoryStructure;
  structureFieldName: string;
  previewFileFunction: (fileBundle: FilePreviewDialogBundle) => void;
  encodeDefects: (defectBundle: AddCheckedItemDialogBundle) => void;
  readOnly: boolean;
  style?: React.CSSProperties;
}

const InventoryStructureEncoding: React.FC<InventoryStructureEncodingProps> = ({
  leaseInventory,
  completeUnitInventory,
  inventoryStructure,
  structureFieldName,
  previewFileFunction,
  encodeDefects,
  readOnly,
  style = {},
}) => {
  const { formatMessage } = useIntl();
  // Should have only one encoding by pair unitStructure-Lease
  const structureEncoding: ExtendedLeaseInventoryEncoding | undefined = inventoryStructure.encodings[0];
  const [showExtraInfo, setShowExtraInfo] = useState<boolean>(true);
  const [fileToDelete, setFileToDelete] = useState<S3Object | EmptyFile | null>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const { values, setFieldValue } = useFormikContext<InventoryOfFixturesFormik>();
  const getEmptyEncoding = (): ExtendedLeaseInventoryEncoding => {
    return {
      id: `NEW-${uuidv4()}`,
      leaseInventoryId: leaseInventory.id,
      structureId: inventoryStructure.id,
      checkedItems: [],
      medias: [],
      clientId: '',
      readId: '',
    };
  };

  const updateEncodingOfCompleteInventory = (
    updateFunction: (encoding: ExtendedLeaseInventoryEncoding) => ExtendedLeaseInventoryEncoding
  ) => {
    const newUnitInventories = values.localUnitInventories.reduce(
      (acc: ExtendedUnitInventory[], unitInventory: ExtendedUnitInventory) => {
        if (unitInventory.id === completeUnitInventory.id) {
          const newStructures = unitInventory.structures.map((uis) => {
            if (uis.id !== inventoryStructure.id) return uis;

            let newEncoding: ExtendedLeaseInventoryEncoding;
            if (isEmpty(uis.encodings)) {
              newEncoding = getEmptyEncoding();
            } else {
              newEncoding = uis.encodings[0];
            }
            return { ...uis, encodings: [updateFunction(newEncoding)] };
          });
          acc.push({ ...unitInventory, structures: newStructures });
        } else {
          acc.push(unitInventory);
        }
        return acc;
      },
      []
    );
    setFieldValue('localUnitInventories', newUnitInventories);
  };

  const removeStructure = () => {
    const newUnitInventories = values.localUnitInventories.reduce(
      (acc: ExtendedUnitInventory[], unitInventory: ExtendedUnitInventory) => {
        if (unitInventory.id === completeUnitInventory.id) {
          const newStructures = unitInventory.structures.filter((uis) => uis.id !== inventoryStructure.id);
          acc.push({ ...unitInventory, structures: newStructures });
        } else {
          acc.push(unitInventory);
        }
        return acc;
      },
      []
    );
    setFieldValue('localUnitInventories', newUnitInventories);
  };

  const expandIconStyle = (showExtraInfo: boolean): object => ({
    transform: `rotate(${showExtraInfo ? 180 : 0}deg)`,
    fill: showExtraInfo ? Colors.DARK_SLATE_GREY : Colors.BLUEY,
    width: 16,
    height: 16,
  });
  return (
    <div style={{ marginLeft: 30, ...style }}>
      <Grid
        container
        style={{ marginTop: 15, marginBottom: 15, alignItems: 'center', cursor: 'pointer' }}
        onClick={(event) => {
          event.stopPropagation();
          setShowExtraInfo(!showExtraInfo);
        }}
      >
        <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-start' }}>
          {(readOnly || inventoryStructure.name === 'Room condition') && (
            <Typography style={{ fontSize: 14, fontWeight: 700 }}>
              {inventoryStructure.name === 'Room condition'
                ? formatMessage({ id: 'leaseInventory.room.generalStructure' })
                : inventoryStructure.name}
            </Typography>
          )}
          {!readOnly && inventoryStructure.name !== 'Room condition' && (
            <TextFieldArea
              name={`${structureFieldName}.name`}
              rows={1}
              rowsMax={1}
              inputProps={{ style: { textAlign: 'left' } }}
              InputProps={{ style: { fontSize: 14, fontWeight: 600, width: '100%' } }}
              onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
            />
          )}
        </Grid>
        <Grid item xs={2} />
        <Grid
          item
          xs={3}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
          onClick={(event) => event.stopPropagation()}
        >
          <EncodingStateSlider
            encodingState={
              !isNil(structureEncoding)
                ? (structureEncoding.state as LeaseInventoryStructureStateType | undefined)
                : undefined
            }
            onChange={(_event, value: number | number[]) => {
              if (typeof value === 'object') return;

              const stateValue = convertNumberToState(value);
              updateEncodingOfCompleteInventory((encoding: ExtendedLeaseInventoryEncoding) => {
                return { ...encoding, state: stateValue };
              });
            }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
          {!readOnly && (
            <>
              <input
                accept="image/*"
                type="file"
                id={inventoryStructure.id}
                multiple={true}
                style={{ display: 'none' }}
                onChange={async (event) => {
                  event.stopPropagation();
                  const selectedFiles = event.target.files;
                  if (!isNilOrEmpty(selectedFiles)) {
                    const newMediaFiles: EmptyFile[] = [];
                    for (const newFile of Array.from(selectedFiles)) {
                      newMediaFiles.push(getEmptyFile(newFile));
                    }
                    const mediaFiles =
                      !isNil(structureEncoding) && !isNil(structureEncoding.medias)
                        ? [...structureEncoding.medias, ...newMediaFiles]
                        : newMediaFiles;
                    updateEncodingOfCompleteInventory((encoding: ExtendedLeaseInventoryEncoding) => {
                      return { ...encoding, medias: mediaFiles as FileModel[] };
                    });
                  }
                  setShowExtraInfo(true);
                }}
              />
              <label htmlFor={inventoryStructure.id}>
                <IconButton component="span" size="small">
                  <ClipIcon style={{ fill: Colors.BLUEY, width: 16, height: 16 }} />
                </IconButton>
              </label>
            </>
          )}
        </Grid>
        <Grid item xs={1} style={{ display: 'flex', justifyContent: 'flex-start' }}>
          {!readOnly && (
            <AudioRecorder
              createFile={async (file: File) => {
                const emptyAudioFile = getEmptyFile(file);
                const updateMediaObject =
                  !isNil(structureEncoding) && !isNil(structureEncoding.medias)
                    ? [...structureEncoding.medias, emptyAudioFile]
                    : [emptyAudioFile];
                updateEncodingOfCompleteInventory((encoding: ExtendedLeaseInventoryEncoding) => {
                  return { ...encoding, medias: updateMediaObject as FileModel[] };
                });
                setShowExtraInfo(true);
              }}
            />
          )}
        </Grid>
        <Grid item xs={1}>
          <CustomIconButton
            onClick={(event) => {
              event.stopPropagation();
              setShowExtraInfo(!showExtraInfo);
            }}
            size="small"
            Icon={ExpandMore}
            iconStyle={expandIconStyle(showExtraInfo)}
          />
        </Grid>
      </Grid>
      <Collapse in={showExtraInfo} mountOnEnter unmountOnExit>
        <InventoryStructureEncodingExtraInfos
          encoding={structureEncoding}
          previewFileFunction={previewFileFunction}
          encodeDefects={encodeDefects}
          updateEncoding={updateEncodingOfCompleteInventory}
          removeStructure={setOpenConfirmDialog}
          setFileToDelete={(file: S3Object | EmptyFile) => setFileToDelete(file)}
          readOnly={readOnly}
          deletable={
            inventoryStructure.type !== UnitInventoryStructureType.GENERAL &&
            (leaseInventory.inventoryType === LeaseInventoryType.FIXTURES_ENTRANCE ||
              inventoryStructure.id.startsWith(`NEW-`))
          }
        />
      </Collapse>
      {!isNil(fileToDelete) && (
        <ConfirmDialog
          open={!isNil(fileToDelete)}
          mainText={formatMessage({ id: 'leaseInventory.deleteFileWarning' })}
          confirmText={formatMessage({ id: 'delete' })}
          confirmAction={
            !isNil(fileToDelete)
              ? () => {
                  updateEncodingOfCompleteInventory((encoding: ExtendedLeaseInventoryEncoding) => {
                    return { ...encoding, medias: encoding.medias!.filter((media) => media.id !== fileToDelete.id) };
                  });
                  setFileToDelete(null);
                }
              : () => {}
          }
          cancelAction={() => setFileToDelete(null)}
          formatMessage={formatMessage}
        />
      )}
      {openConfirmDialog && (
        <ConfirmDialog
          open={openConfirmDialog}
          confirmText={formatMessage({ id: 'delete' })}
          confirmAction={() => {
            removeStructure();
            setOpenConfirmDialog(false);
          }}
          cancelAction={() => setOpenConfirmDialog(false)}
          mainText={formatMessage({ id: 'leaseInventory.confirmDeleteItem' })}
          formatMessage={formatMessage}
        />
      )}
    </div>
  );
};

export default InventoryStructureEncoding;
