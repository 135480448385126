/* eslint-disable react/function-component-definition */
import React, { createRef } from 'react';
import { withStyles, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import { Colors, LooseObject, LeaseInventoryStructureStateType } from '@rentguru/commons-utils';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      height: theme.spacing(3),
    },
    thumb: {
      height: 24,
      width: 24,
      borderRadius: 16,
      backgroundColor: Colors.CLASSICAL_WHITE,
      border: 'solid 1.6px rgba(38, 50, 56, 0.1)',
      boxShadow: 'none',
      '&:focus, &:hover': {
        boxShadow: `${Colors.PORCELAIN_GREY_3} 0 2px 2px`,
      },
      '&.Mui-disabled': {
        height: 24,
        width: 24,
        borderRadius: 16,
        backgroundColor: Colors.CLASSICAL_WHITE,
        border: 'solid 1.6px rgba(38, 50, 56, 0.1)',
        boxShadow: 'none',
      },
    },
  })
);

const convertNumberToColor = (value: number) => {
  if (value < 20) return Colors.CARNATION_RED;
  if (value < 40) return Colors.SUN_YELLOW;
  if (value < 60) return Colors.MINTY_GREEN;
  return Colors.HAZE_GREEN;
};

export const convertNumberToState = (value: number) => {
  if (value < 20) return LeaseInventoryStructureStateType.VERY_BAD;
  if (value < 40) return LeaseInventoryStructureStateType.BAD;
  if (value < 60) return LeaseInventoryStructureStateType.GOOD;
  return LeaseInventoryStructureStateType.VERY_GOOD;
};

export const convertStateToNumber = (state: LeaseInventoryStructureStateType | undefined) => {
  if (state === LeaseInventoryStructureStateType.VERY_BAD) return 7;
  if (state === LeaseInventoryStructureStateType.BAD) return 33.5;
  if (state === LeaseInventoryStructureStateType.GOOD) return 59.5;
  if (state === LeaseInventoryStructureStateType.VERY_GOOD) return 86;
  return undefined;
};

interface Props {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

const ValueLabelComponent = (props: Props) => {
  const { children, open, value } = props;
  const ref = createRef();
  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {React.cloneElement(children, { ref })}
    </Tooltip>
  );
};

const marks = [
  {
    // label: 'VERY_BAD',
    value: 7,
  },
  {
    // label: 'BAD',
    value: 33.5,
  },
  {
    // label: 'GOOD',
    value: 59.5,
  },
  {
    // label: 'VERY GOOD',
    value: 86,
  },
];

// eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
const ThumbComponent = React.forwardRef((props: LooseObject, ref: any) => {
  const classes = useStyles();
  const { className: classNameProps, ...rest } = props;
  if (isNaN(props['aria-valuenow'])) return <div {...props} style={{ visibility: 'hidden' }} ref={ref} />;
  return (
    <div className={clsx(classNameProps, classes.thumb)} {...rest} ref={ref}>
      <div
        style={{
          backgroundColor: convertNumberToColor(props['aria-valuenow']),
          width: 7,
          height: 7,
          borderRadius: '100%',
        }}
      />
    </div>
  );
});

const EncodingSlider = withStyles({
  root: {
    color: Colors.PORCELAIN_GREY_3,
    height: 16,
    borderRadius: 16,
    width: 100,
    '& .MuiSlider-mark[data-index="0"]': {
      backgroundColor: Colors.CARNATION_RED,
    },
    '& .MuiSlider-mark[data-index="1"]': {
      backgroundColor: Colors.SUN_YELLOW,
    },
    '& .MuiSlider-mark[data-index="2"]': {
      backgroundColor: Colors.MINTY_GREEN,
    },
    '& .MuiSlider-mark[data-index="3"]': {
      backgroundColor: Colors.HAZE_GREEN,
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 12px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: Colors.CLASSICAL_BLACK,
    },
  },
  track: {
    height: 2,
  },
  rail: {
    color: Colors.PORCELAIN_GREY_3,
    height: 16,
    borderRadius: 16,
  },
  mark: {
    width: 7,
    height: 7,
    borderRadius: '100%',
    transform: 'translateY(5px)',
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Slider);

interface EncodingStateSliderProps {
  encodingState: LeaseInventoryStructureStateType | undefined;
  onChange: (event: object, value: number | number[]) => void;
  readOnly: boolean;
}

export default function EncodingStateSlider({ encodingState, onChange, readOnly }: EncodingStateSliderProps) {
  const { formatMessage } = useIntl();
  const convertNumberToLegend = (value: number) => {
    if (value < 20) return formatMessage({ id: 'enums.leaseInventoryStructureStateType.VERY_BAD' });
    if (value < 40) return formatMessage({ id: 'enums.leaseInventoryStructureStateType.BAD' });
    if (value < 60) return formatMessage({ id: 'enums.leaseInventoryStructureStateType.GOOD' });
    return formatMessage({ id: 'enums.leaseInventoryStructureStateType.VERY_GOOD' });
  };
  return (
    <EncodingSlider
      marks={marks}
      step={null}
      track={false}
      valueLabelDisplay="auto"
      valueLabelFormat={convertNumberToLegend}
      ValueLabelComponent={ValueLabelComponent}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ThumbComponent={ThumbComponent as any}
      onChange={onChange}
      defaultValue={convertStateToNumber(encodingState)}
      disabled={readOnly}
    />
  );
}
