import { Grid, Typography } from '@material-ui/core';
import { Form, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { LeaseExtended, Unit } from '@rentguru/commons-utils';
import { useUnits } from 'src/hooks/UnitsContext';
import MultipleUnitsSelector from 'src/components/ui/SimpleSelectors/MultipleUnitsSelector';
import { ConfirmDialog, CustomSimpleDialog } from '@up2rent/ui';

interface ChangeProfessionalActivityFormProps {
  lease: LeaseExtended;
  onClose: () => void;
  open: boolean;
}

export interface AddProfessionalUseFormValues {
  units: Unit[];
}

const ChangeProfessionalActivityForm: React.FC<ChangeProfessionalActivityFormProps> = ({ lease, onClose, open }) => {
  const { formatMessage } = useIntl();
  const { getUnit } = useUnits();
  const [localeDialogOpen, setLocaleDialogOpen] = useState<boolean>(false);
  const { isSubmitting, setFieldValue, values, submitForm } = useFormikContext<AddProfessionalUseFormValues>();

  const units = (lease.units ?? []).reduce((acc, currentUnitLease) => {
    if (currentUnitLease.authorizeProfessionalActivity) {
      return acc;
    }
    const unit = getUnit(currentUnitLease.unit!.id);
    acc.push(unit!);
    return acc;
  }, [] as Unit[]);
  useEffect(() => {
    if (units.length === 1) {
      setFieldValue('units', [units[0]]);
    }
    // No need to call it again
    // eslint-disable-next-line
  }, []);

  const onButtonClick = () => {
    setLocaleDialogOpen(true);
  };
  return (
    <Form>
      <CustomSimpleDialog
        open={open}
        onClose={onClose}
        onActionButtonClick={onButtonClick}
        actionButtonLoading={isSubmitting}
        actionButtonDisabled={isEmpty(values.units)}
        actionButtonLabel={formatMessage({ id: 'save' })}
        title={formatMessage({ id: 'lease.detail.action.addProfessionalUse' })}
        dividerBelowTitle
        formatMessage={formatMessage}
      >
        <Grid>
          <Typography variant="h6" style={{ marginTop: 10 }}>
            {formatMessage({ id: 'lease.detail.action.selectUnit' })}
          </Typography>
          <MultipleUnitsSelector
            units={units}
            fieldName="units"
            style={{ width: 500 }}
            inputStyle={{ paddingRight: 0 }}
            inputWidth={540}
          />
        </Grid>
      </CustomSimpleDialog>
      <ConfirmDialog
        mainText={formatMessage({ id: 'lease.detail.action.areYouSure' })}
        title={formatMessage({ id: 'lease.detail.action.areYouSureTitle' })}
        open={localeDialogOpen}
        confirmText={formatMessage({ id: 'yes' })}
        confirmAction={submitForm}
        cancelAction={() => setLocaleDialogOpen(false)}
        cancelText={formatMessage({ id: 'no' })}
        formatMessage={formatMessage}
      />
    </Form>
  );
};

export default ChangeProfessionalActivityForm;
