/* eslint-disable react/no-array-index-key */
import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Popup } from 'react-mapbox-gl';
import { useIntl } from 'react-intl';
import { ReactComponent as ExternalIcon } from 'src/icons/external.svg';
import { useHistory } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';
import { Colors } from '@rentguru/commons-utils';
import { CustomIconButton } from '@up2rent/ui';
import { UnitTypeRepartition } from './BuildingsMap';

export interface PopupData {
  coordinates: [number, number];
  id: string;
  name: string;
  address: string;
  owners: string[];
  unitTypeRepartition: UnitTypeRepartition;
}

interface MapPopupProps {
  popup: PopupData | undefined;
}

export const useStyles = makeStyles({
  button: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  canvas: {
    '&:focus': {
      outline: 'none',
    },
  },
});

const MapPopup: React.FC<MapPopupProps> = ({ popup }) => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const classes = useStyles();
  return (
    <>
      {popup && (
        <>
          {/* @ts-ignore */}
          <Popup coordinates={popup.coordinates} key={`${popup.id}-${popup.address}`} offset={[0, -30]}>
            <div style={{ textAlign: 'left' }}>
              <Typography display="inline" style={{ fontWeight: 700 }}>
                {`${formatMessage({ id: 'building.detail.general.name' })}: `}
              </Typography>
              <Typography display="inline">{popup.name}</Typography>
              <CustomIconButton
                size="small"
                onClick={() =>
                  history.push({
                    pathname: `${RouteDestination.PROPERTIES}/${popup.id}`,
                    state: { goBackUrl: history.location },
                  })
                }
                className={classes.button}
                Icon={ExternalIcon}
                iconStyle={{ fill: Colors.DODGER_BLUE, marginLeft: '5px' }}
              />
            </div>
            <div style={{ textAlign: 'left' }}>
              <Typography display="inline" style={{ fontWeight: 700 }}>
                {`${formatMessage({ id: 'building.detail.general.address' })}: `}
              </Typography>
              <Typography display="inline">{popup.address}</Typography>
            </div>
            {popup.owners.length > 0 && (
              <div style={{ textAlign: 'left' }}>
                <Typography display="inline" style={{ fontWeight: 700 }}>
                  {`${formatMessage({
                    id: popup.owners.length === 1 ? 'building.detail.general.owner' : 'building.detail.general.owners',
                  })}: `}
                </Typography>
                <Typography display="inline">{popup.owners.join(', ')}</Typography>
              </div>
            )}
            {Object.keys(popup.unitTypeRepartition).length > 0 && (
              <div style={{ textAlign: 'left' }}>
                <Typography display="inline" style={{ fontWeight: 700 }}>
                  {`${formatMessage(
                    { id: 'dashboard.map.unitTypes' },
                    { value: Object.keys(popup.unitTypeRepartition).length }
                  )}: `}
                </Typography>
                {(Object.keys(popup.unitTypeRepartition) as (keyof UnitTypeRepartition)[]).map((key, i) => (
                  <div key={`${popup.id}-${i}`}>
                    <Typography display="inline">{`${formatMessage({ id: `dashboard.map.${key}` })}: `}</Typography>
                    <Typography display="inline">{`${popup.unitTypeRepartition[key]!.toFixed(0)}%`}</Typography>
                  </div>
                ))}
              </div>
            )}
          </Popup>
        </>
      )}
    </>
  );
};

export default MapPopup;
