import { Popover, type PopoverProps, makeStyles } from '@material-ui/core';
interface CustomPopoverProps extends PopoverProps, StyleProps {}

interface StyleProps {
  paperBorderRadius?: number;
  paperHeight?: number | number;
  paperPadding?: number | string;
  paperWidth?: number | string;
  enablePointerEvents?: boolean;
}

const useStyles = makeStyles({
  popover: {
    width: ({ paperWidth }: StyleProps) => paperWidth ?? 300,
    pointerEvents: ({ enablePointerEvents }: StyleProps) => (enablePointerEvents ? 'auto' : 'none'),
  },
  paper: {
    padding: ({ paperPadding }: StyleProps) => paperPadding ?? 10,
    width: ({ paperWidth }: StyleProps) => paperWidth ?? 300,
    maxHeight: ({ paperHeight }: StyleProps) => paperHeight ?? 'unset',
    borderRadius: ({ paperBorderRadius }: StyleProps) => paperBorderRadius ?? 4,
  },
});

export const CustomPopover: React.FC<CustomPopoverProps> = ({
  paperBorderRadius,
  paperHeight,
  paperPadding,
  paperWidth,
  enablePointerEvents = false,
  ...popoverProps
}) => {
  const classes = useStyles({ paperBorderRadius, paperHeight, paperPadding, paperWidth, enablePointerEvents });

  return (
    <Popover
      className={classes.popover}
      classes={{
        paper: classes.paper,
      }}
      disableEnforceFocus // Needed for Marker.io
      {...popoverProps}
    >
      {popoverProps.children}
    </Popover>
  );
};
