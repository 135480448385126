import React from 'react';
import { IntegrationName } from '@rentguru/commons-utils';
import PontoIntegrationDialog from './PontoIntegrationDialog';
import Bob50IntegrationDialog from './Bob50IntegrationDialog';
import MyBanxIntegrationDialog from './MyBanxIntegrationDialog';
import OcrIntegrationDialog from './OcrIntegrationDialog';

interface IntegrationDialogsProps {
  name?: IntegrationName;
  id?: string | null;
  onClose: () => void;
}

const IntegrationDialogs: React.FC<IntegrationDialogsProps> = ({ name, onClose, id }) => {
  if (name === IntegrationName.PONTO) {
    return <PontoIntegrationDialog open={true} onClose={onClose} />;
  }
  if (name === IntegrationName.SAGE_BOB50) {
    return <Bob50IntegrationDialog open={true} onClose={onClose} id={id} />;
  }
  if (name === IntegrationName.MYBANX) {
    return <MyBanxIntegrationDialog open={true} onClose={onClose} id={id} />;
  }
  if (name === IntegrationName.OCR) {
    return <OcrIntegrationDialog open={true} onClose={onClose} id={id} />;
  }
  return null;
};

export default IntegrationDialogs;
