import { TableCell, TableRow, Tooltip, Typography } from '@material-ui/core';
import {
  Colors,
  Communication,
  CommunicationStatus,
  ContactType,
  getContactNameFromObject,
  isNilOrEmpty,
  LeaseContact,
} from '@rentguru/commons-utils';
import { CustomIconButton, CustomLoadableIconButton } from '@up2rent/ui';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useGenerateAndDownloadCommunication } from 'src/components/Communications/useGenerateAndDownloadCommunication';
import { stopPropagation } from 'src/components/Dashboard/Dashboard';
import {
  ROW_HEIGHT,
  SENDABLE_COMMUNICATION_SUB_TYPES,
  SNOOZABLE_COMMUNICATION_SUB_TYPES,
} from 'src/components/Dashboard/DashboardTodos/DashboardCommunications';
import SnoozeMenu from 'src/components/Dashboard/DashboardTodos/DashboardDialogs/SnoozeMenu';
import { RouteDestination } from 'src/components/Routes/Routes';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { useCommunications } from 'src/hooks/CommunicationsContext';
import { useLeases } from 'src/hooks/LeasesContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { ReactComponent as DeleteIcon } from 'src/icons/delete.svg';
import { ReactComponent as DownloadSvg } from 'src/icons/download.svg';
import { ReactComponent as HistoryIcon } from 'src/icons/history.svg';
import { ReactComponent as Send } from 'src/icons/send.svg';
import { CommunicationWithTemplate } from 'src/utils/communicationUtils';

interface CommunicationRowProps {
  communication: CommunicationWithTemplate;
  setIgnoreCommunication: (communication: Communication) => void;
  setCommunicationToSnooze: (communication: Communication | null) => void;
}

const CommunicationRow: React.FC<CommunicationRowProps> = ({
  communication,
  setIgnoreCommunication,
  setCommunicationToSnooze,
}) => {
  const { communicationsDetailsWrite, communicationsDetailsDelete } = usePermissions();
  const [sendingLoading, setSendingLoading] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const history = useHistory();
  const { getUnit } = useUnits();
  const { getLease } = useLeases();
  const { getBuilding } = useBuildings();
  const { updateCommunication, getCommunication } = useCommunications();
  const communicationBody = getCommunication(communication.id)?.body;
  const communicationUnit = getUnit(communication.unitId ?? '');
  const communicationLease = getLease(communication.leaseId ?? '');
  const buildingId = communication.buildingId ?? communicationUnit?.buildingId;
  const communicationBuilding = getBuilding(buildingId ?? '');
  const { generateDownloadCommunication, generateDownloadCommunicationLoading } = useGenerateAndDownloadCommunication(
    communication.id
  );

  if (isNil(communicationUnit) && isNil(communicationLease) && isNil(communicationBuilding)) {
    return (
      <TableRow style={{ minHeight: ROW_HEIGHT, height: ROW_HEIGHT }}>
        <TableCell>
          <Typography>{formatMessage({ id: 'loading' })}</Typography>
        </TableCell>
      </TableRow>
    );
  }
  const tenantNames = communicationLease?.contacts?.reduce((acc: string, leaseContact: LeaseContact) => {
    const contact = leaseContact.contact!;
    if (leaseContact.contactRole === ContactType.TENANT) {
      if (acc === '') acc = `${getContactNameFromObject(contact)}`;
      else acc = `${acc}, ${getContactNameFromObject(contact)}`;
    }
    return acc;
  }, '');
  const destination = {
    pathname: RouteDestination.COMMUNICATIONS,
    state: { communicationId: communication.id, goBackUrl: history.location },
  };

  return (
    <TableRow
      hover
      style={{ cursor: 'pointer', textDecoration: 'none', minHeight: ROW_HEIGHT, height: ROW_HEIGHT }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        history.push(destination);
      }}
    >
      <TableCell align="left" padding="none" style={{ paddingLeft: 20 }}>
        <Typography style={{ color: Colors.CLASSICAL_BLACK, fontSize: 14, fontWeight: 700 }}>
          {communication.template ? communication.template.subject : ''}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14, fontWeight: 700 }}>{tenantNames}</Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14, fontWeight: 500 }}>
          {communicationBuilding?.name}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14, fontWeight: 500 }}>
          {communicationUnit?.name}
        </Typography>
      </TableCell>
      {communicationsDetailsWrite && (
        <TableCell align="left" padding="none">
          {communication.template && SNOOZABLE_COMMUNICATION_SUB_TYPES.includes(communication.template.subType!) && (
            <Tooltip title={formatMessage({ id: 'dashboard.notifications.snooze' })} placement="top">
              <CustomIconButton
                onMouseDown={stopPropagation}
                onClick={(event) => {
                  event.stopPropagation();
                  setAnchorEl(event.currentTarget);
                }}
                size="small"
                Icon={HistoryIcon}
                iconStyle={{ fill: Colors.BLUEY }}
              />
            </Tooltip>
          )}
        </TableCell>
      )}
      <TableCell align="left" padding="none">
        {communication.template &&
          SENDABLE_COMMUNICATION_SUB_TYPES.includes(communication.template.subType!) &&
          communicationsDetailsWrite && (
            <Tooltip
              title={
                isNil(communication.recipient) || isEmpty(communication.recipient)
                  ? formatMessage({ id: 'noRecipient' })
                  : formatMessage({ id: 'send' })
              }
              placement="top"
            >
              <CustomLoadableIconButton
                onMouseDown={stopPropagation}
                onClick={async (event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  if (!isNil(communication.recipient) && !isEmpty(communication.recipient)) {
                    setSendingLoading(true);
                    await updateCommunication(communication, { toSend: true });
                  }
                }}
                size="small"
                isLoading={sendingLoading}
                disabled={isNilOrEmpty(communication.recipient)}
                iconStyle={{ fill: Colors.DODGER_BLUE }}
                loadingStyle={{ height: 16, width: 16, color: Colors.DODGER_BLUE }}
                Icon={Send}
              />
            </Tooltip>
          )}
        {communication.template && !SENDABLE_COMMUNICATION_SUB_TYPES.includes(communication.template.subType!) && (
          <Tooltip title={formatMessage({ id: 'download' })} placement="top">
            <CustomLoadableIconButton
              onMouseDown={stopPropagation}
              onClick={async (event) => {
                event.stopPropagation();
                event.preventDefault();
                if (!isNil(communicationBody) && !isEmpty(communicationBody)) {
                  await generateDownloadCommunication();
                }
              }}
              size="small"
              isLoading={generateDownloadCommunicationLoading}
              Icon={DownloadSvg}
              loadingStyle={{ color: Colors.SILVER }}
              iconStyle={{ fill: Colors.SILVER }}
            />
          </Tooltip>
        )}
      </TableCell>
      {communicationsDetailsDelete && (
        <TableCell align="left" padding="none">
          <Tooltip title={formatMessage({ id: 'ignore' })} placement="top">
            <CustomIconButton
              onMouseDown={stopPropagation}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                setIgnoreCommunication(communication);
              }}
              size="small"
              Icon={DeleteIcon}
              iconStyle={{ fill: Colors.BLUEY, width: 16 }}
            />
          </Tooltip>
        </TableCell>
      )}
      <SnoozeMenu
        anchorEl={anchorEl}
        snoozeAction={async (snoozedDate: Date) => {
          await updateCommunication(communication, {
            snoozedDate: snoozedDate.toISOString(),
            status: CommunicationStatus.SNOOZED,
          });
          setAnchorEl(null);
        }}
        customSnooze={() => setCommunicationToSnooze(communication)}
        onClose={() => setAnchorEl(null)}
      />
    </TableRow>
  );
};

export default CommunicationRow;
