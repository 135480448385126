import React from 'react';
import clsx from 'clsx';
import { useFieldFilterReset } from 'src/components/ui/FieldFilterSelector';
import { ReactComponent as Reset } from '../../icons/close.svg';
import { useIntl } from 'react-intl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
      cursor: 'pointer',
      display: 'inline-flex',
      justifyContent: 'flex-start',
      flexDirection: 'inherit',
      alignItems: 'center',
      fontFamily: 'Mulish',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '15px',
      color: Colors.BLUE_GREY,
      fill: Colors.BLUE_GREY,
      '&:hover': {
        color: Colors.SILVER,
        fill: Colors.SILVER,
      },
      '&:focus': {
        color: Colors.BLUE_GREY,
        fill: Colors.BLUE_GREY,
      },
    },
    active: {
      fill: Colors.BLUE_GREY,
      color: Colors.BLUE_GREY,
      '& $icon': {
        opacity: 1,
      },
    },
    icon: {
      height: 16,
      marginRight: 4,
      marginLeft: 4,
      opacity: 0,
      transition: theme.transitions.create(['opacity', 'transform'], {
        duration: theme.transitions.duration.shorter,
      }),
      userSelect: 'none',
      width: 16,
    },
  })
);

interface FieldFilterResetButtonProps {
  resetFilterValues?: () => void;
}
const FieldFilterResetButton: React.FC<FieldFilterResetButtonProps> = ({ resetFilterValues }) => {
  const { executeResetFilters } = useFieldFilterReset();
  const { formatMessage } = useIntl();
  const classes = useStyles();
  return (
    <ButtonBase
      className={clsx(classes.root, { [classes.active]: true })}
      component="span"
      onClick={() => {
        executeResetFilters();
        if (resetFilterValues) {
          resetFilterValues();
        }
      }}
    >
      {formatMessage({ id: 'reinitFilter' })}
      <Reset className={clsx(classes.icon)} />
    </ButtonBase>
  );
};
export default FieldFilterResetButton;
