/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Colors } from '@rentguru/commons-utils';
import { BOX_SHADOW } from '@up2rent/ui';
import clsx from 'clsx';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    width: '100%',
    position: 'relative',
  },
  buttonRoot: {
    height: '37px',
    minWidth: '75px',
    borderRadius: '18px',
    boxShadow: BOX_SHADOW,
    backgroundColor: Colors.CARNATION_RED,
    '&:hover': {
      backgroundColor: Colors.CARNATION_RED,
    },
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    color: Colors.CLASSICAL_WHITE,
    fontFamily: 'Mulish',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '20px',
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  buttonDisabled: {
    height: '37px',
    minWidth: '75px',
    borderRadius: '18px',
    boxShadow: BOX_SHADOW,
    backgroundColor: Colors.TOWER_GREY,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonSuccess: {
    height: '37px',
    minWidth: '75px',
    borderRadius: '18px',
    boxShadow: BOX_SHADOW,
    backgroundColor: Colors.MALACHITE_GREEN,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonProgress: {
    color: Colors.CLASSICAL_WHITE,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

export interface LoaderButtonVerySmallProps {
  children: React.ReactNode;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: React.ReactEventHandler<any>;
  success?: boolean;
  loading?: boolean;
  disabled?: boolean;
  buttonStyle?: React.CSSProperties;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const LoaderButtonVerySmall: React.FC<LoaderButtonVerySmallProps> = ({
  children,
  onClick,
  success,
  loading,
  disabled,
  buttonStyle,
  className,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.wrapper}>
        <Button
          classes={{
            root: success ? classes.buttonSuccess : disabled ? classes.buttonDisabled : classes.buttonRoot,
            label: classes.label,
          }}
          disabled={loading || disabled}
          onClick={onClick}
          type="submit"
          fullWidth
          style={buttonStyle}
          {...rest}
        >
          {!loading && (children || null)}
        </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </div>
  );
};

LoaderButtonVerySmall.defaultProps = {
  onClick: (): void => {},
  success: false,
  loading: false,
};

export default LoaderButtonVerySmall;
