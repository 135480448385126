import { Divider, Grid, Typography } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import React from 'react';
import { useIntl } from 'react-intl';
import { CustomSimpleDialog } from '@up2rent/ui';
import UnitStructureFields from 'src/components/ui/Forms/FormField/UnitStructureFields';
import { ReactComponent as Bulb } from 'src/icons/bulb.svg';

interface EditRoomsDialogProps {
  unitId: string;
  dialogOpen: boolean;
  inside: boolean;
  setDialogOpen: (value: boolean) => void;
  selectUnitInventoryToBeDeleted?: boolean;
}

const EditRoomsDialog: React.FC<EditRoomsDialogProps> = ({
  unitId,
  dialogOpen,
  setDialogOpen,
  inside,
  selectUnitInventoryToBeDeleted = false,
}) => {
  const handleClose = () => {
    setDialogOpen(false);
  };
  const { formatMessage } = useIntl();

  return (
    <CustomSimpleDialog
      open={dialogOpen}
      onClose={handleClose}
      onActionButtonClick={handleClose}
      actionButtonLabel={formatMessage({ id: 'appButton.done' })}
      hideSecondaryButton
      title={formatMessage({ id: 'rentalUnit.detail.inside.rooms.rooms' })}
      dividerBelowTitle
      formatMessage={formatMessage}
    >
      <Grid style={{ marginTop: 10 }}>
        <UnitStructureFields
          inside={inside}
          unitId={unitId}
          selectUnitInventoryToBeDeleted={selectUnitInventoryToBeDeleted}
        />
      </Grid>
      <Divider style={{ margin: '10px 0', padding: 0 }} />
      <Grid
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 20,
          backgroundColor: Colors.PORCELAIN_GREY_1,
          borderRadius: 6,
        }}
      >
        <Bulb style={{ fill: Colors.BLUE_GREY, marginRight: 30 }} />
        <Typography
          variant="subtitle1"
          style={{ color: Colors.SLATE_GREY, fontSize: 14, width: '100%', flexShrink: 1 }}
        >
          {formatMessage({ id: 'rentalUnit.detail.inside.rooms.changesApplyAlert' })}
        </Typography>
      </Grid>
    </CustomSimpleDialog>
  );
};

export default EditRoomsDialog;
