import { Checkbox, Collapse, Divider, Grid, Typography } from '@material-ui/core';
import {
  Colors,
  isSettingsOrganizationOn,
  ROLES_NO_DELETE,
  ROLES_NO_READ,
  UserRight,
  UserRightAccessType,
  UserRightSection,
} from '@rentguru/commons-utils';
import { FormikProps, useFormikContext } from 'formik';
import { isEmpty, isNil } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import CustomCheckBox from 'src/components/ui/CustomCheckBox';
import { CustomPopover } from '@up2rent/ui';
import { ReactComponent as CheckBoxCheckedIcon } from 'src/icons/checkBoxChecked.svg';
import { ReactComponent as CheckBoxIntIcon } from 'src/icons/checkBoxInt.svg';
import { ReactComponent as CheckBoxUncheckedIcon } from 'src/icons/checkBoxUnchecked.svg';
import { ReactComponent as Chevron } from 'src/icons/chevron-right.svg';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import { UpdateRoleUserRightFormValues } from './RolePermissions';

interface RolePermissionsSectionProps {
  title: string;
  keyStart: string;
  divider?: boolean;
}
const rotatingIconStyle = (isOpen: boolean): object => ({
  transform: `rotate(${isOpen ? 90 : 0}deg)`,
  fill: isOpen ? Colors.DARK_SLATE_GREY : Colors.BLUEY,
  marginRight: 10,
});

const isLeaseCreationWriteOn = (userRight: UserRight[]) => {
  return !isNil(
    userRight.find(
      (right) =>
        right.section === UserRightSection.LEASES_CREATION &&
        right.access &&
        right.access.includes(UserRightAccessType.WRITE)
    )
  );
};

const isDeleteOnForSection = (userRight: UserRight[], sections: string[]) => {
  return isOnForSection(userRight, sections, UserRightAccessType.DELETE);
};

const isWriteOnForSection = (userRight: UserRight[], sections: string[]) => {
  return isOnForSection(userRight, sections, UserRightAccessType.WRITE);
};

const isReadOnForSection = (userRight: UserRight[], sections: string[]) => {
  return isOnForSection(userRight, sections, UserRightAccessType.READ);
};

const isOnForSection = (userRight: UserRight[], sections: string[], type: UserRightAccessType) => {
  return (
    userRight.filter((right) => sections.includes(right.section) && right.access && right.access.includes(type))
      .length === sections.length
  );
};

const sectionsWithInfo = [
  UserRightSection.SETTINGS_AUTOMATION,
  UserRightSection.SETTINGS_MANAGEMENT,
  UserRightSection.LEASES_CREATION,
  UserRightSection.LEASES_DETAILS,
  UserRightSection.LEASES_SIGNATURE,
];

const RolePermissionsSection: React.FC<RolePermissionsSectionProps> = ({ divider, title, keyStart }) => {
  const { formatMessage } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [collapse, setCollapse] = useState<boolean>(false);
  const { values, setFieldValue }: FormikProps<UpdateRoleUserRightFormValues> = useFormikContext();
  const [infoAnchor, setInfoAnchor] = useState<{ section: UserRightSection; element: SVGSVGElement } | null>(null);

  const sectionRights = Object.keys(UserRightSection).filter((urs) => urs.startsWith(keyStart));
  const numberOfRights = sectionRights.length;

  const specialCheck = (
    currentValues: UserRight[],
    sectionRight: string,
    type: UserRightAccessType,
    checked: boolean
  ): UserRight[] => {
    let valuesToWorkWith = currentValues;
    let typeToWorkWith = type;
    if (typeToWorkWith === UserRightAccessType.DELETE && checked) {
      // Auto select write
      valuesToWorkWith = getCheckedValues(
        valuesToWorkWith,
        sectionRight,
        UserRightAccessType.WRITE,
        true
      ) as UserRight[];
      typeToWorkWith = UserRightAccessType.WRITE;
      // Delete is done, do the rest as write access
      enqueueSnackbar(formatMessage({ id: 'settings.roles.infoSpecialRoleSelected' }), {
        variant: 'info',
        preventDuplicate: true,
        autoHideDuration: 10000,
      });
    }
    if (typeToWorkWith === UserRightAccessType.WRITE && checked) {
      // Auto select write
      valuesToWorkWith = getCheckedValues(
        valuesToWorkWith,
        sectionRight,
        UserRightAccessType.READ,
        true
      ) as UserRight[];
      enqueueSnackbar(formatMessage({ id: 'settings.roles.infoSpecialRoleSelected' }), {
        variant: 'info',
        preventDuplicate: true,
        autoHideDuration: 10000,
      });
    }
    if (
      sectionRight === UserRightSection.SETTINGS_MANAGEMENT &&
      typeToWorkWith === UserRightAccessType.WRITE &&
      checked
    ) {
      // ALL ON
      const updates = Object.keys(UserRightSection).map((section) => ({
        section: section as UserRightSection,
        access: [UserRightAccessType.READ, UserRightAccessType.WRITE, UserRightAccessType.DELETE],
      }));
      enqueueSnackbar(formatMessage({ id: 'settings.roles.infoSpecialRoleSelected' }), {
        variant: 'info',
        preventDuplicate: true,
        autoHideDuration: 10000,
      });
      return updates;
    }
    if (sectionRight === UserRightSection.LEASES_CREATION && checked && typeToWorkWith === UserRightAccessType.WRITE) {
      const rightsToEnableReadWrite = [
        UserRightSection.BUILDINGS_UNITS_DETAILS,
        UserRightSection.BUILDINGS_UNITS_TECHNICS,
        UserRightSection.BUILDINGS_UNITS_UTILITY_PROVIDERS,
      ];
      const addBuildingsRights = getCheckAllWithSpecials(
        valuesToWorkWith,
        rightsToEnableReadWrite,
        [UserRightAccessType.READ, UserRightAccessType.WRITE],
        true
      );
      const newValues = getCheckAllWithSpecials(
        addBuildingsRights,
        [UserRightSection.LEASES_DETAILS],
        [UserRightAccessType.READ],
        true
      );
      enqueueSnackbar(formatMessage({ id: 'settings.roles.infoSpecialRoleSelected' }), {
        variant: 'info',
        preventDuplicate: true,
        autoHideDuration: 10000,
      });
      return newValues;
    }
    if (
      (sectionRight === UserRightSection.FURNITURES_INVENTORY_CREATION ||
        sectionRight === UserRightSection.INVENTORY_OF_FIXTURES_CREATION ||
        sectionRight === UserRightSection.LEASES_SIGNATURE ||
        sectionRight === UserRightSection.LEASES_CREATION ||
        sectionRight === UserRightSection.FINANCIAL_TRANSACTIONS) &&
      checked
    ) {
      const rightsToEnableReadWrite = [UserRightSection.LEASES_DETAILS];
      const newValues = getCheckAllWithSpecials(
        valuesToWorkWith,
        rightsToEnableReadWrite,
        [UserRightAccessType.READ],
        true
      );

      enqueueSnackbar(formatMessage({ id: 'settings.roles.infoSpecialRoleSelected' }), {
        variant: 'info',
        preventDuplicate: true,
        autoHideDuration: 10000,
      });
      return newValues;
    }
    if (sectionRight === UserRightSection.CONTACTS_DETAILS && type === UserRightAccessType.DELETE && checked) {
      const rightsToEnableRead = [
        UserRightSection.BUILDINGS_UNITS_DETAILS,
        UserRightSection.BUILDINGS_UNITS_TECHNICS,
        UserRightSection.LEASES_DETAILS,
        UserRightSection.BUILDINGS_UNITS_TECHNICS,
        UserRightSection.BUILDINGS_UNITS_UTILITY_PROVIDERS,
      ];
      const rightsToEnableWrite = [UserRightSection.TICKETS_DETAILS, UserRightSection.FINANCIAL_TRANSACTIONS];
      const deleteRights = getCheckAllWithSpecials(
        valuesToWorkWith,
        rightsToEnableWrite,
        [UserRightAccessType.READ, UserRightAccessType.WRITE, UserRightAccessType.DELETE],
        true
      );

      const newValues = getCheckAllWithSpecials(deleteRights, rightsToEnableRead, [UserRightAccessType.READ], true);
      enqueueSnackbar(formatMessage({ id: 'settings.roles.infoSpecialRoleSelected' }), {
        variant: 'info',
        preventDuplicate: true,
        autoHideDuration: 10000,
      });
      return newValues;
    }
    if (sectionRight === UserRightSection.BUILDINGS_UNITS_DETAILS && type === UserRightAccessType.DELETE && checked) {
      const rightsToEnableRead = [UserRightSection.LEASES_DETAILS];
      const rightsToEnableWrite = [
        UserRightSection.COMMUNICATIONS_DETAILS,
        UserRightSection.FINANCIAL_VALUATIONS_AND_COSTS,
        UserRightSection.TICKETS_DETAILS,
        UserRightSection.FINANCIAL_MORTGAGES,
        UserRightSection.NOTIFICATIONS_DETAILS,
        UserRightSection.ADVERTISEMENTS_DETAILS,
      ];
      const deleteRights = getCheckAllWithSpecials(
        valuesToWorkWith,
        rightsToEnableWrite,
        [UserRightAccessType.READ, UserRightAccessType.WRITE, UserRightAccessType.DELETE],
        true
      );

      const newValues = getCheckAllWithSpecials(deleteRights, rightsToEnableRead, [UserRightAccessType.READ], true);
      enqueueSnackbar(formatMessage({ id: 'settings.roles.infoSpecialRoleSelected' }), {
        variant: 'info',
        preventDuplicate: true,
        autoHideDuration: 10000,
      });
      return newValues;
    }

    return [...valuesToWorkWith]; // Don't alter given array, return a new one
  };

  const getCheckedValues = (
    currentValues: UserRight[],
    sectionRight: string,
    type: UserRightAccessType,
    checked: boolean
  ) => {
    const oldValues = currentValues.find((userRight) => userRight.section === sectionRight);
    const typeAlreadyPresent = oldValues
      ? !isNil((oldValues.access as UserRightAccessType[]).find((access: UserRightAccessType) => access === type))
      : false;
    const upatedAccess = !oldValues
      ? [type]
      : typeAlreadyPresent && checked
      ? oldValues.access
      : !typeAlreadyPresent && checked
      ? [...(oldValues.access as UserRightAccessType[]), type]
      : // typeAlreadyPresent&&!checked
        (oldValues.access as UserRightAccessType[]).filter((access: UserRightAccessType) => access !== type);
    const updatedRights = [
      ...currentValues.filter((userRight) => userRight.section !== sectionRight),
      {
        section: sectionRight,
        access: upatedAccess,
      },
    ];
    return updatedRights;
  };
  const onCheck = (sectionRight: string, type: UserRightAccessType, checked: boolean) => {
    if (!values.changed) {
      setFieldValue('changed', true);
    }
    const alteredValues = specialCheck(values.userRights, sectionRight, type, checked);
    const updatedRights = getCheckedValues(alteredValues, sectionRight, type, checked);
    setFieldValue('userRights', updatedRights);
  };

  const getCheckAllWithSpecials = (
    currentValues: UserRight[],
    sections: string[],
    types: UserRightAccessType[],
    checked: boolean
  ) => {
    const newValues = currentValues.reduce((result, currentUserRight) => {
      if (!sections.includes(currentUserRight.section)) {
        result.push(currentUserRight);
        return result;
      }

      const newTypes = types.reduce(
        (typeResult, currentType) => {
          if (
            currentType === UserRightAccessType.READ &&
            [UserRightSection.CONTACTS_DETAILS, UserRightSection.BUILDINGS_UNITS_DETAILS].includes(
              currentUserRight.section as UserRightSection
            )
          ) {
            return typeResult;
          }
          const typeAlreadyPresent = currentUserRight.access
            ? !isNil(
                (currentUserRight.access as UserRightAccessType[]).find(
                  (access: UserRightAccessType) => access === currentType
                )
              )
            : false;
          if (typeAlreadyPresent && checked) {
            return typeResult;
          }
          if (!typeAlreadyPresent && checked) {
            return [...(typeResult as UserRightAccessType[]), currentType];
          }
          // typeAlreadyPresent&&!checked
          return (typeResult as UserRightAccessType[]).filter((access: UserRightAccessType) => access !== currentType);
        },
        currentUserRight.access ? currentUserRight.access : ([] as UserRightAccessType[])
      );

      result.push({ ...currentUserRight, access: newTypes });
      return result;
    }, [] as UserRight[]);

    // Add non existing right
    const nonExisting = sections.filter((section) =>
      isNil(newValues.find((userRight) => userRight.section === section))
    );

    const newRights = checked
      ? [...newValues, ...nonExisting.map((section) => ({ section, access: types }))]
      : newValues;
    const newRightsWithSpecials = sections.reduce((rightsResult, currentSection) => {
      const newRightsResult = types.reduce((resultForType, currentType) => {
        let resultToCheck = resultForType;
        if (currentType === UserRightAccessType.WRITE && isDeleteOnForSection(resultForType, [currentSection])) {
          // If delete is selected and we uncheck whole section, don't uncheck the write related to the delete
          resultToCheck = getCheckedValues(
            resultForType,
            currentSection,
            UserRightAccessType.WRITE,
            true
          ) as UserRight[];
        } else if (currentType === UserRightAccessType.READ && isWriteOnForSection(resultForType, [currentSection])) {
          // If delete is selected and we uncheck whole section, don't uncheck the write related to the delete
          resultToCheck = getCheckedValues(
            resultForType,
            currentSection,
            UserRightAccessType.READ,
            true
          ) as UserRight[];
        }
        return specialCheck(resultToCheck, currentSection, currentType, checked);
      }, rightsResult);
      return newRightsResult;
    }, newRights as UserRight[]);
    return newRightsWithSpecials;
  };

  const onCheckAll = (type: UserRightAccessType, checked: boolean) => {
    if (!values.changed) {
      setFieldValue('changed', true);
    }

    const newRightsWithSpecials = getCheckAllWithSpecials(values.userRights, sectionRights, [type], checked);

    setFieldValue('userRights', newRightsWithSpecials);
  };

  const allReads = values.userRights.filter(
    (userRight) =>
      userRight.section.startsWith(keyStart) &&
      !isNil(userRight.access) &&
      !isEmpty(userRight.access) &&
      userRight.access.includes(UserRightAccessType.READ)
  );
  const allWrites = values.userRights.filter(
    (userRight) =>
      userRight.section.startsWith(keyStart) &&
      !isNil(userRight.access) &&
      !isEmpty(userRight.access) &&
      userRight.access.includes(UserRightAccessType.WRITE)
  );
  const allDeletes = values.userRights.filter(
    (userRight) =>
      userRight.section.startsWith(keyStart) &&
      !isNil(userRight.access) &&
      !isEmpty(userRight.access) &&
      userRight.access.includes(UserRightAccessType.DELETE)
  );
  const disableMasterRead =
    (numberOfRights === 1 && sectionRights[0] === UserRightSection.CONTACTS_DETAILS) ||
    (keyStart === 'BUILDINGS_UNITS_' && isLeaseCreationWriteOn(values.userRights)) ||
    isSettingsOrganizationOn(values.userRights) ||
    isWriteOnForSection(values.userRights, sectionRights);
  const disableMasterWrite =
    (keyStart === 'BUILDINGS_UNITS_' && isLeaseCreationWriteOn(values.userRights)) ||
    isSettingsOrganizationOn(values.userRights) ||
    isDeleteOnForSection(values.userRights, sectionRights);
  const disableMasterDelete = isSettingsOrganizationOn(values.userRights);
  const hideMasterDelete = keyStart === 'SETTINGS_' || keyStart === 'TEMPLATES_';

  return (
    <>
      <Grid
        container
        style={{ marginTop: 10, marginBottom: 10, paddingRight: 30, cursor: numberOfRights === 1 ? 'auto' : 'pointer' }}
        alignItems="center"
        data-test={title}
      >
        <Grid
          item
          xs={9}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: numberOfRights === 1 ? 26 : 0,
          }}
          onClick={numberOfRights === 1 ? undefined : () => setCollapse(!collapse)}
        >
          {numberOfRights > 1 && <Chevron style={rotatingIconStyle(collapse)} />}

          <Typography
            style={{
              fontWeight: 600,
              fontSize: 14,
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: 1.71,
              letterSpacing: 'normal',
              color: Colors.CLASSICAL_BLACK,
            }}
          >
            {title}
          </Typography>
        </Grid>

        <Grid item xs={1} data-test={`read-${title}`}>
          <Checkbox
            size="medium"
            onChange={(e, checked: boolean) => {
              onCheckAll(UserRightAccessType.READ, checked);
            }}
            checked={allReads.length !== 0}
            icon={<CheckBoxUncheckedIcon style={{ fill: Colors.BLUE_GREY }} />}
            checkedIcon={
              <CheckBoxCheckedIcon style={{ fill: disableMasterRead ? Colors.TOWER_GREY : Colors.DODGER_BLUE }} />
            }
            indeterminateIcon={<CheckBoxIntIcon />}
            indeterminate={allReads.length !== numberOfRights && allReads.length !== 0}
            disabled={disableMasterRead}
          />
        </Grid>
        <Grid item xs={1} data-test={`write-${title}`}>
          <Checkbox
            size="medium"
            onChange={(e, checked: boolean) => {
              onCheckAll(UserRightAccessType.WRITE, checked);
            }}
            checked={allWrites.length !== 0}
            icon={<CheckBoxUncheckedIcon style={{ fill: Colors.BLUE_GREY }} />}
            checkedIcon={
              <CheckBoxCheckedIcon style={{ fill: disableMasterWrite ? Colors.TOWER_GREY : Colors.DODGER_BLUE }} />
            }
            indeterminateIcon={<CheckBoxIntIcon />}
            indeterminate={allWrites.length !== numberOfRights && allWrites.length !== 0}
            disabled={disableMasterWrite}
          />
        </Grid>
        <Grid item xs={1}>
          {!hideMasterDelete && (
            <Checkbox
              size="medium"
              onChange={(e, checked: boolean) => {
                onCheckAll(UserRightAccessType.DELETE, checked);
              }}
              checked={allDeletes.length !== 0}
              icon={<CheckBoxUncheckedIcon style={{ fill: Colors.BLUE_GREY }} />}
              checkedIcon={
                <CheckBoxCheckedIcon style={{ fill: disableMasterDelete ? Colors.TOWER_GREY : Colors.DODGER_BLUE }} />
              }
              indeterminateIcon={<CheckBoxIntIcon />}
              indeterminate={allDeletes.length !== numberOfRights && allDeletes.length !== 0}
              disabled={disableMasterDelete}
            />
          )}
        </Grid>
      </Grid>
      <Collapse in={collapse}>
        {sectionRights.map((sectionRight) => {
          const fromValues = values.userRights.find((userRight) => userRight.section === sectionRight);
          const hideRead = ROLES_NO_READ.includes(sectionRight);
          const hideWrite = false; // ROLES_NO_WRITE.includes(sectionRight);
          const hideDelete = ROLES_NO_DELETE.includes(sectionRight);
          const disableRead =
            (sectionRight.startsWith('BUILDINGS_UNITS_') && isLeaseCreationWriteOn(values.userRights)) ||
            isSettingsOrganizationOn(values.userRights) ||
            isWriteOnForSection(values.userRights, [sectionRight]) ||
            sectionRight === UserRightSection.BUILDINGS_UNITS_DETAILS ||
            (sectionRight === UserRightSection.LEASES_DETAILS &&
              (isReadOnForSection(values.userRights, [UserRightSection.FINANCIAL_TRANSACTIONS]) ||
                isReadOnForSection(values.userRights, [UserRightSection.FURNITURES_INVENTORY_CREATION]) ||
                isReadOnForSection(values.userRights, [UserRightSection.INVENTORY_OF_FIXTURES_CREATION]) ||
                isReadOnForSection(values.userRights, [UserRightSection.LEASES_CREATION]) ||
                isWriteOnForSection(values.userRights, [UserRightSection.LEASES_SIGNATURE])));
          const disableWrite =
            ((sectionRight.startsWith('BUILDINGS_UNITS_') && isLeaseCreationWriteOn(values.userRights)) ||
              isSettingsOrganizationOn(values.userRights) ||
              isDeleteOnForSection(values.userRights, [sectionRight])) &&
            sectionRight !== UserRightSection.SETTINGS_MANAGEMENT;
          const disableDelete =
            (sectionRight.startsWith('BUILDINGS_UNITS_') && isLeaseCreationWriteOn(values.userRights)) ||
            isSettingsOrganizationOn(values.userRights);
          return (
            <Grid container style={{ marginBottom: 10, paddingRight: 30 }} alignItems="center" key={sectionRight}>
              <Grid
                item
                xs={9}
                // 26=16px svg+10marginRight icon
                style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 26 }}
              >
                <Typography
                  style={{
                    fontWeight: 'normal',
                    fontSize: 14,
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 1.71,
                    letterSpacing: 'normal',
                    color: Colors.CLASSICAL_BLACK,
                  }}
                >
                  {formatMessage({ id: `enums.UserRightSection.${sectionRight}` })}
                </Typography>
                {sectionsWithInfo.includes(sectionRight as UserRightSection) && (
                  <InfoSvg
                    aria-owns={!isNil(infoAnchor) ? 'roles-info-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={(event) =>
                      setInfoAnchor({ element: event.currentTarget, section: sectionRight as UserRightSection })
                    }
                    onMouseLeave={() => setInfoAnchor(null)}
                    style={{ fill: Colors.SILVER, marginLeft: 20 }}
                  />
                )}
              </Grid>

              <Grid item xs={1}>
                {!hideRead && (
                  <CustomCheckBox
                    size="small"
                    onCheck={(e, checked: boolean) => {
                      onCheck(sectionRight, UserRightAccessType.READ, checked);
                    }}
                    isChecked={
                      fromValues && fromValues.access ? fromValues.access.includes(UserRightAccessType.READ) : false
                    }
                    customCheckedIcon={
                      <CheckBoxCheckedIcon
                        style={{
                          fill: disableRead ? Colors.TOWER_GREY : Colors.DODGER_BLUE,
                          width: 16,
                          height: 16,
                        }}
                      />
                    }
                    customIcon={<CheckBoxUncheckedIcon style={{ fill: Colors.BLUE_GREY, width: 16, height: 16 }} />}
                    disabled={disableRead}
                  />
                )}
              </Grid>
              <Grid item xs={1}>
                {!hideWrite && (
                  <CustomCheckBox
                    size="small"
                    onCheck={(e, checked: boolean) => {
                      onCheck(sectionRight, UserRightAccessType.WRITE, checked);
                    }}
                    isChecked={
                      fromValues && fromValues.access ? fromValues.access.includes(UserRightAccessType.WRITE) : false
                    }
                    customCheckedIcon={
                      <CheckBoxCheckedIcon
                        style={{
                          fill: disableWrite ? Colors.TOWER_GREY : Colors.DODGER_BLUE,
                          width: 16,
                          height: 16,
                        }}
                      />
                    }
                    customIcon={<CheckBoxUncheckedIcon style={{ fill: Colors.BLUE_GREY, width: 16, height: 16 }} />}
                    disabled={disableWrite}
                  />
                )}
              </Grid>
              <Grid item xs={1}>
                {!hideDelete && (
                  <CustomCheckBox
                    size="small"
                    onCheck={(e, checked: boolean) => {
                      onCheck(sectionRight, UserRightAccessType.DELETE, checked);
                    }}
                    isChecked={
                      fromValues && fromValues.access ? fromValues.access.includes(UserRightAccessType.DELETE) : false
                    }
                    customCheckedIcon={
                      <CheckBoxCheckedIcon
                        style={{
                          fill: disableDelete ? Colors.TOWER_GREY : Colors.DODGER_BLUE,
                          width: 16,
                          height: 16,
                        }}
                      />
                    }
                    customIcon={<CheckBoxUncheckedIcon style={{ fill: Colors.BLUE_GREY, width: 16, height: 16 }} />}
                    disabled={disableDelete}
                  />
                )}
              </Grid>
            </Grid>
          );
        })}
      </Collapse>
      {divider && <Divider />}
      <CustomPopover
        id="roles-info-popover"
        open={Boolean(infoAnchor)}
        anchorEl={infoAnchor?.element}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setInfoAnchor(null)}
        disableRestoreFocus
        paperBorderRadius={10}
      >
        <Typography>
          {!isNil(infoAnchor) ? formatMessage({ id: `settings.roles.info.${infoAnchor.section}` }) : ''}
        </Typography>
      </CustomPopover>
    </>
  );
};

export default RolePermissionsSection;
