import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useIntl } from 'react-intl';
import { Divider, Typography, TableCell } from '@material-ui/core';
import { Unit, Lease, UnitEvent, UnitType, Colors, getOwnersName, UnitOwner } from '@rentguru/commons-utils';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { ReactComponent as UnitTypeCommercial } from 'src/icons/unit-type-commercials.svg';
import { ReactComponent as UnitTypeOffice } from 'src/icons/unit-type-office.svg';
import { ReactComponent as UnitTypeParking } from 'src/icons/unit-type-parking.svg';
import { ReactComponent as UnitTypeResidential } from 'src/icons/unit-type-residential.svg';
import { ReactComponent as UnitTypeStudent } from 'src/icons/unit-type-students.svg';
import MoreHorizontalIconButton from 'src/components/ui/CustomIconButtons/MoreHorizontalIconButton';
import UnitStatus from 'src/components/RentalUnits/Details/UnitStatus';
import { PropertyActionMenuAndDetail } from './PropertyRow';

const ROW_HEIGHT = 60;

type UnitRowProps = PropertyActionMenuAndDetail & {
  unit: Unit;
  isSelected: boolean;
  firstRow?: boolean;
  lastRow?: boolean;
};

export const getUnitTypeIconComponent = (unitType: UnitType) => {
  const svgStyle = { width: 24, height: 24 };
  if (unitType === UnitType.COMMERCIAL) {
    return <UnitTypeCommercial style={svgStyle} />;
  }
  if (unitType === UnitType.OFFICE) {
    return <UnitTypeOffice style={svgStyle} />;
  }
  if (unitType === UnitType.PARKING) {
    return <UnitTypeParking style={svgStyle} />;
  }
  if (unitType === UnitType.STUDENT) {
    return <UnitTypeStudent style={svgStyle} />;
  }

  return <UnitTypeResidential style={svgStyle} />;
};

const TableCellWithBottomDivider = ({
  children,
  firstRow,
  lastRow,
  firstColumn,
  lastColumn,
  hover,
  isCentered,
}: {
  children?: React.ReactNode;
  firstRow: boolean;
  lastRow: boolean;
  firstColumn: boolean;
  lastColumn: boolean;
  hover: boolean;
  isCentered?: boolean;
}) => {
  return (
    <TableCell
      scope="row"
      padding="none"
      style={{
        padding: 0,
        backgroundColor: hover ? Colors.GEYSER_GREY : Colors.PORCELAIN_GREY_1,
        height: ROW_HEIGHT,
        borderBottom: 'none',
        ...(firstColumn &&
          firstRow && {
            borderTopLeftRadius: 6,
          }),
        ...(firstColumn &&
          lastRow && {
            borderBottomLeftRadius: 6,
          }),
        ...(lastColumn &&
          lastRow && {
            borderBottomRightRadius: 6,
          }),
        ...(lastColumn &&
          firstRow && {
            borderTopRightRadius: 6,
          }),
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            ...(firstColumn && { paddingLeft: 10 }),
            ...(isCentered && { justifyContent: 'center' }),
          }}
        >
          {children}
        </div>
        {!lastRow && (
          <Divider style={{ ...(firstColumn && { marginLeft: 20 }), ...(lastColumn && { marginRigth: 50 }) }} />
        )}
      </div>
    </TableCell>
  );
};

const UnitRow: React.FC<UnitRowProps> = ({
  unit,
  isSelected,
  firstRow = false,
  lastRow = false,
  actionMenu,
  handleDetail,
}) => {
  const [rowHovered, setRowHovered] = useState<boolean>(false);
  const { buildingsUnitsDetailsWrite } = usePermissions();
  const { formatMessage } = useIntl();

  const leases = unit.leases?.map((unitLease) => unitLease.lease) ?? ([] as Lease[]);
  const events = unit.events ?? ([] as UnitEvent[]);
  const owners = unit.owners ?? ([] as UnitOwner[]);

  return (
    <motion.tr
      aria-checked={isSelected}
      tabIndex={-1}
      style={{
        backgroundColor: Colors.CLASSICAL_WHITE,
        height: ROW_HEIGHT,
        ...(firstRow && {
          paddingTop: 10,
        }),
      }}
      onMouseEnter={() => setRowHovered(true)}
      onMouseLeave={() => setRowHovered(false)}
      onClick={() => {
        if (handleDetail) {
          handleDetail(unit.building!.id, unit.id);
        }
      }}
    >
      <TableCell scope="row" padding="checkbox" style={{ padding: 0, borderBottom: 'none', height: 60 }} />
      <TableCellWithBottomDivider
        firstRow={firstRow}
        lastRow={lastRow}
        firstColumn={true}
        lastColumn={false}
        hover={rowHovered}
      >
        <Typography
          style={{
            fontWeight: 'normal',
            color: Colors.CLASSICAL_BLACK,
            paddingLeft: 10,
            fontSize: 14,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
          }}
        >
          {unit.name}
        </Typography>
      </TableCellWithBottomDivider>
      <TableCellWithBottomDivider
        firstRow={firstRow}
        lastRow={lastRow}
        firstColumn={false}
        lastColumn={false}
        hover={rowHovered}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {getUnitTypeIconComponent(unit.type as UnitType)}
          <Typography
            style={{
              paddingLeft: 10,
              fontSize: 14,
              color: Colors.SLATE_GREY,
              fontWeight: 'normal',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: 'normal',
              letterSpacing: 'normal',
            }}
          >
            {formatMessage({ id: `enums.UnitType.${unit.type}` })}
          </Typography>
        </div>
      </TableCellWithBottomDivider>

      <TableCellWithBottomDivider
        firstRow={firstRow}
        lastRow={lastRow}
        firstColumn={false}
        lastColumn={false}
        hover={rowHovered}
      />

      <TableCellWithBottomDivider
        firstRow={firstRow}
        lastRow={lastRow}
        firstColumn={false}
        lastColumn={false}
        hover={rowHovered}
        isCentered
      >
        <UnitStatus leases={leases as Lease[]} events={events} statusStyle={{ width: '100%' }} />
      </TableCellWithBottomDivider>

      <TableCellWithBottomDivider
        firstRow={firstRow}
        lastRow={lastRow}
        firstColumn={false}
        lastColumn={!buildingsUnitsDetailsWrite}
        hover={rowHovered}
      >
        <Typography
          style={{
            paddingLeft: 10,
            fontSize: 14,
            color: Colors.SLATE_GREY,
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
          }}
        >
          {getOwnersName(owners)}
        </Typography>
      </TableCellWithBottomDivider>

      {buildingsUnitsDetailsWrite && (
        <TableCellWithBottomDivider
          firstRow={firstRow}
          lastRow={lastRow}
          firstColumn={false}
          lastColumn={true}
          hover={rowHovered}
        >
          <MoreHorizontalIconButton
            onClick={(e) => {
              e.stopPropagation();
              actionMenu(e, unit.id, 'MENU_UNIT');
            }}
          />
        </TableCellWithBottomDivider>
      )}
      <TableCell scope="row" padding="none" style={{ padding: 0, borderBottom: 0, height: 60 }} />
    </motion.tr>
  );
};

export default UnitRow;
