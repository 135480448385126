import React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { DNSRecord } from '@rentguru/commons-utils';
import { ActionButtonSecond, actionButtonSecondStyles } from '@up2rent/ui';

const reducedFontProp = {
  inputProps: {
    style: {
      fontSize: '0.75rem',
    },
  },
};

const getValues = (dnsRecord: DNSRecord): { value: string; priority?: string } => {
  if (dnsRecord.type === 'MX') {
    const splitValue = dnsRecord.value.split(' ');
    const priority = splitValue[0];
    const value = splitValue[1];
    return { value, priority };
  }
  return { value: dnsRecord.value };
};

const copyOnClipboard = (value: string) => {
  navigator.clipboard.writeText(value);
};

interface DnsRecordElementProps {
  domainName: string;
  dnsRecord: DNSRecord;
}

const removeDomainNameSuffix = (domainName: string, record: string) => record.replace(`.${domainName}`, '');

const DnsRecordElement: React.FC<DnsRecordElementProps> = ({ domainName, dnsRecord }) => {
  const { formatMessage } = useIntl();
  const actionButtonClasses = actionButtonSecondStyles();

  const { value, priority } = getValues(dnsRecord);
  return (
    <Grid style={{ paddingLeft: 30, marginBottom: 10, marginRight: 30 }}>
      <Typography variant="h6">
        {formatMessage({ id: 'settings.sendingSources.dns.dnsRecordType' })} : {dnsRecord.type}
      </Typography>
      <Grid style={{ display: 'flex' }}>
        <TextField
          disabled
          label={formatMessage({ id: 'settings.sendingSources.dns.name' })}
          value={removeDomainNameSuffix(domainName, dnsRecord.name)}
          margin="dense"
          variant="filled"
          style={{ width: '100%', fontSize: 2 }}
          InputProps={reducedFontProp}
        />
        <ActionButtonSecond
          className={actionButtonClasses.buttonWithMargin}
          onClick={() => {
            copyOnClipboard(removeDomainNameSuffix(domainName, dnsRecord.name));
          }}
          style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}
        >
          {formatMessage({ id: 'settings.sendingSources.dns.copy' })}
        </ActionButtonSecond>
      </Grid>

      <Grid style={{ display: 'flex' }}>
        <TextField
          disabled
          label={formatMessage({ id: 'settings.sendingSources.dns.value' })}
          value={removeDomainNameSuffix(domainName, value)}
          margin="dense"
          variant="filled"
          style={{ top: 0, width: '100%' }}
          InputProps={reducedFontProp}
        />
        <ActionButtonSecond
          className={actionButtonClasses.buttonWithMargin}
          onClick={() => {
            copyOnClipboard(value);
          }}
          style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}
        >
          {formatMessage({ id: 'settings.sendingSources.dns.copy' })}
        </ActionButtonSecond>
      </Grid>

      {priority && (
        <Grid style={{ display: 'flex' }}>
          <TextField
            disabled
            label={formatMessage({ id: 'settings.sendingSources.dns.priority' })}
            value={removeDomainNameSuffix(domainName, priority)}
            margin="dense"
            variant="filled"
            style={{ top: 0, width: '100%' }}
            InputProps={reducedFontProp}
          />
          <ActionButtonSecond
            className={actionButtonClasses.buttonWithMargin}
            onClick={() => {
              copyOnClipboard(priority);
            }}
            style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}
          >
            {formatMessage({ id: 'settings.sendingSources.dns.copy' })}
          </ActionButtonSecond>
        </Grid>
      )}
    </Grid>
  );
};
export default DnsRecordElement;
