import {
  Colors,
  DEFAULT_LANGUAGE,
  Lease,
  LeasePaymentFrequency,
  LeasePaymentInvoicePeriod,
  LeaseStatus,
  LeaseVariousOperation,
  LeaseVariousOperationType,
  ModelWithVersion,
  Unit,
  VariousOperation,
  VariousOperationType,
  doesLeaseNeedsProRataForFirstAndLastMonth,
  getDateOfNextInvoice,
  getNumberOfMonthsFromFrequency,
  getTranslatedVatRates,
  getVariousOperationLabel,
  isNilOrEmpty,
  startOfUTCMonth,
} from '@rentguru/commons-utils';
import { capitalize, cloneDeep, get, isNil, toNumber } from 'lodash';
import React, { FC } from 'react';
import { IntlFormatters, useIntl } from 'react-intl';
import { OperationFormValue } from 'src/components/ui/Forms/FormField/AddOperation';
/* eslint-disable @typescript-eslint/no-shadow */
import {
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  RadioGroup,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { differenceInCalendarMonths, endOfMonth, isAfter, isBefore, isSameMonth, startOfMonth } from 'date-fns';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';

import { AddLeaseFormValues } from 'src/components/Leases/AddLease/AddLeaseForm';
import { resolveMainUnitAndSubUnitIndexAndPrefix } from 'src/components/Leases/AddLease/useAddEditLeaseUtils';
import { CustomInputItemType } from 'src/components/ui/ComboBox/FullCustomComboBox';
import CustomCheckBox from 'src/components/ui/CustomCheckBox';
import CustomizedSelect from 'src/components/ui/CustomizedSelect';
import { CustomSimpleDialog } from '@up2rent/ui';
import FormikDatePicker from 'src/components/ui/FormikDatePicker';
import { PaymentDueDateFieldsSchema } from 'src/components/ui/Forms/FormField/PaymentDueDateFields';
import UnitSelectorField from 'src/components/ui/Forms/FormField/UnitSelectorField';
import IconnedRemark from 'src/components/ui/IconnedRemark';
import SimpleVariousOperationSelector from 'src/components/ui/SimpleSelectors/SimpleVariousOperationSelector';
import SkeletonComponent from 'src/components/ui/SkeletonComponent';
import StyledRadio from 'src/components/ui/StyledRadio';
import TextDetailEditable from 'src/components/ui/TextDetailEditable';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { useLeaseVariousOperations } from 'src/hooks/LeaseVariousOperationsContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { useUser } from 'src/hooks/UserContext';
import { useVariousOperations } from 'src/hooks/VariousOperationsContext';
import { useVatRates } from 'src/hooks/VatRatesContext';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import {
  getLeaseVariousOperationFormInitialValues,
  handleChangeDate,
  handleChangeRepeat,
} from 'src/utils/leaseVariousOperationUtils';
import * as Yup from 'yup';

export const getLeaseVariousOperationDialogTitle = (
  operationType: LeaseVariousOperationType,
  edit: boolean,
  formatMessage: IntlFormatters['formatMessage']
) => {
  if (edit) {
    if (operationType === LeaseVariousOperationType.DISCOUNT) {
      return formatMessage({ id: 'lease.detail.variousOperations.editDiscount' });
    }
    if (operationType === LeaseVariousOperationType.CHARGE) {
      return formatMessage({ id: 'lease.detail.variousOperations.editCharge' });
    }
    return formatMessage({ id: 'lease.detail.variousOperations.editService' });
  }
  if (operationType === LeaseVariousOperationType.DISCOUNT) {
    return formatMessage({ id: 'lease.detail.action.addDiscount' });
  }
  if (operationType === LeaseVariousOperationType.CHARGE) {
    return formatMessage({ id: 'lease.detail.action.addCharge' });
  }
  return formatMessage({ id: 'lease.detail.action.addService' });
};

interface AddLeaseVariousOperationDialogProps {
  operationType: LeaseVariousOperationType;
  unitId?: string;
  onClose: () => void;
  initialLeaseVariousOperation?: LeaseVariousOperation;
  updatingLeaseVariousOperationForAddLease?: { operation: OperationFormValue; index: number } | null | undefined;
  lease?: Lease;
  onSubmit?: (values: OperationFormValue) => void;
  open: boolean;
}

export const isLeaseOperationValid = (
  leaseStartDate: Date,
  leaseEndDate: Date,
  operationStartDate: Date,
  operationEndDate: Date,
  applyVat: boolean,
  operationVatRate?: number | string
) => {
  return (
    isLeaseOperationStartDateValid(leaseStartDate, leaseEndDate, operationStartDate) &&
    isLeaseOperationEndDateValid(leaseStartDate, leaseEndDate, operationStartDate, operationEndDate) &&
    isLeaseOperationVatValid(applyVat, operationVatRate)
  );
};

export const isLeaseOperationVatValid = (applyVat: boolean, operationVatRate?: number | string) => {
  return !applyVat || !isNil(operationVatRate);
};

const isLeaseOperationStartDateValid = (leaseStartDate: Date, leaseEndDate: Date, operationStartDate: Date) => {
  const startMonthNewDateValue = startOfMonth(operationStartDate);
  const startMonthStartDate = startOfMonth(leaseStartDate);

  const operationStartsAfterLeaseStart =
    isAfter(startMonthNewDateValue, startMonthStartDate) || isSameMonth(startMonthNewDateValue, startMonthStartDate);
  const operationStartsBeforeLeaseEnd = isBefore(startMonthNewDateValue, endOfMonth(leaseEndDate));

  return operationStartsAfterLeaseStart && operationStartsBeforeLeaseEnd;
};

const isLeaseOperationEndDateValid = (
  leaseStartDate: Date,
  leaseEndDate: Date,
  operationStartDate: Date,
  operationEndDate: Date
) => {
  const startMonthEndDateValue = startOfMonth(operationEndDate);
  const startMonthParentStartDate = startOfMonth(leaseStartDate);
  const startMonthStartDate = startOfMonth(operationStartDate!);

  const operationEndsAfterStart =
    isAfter(startMonthEndDateValue, startMonthStartDate) || isSameMonth(startMonthEndDateValue, startMonthStartDate);
  const operationStartsBeforeLeaseEnd = isBefore(operationEndDate, endOfMonth(leaseEndDate));
  const operationStartAfterLeaseStart =
    isAfter(startMonthEndDateValue, startMonthParentStartDate) ||
    isSameMonth(startMonthEndDateValue, startMonthParentStartDate);

  return operationEndsAfterStart && operationStartsBeforeLeaseEnd && operationStartAfterLeaseStart;
};

export const getAddLeaseOperationDynamicSchema = (startDate?: Date, endDate?: Date, maxDiscount?: number) => {
  return Yup.object()
    .shape({
      operationType: Yup.string().required(),
      vatRate: Yup.number(),
      label: Yup.string().min(1).required(),
      amount: Yup.number()
        .required()
        .positive()
        // eslint-disable-next-line prefer-arrow-callback, func-names
        .test('is equals to zero', 'value cannot be zero', function (value) {
          if (isNil(value)) return false;
          if (value <= 0) return false;
          if (value > (maxDiscount ?? Infinity)) return false;
          return true;
        }),
      startDate: Yup.string()
        .min(1)
        .required()
        // eslint-disable-next-line prefer-arrow-callback, func-names
        .test('is start date valid', 'start date must in the given range', function (value) {
          const rootValue = (this as unknown as { from: { value?: AddLeaseFormValues }[] })?.from[1]?.value;
          if (!rootValue && (isNil(startDate) || isNil(endDate))) {
            return true;
          }
          const startDateForm = startDate ?? rootValue?.startDate;
          const endDateForm = endDate ?? rootValue?.endDate;

          if (isNil(startDateForm) || isNil(endDateForm)) {
            return true;
          }

          return isLeaseOperationStartDateValid(startDateForm, endDateForm, new Date(value!));
        }),
      endDate: Yup.string()
        .required()
        // eslint-disable-next-line prefer-arrow-callback, func-names
        .test('is end date valid', 'end date must in the given range', function (value) {
          const rootValue = (this as unknown as { from: { value?: AddLeaseFormValues }[] })?.from[1]?.value;
          if (!rootValue && (isNil(startDate) || isNil(endDate))) {
            return true;
          }
          const startDateForm = startDate ?? rootValue?.startDate;
          const endDateForm = endDate ?? rootValue?.endDate;

          if (isNil(startDateForm) || isNil(endDateForm)) {
            return true;
          }
          return isLeaseOperationEndDateValid(
            startDateForm,
            endDateForm,
            new Date(this.parent.startDate),
            new Date(value!)
          );
        }),
      type: Yup.string().min(1).required(),
      separateInvoice: Yup.boolean().required(),
      reason: Yup.string().nullable(),
      unitId: Yup.string(),
    })
    .concat(PaymentDueDateFieldsSchema);
};

const getAmountLabel = (
  operationType: LeaseVariousOperationType,
  showVat: boolean,
  formatMessage: IntlFormatters['formatMessage']
) => {
  let amountLabel = '';
  if (operationType === LeaseVariousOperationType.DISCOUNT) {
    amountLabel = formatMessage({ id: 'lease.detail.action.amountDiscount' });
  } else if (operationType === LeaseVariousOperationType.CHARGE) {
    amountLabel = formatMessage({ id: 'lease.detail.action.chargeAmount' });
  } else if (operationType === LeaseVariousOperationType.SERVICE) {
    amountLabel = formatMessage({ id: 'lease.detail.action.serviceAmount' });
  }

  if (!showVat) {
    return amountLabel;
  }

  const vatLabel = formatMessage({ id: 'lease.detail.indexationGeneral.vatExcl' });

  return `${amountLabel} ${vatLabel}`;
};

const AddLeaseVariousOperationDialog: FC<AddLeaseVariousOperationDialogProps> = ({
  operationType,
  unitId,
  onClose,
  initialLeaseVariousOperation,
  updatingLeaseVariousOperationForAddLease,
  lease,
  onSubmit,
  open,
}) => {
  const { formatMessage } = useIntl();
  const editingLvo = !isNil(initialLeaseVariousOperation);
  const dialogTitle = getLeaseVariousOperationDialogTitle(operationType, editingLvo, formatMessage);

  const { unitsLoading, getUnit } = useUnits();
  const { buildingsLoading } = useBuildings();
  const { vatRates, vatRatesLoading } = useVatRates();
  const { createLeaseVariousOperation, updateLeaseVariousOperation } = useLeaseVariousOperations();
  const { variousOperations } = useVariousOperations();
  const { language, memberId, isAgency } = useUser();

  const parentFormikContext = useFormikContext<AddLeaseFormValues>();
  const { values: parentFormValues, setFieldValue: parentSetFieldValue } = parentFormikContext ?? {
    values: { language: language ?? DEFAULT_LANGUAGE },
    setFieldValue: () => {},
  };
  const { prefixFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(parentFormValues, unitId);

  const isDiscount = operationType === LeaseVariousOperationType.DISCOUNT;
  const isService = operationType === LeaseVariousOperationType.SERVICE;

  const onVariousOperationChangeObserver = (
    operation: CustomInputItemType,
    startDate: Date | string,
    maxEndDate: string | Date,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => {
    const currentOperation = operation as unknown as VariousOperation;
    const label = getVariousOperationLabel(currentOperation, parentFormValues.language);
    const paymentFrequency = currentOperation.paymentFrequency ?? LeasePaymentFrequency.MONTHLY;
    setFieldValue('amount', currentOperation.amount);
    setFieldValue('label', label);
    setFieldValue('paymentFrequency', paymentFrequency);
    // handleChangeRepeat will update duration, repeat and endDate as well
    handleChangeRepeat(
      currentOperation.type as VariousOperationType,
      currentOperation.numberOfMonths ?? undefined,
      paymentFrequency as LeasePaymentFrequency,
      startDate,
      maxEndDate,
      setFieldValue
    );
  };

  const resetOperationSelector = () => {
    parentSetFieldValue('variousOperation', null);
  };

  const today = new Date();

  const { leaseUnits, mainUnit } = lease?.units?.reduce(
    (acc, unit) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      acc.leaseUnits.push(getUnit(unit.unit?.id!)!);
      if (unit.mainUnit) {
        acc.mainUnit = unit.unit!.id;
      }
      return acc;
    },
    { leaseUnits: [], mainUnit: '' } as { leaseUnits: Unit[]; mainUnit: string }
  ) ?? { leaseUnits: [], mainUnit: '' };

  const showVat = parentFormValues.applyVat ?? (lease?.vatRateCharge || lease?.vatRateRent);

  const leaseIsAlreadyActive = lease?.status === LeaseStatus.Active;

  const isLeaseWithProRata = lease
    ? doesLeaseNeedsProRataForFirstAndLastMonth(
        new Date(lease.startDate),
        lease.paymentInvoicePeriod,
        lease.paymentInvoicePeriodCustomDay
      )
    : false;

  const amountLabel = getAmountLabel(operationType, showVat, formatMessage);

  const country = 'BE'; // Shall be changed in the future.
  const maxDiscount = isDiscount ? get(parentFormValues, `${prefixFieldName}rentalPrice`) : undefined;
  const vatRatesToPropose = getTranslatedVatRates(vatRates, formatMessage, country, true);

  const isOperationNill =
    isNil(updatingLeaseVariousOperationForAddLease) || isNil(updatingLeaseVariousOperationForAddLease.operation);

  const isAlreadyInvoiced = !isNil(initialLeaseVariousOperation?.lastInvoiceDate);

  const alreadyStartedAndRestrictedChanges = isAlreadyInvoiced && leaseIsAlreadyActive;
  const nextInvoiceDate = !isNil(initialLeaseVariousOperation)
    ? getDateOfNextInvoice(
        new Date(initialLeaseVariousOperation.startDate),
        initialLeaseVariousOperation.lastInvoiceDate,
        initialLeaseVariousOperation.paymentFrequency,
        initialLeaseVariousOperation.paymentInvoicePeriod,
        initialLeaseVariousOperation.paymentInvoicePeriodCustomDay
      )
    : null;

  const initialValues = getLeaseVariousOperationFormInitialValues(
    parentFormValues,
    unitId ?? mainUnit ?? '',
    lease,
    operationType,
    updatingLeaseVariousOperationForAddLease,
    initialLeaseVariousOperation
  );

  const handleOperation = (values: OperationFormValue) => {
    if (!isNil(onSubmit)) {
      onSubmit(values);
      return;
    }
    const newOperationsList = cloneDeep(
      get(
        parentFormValues,
        `${prefixFieldName}${isDiscount ? 'discounts' : 'leaseOperations'}`,
        [] as OperationFormValue[]
      )
    );
    if (!isNil(updatingLeaseVariousOperationForAddLease)) {
      newOperationsList[updatingLeaseVariousOperationForAddLease.index] = {
        ...newOperationsList[updatingLeaseVariousOperationForAddLease.index],
        ...values,
      };
    } else {
      newOperationsList.push(values);
    }
    parentSetFieldValue(`${prefixFieldName}${isDiscount ? 'discounts' : 'leaseOperations'}`, newOperationsList);
    onClose();
  };

  const handleOperationOnExistingLease = async (
    values: OperationFormValue,
    { setSubmitting, setStatus }: FormikHelpers<OperationFormValue>
  ) => {
    setSubmitting(true);
    setStatus(false);

    const amount = toNumber(values.amount ?? 0);
    const endDateCleaned = values.endDate ? new Date(values.endDate) : new Date();
    const endDate = startOfUTCMonth(endDateCleaned).toISOString();
    const startDateCleaned = values.startDate ? new Date(values.startDate) : new Date();
    const startDate = startOfUTCMonth(startDateCleaned).toISOString();
    const type = values.type === 'DURATION' ? VariousOperationType.RECURRING : (values.type as VariousOperationType);

    const newLeaseVariousOperation = {
      amount,
      contactId: memberId!,
      endDate,
      label: values.label!,
      leaseId: (lease as Lease).id,
      leaseVariousOperationHistories: [{ amount, periodFrom: startDate, periodTo: endDate }],
      operationType: values.operationType as LeaseVariousOperationType,
      paymentFrequency: values.paymentFrequency as LeasePaymentFrequency,
      paymentInvoicePeriod: values.paymentInvoicePeriod as LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay:
        values.paymentInvoicePeriod === LeasePaymentInvoicePeriod.CUSTOM_PERIOD
          ? values.paymentInvoicePeriodCustomDay ?? 1
          : undefined,
      reason: values.reason,
      separateInvoice: values.separateInvoice ?? false,
      startDate,
      type,
      unitId: values.unitId,
      // Only for charges or services
      ...(showVat
        ? {
            vatRate: toNumber(values.vatRate!) ?? 0,
          }
        : {}),
    };

    if (isNil(initialLeaseVariousOperation)) {
      await createLeaseVariousOperation(newLeaseVariousOperation);
    } else {
      // Change leaseVariousOperationHistories if needed
      const amountHasChanged = amount !== initialLeaseVariousOperation.amount;
      const endDateHasChanged = endDate !== initialLeaseVariousOperation.endDate;
      if (isAlreadyInvoiced && (amountHasChanged || endDateHasChanged)) {
        const changeDateCleaned = values.changeDate ? new Date(values.changeDate) : new Date();
        const changeDate = startOfUTCMonth(changeDateCleaned).toISOString();
        const updatedPeriodTo = amountHasChanged ? changeDate : endDate;
        // If only endDate is changed, we are changing the periodTo of the last record
        // If only amount is changed, we are putting a new record in the histories and changing dates
        // If both, we are changing last record and putting a new record in the histories
        const newLeaseVariousOperationHistories = (
          initialLeaseVariousOperation.leaseVariousOperationHistories ?? []
        ).map((history) => {
          if (history.periodTo === initialLeaseVariousOperation.endDate) {
            return {
              ...history,
              periodTo: updatedPeriodTo,
            };
          }
          return history;
        });
        if (amountHasChanged) {
          newLeaseVariousOperation.leaseVariousOperationHistories = [
            ...newLeaseVariousOperationHistories,
            { periodFrom: changeDate, periodTo: endDate, amount },
          ];
        } else {
          newLeaseVariousOperation.leaseVariousOperationHistories = newLeaseVariousOperationHistories;
        }
      }

      const initialWithVersion = initialLeaseVariousOperation as ModelWithVersion<LeaseVariousOperation>;
      const updatedOperation = {
        ...newLeaseVariousOperation,
        id: initialWithVersion!.id,
        _version: initialWithVersion._version,
      };

      await updateLeaseVariousOperation(updatedOperation);
    }

    setSubmitting(false);
    setStatus(true);
    onClose();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getAddLeaseOperationDynamicSchema(
        new Date(initialValues.minStartDate!),
        new Date(initialValues.maxEndDate!)
      )}
      onSubmit={!isNil(lease) ? handleOperationOnExistingLease : handleOperation}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ setFieldValue, touched, values: currentFormValues, errors, handleBlur, isSubmitting, handleSubmit }) => {
        const isStartMonthSameAsLease = isSameMonth(
          new Date(currentFormValues.minStartDate!),
          new Date(currentFormValues.startDate!)
        );

        // Check for right possible duration amount
        const currentPaymentFrequency =
          currentFormValues.paymentFrequency === VariousOperationType.ONE_TIME
            ? LeasePaymentFrequency.MONTHLY
            : (currentFormValues.paymentFrequency as LeasePaymentFrequency);
        const amountOfMonthsFrequency = getNumberOfMonthsFromFrequency(currentPaymentFrequency);
        const maxDurationDifference =
          differenceInCalendarMonths(
            startOfMonth(new Date(currentFormValues.maxEndDate!)),
            startOfMonth(new Date(currentFormValues.startDate!))
            // First month is being paid too.
          ) + 1;
        const maxNumberOfDuration = Math.floor(maxDurationDifference / amountOfMonthsFrequency);

        const durationDifference =
          differenceInCalendarMonths(
            startOfMonth(new Date(currentFormValues.endDate!)),
            startOfMonth(new Date(currentFormValues.startDate!))
            // First month is being paid too.
          ) + 1;

        const durationValue = Math.floor(durationDifference / amountOfMonthsFrequency);

        return (
          <Form>
            <CustomSimpleDialog
              open={open}
              onClose={onClose}
              onActionButtonClick={handleSubmit}
              actionButtonLabel={formatMessage({ id: editingLvo ? 'save' : 'add' })}
              actionButtonLoading={isSubmitting}
              dividerBelowTitle
              title={dialogTitle}
              formatMessage={formatMessage}
            >
              <Grid style={{ marginTop: 10 }}>
                {isAgency && isService && (
                  <Grid style={{ marginBottom: 10, marginTop: 10 }}>
                    <Typography>{formatMessage({ id: 'lease.detail.action.addServiceHelpAgency' })}</Typography>
                  </Grid>
                )}
                {!isDiscount && isNil(initialLeaseVariousOperation) && (
                  <>
                    <Grid style={{ alignItems: 'baseline', marginBottom: 10 }} container>
                      <Grid item xs={isNilOrEmpty(leaseUnits) || leaseUnits.length <= 1 ? 12 : 6}>
                        <SimpleVariousOperationSelector
                          variousOperations={!isNil(variousOperations) ? variousOperations : []}
                          fieldName="operationSelector"
                          inputWidth={isNilOrEmpty(leaseUnits) || leaseUnits.length <= 1 ? 550 : 265}
                          onChangeObserver={(valueToReturn) =>
                            onVariousOperationChangeObserver(
                              valueToReturn,
                              currentFormValues.startDate!,
                              currentFormValues.maxEndDate!,
                              setFieldValue
                            )
                          }
                          disabled={isAlreadyInvoiced}
                          operationType={operationType}
                        />
                      </Grid>
                      {!isNilOrEmpty(leaseUnits) && leaseUnits.length > 1 && (
                        <Grid item xs={6}>
                          <UnitSelectorField
                            units={leaseUnits}
                            style={{ marginLeft: isNil(leaseUnits) ? 0 : 10 }}
                            labelId="lease.addLease.unit"
                            fieldName="unit.id"
                            onChange={(unitId: string) => {
                              setFieldValue('unitId', unitId);
                            }}
                            initialValue={currentFormValues.unitId}
                            inputStyle={{ width: 265 }}
                            disabled={isAlreadyInvoiced}
                          />
                        </Grid>
                      )}
                    </Grid>
                    {showVat && (
                      <Grid container style={{ alignItems: 'center', marginBottom: 10 }}>
                        <Grid item xs={6}>
                          <SkeletonComponent loading={vatRatesLoading || unitsLoading || buildingsLoading}>
                            <CustomizedSelect
                              label={formatMessage({ id: 'accounting.charge.affectCharge.vatRate' })}
                              fieldName="vatRate"
                              onChange={(e) => {
                                setFieldValue('vatRate', e);
                              }}
                              onBlur={handleBlur}
                              value={currentFormValues.vatRate}
                              FormControlProps={{ style: { width: 265 } }}
                              error={Boolean(errors.vatRate && touched.vatRate)}
                              disabled={isAlreadyInvoiced}
                              dataTest="vatRateSelect"
                            >
                              {vatRatesToPropose.map((mi) => (
                                <MenuItem value={mi.value ?? mi.label ?? ''} key={mi.value}>
                                  {mi.label}
                                </MenuItem>
                              ))}
                            </CustomizedSelect>
                          </SkeletonComponent>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}

                <Grid style={{ alignItems: 'center', marginBottom: 10, display: 'flex' }}>
                  <TextDetailEditable
                    name="label"
                    title={formatMessage({
                      id: `lease.detail.action.${isDiscount ? 'discount' : isService ? 'service' : 'charge'}Name`,
                    })}
                    type="text"
                    editMode
                    style={{ maxWidth: 265, marginRight: 20 }}
                    error={Boolean(errors.label && touched.label)}
                    onChangeObserver={resetOperationSelector}
                    dataTest={isDiscount ? 'Discount' : 'Operation'}
                  />
                  <TextDetailEditable
                    name="amount"
                    title={amountLabel}
                    type="number"
                    min={0}
                    max={maxDiscount}
                    editMode
                    style={{ maxWidth: 265 }}
                    endAdornment="€"
                    step={0.01}
                    error={Boolean(errors.amount && touched.amount)}
                    onChangeObserver={resetOperationSelector}
                    dataTest="Amount"
                    typeNumberNoEndArrow
                  />
                </Grid>

                <Collapse
                  in={
                    alreadyStartedAndRestrictedChanges &&
                    initialLeaseVariousOperation &&
                    initialLeaseVariousOperation.amount !== currentFormValues.amount
                  }
                >
                  <Grid style={{ marginBottom: 10, display: 'flex' }}>
                    <FormikDatePicker
                      name="changeDate"
                      label={capitalize(formatMessage({ id: 'accounting.statement.startingFrom' }))}
                      value={currentFormValues.changeDate ?? new Date()}
                      style={{ width: 265, marginRight: 20 }}
                      error={Boolean(errors.changeDate && touched.changeDate)}
                      type="Date | null"
                      views={['year', 'month']}
                      format="MMMM yyyy"
                      keyboard={false}
                      maxDate={startOfMonth(new Date(currentFormValues.endDate!))}
                      minDate={startOfMonth(nextInvoiceDate ?? new Date())}
                    />
                  </Grid>
                </Collapse>

                {!alreadyStartedAndRestrictedChanges && (
                  <Grid style={{ marginBottom: 10, display: 'flex' }}>
                    <FormikDatePicker
                      name="startDate"
                      onDateChange={(date: Date | null) => {
                        if (!isNil(date)) {
                          handleChangeDate(date, currentFormValues, setFieldValue, 'startDate');
                        }
                      }}
                      label={formatMessage({ id: 'lease.detail.general.startDate' })}
                      value={currentFormValues.startDate}
                      style={{ width: 265, marginRight: 20 }}
                      error={Boolean(errors.startDate && touched.startDate)}
                      type="Date | null"
                      views={['year', 'month']}
                      format="MMMM yyyy"
                      keyboard={false}
                      maxDate={startOfMonth(new Date(currentFormValues.maxEndDate!))}
                      minDate={startOfMonth(new Date(currentFormValues.minStartDate!))}
                      disabled={isAlreadyInvoiced}
                    />

                    <CustomizedSelect
                      label={formatMessage({
                        id: 'lease.addLease.paymentFrequency',
                      })}
                      fieldName="paymentDueDateFields.paymentFrequency"
                      value={
                        currentFormValues.type === VariousOperationType.ONE_TIME
                          ? currentFormValues.type
                          : currentFormValues.paymentFrequency
                      }
                      error={Boolean(errors.paymentFrequency && touched.paymentFrequency)}
                      FormControlProps={{ required: true, style: { width: 265 } }}
                      SelectProps={{
                        onChange: (e) => {
                          const type = e.target.value as string;
                          const isOneTime = type === VariousOperationType.ONE_TIME;
                          handleChangeRepeat(
                            isOneTime ? VariousOperationType.ONE_TIME : VariousOperationType.END_WITH_LEASE,
                            undefined,
                            (currentFormValues.paymentFrequency as LeasePaymentFrequency) ??
                              LeasePaymentFrequency.MONTHLY,
                            currentFormValues.startDate!,
                            currentFormValues.maxEndDate!,
                            setFieldValue
                          );

                          if (!isOneTime) {
                            setFieldValue('paymentFrequency', type);
                          }
                        },
                        onBlur: handleBlur,
                      }}
                    >
                      <MenuItem id={VariousOperationType.ONE_TIME} value={VariousOperationType.ONE_TIME}>
                        {formatMessage({ id: `settings.variousOperations.ONE_TIME` })}
                      </MenuItem>
                      {[
                        LeasePaymentFrequency.MONTHLY,
                        LeasePaymentFrequency.QUARTERLY,
                        LeasePaymentFrequency.BI_ANNUAL,
                        LeasePaymentFrequency.YEARLY,
                      ].map((leasePaymentFrequency) => (
                        <MenuItem id={leasePaymentFrequency} key={leasePaymentFrequency} value={leasePaymentFrequency}>
                          {formatMessage({ id: `enums.LeasePaymentFrequency.${leasePaymentFrequency}` })}
                        </MenuItem>
                      ))}
                    </CustomizedSelect>
                  </Grid>
                )}
                <Collapse in={currentFormValues.type !== VariousOperationType.ONE_TIME}>
                  <Grid
                    style={{
                      marginBottom: 10,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 14,
                        fontWeight: 'normal',
                        fontStyle: 'normal',
                        letterSpacing: 0,
                      }}
                    >
                      {formatMessage({ id: `lease.detail.variousOperations.endsOn` })}
                    </Typography>
                    <FormControl component="fieldset">
                      <RadioGroup
                        value={currentFormValues.type}
                        onChange={(_e, value) => {
                          const isMonthly = currentFormValues.paymentFrequency === LeasePaymentFrequency.MONTHLY;
                          if (value !== VariousOperationType.RECURRING || isMonthly) {
                            handleChangeRepeat(
                              value as VariousOperationType,
                              durationValue,
                              currentFormValues.paymentFrequency as LeasePaymentFrequency,
                              currentFormValues.startDate!,
                              currentFormValues.maxEndDate!,
                              setFieldValue
                            );
                          } else {
                            // We are selecting the radio but not updating other value as date is not selectable
                            setFieldValue('type', value);
                          }
                        }}
                      >
                        <FormControlLabel
                          value={VariousOperationType.END_WITH_LEASE}
                          control={<StyledRadio />}
                          label={
                            <Typography
                              style={{
                                fontSize: 14,
                                fontWeight: 'normal',
                                fontStyle: 'normal',
                                letterSpacing: 0,
                                color: Colors.SLATE_GREY,
                              }}
                            >
                              {formatMessage({ id: `settings.variousOperations.END_WITH_LEASE` })}
                            </Typography>
                          }
                        />

                        <FormControlLabel
                          value={VariousOperationType.RECURRING}
                          control={<StyledRadio />}
                          label={
                            <Grid style={{ display: 'flex', alignItems: 'center' }}>
                              <Typography
                                style={{
                                  fontSize: 14,
                                  fontWeight: 'normal',
                                  fontStyle: 'normal',
                                  letterSpacing: 0,
                                  color: Colors.SLATE_GREY,
                                }}
                              >
                                {formatMessage({ id: `lease.detail.variousOperations.on` })}
                              </Typography>

                              <FormikDatePicker
                                name="endDate"
                                onDateChange={(date) => {
                                  if (!isNil(date)) {
                                    handleChangeDate(date, currentFormValues, setFieldValue, 'endDate');
                                  }
                                }}
                                label={formatMessage({ id: 'lease.detail.general.endDate' })}
                                value={currentFormValues.endDate}
                                style={{ width: 265, maxWidth: 265, marginLeft: 10 }}
                                error={Boolean(errors.endDate && touched.endDate)}
                                views={['year', 'month']}
                                format="MMMM yyyy"
                                keyboard={false}
                                maxDate={startOfMonth(new Date(currentFormValues.maxEndDate!))}
                                minDate={startOfMonth(new Date(currentFormValues.startDate!))}
                                disabled={
                                  currentFormValues.type === VariousOperationType.END_WITH_LEASE ||
                                  currentFormValues.paymentFrequency !== LeasePaymentFrequency.MONTHLY
                                }
                              />
                            </Grid>
                          }
                        />

                        <FormControlLabel
                          value="DURATION"
                          control={<StyledRadio />}
                          label={
                            <Grid style={{ display: 'flex', alignItems: 'center' }}>
                              <Typography
                                style={{
                                  fontSize: 14,
                                  fontWeight: 'normal',
                                  fontStyle: 'normal',
                                  letterSpacing: 0,
                                  color: Colors.SLATE_GREY,
                                }}
                              >
                                {formatMessage({ id: `lease.detail.variousOperations.after` })}
                              </Typography>

                              <TextDetailEditable
                                onChangeObserver={(value) => {
                                  handleChangeDate(toNumber(value), currentFormValues, setFieldValue, 'duration');
                                }}
                                type="number"
                                min={1}
                                max={maxNumberOfDuration}
                                editMode
                                style={{ width: 150, marginRight: 10, height: 48, marginLeft: 10 }}
                                name="duration"
                                forcedValue={durationValue}
                                disabled={currentFormValues.type === VariousOperationType.END_WITH_LEASE}
                                title=""
                                dataTest="DurationInMonths"
                              />

                              <Typography
                                style={{
                                  fontSize: 14,
                                  fontWeight: 'normal',
                                  fontStyle: 'normal',
                                  letterSpacing: 0,
                                  color: Colors.SLATE_GREY,
                                }}
                              >
                                {formatMessage({ id: `lease.detail.variousOperations.occurrences` })}
                              </Typography>
                              <Tooltip
                                title={formatMessage(
                                  { id: 'settings.addVariousOperation.maxDuration' },
                                  { maxNumberOfDuration }
                                )}
                                placement="bottom"
                              >
                                <Grid>
                                  <InfoSvg style={{ marginLeft: 10, fill: Colors.SILVER }} />
                                </Grid>
                              </Tooltip>
                            </Grid>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Collapse>

                <Grid style={{ alignItems: 'baseline', marginBottom: 10 }}>
                  <TextDetailEditable
                    name="reason"
                    title={formatMessage({ id: 'lease.detail.action.reason' })}
                    type="text"
                    editMode
                    style={{ maxWidth: 550 }}
                    error={Boolean(errors.reason && touched.reason)}
                    dataTest="Reason"
                  />
                </Grid>

                {!isDiscount && !isAlreadyInvoiced && (
                  <Grid
                    style={{
                      alignItems: 'baseline',
                      marginBottom: 10,
                      display: 'flex',
                      justifyContent: 'left',
                    }}
                  >
                    <FormControlLabel
                      control={
                        <CustomCheckBox
                          isChecked={currentFormValues.separateInvoice}
                          onCheck={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue(event.target.name, event.target.checked);
                          }}
                          name="separateInvoice"
                          disabled={
                            isOperationNill &&
                            isAlreadyInvoiced &&
                            isSameMonth(new Date(currentFormValues.startDate!), today)
                          }
                        />
                      }
                      label={formatMessage({ id: 'lease.detail.variousOperations.separateInvoice' })}
                    />
                    <Tooltip
                      title={formatMessage({ id: 'lease.detail.variousOperations.separateInvoiceInfo' })}
                      placement="bottom"
                    >
                      <Grid>
                        <InfoSvg style={{ fill: Colors.SILVER }} />
                      </Grid>
                    </Tooltip>
                  </Grid>
                )}

                <Divider style={{ marginTop: 20, marginBottom: 20, marginLeft: -24, marginRight: -24 }} />
                {leaseIsAlreadyActive && (
                  <IconnedRemark
                    Icon={InfoSvg}
                    noFixWidth
                    style={{ margin: 0 }}
                    message={
                      <Typography>{formatMessage({ id: 'settings.addVariousOperation.confirmationEmail' })}</Typography>
                    }
                  />
                )}
                <Collapse in={isStartMonthSameAsLease && (!lease || isLeaseWithProRata)}>
                  <IconnedRemark
                    Icon={InfoSvg}
                    noFixWidth
                    style={{ marginTop: 10, marginLeft: 0, marginRight: 0 }}
                    message={
                      <Typography>{formatMessage({ id: 'settings.addVariousOperation.proRataWarning' })}</Typography>
                    }
                  />
                </Collapse>
              </Grid>
            </CustomSimpleDialog>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddLeaseVariousOperationDialog;
