/* eslint-disable react/no-array-index-key */
import { createStyles, makeStyles, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { stopPropagation } from 'src/components/Dashboard/Dashboard';

export interface RankingData {
  name: string;
  primaryText: string;
  primarySubText?: string;
  secondaryText?: string;
  lineDestination?: string;
}
interface RankingTableProps {
  datas: RankingData[];
}

const useStyles = makeStyles(() =>
  createStyles({
    tableRow: {
      '&:last-child td': {
        borderBottom: 0,
      },
    },
  })
);

const RankingTable: React.FC<RankingTableProps> = ({ datas }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div style={{ marginRight: 30, marginLeft: 30 }} onMouseDown={stopPropagation}>
      <Table>
        <TableBody>
          {datas.map((data, index) => (
            <TableRow
              key={`${data.name}${index}${data.primaryText}`}
              classes={{ root: classes.tableRow }}
              hover={!!data.lineDestination}
              style={data.lineDestination ? { cursor: 'pointer', textDecoration: 'none' } : undefined}
              onClick={
                data.lineDestination
                  ? () => history.push({ pathname: data.lineDestination, state: { goBackUrl: history.location } })
                  : undefined
              }
            >
              <TableCell align="left" padding="none">
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    color: Colors.CLASSICAL_BLACK,
                    textAlign: 'left',
                  }}
                >
                  {data.name}
                </Typography>
              </TableCell>
              <TableCell align="right">
                {data.secondaryText && (
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 'normal',
                      fontStretch: 'normal',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      letterSpacing: 'normal',
                      textAlign: 'right',
                      color: Colors.SLATE_GREY,
                    }}
                  >
                    {data.secondaryText}
                  </Typography>
                )}
              </TableCell>
              <TableCell align="left" padding="none">
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 'normal',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    textAlign: 'right',
                    color: Colors.CHARCOAL_GREY,
                  }}
                >
                  {data.primaryText}
                </Typography>
                {data.primarySubText && (
                  <Typography
                    style={{
                      fontSize: 12,
                      fontWeight: 'normal',
                      fontStretch: 'normal',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      letterSpacing: 'normal',
                      textAlign: 'right',
                      color: Colors.SLATE_GREY,
                    }}
                  >
                    {data.primarySubText}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default RankingTable;
