import { getTenantsName, LeaseStatus, SignatureDocument, SignatureDocumentStatus } from '@rentguru/commons-utils';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';
import { useFieldFilterReset } from 'src/components/ui/FieldFilterSelector';
import { FilterEntity, useFilters } from 'src/hooks/FiltersContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { hasEnoughRightsToDeepDeleteLease, usePermissions } from 'src/hooks/utils/PermissionsContext';
import { LeasesTableProps } from '../Leases';
import { filterLeasesDraftsByFilters, isOneLeaseDraftsFilterSelected } from './leaseFiltersUtils';
import { ReactComponent as Logo } from '../../../icons/unitPlaceholder.svg';
import {
  EnhancedTableSharedContextProvider,
  EnhancedTableScrollable,
  TablePlaceHolder,
  ActionButton,
  MenuItemText,
  ConfirmDialog,
} from '@up2rent/ui';
import { Menu, MenuItem, Typography } from '@material-ui/core';
import LeaseDraftRow from './LeaseDraftRow';
import { useSignatureDocuments } from 'src/hooks/SignatureDocumentContext';
import { useLeases } from 'src/hooks/LeasesContext';
import { useLeasePriceHistories } from 'src/hooks/LeasePriceHistoriesContext';
import { useStatementSettings } from 'src/hooks/StatementSettingsContext';
import { getSortableLeaseName } from './leaseTableUtils';
import { useLeaseVariousOperations } from 'src/hooks/LeaseVariousOperationsContext';
import { useInvoices } from 'src/hooks/InvoicesContext';
import { useTransactions } from 'src/hooks/TransactionsContext';
import { useCommunications } from 'src/hooks/CommunicationsContext';
import { useLeaseActionHistories } from 'src/hooks/LeaseActionHistoriesContext';
import { useCommunicationSettingsProfiles } from 'src/hooks/CommunicationSettingsProfilesContext';
import { ReactComponent as NoResultIcon } from 'src/icons/noResult.svg';

const columns = [
  {
    id: 'name',
    labelId: 'rentalUnit.columnHeader.name',
    numeric: false,
    disablePadding: false,
    sortable: true,
    displayFunction: getSortableLeaseName,
    style: { paddingLeft: 30, minWidth: 150 },
  },
  {
    id: 'units',
    labelId: 'lease.addLease.mainUnit',
    numeric: false,
    disablePadding: false,
    sortable: false,
    style: { minWidth: 150, paddingLeft: 0 },
  },
  {
    id: 'endDate',
    labelId: 'financial.period',
    numeric: false,
    disablePadding: true,
    sortable: true,
    style: { minWidth: 250 },
  },
  {
    id: 'type',
    labelId: 'rentalUnit.columnHeader.type',
    numeric: false,
    disablePadding: false,
    sortable: true,
    style: { minWidth: 100, paddingLeft: 0 },
  },
  {
    id: 'tenants',
    labelId: 'lease.detail.general.tenants',
    numeric: false,
    disablePadding: false,
    sortable: true,
    displayFunction: getTenantsName,
    style: { minWidth: 100, paddingLeft: 0 },
  },
  {
    id: 'status',
    labelId: 'rentalUnit.columnHeader.status',
    numeric: false,
    disablePadding: true,
    sortable: true,
    style: { minWidth: 100 },
  },
  {
    id: 'action',
    labelId: 'rentalUnit.columnHeader.action',
    numeric: false,
    disablePadding: false,
    sortable: false,
    style: { paddingRight: 30 },
  },
];

const LeasesTableDrafts: React.FC<LeasesTableProps> = ({ loading, leasesDrafts: leases }) => {
  const [menuEvent, setMenuEvent] = useState<null | {
    anchor: HTMLElement | null;
    id: string;
  }>(null);
  const [dialogDeleteOpen, setDialogDeleteOpen] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const permissions = usePermissions();
  const { leasesDetailsRead, buildingsUnitsDetailsWrite, leasesCreationWrite, leasesCreationDelete } = permissions;
  const { units } = useUnits();
  const { getSignatureDocumentsForEntity, setSignatureResultToZero } = useSignatureDocuments();
  const { updateLease, getLease, deepDeleteLease } = useLeases();
  const { deleteCommunicationSettingsProfile } = useCommunicationSettingsProfiles();
  const { deleteLeasePriceHistory } = useLeasePriceHistories();
  const { deleteLeaseVariousOperation } = useLeaseVariousOperations();
  const { deleteStatementSetting } = useStatementSettings(false);
  const { deleteInvoice } = useInvoices();
  const { deletePosting } = useTransactions();
  const { batchDeleteCommunications } = useCommunications();
  const { deleteLeaseActionHistory } = useLeaseActionHistories();

  const { leasesDraftsFilters, resetFilters } = useFilters();
  const { executeResetFilters } = useFieldFilterReset();

  const history = useHistory();
  const match = useRouteMatch<{ tabName?: string; id?: string }>({
    path: `${RouteDestination.LEASES}/:tabName?/:id?`,
  });

  if (!match) {
    return null;
  }

  const handleDetail = (leaseId: string) => {
    if (!leasesDetailsRead) {
      return;
    }
    history.push({
      pathname: `${RouteDestination.LEASES}/detail/${leaseId}`,
      state: { goBackUrl: `${RouteDestination.LEASES}/drafts` },
    });
  };

  const handleActionMenu = (anchor: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    setMenuEvent({ anchor: anchor as unknown as HTMLElement, id });
  };

  const handleDeleteClickOpen = () => {
    setDialogDeleteOpen(true);
  };

  const handleEditClick = () => {
    history.push({
      pathname: RouteDestination.EDIT_LEASE,
      state: { id: menuEvent!.id },
    });
  };

  const handleCloseDialogs = () => {
    // Close all dialogs at one
    setDialogDeleteOpen(false);
    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setMenuEvent(null);
  };

  const handleRetractLease = async () => {
    const lease = getLease(menuEvent!.id);
    if (!lease) {
      return;
    }
    const pendingSignatureDocuments = getSignatureDocumentsForEntity(
      'Lease',
      lease.id,
      SignatureDocumentStatus.PENDING
    );
    const signaturePromises: Promise<SignatureDocument>[] = [];
    pendingSignatureDocuments?.forEach((document) => {
      signaturePromises.push(setSignatureResultToZero(document));
    });
    await updateLease(lease, { status: LeaseStatus.Draft });
    await Promise.all(signaturePromises);
    handleCloseMenu();
  };

  const handleSetLeaseToDraft = async () => {
    const lease = getLease(menuEvent!.id);
    if (!lease) {
      return;
    }
    const pendingSignatureDocuments = getSignatureDocumentsForEntity(
      'Lease',
      lease.id,
      SignatureDocumentStatus.PENDING
    );
    const signaturePromises: Promise<SignatureDocument>[] = [];
    pendingSignatureDocuments?.forEach((document) => {
      signaturePromises.push(setSignatureResultToZero(document));
    });
    await updateLease(lease, { status: LeaseStatus.Draft });
    await Promise.all(signaturePromises);
    handleCloseMenu();
  };

  const handleDeleteLease = async () => {
    const lease = getLease(menuEvent!.id);
    if (!lease) {
      return;
    }
    await deepDeleteLease(lease, {
      deleteLeasePriceHistory,
      deleteStatementSetting,
      deleteLeaseVariousOperation,
      deleteInvoice,
      deletePosting,
      deleteLeaseActionHistory,
      deleteCommunicationSettingsProfile,
      batchDeleteCommunications,
    });
    handleCloseDialogs();
  };

  // Filtered list based on menu
  const isOneFilterSelected = isOneLeaseDraftsFilterSelected(leasesDraftsFilters);
  const filteredLeases = filterLeasesDraftsByFilters(leases, leasesDraftsFilters);

  const canCreateLease = units && !isEmpty(units) && leasesCreationWrite;
  const noUnitAndCanCreateUnit = units && !isEmpty(units) && buildingsUnitsDetailsWrite;
  const placeHolderSubText = canCreateLease
    ? formatMessage({ id: 'lease.detail.placeholderSubtext' })
    : noUnitAndCanCreateUnit
    ? formatMessage({ id: 'building.placeholder.subText' })
    : '';
  const placeHolderActionDestination = canCreateLease ? RouteDestination.ADD_LEASE : RouteDestination.ADD_UNIT;
  const placeHolderActionLabelId = canCreateLease ? 'lease.addLease.action' : 'rentalUnit.addUnit.action';

  const hideActionMenu = !leasesCreationWrite && !leasesCreationDelete;

  const selectedLease = leases.find((l) => menuEvent && l.id === menuEvent.id);
  const hasEnoughRightsToDeleteAll = hasEnoughRightsToDeepDeleteLease(permissions);
  const canDelete = Boolean(
    selectedLease &&
      [LeaseStatus.Draft, LeaseStatus.Rejected].includes(selectedLease.status as LeaseStatus) &&
      hasEnoughRightsToDeleteAll
  );

  const canEdit = Boolean(
    selectedLease &&
      [LeaseStatus.Draft, LeaseStatus.Rejected].includes(selectedLease.status as LeaseStatus) &&
      leasesCreationWrite
  );

  const canSetToDraft = Boolean(selectedLease && selectedLease.status === LeaseStatus.Rejected && leasesCreationWrite);
  const canSetToRejected = Boolean(
    selectedLease && selectedLease.status === LeaseStatus.OutForSignature && leasesCreationWrite
  );

  return (
    <>
      <EnhancedTableSharedContextProvider
        value={{ orderBy: 'name' }}
        datas={filteredLeases}
        handleDetail={handleDetail}
        handleActionMenu={handleActionMenu}
      >
        {loading && <Typography>{formatMessage({ id: 'loading' })}</Typography>}
        {!loading && (
          <EnhancedTableScrollable
            NoResultIcon={<NoResultIcon />}
            formatMessage={formatMessage}
            datas={filteredLeases}
            columns={hideActionMenu ? columns.filter((c) => c.id !== 'action') : columns}
            defaultOrderBy="name"
            rowComponent={LeaseDraftRow}
            placeHolder={
              <TablePlaceHolder
                Icon={<Logo width={72} />}
                mainText={formatMessage({ id: 'lease.detail.placeholderNoDrafts' })}
                subText={placeHolderSubText}
                actionButton={
                  canCreateLease || noUnitAndCanCreateUnit ? (
                    <ActionButton component={Link} to={placeHolderActionDestination}>
                      {formatMessage({ id: placeHolderActionLabelId })}
                    </ActionButton>
                  ) : undefined
                }
              />
            }
            filtersSelected={isOneFilterSelected}
            resetFiltersFunction={() => {
              executeResetFilters();
              resetFilters(FilterEntity.LEASES_DRAFTS);
            }}
          />
        )}
      </EnhancedTableSharedContextProvider>

      <ConfirmDialog
        open={dialogDeleteOpen}
        confirmText={formatMessage({ id: 'lease.detail.action.confirmDelete' })}
        confirmAction={handleDeleteLease}
        cancelAction={() => handleCloseDialogs()}
        mainText={formatMessage({ id: 'lease.detail.action.deleteWarning' })}
        formatMessage={formatMessage}
      />
      <Menu
        id="action-menu"
        anchorEl={menuEvent?.anchor}
        open={Boolean(!dialogDeleteOpen && menuEvent?.anchor)}
        onClose={handleCloseMenu}
      >
        {canSetToDraft && (
          <MenuItem onClick={handleSetLeaseToDraft}>
            <MenuItemText
              primary={formatMessage({ id: 'lease.detail.action.changeAsDraft' })}
              dataTest="MenuItem_draft"
            />
          </MenuItem>
        )}
        {canDelete && (
          <MenuItem onClick={handleDeleteClickOpen}>
            <MenuItemText primary={formatMessage({ id: 'delete' })} deleteOption dataTest="MenuItem_delete" />
          </MenuItem>
        )}
        {canEdit && (
          <MenuItem onClick={handleEditClick}>
            <MenuItemText primary={formatMessage({ id: 'edit' })} dataTest="MenuItem_edit" />
          </MenuItem>
        )}
        {canSetToRejected && (
          <MenuItem onClick={handleRetractLease}>
            <MenuItemText primary={formatMessage({ id: 'lease.detail.action.retract' })} dataTest="MenuItem_retract" />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default LeasesTableDrafts;
