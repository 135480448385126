import React, { CSSProperties } from 'react';
import { Grid } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Colors } from '@rentguru/commons-utils';
import { ENTITY_DETAIL_PANEL_SIZE } from 'src/components/ui/EntityDetail/EntityDetail';
import { useNoScrollbarStyles } from 'src/components/ui/noScrollbar';
import { CustomIconButton } from '@up2rent/ui';

const STICKY_HEADER_SIZE = 120;
const LEFT_MENU_SIZE = 270;

interface SettingDetailPanelProps {
  onClose?: () => void;
  MenuContent: React.ReactNode;
  TitleContent: React.ReactNode;
  contentStyle?: CSSProperties;
  children?: React.ReactNode;
}

const SettingDetailPanel: React.FC<SettingDetailPanelProps> = ({
  onClose,
  MenuContent,
  TitleContent,
  children,
  contentStyle = {},
}) => {
  const scrollbarClasses = useNoScrollbarStyles();

  return (
    <Grid
      style={{
        display: 'flex',
        height: '100%',
        overflow: 'auto',
        flexDirection: 'column',
      }}
    >
      <Grid
        style={{
          height: STICKY_HEADER_SIZE,
          width: ENTITY_DETAIL_PANEL_SIZE, // Need to fix width as position is fixed
          overflow: 'hidden',
          position: 'fixed',
          display: 'flex',
        }}
      >
        {/* Header in fixed position */}
        <Grid style={{ width: LEFT_MENU_SIZE }}>
          <CustomIconButton
            id="exitBuildingDetails"
            style={{ marginLeft: 15, marginTop: 30, marginBottom: 40 }}
            aria-label="Menu"
            onClick={onClose}
            Icon={ArrowBack}
            iconStyle={{ fill: Colors.BLUE_GREY }}
          />
        </Grid>
        <Grid style={{ width: `calc(100% - ${LEFT_MENU_SIZE}px)` }}>{TitleContent}</Grid>
      </Grid>

      {/* Menu and Content */}
      <Grid
        style={{
          paddingTop: STICKY_HEADER_SIZE,
          height: `calc(100% - ${STICKY_HEADER_SIZE}px`,
          overflow: 'hidden',
        }}
      >
        <Grid style={{ display: 'flex', width: '100%', height: '100%' }}>
          <Grid
            style={{ width: LEFT_MENU_SIZE, overflowY: 'auto', overflowX: 'hidden' }}
            className={scrollbarClasses.noScrollbar}
          >
            {MenuContent}
          </Grid>

          <Grid
            style={{
              width: `calc(100% - ${LEFT_MENU_SIZE}px)`,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* Content */}
            <Grid
              style={{
                width: `100%`,
                backgroundColor: 'white',
                borderTopLeftRadius: 10,
                height: '100%',
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
              className={scrollbarClasses.noScrollbar}
            >
              <Grid
                style={{
                  marginLeft: 30,
                  marginRight: 30,
                  marginTop: 24,
                  ...contentStyle,
                }}
              >
                {children}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SettingDetailPanel;
