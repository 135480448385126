import React, { useState } from 'react';
import { Grid, MenuList } from '@material-ui/core';
import Dialog from 'src/components/ui/Dialog';
import CustomizedMenuItem from 'src/components/ui/CustomizedMenuItem';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ContentDetailHeader from 'src/components/ui/ContentDetailHeader';
import { ReactComponent as ContactSvg } from 'src/icons/contact.svg';
import DeleteRole from 'src/components/Settings/Roles/DeleteRole';
import { ActionButtonSecond, actionButtonSecondStyles } from '@up2rent/ui';
import { useUserRoles } from 'src/hooks/UserRolesContext';
import RolePermissions from './RolePermissions';
import RoleMembers from './RoleMembers';
import MenuNotFound from 'src/components/Routes/MenuNotFound';
import { RouteDestination } from 'src/components/Routes/Routes';
import SettingDetailPanel from 'src/components/Settings/SettingDetailPanel';

const TO_PREFIX = '';

interface GeneralDetailsProps {
  userRoleId: string;
  onClose?: () => void;
}

const GeneralDetails: React.FC<GeneralDetailsProps> = ({ userRoleId, onClose }) => {
  const navItems = [
    {
      to: `${TO_PREFIX}permissions`,
      labelId: 'settings.roles.permissions',
      exact: true,
      component: RolePermissions,
      editable: true,
    },
    {
      to: `${TO_PREFIX}members`,
      labelId: 'settings.members',
      exact: true,
      component: RoleMembers,
      editable: true,
    },
  ];
  const actionButtonClasses = actionButtonSecondStyles();

  const { formatMessage } = useIntl();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const match = useRouteMatch<{ roleId: string; detailName: string }>({
    path: `${RouteDestination.SETTINGS}/roles/:roleId?/:detailName?`,
  });
  const { getUserRole, getUsersWithRole } = useUserRoles();
  const userRole = getUserRole(userRoleId);
  if (isNil(match) || isNil(userRole)) {
    return null;
  }
  const handleCloseDialogs = () => {
    setDeleteDialogOpen(false);
  };

  const users = getUsersWithRole(userRole.id);
  const canDelete = users.length === 0;

  return (
    <>
      <SettingDetailPanel
        onClose={onClose}
        TitleContent={
          <ContentDetailHeader
            EntityIcon={ContactSvg}
            toolbarStyle={{ borderRadius: 0, borderBottomLeftRadius: 10 }}
            title={userRole.name}
            actions={
              canDelete
                ? [
                    <ActionButtonSecond
                      className={actionButtonClasses.buttonWithMargin}
                      key="delete"
                      onClick={() => setDeleteDialogOpen(true)}
                    >
                      {formatMessage({ id: 'delete' })}
                    </ActionButtonSecond>,
                  ]
                : []
            }
          />
        }
        MenuContent={
          <Grid style={{ marginRight: 15, marginLeft: 15 }}>
            <MenuList>
              {navItems.map((m) => {
                const to = !isNil(match.params.detailName)
                  ? match.url.replace(match.params.detailName, m.to)
                  : match.url + (match.url.charAt(match.url.length - 1) === '/' ? '' : '/') + m.to;
                const selected =
                  (!isNil(match.params.detailName) && match.params.detailName === m.to) ||
                  (m.to === TO_PREFIX && isNil(match.params.detailName));

                return (
                  <CustomizedMenuItem key={m.to} to={to} selected={selected}>
                    {formatMessage({ id: m.labelId })}
                  </CustomizedMenuItem>
                );
              })}
            </MenuList>
          </Grid>
        }
      >
        <Switch>
          {navItems.map((m) => {
            const path = match.path.replace(':detailName?', m.to);
            const Comp = m.component;
            return (
              <Route
                path={path}
                key={m.to}
                exact={m.exact}
                render={() => {
                  return <Comp userRole={userRole} />;
                }}
              />
            );
          })}

          <Route
            path="*"
            render={() => {
              return <MenuNotFound />;
            }}
          />
        </Switch>
      </SettingDetailPanel>
      <Dialog
        open={deleteDialogOpen}
        onClose={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();
          handleCloseDialogs();
        }}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DeleteRole afterSubmit={handleCloseDialogs} id={userRole.id} />
      </Dialog>
    </>
  );
};

export default GeneralDetails;
