/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { CSSProperties } from 'react';
import { Typography, TextField, InputAdornment, MenuItem } from '@material-ui/core';
import { useFormikContext, FormikProps } from 'formik';
import { getSafeValueInObject, getSafeValueInArray } from '../../utils/object';
import { clamp, isEmpty, isNil, toNumber } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Colors, LooseObject } from '@rentguru/commons-utils';

export const useStyles = makeStyles({
  label: {
    color: Colors.LIGHT_BLUE_GREY,
  },
  underline: {
    '&:before': {
      borderBottom: `2px solid ${Colors.TOWER_GREY}`,
    },
  },
  select: {
    height: '1.1875em',
  },
  noEndArrow: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '-moz-appearance': 'textfield',
  },
});

export const useDisabledStyles = makeStyles({
  label: {
    color: Colors.LIGHT_BLUE_GREY,
  },
  underline: {
    '&:before': {
      borderBottom: `2px solid ${Colors.TOWER_GREY}`,
    },
  },
  root: {
    '&$disabled:before': {
      borderBottomStyle: 'hidden',
    },
    '&$disabled': {
      backgroundColor: Colors.PORCELAIN_GREY_1,
    },
  },
  disabled: {
    backgroundColor: Colors.PORCELAIN_GREY_1,
  },
  adornmentRoot: {
    '&$disabled': {
      backgroundColor: Colors.PORCELAIN_GREY_1,
    },
  },
});

const getClampedNumberValue = (value: string, min: number, max: number) => {
  const endsWithCommaZero = value.endsWith(',0') || value.endsWith('.0');
  const convertedValue = toNumber(value);
  const clamped = clamp(convertedValue, min, max);
  if (endsWithCommaZero && clamped === convertedValue) {
    return value;
  }
  return clamped;
};

export interface TextDetailEditableProps {
  title: string;
  text?: string | null;
  editMode: boolean;
  name: string;
  error?: boolean;
  type?: string;
  // eslint-disable-next-line no-undef
  endAdornment?: string | JSX.Element;
  endAdornmentStyle?: React.CSSProperties;
  idInArray?: string;
  arrayName?: string;
  selectable?: boolean;
  selectableValues?: any[];
  style?: React.CSSProperties;
  inputProps?: LooseObject;
  noMaxWidth?: Boolean;
  onChangeObserver?: (value: any) => void;
  disabled?: boolean;
  min?: number;
  max?: number;
  step?: number;
  helperText?: string;
  multiline?: boolean;
  rows?: string | number;
  rowsMax?: string | number;
  forcedValue?: string | number;
  typeNumberNoEndArrow?: boolean;
  titleStyle?: CSSProperties;
  useDefaultOnChange?: Boolean;
  dataTest?: string;
  children?: React.ReactNode;
  placeholder?: string;
}

const TextDetailEditable: React.FC<TextDetailEditableProps> = ({
  title,
  text,
  children,
  editMode,
  name,
  error = false,
  type = 'text',
  endAdornment,
  endAdornmentStyle,
  idInArray,
  arrayName,
  selectable = false,
  selectableValues = [],
  style = {},
  inputProps = {},
  noMaxWidth = false,
  onChangeObserver,
  disabled = false,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  step = 1,
  helperText,
  multiline,
  rows,
  rowsMax,
  forcedValue,
  typeNumberNoEndArrow = false,
  titleStyle = {},
  useDefaultOnChange = true,
  dataTest = '',
  placeholder = undefined,
}) => {
  const { values, handleChange, handleBlur, setFieldValue }: FormikProps<any> = useFormikContext();
  const classes = useStyles();
  const disabledClasses = useDisabledStyles();

  const isNumberField = type === 'number';

  const endAdornmentProps = isNil(endAdornment)
    ? null
    : {
        endAdornment: (
          <InputAdornment style={endAdornmentStyle} position="end">
            {endAdornment}
          </InputAdornment>
        ),
      };
  if (disabled && editMode && isNil(forcedValue)) {
    return (
      <TextField
        label={title}
        name={name}
        type={type}
        disabled
        value={
          isNil(arrayName) || isNil(idInArray)
            ? getSafeValueInObject(values, name)
            : getSafeValueInArray(values[arrayName], idInArray, name)
        }
        margin="dense"
        variant="filled"
        style={{ maxWidth: noMaxWidth ? 2500 : 280, top: 0, width: '100%', ...style }}
        error={error}
        inputProps={{ min, max, step }}
        InputProps={{
          ...endAdornmentProps,
          ...inputProps,
          classes: {
            underline: disabledClasses.underline,
            root: disabledClasses.root,
            disabled: disabledClasses.disabled,
          },
        }}
        InputLabelProps={{ className: disabledClasses.label }}
        helperText={helperText}
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
        hiddenLabel={isEmpty(title)}
        data-test={`textDetail${dataTest}`}
        onWheel={(e) => {
          if (isNumberField && e.target instanceof HTMLElement) {
            e.target.blur();
          }
        }}
        placeholder={placeholder}
      />
    );
  }
  if (editMode && !selectable && isNil(forcedValue)) {
    // Normal input, nothing fancy
    const getFieldValue = () => {
      if (isNil(arrayName) || isNil(idInArray)) {
        return getSafeValueInObject(values, name).toString();
      }
      return getSafeValueInArray(values[arrayName], idInArray, name).toString();
    };

    const currentValue = getFieldValue();
    return (
      <TextField
        label={title}
        id={name}
        type={type}
        name={name}
        margin="dense"
        variant="filled"
        value={currentValue}
        onChange={(e) => {
          const targetValue = e.target ? e.target.value : e.currentTarget.value;
          const value =
            isNumberField && targetValue !== '' ? getClampedNumberValue(targetValue, min, max) : targetValue;
          if (isNil(arrayName) || isNil(idInArray)) {
            // Value is not in array
            if (useDefaultOnChange) {
              setFieldValue(name, value);
            }
          } else {
            setFieldValue(
              arrayName,
              values[arrayName].map((o: any) => {
                if (o.id === idInArray) {
                  return { ...o, [name]: value };
                }
                return o;
              })
            );
          }
          if (!isNil(onChangeObserver)) {
            onChangeObserver(value);
          }
        }}
        onBlur={handleBlur}
        error={error}
        style={{ maxWidth: noMaxWidth ? 2500 : 280, width: '100%', top: 0, ...style }}
        inputProps={{ min, max, step }}
        InputProps={{
          ...endAdornmentProps,
          ...inputProps,
          classes: typeNumberNoEndArrow
            ? { underline: classes.underline, input: classes.noEndArrow }
            : { underline: classes.underline },
        }}
        InputLabelProps={{ className: classes.label }}
        helperText={helperText}
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
        hiddenLabel={isEmpty(title)}
        data-test={`textDetail${dataTest}`}
        onWheel={(e) => {
          if (isNumberField && e.target instanceof HTMLElement) {
            e.target.blur();
          }
        }}
        placeholder={placeholder}
      />
    );
  }
  if (editMode && selectable && isNil(forcedValue)) {
    return (
      <TextField
        label={title}
        id={name}
        name={name}
        select
        margin="dense"
        variant="filled"
        value={getSafeValueInObject(values, name)}
        onChange={handleChange}
        onBlur={handleBlur}
        error={error}
        style={{ maxWidth: 280, top: 0, ...style, width: '100%' }}
        inputProps={{ min, max, step }}
        InputProps={{ classes: { underline: classes.underline } }}
        InputLabelProps={{ className: classes.label }}
        SelectProps={{ classes: { select: classes.select } }}
        helperText={helperText}
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
        data-test={`textDetail${dataTest}`}
        onWheel={(e) => {
          if (isNumberField && e.target instanceof HTMLElement) {
            e.target.blur();
          }
        }}
        placeholder={placeholder}
      >
        {selectableValues.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    );
  }
  if (editMode && selectable && !isNil(forcedValue)) {
    return (
      <TextField
        label={title}
        id={name}
        type={type}
        name={name}
        margin="dense"
        variant="filled"
        value={forcedValue}
        onChange={onChangeObserver}
        onBlur={handleBlur}
        error={error}
        style={{ maxWidth: noMaxWidth ? 2500 : 280, width: '100%', top: 0, ...style }}
        inputProps={{ min, max, step }}
        InputProps={{ ...endAdornmentProps, ...inputProps, classes: { underline: classes.underline } }}
        InputLabelProps={{ className: classes.label }}
        helperText={helperText}
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
        data-test={`textDetail${dataTest}`}
        onWheel={(e) => {
          if (isNumberField && e.target instanceof HTMLElement) {
            e.target.blur();
          }
        }}
        placeholder={placeholder}
      />
    );
  }

  if (editMode && !selectable && !isNil(forcedValue)) {
    return (
      <TextField
        label={title}
        id={name}
        type={type}
        name={name}
        margin="dense"
        variant="filled"
        value={forcedValue}
        disabled={disabled}
        onChange={(e) => {
          const targetValue = e.target ? e.target.value : e.currentTarget.value;
          const value =
            isNumberField && targetValue !== '' ? getClampedNumberValue(targetValue, min, max) : targetValue;
          if (!isNil(onChangeObserver)) {
            onChangeObserver(value);
          }
        }}
        onBlur={handleBlur}
        error={error}
        style={{ maxWidth: noMaxWidth ? 2500 : 280, width: '100%', top: 0, ...style }}
        inputProps={{ min, max, step }}
        InputProps={{
          ...endAdornmentProps,
          ...inputProps,
          classes: typeNumberNoEndArrow
            ? { underline: classes.underline, input: classes.noEndArrow }
            : { underline: classes.underline },
        }}
        InputLabelProps={{ className: classes.label }}
        helperText={helperText}
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
        hiddenLabel={isEmpty(title)}
        data-test={`textDetail${dataTest}`}
        onWheel={(e) => {
          if (isNumberField && e.target instanceof HTMLElement) {
            e.target.blur();
          }
        }}
        placeholder={placeholder}
      />
    );
  }

  return (
    <>
      <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12, ...titleStyle }}>{title}</Typography>
      {!children ? (
        <Typography
          style={{ ...style, color: Colors.CLASSICAL_BLACK, fontSize: 16 }}
          data-test={`textDetail${dataTest}`}
        >
          {text}
        </Typography>
      ) : (
        children
      )}
    </>
  );
};

export default TextDetailEditable;
