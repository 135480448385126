import React from 'react';
import isNil from 'lodash/isNil';

export const getFlagImgComponent = (countryCode: string, optionsSize = 25) => {
  if (isNil(countryCode)) {
    return null;
  }

  const flag = new URL(`./flags/${countryCode.toLowerCase()}.svg`, import.meta.url).href;
  return (
    <img
      src={flag}
      alt={countryCode}
      style={{
        width: `${optionsSize}px`,
        height: `${optionsSize}px`,
      }}
    />
  );
};
