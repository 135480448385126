import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { orderBy } from 'lodash';
import SkeletonComponent from 'src/components/ui/SkeletonComponent';
import { useClient } from 'src/hooks/ClientContext';
import { ReactComponent as ReceiptIcon } from 'src/icons/receipt.svg';
import {
  Colors,
  formatNumberToEuroCurrency,
  formatStandardizedUTCDate,
  ApplicationInvoiceStatus,
} from '@rentguru/commons-utils';
import { dateLocaleMap, useLocale } from 'src/i18n/IntlProviderWrapper';
import { makeStyles } from '@material-ui/styles';
import { useIntl } from 'react-intl';

const useStyles = makeStyles({
  invoiceDate: {
    color: Colors.CLASSICAL_BLACK,
    fontSize: 14,
    fontWeight: 600,
    textAlign: 'left',
    textTransform: 'capitalize',
  },
  invoiceStatus: {
    color: Colors.SLATE_GREY,
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'right',
  },
  invoicePrice: {
    color: Colors.CLASSICAL_BLACK,
    fontSize: 14,
    fontWeight: 400,
  },
});

const DATE_FORMAT = 'MMMM yyyy';

const getInvoiceStatusMessageId = (status: ApplicationInvoiceStatus) => {
  const isPaid = status === ApplicationInvoiceStatus.PAID;
  return isPaid ? 'tenant.paid' : 'tenant.unpaid';
};

const SubscriptionInvoices = () => {
  const { formatMessage } = useIntl();
  const { applicationInvoices, loading } = useClient();
  const { language } = useLocale();
  const classes = useStyles();

  if (loading) {
    return <SkeletonComponent loading />;
  }

  const orderedInvoices = orderBy(applicationInvoices, ['invoiceDate'], ['desc']);

  return (
    <Grid container>
      {orderedInvoices.map((invoice) => (
        <React.Fragment key={invoice.id}>
          <Grid item xs={1} style={{ height: 50 }}>
            <ReceiptIcon style={{ fill: Colors.BLUEY }} />
          </Grid>
          <Grid item xs={5}>
            <Typography className={classes.invoiceDate}>
              {formatStandardizedUTCDate(new Date(invoice.invoiceDate), DATE_FORMAT, {
                locale: dateLocaleMap[language],
              })}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.invoiceStatus}>
              {formatMessage({ id: getInvoiceStatusMessageId(invoice.status) })}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.invoicePrice}>{formatNumberToEuroCurrency(invoice.amount)}</Typography>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default SubscriptionInvoices;
