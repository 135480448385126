import React, { useState } from 'react';
import { CustomTableCell } from '@up2rent/ui';
import TableRow from '@material-ui/core/TableRow';
import { Typography, makeStyles } from '@material-ui/core';
import { Colors, formatNumber, LeasePriceHistory, LeaseMonthlyChargesType } from '@rentguru/commons-utils';
import { dateLocaleMap, useLocale } from '../../../../i18n/IntlProviderWrapper';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import { ROW_HEIGHT } from 'src/components/Dashboard/DashboardTodos/DashboardLeaseChargeAdjustments';
import { useHistory } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';
import { Skeleton } from '@material-ui/lab';
import LeaseMainUnitColumn from 'src/components/Leases/Tables/LeaseMainUnitColumn';
import { useLeases } from 'src/hooks/LeasesContext';
import { isNil } from 'lodash';

interface LeaseChargeAdjustmentRowProps extends LeasePriceHistory {
  tenantsNames: string;
  adjustmentRatio: number;
  currentMonthlyCharges: number;
  isSelected: boolean;
}

const useLocalStyles = makeStyles({
  box: {
    borderRadius: 4,
    fontWeight: 'bold',
    height: 30,
    marginRight: 20,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100px',
    overflow: 'hidden',
  },
});

const LeaseChargeAdjustmentRow: React.FC<LeaseChargeAdjustmentRowProps> = ({
  id,
  applicationDate,
  totalMonthlyCharges,
  amountDetails,
  tenantsNames,
  adjustmentRatio,
  currentMonthlyCharges,
  isSelected,
  lease,
}) => {
  const { push: historyPush } = useHistory();
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const { box } = useLocalStyles();
  const { getLease, leasesLoading } = useLeases();
  const [unitListAnchorEl, setUnitListAnchorEl] = useState<HTMLElement | null>(null);
  const closeUnitListPopper = () => {
    setUnitListAnchorEl(null);
  };

  if (leasesLoading) {
    return <Skeleton />;
  }
  const completeLease = getLease(lease?.id ?? '');

  return (
    <TableRow
      hover
      aria-checked={isSelected}
      tabIndex={-1}
      key={id}
      selected={isSelected}
      style={{ paddingTop: 10, minHeight: ROW_HEIGHT, height: ROW_HEIGHT }}
      onClick={() => historyPush(RouteDestination.INDEXATION, { id })}
    >
      <CustomTableCell scope="row" padding="none" style={{ paddingLeft: 20 }}>
        <Typography style={{ fontSize: 14, color: Colors.SLATE_GREY, display: 'inline' }}>
          {format(new Date(applicationDate), 'dd MMMM yyyy', { locale: dateLocaleMap[language] })}
        </Typography>
      </CustomTableCell>
      <CustomTableCell
        scope="row"
        padding="none"
        onMouseEnter={(e) => setUnitListAnchorEl(e.currentTarget)}
        onMouseLeave={closeUnitListPopper}
      >
        <LeaseMainUnitColumn
          unitLeases={completeLease?.units ?? []}
          anchorEl={unitListAnchorEl}
          open={!isNil(unitListAnchorEl)}
          handleClose={closeUnitListPopper}
        />
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        <Typography style={{ color: Colors.CLASSICAL_BLACK, fontSize: 14, fontWeight: 600 }}>{tenantsNames}</Typography>
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14 }}>
          {formatMessage({
            id: `enums.LeaseMonthlyChargesType.${
              amountDetails![0].monthlyChargesType === LeaseMonthlyChargesType.FixedPrice
                ? 'FixedPrice'
                : 'MonthlyProvisioned'
            }`,
          })}
        </Typography>
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14 }}>
          {formatNumber(adjustmentRatio / 100, language, { style: 'percent' })}
        </Typography>
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        <div
          className={box}
          style={{
            border: `1px solid ${Colors.BLUE_GREY}`,
            color: Colors.BLUE_GREY,
          }}
        >
          {formatNumber(currentMonthlyCharges, language, { style: 'currency', currency: 'EUR' })}
        </div>
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        <div
          className={box}
          style={{
            border: `1px solid ${Colors.DODGER_BLUE}`,
            color: Colors.DODGER_BLUE,
          }}
        >
          {formatNumber(totalMonthlyCharges, language, { style: 'currency', currency: 'EUR' })}
        </div>
      </CustomTableCell>
    </TableRow>
  );
};

export default LeaseChargeAdjustmentRow;
