import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import Dialog from 'src/components/ui/Dialog';
import PauseIcon from '@material-ui/icons/Pause';
import { ReactComponent as MicIcon } from 'src/icons/mic.svg';
import CustomAudioPlayer from './CustomAudioPlayer';
import { CustomIconButton } from '@up2rent/ui';
import { Colors } from '@rentguru/commons-utils';


export interface AudioRecorderProps {
  createFile: (file: File) => Promise<void>;
}
const AudioRecorder: React.FC<AudioRecorderProps> = ({ createFile }) => {
  const { formatMessage } = useIntl();
  // const { enqueueSnackbar } = useSnackbar();
  const [recording] = useState<boolean>(false);
  // const [micStream, setMicStream] = useState<MicrophoneStream | null>();
  const [audioFile, setAudioFile] = useState<File | null>(null);

  // const audioBuffer = useMemo(() => {
  //   let buffer: Buffer[] = [];
  //   let format: { bitDepth: number; channels: number; float: boolean; sampleRate: number; signed: boolean } | null;
  //   function add(raw: Buffer[]) {
  //     buffer = buffer.concat(...raw);
  //     return buffer;
  //   }
  //   function newBuffer() {
  //     buffer = [];
  //   }
  //   function setFormat(audioFormat: {
  //     bitDepth: number;
  //     channels: number;
  //     float: boolean;
  //     sampleRate: number;
  //     signed: boolean;
  //   }) {
  //     format = audioFormat;
  //   }
  //   function getFormat() {
  //     return format;
  //   }

  //   return {
  //     reset() {
  //       newBuffer();
  //     },
  //     addData(raw: Buffer[]) {
  //       return add(raw);
  //     },
  //     getData() {
  //       return buffer;
  //     },
  //     setFormat,
  //     getFormat,
  //   };
  // }, []);

  // async function startRecording() {
  //   audioBuffer.reset();

  //   window.navigator.mediaDevices
  //     .getUserMedia({ video: false, audio: true })
  //     .then((stream) => {
  //       const startMic = new MicrophoneStream();

  //       startMic.setStream(stream);
  //       // @ts-ignore
  //       startMic.on('data', (chunk: Buffer) => {
  //         const raw = MicrophoneStream.toRaw(chunk);
  //         if (raw == null) {
  //           return;
  //         }
  //         // @ts-ignore
  //         audioBuffer.addData(raw);
  //       });
  //       // @ts-ignore
  //       startMic.on(
  //         'format',
  //         (format: { bitDepth: number; channels: number; float: boolean; sampleRate: number; signed: boolean }) => {
  //           audioBuffer.setFormat(format);
  //         }
  //       );

  //       setRecording(true);
  //       setMicStream(startMic);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       let errorMessage = error.message;
  //       if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
  //         errorMessage = formatMessage({ id: 'error.audioNotFoundError' });
  //       } else if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
  //         errorMessage = formatMessage({ id: 'error.audioNotReadableError' });
  //       } else if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
  //         errorMessage = formatMessage({ id: 'error.audioNotAllowedError' });
  //       }
  //       enqueueSnackbar(errorMessage, {
  //         variant: 'warning',
  //         preventDuplicate: true,
  //         key: 'Audio record error',
  //       });
  //     });
  // }

  // async function stopRecording() {
  //   if (!isNil(micStream)) {
  //     micStream.stop();
  //     setMicStream(null);
  //   }
  //   setRecording(false);

  //   const resultBuffer: Float32Array = (audioBuffer.getData() as unknown) as Float32Array;
  //   const audioFormat = audioBuffer.getFormat();

  //   if (audioFormat) {
  //     const wav = new WaveFile();
  //     wav.fromScratch(
  //       audioFormat.channels,
  //       audioFormat.sampleRate,
  //       `${audioFormat.bitDepth}${audioFormat.float ? 'f' : ''}`,
  //       resultBuffer
  //     );

  //     const file = new File([wav.toBuffer()], `Audio-${new Date().toJSON()}.wav`, {
  //       type: 'audio/wav',
  //       lastModified: Date.now(),
  //     });
  //     setAudioFile(file);
  //   }
  // }
  const IconToDisplay = recording ? PauseIcon : MicIcon;

  return (
    <>
      <CustomIconButton
        onClick={async (event) => {
          event.stopPropagation();
          // if (recording) {
          //   stopRecording();
          // } else {
          //   startRecording();
          // }
        }}
        size="small"
        Icon={IconToDisplay}
        iconStyle={{ fill: Colors.BLUEY, width: 16, height: 16 }}
      />
      <Dialog
        disableBackdropClick
        onClick={(e) => e.stopPropagation()}
        open={!isNil(audioFile)}
        PaperProps={{ style: { borderRadius: 10, maxWidth: 'none', width: 500 } }}
        onClose={(event) => {
          (event as unknown as React.MouseEvent).stopPropagation();
          setAudioFile(null);
        }}
        style={{ zIndex: 999 }}
      >
        <DialogTitle>
          <Typography style={{ fontSize: 16, fontWeight: 800 }}>
            {formatMessage({ id: 'leaseInventory.uploadRecording' })}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {!isNil(audioFile) && (
            <CustomAudioPlayer audioFile={{ id: '', file: audioFile, url: URL.createObjectURL(audioFile) }} />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              setAudioFile(null);
            }}
            color="primary"
          >
            {formatMessage({ id: 'cancel' })}
          </Button>
          {!isNil(audioFile) && (
            <Button
              onClick={async (event) => {
                event.stopPropagation();
                await createFile(audioFile);
                setAudioFile(null);
              }}
              color="primary"
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            >
              {formatMessage({ id: 'lease.detail.action.confirm' })}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AudioRecorder;
