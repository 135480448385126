import { getTeamChildren } from 'src/utils/teamUtils';
import { ContextLoaderStore } from './ContextLoader';
import { Building, Lease, Team, Unit, UnitLease, UserTeam, isIdInList } from '@rentguru/commons-utils';
import isNil from 'lodash/isNil';

export const filterContextLoaderBundleByTeam = (contextLoaderBundle: ContextLoaderStore, userId: string) => {
  const {
    Building: { data: buildings }, // Already filtered by user rights
    BuildingOwner: { data: buildingOwners },
    Lease: { data: leases },
    LeaseContact: { data: leaseContacts },
    LeaseInventory: { data: leaseInventories },
    Notification: { data: notifications },
    Technic: { data: technics },
    Team: { data: teams },
    Ticket: { data: tickets },
    Unit: { data: units },
    UnitLease: { data: unitLeases },
    UnitOwner: { data: unitOwners },
    UserTeam: { data: userTeams },
  } = contextLoaderBundle;

  const teamsOfUser = userTeams.filter((userTeam) => !isNil(userTeam.user));
  contextLoaderBundle.UserTeam.data = teamsOfUser;
  const isUserInRootTeam = teamsOfUser.some(
    (teamOfUser) => teamOfUser.team && teamOfUser.user!.id === userId && isNil(teamOfUser.team.parentId)
  );
  if (isUserInRootTeam) {
    return contextLoaderBundle;
  }
  const {
    buildings: teamBuildings,
    units: teamUnits,
    leases: teamLeases,
    unitLeases: teamUnitLeases,
    teams: teamsFiltered,
  } = getFilteredTeamsBuildingsUnitsAndLeasesForUserId(
    userId,
    teamsOfUser,
    teams,
    buildings,
    units,
    unitLeases,
    leases
  );

  const teamLeaseInventories = leaseInventories.filter((leaseInventory) =>
    teamLeases.some((teamLease) => teamLease.id === leaseInventory.leaseId)
  );
  // Now that we have Leases + building + units we can filter ou technics
  const teamTechnics = technics.filter((technic) => {
    if (technic.building) {
      return isIdInList(technic.building, teamBuildings);
    }
    if (technic.unit) {
      return isIdInList(technic.unit, teamUnits);
    }
    if (technic.lease) {
      return isIdInList(technic.lease, teamLeases);
    }
    return false;
  });
  // We can now filter the notifications, communications and tickets
  const teamNotifications = notifications.filter((notification) => {
    if (notification.building) {
      return isIdInList(notification.building, teamBuildings);
    }
    if (notification.unit) {
      return isIdInList(notification.unit, teamUnits);
    }
    if (notification.lease) {
      return isIdInList(notification.lease, teamLeases);
    }
    if (notification.technic) {
      return isIdInList(notification.technic, teamTechnics);
    }
    return false;
  });
  const teamTickets = tickets.filter((ticket) => {
    if (ticket.building) {
      return isIdInList(ticket.building, teamBuildings);
    }
    if (ticket.unit) {
      return isIdInList(ticket.unit, teamUnits);
    }
    return false;
  });
  /* And finally remove the links of the contacts so that they appear but without the info
    about other entities */
  const teamBuildingOwners = buildingOwners.filter(
    (buildingOwner) => buildingOwner.building && isIdInList(buildingOwner.building, teamBuildings)
  );
  const teamUnitOwners = unitOwners.filter((unitOwner) => unitOwner.unit && isIdInList(unitOwner.unit, teamUnits));
  const teamLeaseContact = leaseContacts.filter(
    (leaseContact) => leaseContact.lease && isIdInList(leaseContact.lease, teamLeases)
  );
  contextLoaderBundle.Building.data = teamBuildings;
  contextLoaderBundle.BuildingOwner.data = teamBuildingOwners;
  contextLoaderBundle.Lease.data = teamLeases;
  contextLoaderBundle.LeaseContact.data = teamLeaseContact;
  contextLoaderBundle.LeaseInventory.data = teamLeaseInventories;
  contextLoaderBundle.Notification.data = teamNotifications;
  contextLoaderBundle.Technic.data = teamTechnics;
  contextLoaderBundle.Team.data = teamsFiltered;
  contextLoaderBundle.Ticket.data = teamTickets;
  contextLoaderBundle.Unit.data = teamUnits;
  contextLoaderBundle.UnitLease.data = teamUnitLeases;
  contextLoaderBundle.UnitOwner.data = teamUnitOwners;

  return contextLoaderBundle;
};

export const getFilteredTeamsBuildingsUnitsAndLeasesForUserId = (
  userId: string,
  teamsOfUser: UserTeam[],
  teams: Team[],
  buildings: Building[],
  units: Unit[],
  unitLeases: UnitLease[],
  leases: Lease[]
) => {
  const teamIdsOfUser = teamsOfUser.reduce((acc: string[], userTeam: UserTeam) => {
    if (userTeam.userId === userId) {
      acc.push(userTeam.team!.id);
      getTeamChildren(userTeam.team!.id, teams, acc);
    }
    return acc;
  }, []);
  const teamsFiltered = teams.filter((team) => teamIdsOfUser.includes(team.id));
  const teamBuildings = buildings.filter(
    (building) => building.teams && teamIdsOfUser.some((teamId) => building.teams!.includes(teamId))
  );
  // keep only the units of the building of the teams
  const teamUnits = units.filter((unit) => !isNil(unit.building) && isIdInList(unit.building, teamBuildings));
  // Keep only the leases and lease inventories of those units
  const teamUnitLeases = unitLeases.filter((unitLease) => unitLease.unit && isIdInList(unitLease.unit, teamUnits));
  const teamLeases = teamUnitLeases.reduce((acc: Lease[], unitLease: UnitLease) => {
    if (unitLease.lease && !isIdInList(unitLease.lease, acc)) {
      const completeLease = leases.find((lease) => lease.id === unitLease.lease!.id);
      if (completeLease) acc.push(completeLease);
    }
    return acc;
  }, []);

  return {
    teams: teamsFiltered,
    buildings: teamBuildings,
    units: teamUnits,
    leases: teamLeases,
    unitLeases: teamUnitLeases,
  };
};
