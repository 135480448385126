import React, { useState } from 'react';
import NotificationBox from './NotificationBox';
import { LeaseContext, LeaseExtended, useLeases } from '../../../hooks/LeasesContext';
import {
  LeaseAction,
  LeaseInventory,
  LeaseStatus,
  LeaseType,
  SignatureDocumentStatus,
  LEASE_OPERATION_BOX_KEY,
  hasLeaseAlreadyBeenEnded,
} from '@rentguru/commons-utils';
import { useLeaseInventories } from '../../../hooks/LeaseInventoryContext';
import SlicedContent from './SlicedContent';
import { useUser } from 'src/hooks/UserContext';
import isNil from 'lodash/isNil';
import { compareAsc, differenceInMonths } from 'date-fns';
import LeaseOperationRow from './BoxCustomRows/LeaseOperationRow';
import LeaseOperationsHeader from './BoxCustomHeaders/LeaseOperationsHeader';
import { ConfirmDialog } from '@up2rent/ui';
import { useIntl } from 'react-intl';
import TodosLoading from './TodosLoading';
import { SignatureDocumentsContext, useSignatureDocuments } from 'src/hooks/SignatureDocumentContext';
import ExtendLeaseDialog from 'src/components/ui/Dialogs/ExtendLeaseDialog';
import { LeaseActionHistoryContext, useLeaseActionHistories } from 'src/hooks/LeaseActionHistoriesContext';

export const ROW_HEIGHT = 27;

const leaseOperationsSortFunction = (
  operation1: LeaseExtended | LeaseInventory,
  operation2: LeaseExtended | LeaseInventory,
  getLease: (id: string) => LeaseExtended | undefined | null
): number => {
  if (operation1.status === LeaseStatus.Rejected) return 1;
  if (operation2.status === LeaseStatus.Rejected) return -1;
  const lease1 = 'inventoryType' in operation1 ? getLease(operation1.leaseId) : operation1;
  if (isNil(lease1)) return 1;
  const lease2 = 'inventoryType' in operation2 ? getLease(operation2.leaseId) : operation2;
  if (isNil(lease2)) return -1;
  const dateToCmp1 = operation1.status === LeaseStatus.OutForSignature ? lease1.startDate : lease1.endDate;
  const dateToCmp2 = operation2.status === LeaseStatus.OutForSignature ? lease2.startDate : lease2.endDate;
  const dateCmp = compareAsc(new Date(dateToCmp1), new Date(dateToCmp2));
  if (dateCmp !== 0) return dateCmp;

  const type1 = 'type' in operation1 ? operation1.type : (operation1 as LeaseInventory).inventoryType;
  const type2 = 'type' in operation2 ? operation2.type : (operation2 as LeaseInventory).inventoryType;
  if (isNil(type1)) return 1;
  if (isNil(type2)) return -1;
  return type1.localeCompare(type2);
};

const getLeaseOperationsRow = (
  leases: LeaseExtended[],
  leaseInventories: LeaseInventory[],
  getLease: LeaseContext['getLease'],
  getSignatureDocumentsForEntity: SignatureDocumentsContext['getSignatureDocumentsForEntity'],
  getLeaseActionHistoriesOfLease: LeaseActionHistoryContext['getLeaseActionHistoriesOfLease'],
  memberId: string,
  isOwner: boolean
) => {
  let leaseActionsNeeded: LeaseExtended[] = [];
  let leaseInventoriesActionsNeeded: LeaseInventory[] = [];
  // Admin tickets, get everything that has been reject / need to be signed / need to be extened
  if (!isOwner) {
    leaseActionsNeeded = leases.filter((lease) => {
      if (lease.status === LeaseStatus.Rejected) {
        return true;
      }

      if (lease.status === LeaseStatus.OutForSignature) {
        const pendingSignatureDocuments = getSignatureDocumentsForEntity(
          'Lease',
          lease.id,
          SignatureDocumentStatus.PENDING
        );

        return (
          pendingSignatureDocuments &&
          pendingSignatureDocuments.some((document) =>
            document.signatureResults.some((result) => result.contactId === memberId && isNil(result.signed))
          )
        );
      }
      if (lease.status === LeaseStatus.Active && lease.canBeExtended !== false) {
        const actions = getLeaseActionHistoriesOfLease(lease.id);
        const alreadyEnded = hasLeaseAlreadyBeenEnded(actions);
        const noticePeriod = lease.type === LeaseType.COMMERCIAL ? 6 : 3;
        return !alreadyEnded && differenceInMonths(new Date(lease.endDate), new Date()) <= noticePeriod + 1;
      }
      return false;
    });

    leaseInventoriesActionsNeeded = leaseInventories.filter((l) => {
      if (l.status === LeaseStatus.Rejected) return true;
      if (l.status === LeaseStatus.OutForSignature) {
        const lease = getLease(l.leaseId);
        if (isNil(lease)) return false;

        const pendingSignatureDocuments = getSignatureDocumentsForEntity(
          'LeaseInventory',
          l.id,
          SignatureDocumentStatus.PENDING
        );

        return (
          pendingSignatureDocuments &&
          pendingSignatureDocuments.some((document) =>
            document.signatureResults.some((result) => result.contactId === memberId && isNil(result.signed))
          )
        );
      }
      return false;
    });
  }
  // Owner ticket => Only things that need to be signed
  else {
    leaseActionsNeeded = leases.filter((l) => {
      if (l.status === LeaseStatus.OutForSignature) {
        const pendingSignatureDocuments = getSignatureDocumentsForEntity(
          'Lease',
          l.id,
          SignatureDocumentStatus.PENDING
        );

        return (
          pendingSignatureDocuments &&
          pendingSignatureDocuments.some((document) =>
            document.signatureResults.some((result) => result.contactId === memberId && isNil(result.signed))
          )
        );
      }
      return false;
    });

    leaseInventoriesActionsNeeded = leaseInventories.filter((l) => {
      if (l.status === LeaseStatus.OutForSignature) {
        const lease = getLease(l.leaseId);
        if (isNil(lease)) return false;
        const pendingSignatureDocuments = getSignatureDocumentsForEntity(
          'LeaseInventory',
          l.id,
          SignatureDocumentStatus.PENDING
        );

        return (
          pendingSignatureDocuments &&
          pendingSignatureDocuments.some((document) =>
            document.signatureResults.some((result) => result.contactId === memberId && isNil(result.signed))
          )
        );
      }
      return false;
    });
  }
  return [...leaseActionsNeeded, ...leaseInventoriesActionsNeeded];
};

const DashboardLeaseActions: React.FC = () => {
  const { formatMessage } = useIntl();
  const [leaseAction, setLeaseAction] = useState<{
    lease: LeaseExtended;
    action?: LeaseAction;
  } | null>(null);
  const { leases, leasesLoading, getLease, updateLease } = useLeases();
  const { getSignatureDocumentsForEntity, loading } = useSignatureDocuments();
  const { memberId, ownerId, isOwner } = useUser();
  const { leaseInventories, loading: leaseInventoriesLoading } = useLeaseInventories();
  const { getLeaseActionHistoriesOfLease, loading: leaseActionHistoriesLoading } = useLeaseActionHistories();
  if (leasesLoading || leaseInventoriesLoading || loading || leaseActionHistoriesLoading) {
    return (
      <>
        <TodosLoading />
      </>
    );
  }
  const currentContactId = isOwner ? ownerId : memberId;
  const leaseOperations = getLeaseOperationsRow(
    leases,
    leaseInventories ?? [],
    getLease,
    getSignatureDocumentsForEntity,
    getLeaseActionHistoriesOfLease,
    currentContactId!,
    isOwner
  );
  const allActionsNeeded = leaseOperations.sort((l1, l2) => {
    return leaseOperationsSortFunction(l1, l2, getLease);
  });
  const operationsToDisplay = allActionsNeeded.map((operation: LeaseExtended | LeaseInventory) => {
    return (
      <LeaseOperationRow
        key={operation.id}
        entity={operation}
        type={'inventoryType' in operation ? 'inventory' : 'lease'}
        setLeaseAction={setLeaseAction}
      />
    );
  });

  return (
    <>
      <NotificationBox
        boxKey={LEASE_OPERATION_BOX_KEY}
        title={`${formatMessage({ id: 'dashboard.leaseOperations' })} (${operationsToDisplay.length})`}
      >
        <SlicedContent
          data={operationsToDisplay}
          header={<LeaseOperationsHeader />}
          type="table"
          limit={5}
          boxKey={LEASE_OPERATION_BOX_KEY}
          rowHeight={ROW_HEIGHT}
        />
      </NotificationBox>
      {leaseAction?.action === LeaseAction.EXTEND_LEASE && (
        <ExtendLeaseDialog
          lease={leaseAction.lease}
          open={true}
          onClose={() => {
            setLeaseAction(null);
          }}
        />
      )}

      {!isNil(leaseAction) && isNil(leaseAction.action) && (
        <ConfirmDialog
          open={true}
          confirmText={formatMessage({ id: 'lease.detail.action.confirm' })}
          confirmAction={async () => {
            await updateLease(leaseAction!.lease, { canBeExtended: false });
            setLeaseAction(null);
          }}
          cancelAction={() => setLeaseAction(null)}
          mainText={formatMessage({ id: 'dashboard.lease.deleteExtendReminder' })}
          formatMessage={formatMessage}
        />
      )}
    </>
  );
};

export default DashboardLeaseActions;
