import { Divider, Grid, Typography } from '@material-ui/core';
import {
  Colors,
  CustomValue,
  STATEMENT_AMOUNT_OWNER_TOTAL_ACTUAL_EXPENSES,
  STATEMENT_AMOUNT_OWNER_TOTAL_AGENCY_FEES,
  STATEMENT_AMOUNT_OWNER_TOTAL_REPAYMENT,
  formatNumber,
} from '@rentguru/commons-utils';
import React from 'react';
import { useIntl } from 'react-intl';
import { CustomPopover } from '@up2rent/ui';
import { useLocale } from 'src/i18n/IntlProviderWrapper';

interface OwnerStatementsToValidatePopperProps {
  statementAmounts: CustomValue[];
  anchorEl: HTMLElement;
  open: boolean;
  handleClose: () => void;
}

const OwnerStatementsToValidatePopper: React.FC<OwnerStatementsToValidatePopperProps> = ({
  statementAmounts,
  anchorEl,
  open,
  handleClose,
}) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();

  const totalRentReceived = Number(
    statementAmounts.find((a) => a.name === STATEMENT_AMOUNT_OWNER_TOTAL_REPAYMENT)?.value ?? 0
  );
  const totalExpenses = Number(
    statementAmounts.find((a) => a.name === STATEMENT_AMOUNT_OWNER_TOTAL_ACTUAL_EXPENSES)?.value ?? 0
  );
  const totalFees = Number(
    statementAmounts.find((a) => a.name === STATEMENT_AMOUNT_OWNER_TOTAL_AGENCY_FEES)?.value ?? 0
  );

  return (
    <CustomPopover
      id="split-info-popover"
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handleClose}
      disableRestoreFocus
      paperBorderRadius={10}
    >
      <Grid container alignItems="center">
        <Grid item xs={3}>
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 'bold',
              fontStyle: 'normal',
              letterSpacing: 0,
              color: Colors.DODGER_BLUE,
              marginTop: 15,
              marginBottom: 15,
            }}
          >
            {formatNumber(totalRentReceived, language, { style: 'currency', currency: 'EUR' })}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography
            variant="subtitle1"
            style={{
              fontSize: 12,
              fontWeight: 'normal',
              fontStyle: 'normal',
              letterSpacing: 0,
              color: Colors.SLATE_GREY,
              textOverflow: 'ellipsis',
            }}
          >
            {formatMessage({ id: 'accounting.statement.toValidateSection.totalRentReceived' })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 'bold',
              fontStyle: 'normal',
              letterSpacing: 0,
              color: Colors.CARNATION_RED,
              marginTop: 15,
              marginBottom: 15,
            }}
          >
            {formatNumber(totalExpenses, language, { style: 'currency', currency: 'EUR' })}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography
            variant="subtitle1"
            style={{
              fontSize: 12,
              fontWeight: 'normal',
              fontStyle: 'normal',
              letterSpacing: 0,
              color: Colors.SLATE_GREY,
              textOverflow: 'ellipsis',
            }}
          >
            {formatMessage({ id: 'accounting.statement.toValidateSection.totalExpenses' })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 'bold',
              fontStyle: 'normal',
              letterSpacing: 0,
              color: Colors.CARNATION_RED,
              marginTop: 15,
              marginBottom: 15,
            }}
          >
            {formatNumber(totalFees, language, { style: 'currency', currency: 'EUR' })}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography
            variant="subtitle1"
            style={{
              fontSize: 12,
              fontWeight: 'normal',
              fontStyle: 'normal',
              letterSpacing: 0,
              color: Colors.SLATE_GREY,
              textOverflow: 'ellipsis',
            }}
          >
            {formatMessage({ id: 'accounting.statement.toValidateSection.totalFees' })}
          </Typography>
        </Grid>
      </Grid>
    </CustomPopover>
  );
};

export default OwnerStatementsToValidatePopper;
