import { makeStyles } from '@material-ui/core';
import { Colors, LooseObject } from '@rentguru/commons-utils';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ActionButtonSecond } from '@up2rent/ui';
import { ReactComponent as IconAdd } from '../../icons/icon-add.svg';

const useLocalStyles = makeStyles({
  addInfoDiv: {
    color: Colors.SLATE_GREY,
  },
  addInfoBtnText: {
    position: 'relative',
    bottom: '5px',
    marginLeft: '12px',
    textDecoration: 'underline',
  },
});

const buttonStyle = {
  width: '97%',
  height: '60px',
  border: `dashed 1px ${Colors.BLUEY}`,
  borderRadius: '6px',
  backgroundColor: 'transparent',
  marginBottom: '10px',
  boxShadow: 'none',
  margin: ' 20px 20px 0 0 ',
};

interface AddInfoButonProps {
  destination: LooseObject | string;
}

const AddInfoButon: React.FC<AddInfoButonProps> = ({ destination }) => {
  const localClasses = useLocalStyles();
  const { formatMessage } = useIntl();
  return (
    <ActionButtonSecond to={destination} component={Link} style={buttonStyle}>
      <div className={localClasses.addInfoDiv}>
        <IconAdd />
        <span className={localClasses.addInfoBtnText}>{formatMessage({ id: 'technic.addInfo' })}</span>
      </div>
    </ActionButtonSecond>
  );
};

export default AddInfoButon;
