/* eslint-disable @typescript-eslint/no-shadow */
import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  LeasePriceHistory,
  LeaseAmountUpdateStatus,
  UpdateLeasePriceHistoryInput,
  applyProRata,
  getContactNameFromObject,
  getMainUnit,
  ModelWithVersion,
} from '@rentguru/commons-utils';
import { ReactComponent as Logo } from '../../../../icons/transactionPlaceholder.svg';
import { EnhancedTableScrollable, EnhancedTableSharedContextProvider, TablePlaceHolder } from '@up2rent/ui';
import { FilterEntity, useFilters } from 'src/hooks/FiltersContext';
import { useFieldFilterReset } from 'src/components/ui/FieldFilterSelector';
import LeaseChargeAdjustmentRow from './LeaseChargeAdjustmentRow';
import { isEmpty, isNil } from 'lodash';
import { useLeases } from 'src/hooks/LeasesContext';
import { LeaseChargeAdjustementExtended } from '../TenantStatements';
import { filterChargesAdjustments } from '../TenantStatementsFilters';
import ConfirmLeaseAdjustmentDialog from './ConfirmLeaseAdjustmentDialog';
import SkipChargeAdjustmentDialog from './SkipChargeAdjustmentDialog';
import { ReactComponent as NoResultIcon } from 'src/icons/noResult.svg';

const columns = [
  {
    id: 'adjustmentDate',
    labelId: 'accounting.chargeAdjustments.adjustmentDate',
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
  {
    id: 'unitName',
    labelId: 'lease.addLease.mainUnit',
    numeric: false,
    disablePadding: true,
    sortable: true,
  },
  {
    id: 'tenantsNames',
    labelId: 'lease.detail.general.tenants',
    numeric: false,
    disablePadding: true,
    sortable: true,
  },
  {
    id: 'monthlyChargesType',
    labelId: 'communications.filter.type',
    numeric: false,
    disablePadding: true,
    sortable: true,
  },
  {
    id: 'adjustmentRatio',
    labelId: 'accounting.chargeAdjustments.difference',
    numeric: false,
    disablePadding: true,
    sortable: true,
  },
  {
    id: 'currentMonthlyCharges',
    labelId: 'accounting.chargeAdjustments.currentAmount',
    numeric: false,
    disablePadding: true,
    sortable: true,
  },
  {
    id: 'monthlyCharges',
    labelId: 'accounting.chargeAdjustments.proposedAmount',
    numeric: false,
    disablePadding: true,
    sortable: true,
  },
];

export interface LeaseChargeAdjustmentsProps {
  leaseChargeAdjustmentsTodos: LeaseChargeAdjustementExtended[];
  leaseChargeAdjustmentsLoading: boolean;
  updateLeaseChargeAdjustment: (updates: UpdateLeasePriceHistoryInput) => Promise<LeasePriceHistory>;
}

const LeaseChargeAdjustments: React.FC<LeaseChargeAdjustmentsProps> = ({
  leaseChargeAdjustmentsTodos,
  leaseChargeAdjustmentsLoading,
  updateLeaseChargeAdjustment,
}) => {
  const { formatMessage } = useIntl();
  const [dialogDeleteOpen, setDialogDeleteOpen] = useState<string>('');
  const [validateDialogOpen, setValidateDialogOpen] = useState<string>('');
  const { tenantStatementsFilters, resetFilters } = useFilters();
  const { executeResetFilters } = useFieldFilterReset();
  const { leasesLoading, getLease } = useLeases();

  const handleActionMenu = async (_anchorEl: HTMLElement, id: string, eventType: 'VALIDATE' | 'DELETE') => {
    if (eventType === 'VALIDATE') {
      setValidateDialogOpen(id);
    } else {
      setDialogDeleteOpen(id);
    }
  };

  if (leaseChargeAdjustmentsLoading || leasesLoading) {
    return <Typography>{formatMessage({ id: 'loading' })}</Typography>;
  }
  const noFiltersSelected = isEmpty(tenantStatementsFilters.filters) && isNil(tenantStatementsFilters.statementsPeriod);

  const leaseChargeAdjustmentsTodosData = leaseChargeAdjustmentsTodos.reduce((result, leaseChargeAdjustment) => {
    if (!leaseChargeAdjustment || !leaseChargeAdjustment.amountDetails) {
      return result;
    }

    const lease = getLease(leaseChargeAdjustment.lease?.id ?? '');
    if (!lease) {
      return result;
    }

    const mainUnit = getMainUnit(lease.units ?? []);
    const unitName = mainUnit?.name ?? '';
    const tenantsNames = leaseChargeAdjustment.tenants.map((t) => getContactNameFromObject(t)).join(', ');

    const unitLease = leaseChargeAdjustment.amountDetails![0].unitId
      ? leaseChargeAdjustment.lease?.units?.find(
          (u) => u.unit && u.unit.id === leaseChargeAdjustment.amountDetails![0].unitId
        )
      : undefined;
    const currentAmount = unitLease && unitLease.monthlyCharges ? unitLease.monthlyCharges : 0;
    const adjustmentRatio =
      currentAmount !== 0 ? applyProRata(100, leaseChargeAdjustment.totalMonthlyCharges, currentAmount) - 100 : 0;
    result.push({
      ...leaseChargeAdjustment,
      tenantsNames,
      unitName,
      adjustmentRatio,
      currentMonthlyCharges: currentAmount,
    });
    return result;
    // eslint-disable-next-line max-len
  }, [] as (LeaseChargeAdjustementExtended & { tenantsNames: string; unitName: string; adjustmentRatio: number; currentMonthlyCharges: number })[]);

  const filteredLeaseChargesTodos = filterChargesAdjustments(leaseChargeAdjustmentsTodosData, tenantStatementsFilters);
  const leaseChargeAdjustmentToValidate =
    validateDialogOpen !== '' ? leaseChargeAdjustmentsTodos.find((st) => st.id === validateDialogOpen) : undefined;
  const leaseChargeAdjustmentToDelete =
    dialogDeleteOpen !== '' ? leaseChargeAdjustmentsTodos.find((st) => st.id === dialogDeleteOpen) : undefined;
  return (
    <EnhancedTableSharedContextProvider
      value={{ orderBy: 'adjustmentDate' }}
      orderFirst="desc"
      datas={filteredLeaseChargesTodos}
      handleActionMenu={handleActionMenu}
    >
      <EnhancedTableScrollable
        NoResultIcon={<NoResultIcon />}
        formatMessage={formatMessage}
        datas={filteredLeaseChargesTodos}
        columns={columns}
        defaultOrderBy="adjustmentDate"
        rowComponent={LeaseChargeAdjustmentRow}
        placeHolder={
          <TablePlaceHolder
            Icon={<Logo width={72} />}
            mainText={formatMessage({ id: 'accounting.chargeAdjustments.noChargeAdjustements' })}
            subText=""
            mainTextStyle={{ maxWidth: 450 }}
          />
        }
        filtersSelected={!noFiltersSelected}
        resetFiltersFunction={() => {
          executeResetFilters();
          resetFilters(FilterEntity.TENANT_STATEMENTS);
        }}
      />
      {leaseChargeAdjustmentToDelete && (
        <SkipChargeAdjustmentDialog
          open={!isNil(leaseChargeAdjustmentToDelete)}
          leaseChargeAdjustment={leaseChargeAdjustmentToDelete}
          updateLeaseChargeAdjustment={updateLeaseChargeAdjustment}
          onClose={() => setDialogDeleteOpen('')}
        />
      )}
      {leaseChargeAdjustmentToValidate && (
        <ConfirmLeaseAdjustmentDialog
          open={!isNil(leaseChargeAdjustmentToValidate)}
          leaseChargeAdjustment={leaseChargeAdjustmentToValidate}
          valideAdjustment={async (leaseChargeAdjustmentToValidate: LeasePriceHistory) => {
            await updateLeaseChargeAdjustment({
              id: leaseChargeAdjustmentToValidate.id,
              status: LeaseAmountUpdateStatus.ACCEPTED_PROCESSED_NEED_TO_APPLY,
              _version: (leaseChargeAdjustmentToValidate as ModelWithVersion<LeasePriceHistory>)._version,
            });
          }}
          onClose={() => setValidateDialogOpen('')}
        />
      )}
    </EnhancedTableSharedContextProvider>
  );
};

export default LeaseChargeAdjustments;
