/* eslint-disable max-len */
import React, { useState } from 'react';
import { IntlFormatters, useIntl } from 'react-intl';
import { isNil } from 'lodash';
import { Grid, TableCell, TableRow } from '@material-ui/core';
import {
  SendingSourceStatus,
  SendingSourceVerifiedType,
  Colors,
  getSendingSourceStatusColor,
  getSendingSourceStatusName,
  isSendingSourceDefault,
} from '@rentguru/commons-utils';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import {
  OverflowableTypography,
  ActionButtonSecond,
  actionButtonSecondStyles,
  ColoredBox,
  CollapsingChevron,
} from '@up2rent/ui';
import MoreHorizontalIconButton from 'src/components/ui/CustomIconButtons/MoreHorizontalIconButton';
import AddressRow, { AddressType } from './AddressRow';

export const getButtonName = (
  status: SendingSourceStatus,
  verifiedType: SendingSourceVerifiedType,
  formatMessage: IntlFormatters['formatMessage']
) => {
  if (status === SendingSourceStatus.ERROR) {
    if (verifiedType === SendingSourceVerifiedType.DEFAULT) {
      return formatMessage({ id: 'settings.sendingSources.manage' });
    }
    return formatMessage({ id: 'settings.sendingSources.showError' });
  }
  return formatMessage({ id: 'settings.sendingSources.verifyDomain' });
};
interface DomainRowProps {
  status: SendingSourceStatus;
  verifiedType: SendingSourceVerifiedType;
  id: string;
  name: string;
  actionMenu: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => void;
  handleDetail: (id: string) => void;
}

const DomainRow: React.FC<DomainRowProps> = ({ status, id, name, verifiedType, actionMenu, handleDetail }) => {
  const actionButtonClasses = actionButtonSecondStyles();
  const { formatMessage } = useIntl();
  const { settingsAutomationWrite } = usePermissions();
  const [emailAddressesOpen, setEmailAddressesOpen] = useState<boolean>(false);
  const statusText = getSendingSourceStatusName(status, verifiedType, formatMessage);
  const statusColor = getSendingSourceStatusColor(status);
  const showInnerButton =
    status === SendingSourceStatus.NONE ||
    status === SendingSourceStatus.PENDING ||
    status === SendingSourceStatus.ERROR;
  const backgroundColor = isSendingSourceDefault(verifiedType) ? Colors.PORCELAIN_GREY_1 : '';
  const baseStyle = emailAddressesOpen ? { borderBottom: 'none', paddingBottom: 0 } : {};

  return (
    <>
      <TableRow key={id} style={{ backgroundColor }}>
        <TableCell padding="normal" align="center" style={{ paddingRight: 0, paddingLeft: 0, ...baseStyle }}>
          <CollapsingChevron isOpen={emailAddressesOpen} setOpen={setEmailAddressesOpen} />
        </TableCell>
        <TableCell padding="normal" style={{ paddingLeft: 0, ...baseStyle }}>
          <OverflowableTypography
            text={name}
            style={{ fontWeight: isSendingSourceDefault(verifiedType) ? 800 : 'normal', maxWidth: 230 }}
          />
        </TableCell>
        <TableCell padding="normal" style={baseStyle}>
          <Grid style={{ display: 'flex' }}>
            <ColoredBox boxColor={statusColor}>{statusText}</ColoredBox>
            {showInnerButton && settingsAutomationWrite && (
              <ActionButtonSecond
                className={actionButtonClasses.buttonWithMargin}
                onClick={() => {
                  handleDetail(id);
                }}
                style={{ marginLeft: 10, textTransform: 'none' }}
              >
                {getButtonName(status, verifiedType, formatMessage)}
              </ActionButtonSecond>
            )}
          </Grid>
        </TableCell>

        {settingsAutomationWrite && (
          <TableCell padding="normal" style={{ paddingLeft: 0, ...baseStyle }}>
            <MoreHorizontalIconButton
              style={{ paddingLeft: 0 }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                if (!isNil(actionMenu)) {
                  actionMenu(event, id);
                }
              }}
              size="small"
            />
          </TableCell>
        )}
      </TableRow>
      {emailAddressesOpen &&
        Object.values(AddressType).map((addressType) => (
          <AddressRow key={addressType} addressType={addressType} backgroundColor={backgroundColor} id={id} />
        ))}
    </>
  );
};

export default DomainRow;
