import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import isNil from 'lodash/isNil';
import { Grid } from '@material-ui/core';
import Dialog from 'src/components/ui/Dialog';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Colors } from '@rentguru/commons-utils';
import { ReactComponent as Face } from 'src/icons/face.svg';
import { useUsers } from 'src/hooks/UsersContext';
import { getDisplayableUserName, isInvitePending } from 'src/utils/userutils';
import { RouteDestination } from 'src/components/Routes/Routes';
import { ActionButtonSecond, actionButtonSecondStyles , CustomIconButton } from '@up2rent/ui';
import ContentDetailHeader from 'src/components/ui/ContentDetailHeader';
import DeleteMember from '../DeleteMember';
import MemberDetail from './MemberDetail';

interface GeneralDetailsProps {
  userId: string;
  onClose?: () => void;
}

const GeneralDetails: React.FC<GeneralDetailsProps> = ({ userId, onClose }) => {
  const actionButtonClasses = actionButtonSecondStyles();
  const { formatMessage } = useIntl();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const match = useRouteMatch<{ memberId: string; detailName: string }>({
    path: `${RouteDestination.SETTINGS}/members/:memberId?/:detailName?`,
  });
  const { getUser } = useUsers();
  const user = getUser(userId);
  if (isNil(match) || isNil(user)) {
    return null;
  }
  const handleCloseDialogs = () => {
    setDeleteDialogOpen(false);
    if (!isNil(onClose)) {
      onClose();
    }
  };

  return (
    <Grid container style={{ flexGrow: 1, overflow: 'auto', height: '100%' }}>
      <Grid
        item
        style={{
          height: '100%',
          width: 60,
          overflow: 'visible',
          marginLeft: 15,
          marginRight: 15,
        }}
      >
        <CustomIconButton
          style={{ marginTop: 30, marginBottom: 70 }}
          aria-label="Menu"
          onClick={onClose}
          Icon={ArrowBack}
          iconStyle={{ fill: Colors.BLUE_GREY }}
        />
      </Grid>
      <Grid
        style={{
          flexGrow: 1,
          width: 'calc(100% - 280px)',
        }}
      >
        <ContentDetailHeader
          EntityIcon={Face}
          title={getDisplayableUserName(user)}
          actions={
            isInvitePending(user)
              ? [
                  <ActionButtonSecond
                    className={actionButtonClasses.buttonWithMargin}
                    key="delete"
                    onClick={() => setDeleteDialogOpen(true)}
                  >
                    {formatMessage({ id: 'delete' })}
                  </ActionButtonSecond>,
                ]
              : []
          }
        />
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: '10px',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 24,
            paddingBottom: 24,
            minHeight: 'calc(100% - 168px)',
          }}
        >
          <Switch>
            <Route
              path="*"
              render={() => {
                return <MemberDetail user={user} />;
              }}
            />
          </Switch>
        </div>
      </Grid>
      <Dialog
        open={deleteDialogOpen}
        onClose={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();
          handleCloseDialogs();
        }}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DeleteMember afterSubmit={handleCloseDialogs} user={user} />
      </Dialog>
    </Grid>
  );
};

export default GeneralDetails;
