import { Divider, Grid, Typography } from '@material-ui/core';
import {
  CommunicationChannel,
  CommunicationSettingsProfile,
  CommunicationSettingsProfileReferent,
} from '@rentguru/commons-utils';
import { useIntl } from 'react-intl';
import { OptionalSettings } from '../CommunicationSettingsProfileUtils/CommunicationSettingsCommonsUtils';
import { LeaseSettableCommunicationType } from '../CommunicationSettingsProfileUtils/LeaseCommunicationSettingsUtils';
import CommunicationSettingsFormItem from './CommunicationSettingsForm';
interface LeaseCommunicationSettingsFormProps {
  defaultCollapse?: boolean;
  previewProfile?: CommunicationSettingsProfile;
}

const LeaseCommunicationSettingsForm: React.FC<LeaseCommunicationSettingsFormProps> = ({
  defaultCollapse = false,
  previewProfile = null,
}) => {
  const { formatMessage } = useIntl();
  const communicationTypesGroups = [
    {
      id: 'rentPaymentRequests',
      communicationTypesOptions: [
        {
          communicationType: LeaseSettableCommunicationType.LEASE_PAYMENT_REQUEST,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
          optionalSettings: [OptionalSettings.DAYS_BEFORE_DUE_DATE],
        },
        {
          communicationType: LeaseSettableCommunicationType.LEASE_CREDIT_NOTE,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
        },
        {
          communicationType: LeaseSettableCommunicationType.LEASE_OPERATION_PAYMENT_REQUEST,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
        },
        {
          communicationType: LeaseSettableCommunicationType.LEASE_PAYMENT_RECEIPT,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
        },
      ],
    },
    {
      id: 'rentReminders',
      communicationTypesOptions: [
        {
          communicationType: LeaseSettableCommunicationType.LEASE_UNPAID_AMOUNT_REMINDER,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
          optionalSettings: [OptionalSettings.DAYS_OVERDUE],
        },
        {
          communicationType: LeaseSettableCommunicationType.LEASE_UNPAID_AMOUNT_SECOND_REMINDER,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
          optionalSettings: [OptionalSettings.DAYS_OVERDUE],
        },
        {
          communicationType: LeaseSettableCommunicationType.LEASE_UNPAID_AMOUNT_FORMAL_NOTICE,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
          optionalSettings: [OptionalSettings.DAYS_OVERDUE],
        },
      ],
    },
    {
      id: 'statementsSending',
      communicationTypesOptions: [
        {
          communicationType: LeaseSettableCommunicationType.LEASE_TENANT_STATEMENT,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
        },
      ],
    },
    {
      id: 'contractTerminationExtension',
      communicationTypesOptions: [
        {
          communicationType: LeaseSettableCommunicationType.LEASE_CONTRACT_EXTENDED,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
        },
        {
          communicationType: LeaseSettableCommunicationType.LEASE_CONTRACT_REJECTED,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
        },
        {
          communicationType: LeaseSettableCommunicationType.LEASE_CONTRACT_RETRACTED,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
        },
        {
          communicationType: LeaseSettableCommunicationType.LEASE_CONTRACT_REVOKED,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
        },
        {
          communicationType: LeaseSettableCommunicationType.LEASE_TERMINATION,
          possibleCommunicationChannel: [CommunicationChannel.REGISTERED_LETTER],
        },
      ],
    },
    {
      id: 'rentalAdaptation',
      communicationTypesOptions: [
        {
          communicationType: LeaseSettableCommunicationType.LEASE_RENT_INDEXATION,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
        },
        {
          communicationType: LeaseSettableCommunicationType.LEASE_RENT_INDEXATION_SKIPPED,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
        },
        {
          communicationType: LeaseSettableCommunicationType.LEASE_RENTAL_ADAPTATION,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
        },
        {
          communicationType: LeaseSettableCommunicationType.LEASE_CHARGES_ADJUSTMENT,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
        },
      ],
    },
    {
      id: 'leaseOperationMovements',
      communicationTypesOptions: [
        {
          communicationType: LeaseSettableCommunicationType.LEASE_DISCOUNT_CREATED,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
        },
        {
          communicationType: LeaseSettableCommunicationType.LEASE_DISCOUNT_DELETED,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
        },
        {
          communicationType: LeaseSettableCommunicationType.LEASE_DISCOUNT_UPDATED,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
        },
        {
          communicationType: LeaseSettableCommunicationType.LEASE_OPERATION_CREATED,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
        },
        {
          communicationType: LeaseSettableCommunicationType.LEASE_OPERATION_DELETED,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
        },
        {
          communicationType: LeaseSettableCommunicationType.LEASE_OPERATION_UPDATED,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
        },
      ],
    },
    {
      id: 'leaseInfoUpdate',
      communicationTypesOptions: [
        {
          communicationType: LeaseSettableCommunicationType.LEASE_ALLOW_PROFESSIONAL_ACTIVITY_ON,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
        },
        {
          communicationType: LeaseSettableCommunicationType.LEASE_BANKING_INFO_UPDATE,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
        },
      ],
    },
    {
      id: 'documentSignatures',
      communicationTypesOptions: [
        {
          communicationType: LeaseSettableCommunicationType.DOCUMENT_SIGNATURE_REMINDER,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
          optionalSettings: [OptionalSettings.DAYS_OVERDUE],
        },
        {
          communicationType: LeaseSettableCommunicationType.DOCUMENT_SIGNED,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
        },
        {
          communicationType: LeaseSettableCommunicationType.LEASE_CONTRACT_SIGNATURE_REMINDER,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
          optionalSettings: [OptionalSettings.DAYS_OVERDUE],
        },
        {
          communicationType: LeaseSettableCommunicationType.LEASE_CONTRACT_SIGNED,
          possibleCommunicationChannel: [CommunicationChannel.EMAIL],
        },
      ],
    },
  ];
  return (
    <Grid>
      {communicationTypesGroups.map((communicationTypesGroup) => {
        return (
          <Grid key={communicationTypesGroup.id}>
            <Typography
              style={{ textAlign: 'start', fontWeight: 'bold', fontSize: 20, marginTop: 40, marginBottom: 20 }}
            >
              {formatMessage({ id: `settings.communications.communicationTypesGroups.${communicationTypesGroup.id}` })}
            </Typography>
            {communicationTypesGroup.communicationTypesOptions.map((communicationTypeOptions) => {
              return (
                <Grid key={communicationTypeOptions.communicationType}>
                  <CommunicationSettingsFormItem
                    communicationType={communicationTypeOptions.communicationType}
                    possibleCommunicationChannels={communicationTypeOptions.possibleCommunicationChannel}
                    optionalSettings={communicationTypeOptions.optionalSettings}
                    referent={CommunicationSettingsProfileReferent.LEASE}
                    defaultCollapse={defaultCollapse}
                    previewProfile={previewProfile}
                  />
                  <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                </Grid>
              );
            })}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default LeaseCommunicationSettingsForm;
