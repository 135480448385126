import { Colors } from '@rentguru/commons-utils';
import { ReactComponent as DeleteIcon } from 'src/icons/trash.svg';
import { CustomIconButton, CustomIconButtonProps } from '@up2rent/ui';
import { forwardRef } from 'react';

// eslint-disable-next-line react/display-name
const NotificationDeleteIconButton = forwardRef<HTMLButtonElement, CustomIconButtonProps>(({ ...props }, ref) => (
  <CustomIconButton ref={ref} iconStyle={{ padding: 5, fill: Colors.TOWER_GREY }} Icon={DeleteIcon} {...props} />
));

export default NotificationDeleteIconButton;
