import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import Group from '@material-ui/icons/Group';
import Person from '@material-ui/icons/Person';
import { Typography, Tab } from '@material-ui/core';
import { CustomizedTabs , LoaderButton } from '@up2rent/ui';
import { CreateClientFormValues } from './CreateAccount';
import isNil from 'lodash/isNil';
import TextButton from 'src/components/ui/TextButton';
import AddContactForm from 'src/components/Contacts/AddContactForm';

interface CreateClientProps {
  goToPreviousStep: () => void;
  defaultTab?: number;
}

const CreateClient: React.FC<CreateClientProps> = ({ goToPreviousStep, defaultTab }) => {
  const { formatMessage } = useIntl();
  const { setFieldValue, isSubmitting, status } = useFormikContext<CreateClientFormValues>();
  const [tabValue, setTabValue] = useState<number>(0);

  function handleTabChange(newValue: number) {
    setTabValue(newValue);
  }

  return (
    <>
      <Typography variant="h5" paragraph component="h2" gutterBottom>
        {formatMessage({ id: 'signup.createClient' })}
      </Typography>

      {isNil(defaultTab) && (
        <CustomizedTabs
          variant="fullWidth"
          value={tabValue}
          handleChange={(value: number) => {
            handleTabChange(value);
            setFieldValue('privateUser', value === 0);
          }}
        >
          <Tab icon={<Person />} label={formatMessage({ id: 'signup.privateProfile' })} data-test="private-user" />
          <Tab
            icon={<Group />}
            label={formatMessage({ id: 'signup.propertyManagerProfile' })}
            data-test="company-user"
          />
        </CustomizedTabs>
      )}

      {((isNil(defaultTab) && tabValue === 0) || (!isNil(defaultTab) && defaultTab === 0)) && (
        <Typography variant="h6" style={{ marginTop: 30 }}>
          {formatMessage({ id: 'signup.allSet' })}
        </Typography>
      )}

      {((isNil(defaultTab) && tabValue === 1) || (!isNil(defaultTab) && defaultTab === 1)) && (
        <AddContactForm
          viewPerson={false}
          displayTitle={false}
          displayEmail={false}
          displayPhoneNumbers={false}
          displayTabs={false}
          showSendInvite={false}
        />
      )}
      <div
        style={{
          marginTop: 20,
          display: 'flex',
          float: 'right',
          alignItems: 'center',
        }}
      >
        <TextButton onClick={goToPreviousStep} style={{ marginRight: 20 }}>
          {formatMessage({ id: 'building.addBuilding.previousLabel' })}
        </TextButton>
        <LoaderButton loading={isSubmitting} success={status}>
          {formatMessage({ id: tabValue === 0 ? 'signup.go' : 'signup.createButton' })}
        </LoaderButton>
      </div>
    </>
  );
};

export default CreateClient;
