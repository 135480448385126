import React from 'react';
import { Paper, MenuList, Typography, MenuItem, Tooltip, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { Colors } from '@rentguru/commons-utils';
import { startCase } from 'lodash';

const useStyles = makeStyles(() => ({
  title: {
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Mulish',
    fontSize: '20px',
    fontWeight: 700,
    marginBottom: '25px',
    paddingLeft: '20px',
    paddingRight: '20px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '600px',
    overflow: 'hidden',
  },
  section: {
    color: Colors.BLUE_GREY,
    fontFamily: 'Mulish',
    fontSize: '9.5px',
    letterSpacing: '0.6px',
    paddingLeft: '20px',
    paddingRight: '20px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '600px',
    overflow: 'hidden',
  },
  item: {
    fontSize: 14,
    fontFamily: 'Mulish',
    lineHeight: 1.5,
    color: Colors.CHARCOAL_GREY,
    opacity: 0.9,
    fontWeight: 600,
    paddingTop: 1,
    paddingBottom: 1,
    borderRadius: 5,
  },
  selected: {
    backgroundColor: `${Colors.PORCELAIN_GREY_1} !important`,
    color: Colors.DODGER_BLUE,
  },
  paper: {
    width: '220px',
    paddingTop: '20px',
    paddingBottom: '20px',
    borderRadius: '12px',
    height: 'fit-content',
    marginRight: 50,
  },
}));

interface SelectionMenuProps {
  title: string;
  sections: string[];
  sectionsItems: string[][];
  selectedItem: string;
  setSelectedFunction: (section: string) => void;
  errors?: string[];
  sectionErrors?: { [key: string]: string[] };
  children?: React.ReactNode;
}

const SelectionMenu: React.FC<SelectionMenuProps> = ({
  title,
  sections,
  sectionsItems,
  selectedItem,
  setSelectedFunction,
  errors = [],
  sectionErrors = {},
  children,
}) => {
  const classes = useStyles();
  const subsectionComponents = sections.map((sectionTitle, indexSection) => {
    return (
      <div key={sectionTitle}>
        <Typography className={classes.section}>{sectionTitle.toUpperCase()}</Typography>
        <MenuList>
          {sectionsItems[indexSection].map((sectionItem) => {
            const errorPossibleInSection = sectionErrors[sectionItem];
            let sectionHasError = false;
            if (!isNil(errorPossibleInSection) && !isEmpty(errorPossibleInSection)) {
              sectionHasError = errorPossibleInSection.some((errorPossible) => errors.includes(errorPossible));
            }
            return (
              <MenuItem
                key={sectionItem}
                onClick={() => setSelectedFunction(sectionItem)}
                className={classes.item}
                classes={{ selected: classes.selected }}
                selected={selectedItem === sectionItem}
                style={{ paddingLeft: 20, ...(sectionHasError ? { color: Colors.BURNING_ORANGE } : {}) }}
              >
                <ListItemText classes={{ primary: classes.item }}>{startCase(sectionItem)}</ListItemText>
              </MenuItem>
            );
          })}
        </MenuList>
      </div>
    );
  });
  return (
    <Paper elevation={8} className={classes.paper}>
      <Tooltip title={title} placement={'left-start'}>
        <Typography className={classes.title}>{title.toUpperCase()}</Typography>
      </Tooltip>

      {subsectionComponents}
      {children}
    </Paper>
  );
};

export default SelectionMenu;
