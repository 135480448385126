import Dialog from 'src/components/ui/Dialog';
import { isEmpty } from 'lodash';
import React from 'react';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { useLeases } from 'src/hooks/LeasesContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { LeaseInventoryType } from '@rentguru/commons-utils';
import AddFurnituresForm from '../RentalUnits/Details/Inventory/AddFurnituresForm';
import AddInventoryForm from '../RentalUnits/Details/Inventory/AddInventoryForm';
import { LeaseInventoryExtended } from '../RentalUnits/Details/Inventory/Inventory';

interface AddInventoryDialogProps {
  open: boolean;
  id: string;
  onClose: () => void;
  inventoryType: 'FURNITURE' | 'FIXTURE';
}

const AddInventoryDialog: React.FC<AddInventoryDialogProps> = ({ id, onClose, open, inventoryType }) => {
  const { getBuilding } = useBuildings();
  const { getLease } = useLeases();
  const { getUnit } = useUnits();

  const building = getBuilding(id);
  const lease = getLease(id);

  const unitBuilding = building?.units ?? [];
  const filterUnitBuilding = unitBuilding.filter((unit) => {
    const currentUnit = getUnit(unit.id);
    return !isEmpty(currentUnit?.leases ?? []);
  });

  const unitLease = (lease?.units ?? []).map((unitLeaseElement) => {
    return getUnit(unitLeaseElement.unit?.id ?? '')!;
  });

  const units = [...filterUnitBuilding, ...unitLease];

  const [leaseInventories, leaseFurnitureInventories] = (lease?.leaseInventories ?? []).reduce(
    (acc: [LeaseInventoryExtended[], LeaseInventoryExtended[]], li: LeaseInventoryExtended) => {
      if (
        li.inventoryType === LeaseInventoryType.FIXTURES_ENTRANCE ||
        li.inventoryType === LeaseInventoryType.FIXTURES_EXIT
      ) {
        acc[0].push({ ...li, tenants: lease?.tenants ?? [] });
      } else {
        acc[1].push({ ...li, tenants: lease?.tenants ?? [] });
      }
      return acc;
    },
    [[], []]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      fullWidth
      PaperProps={{ style: { borderRadius: 10, maxWidth: 640 } }}
    >
      {inventoryType === 'FURNITURE' && (
        <AddFurnituresForm
          units={units}
          leaseInventories={leaseFurnitureInventories}
          afterSubmit={onClose}
          cancelSubmit={onClose}
        />
      )}
      {inventoryType === 'FIXTURE' && (
        <AddInventoryForm
          units={units}
          leaseInventories={leaseInventories}
          afterSubmit={onClose}
          cancelSubmit={onClose}
        />
      )}
    </Dialog>
  );
};

export default AddInventoryDialog;
