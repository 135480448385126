import React from 'react';
import { ContentHeader, LoaderButton, ActionButton, OverflowableTypography } from '@up2rent/ui';
import { Formik, Form, FormikHelpers } from 'formik';
import TextDetailEditable from 'src/components/ui/TextDetailEditable';
import isNil from 'lodash/isNil';
import toUpper from 'lodash/toUpper';
import Divider from '@material-ui/core/Divider';
import * as Yup from 'yup';
import { useIntl, IntlFormatters } from 'react-intl';
import {
  SendingSource,
  SendingSourceStatus,
  SendingSourceVerifiedType,
  Colors,
  getDefaultSendingSource,
  handleDisposableEmail,
  ModelWithVersion,
  CUSTOM_EMAIL_SUBDOMAIN,
  SendingSourceErrorType,
  getDomainFromEmail,
} from '@rentguru/commons-utils';
import { useSendingSources } from 'src/hooks/SendingSourceContext';
import { Grid, TextField, Typography } from '@material-ui/core';

interface AddDomainProps {
  afterSubmit: (sendingSourceId: string) => void;
  defaultEmailAddress?: string;
  errorMessage?: SendingSourceErrorType;
  onClose: () => void;
}

interface AddDomainValues {
  domain: string;
  noReplyAddressPrefix: string;
  supportAddressPrefix: string;
  chargesAddressPrefix: string;
}

async function verifyExistingDomain(
  domain: string,
  formatMessage: IntlFormatters['formatMessage'],
  createError: (params?: Yup.CreateErrorOptions | undefined) => Yup.ValidationError
) {
  // handleDisposableEmail only check if the domain exist
  const result = await handleDisposableEmail(`validPrefix@${domain}`);
  if (!isNil(result) && result.dns) {
    return true;
  }
  return createError({
    path: 'domain',
    message: formatMessage({ id: 'settings.sendingSources.domainDoesNotExist' }),
  });
}

export function verifyAddressPrefix(addressPrefix: string, formatMessage: IntlFormatters['formatMessage']) {
  return Yup.string()
    .email(formatMessage({ id: 'settings.sendingSources.invalidEmail' }))
    .required(formatMessage({ id: 'mandatoryField' }))
    .isValidSync(`${addressPrefix}@validSuffix.com`);
}

const AddDomainSchema = (formatMessage: IntlFormatters['formatMessage']) =>
  Yup.object().shape({
    domain: Yup.string()
      .required(formatMessage({ id: 'mandatoryField' }))
      // eslint-disable-next-line func-names
      .test('Checking domain validity', 'Domain does not exist', async function (value) {
        if (isNil(value)) return false;
        return await verifyExistingDomain(value, formatMessage, this.createError);
      }),
    noReplyAddressPrefix: Yup.string()
      .required(formatMessage({ id: 'mandatoryField' }))
      .test(
        'Checking no-reply address validity',
        formatMessage({ id: 'settings.sendingSources.invalidEmail' }),
        async (value) => {
          if (isNil(value)) return false;
          return verifyAddressPrefix(value, formatMessage);
        }
      ),
    supportAddressPrefix: Yup.string()
      .required(formatMessage({ id: 'mandatoryField' }))
      .test(
        'Checking support address validity',
        formatMessage({ id: 'settings.sendingSources.invalidEmail' }),
        async (value) => {
          if (isNil(value)) return false;
          return verifyAddressPrefix(value, formatMessage);
        }
      ),
    chargesAddressPrefix: Yup.string()
      .required(formatMessage({ id: 'mandatoryField' }))
      .test(
        'Checking charges address validity',
        formatMessage({ id: 'settings.sendingSources.invalidEmail' }),
        async (value) => {
          if (isNil(value)) return false;
          return verifyAddressPrefix(value, formatMessage);
        }
      ),
  });

const AddDomainDialog: React.FC<AddDomainProps> = ({ afterSubmit, defaultEmailAddress, errorMessage, onClose }) => {
  const { formatMessage } = useIntl();
  const { sendingSources, createSendingSource, updateSendingSource, deleteSendingSource } = useSendingSources();

  const handleCreate = async (
    values: AddDomainValues,
    { setSubmitting, setStatus }: FormikHelpers<AddDomainValues>
  ) => {
    const duplicateErrorSendingSource = sendingSources?.find(
      (sendingSource) =>
        sendingSource.status === SendingSourceStatus.ERROR &&
        sendingSource.emailAddress === getFullAddress(values.noReplyAddressPrefix, values.domain, true)
    );
    if (duplicateErrorSendingSource) {
      await deleteSendingSource(duplicateErrorSendingSource);
    }

    const defaultSendingSource = getDefaultSendingSource(sendingSources);
    if (defaultSendingSource?.status === SendingSourceStatus.ERROR) {
      await updateSendingSource({
        id: defaultSendingSource.id,
        verifiedType: SendingSourceVerifiedType.NONE,
        _version: (defaultSendingSource as ModelWithVersion<SendingSource>)._version,
      });
    }

    const sendingSource = await createSendingSource({
      name: values.domain,
      emailAddress: getFullAddress(values.noReplyAddressPrefix, values.domain, true),
      issuesEmail: getFullAddress(values.supportAddressPrefix, values.domain, false),
      chargesEmail: getFullAddress(values.chargesAddressPrefix, values.domain, false),
      errorMessage,
      status: SendingSourceStatus.NONE,
      verifiedType:
        defaultSendingSource && defaultSendingSource.status !== SendingSourceStatus.ERROR
          ? SendingSourceVerifiedType.NONE
          : SendingSourceVerifiedType.DEFAULT,
    });

    setStatus(true);
    setSubmitting(false);

    if (sendingSource) handleAfterSubmit(sendingSource);
  };

  const domainToDisplay = (domain: string, noReply: boolean) => {
    if (!domain || domain === '') {
      return `${noReply ? '' : `${CUSTOM_EMAIL_SUBDOMAIN}.`}${formatMessage({
        id: 'settings.sendingSources.myAgency',
      })}.com`;
    }
    return `${noReply ? '' : `${CUSTOM_EMAIL_SUBDOMAIN}.`}${domain}`;
  };

  const getFullAddress = (addressPrefix: string, domain: string, noReply: boolean) =>
    `${addressPrefix}@${domainToDisplay(domain, noReply)}`;

  const handleAfterSubmit = (sendingSource: SendingSource) => {
    if (!isNil(afterSubmit)) {
      afterSubmit(sendingSource.id);
    }
  };

  const initialValues: AddDomainValues = {
    domain: defaultEmailAddress ? getDomainFromEmail(defaultEmailAddress) : '',
    noReplyAddressPrefix: 'no-reply',
    supportAddressPrefix: 'support',
    chargesAddressPrefix: 'charges',
  };

  return (
    <>
      <ContentHeader title={formatMessage({ id: 'settings.sendingSources.addDomain' })} />
      <Divider style={{ marginBottom: 20 }} />
      <Grid style={{ marginLeft: 30, marginRight: 30 }}>
        <Typography>
          {`${formatMessage({
            id: 'settings.sendingSources.addDomainInfo3',
          })} `}
          <b>{`${formatMessage({
            id: 'settings.sendingSources.addDomainInfo4',
          })} `}</b>
        </Typography>
        <Typography>
          {`${formatMessage({
            id: 'settings.sendingSources.addDomainInfo1',
          })} `}
          <b>{formatMessage({ id: 'settings.sendingSources.addDomainInfo2' })}</b>
        </Typography>
        <Formik initialValues={initialValues} validationSchema={AddDomainSchema(formatMessage)} onSubmit={handleCreate}>
          {({ errors, touched, isSubmitting, status, values, handleChange }) => (
            <Form>
              <TextDetailEditable
                title={formatMessage({
                  id: 'settings.sendingSources.domain',
                })}
                editMode={true}
                name="domain"
                type="text"
                noMaxWidth
                placeholder={`${formatMessage({ id: 'settings.sendingSources.myAgency' })}.com`}
                helperText={errors.domain && touched.domain ? errors.domain : ''}
                error={Boolean(errors.domain && touched.domain)}
              />
              <Grid style={{ display: 'flex', alignItems: 'flex-end' }}>
                <TextField
                  label={formatMessage({
                    id: 'settings.sendingSources.sendingEmail',
                  })}
                  style={{ width: 270, marginTop: 20 }}
                  name="noReplyAddressPrefix"
                  type="text"
                  onChange={handleChange}
                  placeholder="no-reply"
                  helperText={
                    errors.noReplyAddressPrefix && touched.noReplyAddressPrefix ? errors.noReplyAddressPrefix : ''
                  }
                  error={Boolean(errors.noReplyAddressPrefix && touched.noReplyAddressPrefix)}
                  variant="outlined"
                  value={values.noReplyAddressPrefix}
                />
                <OverflowableTypography
                  text={`@${domainToDisplay(values.domain, true)}`}
                  style={{ fontSize: 16, color: Colors.SLATE_GREY, width: 270, marginBottom: 16, marginLeft: 5 }}
                />
              </Grid>
              <Grid style={{ display: 'flex', alignItems: 'flex-end' }}>
                <TextField
                  label={formatMessage({
                    id: 'settings.sendingSources.supportEmail',
                  })}
                  style={{ width: 270, marginTop: 20 }}
                  name="supportAddressPrefix"
                  type="text"
                  onChange={handleChange}
                  placeholder="support"
                  helperText={
                    errors.supportAddressPrefix && touched.supportAddressPrefix ? errors.supportAddressPrefix : ''
                  }
                  error={Boolean(errors.supportAddressPrefix && touched.supportAddressPrefix)}
                  variant="outlined"
                  value={values.supportAddressPrefix}
                />
                <OverflowableTypography
                  text={`@${domainToDisplay(values.domain, false)}`}
                  style={{ fontSize: 16, color: Colors.SLATE_GREY, width: 270, marginBottom: 16, marginLeft: 5 }}
                />
              </Grid>
              <Grid style={{ display: 'flex', alignItems: 'flex-end' }}>
                <TextField
                  label={formatMessage({
                    id: 'settings.sendingSources.chargesEmail',
                  })}
                  style={{ width: 270, marginTop: 20 }}
                  name="chargesAddressPrefix"
                  type="text"
                  onChange={handleChange}
                  placeholder="charges"
                  helperText={
                    errors.chargesAddressPrefix && touched.chargesAddressPrefix ? errors.chargesAddressPrefix : ''
                  }
                  error={Boolean(errors.chargesAddressPrefix && touched.chargesAddressPrefix)}
                  variant="outlined"
                  value={values.chargesAddressPrefix}
                />
                <OverflowableTypography
                  text={`@${domainToDisplay(values.domain, false)}`}
                  style={{ fontSize: 16, color: Colors.SLATE_GREY, width: 270, marginBottom: 16, marginLeft: 5 }}
                />
              </Grid>

              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <Grid
                style={{
                  marginRight: 30,
                  marginBottom: 20,
                  display: 'flex',
                  float: 'right',
                  alignItems: 'center',
                }}
              >
                <ActionButton
                  onClick={onClose}
                  style={{
                    background: 'none',
                    color: Colors.DARK_SLATE_GREY,
                    marginRight: 20,
                  }}
                >
                  {toUpper(
                    formatMessage({
                      id: 'cancel',
                    })
                  )}
                </ActionButton>
                <LoaderButton loading={isSubmitting} success={status}>
                  {formatMessage({
                    id: 'settings.sendingSources.addDomain',
                  })}
                </LoaderButton>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
};

export default AddDomainDialog;
