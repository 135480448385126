import React from 'react';
import { FilterEntity, LeasesActiveFilters, useFilters } from 'src/hooks/FiltersContext';
import { SelectorChildrenProps } from '../../ui/FieldFilterSelector';
import { LeasesTableProps } from '../Leases';
import FieldFilterResetButton from '../../ui/FieldFilterResetButton';
import LeasesFiltersCommons from './LeasesFiltersCommons';
import LeasesFiltersBalance from './LeasesFiltersBalance';
import LeasesFiltersEndOfLease from './LeasesFiltersEndOfLease';

const LeasesFiltersActive: React.FC<LeasesTableProps> = ({ leasesActive }) => {
  const {
    leasesActiveFilters: { filters, balanceDept, balanceLatePaymentMonth, endingInFromTo },
    updateFilters,
    resetFilters,
  } = useFilters();

  const handleFilterChange = (
    selectedItems: SelectorChildrenProps[],
    filterName: string,
    fieldName: string,
    menuClosed: boolean
  ) => {
    if (menuClosed) {
      return; // Don't set filter again
    }
    if (filterName === 'balance' || filterName === 'endOfLease') {
      // Filter are handled by custom functions
      return;
    }

    const filter = { name: filterName, field: fieldName, items: selectedItems.map((i) => i.value) };
    let newFilters = filters;

    const index = newFilters.findIndex((f) => f.name === filterName);
    if (index === -1) {
      newFilters = [...newFilters, filter];
    } else {
      newFilters = [...newFilters.filter((f) => f.name !== filterName), filter];
    }
    updateFilters<LeasesActiveFilters>(FilterEntity.LEASES_ACTIVE, {
      filters: newFilters,
      balanceDept,
      balanceLatePaymentMonth,
      endingInFromTo,
    });
  };

  const handleFilterReset = (filterName: string) => {
    if (filterName === 'balance') {
      updateFilters<LeasesActiveFilters>(FilterEntity.LEASES_ACTIVE, {
        filters,
        balanceDept: 0,
        balanceLatePaymentMonth: 0,
        endingInFromTo,
      });
    }
    if (filterName === 'endOfLease') {
      updateFilters<LeasesActiveFilters>(FilterEntity.LEASES_ACTIVE, {
        filters,
        balanceDept,
        balanceLatePaymentMonth,
        endingInFromTo: null,
      });
    }
  };

  const handleFilterEndOfLeaseFromTo = (from: Date | null, to: Date | null) => {
    const leaseFilterFromTo =
      from && to
        ? {
            from: from.toJSON(),
            to: to.toJSON(),
          }
        : null;
    updateFilters<LeasesActiveFilters>(FilterEntity.LEASES_ACTIVE, {
      filters,
      balanceDept,
      balanceLatePaymentMonth,
      endingInFromTo: leaseFilterFromTo,
    });
  };

  const onUpdateBalanceFilter = (fieldName: string, value: number) => {
    updateFilters<LeasesActiveFilters>(FilterEntity.LEASES_ACTIVE, {
      filters,
      balanceDept,
      endingInFromTo,
      balanceLatePaymentMonth,
      [fieldName]: value,
    });
  };

  // End of lease
  const endOfLeaseItems = [
    { fromMonth: 0, toMonth: 3, labelId: 'rentalUnit.filter.endOfLeaseInTheNext3Months' },
    { fromMonth: 3, toMonth: 6, labelId: 'rentalUnit.filter.endOfLeaseBetween3And6Months' },
  ];

  return (
    <div
      style={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 15,
        marginRight: 24,
      }}
    >
      <LeasesFiltersCommons filters={filters} handleFilterChange={handleFilterChange} leases={leasesActive} />
      <LeasesFiltersEndOfLease
        filterName="endOfLease"
        labelId="accounting.statement.addTenantStatementSection.endOfLease"
        onReset={handleFilterReset}
        value={endingInFromTo}
        onChange={handleFilterEndOfLeaseFromTo}
        items={endOfLeaseItems}
      />
      <LeasesFiltersBalance
        balanceDept={balanceDept}
        balanceLatePaymentMonth={balanceLatePaymentMonth}
        onChange={onUpdateBalanceFilter}
        onReset={handleFilterReset}
      />
      <FieldFilterResetButton resetFilterValues={() => resetFilters(FilterEntity.LEASES_ACTIVE)} />
    </div>
  );
};

export default LeasesFiltersActive;
