import React from 'react';
import { AgencyRate } from '@rentguru/commons-utils';
import AddEditAgencyRateForm from './AddEditAgencyRateForm';

interface AgencyRateDescriptionProps {
  agencyRate: AgencyRate;
}
const AgencyRateDescription: React.FC<AgencyRateDescriptionProps> = ({ agencyRate }) => {
  return <AddEditAgencyRateForm afterSubmit={() => {}} id={agencyRate.id} />;
};

export default AgencyRateDescription;
