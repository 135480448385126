import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { isNil } from 'lodash';
import { Colors } from '@rentguru/commons-utils';

interface TextDetailProps {
  title: string;
  titleId?: string;
  text?: string | null | number;
  textId?: string;
  style?: React.CSSProperties;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
  endAdornment?: string;
  comoditiesDisplay?: boolean;
  paddingLeft?: number;
  children?: React.ReactNode;
}
const AlignedTextDetail: React.FC<TextDetailProps> = ({
  title,
  titleId,
  text,
  textId,
  children: _children,
  style: _style,
  icon: _icon,
  endAdornment,
  comoditiesDisplay = false,
  paddingLeft = 0,
}) => {
  if (isNil(endAdornment)) {
    return (
      <>
        <Grid container style={{ alignItems: 'center' }}>
          <Grid item xs={6}>
            <Typography
              id={titleId || 'AlignedTextDetailIdTitle'}
              style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography
              id={textId || 'AlignedTextDetailIdText'}
              style={{
                position: 'relative',
                color: Colors.CLASSICAL_BLACK,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {text}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }
  // The new part is here in case the endAdornment is given and not empty
  if (!comoditiesDisplay) {
    return (
      <>
        <Grid container style={{ alignItems: 'center' }}>
          <Grid item xs={6}>
            <Typography
              id={titleId || 'AlignedTextDetailIdTitle'}
              style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography
              id={textId || 'AlignedTextDetailIdText'}
              style={{
                position: 'relative',
                color: Colors.CLASSICAL_BLACK,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {text}
            </Typography>
            {endAdornment ? (
              <Typography style={{ color: Colors.CLASSICAL_BLACK, fontSize: 16, fontWeight: 500, marginLeft: 4 }}>
                {endAdornment}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      </>
    );
  }
  return (
    <>
      <Grid container>
        <Grid item xs={8} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            id={titleId || 'AlignedTextDetailIdTitle'}
            style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12, textAlign: 'left', paddingLeft, maxWidth: 250 }}
          >
            {title}{' '}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
        >
          <Typography
            id={textId || 'AlignedTextDetailIdText'}
            style={{
              position: 'relative',
              color: Colors.CLASSICAL_BLACK,
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {text}
          </Typography>
          {endAdornment ? (
            <Typography style={{ color: Colors.CLASSICAL_BLACK, fontSize: 16, fontWeight: 500, marginLeft: 4 }}>
              {endAdornment}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

export default AlignedTextDetail;
