import { makeStyles } from '@material-ui/styles';

export const useNoScrollbarStyles = makeStyles({
  noScrollbar: {
    '-ms-overflow-style': 'none !important',
    'scrollbar-width': 'none !important',
    '&::-webkit-scrollbar': {
      display: 'none !important',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none !important',
    },
  },
});
