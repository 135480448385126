import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Collapse } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import { ReactComponent as Chevron } from 'src/icons/chevron-right.svg';
import { CustomIconButton } from '@up2rent/ui';
import { LocationDescriptorObject } from 'src/components/ui/CustomizedMenuItem';

interface EntityDetailSubMenuProps {
  to: string;
  baseUrl: string;
  selected: boolean;
  title: string;
  dataTest?: string;
  description: string;
  historyProps?: LocationDescriptorObject;
  children?: React.ReactNode;
  isTenant?: boolean;
  open?: boolean;
}

const rotatingIconStyle = (isOpen: boolean): object => ({
  transform: `rotate(${isOpen ? 90 : 0}deg)`,
  fill: isOpen ? Colors.DARK_SLATE_GREY : Colors.BLUEY,
  height: 20,
  width: 20,
});

const EntityDetailSubMenu: React.FC<EntityDetailSubMenuProps> = ({
  to,
  selected,
  baseUrl,
  historyProps,
  title,
  dataTest = 'EntityDetailsSubMenuDataTest',
  description,
  children,
  isTenant = false,
  open = false,
}) => {
  const history = useHistory();

  const onSelect = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    history.push({ pathname: !selected ? to : baseUrl, ...(historyProps || {}) });
  };
  return (
    <>
      <div
        style={{
          borderRadius: 8,
          paddingTop: 8,
          paddingRight: 10,
          paddingBottom: 8,
          paddingLeft: 10,
          marginTop: 10,
          marginRight: 15,
          marginBottom: 10,
          marginLeft: 15,
          backgroundColor: isTenant ? Colors.PORCELAIN_GREY_3 : 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
        onClick={onSelect}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            style={{
              fontSize: 10,
              fontWeight: 'normal',
              fontStyle: 'normal',
              lineHeight: 1.2,
              letterSpacing: 1,
              color: Colors.LIGHT_BLUE_GREY,
            }}
          >
            {description}
          </Typography>
          <Typography
            style={{
              fontSize: 14,
              fontWeight: 'bold',
              fontStyle: 'normal',
              letterSpacing: 0,
              color: Colors.CLASSICAL_BLACK,
            }}
            data-test={dataTest}
          >
            {title}
          </Typography>
        </div>
        <CustomIconButton
          style={{ color: Colors.BLUE_GREY, height: 'fit-content' }}
          onClick={onSelect}
          Icon={Chevron}
          iconStyle={rotatingIconStyle(selected)}
        />
      </div>
      <Collapse in={selected || open} style={{ paddingLeft: 15, paddingRight: 15 }}>
        {children}
      </Collapse>
    </>
  );
};

export default EntityDetailSubMenu;
