/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useRef, useState } from 'react';
import {
  syncVatRates,
  VatRate,
  createVatRate as createMutation,
  updateVatRate as updateMutation,
  deleteVatRate as deleteMutation,
  CreateVatRateMutationVariables,
  DeleteVatRateMutationVariables,
  CreateVatRateInput,
  UpdateVatRateInput,
  cleanInputUpdate,
  cleanInputCreate,
  getReadId,
  getTableClientId,
} from '@rentguru/commons-utils';
import { deleteAndHideEntity, list, mutation } from '@up2rent/fetch-utils';
import { useUser } from './UserContext';
import { usePermissions } from './utils/PermissionsContext';

export interface VatRateContext {
  vatRates: VatRate[];
  vatRatesLoading: boolean;
  error: string | null;
  createVatRate: (input: Omit<CreateVatRateInput, 'clientId' | 'readId'>) => Promise<VatRate>;
  updateVatRate: (updates: UpdateVatRateInput) => Promise<VatRate>;
  deleteVatRate: (vatRate: VatRate) => Promise<VatRate | null>;
}

export const useVatRates = (shouldFetchVatRate: boolean = false): VatRateContext => {
  const [vatRatesLoading, setVatRatesLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | Error | null>(null);
  const [vatRates, setVatRates] = useState<VatRate[]>([]);
  const firstRender = useRef<boolean>(false);
  const { clientId } = useUser();
  const { financialTransactionsWrite } = usePermissions();

  const fetchVatRates = async (): Promise<VatRate[]> => {
    const result = await list<VatRate>(syncVatRates, 'clientId', getTableClientId(clientId!, 'VatRate'));
    return result;
  };

  useEffect(() => {
    const buildVatRates = async () => {
      setVatRatesLoading(true);
      firstRender.current = true;
      const result = await fetchVatRates();
      setVatRates(result);
      setVatRatesLoading(false);
    };
    if (shouldFetchVatRate && !firstRender.current) buildVatRates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchVatRate, firstRender]);

  const createVatRate = async (input: Omit<CreateVatRateInput, 'clientId' | 'readId'>): Promise<VatRate> => {
    const vatRate = await mutation<VatRate, CreateVatRateMutationVariables>(createMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateVatRateInput),
        readId: getReadId(clientId!, 'VatRate'),
        clientId: getTableClientId(clientId!, 'VatRate'),
      },
    });
    setVatRates((m) => [...m, vatRate]);
    return vatRate;
  };

  const updateVatRate = async (updates: UpdateVatRateInput): Promise<VatRate> => {
    const result = await mutation<VatRate>(updateMutation, {
      input: cleanInputUpdate(updates, false),
    });
    fetchVatRates().then((result) => {
      if (result instanceof Array) {
        setVatRates(result);
      } else {
        setError(result);
      }
    });
    setVatRates((ms) =>
      ms.map((m) => {
        if (m.id === result.id) return result;
        return m;
      })
    );
    return result;
  };

  const deleteVatRate = async (vatRate: VatRate): Promise<VatRate | null> => {
    if (!financialTransactionsWrite) {
      return null;
    }
    const result = await deleteAndHideEntity<VatRate, DeleteVatRateMutationVariables>(
      vatRate,
      deleteMutation,
      updateMutation
    );
    setVatRates(vatRates.filter((m) => m.id !== vatRate.id));
    return result;
  };

  return {
    vatRatesLoading,
    error: error as string | null,
    vatRates,
    createVatRate,
    updateVatRate,
    deleteVatRate,
  };
};
