import React from 'react';
import { useFormikContext, FormikProps } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { UserRole } from '@rentguru/commons-utils';
import ComboBox, { CustomMenuItemType } from '../../ComboBox/TextComboBox';
import isNil from 'lodash/isNil';

export interface RoleSelectorFieldFormValues {
  roleId: string;
}

export const RoleSelectorFieldSchema = Yup.object()
  .shape({
    roleId: Yup.string().min(1).required(),
  })
  .required();

export interface RoleSelectorFieldFieldProps {
  userRoles: UserRole[];
  fieldName: string;
  initialValue?: string;
  readonly?: boolean;
  onChange?: (event: string) => void;
  inputStyle?: React.CSSProperties;
  required?: boolean;
}

const RoleSelectorField: React.FC<RoleSelectorFieldFieldProps> = ({
  userRoles,
  fieldName,
  initialValue: initialObjectId,
  readonly = false,
  onChange,
  inputStyle,
  required = false,
}) => {
  const { errors, touched, setFieldValue }: FormikProps<RoleSelectorFieldFormValues> = useFormikContext();
  const { formatMessage } = useIntl();

  const rolesMenu = userRoles.map((u) => {
    return {
      value: u.id,
      primary: u.name,
    };
  });

  const initialObject = userRoles.find((u) => u.id === initialObjectId);
  let initialValue;
  if (!isNil(initialObject)) {
    initialValue = {
      primary: initialObject.name,
      value: initialObject.id,
    };
  }
  return (
    <div
      style={{
        alignItems: 'baseline',
        display: 'inline-flex',
      }}
    >
      <ComboBox
        data-test="roleComboBox"
        label={`${formatMessage({ id: 'settings.addMember.profile' })}${
          !required ? ` ${formatMessage({ id: 'optional' })}` : ''
        }`}
        data={rolesMenu}
        onChange={(_e: unknown, value: CustomMenuItemType | null) => {
          const safeValue = isNil(value) ? null : (value as CustomMenuItemType).value;
          if (!isNil(onChange)) {
            onChange(safeValue);
          }
          setFieldValue(fieldName, safeValue);
        }}
        initialValue={initialValue}
        inputStyle={inputStyle}
        noOptionsText={formatMessage({ id: 'comboBox.noOptions' })}
        error={Boolean(errors.roleId && touched.roleId)}
        disabled={readonly}
        required={required}
      />
    </div>
  );
};

export default RoleSelectorField;
