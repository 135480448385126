/* eslint-disable default-param-last */
/* eslint-disable no-plusplus */
import { ContentBlock, ContentState, EditorState, genKey, Modifier, RichUtils, SelectionState } from 'draft-js';
import { Colors, LooseObject } from '@rentguru/commons-utils';
import { isNil } from 'lodash';
import { Map } from 'immutable';
import { makeStyles } from '@material-ui/core/styles';

export const tagRegexp = /\{\{(.*?)\}\}/gm;

export const addNewBlockAt = (
  editorState: EditorState,
  placeToInsertKey: string,
  insertion: 'after' | 'before' = 'after',
  newBlockType = 'unstyled',
  data?: LooseObject
) => {
  const content = editorState.getCurrentContent();
  const blockMap = content.getBlockMap();
  const block = blockMap.get(placeToInsertKey);

  if (!block) {
    throw new Error(`The key - ${placeToInsertKey} is not present in blockMap.`);
  }

  const blocksBefore = blockMap.toSeq().takeUntil((v) => v === block);
  const blocksAfter = blockMap
    .toSeq()
    .skipUntil((v) => v === block)
    .rest();
  const newBlockKey = genKey();

  const newBlock = new ContentBlock({
    key: newBlockKey,
    type: newBlockType,
    text: ' ',
    depth: 0,
    ...(data && { data: Map(data) }),
  });

  const newBlockMap = blocksBefore
    .concat(
      insertion === 'after'
        ? [
            [placeToInsertKey, block],
            [newBlockKey, newBlock],
          ]
        : [
            [newBlockKey, newBlock],
            [placeToInsertKey, block],
          ],
      blocksAfter
    )
    .toOrderedMap();

  const selection = editorState.getSelection();

  const newContent = content.merge({
    blockMap: newBlockMap,
    selectionBefore: selection,
    selectionAfter: selection.merge({
      anchorKey: newBlockKey,
      anchorOffset: 0,
      focusKey: newBlockKey,
      focusOffset: 0,
      isBackward: false,
    }),
  }) as ContentState;

  return EditorState.push(editorState, newContent, 'split-block');
};

export const addNewBlocksAt = (
  editorState: EditorState,
  placeToInsertKey: string,
  newBlocks: { type: string; data?: LooseObject; key?: string }[],
  cursorBlockIndexAfterInsertion = 0
): EditorState => {
  const content = editorState.getCurrentContent();
  const blockMap = content.getBlockMap();
  const block = blockMap.get(placeToInsertKey);

  if (!block) {
    throw new Error(`The key - ${placeToInsertKey} is not present in blockMap.`);
  }

  const blocksBefore = blockMap.toSeq().takeUntil((v) => v === block);
  const blocksAfter = blockMap
    .toSeq()
    .skipUntil((v) => v === block)
    .rest();

  const blocksToInsert = newBlocks.map((newBlock) => {
    const { type, data, key } = newBlock;
    const blockKey = key || genKey();
    return [
      blockKey,
      new ContentBlock({
        key: blockKey,
        type,
        text: ' ',
        depth: 0,
        ...(data && { data: Map(data) }),
      }),
    ];
  });

  const newBlockMap = blocksBefore.concat([[placeToInsertKey, block], ...blocksToInsert], blocksAfter).toOrderedMap();

  const selection = editorState.getSelection();
  const cursorAnchorKey = blocksToInsert[cursorBlockIndexAfterInsertion][0] as string;
  const newContent = content.merge({
    blockMap: newBlockMap,
    selectionBefore: selection,
    selectionAfter: selection.merge({
      anchorKey: cursorAnchorKey,
      anchorOffset: 0,
      focusKey: cursorAnchorKey,
      focusOffset: 0,
      isBackward: false,
    }),
  }) as ContentState;

  return EditorState.push(editorState, newContent, 'split-block');
};

export const removeBlock = (contentState: ContentState, blockKey: string) => {
  const prevBlock = contentState.getBlockBefore(blockKey);
  if (!prevBlock) return contentState;
  const removeSelection = new SelectionState({
    anchorKey: prevBlock.getKey(),
    anchorOffset: prevBlock.getLength(),
    focusKey: blockKey,
    focusOffset: 0,
  });
  return Modifier.removeRange(contentState, removeSelection, 'backward');
};

export const isCursorPartiallyOnEntity = (editorState: EditorState, entityType: string) => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();

  const startKey = selectionState.getStartKey();
  const startOffset = selectionState.getStartOffset();
  const endKey = selectionState.getEndKey();
  const endOffset = selectionState.getEndOffset();

  // Simple cursor (no selection)
  const simpleCursor = selectionState.isCollapsed();

  /* Check if the start of cursor is partially on an entity */
  let startCursorPartiallyOnEntity = false;
  const blockAtStartCursor = contentState.getBlockForKey(startKey);
  if (blockAtStartCursor) {
    const entityKeyAtStartCursor = blockAtStartCursor.getEntityAt(startOffset);
    if (entityKeyAtStartCursor && startOffset > 0) {
      // The start cursor is on an entity and there are characters before it.
      const entity = contentState.getEntity(entityKeyAtStartCursor);
      if (entity.getType() === entityType) {
        const previousEntityKey = blockAtStartCursor.getEntityAt(startOffset - 1);
        if (previousEntityKey) {
          // There is an entity before the cursor
          const previousEntity = contentState.getEntity(previousEntityKey);
          // Check if the previous entity is also of the forbidden type
          startCursorPartiallyOnEntity = previousEntity.getType() === entityType;
        }
      }
    }
  }
  /* Same idea for the end of the cursor */
  let endCursorPartiallyOnEntity = false;
  const blockAtEndCursor = contentState.getBlockForKey(endKey);
  if (blockAtEndCursor) {
    const entityKeyAtEndCursor = blockAtEndCursor.getEntityAt(endOffset);
    if (entityKeyAtEndCursor) {
      // The end cursor is on an entity
      const entity = contentState.getEntity(entityKeyAtEndCursor);
      if (entity.getType() === entityType) {
        if (simpleCursor) endCursorPartiallyOnEntity = true;
        else {
          const nextEntityKey = blockAtEndCursor.getEntityAt(endOffset + 1);
          if (nextEntityKey) {
            // There is an entity after the cursor
            const nextEntity = contentState.getEntity(nextEntityKey);
            // Check if the next entity is also of the forbidden type
            endCursorPartiallyOnEntity = nextEntity.getType() === entityType;
          }
        }
      }
    }
  }
  return simpleCursor
    ? startCursorPartiallyOnEntity && endCursorPartiallyOnEntity
    : startCursorPartiallyOnEntity || endCursorPartiallyOnEntity;
};

export const correctSelectionToSelectEntities = (editorState: EditorState, entityType: string) => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();

  const startKey = selectionState.getStartKey();
  const startOffset = selectionState.getStartOffset();
  const endKey = selectionState.getEndKey();
  const endOffset = selectionState.getEndOffset();

  let newStartOffset: number | undefined;
  let newEndOffset: number | undefined;
  const isBackward = selectionState.getIsBackward();

  const blockAtStartCursor = contentState.getBlockForKey(startKey);
  if (blockAtStartCursor) {
    const entityKeyAtStartCursor = blockAtStartCursor.getEntityAt(startOffset);
    if (entityKeyAtStartCursor) {
      // The start cursor is on an entity
      const entity = contentState.getEntity(entityKeyAtStartCursor);
      if (entity.getType() === entityType) {
        const entityOffsets = getEntityRange(blockAtStartCursor, entityKeyAtStartCursor);
        if (entityOffsets) {
          newStartOffset = entityOffsets.start;
        }
      }
    }
  }
  /* Same idea for the end of the cursor */
  const blockAtEndCursor = contentState.getBlockForKey(endKey);
  if (blockAtEndCursor) {
    const entityKeyAtEndCursor = blockAtEndCursor.getEntityAt(endOffset);
    if (entityKeyAtEndCursor) {
      // The end cursor is on an entity
      const entity = contentState.getEntity(entityKeyAtEndCursor);
      if (entity.getType() === entityType) {
        const entityOffsets = getEntityRange(blockAtEndCursor, entityKeyAtEndCursor);
        if (entityOffsets) {
          newEndOffset = entityOffsets.end;
        }
      }
    }
  }

  if (isNil(newStartOffset) && isNil(newEndOffset)) return null;

  newStartOffset = !isNil(newStartOffset) ? newStartOffset : startOffset;
  newEndOffset = !isNil(newEndOffset) ? newEndOffset : endOffset;
  const newSelection = new SelectionState({
    anchorKey: isBackward ? endKey : startKey,
    anchorOffset: isBackward ? newEndOffset : newStartOffset,
    focusKey: isBackward ? startKey : endKey,
    focusOffset: isBackward ? newStartOffset : newEndOffset,
    isBackward,
  });
  return newSelection;
};

export const isCursorOnConditionalBlock = (body: EditorState) => {
  const blockType = RichUtils.getCurrentBlockType(body);
  return blockType === 'conditionalStart' || blockType === 'conditionalElse';
};

export const isSelectionOnConditionalBlock = (body: EditorState) => {
  const content = body.getCurrentContent();
  const selection = body.getSelection();
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();
  let currentBlock = content.getBlockForKey(startKey);
  // TOP TO BOTTOM Iteration
  while (!isNil(currentBlock) && currentBlock.getKey() !== endKey) {
    const currentBlockType = currentBlock.getType();
    const currentBlockKey = currentBlock.getKey();
    if (
      currentBlockType === 'conditionalEnd' ||
      currentBlockType === 'conditionalStart' ||
      currentBlockType === 'conditionalElse'
    ) {
      return true;
    }
    // Perform next iteration on block after
    currentBlock = content.getBlockAfter(currentBlockKey)!;
  }
  return false;
};
/**
 * Go from the top of the selection to the bottom on checks that there are no unclosed conditional blocks
 */
export const isSelectionPartiallyOnCondionnalBlock = (editorState: EditorState) => {
  const content = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();

  // Every time we encounter another end block we substract 1 and another add block we add 1.
  // Counter should be 0 when finding the correct block, otherwise we are in a nested if/end.
  let otherCondionnalBlockCounter = 0;
  let currentBlock = content.getBlockForKey(startKey);
  // TOP TO BOTTOM Iteration
  while (!isNil(currentBlock) && currentBlock.getKey() !== endKey) {
    const currentBlockType = currentBlock.getType();
    const currentBlockKey = currentBlock.getKey();
    if (currentBlockType === 'conditionalEnd') {
      otherCondionnalBlockCounter--;
    } else if (currentBlockType === 'conditionalStart') {
      otherCondionnalBlockCounter++;
    }
    // Perform next iteration on block after
    currentBlock = content.getBlockAfter(currentBlockKey)!;
  }
  return otherCondionnalBlockCounter !== 0;
};

/**
 * This function starts from a selection and iterates to the top of the content to find a condionnalStart block.
 * If it encounters and conditionalEnd block before finding a condionnalStart block, this last is ignored
 */
const getCondionnalStartBlockOfSelection = (editorState: EditorState): ContentBlock | null => {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();

  let conditionalStartBlockFound: ContentBlock | null = null;
  let conditionalEndBlockEncountered = 0;
  let currentBlock = content.getBlockForKey(selection.getAnchorKey());
  while (isNil(conditionalStartBlockFound)) {
    // Did we reach the top of the content? => Exit the loop
    if (isNil(currentBlock)) break;
    const currentBlockType = currentBlock.getType();
    if (currentBlockType === 'conditionalEnd') {
      conditionalEndBlockEncountered++;
    } else if (currentBlockType === 'conditionalStart') {
      // Did we encounter a conditionalEnd block before this one?
      if (conditionalEndBlockEncountered === 0) {
        conditionalStartBlockFound = currentBlock;
      } else {
        conditionalEndBlockEncountered--;
      }
    }
    // Perform next iteration on block above
    currentBlock = content.getBlockBefore(currentBlock.getKey())!;
  }
  return conditionalStartBlockFound;
};

/**
 * This function starts from a selection and iterates to the top of the content to find a condionnalStart block.
 * Same thing for conditionalEnd block below.
 * The else is only allow at the if/end condition level and cannot be nested into another if/end
 */
export const isElseAllowedInSelection = (
  editorState: EditorState,
  startBlockKey: string,
  endBlockKey: string
): boolean => {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  let startBlockIsAbove = false;
  let endBlockIsBelow = false;

  // Every time we encounter another end block we substract 1 and another add block we add 1.
  // Counter should be 0 when finding the correct block, otherwise we are in a nested if/end.
  let otherCondionnalBlockCounter = 0;
  let currentBlock = content.getBlockForKey(selection.getAnchorKey());
  // TOP Iteration
  while (!isNil(currentBlock) && !startBlockIsAbove) {
    const currentBlockType = currentBlock.getType();
    const currentBlockKey = currentBlock.getKey();
    if (currentBlockType === 'conditionalEnd') {
      if (currentBlockKey !== endBlockKey) {
        // Other end block
        otherCondionnalBlockCounter--;
      } else {
        // Our end block is above => FORBIDDEN
        return false;
      }
    } else if (currentBlockType === 'conditionalStart') {
      if (currentBlockKey !== startBlockKey) {
        // Other start block
        otherCondionnalBlockCounter++;
      } else {
        // Our start block is above => Good !
        // Now are we in a nested if?
        if (otherCondionnalBlockCounter !== 0) {
          return false;
        }
        startBlockIsAbove = true;
      }
    }
    // Perform next iteration on block above
    currentBlock = content.getBlockBefore(currentBlock.getKey())!;
  }

  // BOTTOM Iteration
  currentBlock = content.getBlockForKey(selection.getAnchorKey());
  otherCondionnalBlockCounter = 0;
  while (!isNil(currentBlock) && !endBlockIsBelow) {
    const currentBlockType = currentBlock.getType();
    const currentBlockKey = currentBlock.getKey();
    if (currentBlockType === 'conditionalStart') {
      if (currentBlockKey !== startBlockKey) {
        // Other start block
        otherCondionnalBlockCounter++;
      } else {
        // Our start block is below => Forbidden!
        return false;
      }
    } else if (currentBlockType === 'conditionalEnd') {
      if (currentBlockKey !== endBlockKey) {
        // Other end block
        otherCondionnalBlockCounter--;
      } else {
        // We have found our end block => Good!
        // Now is it nested ?
        if (otherCondionnalBlockCounter !== 0) {
          return false;
        }
        endBlockIsBelow = true;
      }
    }
    // Perform next iteration on block below
    currentBlock = content.getBlockAfter(currentBlock.getKey())!;
  }
  return startBlockIsAbove && endBlockIsBelow;
};

export const conditionalColors = [Colors.DODGER_BLUE, Colors.SLATE_GREY, Colors.CARNATION_RED, Colors.CARROT_ORANGE];

export const useConditionalBlockStyles = makeStyles<{}, { color: string; editMode: boolean }>({
  wrapper: ({ color }) => ({
    backgroundColor: color,
    border: `1px solid ${color}`,
    borderRadius: '5px',
    padding: '1px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  label: { color: Colors.CLASSICAL_WHITE, fontFamily: 'Mulish', fontSize: 14, fontWeight: 700 },
  icons: {
    color: Colors.CLASSICAL_WHITE,
    visibility: ({ editMode }) => (editMode ? 'visible' : 'hidden'),
  },
});

export const generateRandomColor = () => {
  return `rgb(${Math.floor(Math.random() * 256)},${Math.floor(Math.random() * 256)},${Math.floor(
    Math.random() * 256
  )})`;
};

export const getNewBlockColor = (editorState: EditorState): string => {
  const exteriorConditionalBlock = getCondionnalStartBlockOfSelection(editorState);
  if (isNil(exteriorConditionalBlock)) return conditionalColors[0];

  const blockColor = exteriorConditionalBlock.getData().get('color');
  const blockColorIndex = conditionalColors.indexOf(blockColor);
  if (blockColorIndex === -1 || blockColorIndex === conditionalColors.length - 1) {
    return generateRandomColor();
  }
  return conditionalColors[blockColorIndex + 1];
};

export const addDataToBlock = (contentState: ContentState, block: ContentBlock, data: LooseObject) => {
  const blockSelection = new SelectionState({
    anchorKey: block.getKey(),
    anchorOffset: 0,
    focusKey: block.getKey(),
    focusOffset: block.getLength() - 1,
  });
  return Modifier.mergeBlockData(contentState, blockSelection, Map(data));
};

export const putMetaDataIntoIfBlocks = (contentState: ContentState) => {
  let newContent = contentState;
  const dataIfBlocks: { startBlockKey: string; elseBlockKey?: string; color: string }[] = [];
  let currentBlock = contentState.getFirstBlock();
  // Going from top to bottom
  while (!isNil(currentBlock)) {
    const currentBlockType = currentBlock.getType();
    const currentBlockKey = currentBlock.getKey();
    if (currentBlockType === 'conditionalStart') {
      const colorIndex = dataIfBlocks.length;
      const color = colorIndex < conditionalColors.length ? conditionalColors[colorIndex] : generateRandomColor();
      dataIfBlocks.push({ startBlockKey: currentBlockKey, color });
    } else if (currentBlockType === 'conditionalElse') {
      const correspondingIfBlock = dataIfBlocks[dataIfBlocks.length - 1];
      if (correspondingIfBlock) correspondingIfBlock.elseBlockKey = currentBlockKey;
    } else if (currentBlockType === 'conditionalEnd') {
      // Updating blocks
      const otherConditionalBlocksData = dataIfBlocks.pop();
      if (otherConditionalBlocksData) {
        const { startBlockKey, elseBlockKey, color } = otherConditionalBlocksData;
        // Adding data to the end block
        newContent = addDataToBlock(newContent, currentBlock, { startBlockKey, elseBlockKey, color });
        // Adding data to the start block
        const startBlock = contentState.getBlockForKey(startBlockKey);
        if (startBlock)
          newContent = addDataToBlock(newContent, startBlock, { elseBlockKey, endBlockKey: currentBlockKey, color });
        if (elseBlockKey) {
          // Adding data to the end block
          const elseBlock = contentState.getBlockForKey(elseBlockKey);
          if (elseBlock)
            newContent = addDataToBlock(newContent, elseBlock, {
              startBlockKey,
              endBlockKey: currentBlockKey,
              color,
            });
        }
      }
    }
    // Perform next iteration on block below
    currentBlock = contentState.getBlockAfter(currentBlock.getKey())!;
  }
  return newContent;
};

export const contentContainsUnclosedIfBlocks = (contentState: ContentState) => {
  let conditionalBlocksEncountered = 0;
  let currentBlock = contentState.getFirstBlock();
  // Going from top to bottom
  while (!isNil(currentBlock)) {
    const currentBlockType = currentBlock.getType();
    if (currentBlockType === 'conditionalStart') {
      conditionalBlocksEncountered++;
    } else if (currentBlockType === 'conditionalEnd') {
      conditionalBlocksEncountered--;
    }
    // Perform next iteration on block below
    currentBlock = contentState.getBlockAfter(currentBlock.getKey())!;
  }
  return conditionalBlocksEncountered !== 0;
};

/**
 * returns the start and end offset of the given entity in the block
 */
const getEntityRange = (block: ContentBlock, entityKey: string) => {
  let entityRange: { start: number; end: number } | null = null;
  block.findEntityRanges(
    (value) => value.getEntity() === entityKey,
    (start, end) => {
      entityRange = {
        start,
        end,
      };
    }
  );
  return entityRange as { start: number; end: number } | null;
};
