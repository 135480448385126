import { Typography } from '@material-ui/core';
import { isNil } from 'lodash';
import React, { MouseEvent, useState } from 'react';

import { useIntl } from 'react-intl';
import { useTransactions } from 'src/hooks/TransactionsContext';
import { CustomSimpleDialog } from '@up2rent/ui';

interface DeleteTransactionDialogProps {
  onClose: () => void;
  transactionId: string;
}

const DeleteTransactionDialog: React.FC<DeleteTransactionDialogProps> = ({ onClose, transactionId }) => {
  const { formatMessage } = useIntl();
  const { deleteTransaction, getTransaction, transactionsLoading } = useTransactions();
  const [loading, setLoading] = useState<boolean>(false);

  const transaction = getTransaction(transactionId);

  if (transactionsLoading || !transaction) {
    return null;
  }

  const handleClick = async (e: MouseEvent) => {
    e.preventDefault();
    setLoading(true);
    await deleteTransaction(transaction);
    setLoading(false);
    onClose();
  };

  return (
    <CustomSimpleDialog
      open={!isNil(transactionId)}
      onClose={onClose}
      onActionButtonClick={handleClick}
      title={formatMessage({ id: 'transactions.deleteTransaction.title' })}
      actionButtonLabel={formatMessage({ id: 'lease.detail.action.confirm' })}
      actionButtonLoading={loading}
      formatMessage={formatMessage}
    >
      <Typography style={{ fontSize: 16 }}>
        {formatMessage({ id: 'transactions.deleteTransaction.message' })}
      </Typography>
    </CustomSimpleDialog>
  );
};

export default DeleteTransactionDialog;
