import React from 'react';
import { useIntl } from 'react-intl';
// icons
import { ReactComponent as ImageSvg } from '../../icons/image.svg';
import { ReactComponent as CsvSvg } from '../../icons/csv.svg';
import { ReactComponent as PdfSvg } from '../../icons/pdf.svg';
import { ReactComponent as VideoSvg } from '../../icons/video.svg';
import { ReactComponent as OtherFileSvg } from '../../icons/otherFile.svg';
import { ReactComponent as ZipSvg } from '../../icons/zip.svg';
import { ReactComponent as WordSvg } from '../../icons/word.svg';
import { ReactComponent as TextSvg } from '../../icons/textIcon.svg';

const getIconFromArray = (iconType: string, iconWidth: number = 20, iconHeight: number = 26) => {
  const iconArray = [
    {
      type: 'image/',
      component: <ImageSvg width={iconWidth} height={iconHeight} />,
      userFriendlyDescription: 'enums.userFriendlyType.image',
    },
    {
      type: 'text/csv',
      component: <CsvSvg width={iconWidth} height={iconHeight} />,
      userFriendlyDescription: 'enums.userFriendlyType.texte',
    },
    {
      type: 'application/pdf',
      component: <PdfSvg width={iconWidth} height={iconHeight} />,
      userFriendlyDescription: 'enums.userFriendlyType.pdf',
    },
    {
      type: 'video/',
      component: <VideoSvg width={iconWidth} height={iconHeight} />,
      userFriendlyDescription: 'enums.userFriendlyType.video',
    },
    {
      type: 'application/zip',
      component: <ZipSvg width={iconWidth} height={iconHeight} />,
      userFriendlyDescription: 'enums.userFriendlyType.archive',
    },
    {
      type: 'application/msword',
      component: <WordSvg width={iconWidth} height={iconHeight} />,
      userFriendlyDescription: 'enums.userFriendlyType.word',
    },
    {
      type: 'text/plain',
      component: <TextSvg width={iconWidth} height={iconHeight} />,
      userFriendlyDescription: 'enums.userFriendlyType.texte',
    },
  ];
  return iconArray.find((obj) => iconType.startsWith(obj.type));
};

interface IconComponentProps {
  dataType: string;
  iconWidth?: number;
  iconHeight?: number;
}

interface UserFriendlyTypeProps {
  dataType: string;
}

export const IconComponent: React.FC<IconComponentProps> = ({ dataType, iconWidth, iconHeight }) => {
  const icon = getIconFromArray(dataType, iconWidth as number, iconHeight as number);
  return icon ? icon.component : <OtherFileSvg width={iconWidth} height={iconHeight} />;
};

export const UserFriendlyType: React.FC<UserFriendlyTypeProps> = ({ dataType }) => {
  const { formatMessage } = useIntl();
  const fileType = getIconFromArray(dataType);
  const friendlyType = formatMessage({
    id: fileType ? fileType.userFriendlyDescription : 'enums.userFriendlyType.other',
  });

  return <>{friendlyType}</>;
};
