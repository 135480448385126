import React, { useState } from 'react';
import {
  LeaseStatus,
  LeaseInventory,
  SignatureDocumentStatus,
  Colors,
  getContactNameFromObject,
} from '@rentguru/commons-utils';
import { Paper, Grid, Typography } from '@material-ui/core';
import isNil from 'lodash/isNil';
import { ReactComponent as CheckIcon } from '../../icons/check.svg';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';
import { ReactComponent as HistoryIcon } from '../../icons/history.svg';
import { useIntl } from 'react-intl';
import { ColoredBox } from '@up2rent/ui';
import { useSignatureDocuments } from 'src/hooks/SignatureDocumentContext';
import { useContacts } from 'src/hooks/ContactsContext';
import { getStatusAndColorDetail } from '../ui/LeaseProgressStepper';

interface LeaseProgressStepperProps {
  leaseInventory: LeaseInventory;
  style?: React.CSSProperties;
}

const LeaseProgressStepper: React.FC<LeaseProgressStepperProps> = ({ leaseInventory, style = {} }) => {
  const { formatMessage } = useIntl();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { getSignatureDocumentsForEntity } = useSignatureDocuments();
  const { getContact } = useContacts();
  const pendingSignatureDocuments = getSignatureDocumentsForEntity(
    'LeaseInventory',
    leaseInventory.id,
    SignatureDocumentStatus.PENDING
  );
  const currentSignatureResults =
    pendingSignatureDocuments && pendingSignatureDocuments[0] ? pendingSignatureDocuments[0].signatureResults : [];
  const { draftColor, pendingColor, pendingText, signedColor } = getStatusAndColorDetail(
    leaseInventory.status as LeaseStatus,
    currentSignatureResults,
    formatMessage
  );
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', ...style }}>
      <ColoredBox boxColor={draftColor}>{formatMessage({ id: 'enums.LeaseStatus.Draft' })}</ColoredBox>
      <div
        style={{ width: '100%', height: 1, backgroundColor: Colors.SEPARATOR_GREY, marginLeft: 10, marginRight: 10 }}
      />
      <ColoredBox
        boxColor={pendingColor}
        onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
        onMouseLeave={() => setAnchorEl(null)}
      >
        {pendingText}
        {!isNil(anchorEl) &&
          (leaseInventory.status === LeaseStatus.OutForSignature || leaseInventory.status === LeaseStatus.Rejected) && (
            <Paper
              elevation={8}
              style={{
                position: 'absolute',
                padding: 12,
                transform: 'translate(-135px, 10px)',
                zIndex: 2,
                width: 400,
                borderRadius: 10,
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <Grid container style={{ marginBottom: 5 }}>
                <Grid item xs={6}>
                  <Typography style={{ fontSize: 12, color: Colors.BLUE_GREY }}>
                    {formatMessage({ id: 'lease.detail.progress.name' })}
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography style={{ fontSize: 12, color: Colors.BLUE_GREY }}>
                    {formatMessage({ id: 'lease.detail.progress.signed' })}
                  </Typography>
                </Grid>
              </Grid>
              {currentSignatureResults.map((result) => {
                const contact = getContact(result.contactId);
                return (
                  <Grid key={result.contactId} container style={{ marginTop: 10 }}>
                    <Grid item xs={6}>
                      <Typography
                        style={{
                          fontSize: 16,
                          whiteSpace: 'nowrap',
                          fontWeight: 600,
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        {getContactNameFromObject(contact)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                      {!isNil(result.signed) ? (
                        result.signed ? (
                          <CheckIcon style={{ fill: Colors.TURQUOISE_BLUE }} />
                        ) : (
                          <CloseIcon style={{ fill: Colors.BURNING_ORANGE }} />
                        )
                      ) : (
                        <HistoryIcon />
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            </Paper>
          )}
      </ColoredBox>
      <div
        style={{ width: '100%', height: 1, backgroundColor: Colors.SEPARATOR_GREY, marginLeft: 10, marginRight: 10 }}
      />
      <ColoredBox boxColor={signedColor}>{formatMessage({ id: 'enums.LeaseStatus.Active' })}</ColoredBox>
    </div>
  );
};

export default LeaseProgressStepper;
