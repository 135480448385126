import React from 'react';
import './Logo.css';
import Grid from '@material-ui/core/Grid';
import up2rentLogo from 'src/icons/up2rent.svg';
import up2rentWithText from 'src/icons/up2rentWithText.svg';

interface LogoProps {
  size?: number;
  margin?: string;
  withText?: boolean;
  textStyle?: React.CSSProperties;
  logoStyle?: React.CSSProperties;
  logoContainerStyle?: React.CSSProperties;
}

const Logo: React.FC<LogoProps> = ({ size, margin, withText, textStyle, logoStyle, logoContainerStyle }) => {
  let styleContainer = {};
  if (typeof size !== 'undefined') {
    styleContainer = { width: size, height: size, margin };
  }

  return (
    <Grid
      alignContent="center"
      alignItems={withText ? 'baseline' : 'center'}
      justify="center"
      container
      style={{ position: 'relative' }}
    >
      {withText ? (
        <Grid>
          <img
            src={up2rentWithText}
            style={textStyle || { height: 70, paddingBottom: 5, marginTop: 30, marginBottom: 20 }}
            alt="Up2Rent"
          />
        </Grid>
      ) : (
        <Grid className="logo" style={logoContainerStyle || styleContainer}>
          <img
            src={up2rentLogo}
            alt="Up2Rent"
            style={logoStyle ? { transform: 'scale(1.5)', ...logoStyle } : { transform: 'scale(1.5)' }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Logo;
