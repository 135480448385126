import React from 'react';
import { useIntl } from 'react-intl';
import Divider from '@material-ui/core/Divider';
import { ContentHeader , ElevatedPaper } from '@up2rent/ui';
import RemindersForm from './Reminders/RemindersForm';
import HelpIconPopover from '../ui/HelpIconPopover';

const RemindersSettings: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <ElevatedPaper style={{ margin: 20, maxWidth: 640, paddingBottom: 0 }}>
      <ContentHeader title={formatMessage({ id: 'settings.remindersMenu' })}>
        <HelpIconPopover
          helperText={formatMessage({ id: 'settings.reminders.globalHelper' })}
          paperStyle={{ maxWidth: 400 }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        />
      </ContentHeader>
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      <RemindersForm />
    </ElevatedPaper>
  );
};

export default RemindersSettings;
