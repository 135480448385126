/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-redeclare */
import React, { Reducer, useEffect, useReducer } from 'react';
import { Hub } from 'aws-amplify/utils';
import { isNil, isEqual } from 'lodash';
import {
  createClientAccount,
  updateUserCustom,
  isEmailAlreadyTaken as isEmailAlreadyTakenMutation,
  CUSTOM_ATTRIBUTE_LANGUAGE,
  CUSTOM_ATTRIBUTE_USER,
  CUSTOM_ATTRIBUTE_TENANT,
  CUSTOM_ATTRIBUTE_OWNER,
  CUSTOM_ATTRIBUTE_MEMBER,
  CUSTOM_ATTRIBUTE_CLIENT,
  CUSTOM_ATTRIBUTE_ACCOUNT_TYPE,
  CUSTOM_ATTRIBUTE_ROOT_USER,
  CUSTOM_ATTRIBUTE_FEATURES,
  getCountryCodeAndNameOfClient,
  CountryCode,
  StatusResult,
  UpdateUserCustomMutationVariables,
  AccountType,
  MutationUserInputUpdatedBy,
  IsEmailAlreadyTakenMutationVariables,
  Client,
  ContactType,
  PhoneNumber,
} from '@rentguru/commons-utils';
import { mutation, get } from '@up2rent/fetch-utils';
import { GDPR_VERSION, GENERAL_CONDITIONS_VERSION } from 'src/components/Auth/Signup';
import {
  confirmResetPassword,
  confirmSignIn,
  fetchAuthSession,
  fetchUserAttributes,
  resetPassword,
  signIn,
  signOut,
  signUp as amplifySignUp,
  confirmSignUp as amplifyConfirmSignUp,
  updatePassword,
  FetchUserAttributesOutput,
  updateUserAttributes,
  resendSignUpCode,
} from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';

const graphQLClient = generateClient();

const getClientQuery = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      contact {
        firstName
        lastName
        commercialName
        companyName
        types
        phoneNumbers {
          number
        }
      }
      issueEmail
      chargeEmail
      issueEmailFromSendingSource
      chargeEmailFromSendingSource
    }
  }
`;

export interface UserContext extends UserState {
  setTemporaryCredentials: (email: string, password: string | null) => void;
  login: (usernameOrEmail: string, password: string) => Promise<boolean>;
  logout: () => Promise<void>;
  completeNewPassword: (newPassword: string) => Promise<void>;
  forgotPassword: (usernameOrEmail: string) => Promise<void>;
  forgotPasswordSubmit: (usernameOrEmail: string, confirmationCode: string, newPassword: string) => Promise<void>;
  signUp: (
    usernameOrEmail: string,
    password: string,
    name: string,
    familyName: string,
    phoneNumber?: string
  ) => Promise<void>;
  joinSignUp: (usernameOrEmail: string, password: string, name: string, familyName: string) => Promise<void>;
  confirmSignUp: (usernameOrEmail: string, password: string, confirmationCode: string) => Promise<void>;
  resendConfirmSignUp: (userNameOremail: string) => Promise<void>;
  createClientUserAndTeam: (
    name: string,
    vat: string,
    businessNumber: string,
    address: { country: string; postalCode: string; street: string; number: string; box: string; city: string },
    firstName: string,
    lastName: string,
    email: string,
    provider: string,
    accountType: AccountType,
    phoneNumber?: string
  ) => Promise<void>;
  editUserPassword: (oldPassword: string, newPassword: string) => Promise<void>;
  refreshToken: () => Promise<void>;
  updateCognitoEmail: (
    userId: string,
    newEmail: string,
    language: string,
    updatedByOwner?: boolean
  ) => Promise<StatusResult>;
  isEmailAlreadyTaken: (email: string) => Promise<StatusResult>;
  refreshEmails: (issueEmailFromSendingSource: string, chargeEmailFromSendingSource: string) => void;
  setUserAttributes: (attributes: { [key: string]: string }) => Promise<void>;
}

interface UserState {
  userAttributes: FetchUserAttributesOutput | null;
  clientId: string | null;
  countryCode: CountryCode | null;
  countryName: string | null;
  countryDialCode: string | null;
  userId: string | null;
  groupIds: string[] | null;
  language: string | null;
  tenantId: string | null;
  isTenant: boolean;
  isAgency: boolean;
  ownerId: string | null;
  isOwner: boolean;
  memberId: string | null;
  accountType: AccountType | null;
  rootUser: boolean;
  features: string[];
  tempEmail: string | null;
  tempPass: string | null;
  isFetchingUser: boolean;
  changePasswordChallenge: boolean;
  confirmSignUpChallenge: boolean;
  issueEmail: string | null;
  chargeEmail: string | null;
  issueEmailFromSendingSource: string | null;
  chargeEmailFromSendingSource: string | null;
  contact: {
    firstName: string | null;
    lastName: string | null;
    commercialName: string | null;
    companyName: string | null;
    types: ContactType[];
    phoneNumbers: PhoneNumber[];
  };
}

type Action =
  | {
      type: 'SET_USER';
      payload: {
        userAttributes: FetchUserAttributesOutput | null;
        clientId: string | null;
        userId: string | null;
        groupIds: string[] | null;
        language: string | null;
        tenantId: string | null;
        ownerId: string | null;
        memberId: string | null;
        accountType: AccountType | null;
        rootUser: boolean;
        features: string[];
      };
    }
  | {
      type: 'SIGN_OUT';
    }
  | {
      type: 'NEW_PASSWORD_REQUESTED';
      payload: { value: boolean };
    }
  | {
      type: 'SET_TEMPORARY_CREDENTIALS';

      payload: { tempEmail: string; tempPass: string | null };
    }
  | {
      type: 'SET_LOCALIZATION';

      payload: { countryCode: CountryCode | null; countryName: string | null; countryDialCode: string | null };
    }
  | {
      type: 'SET_ISSUE_EMAIL';
      payload: {
        issueEmail?: string;
        chargeEmail?: string;
        issueEmailFromSendingSource: string;
        chargeEmailFromSendingSource: string;
      };
    }
  | {
      type: 'SET_CLIENT';
      payload: {
        issueEmail?: string;
        chargeEmail?: string;
        issueEmailFromSendingSource: string;
        chargeEmailFromSendingSource: string;
        contact: {
          firstName: string;
          lastName: string;
          commercialName: string;
          companyName: string;
          types: ContactType[];
          phoneNumbers: PhoneNumber[];
        };
      };
    }
  | {
      type: 'CONFIRM_SIGN_UP';
      payload: { tempEmail: string; tempPass: string };
    };

const initialState: UserState = {
  userAttributes: null,
  clientId: null,
  language: null,
  userId: null,
  groupIds: null,
  tenantId: null,
  ownerId: null,
  memberId: null,
  accountType: null,
  rootUser: false,
  features: [],
  changePasswordChallenge: false,
  confirmSignUpChallenge: false,
  countryCode: null,
  countryName: null,
  countryDialCode: null,
  isAgency: false,
  isFetchingUser: true,
  isOwner: false,
  isTenant: false,
  tempEmail: null,
  tempPass: null,
  issueEmail: null,
  chargeEmail: null,
  issueEmailFromSendingSource: null,
  chargeEmailFromSendingSource: null,
  contact: {
    firstName: null,
    lastName: null,
    commercialName: null,
    companyName: null,
    types: [],
    phoneNumbers: [],
  },
};

const userReducer = (state: UserState, action: Action): UserState => {
  switch (action.type) {
    case 'SET_USER':
      const { accountType, clientId, features, groupIds, language, memberId, ownerId, rootUser, tenantId, userId } =
        state;
      const currentUserDataFromState = {
        accountType,
        clientId,
        features,
        groupIds,
        language,
        memberId,
        ownerId,
        rootUser,
        tenantId,
        userId,
      };
      if (isEqual(currentUserDataFromState, action.payload)) {
        return state;
      }

      const isOwner = !isNil(action.payload.ownerId);
      const isTenant = !isNil(action.payload.tenantId);
      const isAgency = action.payload.accountType === AccountType.AGENCY;

      return {
        ...state,
        isOwner,
        isTenant,
        isAgency,
        ...action.payload,
        isFetchingUser: false,
      };
    case 'SIGN_OUT':
      const { tempEmail, tempPass } = state; // We need to keep the temporary credentials for sign up flow
      return { ...initialState, isFetchingUser: false, tempEmail, tempPass };
    case 'NEW_PASSWORD_REQUESTED':
      if (state.changePasswordChallenge === action.payload.value) {
        return state;
      }
      return {
        ...state,
        changePasswordChallenge: action.payload.value,
      };
    case 'CONFIRM_SIGN_UP':
      return {
        ...state,
        tempEmail: action.payload.tempEmail,
        tempPass: action.payload.tempPass,
        confirmSignUpChallenge: true,
      };
    case 'SET_TEMPORARY_CREDENTIALS':
      if (state.tempEmail === action.payload.tempEmail || state.tempPass === action.payload.tempPass) {
        return state;
      }

      return { ...state, tempEmail: action.payload.tempEmail, tempPass: action.payload.tempPass };
    case 'SET_LOCALIZATION':
      const { countryCode, countryName, countryDialCode } = state;
      if (isEqual({ countryCode, countryName, countryDialCode }, action.payload)) {
        return state;
      }
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_ISSUE_EMAIL':
      const emailState = {
        issueEmail: state.issueEmail,
        chargeEmail: state.chargeEmail,
        issueEmailFromSendingSource: state.issueEmailFromSendingSource,
        chargeEmailFromSendingSource: state.chargeEmailFromSendingSource,
      };
      if (isEqual(emailState, action.payload)) {
        return state;
      }
      return {
        ...state,
        issueEmail: action.payload.issueEmail ?? state.issueEmail,
        chargeEmail: action.payload.chargeEmail ?? state.chargeEmail,
        issueEmailFromSendingSource: action.payload.issueEmailFromSendingSource,
        chargeEmailFromSendingSource: action.payload.chargeEmailFromSendingSource,
      };
    case 'SET_CLIENT':
      return {
        ...state,
        issueEmail: action.payload.issueEmail ?? state.issueEmail,
        chargeEmail: action.payload.chargeEmail ?? state.chargeEmail,
        issueEmailFromSendingSource: action.payload.issueEmailFromSendingSource,
        chargeEmailFromSendingSource: action.payload.chargeEmailFromSendingSource,
        contact: {
          firstName: action.payload.contact.firstName,
          lastName: action.payload.contact.lastName,
          commercialName: action.payload.contact.commercialName,
          companyName: action.payload.contact.companyName,
          types: action.payload.contact.types,
          phoneNumbers: action.payload.contact.phoneNumbers,
        },
      };
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-mutable-exports
export let initLanguage: string;
const browserLanguage = (navigator.language && navigator.languages[0]) || navigator.language || 'en-UK';
if (/^fr/.test(browserLanguage)) initLanguage = 'fr';
else if (/^nl/.test(browserLanguage)) initLanguage = 'nl';
else initLanguage = 'en';

// Create a context that will hold the values that we are going to expose to our components.
// Don't worry about the `null` value. It's gonna be *instantly* overriden by the component below
const UserContext = React.createContext<UserContext | null>(null);

// Create a "controller" component that will calculate all the data that we need to give to our
// components bellow via the `UserContext.Provider` component. This is where the Amplify will be
// mapped to a different interface, the one that we are going to expose to the rest of the app.
export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer<Reducer<UserState, Action>>(userReducer, initialState);
  useEffect(() => {
    let unmounted = false;
    const getUser = async () => {
      try {
        const attributes = await fetchUserAttributes();
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        const sessionPayload = idToken?.payload ?? {};

        const clientId = attributes[CUSTOM_ATTRIBUTE_CLIENT] ?? null;
        const language = attributes[CUSTOM_ATTRIBUTE_LANGUAGE] ?? null;
        const userId = attributes[CUSTOM_ATTRIBUTE_USER] ?? null;
        const tenantId = attributes[CUSTOM_ATTRIBUTE_TENANT] ?? null;
        const ownerId = attributes[CUSTOM_ATTRIBUTE_OWNER] ?? null;
        const memberId = attributes[CUSTOM_ATTRIBUTE_MEMBER] ?? null;
        const accountType = (attributes[CUSTOM_ATTRIBUTE_ACCOUNT_TYPE] ?? null) as AccountType | null;
        const rootUser = attributes[CUSTOM_ATTRIBUTE_ROOT_USER]
          ? attributes[CUSTOM_ATTRIBUTE_ROOT_USER] === 'true'
          : false;

        const features = JSON.parse((sessionPayload[CUSTOM_ATTRIBUTE_FEATURES] as string) ?? '[]');
        const groupIds =
          !isNil(sessionPayload) && !isNil(sessionPayload['cognito:groups'])
            ? (sessionPayload['cognito:groups'] as string[])
            : null;

        if (!unmounted) {
          dispatch({
            type: 'SET_USER',
            payload: {
              userAttributes: attributes,
              accountType,
              clientId,
              features,
              groupIds,
              language,
              memberId,
              ownerId,
              rootUser,
              tenantId,
              userId,
            },
          });
        }
      } catch (err) {
        if (!unmounted) {
          dispatch({ type: 'SIGN_OUT' });
        }
      }
    };
    getUser();

    // set listener for auth events
    const hubListenerCancelToken = Hub.listen('auth', (data) => {
      const { payload } = data;
      if (payload.event === 'signedIn' && !unmounted) {
        getUser();
      } else if (payload.event === 'tokenRefresh' && !unmounted) {
        // Used also when user is changing language
        getUser();
      } else if (payload.event === 'tokenRefresh_failure') {
        console.error('Auth Token refresh failed...');
      } else if (payload.event === 'signedOut') {
        // this listener is needed for form sign ups since the OAuth will redirect & reload
        setTimeout(() => {
          if (!unmounted) {
            dispatch({ type: 'SIGN_OUT' });
          }
        }, 350);
      }
    });
    return () => {
      unmounted = true;
      hubListenerCancelToken(); // stop listening for messages
    };
  }, []);

  useEffect(() => {
    let unmounted = false;

    const getCountryCode = async () => {
      try {
        const countryObject = await getCountryCodeAndNameOfClient();
        if (!unmounted) {
          dispatch({ type: 'SET_LOCALIZATION', payload: countryObject });
        }
      } catch (err) {}
    };
    getCountryCode();
    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    let unmounted = false;

    const getClientDispatch = async () => {
      try {
        const client = await getClient();
        if (!unmounted && client) {
          dispatch({
            type: 'SET_CLIENT',
            payload: {
              issueEmail: client.issueEmail ?? '',
              chargeEmail: client.chargeEmail ?? '',
              issueEmailFromSendingSource: client.issueEmailFromSendingSource ?? '',
              chargeEmailFromSendingSource: client.chargeEmailFromSendingSource ?? '',
              contact: {
                firstName: client.contact?.firstName ?? '',
                lastName: client.contact?.lastName ?? '',
                commercialName: client.contact?.commercialName ?? '',
                companyName: client.contact?.companyName ?? '',
                types: (client.contact?.types as ContactType[]) ?? [],
                phoneNumbers: (client.contact?.phoneNumbers as PhoneNumber[]) ?? [],
              },
            },
          });
        }
      } catch (err) {
        console.error('Error getting Emails:', err);
      }
    };
    if (state.clientId) {
      getClientDispatch();
    }
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.clientId]);

  const clearCognitoLocalStorage = () => {
    const itemsToRemoveFromLocalStorage: string[] = [];
    for (const localSave in window.localStorage) {
      if (localSave.startsWith('CognitoIdentityServiceProvider')) {
        itemsToRemoveFromLocalStorage.push(localSave);
      }
    }
    itemsToRemoveFromLocalStorage.map((itemToRemove) => window.localStorage.removeItem(itemToRemove));
  };

  const login = async (usernameOrEmail: string, password: string) => {
    clearCognitoLocalStorage();
    const result = await signIn({ username: usernameOrEmail, password });
    if (result.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
      dispatch({ type: 'NEW_PASSWORD_REQUESTED', payload: { value: true } });
    }
    if (!result.isSignedIn && result.nextStep.signInStep == 'CONFIRM_SIGN_UP') {
      dispatch({ type: 'CONFIRM_SIGN_UP', payload: { tempEmail: usernameOrEmail, tempPass: password } });
    }

    return true;
  };

  const logout = async () => {
    await signOut({ global: true });
    window.localStorage.clear();
  };

  const completeNewPassword = async (newPassword: string) => {
    await confirmSignIn({ challengeResponse: newPassword });
    dispatch({ type: 'NEW_PASSWORD_REQUESTED', payload: { value: false } });
  };

  const forgotPassword = async (usernameOrEmail: string) => {
    await resetPassword({ username: usernameOrEmail });
  };

  const forgotPasswordSubmit = async (usernameOrEmail: string, confirmationCode: string, newPassword: string) => {
    await confirmResetPassword({ username: usernameOrEmail, confirmationCode, newPassword });
    setTemporaryCredentials(usernameOrEmail, newPassword);
  };

  const setTemporaryCredentials = (email: string, password: string | null) => {
    dispatch({ type: 'SET_TEMPORARY_CREDENTIALS', payload: { tempEmail: email, tempPass: password } });
  };

  const signUp = async (
    usernameOrEmail: string,
    password: string,
    name: string,
    familyName: string,
    phoneNumber?: string
  ) => {
    await amplifySignUp({
      username: usernameOrEmail,
      password,
      options: {
        userAttributes: {
          email: usernameOrEmail,
          name: `${name} ${familyName}`,
          given_name: name,
          family_name: familyName,
          ...(phoneNumber ? { phone_number: phoneNumber } : {}),

          [`${CUSTOM_ATTRIBUTE_LANGUAGE}`]: initLanguage,
        },

        validationData: {}, // optional
        autoSignIn: true,
      },
    });
    dispatch({ type: 'CONFIRM_SIGN_UP', payload: { tempEmail: usernameOrEmail, tempPass: password } });
  };

  const joinSignUp = (usernameOrEmail: string, password: string, name: string, familyName: string) =>
    signUp(usernameOrEmail, password, name, familyName);

  const confirmSignUp = async (usernameOrEmail: string, password: string, confirmationCode: string) => {
    await amplifyConfirmSignUp({
      username: usernameOrEmail,
      confirmationCode,
      options: {
        // Optional. Force user confirmation irrespective of existing alias. By default set to True.
        forceAliasCreation: true,
        autoSignIn: true,
      },
    });
    await login(usernameOrEmail, password);
  };

  const resendConfirmSignUp = async (userNameOrEmail: string) => {
    await resendSignUpCode({ username: userNameOrEmail });
  };

  const createClientUserAndTeam = async (
    companyName: string,
    vat: string,
    businessNumber: string,
    address: { country: string; postalCode: string; street: string; number: string; box: string; city: string },
    firstName: string,
    lastName: string,
    email: string,
    provider: string,
    accountType: AccountType,
    phoneNumber?: string
  ) => {
    await graphQLClient.graphql({
      query: createClientAccount,
      variables: {
        input: {
          companyName,
          vat,
          businessNumber,
          address,
          firstName,
          lastName,
          email,
          phoneNumber,
          provider,
          accountType,
          language: initLanguage,
          GDPRAcceptedVersion: GDPR_VERSION,
          generalConditionsAcceptedVersion: GENERAL_CONDITIONS_VERSION,
        },
      },
    });

    const { tempEmail, tempPass } = state;
    await logout();
    if (!isNil(tempEmail) && !isNil(tempPass)) {
      await login(tempEmail, tempPass);
    }
  };

  const editUserPassword = async (oldPassword: string, newPassword: string) => {
    await updatePassword({ oldPassword, newPassword });
  };

  const refreshToken = async (): Promise<void> => {
    // eslint-disable-next-line no-useless-catch
    try {
      await fetchAuthSession({ forceRefresh: true });
    } catch (err) {
      throw err;
    }
  };

  const refreshEmails = (issueEmailFromSendingSource: string, chargeEmailFromSendingSource: string): void => {
    dispatch({
      type: 'SET_ISSUE_EMAIL',
      payload: {
        issueEmailFromSendingSource,
        chargeEmailFromSendingSource,
      },
    });
  };

  const updateCognitoEmail = async (
    userId: string,
    newEmail: string,
    language: string,
    updatedByOwner: boolean = false
  ): Promise<StatusResult> => {
    return await mutation<StatusResult, UpdateUserCustomMutationVariables>(updateUserCustom, {
      input: {
        id: userId,
        email: newEmail,
        language,
        ...(updatedByOwner ? { updatedBy: MutationUserInputUpdatedBy.OWNER } : undefined),
      },
    });
  };

  const isEmailAlreadyTaken = async (email: string): Promise<StatusResult> => {
    return await mutation<StatusResult, IsEmailAlreadyTakenMutationVariables>(isEmailAlreadyTakenMutation, {
      input: { email },
    });
  };

  const getClient = async (): Promise<Client | undefined> => {
    const client = await get<Client>(getClientQuery, state.clientId ?? '');
    return client;
  };

  const setUserAttributes = async (attributes: { [key: string]: string }) => {
    await updateUserAttributes({ userAttributes: attributes });
    await refreshToken();
  };

  // Make sure to not force a re-render on the components that are reading these values,
  // unless the `user` value has changed. This is an optimisation that is mostly needed in cases
  // where the parent of the current component re-renders and thus the current component is forced
  // to re-render as well. If it does, we want to make sure to give the `UserContext.Provider` the
  // same value as long as the user data is the same. If you have multiple other "controller"
  // components or Providers above this component, then this will be a performance booster.
  const values = React.useMemo(
    () => ({
      ...state,
      setTemporaryCredentials,
      completeNewPassword,
      login,
      logout,
      forgotPassword,
      forgotPasswordSubmit,
      signUp,
      joinSignUp,
      confirmSignUp,
      resendConfirmSignUp,
      createClientUserAndTeam,
      editUserPassword,
      updateCognitoEmail,
      isEmailAlreadyTaken,
      refreshToken,
      refreshEmails,
      setUserAttributes,
    }),
    // Because it's asking to add createClientUserAndTeam method in dependencies and we don't want to do that
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  // Finally, return the interface that we want to expose to our other components
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

// We also create a simple custom hook to read these values from. We want our React components
// to know as little as possible on how everything is handled, so we are not only abstracting them from
// the fact that we are using React's context, but we also skip some imports.
export const useUser = (): UserContext => {
  const context = React.useContext(UserContext);

  if (context === undefined) {
    throw new Error('`useUser` hook must be used within a `UserProvider` component');
  }
  return context as UserContext;
};
