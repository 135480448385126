/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-param-last */
import React, { useContext, useEffect, useMemo, useReducer, Reducer, useRef } from 'react';
import {
  Building,
  Address,
  BuildingEvent,
  DeleteEntityByIdMutationVariables,
  MutationStatus,
  resolveOneToMany,
  resolveManyToMany,
  getReadId,
  getTableClientId,
  cleanInputUpdate,
  cleanInputCreate,
  syncBuildings as syncQuery,
  getBuilding as getQuery,
  createBuilding as createMutation,
  updateBuilding as updateMutation,
  deleteBuilding as deleteMutation,
  CreateBuildingMutationVariables as CreateMutationVariables,
  DeleteBuildingMutationVariables as DeleteMutationVariables,
  CreateBuildingInput as CreateInput,
  updateBuildingEvent as updateBuildingEventMutation,
  getBuildingEvent as getBuildingEventQuery,
  deleteBuildingEvent as deleteBuildingEventMutation,
  CreateBuildingEventMutationVariables,
  DeleteBuildingEventMutationVariables,
  createBuildingEvent as createBuildingEventMutation,
  syncBuildingEvents,
  isNilOrEmpty,
  Model,
  CreateBuildingEventInput,
  OnCreateBuildingSubscription,
  OnUpdateBuildingSubscription,
  OnDeleteBuildingSubscription,
  onCreateBuilding,
  onUpdateBuilding,
  onDeleteBuilding,
  OnCreateBuildingEventSubscription,
  OnUpdateBuildingEventSubscription,
  OnDeleteBuildingEventSubscription,
  onCreateBuildingEvent,
  onUpdateBuildingEvent,
  onDeleteBuildingEvent,
  resolveOneToOne,
  DeepDeletableEntityType,
  deleteEntityById as deleteEntityByIdMutation,
} from '@rentguru/commons-utils';
import { useUser } from './UserContext';
import { intersection, isEmpty, isNil } from 'lodash';
import { ContextLoaderAction, ContextLoaderStore, useContextLoader } from './ContextLoader';
import { usePermissions } from './utils/PermissionsContext';
import {
  deleteEntityWithFetchBefore,
  getFilterFieldNameForIndex,
  list,
  mutation,
  recordWasUpdated,
  useSubscriptions,
} from '@up2rent/fetch-utils';
import { AddressContext, useAddresses } from './AddressContext';

const ENTITY_MODEL_NAME: Model = 'Building';
const ENTITY_MODEL_NAME_BUILDING_EVENT: Model = 'BuildingEvent';

export type ActionBuilding =
  | {
      type: 'SHOULD_FETCH_BUILDING' | 'IS_FETCHING_BUILDING';
    }
  | {
      type: 'ADD_BUILDING';
      payload: { building: Building };
    }
  | {
      type: 'FETCHED_BUILDING';
      payload: { buildings: Building[] };
    }
  | {
      type: 'UPDATE_BUILDING';
      payload: { building: Building };
    }
  | {
      type: 'DELETE_BUILDING';
      payload: { id: string };
    };

type ActionBuildingEvent =
  | {
      type: 'SHOULD_FETCH_BUILDING_EVENT' | 'IS_FETCHING_BUILDING_EVENT';
    }
  | {
      type: 'ADD_BUILDING_EVENT';
      payload: { buildingEvent: BuildingEvent };
    }
  | {
      type: 'FETCHED_BUILDING_EVENT';
      payload: { buildingEvents: BuildingEvent[] };
    }
  | {
      type: 'UPDATE_BUILDING_EVENT';
      payload: { buildingEvent: BuildingEvent };
    }
  | {
      type: 'DELETE_BUILDING_EVENT';
      payload: { id: string };
    };

type BuildingNoId = Omit<Building, 'id' | 'clientId' | 'readId'>;

interface CreateBuilding extends Omit<BuildingNoId, 'address'> {
  address: Omit<Address, 'id' | 'clientId' | 'readId'>;
}

interface BuildingState {
  buildings: Building[];
  shouldFetchBuilding: boolean;
  buildingsLoading: boolean;
}

export interface BuildingContext extends BuildingState, BuildingEventState {
  getBuilding: (id: string) => Building | undefined;
  createBuilding: (input: CreateBuilding) => Promise<Building>;
  updateBuilding: (original: Building, updates: Partial<Building>) => Promise<Building>;
  deleteBuilding: (id: string) => void;
  createBuildingEvent: (input: Omit<BuildingEvent, 'id' | 'clientId' | 'readId'>) => Promise<BuildingEvent>;
  getBuildingEvent: (id: string) => BuildingEvent[];
  updateBuildingEvent: (original: BuildingEvent, updates: Partial<BuildingEvent>) => Promise<BuildingEvent>;
  deleteBuildingEvent: (id: string) => Promise<void>;
  deepDeleteBuilding: (id: string) => Promise<MutationStatus | undefined>;
  createAddress: AddressContext['createAddress'];
  updateAddress: AddressContext['updateAddress'];
  setFetchBuildingEvents: () => void;
  setFetchBuildings: () => void;
  buildingsError: string | undefined;
}

export const buildingReducerDelegation = (
  state: ContextLoaderStore,
  action: ContextLoaderAction
): ContextLoaderStore => {
  switch (action.type) {
    case 'SHOULD_FETCH_BUILDING':
      if (state.Building.loading || state.Building.shouldFetch) {
        return state;
      }
      return {
        ...state,
        Building: { ...state.Building, shouldFetch: true },
      };
    case 'IS_FETCHING_BUILDING':
      if (state.Building.loading) {
        return state;
      }
      return {
        ...state,
        Building: { ...state.Building, loading: true },
      };
    case 'FETCHED_BUILDING':
      return {
        ...state,
        Building: {
          ...state.Building,
          data: action.payload.buildings,
          loading: false,
          shouldFetch: false,
          lastFetch: new Date(),
        },
      };
    case 'ADD_BUILDING':
      // Check if already present - If already added by this user or coming from another user
      if (state.Building.data?.find((object) => object.id === action.payload.building.id)) {
        return state;
      }

      return {
        ...state,
        Building: {
          ...state.Building,
          data: [...state.Building.data, action.payload.building],
        },
      };
    case 'UPDATE_BUILDING':
      // No data
      if (isNilOrEmpty(state.Building.data)) {
        return state;
      }

      // Already present and same object
      const currentObject = state.Building.data?.find((object) => object.id === action.payload.building.id);
      if (!currentObject) {
        return {
          ...state,
          Building: {
            ...state.Building,
            data: [...state.Building.data, action.payload.building],
          },
        };
      }
      if (!recordWasUpdated(currentObject, action.payload.building)) {
        return state;
      }

      // Update
      return {
        ...state,
        Building: {
          ...state.Building,
          data: state.Building.data.map((object) => {
            if (object.id === action.payload.building.id) {
              return action.payload.building;
            }
            return object;
          }),
        },
      };
    case 'DELETE_BUILDING':
      if (isNilOrEmpty(state.Building.data)) {
        return state;
      }
      return {
        ...state,
        Building: {
          ...state.Building,
          data: state.Building.data.filter((object) => object.id !== action.payload.id),
        },
      };

    default:
      return state;
  }
};

interface BuildingEventState {
  buildingEvents: BuildingEvent[] | null;
  loading: boolean;
  shouldFetchBuildingEvent: boolean;
  lastFetch: Date | null;
}

const buildingEventInitialState: BuildingEventState = {
  loading: false,
  buildingEvents: [],
  shouldFetchBuildingEvent: false,
  lastFetch: null,
};

const buildingEventReducer = (state: BuildingEventState, action: ActionBuildingEvent): BuildingEventState => {
  switch (action.type) {
    case 'SHOULD_FETCH_BUILDING_EVENT':
      if (state.loading || state.shouldFetchBuildingEvent) {
        return state;
      }
      return {
        ...state,
        shouldFetchBuildingEvent: true,
      };
    case 'IS_FETCHING_BUILDING_EVENT':
      return {
        ...state,
        loading: true,
      };
    case 'FETCHED_BUILDING_EVENT':
      return {
        ...state,
        loading: false,
        shouldFetchBuildingEvent: false,
        buildingEvents: action.payload.buildingEvents,
        lastFetch: new Date(),
      };
    case 'ADD_BUILDING_EVENT':
      if (state.buildingEvents?.find((object) => object.id === action.payload.buildingEvent.id)) {
        return state;
      }

      return {
        ...state,

        buildingEvents: [...(state.buildingEvents ?? []), action.payload.buildingEvent],
      };

    case 'UPDATE_BUILDING_EVENT':
      // No data
      if (isNilOrEmpty(state.buildingEvents)) {
        return state;
      }

      // Already present and same object
      const currentObject = state.buildingEvents.find((object) => object.id === action.payload.buildingEvent.id);
      if (!currentObject) {
        return {
          ...state,
          buildingEvents: [...state.buildingEvents, action.payload.buildingEvent],
        };
      }
      if (!recordWasUpdated(currentObject, action.payload.buildingEvent)) {
        return state;
      }

      // Update
      return {
        ...state,
        buildingEvents: state.buildingEvents.map((object) => {
          if (object.id === action.payload.buildingEvent.id) {
            return action.payload.buildingEvent;
          }
          return object;
        }),
      };

    case 'DELETE_BUILDING_EVENT':
      if (isNilOrEmpty(state.buildingEvents)) {
        return state;
      }

      return {
        ...state,
        buildingEvents: state.buildingEvents.filter((object) => object.id !== action.payload.id),
      };
    default:
      return state;
  }
};

export const fetchBuildings = async (clientId: string, additionalFilter?: object): Promise<Building[]> => {
  return await list<Building>(
    syncQuery,
    getFilterFieldNameForIndex('byClientId'),
    getTableClientId(clientId, ENTITY_MODEL_NAME),
    additionalFilter
  );
};

export const fetchBuildingEvents = async (
  by: 'byClientId' | 'byBuilding',
  byValue: string,
  additionalFilter?: object
): Promise<BuildingEvent[]> => {
  return await list<BuildingEvent>(syncBuildingEvents, getFilterFieldNameForIndex(by), byValue, additionalFilter);
};

export const BuildingContext = React.createContext<BuildingContext | null>(null);

export const BuildingContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [buildingEventsState, buildingEventsDispatch] = useReducer<Reducer<BuildingEventState, ActionBuildingEvent>>(
    buildingEventReducer,
    buildingEventInitialState
  );
  const {
    Address: { data: addresses, loading: addressLoading },
    Building: { data: buildingsLoader, loading: buildingsLoading, shouldFetch, lastFetch },
    BuildingOwner: { data: buildingOwners, loading: buildingOwnersLoading },
    Unit: { data: units, loading: unitsLoading },
    dispatch: contextDispatch,
  } = useContextLoader();
  const { financialValuationsAndCostsDelete, buildingsUnitsDetailsDelete } = usePermissions();
  const { clientId, isOwner, userId, groupIds: teamIds } = useUser();
  const { createAddress, updateAddress } = useAddresses();

  const loading =
    buildingsLoading || buildingEventsState.loading || buildingOwnersLoading || unitsLoading || addressLoading;

  useEffect(() => {
    const fetchAndSet = async () => {
      buildingEventsDispatch({ type: 'IS_FETCHING_BUILDING_EVENT' });
      const result = await fetchBuildingEvents(
        'byClientId',
        getTableClientId(clientId!, ENTITY_MODEL_NAME_BUILDING_EVENT)
      );
      buildingEventsDispatch({ type: 'FETCHED_BUILDING_EVENT', payload: { buildingEvents: result } });
    };
    if (buildingEventsState.shouldFetchBuildingEvent) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingEventsState.shouldFetchBuildingEvent]);

  useEffect(() => {
    const fetchAndSet = async () => {
      contextDispatch({ type: 'IS_FETCHING_BUILDING' });
      const result = await fetchBuildings(clientId!);
      contextDispatch({ type: 'FETCHED_BUILDING', payload: { buildings: result } });
    };
    if (shouldFetch) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch]);

  useSubscriptions<OnCreateBuildingSubscription, OnUpdateBuildingSubscription, OnDeleteBuildingSubscription>(
    onCreateBuilding,
    onUpdateBuilding,
    onDeleteBuilding,
    (data) => {
      contextDispatch({
        type: 'ADD_BUILDING',
        payload: { building: data.onCreateBuilding as Building },
      });
    },
    (data) => {
      contextDispatch({
        type: 'UPDATE_BUILDING',
        payload: { building: data.onUpdateBuilding as Building },
      });
    },
    (data) => {
      const { id } = data.onDeleteBuilding as Building;
      contextDispatch({
        type: 'DELETE_BUILDING',
        payload: { id },
      });
    }
  );
  useSubscriptions<
    OnCreateBuildingEventSubscription,
    OnUpdateBuildingEventSubscription,
    OnDeleteBuildingEventSubscription
  >(
    onCreateBuildingEvent,
    onUpdateBuildingEvent,
    onDeleteBuildingEvent,
    (data) => {
      buildingEventsDispatch({
        type: 'ADD_BUILDING_EVENT',
        payload: { buildingEvent: data.onCreateBuildingEvent as BuildingEvent },
      });
    },
    (data) => {
      buildingEventsDispatch({
        type: 'UPDATE_BUILDING_EVENT',
        payload: { buildingEvent: data.onUpdateBuildingEvent as BuildingEvent },
      });
    },
    (data) => {
      const { id } = data.onDeleteBuildingEvent as BuildingEvent;
      buildingEventsDispatch({
        type: 'DELETE_BUILDING_EVENT',
        payload: { id },
      });
    }
  );

  const buildings = useMemo<Building[]>(() => {
    if (loading) {
      return [];
    }

    const buildingsReduced = buildingsLoader.reduce((acc: Building[], building: Building) => {
      const ownerCanReadBuilding = isOwner && building.readers && building.readers.includes(userId!);
      const userInTeam = teamIds && building.teams && !isEmpty(intersection(teamIds, building.teams));
      if (userInTeam || ownerCanReadBuilding) {
        acc.push({
          ...building,
          address: building.addressId ? resolveOneToOne(building.addressId, addresses, 'id') : undefined,
          events: resolveOneToMany(building.id, buildingEventsState.buildingEvents ?? [], 'buildingId'),
          units: resolveOneToMany(building.id, units, 'building'),
          owners: resolveManyToMany(building, 'building', buildingOwners),
        });
      }
      return acc;
    }, []);
    return buildingsReduced;
  }, [
    buildingsLoader,
    units,
    buildingOwners,
    buildingEventsState.buildingEvents,
    isOwner,
    teamIds,
    userId,
    loading,
    addresses,
  ]);

  const setFetchBuildingEvents = () => {
    buildingEventsDispatch({ type: 'SHOULD_FETCH_BUILDING_EVENT' });
  };

  const setFetchBuildings = () => {
    contextDispatch({ type: 'SHOULD_FETCH_BUILDING' });
  };

  const getBuilding = (id: string) => {
    return buildings.find((b) => b.id === id);
  };

  const createBuilding = async (input: CreateBuilding): Promise<Building> => {
    const { address, owners: _owners, ...remainingInput } = input;

    if (address && address.street) {
      const newAddress = await createAddress({
        street: address.street,
        ...(!isEmpty(address.number) && { number: address.number }),
        ...(!isEmpty(address.box) && { box: address.box }),
        ...(!isEmpty(address.postalCode) && { postalCode: address.postalCode }),
        ...(!isEmpty(address.city) && { city: address.city }),
        ...(!isEmpty(address.country) && { country: address.country }),
      });
      remainingInput.addressId = newAddress.id;
    }

    const cleanedInput = cleanInputCreate(remainingInput) as CreateInput;
    const building = await mutation<Building, CreateMutationVariables>(createMutation, {
      input: {
        ...cleanedInput,
        clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME),
        readId: getReadId(clientId!, ENTITY_MODEL_NAME),
      },
    });
    contextDispatch({ type: 'ADD_BUILDING', payload: { building } });
    return building;
  };

  const getBuildingEvent = (id: string) => {
    if (isNil(buildingEventsState.buildingEvents)) {
      return [];
    }

    return buildingEventsState.buildingEvents.filter((be) => be.buildingId === id);
  };

  const updateBuilding = async (original: Building, updates: Partial<Building>) => {
    const result = await mutation<Building>(updateMutation, {
      input: { ...cleanInputUpdate({ id: original.id, _version: original._version, ...updates }, false) },
    });
    contextDispatch({ type: 'UPDATE_BUILDING', payload: { building: result } });
    return result;
  };

  const deleteBuilding = async (id: string) => {
    if (!buildingsUnitsDetailsDelete) {
      return;
    }

    await deleteEntityWithFetchBefore<Pick<Building, 'id'>, DeleteMutationVariables>({ id }, getQuery, deleteMutation);
    contextDispatch({ type: 'DELETE_BUILDING', payload: { id } });
  };

  const deepDeleteBuilding = async (id: string) => {
    if (!buildingsUnitsDetailsDelete) {
      return;
    }
    return await mutation<MutationStatus, DeleteEntityByIdMutationVariables>(deleteEntityByIdMutation, {
      input: { entityId: id, entity: DeepDeletableEntityType.BUILDING },
    });
  };

  const createBuildingEvent = async (
    input: Omit<BuildingEvent, 'id' | 'clientId' | 'readId'>
  ): Promise<BuildingEvent> => {
    const buildingEvent = await mutation<BuildingEvent, CreateBuildingEventMutationVariables>(
      createBuildingEventMutation,
      {
        input: {
          ...(cleanInputCreate(input) as CreateBuildingEventInput),
          clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME_BUILDING_EVENT),
          readId: getReadId(clientId!, ENTITY_MODEL_NAME_BUILDING_EVENT),
          ...(isOwner && { writers: [userId!] }),
        },
      }
    );
    buildingEventsDispatch({ type: 'ADD_BUILDING_EVENT', payload: { buildingEvent } });
    return buildingEvent;
  };

  const updateBuildingEvent = async (original: BuildingEvent, updates: Partial<BuildingEvent>) => {
    const result = await mutation<BuildingEvent>(updateBuildingEventMutation, {
      input: { ...cleanInputUpdate({ id: original.id, _version: original._version, ...updates }, false) },
    });
    buildingEventsDispatch({ type: 'UPDATE_BUILDING_EVENT', payload: { buildingEvent: result } });
    return result;
  };

  const deleteBuildingEvent = async (id: string) => {
    if (!financialValuationsAndCostsDelete) {
      return;
    }
    await deleteEntityWithFetchBefore<Pick<BuildingEvent, 'id'>, DeleteBuildingEventMutationVariables>(
      { id },
      getBuildingEventQuery,
      deleteBuildingEventMutation
    );
    buildingEventsDispatch({ type: 'DELETE_BUILDING_EVENT', payload: { id } });
  };

  const values = useMemo(
    () => ({
      buildings,
      buildingEvents: buildingEventsState.buildingEvents,
      getBuilding,
      createBuilding,
      updateBuilding,
      deleteBuilding,
      createBuildingEvent,
      getBuildingEvent,
      updateBuildingEvent,
      deleteBuildingEvent,
      deepDeleteBuilding,
      createAddress,
      updateAddress,
      buildingsError: undefined,
      buildingsLoading: loading,
      loading,
      shouldFetchBuilding: shouldFetch,
      shouldFetchBuildingEvent: buildingEventsState.shouldFetchBuildingEvent,
      lastFetch,
      setFetchBuildingEvents,
      setFetchBuildings,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buildings, buildingEventsState, loading, shouldFetch, lastFetch]
  );

  return <BuildingContext.Provider value={values}>{children}</BuildingContext.Provider>;
};

export const useBuildings = (): BuildingContext => {
  const firstRender = useRef<boolean>(false);
  const context = useContext<BuildingContext | null>(BuildingContext);

  if (isNil(context)) {
    throw new Error('`useBuildings` hook must be used within a `BuildingContextProvider` component');
  }

  useEffect(() => {
    if (!firstRender.current && isNil(context.buildingEvents)) {
      context.setFetchBuildingEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  return context as BuildingContext;
};
