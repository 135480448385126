/* eslint-disable @typescript-eslint/no-shadow */
import isNil from 'lodash/isNil';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import BarChart, { BarChartData } from '../../ui/Graphs/BarChart';
import {
  getOneYearFilter,
  getThisYearPlus6MonthsFilter,
  MetricBoxTimeFiltersProps,
} from './DashboardFilters/MetricBoxTimeFilter';
import { CustomFilterMenuBundle } from './DashboardMetrics';
import MetricBox, { MetricBoxLayoutProps } from './MetricBox';
import {
  areIntervalsOverlapping,
  compareAsc,
  differenceInMonths,
  eachMonthOfInterval,
  format,
  isAfter,
  isBefore,
  startOfMonth,
  isSameMonth,
} from 'date-fns';
import { LeaseStatus, LeaseStatus as LeaseStatusAPI, OCCUPANCY_BOX_KEY } from '@rentguru/commons-utils';
import { isEmpty } from 'lodash';
import { useDashboardFilters } from '../../../hooks/DashboardFiltersContext';
import { dateLocaleMap, dateLocaleMapType, useLocale } from 'src/i18n/IntlProviderWrapper';

const MAX_BAR_LABELS = 16;

interface DashboardMetricTicketsProps extends MetricBoxLayoutProps {
  openCustomFilterMenu: (bundle: CustomFilterMenuBundle) => void;
}

const DashboardMetricOccupancy: React.FC<DashboardMetricTicketsProps> = ({
  openCustomFilterMenu: openCustomFilterMenuFunction,
  forceUpdateLayout,
}) => {
  const { formatMessage } = useIntl();

  const timeFilters: MetricBoxTimeFiltersProps = {
    name: 'occupancy',
    filters: [getOneYearFilter(formatMessage), getThisYearPlus6MonthsFilter(formatMessage)],
    openCustomFilterMenu: (event?: React.MouseEvent<HTMLElement>) => {
      if (!isNil(event)) {
        openCustomFilterMenuFunction({ anchor: event.currentTarget, name: 'occupancy' });
      }
    },
  };

  return (
    <MetricBox
      boxKey={OCCUPANCY_BOX_KEY}
      title={formatMessage({ id: 'financial.occupancyRate' })}
      timeFilterBundle={timeFilters}
      forceUpdateLayout={forceUpdateLayout}
    >
      <MetricBoxContent />
    </MetricBox>
  );
};

const MetricBoxContent: React.FC = () => {
  const {
    filteredUnits,
    dashboardFilters: { occupancy: occupancyFilter },
  } = useDashboardFilters(); // temporal local filters
  const { language } = useLocale();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterStartDate = new Date(occupancyFilter.from);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterEndDate = new Date(occupancyFilter.to);

  const datas: BarChartData[] = useMemo(() => {
    const startOfThisMonth = startOfMonth(new Date());
    const filterMonthsRange = eachMonthOfInterval({
      start: filterStartDate,
      end: filterEndDate,
    });
    const differenceInMonthsValue = differenceInMonths(filterEndDate, filterStartDate);

    const emptyDatas: {
      [monthId: string]: { text: string; id: string; disabled: boolean; numberOfLeases: number; numberOfUnits: number };
    } = {};
    filterMonthsRange.forEach((month) => {
      const id = format(month, 'MMM-yyyy');
      const startOfCurrentMonth = startOfMonth(month);

      let legend = '';
      if (differenceInMonthsValue <= MAX_BAR_LABELS) {
        legend = format(startOfCurrentMonth, 'MMM', { locale: (dateLocaleMap as dateLocaleMapType)[language] });
      } else if (startOfCurrentMonth.getMonth() === 0) {
        legend = format(startOfCurrentMonth, 'yyyy', { locale: (dateLocaleMap as dateLocaleMapType)[language] });
      }

      emptyDatas[id] = {
        numberOfLeases: 0,
        numberOfUnits: 0,
        text: legend,
        id,
        disabled: isAfter(month, startOfThisMonth),
      };
    });

    const rawDatas = filteredUnits.reduce((datas, currentUnit) => {
      const leaseDuringMonths: Date[] = [];
      if (!isNil(currentUnit.leases) && !isEmpty(currentUnit.leases)) {
        currentUnit.leases.forEach((unitLease) => {
          if (
            !isNil(unitLease.lease) &&
            [LeaseStatus.Active, LeaseStatus.Ended, LeaseStatusAPI.Active, LeaseStatusAPI.Ended].includes(
              unitLease.lease.status as LeaseStatus
            )
          ) {
            const leaseStartDate = new Date(unitLease.lease.startDate);
            const leaseEndDate = new Date(unitLease.lease.endDate);
            // Is this lease during range?
            if (
              areIntervalsOverlapping(
                { start: filterStartDate, end: filterEndDate },
                { start: leaseStartDate, end: leaseEndDate },
                { inclusive: true }
              )
            ) {
              // If yes -> Add it to each months relevant during range
              const leaseMonthsRange = eachMonthOfInterval({
                start: compareAsc(leaseStartDate, filterStartDate) > 0 ? leaseStartDate : filterStartDate,
                end: compareAsc(leaseEndDate, filterEndDate) > 0 ? filterEndDate : leaseEndDate,
              });
              // Add based on this range
              leaseMonthsRange.forEach((month) => {
                if (leaseDuringMonths.findIndex((m) => isSameMonth(m, month)) === -1) {
                  leaseDuringMonths.push(month);
                }
              });
            }
          }
        });
      }
      // Check on each months (filters) if we have something to add
      filterMonthsRange.forEach((month) => {
        if (isBefore(new Date(currentUnit.createdAt!), month) || !isEmpty(leaseDuringMonths)) {
          // Add it to result
          const {
            numberOfLeases: oldNumberOfLease,
            numberOfUnits: oldNumberOfUnits,
            ...rest
          } = datas[format(month, 'MMM-yyyy')];
          const numberOfLeaseToAdd = leaseDuringMonths.findIndex((m) => isSameMonth(m, month)) !== -1 ? 1 : 0;
          datas[format(month, 'MMM-yyyy')] = {
            ...rest,
            numberOfLeases: oldNumberOfLease + numberOfLeaseToAdd,
            numberOfUnits: oldNumberOfUnits + 1,
          };
        }
      });

      return datas;
    }, emptyDatas);

    return Object.keys(rawDatas).map((month) => {
      const { text, id, disabled, numberOfLeases, numberOfUnits } = rawDatas[month];
      return { text, id, disabled, value: numberOfUnits === 0 ? 0 : Math.ceil((numberOfLeases / numberOfUnits) * 100) };
    });
  }, [filteredUnits, filterStartDate, filterEndDate, language]);

  const tooltipHtml = (data: BarChartData) => {
    return `${data.value}%<br />${data.id}`;
  };
  return (
    <BarChart
      datas={datas}
      yAxisOptions={{ labelFormat: '%', ticksViewable: 5, maxValue: 100 }}
      tooltipHtml={tooltipHtml}
    />
  );
};

export default DashboardMetricOccupancy;
