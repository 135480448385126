/* eslint-disable @typescript-eslint/no-shadow */
import { Chip, Grid, Typography } from '@material-ui/core';
import { Colors, CommunicationSettingRecipient, ContactType } from '@rentguru/commons-utils';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import FullCustomComboBox, { CustomInputItemType } from 'src/components/ui/ComboBox/FullCustomComboBox';
import { useCustomizedComboStyles } from 'src/components/ui/ComboBox/TextComboBox';
import { ReactComponent as ContactTypeIcon } from 'src/icons/fund.svg';

type ContactTypeComboItem = {
  value: string;
};

interface MultipleContactTypesSelectorProps {
  contactTypes: ContactType[] | CommunicationSettingRecipient[];
  selectedContactTypes?: ContactType[] | CommunicationSettingRecipient[];
  setValue: ((value: string[]) => void) | ((value: CommunicationSettingRecipient[]) => void);
  labelFormatMessageId: string;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  inputWidth?: number;
  paperClass?: string;
  disabled?: boolean;
  allContactTypesChip?: boolean;
  noContactTypesChip?: boolean;
}

const MultipleContactTypeSelector: React.FC<MultipleContactTypesSelectorProps> = ({
  contactTypes,
  selectedContactTypes = [],
  setValue,
  labelFormatMessageId,
  style,
  inputStyle,
  inputWidth = 560,
  allContactTypesChip = false,
  noContactTypesChip = false,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const comboBoxClasses = useCustomizedComboStyles();

  const inputStartAdornment =
    allContactTypesChip || noContactTypesChip
      ? (value: CustomInputItemType | CustomInputItemType[]) => {
          if (isEmpty(value)) {
            return (
              <Chip
                key="AllContactTypesChip"
                variant="outlined"
                label={formatMessage({
                  id: noContactTypesChip ? 'none' : 'communications.addCommunication.contactTypes.allContactTypes',
                })}
                style={{ marginLeft: 15, marginBottom: 10, marginTop: 10 }}
              />
            );
          }
        }
      : undefined;

  const contactTypesToComboBoxItems = (contactTypes: ContactType[] | CommunicationSettingRecipient[]) =>
    contactTypes.map(
      (contactType) =>
        ({
          value: contactType,
        } as ContactTypeComboItem)
    );

  const contactTypesComboBoxItems = contactTypesToComboBoxItems(selectedContactTypes);

  return (
    <Grid
      style={{
        alignItems: 'baseline',
        display: 'flex',
        ...style,
      }}
    >
      <FullCustomComboBox
        value={contactTypesComboBoxItems}
        label={formatMessage({ id: labelFormatMessageId })}
        options={contactTypesToComboBoxItems(contactTypes)}
        inputStyle={{ width: inputWidth, ...inputStyle }}
        onChange={(_event, values) => {
          const contactTypes = values?.map((value) => value.value) ?? [];
          setValue(contactTypes);
        }}
        inputStartAdornment={inputStartAdornment}
        renderOption={(inputItem: CustomInputItemType) => (
          <Grid container alignContent="flex-start" style={{ marginBottom: 5, marginTop: 5 }}>
            <Grid item xs={2}>
              <ContactTypeIcon
                style={{
                  width: 15,
                  height: 15,
                  marginRight: 10,
                  fill: Colors.SLATE_GREY,
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography className={comboBoxClasses.primaryText}>
                {formatMessage({ id: `communications.addCommunication.contactTypes.${inputItem.value}` })}
              </Typography>
            </Grid>
          </Grid>
        )}
        renderInput={(value: CustomInputItemType) => (
          <Typography style={{ fontSize: 12 }}>
            {formatMessage({ id: `communications.addCommunication.contactTypes.${value.value}` })}
          </Typography>
        )}
        renderTags={(value: CustomInputItemType[], getTagProps) =>
          value.map((option: CustomInputItemType, index: number) => (
            <Chip
              key={option.value}
              variant="outlined"
              label={formatMessage({ id: `communications.addCommunication.contactTypes.${option.value}` })}
              {...getTagProps({ index })}
              style={{
                marginLeft: 15,
                marginBottom: 10,
                marginTop: 10,
              }}
            />
          ))
        }
        disableClearable={false}
        clearOnEscape
        disableCloseOnSelect
        {...rest}
      />
    </Grid>
  );
};

export default MultipleContactTypeSelector;
