import React, { useContext, useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import {
  flattenMessages,
  MessagesEN as enLocale,
  MessagesFR as frLocale,
  MessagesNL as nlLocale,
  frCountriesLocale,
  enCountriesLocale,
  nlCountriesLocale,
  deCountriesLocale,
  CUSTOM_ATTRIBUTE_LANGUAGE,
} from '@rentguru/commons-utils';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import frDateLocale from 'date-fns/locale/fr';
import nlDateLocale from 'date-fns/locale/nl';
import enDateLocale from 'date-fns/locale/en-US';
import { useUser, initLanguage } from '../hooks/UserContext';
import { isNil } from 'lodash';

export interface ILocaleContext {
  language: string;
  country: string;
  setCountry: (country: string) => void;
  setLanguage: (language: string) => void;
  updateLanguage: (lang: string) => Promise<void>;
  updateCountry: (country: string) => void;
  langAndCountryFormated: string;
}
export const LocaleContext = React.createContext<ILocaleContext | null>(null);
export const dateLocaleMap: dateLocaleMapType = {
  en: enDateLocale,
  fr: frDateLocale,
  sv: enDateLocale,
  nl: nlDateLocale,
  da: enDateLocale,
  sk: enDateLocale,
  de: enDateLocale,
};

export interface dateLocaleMapType {
  // eslint-disable-next-line no-undef
  [index: string]: Locale;
}

const countriesLocaleMap: Record<string, object> = {
  en: enCountriesLocale,
  fr: frCountriesLocale,
  nl: nlCountriesLocale,
  de: deCountriesLocale,
};
const legalCountryCode: string[] = ['FR', 'DE', 'SE', 'DK', 'BE', 'NL', 'SK'];
export const providerLocaleMap: Record<string, object> = {
  en: enLocale,
  fr: frLocale,
  sv: enLocale,
  nl: nlLocale,
  da: enLocale,
  sk: enLocale,
  de: enLocale,
};

export const LocaleProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const initCountry = '';
  /* Update by getting from cognito */
  const { language: cognitoLanguage, setUserAttributes } = useUser();
  const userLanguage = !isNil(cognitoLanguage) ? cognitoLanguage : initLanguage;
  const [language, setLanguage] = useState<string>(userLanguage);
  const [country, setCountry] = useState<string>(initCountry);

  useEffect(() => {
    setLanguage(userLanguage);
  }, [userLanguage]);

  const updateLanguage = async (lang: string) => {
    if (lang in providerLocaleMap) {
      await setUserAttributes({ [`${CUSTOM_ATTRIBUTE_LANGUAGE}`]: lang });
      setLanguage(lang);
    }
  };
  const updateCountry = (newCountry: string) => {
    if (legalCountryCode.includes(newCountry)) {
      setCountry(newCountry);
    }
  };
  const langAndCountryFormated = country !== '' ? `${language}-${country}` : language;
  const values = React.useMemo(
    () => ({
      language,
      setLanguage,
      country,
      setCountry,
      updateLanguage,
      updateCountry,
      langAndCountryFormated,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language, country, langAndCountryFormated]
  );
  return (
    <LocaleContext.Provider value={values}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={dateLocaleMap[language]}>
        {/* @ts-ignore */}
        <IntlProvider
          key={language}
          locale={language}
          messages={{
            ...flattenMessages(providerLocaleMap[language]),
            ...flattenMessages(countriesLocaleMap[language]),
          }}
          defaultLocale={language}
        >
          {children}
        </IntlProvider>
      </MuiPickersUtilsProvider>
    </LocaleContext.Provider>
  );
};
export const useLocale = (): ILocaleContext => {
  const context = useContext<ILocaleContext | null>(LocaleContext);
  if (context === undefined) {
    throw new Error('`useUser` hook must be used within a `UserProvider` component');
  }
  return context as ILocaleContext;
};
