import React from 'react';
import * as Yup from 'yup';
import { FormikHelpers, Formik, Form } from 'formik';
import isNil from 'lodash/isNil';
import { useIntl } from 'react-intl';
import { Divider } from '@material-ui/core';
import { ContentHeader, LoaderButton , ActionButton } from '@up2rent/ui';
import toUpper from 'lodash/toUpper';
import { useUserRoles } from '../../../hooks/UserRolesContext';
import TextDetailEditable from 'src/components/ui/TextDetailEditable';
import { Colors } from '@rentguru/commons-utils';

interface EditRoleNameFormValues {
  name: string;
}

const EditRoleNameSchema = Yup.object()
  .shape({
    name: Yup.string().required(),
  })
  .required();

interface EditRoleProps {
  afterSubmit?: () => void;
  id: string;
}

const EditRole: React.FC<EditRoleProps> = ({ afterSubmit, id }) => {
  const { formatMessage } = useIntl();
  const { getUserRole, updateUserRole } = useUserRoles();

  const original = getUserRole(id);
  if (isNil(original)) {
    return null;
  }
  const handleUpdate = async (
    values: EditRoleNameFormValues,
    { setSubmitting, setStatus }: FormikHelpers<EditRoleNameFormValues>
  ) => {
    await updateUserRole(original!, { name: values.name });

    setStatus(true);
    setSubmitting(false);
    if (!isNil(afterSubmit)) {
      afterSubmit();
    }
  };

  const initialValues: EditRoleNameFormValues = {
    name: original.name,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={EditRoleNameSchema}
      onSubmit={handleUpdate}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ errors, touched, status, isSubmitting }) => (
        <Form>
          <ContentHeader title={formatMessage({ id: 'settings.roles.editProfile' })} />
          <div style={{ marginLeft: 30, marginRight: 30 }}>
            <div style={{ alignItems: 'baseline' }}>
              <TextDetailEditable
                title={formatMessage({ id: 'settings.addTeam.name' })}
                editMode={true}
                name="name"
                type="text"
                style={{ width: 280, maxWidth: 'none' }}
                error={Boolean(errors.name && touched.name)}
              />
            </div>
          </div>
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          <div
            style={{
              marginRight: 30,
              marginBottom: 20,
              display: 'flex',
              float: 'right',
              alignItems: 'center',
            }}
          >
            <ActionButton
              onClick={() => {
                if (!isNil(afterSubmit)) {
                  afterSubmit();
                }
              }}
              style={{
                background: 'none',
                color: Colors.DARK_SLATE_GREY,
                marginRight: 20,
              }}
            >
              {toUpper(formatMessage({ id: 'cancel' }))}
            </ActionButton>
            <LoaderButton loading={isSubmitting} success={status}>
              {formatMessage({ id: 'save' })}
            </LoaderButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditRole;
