import { isNilOrEmpty, DEFAULT_LANGUAGE, IntegrationStatus, IntegrationName } from '@rentguru/commons-utils';
import { useEffect, useState } from 'react';
import { useIntegrations } from 'src/hooks/IntegrationsContext';
import { useUser } from 'src/hooks/UserContext';
import { v4 as uuidV4 } from 'uuid';
import { generateCodeChallenge, generateRandomString } from './cryptoUtils';
import { getCacheItem, removeCacheItem, setCacheItem } from 'src/utils/cacheUtils';

const PONTO_CLIENT_ID_PROD = '8002ed3d-fa5e-4095-a7d7-b990992768ea';
const PONTO_CLIENT_ID_DEV = '891c25d8-b91d-41bd-8c74-a6f04eb065b5';
export interface PontoCallbackLocation {
  pontoCode: string | null;
  pontoState: string | null;
}

const generateAndSaveCodeVerifier = async () => {
  const codeVerifierInCache = (await getCacheItem('codeVerifier')) as string;
  if (!isNilOrEmpty(codeVerifierInCache)) {
    return codeVerifierInCache;
  }
  const codeVerifier = generateRandomString(96);
  await setCacheItem('codeVerifier', codeVerifier);
  return codeVerifier;
};

const getPontoConnectRedirectUrl = async (clientId: string, language: string) => {
  const { protocol, hostname, host } = window.location;

  const isProd = ['app.up2rent.com', 'demo.up2rent.com'].includes(hostname);
  const pontoClientId = isProd ? PONTO_CLIENT_ID_PROD : PONTO_CLIENT_ID_DEV;
  const baseUrl = isProd ? 'authorization' : 'sandbox-authorization';
  const redirectUrl = `${protocol}//${host}/pontoConnect`;
  const state = clientId.length < 8 ? uuidV4() : clientId;
  // Hash and Base64URL encode the codeVerifier, used as the 'codeChallenge'
  const codeVerifier = await generateAndSaveCodeVerifier();
  const codeChallenge = await generateCodeChallenge(codeVerifier);

  // eslint-disable-next-line max-len
  return `https://${baseUrl}.myponto.com/oauth2/auth?client_id=${pontoClientId}&redirect_uri=${redirectUrl}&response_type=code&scope=ai%20offline_access&state=${state}&code_challenge=${codeChallenge}&code_challenge_method=S256&language=${language}`;
};

export const usePontoConnect = () => {
  const { clientId, language } = useUser();
  const {
    verifyPontoIntegration,
    revokePontoIntegration: revokePontoIntegrationFromContext,
    integrations,
    loading: integrationsLoading,
    fetchAndSetIntegrations,
  } = useIntegrations();
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const pontoIntegration = integrations?.find(
    (integration) => integration.name === IntegrationName.PONTO && integration.status === IntegrationStatus.ACTIVE
  );

  useEffect(() => {
    const verifyPonto = async (pontoCallBack: PontoCallbackLocation) => {
      const codeVerifier = (await getCacheItem('codeVerifier')) as string;
      await verifyPontoIntegration({ authorizationCode: pontoCallBack.pontoCode ?? '', codeVerifier });
      await fetchAndSetIntegrations();
      await removeCacheItem('pontoCallback');
      await removeCacheItem('codeVerifier');
      setLoading(false);
    };

    const handlePontoCallBack = async () => {
      const pontoCallBack: PontoCallbackLocation = (await getCacheItem('pontoCallback')) as PontoCallbackLocation;

      if (pontoCallBack?.pontoCode) {
        setLoading(true);
        verifyPonto(pontoCallBack);
      }
    };

    handlePontoCallBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let unmounted = false;
    const generateRedirectUrl = async () => {
      if (!isNilOrEmpty(clientId) && language) {
        const url = await getPontoConnectRedirectUrl(clientId ?? '', language ?? DEFAULT_LANGUAGE);
        if (!unmounted) {
          setRedirectUrl(url);
        }
      }
    };
    generateRedirectUrl();

    return () => {
      unmounted = true;
    };
  }, [clientId, language]);

  const integrationLoading = loading || integrationsLoading || (pontoIntegration && !pontoIntegration.lastRun);

  const revokePontoIntegration = async () => {
    setLoading(true);
    await revokePontoIntegrationFromContext();
    await fetchAndSetIntegrations();
    setLoading(false);
  };

  return { url: redirectUrl, loading: integrationLoading, integration: pontoIntegration, revokePontoIntegration };
};
