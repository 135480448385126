import React from 'react';

interface ConditionalParentElementProps {
  parentElement?: boolean;
  // eslint-disable-next-line no-undef
  wrapper: (child: React.ReactNode) => JSX.Element;
  children?: React.ReactNode;
}

const ConditionalParentElement: React.FC<ConditionalParentElementProps> = ({
  parentElement = true,
  wrapper,
  children,
}) => {
  if (parentElement) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return wrapper(children) as any;
  }
  return children;
};

export default ConditionalParentElement;
