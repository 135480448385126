import { CommunicationChannel, CommunicationSendingRule, CommunicationSettingRecipient } from '@rentguru/commons-utils';
import * as Yup from 'yup';

export type BaseCommunicationSettingsFormValue = {
  recipients: CommunicationSettingRecipient[];
  recipientsInBcc: CommunicationSettingRecipient[];
  recipientsInCopy: string[];
  sendingRule: CommunicationSendingRule;
  channel: CommunicationChannel;
};

export const BasicSettingsSchema = Yup.object().shape({
  channel: Yup.string().min(1).required(),
  sendingRule: Yup.string().min(1).required(),
});
