import { Typography } from '@material-ui/core';
import { MessageDescriptor } from 'react-intl';

export type HelperType =
  | 'title'
  | 'grossPurchasedPrice'
  | 'netPurchasedPrice'
  | 'propertyValue'
  | 'totalUnits'
  | 'rentalYield'
  | 'dayToLease'
  | 'averageLease';

export const getKeyFigureHelperText = (
  type: HelperType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatMessage: (descriptor: MessageDescriptor, values?: any) => string
) => {
  switch (type) {
    case 'title':
      return (
        <>
          <Typography display="inline">{`${formatMessage({ id: 'thePlural' })} `}</Typography>
          <Typography display="inline" style={{ fontWeight: 'bold', marginTop: 5 }}>
            {formatMessage({ id: 'menu.buildings' }, { value: 2 }).toLowerCase()}
          </Typography>
          <Typography display="inline">{` ${formatMessage({ id: 'dashboard.keyFigures.globalHelper2' })}`}</Typography>
        </>
      );
    case 'grossPurchasedPrice':
      return (
        <>
          <Typography display="inline">{`${formatMessage({
            id: 'dashboard.keyFigures.grossPurchasedPrice',
          })} `}</Typography>
          <Typography display="inline">{`${formatMessage({
            id: 'dashboard.keyFigures.purchasedPriceHelper1',
          })} `}</Typography>
          <Typography display="inline" style={{ fontWeight: 700 }}>
            {formatMessage({ id: 'dashboard.keyFigures.purchasePrice' })}
          </Typography>
          <Typography display="inline">{` ${formatMessage({
            id: 'dashboard.keyFigures.purchasedPriceHelper2',
          })} `}</Typography>
          <Typography display="inline" style={{ fontWeight: 700 }}>
            {`${formatMessage({
              id: 'valuation.acquisitionAdditionalCosts',
            }).toLowerCase()}`}
          </Typography>
          <Typography display="inline">{` ${formatMessage({
            id: 'dashboard.keyFigures.purchasedPriceHelper3',
          })}`}</Typography>
        </>
      );
    case 'netPurchasedPrice':
      return (
        <>
          <Typography display="inline">{`${formatMessage({
            id: 'dashboard.keyFigures.netPurchasedPrice',
          })} `}</Typography>
          <Typography display="inline">{`${formatMessage({
            id: 'dashboard.keyFigures.purchasedPriceHelper1',
          })} `}</Typography>
          <Typography display="inline" style={{ fontWeight: 700 }}>
            {formatMessage({ id: 'dashboard.keyFigures.purchasePrice' })}
          </Typography>
          <Typography display="inline">{` ${formatMessage({
            id: 'dashboard.keyFigures.purchasedPriceHelper3',
          })}`}</Typography>
        </>
      );
    case 'propertyValue':
      return (
        <>
          <Typography display="inline">{`${formatMessage({
            id: 'dashboard.keyFigures.propertyValue',
          })} `}</Typography>
          <Typography display="inline">{`${formatMessage({
            id: 'dashboard.keyFigures.propertyValueHelper1',
          })} `}</Typography>
          <Typography display="inline" style={{ fontWeight: 700 }}>
            {`${formatMessage({
              id: 'valuation.valuations',
            }).toLowerCase()}`}
          </Typography>
          <Typography display="inline">{` ${formatMessage({
            id: 'dashboard.keyFigures.purchasedPriceHelper3',
          })}`}</Typography>
          <Typography display="inline" style={{ marginTop: 5 }}>
            {` ${formatMessage({
              id: 'dashboard.keyFigures.propertyValueHelper2',
            })} `}
          </Typography>
          <Typography display="inline" style={{ fontWeight: 700 }}>
            {`${formatMessage({
              id: 'dashboard.keyFigures.purchasePrice',
            })} `}
          </Typography>
          <Typography display="inline">{` ${formatMessage({
            id: 'dashboard.keyFigures.propertyValueHelper3',
          })}`}</Typography>
        </>
      );
    case 'averageLease':
      return (
        <>
          <Typography display="inline">{`${formatMessage({
            id: 'dashboard.keyFigures.vacancyHelper1',
          })} `}</Typography>
          <Typography display="inline" style={{ fontWeight: 700 }}>
            {`${formatMessage({
              id: 'dashboard.keyFigures.vacant',
            })}`}
          </Typography>
          <Typography display="inline">{` ${formatMessage({
            id: 'dashboard.keyFigures.vacancyHelper2',
          })}`}</Typography>
          <Typography style={{ marginTop: 5 }}>
            {`${formatMessage({
              id: 'dashboard.keyFigures.vacancyHelper3',
            })}`}
          </Typography>
        </>
      );
    case 'rentalYield':
      return (
        <>
          <Typography display="inline">{`${formatMessage({
            id: 'dashboard.keyFigures.rentalYield',
          })} `}</Typography>
          <Typography display="inline">{`${formatMessage({
            id: 'dashboard.keyFigures.rentalYieldHelper1',
          })} `}</Typography>
          <Typography style={{ fontWeight: 700 }} display="inline">
            {`${formatMessage({
              id: 'dashboard.keyFigures.expectedRentalIncome',
            }).toLowerCase()}`}
          </Typography>
          <Typography display="inline">{` ${formatMessage({
            id: 'dashboard.keyFigures.rentalYieldHelper2',
          })} `}</Typography>
          <Typography display="inline" style={{ fontWeight: 700 }}>
            {`${formatMessage({
              id: 'dashboard.keyFigures.propertyValue',
            })}`}
          </Typography>
          <Typography display="inline">{` ${formatMessage({
            id: 'dashboard.keyFigures.rentalYieldHelper3',
          })}`}</Typography>
          <Typography style={{ marginTop: 5 }}>
            {`${formatMessage({
              id: 'dashboard.keyFigures.rentalYieldHelper4',
            })}`}
          </Typography>
          <Typography>
            {`${formatMessage({
              id: 'dashboard.keyFigures.rentalYieldHelper5',
            })}`}
          </Typography>
        </>
      );
    default:
      return null;
  }
};
