export const querystring = (name: string, url = window.location.href, removePlus: boolean = true): null | string => {
  name = name.replace(/[[]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`, 'i');
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(removePlus ? results[2].replace(/\+/g, ' ') : results[2]);
};
