/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-redeclare */
import {
  BankAccount,
  Building,
  COMMUNICATION_TABLE_NAME,
  Communication,
  Contact,
  ContactType,
  CreateTicketInput,
  CreateTicketMutationVariables,
  DOCUMENT_CATEGORY_TABLE_NAME,
  DocumentCategory,
  DocumentType,
  File as FileModel,
  Invoice,
  InvoiceWithPostings,
  Lease,
  LeaseAmountUpdateStatus,
  LeaseContact,
  LeaseExtended as LeaseExtendedFromUtils,
  LeaseInventory,
  LeasePriceHistory,
  LeaseStatus,
  LeaseVariousOperation,
  LooseObject,
  MutationStatus,
  OnUpdateLeaseContactSubscription,
  OnUpdateLeaseSubscription,
  OnUpdateTicketSubscription,
  OnUpdateUserSubscription,
  Posting,
  RejectDocumentMutationVariables,
  SignatureDocument,
  Technic,
  Ticket,
  Transaction,
  TransactionLinkType,
  TransactionStatus,
  Unit,
  UnitInventory,
  UnitLease,
  UnitOwner,
  User,
  cleanInputCreate,
  getReadId,
  getTableClientId,
  onUpdateUser,
  rejectDocument as rejectDocumentMutation,
  syncBankAccounts,
  syncBuildings,
  syncDocumentCategories,
  syncUnitInventories,
  syncUnitOwners,
} from '@rentguru/commons-utils';
import { get, list, mutation, useSubscriptions } from '@up2rent/fetch-utils';
import { isAfter } from 'date-fns';
import { isEmpty } from 'lodash';
import isNil from 'lodash/isNil';
import React, { Reducer, useContext, useEffect, useMemo, useReducer } from 'react';
import { useUser } from '../hooks/UserContext';
import {
  createTicket as createTicketMutation,
  getContact,
  getUser,
  onUpdateLease,
  onUpdateLeaseContact,
  onUpdateTicket,
  syncCommunications,
  syncInvoices,
  syncLeasePriceHistories,
  syncLeaseVariousOperations,
  syncLeases,
  syncPostings,
  syncSignatureDocuments,
  syncTechnics,
  syncTickets,
  syncTransactions,
} from './graphqlHelper';

export interface LeaseExtended extends Lease {
  _version: number;
  _deleted: boolean;
  technics: Technic[];
  leaseInventories: LeaseInventory[];
}

interface TruncatedLease extends Omit<LeaseExtended, 'units' | 'contacts' | 'files' | 'leaseInventories'> {
  units: { items: UnitLease[] };
  contacts: { items: LeaseContact[] };
  files: { items: FileModel[] };
  leaseInventories: { items: LeaseInventory[] };
}

export interface TicketExtended extends Ticket {
  _version: number;
  _deleted: boolean;
}

interface TruncatedTicket extends Omit<TicketExtended, 'conversations'> {
  conversations: { items: Ticket[] };
}

export interface TechnicExtended extends Technic {
  files: FileModel[];
  _deleted: boolean;
}

export interface TruncatedTransaction extends Omit<Transaction, 'postings'> {
  postings: { items: Posting[] };
}

enum TenantReducerAction {
  SET_TENANT = 'setTenant',
  SET_TENANT_INVOICES = 'setTenantInvoices',
  SET_TENANT_LEASES = 'setTenantLeases',
  SET_TENANT_TICKETS = 'setTenantTickets',
  SET_TENANT_TECHNICS = 'setTenantTechnics',
  SET_TENANT_TRANSACTIONS = 'setTenantTransactions',
  SET_TENANT_SIGNATURE_DOCUMENTS = 'setTenantSignatureDocuments',
  SET_TENANT_LEASE_PRICE_HISTORIES = 'setTenantLeasePriceHistories',
  SET_TENANT_LOADING = 'setTenantLoading',
  SET_TENANT_ERROR = 'setTenantError',
  SET_TENANT_BANK_ACCOUNTS = 'setTenantBankAccounts',
  SET_TENANT_LEASE_VARIOUS_OPERATIONS = 'setTenantLeaseVariousOperations',
  SET_TENANT_UNIT_INVENTORIES = 'setTenantUnitInventories',
  SET_TENANT_UNIT_OWNERS = 'setTenantUnitOwners',
  SET_TENANT_BUILDINGS = 'setTenantBuildings',
  SET_TENANT_COMMUNICATIONS = 'setTenantCommunications',
  SET_TENANT_DOCUMENT_CATEGORIES = 'setTenantDocumentCategories',
  SET_TENANT_VALUES = 'setTenantValues',
  SET_TENANT_FILES = 'setTenantFiles',
}

export interface TenantContext {
  tenant: Contact | null;
  tenantUser: User | null;
  tenantInvoices: InvoiceWithPostings[];
  tenantLeases: LeaseExtendedFromUtils[];
  tenantTickets: Ticket[];
  tenantTechnics: TechnicExtended[];
  tenantTransactions: Transaction[];
  tenantSignatureDocuments: SignatureDocument[];
  tenantsLoading: boolean | undefined;
  tenantsError: string | undefined;
  tenantsBankAccounts: BankAccount[];
  tenantLeaseVariousOperations: LeaseVariousOperation[];
  tenantsUnits: Unit[];
  tenantUnitInventories: UnitInventory[];
  tenantUnitOwners: UnitOwner[];
  tenantsBuildings: Building[];
  tenantsCommunications: Communication[];
  tenantsDocumentCategories: DocumentCategory[];
  getTicket: (id: string) => Ticket | undefined;
  createTicket: (input: Omit<Ticket, 'clientId' | 'readId'>, refetch: boolean | undefined) => Promise<Ticket>;
  rejectDocument: (id: string, contactId: string, type: DocumentType, rejectReason: string) => Promise<MutationStatus>;
  getLeasePriceHistoriesOfLease: (leaseId: string) => LeasePriceHistory[];
  getLease: (leaseId: string) => LeaseExtendedFromUtils | undefined;
  getUnit: (unitId: string) => Unit | undefined;
  getInvoicesOfLease: (leaseId: string) => InvoiceWithPostings[];
  getTransactionsForLease: (leaseId: string, filterIgnoredTransaction?: boolean) => Transaction[];
  getLeaseVariousOperationsFromLease: (leaseId: string) => LeaseVariousOperation[] | null;
  getUnitInventoriesFor: (unitId: string, date?: Date) => UnitInventory[];
  getBuilding: (id: string) => undefined;
  getUnitTickets: (id: string) => Ticket[];
  getUnitInventory: (id: string) => UnitInventory | undefined;
  getTechnicsFor: (unitId: string) => Technic[];
  getLeaseTickets: (leaseId: string) => Ticket[];
}

export const TenantContext = React.createContext<TenantContext | null>(null);

const fetchTenant = async (tenantId: string): Promise<{ tenant?: Contact; error?: string }> => {
  try {
    const tenant = await get<Contact>(getContact, tenantId);
    return { tenant };
  } catch (err) {
    return { error: err as string as string };
  }
};

export const fetchUser = async (userId?: string | null): Promise<{ tenant?: User; error?: string }> => {
  if (isNil(userId)) {
    return { tenant: undefined };
  }
  try {
    const tenant = await get<User>(getUser, userId);
    return { tenant };
  } catch (err) {
    return { error: err as string as string };
  }
};

const getContactsFromLease = (lease: TruncatedLease) => {
  return lease.contacts.items.reduce(
    (acc, currentContact) => {
      if (typeof currentContact.contact === 'undefined') {
        return acc;
      }
      if (currentContact.contactRole === ContactType.TENANT) {
        acc[0].push(currentContact.contact!);
      } else if (
        currentContact.contactRole === ContactType.OWNER ||
        currentContact.contactRole === ContactType.MEMBER
      ) {
        acc[1].push(currentContact.contact!);
      } else if (currentContact.contactRole === ContactType.GUARANTOR) {
        acc[2].push(currentContact.contact!);
      }
      return acc;
    },
    [[], [], []] as [Contact[], Contact[], Contact[]]
  );
};

interface TenantContextState {
  tenant: Contact | null;
  tenantUser: User | null;
  tenantInvoices: InvoiceWithPostings[];
  tenantLeases: LeaseExtendedFromUtils[];
  tenantTickets: Ticket[];
  tenantTechnics: TechnicExtended[];
  tenantTransactions: Transaction[];
  tenantSignatureDocuments: SignatureDocument[];
  tenantLeasePriceHistories: LeasePriceHistory[];
  tenantsLoading: boolean | undefined;
  tenantsError: string | undefined;
  tenantsBankAccounts: BankAccount[];
  tenantLeaseVariousOperations: LeaseVariousOperation[];
  tenantUnitInventories: UnitInventory[];
  tenantUnitOwners: UnitOwner[];
  tenantsBuildings: Building[];
  tenantsCommunications: Communication[];
  tenantsDocumentCategories: DocumentCategory[];
}

type Action =
  | {
      type: TenantReducerAction.SET_TENANT;
      payload: Contact | null;
    }
  | {
      type: TenantReducerAction.SET_TENANT_INVOICES;
      payload: InvoiceWithPostings[];
    }
  | {
      type: TenantReducerAction.SET_TENANT_LEASES;
      payload: LeaseExtendedFromUtils[];
    }
  | {
      type: TenantReducerAction.SET_TENANT_TICKETS;
      payload: Ticket[];
    }
  | {
      type: TenantReducerAction.SET_TENANT_TECHNICS;
      payload: TechnicExtended[];
    }
  | {
      type: TenantReducerAction.SET_TENANT_TRANSACTIONS;
      payload: Transaction[];
    }
  | {
      type: TenantReducerAction.SET_TENANT_SIGNATURE_DOCUMENTS;
      payload: SignatureDocument[];
    }
  | {
      type: TenantReducerAction.SET_TENANT_LEASE_PRICE_HISTORIES;
      payload: LeasePriceHistory[];
    }
  | {
      type: TenantReducerAction.SET_TENANT_LOADING;
      payload: boolean | undefined;
    }
  | {
      type: TenantReducerAction.SET_TENANT_ERROR;
      payload: string | undefined;
    }
  | {
      type: TenantReducerAction.SET_TENANT_BANK_ACCOUNTS;
      payload: BankAccount[];
    }
  | {
      type: TenantReducerAction.SET_TENANT_LEASE_VARIOUS_OPERATIONS;
      payload: LeaseVariousOperation[];
    }
  | {
      type: TenantReducerAction.SET_TENANT_UNIT_INVENTORIES;
      payload: UnitInventory[];
    }
  | {
      type: TenantReducerAction.SET_TENANT_UNIT_OWNERS;
      payload: UnitOwner[];
    }
  | {
      type: TenantReducerAction.SET_TENANT_BUILDINGS;
      payload: Building[];
    }
  | {
      type: TenantReducerAction.SET_TENANT_COMMUNICATIONS;
      payload: Communication[];
    }
  | {
      type: TenantReducerAction.SET_TENANT_DOCUMENT_CATEGORIES;
      payload: DocumentCategory[];
    }
  | {
      type: TenantReducerAction.SET_TENANT_VALUES;
      payload: Partial<TenantContextState>;
    };

const TenantReducer = (state: TenantContextState, action: Action): TenantContextState => {
  switch (action.type) {
    case TenantReducerAction.SET_TENANT:
      return {
        ...state,
        tenant: action.payload,
      };
    case TenantReducerAction.SET_TENANT_INVOICES:
      return {
        ...state,
        tenantInvoices: action.payload,
      };
    case TenantReducerAction.SET_TENANT_LEASES:
      return {
        ...state,
        tenantLeases: action.payload,
      };
    case TenantReducerAction.SET_TENANT_TICKETS:
      return {
        ...state,
        tenantTickets: action.payload,
      };
    case TenantReducerAction.SET_TENANT_TECHNICS:
      return {
        ...state,
        tenantTechnics: action.payload,
      };
    case TenantReducerAction.SET_TENANT_TRANSACTIONS:
      return {
        ...state,
        tenantTransactions: action.payload,
      };
    case TenantReducerAction.SET_TENANT_SIGNATURE_DOCUMENTS:
      return {
        ...state,
        tenantSignatureDocuments: action.payload,
      };
    case TenantReducerAction.SET_TENANT_LEASE_PRICE_HISTORIES:
      return {
        ...state,
        tenantLeasePriceHistories: action.payload,
      };
    case TenantReducerAction.SET_TENANT_LOADING:
      return {
        ...state,
        tenantsLoading: action.payload,
      };
    case TenantReducerAction.SET_TENANT_ERROR:
      return {
        ...state,
        tenantsError: action.payload,
      };
    case TenantReducerAction.SET_TENANT_BANK_ACCOUNTS:
      return {
        ...state,
        tenantsBankAccounts: action.payload,
      };
    case TenantReducerAction.SET_TENANT_LEASE_VARIOUS_OPERATIONS:
      return {
        ...state,
        tenantLeaseVariousOperations: action.payload,
      };
    case TenantReducerAction.SET_TENANT_UNIT_INVENTORIES:
      return {
        ...state,
        tenantUnitInventories: action.payload,
      };
    case TenantReducerAction.SET_TENANT_UNIT_OWNERS:
      return {
        ...state,
        tenantUnitOwners: action.payload,
      };
    case TenantReducerAction.SET_TENANT_BUILDINGS:
      return {
        ...state,
        tenantsBuildings: action.payload,
      };
    case TenantReducerAction.SET_TENANT_COMMUNICATIONS:
      return {
        ...state,
        tenantsCommunications: action.payload,
      };
    case TenantReducerAction.SET_TENANT_DOCUMENT_CATEGORIES:
      return {
        ...state,
        tenantsDocumentCategories: action.payload,
      };
    case TenantReducerAction.SET_TENANT_VALUES:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

const initialState = {
  tenant: null,
  tenantUser: null,
  tenantInvoices: [],
  tenantLeases: [],
  tenantTickets: [],
  tenantTechnics: [],
  tenantTransactions: [],
  tenantSignatureDocuments: [],
  tenantLeasePriceHistories: [],
  tenantsLoading: true,
  tenantsError: undefined,
  tenantsBankAccounts: [],
  tenantLeaseVariousOperations: [],
  tenantUnitInventories: [],
  tenantUnitOwners: [],
  tenantsBuildings: [],
  tenantsCommunications: [],
  tenantsDocumentCategories: [],
};

export const TenantContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<TenantContextState, Action>>(TenantReducer, initialState);
  const { tenantId, clientId, userId } = useUser();

  const tenantsUnits = state.tenantLeases.map((lease) => (lease.units ?? []).map((unit) => unit.unit!)).flat();

  const getUnit = (unitId: string) => {
    return tenantsUnits.find((unit) => unit!.id === unitId);
  };

  const getTransactionsForLease = (leaseId: string, filterIgnoredTransaction = false): Transaction[] => {
    if (state.tenantsLoading || state.tenantsError) {
      return [];
    }
    return (state.tenantTransactions ?? []).filter((t) => {
      const ignore = filterIgnoredTransaction && t.status === TransactionStatus.IGNORE;
      return (
        !ignore &&
        t.links &&
        t.links.some((link) => link.linkType === TransactionLinkType.LEASE && link.linkId === leaseId)
      );
    });
  };

  const getUnitInventory = (id: string) => {
    return state.tenantUnitInventories.find((unitInventory) => unitInventory.id === id);
  };

  const fetchLeaseVariousOperations = async (): Promise<{
    leaseVariousOperations: LeaseVariousOperation[];
    error?: string;
  }> => {
    try {
      const leaseVariousOperations = await list<LeaseVariousOperation>(
        syncLeaseVariousOperations,
        'clientId',
        getTableClientId(clientId!, 'LeaseVariousOperation')
      );
      return { leaseVariousOperations };
    } catch (error) {
      return { leaseVariousOperations: [], error: error as string };
    }
  };

  const fetchTenantCommunications = async (): Promise<{
    communications: Communication[];
    error?: string;
  }> => {
    try {
      const communications = await list<Communication>(
        syncCommunications,
        'clientId',
        getTableClientId(clientId!, COMMUNICATION_TABLE_NAME)
      );
      return { communications };
    } catch (error) {
      return { communications: [], error: error as string };
    }
  };

  const fetchTenantDocumentCategories = async (): Promise<{
    documentCategories: DocumentCategory[];
    error?: string;
  }> => {
    try {
      const documentCategories = await list<DocumentCategory>(
        syncDocumentCategories,
        'clientId',
        getTableClientId(clientId!, DOCUMENT_CATEGORY_TABLE_NAME)
      );
      return { documentCategories };
    } catch (error) {
      return { documentCategories: [], error: error as string };
    }
  };

  const fetchTenantBuildings = async (): Promise<{
    buildings: Building[];
    error?: string;
  }> => {
    try {
      const buildings = await list<Building>(
        syncBuildings,
        'clientId',
        getTableClientId(clientId!, 'LeaseVariousOperation')
      );
      return { buildings };
    } catch (error) {
      return { buildings: [], error: error as string };
    }
  };

  const getBuilding = () => {
    return undefined;
  };

  const fetchUnitInventories = async (): Promise<{
    unitInventories: UnitInventory[];
    error?: string;
  }> => {
    try {
      const unitInventories = await list<UnitInventory>(
        syncUnitInventories,
        'clientId',
        getTableClientId(clientId!, 'LeaseVariousOperation')
      );
      return { unitInventories };
    } catch (error) {
      return { unitInventories: [], error: error as string };
    }
  };

  const getLeaseVariousOperationsFromLease = (leaseId: string) => {
    if (state.tenantsLoading || state.tenantsError || !state.tenantLeaseVariousOperations) {
      return null;
    }
    return state.tenantLeaseVariousOperations.filter((lvo) => lvo.lease!.id === leaseId);
  };

  const getUnitInventoriesFor = (unitId: string, date?: Date): UnitInventory[] => {
    if (state.tenantsLoading || state.tenantsError || !state.tenantUnitInventories) {
      return [];
    }
    if (isNil(date)) return state.tenantUnitInventories.filter((u) => u.unitId === unitId);

    return state.tenantUnitInventories.filter((u) => {
      if (u.unitId === unitId) {
        const notDeletedBeforeDate =
          isNil(u.deletedAt) || (!isNil(u.deletedAt) && isAfter(new Date(u.deletedAt), date));
        return notDeletedBeforeDate;
      }
      return false;
    });
  };

  const fetchTenantLeases = async (): Promise<{ leases: LeaseExtendedFromUtils[]; error?: string }> => {
    try {
      const tenantsLeases = await list<TruncatedLease>(syncLeases, 'clientId', getTableClientId(clientId!, 'Lease'));
      const cleanedLeases: LeaseExtendedFromUtils[] = tenantsLeases.reduce(
        (acc: LeaseExtendedFromUtils[], truncatedLease: TruncatedLease) => {
          if (truncatedLease.status !== LeaseStatus.Draft) {
            const { units, contacts, files, leaseInventories, ...rest } = truncatedLease;
            const [tenants, lessors, guarantors] = getContactsFromLease(truncatedLease);
            acc.push({
              units: units.items,
              contacts: contacts.items,
              files: files.items,
              leaseInventories: leaseInventories.items.filter((li) => li.status !== LeaseStatus.Draft),
              tenants,
              lessors,
              guarantors,
              ...rest,
            });
          }
          return acc;
        },
        []
      );
      return { leases: cleanedLeases };
    } catch (err) {
      return { error: err as string, leases: [] };
    }
  };

  const fetchBankAccounts = async (): Promise<{ bankAccounts: BankAccount[]; error?: string }> => {
    try {
      const tenantBankAccounts = await list<BankAccount>(
        syncBankAccounts,
        'clientId',
        getTableClientId(clientId!, 'BankAccount')
      );
      return { bankAccounts: tenantBankAccounts };
    } catch (error) {
      return { error: error as string, bankAccounts: [] };
    }
  };

  const fetchLeasePriceHistories = async (
    by: 'byClientId' | 'byLease',
    byValue: string,
    additionalFilter?: LooseObject
  ): Promise<{ tenantsLeasePriceHistories: LeasePriceHistory[]; error?: string }> => {
    const indexName = by === 'byClientId' ? 'clientId' : 'leaseId';
    try {
      const allTenantsLeasePriceHistories = await list<LeasePriceHistory>(
        syncLeasePriceHistories,
        indexName,
        byValue,
        additionalFilter
      );
      const tenantsLeasePriceHistories = allTenantsLeasePriceHistories.filter(
        (leasePriceHistory) =>
          (leasePriceHistory.amountDetails ?? []).length > 0 &&
          (leasePriceHistory.status === LeaseAmountUpdateStatus.APPLIED ||
            leasePriceHistory.status === LeaseAmountUpdateStatus.ACCEPTED_PROCESSED_NEED_TO_APPLY)
      );
      return { tenantsLeasePriceHistories };
    } catch (error) {
      return { tenantsLeasePriceHistories: [], error: error as string };
    }
  };

  const fetchTenantTickets = async (): Promise<{ tickets: Ticket[]; error?: string }> => {
    try {
      const tenantTickets = await list<TruncatedTicket>(syncTickets, 'clientId', getTableClientId(clientId!, 'Ticket'));
      const cleanedTicket: TicketExtended[] = tenantTickets.reduce(
        (acc: TicketExtended[], truncatedTicket: TruncatedTicket) => {
          if (isNil(truncatedTicket.parentId)) {
            const { conversations, ...rest } = truncatedTicket;
            acc.push({ conversations: conversations.items, ...rest });
          }
          return acc;
        },
        []
      );
      return { tickets: cleanedTicket };
    } catch (err) {
      return { error: err as string, tickets: [] };
    }
  };

  const fetchUnitOwners = async (): Promise<{ unitOwners: UnitOwner[]; error?: string }> => {
    try {
      const unitOwners = await list<UnitOwner>(syncUnitOwners, 'clientId', getTableClientId(clientId!, 'Ticket'));
      return { unitOwners };
    } catch (err) {
      return { error: err as string, unitOwners: [] };
    }
  };

  const fetchTechnics = async (): Promise<{ technics: TechnicExtended[]; error?: string }> => {
    try {
      const technics = await list<TechnicExtended>(
        syncTechnics,
        'clientId',
        getTableClientId(clientId!, 'Technic'),
        undefined,
        true
      );
      return { technics };
    } catch (err) {
      return { error: err as string, technics: [] };
    }
  };

  const fetchTransactions = async (): Promise<{ transactions: Transaction[]; error?: string }> => {
    try {
      const transactions = await list<TruncatedTransaction>(
        syncTransactions,
        'clientId',
        getTableClientId(clientId!, 'Transaction')
      );
      const tenantTransactions: Transaction[] = transactions.reduce(
        (acc: Transaction[], truncatedTransaction: TruncatedTransaction) => {
          const { postings, ...rest } = truncatedTransaction;
          acc.push({ postings: postings.items, ...rest });
          return acc;
        },
        []
      );
      return { transactions: tenantTransactions };
    } catch (err) {
      return { error: err as string, transactions: [] };
    }
  };

  const fetchInvoicesWithPostings = async (): Promise<{ invoices: InvoiceWithPostings[]; error?: string }> => {
    try {
      const invoices = await list<Invoice>(syncInvoices, 'clientId', getTableClientId(clientId!, 'Invoice'));
      const invoicesWithPostings = await Promise.all(
        invoices.map(async (invoice) => {
          const postings = await list<Posting>(syncPostings, 'invoiceId', invoice.id);
          return {
            ...invoice,
            postings,
          };
        })
      );

      return { invoices: invoicesWithPostings };
    } catch (err) {
      return { error: err as string, invoices: [] };
    }
  };

  const fetchSignatureDocuments = async (): Promise<{ signatureDocuments: SignatureDocument[]; error?: string }> => {
    try {
      const signatureDocuments = await list<SignatureDocument>(
        syncSignatureDocuments,
        'clientId',
        getTableClientId(clientId!, 'SignatureDocument')
      );
      return { signatureDocuments };
    } catch (err) {
      return { error: err as string, signatureDocuments: [] };
    }
  };

  const getLeasePriceHistoriesOfLease = (leaseId: string) => {
    if (isEmpty(state.tenantLeasePriceHistories)) {
      return [];
    }

    return state.tenantLeasePriceHistories.filter(
      (leasePriceHistory) => leasePriceHistory.lease && leasePriceHistory.lease.id === leaseId
    );
  };

  const getUnitTickets = (id: string): Ticket[] => {
    if (state.tenantsLoading || state.tenantsError) {
      return [];
    }
    return state.tenantTickets.filter((t) => !isNil(t.unit) && !isNil(t.unit.id) && t.unit.id === id);
  };

  const getLeaseTickets = (id: string): Ticket[] => {
    if (state.tenantsLoading || state.tenantsError) {
      return [];
    }
    const actualLease = state.tenantLeases.find((lease) => lease.id === id);
    const unitIds = (actualLease?.units ?? []).map((unit) => unit.unit?.id);

    return state.tenantTickets.filter((t) => !isNil(t.unitId) && unitIds.includes(t.unitId));
  };

  const getLease = (leaseId: string) => {
    return state.tenantLeases.find((lease) => lease.id === leaseId);
  };

  const queryEntity = async () => {
    dispatch({ type: TenantReducerAction.SET_TENANT_LOADING, payload: true });
    const tenantLeasesPromise = fetchTenantLeases();
    const tenantPromise = fetchTenant(tenantId!);
    const ticketPromise = fetchTenantTickets();
    const technicsPromise = fetchTechnics();
    const transactionsPromise = fetchTransactions();
    const invoicesPromise = fetchInvoicesWithPostings();
    const signatureDocumentsPromise = fetchSignatureDocuments();
    const leasePriceHistoriesPromise = await fetchLeasePriceHistories(
      'byClientId',
      getTableClientId(clientId!, 'LeasePriceHistory')
    );
    const tenantsBankAccountsPromise = await fetchBankAccounts();
    const tenantsLeaseVariousOperationPromise = await fetchLeaseVariousOperations();
    const tenantUnitInventoriesPromise = await fetchUnitInventories();
    const tenantUnitOwnerPromise = await fetchUnitOwners();
    const tenantUser = await fetchUser(userId);
    const tenantBuildingPromise = await fetchTenantBuildings();
    const tenantCommunicationsPromise = await fetchTenantCommunications();
    const tenantDocumentCategoriesPromise = await fetchTenantDocumentCategories();
    const [
      tenantLeasesResult,
      tenantResult,
      ticketsResult,
      technicsResult,
      transactionsResult,
      invoicesResult,
      signatureDocumentsResult,
      leasePriceHistoriesResult,
      bankAccountsResult,
      leaseVariousOperationResult,
      unitInventoriesResult,
      unitOwnersResult,
      buildingResult,
      communicationsResult,
      documentCategoriesResult,
    ] = await Promise.all([
      tenantLeasesPromise,
      tenantPromise,
      ticketPromise,
      technicsPromise,
      transactionsPromise,
      invoicesPromise,
      signatureDocumentsPromise,
      leasePriceHistoriesPromise,
      tenantsBankAccountsPromise,
      tenantsLeaseVariousOperationPromise,
      tenantUnitInventoriesPromise,
      tenantUnitOwnerPromise,
      tenantBuildingPromise,
      tenantCommunicationsPromise,
      tenantDocumentCategoriesPromise,
    ]);

    if (
      tenantLeasesResult.error ||
      tenantResult.error ||
      ticketsResult.error ||
      technicsResult.error ||
      transactionsResult.error ||
      invoicesResult.error ||
      signatureDocumentsResult.error ||
      leasePriceHistoriesResult.error ||
      bankAccountsResult.error ||
      leaseVariousOperationResult.error ||
      unitInventoriesResult.error ||
      unitOwnersResult.error ||
      buildingResult.error ||
      communicationsResult.error ||
      documentCategoriesResult.error
    ) {
      dispatch({
        type: TenantReducerAction.SET_TENANT_ERROR,
        payload: `${tenantLeasesResult.error} - ${tenantResult.error} - ${ticketsResult.error} - 
          ${technicsResult.error} - ${transactionsResult.error} - ${invoicesResult.error} - 
          ${signatureDocumentsResult.error} - ${leasePriceHistoriesResult.error} - ${bankAccountsResult.error} - 
          ${leaseVariousOperationResult.error} - ${unitInventoriesResult.error} ${unitOwnersResult.error} - 
          ${buildingResult.error}`,
      });
    } else {
      dispatch({
        type: TenantReducerAction.SET_TENANT_VALUES,
        payload: {
          tenant: (
            tenantResult as {
              tenant?: Contact | undefined;
              error?: string;
            }
          ).tenant,
          tenantUser: tenantUser.tenant,
          tenantLeases: (
            tenantLeasesResult as {
              leases: LeaseExtendedFromUtils[];
              error?: string;
            }
          ).leases,
          tenantInvoices: (
            invoicesResult as {
              invoices: InvoiceWithPostings[];
              error?: string;
            }
          ).invoices,
          tenantTickets: (
            ticketsResult as {
              tickets: Ticket[];
              error?: string;
            }
          ).tickets,
          tenantTechnics: (
            technicsResult as {
              technics: TechnicExtended[];
              error?: string;
            }
          ).technics,
          tenantTransactions: (
            transactionsResult as {
              transactions: Transaction[];
              error?: string;
            }
          ).transactions,
          tenantSignatureDocuments: (
            signatureDocumentsResult as {
              signatureDocuments: SignatureDocument[];
              error?: string;
            }
          ).signatureDocuments,
          tenantLeasePriceHistories: (
            leasePriceHistoriesResult as {
              tenantsLeasePriceHistories: LeasePriceHistory[];
              error?: string;
            }
          ).tenantsLeasePriceHistories,
          tenantsLoading: false,
          tenantsError: undefined,
          tenantsBankAccounts: (
            bankAccountsResult as {
              bankAccounts: BankAccount[];
              error?: string;
            }
          ).bankAccounts,
          tenantLeaseVariousOperations: (
            leaseVariousOperationResult as {
              leaseVariousOperations: LeaseVariousOperation[];
              error?: string;
            }
          ).leaseVariousOperations,
          tenantUnitInventories: (
            unitInventoriesResult as {
              unitInventories: UnitInventory[];
              error?: string;
            }
          ).unitInventories,
          tenantUnitOwners: (
            unitOwnersResult as {
              unitOwners: UnitOwner[];
              error?: string;
            }
          ).unitOwners,
          tenantsBuildings: (
            buildingResult as {
              buildings: Building[];
              error?: string;
            }
          ).buildings,
          tenantsCommunications: (
            communicationsResult as {
              communications: Communication[];
              error?: string;
            }
          ).communications,
          tenantsDocumentCategories: (
            documentCategoriesResult as {
              documentCategories: DocumentCategory[];
              error?: string;
            }
          ).documentCategories,
        },
      });
    }
  };

  useEffect(() => {
    queryEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId]);

  useSubscriptions<{}, OnUpdateLeaseSubscription, {}>(
    undefined,
    onUpdateLease,
    undefined,
    undefined,
    () => {
      if (tenantId) queryEntity();
    },
    undefined
  );

  useSubscriptions<{}, OnUpdateLeaseContactSubscription, {}>(
    undefined,
    onUpdateLeaseContact,
    undefined,
    undefined,
    () => {
      if (tenantId) queryEntity();
    },
    undefined
  );

  useSubscriptions<{}, OnUpdateTicketSubscription, {}>(
    undefined,
    onUpdateTicket,
    undefined,
    undefined,
    () => {
      if (tenantId) queryEntity();
    },
    undefined
  );

  useSubscriptions<{}, OnUpdateUserSubscription, {}>(
    undefined,
    onUpdateUser,
    undefined,
    undefined,
    () => {
      if (tenantId) queryEntity();
    },
    undefined
  );

  const rejectDocument = async (
    id: string,
    contactId: string,
    type: DocumentType,
    rejectReason: string
  ): Promise<MutationStatus> => {
    const result = await mutation<MutationStatus, RejectDocumentMutationVariables>(rejectDocumentMutation, {
      input: { contactId, id, type, rejectReason },
    });
    return result;
  };

  const getInvoicesOfLease = (leaseId: string) => {
    if (state.tenantsLoading || state.tenantsError || !state.tenantInvoices) {
      return [];
    }
    return state.tenantInvoices.filter((invoice) => invoice.lease && invoice.lease.id === leaseId);
  };

  const getTicket = (id: string): Ticket | undefined => {
    return state.tenantTickets.find((t) => t.id === id);
  };

  const createTicket = async (input: Omit<Ticket, 'clientId' | 'readId'>, refetch = false) => {
    const { contact, ...inputGraphql } = input;
    const cleanedInput = cleanInputCreate({
      ...inputGraphql,
      contactId: contact?.id ?? state.tenant?.id,
    }) as CreateTicketInput;
    const ticket = await mutation<Ticket, CreateTicketMutationVariables>(createTicketMutation, {
      input: {
        ...cleanedInput,
        readId: getReadId(clientId!, 'Ticket'),
        clientId: getTableClientId(clientId!, 'Ticket'),
        readers: [userId!],
      },
    });
    if (refetch) {
      fetchTenantTickets().then((res) => {
        if (isNil(res.error)) dispatch({ type: TenantReducerAction.SET_TENANT_TICKETS, payload: res.tickets });
      });
    }
    return ticket;
  };

  const getTechnicsFor = (unitId: string): Technic[] => {
    if (state.tenantsLoading || state.tenantsError) {
      return [];
    }
    return state.tenantTechnics.filter((t) => t.unitId === unitId);
  };

  const values = useMemo(
    () => ({
      ...state,
      tenantsUnits,
      getTicket,
      getLeasePriceHistoriesOfLease,
      createTicket,
      rejectDocument,
      getLease,
      getUnit,
      getInvoicesOfLease,
      getTransactionsForLease,
      getLeaseVariousOperationsFromLease,
      getUnitInventoriesFor,
      getBuilding,
      getUnitTickets,
      getUnitInventory,
      getTechnicsFor,
      getLeaseTickets,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <TenantContext.Provider value={values}>{children}</TenantContext.Provider>;
};

export const useTenants = (): TenantContext => {
  const context = useContext<TenantContext | null>(TenantContext);

  if (context === undefined) {
    throw new Error('`useTenants` hook must be used within a `TenantContextProvider` component');
  }
  return context as TenantContext;
};
