import { Grid, Paper, Typography } from '@material-ui/core';
import {
  Colors,
  LeaseExtended,
  LeaseStatus,
  formatNumberToEuroCurrency,
  getAmountColor,
  truncateAndEllipsisAfter,
} from '@rentguru/commons-utils';
import { isNil } from 'lodash';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { ColoredBox, CustomIconButton, OverflowableTypography } from '@up2rent/ui';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as EditIcon } from 'src/icons/edit.svg';
import EditLeaseNameDialog from '../AddLease/EditLeaseNameDialog';
import { fromStatusToColor } from './LeaseDetail';
import { PendingButton } from './PendingButton';

interface LeaseMenusProps {
  lease: LeaseExtended;
  isTenant: boolean;
}

export const LeaseCardHeader: React.FC<LeaseMenusProps> = ({ lease, isTenant }) => {
  const [openEditLeaseName, setOpenEditLeaseName] = useState<boolean>(false);
  const [showTitleTooltip, setShowTitleTooltip] = useState<boolean>(false);
  const { language } = useLocale();
  const { formatMessage } = useIntl();

  const isActive = lease.status === LeaseStatus.Active;
  const balance = lease.balance ?? 0;
  const balanceColor = getAmountColor(balance);
  const charLimit = isTenant ? 24 : 18;
  const truncatedLeaseName = truncateAndEllipsisAfter(lease.name, charLimit);
  const needTitleTooltip = truncatedLeaseName.length !== lease.name.length;
  const showPendingButton =
    !isTenant &&
    ![LeaseStatus.Draft, LeaseStatus.OutForSignature, LeaseStatus.Cancelled, LeaseStatus.Rejected].includes(
      lease.status
    );

  return (
    <Grid
      style={{
        backgroundColor: Colors.CLASSICAL_WHITE,
        borderRadius: 8,
        padding: 10,
        border: isTenant ? `1px solid ${Colors.PORCELAIN_GREY_3}` : 'none',
      }}
    >
      <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <OverflowableTypography
          variant="h6"
          color="inherit"
          style={{
            textAlign: 'left',
            color: Colors.CLASSICAL_BLACK,
            fontSize: 20,
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
          }}
          onMouseEnter={() => setShowTitleTooltip(true)}
          onMouseLeave={() => setShowTitleTooltip(false)}
          data-test="ContentDetailHeader-title"
          text={truncatedLeaseName}
        />
        {needTitleTooltip && showTitleTooltip && (
          <Paper
            elevation={8}
            style={{
              position: 'absolute',
              marginTop: 80,
              zIndex: 1,
              padding: 15,
              borderRadius: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              style={{
                color: Colors.CLASSICAL_BLACK,
                fontSize: 14,
              }}
            >
              {lease.name}
            </Typography>
          </Paper>
        )}
        {isActive && !isTenant && (
          <CustomIconButton
            size="small"
            onClick={() => {
              setOpenEditLeaseName(true);
            }}
            style={{ height: 22 }}
            Icon={EditIcon}
            iconStyle={{ fill: Colors.BLUE_GREY }}
          />
        )}
      </Grid>
      <Grid style={{ display: 'flex', paddingTop: 10, paddingBottom: 8 }}>
        <ColoredBox boxColor={fromStatusToColor(lease.status)} dataTest={`LeaseStatus_${lease.status}`}>
          {formatMessage({ id: `enums.LeaseStatus.${lease.status}` })}
        </ColoredBox>
      </Grid>
      {!isNil(balance) && (
        <Grid style={{ display: 'flex', marginTop: 4 }}>
          <Typography style={{ fontSize: 14, fontWeight: 700, display: 'inline-block', marginRight: 5 }}>
            {`${formatMessage({ id: 'tenant.balance' })}: `}
          </Typography>
          <Typography style={{ fontSize: 14, fontWeight: 700, color: balanceColor, display: 'inline-block' }}>
            {formatNumberToEuroCurrency(balance, language)}
          </Typography>
        </Grid>
      )}
      {showPendingButton && <PendingButton leaseId={lease.id} />}
      {openEditLeaseName && (
        <EditLeaseNameDialog
          lease={lease}
          open={openEditLeaseName}
          onClose={() => {
            setOpenEditLeaseName(false);
          }}
        />
      )}
    </Grid>
  );
};
