/* eslint-disable @typescript-eslint/no-shadow */
import React, { lazy } from 'react';
import { Redirect, Switch, useHistory } from 'react-router-dom';
import { isNil, isEmpty } from 'lodash';
import { useUser } from 'src/hooks/UserContext';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { useUsers } from 'src/hooks/UsersContext';

/* Pages */
import NotFound from './NotFound';
import AuthenticatedRoute from './AuthenticatedRoute';

/* Icons */
import { ReactComponent as BuildingSvg } from 'src/icons/building.svg';
import { ReactComponent as ContractSvg } from 'src/icons/contract.svg';
import { ReactComponent as BanknoteSvg } from 'src/icons/banknote.svg';
import { ReactComponent as CommunicationSvg } from 'src/icons/communication.svg';
import { ReactComponent as ContactSvg } from 'src/icons/contact.svg';
import { ReactComponent as TicketSvg } from 'src/icons/ticket.svg';
import { ReactComponent as DashboardSvg } from 'src/icons/dash.svg';
import { ReactComponent as FolderSvg } from 'src/icons/documentsIcon.svg';
import { isMenuVisibleWithRight } from './routeUtils';
import ResetIcon from '../ui/ResetIcon';
import useCustomSubscriptions from './useCustomSubscriptions';
import { retryLazy } from './LazyErrorBoundary';
import { UserStatus } from '@rentguru/commons-utils';
import { SettingsRouteDestination } from 'src/components/Settings/Settings';
import { SUBSCRIPTION_PLAN_TAB_ROUTE } from 'src/components/Settings/SubscriptionSettings';

const Dashboard = lazy(() => retryLazy(() => import('../Dashboard/Dashboard')));
const Communications = lazy(() => retryLazy(() => import('../Communications/Communications')));
const AddUnit = lazy(() => retryLazy(() => import('../RentalUnits/AddUnit/AddUnit')));
const EditUnit = lazy(() => retryLazy(() => import('../RentalUnits/EditUnit/EditUnit')));
const AddLease = lazy(() => retryLazy(() => import('../Leases/AddLease/AddLease')));
const EditLease = lazy(() => retryLazy(() => import('../Leases/EditLease/EditLease')));
const AddBuilding = lazy(() => retryLazy(() => import('../Buildings/AddBuilding/AddBuilding')));
const EditBuilding = lazy(() => retryLazy(() => import('../Buildings/EditBuilding/EditBuilding')));
const Contacts = lazy(() => retryLazy(() => import('../Contacts/Contacts')));
const Settings = lazy(() => retryLazy(() => import('../Settings/Settings')));
const Tickets = lazy(() => retryLazy(() => import('../Tickets/Tickets')));
const AddTicketEntity = lazy(() => retryLazy(() => import('../Tickets/AddTicket/AddTicketEntity')));
const TicketDetail = lazy(() => retryLazy(() => import('../Tickets/Details/TicketDetail')));
const EditInventory = lazy(() => retryLazy(() => import('../LeaseInventory/EditInventory/EditInventory')));
const EditFurnitureInventory = lazy(() =>
  retryLazy(() => import('../FurnitureInventory/EditFurnitureInventory/EditFurnitureInventory'))
);
const EditAdvertisement = lazy(() =>
  retryLazy(() => import('../RentalUnits/Details/Publication/EditPublication/EditPublication'))
);
const Signature = lazy(() => retryLazy(() => import('../RentalUnits/Signature/Signature')));
const AddCharge = lazy(() => retryLazy(() => import('../Accounting/Charges/ChargesToCheck/AddCharge')));
const Accounting = lazy(() => retryLazy(() => import('../Accounting/Accounting')));
const AffectCharge = lazy(() => retryLazy(() => import('../Accounting/Charges/ChargesToAffect/AffectCharge')));
const ChargesArchivedSummary = lazy(() =>
  retryLazy(() => import('../Accounting/Charges/ChargesArchived/ChargesArchivedSummary'))
);
const TransactionReconciliation = lazy(() =>
  retryLazy(() => import('../Accounting/Transactions/Reconciliation/TransactionsReconciliations'))
);
const ValidateTenantStatement = lazy(() =>
  retryLazy(
    () =>
      import(
        '../Accounting/TenantStatements/TenantStatementsToValidate/ValidateTenantStatement/ValidateTenantStatement'
      )
  )
);
const ValidateOwnerStatement = lazy(() =>
  retryLazy(
    () =>
      import('../Accounting/OwnerStatements/OwnerStatementsToValidate/ValidateOwnerStatement/ValidateOwnerStatement')
  )
);
const Properties = lazy(() => retryLazy(() => import('../Properties/Properties')));
const Leases = lazy(() => retryLazy(() => import('../Leases/Leases')));
const Indexation = lazy(() => retryLazy(() => import('../Leases/LeasePriceHistory/Indexation')));
const Documents = lazy(() => retryLazy(() => import('../Folders/FoldersPage')));

export enum RouteDestination {
  DASHBOARD = '/dashboard',
  COMMUNICATIONS = '/communications',
  ACCOUNTING = '/accounting',
  PROPERTIES = '/properties',
  LEASES = '/leases',
  TEMPLATES = '/settings/templates',
  CONTACTS = '/contacts',
  TICKETS = '/issues',
  TICKETS_DETAILS = '/issueDetail',
  ADD_PROPERTY = '/addProperty',
  EDIT_PROPERTY = '/editProperty',
  ADD_UNIT = '/addUnit',
  EDIT_UNIT = '/editUnit',
  ADD_LEASE = '/addLease',
  EDIT_LEASE = '/editLease',
  ADD_CHARGE = '/addCharge',
  AFFECT_CHARGE = '/affectCharge',
  CHARGE_SUMMARY = '/chargeSummary',
  SETTINGS = '/settings',
  ADD_TICKET = '/addIssue',
  EDIT_INVENTORY = '/editInventory',
  EDIT_FURNITURE_INVENTORY = '/editFurnitureInventory',
  EDIT_ADVERTISEMENT = '/editAdvertisement',
  RECONCILE_TRANSACTION = '/reconciliation',
  VALIDATE_TENANT_STATEMENT = '/validateTenantTransaction',
  VALIDATE_OWNER_STATEMENT = '/validateOwnerTransaction',
  EDIT_SIGNATURES = '/editSignatures',
  INDEXATION = '/indexation',
  PONTO_CALLBACK = '/pontoConnect',
  PAYMENT_MANDATE_CALLBACK = '/mandateResponse',
  DOCUMENTS = '/documents',
  ROOT = '/',
}

export interface NavItem {
  to?: string;
  exact?: boolean;
  params?: string;
  svgicon?: React.FC;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: React.FC<any>;
  labelId?: string;
  space?: boolean;
  divider?: boolean;
  menu?: boolean;
  showMenu?: boolean;
}
export interface ExitableLocation {
  goBackUrl: string;
}

export const navItems: NavItem[] = [
  {
    divider: true,
  },
  {
    to: RouteDestination.DASHBOARD,
    params: '/:tabName?',
    exact: true,
    svgicon: DashboardSvg,
    component: Dashboard,
    labelId: 'menu.dashboard',
  },
  {
    to: RouteDestination.COMMUNICATIONS,
    params: `/:tabName?`,
    svgicon: CommunicationSvg,
    component: Communications,
    labelId: 'menu.communications',
  },
  {
    to: RouteDestination.ACCOUNTING,
    params: `/:tabName?`,
    svgicon: BanknoteSvg,
    component: Accounting,
    labelId: 'menu.accounting',
  },
  {
    to: RouteDestination.LEASES,
    params: `/:tabName?/:id?/:detailName?`,
    svgicon: ContractSvg,
    component: Leases,
    labelId: 'menu.leases',
  },
  {
    to: RouteDestination.PROPERTIES,
    params: `/:id?/:detailName?`,
    svgicon: BuildingSvg,
    component: Properties,
    labelId: 'menu.properties',
  },
  {
    to: RouteDestination.DOCUMENTS,
    params: `/:tabName?`,
    svgicon: FolderSvg,
    component: Documents,
    labelId: 'menu.documents',
  },
  {
    to: RouteDestination.CONTACTS,
    params: `/:id?/:detailName?`,
    svgicon: ContactSvg,
    component: Contacts,
    labelId: 'menu.contacts',
  },
  {
    to: RouteDestination.TICKETS,
    params: `/:tabName?`,
    svgicon: TicketSvg,
    component: Tickets,
    labelId: 'menu.tickets',
  },
  {
    to: RouteDestination.TICKETS_DETAILS,
    params: `/:id?`,
    component: TicketDetail,
    menu: false,
  },

  {
    to: RouteDestination.ADD_PROPERTY,
    component: AddBuilding,
    showMenu: false,
    menu: false,
  },
  {
    to: RouteDestination.EDIT_PROPERTY,
    component: EditBuilding,
    showMenu: false,
    menu: false,
  },
  {
    to: RouteDestination.ADD_UNIT,
    component: AddUnit,
    showMenu: false,
    menu: false,
  },
  {
    to: RouteDestination.EDIT_UNIT,
    component: EditUnit,
    showMenu: false,
    menu: false,
  },

  {
    to: RouteDestination.ADD_LEASE,
    component: AddLease,
    showMenu: false,
    menu: false,
  },
  {
    to: RouteDestination.EDIT_LEASE,
    component: EditLease,
    showMenu: false,
    menu: false,
  },
  {
    to: RouteDestination.ADD_CHARGE,
    component: AddCharge,
    showMenu: false,
    menu: false,
  },
  {
    to: RouteDestination.AFFECT_CHARGE,
    component: AffectCharge,
    showMenu: false,
    menu: false,
  },
  {
    to: RouteDestination.CHARGE_SUMMARY,
    component: ChargesArchivedSummary,
    showMenu: false,
    menu: false,
  },
  {
    to: RouteDestination.SETTINGS,
    params: `/:detailName?`,
    component: Settings,
    menu: false,
  },
  {
    to: RouteDestination.ADD_TICKET,
    component: AddTicketEntity,
    showMenu: false,
    menu: false,
  },
  {
    to: RouteDestination.EDIT_INVENTORY,
    component: EditInventory,
    showMenu: false,
    menu: false,
  },
  {
    to: RouteDestination.EDIT_FURNITURE_INVENTORY,
    component: EditFurnitureInventory,
    showMenu: false,
    menu: false,
  },
  {
    to: RouteDestination.EDIT_ADVERTISEMENT,
    component: EditAdvertisement,
    showMenu: false,
    menu: false,
  },
  {
    to: RouteDestination.EDIT_SIGNATURES,
    params: `/:leaseId?`,
    component: Signature,
    showMenu: false,
    menu: false,
  },
  {
    to: RouteDestination.RECONCILE_TRANSACTION,
    component: TransactionReconciliation,
    showMenu: false,
    menu: false,
  },
  {
    to: RouteDestination.VALIDATE_TENANT_STATEMENT,
    component: ValidateTenantStatement,
    showMenu: false,
    menu: false,
  },
  {
    to: RouteDestination.VALIDATE_OWNER_STATEMENT,
    component: ValidateOwnerStatement,
    showMenu: false,
    menu: false,
  },
  {
    to: RouteDestination.INDEXATION,
    component: Indexation,
    showMenu: false,
    menu: false,
  },
];

export const ownerNavItems: NavItem[] = [
  {
    divider: true,
  },
  {
    to: RouteDestination.DASHBOARD,
    params: '/:tabName?',
    exact: true,
    svgicon: DashboardSvg,
    component: Dashboard,
    labelId: 'menu.dashboard',
  },
  {
    to: RouteDestination.LEASES,
    params: `/:tabName?/:id?/:detailName?`,
    svgicon: ContractSvg,
    component: Leases,
    labelId: 'menu.leases',
  },
  {
    to: RouteDestination.PROPERTIES,
    params: `/:id?/:detailName?/:nestedDetailName?`,
    svgicon: BuildingSvg,
    component: Properties,
    labelId: 'menu.properties',
  },
  {
    to: RouteDestination.CONTACTS,
    params: `/:id?/:detailName?`,
    svgicon: ContactSvg,
    component: Contacts,
    labelId: 'menu.contacts',
  },
  {
    to: RouteDestination.TICKETS,
    params: `/:tabName?`,
    svgicon: TicketSvg,
    component: Tickets,
    labelId: 'menu.tickets',
  },
  {
    to: RouteDestination.TICKETS_DETAILS,
    params: `/:id?`,
    component: TicketDetail,
    menu: false,
  },
  {
    to: RouteDestination.SETTINGS,
    params: `/:detailName?`,
    component: Settings,
    menu: false,
  },
  {
    to: RouteDestination.ADD_TICKET,
    component: AddTicketEntity,
    showMenu: false,
    menu: false,
  },
];
const Routes: React.FC = () => {
  const { formatMessage } = useIntl();
  const { isOwner, userId, refreshToken, userAttributes, logout } = useUser();
  const { loading, getUser } = useUsers();
  const { enqueueSnackbar } = useSnackbar();
  const permissions = usePermissions();
  const history = useHistory();
  const isAuthenticated = !isEmpty(userAttributes?.email);
  const selectedProfileNavItems = isOwner ? ownerNavItems : navItems;

  useCustomSubscriptions();

  const authRoutes = selectedProfileNavItems.map(({ divider, space, ...route }) => {
    if (divider || space) {
      return null;
    }
    const RouteComponent = route.component;
    const to = !isNil(route.params) ? `${route.to}${route.params}` : route.to;
    const showMenu = !isNil(route.showMenu) ? route.showMenu : true;

    const currentUser = !isNil(userId) ? getUser(userId) : undefined;
    if (!isNil(currentUser) && currentUser.refreshToken && currentUser.status === UserStatus.DISABLED) {
      logout();
      history.push({
        pathname: RouteDestination.ROOT,
      });
      return;
    }

    if (!loading && !isNil(currentUser) && currentUser.refreshToken) {
      enqueueSnackbar(formatMessage({ id: 'refreshToken' }), {
        persist: true,
        variant: 'warning',
        preventDuplicate: true,
        key: 'Refresh Token',
        action: (
          <ResetIcon
            onClick={async () => {
              await refreshToken();
              window.location.reload();
            }}
            style={{ cursor: 'pointer' }}
          />
        ),
      });
    }

    if (!isOwner && !isMenuVisibleWithRight(route.to as RouteDestination, permissions)) {
      return null;
    }

    if (RouteComponent) {
      return (
        <AuthenticatedRoute
          path={to}
          key={route.to}
          component={RouteComponent}
          showMenu={showMenu}
          isAuthenticated={isAuthenticated}
        />
      );
    }
    return null;
  });
  const routes = (
    <Switch>
      <AuthenticatedRoute path="/" component={Dashboard} showMenu={true} isAuthenticated={isAuthenticated} exact />

      {authRoutes}

      <Redirect
        from={RouteDestination.PONTO_CALLBACK}
        to={{
          pathname: `${RouteDestination.SETTINGS}/${SettingsRouteDestination.INTEGRATION_ROUTE}`,
          state: { from: 'ponto' },
        }}
      />

      <Redirect
        from={RouteDestination.PAYMENT_MANDATE_CALLBACK}
        to={{
          // eslint-disable-next-line max-len
          pathname: `${RouteDestination.SETTINGS}/${SettingsRouteDestination.SUBSCRIPTION_ROUTE}/${SUBSCRIPTION_PLAN_TAB_ROUTE}`,
          state: { from: 'mandateProvider' },
        }}
      />

      {/* Finally, catch all unmatched routes */}
      <AuthenticatedRoute component={NotFound} showMenu={true} isAuthenticated={isAuthenticated} />
    </Switch>
  );
  return routes;
};

export default Routes;
