/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Divider, Typography, Tab } from '@material-ui/core';
import Dialog from 'src/components/ui/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { isNil } from 'lodash';
import { CustomizedTabs , ActionButton } from '@up2rent/ui';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { DashboardNavItems, CACHE_DASHBOARD_TAB } from './Dashboard';
import AddNotification from './DashboardTodos/DashboardDialogs/AddNotification';
import { useNotifications } from 'src/hooks/NotificationsContext';
import { useTechnics } from 'src/hooks/TechnicsContext';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { useContacts } from 'src/hooks/ContactsContext';
import SkeletonComponent from '../ui/SkeletonComponent';
import { useUser } from 'src/hooks/UserContext';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { Colors } from '@rentguru/commons-utils';
import { setCacheItem } from 'src/utils/cacheUtils';

const useStyles = makeStyles(() => ({
  maintitle: {
    flex: 1,
    textAlign: 'left',
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Mulish',
    fontSize: '20px',
    fontWeight: 'normal',
  },
  subtitle: {
    flex: 1,
    textAlign: 'left',
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Mulish',
    fontSize: '30px',
    fontWeight: 'bold',
  },
  flexDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 30,
    marginRight: 30,
    marginBottom: 20,
    alignItems: 'center',
  },
  badge: {
    top: '48%',
    right: -25,
    color: Colors.CLASSICAL_WHITE,
    '-webkit-text-fill-color': Colors.CLASSICAL_WHITE,
    backgroundColor: Colors.CARNATION_RED,
  },
  rootSelected: {
    backgroundColor: Colors.CARNATION_RED,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  root: {
    color: Colors.DARK,
    '-webkit-text-fill-color': '283238',
  },
}));

const DashboardTabHeader: React.FC = () => {
  const { formatMessage } = useIntl();
  const { notificationsDetailsWrite } = usePermissions();
  const match = useRouteMatch<{ tabName: string }>();
  const history = useHistory();
  const classes = useStyles();
  const [dialogAddNotificationOpen, setDialogAddNotificationOpen] = useState<boolean>(false);
  const { notificationsLoading } = useNotifications();
  const { isOwner } = useUser();
  const { unitsLoading } = useUnits();
  const { contactsLoading } = useContacts();
  const { technicsLoading } = useTechnics();
  const { buildingsLoading } = useBuildings();
  const loading = notificationsLoading || unitsLoading || contactsLoading || technicsLoading || buildingsLoading;

  const handleAddNotificationClickOpen = () => {
    setDialogAddNotificationOpen(true);
  };
  const handleCloseDialogs = () => {
    setDialogAddNotificationOpen(false);
  };

  return (
    <>
      <div className={classes.flexDiv}>
        <div>
          <Typography className={classes.maintitle}>{formatMessage({ id: 'menu.dashboard' })}</Typography>
          <Typography className={classes.subtitle}>{formatMessage({ id: 'dashboard.overview.subtitle' })}</Typography>
        </div>
        {match.url === '/dashboard/todos' && !isOwner && notificationsDetailsWrite && (
          <SkeletonComponent loading={loading}>
            <ActionButton onClick={handleAddNotificationClickOpen}>
              {formatMessage({ id: 'dashboard.notifications.addNotification' })}
            </ActionButton>
          </SkeletonComponent>
        )}
      </div>
      <CustomizedTabs
        dynamicDefaultTab
        value={
          match.url === '/'
            ? '/dashboard/'
            : !isNil(match.params.tabName)
            ? match.url
            : match.url + (match.url.charAt(match.url.length - 1) === '/' ? '' : '/')
        }
        handleChange={async (value: string) => {
          await setCacheItem(CACHE_DASHBOARD_TAB, value);
          history.replace(value);
        }}
      >
        {DashboardNavItems.map((m) => {
          let to = !isNil(match.params.tabName)
            ? match.url.replace(match.params.tabName, m.to)
            : match.url + (match.url.charAt(match.url.length - 1) === '/' ? '' : '/') + m.to;
          to = match.path === '/' ? `/dashboard${to}` : to;

          return <Tab label={formatMessage({ id: m.labelId }).toUpperCase()} key={m.to} value={to} />;
        })}
      </CustomizedTabs>
      <Divider />
      <Dialog
        open={dialogAddNotificationOpen}
        onClose={handleCloseDialogs}
        scroll="body"
        disableEscapeKeyDown
        disableEnforceFocus
        maxWidth={false}
        PaperProps={{ style: { borderRadius: 10 } }}
      >
        <AddNotification afterSubmit={handleCloseDialogs} />
      </Dialog>
    </>
  );
};

export default DashboardTabHeader;
