import React from 'react';
import Modal from '@material-ui/core/Modal';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Backdrop } from '@material-ui/core';
import clsx from 'clsx';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { navItems } from '../../Routes/Routes';
import { isNil } from 'lodash';
import { Colors } from '@rentguru/commons-utils';

export const ENTITY_DETAIL_PANEL_SIZE = '75%';
const useStyles = makeStyles((theme: Theme) => ({
  /* Styles applied to the root element. */
  root: {},
  /* Styles applied to the `Paper` component. */
  paper: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flex: '1 0 auto',
    zIndex: theme.zIndex.drawer,
    WebkitOverflowScrolling: 'touch', // Add iOS momentum scrolling.
    // temporary style
    position: 'fixed',
    backgroundColor: Colors.PORCELAIN_GREY_3,
    top: 0,
    // We disable the focus ring for mouse, touch and keyboard users.
    // At some point, it would be better to keep it for keyboard users.
    // :focus-ring CSS pseudo-class will help.
    outline: 'none',
  },

  paperAnchor: {
    left: 'auto',
    right: 0,
    width: ENTITY_DETAIL_PANEL_SIZE,
  },
  /* Styles applied to the `Modal` component. */
  modal: {},
}));

interface EntityDetailProps {
  forceOnClose?: boolean;
  onClose: () => void;
  open: boolean;
  transitionDuration?: { enter: number; exit: number };
  elevation?: number;
  children?: React.ReactNode;
}

// eslint-disable-next-line react/display-name
const EntityDetail: React.FC<EntityDetailProps> = React.forwardRef(
  (
    {
      onClose,
      elevation = 10,
      open = false,
      transitionDuration = { enter: 225, exit: 195 },
      children,
      forceOnClose = false,
    },
    ref
  ) => {
    const classes = useStyles();
    const mounted = React.useRef(false);
    React.useEffect(() => {
      mounted.current = true;
    }, []);
    const history = useHistory();
    const match = useRouteMatch<{ routeName: string }>({ path: '/:routeName' });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const childrenWithProps = React.Children.map(children, (child) => React.cloneElement(child as any, { onClose }));

    const drawer = (
      <Paper elevation={elevation} className={clsx(classes.paper, classes[`paperAnchor`])}>
        <Grid container style={{ display: 'block', height: '100%' }}>
          {childrenWithProps}
        </Grid>
      </Paper>
    );

    const slidingDrawer = (
      <Slide in={open} direction="left" timeout={transitionDuration} appear={mounted.current}>
        {drawer}
      </Slide>
    );

    return (
      <Modal
        BackdropProps={{
          transitionDuration,
        }}
        BackdropComponent={Backdrop}
        className={clsx(classes.root, classes.modal)}
        open={open}
        onClose={() => {
          if (forceOnClose) {
            onClose();
            return;
          }
          const item = navItems.find((nav) => nav.to && match && nav.to === `/${match.params.routeName}`);
          if (!isNil(item) && !isNil(match) && (isNil(item.menu) || item.menu)) {
            history.push(`/${match.params.routeName}`);
          } else {
            onClose();
          }
        }}
        ref={ref}
        disableEnforceFocus // Needed for marker.io
      >
        {slidingDrawer}
      </Modal>
    );
  }
);

export default EntityDetail;
