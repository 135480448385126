import { isEmpty, isNil } from 'lodash';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import RankingBox, { RankingBoxLayoutProps } from './RankingBox';
import {
  getTopElementsBasedOnProperty,
  formatNumber,
  TOP_BUILDINGS_RENTAL_YIELD_BOX_KEY,
  Unit,
} from '@rentguru/commons-utils';
import { useLocale } from '../../../i18n/IntlProviderWrapper';
import { useUnits } from '../../../hooks/UnitsContext';
import { endOfYear, startOfYear } from 'date-fns';
import { calculateGrossRentalYield } from '../DashboardMetrics/utils/DashboardKeyfiguresUtils';
import { useLeasePriceHistories } from 'src/hooks/LeasePriceHistoriesContext';
import { useValuations } from 'src/hooks/ValuationsContext';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { Typography } from '@material-ui/core';
import { useLeaseVariousOperations } from 'src/hooks/LeaseVariousOperationsContext';

const DashboardRankingTopBuildingsRentalYeld: React.FC<RankingBoxLayoutProps> = ({ forceUpdateLayout }) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const { unitsLoading, getUnit } = useUnits();
  const { buildings, buildingsLoading } = useBuildings();
  const { leasePriceHistories, loading: leasesIndexationsLoading } = useLeasePriceHistories();
  const { discounts, loading: leaseVariousOperationsLoading } = useLeaseVariousOperations();
  const { getUnitValuations, getBuildingValuations, loading: valuationsLoading } = useValuations(true);

  const loading =
    unitsLoading || leasesIndexationsLoading || valuationsLoading || buildingsLoading || leaseVariousOperationsLoading;

  const datas = useMemo(() => {
    // Based on Last 1 years
    const today = new Date();
    const start = startOfYear(today);
    const end = endOfYear(today);
    const timeWindow = { start, end };
    const buildingsWithRentalYield = buildings.reduce((result, currentBuilding) => {
      if (
        isNil(currentBuilding.owners) ||
        isEmpty(currentBuilding.owners) ||
        isNil(currentBuilding.units) ||
        isEmpty(currentBuilding.units)
      ) {
        return result;
      }
      //  Get units of building
      const currentUnits = currentBuilding.units.map((u) => getUnit(u.id)).filter((u) => !isNil(u));
      const currentBuildingRentalYield = calculateGrossRentalYield(
        currentUnits as Unit[],
        [currentBuilding],
        timeWindow,
        getUnitValuations,
        getBuildingValuations,
        leasePriceHistories ?? [],
        discounts ?? []
      );

      if (currentBuildingRentalYield === 0) {
        return result;
      }
      result.push({ name: currentBuilding.name, rentalYield: currentBuildingRentalYield });
      return result;
    }, [] as { name: string; rentalYield: number }[]);

    const top5Building = getTopElementsBasedOnProperty<{ name: string; rentalYield: number }>(
      buildingsWithRentalYield,
      'rentalYield',
      5
    );
    return top5Building.map((building) => ({
      name: building.name,
      primaryText: formatNumber(building.rentalYield, language, {
        style: 'percent',
        maximumSignificantDigits: 3,
      }),
    }));
  }, [getUnit, language, leasePriceHistories, discounts, getUnitValuations, getBuildingValuations, buildings]);

  return (
    <RankingBox
      boxKey={TOP_BUILDINGS_RENTAL_YIELD_BOX_KEY}
      title={formatMessage({ id: 'dashboard.topBuildingsRentalYield.title' })}
      infoContent={
        <>
          <Typography style={{ fontSize: 12 }}>
            {formatMessage({ id: 'dashboard.topBuildingsRentalYield.info' }, { numberOfYears: 5 })}
          </Typography>
          <Typography style={{ fontSize: 12 }}>
            {formatMessage({ id: 'dashboard.topBuildingsRentalYield.warning' })}
          </Typography>
          <Typography style={{ fontWeight: 700 }} display="inline">
            {`${formatMessage({
              id: 'dashboard.keyFigures.rentalYield',
            })} `}
          </Typography>
          <Typography display="inline">{`${formatMessage({
            id: 'dashboard.keyFigures.rentalYieldHelper1',
          })} `}</Typography>
          <Typography style={{ fontWeight: 700 }} display="inline">
            {`${formatMessage({
              id: 'dashboard.keyFigures.expectedRentalIncome',
            }).toLowerCase()}`}
          </Typography>
          <Typography display="inline">{` ${formatMessage({
            id: 'dashboard.keyFigures.rentalYieldHelper2',
          })} `}</Typography>
          <Typography display="inline" style={{ fontWeight: 700 }}>
            {`${formatMessage({
              id: 'dashboard.keyFigures.propertyValue',
            })}`}
          </Typography>
          <Typography display="inline">{` ${formatMessage({
            id: 'dashboard.keyFigures.rentalYieldHelper3',
          })}`}</Typography>
          <Typography style={{ marginTop: 5 }}>
            {`${formatMessage({
              id: 'dashboard.keyFigures.rentalYieldHelper4',
            })}`}
          </Typography>
          <Typography>
            {`${formatMessage({
              id: 'dashboard.keyFigures.rentalYieldHelper5',
            })}`}
          </Typography>
        </>
      }
      datas={datas}
      forceUpdateLayout={forceUpdateLayout}
      loading={loading}
    />
  );
};

export default DashboardRankingTopBuildingsRentalYeld;
