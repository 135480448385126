import {
  ClickAwayListener,
  Divider,
  FilledInput,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  Popper,
  Tab,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Add, Clear } from '@material-ui/icons';
import { Autocomplete, AutocompleteCloseReason } from '@material-ui/lab';
import { Colors, Contact, ContactType } from '@rentguru/commons-utils';
import { BOX_SHADOW, CustomizedTabs } from '@up2rent/ui';
import { isNil } from 'lodash';
import isEqual from 'lodash/isEqual';
import { CSSProperties, Dispatch, MouseEvent, SetStateAction, useState } from 'react';
import { useIntl } from 'react-intl';
import AddContact from 'src/components/Contacts/AddContact';
import { stopPropagation } from 'src/components/Dashboard/Dashboard';
import Dialog from 'src/components/ui/Dialog';
import { ReactComponent as DropDown } from 'src/icons/drop-down.svg';
import { ReactComponent as Search } from 'src/icons/search.svg';
import { CustomMenuItemType, getOptionLabelFromCustomMenuItemType, useCustomizedComboStyles } from './TextComboBox';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: {
      border: 'none',
      boxShadow: BOX_SHADOW,
      borderRadius: 12,
      width: 320,
      zIndex: 2000, // Be on top also in dialog
      backgroundColor: Colors.CLASSICAL_WHITE,
      padding: '15px 0 0px',
    },
    popperDisablePortal: {
      position: 'relative',
    },
    inputBase: {
      paddingRight: 10,
      paddingLeft: 10,
      width: '100%',
      borderBottom: 'none',
      '& input': {
        backgroundColor: theme.palette.common.white,
        padding: 10,
        border: 'none',
        fontSize: 14,
        textColor: '78909c',
      },
    },
    paper: {
      boxShadow: 'none',
      margin: 0,
    },
  })
);

interface TabData {
  tabLabel: string;
  tabKey: string;
  datas: CustomMenuItemType[];
  noOptionsText?: string;
  addContactOption?: {
    enable: boolean;
    getMenuItemOption: (contact: Contact) => CustomMenuItemType;
    contactType: ContactType;
  };
}

interface CustomizedComboBoxWithTabsCommonsProps {
  datas: TabData[];
  onChange: (event: React.ChangeEvent<{}> | null, tabKey: string, value: CustomMenuItemType | undefined) => void;
  // If you set the grouped value to true, the data that you pass must have a group field
  grouped?: boolean;
  // This function is used when you want to sort the groups between each other e.g. suggested group before the rest
  // default sort is alphabetic sort
  groupCmpFunction?: (groupA: string, groupB: string) => number;
  initialValue?: { tabKey: string; value: CustomMenuItemType };
  popperStyle?: CSSProperties;
  selectedValue?: CustomMenuItemType;
  title?: string;
}

interface CustomizedComboBoxWithTabsPopperProps extends CustomizedComboBoxWithTabsCommonsProps {
  anchorEl: null | HTMLElement;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
  setSelectedValue?: Dispatch<SetStateAction<CustomMenuItemType | undefined>>;
}

export const CustomizedComboBoxWithTabsPopper: React.FC<CustomizedComboBoxWithTabsPopperProps> = ({
  datas,
  onChange,
  grouped = false,
  groupCmpFunction,
  initialValue,
  popperStyle = {},
  anchorEl,
  setAnchorEl,
  selectedValue,
  setSelectedValue,
  title,
}) => {
  const { formatMessage } = useIntl();
  const classesForTabs = useStyles();
  const classes = useCustomizedComboStyles();
  const [inputValue, setInputValue] = useState<string>('');
  const defaultTabValue = initialValue ? initialValue.tabKey : datas[0].tabKey;
  const [tabValue, setTabValue] = useState<string>(defaultTabValue);
  const [openAddContact, setOpenAddContact] = useState<boolean>(false);

  const sortValuesByPrimaryThenSecondary = (a: CustomMenuItemType, b: CustomMenuItemType) => {
    if (a.isUpper && !b.isUpper) return -1;
    if (!a.isUpper && b.isUpper) return 1;
    if (grouped) {
      // If grouped all values must have a group value
      const groupA = a.group!;
      const groupB = b.group!;
      if (isNil(groupCmpFunction)) {
        // Default sort between groups  alphabetic sort
        if (groupA > groupB) return 1;
        if (groupA < groupB) return -1;
      } else {
        // Else use custom sort
        const cmpResult = groupCmpFunction(groupA, groupB);
        if (cmpResult !== 0) return cmpResult;
      }
    }
    // alphabetic sort by first comparing primary then secondary
    const primaryCmpA = a.primary.toLowerCase();
    const primaryCmpB = b.primary.toLowerCase();
    if (primaryCmpA > primaryCmpB) return 1;
    if (primaryCmpA < primaryCmpB) return -1;
    const secondaryCmpA = !isNil(a.secondary) ? a.secondary : '';
    const secondaryCmpB = !isNil(b.secondary) ? b.secondary : '';
    if (secondaryCmpA >= secondaryCmpB) return 1;
    return -1;
  };

  const handleCloseAutoComplete = (_event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
    if (['toggleInput', 'blur'].includes(reason)) {
      return;
    }
    handleClose();
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    setAnchorEl(null);
  }
  };

  const handleChange = (event: React.ChangeEvent<{}> | null, tabKey: string, value: CustomMenuItemType | undefined) => {
    if (setSelectedValue) {
      setSelectedValue(value);
    }
    onChange(event, tabKey, value);
  };

  const handleTabChange = (newValue: string) => {
    setTabValue(newValue);
  };

  const onCloseAddContact = () => {
    setOpenAddContact(false);
  };

  const getTabDataFromKey = (tabKey: string) => {
    return datas.find((data) => data.tabKey === tabKey);
  };

  const getOptionsForSelectedTab = (tabKey: string) => {
    return getTabDataFromKey(tabKey)!.datas.sort(sortValuesByPrimaryThenSecondary);
  };

  const addContactAfterSubmit = (contact: Contact) => {
    if (!isNil(contact)) {
      const menuItem = getTabDataFromKey(tabValue)?.addContactOption?.getMenuItemOption(contact);
      handleChange(null, tabValue, menuItem);
    }
    onCloseAddContact();
  };

  const open = !isNil(anchorEl);
  const options = getOptionsForSelectedTab(tabValue);
  const lastUpperOption = options.findLast((option) => option.isUpper);

  return (
    <>
      {open && (
        <ClickAwayListener
          onClickAway={() => {
            handleClose();
          }}
        >
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement="bottom-end"
            className={classesForTabs.popper}
            style={popperStyle}
          >
            {title && <Typography className={classes.title}>{title}</Typography>}
            <CustomizedTabs
              handleChange={handleTabChange}
              variant={datas.length <= 2 ? 'fullWidth' : 'scrollable'}
              value={tabValue}
              dynamicDefaultTab
            >
              {datas.map((data) => (
                <Tab label={data.tabLabel} value={data.tabKey} key={data.tabKey} />
              ))}
            </CustomizedTabs>

            <Autocomplete
              open
              onClose={handleCloseAutoComplete}
              clearOnEscape={true}
              options={options}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              groupBy={grouped ? (option) => option.group : (undefined as any)}
              classes={{
                option: classes.option,
                paper: classesForTabs.paper,
                popperDisablePortal: classesForTabs.popperDisablePortal,
              }}
              autoHighlight
              getOptionSelected={(option, value) => !isNil(value) && isEqual(option.value, value.value)}
              getOptionLabel={getOptionLabelFromCustomMenuItemType}
              popupIcon={<DropDown className={classes.icon} />}
              renderOption={(option) => {
                const isLastUpperOption = option.value === lastUpperOption?.value;
                return (
                  <Grid style={{ width: '100%' }}>
                    <Grid
                      container
                      alignContent="space-between"
                      style={{ marginBottom: isLastUpperOption ? 11 : 5, marginTop: 5, width: '100%' }}
                    >
                      <Grid item xs={6}>
                        <Typography className={classes.primaryText}>{option.primary}</Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: 'right' }}>
                        {!isNil(option.secondary) && (
                          <Typography className={classes.secondaryText}>{option.secondary}</Typography>
                        )}
                      </Grid>
                    </Grid>
                    {isLastUpperOption && <Divider style={{ marginBottom: -6 }} />}
                  </Grid>
                );
              }}
              disableClearable={true}
              noOptionsText={
                !isNil(getTabDataFromKey(tabValue)!.noOptionsText)
                  ? getTabDataFromKey(tabValue)!.noOptionsText
                  : formatMessage({ id: 'comboBox.noOptions' })
              }
              value={selectedValue}
              onChange={(event: React.ChangeEvent<{}>, value: CustomMenuItemType) =>
                handleChange(event, tabValue, value)
              }
              inputValue={inputValue}
              onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <>
                  <InputBase
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    className={classesForTabs.inputBase}
                    startAdornment={<Search style={{ fill: Colors.BLUEY }} />}
                    endAdornment={
                      <>
                        <Clear
                          style={{ fill: Colors.BLUEY, cursor: 'pointer' }}
                          onClick={(e) => {
                            handleChange(e, tabValue, undefined);
                            setInputValue('');
                          }}
                        />
                        {getTabDataFromKey(tabValue)?.addContactOption && (
                          <Add
                            style={{ fill: Colors.BLUEY, cursor: 'pointer' }}
                            onClick={(e) => {
                              handleCloseAutoComplete(e, 'escape');
                              setOpenAddContact(true);
                            }}
                          />
                        )}
                      </>
                    }
                  />

                  <Divider color={Colors.SEPARATOR_GREY} />
                </>
              )}
              disablePortal
              renderTags={() => null}
            />
          </Popper>
        </ClickAwayListener>
      )}
      {openAddContact && (
        <Dialog
          open={openAddContact}
          onClose={onCloseAddContact}
          scroll="body"
          disableBackdropClick
          disableEscapeKeyDown
        >
          <AddContact
            afterSubmit={addContactAfterSubmit}
            type={getTabDataFromKey(tabValue)?.addContactOption?.contactType ?? ContactType.CONTRACTOR}
          />
        </Dialog>
      )}
    </>
  );
};

export interface CustomizedComboBoxWithTabsProps extends CustomizedComboBoxWithTabsCommonsProps {
  id: string;
  label: string;
  inputStyle?: CSSProperties;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
}

const CustomizedComboBoxWithTabs: React.FC<CustomizedComboBoxWithTabsProps> = ({
  id,
  datas,
  label,
  inputStyle = {},
  onChange,
  grouped = false,
  groupCmpFunction,
  error = false,
  initialValue,
  disabled = false,
  required = false,
  popperStyle = {},
  title,
}) => {
  const classes = useCustomizedComboStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const defaultSelectedValue = initialValue?.value;
  const [selectedValue, setSelectedValue] = useState<CustomMenuItemType | undefined>(defaultSelectedValue);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = !isNil(anchorEl);
  return (
    <>
      <FormControl fullWidth margin="dense" style={inputStyle} error={error} disabled={disabled} required={required}>
        <InputLabel margin="dense" variant="filled" htmlFor={id} style={{ color: Colors.LIGHT_BLUE_GREY }}>
          {label}
        </InputLabel>
        <FilledInput
          onMouseDown={stopPropagation}
          id={id}
          readOnly
          classes={{ underline: classes.underline }}
          endAdornment={
            !disabled && (
              <DropDown style={{ fill: Colors.BLUEY, transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }} />
            )
          }
          onClick={handleClick}
          value={selectedValue ? getOptionLabelFromCustomMenuItemType(selectedValue) : ''}
        />
      </FormControl>
      <CustomizedComboBoxWithTabsPopper
        datas={datas}
        onChange={onChange}
        grouped={grouped}
        groupCmpFunction={groupCmpFunction}
        initialValue={initialValue}
        popperStyle={popperStyle}
        setSelectedValue={setSelectedValue}
        selectedValue={selectedValue}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        title={title}
      />
    </>
  );
};

export default CustomizedComboBoxWithTabs;
