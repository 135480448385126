import React, { useState } from 'react';
import { LeaseInventory, LeaseInventoryType } from '@rentguru/commons-utils';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import PreviewFileDialogs from '../../ui/PreviewFileDialog';
import { useFormikContext } from 'formik';
import { ReactComponent as Logo } from '../../../icons/unitPlaceholder.svg';
import { TablePlaceHolder } from '@up2rent/ui';
import { useIntl } from 'react-intl';
import AddFurnitureDialog from '../Dialogs/AddFurnitureDialog';
import { FurnitureInventoryFormik } from '../FurnitureInventoryDetails';
import { FilePreviewDialogBundle } from 'src/components/LeaseInventory/LeaseInventoryEncoding/InventoryEncodings';
import FurnitureStructureCollapse from './FurnitureStructureCollapse';
import IssueDialog from '../Dialogs/IssueDialog';
import { ExtendedLeaseInventoryEncoding, ExtendedUnitInventoryStructure } from 'src/hooks/UnitInventoryContext';

interface FurnitureInventoryEncodingsProps {
  leaseInventory: LeaseInventory;
  readOnly?: boolean;
}

export interface FurnitureIssueDialogBundle {
  encoding: ExtendedLeaseInventoryEncoding;
  structure: ExtendedUnitInventoryStructure;
  afterSubmit: (encoding: ExtendedLeaseInventoryEncoding) => void;
}

const FurnitureInventoryEncodings: React.FC<FurnitureInventoryEncodingsProps> = ({
  leaseInventory,
  readOnly = false,
}) => {
  const { formatMessage } = useIntl();
  const [fileToPreview, setFileToPreview] = useState<FilePreviewDialogBundle>({ file: null, onUpdatedFile: undefined });
  const { values } = useFormikContext<FurnitureInventoryFormik>();
  const [issueFurnitureBundle, setIssueFurnitureBundle] = useState<FurnitureIssueDialogBundle | null>(null);
  const [proposedUnitInventory, setProposedUnitInventory] = useState<string>('');
  const localUnitInventories = values.localUnitInventories;
  const entrance = leaseInventory.inventoryType === LeaseInventoryType.FURNITURES_ENTRANCE;

  return (
    <>
      {localUnitInventories.map((ui, index) => {
        return (
          <FurnitureStructureCollapse
            key={ui.id}
            leaseInventory={leaseInventory}
            completeUnitInventory={ui}
            unitInventoryIndex={index}
            divider={index !== localUnitInventories.length - 1}
            proposeUnitInventory={setProposedUnitInventory}
            encodeFurnitureIssue={setIssueFurnitureBundle}
            previewFileFunction={setFileToPreview}
            readOnly={readOnly}
          />
        );
      })}
      {isEmpty(localUnitInventories) && (
        <TablePlaceHolder
          Icon={<Logo width={72} />}
          mainText={formatMessage({ id: 'leaseInventory.roomPlaceholder.mainText' })}
          subText={formatMessage({ id: 'leaseInventory.roomPlaceholder.subText' }, { readOnly: readOnly || !entrance })}
        />
      )}
      {!readOnly && entrance && (
        <AddFurnitureDialog
          open={!isEmpty(proposedUnitInventory)}
          onClose={() => setProposedUnitInventory('')}
          proposedUnitInventories={values.localUnitInventories}
          unitInventoryId={proposedUnitInventory}
        />
      )}
      <PreviewFileDialogs
        open={!isNil(fileToPreview.file)}
        file={fileToPreview.file}
        onClose={() => setFileToPreview({ file: null, onUpdatedFile: undefined })}
        editFunction={readOnly || !entrance ? undefined : fileToPreview.onUpdatedFile}
      />
      {!isNil(issueFurnitureBundle) && (
        <IssueDialog
          afterSubmit={issueFurnitureBundle.afterSubmit}
          onClose={() => {
            setIssueFurnitureBundle(null);
          }}
          open={!isNil(issueFurnitureBundle)}
          encoding={issueFurnitureBundle.encoding}
          structure={issueFurnitureBundle.structure}
        />
      )}
    </>
  );
};

export default FurnitureInventoryEncodings;
