import { format, isSameMonth } from 'date-fns';
import { useFormikContext } from 'formik';
import { get, isNil } from 'lodash';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Button, Grid, Typography } from '@material-ui/core';
import {
  Colors,
  VariousOperationType,
  formatNumber,
  getLocaleFromLanguage,
  removeIndexFromArray,
} from '@rentguru/commons-utils';

import { AddLeaseFormValues } from 'src/components/Leases/AddLease/AddLeaseForm';
import { resolveMainUnitAndSubUnitIndexAndPrefix } from 'src/components/Leases/AddLease/useAddEditLeaseUtils';
import { CustomIconButton , CustomPopover } from '@up2rent/ui';
import { isLeaseOperationValid } from 'src/components/ui/Dialogs/AddLeaseVariousOperationDialog';
import { OperationFormValue } from 'src/components/ui/Forms/FormField/AddOperation';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as ArrowRight } from 'src/icons/arrow-right.svg';
import { ReactComponent as More } from 'src/icons/more.svg';

interface RentOperationRowProps {
  unitId: string;
  operation: OperationFormValue;
  lvoIndex: number;
  last: boolean;
  discount: boolean;
  error: boolean;
  dataTest?: string;

  setUpdatingLeaseVariousOperationForAddLease: (value: { operation: OperationFormValue; index: number }) => void;
}

const RentOperationRow: React.FC<RentOperationRowProps> = ({
  unitId,
  operation,
  lvoIndex,
  last,
  setUpdatingLeaseVariousOperationForAddLease,
  discount,
  error,
  dataTest = '',
}) => {
  const { values, setFieldValue } = useFormikContext<AddLeaseFormValues>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { formatMessage } = useIntl();
  const { language } = useLocale();

  const { prefixFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(values, unitId);

  const leaseStartDate = get(values, `${prefixFieldName}startDate`);
  const leaseEndDate = get(values, `${prefixFieldName}endDate`);
  const operationStartDate = new Date(operation.startDate!);
  const operationEndDate = new Date(operation.endDate!);

  const hasError =
    !discount &&
    !isLeaseOperationValid(
      new Date(leaseStartDate),
      new Date(leaseEndDate),
      operationStartDate,
      operationEndDate,
      values.applyVat,
      operation.vatRate
    );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // eslint-disable-next-line no-undef
  const locale: Locale = getLocaleFromLanguage(language);

  const fieldName = `${prefixFieldName}${discount ? 'discounts' : 'leaseOperations'}`;

  const handleRemoveLVO = () => {
    const actualList = get(values, fieldName);
    const newList = removeIndexFromArray(lvoIndex, actualList);
    setFieldValue(fieldName, newList);
  };

  return (
    <Grid
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        borderBottom: !last ? `1px solid ${Colors.GEYSER_GREY}` : 'none',
        backgroundColor: `${hasError || error ? 'rgba(252, 92, 101, .15)' : Colors.CLASSICAL_WHITE}`,
      }}
    >
      <Grid
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '30%',
          paddingLeft: 20,
          borderRight: `1px solid ${Colors.GEYSER_GREY}`,
          height: 60,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        <Typography
          style={{
            textAlign: 'left',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: 'calc(100% - 15px)',
            display: 'block',
            overflow: 'hidden',
          }}
          data-test={`${dataTest}-label`}
        >
          {operation.label}
        </Typography>
      </Grid>
      <Grid
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '42%',
          paddingLeft: 15,
          paddingRight: 5,
          borderRight: `1px solid ${Colors.GEYSER_GREY}`,
          height: 60,
          flexDirection: 'column',
        }}
      >
        {!isSameMonth(operationStartDate, operationEndDate) ? (
          <>
            <Grid style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
              <Typography
                style={{ textAlign: 'left', color: Colors.DARK, marginRight: 10 }}
                data-test={`${dataTest}-startDate`}
              >
                {format(operationStartDate, 'MMMM yyyy', { locale })}
              </Typography>
              <ArrowRight fill={Colors.DARK} />
              {operation.type === VariousOperationType.END_WITH_LEASE && (
                <Typography
                  style={{ textAlign: 'left', color: Colors.DARK, marginLeft: 10 }}
                  data-test={`${dataTest}-endDate`}
                >
                  {formatMessage({ id: 'lease.detail.action.endWithLease' })}
                </Typography>
              )}
              {operation.type !== VariousOperationType.END_WITH_LEASE && (
                <Typography
                  style={{ textAlign: 'left', color: Colors.DARK, marginLeft: 10 }}
                  data-test={`${dataTest}-endDate`}
                >
                  {format(operationEndDate, 'MMMM yyyy', { locale })}
                </Typography>
              )}
            </Grid>
            <Typography style={{ textAlign: 'left', color: Colors.BLUEY, marginRight: 20 }}>
              {formatMessage({ id: `enums.LeasePaymentFrequency.${operation.paymentFrequency}` })}
            </Typography>
          </>
        ) : (
          <>
            <Typography
              style={{ textAlign: 'left', color: Colors.DARK, marginRight: 10, marginTop: 20 }}
              data-test={`${dataTest}-oneDate`}
            >
              {format(operationStartDate, 'MMMM yyyy', { locale })}
            </Typography>
            <Typography style={{ textAlign: 'left', color: Colors.BLUEY, marginRight: 20 }}>
              {formatMessage({ id: `settings.variousOperations.ONE_TIME` })}
            </Typography>
          </>
        )}
      </Grid>
      <Grid
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          width: '28%',
          height: 60,
        }}
      >
        <Grid style={{ width: '50%' }}>
          <Typography style={{ textAlign: 'left', marginLeft: 20 }} data-test={`${dataTest}-amount`}>
            {formatNumber(Number(operation.amount), values.language as string, {
              style: 'currency',
              currency: 'EUR',
            })}
          </Typography>
        </Grid>
        <Grid style={{ width: '50%', textAlign: 'right', paddingRight: 10 }}>
          <CustomIconButton
            onClick={handleClick}
            data-test={`${dataTest}-Button`}
            iconStyle={{ fill: Colors.TOWER_GREY }}
            Icon={More}
          />
        </Grid>
        <CustomPopover
          open={!isNil(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          paperPadding={0}
          paperWidth={'unset'}
          enablePointerEvents
        >
          <Grid style={{ width: 150 }}>
            <Button
              style={{ width: '100%', height: 40 }}
              onClick={() => {
                setUpdatingLeaseVariousOperationForAddLease({ operation, index: lvoIndex });
                setAnchorEl(null);
              }}
              data-test={`${dataTest}-EditButton`}
            >
              <Typography>{formatMessage({ id: 'edit' })}</Typography>
            </Button>
            <Button
              style={{ width: '100%', height: 40 }}
              onClick={() => {
                handleRemoveLVO();
                setAnchorEl(null);
              }}
              data-test={`${dataTest}-DeleteButton`}
            >
              <Typography>{formatMessage({ id: 'delete' })}</Typography>
            </Button>
          </Grid>
        </CustomPopover>
      </Grid>
    </Grid>
  );
};

export default RentOperationRow;
