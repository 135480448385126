/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useContext } from 'react';

interface ResetFilterObj {
  [key: string]: () => void;
}

export interface IFilterResetContext {
  setCustomResetFilterObj: (filterName: string, resetFunc: () => void) => void;
  executeResetFilters: () => void;
}

export const FieldFilterResetContext = React.createContext<IFilterResetContext | null>(null);

interface FilterResetProviderProps {
  children: React.ReactNode;
}
export const FieldFilterResetProvider: React.FC<FilterResetProviderProps> = ({ children }) => {
  const [resetFilterObj, setResetFilterObj] = useState<ResetFilterObj>({});

  const setCustomResetFilterObj = (filterName: string, resetFunc: () => void): void => {
    resetFilterObj[filterName] = resetFunc;
    setResetFilterObj(resetFilterObj);
  };

  const executeResetFilters = () => {
    Object.keys(resetFilterObj).forEach((key: string) => {
      resetFilterObj[key](); // Execute every reset function in the context
    });
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const values = {
    setCustomResetFilterObj,
    executeResetFilters,
  };
  return <FieldFilterResetContext.Provider value={values}>{children}</FieldFilterResetContext.Provider>;
};

export const useFieldFilterReset = (): IFilterResetContext => {
  const context = useContext<IFilterResetContext | null>(FieldFilterResetContext);

  if (context === undefined) {
    throw new Error('`useFieldFilterReset` hook must be used within a `FieldFilterResetProvider` component');
  }
  return context as IFilterResetContext;
};
