import { Collapse, Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  CommunicationChannel,
  CommunicationStatus,
  CommunicationType,
  ContactType,
  EntityType,
  FileCategory,
  LeaseAction,
  LeaseActionEndInitiator,
  LeaseActionEndReason,
  LeaseActionHistoryStatus,
  LeaseContact,
  LeaseExtended,
  LeaseMonthlyChargesType,
  LeaseVariousOperation,
  ModelWithVersion,
  Posting,
  Statement,
  StatementStatus,
  TemplateSubType,
  TemplateType,
  Unit,
  UnitLease,
  compileTemplate,
  endOfUTCDay,
  getContactNameFromObject,
  getMainUnit,
  setRegisterHelpers,
} from '@rentguru/commons-utils';
import { CustomSimpleDialog } from '@up2rent/ui';
import { addDays, isAfter, isBefore, isSameDay, startOfDay } from 'date-fns';
import { Form, Formik, FormikHelpers } from 'formik';
import { isEmpty, isNil, isNull } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import AddTenantStatement from 'src/components/Accounting/TenantStatements/AddTenantStatement/AddTenantStatement';
import EndLeaseForm, {
  EndLeaseFormValues,
  getEndLeaseInitialValues,
  getEndLeaseSchema,
  useEndLeaseStyles,
} from 'src/components/Leases/Details/End/EndLeaseForm';
import { RouteDestination } from 'src/components/Routes/Routes';
import CustomizedSwitch from 'src/components/ui/CustomizedSwitch';
import IconnedRemark from 'src/components/ui/IconnedRemark';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { useCommunications } from 'src/hooks/CommunicationsContext';
import { useContacts } from 'src/hooks/ContactsContext';
import { useDocumentCategory } from 'src/hooks/FileCategoryContext';
import { useFiles } from 'src/hooks/FilesContext';
import { useInvoices } from 'src/hooks/InvoicesContext';
import { useLeaseActionHistories } from 'src/hooks/LeaseActionHistoriesContext';
import { fetchLeaseContacts } from 'src/hooks/LeaseContactContext';
import { useLeasePriceHistories } from 'src/hooks/LeasePriceHistoriesContext';
import { useLeaseVariousOperations } from 'src/hooks/LeaseVariousOperationsContext';
import { useLeases } from 'src/hooks/LeasesContext';
import { fetchStatements, getAvailableTenantPostingsForLease, useStatements } from 'src/hooks/StatementContext';
import { useStatementSettings } from 'src/hooks/StatementSettingsContext';
import { useTemplates } from 'src/hooks/TemplatesContext';
import { useTransactions } from 'src/hooks/TransactionsContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { useUser } from 'src/hooks/UserContext';
import { useUsers } from 'src/hooks/UsersContext';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';

interface EndLeaseDialogProps {
  open: boolean;
  lease: LeaseExtended;
  onClose: () => void;
}

const EndLeaseDialog: React.FC<EndLeaseDialogProps> = ({ open, lease, onClose }) => {
  const { formatMessage } = useIntl();
  const classes = useEndLeaseStyles();
  const { push: historyPush } = useHistory();
  const { memberId } = useUser();
  const { getClientContact } = useUsers();
  const { getTemplateOrDefault } = useTemplates();
  // We need the loading in case we are opening dialog right after editing a contact
  const { contactsLoading } = useContacts();
  const { leasesLoading, updateUnitLease, updateLease } = useLeases();
  const { loading: leasePriceHistoriesLoading } = useLeasePriceHistories();
  const {
    loading: leaseVariousOperationLoading,
    getActiveLeaseVariousOperationsFromLease,
    updateLeaseVariousOperation,
  } = useLeaseVariousOperations();
  const { loading: invoicesLoading } = useInvoices();
  const { transactionsLoading } = useTransactions();
  const { statementSettingsLoading } = useStatementSettings(false);
  const { communicationsLoading, createCommunication } = useCommunications();
  const { loading: leaseActionHistoriesLoading, createLeaseActionHistory } = useLeaseActionHistories();
  const { loading: filesLoading, createFile } = useFiles();
  const { createStatement, recalculateStatement } = useStatements(false);
  const { getDocumentCategoryByFileCategory } = useDocumentCategory();
  const { getUnit } = useUnits();
  const { getBuilding } = useBuildings();

  const [existingUnClosedStatement, setExistingUnClosedStatement] = useState<Statement | null | undefined>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [remainingChargePostingsToTreat, setRemainingChargePostingsToTreat] = useState<Posting[] | null>(null);
  const [createStatementOnLease, setCreateStatementOnLease] = useState<{
    afterSubmit: () => Promise<void>;
    endDate: string;
  } | null>(null);

  useEffect(() => {
    const buildExistingUnClosedStatement = async () => {
      const statementsForLease = await fetchStatements('byLeaseId', lease.id);
      setExistingUnClosedStatement(statementsForLease.find((s) => s.status === StatementStatus.TO_VALIDATE));
    };

    const buildRemainingChargePostingsToTreat = async () => {
      const availablePostingsForLease = await getAvailableTenantPostingsForLease(lease.id, lease.endDate);
      setRemainingChargePostingsToTreat(availablePostingsForLease as Posting[]);
    };
    buildExistingUnClosedStatement();
    buildRemainingChargePostingsToTreat();
  }, [lease]);

  const loading =
    leasesLoading ||
    leasePriceHistoriesLoading ||
    leaseVariousOperationLoading ||
    invoicesLoading ||
    transactionsLoading ||
    statementSettingsLoading ||
    communicationsLoading ||
    leaseActionHistoriesLoading ||
    filesLoading ||
    contactsLoading ||
    isNull(existingUnClosedStatement) ||
    isNull(remainingChargePostingsToTreat);
  if (loading && !isSubmitting) {
    return (
      <CustomSimpleDialog
        open={open}
        onClose={onClose}
        onActionButtonClick={() => {}}
        actionButtonLabel={formatMessage({ id: 'lease.detail.action.confirm' })}
        actionButtonDisabled
        secondaryButtonDisabled
        title={formatMessage({ id: 'lease.endExtendLease.titleEnd' }, { name: lease.name })}
        formatMessage={formatMessage}
      >
        <Skeleton />
      </CustomSimpleDialog>
    );
  }

  const initialTemplate = getTemplateOrDefault(TemplateType.LETTER, TemplateSubType.LEASE_TERMINATION, lease.language);
  const initialValues: EndLeaseFormValues = getEndLeaseInitialValues(lease, initialTemplate);
  const clientContact = getClientContact();
  const mainUnit = getMainUnit(lease.units ?? []);
  const completeUnit = getUnit(mainUnit?.id ?? '');
  const completeBuilding = getBuilding(completeUnit?.building?.id ?? '');

  const { unitLeasesWithCompleteUnit, completeUnits } = lease.units?.reduce(
    (acc, unitLease) => {
      if (unitLease?.unit?.id === completeUnit?.id) {
        const unit = { ...completeUnit, building: completeBuilding } as Unit;
        acc.unitLeasesWithCompleteUnit.push({
          ...unitLease,
          mainUnit: true,
          unit,
        });
        acc.completeUnits.push(unit);
        return acc;
      }
      acc.unitLeasesWithCompleteUnit.push(unitLease);
      if (unitLease.unit) {
        acc.completeUnits.push(unitLease.unit);
      }
      return acc;
    },
    { completeUnits: [], unitLeasesWithCompleteUnit: [] } as {
      completeUnits: Unit[];
      unitLeasesWithCompleteUnit: UnitLease[];
    }
  ) ?? { completeUnits: [], unitLeasesWithCompleteUnit: [] };

  const onSubmit = async (values: EndLeaseFormValues, { setStatus }: FormikHelpers<EndLeaseFormValues>) => {
    setIsSubmitting(true);

    const newEndDate = endOfUTCDay(new Date(values.endDate));
    const units = lease?.units ?? [];
    const amount =
      values.amountDue.selectedTab === 'amountOfMonths'
        ? values.amountDue.totalAmount
        : values.amountDue.userTotalAmount;
    const totalAmount = amount > 0 ? amount : undefined;

    // Update unit lease
    const updateUnitLeasePromises = units.reduce((acc, unitLease) => {
      if (!unitLease) {
        return acc;
      }
      if (isAfter(new Date(unitLease.endDate), newEndDate)) {
        acc.push(updateUnitLease(unitLease, { endDate: newEndDate.toISOString() }));
      }
      return acc;
    }, [] as Promise<UnitLease>[]);

    await Promise.all(updateUnitLeasePromises);

    if (!isEmpty(values.files)) {
      const filePromises = values.files.map((file) => {
        if (file.file) {
          const leaseContractEndDocumentCategory = getDocumentCategoryByFileCategory(FileCategory.LEASE_CONTRACT_END);
          return createFile(file.file, EntityType.LEASE, lease.id, leaseContractEndDocumentCategory?.id);
        }
        return Promise.resolve(null);
      });
      await Promise.all(filePromises);
    }

    // Registered Letter - Signature Document
    const shouldCreateRegisteredLetter = values.lessorEndNotice.responsible === 'UP2RENT';
    const shouldBeSentOn = new Date(values.lessorEndNotice.sendDate).toISOString();

    // Lease action
    const historyDetails = units.map((unitLease) => {
      return {
        unitId: unitLease.unit?.id ?? '',
        previousStartDate: unitLease.startDate,
        newStartDate: unitLease.startDate,
        previousEndDate: unitLease.endDate,
        newEndDate: newEndDate.toISOString(),
      };
    });
    const isLessorEnding = values.initiator === LeaseActionEndInitiator.LESSOR;
    const isForOwnUse = isLessorEnding && values.lessorEndNotice.endReason === LeaseActionEndReason.OWN_USE;
    const actionHistoryCreated = await createLeaseActionHistory({
      action: LeaseAction.END_LEASE,
      contactId: memberId!,
      leaseId: lease.id,
      historyDetails,
      initiator: values.initiator,
      comment: values.comment,
      totalAmount,
      totalAmountLabel: values.amountDue.totalAmountLabel,
      endReason: isLessorEnding ? values.lessorEndNotice.endReason : undefined,
      communicationChannel: isLessorEnding ? values.lessorEndNotice.channel : undefined,
      communicationSendDate: isLessorEnding ? shouldBeSentOn : undefined,
      communicationByPlatform: isLessorEnding ? shouldCreateRegisteredLetter : undefined,
      endReasonOwnUseName: isForOwnUse ? values.lessorEndNotice.endReasonOwnUseName : undefined,
      endReasonOwnUseRelation: isForOwnUse ? values.lessorEndNotice.endReasonOwnUseRelation : undefined,
      // If needed, signatureDocumentId will be put in the backend when the signatudeDocument is created.
      // If no totalAmount, we don't need to include this amount in last lease invoice so it's already Processed.
      status: totalAmount ? LeaseActionHistoryStatus.TO_PROCESS : LeaseActionHistoryStatus.PROCESSED,
    });

    if (shouldCreateRegisteredLetter) {
      const template = values.template!;
      let templateBody = template.body;
      if (!isNil(templateBody) && templateBody === initialTemplate?.body) {
        setRegisterHelpers();
        const compiledTemplate = compileTemplate(initialTemplate, {
          lease: { ...lease, endDate: newEndDate, units: unitLeasesWithCompleteUnit },
          language: lease.language,
          client: clientContact,
          units: completeUnits,
          leaseActionHistory: actionHistoryCreated,
        });
        templateBody = compiledTemplate.body;
      }
      const tenantFilter = {
        contactRole: { eq: ContactType.TENANT },
      };
      const tenants = await fetchLeaseContacts('byLeaseId', lease.id, tenantFilter);
      const recipientContact: LeaseContact | undefined = tenants.find((tenant) => tenant.contact?.addressId);
      const now = startOfDay(new Date());
      const sendDate = startOfDay(new Date(values.lessorEndNotice.sendDate));
      // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
      await createCommunication({
        channel: CommunicationChannel.REGISTERED_LETTER,
        status: CommunicationStatus.TO_SEND,
        body: templateBody,
        subject: template.subject ?? '',
        expirationDate: shouldBeSentOn,
        toSend: isAfter(now, sendDate) || isSameDay(now, sendDate),
        communicationTemplateId: template.id,
        leaseId: lease.id,
        addressId: recipientContact?.contact?.addressId,
        recipient: getContactNameFromObject(
          lease.tenants.find((currentTenant) => currentTenant.id === recipientContact!.contactId)
        ),
        type: CommunicationType.LEASE_TERMINATION,
      });
    }

    // Lease Update
    await updateLease(lease, {
      canBeExtended: false,
      endDate: newEndDate.toISOString(),
    });

    // Ends Operations if present
    const leaseVariousOperations = getActiveLeaseVariousOperationsFromLease(lease.id);
    if (!isEmpty(leaseVariousOperations)) {
      const leaseVariousOperationsUpdatePromises = leaseVariousOperations?.map((leaseVariousOperation) => {
        const newLeaseVariousOperationHistories = (leaseVariousOperation.leaseVariousOperationHistories ?? []).map(
          (history) => {
            if (history.periodTo === leaseVariousOperation.endDate) {
              return {
                ...history,
                periodTo: newEndDate.toISOString(),
              };
            }
            return history;
          }
        );

        const initialWithVersion = leaseVariousOperation as ModelWithVersion<LeaseVariousOperation>;
        const updatedOperation = {
          endDate: newEndDate.toISOString(),
          leaseVariousOperationHistories: newLeaseVariousOperationHistories,
          id: initialWithVersion!.id,
          _version: initialWithVersion._version,
        };

        return updateLeaseVariousOperation(updatedOperation);
      });

      await Promise.all(leaseVariousOperationsUpdatePromises ?? []);
    }

    if (values.redirectToStatement && existingUnClosedStatement) {
      historyPush({
        pathname: RouteDestination.VALIDATE_TENANT_STATEMENT,
        state: { statementId: existingUnClosedStatement.id },
      });
    }

    if (
      values.shouldOpenAddStatementDialog &&
      isNil(existingUnClosedStatement) &&
      !isEmpty(remainingChargePostingsToTreat)
    ) {
      setCreateStatementOnLease({
        afterSubmit: async () => {
          setStatus(true);
          setIsSubmitting(false);
          onClose();
        },
        endDate: values.endDate,
      });

      return;
    }

    setStatus(true);
    setIsSubmitting(false);
    onClose();
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={getEndLeaseSchema(lease.startDate)}>
        {({ values, setFieldValue, handleSubmit }) => {
          const formDisabled = !values.initiator;

          const newEndDate = new Date(values.endDate);
          const in20Days = addDays(new Date(), 20);
          const shouldProposeLastStatement = isBefore(newEndDate, in20Days);
          const hasMonthlyProvision = lease.units?.some(
            (unitLease) => unitLease.monthlyChargesType === LeaseMonthlyChargesType.MonthlyProvisioned
          );
          const shouldProposeStatement = !formDisabled && shouldProposeLastStatement;
          const showPendingStatementInfo = shouldProposeStatement && !isNil(existingUnClosedStatement);
          const shouldProposeCreateStatement =
            shouldProposeStatement &&
            isNil(existingUnClosedStatement) &&
            !isEmpty(remainingChargePostingsToTreat) &&
            hasMonthlyProvision;
          const shouldShowStatementInfo =
            !formDisabled && hasMonthlyProvision && !showPendingStatementInfo && !shouldProposeCreateStatement;
          return (
            <Form>
              <CustomSimpleDialog
                open={open}
                onClose={onClose}
                onActionButtonClick={handleSubmit}
                actionButtonDisabled={formDisabled}
                actionButtonLabel={formatMessage({ id: 'lease.detail.action.confirm' })}
                actionButtonLoading={isSubmitting}
                dividerBelowTitle
                title={formatMessage({ id: 'lease.endExtendLease.titleEnd' }, { name: lease.name })}
                formatMessage={formatMessage}
              >
                <Grid style={{ marginTop: 20 }}>
                  <EndLeaseForm lease={lease} />
                  <Collapse in={shouldProposeCreateStatement}>
                    <Typography
                      className={classes.informationBlackTitle}
                      style={{
                        marginTop: 10,
                      }}
                    >
                      {formatMessage({ id: `lease.addLease.statement` })}
                    </Typography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography style={{ fontSize: 15, fontWeight: 500 }}>
                        {formatMessage({ id: 'lease.detail.action.earlyTerminationPostingsToTreatWarning' })}
                      </Typography>
                      <CustomizedSwitch
                        checked={values.shouldOpenAddStatementDialog}
                        onChange={() => {
                          setFieldValue('shouldOpenAddStatementDialog', !values.shouldOpenAddStatementDialog);
                        }}
                        color="primary"
                        disableRipple
                        switchOnText={formatMessage({ id: 'rentalUnit.detail.general.yes' })}
                        switchOffText={formatMessage({ id: 'rentalUnit.detail.general.no' })}
                      />
                    </div>
                  </Collapse>

                  <Collapse in={showPendingStatementInfo}>
                    <Typography
                      className={classes.informationBlackTitle}
                      style={{
                        marginTop: 10,
                      }}
                    >
                      {formatMessage({ id: `lease.addLease.statement` })}
                    </Typography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography style={{ fontSize: 15, fontWeight: 500 }}>
                        {formatMessage({ id: 'lease.detail.action.earlyTerminationStatementWarning' })}
                      </Typography>
                      <CustomizedSwitch
                        checked={values.redirectToStatement}
                        onChange={() => {
                          setFieldValue('redirectToStatement', !values.redirectToStatement);
                        }}
                        color="primary"
                        disableRipple
                        switchOnText={formatMessage({ id: 'rentalUnit.detail.general.yes' })}
                        switchOffText={formatMessage({ id: 'rentalUnit.detail.general.no' })}
                      />
                    </div>
                  </Collapse>

                  <Collapse in={shouldShowStatementInfo}>
                    <IconnedRemark
                      Icon={InfoSvg}
                      noFixWidth
                      style={{
                        marginRight: 0,
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 0,
                      }}
                      message={
                        <Typography className={classes.infoMessageText}>
                          {formatMessage({ id: 'lease.endExtendLease.automaticStatement' })}
                        </Typography>
                      }
                    />
                  </Collapse>
                  <IconnedRemark
                    Icon={InfoSvg}
                    noFixWidth
                    style={{
                      marginRight: 0,
                      marginTop: 10,
                      marginBottom: 10,
                      marginLeft: 0,
                    }}
                    message={
                      <Typography className={classes.infoMessageText}>
                        {formatMessage({ id: 'lease.endExtendLease.noChangeDateWarning' })}
                      </Typography>
                    }
                  />
                </Grid>
              </CustomSimpleDialog>
            </Form>
          );
        }}
      </Formik>
      {createStatementOnLease && (
        <AddTenantStatement
          open={!isNil(createStatementOnLease)}
          onClose={async () => {
            await createStatementOnLease.afterSubmit();
            setCreateStatementOnLease(null);
          }}
          createStatement={createStatement}
          recalculateStatement={recalculateStatement}
          afterSubmit={createStatementOnLease.afterSubmit}
          lease={lease}
          defaultPeriodFrom={lease.startDate}
          defaultPeriodTo={createStatementOnLease.endDate}
          defaultClosing={true}
        />
      )}
    </>
  );
};

export default EndLeaseDialog;
