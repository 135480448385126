/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { subMonths, startOfMonth, endOfMonth, isWithinInterval } from 'date-fns';
import { Menu, MenuItem, Avatar, TableRow, Typography } from '@material-ui/core';
import {
  AgencyRateOwner,
  Unit,
  getContactNameFromObject,
  getFirstLettersUppercase,
  stringToColor,
  getFeesForUnitsWithRateCardDuringPeriod,
  formatNumber,
  Colors,
} from '@rentguru/commons-utils';
import { RouteDestination } from 'src/components/Routes/Routes';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { useContacts } from 'src/hooks/ContactsContext';
import { useSettings } from 'src/hooks/SettingsContext';
import { fetchPostings, getPosting } from 'src/hooks/TransactionsContext';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { EnhanceTableRowProps, SmallHeightCustomTableCell , MenuItemText } from '@up2rent/ui';
import UnitNumbersPicto from 'src/components/ui/UnitNumbersPicto';
import SkeletonComponent from 'src/components/ui/SkeletonComponent';
import MoreHorizontalIconButton from 'src/components/ui/CustomIconButtons/MoreHorizontalIconButton';

interface AgencyRateOwnersRowProps extends AgencyRateOwner, EnhanceTableRowProps {
  units: Unit[];
  isSelected: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  selectable?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  handleSelect?: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  handleDetail?: (id: string) => void;
  align?: 'left' | 'center' | 'right';
}

const AgencyRateOwnersRow: React.FC<AgencyRateOwnersRowProps> = ({
  isSelected,
  id,
  owner,
  units,
  agencyRate,
  handleDetail = () => {},
  align = 'left',
  actionMenu,
}) => {
  const [fees, setFees] = useState<number>(0);
  const { buildingsLoading } = useBuildings();
  const { getContact } = useContacts();
  const { agencyRateDelete } = usePermissions();
  const { language } = useLocale();
  const { agencyFeesVat } = useSettings();

  const { formatMessage } = useIntl();
  const [anchorMore, setAnchorMore] = useState<HTMLElement | null>(null);
  const history = useHistory();

  useEffect(() => {
    const getFees = async () => {
      const lastMonth = subMonths(new Date(), 1);
      const periodFrom = startOfMonth(lastMonth);
      const periodTo = endOfMonth(lastMonth);
      const interval = { start: periodFrom, end: periodTo };
      const getPostingsOfLease = async (leaseId: string, unitId: string) => {
        const leasePostings = await fetchPostings('byLease', leaseId, { invoiceId: { attributeExists: true } });
        return leasePostings.filter((posting) => {
          if (posting.unitId !== unitId) {
            return false;
          }
          if (posting.transaction && posting.transaction.statementDate) {
            return isWithinInterval(new Date(posting.transaction.statementDate), interval);
          }
          if (posting.invoice && posting.invoice.dueDate) {
            return isWithinInterval(new Date(posting.invoice.dueDate), interval);
          }
          return false;
        });
      };
      const fees = await getFeesForUnitsWithRateCardDuringPeriod(
        units,
        agencyRate!,
        periodFrom,
        periodTo,
        agencyFeesVat,
        getPostingsOfLease,
        getPosting
      );
      setFees(fees);
    };
    getFees();
  }, [agencyRate, units, agencyFeesVat]);

  if (!owner) {
    return null;
  }
  const contact = getContact(owner.id);
  if (!contact) {
    return null;
  }

  const name = getContactNameFromObject(owner);
  const color = stringToColor(name);

  return (
    <TableRow
      hover
      aria-checked={isSelected}
      tabIndex={-1}
      key={id}
      selected={isSelected}
      onClick={() => {
        handleDetail(id);
      }}
    >
      <SmallHeightCustomTableCell component="th" scope="row" align={align}>
        <div
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            history.push({
              pathname: `${RouteDestination.CONTACTS}/${owner.id}`,
              state: { goBackUrl: history.location },
            });
          }}
        >
          <Avatar
            style={{
              color: `${color}`,
              width: 30,
              height: 30,
              backgroundColor: Colors.PORCELAIN_GREY_3,
              fontSize: 11,
              fontWeight: 800,
              fontStyle: 'normal',
              letterSpacing: 0,
              textAlign: 'center',
              marginRight: 10,
            }}
          >
            {getFirstLettersUppercase(name)}
          </Avatar>
          <Typography
            style={{
              fontSize: 14,
              fontWeight: 600,
              fontStyle: 'normal',
              letterSpacing: 0,
              color: Colors.CLASSICAL_BLACK,
            }}
          >
            {name}
          </Typography>
        </div>
      </SmallHeightCustomTableCell>
      <SmallHeightCustomTableCell component="th" scope="row" align={align}>
        <SkeletonComponent loading={buildingsLoading}>
          <UnitNumbersPicto value={units.length} />
        </SkeletonComponent>
      </SmallHeightCustomTableCell>
      <SmallHeightCustomTableCell component="th" scope="row" align={align}>
        {formatNumber(fees, language, { style: 'currency', currency: 'EUR' })}
      </SmallHeightCustomTableCell>

      <SmallHeightCustomTableCell scope="row" align="right">
        {agencyRateDelete && !agencyRate?.custom && (
          <>
            <MoreHorizontalIconButton
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                setAnchorMore(event.currentTarget);
              }}
            />
            <Menu
              id="more-menu"
              anchorEl={anchorMore}
              keepMounted
              open={Boolean(anchorMore)}
              onClose={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                event.stopPropagation();
                event.preventDefault();
                setAnchorMore(null);
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setAnchorMore(null);
                  if (actionMenu) {
                    actionMenu(event, id);
                  }
                }}
              >
                <MenuItemText primary={formatMessage({ id: 'delete' })} deleteOption />
              </MenuItem>
            </Menu>
          </>
        )}
      </SmallHeightCustomTableCell>
    </TableRow>
  );
};

export default AgencyRateOwnersRow;
