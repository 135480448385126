/* eslint-disable import/named */
import React from 'react';

import clsx from 'clsx';

import Paper, { PaperProps } from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    borderRadius: 12,
    textAlign: 'center',
    marginBottom: 30,
    overflowX: 'auto',
    boxShadow: '0 5px 25px 0 rgba(0, 0, 0, 0.15)',
  },
  padding: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

interface ElevatedPaperProps extends PaperProps {
  removePadding?: boolean;
}

export const ElevatedPaper: React.FC<ElevatedPaperProps> = ({ className, removePadding, children, style, ...rest }) => {
  const classes = useStyles();
  return (
    <Paper
      className={clsx(classes.paper, removePadding ?? classes.padding, className)}
      style={style || {}}
      elevation={10}
      {...rest}
    >
      {children}
    </Paper>
  );
};
