/* eslint-disable @typescript-eslint/no-shadow */
import { CircularProgress, Collapse, Divider, Grid } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Colors, DashboardBoxType } from '@rentguru/commons-utils';
import isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { Layout } from 'react-grid-layout';
import { stopPropagation } from 'src/components/Dashboard/Dashboard';
import { CustomIconButton, CustomPopover, ElevatedPaper , OverflowableTypography } from '@up2rent/ui';
import { useDashboardFilters } from 'src/hooks/DashboardFiltersContext';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import RankingLoader from 'src/components/Dashboard/DashboardLoader';
import BoxSwitchFilters, { BoxSwitchFiltersProps } from 'src/components/Dashboard/Filters/BoxSwitchFilters';
import RankingTable, { RankingData } from './RankingTable';

export interface RankingBoxLayoutProps {
  forceUpdateLayout?: (newLayout: Layout[]) => void;
}

interface RankingBoxProps extends RankingBoxLayoutProps {
  boxKey: string;
  title: string;
  infoContent?: React.ReactNode;
  filtersBundle?: BoxSwitchFiltersProps;
  datas: RankingData[];
  loading?: boolean;
  minHeight?: number;
  children?: React.ReactNode;
}

const RankingBox: React.FC<RankingBoxProps> = ({
  boxKey,
  title,
  infoContent,
  filtersBundle,
  datas,
  loading = false,
  forceUpdateLayout,
  children,
  minHeight = 300,
}) => {
  const { getBoxCollapse, saveNewBoxCollapse } = useDashboardFilters();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [popoverOpen, setPopoverOpen] = useState<SVGSVGElement | null>(null);

  const expandIconStyle = (collapsed: boolean): object => ({
    transform: `rotate(${collapsed ? 0 : 180}deg)`,
    fill: Colors.DARK_SLATE_GREY,
  });

  useEffect(() => {
    const setBoxCollapsed = async () => {
      const collapsed = await getBoxCollapse(boxKey, DashboardBoxType.RANKING);
      setCollapsed(collapsed);
    };
    setBoxCollapsed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxKey]);

  if (loading) {
    return <RankingLoader width="100%" />;
  }

  return (
    <ElevatedPaper style={{ overflow: 'visible', paddingBottom: 32 }}>
      <Grid container style={{ alignItems: 'center' }}>
        <Grid item xs={10} style={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-start' }}>
          <OverflowableTypography
            text={title}
            style={{
              fontSize: '16px',
              fontWeight: 700,
              marginLeft: 20,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          />
          {infoContent && (
            <InfoSvg
              style={{ fill: Colors.SILVER, marginLeft: 10, cursor: 'default' }}
              onMouseEnter={(e) => setPopoverOpen(e.currentTarget)}
              onMouseLeave={() => setPopoverOpen(null)}
            />
          )}
        </Grid>
        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CustomIconButton
            style={{ fill: Colors.BLUEY, marginRight: 20 }}
            size="small"
            onMouseDown={stopPropagation}
            onClick={async (e) => {
              e.stopPropagation();
              e.preventDefault();
              await saveNewBoxCollapse(boxKey, !collapsed, DashboardBoxType.RANKING, forceUpdateLayout);
              setCollapsed(!collapsed);
            }}
            Icon={ExpandMore}
            iconStyle={expandIconStyle(collapsed)}
          />
        </Grid>
      </Grid>
      <Collapse in={!collapsed} mountOnEnter unmountOnExit>
        <div style={{ height: 360 }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 20 }} onMouseDown={stopPropagation}>
            {!isNil(filtersBundle) && <BoxSwitchFilters {...filtersBundle} />}
          </div>
          <Divider style={{ marginTop: isNil(filtersBundle) ? 50 : 20 }} />
          {loading && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
              <CircularProgress style={{ color: Colors.DODGER_BLUE }} size={20} />
            </div>
          )}
          {!loading && (
            <div style={{ minHeight }}>
              {children}
              <RankingTable datas={datas} />
            </div>
          )}
        </div>
      </Collapse>
      <CustomPopover
        open={!isNil(popoverOpen)}
        anchorEl={popoverOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setPopoverOpen(null)}
        disableRestoreFocus
        paperBorderRadius={10}
      >
        {infoContent}
      </CustomPopover>
    </ElevatedPaper>
  );
};
export default RankingBox;
