import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Typography, Grid, Divider, Menu, MenuItem } from '@material-ui/core';
import {
  VariousOperation,
  VariousOperationType,
  Colors,
  formatNumber,
  getVariousOperationLabel,
} from '@rentguru/commons-utils';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { MenuItemText } from '@up2rent/ui';
import MoreHorizontalIconButton from 'src/components/ui/CustomIconButtons/MoreHorizontalIconButton';

interface VariousOperationRowProps {
  variousOperation: VariousOperation;
  divider: boolean;
  doAction: (action: 'EDIT' | 'DELETE', id: string) => void;
}
const VariousOperationRow: React.FC<VariousOperationRowProps> = ({ variousOperation, divider = true, doAction }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { formatMessage } = useIntl();
  const { language, langAndCountryFormated } = useLocale();

  const label = getVariousOperationLabel(variousOperation, language);

  const type =
    variousOperation.type === VariousOperationType.RECURRING
      ? `${variousOperation.numberOfMonths!} ${formatMessage({ id: `lease.detail.variousOperations.occurrences` })}`
      : formatMessage({ id: `settings.variousOperations.${variousOperation.type}` });
  const frequency = formatMessage({ id: `enums.LeasePaymentFrequency.${variousOperation.paymentFrequency}` });
  return (
    <>
      <Grid container style={{ marginTop: 15, marginBottom: 15 }} alignItems="center">
        <Grid item xs={6}>
          <Typography variant="h6" style={{ fontSize: 14, fontWeight: 600, textAlign: 'left' }}>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'left' }}>
          <Typography variant="subtitle1" style={{ fontSize: 14, color: Colors.SLATE_GREY }}>
            {formatNumber(variousOperation.amount, langAndCountryFormated, { style: 'currency', currency: 'EUR' })}
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          style={{ display: 'flex', justifyContent: 'left', flexDirection: 'column', alignItems: 'flex-end' }}
        >
          <Typography variant="subtitle1" style={{ fontSize: 14, color: Colors.SLATE_GREY }}>
            {type}
          </Typography>
          <Typography style={{ color: Colors.BLUEY }}>{frequency}</Typography>
        </Grid>
        <Grid item xs={1}>
          <MoreHorizontalIconButton
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setAnchorEl(event.currentTarget);
            }}
            size="small"
            iconStyle={{ verticalAlign: 'middle', fill: Colors.SLATE_GREY }}
          />
        </Grid>
        <Menu
          id="action-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          style={{ transform: 'translateY(40px)' }}
        >
          <MenuItem
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setAnchorEl(null);
              doAction('EDIT', variousOperation.id);
            }}
          >
            <MenuItemText primary={formatMessage({ id: 'edit' })} />
          </MenuItem>

          <MenuItem
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setAnchorEl(null);
              doAction('DELETE', variousOperation.id);
            }}
          >
            <MenuItemText primary={formatMessage({ id: 'delete' })} deleteOption />
          </MenuItem>
        </Menu>
      </Grid>
      {divider && <Divider />}
    </>
  );
};

export default VariousOperationRow;
