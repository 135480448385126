import { ReactComponent as DropDownIcon } from 'src/icons/more.svg';
import { CustomIconButton, CustomIconButtonProps } from '@up2rent/ui';
import { Colors } from '@rentguru/commons-utils';

const DropDownIconButton: React.FC<CustomIconButtonProps> = ({ iconStyle, ...rest }) => (
  <CustomIconButton
    size="small"
    Icon={DropDownIcon}
    iconStyle={{
      verticalAlign: 'middle',
      fill: Colors.BLUEY,
      ...iconStyle,
    }}
    {...rest}
  />
);

export default DropDownIconButton;
