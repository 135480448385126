import React, { useState } from 'react';
import { TableCell, TableRow, Tooltip, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useUnits } from 'src/hooks/UnitsContext';
import isNil from 'lodash/isNil';
import { Colors, getTenantsName, LeaseExtended } from '@rentguru/commons-utils';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { useLocale, dateLocaleMap, dateLocaleMapType } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as EditIcon } from 'src/icons/edit.svg';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { RouteDestination } from 'src/components/Routes/Routes';
import { LEASES_DETAIL_TO } from 'src/components/Leases/Leases';
import { ROW_HEIGHT } from '../DashboardDrafts';
import { CustomIconButton, useOverflowTooltipStyles } from '@up2rent/ui';
import { stopPropagation } from 'src/components/Dashboard/Dashboard';

interface LeaseDraftRowProps {
  lease: LeaseExtended;
}

const LeaseDraftRow: React.FC<LeaseDraftRowProps> = ({ lease }) => {
  const { leasesCreationWrite } = usePermissions();
  const { formatMessage } = useIntl();
  const { getUnit } = useUnits();
  const { language } = useLocale();
  const history = useHistory();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const classes = useOverflowTooltipStyles();
  const leaseUnit = getUnit(lease.units![0].unit!.id);
  if (isNil(leaseUnit)) {
    return (
      <TableRow style={{ minHeight: ROW_HEIGHT, height: ROW_HEIGHT }}>
        <TableCell>
          <Typography>{formatMessage({ id: 'loading' })}</Typography>
        </TableCell>
      </TableRow>
    );
  }
  const leaseBuilding = leaseUnit.building!;
  const destination = `${RouteDestination.LEASES}/${LEASES_DETAIL_TO}/${lease.id}`;
  const tenantText = getTenantsName(lease.tenants);
  return (
    <TableRow
      hover
      style={{ cursor: 'pointer', textDecoration: 'none', minHeight: ROW_HEIGHT, height: ROW_HEIGHT }}
      onClick={() => history.push({ pathname: destination, state: { goBackUrl: history.location } })}
    >
      <TableCell align="left" padding="none" style={{ paddingLeft: 20 }}>
        <Typography style={{ color: Colors.CLASSICAL_BLACK, fontSize: 14, fontWeight: 700 }}>
          {formatMessage({ id: `enums.LeaseType.${lease.type}` })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14, fontWeight: 500 }}>
          {lease.units!.map((u) => u.unit!.name).join(',')}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14, fontWeight: 500 }}>
          {leaseBuilding.name}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        <Tooltip
          open={tooltipOpen}
          onClose={() => setTooltipOpen(false)}
          onOpen={() => {
            if (lease.tenants.length > 1) {
              setTooltipOpen(true);
            }
          }}
          title={tenantText}
          placement="top"
          classes={{ tooltipPlacementTop: classes.tooltipPlacement }}
        >
          <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14, fontWeight: 500 }}>
            {lease.tenants.length > 1
              ? `${lease.tenants.length} ${formatMessage({ id: 'lease.detail.general.tenants' })}`
              : tenantText}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14, fontWeight: 500 }}>
          {!isNil(lease.createdAt)
            ? format(new Date(lease.createdAt), 'd/MM/yyyy', {
                locale: (dateLocaleMap as dateLocaleMapType)[language],
              })
            : null}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        {leasesCreationWrite && (
          <Tooltip title={formatMessage({ id: 'edit' })} placement="top">
            <CustomIconButton
              onMouseDown={stopPropagation}
              onClick={(event) => {
                event.stopPropagation();
                history.push({
                  pathname: RouteDestination.EDIT_LEASE,
                  state: { id: lease.id },
                });
              }}
              size="small"
              Icon={EditIcon}
              iconStyle={{ fill: Colors.DODGER_BLUE }}
            />
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default LeaseDraftRow;
