/* eslint-disable @typescript-eslint/no-shadow */
import { MenuList } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { UnitLease } from '@rentguru/commons-utils';
import { isNil } from 'lodash';
import { useIntl } from 'react-intl';
import { generatePath, useLocation, useRouteMatch } from 'react-router-dom';
import { ExitableLocation, RouteDestination } from 'src/components/Routes/Routes';
import CustomizedMenuItem from 'src/components/ui/CustomizedMenuItem';
import EntityDetailSubMenu from 'src/components/ui/EntityDetail/EntityDetailSubMenu';
import {
  LeaseUnitsDetailNavItem,
  UNIT_TO_GENERAL,
  getDetailUrlForUnit,
  getUnitPrefixUrl,
} from 'src/components/Leases/Details/leaseDetailRouteUtils';

const UNITS_NUMBER_UPPER_BOUND_TO_OPEN_MENU = 5;

interface LeaseUnitSubMenusProps {
  unitLeases: UnitLease[];
  loading: boolean;
  leaseNavItems: LeaseUnitsDetailNavItem[];
  specificUnitNavItems: {
    unitLease: UnitLease;
    navItems: LeaseUnitsDetailNavItem[];
  }[];
  isTenant?: boolean;
}

export const LeaseUnitSubMenus: React.FC<LeaseUnitSubMenusProps> = ({
  loading,
  unitLeases,
  specificUnitNavItems,
  isTenant = false,
}) => {
  const { formatMessage } = useIntl();
  const match = useRouteMatch<{ id: string; detailName: string; nestedDetailName?: string }>({
    path: `${RouteDestination.LEASES}/${isTenant ? '' : 'detail/'}:id?/:detailName?/:nestedDetailName?`,
  });
  const { state: exitParams } = useLocation<ExitableLocation>();

  if (loading || !match) {
    return <Skeleton style={{ marginRight: 15, marginLeft: 15 }} />;
  }

  return (
    <>
      {unitLeases.map((unitLease) => {
        const unit = unitLease.unit;
        if (!unit) {
          return null;
        }
        const currentSpecificNavItems = specificUnitNavItems.find(
          (unitNavItems) => (unitNavItems.unitLease.unit?.id ?? '') === (unitLease.unit?.id ?? '')
        );
        const prefixUrl = getUnitPrefixUrl(unit);
        const detailUrl = getDetailUrlForUnit(prefixUrl, match, isTenant);
        const selected = !isNil(match.params.detailName) && match.params.detailName === prefixUrl;

        const baseLeaseUrl = generatePath(`${RouteDestination.LEASES}/${isTenant ? '' : 'detail/'}:id/`, {
          id: match.params.id,
        });

        return (
          <EntityDetailSubMenu
            key={detailUrl}
            to={`${detailUrl}/${UNIT_TO_GENERAL}`}
            baseUrl={baseLeaseUrl}
            selected={selected}
            historyProps={{ state: exitParams }}
            title={unit.name}
            description={formatMessage(
              { id: `lease.addLease.${unitLease.mainUnit ? 'mainUnit' : 'subUnit'}` },
              { value: 1 }
            )}
            isTenant={isTenant}
            open={unitLeases.length <= UNITS_NUMBER_UPPER_BOUND_TO_OPEN_MENU}
          >
            <MenuList id="leaseUnitsDetailsSection">
              {!isNil(currentSpecificNavItems) &&
                currentSpecificNavItems.navItems.map((navItem) => {
                  if (!navItem.permission) {
                    return null;
                  }
                  const prefixUrl = getUnitPrefixUrl(unit);
                  const to = generatePath(
                    `${RouteDestination.LEASES}/${isTenant ? '' : 'detail/'}:id/:detailName?/:nestedDetailName?`,
                    {
                      id: match.params.id,
                      detailName: prefixUrl,
                      nestedDetailName: navItem.to,
                    }
                  );
                  const selected =
                    !isNil(match.params.nestedDetailName) &&
                    match.params.nestedDetailName === navItem.to &&
                    match.params.detailName === prefixUrl;

                  return (
                    <CustomizedMenuItem
                      key={to}
                      to={to}
                      selected={selected}
                      historyProps={{ state: exitParams }}
                      isTenant={isTenant}
                    >
                      {formatMessage({ id: navItem.labelId }, { value: navItem.value })}
                    </CustomizedMenuItem>
                  );
                })}
            </MenuList>
          </EntityDetailSubMenu>
        );
      })}
    </>
  );
};

export default LeaseUnitSubMenusProps;
