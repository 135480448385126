/* eslint-disable default-case */
import React, { CSSProperties } from 'react';
import { useIntl } from 'react-intl';
import { isNil, toLower } from 'lodash';
import { formatDistanceToNow } from 'date-fns/esm';
import { Grid, ListItem, Typography } from '@material-ui/core';
import { GenerateReportType, CustomSubscription, Colors, getLocaleFromLanguage } from '@rentguru/commons-utils';
import { useCustomSubscriptions } from 'src/hooks/CustomSubscriptionContext';
import { useUser } from 'src/hooks/UserContext';
import { ReactComponent as OperationalIcon } from 'src/icons/OperationalPerformanceReportIcon.svg';
import { ReactComponent as ChargesIcon } from 'src/icons/ChargesReportIcon.svg';
import { ReactComponent as IncomeIcon } from 'src/icons/IncomeReportIcon.svg';
import { ReactComponent as FeesIcon } from 'src/icons/FeesReportIcon.svg';
import { ReactComponent as TenantsAgeingBalanceIcon } from 'src/icons/TenantsAgeingBalanceReportIcon.svg';
import { ReactComponent as SupplierReportIcon } from 'src/icons/supplierReportIcon.svg';
import NotificationDeleteIconButton from 'src/components/ui/CustomIconButtons/NotificiationDeleteIconButton';
import { useNotificationListStyle } from './NotificationsList';

const getReportIconAccordingToType = (type: GenerateReportType, style: CSSProperties) => {
  switch (type) {
    case GenerateReportType.CHARGES:
      return <ChargesIcon style={style} />;
    case GenerateReportType.FEES:
      return <FeesIcon style={style} />;
    case GenerateReportType.OPERATIONAL_PERFORMANCE:
      return <OperationalIcon style={style} />;
    case GenerateReportType.SUPPLIER_DEBTS:
      return <SupplierReportIcon style={style} />;
    case GenerateReportType.TENANTS_AGING_BALANCE:
      return <TenantsAgeingBalanceIcon style={style} />;
    case GenerateReportType.TURNOVER:
      return <IncomeIcon style={style} />;
  }
};

interface NotificationsReportItemProps {
  reportUrl: string;
  reportType: GenerateReportType;
  fileName: string;
  customSubscription: CustomSubscription;
}

const NotificationsReportItem: React.FC<NotificationsReportItemProps> = ({
  reportUrl,
  reportType,
  fileName,
  customSubscription,
}) => {
  const { formatMessage } = useIntl();
  const localStyles = useNotificationListStyle();

  const { language } = useUser();
  const { deleteCustomSubscription } = useCustomSubscriptions();

  const reportName = toLower(formatMessage({ id: `enums.GenerateReportType.${reportType.toUpperCase()}` }));

  return (
    <ListItem className={localStyles.listItem}>
      <Grid>
        <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            {getReportIconAccordingToType(reportType, {
              transform: 'scale(0.6)',
            })}
            <Typography variant="h6" style={{ color: Colors.CLASSICAL_BLACK }}>
              {formatMessage({ id: 'accountMenu.reportReadyPrimary' })}
            </Typography>
          </Grid>
          <NotificationDeleteIconButton
            onClick={() => {
              deleteCustomSubscription(customSubscription);
            }}
          />
        </Grid>
        {!isNil(customSubscription.createdAt) && (
          <Typography style={{ color: Colors.BLUEY, paddingBottom: 5 }}>
            {formatDistanceToNow(new Date(customSubscription.createdAt!), {
              locale: getLocaleFromLanguage(language || 'en'),
            })}
          </Typography>
        )}
        <Typography style={{ color: Colors.SLATE_GREY }}>
          {formatMessage({ id: 'accountMenu.reportReady1' })} <span style={{ fontWeight: 'bold' }}>{reportName}</span>
          {formatMessage({ id: 'accountMenu.reportReady2' })}{' '}
          <span
            style={{
              color: Colors.DODGER_BLUE,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={async () => {
              const a = document.createElement('a');
              a.href = reportUrl;
              a.download = fileName || 'untitled';
              const clickHandler = () => {
                setTimeout(() => {
                  URL.revokeObjectURL(reportUrl);
                  a.removeEventListener('click', clickHandler);
                }, 150);
              };
              a.addEventListener('click', clickHandler, false);
              a.click();
            }}
          >
            {formatMessage({ id: 'accountMenu.reportReady3' }, { reportName })}
          </span>
        </Typography>
      </Grid>
    </ListItem>
  );
};

export default NotificationsReportItem;
