import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ButtonGroup, Button, IconButton, Typography, TextField } from '@material-ui/core';
import { ReactComponent as Minus } from '../../../../icons/remove.svg';
import { ReactComponent as Plus } from '../../../../icons/add.svg';
import { withStyles } from '@material-ui/styles';
import isNil from 'lodash/isNil';
import { clamp } from 'lodash';
import { UnitInventory, Colors } from '@rentguru/commons-utils';
import SelectRoomsToBeDeletedDialog from './SelectRoomsToBeDeletedDialog';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& > *': {
        margin: 10,
      },
      marginLeft: 10,
    },
  })
);

const ColorizedIconButton = withStyles(() => ({
  root: {
    backgroundColor: Colors.PORCELAIN_GREY_3,
    padding: 0,
  },
}))(IconButton);

const CssTextField = withStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
        padding: 0,
        margin: 0,
      },
      '& input': {
        paddingLeft: 0,
        paddingRight: 0,
        border: 'none',
        textAlign: 'center',
      },
    },
  },
}))(TextField);

const ColorizedNumberButton = withStyles({
  root: {
    fontSize: 16,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: Colors.CLASSICAL_BLACK,
    boxShadow: 'none',
    backgroundColor: Colors.PORCELAIN_GREY_3,
    borderColor: Colors.PORCELAIN_GREY_3,
    maxWidth: 10,
    '&:disabled': {
      color: Colors.CLASSICAL_BLACK,
      border: 0,
    },
  },
})(Button);

interface PlusMinusSelectableDeleteButtoGroupProps {
  id?: string;
  text?: string;
  onChange?: (value: number, name?: string) => void;
  name?: string;
  initialValue?: number;
  minValue?: number;
  maxValue?: number;
  style?: React.CSSProperties;
  editable?: boolean;
  listOfUnits?: UnitInventory[];
  inside?: boolean;
}

const PlusMinusSelectableDeleteButtonGroup: React.FC<PlusMinusSelectableDeleteButtoGroupProps> = ({
  id,
  text,
  onChange,
  name,
  initialValue = 0,
  minValue,
  maxValue,
  editable = true,
  style = {},
  listOfUnits,
  inside,
}) => {
  const [quantity, setQuantity] = useState<number>(initialValue);
  const [dialogUnitInventoriesOpen, setDialogUnitInventoriesOpen] = useState<boolean>(false);
  const classes = useStyles();

  const increaseQuantity = () => {
    const newQuantity = isNil(maxValue) ? quantity + 1 : Math.min(maxValue, quantity + 1);
    handleNewQuantity(newQuantity);
  };

  const handleMinusClick = () => {
    if (quantity === 0) {
      return;
    }
    if (quantity === 1) {
      handleNewQuantity(0);
      return;
    }
    setDialogUnitInventoriesOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogUnitInventoriesOpen(false);
  };

  const handleNewQuantity = (newQuantity: number) => {
    setQuantity(newQuantity);
    if (!isNil(onChange)) {
      onChange(newQuantity, name);
    }
  };

  return (
    <div className={classes.root} style={style}>
      <ButtonGroup
        size="small"
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
        }}
      >
        <ColorizedIconButton onClick={handleMinusClick} size="small" id={`minus${id}`}>
          <Minus style={{ fill: Colors.BLUE_GREY, objectFit: 'contain' }} />
        </ColorizedIconButton>
        {editable ? (
          <CssTextField
            style={{ width: 40, backgroundColor: Colors.PORCELAIN_GREY_3 }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newQuantityRaw = Number(event.target.value);
              if (!isNaN(newQuantityRaw)) {
                const minQuantity = isNil(minValue) ? newQuantityRaw : minValue;
                const maxQuantity = isNil(maxValue) ? newQuantityRaw : maxValue;
                const newQuantity = clamp(newQuantityRaw, minQuantity, maxQuantity);
                handleNewQuantity(newQuantity);
              }
            }}
            value={quantity}
          />
        ) : (
          <ColorizedNumberButton disableFocusRipple disableRipple disableTouchRipple disabled>
            {quantity}
          </ColorizedNumberButton>
        )}
        <ColorizedIconButton onClick={increaseQuantity} size="small" id={`plus${id}`}>
          <Plus style={{ fill: Colors.BLUE_GREY, objectFit: 'contain' }} />
        </ColorizedIconButton>
      </ButtonGroup>
      {text && (
        <Typography
          style={{
            fontSize: 14,
            fontWeight: 600,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: Colors.CLASSICAL_BLACK,
            marginLeft: 10,
          }}
        >
          {text}
        </Typography>
      )}

      {dialogUnitInventoriesOpen && (
        <SelectRoomsToBeDeletedDialog
          dialogUnitInventoriesOpen={dialogUnitInventoriesOpen}
          handleCloseDialog={handleCloseDialog}
          unitInventoryRoomType={name}
          inside={inside}
          listofUnitsToBePossiblyDeleted={listOfUnits as UnitInventory[]}
          setQuantity={setQuantity}
        />
      )}
    </div>
  );
};

export default PlusMinusSelectableDeleteButtonGroup;
