import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { ElevatedPaperTable } from '@up2rent/ui';
import FormHeaderColorDots from 'src/components/ui/FormHeaderColorDots';
import FloodEdits from './FloodEdits';
// import TownPlanningsEdit from './TownPlanningsEdit';
import UrbanisticPrescriptionsEdit from './UrbanisticPrescriptionsEdit';

const MoreEdits = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Grid item style={{ width: 640 }}>
        <ElevatedPaperTable style={{ maxWidth: 640, paddingBottom: 10 }}>
          <FormHeaderColorDots
            title={formatMessage({ id: 'rentalUnit.detail.more.more' })}
            mandatoryDot={false}
            recommendedDot={false}
          />
          <UrbanisticPrescriptionsEdit />
          <FloodEdits />
          {/* {<TownPlanningsEdit />} */}
        </ElevatedPaperTable>
      </Grid>
    </>
  );
};

export default MoreEdits;
