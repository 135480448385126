import { Divider, Grid, Typography } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import React from 'react';

interface SettingBoxDetailLineProps {
  text: string;
  value?: string;
  IconValue?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}
const SettingBoxDetailLine: React.FC<SettingBoxDetailLineProps> = ({ text, value, IconValue }) => {
  return (
    <>
      <Grid container style={{ alignItems: 'center', height: 40, justifyContent: 'space-between' }}>
        <Grid item style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Typography style={{ fontSize: 14, fontWeight: 600 }}>{text}</Typography>
        </Grid>
        <Grid item>
          {value && (
            <Typography
              style={{
                fontSize: 14,
                fontWeight: 'normal',
                fontStyle: 'normal',
                letterSpacing: 0,
                color: Colors.SLATE_GREY,
              }}
            >
              {value}
            </Typography>
          )}
          {IconValue && <IconValue />}
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

export default SettingBoxDetailLine;
