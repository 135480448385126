import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import {
  Colors,
  DocumentCategory,
  EmptyFileWithUrl,
  EntityType,
  FileCategory,
  S3Object,
} from '@rentguru/commons-utils';
import { ActionButton, ContentHeader, ElevatedPaper } from '@up2rent/ui';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDocumentCategory } from 'src/hooks/FileCategoryContext';
import { getS3ObjectUrls, useFiles } from 'src/hooks/FilesContext';
import { useUser } from 'src/hooks/UserContext';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as Bulb } from '../../../icons/bulb.svg';
import CropFileDialog from '../../ui/Dialogs/CropFileDialog';
import DropZoneField from '../../ui/Forms/FormField/DropZoneField';

const LOGO_HEIGHT = 132;
const LOGO_WIDTH = 320;

const CompanyLogoForm = () => {
  const { formatMessage } = useIntl();
  const { clientId } = useUser();
  const { loading, createFile, deleteFile, getFiles } = useFiles();
  const [logoS3Object, setLogoS3Object] = useState<S3Object | EmptyFileWithUrl | null>(null);
  const [fileToCrop, setFileToCrop] = useState<File | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { getDocumentCategoryByFileCategory } = useDocumentCategory();
  const clientLogoDocumentCategory = getDocumentCategoryByFileCategory(FileCategory.CLIENT_LOGO) as DocumentCategory;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const files = useMemo(() => getFiles(EntityType.CLIENT, [clientId!]), [loading]);

  useEffect(() => {
    const fetchLogo = async () => {
      const results = await getS3ObjectUrls([files[0]]);
      if (results[0]) setLogoS3Object(results[0]);
    };

    if (!isEmpty(files) && !loading) {
      fetchLogo();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (!isEmpty(acceptedFiles)) setFileToCrop(acceptedFiles[0]);
  }, []);

  if (loading) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{formatMessage({ id: 'loading' })}</Typography>
      </ElevatedPaper>
    );
  }

  return (
    <ElevatedPaper style={{ margin: 20, maxWidth: 640, paddingBottom: 0 }}>
      <ContentHeader title={formatMessage({ id: 'settings.generalSection.companyLogo' })}>
        <ActionButton
          style={{ minWidth: 100, height: 50, fontSize: 16, fontWeight: 800 }}
          onClick={async () => {
            if (logoS3Object && 'file' in logoS3Object) {
              if (!isEmpty(files)) {
                await deleteFile(files[0]);
              }
              await createFile(logoS3Object.file, EntityType.CLIENT, clientId!, clientLogoDocumentCategory?.id, {
                level: 'public',
                acl: 'public-read',
              });
              enqueueSnackbar(formatMessage({ id: 'settings.changesSaved' }), { variant: 'success' });
            } else if (isNil(logoS3Object) && !isEmpty(files)) {
              await deleteFile(files[0]);
              enqueueSnackbar(formatMessage({ id: 'settings.changesSaved' }), { variant: 'success' });
            }
          }}
        >
          {formatMessage({ id: 'save' })}
        </ActionButton>
      </ContentHeader>
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: 15,
          marginLeft: 8, // + 16 padding inside the component
          marginRight: 8,
        }}
      >
        <DropZoneField
          onDrop={onDrop}
          currentFiles={logoS3Object ? [logoS3Object] : []}
          maxFiles={1}
          centerSelectedFile={true}
          removeFileByIndex={(_index: number) => setLogoS3Object(null)}
          accept={'image/*'}
          height={LOGO_HEIGHT}
          width={LOGO_WIDTH}
          style={{ width: 'none' }}
          itemStyle={{ width: 'none', height: 'none' }}
          fileItemStyle={{ width: 'none', height: 'none' }}
        />
      </div>
      <div
        style={{
          padding: '15px 40px 15px 40px',
          backgroundColor: Colors.PORCELAIN_GREY_1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Bulb style={{ fill: Colors.BLUE_GREY, marginBottom: 5, height: 16, width: 16 }} />
        <Typography
          style={{
            fontSize: '14px',
            lineHeight: '20px',
            color: Colors.SLATE_GREY,
          }}
        >
          {formatMessage({ id: 'settings.generalSection.logoHelper' })}
        </Typography>
      </div>
      {fileToCrop && (
        <CropFileDialog
          fileToCrop={fileToCrop}
          open={!isNil(fileToCrop)}
          onClose={() => setFileToCrop(null)}
          afterSubmit={(file: File) => {
            setLogoS3Object({ id: `NEW-${uuidv4()}`, file, url: URL.createObjectURL(file) });
          }}
          locked={true}
          crop={{ width: LOGO_WIDTH, height: LOGO_HEIGHT }}
        />
      )}
    </ElevatedPaper>
  );
};

export default CompanyLogoForm;
