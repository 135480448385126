/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import { ContentHeader, CustomizedTabs } from '@up2rent/ui';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import {
  Box,
  Checkbox,
  CheckboxProps,
  Collapse,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Tab,
  TextField,
  Theme,
  ThemeProvider,
  Typography,
  withStyles,
  createTheme,
} from '@material-ui/core';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import {
  ContactType,
  CivilityType,
  Colors,
  typesContainsType,
  isOneTypeOverlapping,
  CONTACT_TYPES_EMAIL_NOT_MANDATORY,
} from '@rentguru/commons-utils';
import AddressFields from 'src/components/ui/Forms/FormField/AddressFields';
import FormikDatePicker from 'src/components/ui/FormikDatePicker';
import CustomizedSelect from 'src/components/ui/CustomizedSelect';
import PhoneNumbersFields from 'src/components/ui/Forms/FormField/PhoneNumbersFields';
import { AddContactValues } from './AddContact';
import CustomizedSwitch from 'src/components/ui/CustomizedSwitch';

const theme = createTheme({
  overrides: {
    MuiFormControlLabel: {
      root: {
        display: 'inline',
      },
    },
    MuiSvgIcon: {
      root: {
        display: 'inline',
        fontSize: '1.4rem',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    rootSelect: {
      '& > *': {
        width: 255,
        marginRight: 30,
      },
    },
    formControl: {
      marginTop: theme.spacing(1),
      marginLeft: 17,
    },
    label: {
      fontSize: '17px',
      lineHeight: '24px',
    },
    underline: {
      '&:before': {
        borderBottom: `2px solid ${Colors.TOWER_GREY}`,
      },
    },
  })
);

const CustomCheckbox = withStyles({
  root: {
    color: Colors.DODGER_BLUE,
    marginRight: '2px',
    marginBottom: '5px',
    '&$checked': {
      color: Colors.DODGER_BLUE,
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

interface AddContactFormProps {
  viewPerson: boolean;
  handleTabChange?: (index: number) => void;
  displayTitle?: boolean;
  displayEmail?: boolean;
  displayPhoneNumbers?: boolean;
  displayTabs?: boolean;
  proposedTypes?: ContactType[];
  showSendInvite?: boolean;
}

const AddContactForm: React.FC<AddContactFormProps> = ({
  viewPerson,
  handleTabChange,
  displayTitle = true,
  displayEmail = true,
  displayPhoneNumbers = true,
  displayTabs = true,
  proposedTypes = [],
  showSendInvite = true,
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { values, setFieldValue, errors, touched, handleChange, handleBlur } = useFormikContext<AddContactValues>();

  return (
    <>
      {displayTitle && (
        <ContentHeader
          title={formatMessage({ id: 'contact.addContact.title' })}
          toolbarStyle={{ alignItems: 'center' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'baseline',
              marginTop: 15,
              marginBottom: 15,
            }}
          >
            <FormControl variant="outlined" error={Boolean(errors.types && touched.types)} required>
              <InputLabel htmlFor="typeInput">
                {formatMessage({
                  id: 'contact.addContact.typeLabel',
                })}
              </InputLabel>
              <Select
                disabled={proposedTypes.length === 1}
                value={values.types[0]} // Should be changed when allowing multiple types per contact
                onChange={(
                  event: React.ChangeEvent<{
                    name?: string | undefined;
                    value: unknown;
                  }>
                ) => {
                  const newTypes = [event.target.value];
                  setFieldValue('types', newTypes);
                  setFieldValue('sendInvitation', !newTypes.includes(ContactType.CONTRACTOR));
                }}
                label={formatMessage({
                  id: 'contact.addContact.typeLabel',
                })}
                inputProps={{
                  name: 'type',
                  id: 'typeInput',
                }}
                style={{ width: 200 }}
              >
                {proposedTypes.map((type) => {
                  return (
                    <MenuItem key={type} value={type}>
                      {formatMessage({ id: `contact.type.${type.toLowerCase()}` })}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </ContentHeader>
      )}
      {displayTabs && handleTabChange && (
        <CustomizedTabs handleChange={(i: number) => handleTabChange(i)} variant="fullWidth">
          <Tab label={formatMessage({ id: 'contact.addContact.physicalPerson' })} id="physicalPerson" />
          <Tab label={formatMessage({ id: 'contact.addContact.corporation' })} id="corporation" />
        </CustomizedTabs>
      )}

      <Grid style={{ marginLeft: 30, marginRight: 30 }}>
        {showSendInvite && (
          <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: 2, marginTop: 8 }}>
            <CustomizedSwitch
              checked={values.sendInvitation}
              name="sendInvitation"
              onChange={(event) => {
                const checked = event.target.checked;
                setFieldValue('sendInvitation', checked);
              }}
            />
            <Typography style={{ fontSize: 14 }}>{formatMessage({ id: 'settings.addMember.invite' })}</Typography>
          </Grid>
        )}
        {viewPerson && (
          <div style={{ display: 'flex' }}>
            <TextField
              InputProps={{ classes: { underline: classes.underline } }}
              label={formatMessage({
                id: 'contact.addContact.firstNameLabel',
              })}
              type="text"
              name="firstName"
              autoComplete="firstName"
              margin="dense"
              variant="filled"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              style={{ width: 280, marginRight: 30 }}
              required
              error={Boolean(errors.firstName && touched.firstName)}
            />

            <TextField
              InputProps={{ classes: { underline: classes.underline } }}
              label={formatMessage({
                id: 'contact.addContact.lastNameLabel',
              })}
              required
              type="text"
              name="lastName"
              autoComplete="lastName"
              margin="dense"
              variant="filled"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              style={{ width: 280 }}
              error={Boolean(errors.lastName && touched.lastName)}
            />
          </div>
        )}
        {/* company name and VAT checkbox */}
        {!viewPerson && (
          <>
            <TextField
              InputProps={{ classes: { underline: classes.underline } }}
              label={formatMessage({
                id: 'contact.addContact.companyNameLabel',
              })}
              type="text"
              name="companyName"
              autoComplete="companyName"
              margin="dense"
              variant="filled"
              value={values.companyName}
              onChange={handleChange}
              onBlur={handleBlur}
              style={{ width: '100%', marginRight: 30 }}
              required
              error={Boolean(errors.companyName && touched.companyName)}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Box
                display="flex"
                justifyContent="left"
                bgcolor="background.paper"
                borderColor={Colors.TOWER_GREY}
                m={1}
                border={1}
                style={{ width: '48%', marginLeft: 0, marginRight: 20 }}
                borderRadius={6}
              >
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormGroup>
                    <ThemeProvider theme={theme}>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            checked={values.vatLiable}
                            onChange={(event) => {
                              const checked = event.target.checked;
                              if (checked && values.address.country === 'BE' && !isEmpty(values.businessNumber)) {
                                setFieldValue('vat', `BE${values.businessNumber}`);
                              } else {
                                setFieldValue('vat', '');
                              }
                              setFieldValue('vatLiable', checked);
                            }}
                            name="vatLiable"
                            color="primary"
                          />
                        }
                        label={formatMessage({
                          id: 'contact.addContact.vatLiable',
                        })}
                        classes={{
                          label: classes.label,
                        }}
                      />
                    </ThemeProvider>
                  </FormGroup>
                </FormControl>
              </Box>
              {/* Company VAT Number */}
              {values.vatLiable && (
                <TextField
                  InputProps={{ classes: { underline: classes.underline } }}
                  label={formatMessage({
                    id: 'contact.addContact.vatLabel',
                  })}
                  InputLabelProps={{ id: 'vatLabel' }}
                  type="text"
                  name="vat"
                  autoComplete="vat"
                  margin="dense"
                  variant="filled"
                  value={values.vat}
                  onChange={(value) => {
                    const currentValue = value.target.value;
                    if (values.vatLiable && values.address.country === 'BE') {
                      setFieldValue('businessNumber', currentValue.replace(/\D/g, ''));
                    }
                    handleChange(value);
                  }}
                  onBlur={handleBlur}
                  style={{ width: '48%' }}
                  error={Boolean(errors.vat && touched.vat)}
                  required
                />
              )}
            </div>

            <TextField
              InputProps={{ classes: { underline: classes.underline } }}
              label={formatMessage({
                id: 'contact.addContact.enterpriseNumber',
              })}
              id="businessNumber"
              type="text"
              name="businessNumber"
              autoComplete="businessNumber"
              margin="dense"
              variant="filled"
              value={values.businessNumber}
              onChange={(value) => {
                handleChange(value);
                const currentValue = value.target.value;
                if (values.vatLiable && values.address.country === 'BE') {
                  setFieldValue('vat', !isEmpty(currentValue) ? `BE${currentValue}` : '');
                }
              }}
              onBlur={handleBlur}
              style={{ width: '100%', marginRight: 30 }}
              required={values.vatLiable}
              error={Boolean(errors.businessNumber && touched.businessNumber)}
            />
          </>
        )}

        {displayEmail && (
          <TextField
            InputProps={{ classes: { underline: classes.underline } }}
            label={formatMessage({
              id: 'contact.addContact.emailLabel',
            })}
            id="email"
            type="email"
            name="email"
            autoComplete="email"
            margin="dense"
            variant="filled"
            fullWidth
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.email && touched.email ? errors.email : ''}
            required={!isOneTypeOverlapping(values.types, CONTACT_TYPES_EMAIL_NOT_MANDATORY)}
            error={Boolean(errors.email && touched.email)}
          />
        )}
        <Collapse
          in={
            !isNil(values.email) &&
            !isEmpty(values.email) &&
            !isEmpty(proposedTypes) &&
            values.sendInvitation &&
            typesContainsType(values.types, ContactType.OWNER)
          }
        >
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            <Typography style={{ color: Colors.TOWER_GREY }}>
              {formatMessage({ id: 'contact.addContact.inviteOwner' })}
            </Typography>
          </Grid>
        </Collapse>

        {viewPerson && (
          <>
            <div style={{ display: 'flex' }}>
              {/* Company Business Number */}
              <CustomizedSelect
                label={`${formatMessage({ id: 'contact.detail.general.gender' })} ${formatMessage({
                  id: 'optional',
                })}`}
                fieldName="civility"
                value={!isNil(values.civility) ? values.civility : ''}
                className={classes.rootSelect}
                error={Boolean(errors.civility && touched.civility)}
                margin="dense"
              >
                <MenuItem id="none" key="none" value="">
                  {formatMessage({ id: 'tickets.list.none' })}
                </MenuItem>
                <MenuItem id="MAN" key="MAN" value={CivilityType.MAN}>
                  {formatMessage({ id: 'contact.detail.gender.man' })}
                </MenuItem>
                <MenuItem id="WOMAN" key="WOMAN" value={CivilityType.WOMAN}>
                  {formatMessage({ id: 'contact.detail.gender.woman' })}
                </MenuItem>
                <MenuItem id="OTHER" key="OTHER" value={CivilityType.OTHER}>
                  {formatMessage({ id: 'contact.detail.gender.other' })}
                </MenuItem>
              </CustomizedSelect>
              {/* National Register */}
              <TextField
                InputProps={{ classes: { underline: classes.underline } }}
                label={`${formatMessage({ id: 'contact.detail.general.nationalRegister' })} ${formatMessage({
                  id: 'optional',
                })}`}
                id="nationalRegister"
                type="text"
                name="nationalRegister"
                autoComplete="nationalRegister"
                margin="dense"
                variant="filled"
                value={values.nationalRegister}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ width: 255 }}
                error={Boolean(errors.nationalRegister && touched.nationalRegister)}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'end' }}>
              {/* Birth date */}
              <FormikDatePicker
                name="birthDate"
                label={`${formatMessage({ id: 'contact.detail.general.birthDate' })} ${formatMessage({
                  id: 'optional',
                })}`}
                value={values.birthDate}
                style={{ width: 255, marginRight: 30, marginTop: 16 }}
                required={false}
                type="string"
                margin="dense"
                maxDate={new Date()}
              />
              {/* birth place */}
              <TextField
                InputProps={{ classes: { underline: classes.underline } }}
                label={`${formatMessage({ id: 'contact.detail.general.birthPlace' })} ${formatMessage({
                  id: 'optional',
                })}`}
                type="text"
                name="birthPlace"
                margin="dense"
                variant="filled"
                value={values.birthPlace}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ width: 280 }}
                error={Boolean(errors.birthPlace && touched.birthPlace)}
              />
            </div>
            {typesContainsType(values.types, ContactType.OWNER) && (
              <CustomizedSelect
                label={formatMessage({ id: 'settings.languages' })}
                fieldName="language"
                value={values.language}
                FormControlProps={{ required: false, style: { width: 540 } }}
                error={Boolean(errors.language && touched.language)}
              >
                <MenuItem value="en">{formatMessage({ id: 'enums.AdvertisementLanguages.en' })}</MenuItem>
                <MenuItem value="fr">{formatMessage({ id: 'enums.AdvertisementLanguages.fr' })}</MenuItem>
                <MenuItem value="nl">{formatMessage({ id: 'enums.AdvertisementLanguages.nl' })}</MenuItem>
              </CustomizedSelect>
            )}
          </>
        )}

        <AddressFields
          containerStyle={{ marginLeft: 0, marginRight: 0 }}
          placeAutocompleteStyle={{ width: '100%' }}
          placeAutocompleteMargin="dense"
        />
        {displayPhoneNumbers && (
          <>
            {values.phoneNumbers.length !== 0 && <Divider style={{ marginTop: 20 }} />}
            <PhoneNumbersFields marginLeftRight={0} />
          </>
        )}
      </Grid>
    </>
  );
};

export default AddContactForm;
