import React, { useState } from 'react';
import { Collapse, Divider, Grid } from '@material-ui/core';
import PebRowBody from './LeasePEBFormRowBody';
import PEBRowHeader from './LeasePEBFormRowHeader';

interface PebRowProps {
  isUnit: boolean;
  unitId?: string;
  prefixFieldName: string;
  pebIndex: number;
  setPebIndex: (index: number) => void;
}

const PebRow: React.FC<PebRowProps> = ({ isUnit, prefixFieldName, pebIndex, setPebIndex, unitId }) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <Grid>
      <PEBRowHeader
        isUnit={isUnit}
        prefixFieldName={prefixFieldName}
        pebIndex={pebIndex}
        isOpen={isOpen}
        setOpen={setOpen}
      />
      <Collapse in={isOpen}>
        <PebRowBody
          isUnit={isUnit}
          unitId={unitId}
          prefixFieldName={prefixFieldName}
          pebIndex={pebIndex}
          setPebIndex={setPebIndex}
        />
      </Collapse>
      {!isUnit && <Divider style={{ marginTop: 10, marginBottom: 10 }} />}
    </Grid>
  );
};

export default PebRow;
