import { Formik } from 'formik';
import React from 'react';
import { LeaseExtended, useLeases } from 'src/hooks/LeasesContext';
import ChangeProfessionalActivityForm, {
  AddProfessionalUseFormValues,
} from './Forms/FormSection/ChangeProfessionalActivityForm';
import * as Yup from 'yup';

interface ChangeProfessionalActivityDialogProps {
  open: boolean;
  lease: LeaseExtended;
  onClose: () => void;
}

const ChangeProfessionalActivitySchema = Yup.object().shape({
  units: Yup.array().min(1).required(),
});

const ChangeProfessionalActivityDialog: React.FC<ChangeProfessionalActivityDialogProps> = ({
  open,
  onClose,
  lease,
}) => {
  const { changeProfessionalActivityForLease } = useLeases();
  const initialValues: AddProfessionalUseFormValues = {
    units: [],
  };

  const onSubmit = async (values: AddProfessionalUseFormValues) => {
    const unitIds = values.units.map((unit) => unit.id);
    await changeProfessionalActivityForLease({ leaseId: lease.id, unitIds });
    onClose();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={ChangeProfessionalActivitySchema}>
      <ChangeProfessionalActivityForm lease={lease} onClose={onClose} open={open} />
    </Formik>
  );
};

export default ChangeProfessionalActivityDialog;
