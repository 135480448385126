import { Filter } from 'src/hooks/FiltersContext';
import { LooseObject } from '@rentguru/commons-utils';
import { arrayIncludesFieldOfObject } from './array';

export const isEntityInClassicFilters = (entity: LooseObject, filters: Filter[]) => {
  return !filters.some((filter) => filter.items.length > 0 && !isEntityInClassicFilter(entity, filter));
};

export const isEntityInClassicFilter = (entity: LooseObject, filter: Filter) => {
  return filter.items.length === 0 || arrayIncludesFieldOfObject(filter.items, filter.field, entity);
};

export const sortValuesByLabel = (values: { label: string; value: string }[]) => {
  values.sort((v1, v2) => (v1.label > v2.label ? 1 : -1));
};
