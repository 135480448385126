import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as Logo } from 'src/icons/transactionPlaceholder.svg';
import {
  EnhancedTableScrollable,
  EnhancedTableSharedContextProvider,
  TablePlaceHolder,
  MenuItemText,
  ConfirmDialog,
} from '@up2rent/ui';
import { FilterEntity, useFilters } from 'src/hooks/FiltersContext';
import {
  Statement,
  getContactNameFromObject,
  STATEMENT_AMOUNT_TENANT_TOTAL_ADJUSTEMENT,
  StatementStatus,
  UpdateStatementInput,
  ModelWithVersion,
} from '@rentguru/commons-utils';
import TenantStatementsToValidateRow from './TenantStatementsToValidateRow';
import { Menu, MenuItem } from '@material-ui/core';
import { filterTenantStatements } from '../TenantStatementsFilters';
import { useFieldFilterReset } from '../../../ui/FieldFilterSelector';
import { TenantStatementExtended } from '../TenantStatements';
import ConfirmValidateTenantStatementDialog from './ValidateTenantStatement/ConfirmValidateTenantStatementDialog';
import { ReactComponent as NoResultIcon } from 'src/icons/noResult.svg';

const columns = [
  {
    id: 'periodSortLabel',
    labelId: 'financial.period',
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
  {
    id: 'tenantsNames',
    labelId: 'lease.detail.general.tenants',
    numeric: false,
    disablePadding: true,
    sortable: true,
  },
  {
    id: 'unitsNames',
    labelId: 'building.columnHeader.units',
    numeric: false,
    disablePadding: true,
    sortable: true,
  },
  {
    id: 'source',
    labelId: 'tickets.filter.sourceType',
    numeric: false,
    disablePadding: true,
    sortable: true,
  },
  {
    id: 'totalAmount',
    labelId: 'transactions.list.amount',
    numeric: false,
    disablePadding: true,
    sortable: true,
  },
  {
    id: 'more',
    labelId: 'transactions.list.action',
    numeric: false,
    disablePadding: false,
    sortable: false,
    style: { textAlign: 'center' },
  },
];
export interface ActionMenuChargeInbox {
  anchorEl: HTMLElement;
  id: string;
}

interface TenantStatementsToValidateProps {
  tenantStatementsToCheck: TenantStatementExtended[];
  recalculateStatement: (id: string) => Promise<boolean>;
  updateStatement: (updates: UpdateStatementInput) => Promise<Statement>;
  deleteStatement: (charge: Statement) => Promise<Statement>;
}

const TenantStatementsToValidate: React.FC<TenantStatementsToValidateProps> = ({
  tenantStatementsToCheck,
  recalculateStatement,
  updateStatement,
  deleteStatement,
}) => {
  const { formatMessage } = useIntl();
  const [dialogDeleteOpen, setDialogDeleteOpen] = useState<string>('');
  const [validateDialogOpen, setValidateDialogOpen] = useState<string>('');
  const [actionMenu, setActionMenu] = useState<ActionMenuChargeInbox | null>(null);
  const { tenantStatementsFilters, resetFilters } = useFilters();
  const { executeResetFilters } = useFieldFilterReset();
  const noFiltersSelected = isEmpty(tenantStatementsFilters.filters) && isNil(tenantStatementsFilters.statementsPeriod);

  const handleActionMenu = async (
    anchorEl: HTMLElement,
    id: string,
    eventType: 'MORE' | 'RECALCULATE' | 'VALIDATE',
    afterSubmit?: () => void
  ) => {
    if (eventType === 'MORE') {
      setActionMenu({ anchorEl, id });
    } else if (eventType === 'VALIDATE') {
      setValidateDialogOpen(id);
    } else {
      await recalculateStatement(id);
      if (afterSubmit) afterSubmit();
    }
  };

  const tenantStatementsToCheckData = tenantStatementsToCheck.map((statement) => {
    const tenantsNames = statement.tenants.map((t) => getContactNameFromObject(t)).join(', ');
    const unitsNames = statement.units.map((u) => u.name).join(', ');
    const adjustementAmount = statement.amounts
      ? statement.amounts.find((amount) => amount.name === STATEMENT_AMOUNT_TENANT_TOTAL_ADJUSTEMENT)
      : undefined;
    const totalAmount = adjustementAmount ? Number(adjustementAmount.value) : undefined;
    const periodSortLabel = `${statement.periodFrom}${statement.periodTo}`;
    return {
      ...statement,
      tenantsNames,
      unitsNames,
      totalAmount,
      periodSortLabel,
    };
  });
  const filteredTenantStatementsToCheck = filterTenantStatements(tenantStatementsToCheckData, tenantStatementsFilters);

  const statementToValidate =
    validateDialogOpen !== '' ? tenantStatementsToCheck.find((st) => st.id === validateDialogOpen) : null;
  const validateStatement = async () => {
    if (statementToValidate) {
      await updateStatement({
        id: statementToValidate.id,
        sentDate: new Date().toISOString(),
        status: StatementStatus.DONE,
        _version: (statementToValidate as ModelWithVersion<Statement>)._version,
      });
      setValidateDialogOpen('');
    }
  };

  return (
    <EnhancedTableSharedContextProvider
      value={{ orderBy: 'periodFrom' }}
      orderFirst="desc"
      datas={filteredTenantStatementsToCheck}
      handleActionMenu={handleActionMenu}
    >
      <EnhancedTableScrollable
        NoResultIcon={<NoResultIcon />}
        formatMessage={formatMessage}
        datas={filteredTenantStatementsToCheck}
        columns={columns}
        defaultOrderBy="periodFrom"
        rowComponent={TenantStatementsToValidateRow}
        placeHolder={
          <TablePlaceHolder
            Icon={<Logo width={72} />}
            mainText={formatMessage(
              { id: 'accounting.statement.toValidateSection.noStatements' },
              { statements: formatMessage({ id: 'accounting.statement.tenantStatements' }, { value: 1 }).toLowerCase() }
            )}
            subText=""
            mainTextStyle={{ maxWidth: 450 }}
          />
        }
        filtersSelected={!noFiltersSelected}
        resetFiltersFunction={() => {
          executeResetFilters();
          resetFilters(FilterEntity.TENANT_STATEMENTS);
        }}
      />
      {actionMenu && (
        <Menu
          id="action-menu"
          anchorEl={actionMenu.anchorEl}
          open={Boolean(actionMenu.anchorEl)}
          onClose={() => setActionMenu(null)}
        >
          <MenuItem
            onClick={() => {
              setDialogDeleteOpen(actionMenu.id);
              setActionMenu(null);
            }}
          >
            <MenuItemText primary={formatMessage({ id: 'delete' })} deleteOption />
          </MenuItem>
        </Menu>
      )}
      <ConfirmDialog
        open={dialogDeleteOpen !== ''}
        confirmText={formatMessage({ id: 'accounting.statement.confirmDelete' })}
        confirmAction={async () => {
          const statementToDelete = tenantStatementsToCheck.find((st) => st.id === dialogDeleteOpen);
          if (statementToDelete) await deleteStatement(statementToDelete);
          setDialogDeleteOpen('');
        }}
        cancelAction={() => {
          setDialogDeleteOpen('');
        }}
        mainText={formatMessage(
          { id: 'accounting.statement.toValidateSection.deleteWarning' },
          { statement: formatMessage({ id: 'accounting.statement.tenantStatements' }, { value: 1 }).toLowerCase() }
        )}
        formatMessage={formatMessage}
      />
      {validateDialogOpen !== '' && statementToValidate && (
        <ConfirmValidateTenantStatementDialog
          open={validateDialogOpen !== ''}
          onClose={() => setValidateDialogOpen('')}
          validateStatement={validateStatement}
          statement={statementToValidate}
        />
      )}
    </EnhancedTableSharedContextProvider>
  );
};

export default TenantStatementsToValidate;
