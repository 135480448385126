import React from 'react';
import { Typography, Grid, Divider } from '@material-ui/core';
import { useIntl } from 'react-intl';

const AccountLabelHeader: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Grid container style={{ marginTop: 15, marginBottom: 15 }} alignItems="center">
        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Typography variant="h6" style={{ fontSize: 14, fontWeight: 700 }}>
            {formatMessage({ id: 'accounting.accountLabel.class' })}
          </Typography>
        </Grid>
        <Grid item xs={9} style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Typography variant="h6" style={{ fontSize: 14, fontWeight: 700 }}>
            {formatMessage({ id: 'templates.columnHeader.type' })}
          </Typography>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Divider style={{ marginLeft: -30, marginRight: -30 }} />
    </>
  );
};

export default AccountLabelHeader;
