/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Formik, Form, FormikHelpers, FormikErrors } from 'formik';
import * as Yup from 'yup';
import { useStyles } from 'src/components/ui/TextDetailEditable';
import { useUser } from 'src/hooks/UserContext';
import { Typography, TextField, Link, Grid } from '@material-ui/core';
import { ElevatedPaper, LoaderButton } from '@up2rent/ui';
import { AuthProps, FormType } from 'src/App';
import { onChangeToLowerCaseFieldValue } from 'src/utils/formutils';
import { authStyles } from './authStyles';

const DEFAULT_REQUIRED_MESSAGE = 'requiredField';

const LoginSchema = Yup.object().shape({
  email: Yup.string().min(1).required(DEFAULT_REQUIRED_MESSAGE),
  password: Yup.string().min(1).required(DEFAULT_REQUIRED_MESSAGE),
});

interface LoginFormValues {
  email: string;
  password: string;
  defaultErrorMessage?: string;
}

const initialValues: LoginFormValues = {
  email: '',
  password: '',
};

enum LoginErrors {
  USER_DISABLED = 'User is disabled.',
}

const Login: React.FC<AuthProps> = ({ updateFormType }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { login } = useUser();
  const styles = authStyles();

  const [forgotPosition, setForgotPosition] = useState(35);
  const changeForgotPosition = () => {
    setForgotPosition(forgotPosition === 35 ? 20 : 35);
  };

  const handleSubmit = async (
    values: LoginFormValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<LoginFormValues>
  ) => {
    try {
      await login(values.email, values.password);
      setStatus(true);
      setSubmitting(false);
    } catch (error) {
      const err: Error = error as Error;
      console.error('err', err);
      // @ts-ignore

      setErrors({ password: err.name, defaultErrorMessage: err.message });
      setSubmitting(false);
    }
  };

  const getEmailErrorMessage = (rawErrorMessage: string | undefined) => {
    if (LoginErrors.USER_DISABLED === rawErrorMessage) {
      return formatMessage({ id: 'error.disabledUser' });
    }
    if (rawErrorMessage === DEFAULT_REQUIRED_MESSAGE) {
      return formatMessage({ id: 'mandatoryField' });
    }
  };

  const getPasswordErrorMessage = (errors: FormikErrors<LoginFormValues>) => {
    const rawErrorMessage = errors.password;
    if (rawErrorMessage === DEFAULT_REQUIRED_MESSAGE) {
      return formatMessage({ id: 'mandatoryField' });
    }

    if (rawErrorMessage) {
      return formatMessage({
        id: `error.${rawErrorMessage}`,
        defaultMessage: errors.defaultErrorMessage ?? '',
      });
    }
  };

  return (
    <Grid alignContent="center" alignItems="center" container direction="column">
      <Formik initialValues={initialValues} validationSchema={LoginSchema} onSubmit={handleSubmit}>
        {({ values, errors, touched, handleChange, handleBlur, isSubmitting, status, setFieldValue }) => (
          <ElevatedPaper className={styles.mainContainer}>
            <Form>
              <Typography variant="h5" paragraph component="h2" gutterBottom>
                {formatMessage({ id: 'login.connectWithMail' })}
              </Typography>

              <TextField
                label={formatMessage({ id: 'login.email' })}
                id="email"
                type="email"
                name="email"
                autoComplete="email"
                margin="normal"
                variant="filled"
                fullWidth
                value={values.email}
                onChange={(event) => {
                  onChangeToLowerCaseFieldValue(event, 'email', setFieldValue);
                }}
                onBlur={handleBlur}
                required
                error={Boolean(errors.email && touched.email)}
                helperText={touched.email ? getEmailErrorMessage(errors.email) : undefined}
                InputProps={{ classes: { underline: classes.underline } }}
                InputLabelProps={{ className: classes.label }}
              />
              <div style={{ position: 'relative', marginBottom: 20 }}>
                <TextField
                  label={formatMessage({ id: 'login.password' })}
                  id="password"
                  type="password"
                  autoComplete="current-password"
                  margin="normal"
                  variant="filled"
                  fullWidth
                  value={values.password}
                  onChange={handleChange}
                  onBlur={(e) => {
                    handleBlur(e);
                    changeForgotPosition();
                  }}
                  onFocus={changeForgotPosition}
                  required
                  error={Boolean(errors.password && touched.password)}
                  helperText={touched.password ? getPasswordErrorMessage(errors) : undefined}
                  InputProps={{ classes: { underline: classes.underline } }}
                  InputLabelProps={{ className: classes.label }}
                />
                <div style={{ position: 'absolute', top: forgotPosition, right: 15 }}>
                  <Link onClick={() => updateFormType(FormType.FORGOT_PASSWORD)}>
                    <Typography style={{ cursor: 'pointer' }}>
                      {formatMessage({ id: 'login.forgottenPassword' })}
                    </Typography>
                  </Link>
                </div>
              </div>

              <LoaderButton id="loginSubmitButton" loading={isSubmitting} success={status} disabled={status}>
                {formatMessage({
                  id: 'login.connect',
                })}
              </LoaderButton>
            </Form>
          </ElevatedPaper>
        )}
      </Formik>
      <Typography>{formatMessage({ id: 'login.notYetAccountQuestion' })}</Typography>
      <Link onClick={() => updateFormType(FormType.SIGN_UP)} data-test="signup-link">
        <Typography style={{ cursor: 'pointer' }}>{formatMessage({ id: 'login.createAccount' })}</Typography>
      </Link>
    </Grid>
  );
};

export default Login;
