import { Divider, Grid, Typography } from '@material-ui/core';
import {
  Colors,
  getDefaultSendingSource,
  ModelWithVersion,
  SendingSource,
  SendingSourceVerifiedType,
} from '@rentguru/commons-utils';
import { toUpper } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { ActionButton , ContentHeader, LoaderButton } from '@up2rent/ui';
import { useSendingSources } from 'src/hooks/SendingSourceContext';

interface MakeDefaultDialogProps {
  id: string;
  closeAction: () => void;
}

const MakeDefaultDialog: React.FC<MakeDefaultDialogProps> = ({ id, closeAction }) => {
  const { formatMessage } = useIntl();
  const { getSendingSource, updateSendingSource, sendingSources, sendingSourcesLoading } = useSendingSources();

  const selectedSendingSource = getSendingSource(id)!;

  const handleMakeDefault = async () => {
    const oldDefaultSendingSource = getDefaultSendingSource(sendingSources);
    if (oldDefaultSendingSource) {
      await updateSendingSource({
        id: oldDefaultSendingSource.id,
        verifiedType: SendingSourceVerifiedType.NONE,
        _version: (oldDefaultSendingSource as ModelWithVersion<SendingSource>)._version,
      });
    }

    await updateSendingSource({
      id: selectedSendingSource.id,
      verifiedType: SendingSourceVerifiedType.DEFAULT,
      _version: (selectedSendingSource as ModelWithVersion<SendingSource>)._version,
    });
    closeAction();
  };

  return (
    <Grid style={{ width: 600 }}>
      <ContentHeader title={formatMessage({ id: 'settings.sendingSources.defaultTitle' })} />

      <Divider style={{ marginBottom: 20 }} />

      <Grid style={{ marginLeft: 30, marginRight: 30 }}>
        <Typography>
          {formatMessage({ id: 'settings.sendingSources.defaultInfo1' })}
          <b>{selectedSendingSource.emailAddress}</b>
          {formatMessage({ id: 'settings.sendingSources.defaultInfo2' })}
        </Typography>
      </Grid>

      <Divider style={{ marginBottom: 20, marginTop: 20 }} />

      <Grid
        style={{
          marginRight: 30,
          marginBottom: 20,
          display: 'flex',
          float: 'right',
          alignItems: 'center',
        }}
      >
        <ActionButton
          onClick={closeAction}
          style={{
            background: 'none',
            color: Colors.DARK_SLATE_GREY,
            marginRight: 20,
          }}
        >
          {toUpper(
            formatMessage({
              id: 'cancel',
            })
          )}
        </ActionButton>
        <LoaderButton loading={sendingSourcesLoading} onClick={handleMakeDefault}>
          {formatMessage({
            id: 'settings.sendingSources.defaultMenu',
          })}
        </LoaderButton>
      </Grid>
    </Grid>
  );
};

export default MakeDefaultDialog;
