import { Warning } from '@material-ui/icons';
import {
  Colors,
  getOldestInvoiceOfExactAmount,
  InvoiceWithPostings,
  LinkTo,
  LinkType,
  Transaction,
} from '@rentguru/commons-utils';
import { CustomIconButton } from '@up2rent/ui';
import { FormikErrors, useFormikContext } from 'formik';
import { isNull } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { getLeaseInvoicesWithPostings } from 'src/components/Accounting/Transactions/utils';
import CustomizedComboBoxWithTabs from 'src/components/ui/ComboBox/CustomizedComboBoxWithTabs';
import { CustomMenuItemType } from 'src/components/ui/ComboBox/TextComboBox';
import TextDetailEditable from 'src/components/ui/TextDetailEditable';
import { ReactComponent as Delete } from 'src/icons/delete.svg';
import { SplitTransactionValues } from './SplitTransactionDialog';

interface SplitTransactionDialogRowProps {
  index: number;
  transaction: Transaction;
  leasesMenuItems: CustomMenuItemType[];
  contractorsMenuItems: CustomMenuItemType[];
  decreaseNumberOfSplits: (index: number) => void;
}

interface LeaseAndInvoices {
  leaseId: string;
  invoices: InvoiceWithPostings[];
}

const SplitTransactionDialogRow: React.FC<SplitTransactionDialogRowProps> = ({
  index,
  leasesMenuItems,
  transaction,
  contractorsMenuItems,
  decreaseNumberOfSplits,
}) => {
  const { formatMessage } = useIntl();
  const { values, setFieldValue, touched, errors, isSubmitting } = useFormikContext<SplitTransactionValues>();
  // We save the invoices of the lease as state value so that we don't fetch them everytime we change the split amount
  const [savedLeaseAndInvoices, setSavedLeaseAndInvoices] = useState<LeaseAndInvoices | null>(null);

  const currentLink = values.links[index];
  /* If we are an agency and we have a link on a Lease, we must fetch the unpaid invoices of the lease
  to see if we can make a direct match or if we have to reconcilialte the transactions
  */
  useEffect(() => {
    const getUnPaidInvoicesOfLeaseLink = async (leaseId: string) => {
      let invoicesWithPostings: InvoiceWithPostings[];
      if (savedLeaseAndInvoices && savedLeaseAndInvoices.leaseId === leaseId) {
        invoicesWithPostings = savedLeaseAndInvoices.invoices;
      } else {
        invoicesWithPostings = await getLeaseInvoicesWithPostings(leaseId, { paid: { eq: false } });
        setSavedLeaseAndInvoices({ leaseId, invoices: invoicesWithPostings });
      }

      const invoiceMatch = getOldestInvoiceOfExactAmount(invoicesWithPostings, currentLink.amount);
      setFieldValue(`links[${index}].invoiceMatch`, invoiceMatch);
    };
    // We only reconcile postive transactions for linked to leases by agencies
    if (currentLink.type === LinkType.Lease && currentLink.leaseId && transaction.amount > 0) {
      getUnPaidInvoicesOfLeaseLink(currentLink.leaseId);
    } else if (currentLink.type === LinkType.Contractor && currentLink.invoiceMatch) {
      setFieldValue(`links[${index}].invoiceMatch`, undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLink.amount, currentLink.leaseId, currentLink.type]);

  const redirectionRequired = isNull(currentLink.invoiceMatch);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      {redirectionRequired && <Warning style={{ color: Colors.SUN_YELLOW, marginRight: 10 }} />}
      <div style={{ alignItems: 'baseline' }}>
        <TextDetailEditable
          editMode={true}
          title={formatMessage({ id: 'transactions.list.amount' })}
          name={`links[${index}].amount`}
          type="number"
          min={0}
          step={0.01}
          style={{ width: 190, maxWidth: 'none', marginRight: 20 }}
          endAdornment="€"
          error={
            Boolean(
              touched.links &&
                errors.links &&
                errors.links[index] &&
                (errors.links[index] as FormikErrors<LinkTo>).amount
            ) || Boolean(errors.totalAmount)
          }
        />
      </div>
      <CustomizedComboBoxWithTabs
        id={`comboBox_${index}`}
        datas={[
          {
            tabLabel: formatMessage({ id: 'lease.title' }),
            tabKey: LinkType.Lease,
            datas: leasesMenuItems,
          },
          {
            tabLabel: formatMessage({ id: 'contact.type.contractor' }),
            tabKey: LinkType.Contractor,
            datas: contractorsMenuItems,
          },
        ]}
        label={formatMessage({ id: 'transactions.list.linkTo' })}
        inputStyle={{ width: 335 }}
        error={Boolean(
          touched.links && errors.links && errors.links[index] && (errors.links[index] as FormikErrors<LinkTo>).type
        )}
        disabled={isSubmitting}
        onChange={(_e, tabKey, value) => {
          if (!value) {
            setFieldValue(`links[${index}]`, { amount: values.links[index].amount });
            return;
          }

          setFieldValue(`links[${index}]`, {
            ...values.links[index],
            ...(tabKey === LinkType.Lease
              ? { type: LinkType.Lease, leaseId: value!.value.leaseId }
              : { type: LinkType.Contractor, id: value!.value.contractorId }),
          });
        }}
      />
      <CustomIconButton
        style={{ color: Colors.BLUE_GREY }}
        onClick={() => {
          decreaseNumberOfSplits(index);
        }}
        Icon={Delete}
        iconStyle={{ fill: Colors.BLUE_GREY }}
      />
    </div>
  );
};

export default SplitTransactionDialogRow;
