import React from 'react';
import CustomizedComboBox, { CustomizedComboBoxProps, CustomMenuItemType } from './TextComboBox';
import { useFormikContext, FormikProps } from 'formik';
import isNil from 'lodash/isNil';

interface AdditionalFormikProps {
  name: string;
}

type FormikComboBox = AdditionalFormikProps & Omit<CustomizedComboBoxProps, 'onChange'>;

// eslint-disable-next-line no-redeclare
const FormikComboBox: React.FC<FormikComboBox> = ({ name, ...rest }) => {
  const { setFieldValue }: FormikProps<unknown> = useFormikContext();
  const onChange = (_event: unknown, value: CustomMenuItemType | null) => {
    if (isNil(value)) setFieldValue(name, undefined);
    else setFieldValue(name, (value as CustomMenuItemType).value);
  };
  return <CustomizedComboBox {...rest} onChange={onChange} />;
};
export default FormikComboBox;
