import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { isNil } from 'lodash';
import { formatDistanceToNow } from 'date-fns';
import { Grid, ListItem, Typography } from '@material-ui/core';
import {
  CustomSubscription,
  SignatureDocumentStatus,
  SignatureDocumentType,
  Colors,
  getLocaleFromLanguage,
} from '@rentguru/commons-utils';
import { useUser } from 'src/hooks/UserContext';
import { useSignatureDocuments } from 'src/hooks/SignatureDocumentContext';
import { useLeases } from 'src/hooks/LeasesContext';
import { useCustomSubscriptions } from 'src/hooks/CustomSubscriptionContext';
import { ReactComponent as SignIcon } from 'src/icons/lease-action-sign.svg';
import NotificationDeleteIconButton from 'src/components/ui/CustomIconButtons/NotificiationDeleteIconButton';
import { RouteDestination } from 'src/components/Routes/Routes';
import { useNotificationListStyle } from './NotificationsList';

interface NotificationsSignatureDocumentItemProps {
  signatureDocumentId: string;
  customSubscription: CustomSubscription;
  signatureUrl?: string;
  hasError: boolean;
  leaseId?: string;
}

const NotificationsSignatureDocumentItem: React.FC<NotificationsSignatureDocumentItemProps> = ({
  signatureUrl,
  customSubscription,
  signatureDocumentId,
  hasError,
  leaseId,
}) => {
  const { formatMessage } = useIntl();
  const localStyles = useNotificationListStyle();

  const { language } = useUser();
  const { deleteCustomSubscriptions } = useCustomSubscriptions();
  const { getSignatureDocument } = useSignatureDocuments();
  const { getLease } = useLeases();

  const signatureDocument = getSignatureDocument(signatureDocumentId);
  // Related lease
  const lease = getLease(leaseId ?? signatureDocument?.foreignKey ?? '');
  const signatureDocumentType = signatureDocument?.type ?? SignatureDocumentType.DOCUMENT_TO_SIGN;
  const isDocumentToSign = signatureDocumentType === SignatureDocumentType.DOCUMENT_TO_SIGN;
  const isRegisteredLetter = signatureDocumentType === SignatureDocumentType.REGISTERED_LETTER;

  const titleMessageId = isDocumentToSign ? 'lease.addLease.documentToSign' : 'accountMenu.registeredLetter';

  return (
    <ListItem className={localStyles.listItem}>
      <Grid>
        <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            <SignIcon
              style={{
                width: 45,
                height: 45,
                transform: 'scale(0.6)',
              }}
            />
            <Typography variant="h6" style={{ color: Colors.CLASSICAL_BLACK }}>
              {formatMessage({ id: titleMessageId })}
            </Typography>
          </Grid>
          <NotificationDeleteIconButton
            onClick={() => {
              deleteCustomSubscriptions([customSubscription]);
            }}
          />
        </Grid>
        {!isNil(customSubscription.createdAt) && (
          <Typography style={{ color: Colors.BLUEY, paddingBottom: 5 }}>
            {formatDistanceToNow(new Date(customSubscription.createdAt!), {
              locale: getLocaleFromLanguage(language || 'en'),
            })}
          </Typography>
        )}
        {isDocumentToSign && !hasError && (
          <Typography style={{ color: Colors.SLATE_GREY }}>
            {formatMessage({
              id: 'accountMenu.notificationDocumentToSign',
            })}{' '}
            <a
              style={{
                color: Colors.DODGER_BLUE,
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              target="_blank"
              rel="noopener noreferrer"
              href={signatureUrl}
            >
              {formatMessage({ id: 'signatures.document' })}
            </a>
          </Typography>
        )}
        {isDocumentToSign && hasError && (
          <Typography style={{ color: Colors.SLATE_GREY }}>
            {formatMessage({
              id: 'accountMenu.notificationDocumentError',
            })}{' '}
            <Link
              to={`${RouteDestination.LEASES}/detail/${lease?.id}`}
              onClick={(e) => e.stopPropagation()}
              style={{ color: Colors.DODGER_BLUE, textDecoration: 'underline', cursor: 'pointer' }}
            >
              {lease?.name ?? ''}
            </Link>
          </Typography>
        )}
        {isRegisteredLetter && (
          <Typography style={{ color: Colors.SLATE_GREY }}>
            {formatMessage({
              id:
                signatureDocument?.status === SignatureDocumentStatus.ERROR
                  ? 'accountMenu.notificationRegisteredLetterError'
                  : 'accountMenu.notificationRegisteredLetterRejected',
            })}
            <Link
              to={`${RouteDestination.LEASES}/detail/${lease?.id}`}
              onClick={(e) => e.stopPropagation()}
              style={{ color: Colors.DODGER_BLUE, textDecoration: 'underline', cursor: 'pointer' }}
            >
              {lease?.name ?? ''}
            </Link>
          </Typography>
        )}
      </Grid>
    </ListItem>
  );
};

export default NotificationsSignatureDocumentItem;
