import { Typography } from '@material-ui/core';
import { CustomSimpleDialog, CustomSimpleDialogProps } from '@up2rent/ui';
import { useIntl } from 'react-intl';

interface EPBOverwriteWarningDialogProps extends Omit<CustomSimpleDialogProps, 'actionButtonLabel' | 'children'> {}

const EPBOverwriteWarningDialog: React.FC<EPBOverwriteWarningDialogProps> = ({ ...rest }) => {
  const { formatMessage } = useIntl();

  return (
    <CustomSimpleDialog actionButtonLabel={formatMessage({ id: 'proceed' })} {...rest} formatMessage={formatMessage}>
      <Typography style={{ fontSize: 15, fontWeight: 500, marginLeft: 20, marginRight: 20, marginTop: 25 }}>
        {formatMessage({ id: 'technic.addTechnicDialog.overwriteEpbWarning' })}
      </Typography>
    </CustomSimpleDialog>
  );
};

export default EPBOverwriteWarningDialog;
