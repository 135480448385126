/* eslint-disable default-case */
import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { isNil } from 'lodash';
import { formatDistanceToNow } from 'date-fns/esm';
import { Grid, ListItem, Typography } from '@material-ui/core';
import {
  CustomSubscription,
  SendingSourceVerifiedType,
  Colors,
  getLocaleFromLanguage,
  SendingSourceMessageType,
} from '@rentguru/commons-utils';
import { useCustomSubscriptions } from 'src/hooks/CustomSubscriptionContext';
import { useUser } from 'src/hooks/UserContext';
import { ReactComponent as SettingsIcon } from 'src/icons/lease-action-charge.svg';
import { RouteDestination } from 'src/components/Routes/Routes';
import NotificationDeleteIconButton from 'src/components/ui/CustomIconButtons/NotificiationDeleteIconButton';
import { useNotificationListStyle } from './NotificationsList';

interface NotificationsSendingSourceItemProps {
  name: string;
  emailAddress: string;
  verifiedType: SendingSourceVerifiedType;
  notificationType: SendingSourceMessageType;
  customSubscription: CustomSubscription;
}

const NotificationsSendingSourceItem: React.FC<NotificationsSendingSourceItemProps> = ({
  name,
  emailAddress,
  verifiedType,
  notificationType,
  customSubscription,
}) => {
  const { formatMessage } = useIntl();
  const localStyles = useNotificationListStyle();

  const { language } = useUser();
  const { deleteCustomSubscription } = useCustomSubscriptions();

  const state =
    notificationType === SendingSourceMessageType.SUCCESS && verifiedType === SendingSourceVerifiedType.DEFAULT
      ? 'active'
      : notificationType.toLowerCase();
  const displayAddress = ['error', 'active'].includes(state);
  return (
    <ListItem className={localStyles.listItem}>
      <Grid>
        <Grid style={{ display: 'flex', alignItems: 'center' }}>
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <SettingsIcon
                style={{
                  transform: 'scale(0.6)',
                }}
              />
            </div>
            <Typography variant="h6" style={{ color: Colors.CLASSICAL_BLACK, textJustify: 'inter-word' }}>
              {formatMessage({
                id: `settings.sendingSources.notification.title.${notificationType.toLowerCase()}`,
              })}
            </Typography>
          </Grid>
          <NotificationDeleteIconButton
            onClick={() => {
              deleteCustomSubscription(customSubscription);
            }}
          />
        </Grid>
        {!isNil(customSubscription.createdAt) && (
          <Typography style={{ color: Colors.BLUEY, paddingBottom: 5 }}>
            {formatDistanceToNow(new Date(customSubscription.createdAt!), {
              locale: getLocaleFromLanguage(language || 'en'),
            })}
          </Typography>
        )}
        <Grid style={{ color: Colors.SLATE_GREY }}>
          <Typography>
            {formatMessage({ id: `settings.sendingSources.notification.body.${state}1` })}
            <b>{displayAddress ? emailAddress : name}</b>
            {formatMessage({ id: `settings.sendingSources.notification.body.${state}2` })}
            <Link
              to={`${RouteDestination.SETTINGS}/domains`}
              onClick={(e) => e.stopPropagation()}
              style={{ color: Colors.DODGER_BLUE, textDecoration: 'underline', cursor: 'pointer' }}
            >
              {formatMessage({ id: `settings.sendingSources.notification.body.${state}3` })}
            </Link>
            {formatMessage({ id: `settings.sendingSources.notification.body.${state}4` })}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default NotificationsSendingSourceItem;
