import { Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  BlockedEmail,
  Building,
  Colors,
  Communication,
  getSignatureDocumentTrackingLink,
  getSignatureDocumentTrackingProvider,
  Lease,
  SignatureDocument,
  SignatureDocumentStatus,
} from '@rentguru/commons-utils';
import { capitalize, isEmpty } from 'lodash';
import React from 'react';
import { IntlFormatters, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { CommunicationStatus } from 'src/API';
import FormInfoBox from 'src/components/ui/FormInfoBox';
import { useCommunications } from 'src/hooks/CommunicationsContext';
import { getPrintableDate } from 'src/utils/dates';
import { RouteDestination } from '../Routes/Routes';
import FormAlertBox from '../ui/FormAlertBox';
import RegisteredLetterStatusBox from './RegisteredLetterStatusBox';

const useStyles = makeStyles({
  informationGrid: {
    display: 'flex',
    flexDirection: 'column',
  },
  informationTitle: { color: Colors.LIGHT_BLUE_GREY, fontSize: 12, paddingBottom: 12 },
  communicationLink: {
    color: Colors.SLATE_GREY,
    fontSize: 14,
    textDecoration: 'underline',
    '&:hover': {
      color: Colors.DARK_SKY_BLUE,
    },
  },
  entityNameTypography: { color: Colors.SLATE_GREY, textDecoration: 'underline' },
});

const BouncedEmailsList = ({
  blockedEmails,
  formatMessage,
}: {
  blockedEmails: BlockedEmail[];
  formatMessage: IntlFormatters['formatMessage'];
}) => (
  <Grid>
    {blockedEmails?.map((blockedEmail) => (
      <Typography key={blockedEmail.email} style={{ color: Colors.BURNING_ORANGE, fontSize: 14 }}>
        • {blockedEmail.email} : {formatMessage({ id: `enums.BounceTypes.${blockedEmail.bounceType}` })}
      </Typography>
    ))}
  </Grid>
);

const BouncingLinkedCommunicationsList = ({
  bouncingLinkedCommunications,
  className,
}: {
  bouncingLinkedCommunications: Communication[];
  className: string;
}) => (
  <Grid>
    {bouncingLinkedCommunications?.map((bouncingLinkedCommunication) => {
      return (
        <Typography key={bouncingLinkedCommunication.id} style={{ color: Colors.SLATE_GREY, fontSize: 14 }}>
          {'• '}
          <RouterLink
            to={{
              pathname: `${RouteDestination.COMMUNICATIONS}`,
              state: { communication: bouncingLinkedCommunication.id },
            }}
            className={className}
          >
            {bouncingLinkedCommunication.recipient?.replace(',', ', ')}
          </RouterLink>
        </Typography>
      );
    })}
  </Grid>
);

const SentLinkedCommunicationsList = ({
  sentLinkedCommunications,
  className,
}: {
  sentLinkedCommunications: Communication[];
  className: string;
}) => (
  <Grid>
    {sentLinkedCommunications?.map((sentLinkedCommunication) => {
      return (
        <Typography key={sentLinkedCommunication.id} style={{ color: Colors.SLATE_GREY, fontSize: 14 }}>
          {'• '}
          <RouterLink
            to={{
              pathname: `${RouteDestination.COMMUNICATIONS}${'/communications-history'}`,
              state: { communication: sentLinkedCommunication.id },
            }}
            className={className}
          >
            {sentLinkedCommunication.recipient?.replace(',', ', ')}
          </RouterLink>
        </Typography>
      );
    })}
  </Grid>
);

const CommunicationDetailsContentHeader = ({
  signatureDocument,
  isLetter,
  dateLabelId,
  date,
  status,
  blockedEmails,
  linkedCommunicationsIds,
  dateStyle = {},
  gridContainerStyle = {},
  gridItemsStyle = {},
  lease,
  building,
}: {
  signatureDocument: SignatureDocument | null | undefined;
  isLetter: boolean;
  dateLabelId: string;
  date: string | undefined | null;
  status?: CommunicationStatus;
  blockedEmails?: BlockedEmail[];
  linkedCommunicationsIds?: string[];
  dateStyle?: React.CSSProperties;
  gridContainerStyle?: React.CSSProperties;
  gridItemsStyle?: React.CSSProperties;
  lease?: Lease | null;
  building?: Building | null;
}) => {
  const { formatMessage } = useIntl();
  const { getCommunication } = useCommunications();
  const detailClasses = useStyles();
  const trackingLink = getSignatureDocumentTrackingLink(signatureDocument);
  const trackingProvider = getSignatureDocumentTrackingProvider(signatureDocument);
  const isLetterInError =
    signatureDocument &&
    [SignatureDocumentStatus.REJECTED, SignatureDocumentStatus.ERROR].includes(
      signatureDocument.status as SignatureDocumentStatus
    );
  const communicationIsBouncing = status === CommunicationStatus.BOUNCE_ERROR;
  const bouncingLinkedCommunications: Communication[] = [];
  const sentLinkedCommunications: Communication[] = [];

  linkedCommunicationsIds?.forEach((linkedCommunicationId) => {
    const linkedCommunication = getCommunication(linkedCommunicationId) as Communication;
    if (linkedCommunication?.status === CommunicationStatus.SENT) {
      sentLinkedCommunications.push(linkedCommunication);
    }
    if (
      linkedCommunication?.status === CommunicationStatus.BOUNCE_ERROR ||
      linkedCommunication?.status === CommunicationStatus.TO_SEND
    ) {
      bouncingLinkedCommunications.push(linkedCommunication);
    }
  });

  return (
    <Grid container style={gridContainerStyle}>
      {isLetterInError && (
        <Grid item xs={12}>
          <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 20 }}>
            <FormAlertBox
              messagesGridStyle={{ marginRight: 30, fontSize: 14 }}
              gridStyle={{ width: '100%', marginRight: 30, marginLeft: 20 }}
              message1={formatMessage({
                id: 'communications.detail.registeredLetterError',
              })}
            />
          </div>
        </Grid>
      )}
      {communicationIsBouncing && (
        <Grid item xs={12}>
          <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 20 }}>
            <FormAlertBox
              messagesGridStyle={{ marginRight: 30, fontSize: 14 }}
              gridStyle={{ width: '100%', marginRight: 30, marginLeft: 20 }}
              message1={formatMessage({
                id: 'communications.detail.bounceIntro',
              })}
              subContent={<BouncedEmailsList blockedEmails={blockedEmails!} formatMessage={formatMessage} />}
            />
          </div>
        </Grid>
      )}
      {!isEmpty(sentLinkedCommunications) && (
        <Grid item xs={12}>
          <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 20 }}>
            <FormInfoBox
              message={formatMessage({
                id: 'communications.detail.linkedCommunicationAlreadySentIntro',
              })}
              subContent={
                <SentLinkedCommunicationsList
                  sentLinkedCommunications={sentLinkedCommunications}
                  className={detailClasses.communicationLink}
                />
              }
            />
          </div>
        </Grid>
      )}
      {!isEmpty(bouncingLinkedCommunications) && (
        <Grid item xs={12}>
          <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 20 }}>
            <FormInfoBox
              message={formatMessage({
                id: 'communications.detail.linkedCommunicationNotSentIntro',
              })}
              subContent={
                <BouncingLinkedCommunicationsList
                  bouncingLinkedCommunications={bouncingLinkedCommunications}
                  className={detailClasses.communicationLink}
                />
              }
            />
          </div>
        </Grid>
      )}
      <Grid className={detailClasses.informationGrid} item xs={3} style={gridItemsStyle}>
        <Typography className={detailClasses.informationTitle}>{formatMessage({ id: dateLabelId })}</Typography>
        <Typography variant="body2" style={dateStyle}>
          {getPrintableDate(date)}
        </Typography>
      </Grid>
      {signatureDocument && signatureDocument.expirationDate && (
        <Grid className={detailClasses.informationGrid} item xs={3} style={gridItemsStyle}>
          <Typography className={detailClasses.informationTitle}>
            {formatMessage({ id: 'communications.list.dateOfSending' })}
          </Typography>
          <Typography variant="body2" style={dateStyle}>
            {getPrintableDate(signatureDocument.expirationDate)}
          </Typography>
        </Grid>
      )}
      {lease?.name && (
        <Grid className={detailClasses.informationGrid} item xs={3} style={gridItemsStyle}>
          <Typography className={detailClasses.informationTitle}>
            {formatMessage({ id: 'communications.list.lease' })}
          </Typography>
          <Typography variant="body2" className={detailClasses.entityNameTypography}>
            <RouterLink
              to={`${RouteDestination.LEASES}/detail/${lease.id}`}
              className={detailClasses.entityNameTypography}
            >
              {lease.name}
            </RouterLink>
          </Typography>
        </Grid>
      )}
      {building?.name && (
        <Grid className={detailClasses.informationGrid} item xs={3} style={gridItemsStyle}>
          <Typography className={detailClasses.informationTitle}>
            {formatMessage({ id: 'communications.list.building' })}
          </Typography>
          <Typography variant="body2" className={detailClasses.entityNameTypography}>
            <RouterLink
              to={`${RouteDestination.PROPERTIES}/${building.id}`}
              className={detailClasses.entityNameTypography}
            >
              {building.name}
            </RouterLink>
          </Typography>
        </Grid>
      )}
      {isLetter && signatureDocument && (
        <>
          <Grid item xs={3} className={detailClasses.informationGrid} style={gridItemsStyle}>
            <Typography className={detailClasses.informationTitle}>
              {formatMessage({ id: `lease.addLease.currentStatus` })}
            </Typography>
            <RegisteredLetterStatusBox signatureDocument={signatureDocument} />
          </Grid>
          {trackingLink && (
            <Grid item xs={3} className={detailClasses.informationGrid} style={gridItemsStyle}>
              <Typography className={detailClasses.informationTitle}>
                {formatMessage({ id: `communications.detail.tracking` })}
              </Typography>
              <Link href={trackingLink} style={{ textDecoration: 'none' }} target="_blank">
                <Typography
                  style={{
                    color: Colors.SLATE_GREY,
                    textDecoration: 'underline',
                  }}
                >
                  {capitalize(trackingProvider)}
                </Typography>
              </Link>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default CommunicationDetailsContentHeader;
