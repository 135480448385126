import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import isNil from 'lodash/isNil';
import { Grid, Divider, Typography, Tooltip, makeStyles } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { Colors, contactContainsType, getContactTypes, ContactType, isNilOrEmpty } from '@rentguru/commons-utils';
import { useLocale, dateLocaleMap, dateLocaleMapType } from 'src/i18n/IntlProviderWrapper';
import { useContacts } from 'src/hooks/ContactsContext';
import { ReactComponent as ExternalIcon } from 'src/icons/external.svg';
import { getOwnersOfJointownership } from 'src/utils/contactutils';
import { RouteDestination } from 'src/components/Routes/Routes';
import TextDetail from 'src/components/ui/TextDetail';
import TextDetailPhoneNumbers from 'src/components/ui/TextDetailPhoneNumbers';
import { CustomIconButton } from '@up2rent/ui';
import ContactAddressDetails from './ContactAddressDetails';
import ContactCard from 'src/components/Contacts/ContactCard';
import EditContact from 'src/components/Contacts/EditContact';
import Dialog from 'src/components/ui/Dialog';
import { StarRounded, StarBorderRounded } from '@material-ui/icons';

const useStyles = makeStyles({
  starIcon: {
    color: Colors.CARROT_ORANGE,
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

interface GeneralDetailsProps {
  id: string;
  showLinkButton?: boolean;
}

const GeneralDetails: React.FC<GeneralDetailsProps> = ({ id, showLinkButton = false }) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const history = useHistory();
  const { getContact, contactsError, contactsLoading, updateContact } = useContacts();
  const contact = getContact(id);
  const [contactIdForEditDialog, setContactIdForEditDialog] = useState<string | null>(null);
  const classes = useStyles();

  if (contactsLoading) {
    return <Typography>{formatMessage({ id: 'loading' })}</Typography>;
  }
  if (contactsError) {
    return <Typography>{contactsError}</Typography>;
  }
  if (isNil(contact)) {
    return null;
  }
  const address = contact.address;
  const isJointOwnerShip = contactContainsType(contact, ContactType.JOINT_OWNERSHIP);
  const ownersOfJointOwnerShip = isJointOwnerShip ? getOwnersOfJointownership(contact, getContact) : [];
  const closeEditContactDialog = () => setContactIdForEditDialog(null);
  const updateContactPerson = async (ownerId: string, contactPerson: boolean) => {
    const newJointOwners = contact.jointOwners?.map((jointOwner) =>
      jointOwner.contactId === ownerId ? { ...jointOwner, contactPerson } : jointOwner
    );
    await updateContact(contact, {
      jointOwners: newJointOwners,
    });
  };

  return (
    <Grid style={{ flexGrow: 1, marginRight: 8 }}>
      {!isJointOwnerShip && (
        <>
          {!isNilOrEmpty(contact.companyName) ? (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextDetail
                  title={formatMessage({
                    id: 'contact.detail.general.companyNameLabel',
                  })}
                >
                  <Typography
                    style={{ color: Colors.CLASSICAL_BLACK, fontSize: 16, fontWeight: 500, display: 'inline-block' }}
                  >
                    {contact.companyName}
                  </Typography>
                  {showLinkButton && (
                    <CustomIconButton
                      id="goToContact"
                      size="small"
                      onClick={() =>
                        history.push({
                          pathname: `${RouteDestination.CONTACTS}/${id}`,
                          state: { goBackUrl: history.location },
                        })
                      }
                      Icon={ExternalIcon}
                      iconStyle={{ fill: Colors.DODGER_BLUE }}
                      style={{ marginLeft: '5px' }}
                    />
                  )}
                </TextDetail>
              </Grid>
              {!isNil(contact.businessNumber) && (
                <Grid item xs={6}>
                  <TextDetail
                    title={formatMessage({ id: 'contact.addContact.businessNumber' })}
                    text={contact.businessNumber}
                  />
                </Grid>
              )}
              {!isNil(contact.vat) && (
                <Grid item xs={6}>
                  <TextDetail title={formatMessage({ id: 'contact.detail.general.vatLabel' })} text={contact.vat} />
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextDetail
                  title={formatMessage({ id: 'contact.detail.general.firstNameLabel' })}
                  text={contact.firstName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextDetail
                  title={formatMessage({ id: 'contact.detail.general.lastNameLabel' })}
                  text={contact.lastName}
                />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            {!isNil(contact.email) && (
              <Grid item xs={6}>
                <TextDetail
                  title={formatMessage({ id: 'contact.detail.general.emailLabel' })}
                  text={contact.email}
                  dataTest="emailDetail"
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <TextDetail
                title={formatMessage({
                  id: 'contact.addContact.typeLabel',
                })}
                text={getContactTypes(contact, formatMessage)}
              />
            </Grid>
            {!isNilOrEmpty(contact.phoneNumbers) && (
              <Grid item xs={6}>
                <TextDetailPhoneNumbers
                  title={formatMessage({ id: 'contact.detail.general.phoneLabel' })}
                  phones={contact.phoneNumbers}
                />
              </Grid>
            )}
          </Grid>
          {(!isNil(contact.birthDate) ||
            !isNil(contact.birthPlace) ||
            !isNil(contact.civility) ||
            !isNil(contact.nationalRegister)) && (
            <>
              <Divider style={{ marginTop: 30, marginBottom: 30, marginLeft: -30, marginRight: -30 }} />
              <Grid container spacing={2} style={{ marginTop: 20 }}>
                {!isNil(contact.birthDate) && (
                  <Grid item xs={6}>
                    <TextDetail
                      title={formatMessage({ id: 'contact.detail.general.birthDate' })}
                      text={format(parseISO(contact.birthDate), 'd MMM yyyy', {
                        locale: (dateLocaleMap as dateLocaleMapType)[language],
                      })}
                    />
                  </Grid>
                )}
                {!isNil(contact.birthPlace) && (
                  <Grid item xs={6}>
                    <TextDetail
                      title={formatMessage({ id: 'contact.detail.general.birthPlace' })}
                      text={contact.birthPlace}
                    />
                  </Grid>
                )}
                {!isNil(contact.civility) && (
                  <Grid item xs={6}>
                    <TextDetail
                      title={formatMessage({ id: 'contact.detail.general.gender' })}
                      text={formatMessage({ id: `contact.detail.gender.${contact.civility.toLocaleLowerCase()}` })}
                    />
                  </Grid>
                )}

                {!isNil(contact.nationalRegister) && (
                  <Grid item xs={6}>
                    <TextDetail
                      title={formatMessage({ id: 'contact.detail.general.nationalRegister' })}
                      text={contact.nationalRegister}
                    />
                  </Grid>
                )}
                {!isNil(contact.language) && (
                  <Grid item xs={6}>
                    <TextDetail
                      title={formatMessage({ id: 'settings.languages' })}
                      text={formatMessage({ id: `enums.AdvertisementLanguages.${contact.language}` })}
                    />
                  </Grid>
                )}
              </Grid>
            </>
          )}
          <ContactAddressDetails address={address} />
        </>
      )}
      {isJointOwnerShip && (
        <>
          <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>
            {formatMessage({ id: 'settings.members' })}
          </Typography>
          <Grid container spacing={2} style={{ marginTop: 5 }}>
            {ownersOfJointOwnerShip.map((owner, index) => {
              const isContactPerson = contact.jointOwners!.find(
                (jointOwner) => jointOwner.contactId === owner.id
              )?.contactPerson;
              return (
                <Grid key={owner!.id} item xs={4}>
                  <Grid style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                    {isContactPerson ? (
                      <Tooltip title={formatMessage({ id: 'contact.editContact.removeContactPersonLabel' })}>
                        <StarRounded
                          className={classes.starIcon}
                          onClick={async () => await updateContactPerson(owner.id, false)}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title={formatMessage({ id: 'contact.editContact.indicateContactPersonLabel' })}>
                        <StarBorderRounded
                          className={classes.starIcon}
                          onClick={async () => await updateContactPerson(owner.id, true)}
                        />
                      </Tooltip>
                    )}
                    <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12, marginLeft: 5 }}>
                      {`${formatMessage({ id: 'building.detail.general.owner' })} ${index + 1}`}
                    </Typography>
                  </Grid>
                  <ContactCard contact={owner} onClick={() => setContactIdForEditDialog(owner.id)} />
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
      {!isNil(contactIdForEditDialog) && (
        <Dialog open onClose={closeEditContactDialog} scroll="body">
          <EditContact afterSubmit={closeEditContactDialog} id={contactIdForEditDialog} />
        </Dialog>
      )}
    </Grid>
  );
};

export default GeneralDetails;
