import { Avatar, Typography } from '@material-ui/core';
import {
  Colors,
  getContactNameFromObject,
  getFirstLettersUppercase,
  stringToColor,
  Contact,
} from '@rentguru/commons-utils';
import React from 'react';

interface ContactCardProps {
  contact: Contact;
  onClick: () => void;
  dataTest?: string;
}

const ContactCard: React.FC<ContactCardProps> = ({ contact, onClick, dataTest = '' }) => {
  const name = getContactNameFromObject(contact);
  const color = stringToColor(name);

  return (
    <div
      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        onClick();
      }}
      data-test={`contactCard${dataTest}`}
    >
      <Avatar
        style={{
          color,
          width: 30,
          height: 30,
          backgroundColor: Colors.PORCELAIN_GREY_3,
          fontSize: 11,
          fontWeight: 800,
          fontStyle: 'normal',
          letterSpacing: 0,
          textAlign: 'center',
          marginRight: 13,
        }}
      >
        {getFirstLettersUppercase(name)}
      </Avatar>
      <Typography
        style={{
          fontSize: 16,
          lineHeight: 1.5,
          color: Colors.CLASSICAL_BLACK,
        }}
      >
        {name}
      </Typography>
    </div>
  );
};

export default ContactCard;
