import { Cache } from 'aws-amplify/utils';

export const getCacheItem = async (key: string) => {
  return await Cache.getItem(key);
};

export const setCacheItem = async (key: string, value: unknown) => {
  await Cache.setItem(key, value);
};

export const removeCacheItem = async (key: string) => {
  await Cache.removeItem(key);
};

export const clearCache = async () => {
  await Cache.clear();
};
