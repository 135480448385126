import { endOfYear, isEqual, parseISO, startOfDay, startOfYear, subMonths, subYears, addMonths } from 'date-fns';
import { isNil } from 'lodash';
import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import CustomYearPicker from 'src/components/ui/CustomYearPicker';
import { DashboardFilters, useDashboardFilters } from 'src/hooks/DashboardFiltersContext';
import FilterButton from './FilterButton';

interface MetricBoxTimeFilter {
  label: string;
  from: Date;
  to: Date;
}

const today = startOfDay(new Date());

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getLastYearFilter = (formatMessage: (descriptor: MessageDescriptor, values?: any) => string) => {
  const todayOneYearBefore = subYears(today, 1);
  const startOfLastYear = startOfYear(todayOneYearBefore);
  const endOfLastYear = endOfYear(todayOneYearBefore);
  return { label: formatMessage({ id: 'dashboard.filters.lastYear' }), from: startOfLastYear, to: endOfLastYear };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getLast5YearsFilter = (formatMessage: (descriptor: MessageDescriptor, values?: any) => string) => {
  const todayFiveYearBefore = subYears(today, 5);
  const startOf5YearsBefore = startOfYear(todayFiveYearBefore);
  const endOf5YearsBefore = endOfYear(todayFiveYearBefore);
  return {
    label: formatMessage({ id: 'dashboard.filters.last5Years' }),
    from: startOf5YearsBefore,
    to: endOf5YearsBefore,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getLast3MonthsFilter = (formatMessage: (descriptor: MessageDescriptor, values?: any) => string) => {
  const today3MonthsBefore = subMonths(today, 3);
  return { label: formatMessage({ id: 'dashboard.filters.last3Months' }), from: today3MonthsBefore, to: today };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getThisYearFilter = (formatMessage: (descriptor: MessageDescriptor, values?: any) => string) => {
  const startOfThisYear = startOfYear(today);
  return { label: formatMessage({ id: 'dashboard.filters.thisYear' }), from: startOfThisYear, to: today };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getOneYearFilter = (formatMessage: (descriptor: MessageDescriptor, values?: any) => string) => {
  const today6MonthsBefore = subMonths(today, 6);
  const today6MonthsAfter = addMonths(today, 6);
  return {
    label: formatMessage({ id: 'dashboard.filters.last6Months' }),
    from: today6MonthsBefore,
    to: today6MonthsAfter,
  };
};

export const getThisYearPlus6MonthsFilter = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatMessage: (descriptor: MessageDescriptor, values?: any) => string
) => {
  const startOfThisYear = startOfYear(today);
  const today6MonthsAfter = addMonths(today, 6);
  return { label: formatMessage({ id: 'dashboard.filters.thisYear' }), from: startOfThisYear, to: today6MonthsAfter };
};

export type MetricBoxTimeFilterName = keyof Omit<DashboardFilters, 'filters'>; // should be a name of MetricBoxFilters

export interface MetricBoxTimeFiltersProps {
  name: MetricBoxTimeFilterName;
  filters: MetricBoxTimeFilter[];
  customYearFilter?: boolean;
  openCustomFilterMenu?: (event?: React.MouseEvent<HTMLElement>) => void;
}

const MetricBoxTimeFilters: React.FC<MetricBoxTimeFiltersProps> = ({
  name,
  filters,
  customYearFilter,
  openCustomFilterMenu,
}) => {
  const { formatMessage } = useIntl();
  const { dashboardFilters, updateDashboardFilters, resetDashboardFilters } = useDashboardFilters();
  const handleFilterChange = async (from: Date, to: Date) => {
    await updateDashboardFilters({
      ...dashboardFilters,
      [name]: { from: from.toISOString(), to: to.toISOString() },
    });
  };

  const resetFilter = async () => {
    await resetDashboardFilters(name);
  };

  const currentFilterValue = dashboardFilters[name];
  const filterSelected = !isNil(currentFilterValue)
    ? filters.find(
        (filter) =>
          isEqual(filter.from, parseISO(currentFilterValue.from)) && isEqual(filter.to, parseISO(currentFilterValue.to))
      )
    : null;
  return (
    <>
      {filters.map((filter) => {
        const isFilteredSelected = !isNil(filterSelected) && filterSelected.label === filter.label;
        return (
          <FilterButton
            key={`${name}${filter.label}`}
            label={filter.label}
            onClick={() => {
              if (isFilteredSelected) {
                resetFilter();
              } else {
                handleFilterChange(filter.from, filter.to);
              }
            }}
            selected={isFilteredSelected}
          />
        );
      })}
      {customYearFilter && (
        <CustomYearPicker
          value={dashboardFilters[name].from}
          onChange={(date: Date | null) => {
            if (!isNil(date)) {
              handleFilterChange(startOfYear(date), endOfYear(date));
            }
          }}
        />
      )}
      {!isNil(openCustomFilterMenu) && (
        <FilterButton
          key={`${name}custom`}
          label={formatMessage({ id: 'dashboard.snooze.custom' })}
          onClick={openCustomFilterMenu}
          selected={!isNil(currentFilterValue) && isNil(filterSelected)}
        />
      )}
    </>
  );
};

export default MetricBoxTimeFilters;
