import React from 'react';
import * as Yup from 'yup';
import { FormikHelpers, Formik, Form } from 'formik';
import isNil from 'lodash/isNil';
import { useIntl } from 'react-intl';
import { Divider, Typography } from '@material-ui/core';
import { ContentHeader, LoaderButton , ActionButton } from '@up2rent/ui';
import toUpper from 'lodash/toUpper';
import { useUsers } from '../../../hooks/UsersContext';
import { useTeams } from '../../../hooks/TeamsContext';
import {
  ContactType,
  Team,
  User,
  UserTeam,
  Colors,
  contactContainsType,
  getContactName,
} from '@rentguru/commons-utils';
import { CustomMenuItemType } from '../../ui/ComboBox/TextComboBox';
import ComboBox from '../../ui/ComboBox/FormikTextComboBox';
import { getTeamChildren, getTeamParents } from 'src/utils/teamUtils';

interface AddMemberToTeamFormValues {
  userId: string;
}

const AddMemberSchema = Yup.object()
  .shape({
    userId: Yup.string().min(1).required(),
  })
  .required();

interface AddMemberToTeamFormProps {
  team: Team;
  onClose?: (userId?: string) => void;
}

const initialValues: AddMemberToTeamFormValues = {
  userId: '',
};

const AddMemberToTeamForm: React.FC<AddMemberToTeamFormProps> = ({ team, onClose }) => {
  const { formatMessage } = useIntl();
  const { users, createUserTeam, getUser, updateUser, loading, error } = useUsers();
  const { getTeamUsers, getUserTeams, deleteUserTeam, teams, getTeam } = useTeams();

  const usersTeam = getTeamUsers(team.id);
  const members = users.filter(
    (u) => !u.rootUser && !isNil(u.contact) && contactContainsType(u.contact, ContactType.MEMBER)
  );
  const usersNotInTeam = members.filter((u) => isNil(usersTeam.find((ut) => ut.id === u.id)));
  const handleCreate = async (
    values: AddMemberToTeamFormValues,
    { setSubmitting, setStatus }: FormikHelpers<AddMemberToTeamFormValues>
  ) => {
    const { userId } = values;
    const teamsOfUser = getUserTeams(userId);
    const userTeamPromises: Promise<UserTeam | User>[] = [];
    for (const teamOfUser of teamsOfUser) {
      const teamChildren: string[] = [];
      const teamParents: string[] = [];
      getTeamChildren(teamOfUser.id, teams, teamChildren);
      getTeamParents(teamOfUser, teams, teamParents);
      if (teamChildren.includes(team.id) || teamParents.includes(team.id)) {
        const completeTeam = getTeam(teamOfUser.id);
        if (completeTeam) {
          const userTeamToDelete = completeTeam.users!.find((t) => t.user && t.user.id === userId);
          if (userTeamToDelete) {
            userTeamPromises.push(deleteUserTeam(userTeamToDelete.id));
          }
        }
      }
    }
    const completeUser = getUser(values.userId);
    if (completeUser) {
      userTeamPromises.push(createUserTeam({ teamId: team.id, userId: completeUser.id }));
      userTeamPromises.push(updateUser(completeUser, { refreshToken: true }));
    }

    setStatus(true);
    setSubmitting(false);
    if (!isNil(onClose)) {
      onClose(values.userId);
    }
  };

  if (loading) {
    return <Typography>{formatMessage({ id: 'loading' })}</Typography>;
  }
  if (error) {
    return <Typography>{error}</Typography>;
  }

  const suggestions: CustomMenuItemType[] = usersNotInTeam.map((u) => {
    const name = getContactName(u.firstName, u.lastName);
    return { primary: name, secondary: u.email, value: u.id };
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddMemberSchema}
      onSubmit={handleCreate}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, errors, touched, status, isSubmitting }) => {
        const { userId: selectedUserId } = values;
        const userTeams = getUserTeams(selectedUserId);
        let downgradeWarning: string | undefined;
        let upgradeWarning: string | undefined;
        for (const userTeam of userTeams) {
          const teamChildren: string[] = [];
          const teamParents: string[] = [];
          getTeamChildren(userTeam.id, teams, teamChildren);
          getTeamParents(userTeam, teams, teamParents);
          if (teamChildren.includes(team.id)) {
            downgradeWarning = formatMessage({ id: 'settings.editTeam.downgradeWarning' }, { name: userTeam.name });
          } else if (teamParents.includes(team.id)) {
            upgradeWarning = formatMessage({ id: 'settings.editTeam.upgradeWarning' }, { name: userTeam.name });
          }
        }
        return (
          <Form>
            <ContentHeader title={formatMessage({ id: 'settings.addMember.addUser' })} />
            <Divider style={{ marginBottom: 20 }} />
            <div style={{ paddingLeft: 30, paddingRight: 30 }}>
              <ComboBox
                label={formatMessage({ id: 'settings.addMember.selectUser' })}
                name="userId"
                data={suggestions}
                inputStyle={{ width: 580 }}
                noOptionsText={formatMessage({ id: 'settings.addMember.noUsers' })}
                error={Boolean(errors.userId && touched.userId)}
              />
              {(downgradeWarning || upgradeWarning) && (
                <Typography style={{ color: Colors.BURNING_ORANGE }}>{upgradeWarning || downgradeWarning}</Typography>
              )}
            </div>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <div
              style={{
                marginRight: 30,
                marginBottom: 20,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <ActionButton
                onClick={() => {
                  if (!isNil(onClose)) {
                    onClose();
                  }
                }}
                style={{
                  background: 'none',
                  color: Colors.DARK_SLATE_GREY,
                  marginRight: 20,
                }}
              >
                {toUpper(
                  formatMessage({
                    id: 'cancel',
                  })
                )}
              </ActionButton>
              <LoaderButton loading={isSubmitting} success={status}>
                {formatMessage({
                  id: 'save',
                })}
              </LoaderButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddMemberToTeamForm;
