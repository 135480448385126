/* eslint-disable @typescript-eslint/no-shadow */
import { Collapse, Grid, IconButton, InputAdornment, Typography } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Skeleton } from '@material-ui/lab';
import {
  Colors,
  EmptyFile,
  File as FileModel,
  LeaseInventory,
  LeaseInventoryType,
  S3Object,
  formatNumber,
  getEmptyFile,
} from '@rentguru/commons-utils';
import { ConfirmDialog, CustomIconButton } from '@up2rent/ui';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { FilePreviewDialogBundle } from 'src/components/LeaseInventory/LeaseInventoryEncoding/InventoryEncodings';
import CheckButtonGroup from 'src/components/ui/CheckButtonGroup';
import PlusMinusButtonGroup from 'src/components/ui/PlusMinusButtonGroup';
import TextFieldArea from 'src/components/ui/TextFieldArea';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { v4 as uuidv4 } from 'uuid';
import {
  ExtendedLeaseInventoryEncoding,
  ExtendedUnitInventory,
  ExtendedUnitInventoryStructure,
} from '../../../hooks/UnitInventoryContext';
import { ReactComponent as ClipIcon } from '../../../icons/paperclip.svg';
import AudioRecorder from '../../ui/AudioRecorder';
import { FurnitureInventoryFormik } from '../FurnitureInventoryDetails';
import { FurnitureIssueDialogBundle } from './FurnitureInventoryEncoding';
import FurnitureStructureEncodingExtraInfos from './FurnitureStructureEncodingExtraInfos';

interface FurnitureStructureEncodingProps {
  leaseInventory: LeaseInventory;
  completeUnitInventory: ExtendedUnitInventory;
  inventoryStructure: ExtendedUnitInventoryStructure;
  structureFieldName: string;
  previewFileFunction: (fileBundle: FilePreviewDialogBundle) => void;
  encodeFurnitureIssue: (issueBundle: FurnitureIssueDialogBundle) => void;
  readOnly: boolean;
  style?: React.CSSProperties;
}

const FurnitureStructureEncoding: React.FC<FurnitureStructureEncodingProps> = ({
  leaseInventory,
  completeUnitInventory,
  inventoryStructure,
  structureFieldName,
  previewFileFunction,
  encodeFurnitureIssue,
  readOnly,
  style = {},
}) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  // Should have only one encoding by pair unitStructure-Lease
  const structureEncoding: ExtendedLeaseInventoryEncoding | undefined = inventoryStructure.encodings[0];
  const [originalEncoding] = useState(structureEncoding);
  const [showExtraInfo, setShowExtraInfo] = useState<boolean>(false);
  const [fileToDelete, setFileToDelete] = useState<S3Object | EmptyFile | null>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const { values, setFieldValue } = useFormikContext<FurnitureInventoryFormik>();
  const getEmptyFurnitureEncoding = (): ExtendedLeaseInventoryEncoding => {
    return {
      id: `NEW-${uuidv4()}`,
      leaseInventoryId: leaseInventory.id,
      structureId: inventoryStructure.id,
      furnitureQuantity: 1,
      furniturePrice: undefined,
      checkedItems: [],
      medias: [],
      clientId: '',
      readId: '',
    };
  };

  const updateEncodingOfCompleteInventory = (
    updateFunction: (encoding: ExtendedLeaseInventoryEncoding) => ExtendedLeaseInventoryEncoding
  ) => {
    const newUnitInventories = values.localUnitInventories.reduce(
      (acc: ExtendedUnitInventory[], unitInventory: ExtendedUnitInventory) => {
        if (unitInventory.id === completeUnitInventory.id) {
          const newStructures = unitInventory.structures.map((uis) => {
            if (uis.id !== inventoryStructure.id) return uis;

            let newEncoding: ExtendedLeaseInventoryEncoding;
            if (isEmpty(uis.encodings)) {
              newEncoding = getEmptyFurnitureEncoding();
            } else {
              newEncoding = uis.encodings[0];
            }
            return { ...uis, encodings: [updateFunction(newEncoding)] };
          });
          acc.push({ ...unitInventory, structures: newStructures });
        } else {
          acc.push(unitInventory);
        }
        return acc;
      },
      []
    );
    setFieldValue('localUnitInventories', newUnitInventories);
  };

  const removeStructure = () => {
    const newUnitInventories = values.localUnitInventories.reduce(
      (acc: ExtendedUnitInventory[], unitInventory: ExtendedUnitInventory) => {
        if (unitInventory.id === completeUnitInventory.id) {
          const newStructures = unitInventory.structures.filter((uis) => uis.id !== inventoryStructure.id);
          acc.push({ ...unitInventory, structures: newStructures });
        } else {
          acc.push(unitInventory);
        }
        return acc;
      },
      []
    );
    setFieldValue('localUnitInventories', newUnitInventories);
  };

  const expandIconStyle = (showExtraInfo: boolean): object => ({
    transform: `rotate(${showExtraInfo ? 180 : 0}deg)`,
    fill: showExtraInfo ? Colors.DARK_SLATE_GREY : Colors.BLUEY,
    width: 16,
    height: 16,
  });

  if (isNil(structureEncoding) && readOnly) {
    return (
      <Grid container style={{ marginTop: 15, marginBottom: 15, alignItems: 'center', cursor: 'pointer' }}>
        <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Typography style={{ fontSize: 14, fontWeight: 700 }}>{inventoryStructure.name}</Typography>
        </Grid>
        <Grid item xs={8} style={{ display: 'flex', color: Colors.SLATE_GREY }}>
          <Typography style={{ fontSize: 14, fontWeight: 700 }}>
            {formatMessage({ id: 'furnitureInventory.nothingEncoded' })}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  if (isNil(structureEncoding) && !readOnly) {
    // Add an empty encoding
    updateEncodingOfCompleteInventory((encoding: ExtendedLeaseInventoryEncoding) => {
      return encoding;
    });
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton style={{ width: '100%' }} />
      </div>
    );
  }

  const entrance = leaseInventory.inventoryType === LeaseInventoryType.FURNITURES_ENTRANCE;

  return (
    <div style={{ marginLeft: 30, ...style }}>
      <Grid
        container
        style={{ marginTop: 15, marginBottom: 15, alignItems: 'center', cursor: 'pointer' }}
        onClick={(event) => {
          event.stopPropagation();
          setShowExtraInfo(!showExtraInfo);
        }}
      >
        <Grid item xs={6}>
          {readOnly || !entrance ? (
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Typography
                style={{ fontSize: 14, fontWeight: 700, display: 'inline-flex', marginRight: 30, minWidth: 30 }}
              >
                {structureEncoding.furnitureQuantity}
              </Typography>
              <Typography style={{ fontSize: 14, fontWeight: 700, display: 'inline-flex' }}>
                {inventoryStructure.name}
              </Typography>
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <PlusMinusButtonGroup
                initialValue={!isNil(structureEncoding.furnitureQuantity) ? structureEncoding.furnitureQuantity : 0}
                onChange={(value: number) => {
                  updateEncodingOfCompleteInventory((encoding: ExtendedLeaseInventoryEncoding) => {
                    return {
                      ...encoding,
                      furnitureQuantity: value,
                    };
                  });
                }}
                style={{ marginLeft: -10 }}
              />
              <TextFieldArea
                name={`${structureFieldName}.name`}
                rows={1}
                rowsMax={1}
                inputProps={{ style: { textAlign: 'left' } }}
                InputProps={{ style: { fontSize: 14, fontWeight: 600, width: '100%' } }}
                onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          {readOnly || !entrance ? (
            <Typography style={{ fontSize: 14, color: Colors.SLATE_GREY }}>
              {!isNil(structureEncoding.furniturePrice)
                ? formatNumber(structureEncoding.furniturePrice, language, {
                    style: 'currency',
                    currency: 'EUR',
                  })
                : ''}
            </Typography>
          ) : (
            <TextFieldArea
              value={!isNil(structureEncoding) ? structureEncoding.furniturePrice : 0}
              onChange={(value: string) => {
                updateEncodingOfCompleteInventory((encoding: ExtendedLeaseInventoryEncoding) => {
                  if (value === '')
                    return {
                      ...encoding,
                      furniturePrice: 0, // It is WRONG but it allows '.' in the textField
                    };
                  const convertedValue = Number(value);
                  if (isNaN(convertedValue) || convertedValue < 0) return encoding;

                  const isFloat = value.includes('.');
                  return {
                    ...encoding,
                    // It is WRONG but it allows '.' in the textField
                    furniturePrice: isFloat ? (value as unknown as number) : convertedValue,
                  };
                });
              }}
              rows={1}
              rowsMax={1}
              inputProps={{ style: { textAlign: 'right' } }}
              InputProps={{ style: { fontSize: 14, color: Colors.SLATE_GREY, width: '100%' } }}
              EndAdornment={
                <InputAdornment position="end">
                  <Typography style={{ fontSize: 14, color: Colors.SLATE_GREY }}>€</Typography>
                </InputAdornment>
              }
              onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
            />
          )}
        </Grid>
        {entrance && (
          <Grid item xs={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!readOnly && (
              <>
                <input
                  accept="image/*"
                  type="file"
                  id={inventoryStructure.id}
                  multiple={true}
                  style={{ display: 'none' }}
                  onChange={async (event) => {
                    event.stopPropagation();
                    const selectedFiles = event.target.files;
                    if (!isNil(selectedFiles) && !isEmpty(selectedFiles)) {
                      const newMediaFiles: EmptyFile[] = [];
                      for (const newFile of Array.from(selectedFiles)) {
                        newMediaFiles.push(getEmptyFile(newFile));
                      }
                      const mediaFiles =
                        !isNil(structureEncoding) && !isNil(structureEncoding.medias)
                          ? [...structureEncoding.medias, ...newMediaFiles]
                          : newMediaFiles;
                      updateEncodingOfCompleteInventory((encoding: ExtendedLeaseInventoryEncoding) => {
                        return { ...encoding, medias: mediaFiles as FileModel[] };
                      });
                    }
                    setShowExtraInfo(true);
                  }}
                />
                <label htmlFor={inventoryStructure.id}>
                  <IconButton component="span" size="small">
                    <ClipIcon style={{ fill: Colors.BLUEY, width: 16, height: 16 }} />
                  </IconButton>
                </label>
              </>
            )}
          </Grid>
        )}
        <Grid item xs={entrance ? 1 : 2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {!readOnly && entrance && (
            <AudioRecorder
              createFile={async (file: File) => {
                const emptyAudioFile = getEmptyFile(file);
                const updateMediaObject =
                  !isNil(structureEncoding) && !isNil(structureEncoding.medias)
                    ? [...structureEncoding.medias, emptyAudioFile]
                    : [emptyAudioFile];
                updateEncodingOfCompleteInventory((encoding: ExtendedLeaseInventoryEncoding) => {
                  return { ...encoding, medias: updateMediaObject as FileModel[] };
                });
                setShowExtraInfo(true);
              }}
            />
          )}
          {!entrance && (
            <CheckButtonGroup
              onCheck={
                readOnly
                  ? () => {}
                  : () => {
                      updateEncodingOfCompleteInventory((_oldEncoding: ExtendedLeaseInventoryEncoding) => {
                        return { ...originalEncoding, furnitureExitIssues: [] };
                      });
                    }
              }
              onUncheck={
                readOnly
                  ? () => {}
                  : () => {
                      encodeFurnitureIssue({
                        encoding: structureEncoding,
                        structure: inventoryStructure,
                        afterSubmit: (encoding: ExtendedLeaseInventoryEncoding) =>
                          updateEncodingOfCompleteInventory((_oldEncoding: ExtendedLeaseInventoryEncoding) => {
                            return encoding;
                          }),
                      });
                    }
              }
              checked={
                !isNil(structureEncoding) && !isNil(structureEncoding.furnitureExitIssues)
                  ? !!isEmpty(structureEncoding.furnitureExitIssues)
                  : undefined
              }
            />
          )}
        </Grid>
        <Grid item xs={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CustomIconButton
            onClick={(event) => {
              event.stopPropagation();
              setShowExtraInfo(!showExtraInfo);
            }}
            size="small"
            Icon={ExpandMore}
            iconStyle={expandIconStyle(showExtraInfo)}
          />
        </Grid>
      </Grid>
      <Collapse in={showExtraInfo} mountOnEnter unmountOnExit>
        <FurnitureStructureEncodingExtraInfos
          encoding={structureEncoding}
          structure={inventoryStructure}
          structureFieldName={structureFieldName}
          previewFileFunction={previewFileFunction}
          updateEncoding={updateEncodingOfCompleteInventory}
          removeStructure={setOpenConfirmDialog}
          setFileToDelete={(file: S3Object | EmptyFile) => setFileToDelete(file)}
          readOnly={readOnly || !entrance}
        />
      </Collapse>
      {!isNil(fileToDelete) && (
        <ConfirmDialog
          open={!isNil(fileToDelete)}
          mainText={formatMessage({ id: 'leaseInventory.deleteFileWarning' })}
          confirmText={formatMessage({ id: 'delete' })}
          confirmAction={
            !isNil(fileToDelete)
              ? () => {
                  updateEncodingOfCompleteInventory((encoding: ExtendedLeaseInventoryEncoding) => {
                    return { ...encoding, medias: encoding.medias!.filter((media) => media.id !== fileToDelete.id) };
                  });
                  setFileToDelete(null);
                }
              : () => {}
          }
          cancelAction={() => setFileToDelete(null)}
          formatMessage={formatMessage}
        />
      )}
      {openConfirmDialog && (
        <ConfirmDialog
          open={openConfirmDialog}
          confirmText={formatMessage({ id: 'delete' })}
          confirmAction={removeStructure}
          cancelAction={() => setOpenConfirmDialog(false)}
          mainText={formatMessage({ id: 'furnitureInventory.confirmDeleteItem' })}
          formatMessage={formatMessage}
        />
      )}
    </div>
  );
};

export default FurnitureStructureEncoding;
