/* eslint-disable @typescript-eslint/no-shadow */
import { Divider, FormControl, FormControlLabel, Grid, MenuItem, RadioGroup, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import SimpleLeaseSelector from 'src/components/ui/SimpleSelectors/SimpleLeaseSelector';
import CustomDatePicker from 'src/components/ui/CustomDatePicker';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import { useLeases } from 'src/hooks/LeasesContext';
import { useIntl } from 'react-intl';
import SkeletonComponent from 'src/components/ui/SkeletonComponent';
import { useFormikContext } from 'formik';
import { AddTenantStatementValues } from './AddTenantStatement';
import { fetchStatements, getAvailableTenantPostingsForLease } from 'src/hooks/StatementContext';
import AddTenantStatementPostings from './AddTenantStatementPostings';
import {
  AccountLabel,
  Lease,
  LeaseMonthlyChargesType,
  LeaseStatus,
  StatementStatus,
  endOfUTCDay,
  endOfUTCMonth,
  getLastSemester,
  getLastTrimester,
  getLastYear,
  startOfUTCDay,
  startOfUTCMonth,
  getUTCDate,
  Colors,
  isDateValid,
} from '@rentguru/commons-utils';
import StyledRadio from 'src/components/ui/StyledRadio';
import { areIntervalsOverlapping, isBefore, isSameDay, maxTime, minTime, parseISO } from 'date-fns';
import { isNil } from 'lodash';
import CustomizedSelect from 'src/components/ui/CustomizedSelect';
import IconnedRemark from 'src/components/ui/IconnedRemark';
import { ReactComponent as BulbSVG } from 'src/icons/bulb.svg';
import HelpIconPopover from 'src/components/ui/HelpIconPopover';

interface AddTenantStatementFormProps {
  accountLabels: AccountLabel[];
  defaultLease?: Lease;
  defaultClosing?: boolean;
}

export interface IntervalWithKey {
  key: string;
  start: Date | null;
  end: Date | null;
}

const AddTenantStatementForm: React.FC<AddTenantStatementFormProps> = ({
  accountLabels,
  defaultLease,
  defaultClosing,
}) => {
  const { formatMessage } = useIntl();
  const { leasesLoading, leases } = useLeases();
  const { values, errors, setFieldValue, touched } = useFormikContext<AddTenantStatementValues>();

  const {
    lease,
    postingsToTreat,
    periodFrom,
    periodTo,
    closingStatement,
    existingClosingStatement,
    existingUnclosedStatement,
  } = values;

  useEffect(() => {
    const buildPostingsToTreat = async (leaseId: string, periodTo: string) => {
      const availablePostingsForLease = await getAvailableTenantPostingsForLease(leaseId, periodTo);
      setFieldValue('postingsToTreat', availablePostingsForLease);
    };
    if (lease && periodTo) buildPostingsToTreat(lease.id, periodTo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lease, periodTo]);

  useEffect(() => {
    const getExistingLeaseStatement = async (leaseId: string) => {
      const statementsForLease = await fetchStatements('byLeaseId', leaseId);
      const closingStatementForLease = statementsForLease.find((s) => s.closingStatement);
      const unclosedStatementForLease = statementsForLease.find((s) => s.status === StatementStatus.TO_VALIDATE);

      setFieldValue('existingClosingStatement', closingStatementForLease);
      setFieldValue('existingUnclosedStatement', unclosedStatementForLease);
    };
    if (lease) getExistingLeaseStatement(lease.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lease]);

  let helperText = '';
  if (postingsToTreat && postingsToTreat.length === 0) {
    helperText = formatMessage({ id: 'accounting.statement.addTenantStatementSection.noPostingsToTreatError' });
  } else if (existingUnclosedStatement) {
    helperText = formatMessage({ id: 'accounting.statement.addTenantStatementSection.alreadyUnClosedStatementError' });
  } else if (closingStatement && existingClosingStatement) {
    helperText = formatMessage({ id: 'accounting.statement.addTenantStatementSection.alreadyClosingStatementWarning' });
  }

  const today = new Date();
  const lastYear = getLastYear(today);
  const lastTrimester = getLastTrimester(today);
  const lastSemester = getLastSemester(today);
  const periodCoveredTypes: IntervalWithKey[] = [
    {
      key: 'dashboard.filters.thisMonth',
      start: startOfUTCMonth(today),
      end: endOfUTCMonth(today),
    },
    {
      key: 'dashboard.filters.lastTrimester',
      start: lastTrimester.start,
      end: lastTrimester.end,
    },
    { key: 'dashboard.filters.lastSemester', start: lastSemester.start, end: lastSemester.end },
    {
      key: 'dashboard.filters.lastYear',
      start: lastYear.start,
      end: lastYear.end,
    },
    { key: 'dashboard.snooze.custom', start: null, end: null },
  ];

  const dateTypeSelected =
    values.periodFrom && values.periodTo
      ? periodCoveredTypes.find(
          (periodCoveredType) =>
            periodCoveredType.start &&
            periodCoveredType.end &&
            isSameDay(periodCoveredType.start, new Date(values.periodFrom!)) &&
            isSameDay(periodCoveredType.end, new Date(values.periodTo!))
        )
      : undefined;
  const keySelected = dateTypeSelected ? dateTypeSelected.key : 'dashboard.snooze.custom';

  const leasesToProposes = leases.filter((l) => {
    if (
      l.units &&
      l.units.every((unitLease) => unitLease.monthlyChargesType !== LeaseMonthlyChargesType.MonthlyProvisioned)
    ) {
      return false;
    }

    if (!(l.status === LeaseStatus.Active || l.status === LeaseStatus.Ended || l.status === LeaseStatus.Cancelled))
      return false;
    if (periodFrom && periodTo && isBefore(new Date(periodTo), new Date(periodFrom))) return false;
    const leaseInterval = {
      start: new Date(l.startDate),
      end: new Date(l.endDate),
    };
    const periodInterval = {
      start: periodFrom ? new Date(periodFrom) : minTime,
      end: periodTo ? new Date(periodTo) : maxTime,
    };
    if (!areIntervalsOverlapping(leaseInterval, periodInterval)) return false;
    return true;
  });

  return (
    <Grid style={{ paddingLeft: 30, paddingRight: 30 }}>
      <Typography style={{ fontSize: 14, fontWeight: 700 }}>
        {formatMessage({ id: 'accounting.statement.statementPeriod' })}
      </Typography>
      <CustomizedSelect
        label={formatMessage({ id: 'accounting.charge.affectCharge.selectPeriod' })}
        value={keySelected}
        error={false}
        FormControlProps={{ required: false, style: { width: 580 } }}
      >
        {periodCoveredTypes.map((periodCoveredType) => {
          return (
            <MenuItem
              key={periodCoveredType.key}
              value={periodCoveredType.key}
              onClick={() => {
                setFieldValue('periodFrom', periodCoveredType.start ? periodCoveredType.start.toISOString() : null);
                setFieldValue('periodTo', periodCoveredType.end ? periodCoveredType.end.toISOString() : null);
              }}
            >
              {formatMessage({ id: periodCoveredType.key })}
            </MenuItem>
          );
        })}
      </CustomizedSelect>
      <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
        <CustomDatePicker
          name="periodFrom"
          label={formatMessage({ id: 'lease.detail.general.startDate' })}
          value={periodFrom ? getUTCDate(new Date(periodFrom)) : null}
          style={{ width: 280 }}
          onChange={(date) => {
            if (date) setFieldValue('periodFrom', startOfUTCDay(date).toISOString());
          }}
          maxDate={periodTo && isDateValid(parseISO(periodTo)) ? parseISO(periodTo) : undefined}
          keyboardIconStyle={{ width: '20px' }}
          keyboardIconProps={{ size: 'small' }}
          safeDateFormat={true}
          error={Boolean(touched.periodFrom && errors.periodFrom)}
          onClear={() => setFieldValue('periodFrom', null)}
        />
        <CustomDatePicker
          name="periodTo"
          label={formatMessage({ id: 'lease.detail.general.endDate' })}
          value={periodTo ? getUTCDate(new Date(periodTo)) : null}
          style={{ width: 280, marginLeft: 20 }}
          onChange={(date) => {
            if (date) setFieldValue('periodTo', endOfUTCDay(date).toISOString());
          }}
          minDate={periodFrom && isDateValid(parseISO(periodFrom)) ? parseISO(periodFrom) : undefined}
          keyboardIconStyle={{ width: '20px' }}
          keyboardIconProps={{ size: 'small' }}
          safeDateFormat={true}
          error={Boolean(touched.periodTo && errors.periodTo)}
          onClear={() => setFieldValue('periodTo', null)}
        />
      </Grid>
      <Divider style={{ margin: '30px -30px 30px -30px' }} />
      <Grid style={{ display: 'flex' }}>
        <Typography style={{ fontSize: 14, fontWeight: 700 }}>
          {formatMessage({ id: 'accounting.statement.addTenantStatementSection.chooseLease' })}
        </Typography>
        <HelpIconPopover
          iconStyle={{ marginLeft: 10 }}
          HelperBody={
            <Grid style={{ maxWidth: 300 }}>
              <Typography>{formatMessage({ id: 'accounting.statement.helperText' })}</Typography>
            </Grid>
          }
        />
      </Grid>
      <SkeletonComponent loading={leasesLoading!}>
        <SimpleLeaseSelector
          defaultValue={defaultLease}
          disabled={!isNil(defaultLease)}
          leases={leasesToProposes}
          type="uncontrolled"
          fieldName="lease"
          inputWidth={580}
        />
      </SkeletonComponent>
      <Divider style={{ margin: '30px -30px 30px -30px' }} />
      <Typography style={{ fontSize: 14, fontWeight: 700, marginRight: 10 }}>
        {formatMessage({ id: 'accounting.statement.addTenantStatementSection.statementType' })}
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          value={closingStatement}
          onChange={(_e, value) => {
            setFieldValue('closingStatement', value === 'true');
          }}
          row={false}
        >
          <FormControlLabel
            value={false}
            control={<StyledRadio />}
            label={
              <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                {formatMessage({ id: 'accounting.statement.addTenantStatementSection.intermediate' })}
              </Typography>
            }
            disabled={!isNil(defaultClosing)}
          />
          <FormControlLabel
            value={true}
            control={<StyledRadio />}
            label={
              <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                {formatMessage({ id: 'accounting.statement.addTenantStatementSection.endOfLease' })}
              </Typography>
            }
            disabled={!isNil(defaultClosing)}
          />
        </RadioGroup>
      </FormControl>
      {(helperText !== '' || (lease && postingsToTreat && postingsToTreat.length > 1)) && (
        <Divider style={{ margin: '30px -30px 30px -30px' }} />
      )}
      {helperText !== '' && (
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          <InfoSvg
            style={{
              fill:
                errors.existingUnclosedStatement || errors.postingsToTreat ? Colors.CARNATION_RED : Colors.SLATE_GREY,
              marginRight: 20,
            }}
          />
          <Typography
            style={{
              fontSize: 14,
              color:
                errors.existingUnclosedStatement || errors.postingsToTreat ? Colors.CARNATION_RED : Colors.SLATE_GREY,
              maxWidth: 540,
            }}
          >
            {helperText}
          </Typography>
        </Grid>
      )}
      {lease && postingsToTreat && postingsToTreat.length >= 1 && (
        <>
          <Typography style={{ fontSize: 14, fontWeight: 700, marginBottom: 20 }}>
            {formatMessage({ id: 'accounting.statement.addTenantStatementSection.remainingPostings' })}
          </Typography>
          <IconnedRemark
            Icon={BulbSVG}
            message={
              <Grid>
                <Typography variant="h6">{formatMessage({ id: 'accounting.statement.important' })}</Typography>
                <Typography>{formatMessage({ id: 'accounting.statement.remarkMainMessage' })}</Typography>
                <Typography variant="h6">{formatMessage({ id: 'accounting.statement.toRemember' })}</Typography>
                <ul style={{ margin: 0 }}>
                  <li>
                    <Typography>{formatMessage({ id: 'accounting.statement.dot1' })}</Typography>
                  </li>
                  <li>
                    <Typography>{formatMessage({ id: 'accounting.statement.dot2' })}</Typography>
                  </li>
                </ul>
              </Grid>
            }
            style={{ marginLeft: 0 }}
          />
          <AddTenantStatementPostings postings={postingsToTreat} accountLabels={accountLabels} />
        </>
      )}
    </Grid>
  );
};

export default AddTenantStatementForm;
