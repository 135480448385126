import React from 'react';
import { useFormikContext, FormikProps } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import TextDetailEditable from '../../TextDetailEditable';
import { Divider, Typography, Collapse, InputAdornment, MenuItem, Tooltip, Grid } from '@material-ui/core';
import CustomizedSwitch from '../../CustomizedSwitch';
import { isNil, toNumber } from 'lodash';
import CustomizedSelect from '../../CustomizedSelect';
import { getTranslatedEnumFormatMessage } from '../../../../utils/enumTypes';
import { LeaseModeFormValues } from '../FormSection/LeaseModeSelectorForm';
import { ReactComponent as InfoSvg } from '../../../../icons/info.svg';
import HelpIconPopover from 'src/components/ui/HelpIconPopover';
import SkeletonComponent from '../../SkeletonComponent';
import { Colors, getTranslatedVatRates, LeaseMonthlyChargesType, VatRate } from '@rentguru/commons-utils';
import { FurnishedRentalFormValues } from '../FormSection/FurnishedRental';
import { isAfter, isToday } from 'date-fns';
import { MonthlyChargesFieldsFormValues } from './ChargesFields';

export interface LeaseRentFormValues {
  allowChargeAdjustment: boolean;
  furnishedRentalRentalPricePercentage?: number;
  includeMonthlyCharge: boolean;
  indexation?: boolean;
  initialRentalPrice: number;
  monthlyCharges?: number;
  monthlyChargesType?: LeaseMonthlyChargesType;
  rentalPrice: number;
  applyVat: boolean;
  vatRateCharge?: number;
  vatRateRent?: number;
  rentalIndexation: boolean;
  chargeIndexation: boolean;
}

export const LeaseRentSchema = Yup.object()
  .shape({
    allowChargeAdjustment: Yup.boolean().nullable(),
    includeMonthlyCharge: Yup.boolean().required(),
    rentalPrice: Yup.number().positive().required(),
    monthlyCharges: Yup.number().min(0).nullable(),
    indexation: Yup.boolean().nullable(),
    rentalIndexation: Yup.boolean().nullable(),
    chargeIndexation: Yup.boolean().nullable(),
    furnishedRentalRentalPricePercentage: Yup.number().positive().when('furnishedRental', {
      is: true,
      then: Yup.number().positive().required(),
      otherwise: Yup.number().nullable(),
    }),
    initialRentalPrice: Yup.number()
      .when('newLease', {
        is: false,
        then: Yup.number().positive().required(),
        otherwise: Yup.number().nullable(),
      })
      // eslint-disable-next-line func-names
      .test(function (value) {
        const parentValue = this.parent as LeaseRentFormValues & { startDate: string } & MonthlyChargesFieldsFormValues;
        const indexationActive = Boolean(parentValue.indexation && parentValue.rentalIndexation);
        return isInitialRentalPriceValidWithIndexationSettingsAndValue(
          indexationActive,
          new Date(parentValue.startDate),
          toNumber(value),
          toNumber(parentValue.rentalPrice)
        );
      }),
    initialChargePrice: Yup.number()
      .when('newLease', {
        is: false,
        then: Yup.number().required(),
        otherwise: Yup.number().nullable(),
      })
      // eslint-disable-next-line func-names
      .test(function (value) {
        const parentValue = this.parent as LeaseRentFormValues & { startDate: string } & MonthlyChargesFieldsFormValues;
        const indexationActive = Boolean(parentValue.indexation && parentValue.chargeIndexation);
        return isInitialChargesPriceValidWithIndexationSettingsAndValue(
          indexationActive,
          new Date(parentValue.startDate),
          toNumber(value),
          toNumber(parentValue.monthlyChargesFields.monthlyCharges),
          parentValue.monthlyChargesFields.monthlyChargesType as LeaseMonthlyChargesType | undefined
        );
      }),
  })
  .required();

export const isInitialRentalPriceValidWithIndexationSettingsAndValue = (
  indexation: boolean,
  startDate: Date,
  initialRentalPrice: number,
  rentalPrice: number
) => {
  if (!indexation || isAfter(startDate, new Date()) || isToday(startDate)) {
    return true;
  }
  return initialRentalPrice <= rentalPrice;
};

export const isInitialChargesPriceValidWithIndexationSettingsAndValue = (
  indexation: boolean,
  startDate: Date,
  initialMonthlyPrice: number,
  monthlyPrice: number,
  chargeType?: LeaseMonthlyChargesType
) => {
  if (!chargeType || chargeType === LeaseMonthlyChargesType.MonthlyProvisioned) {
    return true;
  }
  if (!indexation || isAfter(startDate, new Date()) || isToday(startDate)) {
    return true;
  }
  return initialMonthlyPrice <= monthlyPrice;
};

interface LeaseRentFieldsProps {
  vatRates: VatRate[];
  vatRatesLoading: boolean;
}

const LeaseRentFields: React.FC<LeaseRentFieldsProps> = ({ vatRates, vatRatesLoading }) => {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue }: FormikProps<LeaseRentFormValues> =
    useFormikContext();
  const { formatMessage } = useIntl();
  const country = 'BE';
  // TODO filter by correct country
  const vatRatesToPropose = getTranslatedVatRates(vatRates, formatMessage, country, true);

  const shouldDisplayFurnishedRentPercentage = (values as unknown as FurnishedRentalFormValues).furnishedRental
    .furnishedRental;
  const shouldDisplayInitialRentalPrice = isNil((values as unknown as LeaseModeFormValues).newLease)
    ? false // Means we are editing the lease
    : !(values as unknown as LeaseModeFormValues).newLease;
  return (
    <>
      <>
        {shouldDisplayInitialRentalPrice && (
          <TextDetailEditable
            editMode
            title={formatMessage({ id: 'lease.indexation.initialRentalPrice' })}
            name="initialRentalPrice"
            type="number"
            style={{ width: 580 }}
            min={0}
            endAdornment={<InputAdornment position="end">EUR</InputAdornment>}
            typeNumberNoEndArrow
            noMaxWidth
            error={Boolean(errors.initialRentalPrice && touched.initialRentalPrice)}
          />
        )}

        <TextDetailEditable
          editMode
          title={formatMessage({ id: 'lease.addLease.rentalPrice' })}
          name="rentalPrice"
          type="number"
          style={{ width: 580 }}
          min={0}
          endAdornment={<InputAdornment position="end">EUR</InputAdornment>}
          typeNumberNoEndArrow
          noMaxWidth
          error={Boolean(errors.rentalPrice && touched.rentalPrice)}
        />

        {shouldDisplayFurnishedRentPercentage && (
          <TextDetailEditable
            editMode
            title={formatMessage({ id: 'lease.addLease.furnishedRentalRentalPricePercentage' })}
            name="furnishedRentalRentalPricePercentage"
            type="number"
            style={{ width: 580 }}
            min={0}
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
            typeNumberNoEndArrow
            noMaxWidth
            error={Boolean(errors.furnishedRentalRentalPricePercentage && touched.furnishedRentalRentalPricePercentage)}
          />
        )}
        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      </>
      <div
        style={{
          marginLeft: 30,
          marginRight: 30,
          marginBottom: 5,
          marginTop: 5,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
            {formatMessage({ id: 'lease.detail.general.monthlyCharges' })}
          </Typography>
          <CustomizedSwitch
            checked={values.includeMonthlyCharge}
            onChange={() => {
              setFieldValue('includeMonthlyCharge', !values.includeMonthlyCharge);
            }}
            aria-label="Collapse"
            color="primary"
            disableRipple
            switchOnText={formatMessage({ id: 'lease.addLease.includeInLease' })}
            switchOffText={formatMessage({ id: 'lease.addLease.ignore' })}
          />
        </div>
        <Collapse in={values.includeMonthlyCharge}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <CustomizedSelect
              label={formatMessage({
                id: 'lease.addLease.monthlyChargesType',
              })}
              fieldName="monthlyChargesType"
              value={values.monthlyChargesType}
              FormControlProps={{ required: true, style: { width: 550 } }}
              error={Boolean(errors.monthlyChargesType && touched.monthlyChargesType)}
              SelectProps={{
                onChange: handleChange,
                onBlur: handleBlur,
              }}
            >
              {getTranslatedEnumFormatMessage(formatMessage, 'LeaseMonthlyChargesType').map((mi) => (
                <MenuItem value={mi.value ?? mi.label ?? ''} key={mi.value}>
                  {mi.label}
                </MenuItem>
              ))}
            </CustomizedSelect>
            <Tooltip title={formatMessage({ id: `lease.addLease.monthlyChargesTypeHelper` })} placement="top">
              <Grid>
                <InfoSvg style={{ fill: Colors.SILVER }} />
              </Grid>
            </Tooltip>
          </div>
          <div style={{ display: 'flex' }}>
            <TextDetailEditable
              title={`${formatMessage({
                id: 'lease.addLease.monthlyCharges',
              })} ${formatMessage({
                id: 'optional',
              })}`}
              editMode={true}
              name="monthlyCharges"
              type="number"
              style={{ width: 280 }}
              endAdornment="EUR"
              error={Boolean(errors.monthlyCharges && touched.monthlyCharges)}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 10,
              marginLeft: 10,
              marginRight: 10,
            }}
            data-test="chargeAdjustment"
          >
            <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
              {formatMessage({ id: 'accounting.chargeAdjustments.allowChargeAdjustment' })}
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <CustomizedSwitch
                checked={values.allowChargeAdjustment}
                onChange={handleChange}
                onBlur={handleBlur}
                aria-label="Collapse"
                color="primary"
                disableRipple
                id="allowChargeAdjustment"
                name="allowChargeAdjustment"
              />
              <HelpIconPopover
                helperText={formatMessage({ id: 'accounting.chargeAdjustments.allowChargeAdjustmentHelper' })}
                paperStyle={{ maxWidth: 400 }}
              />
            </div>
          </div>
        </Collapse>
      </div>
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      <div
        style={{
          marginLeft: 30,
          marginRight: 30,
          marginBottom: 5,
          marginTop: 5,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          data-test="indexation"
        >
          <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
            {formatMessage({ id: 'lease.addLease.rentIndexation' })}
          </Typography>
          <CustomizedSwitch
            checked={values.indexation}
            onChange={handleChange}
            onBlur={handleBlur}
            aria-label="Collapse"
            color="primary"
            disableRipple
            id="indexation"
            name="indexation"
          />
        </div>
      </div>
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      <div
        style={{
          marginLeft: 30,
          marginRight: 30,
          marginBottom: 5,
          marginTop: 5,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
            {formatMessage({ id: 'accounting.vat.applyVatOnLease' })}
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <CustomizedSwitch
              checked={values.applyVat}
              onChange={() => {
                setFieldValue('applyVat', !values.applyVat);
              }}
              aria-label="Collapse"
              color="primary"
              disableRipple
            />
            <HelpIconPopover
              helperText={formatMessage({ id: 'accounting.vat.vatHelper' })}
              paperStyle={{ width: 350 }}
            />
          </div>
        </div>
        <Collapse in={values.applyVat}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
              {formatMessage({ id: 'accounting.vat.vatOnRents' })}
            </Typography>
            <SkeletonComponent loading={vatRatesLoading}>
              <CustomizedSelect
                label={formatMessage({ id: 'accounting.charge.affectCharge.vatRate' })}
                fieldName="vatRateRent"
                value={values.vatRateRent}
                FormControlProps={{ required: true, style: { width: 280 } }}
                error={Boolean(errors.vatRateRent && touched.vatRateRent)}
                SelectProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
              >
                {vatRatesToPropose.map((mi) => (
                  <MenuItem value={mi.value ?? mi.label ?? ''} key={mi.value}>
                    {mi.label}
                  </MenuItem>
                ))}
              </CustomizedSelect>
            </SkeletonComponent>
          </div>
          {values.monthlyChargesType === LeaseMonthlyChargesType.FixedPrice && (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                {formatMessage({ id: 'accounting.vat.vatOnCharges' })}
              </Typography>
              <SkeletonComponent loading={vatRatesLoading}>
                <CustomizedSelect
                  label={formatMessage({ id: 'accounting.charge.affectCharge.vatRate' })}
                  fieldName="vatRateCharge"
                  value={values.vatRateCharge}
                  FormControlProps={{ required: true, style: { width: 280 } }}
                  error={Boolean(errors.vatRateCharge && touched.vatRateCharge)}
                  SelectProps={{
                    onChange: handleChange,
                    onBlur: handleBlur,
                  }}
                >
                  {vatRatesToPropose.map((mi) => (
                    <MenuItem value={mi.value ?? mi.label ?? ''} key={mi.value}>
                      {mi.label}
                    </MenuItem>
                  ))}
                </CustomizedSelect>
              </SkeletonComponent>
            </div>
          )}
        </Collapse>
      </div>
    </>
  );
};

export default LeaseRentFields;
