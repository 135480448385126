import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import NotificationBox from './NotificationBox';
import SlicedContent from './SlicedContent';
import { ReactComponent as ExternalIcon } from '../../../icons/external.svg';
import { Link } from 'react-router-dom';
import {
  Colors,
  getContactNameFromObject,
  getStatementTotalAmount,
  ModelWithVersion,
  OWNER_STATEMENTS_TO_VALIDATE_KEY,
  Statement,
  StatementStatus,
} from '@rentguru/commons-utils';
import { useStatements } from 'src/hooks/StatementContext';
import { ConfirmDialog, MenuItemText } from '@up2rent/ui';
import { extendOwnerStatement } from 'src/components/Accounting/OwnerStatements/OwnerStatements';
import { ActionMenuChargeInbox } from 'src/components/Accounting/OwnerStatements/OwnerStatementsToValidate/OwnerStatementsToValidate';
import OwnerStatementsToValidateRow from 'src/components/Accounting/OwnerStatements/OwnerStatementsToValidate/OwnerStatementToValidateRow';
import ConfirmValidateOwnerStatementDialog from 'src/components/Accounting/OwnerStatements/OwnerStatementsToValidate/ValidateOwnerStatement/ConfirmValidateOwnerStatementDialog';
import OwnerStatementsHeader from './BoxCustomHeaders/OwnerStatementsHeader';
import { useUnits } from 'src/hooks/UnitsContext';
import { statementSortFunction } from './DashboardTenantStatements';
import TodosLoading from './TodosLoading';
import { stopPropagation } from 'src/components/Dashboard/Dashboard';

export const ROW_HEIGHT = 74;

const DashboardOwnerStatements: React.FC = () => {
  const { formatMessage } = useIntl();
  const { ownerStatementsToCheck, statementsLoading, updateStatement, deleteStatement, recalculateStatement } =
    useStatements(true);
  const { getUnit, unitsLoading } = useUnits();
  const [dialogDeleteOpen, setDialogDeleteOpen] = useState<string>('');
  const [validateDialogOpen, setValidateDialogOpen] = useState<string>('');
  const [actionMenu, setActionMenu] = useState<ActionMenuChargeInbox | null>(null);

  const handleActionMenu = async (
    anchorEl: React.MouseEvent<HTMLElement>,
    id: string,
    eventType: 'MORE' | 'RECALCULATE' | 'VALIDATE',
    afterSubmit?: () => void
  ) => {
    if (eventType === 'MORE') {
      setActionMenu({ anchorEl: anchorEl.currentTarget, id });
    } else if (eventType === 'VALIDATE') {
      setValidateDialogOpen(id);
    } else {
      await recalculateStatement(id);
      if (afterSubmit) afterSubmit();
    }
  };

  if (statementsLoading || unitsLoading) {
    return (
      <>
        <TodosLoading />
      </>
    );
  }

  const ownerStatementsToCheckData = ownerStatementsToCheck.map((statement) => {
    const extendedStatement = extendOwnerStatement(statement, getUnit);
    const numberOfTypes = extendedStatement.types ? extendedStatement.types.length : 0;
    const numberOfUnits = extendedStatement.units.length;
    const totalAmount = getStatementTotalAmount(statement);
    const ownerName = extendedStatement.owner ? getContactNameFromObject(statement.owner) : '';
    const periodSortLabel = `${extendedStatement.periodFrom}${extendedStatement.periodTo}`;
    return {
      ...extendedStatement,
      amounts: statement.amounts ?? [],
      ownerName,
      numberOfTypes,
      numberOfUnits,
      totalAmount,
      periodSortLabel,
    };
  });

  const statementToValidate =
    validateDialogOpen !== '' ? ownerStatementsToCheck.find((st) => st.id === validateDialogOpen) : null;

  const validateStatement = async () => {
    if (statementToValidate) {
      await updateStatement({
        id: statementToValidate.id,
        sentDate: new Date().toISOString(),
        status: StatementStatus.DONE,
        _version: (statementToValidate as ModelWithVersion<Statement>)._version,
      });
      setValidateDialogOpen('');
    }
  };

  const statementToDisplay = ownerStatementsToCheckData.sort(statementSortFunction).map((statement) => {
    return (
      <OwnerStatementsToValidateRow
        key={statement.id}
        {...statement}
        isSelected={false}
        actionMenu={handleActionMenu}
      />
    );
  });

  const titleComponent = (
    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
      <Typography
        style={{
          flex: 1,
          textAlign: 'left',
          color: Colors.CLASSICAL_BLACK,
          fontFamily: 'Mulish',
          fontSize: '16px',
          fontWeight: 'bold',
          marginLeft: 20,
        }}
      >
        {`${formatMessage({ id: 'dashboard.ownerStatements' })} (${statementToDisplay.length})`}
      </Typography>
      <IconButton
        size="small"
        onMouseDown={stopPropagation}
        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => e.stopPropagation()}
        component={Link}
        to="/accounting/ownerStatements"
        style={{ marginLeft: 10 }}
      >
        <ExternalIcon style={{ fill: Colors.DODGER_BLUE }} />
      </IconButton>
    </div>
  );

  return (
    <>
      <NotificationBox boxKey={OWNER_STATEMENTS_TO_VALIDATE_KEY} titleComponent={titleComponent}>
        <SlicedContent
          data={statementToDisplay}
          header={<OwnerStatementsHeader />}
          limit={3}
          rowHeight={ROW_HEIGHT}
          boxKey={OWNER_STATEMENTS_TO_VALIDATE_KEY}
        />
      </NotificationBox>
      {actionMenu && (
        <Menu
          onMouseDown={stopPropagation}
          id="action-menu"
          anchorEl={actionMenu.anchorEl}
          open={Boolean(actionMenu.anchorEl)}
          onClose={() => setActionMenu(null)}
        >
          <MenuItem
            onClick={() => {
              setDialogDeleteOpen(actionMenu.id);
              setActionMenu(null);
            }}
          >
            <MenuItemText primary={formatMessage({ id: 'delete' })} deleteOption />
          </MenuItem>
        </Menu>
      )}
      <ConfirmDialog
        open={dialogDeleteOpen !== ''}
        confirmText={formatMessage({ id: 'accounting.statement.confirmDelete' })}
        confirmAction={async () => {
          const statementToDelete = ownerStatementsToCheck.find((st) => st.id === dialogDeleteOpen);
          if (statementToDelete) await deleteStatement(statementToDelete);
          setDialogDeleteOpen('');
        }}
        cancelAction={() => {
          setDialogDeleteOpen('');
        }}
        mainText={formatMessage(
          { id: 'accounting.statement.toValidateSection.deleteWarning' },
          { statement: formatMessage({ id: 'accounting.statement.ownerStatements' }, { value: 1 }).toLowerCase() }
        )}
        formatMessage={formatMessage}
      />
      {validateDialogOpen !== '' && statementToValidate && (
        <ConfirmValidateOwnerStatementDialog
          open={validateDialogOpen !== ''}
          onClose={() => setValidateDialogOpen('')}
          validateStatement={validateStatement}
          statement={statementToValidate}
        />
      )}
    </>
  );
};

export default DashboardOwnerStatements;
