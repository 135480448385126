import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import { Avatar, Typography } from '@material-ui/core';
import {
  Contact,
  UnitType,
  Colors,
  getContactNameFromObject,
  getFirstLettersUppercase,
  getNumberOfUnitsForOwner,
  stringToColor,
} from '@rentguru/commons-utils';
import { EnhanceTableRowProps, SmallHeightCustomTableCell } from '@up2rent/ui';
import { useBuildings } from 'src/hooks/BuildingsContext';
import UnitNumbersPicto from 'src/components/ui/UnitNumbersPicto';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useUnits } from 'src/hooks/UnitsContext';
import SkeletonComponent from 'src/components/ui/SkeletonComponent';
import { RouteDestination } from 'src/components/Routes/Routes';

interface AgencyRateIncompleteOwnersRowProps extends EnhanceTableRowProps {
  owner: Contact;
  missingTypes: UnitType[];
  handleDetail?: (id: string) => void;
  align?: 'left' | 'center' | 'right';
}

const AgencyRateIncompleteOwnersRow: React.FC<AgencyRateIncompleteOwnersRowProps> = ({
  isSelected,
  id,
  owner,
  missingTypes,
  handleDetail = () => {},
  align = 'left',
}) => {
  const { buildings, buildingsLoading } = useBuildings();
  const { units, unitsLoading } = useUnits();

  const { formatMessage } = useIntl();
  const history = useHistory();

  const name = getContactNameFromObject(owner);
  const color = stringToColor(name);

  return (
    <TableRow
      hover
      aria-checked={isSelected}
      tabIndex={-1}
      key={id}
      selected={isSelected}
      onClick={() => {
        handleDetail(id);
      }}
    >
      <SmallHeightCustomTableCell component="th" scope="row" align={align}>
        <div
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            history.push({
              pathname: `${RouteDestination.CONTACTS}/${owner.id}`,
              state: { goBackUrl: history.location },
            });
          }}
        >
          <Avatar
            style={{
              color: `${color}`,
              width: 30,
              height: 30,
              backgroundColor: Colors.PORCELAIN_GREY_3,
              fontSize: 11,
              fontWeight: 800,
              fontStyle: 'normal',
              letterSpacing: 0,
              textAlign: 'center',
              marginRight: 10,
            }}
          >
            {getFirstLettersUppercase(name)}
          </Avatar>
          <Typography
            style={{
              fontSize: 14,
              fontWeight: 600,
              fontStyle: 'normal',
              letterSpacing: 0,
              color: Colors.CLASSICAL_BLACK,
            }}
          >
            {name}
          </Typography>
        </div>
      </SmallHeightCustomTableCell>
      <SmallHeightCustomTableCell component="th" scope="row" align={align}>
        <SkeletonComponent loading={unitsLoading || buildingsLoading}>
          <UnitNumbersPicto value={getNumberOfUnitsForOwner(owner, buildings, units)} />
        </SkeletonComponent>
      </SmallHeightCustomTableCell>
      <SmallHeightCustomTableCell component="th" scope="row" align={align}>
        {missingTypes.length === Object.keys(UnitType).length
          ? formatMessage({ id: 'agencyRates.detail.unitTypeAll' })
          : missingTypes.map((type) => formatMessage({ id: `enums.UnitType.${type}` })).join(', ')}
      </SmallHeightCustomTableCell>
    </TableRow>
  );
};

export default AgencyRateIncompleteOwnersRow;
