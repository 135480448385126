/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  getDateOfNextInvoice,
  getNumberOfMonthsFromFrequency,
  LeasePaymentInvoicePeriod,
  LeasePaymentFrequency,
  LeaseVariousOperationType,
  Lease,
  VariousOperationType,
  LeaseVariousOperation,
} from '@rentguru/commons-utils';
import { addMonths, differenceInCalendarMonths, isAfter, isBefore, startOfMonth } from 'date-fns';
import { get, isNil } from 'lodash';
import { AddLeaseFormValues } from 'src/components/Leases/AddLease/AddLeaseForm';
import {
  isLeaseAlreadyStarted,
  resolveMainUnitAndSubUnitIndexAndPrefix,
} from 'src/components/Leases/AddLease/useAddEditLeaseUtils';
import { OperationFormValue } from 'src/components/ui/Forms/FormField/AddOperation';

const handleChangeStartDate = (
  newDate: Date,
  values: OperationFormValue,
  setFieldValue: (field: string, value: any) => void
) => {
  const oneTimeDuration = 1;
  // eslint-disable-next-line default-case
  switch (values.type) {
    case VariousOperationType.ONE_TIME:
      setFieldValue(`endDate`, startOfMonth(addMonths(newDate, oneTimeDuration)));
      break;
    case 'DURATION':
    case VariousOperationType.RECURRING:
    case VariousOperationType.END_WITH_LEASE:
      const endDate = new Date(values.endDate!);
      const startDate = new Date(newDate!);
      const recurringDuration = Math.abs(differenceInCalendarMonths(endDate, startDate));
      // If startDate after endDate
      if (isAfter(startDate, endDate)) {
        // Check difference and if it can be reported
        let endDateFromStartDateDuration = startOfMonth(addMonths(startDate, recurringDuration));
        if (values.maxEndDate && isAfter(endDateFromStartDateDuration, new Date(values.maxEndDate!))) {
          // End date is too late, set to same month as new start
          endDateFromStartDateDuration = startOfMonth(newDate);
        }
        setFieldValue(`endDate`, endDateFromStartDateDuration);
      }
  }
  setFieldValue(`startDate`, newDate);
};

const handleChangeEndDate = (
  newDate: Date,
  values: OperationFormValue,
  setFieldValue: (field: string, value: any) => void
) => {
  if (values.type === VariousOperationType.ONE_TIME) {
    return;
  }

  const startDate = new Date(values.startDate!);
  const recurringDuration = differenceInCalendarMonths(newDate, startDate);

  const endDate = recurringDuration >= 1 ? newDate : startDate;
  const duration = recurringDuration >= 1 ? recurringDuration : 1;
  setFieldValue(`duration`, duration);
  setFieldValue(`endDate`, endDate);
};

export const handleChangeDate = (
  newValue: Date | number,
  values: OperationFormValue,
  setFieldValue: (field: string, value: any) => void,
  updatedField: 'startDate' | 'duration' | 'endDate'
) => {
  // eslint-disable-next-line default-case
  switch (updatedField) {
    case 'startDate':
      handleChangeStartDate(new Date(newValue), values, setFieldValue);
      break;
    case 'duration':
      handleChangeDuration(
        newValue as number,
        values.startDate!,
        values.maxEndDate!,
        (values.paymentFrequency as LeasePaymentFrequency) ?? LeasePaymentFrequency.MONTHLY,
        setFieldValue
      );
      break;
    case 'endDate':
      handleChangeEndDate(new Date(newValue), values, setFieldValue);
      break;
  }
};

const handleChangeDuration = (
  newValue: number,
  startDate: string | Date,
  maxEndDate: string | Date,
  paymentFrequency: LeasePaymentFrequency,
  setFieldValue: (field: string, value: any) => void
) => {
  const newDurationRaw = newValue;
  if (!isNaN(newDurationRaw) && newDurationRaw !== 0) {
    const amountOfMonthsFrequency = getNumberOfMonthsFromFrequency(paymentFrequency);
    const newDuration = Math.floor(newDurationRaw * amountOfMonthsFrequency);
    const maxDurationDifference =
      differenceInCalendarMonths(
        startOfMonth(new Date(maxEndDate!)),
        startOfMonth(new Date(startDate!))
        // First month is being paid too.
      ) + 1;

    // First month is also counting and will be invoiced so we add the duration -1 month
    const newClampedDuration = newDuration - 1 > maxDurationDifference ? maxDurationDifference : newDuration - 1;
    const newEndDate = addMonths(new Date(startDate!), newClampedDuration);
    setFieldValue(`endDate`, startOfMonth(new Date(newEndDate)));
  }
};

export const handleChangeRepeat = (
  operationType: VariousOperationType,
  predefinedNumberOfMonths: number | undefined,
  currentPaymentFrequency: LeasePaymentFrequency,
  startDate: string | Date,
  maxEndDate: string | Date,
  setFieldValue: (field: string, value: any) => void
) => {
  if (operationType === VariousOperationType.END_WITH_LEASE) {
    setFieldValue('endDate', startOfMonth(new Date(maxEndDate!)));
  } else if (operationType === VariousOperationType.ONE_TIME) {
    setFieldValue('endDate', startOfMonth(new Date(startDate!)));
    setFieldValue('paymentFrequency', LeasePaymentFrequency.MONTHLY);
  } else {
    handleChangeDuration(predefinedNumberOfMonths ?? 1, startDate, maxEndDate, currentPaymentFrequency, setFieldValue);
  }

  setFieldValue('type', operationType);
};

const getMaxEndDate = (values: AddLeaseFormValues, unitId?: string, lease?: Lease) => {
  if (!isNil(lease)) {
    return new Date(lease!.endDate);
  }

  const { prefixFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(values, unitId);
  const startDate = new Date(get(values, `${prefixFieldName}startDate`));
  const endDate = new Date(get(values, `${prefixFieldName}endDate`));
  const amountOfMonthsToAddToGetEndDate = differenceInCalendarMonths(endDate, startDate);
  return addMonths(startDate, amountOfMonthsToAddToGetEndDate);
};

const getMinStartDate = (values: AddLeaseFormValues, unitId?: string, lease?: Lease) => {
  if (!isNil(lease)) {
    return new Date(lease.startDate);
  }

  const { prefixFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(values, unitId);

  const startDate = get(values, `${prefixFieldName}startDate`);
  if (isLeaseAlreadyStarted(new Date(startDate))) {
    return new Date();
  }
  return new Date(startDate);
};

export const getInitialVatRate = (showVat: boolean, oldValue?: number | string | null) => {
  if (!showVat) {
    return undefined;
  }
  if (oldValue) {
    return oldValue;
  }

  return 21;
};

const getInitialStartDate = (
  initialLeaseVariousOperation: LeaseVariousOperation | undefined,
  updateOperationStartDate: string | Date | null | undefined,
  minStartDate: Date
) => {
  if (initialLeaseVariousOperation) {
    return new Date(initialLeaseVariousOperation.startDate);
  }
  if (updateOperationStartDate) {
    return new Date(updateOperationStartDate);
  }

  // If date is before today, set today as start date
  const today = startOfMonth(new Date());
  const startMonthMinStartDate = startOfMonth(new Date(minStartDate));

  if (isBefore(startMonthMinStartDate, today)) {
    // Default start is next month
    return addMonths(today, 1);
  }

  return startMonthMinStartDate;
};

const getInitialEndDate = (
  initialLeaseVariousOperation: LeaseVariousOperation | undefined,
  updateOperationEndDate: string | Date | null | undefined,
  maxEndDate: Date
) => {
  if (initialLeaseVariousOperation) {
    return new Date(initialLeaseVariousOperation.endDate);
  }
  if (updateOperationEndDate) {
    return new Date(updateOperationEndDate);
  }
  return startOfMonth(new Date(maxEndDate));
};

export const getLeaseVariousOperationFormInitialValues = (
  parentFormValues: AddLeaseFormValues,
  unitId: string,
  lease: Lease | undefined,
  operationType: LeaseVariousOperationType,
  updatingLeaseVariousOperationForAddLease:
    | {
        operation: OperationFormValue;
        index: number;
      }
    | null
    | undefined,
  initialLeaseVariousOperation: LeaseVariousOperation | undefined
) => {
  // Initial values
  const initialAmount =
    updatingLeaseVariousOperationForAddLease?.operation?.amount ?? initialLeaseVariousOperation?.amount ?? '';

  const minStartDate = getMinStartDate(parentFormValues, unitId, lease);
  const startDate = getInitialStartDate(
    initialLeaseVariousOperation,
    updatingLeaseVariousOperationForAddLease?.operation.startDate,
    minStartDate
  );
  const maxEndDate = getMaxEndDate(parentFormValues, unitId, lease);
  let endDate = getInitialEndDate(
    initialLeaseVariousOperation,
    updatingLeaseVariousOperationForAddLease?.operation.endDate,
    maxEndDate
  );

  const initialLabel =
    updatingLeaseVariousOperationForAddLease?.operation?.label ?? initialLeaseVariousOperation?.label ?? '';

  const initialReason =
    updatingLeaseVariousOperationForAddLease?.operation?.reason ?? initialLeaseVariousOperation?.reason ?? '';

  const initialSeparateInvoice =
    updatingLeaseVariousOperationForAddLease?.operation?.separateInvoice ??
    initialLeaseVariousOperation?.separateInvoice ??
    false;

  const initialPaymentFrequency =
    updatingLeaseVariousOperationForAddLease?.operation?.paymentFrequency ??
    initialLeaseVariousOperation?.paymentFrequency ??
    LeasePaymentFrequency.MONTHLY;

  const initialType = getInitialType(
    initialPaymentFrequency as LeasePaymentFrequency,
    updatingLeaseVariousOperationForAddLease,
    initialLeaseVariousOperation
  );
  // Change endDate if it's one time
  if (initialType === VariousOperationType.ONE_TIME) {
    endDate = startDate;
  }

  const initialPaymentInvoicePeriod =
    updatingLeaseVariousOperationForAddLease?.operation?.paymentInvoicePeriod ??
    initialLeaseVariousOperation?.paymentInvoicePeriod ??
    LeasePaymentInvoicePeriod.CALENDAR_PERIOD;

  const initialPaymentInvoicePeriodCustomDay =
    updatingLeaseVariousOperationForAddLease?.operation?.paymentInvoicePeriodCustomDay ??
    initialLeaseVariousOperation?.paymentInvoicePeriodCustomDay ??
    null;

  const showVat = parentFormValues.applyVat ?? (lease?.vatRateCharge || lease?.vatRateRent);
  const initialVatRate = getInitialVatRate(
    showVat,
    updatingLeaseVariousOperationForAddLease?.operation.vatRate ?? initialLeaseVariousOperation?.vatRate
  );

  const nextInvoiceDate = !isNil(initialLeaseVariousOperation)
    ? getDateOfNextInvoice(
        new Date(initialLeaseVariousOperation.startDate),
        initialLeaseVariousOperation.lastInvoiceDate,
        initialLeaseVariousOperation.paymentFrequency,
        initialLeaseVariousOperation.paymentInvoicePeriod,
        initialLeaseVariousOperation.paymentInvoicePeriodCustomDay
      )
    : null;

  const initialValues: OperationFormValue = {
    amount: initialAmount,
    minStartDate,
    startDate,
    maxEndDate,
    endDate,
    changeDate: nextInvoiceDate,
    label: initialLabel,
    operationType,
    reason: initialReason,
    separateInvoice: initialSeparateInvoice,
    vatRate: initialVatRate,
    unitId,
    type: initialType,
    paymentFrequency: initialPaymentFrequency,
    paymentInvoicePeriod: initialPaymentInvoicePeriod,
    paymentInvoicePeriodCustomDay: initialPaymentInvoicePeriodCustomDay,
  };

  return initialValues;
};

const getInitialType = (
  initialPaymentFrequency: LeasePaymentFrequency,
  updatingLeaseVariousOperationForAddLease:
    | {
        operation: OperationFormValue;
        index: number;
      }
    | null
    | undefined,
  initialLeaseVariousOperation: LeaseVariousOperation | undefined
) => {
  if (initialPaymentFrequency !== LeasePaymentFrequency.MONTHLY) {
    return 'DURATION';
  }

  return (
    updatingLeaseVariousOperationForAddLease?.operation?.type ??
    initialLeaseVariousOperation?.type ??
    VariousOperationType.ONE_TIME
  );
};
