/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-shadow */
import { Divider, makeStyles, TextField } from '@material-ui/core';
import Dialog from 'src/components/ui/Dialog';
import { Add } from '@material-ui/icons';
import {
  Colors,
  DEFECTS,
  InventoryCustomItem,
  InventoryItemType,
  LeaseInventoryCheckedItem,
  LeaseInventoryEncoding,
} from '@rentguru/commons-utils';
import { Form, Formik } from 'formik';
import isNil from 'lodash/isNil';
import toUpper from 'lodash/toUpper';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ActionButton , ContentHeader, LoaderButton } from '@up2rent/ui';
import PlusMinusButtonGroup from '../../../ui/PlusMinusButtonGroup';
import { classifyInventoryCheckedItems } from '../../../../hooks/LeaseInventoryContext';
import { ExtendedLeaseInventoryEncoding } from '../../../../hooks/UnitInventoryContext';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles({
  addButton: {
    background: Colors.CLASSICAL_WHITE,
    color: Colors.DARK_SLATE_GREY,
    '&:hover': {
      background: Colors.PORCELAIN_GREY_2,
    },
  },
});

interface AddDefectsDialogProps {
  open: boolean;
  encoding?: ExtendedLeaseInventoryEncoding;
  updateEncoding?: (
    updateFunction: (encoding: ExtendedLeaseInventoryEncoding) => ExtendedLeaseInventoryEncoding
  ) => void;
  customItems: InventoryCustomItem[];
  updateCustomItems: (updateFunction: (item: InventoryCustomItem[]) => InventoryCustomItem[]) => void;
  onClose: () => void;
}
interface FormikDefects {
  defects: LeaseInventoryCheckedItem[];
}

const AddDefectsDialog: React.FC<AddDefectsDialogProps> = ({
  open,
  encoding,
  customItems,
  updateEncoding,
  updateCustomItems,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const [customDefect, setCustomDefect] = useState<string | null>(null);
  const [defects, ,] =
    !isNil(encoding) && !isNil(encoding.checkedItems)
      ? classifyInventoryCheckedItems(encoding.checkedItems)
      : [[], [], []];
  const customSection = !isNil(customDefect);
  const customDefects = customItems.filter((ci) => ci.type === InventoryItemType.DEFECT);
  // Empty defects by default
  const initialValues: LeaseInventoryCheckedItem[] = DEFECTS.map((DEFECT) => {
    return {
      id: `NEW-${uuidv4()}`,
      totalDefects: 0,
      value: DEFECT,
      custom: false,
      leaseInventoryEncodingId: !isNil(encoding) ? encoding.id : '',
      readId: '',
      type: InventoryItemType.DEFECT,
      clientId: '',
    };
  });
  customDefects.forEach((customDefect) => {
    initialValues.push({
      id: `NEW-${uuidv4()}`,
      totalDefects: 0,
      custom: true,
      leaseInventoryEncodingId: !isNil(encoding) ? encoding.id : '',
      customItem: customDefect,
      readId: '',
      type: InventoryItemType.DEFECT,
      clientId: '',
    });
  });
  // Add the custom ones and replaces the ones by default if real values exist
  defects.forEach((defect) => {
    let initialValueIndex: number;
    if (defect.custom && !isNil(defect.customItem)) {
      initialValueIndex = initialValues.findIndex(
        (initVal) => !isNil(initVal.customItem) && initVal.customItem.id === defect.customItem!.id
      );
    } else {
      initialValueIndex = initialValues.findIndex((initVal) => initVal.value === defect.value);
    }
    if (initialValueIndex > -1) {
      initialValues[initialValueIndex] = defect;
    }
  });

  const handleSubmit = (values: FormikDefects) => {
    const newDefects = values.defects.filter((defect) => !isNil(defect.totalDefects) && defect.totalDefects > 0);
    if (!isNil(updateEncoding)) {
      updateEncoding((encoding: LeaseInventoryEncoding) => {
        const previousCheckedItems = encoding.checkedItems!;
        return {
          ...encoding,
          checkedItems: [...previousCheckedItems.filter((pci) => pci.type !== InventoryItemType.DEFECT), ...newDefects],
        };
      });
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      scroll="body"
      PaperProps={{ style: { maxWidth: 'none', width: 400, borderRadius: 10 } }}
    >
      <Formik
        initialValues={{ defects: initialValues } as FormikDefects}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values, setFieldValue, status, isSubmitting }) => {
          return (
            <Form>
              <div
                style={{
                  display: 'flex',
                  justifyContent: customSection ? 'flex-start' : 'space-between',
                  alignItems: 'center',
                }}
              >
                <ContentHeader
                  title={
                    customSection
                      ? formatMessage({ id: 'leaseInventory.addDefect.title' })
                      : formatMessage({ id: 'leaseInventory.room.structureDetails.defects' }, { value: 2 })
                  }
                  handleBack={customSection ? () => setCustomDefect(null) : undefined}
                />
                {!customSection && (
                  <ActionButton
                    onClick={() => setCustomDefect('')}
                    style={{
                      marginRight: 24,
                    }}
                    className={classes.addButton}
                  >
                    <Add />
                    {toUpper(formatMessage({ id: 'leaseInventory.addDefect.customDefect' }))}
                  </ActionButton>
                )}
              </div>
              <Divider />
              {!customSection && (
                <>
                  {values.defects.map((defect, index) => {
                    const updateDefect = (value: number, name?: string) => {
                      const newDefects = values.defects.map((defect) => {
                        if (defect.id === name) {
                          return { ...defect, totalDefects: value };
                        }
                        return defect;
                      });
                      setFieldValue('defects', newDefects);
                    };
                    return (
                      <div style={{ marginTop: 5, marginBottom: 5 }} key={`${defect.id} ${index}`}>
                        <PlusMinusButtonGroup
                          text={
                            defect.custom
                              ? defect.customItem!.value
                              : formatMessage({ id: `enums.leaseInventoryDefects.${defect.value}` })
                          }
                          name={defect.id}
                          initialValue={defect.totalDefects!}
                          onChange={updateDefect}
                        />
                        {index !== values.defects.length - 1 && <Divider />}
                      </div>
                    );
                  })}
                </>
              )}
              {customSection && (
                <div style={{ marginTop: 40, marginBottom: 40, marginLeft: 10, marginRight: 10 }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={customDefect}
                    onChange={(event) => setCustomDefect(event.target.value)}
                    label={formatMessage({ id: 'leaseInventory.addDefect.defectName' })}
                  />
                </div>
              )}
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <div
                style={{
                  marginRight: 30,
                  marginBottom: 20,
                  display: 'flex',
                  float: 'right',
                  alignItems: 'center',
                }}
              >
                <ActionButton
                  onClick={() => {
                    if (!isNil(onClose)) {
                      onClose();
                    }
                  }}
                  style={{
                    background: 'none',
                    color: Colors.DARK_SLATE_GREY,
                    marginRight: 20,
                  }}
                >
                  {toUpper(
                    formatMessage({
                      id: 'bankAccount.addBankAccount.cancelLabel',
                    })
                  )}
                </ActionButton>
                {customSection ? (
                  <ActionButton
                    style={{ minWidth: 120, height: 50, fontSize: 16, fontWeight: 800 }}
                    onClick={() => {
                      if (!isNil(customDefect)) {
                        const newCustomDefect = {
                          id: `NEW-${uuidv4()}`,
                          value: customDefect,
                          type: InventoryItemType.DEFECT,
                          clientId: '',
                          readId: '',
                        };
                        const newCheckedDefect = {
                          id: `NEW-${uuidv4()}`,
                          custom: true,
                          leaseInventoryEncodingId: !isNil(encoding) ? encoding.id : '',
                          customItem: newCustomDefect,
                          readId: '',
                          type: InventoryItemType.DEFECT,
                          totalDefects: 0,
                          clientId: '',
                        };
                        updateCustomItems((customItems) => {
                          return [...customItems, newCustomDefect];
                        });
                        setFieldValue('defects', [...values.defects, newCheckedDefect]);
                      }
                      setCustomDefect(null);
                    }}
                  >
                    {formatMessage({ id: 'add' })}
                  </ActionButton>
                ) : (
                  <LoaderButton loading={isSubmitting} success={status}>
                    {formatMessage({
                      id: 'bankAccount.addBankAccount.submitLabel',
                    })}
                  </LoaderButton>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default AddDefectsDialog;
