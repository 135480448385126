/* eslint-disable react/no-array-index-key */
/* eslint-disable no-promise-executor-return */
import { Divider, Grid, Typography, makeStyles } from '@material-ui/core';
import {
  BlockedEmail,
  Colors,
  CommunicationChannel,
  CommunicationRecipientType,
  CommunicationStatus,
  Contact,
  ContactType,
  EntityType,
  FileCategory,
  File as FileModel,
  NewFile,
  S3Object,
  TemplateSubType,
  getContactNameFromObject,
} from '@rentguru/commons-utils';
import { EditorState } from 'draft-js';
import { isEmpty, isEqual, isNil } from 'lodash';
import React, { useEffect } from 'react';
import { IntlFormatters, useIntl } from 'react-intl';
import { CustomMenuItemType } from 'src/components/ui/ComboBox/TextComboBox';
import TemplateEditor from 'src/components/ui/TemplateEditor';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { getS3ObjectUrls, useFiles } from 'src/hooks/FilesContext';
import { useInvoices } from 'src/hooks/InvoicesContext';
import { useLeases } from 'src/hooks/LeasesContext';
import { useSignatureDocuments } from 'src/hooks/SignatureDocumentContext';
import { CommunicationWithTemplate, isLetterCommunication } from 'src/utils/communicationUtils';
import CommunicationDetailsContentAddress from './CommunicationDetailsContentAddress';
import CommunicationDetailsContentHeader from './CommunicationDetailsContentHeader';
import CommunicationDetailsContentRecipient from './CommunicationDetailsContentRecipient';
import CommunicationDetailsFiles from './CommunicationDetailsFiles';

const useStyles = makeStyles({
  divider: {
    marginLeft: -30,
    marginRight: -20,
  },
  recipientsGrid: {
    padding: '0px 24px 12px 24px',
  },
  textWarning: {
    fontSize: 14,
    color: Colors.BURNING_ORANGE,
  },
});
interface CommunicationDetailContentProps {
  communication: CommunicationWithTemplate;
  recipients: (CustomMenuItemType | string)[];
  setRecipients: (recipient: (CustomMenuItemType | string)[]) => void;
  recipientsInCc: (CustomMenuItemType | string)[];
  setRecipientsInCc: (recipient: (CustomMenuItemType | string)[]) => void;
  recipientsInBcc: (CustomMenuItemType | string)[];
  setRecipientsInBcc: (recipient: (CustomMenuItemType | string)[]) => void;
  files: (S3Object | NewFile)[];
  setFiles: (value: (S3Object | NewFile)[]) => void;
  bodyEditor: EditorState;
  setBody: (body: EditorState) => void;
  titleEditor: EditorState;
  setTitle: (edit: EditorState) => void;
  editable?: boolean;
  emailError: string | null;
}

const getCustomMenuItemForContactsWithType = (
  contacts: Contact[],
  type: ContactType,
  formatMessage: IntlFormatters['formatMessage']
) => {
  return contacts.reduce((acc: CustomMenuItemType[], value: Contact) => {
    if (!isNil(value.email)) {
      const itemToPush: CustomMenuItemType = {
        primary: getContactNameFromObject(value),
        secondary: value.email,
        value: value.email,
        group: formatMessage({ id: `contact.type.${type.toLowerCase()}` }),
      };
      if (isNil(acc.find((item) => isEqual(itemToPush, item)))) acc.push(itemToPush);
    }
    return acc;
  }, []);
};

const getFilteredInvoices = (invoices: FileModel[], templateSubType?: TemplateSubType) => {
  if (templateSubType === TemplateSubType.LEASE_PAYMENT_RECEIPT) {
    return invoices.filter((invoice) => invoice.category?.fileCategory === FileCategory.INVOICE_RECEIPT);
  }
  return invoices.filter((invoice) => invoice.category?.fileCategory !== FileCategory.INVOICE_RECEIPT);
};

const CommunicationDetailContent: React.FC<CommunicationDetailContentProps> = ({
  communication,
  recipients,
  setRecipients,
  recipientsInCc,
  setRecipientsInCc,
  recipientsInBcc,
  setRecipientsInBcc,
  files,
  setFiles,
  bodyEditor,
  setBody,
  titleEditor,
  setTitle,
  emailError,
  editable = false,
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { getAllContactsOwnersFromLease, getLease } = useLeases();
  const linkedOwnersContacts = !isNil(communication.lease) ? getAllContactsOwnersFromLease(communication.lease.id) : [];
  const { loading: invoicesLoading } = useInvoices();
  const { getBuilding } = useBuildings();
  const { loading: signatureDocumentsLoading, getSignatureDocument } = useSignatureDocuments();
  const { fetchFileFromId, getFiles, loading: fileLoading } = useFiles();

  let linkedTenantsContacts: Contact[] = [];
  let linkedGuarantorsContacts: Contact[] = [];
  if (communication.lease && communication.lease.id) {
    const lease = getLease(communication.lease.id);
    linkedTenantsContacts = lease?.tenants || [];
    linkedGuarantorsContacts = lease?.guarantors || [];
  }

  useEffect(() => {
    const buildingInvoicesWithFiles = async () => {
      const invoiceFiles = communication.invoiceId ? getFiles(EntityType.INVOICE, [communication.invoiceId]) : [];
      const statementFiles = communication.statementId
        ? getFiles(EntityType.STATEMENT, [communication.statementId])
        : [];
      const communicationFiles = communication.id ? getFiles(EntityType.COMMUNICATION, [communication.id]) : [];
      const leasePriceHistoryFiles = communication.leasePriceHistoryId
        ? getFiles(EntityType.LEASE_PRICE_HISTORY, [communication.leasePriceHistoryId])
        : [];
      const invoices = getFilteredInvoices(invoiceFiles, communication?.template?.subType);
      const s3Objects = await getS3ObjectUrls([
        ...invoices,
        ...statementFiles,
        ...communicationFiles,
        ...leasePriceHistoryFiles,
      ]);
      setFiles(s3Objects);
    };
    if (!invoicesLoading && !fileLoading) buildingInvoicesWithFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesLoading, fileLoading]);

  const signatureDocument =
    communication.signatureDocument ?? getSignatureDocument(communication.signatureDocumentId ?? '');

  useEffect(() => {
    const buildingFiles = async () => {
      if (!communication.signatureDocumentId) {
        return;
      }

      if (!signatureDocument || !signatureDocument.fileIds) {
        return;
      }

      const filesPromises = signatureDocument.fileIds.map((fileId) => fetchFileFromId(fileId));
      const s3Objects = await Promise.all(filesPromises);
      setFiles([...files, ...s3Objects.flat()]);
    };
    if (!signatureDocumentsLoading) buildingFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signatureDocumentsLoading, communication.signatureDocumentId, communication.id]);

  if (isNil(communication.id)) {
    return null;
  }

  const autoCompleteOptions: CustomMenuItemType[] = [
    ...getCustomMenuItemForContactsWithType(linkedOwnersContacts, ContactType.OWNER, formatMessage),
    ...getCustomMenuItemForContactsWithType(linkedTenantsContacts, ContactType.TENANT, formatMessage),
    ...getCustomMenuItemForContactsWithType(linkedGuarantorsContacts, ContactType.GUARANTOR, formatMessage),
  ];

  const toSend = communication.status === CommunicationStatus.TO_SEND;
  const dateLabelId = `communications.list.${toSend ? 'startDate' : 'sentDate'}`;
  const dateToUse = toSend ? communication.createdAt : communication.sentAt;
  const isLetter = isLetterCommunication(communication.channel as CommunicationChannel);
  const building = communication.buildingId ? getBuilding(communication.buildingId) : undefined;

  return (
    <>
      <CommunicationDetailsContentHeader
        date={dateToUse}
        dateLabelId={dateLabelId}
        signatureDocument={signatureDocument}
        isLetter={isLetter}
        gridItemsStyle={{ paddingTop: 12, paddingLeft: 24 }}
        lease={communication.lease}
        building={building}
        status={communication.status as CommunicationStatus}
        blockedEmails={communication.blockedEmails as BlockedEmail[]}
        linkedCommunicationsIds={communication.linkedCommunicationsIds as string[]}
      />
      <Divider className={classes.divider} style={{ marginBottom: 12, marginTop: 12 }} />
      {isLetter && (
        <>
          <Grid className={classes.recipientsGrid}>
            <CommunicationDetailsContentAddress communicationId={communication.id} editable={editable} />
          </Grid>
          <Divider className={classes.divider} />
        </>
      )}
      {!isLetter && (
        <>
          <Grid className={classes.recipientsGrid}>
            <CommunicationDetailsContentRecipient
              recipients={recipients}
              setRecipients={setRecipients}
              editable={editable}
              autoCompleteOptions={autoCompleteOptions}
              recipientType={CommunicationRecipientType.TO}
            />
            <Grid style={{ marginBottom: 20 }} />
            <CommunicationDetailsContentRecipient
              recipients={recipientsInCc}
              setRecipients={setRecipientsInCc}
              editable={editable}
              autoCompleteOptions={autoCompleteOptions}
              recipientType={CommunicationRecipientType.CC}
            />
            <Grid style={{ marginBottom: 20 }} />
            <CommunicationDetailsContentRecipient
              recipients={recipientsInBcc}
              setRecipients={setRecipientsInBcc}
              editable={editable}
              autoCompleteOptions={autoCompleteOptions}
              recipientType={CommunicationRecipientType.BCC}
            />

            {!isNil(emailError) && (
              <Typography className={classes.textWarning}>
                {formatMessage({ id: 'communications.detail.emailWarning' })}
              </Typography>
            )}
          </Grid>
          <Divider className={classes.divider} />
        </>
      )}
      <TemplateEditor
        title={!isLetter ? titleEditor : undefined}
        setTitle={!isLetter ? setTitle : undefined}
        body={bodyEditor}
        setBody={setBody}
        editable={editable}
        editMode={editable}
        dividerStyle={{ marginLeft: -30, marginRight: -20 }}
        editorPadding={'0 0 12px 24px'}
        hideTextStyle={isLetter}
      />
      {(!isEmpty(files) || editable) && (
        <Grid>
          <Divider style={{ marginLeft: -30, marginRight: -20, marginBottom: 10 }} />
          <CommunicationDetailsFiles files={files} setFiles={setFiles} canAddFile={editable} />
        </Grid>
      )}
    </>
  );
};

export default CommunicationDetailContent;
