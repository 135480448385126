/* eslint-disable no-console */
/* eslint-disable func-names */
import React from 'react';
import { useIntl } from 'react-intl';
import { Formik, FormikHelpers, Form } from 'formik';
import * as Yup from 'yup';
import { isNil } from 'lodash';
import { useUser } from 'src/hooks/UserContext';
import { Typography, TextField, Grid } from '@material-ui/core';
import { ElevatedPaper, LoaderButton } from '@up2rent/ui';
import { AuthProps } from 'src/App';
import { useStyles } from 'src/components/ui/TextDetailEditable';
import { onChangeToLowerCaseFieldValue } from 'src/utils/formutils';
import { isNilOrEmpty } from '@rentguru/commons-utils';
import { authStyles } from './authStyles';
import Logo from 'src/components/ui/Logo';

export const parseInvalidPasswordException = (e: Error) => {
  if (e.message.indexOf('Password not long') !== -1) return 'InvalidPasswordExceptionLong';
  if (e.message.indexOf('Password must have upper') !== -1) return 'InvalidPasswordExceptionUpper';
  if (e.message.indexOf('Password must have lower') !== -1) return 'InvalidPasswordExceptionLower';
  if (e.message.indexOf('Password must have number') !== -1) return 'InvalidPasswordExceptionNumeric';
  return e.message;
};

export const parseInvalidParameterException = (e: Error) => {
  if (e.message.indexOf("Value at 'password' failed") !== -1) return 'InvalidParameterExceptionPassword';
  return e.message;
};

const SignupSchema = Yup.object().shape({
  email: Yup.string().min(1).required(),
  password: Yup.string().min(1).required(),
  confirmPassword: Yup.string().test('sameAsPassword', 'Passwords should be the same', function (value) {
    // keep function for this.parent below
    const { password } = this.parent;
    return !isNil(value) && password === value;
  }),
  name: Yup.string().min(1).required(),
  familyName: Yup.string().min(1).required(),
});

interface SignupFormValues {
  email: string;
  password: string;
  confirmPassword: string;
  name: string;
  familyName: string;
  defaultErrorMessage?: string;
}

interface JoinSignupProps {
  email: string;
  name?: string | null;
  familyName?: string | null;
  isOwner?: boolean;
}

const JoinSignup: React.FC<AuthProps & JoinSignupProps> = ({ email, name, familyName, isOwner }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { joinSignUp } = useUser();
  const styles = authStyles();

  const initialValues: SignupFormValues = {
    email,
    password: '',
    confirmPassword: '',
    name: name ?? '',
    familyName: familyName ?? '',
  };

  const isNameDisabled = !isNilOrEmpty(name) && !isOwner;
  const isFamilyNameDisabled = !isNilOrEmpty(familyName) && !isOwner;

  const handleSubmit = async (
    values: SignupFormValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<SignupFormValues>
  ) => {
    try {
      await joinSignUp(values.email, values.password, values.name, values.familyName);
      setSubmitting(false);
    } catch (err) {
      console.log('err', err);

      if ((err as Error).name === 'UsernameExistsException') {
        setErrors({ email: (err as Error).name, defaultErrorMessage: (err as Error).message });
      } else if ((err as Error).name === 'InvalidPasswordException') {
        setErrors({
          password: parseInvalidPasswordException(err as Error),
          defaultErrorMessage: (err as Error).message,
        });
      } else if ((err as Error).name === 'InvalidParameterException') {
        setErrors({
          password: parseInvalidParameterException(err as Error),
          defaultErrorMessage: (err as Error).message,
        });
      } else {
        setErrors({ email: (err as Error).name, defaultErrorMessage: (err as Error).message });
      }

      setStatus(true);
      setSubmitting(false);
    }
  };

  const getAdditionalProps = (isDisabled: boolean) =>
    isDisabled
      ? { variant: 'outlined' as const }
      : { variant: 'filled' as const, InputProps: { classes: { underline: classes.underline } } };

  return (
    <Grid alignContent="center" alignItems="center" container direction="column">
      <Formik initialValues={initialValues} validationSchema={SignupSchema} onSubmit={handleSubmit} validateOnChange>
        {({ values, errors, touched, handleChange, handleBlur, isSubmitting, status, setFieldValue }) => {
          return (
            <ElevatedPaper className={styles.mainContainer}>
              <Form>
                <Typography variant="h5" paragraph component="h2" gutterBottom>
                  {formatMessage({ id: 'login.joinWithMail' })}
                </Typography>

                <TextField
                  label={formatMessage({
                    id: 'signup.emailLabel',
                  })}
                  id="email"
                  name="email"
                  type="text"
                  variant="outlined"
                  value={values.email}
                  onChange={(event) => {
                    onChangeToLowerCaseFieldValue(event, 'email', setFieldValue);
                  }}
                  onBlur={handleBlur}
                  style={{ width: 340, marginTop: 15, marginBottom: 10 }}
                  error={Boolean(errors.email && touched.email)}
                  InputLabelProps={{ className: classes.label }}
                  disabled
                />
                <TextField
                  label={formatMessage({ id: 'signup.nameLabel' })}
                  id="name"
                  type="text"
                  margin="normal"
                  fullWidth
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  error={Boolean(errors.name && touched.name)}
                  InputLabelProps={{ className: classes.label }}
                  disabled={isNameDisabled}
                  {...getAdditionalProps(isNameDisabled)}
                />
                <TextField
                  label={formatMessage({ id: 'signup.familyNameLabel' })}
                  id="familyName"
                  type="text"
                  margin="normal"
                  fullWidth
                  value={values.familyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  error={Boolean(errors.familyName && touched.familyName)}
                  InputLabelProps={{ className: classes.label }}
                  disabled={isFamilyNameDisabled}
                  {...getAdditionalProps(isFamilyNameDisabled)}
                />
                <TextField
                  label={formatMessage({ id: 'signup.passwordLabel' })}
                  id="password"
                  type="password"
                  autoComplete="current-password"
                  margin="normal"
                  variant="filled"
                  fullWidth
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  error={Boolean(errors.password && touched.password)}
                  helperText={
                    errors.password && touched.password
                      ? formatMessage({
                          id: `error.${errors.password}`,
                          defaultMessage: errors.defaultErrorMessage
                            ? errors.defaultErrorMessage
                            : formatMessage({ id: 'signup.passwordHelp' }),
                        })
                      : formatMessage({ id: 'signup.passwordHelp' })
                  }
                  InputProps={{ classes: { underline: classes.underline } }}
                  InputLabelProps={{ className: classes.label }}
                />
                <TextField
                  label={formatMessage({ id: 'signup.confirmPasswordLabel' })}
                  id="confirmPassword"
                  type="password"
                  autoComplete="current-password"
                  margin="normal"
                  variant="filled"
                  fullWidth
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  error={Boolean(errors.confirmPassword && touched.confirmPassword)}
                  helperText={
                    errors.confirmPassword && touched.confirmPassword
                      ? formatMessage({ id: 'error.confirmationPassword' })
                      : ''
                  }
                  InputProps={{ classes: { underline: classes.underline } }}
                  InputLabelProps={{ className: classes.label }}
                  style={{ paddingBottom: 30 }}
                />
                <LoaderButton data-test="createAccount" loading={isSubmitting} success={status}>
                  {formatMessage({ id: 'login.joinCreateAccount' })}
                </LoaderButton>
              </Form>
            </ElevatedPaper>
          );
        }}
      </Formik>
      <Logo
        size={20}
        withText
        margin="0px"
        logoStyle={{ width: 20, height: 20, margin: 0 }}
        textStyle={{ width: 88, height: 13, objectFit: 'contain' }}
      />
    </Grid>
  );
};

export default JoinSignup;
