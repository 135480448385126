import { Grid, Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import {
  AgencyRate,
  Colors,
  Statement,
  StatementType,
  UnitType,
  formatStandardizedUTCDate,
} from '@rentguru/commons-utils';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { CustomSimpleDialog } from '@up2rent/ui';
import { getAgencyRate } from 'src/hooks/AgencyRatesContext';
import { fetchAgencyRateStatements } from 'src/hooks/StatementContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { dateLocaleMap, useLocale } from 'src/i18n/IntlProviderWrapper';

interface ConfirmValidateOwnerStatementDialogProps {
  open: boolean;
  statement: Statement;
  validateStatement: () => Promise<void>;
  onClose: () => void;
}

const ConfirmValidateOwnerStatementDialog: React.FC<ConfirmValidateOwnerStatementDialogProps> = ({
  open,
  statement,
  validateStatement,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const { getUnit } = useUnits();
  const [agencyRatesApplied, setAgencyRatesApplied] = useState<AgencyRate[] | null>(null);

  const feesIncludedInStatement = statement.types && statement.types.includes(StatementType.FEES);

  useEffect(() => {
    const buildAgencyRateStatements = async () => {
      const agencyRateStatements = await fetchAgencyRateStatements('byStatement', statement.id);
      const agencyRatesAppliedPromises = agencyRateStatements.reduce((acc: Promise<AgencyRate>[], ags) => {
        if (ags.agencyRateId) {
          acc.push(getAgencyRate(ags.agencyRateId));
        }
        return acc;
      }, []);
      const completeAgencyRates = await Promise.all(agencyRatesAppliedPromises);
      setAgencyRatesApplied(completeAgencyRates);
    };
    if (feesIncludedInStatement) {
      buildAgencyRateStatements();
    }
  }, [feesIncludedInStatement, statement.id]);

  const allUnits = useMemo(() => {
    return feesIncludedInStatement && statement.unitIds ? statement.unitIds.map((unitId) => getUnit(unitId)) : [];
  }, [statement.unitIds, getUnit, feesIncludedInStatement]);

  const unitsNotCoveredByFees =
    agencyRatesApplied && feesIncludedInStatement
      ? allUnits.filter((unit) => {
          if (!unit) return false;
          // We didn't find any agency rate that is coering the type of the unit ?
          const noAgencyRateCoveredThisUnitType = !agencyRatesApplied.some((agencyRate) =>
            agencyRate.unitType.includes(unit!.type as UnitType)
          );
          return noAgencyRateCoveredThisUnitType;
        })
      : [];

  const fromDateToString = formatStandardizedUTCDate(new Date(statement.periodFrom), 'dd/MM/yyyy', {
    locale: dateLocaleMap[language],
  });
  const toDateToString = formatStandardizedUTCDate(new Date(statement.periodTo), 'dd/MM/yyyy', {
    locale: dateLocaleMap[language],
  });

  return (
    <CustomSimpleDialog
      open={open}
      onClose={onClose}
      onActionButtonClick={async () => {
        setValidationLoading(true);
        await validateStatement();
        setValidationLoading(false);
      }}
      actionButtonLabel={formatMessage({ id: 'accounting.statement.toValidateSection.validateAndSend' })}
      actionButtonLoading={validationLoading}
      title={formatMessage({ id: 'accounting.statement.toValidateSection.validateOwnerDialogTitle' })}
      dividerBelowTitle
      maxWidth={750}
      formatMessage={formatMessage}
    >
      <Grid style={{ marginTop: 10 }}>
        <Typography style={{ fontSize: 16, textAlign: 'justify' }}>
          {formatMessage(
            { id: 'accounting.statement.toValidateSection.validateOwnerHelper' },
            { fromDate: fromDateToString, toDate: toDateToString }
          )}
        </Typography>
        <Typography style={{ fontSize: 16, textAlign: 'justify' }}>
          {formatMessage({ id: 'accounting.statement.toValidateSection.validateOwnerHelper2' })}
        </Typography>
        <Typography style={{ fontSize: 16, textAlign: 'justify' }}>
          {formatMessage({ id: 'accounting.statement.toValidateSection.validateHelper3' })}
        </Typography>

        {feesIncludedInStatement && !isEmpty(unitsNotCoveredByFees) && (
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 10,
              textAlign: 'justify',
            }}
          >
            <Warning style={{ color: Colors.SUN_YELLOW, marginRight: 10 }} />
            <Typography style={{ color: Colors.SUN_YELLOW, fontSize: 16 }}>
              {formatMessage(
                { id: 'accounting.statement.toValidateSection.unitsNotByAgencyRateCoveredWarning' },
                { units: unitsNotCoveredByFees.map((u) => u?.name ?? '').join(', ') }
              )}
            </Typography>
          </Grid>
        )}
      </Grid>
    </CustomSimpleDialog>
  );
};

export default ConfirmValidateOwnerStatementDialog;
