import { Grid, MenuItem, Typography } from '@material-ui/core';
import {
  ACCOUNT_TURNOVER_VARIOUS_OPERATIONS_CHARGE_CLASS,
  ACCOUNT_TURNOVER_VARIOUS_OPERATIONS_SERVICE_CLASS,
  Colors,
  filterAccountLabelsForSpecificUnitLease,
  getVatToApply,
  LeaseExtended,
  Unit,
} from '@rentguru/commons-utils';
import { CustomIconButton } from '@up2rent/ui';
import { useFormikContext } from 'formik';
import { isNil } from 'lodash';
import { useIntl } from 'react-intl';
import CustomDatePicker from 'src/components/ui/CustomDatePicker';
import { CustomizedSelect } from 'src/components/ui/CustomizedSelect';
import UnitSelectorField from 'src/components/ui/Forms/FormField/UnitSelectorField';
import TextDetailEditable from 'src/components/ui/TextDetailEditable';
import { useTransactions } from 'src/hooks/TransactionsContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as DeleteIcon } from 'src/icons/delete.svg';
import { getSafeValueInObject } from 'src/utils/object';
import { AddCustomInvoiceValues, CustomPosting, useAddCustomInvoiceStyles } from './addCustomInvoiceDialogUtils';

interface EditableCustomPostingProps {
  lease: LeaseExtended;
  posting: CustomPosting;
  index: number;
}

const EditableCustomPosting: React.FC<EditableCustomPostingProps> = ({ lease, posting, index }) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const { getAccountLabels } = useTransactions();
  const { getUnit } = useUnits();
  const { values, setValues, errors } = useFormikContext<AddCustomInvoiceValues>();
  const classes = useAddCustomInvoiceStyles();
  const targetUnitLease = lease.units?.find((unitLeases) => unitLeases.unitId === posting.unitId);
  const accountLabels = targetUnitLease
    ? filterAccountLabelsForSpecificUnitLease(getAccountLabels(), targetUnitLease).filter(
        (accountLabel) =>
          ![
            ACCOUNT_TURNOVER_VARIOUS_OPERATIONS_SERVICE_CLASS,
            ACCOUNT_TURNOVER_VARIOUS_OPERATIONS_CHARGE_CLASS,
          ].includes(accountLabel.class)
      )
    : [];
  const selectedAccountLabel = posting.accountLabel;
  const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
  const postingIndex = values.history.indexOf(posting);
  const unitsOfLease =
    lease.units?.reduce((acc, unitLease) => {
      if (unitLease.unit) {
        const unit = getUnit(unitLease.unitId);
        if (unit) {
          acc.push(unit);
        }
      }
      return acc;
    }, [] as Unit[]) ?? [];

  const showVatExcludedLabel = !isNil(getVatToApply(lease, posting.accountLabel?.class ?? 0));
  const amountLabel = `${formatMessage({
    id: 'transactions.addTransaction.amount',
  })} ${
    showVatExcludedLabel
      ? formatMessage({
          id: `lease.detail.indexationGeneral.vatExcl`,
        })
      : ''
  }`;

  const onValueChange = (attributeName: string, value: string | Date | null) => {
    setValues((currentValues: AddCustomInvoiceValues) => {
      const newHistory = currentValues.history.map((currentPosting) => {
        if (currentPosting.id === posting.id) {
          return { ...currentPosting, [attributeName]: value };
        }
        return currentPosting;
      });
      return { ...currentValues, history: newHistory };
    });
  };

  const onTransactionTypeChange = (value: string) =>
    setValues((currentValues: AddCustomInvoiceValues) => {
      const newHistory = currentValues.history.map((currentPosting) => {
        if (currentPosting.id === posting.id) {
          const accountLabel = accountLabels?.find((currentAccountLabel) => currentAccountLabel.id === value);
          const currentLabel = accountLabel?.labels?.find((label) => label.language === language)?.label ?? '';
          return {
            ...currentPosting,
            transactionType: value,
            label: currentLabel,
            accountLabel: accountLabel ?? null,
          };
        }
        return currentPosting;
      });
      return { ...currentValues, history: newHistory };
    });

  return (
    <Grid style={{ marginTop: 10, marginBottom: 6 }}>
      <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography className={classes.subtitle}>
          {formatMessage({ id: 'lease.detail.addCustomInvoice.posting' })} {index + 1}
        </Typography>
        <CustomIconButton
          style={{ color: Colors.BLUE_GREY, height: 40 }}
          onClick={() => {
            setValues((currentValues: AddCustomInvoiceValues) => ({
              ...currentValues,
              history: currentValues.history.filter((currentPosting) => currentPosting.id !== posting.id),
            }));
          }}
          Icon={DeleteIcon}
          iconStyle={{ fill: Colors.BLUE_GREY }}
          disabled={values.history.length === 1}
        />
      </Grid>
      <Grid className={classes.editableCustomPostingRow}>
        <CustomizedSelect
          label={formatMessage({ id: 'lease.addLease.transactionType' })}
          value={selectedAccountLabel?.id ?? ''}
          FormControlProps={{ required: true, style: { minWidth: 150 } }}
          error={Boolean(getSafeValueInObject(errors, `history[${postingIndex}].transactionType`))}
          SelectProps={{
            renderValue: (value) => {
              const accountLabelClass = accountLabels.find((accountLabel) => accountLabel.id === value)?.class;
              return <Typography className={classes.subtitle}>{accountLabelClass}</Typography>;
            },
          }}
          onChange={(value: string) => onTransactionTypeChange(value)}
        >
          {accountLabels
            .sort((a, b) => {
              const labelA = a.labels?.find((label) => label.language === language)?.label ?? '';
              const labelB = b.labels?.find((label) => label.language === language)?.label ?? '';
              return collator.compare(labelA, labelB);
            })
            .map((accountLabel) => {
              const labelToDisplay = accountLabel.labels?.find((label) => label.language === language)?.label ?? '';
              return (
                <MenuItem value={accountLabel.id} key={accountLabel.id}>
                  <Grid style={{ display: 'flex', gap: 10 }}>
                    <Typography style={{ fontWeight: 'bold' }}>{accountLabel.class}</Typography>
                    <Typography>{labelToDisplay}</Typography>
                  </Grid>
                </MenuItem>
              );
            })}
        </CustomizedSelect>
        <TextDetailEditable
          title={formatMessage({ id: 'lease.detail.addCustomInvoice.label' })}
          editMode
          name={`history[${postingIndex}].label`}
          type="text"
          error={Boolean(getSafeValueInObject(errors, `history[${postingIndex}].label`))}
          text={selectedAccountLabel?.labels?.find((label) => label.language === language)?.label ?? ''}
          onChangeObserver={(value: string) => onValueChange('label', value)}
          style={{ width: '55%' }}
          noMaxWidth
        />
        <TextDetailEditable
          title={amountLabel}
          editMode
          name={`history[${postingIndex}].amount`}
          type="number"
          endAdornment="€"
          style={{ width: '25%' }}
          error={Boolean(getSafeValueInObject(errors, `history[${postingIndex}].amount`))}
          typeNumberNoEndArrow
          step={0.01}
        />
      </Grid>
      <Grid className={classes.editableCustomPostingRow}>
        <CustomDatePicker
          name={`history[${postingIndex}].dueDate`}
          label={formatMessage({ id: 'financial.dueDate' })}
          value={posting.dueDate ?? new Date()}
          style={{ minWidth: 150, width: 150 }}
          keyboardIconStyle={{ width: 20 }}
          keyboardIconProps={{ size: 'small' }}
          safeDateFormat
          onChange={(date: Date | null) => onValueChange('dueDate', date)}
          error={Boolean(getSafeValueInObject(errors, `history[${postingIndex}].dueDate`))}
        />
        <UnitSelectorField
          units={unitsOfLease}
          labelId="lease.addLease.unit"
          fieldName={`history[${postingIndex}].unitId`}
          onChange={(unitId: string) => onValueChange('unitId', unitId)}
          initialValue={posting.unitId ?? undefined}
          style={{ width: '100%' }}
          error={Boolean(getSafeValueInObject(errors, `history[${postingIndex}].unitId`))}
          disabled={unitsOfLease.length === 1}
        />
      </Grid>
    </Grid>
  );
};

export default EditableCustomPosting;
