import { useEffect, useRef, useState } from 'react';
import { isEmpty, isNil } from 'lodash';
import { Grid, useMediaQuery } from '@material-ui/core';
import {
  LeaseExtended,
  Colors,
  Building,
  LeaseStatus,
  SignatureDocumentResult,
  S3Object,
} from '@rentguru/commons-utils';
import { ReactComponent as ChevronRight } from 'src/icons/chevron-right.svg';
import PropertyActionMenu from 'src/components/Properties/PropertyActionMenu/PropertyActionMenu';
import TenantLeaseActionMenuDialogs from 'src/components/TenantView/Leases/TenantLeaseActionMenuDialog';
import { CustomIconButton, MOBILE_MAX_WIDTH } from '@up2rent/ui';
import QuickActionButton from 'src/components/ui/QuickActionButton/QuickActionButton';
import { useNoScrollbarStyles } from 'src/components/ui/noScrollbar';
import LeaseActionMenuDialogs from './LeaseActionMenuDialogs';
import { LeaseAction, QuickAction } from './useLeaseActionsMenuUtils';

interface LeaseDetailActionsMenuProps {
  lease?: LeaseExtended;
  property?: Building;
  actions: QuickAction[];
  leaseContract?: S3Object | null | undefined;
  generatePDF?: () => Promise<void>;
  signatureResult?: SignatureDocumentResult | null | undefined;
  editLease?: () => void;
  setLeaseToDraft?: () => Promise<void>;
  isTenant?: boolean;
}

const IntermediateDetailActionsMenu: React.FC<LeaseDetailActionsMenuProps> = ({
  lease,
  property,
  actions,
  leaseContract,
  generatePDF,
  signatureResult,
  editLease,
  setLeaseToDraft,
  isTenant = false,
}) => {
  const scrollbarClasses = useNoScrollbarStyles();
  const [isOverFlowing, setIsOverFlowing] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<{
    open: boolean;
    type: LeaseAction | null;
  }>({
    open: false,
    type: null,
  });
  const ref = useRef<HTMLDivElement | null>(null);
  const firstRender = useRef<boolean>(true);
  const isMobileLayout = useMediaQuery(MOBILE_MAX_WIDTH);
  const tenantMobileView = isTenant && isMobileLayout;
  const height = tenantMobileView ? 100 : 165;

  useEffect(() => {
    const handleResize = () => {
      if (!isNil(ref.current)) {
        const hasOverflow = ref.current?.clientWidth < ref.current?.scrollWidth;
        if (hasOverflow && !isOverFlowing) setIsOverFlowing(true);
        if (!hasOverflow && isOverFlowing) setIsOverFlowing(false);
      }
    };
    if (firstRender.current) {
      firstRender.current = false;
      handleResize();
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isOverFlowing]);

  if (isEmpty(actions)) {
    // Don't display if no actions possible at all
    return null;
  }

  const onCloseDialog = () => setDialogOpen({ open: false, type: null });

  const onClick = async (type: LeaseAction) => {
    if (!isNil(editLease) && type === LeaseAction.EDIT) {
      editLease();
      return;
    }
    if (!isNil(setLeaseToDraft) && (type === LeaseAction.SET_TO_DRAFT || type === LeaseAction.RETRACT)) {
      await setLeaseToDraft();
      return;
    }
    if (type === LeaseAction.GENERATE_PDF && !isNil(generatePDF)) {
      await generatePDF();
      return;
    }

    setDialogOpen({ open: true, type });
  };
  const onButtonClick = (side: 'left' | 'right') => {
    // Value that is slightly less than the actual width of the buttons
    // This way, the user will not think it is the end of the buttons since his screen will not match
    // the side of said button
    const amountOfPixelsToScroll = 165;
    if (!isNil(ref.current)) {
      if (side === 'left') {
        ref.current.scrollLeft -= amountOfPixelsToScroll;
      } else {
        ref.current.scrollLeft += amountOfPixelsToScroll;
      }
    }
  };

  return (
    <>
      <Grid
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          marginBottom: isTenant ? 0 : 20,
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10,
        }}
      >
        <Grid style={{ display: 'flex', position: 'relative' }}>
          {isOverFlowing && (
            <Grid
              style={{
                display: 'flex',
                alignItems: 'center',
                position: 'absolute',
                left: 0,
                top: '40%',
                borderRadius: '50%',
                zIndex: 10000,
              }}
            >
              <CustomIconButton
                style={{
                  transform: 'rotate(180deg)',
                  height: 40,
                  left: 10,
                  backgroundColor: Colors.LIGHT_BLUE_GREY,
                  border: `1px solid ${Colors.LIGHT_BLUE_GREY}`,
                }}
                iconStyle={{ fill: Colors.CLASSICAL_WHITE }}
                onClick={() => onButtonClick('left')}
                Icon={ChevronRight}
              />
            </Grid>
          )}
          <Grid
            style={{
              display: 'flex',
              height,
              minHeight: height,
              overflowX: 'auto',
              overflowY: 'hidden',
              paddingTop: 25,
              paddingBottom: 25,
              paddingLeft: 30,
              paddingRight: 30,
              scrollBehavior: 'smooth',
            }}
            ref={ref}
            className={scrollbarClasses.noScrollbar}
          >
            {actions.map((action) => {
              // Disabled for now
              if (
                [
                  LeaseAction.ADJUST_CHARGE,
                  LeaseAction.CHANGE_TENANTS,
                  LeaseAction.CHANGE_UNITS,
                  LeaseAction.GENERATE_PDF,
                ].includes(action.type)
              ) {
                return null;
              }

              let extras = {} as { href?: string; target?: string };
              if (action.type === LeaseAction.SIGN) {
                extras = { target: '_blank', href: signatureResult?.signatureLink ?? '#' };
                if (signatureResult?.signed) return null;
                if (extras.href === '#') return null;
              }
              const allowClick = action.hasEnoughRights && action.type !== LeaseAction.SIGN;

              if (
                (lease?.status !== LeaseStatus.OutForSignature &&
                  action.type === LeaseAction.REJECT_DOCUMENT_SIGNATURE) ||
                (lease?.status !== LeaseStatus.OutForSignature && action.type === LeaseAction.SIGN)
              ) {
                return null;
              }

              return (
                <QuickActionButton
                  key={action.type}
                  onClick={allowClick ? () => onClick(action.type) : undefined}
                  disabled={!action.hasEnoughRights}
                  helperTextLabelIds={[[action.helperLabelId], undefined]}
                  textLabelId={action.textLabelId}
                  icon={action.iconComponent}
                  dataTest={LeaseAction[action.type]}
                  buttonStyle={{
                    height,
                    minHeight: height,
                    marginRight: 10,
                    padding: isNil(extras.href) ? '0px 50px' : 0,
                    justifyContent: 'center',
                    justifyItems: 'center',
                    alignItems: 'center',
                    width: 175,
                  }}
                  textStyle={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    textAlign: 'center',
                    marginTop: 10,
                  }}
                  {...extras}
                />
              );
            })}
          </Grid>
          {isOverFlowing && (
            <Grid
              style={{
                display: 'flex',
                alignItems: 'center',
                position: 'absolute',
                right: 30,
                top: '40%',
                borderRadius: '50%',
                zIndex: 10000,
              }}
            >
              <CustomIconButton
                style={{
                  height: 40,
                  backgroundColor: Colors.LIGHT_BLUE_GREY,
                  border: `1px solid ${Colors.LIGHT_BLUE_GREY}`,
                }}
                iconStyle={{ fill: Colors.CLASSICAL_WHITE }}
                onClick={() => onButtonClick('right')}
                Icon={ChevronRight}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      {!isTenant && !isNil(lease) && (
        <LeaseActionMenuDialogs
          lease={lease}
          {...dialogOpen}
          onCloseDialog={onCloseDialog}
          leaseContract={leaseContract}
        />
      )}
      {!isTenant && !isNil(property) && (
        <PropertyActionMenu property={property} {...dialogOpen} onCloseDialog={onCloseDialog} />
      )}
      {isTenant && !isNil(lease) && (
        <TenantLeaseActionMenuDialogs
          lease={lease}
          {...dialogOpen}
          onCloseDialog={onCloseDialog}
          leaseContract={leaseContract}
        />
      )}
    </>
  );
};

export default IntermediateDetailActionsMenu;
