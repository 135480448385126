import { Grid, GridSize, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { Colors } from '@rentguru/commons-utils';
import { OverflowableTypography } from '@up2rent/ui';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    backgroundColor: 'white',
    justifyContent: 'space-between',
    marginBottom: '20px',
    height: '80px',
    borderRadius: 0,
    borderBottomLeftRadius: 10,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 10,
    paddingBottom: 10,
  },
  h6: {
    textAlign: 'left',
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Mulish',
    fontSize: '20px',
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

export interface HeaderOptions {
  name: string;
  onClick: () => void;
}

interface ContentDetailHeader {
  EntityIcon?: React.ElementType;
  title: string;
  titleStatus?: React.ReactNode;
  actions: React.ReactNode[];
  toolbarStyle?: React.CSSProperties;
  loading?: boolean;
  titleGridSpace?: GridSize;
  actionsGridSpace?: GridSize;
}
// eslint-disable-next-line no-redeclare
const ContentDetailHeader: React.FC<ContentDetailHeader> = ({
  EntityIcon,
  title,
  titleStatus,
  actions,
  toolbarStyle = {},
  loading = false,
  titleGridSpace = 8,
  actionsGridSpace = 4,
}) => {
  const classes = useStyles();

  return (
    <Toolbar style={toolbarStyle} className={classes.root}>
      <Grid container spacing={1} style={{ alignItems: 'center', overflow: 'hidden' }}>
        <Grid item sm={12} md={titleGridSpace}>
          <Grid
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'left',
              alignItems: 'center',
              gap: 5,
            }}
          >
            {!loading && EntityIcon && <EntityIcon style={{ marginRight: 5, fill: Colors.BLUE_GREY }} />}
            {loading && <Skeleton style={{ marginRight: 5 }} variant="rect" width={150} height={30} />}
            <OverflowableTypography variant="h6" color="inherit" className={classes.h6} text={loading ? '' : title} />
            {!loading && <>{titleStatus}</>}
          </Grid>
        </Grid>
        <Grid
          item
          sm={12}
          md={actionsGridSpace}
          style={{
            display: 'flex',
            flex: 1,
            padding: '10px 4px',
            // With MUI V5, we can improve further by replacing the line underneath with
            // justifyContent: { sm: 'left', md: 'right'},
            justifyContent: 'right',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          {loading && actions && <Skeleton variant="rect" width={50} />}
          {!loading && <>{actions}</>}
        </Grid>
      </Grid>
    </Toolbar>
  );
};
export default ContentDetailHeader;
