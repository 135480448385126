/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-param-last */
import React, { Reducer, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { useUser } from './UserContext';
import {
  CreateInventoryCustomItemInput,
  UpdateInventoryCustomItemInput,
  InventoryCustomItem,
  cleanInputCreate,
  cleanInputUpdate,
  getReadId,
  getTableClientId,
  syncInventoryCustomItems as syncQuery,
  getInventoryCustomItem as getQuery,
  createInventoryCustomItem as createMutation,
  updateInventoryCustomItem as updateMutation,
  deleteInventoryCustomItem as deleteMutation,
  CreateInventoryCustomItemMutationVariables as CreateMutationVariables,
  DeleteInventoryCustomItemMutationVariables as DeleteMutationVariables,
  CreateInventoryCustomItemInput as CreateInput,
  Model,
} from '@rentguru/commons-utils';
import { isEmpty, isNil } from 'lodash';
import { usePermissions } from './utils/PermissionsContext';
import {
  deleteEntityWithFetchBefore,
  getFilterFieldNameForIndex,
  list,
  mutation,
  recordWasUpdated,
} from '@up2rent/fetch-utils';

const ENTITY_MODEL_NAME: Model = 'InventoryCustomItem';

export interface InventoryCustomItemsContext extends InventoryCustomItemState {
  getInventoryCustomItem: (id: string) => InventoryCustomItem | null | undefined;
  createInventoryCustomItem: (
    input: Omit<CreateInventoryCustomItemInput, 'clientId' | 'readId'>
  ) => Promise<InventoryCustomItem>;
  updateInventoryCustomItem: (updates: UpdateInventoryCustomItemInput) => Promise<InventoryCustomItem>;
  deleteInventoryCustomItem: (id: string) => Promise<InventoryCustomItem | null>;
  setFetchInventoryCustomItem: () => void;
}

interface InventoryCustomItemState {
  inventoryCustomItems: InventoryCustomItem[] | null;
  loading: boolean;
  lastSync?: Date;
  shouldFetch: boolean;
}

type Action =
  | {
      type: 'SHOULD_FETCH' | 'IS_FETCHING';
    }
  | {
      type: 'ADD_INVENTORY_CUSTOM_ITEM';
      payload: { inventoryCustomItem: InventoryCustomItem };
    }
  | {
      type: 'FETCHED';
      payload: { inventoryCustomItems: InventoryCustomItem[]; error?: string };
    }
  | {
      type: 'ERROR';
      payload: { error: string };
    }
  | {
      type: 'UPDATE_INVENTORY_CUSTOM_ITEM';
      payload: { inventoryCustomItem: InventoryCustomItem };
    }
  | {
      type: 'DELETE_INVENTORY_CUSTOM_ITEM';
      payload: { id: string };
    };

const initialState: InventoryCustomItemState = {
  inventoryCustomItems: [],
  loading: false,
  shouldFetch: false,
};

const inventoryCustomItemReducer = (state: InventoryCustomItemState, action: Action): InventoryCustomItemState => {
  switch (action.type) {
    case 'SHOULD_FETCH':
      if (state.loading) {
        return state;
      }
      return {
        ...state,
        shouldFetch: true,
      };
    case 'IS_FETCHING':
      return {
        ...state,
        loading: true,
      };

    case 'FETCHED':
      if (action.payload.error) {
        return {
          ...state,
          inventoryCustomItems: [],
          loading: false,
          shouldFetch: false,
        };
      }
      const inventoryCustomItems = action.payload.inventoryCustomItems;
      return {
        ...state,
        loading: false,
        shouldFetch: false,
        lastSync: new Date(),
        inventoryCustomItems,
      };
    case 'ADD_INVENTORY_CUSTOM_ITEM':
      return {
        ...state,
        inventoryCustomItems: [...(state.inventoryCustomItems ?? []), action.payload.inventoryCustomItem],
      };
    case 'UPDATE_INVENTORY_CUSTOM_ITEM':
      if (!state.inventoryCustomItems) {
        return state;
      }

      const updatedInventoryCustomItem = action.payload.inventoryCustomItem;
      const oldRecord = state.inventoryCustomItems.find(
        (inventoryCustomItem) => inventoryCustomItem.id === updatedInventoryCustomItem.id
      );
      if (isNil(oldRecord) || !recordWasUpdated(oldRecord, updatedInventoryCustomItem)) {
        return state;
      }

      return {
        ...state,
        inventoryCustomItems: state.inventoryCustomItems.map((inventoryCustomItem) => {
          if (inventoryCustomItem.id === updatedInventoryCustomItem.id) {
            return updatedInventoryCustomItem;
          }
          return inventoryCustomItem;
        }),
      };
    case 'DELETE_INVENTORY_CUSTOM_ITEM':
      if (!state.inventoryCustomItems) {
        return state;
      }

      return {
        ...state,
        inventoryCustomItems: state.inventoryCustomItems.filter(
          (inventoryCustomItem) => inventoryCustomItem.id !== action.payload.id
        ),
      };
    default:
      return state;
  }
};

const fetchInventoryCustomItems = async (
  clientId: string,
  additionalFilter?: object
): Promise<InventoryCustomItem[]> => {
  return await list<InventoryCustomItem>(
    syncQuery,
    getFilterFieldNameForIndex('byClientId'),
    getTableClientId(clientId, ENTITY_MODEL_NAME),
    additionalFilter
  );
};

export const InventoryCustomItemsContext = React.createContext<InventoryCustomItemsContext | null>(null);

export const InventoryCustomItemsContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<InventoryCustomItemState, Action>>(
    inventoryCustomItemReducer,
    initialState
  );
  const { clientId } = useUser();
  const { inventoryOfFixturesCreationDelete, furnituresInventoryCreationDelete } = usePermissions();

  const { loading, inventoryCustomItems } = state;

  const setFetchInventoryCustomItem = () => {
    dispatch({ type: 'SHOULD_FETCH' });
  };

  useEffect(() => {
    const fetchAndSet = async () => {
      dispatch({ type: 'IS_FETCHING' });
      const result = await fetchInventoryCustomItems(clientId!);
      dispatch({ type: 'FETCHED', payload: { inventoryCustomItems: result } });
    };
    if (state.shouldFetch) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetch]);

  const getInventoryCustomItem = (id: string) => {
    if (loading || !inventoryCustomItems) {
      return null;
    }
    return inventoryCustomItems.find((item) => item.id === id);
  };

  const createInventoryCustomItem = async (
    input: Omit<CreateInventoryCustomItemInput, 'clientId' | 'readId'>
  ): Promise<InventoryCustomItem> => {
    const inventoryCustomItem = await mutation<InventoryCustomItem, CreateMutationVariables>(createMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateInput),
        clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME),
        readId: getReadId(clientId!, ENTITY_MODEL_NAME),
      },
    });
    dispatch({ type: 'ADD_INVENTORY_CUSTOM_ITEM', payload: { inventoryCustomItem } });
    return inventoryCustomItem;
  };

  const updateInventoryCustomItem = async (updates: UpdateInventoryCustomItemInput): Promise<InventoryCustomItem> => {
    const result = await mutation<InventoryCustomItem>(updateMutation, {
      input: { ...cleanInputUpdate(updates, false) },
    });
    dispatch({ type: 'UPDATE_INVENTORY_CUSTOM_ITEM', payload: { inventoryCustomItem: result } });
    return result;
  };

  const deleteInventoryCustomItem = async (id: string): Promise<InventoryCustomItem | null> => {
    const inventoryCustomItem = getInventoryCustomItem(id);
    if (!inventoryCustomItem) {
      return null;
    }
    if (!inventoryOfFixturesCreationDelete && !furnituresInventoryCreationDelete) {
      return inventoryCustomItem;
    }

    await deleteEntityWithFetchBefore<Pick<InventoryCustomItem, 'id'>, DeleteMutationVariables>(
      { id: inventoryCustomItem.id },
      getQuery,
      deleteMutation
    );

    dispatch({ type: 'DELETE_INVENTORY_CUSTOM_ITEM', payload: { id: inventoryCustomItem.id } });
    return inventoryCustomItem;
  };

  const values = useMemo(
    () => ({
      ...state,
      error: null,
      getInventoryCustomItem,
      createInventoryCustomItem,
      updateInventoryCustomItem,
      deleteInventoryCustomItem,
      setFetchInventoryCustomItem,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <InventoryCustomItemsContext.Provider value={values}>{children}</InventoryCustomItemsContext.Provider>;
};

export const useInventoryCustomItems = (): InventoryCustomItemsContext => {
  const firstRender = useRef<boolean>(false);
  const context = useContext<InventoryCustomItemsContext | null>(InventoryCustomItemsContext);

  if (isNil(context)) {
    throw new Error('`useInventoryCustomItems` hook must be used within a `InventoryCustomItemsContext` component');
  }
  useEffect(() => {
    if (!firstRender.current && isEmpty(context.inventoryCustomItems) && isNil(context.lastSync)) {
      context.setFetchInventoryCustomItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  return context;
};
