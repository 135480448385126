/* eslint-disable default-case */
import React, { CSSProperties } from 'react';
import { useFormikContext, FormikProps } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { Unit, UnitType } from '@rentguru/commons-utils';
import isNil from 'lodash/isNil';
import ComboBox, { CustomizedComboBoxProps, CustomMenuItemType } from '../../ComboBox/TextComboBox';
import { getSubUnitInitialValues, AdditionalUnit } from '../FormSection/AddAdditionalUnits';
import { ReactComponent as ParkingIcon } from '../../../../icons/icon-parking.svg';
import { ReactComponent as OfficeIcon } from '../../../../icons/iconOffice.svg';
import { ReactComponent as StudentIcon } from '../../../../icons/iconStudents.svg';
import { ReactComponent as CommercialIcon } from '../../../../icons/iconCommercials.svg';
import { ReactComponent as ResidentialIcon } from '../../../../icons/icon-residential.svg';
import { AddLeaseFormValues } from 'src/components/Leases/AddLease/AddLeaseForm';
import { useUnits } from 'src/hooks/UnitsContext';
import { useUnitInventories } from 'src/hooks/UnitInventoryContext';
import { useTechnics } from 'src/hooks/TechnicsContext';
import { useDocumentCategory } from 'src/hooks/FileCategoryContext';

export interface UnitSelectorWithLogoFormValues {
  unit: {
    id?: string;
  };
}

export const UnitSelectorWithLogoSchema = Yup.object()
  .shape({
    unit: Yup.object().shape({
      id: Yup.string().min(1).required(),
    }),
  })
  .required();

export const getUnitIcon = (unitType: UnitType | undefined, style: CSSProperties) => {
  switch (unitType) {
    case UnitType.RESIDENTIAL:
      return <ResidentialIcon style={style} />;
    case UnitType.STUDENT:
      return <StudentIcon style={style} />;
    case UnitType.COMMERCIAL:
      return <CommercialIcon style={style} />;
    case UnitType.OFFICE:
      return <OfficeIcon style={style} />;
    case UnitType.PARKING:
      return <ParkingIcon style={style} />;
    case UnitType.OTHER:
      return;
  }
  return <ParkingIcon />;
};

interface UnitSelectorWithLogoFieldProps
  extends Omit<CustomizedComboBoxProps, 'onChange' | 'initialValue' | 'label' | 'data'> {
  units: Unit[];
  fieldName: string;
  initialValue?: string;
  style?: React.CSSProperties;
  index: number;
  onChange?: (additionalUnit: AdditionalUnit) => void;
}

const UnitSelectorWithLogoField: React.FC<UnitSelectorWithLogoFieldProps> = ({
  units: selectableUnits,
  fieldName,
  initialValue: initialUnitId,
  style,
  onChange,
  ...rest
}) => {
  const { values, setFieldValue, errors, touched }: FormikProps<AddLeaseFormValues> = useFormikContext();
  const { formatMessage } = useIntl();
  const { units, getUnit } = useUnits();
  const { getUnitInventoriesFor } = useUnitInventories();
  const { technics } = useTechnics();
  const { getDocumentCategoryByFileCategory } = useDocumentCategory();

  const unitsMenu = selectableUnits.reduce((acc, currentUnit) => {
    if (isNil(values.subUnits.find((subUnit) => subUnit.id === currentUnit.id)) && values.unit.id !== currentUnit.id) {
      acc.push({
        primary: currentUnit.name,
        secondary: currentUnit.building!.name,
        value: currentUnit.id,
        renderIcon: () => getUnitIcon(currentUnit.type as UnitType, { width: 24, height: 24 }),
      });
    }
    return acc;
    // eslint-disable-next-line no-undef
  }, [] as { primary: string; secondary: string; value: string; renderIcon: () => JSX.Element | undefined }[]);

  const initialUnit = units.find((u) => u.id === initialUnitId);
  // eslint-disable-next-line
  let initialValue: CustomMenuItemType | undefined = undefined;
  // eslint-disable-next-line no-undef
  let unitIcon: JSX.Element | undefined;
  if (!isNil(initialUnit)) {
    unitIcon = getUnitIcon(initialUnit.type as UnitType, { width: 24, height: 24 });
    initialValue = {
      primary: initialUnit.name,
      secondary: initialUnit.building!.name,
      value: initialUnit.id,
    };
  }

  return (
    <div
      style={{
        alignItems: 'baseline',
        display: 'flex',
        ...style,
      }}
    >
      <ComboBox
        label={formatMessage({
          id: 'lease.addLease.unit',
        })}
        data={unitsMenu}
        onChange={async (_e: unknown, value: CustomMenuItemType | null) => {
          const safeValue = isNil(value) ? null : (value as CustomMenuItemType).value;
          const unit = getUnit(safeValue);

          const newSubUnitInitialValues = await getSubUnitInitialValues(
            unit?.id,
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            unit?.building?.id!,
            unit?.type as UnitType,
            unit?.advertisedRentalPrice,
            unit?.advertisedMonthlyCharges,
            unit?.surface,
            values,
            technics,
            getUnitInventoriesFor,
            getDocumentCategoryByFileCategory
          );

          setFieldValue(fieldName, newSubUnitInitialValues);

          if (!isNil(onChange)) {
            onChange(newSubUnitInitialValues);
          }
        }}
        initialValue={initialValue}
        noOptionsText={formatMessage({ id: 'comboBox.noUnits' })}
        error={Boolean(errors.unit && errors.unit.id && touched.unit && touched.unit.id)}
        {...(!isNil(initialUnitId) ? { inputAdornment: unitIcon } : {})}
        {...rest}
      />
    </div>
  );
};

export default UnitSelectorWithLogoField;
