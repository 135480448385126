import React from 'react';
import { Typography } from '@material-ui/core';
import { getFlagImgComponent } from './CountrySelector/utils';
import { Colors } from '@rentguru/commons-utils';

interface TextDetailCountryProps {
  title: string;
  text?: string | null;
}
const TextDetailCountry: React.FC<TextDetailCountryProps> = ({ title, text }) => {
  return (
    <>
      <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>{title}</Typography>
      {getFlagImgComponent(text as string)}
    </>
  );
};

export default TextDetailCountry;
