import { useEffect } from 'react';
import Observable from 'zen-observable';
import { getLambdaAuthorizerTokenParam } from './utils';
import { generateClient } from 'aws-amplify/api';

const graphQLClient = generateClient();

const SUBSCRIPTION_REGEX = /subscription\s+(\w+)\s*\(([^)]*)\)\s*{([\s\S]*?)}/i;

const getSubscriptionNameFromQuery = (subscriptionQuery: string) => {
  const match = subscriptionQuery.match(SUBSCRIPTION_REGEX);

  if (!match) {
    return null;
  }
  return match[1][0].toLowerCase() + match[1].slice(1);
};

export const useSubscriptions = <C extends object, U extends object, D extends object>(
  onCreateSubscriptionQuery?: string,
  onUpdateSubscriptionQuery?: string,
  onDeleteSubscriptionQuery?: string,
  onCreateData?: (data: C) => void,
  onUpdateData?: (data: U) => void,
  onDeleteData?: (data: D) => void,
  onCreateDataError?: (error: Error) => void,
  onUpdateDataError?: (error: Error) => void,
  onDeleteDataError?: (error: Error) => void,
  lambdaAuthMode?: boolean
) => {
  if (!onCreateSubscriptionQuery && !onUpdateSubscriptionQuery && !onDeleteSubscriptionQuery) {
    throw new Error('useSubscriptions: No subscription queries provided');
  }

  useEffect(() => {
    // eslint-disable-next-line no-undef
    let onCreateSubscription: ZenObservable.Subscription;
    // eslint-disable-next-line no-undef
    let onUpdateSubscription: ZenObservable.Subscription;
    // eslint-disable-next-line no-undef
    let onDeleteSubscription: ZenObservable.Subscription;

    const launchSubscriptions = async () => {
      let authParams = {};
      if (lambdaAuthMode) {
        const lambdaAuthorizerAuthToken = await getLambdaAuthorizerTokenParam(true);
        authParams = {
          authMode: 'lambda',
          authToken: lambdaAuthorizerAuthToken!,
        };
      }
      if (onCreateSubscriptionQuery) {
        const createName = getSubscriptionNameFromQuery(onCreateSubscriptionQuery);
        onCreateSubscription = (
          graphQLClient.graphql({
            query: onCreateSubscriptionQuery,
            ...authParams,
          }) as unknown as Observable<{ data: C }>
        ).subscribe({
          next: (payload) => {
            if (payload.data[createName as keyof C] && onCreateData) {
              onCreateData(payload.data);
            }
          },
          error: (error) => {
            if (onCreateDataError) {
              onCreateDataError(error);
            }
          },
        });
      }
      if (onUpdateSubscriptionQuery) {
        const updateName = getSubscriptionNameFromQuery(onUpdateSubscriptionQuery);
        onUpdateSubscription = (
          graphQLClient.graphql({
            query: onUpdateSubscriptionQuery,
            ...authParams,
          }) as unknown as Observable<{ data: U }>
        ).subscribe({
          next: (payload) => {
            if (payload.data[updateName as keyof U] && onUpdateData) {
              onUpdateData(payload.data);
            }
          },
          error: (error) => {
            if (onUpdateDataError) {
              onUpdateDataError(error);
            }
          },
        });
      }
      if (onDeleteSubscriptionQuery) {
        const deleteName = getSubscriptionNameFromQuery(onDeleteSubscriptionQuery);
        onDeleteSubscription = (
          graphQLClient.graphql({
            query: onDeleteSubscriptionQuery,
            ...authParams,
          }) as unknown as Observable<{ data: D }>
        ).subscribe({
          next: (payload) => {
            if (payload.data[deleteName as keyof D] && onDeleteData) {
              onDeleteData(payload.data);
            }
          },
          error: (error) => {
            if (onDeleteDataError) {
              onDeleteDataError(error);
            }
          },
        });
      }
    };

    launchSubscriptions();

    return () => {
      if (onCreateSubscription) {
        onCreateSubscription.unsubscribe();
      }
      if (onUpdateSubscription) {
        onUpdateSubscription.unsubscribe();
      }
      if (onDeleteSubscription) {
        onDeleteSubscription.unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
