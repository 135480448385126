/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-shadow */
import { Divider, Typography, Grid, TextField, makeStyles } from '@material-ui/core';
import Dialog from 'src/components/ui/Dialog';
import isNil from 'lodash/isNil';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { classifyInventoryCheckedItems } from '../../../../hooks/LeaseInventoryContext';
import {
  InventoryCustomItem,
  InventoryItemType,
  LeaseInventoryCheckedItem,
  LeaseInventoryEncoding,
  Colors,
  MATERIALS,
} from '@rentguru/commons-utils';
import { v4 as uuidv4 } from 'uuid';
import { Form, Formik } from 'formik';
import { ActionButton , LoaderButton, ContentHeader } from '@up2rent/ui';
import toUpper from 'lodash/toUpper';
import CustomCheckBox from '../../../ui/CustomCheckBox';
import { Add } from '@material-ui/icons';
import { ExtendedLeaseInventoryEncoding } from '../../../../hooks/UnitInventoryContext';

const useStyles = makeStyles({
  addButton: {
    background: Colors.CLASSICAL_WHITE,
    color: Colors.DARK_SLATE_GREY,
    '&:hover': {
      background: Colors.PORCELAIN_GREY_2,
    },
  },
});

interface AddMaterialsDialogProps {
  open: boolean;
  encoding?: ExtendedLeaseInventoryEncoding;
  updateEncoding?: (
    updateFunction: (encoding: ExtendedLeaseInventoryEncoding) => ExtendedLeaseInventoryEncoding
  ) => void;
  customItems: InventoryCustomItem[];
  updateCustomItems: (updateFunction: (item: InventoryCustomItem[]) => InventoryCustomItem[]) => void;
  onClose: () => void;
}

interface FormikCheckedMaterials {
  checkedMaterials: CheckedMaterial[];
}

interface CheckedMaterial extends LeaseInventoryCheckedItem {
  checked: boolean;
}

const AddMaterialsDialog: React.FC<AddMaterialsDialogProps> = ({
  open,
  encoding,
  customItems,
  updateEncoding,
  updateCustomItems,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const [customMaterial, setCustomMaterial] = useState<string | null>(null);
  const [, , materials] =
    !isNil(encoding) && !isNil(encoding.checkedItems)
      ? classifyInventoryCheckedItems(encoding.checkedItems)
      : [[], [], []];
  const customSection = !isNil(customMaterial);
  const customMaterials = customItems.filter((ci) => ci.type === InventoryItemType.MATERIAL);
  // unchecked material by default
  const initialValues: CheckedMaterial[] = MATERIALS.map((MATERIAL) => {
    return {
      id: `NEW-${uuidv4()}`,
      value: MATERIAL,
      custom: false,
      leaseInventoryEncodingId: !isNil(encoding) ? encoding.id : '',
      readId: '',
      type: InventoryItemType.MATERIAL,
      checked: false,
      clientId: '',
    };
  });

  customMaterials.forEach((customMaterial) => {
    initialValues.push({
      id: `NEW-${uuidv4()}`,
      custom: true,
      leaseInventoryEncodingId: !isNil(encoding) ? encoding.id : '',
      customItem: customMaterial,
      readId: '',
      type: InventoryItemType.MATERIAL,
      checked: false,
      clientId: '',
    });
  });

  // Add the custom ones and replaces the ones by default if real values exist
  materials.forEach((material) => {
    const checkedMaterial = { ...material, checked: true };
    let initialValueIndex: number;
    if (material.custom && !isNil(material.customItem)) {
      initialValueIndex = initialValues.findIndex(
        (initVal) => !isNil(initVal.customItem) && initVal.customItem.id === material.customItem!.id
      );
    } else {
      initialValueIndex = initialValues.findIndex((initVal) => initVal.value === material.value);
    }
    if (initialValueIndex > -1) {
      initialValues[initialValueIndex] = checkedMaterial;
    }
  });

  const handleSubmit = (values: FormikCheckedMaterials) => {
    const newMaterials = values.checkedMaterials.filter((material) => material.checked);
    if (!isNil(updateEncoding)) {
      updateEncoding((encoding: LeaseInventoryEncoding) => {
        const previousCheckedItems = encoding.checkedItems!;
        return {
          ...encoding,
          checkedItems: [
            ...previousCheckedItems.filter((pci) => pci.type !== InventoryItemType.MATERIAL),
            ...newMaterials,
          ],
        };
      });
    }
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      scroll="body"
      PaperProps={{ style: { maxWidth: 'none', width: 400, borderRadius: 10 } }}
    >
      <Formik
        initialValues={{ checkedMaterials: initialValues } as FormikCheckedMaterials}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values, setFieldValue, status, isSubmitting }) => {
          return (
            <Form>
              <div
                style={{
                  display: 'flex',
                  justifyContent: customSection ? 'flex-start' : 'space-between',
                  alignItems: 'center',
                }}
              >
                <ContentHeader
                  title={
                    customSection
                      ? formatMessage({ id: 'leaseInventory.addMaterial.title' })
                      : formatMessage({ id: 'leaseInventory.room.structureDetails.materials' }, { value: 2 })
                  }
                  handleBack={customSection ? () => setCustomMaterial(null) : undefined}
                />
                {!customSection && (
                  <ActionButton
                    onClick={() => setCustomMaterial('')}
                    style={{
                      marginRight: 24,
                    }}
                    className={classes.addButton}
                  >
                    <Add />
                    {toUpper(formatMessage({ id: 'leaseInventory.addMaterial.customMaterial' }))}
                  </ActionButton>
                )}
              </div>
              <Divider />
              {!customSection && (
                <Grid container>
                  {values.checkedMaterials.map((checkedMaterial, index) => {
                    const updateMaterial = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                      const newMaterials = values.checkedMaterials.map((cm) => {
                        if (checkedMaterial.id === cm.id) cm.checked = checked;
                        return cm;
                      });
                      setFieldValue('checkedMaterials', newMaterials);
                    };
                    return (
                      <Grid
                        item
                        xs={6}
                        style={{ marginTop: 10, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                        key={`${checkedMaterial.id} ${index}`}
                      >
                        <CustomCheckBox
                          isChecked={checkedMaterial.checked}
                          onCheck={updateMaterial}
                          checkedColor={Colors.DODGER_BLUE}
                        />
                        <Typography style={{ fontSize: 14, fontWeight: 700 }}>
                          {checkedMaterial.custom
                            ? checkedMaterial.customItem!.value
                            : formatMessage({ id: `enums.leaseInventoryMaterials.${checkedMaterial.value}` })}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
              {customSection && (
                <div style={{ marginTop: 40, marginBottom: 40, marginLeft: 10, marginRight: 10 }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={customMaterial}
                    onChange={(event) => setCustomMaterial(event.target.value)}
                    label={formatMessage({ id: 'leaseInventory.addMaterial.materialName' })}
                  />
                </div>
              )}
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <div
                style={{
                  marginRight: 30,
                  marginBottom: 20,
                  display: 'flex',
                  float: 'right',
                  alignItems: 'center',
                }}
              >
                <ActionButton
                  onClick={() => {
                    if (!isNil(onClose)) {
                      onClose();
                    }
                  }}
                  style={{
                    background: 'none',
                    color: Colors.DARK_SLATE_GREY,
                    marginRight: 20,
                  }}
                >
                  {toUpper(
                    formatMessage({
                      id: 'bankAccount.addBankAccount.cancelLabel',
                    })
                  )}
                </ActionButton>
                {customSection ? (
                  <ActionButton
                    style={{ minWidth: 120, height: 50, fontSize: 16, fontWeight: 800 }}
                    onClick={() => {
                      if (!isNil(customMaterial)) {
                        const newMaterial = {
                          id: `NEW-${uuidv4()}`,
                          value: customMaterial,
                          type: InventoryItemType.MATERIAL,
                          clientId: '',
                          readId: '',
                        };
                        const newCheckedMaterial = {
                          id: `NEW-${uuidv4()}`,
                          custom: true,
                          leaseInventoryEncodingId: !isNil(encoding) ? encoding.id : '',
                          customItem: newMaterial,
                          readId: '',
                          type: InventoryItemType.MATERIAL,
                          checked: false,
                          clientId: '',
                        };
                        updateCustomItems((customItems) => {
                          return [...customItems, newMaterial];
                        });
                        setFieldValue('checkedMaterials', [...values.checkedMaterials, newCheckedMaterial]);
                      }
                      setCustomMaterial(null);
                    }}
                  >
                    {formatMessage({ id: 'add' })}
                  </ActionButton>
                ) : (
                  <LoaderButton loading={isSubmitting} success={status}>
                    {formatMessage({
                      id: 'bankAccount.addBankAccount.submitLabel',
                    })}
                  </LoaderButton>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default AddMaterialsDialog;
