import { FormControl, FormControlLabel, Grid, RadioGroup, Tooltip, Typography } from '@material-ui/core';
import { FormikProps, useFormikContext } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import StyledRadio from 'src/components/ui/StyledRadio';
import { AgencyRateRepartitionType, Colors } from '@rentguru/commons-utils';
import { AddEditAgencyRateValues } from '../AddEditAgencyRateForm';
import { ReactComponent as InfoSvg } from '../../../../../icons/info.svg';

const AgencyRateRepartitionTypeField = () => {
  const { values, setFieldValue }: FormikProps<AddEditAgencyRateValues> = useFormikContext();
  const { formatMessage } = useIntl();

  return (
    <>
      <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'start' }}>
        <Typography
          style={{
            fontSize: 14,
            fontWeight: 'bold',
            fontStyle: 'normal',
            letterSpacing: 0,
            color: Colors.CLASSICAL_BLACK,
          }}
        >
          {formatMessage({ id: 'agencyRates.detail.repartitionType' })}
        </Typography>
        <Tooltip title={formatMessage({ id: 'agencyRates.detail.repartitionTypeInfo' })} placement="bottom">
          <Grid>
            <InfoSvg style={{ fill: Colors.SILVER, paddingLeft: 10 }} />
          </Grid>
        </Tooltip>
      </div>
      <FormControl component="fieldset">
        <RadioGroup
          value={values.repartitionType}
          onChange={(_e, value) => {
            setFieldValue('repartitionType', value);
          }}
          row
        >
          {Object.keys(AgencyRateRepartitionType).map((element) => (
            <FormControlLabel
              value={element}
              key={element}
              control={<StyledRadio />}
              label={
                <Typography style={{ fontSize: 15, fontWeight: 500 }}>
                  {formatMessage({ id: `agencyRates.detail.${element}` })}
                </Typography>
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default AgencyRateRepartitionTypeField;
