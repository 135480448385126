import React, { SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import { Divider, Grid, Tooltip, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { Colors, isStringDateValid } from '@rentguru/commons-utils';
import { FileCategoryTypeForm as FileFieldsCategory } from 'src/hooks/TechnicsContext';
import { ReactComponent as Delete } from 'src/icons/delete.svg';
import { useLocale, dateLocaleMap, dateLocaleMapType } from 'src/i18n/IntlProviderWrapper';
import { CustomIconButton, CollapsingChevron } from '@up2rent/ui';
import { FileFormValues as ImpFormValues } from 'src/components/ui/Forms/FormField/FileByCategoryFields';

const textStyles = { fontSize: 14, color: Colors.SLATE_GREY };

interface PEBRowHeaderProps {
  isUnit: boolean;
  prefixFieldName: string;
  pebIndex: number;
  setOpen: (value: SetStateAction<boolean>) => void;
  isOpen: boolean;
}

const PEBRowHeader: React.FC<PEBRowHeaderProps> = ({ isUnit, prefixFieldName, pebIndex, setOpen, isOpen }) => {
  const { formatMessage } = useIntl();
  const { values, setFieldValue } = useFormikContext<ImpFormValues>();
  const { language } = useLocale();

  const formatedDateField = (fieldToCheck: string | null | undefined): string =>
    !fieldToCheck || !isStringDateValid(fieldToCheck)
      ? ''
      : format(new Date(fieldToCheck), 'dd/MM/yyyy', {
          locale: (dateLocaleMap as dateLocaleMapType)[language],
        });

  const pebEnergeticPerformance =
    (get(values, `${prefixFieldName}filesByCategories[${pebIndex}]`) as unknown as FileFieldsCategory | null)
      ?.PebEnergeticPerformance ?? '';

  const pebIssueDate = (
    get(values, `${prefixFieldName}filesByCategories[${pebIndex}]`) as unknown as FileFieldsCategory | null
  )?.PebIssueDate;

  const pebValidityDate = (
    get(values, `${prefixFieldName}filesByCategories[${pebIndex}]`) as unknown as FileFieldsCategory | null
  )?.PebValidityDate;

  return (
    <Grid container style={{ paddingTop: 13, paddingBottom: 13, display: 'flex', alignItems: 'center' }}>
      <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', marginLeft: 10 }}>
        <CollapsingChevron setOpen={setOpen} isOpen={isOpen} />
        <Typography variant="h6" style={{ fontSize: 14, fontWeight: 700, marginLeft: 10 }}>
          {formatMessage({ id: isUnit ? 'lease.addLease.unit' : 'rentalUnit.detail.general.building' })}
        </Typography>
      </Grid>

      <Grid item xs={2}>
        <Typography variant="subtitle1" style={textStyles}>
          {pebEnergeticPerformance}
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography variant="subtitle1" style={textStyles}>
          {formatedDateField(pebIssueDate)}
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography variant="subtitle1" style={textStyles}>
          {formatedDateField(pebValidityDate)}
        </Typography>
      </Grid>
      <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
        <Tooltip title={formatMessage({ id: 'discard' })}>
          <CustomIconButton
            onClick={() =>
              setFieldValue(
                `${prefixFieldName}filesByCategories`,
                (get(values, `${prefixFieldName}filesByCategories`, []) as FileFieldsCategory[]).filter(
                  (_s, idx) => pebIndex !== idx
                )
              )
            }
            size="small"
            Icon={Delete}
            iconStyle={{ fill: Colors.BLUE_GREY }}
          />
        </Tooltip>
      </Grid>
      {isUnit && <Divider style={{ marginBottom: 10 }} />}
    </Grid>
  );
};

export default PEBRowHeader;
