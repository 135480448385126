import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Route, useLocation, useRouteMatch, Switch } from 'react-router-dom';
import { Formik } from 'formik';
import isNil from 'lodash/isNil';
import { Typography, Grid, Toolbar, Divider, MenuList } from '@material-ui/core';
import WeekendIcon from '@material-ui/icons/Weekend';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { LeaseInventory, LeaseInventoryType, LeaseStatus, Colors } from '@rentguru/commons-utils';
import { ExtendedUnitInventory, useUnitInventories } from 'src/hooks/UnitInventoryContext';
import { useLeases, LeaseExtended } from 'src/hooks/LeasesContext';
import {
  calculateTotalPriceAndNumberOfIssues,
  flattenUnitInventoriesOnIssues,
  UnitInventoryFlattenOnIssues,
} from 'src/utils/leaseInventoryUtils';
import { ExitableLocation, RouteDestination } from 'src/components/Routes/Routes';
import MenuNotFound from 'src/components/Routes/MenuNotFound';
import { fromStatusToColor } from 'src/components/Leases/Details/LeaseDetail';
import GeneralDetails from 'src/components/LeaseInventory/GeneralDetails';
import LeaseInventoryActionBar from 'src/components/LeaseInventory/LeaseInventoryActionBar';
import { LEASES_DETAIL_TO } from 'src/components/Leases/Leases';
import DashboardLoader from 'src/components/Dashboard/DashboardLoader';
import { ColoredBox, CustomIconButton } from '@up2rent/ui';
import CustomizedMenuItem from 'src/components/ui/CustomizedMenuItem';
import { useStyles as useContentDetailHeaderStyles } from 'src/components/ui/ContentDetailHeader';
import FurnitureInventoryEncoding from './FurnitureInventoryEncoding/FurnitureInventoryEncoding';
import FurnitureInventorySummary from './FurnitureInventoryEncoding/FurnitureInventorySummary';

interface FurnitureInventoryProps {
  id: string;
  onClose?: () => void;
  unitId: string;
}
interface FurnitureInventoryRowProps {
  leaseInventory: LeaseInventory;
  lease: LeaseExtended;
  // eslint-disable-next-line react/no-unused-prop-types
  onClose?: () => void;
}

export interface FurnitureInventoryFormik {
  localUnitInventories: ExtendedUnitInventory[];
  initialUnitInventories: ExtendedUnitInventory[];
}

const TO_PREFIX = '';

const FurnitureInventoryHeader: React.FC<FurnitureInventoryRowProps> = ({ leaseInventory, lease }) => {
  const { formatMessage } = useIntl();
  const classes = useContentDetailHeaderStyles();
  if (isNil(lease)) return null;
  const statusText = formatMessage({ id: `enums.LeaseStatus.${leaseInventory.status}` });
  const statusColor = fromStatusToColor(leaseInventory.status as LeaseStatus);
  return (
    <Toolbar
      style={{
        display: 'block',
        backgroundColor: 'white',
        paddingTop: 30,
        paddingBottom: 10,
        borderRadius: '10px',
        paddingLeft: 30,
        paddingRight: 30,
        marginBottom: '20px',
      }}
    >
      <Grid container style={{ alignItems: 'center' }}>
        <Grid item xs={1}>
          <WeekendIcon style={{ fill: Colors.BLUE_GREY, transform: 'translateY(3px)' }} />
        </Grid>
        <Grid item xs={5}>
          <Typography variant="h6" color="inherit" className={classes.h6}>
            {lease.units!.map((u) => u.unit!.name).join(',')}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            style={{
              fontFamily: 'Mulish',
              fontSize: 14,
              textTransform: 'none',
              color: Colors.CHARCOAL_GREY,
              fontWeight: 600,
            }}
          >
            {leaseInventory.inventoryType === LeaseInventoryType.FURNITURES_ENTRANCE
              ? formatMessage({ id: 'furnitureInventory.entrance' })
              : formatMessage({ id: 'furnitureInventory.exit' })}
          </Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <ColoredBox boxColor={statusColor}>{statusText}</ColoredBox>
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: 15, marginTop: 15 }} />
      <LeaseInventoryActionBar leaseInventory={leaseInventory} lease={lease} />
    </Toolbar>
  );
};

const FurnitureInventoryDetail: React.FC<FurnitureInventoryProps> = ({ id, onClose, unitId }) => {
  const { formatMessage } = useIntl();
  const { getLeaseInventory, getLease, leasesLoading } = useLeases();
  const match = useRouteMatch<{ id: string; inventoryDetailName: string }>({
    path: [
      `${RouteDestination.PROPERTIES}/:id?/:detailName?/furniture-:unitInventoryId?/:inventoryDetailName?`,
      `${RouteDestination.LEASES}/${LEASES_DETAIL_TO}/:leaseId?/furniture-:unitInventoryId?/:inventoryDetailName?`,
    ],
  });
  const { state: exitParams } = useLocation<ExitableLocation>();
  const { buildCompleteInventoriesForUnitAndLeaseInventory, unitInventoriesLoading } = useUnitInventories();
  const [initialValues, setInitialValues] = useState<FurnitureInventoryFormik>({
    localUnitInventories: [],
    initialUnitInventories: [],
  });
  const [isFetching, setIsFetching] = useState<boolean>(true);

  const leaseInventory = getLeaseInventory(id);
  const lease = !isNil(leaseInventory) ? getLease(leaseInventory.leaseId) : undefined;
  const loading = leasesLoading && unitInventoriesLoading;

  useEffect(() => {
    let isMounted = true;
    const fetchAll = async () => {
      const unitInventoriesList = await buildCompleteInventoriesForUnitAndLeaseInventory(
        unitId,
        leaseInventory!.id,
        new Date(lease!.createdAt!),
        'furnitures'
      );
      if (isMounted) {
        setInitialValues({
          localUnitInventories: unitInventoriesList,
          initialUnitInventories: unitInventoriesList,
        });
        setIsFetching(false);
      }
    };

    if (!loading && !isNil(lease) && !isNil(leaseInventory)) fetchAll();

    return () => {
      isMounted = false;
    };
  }, [buildCompleteInventoriesForUnitAndLeaseInventory, leaseInventory, lease, unitId, loading]);

  if (isNil(match) || isNil(leaseInventory) || isNil(lease)) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <DashboardLoader style={{ width: 640, height: 80 }} />
      </div>
    );
  }

  const entrance = leaseInventory.inventoryType === LeaseInventoryType.FURNITURES_ENTRANCE;

  const navItems = [
    {
      to: `${TO_PREFIX}`,
      labelId: 'leaseInventory.editMenu.info',
      exact: true,
      component: GeneralDetails,
      editable: true,
    },
    {
      to: `${TO_PREFIX}inventory`,
      labelId: 'rentalUnit.detail.inventory.furnitures',
      component: FurnitureInventoryEncoding,
      exact: true,
    },
    ...(!entrance
      ? [
          {
            to: `${TO_PREFIX}summary`,
            labelId: 'summary',
            component: FurnitureInventorySummary,
            exact: true,
          },
        ]
      : []),
  ];

  let flattenedOnIssuesUnitInventories: UnitInventoryFlattenOnIssues[];
  let totalPenalty: number = 0;
  let numberOfIssues: number = 0;
  if (!isFetching) {
    flattenedOnIssuesUnitInventories = flattenUnitInventoriesOnIssues(initialValues.initialUnitInventories);
    const res = calculateTotalPriceAndNumberOfIssues(flattenedOnIssuesUnitInventories);
    totalPenalty = res.totalPenalty;
    numberOfIssues = res.numberOfIssues;
  }

  return (
    <Grid container style={{ flexGrow: 1, overflow: 'auto', height: '100%' }}>
      <Grid
        item
        style={{
          height: '100%',
          width: 240,
          overflow: 'visible',
          marginLeft: 15,
          marginRight: 15,
        }}
      >
        <CustomIconButton
          style={{ marginTop: 30, marginBottom: 70 }}
          aria-label="Menu"
          onClick={onClose}
          Icon={ArrowBack}
          iconStyle={{ fill: Colors.BLUEY }}
        />
        <Typography
          variant="subtitle1"
          style={{
            color: Colors.LIGHT_BLUE_GREY,
            fontSize: 10,
            letterSpacing: '1px',
            lineHeight: '12px',
            textTransform: 'uppercase',
            marginLeft: 15,
            marginRight: 15,
          }}
        >
          {formatMessage({ id: 'leaseInventory.details' })}
        </Typography>
        <MenuList>
          {navItems.map((m) => {
            const to = !isNil(match.params.inventoryDetailName)
              ? match.url.replace(match.params.inventoryDetailName, m.to!)
              : match.url + (match.url.charAt(match.url.length - 1) === '/' ? '' : '/') + m.to;
            const selected =
              (!isNil(match.params.inventoryDetailName) && match.params.inventoryDetailName === m.to) ||
              (m.to === TO_PREFIX && isNil(match.params.inventoryDetailName));

            return (
              <CustomizedMenuItem key={m.to} to={to} selected={selected} historyProps={{ state: exitParams }}>
                {formatMessage({ id: m.labelId })}
              </CustomizedMenuItem>
            );
          })}
        </MenuList>
      </Grid>
      <Grid
        style={{
          flexGrow: 1,
          width: 'calc(100% - 280px)',
        }}
      >
        <FurnitureInventoryHeader leaseInventory={leaseInventory} lease={lease} />
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: '10px',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 24,
            paddingBottom: 24,
            minHeight: 'calc(100% - 168px)',
          }}
        >
          {isFetching && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <DashboardLoader style={{ width: 640, height: 80 }} />
            </div>
          )}
          {!isFetching && (
            <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={() => {}}>
              {() => {
                return (
                  <Switch>
                    {navItems.map((m) => {
                      const path = match.path.replace(':detailName?', m.to!);
                      const Comp = m.component;
                      return (
                        <Route
                          path={path}
                          key={m.to}
                          exact={m.exact}
                          render={() => {
                            return (
                              <Comp
                                leaseInventory={leaseInventory}
                                lease={lease}
                                readOnly={true}
                                flattenedOnIssuesUnitInventories={flattenedOnIssuesUnitInventories}
                                totalPenalty={totalPenalty}
                                numberOfIssues={numberOfIssues}
                              />
                            );
                          }}
                        />
                      );
                    })}

                    <Route
                      path="*"
                      render={() => {
                        return <MenuNotFound />;
                      }}
                    />
                  </Switch>
                );
              }}
            </Formik>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
export default FurnitureInventoryDetail;
