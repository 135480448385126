import { Divider, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { Colors, UnitInventory } from '@rentguru/commons-utils';
import { FormikProps, useFormikContext } from 'formik';
import { isEmpty, isNil } from 'lodash';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import CustomCheckBox from 'src/components/ui/CustomCheckBox';
import { CustomSimpleDialog } from '@up2rent/ui';
import { UnitInventoriesFormValues } from 'src/components/ui/Forms/FormField/UnitStructureFields';
import { ReactComponent as Bulb } from 'src/icons/bulb.svg';

interface SelectRoomsToBeDeletedDialogProps {
  dialogUnitInventoriesOpen: boolean;
  listofUnitsToBePossiblyDeleted: UnitInventory[];
  unitInventoryRoomType: string | undefined;
  inside?: boolean;
  handleCloseDialog: () => void;
  setQuantity: (amount: number) => void;
}

const SelectRoomsToBeDeletedDialog: React.FC<SelectRoomsToBeDeletedDialogProps> = ({
  dialogUnitInventoriesOpen,
  listofUnitsToBePossiblyDeleted,
  unitInventoryRoomType,
  inside = true,
  handleCloseDialog,
  setQuantity,
}) => {
  const { formatMessage } = useIntl();
  const { values, setFieldValue }: FormikProps<UnitInventoriesFormValues> = useFormikContext();

  const initialSelectionOfUnits: {
    selected: boolean;
    unitInventory: UnitInventory;
  }[] = listofUnitsToBePossiblyDeleted.map((unit) => {
    return {
      selected: false,
      unitInventory: unit,
    };
  });
  const [selectedInventoryToBeDeleted, setselectedInventoryToBeDeleted] =
    useState<{ selected: boolean; unitInventory: UnitInventory }[]>(initialSelectionOfUnits);

  const handleDeletion = (inventoriesToDelete: { selected: boolean; unitInventory: UnitInventory }[]) => {
    const unitStructure = values.unitStructures.filter((structure) => structure.roomType === unitInventoryRoomType)[0];
    let unitInventories = unitStructure.unitInventories;
    if (!isEmpty(inventoriesToDelete)) {
      for (const inventory of inventoriesToDelete) {
        unitInventories = unitInventories.filter((unitInventory) => unitInventory.id !== inventory.unitInventory.id);
      }
    }

    setFieldValue('unitStructures', [
      ...values.unitStructures.filter((structure) => structure.roomType !== unitInventoryRoomType),
      {
        roomType: unitInventoryRoomType,
        unitInventories,
      },
    ]);
    setQuantity(unitInventories.filter((inventory) => inventory.inside === inside).length);
  };

  return (
    <CustomSimpleDialog
      open={dialogUnitInventoriesOpen}
      onClose={handleCloseDialog}
      onActionButtonClick={() => {
        handleDeletion(selectedInventoryToBeDeleted.filter((selected) => selected.selected));
        handleCloseDialog();
      }}
      actionButtonLabel={formatMessage({ id: 'appButton.done' })}
      hideSecondaryButton
      title={formatMessage({ id: 'rentalUnit.detail.inside.rooms.rooms' })}
      dividerBelowTitle
      formatMessage={formatMessage}
    >
      <Grid style={{ marginTop: 20 }}>
        {selectedInventoryToBeDeleted.map((potientiallySelectedUnit, index) => {
          return (
            <Grid key={potientiallySelectedUnit.unitInventory.id} item xs={12}>
              <FormControlLabel
                control={
                  <CustomCheckBox
                    secondary={true}
                    isChecked={selectedInventoryToBeDeleted[index].selected}
                    onCheck={() => {
                      selectedInventoryToBeDeleted[index].selected = !selectedInventoryToBeDeleted[index].selected;
                      setselectedInventoryToBeDeleted([...selectedInventoryToBeDeleted]);
                    }}
                    name={
                      !isNil(potientiallySelectedUnit.unitInventory.roomName)
                        ? potientiallySelectedUnit.unitInventory.roomName
                        : potientiallySelectedUnit.unitInventory.roomType
                    }
                  />
                }
                label={
                  !isNil(potientiallySelectedUnit.unitInventory.roomName)
                    ? potientiallySelectedUnit.unitInventory.roomName
                    : potientiallySelectedUnit.unitInventory.roomType
                }
              />
            </Grid>
          );
        })}
      </Grid>
      <Divider style={{ margin: '10px 0', padding: 0 }} />
      <Grid
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 20,
          marginBottom: 20,
          backgroundColor: Colors.PORCELAIN_GREY_1,
          borderRadius: 6,
        }}
      >
        <Bulb style={{ fill: Colors.BLUE_GREY, marginRight: 30 }} />
        <Typography
          variant="subtitle1"
          style={{ color: Colors.SLATE_GREY, fontSize: 14, width: '100%', flexShrink: 1 }}
        >
          {formatMessage({ id: 'rentalUnit.detail.inside.rooms.changesApplyAlert' })}
        </Typography>
      </Grid>
    </CustomSimpleDialog>
  );
};

export default SelectRoomsToBeDeletedDialog;
