import {
  CommunicationSettingsProfile,
  CommunicationSettingsProfileReferent,
  CommunicationType,
  getCommunicationSettings,
} from '@rentguru/commons-utils';
import { Dispatch, SetStateAction } from 'react';
import OwnerCommunicationSettingsForm from '../CommunicationSettingsProfileForm/OwnerCommunicationSettingsProfileForm';
import {
  OwnerCommunicationSettingsFormValues,
  OwnerSettableCommunicationType,
} from '../CommunicationSettingsProfileUtils/OwnerCommunicationSettingsUtils';
import CommunicationSettingsProfileDetails from './CommunicationSettingsProfileDetails';

type OwnerCommunicationsSettingsProfileDetailsProps = {
  ownerCommunicationSettingsProfile: CommunicationSettingsProfile;
  isLastProfile: boolean;
  setDeleteProfileDialog: Dispatch<
    SetStateAction<{
      dialogOpen: boolean;
      idToDelete?: string;
      referent?: CommunicationSettingsProfileReferent;
    }>
  >;
};

const OwnerCommunicationsSettingsProfileDetails: React.FC<OwnerCommunicationsSettingsProfileDetailsProps> = ({
  ownerCommunicationSettingsProfile,
  isLastProfile,
  setDeleteProfileDialog,
}) => {
  const ownerStatementCurrentSettings = getCommunicationSettings(
    CommunicationType.OWNER_STATEMENT,
    ownerCommunicationSettingsProfile
  );

  const initialValues: OwnerCommunicationSettingsFormValues = {
    defaultName: ownerCommunicationSettingsProfile.defaultName!,
    [OwnerSettableCommunicationType.OWNER_STATEMENT]: {
      sendingRule: ownerStatementCurrentSettings.sendingRule,
      recipients: ownerStatementCurrentSettings.recipients,
      recipientsInBcc: ownerStatementCurrentSettings.recipientsInBcc,
      recipientsInCopy: ownerStatementCurrentSettings.recipientsInCopy,
      channel: ownerStatementCurrentSettings.channel,
    },
  };

  return (
    <CommunicationSettingsProfileDetails
      initialValues={initialValues}
      CustomForm={OwnerCommunicationSettingsForm}
      profile={ownerCommunicationSettingsProfile}
      isLastProfile={isLastProfile}
      setDeleteProfileDialog={setDeleteProfileDialog}
    />
  );
};

export default OwnerCommunicationsSettingsProfileDetails;
