import { isNil } from 'lodash';
import React from 'react';
import { ExtendedUnitInventory, ExtendedUnitInventoryStructure } from '../../../../hooks/UnitInventoryContext';
import { LeaseInventory, UnitInventoryStructureType } from '@rentguru/commons-utils';
import { AddCheckedItemDialogBundle, FilePreviewDialogBundle } from '../InventoryEncodings';
import InventoryStructureEncoding from '../InventoryStructureEncoding';

interface GeneralTabProps {
  leaseInventory: LeaseInventory;
  completeUnitInventory: ExtendedUnitInventory;
  unitInventoryIndex: number;
  encodeDefects: (defectBundle: AddCheckedItemDialogBundle) => void;
  previewFileFunction: (bundle: FilePreviewDialogBundle) => void;
  readOnly: boolean;
}

const GeneralTab: React.FC<GeneralTabProps> = ({
  leaseInventory,
  completeUnitInventory,
  unitInventoryIndex,
  encodeDefects,
  previewFileFunction,
  readOnly,
}) => {
  const generalStructures = completeUnitInventory.structures.reduce(
    (acc: { struct: ExtendedUnitInventoryStructure; index: number }[], struct, index) => {
      if (struct.type === UnitInventoryStructureType.GENERAL) {
        acc.push({ index, struct });
      }
      return acc;
    },
    []
  );
  const generalStructure = generalStructures[0];
  if (isNil(generalStructure)) return null;
  return (
    <InventoryStructureEncoding
      completeUnitInventory={completeUnitInventory}
      leaseInventory={leaseInventory}
      structureFieldName={`localUnitInventories[${unitInventoryIndex}].structures[${generalStructure.index}]`}
      inventoryStructure={generalStructure.struct}
      previewFileFunction={previewFileFunction}
      encodeDefects={encodeDefects}
      readOnly={readOnly}
    />
  );
};

export default GeneralTab;
