import React from 'react';
import EndLeaseIndemnityField, { IndemnityFieldProps } from './EndLeaseIndemnityField';
import { LeaseActionEndInitiator, LeaseType, Colors, MessagesEN } from '@rentguru/commons-utils';
import { EndLeaseFormValues, iconnedRemarkMargins, useEndLeaseStyles } from './EndLeaseForm';
import { useFormikContext } from 'formik';
import { differenceInMonths, isSameDay } from 'date-fns';
import { Collapse, FormControlLabel, Grid, Tooltip, Typography } from '@material-ui/core';
import EndLeaseNoticePeriodField from './EndLeaseNoticePeriodField';
import CustomCheckBox from 'src/components/ui/CustomCheckBox';
import { useIntl } from 'react-intl';
import EndLeaseCommentToTenantField from './EndLeaseCommentToTenantField';
import { ReactComponent as InfoSvg } from '../../../../icons/info.svg';
import EndLeaseFileField from './EndLeaseFileField';
import IconnedRemark from 'src/components/ui/IconnedRemark';
import FormAlertBox from 'src/components/ui/FormAlertBox';
import { isCommercialEndNotInNormalPeriod, isResidentialLease, isShortTermsLease } from './utils';

const EndLeaseTenantFields = ({
  leaseType,
  leaseStartDate,
  leaseEndDate,
  region,
  ...props
}: IndemnityFieldProps & { leaseStartDate: Date; leaseEndDate: Date }) => {
  const { formatMessage } = useIntl();
  const { values, setFieldValue } = useFormikContext<EndLeaseFormValues>();
  const classes = useEndLeaseStyles();

  const newEndDate = new Date(values.endDate);
  const isEndSameDay = isSameDay(leaseEndDate, newEndDate);
  const isCommercial = leaseType === LeaseType.COMMERCIAL;
  const isShortTerms = isShortTermsLease(leaseType);
  const isResidential = isResidentialLease(leaseType);
  const isStudent = leaseType === LeaseType.STUDENT;
  const shouldShowNoticePeriod = isCommercial || (isShortTerms && !isEndSameDay);
  const shouldShowCompensation =
    !isEndSameDay && (isResidential || isShortTerms || isCommercial || (isStudent && !values.studentValidEndReason));
  const shouldShowCommercialWarning = isCommercialEndNotInNormalPeriod(leaseType, leaseStartDate, newEndDate);
  const shouldShowBrusselsShortTermsWarning =
    isShortTerms &&
    region === MessagesEN.enums.AddressRegion.Brussels &&
    differenceInMonths(newEndDate, leaseStartDate) < 6;

  return (
    <>
      <Collapse in={shouldShowNoticePeriod}>
        <EndLeaseNoticePeriodField leaseType={leaseType} initiator={values.initiator as LeaseActionEndInitiator} />
      </Collapse>
      {leaseType === LeaseType.STUDENT && (
        <>
          <Typography
            className={classes.informationFillInTitle}
            style={{
              marginTop: 20,
            }}
          >
            {formatMessage({ id: `lease.endExtendLease.reason` })}
          </Typography>

          <Grid>
            <FormControlLabel
              control={
                <CustomCheckBox
                  onCheck={(event) => {
                    setFieldValue(`studentValidEndReason`, event.target.checked);
                  }}
                  isChecked={values.studentValidEndReason}
                  secondary
                />
              }
              label={
                <Typography style={{ fontSize: 15, fontWeight: 500 }}>
                  {formatMessage({ id: 'lease.endExtendLease.warningAcceptableEndingReason' })}
                </Typography>
              }
            />
          </Grid>
        </>
      )}
      <EndLeaseCommentToTenantField />
      <Collapse in={shouldShowCompensation}>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
          <Typography className={classes.informationFillInTitle}>
            {formatMessage({ id: `lease.endExtendLease.compensation` })}
          </Typography>
          <Tooltip title={formatMessage({ id: 'lease.endExtendLease.compensationInfo' })} placement="right">
            <Grid>
              <InfoSvg style={{ marginLeft: 10, fill: Colors.SILVER }} />
            </Grid>
          </Tooltip>
        </div>

        <EndLeaseIndemnityField {...props} leaseType={leaseType} region={region} />
      </Collapse>
      <EndLeaseFileField />
      {shouldShowCommercialWarning && (
        <div className={classes.alertBoxDiv}>
          <FormAlertBox
            removeLeftMargin
            messagesGridStyle={{ marginRight: 30, fontSize: 14 }}
            width={550}
            message1={formatMessage({ id: 'lease.endExtendLease.warningCommercial' })}
          />
        </div>
      )}
      {shouldShowBrusselsShortTermsWarning && (
        <div className={classes.alertBoxDiv}>
          <FormAlertBox
            removeLeftMargin
            messagesGridStyle={{ marginRight: 30, fontSize: 14 }}
            width={550}
            message1={formatMessage({ id: 'lease.endExtendLease.warningBrusselsShortTermsEnd' })}
          />
        </div>
      )}

      <IconnedRemark
        Icon={InfoSvg}
        noFixWidth
        style={iconnedRemarkMargins}
        message={
          <Typography className={classes.infoMessageText}>
            {formatMessage({ id: 'settings.addVariousOperation.confirmationEmail' })}
          </Typography>
        }
      />
    </>
  );
};

export default EndLeaseTenantFields;
