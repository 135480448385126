import React from 'react';
import { useIntl } from 'react-intl';
import { IconButton, Typography } from '@material-ui/core';
import NotificationBox from './NotificationBox';
import { useTickets } from '../../../hooks/TicketsContext';
import { compareAsc } from 'date-fns';
import { Ticket, TicketStatus, Colors, TICKET_TO_DO_BOX_KEY } from '@rentguru/commons-utils';
import { Link } from 'react-router-dom';
import { ReactComponent as ExternalIcon } from '../../../icons/external.svg';
import SlicedContent from './SlicedContent';
import TicketsToOthersRow from '../../Tickets/TicketsToOthersRow';
import TicketsHeader from './BoxCustomHeaders/TicketsHeader';
import isNil from 'lodash/isNil';
import TodosLoading from './TodosLoading';
import { RouteDestination } from 'src/components/Routes/Routes';
import { stopPropagation } from 'src/components/Dashboard/Dashboard';

export const ROW_HEIGHT = 74;

const ticketSortFunction = (ticket1: Ticket, ticket2: Ticket): number => {
  if (ticket1.status !== ticket2.status) {
    return ticket1.status === TicketStatus.URGENT ? -1 : 1;
  }
  if (!isNil(ticket1.assignedTo) && isNil(ticket2.assignedTo)) return 1;
  if (isNil(ticket1.assignedTo) && !isNil(ticket2.assignedTo)) return -1;

  if (isNil(ticket1.plannedAt)) return 1;
  if (isNil(ticket2.plannedAt)) return -1;
  return compareAsc(new Date(ticket1.plannedAt), new Date(ticket2.plannedAt));
};

const DashboardTickets: React.FC = () => {
  const { formatMessage } = useIntl();
  const { ticketsAssignedToMe, ticketsAssignedToOthers, ticketsUnassigned, ticketsLoading } = useTickets();

  const ticketsToDisplayArray = [...ticketsAssignedToMe, ...ticketsUnassigned, ...ticketsAssignedToOthers].sort(
    ticketSortFunction
  );
  if (ticketsLoading) {
    return (
      <>
        <TodosLoading />
      </>
    );
  }

  const titleComponent = (
    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
      <Typography
        style={{
          flex: 1,
          textAlign: 'left',
          color: Colors.CLASSICAL_BLACK,
          fontFamily: 'Mulish',
          fontSize: '16px',
          fontWeight: 'bold',
          marginLeft: 20,
        }}
      >
        {`${formatMessage({ id: 'dashboard.tickets' })} (${ticketsToDisplayArray.length})`}
      </Typography>
      <IconButton
        size="small"
        onMouseDown={stopPropagation}
        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => e.stopPropagation()}
        component={Link}
        to={RouteDestination.TICKETS}
        style={{ marginLeft: 10 }}
      >
        <ExternalIcon style={{ fill: Colors.DODGER_BLUE }} />
      </IconButton>
    </div>
  );

  const ticketsToDisplay = ticketsToDisplayArray.map((ticket) => {
    return <TicketsToOthersRow key={ticket.id} {...ticket} />;
  });
  return (
    <NotificationBox boxKey={TICKET_TO_DO_BOX_KEY} titleComponent={titleComponent}>
      <SlicedContent
        data={ticketsToDisplay}
        header={<TicketsHeader />}
        limit={5}
        rowHeight={ROW_HEIGHT}
        boxKey={TICKET_TO_DO_BOX_KEY}
      />
    </NotificationBox>
  );
};

export default DashboardTickets;
