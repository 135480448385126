import isNil from 'lodash/isNil';
import React, { useCallback, useRef, useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useIntl } from 'react-intl';
import { blobToFile } from 'src/hooks/FilesContext';
import { CustomSimpleDialog } from '@up2rent/ui';
import { Grid } from '@material-ui/core';

interface CropFileDialogProps {
  fileToCrop: File;
  open: boolean;
  onClose: () => void;
  afterSubmit: (file: File) => void;
  crop?: Crop;
  locked?: boolean;
}
const CropFileDialog: React.FC<CropFileDialogProps> = ({
  fileToCrop,
  open,
  onClose,
  afterSubmit,
  crop: cropProps = {
    unit: '%',
    width: 30,
    aspect: 16 / 9,
  },
  locked = false,
}) => {
  const { formatMessage } = useIntl();
  const [src] = useState(URL.createObjectURL(fileToCrop));
  const [crop, setCrop] = useState<Crop>(cropProps);
  const [croppedFile, setCroppedFile] = useState<File | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const handleSubmit = () => {
    if (croppedFile) {
      afterSubmit(croppedFile);
    }
    onClose();
  };

  const onLoad = useCallback((img: HTMLImageElement) => {
    imgRef.current = img;
  }, []);

  function getCroppedImg(image: HTMLImageElement, currentCrop: Crop) {
    const cropWidth = Math.min(image.naturalWidth, currentCrop.width!);
    const cropHeight = Math.min(image.naturalHeight, currentCrop.height!);
    const cropX = Math.max(currentCrop.x!, 0);
    const cropY = Math.max(currentCrop.y!, 0);
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const canvas = document.createElement('canvas');
    canvas.width = cropWidth;
    canvas.height = cropHeight;
    const ctx = canvas.getContext('2d');
    if (ctx)
      ctx.drawImage(
        image,
        cropX * scaleX,
        cropY * scaleY,
        cropWidth * scaleX,
        cropHeight * scaleY,
        0,
        0,
        cropWidth,
        cropHeight
      );

    // As Base64 string
    canvas.toBlob((blob) => {
      if (blob) {
        const file = blobToFile(blob, fileToCrop.name);
        setCroppedFile(file);
      }
    });
  }

  return (
    <CustomSimpleDialog
      open={open}
      onClose={onClose}
      onActionButtonClick={handleSubmit}
      actionButtonLabel={formatMessage({ id: 'save' })}
      actionButtonDisabled={isNil(croppedFile)}
      dividerBelowTitle
      title={formatMessage({ id: 'settings.generalSection.cropLogo' })}
      formatMessage={formatMessage}
    >
      <Grid style={{ marginTop: 10 }}>
        <ReactCrop
          src={src}
          crop={crop}
          onImageLoaded={onLoad}
          onChange={(c) => {
            setCrop(c);
          }}
          onComplete={(currentCrop: Crop) => {
            if (imgRef.current) getCroppedImg(imgRef.current, currentCrop);
          }}
          locked={locked}
          style={{ width: '100%' }}
        />
      </Grid>
    </CustomSimpleDialog>
  );
};

export default CropFileDialog;
