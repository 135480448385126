import { Typography } from '@material-ui/core';
import { Colors, isNilOrEmpty } from '@rentguru/commons-utils';
import { useState } from 'react';
import { type IntlFormatters } from 'react-intl';
import { isNil } from 'lodash';
import { CustomSimpleDialog } from './CustomSimpleDialog';

interface ConfirmDialogProps {
  title?: string;
  mainText: string;
  warningText?: string;
  confirmText: string;
  cancelText?: string;
  confirmAction: () => Promise<void> | void;
  cancelAction: () => void;
  open: boolean;
  autofocus?: boolean;
  formatMessage: IntlFormatters['formatMessage'];
}
export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  mainText,
  warningText,
  confirmText,
  cancelText,
  confirmAction,
  cancelAction,
  open,
  title,
  autofocus = true,
  formatMessage,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const cancelButtonText = cancelText ?? formatMessage({ id: 'cancel' });

  return (
    <CustomSimpleDialog
      open={open}
      onClose={cancelAction}
      onActionButtonClick={async () => {
        setLoading(true);
        await confirmAction();
        setLoading(false);
      }}
      actionButtonLabel={confirmText}
      actionButtonDisabled={loading}
      actionButtonAutofocus={autofocus}
      actionButtonLoading={loading}
      secondaryButtonLabel={cancelButtonText}
      title={title ?? formatMessage({ id: 'warning' })}
      dividerBelowTitle={!isNil(title)}
      formatMessage={formatMessage}
    >
      <Typography id="alert-dialog-description" style={{ fontSize: 16, marginTop: 16 }}>
        {mainText}
      </Typography>
      {!isNilOrEmpty(warningText) && <Typography style={{ color: Colors.RED_ORANGE }}>{warningText}</Typography>}
    </CustomSimpleDialog>
  );
};
