/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-redeclare */
import {
  CreateTechnicInput as CreateInput,
  CreateTechnicMutationVariables as CreateMutationVariables,
  DeleteTechnicMutationVariables as DeleteMutationVariables,
  DocumentCategoryWithoutReadIdAndClientId,
  EntityType,
  FileCategory,
  File as FileModel,
  Model,
  NewFile,
  OnCreateTechnicSubscription,
  OnDeleteTechnicSubscription,
  OnUpdateTechnicSubscription,
  RepartitionKey,
  S3Object,
  Technic,
  TechnicType,
  UtilityHistoryDTO,
  cleanInputCreate,
  cleanInputUpdate,
  getReadId,
  getTableClientId,
  isNilOrEmpty,
  onCreateTechnic,
  onDeleteTechnic,
  onUpdateTechnic,
  resolveOneToOne,
} from '@rentguru/commons-utils';
import {
  cleanMixedDatastoreAndAPISubscription,
  deleteEntityWithFetchBefore,
  getFilterFieldNameForIndex,
  list,
  mutation,
  recordWasUpdated,
  useSubscriptions,
} from '@up2rent/fetch-utils';
import { isError, isNil } from 'lodash';
import React, { useContext, useEffect, useMemo } from 'react';
import { ContextLoaderAction, ContextLoaderStore, useContextLoader } from './ContextLoader';
import { fetchFilesAndGetS3ObjectUrls, useFiles } from './FilesContext';
import { useNotifications } from './NotificationsContext';
import { fetchTechnicRepartitionKeys, useRepartitionKeys } from './RepartitionKeyContext';
import { useUser } from './UserContext';
import { usePermissions } from './utils/PermissionsContext';
import {
  createTechnic as createMutation,
  deleteTechnic as deleteMutation,
  getTechnic as getQuery,
  syncTechnics,
  updateTechnic as updateMutation,
} from './utils/cleanedQueries';

const ENTITY_MODEL_NAME: Model = 'Technic';

export type ActionTechnic =
  | {
      type: 'SHOULD_FETCH_TECHNIC' | 'IS_FETCHING_TECHNIC';
    }
  | {
      type: 'ADD_TECHNIC';
      payload: { technic: Technic };
    }
  | {
      type: 'FETCHED_TECHNIC';
      payload: { technics: Technic[] };
    }
  | {
      type: 'UPDATE_TECHNIC';
      payload: { technic: Technic };
    }
  | {
      type: 'DELETE_TECHNIC';
      payload: { id: string };
    };

export interface FileCategoryTypeForm
  extends Omit<Technic, 'id' | 'type' | 'clientId' | 'files' | 'readId' | 'communicationSettingsProfileId'> {
  category: DocumentCategoryWithoutReadIdAndClientId;
  ignore?: boolean;
  files: (S3Object | NewFile)[];
  id?: string;
}

export interface ClassifiedTechnics {
  utilityProviders: Technic[];
  PEBs: Technic[];
  detectors: Technic[];
  heatings: Technic[];
  chimneys: Technic[];
  fuelTanks: Technic[];
}

export const fromFileCategoryToTechnic = (category: FileCategory) => {
  if (category === FileCategory.HYDROCARBONTANK_CERTIFICATE) return TechnicType.FUELTANK;
  if (category === FileCategory.PEB) return TechnicType.PEB;
  if (category === FileCategory.HEATING) return TechnicType.HEATING;
  if (category === FileCategory.UTILITY) return TechnicType.UTILITY_PROVIDER;
  if (category === FileCategory.CHIMNEY) return TechnicType.CHIMNEY;
  if (category === FileCategory.DETECTOR) return TechnicType.DETECTOR;
};

export const fromTechnicTypeToFileCategory = (type: TechnicType) => {
  if (type === TechnicType.FUELTANK) return FileCategory.HYDROCARBONTANK_CERTIFICATE;
  if (type === TechnicType.PEB) return FileCategory.PEB;
  if (type === TechnicType.HEATING) return FileCategory.HEATING;
  if (type === TechnicType.UTILITY_PROVIDER) return FileCategory.UTILITY;
  if (type === TechnicType.CHIMNEY) return FileCategory.CHIMNEY;
  if (type === TechnicType.DETECTOR) return FileCategory.DETECTOR;
};

export const attachFilesToTechnic = async (technics: Technic[]) => {
  const filesPromises = technics.map((technic) => fetchFilesAndGetS3ObjectUrls(EntityType.TECHNIC, technic.id));
  const filesResults = await Promise.all(filesPromises);

  const technicsWithFiles = technics.map((technic) => {
    const maintenanceFiles = filesResults.find(
      (files) =>
        !isError(files) &&
        files.some((file) => file.foreignKey === technic.id && file.foreignTableName === EntityType.TECHNIC)
    );
    if (maintenanceFiles) {
      return { ...technic, files: maintenanceFiles as S3Object[] };
    }

    return technic;
  });

  return technicsWithFiles;
};

export const groupFilesAndTechnicsByCategory = (
  files: S3Object[][],
  technics: (Technic | undefined)[]
): FileCategoryTypeForm[] => {
  return files.reduce((acc: FileCategoryTypeForm[], files: S3Object[], i: number) => {
    const filesTechnic = !isNil(technics[i]) ? technics[i]! : {};
    for (const file of files) {
      const index = acc.findIndex((filesWithCategory) => filesWithCategory.category === file.category);
      if (index > -1) {
        acc[index].files.push(file);
        acc[index] = { ...filesTechnic, ...acc[index] };
      } else {
        acc.push({ ...filesTechnic, category: file.category!, files: [file] });
      }
    }
    return acc;
  }, []);
};

export const classifyTechnics = (technics: Technic[]): ClassifiedTechnics => {
  return technics.reduce(
    (acc: ClassifiedTechnics, technic: Technic) => {
      if (technic.type === TechnicType.UTILITY_PROVIDER) {
        acc.utilityProviders.push(technic);
      } else if (technic.type === TechnicType.PEB) {
        acc.PEBs.push(technic);
      } else if (technic.type === TechnicType.DETECTOR) {
        acc.detectors.push(technic);
      } else if (technic.type === TechnicType.HEATING) {
        acc.heatings.push(technic);
      } else if (technic.type === TechnicType.CHIMNEY) {
        acc.chimneys.push(technic);
      } else if (technic.type === TechnicType.FUELTANK) {
        acc.fuelTanks.push(technic);
      }
      return acc;
    },
    { utilityProviders: [], PEBs: [], detectors: [], heatings: [], chimneys: [], fuelTanks: [] }
  );
};

const cleanTechnicInput = (input: Partial<Technic>) => {
  const {
    contact: _contact,
    utilityDistributor: _utilityDistributor,
    building: _building,
    unit: _unit,
    lease: _lease,
    files: _files,
    utilityHistory,
    ...cleanedObject
  } = input;

  return {
    ...cleanedObject,
    utilityHistory: (utilityHistory as UtilityHistoryDTO[] | null | undefined)?.map(
      ({ __typename, ...history }) => history
    ),
  };
};

export interface TechnicsContext {
  technics: Technic[];
  technicsWithoutLeasesLink: Technic[]; // Technics filtered to remove all linked technic to leaseId
  getTechnic: (id: string) => Technic | undefined;
  getTechnicsFor: (unitId: string) => Technic[];
  createTechnic: (
    input: Omit<Technic, 'id' | 'clientId' | 'readId'> & Partial<Pick<Technic, 'id'>>
  ) => Promise<Technic>;
  updateTechnic: (original: Technic, updates: Partial<Technic>) => Promise<Technic>;
  deleteTechnic: (id: string) => Promise<Technic>;
  deepDeleteTechnic: (technic: Technic, alreadyNetworkData?: boolean) => Promise<void>;
  technicsLoading: boolean | undefined;
  technicsError: string | undefined;
  setFetchTechnic: () => void;
}

export const technicReducerDelegation = (
  state: ContextLoaderStore,
  action: ContextLoaderAction
): ContextLoaderStore => {
  switch (action.type) {
    case 'SHOULD_FETCH_TECHNIC':
      if (state.Technic.loading || state.Technic.shouldFetch) {
        return state;
      }
      return {
        ...state,
        Technic: { ...state.Technic, shouldFetch: true },
      };
    case 'IS_FETCHING_TECHNIC':
      if (state.Technic.loading) {
        return state;
      }
      return {
        ...state,
        Technic: { ...state.Technic, loading: true },
      };
    case 'FETCHED_TECHNIC':
      return {
        ...state,
        Technic: {
          ...state.Technic,
          data: action.payload.technics,
          loading: false,
          shouldFetch: false,
          lastFetch: new Date(),
        },
      };
    case 'ADD_TECHNIC':
      // Check if already present - If already added by this user or coming from another user
      if (state.Technic.data?.find((object) => object.id === action.payload.technic.id)) {
        return state;
      }

      return {
        ...state,
        Technic: {
          ...state.Technic,
          data: [...state.Technic.data, action.payload.technic],
        },
      };
    case 'UPDATE_TECHNIC':
      // No data
      if (isNilOrEmpty(state.Technic.data)) {
        return state;
      }

      // Already present and same object
      const currentObject = state.Technic.data?.find((object) => object.id === action.payload.technic.id);
      if (!currentObject) {
        return {
          ...state,
          Technic: {
            ...state.Technic,
            data: [...state.Technic.data, action.payload.technic],
          },
        };
      }
      if (!recordWasUpdated(currentObject, action.payload.technic)) {
        return state;
      }

      // Update
      return {
        ...state,
        Technic: {
          ...state.Technic,
          data: state.Technic.data.map((object) => {
            if (object.id === action.payload.technic.id) {
              return action.payload.technic;
            }
            return object;
          }),
        },
      };
    case 'DELETE_TECHNIC':
      if (isNilOrEmpty(state.Technic.data)) {
        return state;
      }
      return {
        ...state,
        Technic: {
          ...state.Technic,
          data: state.Technic.data.filter((object) => object.id !== action.payload.id),
        },
      };

    default:
      return state;
  }
};

const fetchTechnics = async (
  by: 'byUnitId' | 'byLeaseId' | 'byClientId',
  byValue: string,
  additionalFilter?: object
): Promise<Technic[]> => {
  return await list<Technic>(syncTechnics, getFilterFieldNameForIndex(by), byValue, additionalFilter, true);
};

// eslint-disable-next-line no-redeclare
export const TechnicsContext = React.createContext<TechnicsContext | null>(null);

export const TechnicsContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const {
    Technic: { data: technicsLoader, loading: technicsLoading, shouldFetch },
    Contact: { data: contactsLoader, loading: contactsLoading },
    Building: { data: buildingsLoader, loading: buildingsLoading },
    Unit: { data: unitsLoader, loading: unitsLoading },
    Lease: { data: leasesLoader, loading: leasesLoading },
    dispatch: contextDispatch,
  } = useContextLoader();
  const { buildingsUnitsTechnicsDelete, buildingsUnitsUtilityProvidersDelete } = usePermissions();
  const { deleteFile, fetchFiles } = useFiles();
  const { deleteRepartitionKey } = useRepartitionKeys(false);
  const { notifications, deleteNotification, notificationsLoading } = useNotifications();
  const { clientId, isOwner, userId } = useUser();

  const loading =
    technicsLoading || contactsLoading || buildingsLoading || unitsLoading || leasesLoading || notificationsLoading;

  const technics = useMemo<Technic[]>(() => {
    if (loading) {
      return [];
    }
    const technicsMapped = technicsLoader.map((technic) => {
      const utilityHistory = technic.utilityHistory ?? [];
      return {
        ...technic,
        utilityHistory,
        ...(technic.buildingId ? { building: resolveOneToOne(technic.buildingId, buildingsLoader, 'id') } : {}),
        ...(technic.unitId ? { unit: resolveOneToOne(technic.unitId, unitsLoader, 'id') } : {}),
        ...(technic.leaseId ? { lease: resolveOneToOne(technic.leaseId, leasesLoader, 'id') } : {}),
        ...(technic.utilityDistributorId
          ? { utilityDistributor: resolveOneToOne(technic.utilityDistributorId, contactsLoader, 'id') }
          : {}),
        ...(technic.contactId ? { contact: resolveOneToOne(technic.contactId, contactsLoader, 'id') } : {}),
      };
    });
    // adding new technic on lease implies to create 2 technics.
    // one for the lease and one for the unit.
    // first one for tenant, second for owner.
    // The owner technics shall thus not contain any leaseId.
    return technicsMapped;
  }, [technicsLoader, loading, buildingsLoader, unitsLoader, leasesLoader, contactsLoader]);

  const setFetchTechnic = () => {
    contextDispatch({ type: 'SHOULD_FETCH_TECHNIC' });
  };

  useEffect(() => {
    const fetchAndSet = async () => {
      contextDispatch({ type: 'IS_FETCHING_TECHNIC' });
      const result = await fetchTechnics('byClientId', getTableClientId(clientId!, ENTITY_MODEL_NAME));
      contextDispatch({ type: 'FETCHED_TECHNIC', payload: { technics: result } });
    };
    if (shouldFetch) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch]);

  useSubscriptions<OnCreateTechnicSubscription, OnUpdateTechnicSubscription, OnDeleteTechnicSubscription>(
    cleanMixedDatastoreAndAPISubscription(onCreateTechnic),
    cleanMixedDatastoreAndAPISubscription(onUpdateTechnic),
    cleanMixedDatastoreAndAPISubscription(onDeleteTechnic),
    (data) => {
      contextDispatch({
        type: 'ADD_TECHNIC',
        payload: { technic: data.onCreateTechnic as Technic },
      });
    },
    (data) => {
      contextDispatch({
        type: 'UPDATE_TECHNIC',
        payload: { technic: data.onUpdateTechnic as Technic },
      });
    },
    (data) => {
      const { id } = data.onDeleteTechnic as Technic;
      contextDispatch({
        type: 'DELETE_TECHNIC',
        payload: { id },
      });
    },
    undefined,
    undefined,
    undefined,
    true
  );

  const createTechnic = async (input: Omit<Technic, 'id' | 'clientId' | 'readId'> & Partial<Pick<Technic, 'id'>>) => {
    const cleanedObject = cleanTechnicInput(input);
    const technic = await mutation<Technic, CreateMutationVariables>(
      createMutation,
      {
        input: {
          ...(cleanInputCreate(cleanedObject) as CreateInput),
          PebIssueDate: cleanedObject.PebIssueDate ? new Date(cleanedObject.PebIssueDate).toISOString() : undefined,
          PebValidityDate: cleanedObject.PebValidityDate
            ? new Date(cleanedObject.PebValidityDate).toISOString()
            : undefined,
          clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME),
          readId: getReadId(clientId!, ENTITY_MODEL_NAME),
          ...(isOwner && { writers: [userId!] }),
        },
      },
      true
    );
    contextDispatch({ type: 'ADD_TECHNIC', payload: { technic } });
    return technic;
  };

  const updateTechnic = async (original: Technic, updates: Partial<Technic>) => {
    const cleanedObject = cleanTechnicInput(updates);
    const result = await mutation<Technic>(
      updateMutation,
      {
        input: { ...cleanInputUpdate({ id: original.id, _version: original._version, ...cleanedObject }, false) },
      },
      true
    );
    contextDispatch({ type: 'UPDATE_TECHNIC', payload: { technic: result } });
    return result;
  };

  const deleteTechnic = async (id: string) => {
    const technic = getTechnic(id)!;
    if (
      (!buildingsUnitsTechnicsDelete && technic.type !== TechnicType.UTILITY_PROVIDER) ||
      (!buildingsUnitsUtilityProvidersDelete && technic.type === TechnicType.UTILITY_PROVIDER)
    ) {
      return technic;
    }

    await deleteEntityWithFetchBefore<Pick<Technic, 'id'>, DeleteMutationVariables>(
      { id },
      getQuery,
      deleteMutation,
      undefined,
      true
    );

    contextDispatch({ type: 'DELETE_TECHNIC', payload: { id } });
    return technic;
  };

  const getTechnic = (id: string): Technic | undefined => (loading ? undefined : technics.find((u) => u.id === id));

  const getTechnicsFor = (id: string): Technic[] =>
    loading
      ? []
      : technics.filter(
          (t) => (t.unitId === id && isNil(t.leaseId)) || (t.buildingId === id && isNil(t.leaseId)) || t.leaseId === id
        );

  const deepDeleteTechnic = async (technic: Technic, filesAlreadyIncluded = false) => {
    if (
      (!buildingsUnitsTechnicsDelete && technic.type !== TechnicType.UTILITY_PROVIDER) ||
      (!buildingsUnitsUtilityProvidersDelete && technic.type === TechnicType.UTILITY_PROVIDER)
    ) {
      return;
    }
    const deletePromises: Promise<Technic | FileModel | RepartitionKey | void>[] = [];
    const technicFiles = filesAlreadyIncluded ? technic.files! : await fetchFiles(EntityType.TECHNIC, technic.id);
    for (const technicFile of technicFiles as FileModel[]) {
      deletePromises.push(deleteFile(technicFile));
    }
    const technicRepartitionKeys = await fetchTechnicRepartitionKeys(technic.id, clientId!);
    for (const technicRepartitionKey of technicRepartitionKeys) {
      deletePromises.push(deleteRepartitionKey(technicRepartitionKey));
    }
    const technicNotifications = notifications.filter(
      (notification) => notification.technic && notification.technic.id === technic.id
    );
    for (const technicNotification of technicNotifications) {
      deletePromises.push(deleteNotification(technicNotification.id));
    }

    deletePromises.push(deleteTechnic(technic.id));
    await Promise.all(deletePromises);
  };

  // Adding new technic on lease implies to create 2 technics: 1 for lease (tenant) and 1 for unit (manager/owner).
  // The manager technics shall thus not contain any leaseId.
  const technicsWithoutLeasesLink = technics.filter((technic) => isNil(technic.leaseId));

  const values = useMemo(
    () => ({
      technicsWithoutLeasesLink,
      technics,
      getTechnic,
      getTechnicsFor,
      createTechnic,
      updateTechnic,
      deleteTechnic,
      deepDeleteTechnic,
      technicsError: undefined,
      technicsLoading: loading,
      setFetchTechnic,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [technics, loading]
  );

  return <TechnicsContext.Provider value={values}>{children}</TechnicsContext.Provider>;
};

export const useTechnics = (): TechnicsContext => {
  const context = useContext<TechnicsContext | null>(TechnicsContext);

  if (context === undefined) {
    throw new Error('`useTechnics` hook must be used within a `TechnicsContextProvider` component');
  }
  return context as TechnicsContext;
};
