import { Grid, Typography } from '@material-ui/core';
import {
  Colors,
  CommunicationSettingsProfile,
  CommunicationType,
  doesLeaseNeedsProRataForFirstAndLastMonth,
  getCommunicationSettings,
  getDateOfNextInvoice,
  getInvoicePeriodTo,
  getLocaleFromLanguage,
  getNumberOfMonthsFromFrequency,
  isLeaseFirstInvoice,
  LeasePaymentFrequency,
  LeasePaymentInvoicePeriod,
} from '@rentguru/commons-utils';
import {
  addMonths,
  format,
  isBefore,
  isSameDay,
  isSameMonth,
  setDate,
  startOfMonth,
  subDays,
  subMonths,
} from 'date-fns';
import { isEmpty, isNil, range } from 'lodash';
import { CSSProperties, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useCommunicationSettingsProfiles } from 'src/hooks/CommunicationSettingsProfilesContext';
import { ReactComponent as BulbIcon } from 'src/icons/bulb.svg';
import HelpIconPopover from './HelpIconPopover';
import BulbRemark from './IconnedRemark';

const getLastInvoiceDate = (
  didLeaseStartInThePast: boolean,
  // eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
  isSameMonth: boolean,
  amountOfMonthsOfFrequency: number
) => {
  if (!didLeaseStartInThePast || isSameMonth) {
    return null;
  }
  if (amountOfMonthsOfFrequency === 0.5) {
    const today = new Date();
    if (today.getDate() > 15) {
      return startOfMonth(today);
    }
    return setDate(subMonths(today, 1), 15);
  }
  return startOfMonth(subMonths(new Date(), amountOfMonthsOfFrequency));
};

// eslint-disable-next-line no-undef
export const formatData = (dataRaw: { periodFrom: Date; periodTo: Date }[], locale: Locale) => {
  return dataRaw.map((dataElement) => {
    return {
      primary: `${format(dataElement.periodFrom, 'dd/MM/yyyy', { locale })} → ${format(
        dataElement.periodTo,
        'dd/MM/yyyy',
        {
          locale,
        }
      )}`,
      value: dataElement.periodFrom,
    };
  });
};

const getTheoreticalPeriod = (
  startDate: Date,
  beginOfPeriod: Date | null,
  paymentFrequency: LeasePaymentFrequency,
  paymentInvoicePeriod: LeasePaymentInvoicePeriod,
  paymentInvoicePeriodCustomDay: number,
  lastInvoiceDate: Date | null,
  period: 'begin' | 'end'
) => {
  if (period === 'begin') {
    return getDateOfNextInvoice(
      new Date(startDate),
      beginOfPeriod ? beginOfPeriod.toISOString() : lastInvoiceDate ? lastInvoiceDate.toISOString() : null,
      paymentFrequency,
      paymentInvoicePeriod,
      paymentInvoicePeriodCustomDay
    );
  }
  return getInvoicePeriodTo(beginOfPeriod!, paymentFrequency, paymentInvoicePeriod, paymentInvoicePeriodCustomDay);
};

export const getPeriodOptions = (
  startDate: Date,
  endDate: Date,
  amountOfMonthsOfFrequency: number,
  didLeaseStartInThePast: boolean,
  paymentFrequency: LeasePaymentFrequency,
  paymentInvoicePeriod: LeasePaymentInvoicePeriod,
  paymentInvoicePeriodCustomDay: number
): { periodFrom: Date; periodTo: Date }[] => {
  const today = new Date();
  const lastInvoiceDate = getLastInvoiceDate(
    didLeaseStartInThePast,
    isSameMonth(today, startDate),
    amountOfMonthsOfFrequency
  );

  let beginOfFirstPeriod: null | Date = null;
  let endOfFirstPeriod: null | Date = null;
  const periods = range(0, 3).map((index) => {
    beginOfFirstPeriod = getTheoreticalPeriod(
      new Date(startDate),
      beginOfFirstPeriod || lastInvoiceDate || null,
      paymentFrequency,
      paymentInvoicePeriod,
      paymentInvoicePeriodCustomDay,
      lastInvoiceDate,
      'begin'
    );
    endOfFirstPeriod = getTheoreticalPeriod(
      new Date(startDate),
      beginOfFirstPeriod,
      paymentFrequency,
      paymentInvoicePeriod,
      paymentInvoicePeriodCustomDay,
      lastInvoiceDate,
      'end'
    );
    // For the first period only.
    if (index === 0) {
      const isLeaseVeryFirstInvoice = isLeaseFirstInvoice(
        startDate,
        endDate!,
        lastInvoiceDate ? lastInvoiceDate.toISOString() : null,
        true,
        beginOfFirstPeriod,
        endOfFirstPeriod
      );

      if (isLeaseVeryFirstInvoice) {
        beginOfFirstPeriod = startDate;
      }
    }

    return {
      periodFrom: beginOfFirstPeriod!,
      periodTo: endOfFirstPeriod,
    };
  });
  return periods;
};

export interface PaymentDueDateNextPeriodProps {
  language?: string;
  startDate: Date;
  endDate: Date;
  didLeaseStartInThePast: boolean;
  paymentFrequency: LeasePaymentFrequency;
  paymentInvoicePeriod: LeasePaymentInvoicePeriod;
  paymentInvoicePeriodCustomDay: number;
  lastInvoiceDate: string | null;
  doesLeaseAlreadyExist?: boolean;
  style?: CSSProperties;
  showHelper?: boolean;
}

const PaymentDueDateNextPeriod: React.FC<PaymentDueDateNextPeriodProps> = ({
  language,
  startDate,
  endDate,
  didLeaseStartInThePast,
  paymentFrequency,
  paymentInvoicePeriod,
  paymentInvoicePeriodCustomDay,
  lastInvoiceDate,
  doesLeaseAlreadyExist,
  style,
  showHelper = true,
}) => {
  const { formatMessage } = useIntl();
  const locale = getLocaleFromLanguage((language ?? 'en').toLocaleLowerCase());
  const { getDefaultLeaseCommunicationSettingsProfiles, loading: communicationSettingsProfilesLoading } =
    useCommunicationSettingsProfiles();

  const [leaseCommunicationSettingsProfiles, setLeaseCommunicationSettingsProfiles] = useState<
    CommunicationSettingsProfile[]
  >([]);

  useEffect(() => {
    if (communicationSettingsProfilesLoading) {
      return;
    }
    const leaseProfiles = getDefaultLeaseCommunicationSettingsProfiles();
    setLeaseCommunicationSettingsProfiles(leaseProfiles);
  }, [getDefaultLeaseCommunicationSettingsProfiles, communicationSettingsProfilesLoading]);

  if (communicationSettingsProfilesLoading || isEmpty(leaseCommunicationSettingsProfiles)) {
    return (
      <Grid container style={{ justifyContent: 'center' }}>
        <Typography style={{ margin: 20 }}>{formatMessage({ id: 'loading' })}</Typography>
      </Grid>
    );
  }

  // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
  const leaseCommunicationSettingsProfile = leaseCommunicationSettingsProfiles[0];
  const leasePaymentRequestDaysBeforeDueDate = getCommunicationSettings(
    CommunicationType.LEASE_PAYMENT_REQUEST,
    leaseCommunicationSettingsProfile
  ).daysBeforeDueDate;
  const amountOfMonthsOfFrequency = getNumberOfMonthsFromFrequency(paymentFrequency);
  const dataRaw = getPeriodOptions(
    startDate,
    endDate,
    amountOfMonthsOfFrequency,
    didLeaseStartInThePast,
    paymentFrequency,
    paymentInvoicePeriod,
    paymentInvoicePeriodCustomDay
  );

  const reversedUtcLastInvoiceDate = doesLeaseAlreadyExist
    ? addMonths(new Date(lastInvoiceDate ?? startDate), amountOfMonthsOfFrequency)
    : new Date(lastInvoiceDate ?? startDate);

  const sendingInvoiceDate = subDays(reversedUtcLastInvoiceDate, leasePaymentRequestDaysBeforeDueDate!);
  const isTodayAfterInvoiceDate = isBefore(sendingInvoiceDate, new Date());
  const paymentDueDate = !isNil(lastInvoiceDate) ? reversedUtcLastInvoiceDate : new Date(startDate);

  const periodIndex = dataRaw.findIndex((data) => {
    // bi-monthly!!!
    return isSameDay(data.periodFrom, reversedUtcLastInvoiceDate);
  });
  const coveredPeriod = dataRaw[periodIndex > -1 ? periodIndex : 0];

  const doesLeaseNeedProRata = doesLeaseNeedsProRataForFirstAndLastMonth(
    paymentDueDate,
    paymentInvoicePeriod as LeasePaymentInvoicePeriod,
    paymentInvoicePeriodCustomDay
  );

  return (
    <BulbRemark
      Icon={BulbIcon}
      style={style}
      message={
        <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Grid>
            <Typography style={{ fontSize: 12, color: Colors.SLATE_GREY, textAlign: 'left' }}>
              {formatMessage({ id: 'lease.addLease.nextInvoice' })}
            </Typography>
            <Typography style={{ fontSize: 12, color: Colors.SLATE_GREY, textAlign: 'left', marginLeft: 20 }}>
              - {formatMessage({ id: 'lease.addLease.invoiceDate' })}:{' '}
              {format(isTodayAfterInvoiceDate ? new Date() : sendingInvoiceDate, 'dd/MM/yyyy', {
                locale,
              })}
            </Typography>
            <Typography style={{ fontSize: 12, color: Colors.SLATE_GREY, textAlign: 'left', marginLeft: 20 }}>
              - {formatMessage({ id: 'lease.addLease.paymentDueDateShort' })}:{' '}
              {format(paymentDueDate, 'dd/MM/yyyy', { locale })}
            </Typography>
            <Typography style={{ fontSize: 12, color: Colors.SLATE_GREY, textAlign: 'left', marginLeft: 20 }}>
              - {formatMessage({ id: 'lease.addLease.coveredPeriod' })}:{' '}
              {format(coveredPeriod.periodFrom, 'dd/MM/yyyy', { locale })}→
              {format(coveredPeriod.periodTo, 'dd/MM/yyyy', { locale })}
            </Typography>
            <Typography style={{ fontSize: 12, color: Colors.SLATE_GREY, textAlign: 'left', marginLeft: 20 }}>
              {doesLeaseNeedProRata ? formatMessage({ id: 'lease.addLease.proRataMessage' }) : ''}
            </Typography>
          </Grid>
          {showHelper && (
            <Grid>
              <HelpIconPopover
                helperText={
                  didLeaseStartInThePast
                    ? formatMessage({
                        id: `lease.addLease.datesListForStartPeriodInPast`,
                      })
                    : formatMessage({
                        id: `lease.addLease.datesList`,
                      })
                }
                paperStyle={{ maxWidth: 400 }}
                iconStyle={{ marginRight: 10 }}
              />
            </Grid>
          )}
        </Grid>
      }
    />
  );
};

export default PaymentDueDateNextPeriod;
