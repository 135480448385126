import React from 'react';
import { TextField, Typography, Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { isNil } from 'lodash';
import { ReactComponent as DropDown } from '../../../icons/drop-down.svg';
import { useIntl } from 'react-intl';
import isEqual from 'lodash/isEqual';
import { Colors } from '@rentguru/commons-utils';

export interface CustomMenuItemType {
  primary: string;
  secondary?: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  // eslint-disable-next-line no-undef
  renderIcon?: () => JSX.Element | undefined;
  group?: string;
  isUpper?: boolean;
}
/* Standard ComboBox, if you want to use it with Formik use the FormikComboBox instead */
export interface CustomizedComboBoxProps {
  data: CustomMenuItemType[];
  label: string;
  inputStyle?: React.CSSProperties;
  paperClass?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (event: any, value: CustomMenuItemType | null) => void;
  // If you set the grouped value to true, the data that you pass must have a group field
  grouped?: boolean;
  // This function is used when you want to sort the groups between each other e.g. suggested group before the rest
  // default sort is alphabetic sort
  groupCmpFunction?: (groupA: string, groupB: string) => number;
  error?: boolean;
  noOptionsText?: string;
  initialValue?: CustomMenuItemType;
  disabled?: boolean;
  required?: boolean;
  inputAdornment?: React.ReactNode;
  multiple?: boolean;
}

export const getOptionLabelFromCustomMenuItemType = (option: CustomMenuItemType) => {
  if (isNil(option.secondary)) return option.primary;
  return `${option.primary} - ${option.secondary}`;
};

export const useCustomizedComboStyles = makeStyles({
  root: {
    '&$disabled:before': {
      borderBottomStyle: 'hidden',
    },
  },
  disabled: {
    backgroundColor: Colors.PORCELAIN_GREY_1,
  },
  option: {
    fontSize: 14,
  },
  label: {
    color: Colors.LIGHT_BLUE_GREY,
  },
  underline: {
    '&&:before': {
      borderBottom: `2px solid ${Colors.TOWER_GREY}`,
    },
  },
  select: {
    height: '1.1875em',
  },
  primaryText: {
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Mulish',
    fontSize: '11px',
    fontWeight: 700,
    letterSpacing: '0.7px',
  },
  secondaryText: {
    color: Colors.BLUE_GREY,
    fontFamily: 'Mulish',
    fontSize: '9.5px',
    letterSpacing: '0.6px',
  },
  icon: {
    fill: Colors.BLUEY,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    paddingBottom: 16,
    paddingLeft: 16,
  },
});

const CustomizedComboBox: React.FC<CustomizedComboBoxProps> = ({
  data,
  label,
  inputStyle = {},
  paperClass = '',
  onChange,
  grouped = false,
  groupCmpFunction,
  error = false,
  noOptionsText,
  initialValue,
  disabled = false,
  required = false,
  inputAdornment,
  ...rest
}) => {
  const classes = useCustomizedComboStyles();
  const { formatMessage } = useIntl();

  const sortValuesByPrimaryThenSecondary = (a: CustomMenuItemType, b: CustomMenuItemType) => {
    if (grouped) {
      // If grouped all values must have a group value
      const groupA = a.group!;
      const groupB = b.group!;
      if (isNil(groupCmpFunction)) {
        // Default sort between groups  alphabetic sort
        if (groupA > groupB) return 1;
        if (groupA < groupB) return -1;
      } else {
        // Else use custom sort
        const cmpResult = groupCmpFunction(groupA, groupB);
        if (cmpResult !== 0) return cmpResult;
      }
    }
    // alphabetic sort by first comparing primary then secondary
    const primaryCmpA = a.primary.toLowerCase();
    const primaryCmpB = b.primary.toLowerCase();
    if (primaryCmpA > primaryCmpB) return 1;
    if (primaryCmpA < primaryCmpB) return -1;
    const secondaryCmpA = !isNil(a.secondary) ? a.secondary : '';
    const secondaryCmpB = !isNil(b.secondary) ? b.secondary : '';
    if (secondaryCmpA >= secondaryCmpB) return 1;
    return -1;
  };

  const options = data.sort(sortValuesByPrimaryThenSecondary);
  return (
    <Autocomplete
      clearOnEscape={true}
      options={options}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      groupBy={grouped ? (option) => option.group : (undefined as any)}
      classes={{
        option: classes.option,
        paper: paperClass,
      }}
      style={{ width: '100%' }}
      defaultValue={!isNil(initialValue) ? initialValue : undefined}
      autoHighlight
      getOptionSelected={(option, value) => isEqual(option.value, value.value)}
      getOptionLabel={getOptionLabelFromCustomMenuItemType}
      popupIcon={<DropDown className={classes.icon} />}
      renderOption={(option) => {
        return (
          <Grid container alignContent="space-between" style={{ marginBottom: '5px', marginTop: '5px' }}>
            <Grid item xs={6}>
              <Typography className={classes.primaryText}>{option.primary}</Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              {!isNil(option.secondary) && (
                <Grid style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <Typography className={classes.secondaryText} style={{ marginRight: 10 }}>
                    {option.secondary}
                  </Typography>
                  {option.hasOwnProperty('renderIcon') && option.renderIcon!()}
                </Grid>
              )}
            </Grid>
          </Grid>
        );
      }}
      onChange={onChange}
      disableClearable={true}
      noOptionsText={!isNil(noOptionsText) ? noOptionsText : formatMessage({ id: 'comboBox.noOptions' })}
      renderInput={(params) => {
        return (
          <>
            <TextField
              {...params}
              required={required}
              label={label}
              margin="dense"
              variant="filled"
              style={{ ...inputStyle }}
              inputProps={{
                ...params.inputProps,
              }}
              InputProps={{
                ...params.InputProps,
                classes: { underline: classes.underline },
                endAdornment: isNil(inputAdornment) ? (
                  params.InputProps.endAdornment
                ) : (
                  <>
                    {inputAdornment}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              InputLabelProps={{ className: classes.label }}
              SelectProps={{ classes: { select: classes.select } }}
              error={error}
            />
          </>
        );
      }}
      disabled={disabled}
      {...rest}
    />
  );
};

export default CustomizedComboBox;
