import React from 'react';
import { useIntl } from 'react-intl';
import { Divider, Grid, Typography } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';

interface PebFormHeaderProps {}
const PebFormHeader: React.FC<PebFormHeaderProps> = () => {
  const { formatMessage } = useIntl();
  return (
    <Grid>
      <Grid container style={{ marginBottom: 10 }}>
        <Grid item xs={2}>
          <Typography variant="h6" style={{ fontSize: 12, color: Colors.LIGHT_BLUE_GREY }}>
            {formatMessage({ id: 'lease.detail.action.type' })}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1" style={{ fontSize: 12, color: Colors.LIGHT_BLUE_GREY }}>
            {formatMessage({ id: 'technic.PEB' })}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1" style={{ fontSize: 12, color: Colors.LIGHT_BLUE_GREY }}>
            {formatMessage({ id: 'technic.epbTab.epbIssueDate' })}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1" style={{ fontSize: 12, color: Colors.LIGHT_BLUE_GREY }}>
            {formatMessage({ id: 'technic.epbTab.epbValidityDate' })}
          </Typography>
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: 10 }} />
    </Grid>
  );
};

export default PebFormHeader;
