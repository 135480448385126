import { Grid } from '@material-ui/core';
import { CommunicationSettingRecipient } from '@rentguru/commons-utils';
import MultipleContactSelector from 'src/components/ui/MultipleContactSelector';
import MultipleContactTypeSelector from 'src/components/ui/MultipleContactTypeSelector';
import { useContacts } from 'src/hooks/ContactsContext';

interface CommunicationRecipientInCopyFormItemProps {
  recipientsInBcc: CommunicationSettingRecipient[];
  setRecipientsInBcc: (value: CommunicationSettingRecipient[]) => void;
  selectedContactIds: string[];
  setSelectedContactIds: (value: string[]) => void;
  disabled: boolean;
}

const CommunicationRecipientInCopyFormItem: React.FC<CommunicationRecipientInCopyFormItemProps> = ({
  recipientsInBcc,
  setRecipientsInBcc,
  selectedContactIds,
  setSelectedContactIds,
  disabled,
}) => {
  const { contacts: allContacts } = useContacts();
  const possibleContactTypes = [
    CommunicationSettingRecipient.GUARANTOR,
    CommunicationSettingRecipient.TENANT,
    CommunicationSettingRecipient.OWNER,
  ];
  return (
    <Grid>
      <MultipleContactTypeSelector
        contactTypes={possibleContactTypes}
        selectedContactTypes={recipientsInBcc}
        setValue={setRecipientsInBcc}
        labelFormatMessageId="communications.detail.recipient.BCC"
        disabled={disabled}
        inputWidth={464}
        noContactTypesChip
      />
      <MultipleContactSelector
        contacts={allContacts}
        selectedContactIds={selectedContactIds}
        setSelectedContactIds={setSelectedContactIds}
        disabled={disabled}
        inputWidth={464}
        allContactsChip={false}
        noContactChip
        labelFormatMessageId="communications.detail.recipient.CC"
      />
    </Grid>
  );
};

export default CommunicationRecipientInCopyFormItem;
