/* eslint-disable no-plusplus */
import {
  Avatar,
  Collapse,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  Colors,
  Contact,
  ContactType,
  CreditOperationType,
  getContactNameFromObject,
  getFirstLettersUppercase,
  isCreditUsageCostValid,
  LeaseExtended,
  SignatureType,
  stringToColor,
} from '@rentguru/commons-utils';
import { useFormikContext } from 'formik';
import { isEmpty, isNil } from 'lodash';
import React, { CSSProperties } from 'react';
import { useIntl } from 'react-intl';
import { SignatureFormValues } from 'src/components/RentalUnits/Signature/Signature';
import { useClient } from 'src/hooks/ClientContext';
import { useContacts } from '../../../../hooks/ContactsContext';
import { ReactComponent as ManualIcon } from '../../../../icons/edit20.svg';
import { ReactComponent as EidIcon } from '../../../../icons/eid.svg';
import { ReactComponent as ItsmeIcon } from '../../../../icons/itsme.svg';
import { ReactComponent as MailOtpIcon } from '../../../../icons/mailotp.svg';
import { ReactComponent as SmsOtpIcon } from '../../../../icons/smsotp.svg';
import CustomCheckBox from '../../CustomCheckBox';
import FormAlertBox from '../../FormAlertBox';
import TextFieldArea from '../../TextFieldArea';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    manualRoot: {
      paddingLeft: 26,
      paddingTop: 5,
    },
    signatureRoot: {
      paddingTop: 0,
    },
    manualLabel: {
      height: 18,
      fontSize: 16,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: Colors.CLASSICAL_BLACK,
    },
    signatureLabel: {
      fontSize: 16,
      color: 'black',
    },
    greenSignatureLabel: {
      fontSize: 16,
      color: Colors.TURQUOISE_BLUE,
    },
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
    customisedSelect: {
      '& > *': {
        width: 280,
      },
    },
    addMessage: {
      fontWeight: 'bold',
      fontFamily: 'Mulish',
      lineHeight: 1.57,
      fontSize: 14,
    },
    formControl: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

interface SendForSignatureFormProps {
  lease: LeaseExtended;
}

const geticonAccordingToSignature = (signatureMethod: SignatureType, style: CSSProperties) => {
  switch (signatureMethod) {
    case SignatureType.EID:
      return <EidIcon style={style} />;
    case SignatureType.ITSME:
      return <ItsmeIcon style={style} />;
    case SignatureType.EMAILOTP:
      return <MailOtpIcon style={style} />;
    case SignatureType.SMSOTP:
      return <SmsOtpIcon style={style} />;
    default:
      return <ManualIcon style={style} />;
  }
};

const SendForSignatureForm: React.FC<SendForSignatureFormProps> = ({ lease }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { getContact } = useContacts();
  const { values, setFieldValue, errors } = useFormikContext<SignatureFormValues>();
  const { creditUsageCosts } = useClient();

  const addOrRemoveSignatureMethodFromArray = (signatureMethod: SignatureType) => {
    if ((values.signatureMethods ?? []).includes(signatureMethod)) {
      setFieldValue(
        'signatureMethods',
        (values.signatureMethods ?? []).filter((currentSignatureMethod) => currentSignatureMethod !== signatureMethod)
      );
    } else {
      setFieldValue('signatureMethods', [...values.signatureMethods, signatureMethod]);
    }
  };

  const completeLeaseContacts: Contact[] = lease.contacts!.reduce((acc: Contact[], lc) => {
    const completeContact = getContact(lc.contact!.id);
    if (!isNil(completeContact)) {
      acc.push(completeContact);
    }
    return acc;
  }, []);

  let tenantNumber = 1;
  let lessorNumber = 1;
  let guarantorNumber = 1;

  const leftMenuSignatureMethods = [SignatureType.EID, SignatureType.ITSME];
  const rightMenuSignatureMethods = [SignatureType.SMSOTP, SignatureType.EMAILOTP, SignatureType.MANUAL];
  const menuSignatureMethods = [leftMenuSignatureMethods, rightMenuSignatureMethods];

  const shouldShowWarning = !values.signatureMethods.some((signatureMethod) =>
    leftMenuSignatureMethods.includes(signatureMethod)
  );

  const signaturePrice = creditUsageCosts?.find(
    (creditCost) => creditCost.type === CreditOperationType.SIGNATURE && isCreditUsageCostValid(creditCost)
  );

  return (
    <>
      <Grid container style={{ display: 'flex', justifyContent: 'left', paddingLeft: 30 }}>
        {completeLeaseContacts.map((contact) => {
          let labelId = '';
          let number = 1;

          // eslint-disable-next-line @typescript-eslint/no-shadow
          const leaseContact = lease.contacts?.find((leaseContact) => leaseContact.contact!.id === contact.id);
          const name = getContactNameFromObject(leaseContact?.contact);
          const color = stringToColor(name);

          if (leaseContact!.contactRole === ContactType.TENANT) {
            labelId = 'lease.addLease.tenant';
            number = tenantNumber;
            tenantNumber++;
          } else if (leaseContact!.contactRole === ContactType.GUARANTOR) {
            labelId = 'lease.detail.general.guarantor';
            number = guarantorNumber;
            guarantorNumber++;
          } else {
            labelId = 'lease.addLease.lessor';
            number = lessorNumber;
            lessorNumber++;
          }

          return (
            <Grid item xs={6} key={contact.id} style={{ marginTop: 10, display: 'flex' }}>
              <Grid>
                <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
                  {`${formatMessage({ id: labelId })} ${number}`}
                </Typography>
                <Grid style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
                  <Avatar
                    style={{
                      color,
                      backgroundColor: 'rgba(0,0,0,0)',
                      border: `1.5px ${color} solid`,
                      fontSize: '12px',
                      fontWeight: 'bold',
                      marginRight: 16,
                    }}
                  >
                    {getFirstLettersUppercase(name)}
                  </Avatar>
                  <Typography style={{ color: Colors.CLASSICAL_BLACK, fontSize: 16, fontWeight: 500 }}>
                    {getContactNameFromObject(contact)}
                  </Typography>
                </Grid>
                <Collapse
                  in={
                    (values.signatureMethods ?? []).includes(SignatureType.SMSOTP) &&
                    (isNil(contact.phoneNumbers) || isEmpty(contact.phoneNumbers))
                  }
                >
                  <FormAlertBox
                    gridStyle={{ marginTop: 15 }}
                    removeLeftMargin
                    message1={formatMessage({ id: 'lease.detail.action.missingPhone' })}
                    width={280}
                    smallVersion
                  />
                </Collapse>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      <Typography style={{ marginLeft: 30, marginRight: 30, fontSize: 14 }}>
        {formatMessage({
          id: 'lease.detail.action.explanation',
        })}
      </Typography>
      <Collapse in={shouldShowWarning} style={{ marginLeft: 30 }}>
        <FormAlertBox
          gridStyle={{ marginTop: 15 }}
          removeLeftMargin
          message1={formatMessage({ id: 'lease.detail.action.selectWarning' })}
          width={580}
        />
      </Collapse>
      <Grid
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          marginTop: 20,
          marginLeft: 30,
          marginRight: 30,
        }}
      >
        <Grid
          style={{
            opacity: 0.9,
            borderRadius: 5,
            padding: 10,
            border: `solid 1px ${errors.signatureMethods ? 'red' : Colors.SILVER}`,
            marginBottom: 20,
          }}
        >
          <FormGroup row>
            <FormLabel component="legend" color="primary" focused={false}>
              {formatMessage({ id: 'lease.detail.action.selectMethod' })}
            </FormLabel>
          </FormGroup>
          {signaturePrice && (
            <FormGroup row style={{ paddingTop: 10 }}>
              <FormLabel
                color="primary"
                focused={false}
                style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
              >
                {`${signaturePrice.numberOfCreditsPerOperation} ${formatMessage({
                  id: 'settings.credits.name',
                })}/${formatMessage({
                  id: 'lease.detail.action.signature',
                })}`}
              </FormLabel>
            </FormGroup>
          )}
          <FormGroup row style={{ justifyContent: 'space-evenly' }}>
            {menuSignatureMethods.map((leftOrRightMenu, index) => {
              return (
                <Grid key={leftOrRightMenu.toString()} style={{ display: 'flex' }}>
                  <Grid>
                    <FormControl component="fieldset" className={classes.formControl}>
                      {leftOrRightMenu.map((signatureMethod) => {
                        return (
                          <FormGroup
                            row
                            key={signatureMethod}
                            style={{ display: 'flex', justifyContent: 'space-between' }}
                          >
                            <FormControlLabel
                              control={
                                <CustomCheckBox
                                  id={signatureMethod}
                                  isChecked={(values.signatureMethods ?? []).includes(signatureMethod)}
                                  onCheck={() => addOrRemoveSignatureMethodFromArray(signatureMethod)}
                                  color="black"
                                  name={signatureMethod}
                                  customIcon={geticonAccordingToSignature(signatureMethod, {
                                    fill: index !== 0 ? Colors.LIGHT_BLUE_GREY : Colors.SCARLET_ORANGE,
                                  })}
                                  customCheckedIcon={geticonAccordingToSignature(signatureMethod, {
                                    fill: index !== 0 ? Colors.TURQUOISE_BLUE : Colors.SCARLET_ORANGE,
                                  })}
                                  dataTest={`signature-method-${signatureMethod}`}
                                />
                              }
                              label={`${formatMessage({ id: `lease.detail.signatureMethods.${signatureMethod}` })}*`}
                              classes={{
                                root: classes.signatureRoot,
                                label: !(values.signatureMethods ?? []).includes(signatureMethod)
                                  ? classes.signatureLabel
                                  : classes.greenSignatureLabel,
                              }}
                            />
                          </FormGroup>
                        );
                      })}
                    </FormControl>
                  </Grid>
                  {index !== menuSignatureMethods.length - 1 && (
                    <Divider
                      orientation="vertical"
                      flexItem
                      style={{ marginTop: 20, marginBottom: 20, marginLeft: 10 }}
                    />
                  )}
                </Grid>
              );
            })}
          </FormGroup>
        </Grid>
        <TextFieldArea
          name="additionalMessage"
          title={`${formatMessage({ id: 'tickets.addTicket.message' })} ${formatMessage({ id: 'optional' })}`}
          value={values.additionalMessage}
          className={classes.addMessage}
          rows={6}
          rowsMax={8}
          variant="filled"
        />

        <Typography variant="subtitle2" style={{ fontStyle: 'italic', marginRight: 30, marginTop: 10, fontSize: 14 }}>
          {`*${formatMessage({ id: 'lease.detail.action.selectWarning' })}`}
        </Typography>
      </Grid>
    </>
  );
};

export default SendForSignatureForm;
