import { Collapse, Grid, MenuItem, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import { DEFAULT_LANGUAGE, formatNumber, LeaseActionEndInitiator, LeaseType } from '@rentguru/commons-utils';
import { useFormikContext } from 'formik';
import { clamp, isNil, toNumber } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { useUser } from 'src/hooks/UserContext';
import { EndLeaseFormValues } from './EndLeaseForm';
import { useAdornmentStyles } from 'src/components/ui/Forms/FormSection/FurnishedRental';
import TextDetailEditable, { useStyles as useTextDetailEditableStyles } from 'src/components/ui/TextDetailEditable';
import { ReactComponent as Chevron } from '../../../../icons/chevron-right.svg';
import { isInFlanders, isShortTermsLease } from './utils';

export interface IndemnityFieldProps {
  leaseType: LeaseType;
  region?: string | null;
  totalRent: number;
}

const EndLeaseIndemnityField = ({ leaseType, region, totalRent }: IndemnityFieldProps) => {
  const { formatMessage } = useIntl();
  const { language = DEFAULT_LANGUAGE } = useUser();
  const { values, setFieldValue, handleBlur } = useFormikContext<EndLeaseFormValues>();
  const adornmentClasses = useAdornmentStyles();
  const textDetailEditableStyles = useTextDetailEditableStyles();

  const handleTabChange = (event: React.ChangeEvent<{}>, newTab: 'amountOfMonths' | 'amountInEur') => {
    setFieldValue('amountDue.selectedTab', newTab);
    if (newTab === 'amountInEur') {
      setFieldValue('amountDue.userTotalAmount', values.amountDue.totalAmount);
    } else {
      // Reset value
      setFieldValue('amountDue.userTotalAmount', -1);
    }
  };

  const isLessorEnding = values.initiator === LeaseActionEndInitiator.LESSOR;
  const isCommercial = leaseType === LeaseType.COMMERCIAL;
  const isShortTerms = isShortTermsLease(leaseType);
  const isFlanders = isInFlanders(region);

  return (
    <>
      <TextField
        label={formatMessage({
          id: `lease.addLease.${
            values.amountDue.selectedTab === 'amountOfMonths' ? 'amountOfMonths' : 'rentalGuaranteeAmount'
          }`,
        })}
        id={
          values.amountDue.selectedTab === 'amountOfMonths' ? 'amountDue.amountOfMonths' : 'amountDue.userTotalAmount'
        }
        onBlur={handleBlur}
        type="number"
        margin="dense"
        variant="filled"
        value={
          values.amountDue.selectedTab === 'amountOfMonths'
            ? values.amountDue.amountOfMonths.toString()
            : values.amountDue.userTotalAmount.toString()
        }
        onChange={(event) => {
          const correctPositiveValue = clamp(toNumber(event.target.value), 0, Infinity);
          if (values.amountDue.selectedTab === 'amountOfMonths') {
            setFieldValue(`amountDue.amountOfMonths`, toNumber(correctPositiveValue));
            setFieldValue(`amountDue.totalAmount`, toNumber(correctPositiveValue) * totalRent);
          } else {
            setFieldValue(`amountDue.userTotalAmount`, toNumber(correctPositiveValue));
          }
        }}
        onWheel={(e) => {
          if (e.target instanceof HTMLElement) {
            e.target.blur();
          }
        }}
        InputProps={{
          endAdornment: (
            <>
              {values.amountDue.selectedTab === 'amountOfMonths' && (
                <Chevron style={{ transform: `rotate(90deg)`, width: 76 }} />
              )}
              <Grid
                style={{
                  width: values.amountDue.selectedTab === 'amountOfMonths' ? 1150 : 970,
                  margin: 0,
                  padding: 0,
                  display: 'flex',
                }}
              >
                {values.amountDue.selectedTab === 'amountInEur' ? (
                  <Grid style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>EUR</Typography>
                  </Grid>
                ) : (
                  <Grid style={{ width: 24 }} />
                )}
                <Tabs
                  TabIndicatorProps={{ style: { display: 'none' } }}
                  value={values.amountDue.selectedTab}
                  onChange={handleTabChange}
                  variant="standard"
                >
                  <Tab
                    className={adornmentClasses.tabStyle}
                    label={formatMessage({ id: 'lease.addLease.amountOfMonths' })}
                    value="amountOfMonths"
                    id="amountOfMonths"
                  />

                  <Tab
                    className={adornmentClasses.tabStyle}
                    label={formatMessage({ id: 'lease.addLease.guaranteeAmount' })}
                    value="amountInEur"
                    id="amountInEur"
                  />
                </Tabs>
              </Grid>
            </>
          ),
          inputProps: { min: 0 },
          classes: { underline: textDetailEditableStyles.underline, input: textDetailEditableStyles.noEndArrow },
          style: { width: 540 },
        }}
        SelectProps={{ IconComponent: () => null }}
        select={values.amountDue.selectedTab === 'amountOfMonths'}
        InputLabelProps={{ className: textDetailEditableStyles.label }}
        style={{ padding: 0 }}
      >
        <MenuItem value={0}>0</MenuItem>
        <MenuItem value={0.5}>{formatNumber(0.5, language!)}</MenuItem>
        <MenuItem value={1}>1</MenuItem>
        {isFlanders && <MenuItem value={1.5}>{formatNumber(1.5, language!)}</MenuItem>}
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
        {((!isCommercial && !isShortTerms && isLessorEnding) || isCommercial) && <MenuItem value={6}>6</MenuItem>}
        {!isCommercial && !isShortTerms && isLessorEnding && <MenuItem value={9}>9</MenuItem>}
      </TextField>
      <Collapse in={values.amountDue.selectedTab === 'amountOfMonths'}>
        <TextDetailEditable
          title={formatMessage({
            id: 'lease.addLease.rentalGuaranteeAmount',
          })}
          name="amountDue.totalAmount"
          text={
            !isNil(values.amountDue.totalAmount)
              ? `${formatNumber(values.amountDue.totalAmount, language ?? DEFAULT_LANGUAGE, {
                  currency: 'EUR',
                })} € (${values.amountDue.amountOfMonths} ${formatMessage(
                  { id: 'rentalUnit.filter.month' },
                  { value: values.amountDue.amountOfMonths }
                )})`
              : ''
          }
          editMode={false}
        />
      </Collapse>
    </>
  );
};

export default EndLeaseIndemnityField;
