import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { ElevatedPaperTable } from '@up2rent/ui';
import FormHeaderColorDots from 'src/components/ui/FormHeaderColorDots';
import LandTerrainEdit from './LandTerrainEdit';
import OutsideInventory from './OutsideInventory';

const OutsideEdits = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Grid item style={{ width: 640 }}>
        <ElevatedPaperTable style={{ maxWidth: 640, paddingBottom: 10 }}>
          <FormHeaderColorDots
            title={formatMessage({ id: 'rentalUnit.detail.outside.outside' })}
            mandatoryDot={false}
          />
          <OutsideInventory />
          <LandTerrainEdit />
        </ElevatedPaperTable>
      </Grid>
    </>
  );
};

export default OutsideEdits;
