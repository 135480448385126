import DashboardLoader from '../Dashboard/DashboardLoader';

const NotificationsLoader = () => {
  return (
    <>
      <DashboardLoader width="100%" height={120} marginBottom={15} />
      <DashboardLoader width="100%" height={120} marginBottom={15} />
      <DashboardLoader width="100%" height={120} marginBottom={15} />
      <DashboardLoader width="100%" height={120} marginBottom={5} />
    </>
  );
};

export default NotificationsLoader;
