import React from 'react';
import { useFormikContext, FormikProps } from 'formik';
import * as Yup from 'yup';
import { isNil, isEmpty } from 'lodash';
import { makeStyles, createStyles, FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import '../../../../css/TeamSelector.css';
import { ReactComponent as RadioIconChecked } from '../../../../icons/radio-on.svg';
import { ReactComponent as RadioIcon } from '../../../../icons/radio-off.svg';
import { Colors, Team } from '@rentguru/commons-utils';

export interface TeamSelectorFormValues {
  parentId?: string;
  teamId?: string;
}

export const TeamSelectorSchema = Yup.object()
  .shape({
    parentId: Yup.string().nullable(),
    teamId: Yup.string(),
  })
  .required();

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
    },

    group: {},
    formLabelroot: {
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',
      // For correct alignment with the text.
      verticalAlign: 'bottom',
      WebkitTapHighlightColor: 'transparent',
      marginRight: 16, // used for row presentation of radio/checkbox
      paddingLeft: 12,
    },
    formLabelLabel: {
      fontSize: 16,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.25,
      letterSpacing: 'normal',
      color: Colors.CLASSICAL_BLACK,
    },
  })
);

interface TeamSelectorFieldProps {
  teams: Team[];
  initTeam?: string;
  readonly?: boolean;
  onChange?: (event: string) => void;
  fieldName?: 'teamId' | 'parentId';
}

type StyledTreeItemProps = {
  parentId?: string | null;
  id: string;
  label: string;
  dataTest?: string;
  children?: React.ReactNode;
};

const ListTreeItem: React.FC<StyledTreeItemProps> = ({
  label,
  children,
  parentId: _parentId,
  id,
  dataTest,
  ...other
}) => {
  const classes = useStyles();

  return (
    <li>
      <FormControlLabel
        value={id}
        control={<Radio icon={<RadioIcon />} checkedIcon={<RadioIconChecked />} color="primary" />}
        label={label}
        classes={{ root: classes.formLabelroot, label: classes.formLabelLabel }}
        data-test={dataTest}
        {...other}
      />
      {isNil(children) || isEmpty(children) ? <>{children}</> : <ul style={{ paddingLeft: 40 }}>{children}</ul>}
    </li>
  );
};

const renderTree = (team: Team) => (
  <ListTreeItem
    key={team.id}
    id={team.id}
    parentId={team.parentId}
    label={team.name}
    dataTest={`TeamListTreeItem_${team.id}`}
  >
    {Array.isArray(team.children) ? team.children.map((subTeam) => renderTree(subTeam)) : null}
  </ListTreeItem>
);

const TeamSelectorField: React.FC<TeamSelectorFieldProps> = ({
  teams,
  initTeam,
  readonly = false,
  onChange,
  fieldName = 'parentId',
}) => {
  const { values, handleChange: formikHandleChange }: FormikProps<TeamSelectorFormValues> = useFormikContext();
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formikHandleChange(event);
    if (!isNil(onChange)) {
      onChange((event.target as HTMLInputElement).value as string);
    }
  };

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" disabled={readonly}>
        <RadioGroup
          aria-label="team"
          name={fieldName}
          className={classes.group}
          value={(fieldName === 'parentId' ? values.parentId : values.teamId) || initTeam}
          onChange={handleChange}
        >
          <ul className="tree">{teams.map((tt) => renderTree(tt))}</ul>
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default TeamSelectorField;
