/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import {
  AccountLabel,
  BankAccount,
  CreatePostingInput,
  CreateTransactionInput,
  getAmountReconciledOfTransaction,
  getExcludedVatAmountFromIncludedVatAmount,
  getPostingsWithAmountLeftToPay,
  Invoice,
  InvoiceWithPostings,
  isPostingARentDiscount,
  isPostingARentPayment,
  Posting,
  PostingForeignType,
  PostingType,
  roundAtSecondDecimal,
  Transaction,
  TransactionLinkType,
  TransactionStatus,
  uniquePush,
  UpdateInvoiceInput,
  UpdateTransactionInput,
} from '@rentguru/commons-utils';
import { endOfMonth, startOfMonth } from 'date-fns';
import { isEmpty, isNil, sumBy } from 'lodash';
import { fetchInvoices } from 'src/hooks/InvoicesContext';
import { TransactionContext } from 'src/hooks/TransactionsContext';
import { v4 as uuidv4 } from 'uuid';
import { PostingWithAmountSelected, TransactionWithAmountLinked } from './LeaseTransactionsReconcilation';

type ReconciliationType = 'TRANSACTION_AND_INVOICES' | 'TRANSACTION_AND_TRANSACTION' | 'INVOICES' | 'NONE';

export const getReconciliationType = (
  postingsSelected: PostingWithAmountSelected[],
  positiveTransactionSelected: TransactionWithAmountLinked | null,
  negativeTransactionSelected: TransactionWithAmountLinked | null,
  invoicesToReconcile: InvoiceWithPostings[]
): ReconciliationType => {
  if (
    isEmpty(postingsSelected) &&
    positiveTransactionSelected &&
    negativeTransactionSelected &&
    Math.abs(positiveTransactionSelected.amountToBeReconciled) ===
      Math.abs(negativeTransactionSelected.amountToBeReconciled)
  ) {
    // One positive transaction with one negative transaction EXACT match
    return 'TRANSACTION_AND_TRANSACTION';
  }
  const amountLeftToAssign = getAmountOfSelection(
    postingsSelected,
    positiveTransactionSelected,
    negativeTransactionSelected
  );
  if (
    !positiveTransactionSelected &&
    !negativeTransactionSelected &&
    !isEmpty(postingsSelected) &&
    amountLeftToAssign === 0
  ) {
    // Bundle of invoices and credit note
    return 'INVOICES';
  }
  const oneTransactionSelected =
    (positiveTransactionSelected && !negativeTransactionSelected) ||
    (!positiveTransactionSelected && negativeTransactionSelected);
  if (oneTransactionSelected && !isEmpty(postingsSelected) && amountLeftToAssign === 0) {
    // One transaction is paying for multiple invoices and exact match
    return 'TRANSACTION_AND_INVOICES';
  }
  const allPostingsAreSelected = areAllPostingsSelected(invoicesToReconcile, postingsSelected);
  if (oneTransactionSelected && !isEmpty(postingsSelected) && allPostingsAreSelected) {
    // One transaction is paying for multiple invoices and no exact match because we have no invoices left to pay
    const amountLeftToAssignLowerThanTransaction =
      (positiveTransactionSelected &&
        amountLeftToAssign > 0 &&
        amountLeftToAssign < positiveTransactionSelected.amountToBeReconciled) ||
      (negativeTransactionSelected &&
        amountLeftToAssign < 0 &&
        amountLeftToAssign > negativeTransactionSelected.amountToBeReconciled);
    // We allow this exceptionnal case, only if the new tranaction
    // that will be created resulting this reconciliation will
    // be a reduced version of the initial one. This means that it should not change of sign and have a lower amount.
    // Example 1: transaction of +200 can become a new transaction of ]0, 199]
    // example 2: transaction of -500 can become a new transaction of [-499, 0[
    if (amountLeftToAssignLowerThanTransaction) return 'TRANSACTION_AND_INVOICES';
  }
  return 'NONE';
};

const areAllPostingsSelected = (
  invoicesToReconcile: InvoiceWithPostings[],
  postingsSelected: PostingWithAmountSelected[]
) => {
  // true if we can't find an invoice that is not selected
  return (
    !isEmpty(invoicesToReconcile) &&
    !invoicesToReconcile.some((invoice) => !isInvoiceSelected(invoice, postingsSelected))
  );
};

export const getAmountOfSelection = (
  postingsSelected: PostingWithAmountSelected[],
  positiveTransactionSelected?: TransactionWithAmountLinked | null,
  negativeTransactionSelected?: TransactionWithAmountLinked | null
) => {
  let amountSelected = 0;
  if (positiveTransactionSelected) amountSelected += positiveTransactionSelected.amountToBeReconciled;
  if (negativeTransactionSelected) amountSelected += negativeTransactionSelected.amountToBeReconciled;
  if (postingsSelected) amountSelected -= sumBy(postingsSelected, 'amountSelected');
  return roundAtSecondDecimal(amountSelected);
};

export const isInvoiceSelected = (invoice: InvoiceWithPostings, postingsSelected: PostingWithAmountSelected[]) => {
  const invoicePostingsWithAmountLeftToPay = getPostingsWithAmountLeftToPay(invoice.postings, true);
  // true if we can't find a posting that is not selected or that is not paid entirely by the selection
  return !invoicePostingsWithAmountLeftToPay.some((posting) => {
    const postingSelection = postingsSelected.find((p) => p.id === posting.id);
    return !(postingSelection && postingSelection.amountSelected === posting.totalAmount);
  });
};

export const isPostingSelected = (posting: Posting, postingsSelected: PostingWithAmountSelected[]) => {
  return postingsSelected.some((p) => p.id === posting.id);
};

const markInvoicesAsUnpaid = async (
  deletedPostings: Posting[],
  updateInvoice: (updates: UpdateInvoiceInput) => Promise<Invoice>,
  allInvoices?: Invoice[]
) => {
  let allPostingInvoices = allInvoices;
  if (isNil(allPostingInvoices)) {
    const allPostingLeases = deletedPostings.reduce((acc: string[], posting) => {
      if (posting.lease) uniquePush(acc, posting.lease.id);
      return acc;
    }, []);
    const allLeasesInvoices = await Promise.all(
      allPostingLeases.map(async (leaseId) => {
        return fetchInvoices('byLease', leaseId);
      })
    );
    allPostingInvoices = allLeasesInvoices.flat();
  }
  const allInvoicesToMarkAsUnpaid = deletedPostings.reduce((acc: string[], posting) => {
    if (posting.invoiceId) uniquePush(acc, posting.invoiceId);
    return acc;
  }, []);
  const invoicesMarkedAsUnpaid = await Promise.all(
    allInvoicesToMarkAsUnpaid.map(async (invoiceId) => {
      const completeInvoice = allPostingInvoices?.find((i) => i.id === invoiceId);
      if (completeInvoice && completeInvoice.paid) {
        return await updateInvoice({
          id: invoiceId,
          paid: false,
          _version: completeInvoice._version,
          leaseId: completeInvoice.leaseId,
        });
      }
    })
  );
  return invoicesMarkedAsUnpaid.filter((i) => !isNil(i)) as Invoice[];
};

export const unlinkTransaction = async (
  transaction: Transaction,
  transactionPostings: Posting[],
  originalTransactions: Transaction[], // Transactions with ALL postings (not only those from current split)
  _unlinkedEntityIds: string[],
  deletePosting: (posting: Posting) => Promise<Posting | null>,
  updateInvoice: (updates: UpdateInvoiceInput) => Promise<Invoice>,
  updateTransaction: (updates: UpdateTransactionInput) => Promise<Transaction>,
  deleteTransaction: (transaction: Transaction) => Promise<Transaction | null>,
  transactionInvoices?: Invoice[]
) => {
  const updateTransactionsPromises: Promise<Transaction>[] = [];
  const deleteTransactionPromises: Promise<Transaction | null>[] = [];
  const deletedForeignPostingsPromises: Promise<{ updatedTransactions: Transaction[]; deletedPostings: Posting[] }>[] =
    [];
  // Delete all the postings of the transaction
  const deletePostingsPromises = transactionPostings.reduce((acc: Promise<Posting>[], posting) => {
    acc.push(deletePosting(posting) as Promise<Posting>);
    if (posting.foreignId && posting.foreignType === PostingForeignType.TRANSACTION_RECONCILIATION) {
      // + delete an eventual foreign posting (= linked with another transaction)
      deletedForeignPostingsPromises.push(
        deleteForeignPosting(posting, originalTransactions, updateTransaction, deletePosting)
      );
    }
    return acc;
  }, []);
  // Update the invoices accordingly
  const updateInvoicesAsUnpaidPromise = markInvoicesAsUnpaid(transactionPostings, updateInvoice, transactionInvoices);

  const deletePostingsPromise = Promise.all(deletePostingsPromises);
  const deletedForeignPostingsPromise = Promise.all(deletedForeignPostingsPromises);
  // We MUST await the postings deletions as we may delete their transaction afterwards
  // And it can generate an unauthorized error (because we change client Id of deleted item)
  const [deletedPostings, deletedForeignPostings, updatedInvoices] = await Promise.all([
    deletePostingsPromise,
    deletedForeignPostingsPromise,
    updateInvoicesAsUnpaidPromise,
  ]);

  // Update the transaction to its new correct status
  if (transaction.status === TransactionStatus.IGNORE) {
    // If we unlink a invoices cancelled by credit notes group we delete the fake transaction
    deleteTransactionPromises.push(deleteTransaction(transaction));
  } else {
    // Otherwise we update the status of the transaction
    let newTransactionStatus: TransactionStatus;
    if (transaction.links && transaction.links.length > 1) {
      // Split => Check if reconciled on other leases
      const originalTransaction = originalTransactions.find((t) => t.id === transaction.id);
      const oldAmountReconciled = originalTransaction
        ? getAmountReconciledOfTransaction(originalTransaction.postings ?? [])
        : 0;
      const unlinkedAmount = getAmountReconciledOfTransaction(transactionPostings);
      newTransactionStatus =
        oldAmountReconciled === unlinkedAmount
          ? TransactionStatus.TO_RECONCILE
          : TransactionStatus.PARTIALLY_RECONCILED;
    } else {
      newTransactionStatus = TransactionStatus.TO_RECONCILE;
    }
    updateTransactionsPromises.push(
      updateTransaction({
        id: transaction.id,
        _version: (transaction as any)._version,
        status: newTransactionStatus,
      })
    );
  }

  const updateTransactionsPromise = Promise.all(updateTransactionsPromises);
  const deleteTransactionPromise = Promise.all(deleteTransactionPromises);
  const [updatedTransactions, deletedTransaction] = await Promise.all([
    updateTransactionsPromise,
    deleteTransactionPromise,
  ]);
  // Add the foreign updated transactions and deleted postings to the arrays
  deletedForeignPostings.forEach((deleteForeignPosting) => {
    const { updatedTransactions: foreignUpdatedTransaction, deletedPostings: foreignDeletedPostings } =
      deleteForeignPosting;
    updatedTransactions.push(...foreignUpdatedTransaction);
    deletedPostings.push(...foreignDeletedPostings);
  });

  return {
    deletedPostings,
    updatedInvoices,
    updatedTransactions,
    deletedTransaction: deletedTransaction[0],
  };
};

/**
 * Delete the posting linked to the foreignId of the posting + update its transaction to the correct status
 */
const deleteForeignPosting = async (
  posting: Posting,
  originalTransactions: Transaction[],
  updateTransaction: (updates: UpdateTransactionInput) => Promise<Transaction>,
  deletePosting: (posting: Posting) => Promise<Posting | null> | undefined
): Promise<{ updatedTransactions: Transaction[]; deletedPostings: Posting[] }> => {
  const updatedTransactions: Promise<Transaction>[] = [];
  const deletedPostings: Promise<Posting>[] = [];
  if (posting.foreignId) {
    // get the complete foreign transaction
    const foreignTransaction = originalTransactions.find((t) => t.id === posting.foreignId);
    const foreignPosting = foreignTransaction?.postings?.find(
      (p) => p.foreignId && p.foreignType === PostingForeignType.TRANSACTION_RECONCILIATION
    );
    if (foreignTransaction && foreignPosting) {
      deletedPostings.push(deletePosting(foreignPosting) as Promise<Posting>);
      if (foreignPosting.transaction) {
        const oldAmountReconciled = Math.abs(getAmountReconciledOfTransaction(foreignTransaction.postings ?? []));
        // If the new amount linked becomes 0, the transaction becomes TO_RECONCILE
        const newForeignTransactionStatus =
          oldAmountReconciled === posting.totalAmount
            ? TransactionStatus.TO_RECONCILE
            : TransactionStatus.PARTIALLY_RECONCILED;
        updatedTransactions.push(
          updateTransaction({
            id: foreignTransaction.id,
            _version: (foreignTransaction as any)._version,
            status: newForeignTransactionStatus,
          })
        );
      }
      const updatedTransactionsPromise = Promise.all(updatedTransactions);
      const deletedPostingsPromise = Promise.all(deletedPostings);
      const [updatedTransactionsResult, deletedPostingsResult] = await Promise.all([
        updatedTransactionsPromise,
        deletedPostingsPromise,
      ]);
      return { updatedTransactions: updatedTransactionsResult, deletedPostings: deletedPostingsResult };
    }
  }
  return { updatedTransactions: [], deletedPostings: [] };
};

/**
 * If the posting is a rent posting => This will returns every rent reductions postings
 * And vice-versa
 */
export const getLinkedPostings = (
  posting: Posting,
  otherOriginalPostings: Posting[],
  otherPostingsWithAmount: Posting[]
) => {
  let linkedPostings: Posting[] = [];
  if (isPostingARentDiscount(posting) || isPostingARentPayment(posting)) {
    linkedPostings = otherOriginalPostings.filter(
      (p) => p.id !== posting.id && (isPostingARentDiscount(p) || isPostingARentPayment(p))
    );
  }
  return linkedPostings.map((p) => {
    const postingWithAmountSelectable = otherPostingsWithAmount.find((op) => op.id === p.id);
    return {
      originalPosting: p,
      amountSelectable: postingWithAmountSelectable ? postingWithAmountSelectable.totalAmount : 0,
    };
  });
};

/**
 * First: rent discounts
 * Second: rents
 * Third: the rest
 */
export const sortByRentPriority = <T extends Posting>(postings: T[]) => {
  return postings.sort((p1, p2) => {
    if (isPostingARentDiscount(p1)) return -1;
    if (isPostingARentDiscount(p2)) return 1;
    if (isPostingARentPayment(p1)) return -1;
    if (isPostingARentPayment(p2)) return 1;
    return -1;
  });
};

export const sortInvoicesByRentPriority = (invoicesWithPostings: InvoiceWithPostings[]) =>
  invoicesWithPostings.map((invoiceWithPosting) => ({
    ...invoiceWithPosting,
    postings: sortByRentPriority(invoiceWithPosting.postings),
  }));

export const sortPostingsByPositivessAndInvoices = (postings: PostingWithAmountSelected[], ascending: boolean) => {
  return postings.sort((p1, p2) => {
    if (ascending) {
      if (p1.amountSelected < 0) return -1;
      if (p2.amountSelected < 0) return 1;
      return p1.invoiceId && p2.invoiceId && p1.invoiceId < p2.invoiceId ? -1 : 1;
    }
    if (p1.amountSelected > 0) return -1;
    if (p2.amountSelected > 0) return 1;
    return p1.invoiceId && p2.invoiceId && p1.invoiceId < p2.invoiceId ? -1 : 1;
  });
};

export const getPositiveWithNegativeTransactionReconciliationAmount = (
  positiveTransaction: TransactionWithAmountLinked,
  negativeTransaction: TransactionWithAmountLinked
) => {
  return Math.min(Math.abs(negativeTransaction.amountToBeReconciled), positiveTransaction.amountToBeReconciled);
};

/**
 * Split the transactions into 2 groups.
 * @returns [group1, group2]
 *
 * group1 = reconciled transactions,
 * group2 = Unreconciled transactions,
 */
export const splitTransactionsIntoReconciliationGroups = (transactions: Transaction[], leaseId: string) => {
  return transactions.reduce(
    (acc: [TransactionWithAmountLinked[], TransactionWithAmountLinked[]], transaction) => {
      const splitForLease = transaction.links?.find(
        (t) => t.linkType === TransactionLinkType.LEASE && t.linkId === leaseId
      );
      if (!splitForLease) {
        acc[1].push({ ...transaction, amountLinked: 0, amountToBeReconciled: transaction.amount });
        return acc;
      }
      // The amount to be reconciled for this transaction on this lease
      const transactionAmount = roundAtSecondDecimal(splitForLease.amount);
      if (isNil(transaction.postings) || isEmpty(transaction.postings)) {
        acc[1].push({ ...transaction, amountLinked: 0, amountToBeReconciled: transactionAmount });
        return acc;
      }
      const leaseTransactionPostings = transaction.postings.filter((p) => isNil(p.lease) || p.lease.id === leaseId);
      const amountReconciled = getAmountReconciledOfTransaction(leaseTransactionPostings);
      // The ignored transaction have an amount of 0
      if (amountReconciled === 0 && transaction.status !== TransactionStatus.IGNORE) {
        acc[1].push({ ...transaction, amountToBeReconciled: transactionAmount, amountLinked: 0, postings: [] });
      } else if (amountReconciled !== transactionAmount) {
        // Partially reconciled transaction => split into one reconciled and one not reconciled
        acc[0].push({
          ...transaction,
          amountToBeReconciled: roundAtSecondDecimal(transactionAmount - amountReconciled),
          amountLinked: amountReconciled,
          postings: leaseTransactionPostings,
        }); // Reconciled one
        acc[1].push({
          ...transaction,
          amountToBeReconciled: roundAtSecondDecimal(transactionAmount - amountReconciled),
          amountLinked: amountReconciled,
          postings: [],
        }); // Unreconciled one
      } else {
        acc[0].push({
          ...transaction,
          amountToBeReconciled: roundAtSecondDecimal(transactionAmount - amountReconciled),
          amountLinked: amountReconciled,
          postings: leaseTransactionPostings,
        });
      }

      return acc;
    },
    [[], []]
  );
};

/**
 * This function corrects the amounts of the postings linked to an invoice based on the selection.
 * Example: If a posting is fully selected, its amount left to pay will be 0.
 */
export const updateAmountsBasedOnSelection = (postings: Posting[], selectedPostings: PostingWithAmountSelected[]) => {
  const updatedPostings = [...postings];
  for (const selectPosting of selectedPostings) {
    const correspondingPostingIndex = updatedPostings.findIndex((p) => p.id === selectPosting.id);
    if (correspondingPostingIndex > -1) {
      const correspondingPosting = updatedPostings[correspondingPostingIndex];
      updatedPostings[correspondingPostingIndex] = {
        ...correspondingPosting,
        totalAmount: roundAtSecondDecimal(correspondingPosting.totalAmount - selectPosting.amountSelected),
      };
    }
  }
  return updatedPostings;
};

interface LinkingOperationsBundle {
  createPosting: (input: CreatePostingInput | Omit<CreatePostingInput, 'clientId' | 'readId'>) => Promise<Posting>;
  createTransaction: (
    input: CreateTransactionInput | Omit<CreateTransactionInput, 'readId' | 'clientId'>
  ) => Promise<Transaction>;
  getAccountLabelForLease: () => AccountLabel;
  getAccountLabelForBankAccountOrInsert: (bankAccount: BankAccount) => Promise<AccountLabel>;
  updateInvoice: (updates: UpdateInvoiceInput) => Promise<Invoice>;
  updateTransaction: TransactionContext['updateTransaction'];
}

export const linkTransactionWithInvoices = async (
  transactionSelected: TransactionWithAmountLinked,
  postingsSelected: PostingWithAmountSelected[],
  originalTransactions: Transaction[],
  invoicesToReconcile: InvoiceWithPostings[],
  leaseId: string,
  operationsBundle: LinkingOperationsBundle
): Promise<{ updatedInvoices: Invoice[]; createdPostings: Posting[]; newTransaction: Transaction }> => {
  if (!transactionSelected.bankAccount)
    return { updatedInvoices: [], createdPostings: [], newTransaction: transactionSelected };
  const amountSelected = getAmountOfSelection(postingsSelected);

  const linkedInvoicesToTransactionPromise = linkedSelectedPostings(
    postingsSelected,
    transactionSelected.id,
    leaseId,
    invoicesToReconcile,
    operationsBundle
  );
  const linkTransactionPromise = linkTransaction(
    transactionSelected,
    originalTransactions,
    leaseId,
    amountSelected,
    operationsBundle
  );
  const [{ invoicesPostings, updatedInvoices }, { newTransaction, newTransactionPosting }] = await Promise.all([
    linkedInvoicesToTransactionPromise,
    linkTransactionPromise,
  ]);
  const createdPostings = [...invoicesPostings, newTransactionPosting];
  const originalTransaction = originalTransactions.find((t) => t.id === transactionSelected.id)!;
  // Put back the postings on the transaction with the one one
  Object.assign(newTransaction, { postings: [...(originalTransaction.postings ?? []), ...createdPostings] });
  return { updatedInvoices, createdPostings, newTransaction };
};

export const linkTransactionWithTransaction = async (
  positiveTransaction: TransactionWithAmountLinked,
  negativeTransaction: TransactionWithAmountLinked,
  originalTransactions: Transaction[],
  leaseId: string,
  operationsBundle: LinkingOperationsBundle
) => {
  const reconciliationAmount = positiveTransaction.amountToBeReconciled;
  const linkPositiveTransactionPromise = linkTransaction(
    positiveTransaction,
    originalTransactions,
    leaseId,
    reconciliationAmount,
    operationsBundle,
    {
      foreignId: negativeTransaction.id,
      foreignType: PostingForeignType.TRANSACTION_RECONCILIATION,
    }
  );
  const linkNegativeTransactionPromise = linkTransaction(
    negativeTransaction,
    originalTransactions,
    leaseId,
    reconciliationAmount,
    operationsBundle,
    {
      foreignId: positiveTransaction.id,
      foreignType: PostingForeignType.TRANSACTION_RECONCILIATION,
    }
  );
  const [
    { newTransaction: newPositiveTransaction, newTransactionPosting: newPositiveTransactionPosting },
    { newTransaction: newNegativeTransaction, newTransactionPosting: newNegativeTransactionPosting },
  ] = await Promise.all([linkPositiveTransactionPromise, linkNegativeTransactionPromise]);

  const originalPostiveTransaction = originalTransactions.find((t) => t.id === positiveTransaction.id)!;
  const originalNegativeTransaction = originalTransactions.find((t) => t.id === negativeTransaction.id)!;
  // Put back the postings on the transactions with the one one
  Object.assign(newPositiveTransaction, {
    postings: [...(originalPostiveTransaction.postings ?? []), newPositiveTransactionPosting],
  });
  Object.assign(newNegativeTransaction, {
    postings: [...(originalNegativeTransaction.postings ?? []), newNegativeTransactionPosting],
  });

  return { newPositiveTransaction, newNegativeTransaction };
};

export const linkInvoicesWithCreditNotes = async (
  bankAccountId: string,
  postingsSelected: PostingWithAmountSelected[],
  leaseId: string,
  invoicesToReconcile: InvoiceWithPostings[],
  operationsBundle: LinkingOperationsBundle
) => {
  const { createTransaction } = operationsBundle;
  // See the accounting docs
  const ignoredTransactionId = uuidv4();
  const today = new Date().toISOString();
  const newTransactionPromise = createTransaction({
    id: ignoredTransactionId,
    status: TransactionStatus.IGNORE,
    amount: 0,
    links: [{ amount: 0, linkId: leaseId, linkType: TransactionLinkType.LEASE }],
    statementDate: today,
    operationDate: today,
    updatedAt: today,
    bankAccountId,
  });
  const linkPostingsPromises = linkedSelectedPostings(
    postingsSelected,
    ignoredTransactionId,
    leaseId,
    invoicesToReconcile,
    operationsBundle
  );
  const [newTransaction, { invoicesPostings, updatedInvoices }] = await Promise.all([
    newTransactionPromise,
    linkPostingsPromises,
  ]);
  Object.assign(newTransaction, { postings: invoicesPostings });
  return { updatedInvoices, invoicesPostings, newTransaction };
};

export const linkedSelectedPostings = async (
  postingsSelected: PostingWithAmountSelected[],
  transactionId: string,
  leaseId: string,
  invoicesToReconcile: InvoiceWithPostings[],
  operationsBundle: LinkingOperationsBundle
) => {
  const { createPosting, getAccountLabelForLease, updateInvoice } = operationsBundle;
  const accountForLease = getAccountLabelForLease();

  const createdPostingsPromises: Promise<Posting>[] = [];
  const updateInvoicesPromises: Promise<Invoice>[] = [];

  postingsSelected.forEach((postingSelected) => {
    const postingTotalAmount = Math.abs(postingSelected.amountSelected);
    const postingVATExcludedAmount = getExcludedVatAmountFromIncludedVatAmount(
      postingTotalAmount,
      postingSelected.vatRate
    );
    const vatAmount = roundAtSecondDecimal(postingTotalAmount - postingVATExcludedAmount);
    const today = new Date().toISOString();
    createdPostingsPromises.push(
      createPosting({
        leaseId,
        unitId: postingSelected.unitId,
        transactionId,
        invoiceId: postingSelected.invoiceId,
        invoicePostingId: postingSelected.id,
        leaseVariousOperationId: postingSelected.leaseVariousOperationId,
        type: postingSelected.type as PostingType,
        accountLabelId: accountForLease.id,
        class: accountForLease.class,
        topClass: accountForLease.topClass,
        periodFrom: postingSelected.periodFrom,
        periodTo: postingSelected.periodTo,
        vatRate: postingSelected.vatRate,
        vatAmount,
        amountVatExcluded: postingVATExcludedAmount,
        totalAmount: postingTotalAmount,
        reconciledAt: today,
      })
    );
  });
  // UPDATE INVOICE TO PAID = true
  for (const unpaidInvoice of invoicesToReconcile) {
    // An invoice completly linked is the same as a paid one at this point.
    const postingsWithInitialAmountLeftToPay = getPostingsWithAmountLeftToPay(
      unpaidInvoice.postings,
      false
    ) as unknown as Posting[];
    const postingsWithAmountLeftToPay = updateAmountsBasedOnSelection(
      postingsWithInitialAmountLeftToPay,
      postingsSelected
    );
    const totalAmountLeftToPay = roundAtSecondDecimal(sumBy(postingsWithAmountLeftToPay, 'totalAmount'));
    const invoiceCompletlyLinked = totalAmountLeftToPay === 0;
    if (invoiceCompletlyLinked) {
      updateInvoicesPromises.push(
        updateInvoice({ id: unpaidInvoice.id, paid: true, _version: unpaidInvoice._version, leaseId })
      );
    }
  }
  const createdPostingsPromise = Promise.all(createdPostingsPromises);
  const updateInvoicesPromise = Promise.all(updateInvoicesPromises);
  const [invoicesPostings, updatedInvoices] = await Promise.all([createdPostingsPromise, updateInvoicesPromise]);
  return { invoicesPostings, updatedInvoices };
};

export const linkTransaction = async (
  transaction: TransactionWithAmountLinked,
  originalTransactions: Transaction[],
  leaseId: string,
  amountToLink: number,
  operationsBundle: LinkingOperationsBundle,
  additionalPostingValues: Partial<CreatePostingInput> = {}
) => {
  const { createPosting, getAccountLabelForBankAccountOrInsert, updateTransaction } = operationsBundle;
  const accountForBankAccount = await getAccountLabelForBankAccountOrInsert(transaction.bankAccount!);
  // BANK DEBIT POSTING
  const today = new Date().toISOString();
  const transactionPostingPromise = createPosting({
    leaseId,
    bankAccountId: transaction.bankAccountId,
    type: transaction.amount > 0 ? PostingType.DEBIT : PostingType.CREDIT,
    accountLabelId: accountForBankAccount.id,
    class: accountForBankAccount.class,
    topClass: accountForBankAccount.topClass,
    transactionId: transaction.id,
    totalAmount: Math.abs(amountToLink),
    periodFrom: startOfMonth(new Date(transaction.operationDate!)).toISOString(),
    periodTo: endOfMonth(new Date(transaction.operationDate!)).toISOString(),
    reconciledAt: today,
    ...additionalPostingValues,
  });
  let newTransactionStatus: TransactionStatus;
  if (transaction.links && transaction.links.length > 1) {
    // Splitted transaction => We have to get the amount reconciled by ALL the postings to calculate the new Status
    const originalTransaction = originalTransactions.find((t) => t.id === transaction.id);
    const currentReconciledAmount = originalTransaction
      ? getAmountReconciledOfTransaction(originalTransaction.postings ?? [])
      : 0;
    const newReconciledAmount = roundAtSecondDecimal(currentReconciledAmount + amountToLink);
    // The transaction is reconciled, only if
    // The covered amount by ALL the transaction + the new linked amount = the total amount of the transation
    newTransactionStatus =
      transaction.amount === newReconciledAmount
        ? TransactionStatus.RECONCILED
        : TransactionStatus.PARTIALLY_RECONCILED;
  } else {
    newTransactionStatus =
      roundAtSecondDecimal(transaction.amountToBeReconciled + amountToLink) === 0
        ? TransactionStatus.RECONCILED
        : TransactionStatus.PARTIALLY_RECONCILED;
  }
  const newTransactionPromise = updateTransaction({
    id: transaction.id,
    _version: (transaction as any)._version,
    status: newTransactionStatus,
  });
  const [newTransactionPosting, newTransaction] = await Promise.all([transactionPostingPromise, newTransactionPromise]);
  return { newTransaction, newTransactionPosting };
};
