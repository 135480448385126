import { Grid, InputAdornment } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import TextDetailEditable from '../../TextDetailEditable';

export interface SurfaceOfUnitFormValues {
  surface: number;
}

interface SurfaceOfUnitProps {
  fieldName?: string;
}

const SurfaceOfUnitFields: React.FC<SurfaceOfUnitProps> = ({ fieldName = 'surface' }) => {
  const { formatMessage } = useIntl();
  const { errors } = useFormikContext();
  return (
    <Grid style={{ width: 580, textAlign: 'left', marginLeft: 30, marginBottom: 20, marginTop: 20 }}>
      <TextDetailEditable
        editMode
        title={formatMessage({ id: 'rentalUnit.detail.general.surface' })}
        name={fieldName}
        type="number"
        style={{ width: 580 }}
        min={0}
        endAdornment={<InputAdornment position="end">m²</InputAdornment>}
        typeNumberNoEndArrow
        noMaxWidth
        error={Boolean(get(errors, fieldName))}
      />
    </Grid>
  );
};

export default SurfaceOfUnitFields;
