/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-shadow */
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { calculateDistanceBetweenCoordinatesInMeters, Colors, NearbyComodity } from '@rentguru/commons-utils';
import { FormikProps, useFormikContext } from 'formik';
import { isEmpty, isNil } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { CustomPopover } from '@up2rent/ui';
import { StructureHelperBundle } from 'src/components/ui/Forms/FormSection/UnitStructureForm';
import ResetIcon from 'src/components/ui/ResetIcon';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as Add } from 'src/icons/add.svg';
import { ReactComponent as Delete } from 'src/icons/delete.svg';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import AlignedTextDetail from 'src/components/RentalUnits/Details/Publication/AlignedTextDetail';
import { EditAdvertisementFormValues } from 'src/components/RentalUnits/Details/Publication/EditPublication/EditPublicationForm';
import AddCommoditiesDialog from './AddCommoditiesDialog';

const GOOGLE_MAPS_TOKEN = import.meta.env.VITE_GOOGLE_MAPS_TOKEN;

const useLocalStyles = makeStyles({
  h6: {
    flex: 1,
    textAlign: 'left',
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Mulish',
    fontSize: '16px',
    fontWeight: 700,
    margin: '20px 0 30px 30px',
  },
  dialogTitle: {
    flex: 1,
    textAlign: 'left',
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Mulish',
    fontSize: '16px',
    fontWeight: 700,
    margin: '10px 0 5px 30px',
  },
  title: {
    flex: 1,
    textAlign: 'left',
    color: Colors.CHARCOAL_GREY,
    fontFamily: 'Mulish',
    fontSize: '14px',
    fontWeight: 700,
    margin: '20px 0 0 0px',
  },
  commodity: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    margin: '30px 30px 0 45px',
    padding: '0 0 20px 0',
    bottom: 0,
  },
  paper: { borderRadius: '10px' },
});

enum NearbyCommoditiesTypes {
  RESTAURANT = 'RESTAURANT',
  SUPERMARKET = 'SUPERMARKET',
  TRANSPORTS = 'TRANSPORTS',
  SCHOOL = 'SCHOOL',
  HEALTHCARE = 'HEALTHCARE',
}
interface PlaceDetailDistanceType {
  type: NearbyCommoditiesTypes | undefined;
  placeDetailDistance: PlaceDetailDistanceSubType[];
}
interface PlaceDetailDistanceSubType {
  place: PlaceDetailCommodity | undefined;
  distanceWithCurrentUnit: number;
  subType: string;
}

interface PlaceDetailCommodity {
  name: string;
  short_name: string;
  types: string[];
}

interface commodityDisplay {
  type: string;
  data: {
    name: string;
    distance: number;
  }[];
}

// If need to add new type, check supported types on google:
// https://developers.google.com/maps/documentation/places/web-service/supported_types
const getArrayOfGoogleTypesAccordingToGeneralType = (type: NearbyCommoditiesTypes): string[] => {
  switch (type) {
    case NearbyCommoditiesTypes.SCHOOL:
      return ['school', 'primary_school', 'secondary_school', 'university'];
    case NearbyCommoditiesTypes.TRANSPORTS:
      return ['bus_station', 'subway_station', 'light_rail_station', 'train_station'];
    case NearbyCommoditiesTypes.SUPERMARKET:
      return ['supermarket', 'shopping_mall'];
    case NearbyCommoditiesTypes.RESTAURANT:
      return ['restaurant'];
    case NearbyCommoditiesTypes.HEALTHCARE:
      return ['dentist', 'doctor', 'drugstore', 'hospital', 'pharmacy'];
    default:
      return [];
  }
};

const CommoditiesEdit = () => {
  const { formatMessage } = useIntl();
  const classes = useLocalStyles();
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(Boolean(document.querySelector('#google-maps')));
  const { language } = useLocale();
  const { values, setFieldValue }: FormikProps<EditAdvertisementFormValues> = useFormikContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newCommodityName, setNewCommodityName] = useState('');
  const [newCommodityDistance, setNewCommodityDistance] = useState('');
  const [selectedCommodityType, setselectedCommodityType] = useState('');
  const [openStructureHelper, setOpenStructureHelper] = useState<StructureHelperBundle>({
    anchor: null,
    inside: false,
  });

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const resetValues = () => {
    setNewCommodityDistance('');
    setNewCommodityName('');
  };

  const handlePopoverOpen = (event: React.MouseEvent<SVGSVGElement, MouseEvent>, inside: boolean) => {
    setOpenStructureHelper({ anchor: event.currentTarget, inside });
  };

  const handlePopoverClose = () => {
    setOpenStructureHelper({ anchor: null, inside: false });
  };

  const loadScript = (src: string, position: HTMLElement | null, id: string) => {
    if (!position) {
      return;
    }
    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    script.addEventListener('load', () => {
      setScriptLoaded(true);
    });
    position.appendChild(script);
  };

  if (typeof window !== 'undefined' && !scriptLoaded) {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_TOKEN}&libraries=places&language=${language}`,
      document.querySelector('head'),
      'google-maps'
    );
  }

  const sendRequest = useCallback(() => {
    const nearestCommodityAccordingToType: PlaceDetailDistanceType[] = [];
    if (!(window as any).google) {
      return;
    }
    const google = (window as any).google;

    const adresse = new google.maps.LatLng(values.building.address.latitude, values.building.address.longitude);
    const map = new google.maps.Map(document.getElementById('nearbyCommoditiesMap'), {
      center: adresse,
    });

    const service = new google.maps.places.PlacesService(map);

    Object.keys(NearbyCommoditiesTypes).forEach((generalType) => {
      const arrayOfTypesToCheckOnGoogleAPI = getArrayOfGoogleTypesAccordingToGeneralType(
        generalType as NearbyCommoditiesTypes
      );
      arrayOfTypesToCheckOnGoogleAPI.forEach((subType) => {
        const request = {
          location: adresse,
          radius: '2000',
          fields: ['name', 'geometry'],
          type: subType,
        };
        service.nearbySearch(request, (results: PlaceDetailCommodity, status: 'OK' | 'ZERO_RESULTS') => {
          if (status !== 'OK') {
            return;
          }
          Object.values(results).forEach((result) => {
            const currentNearestObjectIndexAccordingToTypeInArray = nearestCommodityAccordingToType.findIndex(
              (commodityWithType) => commodityWithType.type === generalType
            );
            const resultLon = result.geometry.location.lng();
            const resultLat = result.geometry.location.lat();
            const unitLon = values.building.address.longitude as number;
            const unitLat = values.building.address.latitude as number;
            const distancebetweenCurrentResultAndCurrentUnit = calculateDistanceBetweenCoordinatesInMeters(
              resultLat,
              resultLon,
              unitLat,
              unitLon
            );
            // If the type is not yet in the array
            if (currentNearestObjectIndexAccordingToTypeInArray === -1) {
              nearestCommodityAccordingToType.push({
                type: generalType as NearbyCommoditiesTypes,
                placeDetailDistance: [
                  {
                    place: result,
                    distanceWithCurrentUnit:
                      10 * Math.floor(Math.ceil(distancebetweenCurrentResultAndCurrentUnit) / 10),
                    subType,
                  },
                ],
              });

              // The generalType is in the array
            } else {
              // Get the index of the subType
              const subTypeIndex = nearestCommodityAccordingToType[
                currentNearestObjectIndexAccordingToTypeInArray
              ].placeDetailDistance.findIndex((placeDetail) => placeDetail.subType === subType);
              // replace the value if we found a closer one.
              if (
                subTypeIndex !== -1 &&
                nearestCommodityAccordingToType[currentNearestObjectIndexAccordingToTypeInArray].placeDetailDistance[
                  subTypeIndex
                ].distanceWithCurrentUnit > distancebetweenCurrentResultAndCurrentUnit
              ) {
                nearestCommodityAccordingToType[currentNearestObjectIndexAccordingToTypeInArray].placeDetailDistance[
                  subTypeIndex
                ] = {
                  subType,
                  place: result,
                  distanceWithCurrentUnit: Math.ceil(distancebetweenCurrentResultAndCurrentUnit),
                };
              }
              // Add the value if we did not find one yet.
              if (subTypeIndex === -1) {
                nearestCommodityAccordingToType[
                  currentNearestObjectIndexAccordingToTypeInArray
                ].placeDetailDistance.push({
                  subType,
                  place: result,
                  distanceWithCurrentUnit: Math.ceil(distancebetweenCurrentResultAndCurrentUnit),
                });
              }
            }
          });

          // setNearestCommodityAccordingToTypeState(nearestCommodityAccordingToType);
          const finalValue: NearbyComodity[] = [];
          nearestCommodityAccordingToType.forEach((generalType) => {
            generalType.placeDetailDistance.forEach((subType) => {
              const commodity: NearbyComodity = {
                type: generalType.type as NearbyCommoditiesTypes,
                name: subType.place!.name,
                distance: 10 * Math.floor(subType.distanceWithCurrentUnit / 10),
              };
              finalValue.push(commodity);
            });
          });
          /* Pay attention and respect below format!! */
          setFieldValue('advertisement.nearbyComodities', finalValue);
        });
      });
    });
  }, [values.building.address.latitude, values.building.address.longitude, setFieldValue]);

  useEffect(() => {
    if (scriptLoaded && isNil(values.advertisement?.nearbyComodities)) {
      // ('Fetching nearby commodities');
      sendRequest();
    }
  }, [sendRequest, scriptLoaded, values.advertisement]);

  const commodities: commodityDisplay[] = [];
  if (values.advertisement.nearbyComodities) {
    values.advertisement.nearbyComodities?.forEach((commodity) => {
      const currentIndex = commodities.findIndex((type) => type.type === commodity.type);
      if (currentIndex === -1) {
        commodities.push({
          type: commodity.type,
          data: [
            {
              name: commodity.name,
              distance: commodity.distance,
            },
          ],
        });
      } else {
        commodities[currentIndex].data.push({
          name: commodity.name,
          distance: commodity.distance,
        });
      }
    });
  }

  const deleteCommodity = (generalIndex: any, subIndex: any) => {
    const elementToRemove = commodities[generalIndex].data[subIndex];
    if (values.advertisement.nearbyComodities) {
      const newCommodities = values.advertisement.nearbyComodities.filter(
        (commodity) =>
          !(
            commodity.type === commodities[generalIndex].type &&
            commodity.name === elementToRemove.name &&
            commodity.distance === elementToRemove.distance
          )
      );
      setFieldValue('advertisement.nearbyComodities', newCommodities);
    }
  };

  const addNewCommodity = () => {
    if (values.advertisement.nearbyComodities) {
      // Change memory address to get prompt in case me press return button without saving.
      const newCommodities = [
        ...values.advertisement.nearbyComodities,
        {
          type: selectedCommodityType as NearbyCommoditiesTypes,
          distance: 10 * Math.floor(Number(newCommodityDistance) / 10),
          name: newCommodityName,
        },
      ];

      setFieldValue('advertisement.nearbyComodities', newCommodities);
    }
  };

  return (
    <>
      {!isNil(values.advertisement.nearbyComodities) && !isEmpty(values.advertisement.nearbyComodities) ? (
        <>
          <Grid container>
            <Grid item xs={5} style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6" color="inherit" className={classes.h6}>
                {formatMessage({ id: 'rentalUnit.editUnit.advertisement.nearbyCommodities.nearbyCommodities' })}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                position: 'relative',
                bottom: 5,
              }}
            >
              <InfoSvg
                style={{
                  cursor: 'default',
                  fill: Colors.NOBEL_GREY,
                }}
                onMouseEnter={(e) => handlePopoverOpen(e, true)}
                onMouseLeave={handlePopoverClose}
              />
            </Grid>
            <Grid style={{ display: 'flex', alignItems: 'center', position: 'relative', bottom: 5 }}>
              <ResetIcon style={{ fill: Colors.LIGHT_BLUE_GREY }} onClick={() => sendRequest()} />
            </Grid>
            <Grid item xs={12} />
          </Grid>
          <Grid container>
            {commodities &&
              !isEmpty(commodities) &&
              commodities.map((generalType, generalIndex) => {
                if (!isNil(generalType.data) && !isEmpty(generalType.data)) {
                  return (
                    <Grid key={generalType.type} item xs={6} style={{ marginBottom: 15 }}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography
                            variant="h6"
                            color="inherit"
                            className={classes.title}
                            style={{ paddingLeft: generalIndex % 2 === 0 ? 30 : 0 }}
                          >
                            {formatMessage({
                              id:
                                generalType.data.length > 1
                                  ? `rentalUnit.editUnit.advertisement.commodityType.plural.${generalType.type}`
                                  : `rentalUnit.editUnit.advertisement.commodityType.singular.${generalType.type}`,
                            })}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={{
                            cursor: 'pointer',
                            marginLeft: generalIndex % 2 === 0 ? 40 : 10,
                            marginTop: 15,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '78%',
                          }}
                          onClick={() => setDialogOpen(true)}
                        >
                          <Add style={{ fill: Colors.LIGHT_BLUE_GREY, transform: 'scale(0.7)' }} />
                          <Typography
                            style={{
                              borderRadius: 6,
                              padding: 5,
                              paddingLeft: 0,
                              color: Colors.LIGHT_BLUE_GREY,
                            }}
                            onClick={() => setselectedCommodityType(generalType.type)}
                          >
                            {formatMessage({ id: 'contact.addContact.action' })}
                          </Typography>
                        </Grid>
                      </Grid>
                      {generalType.data!.map((subType, subIndex) => (
                        <Grid
                          container
                          key={`${subType.name}${subIndex}${generalIndex}`}
                          style={{ marginLeft: generalIndex % 2 === 0 ? 28 : -2 }}
                        >
                          <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                            <Delete
                              style={{ fill: Colors.LIGHT_BLUE_GREY }}
                              onClick={() => deleteCommodity(generalIndex, subIndex)}
                            />
                          </Grid>
                          <Grid item xs={10}>
                            <AlignedTextDetail
                              style={{ fontWeight: 'normal', textAlign: 'right' }}
                              title={subType.name}
                              text={subType.distance}
                              endAdornment=" m"
                              comoditiesDisplay={true}
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  );
                }
                return null;
              })}
          </Grid>
          <AddCommoditiesDialog
            addNewCommodity={addNewCommodity}
            dialogOpen={dialogOpen}
            handleCloseDialog={handleCloseDialog}
            resetValues={resetValues}
            newCommodityDistance={newCommodityDistance}
            newCommodityName={newCommodityName}
            setNewCommodityDistance={setNewCommodityDistance}
            setNewCommodityName={setNewCommodityName}
          />
          <div id="nearbyCommoditiesMap" />
          <Divider style={{ margin: '25px 0 0 0' }} />
          <CustomPopover
            open={!isNil(openStructureHelper.anchor)}
            anchorEl={openStructureHelper.anchor}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
            paperPadding={5}
            paperWidth={'unset'}
          >
            <Typography>{formatMessage({ id: 'rentalUnit.editUnit.advertisement.addCommodity.help' })}</Typography>
          </CustomPopover>
        </>
      ) : (
        <div id="nearbyCommoditiesMap" />
      )}
    </>
  );
};

export default CommoditiesEdit;
