import React from 'react';
import { useHistory } from 'react-router-dom';
import { friendlyFormatIBAN } from 'ibantools';
import { Typography, Grid, Divider, makeStyles } from '@material-ui/core';
import { BankAccount, Colors, IntegrationName, getContactNameFromObject } from '@rentguru/commons-utils';
import { ReactComponent as ExternalIcon } from 'src/icons/external.svg';
import { RouteDestination } from 'src/components/Routes/Routes';
import { CustomIconButton } from '@up2rent/ui';
import { AddEditBankMenuBundle } from 'src/components/Settings/BankAccountsSettings';
import { IntegrationColumn } from './IntegrationColumn';
import MoreHorizontalIconButton from 'src/components/ui/CustomIconButtons/MoreHorizontalIconButton';

export const useStyles = makeStyles({
  button: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  rowContainer: { paddingLeft: 5, paddingTop: 15, paddingBottom: 15 },
});

interface BankAccountRowProps {
  bankAccount: BankAccount;
  openMenu: (bankAccountBundle: AddEditBankMenuBundle) => void;
  divider: boolean;
  disabled?: boolean;
}
const BankAccountRow: React.FC<BankAccountRowProps> = ({ bankAccount, openMenu, disabled, divider = true }) => {
  const history = useHistory();
  const classes = useStyles();
  const currentIntegrations = bankAccount.integrations as IntegrationName[];

  return (
    <div style={{ backgroundColor: disabled ? Colors.PORCELAIN_GREY_3 : Colors.CLASSICAL_WHITE }}>
      <Grid container className={classes.rowContainer} alignItems="center">
        <Grid item xs={4} style={{ display: 'flex', alignItems: 'flex-start' }}>
          <Typography style={{ fontSize: 14, fontWeight: 600, textAlign: 'left' }}>
            {bankAccount.contact ? getContactNameFromObject(bankAccount.contact) : ''}
          </Typography>
          {bankAccount.contact && (
            <CustomIconButton
              id="goToContact"
              size="small"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                history.push({
                  pathname: `${RouteDestination.CONTACTS}/${bankAccount.contact!.id}`,
                  state: { goBackUrl: history.location },
                });
              }}
              className={classes.button}
              Icon={ExternalIcon}
              iconStyle={{ fill: Colors.DODGER_BLUE, marginLeft: '5px' }}
            />
          )}
        </Grid>
        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'left' }}>
          <Typography variant="subtitle1" style={{ fontSize: 14, color: Colors.SLATE_GREY, textAlign: 'left' }}>
            {bankAccount.bankName}
          </Typography>
        </Grid>
        <Grid item xs={1} style={{ display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
          {currentIntegrations?.map((integration) => (
            <IntegrationColumn key={integration} integrationName={integration} />
          ))}
        </Grid>
        <Grid
          item
          xs={3}
          style={{ display: 'flex', justifyContent: 'left', flexDirection: 'column', alignItems: 'flex-end' }}
        >
          <Typography variant="subtitle1" style={{ fontSize: 14, color: Colors.SLATE_GREY }}>
            {friendlyFormatIBAN(bankAccount.number)}
          </Typography>
          {bankAccount.bicNumber && (
            <Typography
              style={{
                fontSize: 12,
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 'normal',
                letterSpacing: 'normal',
                textAlign: 'right',
                color: Colors.SLATE_GREY,
              }}
            >
              {bankAccount.bicNumber}
            </Typography>
          )}
        </Grid>
        <Grid item xs={1}>
          <MoreHorizontalIconButton
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              openMenu({ anchorEl: event.currentTarget, bankAccount });
            }}
            size="small"
            iconStyle={{ verticalAlign: 'middle', fill: Colors.SLATE_GREY }}
          />
        </Grid>
      </Grid>
      {divider && <Divider />}
    </div>
  );
};

export default BankAccountRow;
