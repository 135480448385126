import { TableCell, TableRow, Typography } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import React from 'react';
import { useIntl } from 'react-intl';

const TransactionsHeader = () => {
  const { formatMessage } = useIntl();
  const headerPadding = { paddingLeft: 0, paddingBottom: 10, paddingRight: 0, paddingTop: 10 };
  return (
    <TableRow>
      <TableCell align="left" padding="none" style={{ ...headerPadding, paddingLeft: 20 }}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'transactions.list.date' })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none" style={headerPadding}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'transactions.list.amount' })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none" style={headerPadding}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'transactions.list.from' })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none" style={headerPadding}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'transactions.list.to' })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none" />
      <TableCell align="left" padding="none" style={headerPadding}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'transactions.list.linkTo' })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none" style={headerPadding}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'transactions.list.rememberRule' })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none" style={headerPadding} />
      <TableCell align="left" padding="none" style={headerPadding}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'transactions.list.action' })}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default TransactionsHeader;
