/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
import React from 'react';
import { Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { MessageDescriptor, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { isNil } from 'lodash';
import TextDetailEditable from '../../../ui/TextDetailEditable';
import { isAfter } from 'date-fns';
import { getDetailedOverlappingError } from 'src/utils/unitsUtils';
import { dateLocaleMap } from 'src/i18n/IntlProviderWrapper';
import { getAllOverlappableEvents, getProblematicEvent, Event } from './RenovationCostsFields';
import CustomDatePicker from '../../CustomDatePicker';
import { Colors, endOfUTCDay, getUTCDate, startOfUTCDay, Unit } from '@rentguru/commons-utils';

export const getDynamicOwnUseSchema = (
  entity: Unit,
  formatMessage: (descriptor: MessageDescriptor, values?: any) => string,
  language: string
) => {
  return Yup.object()
    .shape({
      id: Yup.string().min(1).required(),
      startDate: Yup.string().required(),
      endDate: Yup.string()
        .required()
        .test('After start date', 'overlapping own uses dates error', function (value) {
          if (isNil(value)) return false;

          return isAfter(new Date(value), new Date(this.parent.startDate));
        }),
      note: Yup.string().nullable(),
    })
    .test('Events test', 'No overlapping events error', function (event) {
      if (isNil(event) || isNil(event.id) || isNil(event.startDate) || isNil(event.endDate)) return true;
      const cleanEvent = { id: event.id, startDate: event.startDate, endDate: event.endDate };
      const allEvents = getAllOverlappableEvents(entity, [cleanEvent], false);
      const problematicEvent = getProblematicEvent([cleanEvent], allEvents);
      if (isNil(problematicEvent)) return true;

      const { problemEvent } = problematicEvent;
      return this.createError({
        path: `overlapping`,
        message: getDetailedOverlappingError(problemEvent, formatMessage, dateLocaleMap[language]),
      });
    })
    .required();
};

const OwnUseFields: React.FC = () => {
  const { values, errors, touched, setFieldValue } = useFormikContext<Event>();
  const { formatMessage } = useIntl();

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CustomDatePicker
          name="startDate"
          label={formatMessage({ id: 'mortgage.startDate' })}
          value={values.startDate ? getUTCDate(new Date(values.startDate)) : null}
          style={{ width: 280 }}
          onChange={(date) => {
            if (date) setFieldValue(`startDate`, startOfUTCDay(date).toISOString());
          }}
          safeDateFormat={true}
          error={Boolean(errors.startDate && touched.startDate)}
        />
        <CustomDatePicker
          name="endDate"
          label={formatMessage({ id: 'lease.addLease.endDate' })}
          value={values.endDate ? getUTCDate(new Date(values.endDate)) : null}
          onChange={(date) => {
            if (date) setFieldValue(`endDate`, endOfUTCDay(date).toISOString());
          }}
          style={{ width: 280, marginLeft: 20 }}
          error={Boolean(errors.endDate && touched.endDate)}
          minDate={!isNil(values.startDate) ? new Date(values.startDate) : undefined}
        />
      </div>
      {Boolean((errors as any).overlapping) && (
        <div style={{ textAlign: 'center' }}>
          <Typography style={{ color: Colors.BURNING_ORANGE }}>{formatMessage({ id: 'event.overlapping' })}</Typography>
          <Typography style={{ color: Colors.BURNING_ORANGE }}>{(errors as any).overlapping}</Typography>
        </div>
      )}
      <TextDetailEditable
        editMode={true}
        title={formatMessage({
          id: 'mortgage.note',
        })}
        name="note"
        noMaxWidth
        rows={1}
        rowsMax={4}
        multiline
        type="text"
        text={isNil(values.note) ? '' : values.note}
        error={Boolean(errors.note && touched.note)}
      />
    </div>
  );
};

export default OwnUseFields;
