import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ButtonGroup, Button, IconButton, Typography, TextField } from '@material-ui/core';
import { ReactComponent as Minus } from '../../icons/remove.svg';
import { ReactComponent as Plus } from '../../icons/add.svg';
import { withStyles } from '@material-ui/styles';
import isNil from 'lodash/isNil';
import { clamp } from 'lodash';
import { Colors } from '@rentguru/commons-utils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& > *': {
        margin: 10,
      },
      marginLeft: 10,
    },
  })
);

const ColorizedIconButton = withStyles(() => ({
  root: {
    backgroundColor: Colors.PORCELAIN_GREY_3,
    padding: 0,
  },
}))(IconButton);

const CssTextField = withStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
        padding: 0,
        margin: 0,
      },
      '& input': {
        paddingLeft: 0,
        paddingRight: 0,
        border: 'none',
        textAlign: 'center',
      },
    },
  },
}))(TextField);

const ColorizedNumberButton = withStyles({
  root: {
    fontSize: 16,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: Colors.CLASSICAL_BLACK,
    boxShadow: 'none',
    backgroundColor: Colors.PORCELAIN_GREY_3,
    borderColor: Colors.PORCELAIN_GREY_3,
    maxWidth: 10,
    '&:disabled': {
      color: Colors.CLASSICAL_BLACK,
      border: 0,
    },
  },
})(Button);

interface PlusMinusButtonGroupProps {
  text?: string;
  onChange?: (value: number, name?: string) => void;
  name?: string;
  initialValue?: number;
  minValue?: number;
  maxValue?: number;
  style?: React.CSSProperties;
  editable?: boolean;
}

const PlusMinusButtonGroup: React.FC<PlusMinusButtonGroupProps> = ({
  text,
  onChange,
  name,
  initialValue = 0,
  minValue,
  maxValue,
  editable = true,
  style = {},
}) => {
  const [quantity, setQuantity] = useState<number>(initialValue);
  const classes = useStyles();

  useEffect(() => {
    if (initialValue !== quantity) {
      setQuantity(initialValue);
    }
    // eslint-disable-next-line
  }, [initialValue]);

  const increaseQuantity = () => {
    const newQuantity = isNil(maxValue) ? quantity + 1 : Math.min(maxValue, quantity + 1);
    setQuantity(newQuantity);
    handleNewQuantity(newQuantity);
  };

  const decreaseQuantity = () => {
    if (quantity === 0) {
      return;
    }
    const newQuantity = isNil(minValue) ? quantity - 1 : Math.max(minValue, quantity - 1);
    handleNewQuantity(newQuantity);
  };

  const handleNewQuantity = (newQuantity: number) => {
    setQuantity(newQuantity);
    if (!isNil(onChange)) {
      onChange(newQuantity, name);
    }
  };

  return (
    <div className={classes.root} style={style} data-test={name}>
      <ButtonGroup
        size="small"
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
        }}
      >
        <ColorizedIconButton onClick={decreaseQuantity} size="small">
          <Minus style={{ fill: Colors.BLUE_GREY, objectFit: 'contain' }} />
        </ColorizedIconButton>
        {editable ? (
          <CssTextField
            style={{ width: 40, backgroundColor: Colors.PORCELAIN_GREY_3 }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newQuantityRaw = Number(event.target.value);
              if (!isNaN(newQuantityRaw)) {
                const minQuantity = isNil(minValue) ? newQuantityRaw : minValue;
                const maxQuantity = isNil(maxValue) ? newQuantityRaw : maxValue;
                const newQuantity = clamp(newQuantityRaw, minQuantity, maxQuantity);
                handleNewQuantity(newQuantity);
              }
            }}
            value={quantity}
          />
        ) : (
          <ColorizedNumberButton disableFocusRipple disableRipple disableTouchRipple disabled>
            {quantity}
          </ColorizedNumberButton>
        )}
        <ColorizedIconButton onClick={increaseQuantity} size="small">
          <Plus style={{ fill: Colors.BLUE_GREY, objectFit: 'contain' }} />
        </ColorizedIconButton>
      </ButtonGroup>
      {text && (
        <Typography
          style={{
            fontSize: 14,
            fontWeight: 600,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: Colors.CLASSICAL_BLACK,
            marginLeft: 10,
            textAlign: 'left',
          }}
        >
          {text}
        </Typography>
      )}
    </div>
  );
};

export default PlusMinusButtonGroup;
