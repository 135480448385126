import { isNil } from 'lodash';
import { LeaseInventoryType } from '@rentguru/commons-utils';
import { useLeaseInventories } from './LeaseInventoryContext';

const useLeaseInventoryUtils = (leaseId: string, isTenant = false) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (!isTenant) return useOwnerLeaseInventoryUtils(leaseId);
  return {
    hasFurnitureEntranceInventory: undefined,
    hasFurnitureExitInventory: undefined,
    hasFixtureEntranceInventory: undefined,
    hasFixtureExitInventory: undefined,
    hasBothFixtureEntranceAndExitInventory: false,
    hasBothFurnitureEntranceAndExitInventory: false,
  };
};

const useOwnerLeaseInventoryUtils = (leaseId: string) => {
  const { getLeaseInventoryByType } = useLeaseInventories();

  const hasFurnitureEntranceInventory = getLeaseInventoryByType(leaseId, LeaseInventoryType.FURNITURES_ENTRANCE);
  const hasFurnitureExitInventory = getLeaseInventoryByType(leaseId, LeaseInventoryType.FURNITURES_EXIT);

  const hasFixtureEntranceInventory = getLeaseInventoryByType(leaseId, LeaseInventoryType.FIXTURES_ENTRANCE);
  const hasFixtureExitInventory = getLeaseInventoryByType(leaseId, LeaseInventoryType.FIXTURES_EXIT);

  const hasBothFurnitureEntranceAndExitInventory =
    !isNil(hasFurnitureEntranceInventory) && !isNil(hasFurnitureExitInventory);
  const hasBothFixtureEntranceAndExitInventory = !isNil(hasFixtureEntranceInventory) && !isNil(hasFixtureExitInventory);

  return {
    hasFurnitureEntranceInventory,
    hasFurnitureExitInventory,
    hasFixtureEntranceInventory,
    hasFixtureExitInventory,
    hasBothFixtureEntranceAndExitInventory,
    hasBothFurnitureEntranceAndExitInventory,
  };
};

export default useLeaseInventoryUtils;
