import { Grid, ListItem, Typography } from '@material-ui/core';
import {
  Colors,
  CustomSubscription,
  DeepDeletableEntityType,
  getLocaleFromLanguage,
  getTypedLanguage,
} from '@rentguru/commons-utils';
import { formatDistanceToNow } from 'date-fns';
import { isNil } from 'lodash';
import { useIntl } from 'react-intl';
import NotificationDeleteIconButton from 'src/components/ui/CustomIconButtons/NotificiationDeleteIconButton';
import { useCustomSubscriptions } from 'src/hooks/CustomSubscriptionContext';
import { useUser } from 'src/hooks/UserContext';
import { useNotificationListStyle } from './NotificationsList';
import { ReactComponent as BuildingIcon } from 'src/icons/buildingWithCircle.svg';
import { ReactComponent as UnitIcon } from 'src/icons/lease-action-units.svg';
import { ReactComponent as LeaseIcon } from 'src/icons/lease-action-extend.svg';
import { ReactComponent as ContactIcon } from 'src/icons/contactWithCircle.svg';

export interface NotificationsEntityDeletionItemProps {
  customSubscription: CustomSubscription;
  entity: DeepDeletableEntityType;
  entityName: string;
  error?: boolean;
}

const getTranslationKeyPrefix = (entity: DeepDeletableEntityType) => {
  if (entity === DeepDeletableEntityType.BUILDING) {
    return 'building.deleteBuilding.';
  }
  if (entity === DeepDeletableEntityType.CONTACT) {
    return 'contact.deleteContact.';
  }
  if (entity === DeepDeletableEntityType.LEASE) {
    return 'lease.deleteLease.';
  }
  if (entity === DeepDeletableEntityType.UNIT) {
    return 'rentalUnit.deleteUnit.';
  }
  return '';
};

const getIcon = (entity: DeepDeletableEntityType) => {
  const iconStyle = {
    width: 45,
    height: 45,
    transform: 'scale(0.6)',
  };
  if (entity === DeepDeletableEntityType.BUILDING) {
    return <BuildingIcon style={iconStyle} />;
  }
  if (entity === DeepDeletableEntityType.CONTACT) {
    return <ContactIcon style={iconStyle} />;
  }
  if (entity === DeepDeletableEntityType.LEASE) {
    return <LeaseIcon style={iconStyle} />;
  }
  return <UnitIcon style={iconStyle} />;
};

export const NotificationsEntityDeletionItem: React.FC<NotificationsEntityDeletionItemProps> = ({
  customSubscription,
  entity,
  entityName,
  error = false,
}) => {
  const { formatMessage } = useIntl();
  const localStyles = useNotificationListStyle();
  const { language } = useUser();
  const { deleteCustomSubscriptions } = useCustomSubscriptions();
  const translationKeyPrefix = getTranslationKeyPrefix(entity);

  return (
    <ListItem className={localStyles.listItem}>
      <Grid>
        <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            {getIcon(entity)}
            <Typography variant="h6" style={{ color: Colors.CLASSICAL_BLACK }}>
              {formatMessage({ id: `${translationKeyPrefix}menu` }, { name: entityName })}
            </Typography>
          </Grid>
          <NotificationDeleteIconButton
            onClick={() => {
              deleteCustomSubscriptions([customSubscription]);
            }}
          />
        </Grid>
        {!isNil(customSubscription.createdAt) && (
          <Typography style={{ color: Colors.BLUEY, paddingBottom: 5 }}>
            {formatDistanceToNow(new Date(customSubscription.createdAt!), {
              locale: getLocaleFromLanguage(getTypedLanguage(language)),
            })}
          </Typography>
        )}
        {customSubscription && (
          <Typography style={{ color: Colors.SLATE_GREY }}>
            {formatMessage(
              { id: `${translationKeyPrefix}${error ? 'deletionErrorWithName' : 'deletionSuccessWithName'}` },
              { name: entityName }
            )}
          </Typography>
        )}
      </Grid>
    </ListItem>
  );
};
