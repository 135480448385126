import { Divider } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { ContentHeader, LoaderButton , ActionButton } from '@up2rent/ui';
import {
  Lease,
  Statement,
  CreateStatementInput,
  Posting,
  StatementSource,
  StatementStatus,
  Colors,
} from '@rentguru/commons-utils';
import * as Yup from 'yup';
import AddTenantStatementForm from './AddTenantStatementForm';
import { isEmpty, isNil, toUpper } from 'lodash';
import { useTransactions } from 'src/hooks/TransactionsContext';
import { useHistory } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';
import Dialog from 'src/components/ui/Dialog';

const AddTenantStatementSchema = Yup.object().shape({
  lease: Yup.object().required(),
  periodFrom: Yup.string().required(),
  periodTo: Yup.string().required(),
  closingStatement: Yup.bool().required(),
  existingClosingStatement: Yup.object().nullable(),
  existingUnclosedStatement: Yup.object()
    .nullable()
    .test((value) => isNil(value)),
  postingsToTreat: Yup.array().min(1).required(),
});

export interface AddTenantStatementValues {
  lease: Lease | null;
  periodFrom: string | null;
  periodTo: string | null;
  postingsToTreat: Posting[] | null;
  closingStatement: boolean;
  existingClosingStatement: Statement | null;
  existingUnclosedStatement: Statement | null;
}

interface AddTenantStatementProps {
  open: boolean;
  lease?: Lease;
  defaultPeriodFrom?: string;
  defaultPeriodTo?: string;
  defaultClosing?: boolean;
  createStatement: (input: Omit<CreateStatementInput, 'clientId' | 'readId'>) => Promise<Statement>;
  recalculateStatement: (id: string) => Promise<boolean>;
  onClose: () => void;
  afterSubmit?: () => Promise<void>;
}

const AddTenantStatement: React.FC<AddTenantStatementProps> = ({
  open,
  lease: defaultLease,
  defaultClosing,
  defaultPeriodFrom,
  defaultPeriodTo,
  onClose,
  createStatement,
  recalculateStatement,
  afterSubmit,
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { accountLabels } = useTransactions();

  const hanldeCreateTenantStatement = async (
    values: AddTenantStatementValues,
    { setSubmitting, setStatus }: FormikHelpers<AddTenantStatementValues>
  ) => {
    const { lease, periodFrom, periodTo, closingStatement } = values;
    const newStatement = await createStatement({
      closingStatement,
      periodFrom: periodFrom!,
      periodTo: periodTo!,
      leaseId: lease!.id,
      source: StatementSource.MANUAL,
      status: StatementStatus.TO_VALIDATE,
    });
    await recalculateStatement(newStatement.id);
    setStatus(true);
    setSubmitting(false);
    if (afterSubmit) await afterSubmit();
    onClose();
  };

  const initialValues: AddTenantStatementValues = {
    lease: defaultLease ?? null,
    periodFrom: defaultPeriodFrom ?? null,
    periodTo: defaultPeriodTo ?? null,
    postingsToTreat: null,
    closingStatement: defaultClosing ?? false,
    existingClosingStatement: null,
    existingUnclosedStatement: null,
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: { maxWidth: 'none' } }}>
      <ContentHeader
        toolbarStyle={{ display: 'flex', justifyContent: 'space-between' }}
        title={formatMessage({ id: 'accounting.statement.addTenantStatement' })}
      />
      <Divider style={{ marginBottom: 30 }} />
      <Formik
        initialValues={initialValues}
        validationSchema={AddTenantStatementSchema}
        onSubmit={hanldeCreateTenantStatement}
      >
        {({ values, status, isSubmitting }) => {
          return (
            <Form>
              <AddTenantStatementForm
                accountLabels={accountLabels}
                defaultLease={defaultLease}
                defaultClosing={defaultClosing}
              />
              <Divider style={{ marginTop: 30, marginBottom: 30 }} />
              <div
                style={{
                  marginRight: 30,
                  marginBottom: 20,
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 30,
                  justifyContent: 'flex-end',
                }}
              >
                <ActionButton
                  onClick={onClose}
                  style={{
                    background: 'none',
                    color: Colors.DARK_SLATE_GREY,
                    marginRight: 20,
                  }}
                >
                  {toUpper(formatMessage({ id: 'cancel' }))}
                </ActionButton>
                {!isNil(values.existingUnclosedStatement) && (
                  <ActionButton
                    onClick={() => {
                      history.push({
                        pathname: RouteDestination.VALIDATE_TENANT_STATEMENT,
                        state: { statementId: values.existingUnclosedStatement!.id },
                      });
                    }}
                    style={{
                      background: 'none',
                      color: Colors.DARK_SLATE_GREY,
                      marginRight: 20,
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {toUpper(formatMessage({ id: 'accounting.statement.addTenantStatementSection.goSeeStatement' }))}
                    </div>
                  </ActionButton>
                )}

                <LoaderButton
                  loading={isSubmitting}
                  success={status}
                  disabled={
                    !values.postingsToTreat ||
                    isEmpty(values.postingsToTreat) ||
                    !isNil(values.existingUnclosedStatement)
                  }
                >
                  {formatMessage({ id: 'transactions.list.add' })}
                </LoaderButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default AddTenantStatement;
