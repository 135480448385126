/* eslint-disable @typescript-eslint/no-var-requires */
// Based on: https://github.com/ekwonye-richard/react-flags-select/blob/master/src/index.js
import React from 'react';
import { useIntl } from 'react-intl';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import clsx from 'clsx';
import { Typography, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import {
  CountryCode as CountryCodeImp,
  CountryObject,
  CountryObjectWithFlags,
  countries,
} from '@rentguru/commons-utils';
import { useCustomizedComboStyles } from 'src/components/ui/ComboBox/TextComboBox';
import { ReactComponent as DropDown } from 'src/icons/drop-down.svg';

const WORLD_FLAG = new URL(`./flags/world.svg`, import.meta.url).href;

export enum CountryResultToDisplay {
  Name,
  Code,
  DialCode,
}

export const useStyles = makeStyles({
  paper: {
    minWidth: '250px',
  },
  inputRoot: {
    '& $input': { minWidth: '0px !important' },
  },
  tag: {
    margin: 2,
  },
});

export type CountryCode = CountryCodeImp;

export interface CountrySelectorProps {
  optionsSize?: number;
  selectedCountry?: CountryCodeImp;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (event: any, values: CountryObject[]) => void;
  disabled?: boolean;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  formStyle?: React.CSSProperties;
  required?: boolean;
  error?: boolean;
  margin?: 'dense' | 'normal';
  resultToDisplay?: CountryResultToDisplay;
  helperText?: string;
}

const CountrySelector: React.FC<CountrySelectorProps> = ({
  onChange,
  optionsSize = 15,
  disabled = false,
  selectedCountry,
  fullWidth = false,
  formStyle = {},
  error = false,
  margin = 'dense',
  resultToDisplay = CountryResultToDisplay.Code,
  helperText,
}) => {
  const { formatMessage } = useIntl();
  const localClasses = useStyles();
  const classes = useCustomizedComboStyles();

  const countriesWithFlags: CountryObjectWithFlags[] = Object.values(countries)
    .reduce((acc: CountryObjectWithFlags[], country: CountryObject) => {
      try {
        const flag = new URL(`./flags/${country.code.toLowerCase()}.svg`, import.meta.url).href;

        acc.push({
          ...country,
          name: formatMessage({ id: `countries.${country.code}` }),
          flag: flag.endsWith('undefined') ? WORLD_FLAG : flag,
        });
        return acc;
      } catch (err) {
        return acc;
      }
    }, [])
    .sort((countryA, countryB) => (countryA.name < countryB.name ? -1 : 1));

  const selectedValue = countriesWithFlags.find((c) => c.code === selectedCountry);

  return (
    <Autocomplete
      multiple
      clearOnEscape={true}
      options={countriesWithFlags}
      classes={{
        option: classes.option,
        paper: localClasses.paper,
        inputRoot: localClasses.inputRoot,
        tag: localClasses.tag,
      }}
      style={{ width: '100%' }}
      disabled={disabled}
      value={isNil(selectedValue) ? [] : [selectedValue]}
      autoHighlight
      getOptionSelected={(option, value) => isEqual(option.code, value.code)}
      getOptionLabel={(option) => `${option.name} - ${option.code} - ${option.dial_code}`}
      popupIcon={<DropDown className={classes.icon} />}
      renderOption={(option) => {
        const countryName = option.name;
        return (
          <>
            <img
              src={option.flag}
              alt={countryName}
              style={{
                width: `${optionsSize}px`,
                height: `${optionsSize}px`,
                marginRight: 15,
              }}
            />
            {countryName}
            {resultToDisplay === CountryResultToDisplay.DialCode ? ` (${option.dial_code})` : ''}
          </>
        );
      }}
      onChange={onChange}
      disableClearable={true}
      noOptionsText="No countries found"
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={formatMessage({
              id: 'contact.addContact.countryLabel',
            })}
            margin={margin}
            variant="filled"
            inputProps={{
              ...params.inputProps,
            }}
            fullWidth={fullWidth}
            style={{ minWidth: 140, ...formStyle }}
            InputProps={{
              ...params.InputProps,
              classes: { underline: classes.underline },
              className: clsx(params.InputProps.className, localClasses.inputRoot),
            }}
            InputLabelProps={{ className: classes.label }}
            SelectProps={{ classes: { select: classes.select } }}
            error={error}
            disabled={disabled}
            helperText={helperText}
            data-test="countrySelector"
          />
        );
      }}
      fullWidth={fullWidth}
      renderTags={(values, getTagProps) => {
        const countryCode = values[0].code;
        const countryName = values[0].name;
        const countryDialCode = values[0].dial_code;
        const flag = values[0].flag;
        const tagProps = { ...getTagProps({ index: 0 }) };
        // @ts-ignore
        delete tagProps.onDelete;
        return (
          <div style={{ display: 'flex', alignItems: 'center' }} {...tagProps}>
            <img
              src={flag}
              alt={countryName}
              style={{
                width: `${optionsSize}px`,
                height: `${optionsSize}px`,
                marginRight: 15,
              }}
            />
            <Typography
              style={{ fontFamily: 'Mulish', fontSize: 16, fontWeight: 400, color: 'black', textAlign: 'left' }}
            >
              {resultToDisplay === CountryResultToDisplay.Code
                ? countryCode
                : resultToDisplay === CountryResultToDisplay.DialCode
                ? countryDialCode
                : countryName}
            </Typography>
          </div>
        );
      }}
    />
  );
};

export default CountrySelector;
