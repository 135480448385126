/* eslint-disable @typescript-eslint/no-shadow */
import {
  getLeaseMonthDueDate,
  isVariousOperationApplicableAtDate,
  roundAtSecondDecimal,
  isBeforeOrEqual,
  Building,
  Lease,
  LeasePriceHistory,
  LeaseAmountUpdateStatus,
  LeaseStatus,
  LeaseVariousOperation,
  Unit,
  UnitEvent,
  UnitEventType,
  LeasePaymentInvoicePeriod,
} from '@rentguru/commons-utils';
import { closestIndexTo, eachMonthOfInterval, endOfMonth, isAfter, isBefore, startOfMonth, subMonths } from 'date-fns';
import { sumBy } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

export interface TimeWindow {
  start: Date;
  end: Date;
}

interface MonthlyValues {
  startOfMonth: Date;
  value: number;
}
/**
 * If there are no indexations for the lease, the function juste returns the rentalPrice of the lease.
 * Othterwise, it finds the latest indexation before the date and returns its rentalPrice
 */
export const getLeaseIndexedRentalPriceAtTime = (lease: Lease, indexationsOfLease: LeasePriceHistory[], date: Date) => {
  const indexationsBeforeDate = indexationsOfLease.filter((indexation) =>
    isBeforeOrEqual(new Date(indexation.applicationDate), date)
  );
  if (isEmpty(indexationsBeforeDate)) return lease.totalInitialRentalPrice;
  const closestIndexationIndex = closestIndexTo(
    date,
    indexationsBeforeDate.map((indexation) => new Date(indexation.applicationDate))
  );
  return indexationsBeforeDate[closestIndexationIndex].totalRentalPrice;
};

export const getLeaseIndexedRentalPriceForMonth = (
  lease: Lease,
  leaseIndexations: LeasePriceHistory[],
  startOfMonth: Date
) => {
  const indexationsOfLease = leaseIndexations.filter(
    (li) => !isNil(li.lease) && li.lease.id === lease.id && li.status === LeaseAmountUpdateStatus.APPLIED
  );
  if (isEmpty(indexationsOfLease)) return lease.totalRentalPrice;

  // We have to check the price of the lease at the moment it was paid to see if it was indexed before.
  const leaseMonthDueDate = getLeaseMonthDueDate(
    new Date(lease.startDate),
    lease.paymentInvoicePeriod as LeasePaymentInvoicePeriod,
    lease.paymentInvoicePeriodCustomDay,
    startOfMonth
  );
  return getLeaseIndexedRentalPriceAtTime(lease, indexationsOfLease, leaseMonthDueDate);
};

const getLeaseDiscountForMonth = (lease: Lease, discounts: LeaseVariousOperation[], startOfMonth: Date) => {
  const discountsOfLease = discounts.filter((li) => !isNil(li.lease) && li.lease.id === lease.id);
  if (isEmpty(discountsOfLease)) return 0;

  // We have to check the price of the lease at the moment it was paid to see if it was indexed before.
  const leaseMonthDueDate = discountsOfLease.filter((discount) =>
    isVariousOperationApplicableAtDate(discount, startOfMonth)
  );
  return sumBy(leaseMonthDueDate, 'amount');
};

export const getActiveLeasesWithinTimeWindow = (leases: Lease[], timeWindow: TimeWindow): Lease[] => {
  return leases!.filter((lease: Lease) => {
    if (!isNil(lease)) {
      // Ignore draft, outforsignature, rejected and cancelled
      if (lease.status === LeaseStatus.Active || lease.status === LeaseStatus.Ended) {
        const leaseStartAfterInterval = isAfter(new Date(lease.startDate), timeWindow.end);
        const leaseEndBeforeInterval = isBefore(new Date(lease.endDate), timeWindow.start);
        return !leaseStartAfterInterval && !leaseEndBeforeInterval;
      }
    }
    return false;
  }, []);
};

export const getOwnUseEventsInTimeWindow = (unitEvents: UnitEvent[], timeWindow: TimeWindow): UnitEvent[] => {
  return unitEvents!.filter((unitEvent: UnitEvent) => {
    // Ignore draft, outforsignature, rejected and cancelled
    if (unitEvent.type === UnitEventType.OWN_USE) {
      const ownUseStartAfterInterval = isAfter(new Date(unitEvent.startDate), timeWindow.end);
      const ownUseEndBeforeInterval = isBefore(new Date(unitEvent.endDate), timeWindow.start);
      return !ownUseStartAfterInterval && !ownUseEndBeforeInterval;
    }

    return false;
  }, []);
};

/**
 * Keep only the leases that have at least one billable date inside the interval.
 * Reminder: a lease is always paid one month in advance, so the endDate month of the lease is not billable.
 * Example: If the lease ends the 15th of October. The last billable date was the 15th of Sepember.
 */
export const getBillableLeasesOfTimeWindow = (leases: Lease[], timeWindow: TimeWindow): Lease[] => {
  return leases.filter((lease) => {
    const firstBillableDate = getLeaseMonthDueDate(
      new Date(lease.startDate),
      lease.paymentInvoicePeriod as LeasePaymentInvoicePeriod,
      lease.paymentInvoicePeriodCustomDay,
      startOfMonth(new Date(lease.startDate))
    );
    // Paid one month in advance
    const oneMonthBeforeEndDate = subMonths(new Date(lease.endDate), 1);
    const lastBillableDate = getLeaseMonthDueDate(
      new Date(lease.startDate),
      lease.paymentInvoicePeriod as LeasePaymentInvoicePeriod,
      lease.paymentInvoicePeriodCustomDay,
      oneMonthBeforeEndDate
    );
    const { start: startWindow, end: endWindow } = timeWindow;
    // Needs to be active or ended (draft, outforsignature and cancelled are ignored)
    // And ignore lease that start and end the same month
    if (
      (lease.status === LeaseStatus.Active || lease.status === LeaseStatus.Ended) &&
      isAfter(lastBillableDate, firstBillableDate)
    ) {
      const leaseFirstDueDateAfterInterval = isAfter(firstBillableDate, endWindow);
      const leaseLastDueDateBeforeInterval = isBefore(lastBillableDate, startWindow);
      // If the interval starts after the last due date or ends before the first due date, it is not billable.
      const leaseIsNotBillable = leaseFirstDueDateAfterInterval || leaseLastDueDateBeforeInterval;
      // Keep only the billable leases
      return !leaseIsNotBillable;
    }
    return false;
  });
};

/**
 * Flatten the units into all their leases (duplicated leases are allowed)
 */
export const getAllLeasesOfUnits = (units: Unit[]): Lease[] => {
  return units.reduce((acc: Lease[], unit: Unit) => {
    for (const unitLease of unit.leases!) {
      acc.push(unitLease.lease!);
    }
    return acc;
  }, []);
};

/**
 * Flatten the buildings into all their units (duplicated units are allowed)
 */
export const getAllUnitsOfBuilding = (buildings: Building[]): Unit[] => {
  return buildings.reduce((acc: Unit[], building: Building) => {
    if (!isNil(building.units)) {
      acc = [...acc, ...building.units];
    }
    return acc;
  }, []);
};
/**
 * Keep all the units' leases that are billable for the month and add their expected income.
 */
const calculateExpectedMonthIncome = (
  startOfMonth: Date,
  units: Unit[],
  leaseIndexations: LeasePriceHistory[],
  discounts: LeaseVariousOperation[]
) => {
  const timeWindow = { start: startOfMonth, end: endOfMonth(startOfMonth) };
  const allLeases = getAllLeasesOfUnits(units);
  // Keep only the billable leases of the month
  const activeLeasesInTimeWindow = getBillableLeasesOfTimeWindow(allLeases, timeWindow);
  const expectedIncome = activeLeasesInTimeWindow.reduce((totalIncome: number, lease: Lease) => {
    const leaseIncome = getLeaseIndexedRentalPriceForMonth(lease, leaseIndexations, startOfMonth);
    const discount = getLeaseDiscountForMonth(lease, discounts, startOfMonth);
    return totalIncome + leaseIncome - discount;
  }, 0);
  return roundAtSecondDecimal(expectedIncome);
};

export const calculateExpectedMonthIncomesInTimeWindow = (
  units: Unit[],
  leaseIndexations: LeasePriceHistory[],
  discounts: LeaseVariousOperation[],
  timeWindow: TimeWindow
): MonthlyValues[] => {
  const eachMonthToCompute = eachMonthOfInterval(timeWindow);
  // For each month let's compute the expected income
  const predictedRentValues = eachMonthToCompute.map((month) => {
    const startMonth = startOfMonth(month);
    return {
      startOfMonth: startMonth,
      value: calculateExpectedMonthIncome(startMonth, units, leaseIndexations, discounts),
    };
  });
  return predictedRentValues;
};

/**
 * Calculate how much money we can expect to receive from unit in time window.
 */
export const calculateExpectedUnitIncomeInTimeWindow = (
  unit: Unit,
  leaseIndexations: LeasePriceHistory[],
  discounts: LeaseVariousOperation[],
  timeWindow: TimeWindow
) => {
  const unitMonthlyIncomes = calculateExpectedMonthIncomesInTimeWindow([unit], leaseIndexations, discounts, timeWindow);
  return unitMonthlyIncomes.reduce((total, monthlyIncome) => {
    return total + monthlyIncome.value;
  }, 0);
};

export const preFilterLeaseIndexationsAndDiscountsBasedOnUnits = (
  units: Unit[],
  leaseIndexations: LeasePriceHistory[],
  discounts: LeaseVariousOperation[]
) => {
  return units.reduce(
    (acc: { discounts: LeaseVariousOperation[]; leaseIndexations: LeasePriceHistory[] }, unit) => {
      const unitLeasesIds = unit.leases ? unit.leases.map((unitLease) => unitLease.lease!.id) : [];
      const filteredLeaseIndexations = leaseIndexations.filter((li) => li.lease && unitLeasesIds.includes(li.lease.id));
      const filteredDiscounts = discounts.filter((d) => d.lease && unitLeasesIds.includes(d.lease.id));
      if (!isEmpty(filteredLeaseIndexations))
        acc.leaseIndexations = [...acc.leaseIndexations, ...filteredLeaseIndexations];
      if (!isEmpty(filteredDiscounts)) acc.discounts = [...acc.discounts, ...filteredDiscounts];
      return acc;
    },
    { discounts: [], leaseIndexations: [] }
  );
};
