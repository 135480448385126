import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Colors, LooseObject } from '@rentguru/commons-utils';

const styles = {
  root: {
    flexGrow: 1,
  },
  h6: {
    flex: 1,
    textAlign: 'left' as const,
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Mulish',
    fontSize: '16px',
    fontWeight: 700,
  },
};
interface FormHeaderProps {
  id?: string;
  classes: LooseObject;
  title: string;
  children?: React.ReactNode;
  toolbarStyle?: React.CSSProperties;
}
const FormHeader: React.FC<FormHeaderProps> = ({ id, classes, title, children, toolbarStyle = {} }) => {
  return (
    <div id={id} className={classes.root}>
      <Toolbar style={toolbarStyle}>
        <Typography variant="h6" color="inherit" className={classes.h6}>
          {title}
        </Typography>
        {children}
      </Toolbar>
    </div>
  );
};

export default withStyles(styles)(FormHeader);
