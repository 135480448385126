import React, { useEffect, useState } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { useIntl } from 'react-intl';
import { useBankAccounts } from 'src/hooks/BankAccountsContext';
import { getContactNameFromObject, BankAccount, Posting } from '@rentguru/commons-utils';
import { useLeases } from 'src/hooks/LeasesContext';
import { useTransactions, fetchPostings } from 'src/hooks/TransactionsContext';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { Skeleton } from '@material-ui/lab';

interface DeleteBankAccountProps {
  afterSubmit: () => void;
  bankAccount: BankAccount;
}
const DeleteBankAccount: React.FC<DeleteBankAccountProps> = ({ bankAccount, afterSubmit }) => {
  const { formatMessage } = useIntl();
  const { updateBankAccount, deleteBankAccount } = useBankAccounts();
  const { transactions, accountLabels, transactionsLoading } = useTransactions();
  const { leases, leasesLoading } = useLeases();
  const [bankAccountPostings, setBankAccountPostings] = useState<Posting[] | null>(null);

  const handleClose = () => {
    afterSubmit();
  };

  useEffect(() => {
    const fetchBankAccountsPostings = async () => {
      const results = await fetchPostings('byBankAccount', bankAccount.id);
      setBankAccountPostings(results);
    };
    fetchBankAccountsPostings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (leasesLoading || transactionsLoading || isNil(bankAccountPostings)) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton />
      </div>
    );
  }

  const bankAccountsLeases = leases.filter((lease) => lease.bankAccount && lease.bankAccountId === bankAccount.id);
  const bankAccountstransactions = transactions.filter(
    (transaction) => transaction.bankAccount && transaction.bankAccount.id === bankAccount.id
  );
  const bankAccountAccountLabels = accountLabels.filter(
    (accountLabel) => accountLabel.bankAccount && accountLabel.bankAccount.id === bankAccount.id
  );

  const isBankAccountDeletable =
    isEmpty(bankAccountsLeases) &&
    isEmpty(bankAccountstransactions) &&
    isEmpty(bankAccountAccountLabels) &&
    isEmpty(bankAccountPostings);

  const linkedEntitiesTexts: string[] = [];
  if (!isEmpty(bankAccountsLeases))
    linkedEntitiesTexts.push(formatMessage({ id: 'settings.deleteBankAccount.leases' }));
  if (!isEmpty(bankAccountstransactions))
    linkedEntitiesTexts.push(formatMessage({ id: 'settings.deleteBankAccount.transactions' }));
  if (!isEmpty(bankAccountAccountLabels) || !isEmpty(bankAccountPostings))
    linkedEntitiesTexts.push(formatMessage({ id: 'settings.deleteBankAccount.accountingData' }));

  let linkedEntitiesText = '';
  if (linkedEntitiesTexts.length > 0) {
    if (linkedEntitiesTexts.length === 1) {
      linkedEntitiesText = linkedEntitiesTexts[0];
    } else {
      linkedEntitiesText = linkedEntitiesTexts.reduce((acc: string, splittedElement: string, index) => {
        if (index === linkedEntitiesTexts.length - 2) {
          return `${acc}${splittedElement} ${formatMessage({ id: 'rentalUnit.deleteUnit.and' })} `;
        }
        if (index === linkedEntitiesTexts.length - 1) {
          return `${acc}${splittedElement}`;
        }
        return `${acc}${splittedElement}, `;
      }, '');
    }
  }

  return (
    <>
      <DialogTitle id="alert-dialog-slide-title">
        {formatMessage({ id: 'settings.deleteBankAccount.menu' })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description" style={{ color: 'red' }}>
          {isBankAccountDeletable
            ? formatMessage(
                { id: 'settings.deleteBankAccount.title' },
                { name: bankAccount.contact ? getContactNameFromObject(bankAccount.contact) : '' }
              )
            : formatMessage({ id: 'settings.deleteBankAccount.disableWarning' }, { links: linkedEntitiesText })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {formatMessage({ id: 'cancel' })}
        </Button>
        <Button
          onClick={async (e) => {
            e.preventDefault();
            if (isBankAccountDeletable) await deleteBankAccount(bankAccount.id);
            else await updateBankAccount(bankAccount, { disabled: true });
            handleClose();
          }}
          color="primary"
        >
          {formatMessage({ id: isBankAccountDeletable ? 'delete' : 'disable' })}
        </Button>
      </DialogActions>
    </>
  );
};

export default DeleteBankAccount;
