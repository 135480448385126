import { Collapse, Divider, Typography } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import isNil from 'lodash/isNil';
import React from 'react';

interface AccountTypeItemProps {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  mainText: string;
  subText: string;
  selected: boolean;
  selectedColor?: string;
  onClick: () => void;
  CollapseComponent?: React.ReactNode;
  style?: React.CSSProperties;
}

const AccountTypeItem: React.FC<AccountTypeItemProps> = ({
  Icon,
  mainText,
  subText,
  selected,
  selectedColor = Colors.CARNATION_RED,
  onClick,
  CollapseComponent,
  style = {},
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        border: `solid 2px ${selected ? selectedColor : Colors.SILVER}`,
        borderRadius: '10px',
        padding: '10px 20px 10px 20px',
        maxWidth: 640,
        cursor: 'pointer',
        ...style,
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Icon style={{ fill: Colors.SLATE_GREY, width: 50, marginRight: 20 }} />
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <Typography style={{ fontWeight: 700, fontSize: 16, textAlign: 'left' }}>{mainText}</Typography>
          <Typography style={{ fontSize: 14, color: Colors.SLATE_GREY, textAlign: 'left' }}>{subText}</Typography>
        </div>
      </div>
      {!isNil(CollapseComponent) && (
        <Collapse in={selected} mountOnEnter unmountOnExit>
          <Divider style={{ margin: '10px -20px 10px -20px' }} />
          <div style={{ margin: 30 }}>{CollapseComponent}</div>
        </Collapse>
      )}
    </div>
  );
};

export default AccountTypeItem;
