import { isNil, isEmpty } from 'lodash';

/**
 * @param arr Array to look into
 * @param field The field to look (can contains . if inside a subobject)
 * @param object The object to check
 */
export const arrayIncludesFieldOfObject = <T>(arr: unknown[], field: keyof T | string, object: T): boolean => {
  if (isNil(object)) return false;
  let valueInSubObject = object[field as keyof T];

  const fields = (field as string).split('.');
  if (typeof fields === 'undefined' || fields.length <= 1) {
    return arr.includes(valueInSubObject);
  }
  valueInSubObject = object[fields[0] as keyof T];
  if (typeof valueInSubObject !== 'undefined' && Array.isArray(valueInSubObject)) {
    if (isEmpty(valueInSubObject)) {
      return false;
    }
    // For each element of this new array, I need to check if it is included in arr
    const result = valueInSubObject
      .map((v) => arrayIncludesFieldOfObject(arr, fields.slice(1).join('.'), v))
      .reduce((currentValue, value) => {
        return currentValue && value;
      }, true);
    return result;
  }
  return arrayIncludesFieldOfObject(arr, fields.slice(1).join('.'), valueInSubObject);
};
