import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';

const useStyles = makeStyles({
  buttonText: {
    fontFamily: 'Mulish',
    fontSize: 12,
    textTransform: 'uppercase',
    color: Colors.DARK_SLATE_GREY,
    fontWeight: 700,
  },
});

interface TextButtonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (event?: any) => void;
  onMouseDown?: (event: React.MouseEvent) => void;
  target?: string;
  href?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}
const TextButton: React.FC<TextButtonProps> = ({
  onClick = () => {},
  onMouseDown = () => {},
  target,
  href = '',
  disabled = false,
  children,
  style = {},
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Button
      target={target}
      onMouseDown={onMouseDown}
      href={href}
      classes={{ text: classes.buttonText }}
      disabled={disabled}
      onClick={onClick}
      style={style}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default TextButton;
