/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-param-last */
import React, { useContext, useState, useEffect, useMemo } from 'react';
import {
  Setting,
  syncSettings,
  createSetting as createQuery,
  updateSetting as updateQuery,
  deleteSetting as deleteQuery,
  CreateSettingMutationVariables,
  CreateSettingInput,
  DeleteSettingMutationVariables,
  UpdateSettingMutationVariables,
  cleanInputCreate,
  cleanInputUpdate,
  LooseObject,
  getReadId,
  getTableClientId,
  SETTING_VAT_AGENCY_RATE,
} from '@rentguru/commons-utils';
import { deleteAndHideEntity, list, mutation } from '@up2rent/fetch-utils';
import { useUser } from './UserContext';
import { isNil } from 'lodash';

export interface SettingContext {
  settings: Setting[];
  createSetting: (input: Omit<Setting, 'id' | 'clientId' | 'readId'>) => Promise<Setting>;
  updateSetting: (original: Setting, updates: Partial<Setting>) => Promise<Setting>;
  deleteSetting: (id: string) => Promise<void>;
  agencyFeesVat: number | undefined;
  settingsLoading: boolean | undefined;
  settingsError: string | undefined;
}

export const SettingContext = React.createContext<SettingContext | null>(null);

export const SettingContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [settings, setSettings] = useState<Setting[]>([]);
  const [settingsLoading, setSettingsLoading] = useState<boolean | undefined>(undefined);
  const [settingsError, setSettingsError] = useState<string | undefined>(undefined);
  const { clientId } = useUser();

  const fetchSettings = async (): Promise<Setting[]> => {
    return await list<Setting>(syncSettings, 'clientId', getTableClientId(clientId!, 'Setting'));
  };

  const clientVatSetting = settings.find((s) => s.name === SETTING_VAT_AGENCY_RATE);
  const agencyFeesVat =
    !isNil(clientVatSetting) && clientVatSetting.value !== 'N/A' && !isNaN(Number(clientVatSetting.value))
      ? Number(clientVatSetting.value)
      : undefined;

  useEffect(() => {
    const unmounted = false;

    const queryEntity = async () => {
      setSettingsLoading(true);
      try {
        const settingsResult = await fetchSettings();
        if (!unmounted) {
          setSettings(settingsResult);
        }
      } catch (err) {
        if (!unmounted) {
          setSettingsError(err as string);
        }
      } finally {
        setSettingsLoading(false);
      }
    };

    queryEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createSetting = async (input: Omit<Setting, 'id' | 'clientId' | 'readId'>) => {
    let result: Setting | undefined;
    try {
      result = await mutation<Setting, CreateSettingMutationVariables>(createQuery, {
        input: {
          ...(cleanInputCreate(input) as CreateSettingInput),
          readId: getReadId(clientId!, 'Setting'),
          clientId: getTableClientId(clientId!, 'Setting'),
        },
      });

      setSettings((s) => [...s, result!]);
    } catch (err) {
      setSettingsError(err as string);
    }
    return result!;
  };

  const updateSetting = async (original: Setting, updates: Partial<Setting>) => {
    let result: Setting | undefined;
    try {
      result = await mutation<Setting, UpdateSettingMutationVariables>(updateQuery, {
        input: {
          id: original.id,
          ...cleanInputUpdate(updates),
          _version: (original as LooseObject)._version,
          clientId: getTableClientId(clientId!, 'Setting'),
        },
      });
      if (result) {
        setSettings((settings) =>
          settings.map((s) => {
            if (s.id === result!.id) return result!;
            return s;
          })
        );
      }
    } catch (err) {
      setSettingsError(err as string);
    }
    return result!;
  };

  const deleteSetting = async (id: string) => {
    try {
      const setting = settings.find((s) => s.id === id);
      if (isNil(setting)) {
        setSettingsError('Not found');
        return;
      }
      await deleteAndHideEntity<Setting, DeleteSettingMutationVariables>(setting, deleteQuery, updateQuery);
      setSettings(settings.filter((m) => m.id === id));
    } catch (err) {
      setSettingsError(err as string);
    }
  };

  const values = useMemo(
    () => ({
      settings,
      createSetting,
      updateSetting,
      deleteSetting,
      settingsError,
      settingsLoading,
      agencyFeesVat,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [settings, settingsError, settingsLoading, agencyFeesVat]
  );

  return <SettingContext.Provider value={values}>{children}</SettingContext.Provider>;
};

export const useSettings = (): SettingContext => {
  const context = useContext<SettingContext | null>(SettingContext);

  if (context === undefined) {
    throw new Error('`useSettings` hook must be used within a `SettingContextProvider` component');
  }
  return context as SettingContext;
};
