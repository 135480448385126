import { Divider, Grid } from '@material-ui/core';
import { UnitType } from '@rentguru/commons-utils';
import { isNil } from 'lodash';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { ElevatedPaper } from '@up2rent/ui';
import FormHeader from '../../FormHeader';
import RentFields from '../FormField/RentFields';

interface RentFormProps {
  unitId?: string;
  unitType?: UnitType;
}

const RentForm: FC<RentFormProps> = ({ unitId, unitType }) => {
  const { formatMessage } = useIntl();

  if (isNil(unitId)) {
    return null;
  }

  return (
    <Grid>
      <ElevatedPaper>
        <FormHeader title={formatMessage({ id: `lease.addLease.financialTerms` })} />
        <Divider style={{ marginBottom: 30 }} />
        <RentFields unitId={unitId} unitType={unitType} />
      </ElevatedPaper>
    </Grid>
  );
};

export default RentForm;
