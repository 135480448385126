import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { IconButton, Typography } from '@material-ui/core';
import NotificationBox from './NotificationBox';
import TransactionToCheckRow from '../../Accounting/Transactions/TransactionsToCheckRow';
import { useTransactions } from '../../../hooks/TransactionsContext';
import SlicedContent from './SlicedContent';
import { ReactComponent as ExternalIcon } from '../../../icons/external.svg';
import { Link } from 'react-router-dom';
import { Transaction, Colors, TRANSACTION_BOX_KEY, ModelWithVersion } from '@rentguru/commons-utils';
import { compareAsc } from 'date-fns';
import isNil from 'lodash/isNil';
import TransactionsHeader from './BoxCustomHeaders/TransactionsHeader';
import DeleteTransactionDialog from 'src/components/Accounting/Transactions/Dialogs/DeleteTransactionDialog';
import SplitTransactionDialog from 'src/components/Accounting/Transactions/Dialogs/SplitTransactionDialog';
import { useLeases } from 'src/hooks/LeasesContext';
import { useContacts } from 'src/hooks/ContactsContext';
import { useUnits } from 'src/hooks/UnitsContext';
import TodosLoading from './TodosLoading';
import { stopPropagation } from 'src/components/Dashboard/Dashboard';

export const ROW_HEIGHT = 106;

const transactionsSortFunction = (transaction1: Transaction, transaction2: Transaction): number => {
  if (isNil(transaction1.operationDate)) return 1;
  if (isNil(transaction2.operationDate)) return -1;
  return compareAsc(new Date(transaction1.operationDate), new Date(transaction2.operationDate));
};

const DashboardTransactions: React.FC = () => {
  const { formatMessage } = useIntl();
  const { transactionsToCheck, transactionsLoading } = useTransactions();
  const { leasesLoading } = useLeases();
  const { contactsLoading } = useContacts();
  const { unitsLoading } = useUnits();
  const [idToDelete, setIdToDelete] = useState<string | null>(null);
  const [idToSplit, setIdToSplit] = useState<string | null>(null);

  const handleClose = () => {
    setIdToDelete(null);
    setIdToSplit(null);
  };

  const handleShowDialogs = (
    _e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string,
    toDelete?: boolean,
    toSplit?: boolean
  ) => {
    if (toDelete) {
      setIdToDelete(id);
    }
    if (toSplit) {
      setIdToSplit(id);
    }
  };

  const dataLoading = leasesLoading || contactsLoading || unitsLoading || transactionsLoading;
  if (dataLoading) {
    return (
      <>
        <TodosLoading />
      </>
    );
  }

  const transactionsToDisplay = transactionsToCheck.sort(transactionsSortFunction).map((transaction) => {
    return (
      <TransactionToCheckRow
        key={transaction.id}
        {...transaction}
        isSelected={false}
        _version={(transaction as ModelWithVersion<Transaction>)._version}
        formatMessage={formatMessage}
        actionMenu={handleShowDialogs}
      />
    );
  });

  const titleComponent = (
    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
      <Typography
        style={{
          flex: 1,
          textAlign: 'left',
          color: Colors.CLASSICAL_BLACK,
          fontFamily: 'Mulish',
          fontSize: '16px',
          fontWeight: 'bold',
          marginLeft: 20,
        }}
      >
        {`${formatMessage({ id: 'dashboard.transactions' })} (${transactionsToDisplay.length})`}
      </Typography>
      <IconButton
        size="small"
        onMouseDown={stopPropagation}
        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => e.stopPropagation()}
        component={Link}
        to="/accounting/transactions"
        style={{ marginLeft: 10 }}
      >
        <ExternalIcon style={{ fill: Colors.DODGER_BLUE }} />
      </IconButton>
    </div>
  );

  return (
    <>
      <NotificationBox boxKey={TRANSACTION_BOX_KEY} titleComponent={titleComponent}>
        <SlicedContent
          data={transactionsToDisplay}
          header={<TransactionsHeader />}
          limit={3}
          rowHeight={ROW_HEIGHT}
          boxKey={TRANSACTION_BOX_KEY}
        />
      </NotificationBox>
      {idToDelete && <DeleteTransactionDialog onClose={handleClose} transactionId={idToDelete} />}
      {idToSplit && <SplitTransactionDialog open={!isNil(idToSplit)} onClose={handleClose} id={idToSplit} />}
    </>
  );
};

export default DashboardTransactions;
