/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-param-last */
import React, { Reducer, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import {
  LeaseVariousOperation,
  syncLeaseVariousOperations,
  getLeaseVariousOperation as getVariousOperationQuery,
  createLeaseVariousOperation as createMutation,
  updateLeaseVariousOperation as updateMutation,
  deleteLeaseVariousOperation as deleteMutation,
  CreateLeaseVariousOperationMutationVariables,
  DeleteLeaseVariousOperationMutationVariables,
  CreateLeaseVariousOperationInput,
  UpdateLeaseVariousOperationInput,
  cleanInputUpdate,
  cleanInputCreate,
  getReadId,
  getTableClientId,
  classifyLeaseVariousOperation,
  LooseObject,
} from '@rentguru/commons-utils';
import { list, mutation, NUMBER_OF_MINUTES_FOR_REFETCH, deleteEntityWithFetchBefore } from '@up2rent/fetch-utils';
import { useUser } from './UserContext';
import { differenceInMinutes, isAfter } from 'date-fns';

export interface LeaseVariousOperationContext extends State {
  setFetchLeaseVariousOperations: () => void;
  getLeaseVariousOperation: (id: string) => LeaseVariousOperation | undefined | null;
  getLeaseVariousOperationsFromLease: (leaseId: string) => LeaseVariousOperation[] | null;
  getActiveLeaseVariousOperationsFromLease: (leaseId: string) => LeaseVariousOperation[] | null;
  createLeaseVariousOperation: (
    input: Omit<CreateLeaseVariousOperationInput, 'clientId' | 'readId'>
  ) => Promise<LeaseVariousOperation>;
  updateLeaseVariousOperation: (updates: UpdateLeaseVariousOperationInput) => Promise<LeaseVariousOperation>;
  deleteLeaseVariousOperation: (leaseVariousOperation: LeaseVariousOperation) => Promise<LeaseVariousOperation>;
}

interface State {
  loading: boolean;
  error: string | null;
  shouldFetch: boolean;
  lastFetch: Date | null;
  leaseVariousOperations: LeaseVariousOperation[] | null;
  operations: LeaseVariousOperation[] | null;
  discounts: LeaseVariousOperation[] | null;
}

type Action =
  | {
      type: 'SHOULD_FETCH' | 'IS_FETCHING';
    }
  | {
      type: 'FETCHED';
      payload: { leaseVariousOperations: LeaseVariousOperation[] };
    }
  | {
      type: 'ADD' | 'UPDATE';
      payload: { leaseVariousOperation: LeaseVariousOperation };
    }
  | {
      type: 'DELETE';
      payload: { id: string };
    };

const initialState: State = {
  loading: false,
  error: null,
  shouldFetch: false,
  lastFetch: null,
  leaseVariousOperations: null,
  operations: null,
  discounts: null,
};

const contextReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SHOULD_FETCH':
      // Check lastFetch
      if (
        state.loading ||
        state.shouldFetch ||
        (state.lastFetch && differenceInMinutes(new Date(), state.lastFetch) < NUMBER_OF_MINUTES_FOR_REFETCH)
      ) {
        return state;
      }
      return {
        ...state,
        shouldFetch: true,
      };
    case 'IS_FETCHING':
      return {
        ...state,
        loading: true,
      };
    case 'FETCHED':
      // Build List with merged object
      const leaseVariousOperationsFetched = action.payload.leaseVariousOperations;
      const { operations: operationsFetched, discounts: discountsFetched } =
        classifyLeaseVariousOperation(leaseVariousOperationsFetched);

      return {
        ...state,
        loading: false,
        shouldFetch: false,
        lastFetch: new Date(),
        leaseVariousOperations: leaseVariousOperationsFetched,
        operations: operationsFetched,
        discounts: discountsFetched,
      };
    case 'ADD':
      if (!state.leaseVariousOperations) {
        return state;
      }
      const entity = action.payload.leaseVariousOperation;
      const leaseVariousOperationsAdded = [...state.leaseVariousOperations, entity];
      const { operations: operationsAdded, discounts: discountsAdded } =
        classifyLeaseVariousOperation(leaseVariousOperationsAdded);

      return {
        ...state,
        leaseVariousOperations: leaseVariousOperationsAdded,
        operations: operationsAdded,
        discounts: discountsAdded,
      };
    case 'UPDATE':
      if (!state.leaseVariousOperations) {
        return state;
      }
      const leaseVariousOperationsUpdated = state.leaseVariousOperations.map((item) => {
        if (item.id === action.payload.leaseVariousOperation.id) {
          return action.payload.leaseVariousOperation;
        }
        return item;
      });
      const { operations: operationsUpdated, discounts: discountsUpdated } =
        classifyLeaseVariousOperation(leaseVariousOperationsUpdated);

      return {
        ...state,
        leaseVariousOperations: leaseVariousOperationsUpdated,
        operations: operationsUpdated,
        discounts: discountsUpdated,
      };
    case 'DELETE':
      if (!state.leaseVariousOperations) {
        return state;
      }
      const leaseVariousOperationsDeleted = state.leaseVariousOperations.filter(
        (item) => item.id !== action.payload.id
      );
      const { operations: operationsDeleted, discounts: discountsDeleted } =
        classifyLeaseVariousOperation(leaseVariousOperationsDeleted);

      return {
        ...state,
        leaseVariousOperations: leaseVariousOperationsDeleted,
        operations: operationsDeleted,
        discounts: discountsDeleted,
      };
    default:
      return state;
  }
};

export const fetchLeaseVariousOperations = async (
  by: 'byClientId' | 'byLease',
  byValue: string,
  additionalFilter?: LooseObject
): Promise<LeaseVariousOperation[]> => {
  const indexName = by === 'byClientId' ? 'clientId' : 'leaseId';
  return await list<LeaseVariousOperation>(syncLeaseVariousOperations, indexName, byValue, additionalFilter);
};

export const LeaseVariousOperationContext = React.createContext<LeaseVariousOperationContext | null>(null);

export const LeaseVariousOperationContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { clientId } = useUser();
  const [state, dispatch] = useReducer<Reducer<State, Action>>(contextReducer, initialState);

  const setFetchLeaseVariousOperations = () => {
    dispatch({ type: 'SHOULD_FETCH' });
  };

  useEffect(() => {
    const fetchAndSet = async () => {
      dispatch({ type: 'IS_FETCHING' });
      const result = await fetchLeaseVariousOperations(
        'byClientId',
        getTableClientId(clientId!, 'LeaseVariousOperation')
      );
      dispatch({ type: 'FETCHED', payload: { leaseVariousOperations: result } });
    };
    if (state.shouldFetch) fetchAndSet();
    // eslint-disable-next-line
  }, [state.shouldFetch]);

  const getLeaseVariousOperation = (id: string): LeaseVariousOperation | undefined | null => {
    if (state.loading || state.error || !state.leaseVariousOperations) {
      return null;
    }
    return state.leaseVariousOperations.find((leaseVariousOperation) => leaseVariousOperation.id === id);
  };

  const createLeaseVariousOperation = async (
    input: Omit<CreateLeaseVariousOperationInput, 'clientId' | 'readId'>
  ): Promise<LeaseVariousOperation> => {
    const leaseVariousOperation = await mutation<LeaseVariousOperation, CreateLeaseVariousOperationMutationVariables>(
      createMutation,
      {
        input: {
          ...(cleanInputCreate(input) as CreateLeaseVariousOperationInput),
          readId: getReadId(clientId!, 'LeaseVariousOperation'),
          clientId: getTableClientId(clientId!, 'LeaseVariousOperation'),
        },
      }
    );
    dispatch({ type: 'ADD', payload: { leaseVariousOperation } });
    dispatch({ type: 'SHOULD_FETCH' });
    return leaseVariousOperation;
  };

  const updateLeaseVariousOperation = async (
    updates: UpdateLeaseVariousOperationInput
  ): Promise<LeaseVariousOperation> => {
    const result = await mutation<LeaseVariousOperation>(updateMutation, {
      input: cleanInputUpdate(updates, false, true),
    });

    dispatch({ type: 'UPDATE', payload: { leaseVariousOperation: result } });
    dispatch({ type: 'SHOULD_FETCH' });

    return result;
  };

  const deleteLeaseVariousOperation = async (
    leaseVariousOperation: LeaseVariousOperation
  ): Promise<LeaseVariousOperation> => {
    const result = await deleteEntityWithFetchBefore<
      LeaseVariousOperation,
      DeleteLeaseVariousOperationMutationVariables
    >(leaseVariousOperation, getVariousOperationQuery, deleteMutation);
    dispatch({ type: 'DELETE', payload: { id: leaseVariousOperation.id } });
    return result;
  };

  const getLeaseVariousOperationsFromLease = (leaseId: string) => {
    if (state.loading || state.error || !state.leaseVariousOperations) {
      return null;
    }
    return state.leaseVariousOperations.filter((lvo) => lvo.lease?.id === leaseId);
  };

  const getActiveLeaseVariousOperationsFromLease = (leaseId: string) => {
    if (state.loading || state.error || !state.leaseVariousOperations) {
      return null;
    }
    const today = new Date();
    return state.leaseVariousOperations.filter(
      (leaseVariousOperation) =>
        leaseVariousOperation.lease?.id === leaseId && isAfter(new Date(leaseVariousOperation.endDate), today)
    );
  };

  const values = useMemo(
    () => ({
      ...state,
      setFetchLeaseVariousOperations,
      createLeaseVariousOperation,
      deleteLeaseVariousOperation,
      getLeaseVariousOperation,
      getLeaseVariousOperationsFromLease,
      getActiveLeaseVariousOperationsFromLease,
      updateLeaseVariousOperation,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <LeaseVariousOperationContext.Provider value={values}>{children}</LeaseVariousOperationContext.Provider>;
};
export const useLeaseVariousOperations = (): LeaseVariousOperationContext => {
  const firstRender = useRef<boolean>(false);
  const context = useContext<LeaseVariousOperationContext | null>(LeaseVariousOperationContext);

  if (!context) {
    throw new Error('`useLeaseVariousOperations` hook must be used within a `LeaseVariousOperationContext` component');
  }

  useEffect(() => {
    if (!firstRender.current) context.setFetchLeaseVariousOperations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  return context;
};
