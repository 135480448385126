import { ReactComponent as DocumentIcon } from 'src/icons/doc.svg';
import { CustomIconButton, CustomIconButtonProps } from '@up2rent/ui';
import { Colors } from '@rentguru/commons-utils';
import { forwardRef } from 'react';

interface DocumentIconButtonProps extends CustomIconButtonProps {
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
}

// eslint-disable-next-line react/display-name
const DocumentIconButton = forwardRef<HTMLButtonElement, DocumentIconButtonProps>((props, ref) => (
  <CustomIconButton
    ref={ref}
    Icon={DocumentIcon}
    iconStyle={{ fill: Colors.DODGER_BLUE }}
    disabledIconStyle={{ color: Colors.BLACK_20OPACITY }}
    {...props}
  />
));

export default DocumentIconButton;
