import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { defaultRankingBoxOrder, useDashboardFilters } from 'src/hooks/DashboardFiltersContext';
import { useUser } from 'src/hooks/UserContext';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import DashboardRankingReceivables from './DashboardRankingReceivables';
import DashboardRankingTopBuildingsRentalYeld from './DashboardRankingTopBuildingsRentalYeld';
import DashboardRankingTopOwners from './DashboardRankingTopOwners';
import DashboardRankingTopTenantsIncome from './DashboardRankingTopTenantsIncome';
import DashboardRankingTopUnits from './DashboardRankingTopUnits';
import DashboardRankingTopUnitsRentalYield from './DashboardRankingTopUnitsRentalYield';
import {
  TOP_BUILDINGS_RENTAL_YIELD_BOX_KEY,
  TOP_TENANTS_INCOME_BOX_KEY,
  TOP_UNITS_BOX_KEY,
  TOP_UNITS_RENTAL_YIELD_BOX_KEY,
  TOP_CREDITORS_BOX_KEY,
  TOP_OWNERS_BOX_KEY,
  DashboardBoxType,
  LooseObject,
} from '@rentguru/commons-utils';
import { WidthProvider, Responsive, Layout } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import '../DashboardMetrics/utils/dashboardLayout.css';
import RankingLoader from '../DashboardLoader';
import { Grid } from '@material-ui/core';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const DashboardRanking: React.FC = () => {
  const {
    financialTransactionsRead,
    financialValuationsAndCostsRead,
    leasesDetailsRead,
    loading: permissionsLoading,
  } = usePermissions();
  const { isOwner } = useUser();
  const { getBoxOrder, saveNewLayout } = useDashboardFilters();
  const [layout, setLayout] = useState<Layout[]>([]);

  const permissions: LooseObject = {
    [TOP_OWNERS_BOX_KEY]: financialTransactionsRead && leasesDetailsRead && !isOwner,
    [TOP_UNITS_BOX_KEY]: financialTransactionsRead && leasesDetailsRead,
    [TOP_CREDITORS_BOX_KEY]: financialTransactionsRead && leasesDetailsRead && !isOwner,
    [TOP_UNITS_RENTAL_YIELD_BOX_KEY]: financialValuationsAndCostsRead && leasesDetailsRead,
    [TOP_BUILDINGS_RENTAL_YIELD_BOX_KEY]: financialValuationsAndCostsRead && leasesDetailsRead,
    [TOP_TENANTS_INCOME_BOX_KEY]: financialTransactionsRead && leasesDetailsRead && !isOwner,
  };

  useEffect(() => {
    const getSavedBoxOrder = async () => {
      // Get the order saved in cache
      let boxLayouts = (await getBoxOrder(DashboardBoxType.RANKING)) as Layout[];

      // Check which boxes, he's allowed to see
      const allowedBoxes = Object.entries(permissions).reduce((acc: string[], boxAndPermission) => {
        const [boxKey, permission] = boxAndPermission;
        if (permission) {
          acc.push(boxKey);
        }
        return acc;
      }, []);

      // If a box is allowed but is not in cache, it means that the rights were updated or we switched accounts
      // => Default view !
      const boxAbsent = allowedBoxes.some((allowKey) => !boxLayouts.some((boxLayout) => boxLayout.i === allowKey));
      if (boxAbsent) {
        boxLayouts = defaultRankingBoxOrder;
      }

      // Show only the allowed boxes => filter boxes based on permissions
      const filteredBoxes = boxLayouts.filter((boxLayout) => {
        const boxKey = boxLayout.i;
        return allowedBoxes.includes(boxKey);
      });
      setLayout(filteredBoxes);
    };
    if (!permissionsLoading) {
      getSavedBoxOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionsLoading]);

  if (permissionsLoading) {
    return (
      <Grid style={{ display: 'flex', flexWrap: 'wrap' }}>
        {Object.keys(permissions).map(() => {
          // eslint-disable-next-line react/jsx-key
          return <RankingLoader width="100%" />;
        })}
      </Grid>
    );
  }

  if (isEmpty(layout)) {
    return null;
  }
  return (
    <>
      {/* @ts-ignore */}
      <ResponsiveReactGridLayout
        className="layout"
        breakpoints={{ lg: 1200 }}
        cols={{ lg: 2 }}
        layouts={{ lg: layout }}
        rowHeight={80}
        onLayoutChange={async (currentLayout: Layout[]) => {
          await saveNewLayout(currentLayout, DashboardBoxType.RANKING);
        }}
        isResizable={false}
      >
        <div key={TOP_OWNERS_BOX_KEY}>
          <CustomGridItemComponent>
            <DashboardRankingTopOwners forceUpdateLayout={setLayout} />
          </CustomGridItemComponent>
        </div>
        <div key={TOP_UNITS_BOX_KEY}>
          <CustomGridItemComponent>
            <DashboardRankingTopUnits forceUpdateLayout={setLayout} />
          </CustomGridItemComponent>
        </div>
        <div key={TOP_CREDITORS_BOX_KEY}>
          <CustomGridItemComponent>
            <DashboardRankingReceivables forceUpdateLayout={setLayout} />
          </CustomGridItemComponent>
        </div>
        <div key={TOP_UNITS_RENTAL_YIELD_BOX_KEY}>
          <CustomGridItemComponent>
            <DashboardRankingTopUnitsRentalYield forceUpdateLayout={setLayout} />
          </CustomGridItemComponent>
        </div>
        <div key={TOP_BUILDINGS_RENTAL_YIELD_BOX_KEY}>
          <CustomGridItemComponent>
            <DashboardRankingTopBuildingsRentalYeld forceUpdateLayout={setLayout} />
          </CustomGridItemComponent>
        </div>
        <div key={TOP_TENANTS_INCOME_BOX_KEY}>
          <CustomGridItemComponent>
            <DashboardRankingTopTenantsIncome forceUpdateLayout={setLayout} />
          </CustomGridItemComponent>
        </div>
      </ResponsiveReactGridLayout>
    </>
  );
};

// eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
const CustomGridItemComponent = React.forwardRef(({ style, className, children }: any, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <div style={{ ...style }} className={className} ref={ref as any}>
      {children}
    </div>
  );
});

export default DashboardRanking;
