import { Address, Communication } from '@rentguru/commons-utils';
import { CustomSimpleDialog } from '@up2rent/ui';
import { Formik } from 'formik';
import 'react-image-crop/dist/ReactCrop.css';
import { useIntl } from 'react-intl';
import { OptionnalAddressSchema } from 'src/components/Contacts/AddContact';
import AddressFields from 'src/components/ui/Forms/FormField/AddressFields';
import { useAddresses } from 'src/hooks/AddressContext';
import { useCommunications } from 'src/hooks/CommunicationsContext';
import { useContacts } from 'src/hooks/ContactsContext';

interface EditAddressDialogProps {
  communication: Communication;
  open: boolean;
  onClose: () => void;
}

const EditAddressDialog: React.FC<EditAddressDialogProps> = ({ communication, open, onClose }) => {
  const { formatMessage } = useIntl();
  const { createAddress, deleteAddress } = useAddresses();
  const { updateCommunication } = useCommunications();
  const { contacts } = useContacts();
  const { address } = communication;
  const initialValues = {
    address,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={OptionnalAddressSchema}
      onSubmit={async (values: { address: Address | null | undefined }) => {
        const valuesCopy = {
          address: { ...values.address, id: undefined } as Omit<Address, 'id' | 'readId' | 'clientId'>,
        };

        const createdAddress = await createAddress({ ...valuesCopy.address });
        await updateCommunication(communication, {
          id: communication.id,
          addressId: createdAddress.id,
        });

        // To avoid orphan record
        const initialAddressId = initialValues.address?.id;
        if (initialAddressId && !contacts.some((contact) => contact.addressId === initialAddressId)) {
          await deleteAddress(initialAddressId);
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <CustomSimpleDialog
            open={open}
            onClose={onClose}
            onActionButtonClick={(e?: React.FormEvent<HTMLFormElement>) => {
              handleSubmit(e);
              onClose();
            }}
            actionButtonLoading={isSubmitting}
            actionButtonLabel={formatMessage({ id: 'save' })}
            dividerBelowTitle
            title={formatMessage({ id: 'communications.detail.recipient.editAddress' })}
            formatMessage={formatMessage}
          >
            <AddressFields
              containerStyle={{ marginTop: 10 }}
              placeAutocompleteStyle={{ width: '-webkit-fill-available' }}
            />
          </CustomSimpleDialog>
        );
      }}
    </Formik>
  );
};

export default EditAddressDialog;
