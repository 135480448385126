import React from 'react';
import { Building } from '@rentguru/commons-utils';
import { LeaseAction } from 'src/components/Leases/Details/ActionsMenu/useLeaseActionsMenuUtils';
import AddTechnicDialog from 'src/components/Technics/AddTechnicDialog';
import AddMeterDialog from 'src/components/Technics/AddMeterDialog';
import { Grid } from '@material-ui/core';
import { Redirect, useLocation } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';
import AddInventoryDialog from 'src/components/Technics/AddInventoryDialog';
import { isNil } from 'lodash';
import { useUnits } from 'src/hooks/UnitsContext';
import useTechnicsUtils from 'src/components/Technics/useTechnicsUtils';

export interface PropertyActionMenuProps {
  property: Building;
  type: LeaseAction | null;
  onCloseDialog: () => void;
}

export const PropertyActionMenu: React.FC<PropertyActionMenuProps> = ({ property, type, onCloseDialog }) => {
  const { pathname } = useLocation();
  const { getUnit } = useUnits();
  const { getUnitId, getPropertyId } = useTechnicsUtils();

  const buildingId = getPropertyId();
  const unitId = getUnitId();
  const unit = getUnit(getUnitId() ?? '');

  return (
    <>
      {type === LeaseAction.ADD_TECHNIC && (
        <AddTechnicDialog
          open={type === LeaseAction.ADD_TECHNIC}
          onClose={onCloseDialog}
          id={!isNil(unit) ? unitId : buildingId}
          entity={isNil(unit) ? 'building' : 'unit'}
          shouldRedirectAfterCreate
        />
      )}
      {type === LeaseAction.ADD_METER && (
        <AddMeterDialog
          open={type === LeaseAction.ADD_METER}
          onClose={onCloseDialog}
          id={!isNil(unit) ? unitId! : buildingId ?? ''}
          showComplementaryData
          shouldRedirectAfterCreate
        />
      )}
      {type === LeaseAction.ADD_TICKET && (
        <Grid>
          <Redirect
            to={{
              pathname: RouteDestination.ADD_TICKET,
              state: {
                unitId,
                buildingId,
                goBackUrl: pathname,
              },
            }}
          />
        </Grid>
      )}
      {type === LeaseAction.ADD_FURNITURE_INVENTORY && (
        <AddInventoryDialog
          open={type === LeaseAction.ADD_FURNITURE_INVENTORY}
          onClose={onCloseDialog}
          inventoryType="FURNITURE"
          id={property.id}
        />
      )}
      {type === LeaseAction.ADD_INVENTORY_OF_FIXTURE && (
        <AddInventoryDialog
          open={type === LeaseAction.ADD_INVENTORY_OF_FIXTURE}
          onClose={onCloseDialog}
          inventoryType="FIXTURE"
          id={property.id}
        />
      )}
    </>
  );
};

export default PropertyActionMenu;
