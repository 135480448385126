import React from 'react';
import { ElevatedPaperTable, EnhancedTableSharedContextProvider, EnhancedTable, TablePlaceHolder } from '@up2rent/ui';
import isNil from 'lodash/isNil';
import TicketDetailAttachmentsRow from './TicketDetailAttachmentsRow';
import { Ticket, File } from '@rentguru/commons-utils';
import { useIntl } from 'react-intl';
import { ReactComponent as AttachmentPlaceholderIcon } from 'src/icons/attachementPlaceholder.svg';
import { ReactComponent as NoResultIcon } from 'src/icons/noResult.svg';

interface TicketDetailAttachmentProps {
  ticket?: Ticket;
  fileArray: File[];
}

export interface AttachmentObject {
  bucket: string;
  region: string;
  key: string;
  mimeType: string;
  size: number;
  __typename: string;
  createdAt: string | Date;
  fileKey: string;
}
const columns = [
  {
    id: 'icon',
    labelId: '',
    numeric: false,
    disablePadding: false,
    sortable: false,
  },
  {
    id: 'name',
    labelId: 'tickets.attachment.name',
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
  {
    id: 'date',
    labelId: 'tickets.attachment.date',
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
  {
    id: 'type',
    labelId: 'tickets.attachment.type',
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
  {
    id: 'size',
    labelId: 'tickets.attachment.size',
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
];

const TicketDetailAttachment: React.FC<TicketDetailAttachmentProps> = ({ ticket, fileArray }) => {
  const { formatMessage } = useIntl();
  const conversationList = !isNil(ticket) ? [...ticket.conversations!, ticket] : [];
  const attachmentList = conversationList.map((conversation) => ({ id: conversation.id }));

  return (
    <>
      {!isNil(conversationList) && (
        <ElevatedPaperTable>
          <EnhancedTableSharedContextProvider
            value={{ orderBy: 'createdAt' }}
            orderFirst="desc"
            datas={conversationList}
          >
            <EnhancedTable
              NoResultIcon={<NoResultIcon />}
              formatMessage={formatMessage}
              datas={attachmentList}
              columns={columns}
              defaultOrderBy="createdAt"
              rowComponent={TicketDetailAttachmentsRow}
              noMinWidth={true}
              placeHolder={
                <TablePlaceHolder
                  Icon={<AttachmentPlaceholderIcon />}
                  mainText={formatMessage({ id: 'tickets.placeholder.attachmentMainText' })}
                  subText=""
                />
              }
              fileArray={fileArray}
            />
          </EnhancedTableSharedContextProvider>
        </ElevatedPaperTable>
      )}
    </>
  );
};

export default TicketDetailAttachment;
