/* eslint-disable @typescript-eslint/no-shadow */
import {
  Unit,
  Building,
  Valuation,
  LeasePriceHistory,
  LeaseVariousOperation,
  isBeforeOrEqual,
} from '@rentguru/commons-utils';
import { isNil, isEmpty } from 'lodash';
import { isBefore, closestIndexTo, eachMonthOfInterval, endOfMonth, isAfter, differenceInDays } from 'date-fns';
import {
  calculateExpectedUnitIncomeInTimeWindow,
  getActiveLeasesWithinTimeWindow,
  getBillableLeasesOfTimeWindow,
  getLeaseIndexedRentalPriceForMonth,
  getOwnUseEventsInTimeWindow,
  preFilterLeaseIndexationsAndDiscountsBasedOnUnits,
  TimeWindow,
} from './MetricUtils';

interface OccupationEvent {
  startDate: string;
  endDate: string;
}

interface AcquiredBuildingWithPercentage {
  building: Building;
  managementDate: Date;
  percentage: number; // The percentage of days the building was owned in a time window
}

interface AcquiredUnitWithPercentage {
  unit: Unit;
  managementDate: Date;
  percentage: number; // The percentage of days the unit was owned in a time window
}

// A building is acquired if all its units are acquired
const isBuildingAcquired = (building: Building, acquiredUnits: Unit[]) => {
  if (isNil(building.units) || isEmpty(building.units)) return false;
  return building.units.reduce((buildingAcquired: boolean, unit: Unit) => {
    const isUnitAcquired = acquiredUnits.some((au) => au.id === unit.id);
    if (!isUnitAcquired) {
      return false;
    }
    return buildingAcquired;
  }, true);
};
/**
 * Keep only the units and buildings that were acquired at the given date
 * Units are kept based on their acquisition date.
 * Building are kept if all their units are acquired.
 * Building without units are therefore excluded.
 */
export const getAcquiredUnitsAndBuildingAtDate = (units: Unit[], buildings: Building[], date: Date) => {
  const acquiredUnits = units.filter((u) => isBefore(new Date(u.managementDate), date));
  const acquiredBuildings = buildings.filter((b) => isBuildingAcquired(b, acquiredUnits));
  return { units: acquiredUnits, buildings: acquiredBuildings };
};

const getLatestValuationAtDate = (valuations: Valuation[], date: Date) => {
  const valuationsBeforeDate = valuations.filter((valuation) => isBeforeOrEqual(new Date(valuation.date), date));
  if (isEmpty(valuationsBeforeDate)) return undefined;
  const closestValuationIndex = closestIndexTo(
    date,
    valuationsBeforeDate.map((valuation) => new Date(valuation.date))
  );
  return valuations[closestValuationIndex];
};

/**
 * Return building with its evaluated price at the given date.
 * If no valuations or purchased price, returns undefined.
 */
export const getBuildingEvaluatedPrice = (
  building: Building,
  date: Date,
  getBuildingValuations: (buildingId: string) => Valuation[]
): number | undefined => {
  const valuations = getBuildingValuations(building.id);
  if (!isNil(valuations) && !isEmpty(valuations)) {
    const latestValuationAtDate = getLatestValuationAtDate(valuations, date);
    if (!isNil(latestValuationAtDate)) {
      return latestValuationAtDate.valorization;
    }
  }
  if (!isNil(building.acquisitionValue)) {
    return building.acquisitionValue;
  }
};

/**
 * Return unit  with its evaluated price at the given date
 * If no valuations or purchased price, returns undefined.
 */
export const getUnitEvaluatedPrice = (
  unit: Unit,
  date: Date,
  getUnitValuations: (unitId: string) => Valuation[]
): number | undefined => {
  const valuations = getUnitValuations(unit.id);
  if (!isNil(valuations) && !isEmpty(valuations)) {
    const latestValuationAtDate = getLatestValuationAtDate(valuations, date);
    if (!isNil(latestValuationAtDate)) {
      return latestValuationAtDate.valorization;
    }
  }
  if (!isNil(unit.acquisitionValue)) {
    return unit.acquisitionValue;
  }
};

/**
 * For a set of buildings and units, calculate the total property value,
 * taking their latest valuations if they have one.
 * If not use their acquisition cost instead (if they have one).
 */
export const calculateTotalPropertyValue = (
  units: Unit[],
  buildings: Building[],
  date: Date,
  getUnitValuations: (unitId: string) => Valuation[],
  getBuildingValuations: (buildingId: string) => Valuation[]
) => {
  const unitsEvaluatedPrices = units.reduce((total: number, unit: Unit) => {
    const unitEvaluatedPrice = getUnitEvaluatedPrice(unit, date, getUnitValuations);
    if (!isNil(unitEvaluatedPrice)) total += unitEvaluatedPrice;
    return total;
  }, 0);
  const buildingsEvaluatedPrices = buildings.reduce((total: number, building: Building) => {
    const buildingEvaluatedPrice = getBuildingEvaluatedPrice(building, date, getBuildingValuations);
    if (!isNil(buildingEvaluatedPrice)) total += buildingEvaluatedPrice;
    return total;
  }, 0);
  return unitsEvaluatedPrices + buildingsEvaluatedPrices;
};

/**
 * Calculate the net purchased price using the acquisition values
 */
export const calculateNetPurchasePrice = (units: Unit[], buildings: Building[]) => {
  const unitsNetPurchasedPrices = units.reduce((acc: number, unit: Unit) => {
    if (!isNil(unit.acquisitionValue)) {
      acc += unit.acquisitionValue;
    }
    return acc;
  }, 0);
  const buildingsNetPurchasedPrices = buildings.reduce((acc: number, building: Building) => {
    if (!isNil(building.acquisitionValue)) {
      acc += building.acquisitionValue;
    }
    return acc;
  }, 0);
  return unitsNetPurchasedPrices + buildingsNetPurchasedPrices;
};

/**
 * Calculate the gross purchased price using the acquisition values and the additional costs
 */
export const calculateGrossPurchasePrice = (units: Unit[], buildings: Building[]) => {
  const unitsNetPurchasedPrices = units.reduce((acc: number, unit: Unit) => {
    if (!isNil(unit.acquisitionValue)) {
      const additionalCosts = !isNil(unit.acquisitionAdditionalCostsValue) ? unit.acquisitionAdditionalCostsValue : 0;
      acc += unit.acquisitionValue + additionalCosts;
    }
    return acc;
  }, 0);
  const buildingsNetPurchasedPrices = buildings.reduce((acc: number, building: Building) => {
    if (!isNil(building.acquisitionValue)) {
      const additionalCosts = !isNil(building.acquisitionAdditionalCostsValue)
        ? building.acquisitionAdditionalCostsValue
        : 0;
      acc += building.acquisitionValue + additionalCosts;
    }
    return acc;
  }, 0);
  return unitsNetPurchasedPrices + buildingsNetPurchasedPrices;
};

const unoccupiedDaysBetweenTwoOccupations = (occupationBefore: OccupationEvent, occupationAfter: OccupationEvent) => {
  return differenceInDays(new Date(occupationAfter.startDate), new Date(occupationBefore.endDate));
};

/**
 * Calculate the number of unoccupied days between all the events.
 * The events should be sorted by startDate.
 */
const totalUnoccupiedDaysBetweenOccupations = (sortedOccupationEvents: OccupationEvent[]) => {
  const totalNumberOfUnoccupiedDays = sortedOccupationEvents.reduce(
    (acc: number, occupationEvent: OccupationEvent, index) => {
      if (index !== sortedOccupationEvents.length - 1) {
        const nextOccupationEvent = sortedOccupationEvents[index + 1];
        acc += unoccupiedDaysBetweenTwoOccupations(occupationEvent, nextOccupationEvent);
      }
      return acc;
    },
    0
  );
  return totalNumberOfUnoccupiedDays;
};

/**
 * Calculate the number of rental vacancy days of an unit in a time window
 */
export const numberOfVacancyDaysOfUnitInTimeWindow = (unit: Unit, timeWindow: TimeWindow) => {
  const unitLeasesInTimeWindow = getActiveLeasesWithinTimeWindow(
    unit.leases!.map((ul) => ul.lease!),
    timeWindow
  );
  const unitOwnUsesInTimeWindow = getOwnUseEventsInTimeWindow(unit.events!, timeWindow);
  const occupationEvents = [...unitLeasesInTimeWindow, ...unitOwnUsesInTimeWindow];
  const occupationsSortedByStartDate: OccupationEvent[] = occupationEvents.sort((l1, l2) => {
    return isBefore(new Date(l1.startDate), new Date(l2.startDate)) ? -1 : 1;
  });
  const firstOccupationEvent = occupationsSortedByStartDate[0] as OccupationEvent | undefined;
  const lastOccupationEvent = occupationsSortedByStartDate[occupationsSortedByStartDate.length - 1] as
    | OccupationEvent
    | undefined;
  if (isNil(firstOccupationEvent) || isAfter(new Date(firstOccupationEvent.startDate), timeWindow.start)) {
    occupationsSortedByStartDate.unshift({
      startDate: timeWindow.start.toISOString(),
      endDate: timeWindow.start.toISOString(),
    });
  }
  if (isNil(lastOccupationEvent) || isBefore(new Date(lastOccupationEvent.endDate), timeWindow.end)) {
    occupationsSortedByStartDate.push({
      startDate: timeWindow.end.toISOString(),
      endDate: timeWindow.end.toISOString(),
    });
  }
  return totalUnoccupiedDaysBetweenOccupations(occupationsSortedByStartDate);
};

/**
 * Calculate the average number of days the leases stay inactive or are in renovations.
 * i.e. the number of days they are not rented or occupied by the owner.
 */
export const calculateAverageDaysOfRentalVacancy = (units: Unit[], timeWindow: TimeWindow) => {
  const unitAveragedDaysToLease = units.reduce(
    (acc: { totalValue: number; unitsAdded: number }, unit: Unit) => {
      // Ignore units that we acquired in the running year => We didn't handle them all year
      if (isBefore(new Date(unit.managementDate), timeWindow.start)) {
        const numberOfVacancyDaysOfUnit = numberOfVacancyDaysOfUnitInTimeWindow(unit, timeWindow);
        acc.totalValue += numberOfVacancyDaysOfUnit;
        acc.unitsAdded += 1;
      }
      return acc;
    },
    { totalValue: 0, unitsAdded: 0 }
  );
  return unitAveragedDaysToLease.unitsAdded === 0
    ? 0
    : unitAveragedDaysToLease.totalValue / unitAveragedDaysToLease.unitsAdded;
};

/**
 * For each month of the time window, get the indexed rent associated to the billable lease of the month.
 * After each month was iterated, perform an weighted sum between the perceived rents.
 * The wieghts of the sum are the ratio between the number of times the lease was billed and the total number of months
 */
const getUnitAverageRentInTimeWindow = (unit: Unit, leaseIndexations: LeasePriceHistory[], timeWindow: TimeWindow) => {
  const eachMonthToCompute = eachMonthOfInterval(timeWindow);
  const unitLeases = unit.leases!.map((ul) => ul.lease!);
  const proRataRents = eachMonthToCompute.reduce((acc: { [key: string]: number }, month) => {
    const timeWindow = { start: month, end: endOfMonth(month) };
    // Get the billable lease of the month
    const monthBillableLease = getBillableLeasesOfTimeWindow(unitLeases, timeWindow)[0];
    if (!isNil(monthBillableLease)) {
      const leaseIncome = getLeaseIndexedRentalPriceForMonth(monthBillableLease, leaseIndexations, month);
      const previousIncomeValue = acc[leaseIncome];
      if (isNil(previousIncomeValue)) {
        // New lease income value => Add it to the object
        acc[leaseIncome] = 1;
      } else {
        // Existing income value => One more
        acc[leaseIncome] += 1;
      }
    }
    return acc;
  }, {});
  // Finally perform the weighted sum
  let totalCumulatedRent = 0;
  let totalNumberOfMonths = 0;
  for (const rent of Object.keys(proRataRents)) {
    const numberOfMonths = proRataRents[rent];
    totalCumulatedRent += Number(rent) * numberOfMonths;
    totalNumberOfMonths += numberOfMonths;
  }
  return totalNumberOfMonths > 0 ? totalCumulatedRent / totalNumberOfMonths : 0;
};
/**
 * Calculate the average indexed rent on all active leases in time period
 */
export const calculateAverageRent = (units: Unit[], leaseIndexations: LeasePriceHistory[], timeWindow: TimeWindow) => {
  const { unitsAdded, totalRent } = units.reduce(
    (acc: { unitsAdded: number; totalRent: number }, unit) => {
      const averageUnitRent = getUnitAverageRentInTimeWindow(unit, leaseIndexations, timeWindow);
      if (averageUnitRent > 0) {
        acc.unitsAdded += 1;
        acc.totalRent += averageUnitRent;
      }
      return acc;
    },
    {
      unitsAdded: 0,
      totalRent: 0,
    }
  );
  return unitsAdded !== 0 ? totalRent / unitsAdded : 0;
};

/**
 * Calculate the gross rental yield of a given year based on the the acquired Units and Buildings
 * The gross rental yield is calculated the following way:
 * Expected yearly rental income of the units/buildings divided by their evaluated property value at the end of the year
 *
 * If units are acquired in the middle of the year, their property value are weighted by the number of days they
 * were acquired during the year.
 *
 * If buildings are acquired in the middle of the year. We use the ame pro rata on all their units, starting from the
 * day the last building's unit was acquired. (all rents are therefore consider after this date).
 * e.g. if a building has 3 units but acquired the 3rd one only in the middle of year. Its 3 units will add half
 * their property values and their rents will be measured starting the half of the year.
 */
export const calculateGrossRentalYield = (
  acquiredUnits: Unit[],
  acquiredBuildings: Building[],
  yearWindow: TimeWindow,
  getUnitValuations: (unitId: string) => Valuation[],
  getBuildingValuations: (buildingId: string) => Valuation[],
  leaseIndexations: LeasePriceHistory[],
  discounts: LeaseVariousOperation[]
) => {
  // Handle units and buildings that arrive in the middle of the year
  const { acquiredUnitsWithPercentage, acquiredBuildingsWithPercentage } = percentageOfAcquiredDaysDuringTimeWindow(
    acquiredUnits,
    acquiredBuildings,
    yearWindow
  );
  /* The units below are those that participate in the property value.
  The rental yield will be based on how much money they made compared to their property values */
  const unitsThatAddedPropertyValues: AcquiredUnitWithPercentage[] = [];

  /* Total property value with pro rata */
  const unitsProRataTotalPropertyValue = acquiredUnitsWithPercentage.reduce((acc: number, unitWithPercentage) => {
    const { unit, percentage } = unitWithPercentage;
    const unitEvaluatedPrice = getUnitEvaluatedPrice(unit, yearWindow.end, getUnitValuations);
    if (!isNil(unitEvaluatedPrice)) {
      acc += percentage * unitEvaluatedPrice;
      unitsThatAddedPropertyValues.push(unitWithPercentage);
    }
    return acc;
  }, 0);
  const buildingsProRataTotalPropertyValue = acquiredBuildingsWithPercentage.reduce(
    (acc: number, buildingWithPercentage) => {
      const { building, percentage, managementDate } = buildingWithPercentage;
      const buildingEvaluatedPrice = getBuildingEvaluatedPrice(building, yearWindow.end, getBuildingValuations);
      if (!isNil(buildingEvaluatedPrice)) {
        acc += percentage * buildingEvaluatedPrice;
        for (const unit of building.units!) {
          const completeUnit = acquiredUnits.find((u) => u.id === unit.id);
          // Push unit BUT with building percentage and acquisition date!
          if (!isNil(completeUnit)) {
            unitsThatAddedPropertyValues.push({ unit: completeUnit, percentage, managementDate });
          }
        }
      }
      return acc;
    },
    0
  );
  const totalProRataPropertyValue = unitsProRataTotalPropertyValue + buildingsProRataTotalPropertyValue;
  /* Yearly rental income considering entities acquired in the running year */
  const totalProRataExpectedAnnualIncome = unitsThatAddedPropertyValues.reduce((acc: number, unitWithPercentage) => {
    const { unit, percentage, managementDate } = unitWithPercentage;
    let timeWindow: TimeWindow;
    if (percentage < 1) {
      // unit acquired in the running year => Only consider rents starting from acquisition date
      timeWindow = { start: managementDate, end: yearWindow.end };
    } else {
      timeWindow = yearWindow;
    }
    const { leaseIndexations: filteredLeaseIndexations, discounts: filteredDiscounts } =
      preFilterLeaseIndexationsAndDiscountsBasedOnUnits([unit], leaseIndexations, discounts);
    const annualUnitIncome = calculateExpectedUnitIncomeInTimeWindow(
      unit,
      filteredLeaseIndexations,
      filteredDiscounts,
      timeWindow
    );
    return acc + annualUnitIncome;
  }, 0);

  return totalProRataExpectedAnnualIncome === 0 || totalProRataPropertyValue === 0
    ? 0
    : totalProRataExpectedAnnualIncome / totalProRataPropertyValue;
};

/**
 * Calculate the percentage units and buildings were acquired during the time window
 * Buildings percentage are calculated based on their lowest unit percentage.
 * Unacquired units and buildings are filtered.
 */
const percentageOfAcquiredDaysDuringTimeWindow = (
  acquiredUnits: Unit[],
  acquiredBuildings: Building[],
  timeWindow: TimeWindow
): {
  acquiredUnitsWithPercentage: AcquiredUnitWithPercentage[];
  acquiredBuildingsWithPercentage: AcquiredBuildingWithPercentage[];
} => {
  const numberOfDaysInTimeWindow = differenceInDays(timeWindow.end, timeWindow.start);
  const acquiredUnitsWithPercentage = acquiredUnits.reduce((acc: AcquiredUnitWithPercentage[], unit) => {
    const managementDate = new Date(unit.managementDate);
    if (isBefore(managementDate, timeWindow.start)) {
      // The unit was already acquired before the start date => 100% possessed
      acc.push({ unit, percentage: 1, managementDate });
    } else if (isBefore(managementDate, timeWindow.end)) {
      // The unit was acquired before the end date => calculate how much days compared to time window
      const numberOfAcquiredDaysInTimeWindow = differenceInDays(timeWindow.end, managementDate);
      acc.push({ unit, percentage: numberOfAcquiredDaysInTimeWindow / numberOfDaysInTimeWindow, managementDate });
    }
    return acc;
  }, []);
  const acquiredBuildingsWithPercentage = acquiredBuildings.reduce(
    (acc: AcquiredBuildingWithPercentage[], building) => {
      const result = buildingAcquiredDaysPercentage(building, acquiredUnitsWithPercentage);
      if (!isNil(result)) {
        acc.push({ building, percentage: result.percentage, managementDate: result.managementDate });
      }
      return acc;
    },
    []
  );
  return { acquiredUnitsWithPercentage, acquiredBuildingsWithPercentage };
};

/**
 * The acquisition date and percentage of the building correspond the lowest one among all its units.
 * If all its units are not acquired, the building is ignored.
 */
const buildingAcquiredDaysPercentage = (building: Building, acquiredUnits: AcquiredUnitWithPercentage[]) => {
  if (isNil(building.units) || isEmpty(building.units)) return undefined;
  const { lowestPercentage, allUnitsAcquired, managementDate } = building.units.reduce(
    (acc: { lowestPercentage: number; allUnitsAcquired: boolean; managementDate: Date }, unit: Unit) => {
      const unitBuildingWithPercentage = acquiredUnits.find((u) => u.unit.id === unit.id);
      if (isNil(unitBuildingWithPercentage)) acc.allUnitsAcquired = false;
      else {
        const previousLowestPercentage = acc.lowestPercentage;
        const newPercentage = unitBuildingWithPercentage.percentage;
        if (newPercentage < previousLowestPercentage) {
          acc.managementDate = unitBuildingWithPercentage.managementDate;
          acc.lowestPercentage = newPercentage;
        }
      }
      return acc;
    },
    { lowestPercentage: 1, allUnitsAcquired: true, managementDate: new Date() }
  );
  if (allUnitsAcquired) return { percentage: lowestPercentage, managementDate };
};

export const calculateNumberOfUnits = (units: Unit[]) => {
  return units.length;
};
