/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-param-last */
import {
  CommunicationSettingsProfile,
  CommunicationSettingsProfileReferent,
  CreateCommunicationSettingsProfileInput,
  CreateCommunicationSettingsProfileMutationVariables,
  DeleteCommunicationSettingsProfileMutationVariables,
  LooseObject,
  UpdateCommunicationSettingsProfileInput,
  cleanInputCreate,
  cleanInputUpdate,
  createCommunicationSettingsProfile as createMutation,
  deleteCommunicationSettingsProfile as deleteMutation,
  getCommunicationSettingsProfile as getQuery,
  getReadId,
  getTableClientId,
  syncCommunicationSettingsProfiles,
  updateCommunicationSettingsProfile as updateMutation,
} from '@rentguru/commons-utils';
import { NUMBER_OF_MINUTES_FOR_REFETCH, deleteEntityWithFetchBefore, list, get, mutation } from '@up2rent/fetch-utils';
import { differenceInMinutes } from 'date-fns';
import { isEmpty, isNil } from 'lodash';
import React, { Reducer, useContext, useEffect, useMemo, useReducer } from 'react';
import { useUser } from './UserContext';
import { usePermissions } from './utils/PermissionsContext';

export interface CommunicationSettingsProfileContext extends State {
  getDefaultLeaseCommunicationSettingsProfiles: () => CommunicationSettingsProfile[];
  getDefaultOwnerCommunicationSettingsProfiles: () => CommunicationSettingsProfile[];
  getDefaultTechnicCommunicationSettingsProfiles: () => CommunicationSettingsProfile[];
  createCommunicationSettingsProfile: (
    input:
      | CreateCommunicationSettingsProfileInput
      | Omit<CreateCommunicationSettingsProfileInput, 'clientId' | 'readId'>
  ) => Promise<CommunicationSettingsProfile>;
  updateCommunicationSettingsProfile: (
    original: CommunicationSettingsProfile,
    updates: Partial<UpdateCommunicationSettingsProfileInput>
  ) => Promise<CommunicationSettingsProfile>;
  deleteCommunicationSettingsProfile: (
    communicationSettingsProfile: CommunicationSettingsProfile,
    replacementId?: string
  ) => Promise<CommunicationSettingsProfile | null>;
}

interface State {
  loading: boolean;
  error: string | null;
  shouldFetch: boolean;
  lastCommunicationSettingsProfilesFetch: Date | null;
  defaultCommunicationSettingsProfiles: CommunicationSettingsProfile[] | null;
}

type Action =
  | {
      type: 'FETCH_COMMUNICATION_SETTINGS_PROFILES';
    }
  | {
      type: 'FETCHED';
      payload: { defaultCommunicationSettingsProfiles: CommunicationSettingsProfile[] };
    }
  | {
      type: 'ADD' | 'UPDATE';
      payload: { communicationSettingsProfile: CommunicationSettingsProfile };
    }
  | {
      type: 'DELETE';
      payload: { id: string };
    };

const initialState: State = {
  loading: false,
  error: null,
  shouldFetch: true,
  defaultCommunicationSettingsProfiles: null,
  lastCommunicationSettingsProfilesFetch: null,
};

const contextReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'FETCH_COMMUNICATION_SETTINGS_PROFILES':
      // Check lastFetch
      if (
        state.loading ||
        state.shouldFetch ||
        (state.lastCommunicationSettingsProfilesFetch &&
          differenceInMinutes(new Date(), state.lastCommunicationSettingsProfilesFetch) < NUMBER_OF_MINUTES_FOR_REFETCH)
      ) {
        return state;
      }
      return {
        ...state,
        shouldFetch: true,
        loading: true,
      };
    case 'FETCHED':
      // Build List with merged object
      const newDefaultCommunicationSettingsProfiles = [
        ...action.payload.defaultCommunicationSettingsProfiles,
        ...(state.defaultCommunicationSettingsProfiles ?? []).filter(
          (oldCommunicationSettingsProfile) =>
            !action.payload.defaultCommunicationSettingsProfiles.some(
              (newCommunicationSettingsProfile) =>
                newCommunicationSettingsProfile.id === oldCommunicationSettingsProfile.id
            )
        ),
      ];

      return {
        ...state,
        loading: !isEmpty(state.shouldFetch),
        shouldFetch: false,
        lastCommunicationSettingsProfilesFetch: new Date(),
        defaultCommunicationSettingsProfiles: newDefaultCommunicationSettingsProfiles,
      };
    case 'ADD':
      if (!state.defaultCommunicationSettingsProfiles) {
        return state;
      }
      const entity = action.payload.communicationSettingsProfile;
      if (!entity.isDefaultSetting) {
        return state;
      }
      return {
        ...state,
        defaultCommunicationSettingsProfiles: [...state.defaultCommunicationSettingsProfiles, entity],
      };
    case 'UPDATE':
      if (!state.defaultCommunicationSettingsProfiles) {
        return state;
      }

      return {
        ...state,
        defaultCommunicationSettingsProfiles: state.defaultCommunicationSettingsProfiles.map((item) => {
          if (item.id === action.payload.communicationSettingsProfile.id) {
            return action.payload.communicationSettingsProfile;
          }
          return item;
        }),
      };
    case 'DELETE':
      if (!state.defaultCommunicationSettingsProfiles) {
        return state;
      }

      return {
        ...state,
        defaultCommunicationSettingsProfiles: state.defaultCommunicationSettingsProfiles.filter(
          (item) => item.id !== action.payload.id
        ),
      };
    default:
      return state;
  }
};

export const getCommunicationSettingsProfile = async (id: string) =>
  await get<CommunicationSettingsProfile>(getQuery, id);

const fetchCommunicationSettingsProfiles = async (clientId: string, additionalFilter?: LooseObject) =>
  await list<CommunicationSettingsProfile>(syncCommunicationSettingsProfiles, 'clientId', clientId, additionalFilter);

export const CommunicationSettingsProfileContext = React.createContext<CommunicationSettingsProfileContext | null>(
  null
);

export const CommunicationSettingsProfileContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { settingsAutomationWrite } = usePermissions();
  const { clientId } = useUser();
  const [state, dispatch] = useReducer<Reducer<State, Action>>(contextReducer, initialState);

  useEffect(() => {
    let unmounted = false;
    const fetchAndSet = async () => {
      const defaultCommunicationSettingsProfiles = await fetchCommunicationSettingsProfiles(
        getTableClientId(clientId!, 'CommunicationSettingsProfile'),
        { and: [{ isDefaultSetting: { eq: true } }] }
      );

      let technicProfileIsMissing = true;
      let leaseProfileIsMissing = true;
      let ownerProfileIsMissing = true;
      for (const profile of defaultCommunicationSettingsProfiles) {
        if (ownerProfileIsMissing && profile.referent === CommunicationSettingsProfileReferent.OWNER) {
          ownerProfileIsMissing = false;
        } else if (leaseProfileIsMissing && profile.referent === CommunicationSettingsProfileReferent.LEASE) {
          leaseProfileIsMissing = false;
        } else if (technicProfileIsMissing && profile.referent === CommunicationSettingsProfileReferent.TECHNIC) {
          technicProfileIsMissing = false;
        }
      }

      if (ownerProfileIsMissing || leaseProfileIsMissing || ownerProfileIsMissing) {
        throw new Error('A communication settings profile is missing');
      }
      if (!unmounted) {
        dispatch({
          type: 'FETCHED',
          payload: { defaultCommunicationSettingsProfiles },
        });
      }
    };
    if (state.shouldFetch) {
      fetchAndSet();
    }
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetch]);

  const getDefaultLeaseCommunicationSettingsProfiles = () => {
    dispatch({ type: 'FETCH_COMMUNICATION_SETTINGS_PROFILES' });
    if (state.loading || state.error || !state.defaultCommunicationSettingsProfiles) {
      return [];
    }

    return state.defaultCommunicationSettingsProfiles.filter(
      (communicationSettingProfile) =>
        communicationSettingProfile.referent === CommunicationSettingsProfileReferent.LEASE
    );
  };

  const getDefaultTechnicCommunicationSettingsProfiles = () => {
    dispatch({ type: 'FETCH_COMMUNICATION_SETTINGS_PROFILES' });
    if (state.loading || state.error || !state.defaultCommunicationSettingsProfiles) {
      return [];
    }

    return state.defaultCommunicationSettingsProfiles.filter(
      (communicationSettingProfile) =>
        communicationSettingProfile.referent === CommunicationSettingsProfileReferent.TECHNIC
    );
  };

  const getDefaultOwnerCommunicationSettingsProfiles = () => {
    dispatch({ type: 'FETCH_COMMUNICATION_SETTINGS_PROFILES' });
    if (state.loading || state.error || !state.defaultCommunicationSettingsProfiles) {
      return [];
    }

    return state.defaultCommunicationSettingsProfiles.filter(
      (communicationSettingProfile) =>
        communicationSettingProfile.referent === CommunicationSettingsProfileReferent.OWNER
    );
  };

  const createCommunicationSettingsProfile = async (
    input:
      | CreateCommunicationSettingsProfileInput
      | Omit<CreateCommunicationSettingsProfileInput, 'clientId' | 'readId'>
  ): Promise<CommunicationSettingsProfile> => {
    const communicationSettingsProfile = await mutation<
      CommunicationSettingsProfile,
      CreateCommunicationSettingsProfileMutationVariables
    >(createMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateCommunicationSettingsProfileInput),
        clientId: getTableClientId(clientId!, 'CommunicationSettingsProfile'),
        readId: getReadId(clientId!, 'CommunicationSettingsProfile'),
      },
    });
    dispatch({ type: 'ADD', payload: { communicationSettingsProfile } });
    return communicationSettingsProfile;
  };

  const updateCommunicationSettingsProfile = async (
    original: CommunicationSettingsProfile,
    updates: Partial<UpdateCommunicationSettingsProfileInput>
  ): Promise<CommunicationSettingsProfile> => {
    const communicationSettingsProfile = await mutation<CommunicationSettingsProfile>(updateMutation, {
      input: { ...cleanInputUpdate({ id: original.id, _version: original._version, ...updates }, false) },
    });
    dispatch({ type: 'UPDATE', payload: { communicationSettingsProfile } });
    return communicationSettingsProfile;
  };

  const deleteCommunicationSettingsProfile = async (
    communicationSettingsProfile: CommunicationSettingsProfile,
    replacementId?: string
  ): Promise<CommunicationSettingsProfile | null> => {
    if (!settingsAutomationWrite) {
      return null;
    }
    const profileToDelete = isNil(replacementId)
      ? communicationSettingsProfile
      : await mutation<CommunicationSettingsProfile>(updateMutation, {
          input: {
            ...cleanInputUpdate(
              { id: communicationSettingsProfile.id, _version: communicationSettingsProfile._version, replacementId },
              false
            ),
          },
        });

    const result = await deleteEntityWithFetchBefore<
      CommunicationSettingsProfile,
      DeleteCommunicationSettingsProfileMutationVariables
    >(profileToDelete, getQuery, deleteMutation);
    dispatch({ type: 'DELETE', payload: { id: communicationSettingsProfile.id } });
    return result;
  };

  const values = useMemo(
    () => ({
      ...state,
      getDefaultLeaseCommunicationSettingsProfiles,
      getDefaultTechnicCommunicationSettingsProfiles,
      getDefaultOwnerCommunicationSettingsProfiles,
      createCommunicationSettingsProfile,
      updateCommunicationSettingsProfile,
      deleteCommunicationSettingsProfile,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return (
    <CommunicationSettingsProfileContext.Provider value={values}>
      {children}
    </CommunicationSettingsProfileContext.Provider>
  );
};

export const useCommunicationSettingsProfiles = (): CommunicationSettingsProfileContext => {
  const context = useContext<CommunicationSettingsProfileContext | null>(CommunicationSettingsProfileContext);

  if (!context) {
    throw new Error(
      '`useCommunicationSettingsProfiles` hook must be used within a `CommunicationSettingsProfileContext` component'
    );
  }

  return context;
};
