import { getContactNameFromObject, isNilOrEmpty, uniquePush, Lease, Unit } from '@rentguru/commons-utils';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Filter } from 'src/hooks/FiltersContext';
import { LeaseExtendedWithOwner } from 'src/hooks/LeasesContext';
import { FieldFilterSelector, SelectorChildrenProps } from '../../ui/FieldFilterSelector';
import { getSorting, stableSort } from '@up2rent/ui';

interface LeasesFiltersCommonsProps {
  handleFilterChange: (
    selectedItems: SelectorChildrenProps[],
    filterName: string,
    fieldName: string,
    menuClosed: boolean
  ) => void;
  leases: LeaseExtendedWithOwner[];
  filters: Filter[];
}
const LeasesFiltersCommons: React.FC<LeasesFiltersCommonsProps> = ({ handleFilterChange, leases, filters }) => {
  const { formatMessage } = useIntl();

  // Lease name
  const leaseNames = useMemo<SelectorChildrenProps[]>(() => {
    const names = leases.map((lease) => ({ value: lease.id, label: lease.name }));
    return stableSort(names, getSorting('asc', 'label'));
  }, [leases]);
  // Tenants name
  const tenantsNames = useMemo<SelectorChildrenProps[]>(() => {
    const names = leases.reduce((result, lease) => {
      lease.tenants.forEach((tenant) => {
        const tenantName = getContactNameFromObject(tenant);
        uniquePush(result, { value: tenant.id, label: tenantName });
      });
      return result;
    }, [] as SelectorChildrenProps[]);
    return stableSort(names, getSorting('asc', 'label'));
  }, [leases]);

  // Building name
  const units = useMemo<Unit[]>(() => {
    // Given lease object should have units and building
    return leases.reduce((currentList: Unit[], currentObject: Lease) => {
      if (isNilOrEmpty(currentObject.units)) {
        return currentList;
      }

      currentObject.units?.forEach((unitLease) => {
        currentList.push(unitLease.unit!);
      });

      return currentList;
    }, [] as Unit[]);
  }, [leases]);
  const propertiesNames = useMemo<SelectorChildrenProps[]>(() => {
    const names = units.reduce((result, currentUnit) => {
      if (!currentUnit || !currentUnit.building) {
        return result;
      }
      uniquePush(result, { value: currentUnit.building.id, label: currentUnit.building.name });
      return result;
    }, [] as SelectorChildrenProps[]);
    return stableSort(names, getSorting('asc', 'label'));
  }, [units]);

  // Cities name
  const citiesNames = useMemo<SelectorChildrenProps[]>(() => {
    const names = units.reduce((result, currentUnit) => {
      if (
        !currentUnit ||
        !currentUnit.building ||
        !currentUnit.building.address ||
        !currentUnit.building.address.city
      ) {
        return result;
      }
      const city = currentUnit.building.address.city;
      uniquePush(result, { value: city, label: city });
      return result;
    }, [] as SelectorChildrenProps[]);
    return stableSort(names, getSorting('asc', 'label'));
  }, [units]);

  // Units name
  const unitsNames = useMemo<SelectorChildrenProps[]>(() => {
    const names = units.reduce((result, currentUnit) => {
      if (!currentUnit) {
        return result;
      }
      uniquePush(result, { value: currentUnit.id, label: currentUnit.name });
      return result;
    }, [] as SelectorChildrenProps[]);

    return stableSort(names, getSorting('asc', 'label'));
  }, [units]);

  // Type
  const typeNames = leases
    .reduce((currentList: string[], currentObject: Lease) => {
      if (currentObject.type && !currentList.includes(currentObject.type)) {
        currentList.push(currentObject.type);
      }
      return currentList;
    }, [])
    .map((type) => ({ value: type, label: formatMessage({ id: `enums.LeaseType.${type}` }) }));

  // Owner name
  const ownersNames = useMemo<SelectorChildrenProps[]>(() => {
    const names = leases.reduce((result, lease) => {
      if (!lease.owner) {
        return result;
      }
      const ownerName = getContactNameFromObject(lease.owner);
      uniquePush(result, { value: lease.owner.id, label: ownerName });
      return result;
    }, [] as SelectorChildrenProps[]);
    return stableSort(names, getSorting('asc', 'label'));
  }, [leases]);

  // Selected?
  const initialLeasesSelectedItems = filters.filter((f) => f.name === 'lease');
  const initialLeasesSelectedItemsOrNull = isEmpty(initialLeasesSelectedItems)
    ? null
    : initialLeasesSelectedItems[0].items.map((i) => ({ value: i }));
  const initialTenantsSelectedItems = filters.filter((f) => f.name === 'tenants');
  const initialTenantsSelectedItemsOrNull = isEmpty(initialTenantsSelectedItems)
    ? null
    : initialTenantsSelectedItems[0].items.map((i) => ({ value: i }));
  const initialBuildingsSelectedItems = filters.filter((f) => f.name === 'building');
  const initialBuildingsSelectedItemsOrNull = isEmpty(initialBuildingsSelectedItems)
    ? null
    : initialBuildingsSelectedItems[0].items.map((i) => ({ value: i }));

  const initialUnitsSelectedItems = filters.filter((f) => f.name === 'unit');
  const initialUnitsSelectedItemsOrNull = isEmpty(initialUnitsSelectedItems)
    ? null
    : initialUnitsSelectedItems[0].items.map((i) => ({ value: i }));
  const initialTypesSelectedItems = filters.filter((f) => f.name === 'type');
  const initialTypesSelectedItemsOrNull = isEmpty(initialTypesSelectedItems)
    ? null
    : initialTypesSelectedItems[0].items.map((i) => ({ value: i }));
  const initialCitiesSelectedItems = filters.filter((f) => f.name === 'city');
  const initialCitiesSelectedItemsOrNull = isEmpty(initialCitiesSelectedItems)
    ? null
    : initialCitiesSelectedItems[0].items.map((i) => ({ value: i }));
  const initialOwnersSelectedItems = filters.filter((f) => f.name === 'owner');
  const initialOwnersSelectedItemsOrNull = isEmpty(initialOwnersSelectedItems)
    ? null
    : initialOwnersSelectedItems[0].items.map((i) => ({ value: i }));

  return (
    <>
      <FieldFilterSelector
        label={formatMessage({ id: 'property.filters.leaseName' })}
        multipleSelection
        showResetButton
        filterName="lease"
        fieldName="id"
        textReset={formatMessage({ id: 'reinitFilter' })}
        textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
        textSearchField={formatMessage({ id: 'search' })}
        textSelectAll={formatMessage({ id: 'selectAll' })}
        onChange={handleFilterChange}
        initialSelectedItems={initialLeasesSelectedItemsOrNull}
        scrollable
        scrollableInitialLimit={50}
      >
        {leaseNames}
      </FieldFilterSelector>

      <FieldFilterSelector
        label={formatMessage({ id: 'property.filters.tenantName' })}
        multipleSelection
        showResetButton
        filterName="tenants"
        fieldName="id"
        textReset={formatMessage({ id: 'reinitFilter' })}
        textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
        textSearchField={formatMessage({ id: 'search' })}
        textSelectAll={formatMessage({ id: 'selectAll' })}
        onChange={handleFilterChange}
        initialSelectedItems={initialTenantsSelectedItemsOrNull}
        scrollable
        scrollableInitialLimit={50}
      >
        {tenantsNames}
      </FieldFilterSelector>

      <FieldFilterSelector
        label={formatMessage({ id: 'property.filters.propertyName' })}
        multipleSelection
        showResetButton
        filterName="building"
        fieldName="units.unit.building.id"
        textReset={formatMessage({ id: 'reinitFilter' })}
        textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
        textSearchField={formatMessage({ id: 'search' })}
        textSelectAll={formatMessage({ id: 'selectAll' })}
        onChange={handleFilterChange}
        initialSelectedItems={initialBuildingsSelectedItemsOrNull}
        scrollable
        scrollableInitialLimit={50}
      >
        {propertiesNames}
      </FieldFilterSelector>

      <FieldFilterSelector
        label={formatMessage({ id: 'building.columnHeader.city' })}
        multipleSelection
        showResetButton
        filterName="city"
        fieldName="units.unit.building.address.city"
        textReset={formatMessage({ id: 'reinitFilter' })}
        textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
        textSearchField={formatMessage({ id: 'search' })}
        textSelectAll={formatMessage({ id: 'selectAll' })}
        onChange={handleFilterChange}
        initialSelectedItems={initialCitiesSelectedItemsOrNull}
        scrollable
        scrollableInitialLimit={50}
      >
        {citiesNames}
      </FieldFilterSelector>

      <FieldFilterSelector
        label={formatMessage({ id: 'property.filters.unitName' })}
        multipleSelection
        showResetButton
        filterName="unit"
        fieldName="units.unit.id"
        textReset={formatMessage({ id: 'reinitFilter' })}
        textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
        textSearchField={formatMessage({ id: 'search' })}
        textSelectAll={formatMessage({ id: 'selectAll' })}
        onChange={handleFilterChange}
        initialSelectedItems={initialUnitsSelectedItemsOrNull}
        scrollable
        scrollableInitialLimit={50}
      >
        {unitsNames}
      </FieldFilterSelector>

      <FieldFilterSelector
        label={formatMessage({ id: 'building.detail.general.owner' })}
        multipleSelection
        showResetButton
        filterName="owner"
        fieldName="owner.id"
        textReset={formatMessage({ id: 'reinitFilter' })}
        textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
        textSearchField={formatMessage({ id: 'search' })}
        textSelectAll={formatMessage({ id: 'selectAll' })}
        onChange={handleFilterChange}
        initialSelectedItems={initialOwnersSelectedItemsOrNull}
        scrollable
        scrollableInitialLimit={50}
      >
        {ownersNames}
      </FieldFilterSelector>

      <FieldFilterSelector
        label={formatMessage({ id: 'rentalUnit.columnHeader.type' })}
        multipleSelection
        showResetButton
        showSearchField={false}
        filterName="type"
        fieldName="type"
        textReset={formatMessage({ id: 'reinitFilter' })}
        textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
        textSearchField={formatMessage({ id: 'search' })}
        textSelectAll={formatMessage({ id: 'selectAll' })}
        onChange={handleFilterChange}
        initialSelectedItems={initialTypesSelectedItemsOrNull}
      >
        {typeNames}
      </FieldFilterSelector>
    </>
  );
};

export default LeasesFiltersCommons;
