/* eslint-disable @typescript-eslint/no-shadow */
import React, { lazy } from 'react';
import {
  LeaseExtended,
  FEATURE_INVENTORY,
  FEATURE_ADVERTISEMENT,
  isFeatureEnabled,
  removeFirstCharacter,
  LeaseMonthlyChargesType,
  Unit,
} from '@rentguru/commons-utils';
import { PermissionsContext } from 'src/hooks/utils/PermissionsContext';
import { isNil } from 'lodash';
// eslint-disable-next-line
import { generatePath, match } from 'react-router-dom';
import TicketsFilters from '../../Tickets/TicketsFilters';
import { RouteDestination } from 'src/components/Routes/Routes';
import { retryLazy } from 'src/components/Routes/LazyErrorBoundary';

/* Lease Menu Import  */
const GeneralDetails = lazy(() => retryLazy(() => import('./GeneralDetails/GeneralDetails')));
const Financial = lazy(() => retryLazy(() => import('./Financial/Financial')));
const LeaseVariousOperations = lazy(() => retryLazy(() => import('./LeaseVariousOperations')));
const Inventory = lazy(() => retryLazy(() => import('src/components/RentalUnits/Details/Inventory/Inventory')));
const AccountingSetting = lazy(() => retryLazy(() => import('./AccountingSettings')));
const LeaseIndexation = lazy(() => retryLazy(() => import('./LeaseIndexation/LeaseIndexation')));
const TenantGeneralDetails = lazy(() =>
  retryLazy(() => import('../../TenantView/Leases/Details/TenantGeneralDetails'))
);
const TenantInventory = lazy(() => retryLazy(() => import('src/components/TenantView/Inventory/TenantInventory')));
const TenantLeasePriceHistory = lazy(() =>
  retryLazy(() => import('src/components/TenantView/Leases/Details/TenantLeasePriceHistory'))
);
const TenantLeaseTransaction = lazy(() =>
  retryLazy(() => import('src/components/TenantView/Leases/TenantLeaseTransaction'))
);
const TenantLeaseVariousOperation = lazy(() =>
  retryLazy(() => import('src/components/TenantView/Leases/TenantLeaseVariousOperation'))
);
const TenantLeaseCommunications = lazy(() =>
  retryLazy(() => import('src/components/TenantView/Leases/TenantUnitCommunications'))
);

/* Unit Menu Import */
const UnitGeneralDetails = lazy(() => retryLazy(() => import('../../RentalUnits/Details/GeneralDetails')));
const Advertisement = lazy(() => retryLazy(() => import('../../RentalUnits/Details/Publication/Publication')));
const UnitTickets = lazy(() => retryLazy(() => import('../../RentalUnits/Details/TicketsPanel/UnitTickets')));
const UnitLeases = lazy(() => retryLazy(() => import('../../RentalUnits/Details/UnitLeases/UnitLeases')));
const LeaseUnitCommunicationsDetail = lazy(() => retryLazy(() => import('./LeaseUnitCommunicationsDetail')));
const Technics = lazy(() => retryLazy(() => import('../../Technics/Technics')));
const UtilityProviders = lazy(() => retryLazy(() => import('../../UtilityProviders/UtilityProviders')));
const TenantUnitGeneralDetails = lazy(() =>
  retryLazy(() => import('src/components/TenantView/RentalUnits/TenantGeneralDetails'))
);
const TenantUtilityProviders = lazy(() =>
  retryLazy(() => import('src/components/TenantView/Leases/Details/TenantUtilityProviders'))
);
const TenantTechnics = lazy(() => retryLazy(() => import('src/components/TenantView/Leases/TenantTechnics')));
const TenantUnitTickets = lazy(() => retryLazy(() => import('src/components/TenantView/Leases/TenantUnitTickets')));
const LeaseHistory = lazy(() => retryLazy(() => import('./History/LeaseHistory')));
const LeaseEndDetail = lazy(() => retryLazy(() => import('./End/LeaseEndDetail')));

const Documents = lazy(() => retryLazy(() => import('../../Folders/FoldersLander')));

export type LeaseUnitsDetailNavItem = {
  to: string;
  labelId: string;
  exact?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FC<any>;
  editable?: boolean;
  permission: boolean;
  value?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterComponent?: React.FC<any>;
  badgeCount?: number;
};

/**
 * TS doc in below types is here to help knowing if it is even necessary to check if a tab should appear or not.
 * Since some tabs should only appear in owner or tenant view, it's not necessary to check if they should appear
 * or not in all views.
 */

export type LeaseNavItemsElementsToShow = {
  /**
   * For owner and manager
   */
  shouldShowEndLease: boolean;
  shouldShowLeaseHistory: boolean;
  /**
   * For everyone
   */
  shouldShowFinancial: boolean;
  shouldShowOperations: boolean;
  shouldShowIndexation: boolean;
  shouldShowCommunications: boolean;
  shouldShowDocuments: boolean;
  /**
   * For owners only
   */
  shouldShowInventory: boolean;
  shouldShowStatements: boolean;
  /**
   * For tenant only.
   */
  shouldShowTickets: boolean;
};

export type UnitNavItemsElementsToShow = {
  /**
   * For owners only
   */
  shouldShowAdvertisement: boolean;
  shouldShowInventory: boolean;
  /**
   * For everyone
   */
  shouldShowUtility: boolean;
  shouldShowTechnics: boolean;
  shouldShowTickets: boolean;
  shouldShowDocuments: boolean;
};

export const LEASE_TO_PREFIX = '';
const LEASE_TO_GENERAL = `${LEASE_TO_PREFIX}`;

export const getLeaseNavItems = (
  lease: LeaseExtended,
  permissions: PermissionsContext,
  features: string[],
  elementsToShow: Partial<LeaseNavItemsElementsToShow>,
  badgeCount?: number,
  isTenant = false
): LeaseUnitsDetailNavItem[] => {
  const {
    financialTransactionsRead,
    inventoryOfFixturesCreationRead,
    furnituresInventoryCreationRead,
    leasesDetailsRead,
    communicationsDetailsRead,
    ticketsDetailsRead,
    settingsAutomationWrite,
  } = permissions;

  const {
    shouldShowEndLease = false,
    shouldShowLeaseHistory = false,
    shouldShowFinancial = false,
    shouldShowInventory = false,
    shouldShowOperations = false,
    shouldShowStatements = false,
    shouldShowIndexation = false,
    shouldShowTickets = false,
    shouldShowDocuments = false,
  } = elementsToShow;

  let leaseNavItems = [
    {
      to: LEASE_TO_GENERAL,
      labelId: 'lease.detail.menu.terms',
      exact: true,
      component: isTenant ? TenantGeneralDetails : GeneralDetails,
      editable: true,
      permission: true,
    },
    {
      to: `${LEASE_TO_PREFIX}financial`,
      labelId: 'lease.detail.menu.financial',
      exact: true,
      component: isTenant ? TenantLeaseTransaction : Financial,
      editable: true,
      permission: shouldShowFinancial && financialTransactionsRead,
    },
    {
      to: `${LEASE_TO_PREFIX}inventory`,
      labelId: 'lease.detail.menu.inventory',
      component: isTenant ? TenantInventory : Inventory,
      permission: shouldShowInventory && (inventoryOfFixturesCreationRead || furnituresInventoryCreationRead),
    },
    {
      to: `${LEASE_TO_PREFIX}operations`,
      labelId: 'lease.detail.menu.operations',
      exact: true,
      component: isTenant ? TenantLeaseVariousOperation : LeaseVariousOperations,
      permission: shouldShowOperations && financialTransactionsRead,
    },
    {
      to: `${LEASE_TO_PREFIX}statements`,
      labelId: 'accounting.statement.tenantStatements',
      exact: true,
      component: AccountingSetting,
      permission: shouldShowStatements && financialTransactionsRead,
    },
    {
      to: `${LEASE_TO_PREFIX}${removeFirstCharacter(RouteDestination.INDEXATION)}`,
      labelId: 'lease.detail.menu.indexation',
      exact: true,
      component: isTenant ? TenantLeasePriceHistory : LeaseIndexation,
      permission: shouldShowIndexation && leasesDetailsRead,
      ...(!isTenant ? { badgeCount } : {}),
    },
    {
      to: `${LEASE_TO_PREFIX}${removeFirstCharacter(RouteDestination.COMMUNICATIONS)}`,
      labelId: 'menu.communications',
      component: isTenant ? TenantLeaseCommunications : LeaseUnitCommunicationsDetail,
      permission: communicationsDetailsRead || settingsAutomationWrite,
    },
    {
      to: `${LEASE_TO_PREFIX}documents`,
      labelId: 'menu.documents',
      component: Documents,
      permission: shouldShowDocuments,
    },
    ...(isTenant
      ? [
          // Tenants only
          {
            to: `${UNIT_TO_PREFIX}${removeFirstCharacter(RouteDestination.TICKETS)}`,
            labelId: 'rentalUnit.detail.menu.tickets',
            component: TenantUnitTickets,
            filterComponent: TicketsFilters,
            permission: shouldShowTickets && ticketsDetailsRead,
          },
        ]
      : [
          // For owner and manager only
          {
            to: `${LEASE_TO_PREFIX}history`,
            labelId: 'lease.detail.menu.history',
            exact: true,
            component: LeaseHistory,
            editable: true,
            permission: shouldShowLeaseHistory,
          },
          {
            to: `${LEASE_TO_PREFIX}end`,
            labelId: 'lease.detail.menu.end',
            exact: true,
            component: LeaseEndDetail,
            editable: true,
            permission: shouldShowEndLease,
          },
        ]),
  ];

  // Feature filters
  if (!isFeatureEnabled(features, FEATURE_INVENTORY)) {
    leaseNavItems = leaseNavItems.filter((navItems) => !navItems.to.includes('inventory'));
  }

  // doesn't display statements if charge are fixed
  if (lease.units!.some((unitLease) => unitLease.monthlyChargesType !== LeaseMonthlyChargesType.MonthlyProvisioned)) {
    return leaseNavItems.filter((nv) => !nv.to.includes('statements'));
  }

  return leaseNavItems;
};

const UNIT_TO_PREFIX = '';
export const UNIT_TO_GENERAL = `${UNIT_TO_PREFIX}description`;
export const UNIT_TO_LEASES = `${UNIT_TO_PREFIX}leases`;

export const getUnitsNavItems = (
  permissions: PermissionsContext,
  features: string[],
  elementsToShow: Partial<UnitNavItemsElementsToShow>,
  isTenant = false
): LeaseUnitsDetailNavItem[] => {
  const {
    leasesDetailsRead,
    leasesCreationRead,
    advertisementsDetailsRead,
    buildingsUnitsUtilityProvidersRead,
    buildingsUnitsTechnicsRead,
    inventoryOfFixturesCreationRead,
    furnituresInventoryCreationRead,
    ticketsDetailsRead,
  } = permissions;

  const {
    shouldShowAdvertisement = false,
    shouldShowUtility = false,
    shouldShowTechnics = false,
    shouldShowInventory = false,
    shouldShowTickets = false,
    shouldShowDocuments = false,
  } = elementsToShow;

  let unitNavItems = [
    {
      to: UNIT_TO_GENERAL,
      labelId: 'rentalUnit.detail.menu.general',
      exact: true,
      component: isTenant ? TenantUnitGeneralDetails : UnitGeneralDetails,
      editable: true,
      permission: true,
    },
    {
      to: UNIT_TO_LEASES,
      labelId: 'contact.detail.menu.leaseTitle',
      exact: true,
      component: UnitLeases,
      value: 2,
      editable: true,
      permission: leasesDetailsRead || leasesCreationRead,
    },
    {
      to: `${UNIT_TO_PREFIX}advertisement`,
      labelId: 'rentalUnit.detail.menu.advertisement',
      component: Advertisement,
      permission: shouldShowAdvertisement && advertisementsDetailsRead,
    },
    {
      to: `${UNIT_TO_PREFIX}meters`,
      labelId: 'utilityProviders.name',
      component: isTenant ? TenantUtilityProviders : UtilityProviders,
      permission: shouldShowUtility && buildingsUnitsUtilityProvidersRead,
    },
    {
      to: `${UNIT_TO_PREFIX}technics`,
      labelId: 'technic.name',
      value: 2,
      exact: true,
      component: isTenant ? TenantTechnics : Technics,
      editable: true,
      permission: shouldShowTechnics && buildingsUnitsTechnicsRead,
    },
    {
      to: `${UNIT_TO_PREFIX}inventory`,
      labelId: 'menu.inventory',
      exact: true,
      component: Inventory,
      editable: true,
      permission: shouldShowInventory && (inventoryOfFixturesCreationRead || furnituresInventoryCreationRead),
    },
    {
      to: `${UNIT_TO_PREFIX}documents`,
      labelId: 'menu.documents',
      exact: true,
      component: Documents,
      editable: true,
      permission: shouldShowDocuments,
    },
    ...(!isTenant
      ? [
          {
            to: `${UNIT_TO_PREFIX}tickets`,
            labelId: 'rentalUnit.detail.menu.tickets',
            component: UnitTickets,
            filterComponent: TicketsFilters,
            permission: shouldShowTickets && ticketsDetailsRead,
          },
        ]
      : []),
  ];
  // Feature filters
  if (!isFeatureEnabled(features, FEATURE_INVENTORY)) {
    unitNavItems = unitNavItems.filter((navItems) => !navItems.to.includes('inventory'));
  }
  if (!isFeatureEnabled(features, FEATURE_ADVERTISEMENT)) {
    unitNavItems = unitNavItems.filter((navItems) => !navItems.to.includes('advertisement'));
  }

  if (isTenant) {
    return unitNavItems.filter((navItem) => navItem.to !== UNIT_TO_LEASES);
  }

  return unitNavItems;
};

export const getDetailUrlForUnit = (
  unitPrefixUrl: string,
  match: match<{
    id: string;
    detailName: string;
    nestedDetailName?: string | undefined;
  }>,
  isTenant = false
) => {
  return generatePath(`${RouteDestination.LEASES}/${isTenant ? '' : 'detail/'}:id/:detailName`, {
    id: match.params.id,
    detailName: unitPrefixUrl,
  });
};

const UNIT_PREFIX = 'unit-';

export const getUnitPrefixUrl = (unit: Unit) => {
  return `${UNIT_PREFIX}${unit.id}`;
};

export const getUnitIdFromMatchParams = (
  match: match<{
    id: string;
    detailName: string;
    nestedDetailName?: string | undefined;
  }>
) => {
  if (isNil(match.params.detailName)) {
    return null;
  }
  return match.params.detailName.substring(UNIT_PREFIX.length);
};

export const getInventoryIdFromMatchParams = (
  match: match<{
    id: string;
    detailName: string;
    nestedDetailName?: string | undefined;
  }>
) => {
  if (isNil(match.params.nestedDetailName)) {
    return null;
  }
  const fixture = match.params.nestedDetailName.startsWith('inventory-');
  const prefix = fixture ? 'inventory-' : 'furniture-';
  return match.params.nestedDetailName.substring(prefix.length);
};
