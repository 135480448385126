/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-param-last */
import React, { useReducer, useEffect, useContext, useRef, Reducer, useMemo } from 'react';
import { useUser } from './UserContext';
import { deleteAndHideEntity, get, list, mutation, NUMBER_OF_MINUTES_FOR_REFETCH } from '@up2rent/fetch-utils';
import {
  cleanInputCreate,
  cleanInputUpdate,
  getReadId,
  getTableClientId,
  AgencyRate,
  AgencyRateOwner,
  CreateAgencyRateInput,
  CreateAgencyRateMutationVariables,
  UpdateAgencyRateInput,
  AgencyRateOwner as AgencyRateOwnerAPI,
  DeleteAgencyRateOwnerMutationVariables,
  CreateAgencyRateOwnerInput,
  CreateAgencyRateOwnerMutationVariables,
  createAgencyRate as createMutation,
  updateAgencyRate as updateMutation,
  deleteAgencyRate as deleteMutation,
  createAgencyRateOwner as createOwnerMutation,
  updateAgencyRateOwner as updateOwnerMutation,
  deleteAgencyRateOwner as deleteOwnerMutation,
  syncAgencyRateOwners,
  syncAgencyRates,
  getAgencyRate as getAgencyRateQuery,
} from '@rentguru/commons-utils';
import { isNil } from 'lodash';
import { usePermissions } from './utils/PermissionsContext';
import { differenceInMinutes } from 'date-fns';

export const getAgencyRate = async (id: string) => {
  const result = await get<AgencyRate>(getAgencyRateQuery, id);
  return result;
};

export interface AgencyRatesContext extends AgencyRatesState {
  setFetchAgencyRates: () => void;
  getAgencyRate: (id: string) => AgencyRate | null | undefined;
  createAgencyRate: (input: Omit<CreateAgencyRateInput, 'clientId' | 'readId'>) => Promise<AgencyRate>;
  updateAgencyRate: (updates: UpdateAgencyRateInput) => Promise<AgencyRate>;
  deleteAgencyRate: (agencyRate: AgencyRate) => Promise<AgencyRate | null>;
  getAgencyRateOwner: (id: string) => AgencyRateOwner | null | undefined;
  getAgencyRateOwnersOfOwner: (ownerId: string) => AgencyRateOwner[] | null | undefined;
  createAgencyRateOwner: (input: Omit<CreateAgencyRateOwnerInput, 'clientId' | 'readId'>) => Promise<AgencyRateOwner>;
  deleteAgencyRateOwner: (agencyRateOwner: AgencyRateOwner) => Promise<AgencyRateOwner | null>;
}

interface AgencyRatesState {
  loading: boolean;
  error: string | null;
  shouldFetchAgencyRates: boolean;
  agencyRates: AgencyRate[] | null;
  agencyRateOwners: AgencyRateOwner[] | null;
  lastFetchForAgencyRates: Date | null;
}

type Action =
  | {
      type: 'SHOULD_FETCH' | 'IS_FETCHING';
    }
  | {
      type: 'FETCHED';

      payload: { agencyRates: AgencyRate[]; agencyRateOwners: AgencyRateOwner[] };
    }
  | {
      type: 'ADD_AGENCYRATE' | 'UPDATE_AGENCYRATE';

      payload: { agencyRate: AgencyRate };
    }
  | {
      type: 'ADD_AGENCYRATE_OWNER';

      payload: { agencyRateOwner: AgencyRateOwner };
    }
  | {
      type: 'DELETE_AGENCYRATE';

      payload: { id: string };
    }
  | {
      type: 'DELETE_AGENCYRATE_OWNER';

      payload: { id: string };
    };

const initialState: AgencyRatesState = {
  loading: false,
  error: null,
  shouldFetchAgencyRates: false,
  agencyRates: null,
  agencyRateOwners: null,
  lastFetchForAgencyRates: null,
};

const agencyRatesReducer = (state: AgencyRatesState, action: Action): AgencyRatesState => {
  switch (action.type) {
    case 'SHOULD_FETCH':
      // Check //lastFetchForAgencyRates
      if (
        state.loading ||
        state.shouldFetchAgencyRates ||
        (state.lastFetchForAgencyRates &&
          differenceInMinutes(new Date(), state.lastFetchForAgencyRates) < NUMBER_OF_MINUTES_FOR_REFETCH)
      ) {
        return state;
      }
      return {
        ...state,
        shouldFetchAgencyRates: true,
      };
    case 'IS_FETCHING':
      return {
        ...state,
        loading: true,
      };
    case 'FETCHED':
      // Build List with merged object
      const agencyRateOwners = action.payload.agencyRateOwners;
      const agencyRates = action.payload.agencyRates.reduce((result, currentAgencyRate) => {
        const owners = agencyRateOwners.filter(
          (aro) =>
            (aro as AgencyRateOwnerAPI).agencyRateId &&
            (aro as AgencyRateOwnerAPI).agencyRateId === currentAgencyRate.id
        );
        result.push({ ...currentAgencyRate, owners: owners as AgencyRateOwner[] });
        return result;
      }, [] as AgencyRate[]);
      return {
        ...state,
        loading: false,
        shouldFetchAgencyRates: false,
        agencyRates,
        agencyRateOwners,
        lastFetchForAgencyRates: new Date(),
      };
    case 'ADD_AGENCYRATE':
      if (!state.agencyRates) {
        return state;
      }
      return {
        ...state,
        agencyRates: [...state.agencyRates, { ...action.payload.agencyRate, owners: [] }],
      };
    case 'ADD_AGENCYRATE_OWNER':
      if (!state.agencyRateOwners || !state.agencyRates) {
        return state;
      }
      const agencyRateOwner = action.payload.agencyRateOwner;

      return {
        ...state,
        // Update agencyRate with this owner
        agencyRates: state.agencyRates.map((agencyRate) => {
          if (agencyRate.id === (agencyRateOwner as AgencyRateOwnerAPI).agencyRateId) {
            return {
              ...agencyRate,
              owners: agencyRate.owners ? [...agencyRate.owners, agencyRateOwner] : [agencyRateOwner],
            };
          }
          return agencyRate;
        }),
        // Add him to agency rate owners list
        agencyRateOwners: [...state.agencyRateOwners, agencyRateOwner],
      };
    case 'UPDATE_AGENCYRATE':
      if (!state.agencyRates) {
        return state;
      }
      return {
        ...state,
        agencyRates: state.agencyRates.map((ar) => {
          if (ar.id === action.payload.agencyRate.id) {
            return { ...action.payload.agencyRate, owners: ar.owners };
          }
          return ar;
        }),
        agencyRateOwners: state.agencyRateOwners ? [...state.agencyRateOwners] : null,
      };
    case 'DELETE_AGENCYRATE':
      if (!state.agencyRates) {
        return state;
      }
      return {
        ...state,
        agencyRates: state.agencyRates.filter((ar) => ar.id !== action.payload.id),
        agencyRateOwners: state.agencyRateOwners ? [...state.agencyRateOwners] : null,
      };
    case 'DELETE_AGENCYRATE_OWNER':
      if (!state.agencyRateOwners || !state.agencyRates) {
        return state;
      }
      const actionRateOwnerId = action.payload.id;
      return {
        ...state,
        // Update agencyRate with this owner
        agencyRates: state.agencyRates.map((agencyRate) => {
          if (agencyRate.owners && agencyRate.owners.some((aro) => aro.id === actionRateOwnerId)) {
            return {
              ...agencyRate,
              owners: agencyRate.owners.filter((aro) => aro.id !== actionRateOwnerId),
            };
          }
          return agencyRate;
        }),
        // Add him to agency rate owners list
        agencyRateOwners: state.agencyRateOwners.filter((aro) => aro.id !== actionRateOwnerId),
      };
    default:
      return state;
  }
};

export const AgencyRatesContext = React.createContext<AgencyRatesContext | null>(null);

export const AgencyRatesContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { clientId } = useUser();
  const { agencyRateDelete } = usePermissions();
  const [state, dispatch] = useReducer<Reducer<AgencyRatesState, Action>>(agencyRatesReducer, initialState);

  const fetchAgencyRates = async (): Promise<AgencyRate[]> => {
    return await list<AgencyRate>(syncAgencyRates, 'clientId', getTableClientId(clientId!, 'AgencyRate'));
  };
  const fetchAgencyRateOwners = async (): Promise<AgencyRateOwner[]> => {
    return await list<AgencyRateOwner>(
      syncAgencyRateOwners,
      'clientId',
      getTableClientId(clientId!, 'AgencyRateOwner')
    );
  };

  const setFetchAgencyRates = () => {
    dispatch({ type: 'SHOULD_FETCH' });
  };

  useEffect(() => {
    const fetchAndSet = async () => {
      dispatch({ type: 'IS_FETCHING' });
      const result = await fetchAgencyRates();
      const resultOwners = await fetchAgencyRateOwners();
      dispatch({ type: 'FETCHED', payload: { agencyRates: result, agencyRateOwners: resultOwners } });
    };
    if (state.shouldFetchAgencyRates) fetchAndSet();
    // eslint-disable-next-line
  }, [state.shouldFetchAgencyRates]);

  const getAgencyRate = (id: string) => {
    if (state.loading || state.error || !state.agencyRates) {
      return null;
    }
    return state.agencyRates.find((ar) => ar.id === id);
  };

  const createAgencyRate = async (input: Omit<CreateAgencyRateInput, 'clientId' | 'readId'>): Promise<AgencyRate> => {
    const result = await mutation<AgencyRate, CreateAgencyRateMutationVariables>(createMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateAgencyRateInput),
        readId: getReadId(clientId!, 'AgencyRate'),
        clientId: getTableClientId(clientId!, 'AgencyRate'),
      },
    });
    dispatch({ type: 'ADD_AGENCYRATE', payload: { agencyRate: result } });
    dispatch({ type: 'SHOULD_FETCH' });
    return result;
  };

  const updateAgencyRate = async (updates: UpdateAgencyRateInput): Promise<AgencyRate> => {
    const result = await mutation<AgencyRate>(updateMutation, {
      input: cleanInputUpdate(updates, false, true),
    });
    dispatch({ type: 'UPDATE_AGENCYRATE', payload: { agencyRate: result } });
    dispatch({ type: 'SHOULD_FETCH' });
    return result;
  };

  const deleteAgencyRate = async (agencyRate: AgencyRate): Promise<AgencyRate | null> => {
    if (!agencyRateDelete) {
      return null;
    }
    const result = await deleteAndHideEntity<AgencyRate>(agencyRate, deleteMutation, updateMutation);
    dispatch({ type: 'DELETE_AGENCYRATE', payload: { id: agencyRate.id } });
    return result;
  };

  const getAgencyRateOwnersOfOwner = (ownerId: string) => {
    if (state.loading || state.error || !state.agencyRateOwners) {
      return null;
    }
    return state.agencyRateOwners.filter((aro) => aro.owner?.id === ownerId);
  };

  const getAgencyRateOwner = (id: string) => {
    if (state.loading || state.error || !state.agencyRateOwners) {
      return null;
    }
    return state.agencyRateOwners.find((ar) => ar.id === id);
  };

  const createAgencyRateOwner = async (
    input: Omit<CreateAgencyRateOwnerInput, 'clientId' | 'readId'>
  ): Promise<AgencyRateOwner> => {
    const result = await mutation<AgencyRateOwner, CreateAgencyRateOwnerMutationVariables>(createOwnerMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateAgencyRateOwnerInput),
        readId: getReadId(clientId!, 'AgencyRateOwner'),
        clientId: getTableClientId(clientId!, 'AgencyRateOwner'),
      },
    });
    dispatch({ type: 'ADD_AGENCYRATE_OWNER', payload: { agencyRateOwner: result } });
    dispatch({ type: 'SHOULD_FETCH' });
    return result;
  };

  const deleteAgencyRateOwner = async (agencyRateOwner: AgencyRateOwner): Promise<AgencyRateOwner | null> => {
    if (!agencyRateDelete) {
      return null;
    }
    const result = await deleteAndHideEntity<AgencyRateOwner, DeleteAgencyRateOwnerMutationVariables>(
      agencyRateOwner,
      deleteOwnerMutation,
      updateOwnerMutation
    );
    dispatch({ type: 'DELETE_AGENCYRATE_OWNER', payload: { id: agencyRateOwner.id } });
    return result;
  };

  const values = useMemo(
    () => ({
      ...state,
      setFetchAgencyRates,
      getAgencyRate,
      createAgencyRate,
      updateAgencyRate,
      deleteAgencyRate,
      getAgencyRateOwnersOfOwner,
      getAgencyRateOwner,
      createAgencyRateOwner,
      deleteAgencyRateOwner,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <AgencyRatesContext.Provider value={values}>{children}</AgencyRatesContext.Provider>;
};

export const useAgencyRates = (): AgencyRatesContext => {
  const firstRender = useRef<boolean>(false);
  const context = useContext<AgencyRatesContext | null>(AgencyRatesContext);

  if (isNil(context)) {
    throw new Error('`useAgencyRates` hook must be used within a `AgencyRatesContext` component');
  }

  useEffect(() => {
    if (!firstRender.current) context.setFetchAgencyRates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  return context;
};
