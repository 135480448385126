/* eslint-disable no-console */
import React, { useState } from 'react';
import { isNil } from 'lodash';
import { DashboardSwitchFilters, useDashboardFilters } from 'src/hooks/DashboardFiltersContext';
import FilterButton from '../DashboardMetrics/DashboardFilters/FilterButton';
import BoxSwitchOverflowMenu from './BoxSwitchOverflowMenu';

export interface RankingBoxSwitchFilter {
  label: string;
  value?: string;
  menuItems?: {
    label: string;
    value: string;
  }[];
}

interface FilterOverflowMenu {
  anchor: HTMLElement | null;
  filter: RankingBoxSwitchFilter | null;
}

export type SwitchFilterName = keyof Omit<DashboardSwitchFilters, 'filters'>;

export interface BoxSwitchFiltersProps {
  name: SwitchFilterName;
  filters: RankingBoxSwitchFilter[];
}

const BoxSwitchFilters: React.FC<BoxSwitchFiltersProps> = ({ name, filters }) => {
  const [customFilterMenu, setCustomFilterMenu] = useState<FilterOverflowMenu>({ anchor: null, filter: null });

  const closeCustomMenu = () => {
    setCustomFilterMenu({ filter: null, anchor: null });
  };

  const { switchFilters, updateSwitchFilters } = useDashboardFilters();
  const handleSwitchFilterChange = (filter: RankingBoxSwitchFilter) => {
    updateSwitchFilters({
      ...switchFilters,
      [name]: { name: filter.label, value: filter.value! },
    });
  };

  const currentFilter = switchFilters[name];
  const currentSelectedValue = !isNil(currentFilter)
    ? currentFilter.value
    : filters[0].value
    ? filters[0].value
    : filters[0].menuItems
    ? filters[0].menuItems[0].value
    : null;

  if (isNil(currentSelectedValue)) {
    console.log(`Filter wrongly configured for ${name}`);
  }

  return (
    <>
      {filters.map((filter) => {
        const isOverflowFilter = filter.hasOwnProperty('menuItems');

        const isFilteredSelected =
          !isNil(currentSelectedValue) &&
          ((isOverflowFilter && !isNil(filter.menuItems!.find((item) => item.value === currentSelectedValue))) ||
            (!isOverflowFilter && currentSelectedValue === filter.value!));
        return (
          <FilterButton
            key={`${name}${filter.label}`}
            label={filter.label}
            onClick={(event) => {
              if (!isFilteredSelected && !isOverflowFilter) {
                handleSwitchFilterChange(filter);
              } else if (isOverflowFilter) {
                setCustomFilterMenu({ anchor: event!.currentTarget, filter });
              }
            }}
            selected={isFilteredSelected}
          />
        );
      })}
      <BoxSwitchOverflowMenu {...customFilterMenu} name={name} onClose={closeCustomMenu} />
    </>
  );
};

export default BoxSwitchFilters;
