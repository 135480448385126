/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ReactComponent as CheckBoxCheckedIcon } from '../../icons/checkBoxChecked.svg';
import { ReactComponent as CheckBoxCheckedBlueIcon } from '../../icons/checkBoxCheckedBlue.svg';
import { ReactComponent as CheckBoxUncheckedIcon } from '../../icons/checkBoxUnchecked.svg';
import { Checkbox, CheckboxProps } from '@material-ui/core';
import { isEmpty } from 'lodash';
import isNil from 'lodash/isNil';
import { Colors } from '@rentguru/commons-utils';

interface CustomCheckBoxProps {
  isChecked?: boolean;
  onCheck?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  secondary?: boolean;
  disabled?: boolean;
  color?: string;
  checkedColor?: string;
  name?: string;
  customIcon?: any;
  customCheckedIcon?: any;
  size?: 'medium' | 'small';
  id?: string;
  override?: CheckboxProps;
  dataTest?: string;
}
const CustomCheckBox: React.FC<CustomCheckBoxProps> = ({
  isChecked = false,
  onCheck,
  secondary = false,
  disabled = false,
  color = '',
  checkedColor = '',
  name = '',
  customIcon,
  customCheckedIcon,
  size = 'medium',
  id,
  dataTest,
  override = {},
}) => {
  return (
    <Checkbox
      id={id}
      size={size}
      onChange={onCheck}
      checked={isChecked}
      data-test={dataTest}
      icon={
        !isNil(customIcon) && !isEmpty(customIcon) ? (
          customIcon
        ) : (
          <CheckBoxUncheckedIcon
            style={{ fill: disabled ? 'rgba(0, 0, 0, 0.26)' : !isEmpty(color) ? color : Colors.BLUE_GREY }}
          />
        )
      }
      checkedIcon={
        !isNil(customCheckedIcon) && !isEmpty(customCheckedIcon) ? (
          customCheckedIcon
        ) : secondary ? (
          <CheckBoxCheckedBlueIcon />
        ) : (
          <CheckBoxCheckedIcon style={{ fill: !isEmpty(checkedColor) ? checkedColor : Colors.TURQUOISE_BLUE }} />
        )
      }
      disabled={disabled}
      name={name}
      {...override}
    />
  );
};

export default CustomCheckBox;
