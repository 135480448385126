import Dialog from 'src/components/ui/Dialog';
import { Skeleton } from '@material-ui/lab';
import { useIntl } from 'react-intl';
import { ConfirmDialog } from '@up2rent/ui';
import { useCommunications } from 'src/hooks/CommunicationsContext';
import { useSignatureDocuments } from 'src/hooks/SignatureDocumentContext';
import { SignatureDocument } from '@rentguru/commons-utils';

const ConfirmDeleteLetterDialog = ({
  open,
  signatureDocument,
  afterSubmit,
}: {
  open: boolean;
  signatureDocument: SignatureDocument;
  afterSubmit: (validate: boolean) => void;
}) => {
  const { formatMessage } = useIntl();
  const { loading, deleteSignatureDocument } = useSignatureDocuments();
  const { communicationsLoading, deleteCommunication, getCommunicationsForSignatureDocument } = useCommunications();

  if (loading || communicationsLoading) {
    return (
      <Dialog open={true}>
        <Skeleton />
      </Dialog>
    );
  }

  return (
    <ConfirmDialog
      open={open}
      confirmText={formatMessage({ id: 'yes' })}
      confirmAction={async () => {
        // Delete communication
        const communications = getCommunicationsForSignatureDocument(signatureDocument.id);
        const communicationsPromises =
          communications?.map((communication) => deleteCommunication(communication.id)) ?? [];
        await Promise.all(communicationsPromises);

        await deleteSignatureDocument(signatureDocument);

        afterSubmit(true);
      }}
      cancelText={formatMessage({ id: 'no' })}
      cancelAction={() => {
        afterSubmit(false);
      }}
      mainText={formatMessage({ id: 'lease.endExtendLease.confirmCancelRegisteredLetter' })}
      formatMessage={formatMessage}
    />
  );
};

export default ConfirmDeleteLetterDialog;
