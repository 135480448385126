import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSpring, animated } from 'react-spring'; // web.cjs is required for IE 11 support
import { isNil, isEmpty } from 'lodash';
import { Collapse, Typography, Divider, Menu, MenuItem, makeStyles, Theme, createStyles } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import { Team as TeamModel, Colors } from '@rentguru/commons-utils';
import { useTeams } from 'src/hooks/TeamsContext';
import { ReactComponent as FolderMinus } from 'src/icons/folder-minus.svg';
import { ReactComponent as FolderPlus } from 'src/icons/folder-plus.svg';
import { ReactComponent as Team } from 'src/icons/team.svg';
import { ReactComponent as Member } from 'src/icons/member.svg';
import { RouteDestination } from 'src/components/Routes/Routes';
import Dialog from 'src/components/ui/Dialog';
import MoreHorizontalIconButton from 'src/components/ui/CustomIconButtons/MoreHorizontalIconButton';
import { ContentHeader, ElevatedPaper, ActionButton , MenuItemText } from '@up2rent/ui';
import EntityDetail from 'src/components/ui/EntityDetail/EntityDetail';
import AddTeamForm from './Teams/AddTeamForm';
import EditTeam from './Teams/EditTeam';
import DeleteTeam from './Teams/DeleteTeam';
import GeneralDetails from './Teams/Details/GeneralDetails';

const TransitionComponent = (props: TransitionProps) => {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
  });

  return (
    <animated.div style={style}>
      <Collapse style={{ overflow: 'hidden' }} {...props} />
    </animated.div>
  );
};

type StyledTreeItemProps = TreeItemProps & {
  numberOfMembers?: number;
  parentId?: string | null;
  id: string;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      '&:hover > $content': {
        backgroundColor: 'transparent',
      },
      '&:focus > $content, &$selected > $content': {
        backgroundColor: `transparent`,
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent !important',
      },
    },
    content: {
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
      display: 'flex',
      alignItems: 'center',
      justifyItems: 'center',
    },
    group: {
      marginLeft: 20,
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      justifyItems: 'center',
      paddingBottom: 10,
      paddingTop: 10,
    },
    labelText: {
      paddingLeft: 20,
      fontSize: '14px',
      fontWeight: 400,
      color: Colors.CLASSICAL_BLACK,
      '&$selected': {
        color: Colors.DODGER_BLUE,
      },
    },
  })
);

const StyledTreeItem: React.FC<StyledTreeItemProps> = ({
  numberOfMembers,
  label,
  children,
  parentId,
  id,
  ...other
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [anchorE1, setAnchorE1] = useState<null | HTMLElement>(null);
  const classes = useTreeItemStyles();
  const { formatMessage } = useIntl();
  const history = useHistory();

  const handleCloseMenu = () => {
    setAnchorE1(null);
  };

  const handleActionMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorE1(event.currentTarget);
  };

  const handleCloseDialogs = () => {
    setDeleteDialogOpen(false);
    setEditDialogOpen(false);
  };

  return (
    <>
      <TreeItem
        label={
          <>
            <div
              className={classes.labelRoot}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                history.push(`${RouteDestination.SETTINGS}/teams/${id}/buildings`);
              }}
            >
              <div style={{ display: 'flex' }}>
                <Typography variant="body2" className={classes.labelText}>
                  {label}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {!isNil(numberOfMembers) && numberOfMembers !== 0 && (
                  <>
                    <Member style={{ fill: Colors.TOWER_GREY }} />
                    <Typography style={{ paddingLeft: 9 }}>{numberOfMembers}</Typography>
                  </>
                )}

                <MoreHorizontalIconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleActionMenu(e);
                  }}
                />
                <Menu
                  id="action-menu"
                  anchorEl={anchorE1}
                  open={Boolean(anchorE1)}
                  onClose={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    e.stopPropagation();
                    handleCloseMenu();
                  }}
                >
                  <MenuItem
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      setEditDialogOpen(true);
                    }}
                  >
                    <MenuItemText primary={formatMessage({ id: 'edit' })} />
                  </MenuItem>
                  {!isNil(parentId) && (isNil(children) || isEmpty(children)) && (
                    <MenuItem
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setDeleteDialogOpen(true);
                      }}
                    >
                      <MenuItemText primary={formatMessage({ id: 'delete' })} deleteOption />
                    </MenuItem>
                  )}
                </Menu>
              </div>
            </div>
            <Divider />
          </>
        }
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
        }}
        TransitionComponent={TransitionComponent}
        {...other}
      >
        {children}
      </TreeItem>
      <Dialog
        open={deleteDialogOpen}
        onClose={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();
          handleCloseDialogs();
        }}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DeleteTeam afterSubmit={handleCloseDialogs} id={id} />
      </Dialog>
      <Dialog
        open={editDialogOpen}
        onClose={handleCloseDialogs}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <EditTeam onClose={handleCloseDialogs} id={id} />
      </Dialog>
    </>
  );
};

const useStyles = makeStyles(
  createStyles({
    root: {
      height: 'auto',
      flexGrow: 1,
    },
  })
);

const renderTree = (team: TeamModel) => (
  <StyledTreeItem
    key={team.id}
    nodeId={team.id}
    id={team.id}
    parentId={team.parentId}
    label={team.name}
    numberOfMembers={team.users ? team.users.length : 0}
  >
    {Array.isArray(team.children) ? team.children.map((subTeam) => renderTree(subTeam)) : null}
  </StyledTreeItem>
);

const TeamsSettings: React.FC = () => {
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
  const history = useHistory();
  const matchLocal = useRouteMatch<{ teamId: string }>({
    path: `${RouteDestination.SETTINGS}/teams/:teamId?`,
  });
  const { formatMessage } = useIntl();
  const { loading, error, teams, getTreeTeams } = useTeams();
  const classes = useStyles();
  const openDetail = !isNil(matchLocal) && !isNil(matchLocal.params) && !isNil(matchLocal.params.teamId);
  if (loading || isEmpty(teams)) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{formatMessage({ id: 'loading' })}</Typography>
      </ElevatedPaper>
    );
  }
  if (error) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{error}</Typography>
      </ElevatedPaper>
    );
  }
  const defaultExpanded = teams.reduce((acc: string[], t: TeamModel) => {
    if (isNil(t.parentId)) acc.push(t.id);
    return acc;
  }, []);

  const onCloseDialog = () => {
    setAddDialogOpen(false);
  };

  return (
    <>
      <ElevatedPaper style={{ margin: 20, maxWidth: 640, paddingBottom: 0 }}>
        <ContentHeader title={formatMessage({ id: 'settings.team' })}>
          <ActionButton onClick={() => setAddDialogOpen(true)}>{formatMessage({ id: 'add' })}</ActionButton>
        </ContentHeader>
        <div style={{ paddingLeft: 30, paddingRight: 30 }}>
          <TreeView
            className={classes.root}
            defaultCollapseIcon={<FolderMinus style={{ width: 20, height: 20, objectFit: 'contain' }} />}
            defaultExpandIcon={<FolderPlus style={{ width: 20, height: 20, objectFit: 'contain' }} />}
            defaultEndIcon={<Team style={{ width: 20, height: 20, objectFit: 'contain' }} />}
            defaultExpanded={defaultExpanded}
          >
            {getTreeTeams().map((tt) => renderTree(tt))}
          </TreeView>
        </div>
      </ElevatedPaper>
      <Dialog open={addDialogOpen} onClose={() => onCloseDialog()} scroll="body">
        <AddTeamForm onClose={onCloseDialog} />
      </Dialog>
      <EntityDetail open={openDetail} onClose={() => history.push(`${RouteDestination.SETTINGS}/teams`)}>
        <GeneralDetails teamId={!isNil(matchLocal) ? matchLocal!.params.teamId : ''} />
      </EntityDetail>
    </>
  );
};

export default TeamsSettings;
