import { isEqual, isNil } from 'lodash';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDashboardFilters } from '../../../hooks/DashboardFiltersContext';
import { BoxSwitchFiltersProps } from '../Filters/BoxSwitchFilters';
import RankingBox, { RankingBoxLayoutProps } from './RankingBox';
import {
  getTopElementsBasedOnProperty,
  formatNumber,
  getContactNameFromObject,
  TOP_TENANTS_INCOME_BOX_KEY,
  LeaseStatus,
  UnitType,
} from '@rentguru/commons-utils';
import { useLocale } from '../../../i18n/IntlProviderWrapper';
import { startOfMonth } from 'date-fns';
import { useLeasePriceHistories } from 'src/hooks/LeasePriceHistoriesContext';
import { useLeases } from 'src/hooks/LeasesContext';
import { getLeaseIndexedRentalPriceForMonth } from '../DashboardMetrics/utils/MetricUtils';
import { Typography } from '@material-ui/core';

const DashboardRankingTopTenantsIncome: React.FC<RankingBoxLayoutProps> = ({ forceUpdateLayout }) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const {
    switchFilters: { topTenantsIncome: topTenantsFilter },
  } = useDashboardFilters();
  const { leases, leasesLoading } = useLeases();
  const { leasePriceHistories, loading: leasesIndexationsLoading } = useLeasePriceHistories();

  const loading = leasesLoading || leasesIndexationsLoading;

  const switchs: BoxSwitchFiltersProps = {
    name: 'topTenantsIncome',
    filters: [
      {
        label: formatMessage({ id: 'dashboard.rankingFilters.all' }),
        value: 'all',
      },
      {
        label: formatMessage({ id: 'dashboard.rankingFilters.unitType' }),
        menuItems: Object.keys(UnitType).map((unitType) => ({
          label: formatMessage({ id: `enums.UnitType.${unitType}` }),
          value: unitType,
        })),
      },
    ],
  };

  const ongoingLeases = useMemo(() => leases.filter((lease) => lease.status === LeaseStatus.Active), [leases]);

  const datas = useMemo(() => {
    const startOfMonthDate = startOfMonth(new Date());
    let totalIncome = 0;

    const tenantsWithRentalPrice = ongoingLeases.reduce((result, currentLease) => {
      // Check if unit is on selected unitType
      const unitLease = currentLease.units ? currentLease.units.find((u) => u.unit) : null;
      let addToResult = true;
      if (!isNil(unitLease) && !isNil(topTenantsFilter) && topTenantsFilter.value !== 'all') {
        if (topTenantsFilter.value !== unitLease.unit?.type) {
          addToResult = false;
        }
      }
      if (!addToResult) {
        return result;
      }
      const rentalPrice = getLeaseIndexedRentalPriceForMonth(currentLease, leasePriceHistories ?? [], startOfMonthDate);
      totalIncome += rentalPrice;
      // For each contactId, check if we already have this contactId
      currentLease.tenants.forEach((tenant) => {
        const tenantInResult = result.find((res) => res.contactId === tenant.id);
        if (!isNil(tenantInResult)) {
          // If yes, update rentalPrice and leasesIds
          tenantInResult.leaseIds = [...tenantInResult.leaseIds, currentLease.id];
          tenantInResult.rentalPrice += rentalPrice;
        } else {
          // If no, add it
          result.push({
            contactId: tenant.id,
            leaseIds: [currentLease.id],
            tenantName: getContactNameFromObject(tenant),
            rentalPrice,
          });
        }
      });

      return result;
    }, [] as { contactId: string; leaseIds: string[]; tenantName: string; rentalPrice: number }[]);

    // Get top20
    const top20Tenants = getTopElementsBasedOnProperty<{
      contactId: string;
      leaseIds: string[];
      tenantName: string;
      rentalPrice: number;
    }>(tenantsWithRentalPrice, 'rentalPrice', 20);
    // Iterate on top20 and reduce to merge needed contacts
    const mergedResult = top20Tenants.reduce((result, currentTenant) => {
      const sameLeasesOnAnotherTenant = result.find((res) => isEqual(res.leaseIds, currentTenant.leaseIds));
      if (!isNil(sameLeasesOnAnotherTenant)) {
        // If leasesIds are the same, add to the same record
        sameLeasesOnAnotherTenant.tenantName = `${sameLeasesOnAnotherTenant.tenantName}, ${currentTenant.tenantName}`;
        return result;
      }
      result.push(currentTenant);
      return result;
    }, [] as { contactId: string; leaseIds: string[]; tenantName: string; rentalPrice: number }[]);

    // Get top 5
    const top5Tenants = getTopElementsBasedOnProperty<{
      contactId: string;
      leaseIds: string[];
      tenantName: string;
      rentalPrice: number;
    }>(mergedResult, 'rentalPrice', 5);

    return top5Tenants.map((tenant) => ({
      name: tenant.tenantName,
      primaryText: formatNumber(tenant.rentalPrice, language, {
        style: 'currency',
        currency: 'EUR',
      }),
      primarySubText: formatNumber(tenant.rentalPrice / totalIncome, language, {
        style: 'percent',
        maximumSignificantDigits: 2,
      }),
      secondaryText: `${tenant.leaseIds.length} ${formatMessage(
        { id: 'contact.detail.menu.leaseTitle' },
        { value: tenant.leaseIds.length }
      )}`,
      lineDestination: `/contacts/${tenant.contactId}`,
    }));
  }, [ongoingLeases, topTenantsFilter, language, leasePriceHistories, formatMessage]);

  return (
    <RankingBox
      boxKey={TOP_TENANTS_INCOME_BOX_KEY}
      title={formatMessage({ id: 'dashboard.topTenantsIncome.title' })}
      infoContent={
        <>
          <Typography style={{ fontSize: 12 }}>
            {formatMessage({ id: 'dashboard.topTenantsIncome.info' }, { numberOfYears: 5 })}
          </Typography>
          <Typography display="inline">{` ${formatMessage({ id: 'dashboard.topTenantsIncome.info2' })}`}</Typography>
        </>
      }
      filtersBundle={switchs}
      datas={datas}
      forceUpdateLayout={forceUpdateLayout}
      loading={loading}
    />
  );
};

export default DashboardRankingTopTenantsIncome;
