import { get } from 'aws-amplify/api';
import { downloadData, uploadData } from 'aws-amplify/storage';
import { getTempFileKeyForConvertion, getSanitizedFile } from '@rentguru/commons-utils';
import awsExports from '../aws-exports-oauth';

export const handleNewHEICFile = async (file: File) => {
  const heicFile = getSanitizedFile(file);

  const fileKey = getTempFileKeyForConvertion(heicFile.name);
  await uploadData({
    key: fileKey,
    data: heicFile,
    options: {
      contentDisposition: `attachment; filename="${heicFile.name}"`,
    },
  }).result;

  try {
    const apiName = 'convertor';
    const path = '/heic/jpeg';

    const myInit = {
      headers: {},
      queryParams: {
        s3Bucket: awsExports.aws_user_files_s3_bucket,
        s3FileKey: `public/${fileKey}`,
      },
    };

    const getOperation = get({ apiName, path, options: myInit });
    const { body } = await getOperation.response;
    const response = (await body.json()) as { fileKey: string; fileUrl: string };

    const jpgFileKey = response.fileKey.replace('public/', '');
    const { body: storageBody } = await downloadData({ key: jpgFileKey }).result;
    const convertedBlob = await storageBody.blob();

    return convertedBlob;
  } catch (err) {
    console.error('error in convertor', err);
    return null;
  }
};
