import { Divider, Grid, Typography } from '@material-ui/core';
import { Colors, CommunicationSettingsProfile, LeaseStatus, Technic } from '@rentguru/commons-utils';
import { Form, Formik, FormikHelpers } from 'formik';
import { isEmpty } from 'lodash';
import isNil from 'lodash/isNil';
import toUpper from 'lodash/toUpper';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';
import { includeComplementaryDataSchema } from 'src/components/Technics/AddTechnicDialog';
import { getLinks } from 'src/components/Technics/Heatings/AddHeatingForm';
import useTechnicsUtils from 'src/components/Technics/useTechnicsUtils';
import { ActionButton , LoaderButton } from '@up2rent/ui';
import UtilityProvidersFields, {
  UtilityProvidersFormValues,
  UtilityProvidersSchema,
  emptyUtilityProvider,
} from 'src/components/ui/Forms/FormField/UtilityProvidersFields';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { useCommunicationSettingsProfiles } from 'src/hooks/CommunicationSettingsProfilesContext';
import { useContacts } from 'src/hooks/ContactsContext';
import { useLeases } from 'src/hooks/LeasesContext';
import { useTechnics } from 'src/hooks/TechnicsContext';
import { useUnits } from 'src/hooks/UnitsContext';

interface AddUtilityProps {
  id: string;
  afterSubmit: (id: string) => void;
  cancelSubmit: () => void;
  showComplementaryData?: boolean;
  shouldRedirectAfterCreate?: boolean;
}

const AddUtility: React.FC<AddUtilityProps> = ({
  id,
  afterSubmit,
  cancelSubmit,
  showComplementaryData = false,
  shouldRedirectAfterCreate = false,
}) => {
  const { formatMessage } = useIntl();
  const { createTechnic } = useTechnics();
  const { getBuilding } = useBuildings();
  const { getContact } = useContacts();
  const { getUnit } = useUnits();
  const { getLease } = useLeases();
  const history = useHistory();
  const { getPropertyId } = useTechnicsUtils();
  const { getDefaultTechnicCommunicationSettingsProfiles, loading: communicationSettingsProfilesLoading } =
    useCommunicationSettingsProfiles();
  const [technicCommunicationSettingsProfiles, setTechnicCommunicationSettingsProfiles] = useState<
    CommunicationSettingsProfile[]
  >([]);

  useEffect(() => {
    if (communicationSettingsProfilesLoading) {
      return;
    }
    const technicProfiles = getDefaultTechnicCommunicationSettingsProfiles();
    setTechnicCommunicationSettingsProfiles(technicProfiles);
  }, [getDefaultTechnicCommunicationSettingsProfiles, communicationSettingsProfilesLoading]);

  if (communicationSettingsProfilesLoading || isEmpty(technicCommunicationSettingsProfiles)) {
    return (
      <Grid container style={{ justifyContent: 'center' }}>
        <Typography style={{ margin: 20 }}>{formatMessage({ id: 'loading' })}</Typography>
      </Grid>
    );
  }

  // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
  const defaultTechnicProfile =
    !isEmpty(technicCommunicationSettingsProfiles) && technicCommunicationSettingsProfiles[0];

  const unit = getUnit(id);

  const handleAfterSubmit = () => {
    if (!isNil(afterSubmit)) {
      afterSubmit(id);
    }
  };
  const handleCancel = () => {
    if (!isNil(cancelSubmit)) {
      cancelSubmit();
    }
  };

  const handleUtilityCreate = async (
    values: UtilityProvidersFormValues,
    { setSubmitting, setStatus }: FormikHelpers<UtilityProvidersFormValues>
  ) => {
    const unitFromForm = getUnit(values.unitId ?? '');
    const building = getBuilding(unitFromForm?.building?.id ?? '');
    const buildingId = building?.id;
    const leasesOfUnit = (unitFromForm?.leases ?? []).map((unitLease) => {
      return getLease(unitLease?.lease?.id ?? '');
    });
    const activeLease = leasesOfUnit.find((leaseExtended) => leaseExtended?.status === LeaseStatus.Active);
    const utilityProvider = values.utilityProviders[0];
    const { contactId, distributorId, utilityReading, utilityIndexDate, ...rest } = utilityProvider;
    const contractor = contactId ? getContact(contactId) : undefined;
    const distributor = distributorId ? getContact(distributorId) : undefined;

    const utilityHistory =
      !isNil(utilityReading) && !isNil(utilityIndexDate)
        ? [
            {
              reading: utilityReading,
              date: typeof utilityIndexDate === 'object' ? utilityIndexDate.toISOString() : utilityIndexDate,
            },
          ]
        : undefined;

    const usableId = !isNil(unitFromForm) ? unitFromForm.id : id;

    const technics: Omit<Technic, 'id' | 'clientId' | 'readId'>[] = [
      {
        // Generic for technics. code has to make sure it creates a complete technic type
        ...rest,
        // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
        communicationSettingsProfileId: defaultTechnicProfile ? defaultTechnicProfile.id : 'mockId',
        utilityHistory,
        ...getLinks(values, usableId, !isNil(unitFromForm) ? 'unit' : 'building', buildingId ?? ''),
        ...(!isNil(contractor) && { contactId: contractor.id }),
        ...(!isNil(distributor) && { utilityDistributorId: distributor.id }),
      },
      ...(!isNil(activeLease)
        ? [
            {
              utilityHistory,
              // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
              communicationSettingsProfileId: defaultTechnicProfile ? defaultTechnicProfile.id : 'mockId',
              ...rest,
              ...(!isNil(contractor) && { contactId: contractor.id }),
              ...(!isNil(distributor) && { utilityDistributorId: distributor.id }),
              unitId: unitFromForm!.id,
              leaseId: activeLease.id,
            },
          ]
        : []),
    ];

    await Promise.all(technics.map((technic) => createTechnic(technic)));

    setStatus(true);
    setSubmitting(false);
    handleAfterSubmit();
    if (shouldRedirectAfterCreate) {
      const currentUnit = getUnit(values.onBuilding ? '' : values.unitId ?? '');
      const unitId = `unit-${currentUnit?.id}`;
      history.push({
        pathname: `${RouteDestination.PROPERTIES}/${getPropertyId()}/${!isNil(currentUnit) ? unitId : 'meters'}/${
          !isNil(currentUnit) ? 'meters' : ''
        }`,
      });
    }
  };

  return (
    <Formik
      initialValues={
        {
          utilityProviders: [emptyUtilityProvider],
          unitId: !isNil(unit) ? id : '',
          onBuilding: isNil(unit),
        } as UtilityProvidersFormValues
      }
      validationSchema={includeComplementaryDataSchema(UtilityProvidersSchema, showComplementaryData)}
      onSubmit={handleUtilityCreate}
    >
      {({ isSubmitting, status }) => (
        <Form>
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 25,
                marginTop: 25,
                marginLeft: 30,
                marginRight: 30,
              }}
            >
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: 18,

                  textAlign: 'left',
                }}
              >
                {formatMessage({ id: 'technic.utilityTab.addUtility' })}
              </Typography>
            </div>
            <Divider style={{ marginBottom: 10 }} />
            <UtilityProvidersFields showComplementaryData={showComplementaryData} multiple={false} />
          </>
          <div
            style={{
              marginBottom: 20,
              marginRight: 30,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <ActionButton
              onClick={() => {
                handleCancel();
              }}
              style={{ background: 'none', color: Colors.DARK_SLATE_GREY, marginRight: 20 }}
            >
              {toUpper(formatMessage({ id: 'cancel' }))}
            </ActionButton>
            <LoaderButton loading={isSubmitting} success={status}>
              {formatMessage({ id: 'save' })}
            </LoaderButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddUtility;
