/* eslint-disable max-len */
import React from 'react';
import './LoadingProgress.css';
import { Grid } from '@material-ui/core';

interface LoadingProgressProps {
  loading: boolean;
  width?: number;
  height?: number;
  handleClick?: () => void;
}

const LoadingProgress: React.FC<LoadingProgressProps> = ({ loading, width = 70, height = 80, handleClick }) => {
  return (
    <Grid
      style={{
        width,
        height,
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: loading ? 'default' : 'pointer',
      }}
      onClick={handleClick}
      id={loading ? '' : 'Up2Rent'}
    >
      <svg width={height} height={height} viewBox="0 0 60 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.881 36.7341L34.9062 28.7416L26.639 24.1864L27.0027 25.8119L22.7323 27.296L21.7991 23.3447L26.1177 21.8606L26.3365 22.8404L29.4838 11.0186L29.0719 9.16506H29.0686L21.5384 0L0 7.3404L4.70801 27.1225L11.8778 36.7341H11.881ZM24.853 16.3641L25.7347 20.3154L21.4644 21.7963L20.5344 17.845L24.853 16.3641ZM21.1072 27.8485L16.8368 29.3295L15.9068 25.3782L20.2254 23.8973L21.1072 27.8485ZM18.9575 18.3976L19.8393 22.3489L15.5689 23.8298L14.6389 19.8785L18.9575 18.3976Z"
          fill="#4B7BEC"
          className={!loading ? 'svg-no-anim' : 'svg-elem-1'}
        />
        <path
          d="M59.9968 31.8924L53.6058 29.6309L53.0748 29.467L47.2051 27.652L46.9669 27.5781L46.6838 27.6263L41.8309 28.4776L38.6 29.043L36.6917 29.4092L34.3779 45.391H34.3844H34.3811L47.1536 49.6475L57.7957 47.9449L60 31.8924H59.9968ZM53.6766 34.3435L56.1159 35.243L55.6525 38.3237L53.1939 37.4596L53.6766 34.3435ZM55.4819 39.1332L55.0217 42.2139L52.5599 41.3498L53.0426 38.2337L55.4819 39.1332ZM50.4907 33.1999L52.9332 34.0994L52.4698 37.1801L50.0112 36.3127L50.4907 33.1999ZM49.86 37.0901L52.2992 37.9896L51.8358 41.0703L49.3773 40.203L49.86 37.0901Z"
          fill="#4B7BEC"
          className={!loading ? 'svg-no-anim' : 'svg-elem-2'}
        />
        <path
          d="M51.026 7.99878L50.9037 7.92489L32.9663 4.24023L30.2535 14.4493L27.959 23.0811L28.5736 23.4216H28.5769L36.3742 27.8195L43.2093 26.6052L46.1313 26.0912L47.0742 25.9273L47.116 25.9402L53.4846 27.9094L57.6294 12.0625L51.026 7.99878ZM43.9141 19.9394L33.803 17.9123L34.5013 15.3167L44.5448 17.3791L43.9141 19.9394ZM45.0179 15.8435L34.9068 13.8133L35.6083 11.2176L45.6486 13.2832L45.0179 15.8435Z"
          fill="#4B7BEC"
          className={!loading ? 'svg-no-anim' : 'svg-elem-3'}
        />
      </svg>
    </Grid>
  );
};
export default LoadingProgress;
