import React from 'react';
import { FilterEntity, LeasesArchivedFilters, useFilters } from 'src/hooks/FiltersContext';
import { SelectorChildrenProps } from '../../ui/FieldFilterSelector';
import { LeasesTableProps } from '../Leases';
import FieldFilterResetButton from '../../ui/FieldFilterResetButton';
import LeasesFiltersCommons from './LeasesFiltersCommons';
import LeasesFiltersBalance from './LeasesFiltersBalance';
import LeasesFiltersEndOfLease from './LeasesFiltersEndOfLease';

const LeasesFiltersArchived: React.FC<LeasesTableProps> = ({ leasesArchived }) => {
  const {
    leasesArchivedFilters: { filters, balanceDept, endedSinceFromTo },
    updateFilters,
    resetFilters,
  } = useFilters();

  const handleFilterChange = (
    selectedItems: SelectorChildrenProps[],
    filterName: string,
    fieldName: string,
    menuClosed: boolean
  ) => {
    if (menuClosed) {
      return; // Don't set filter again
    }
    if (filterName === 'balance' || filterName === 'endedSinceFromTo') {
      // Filter are handled by custom functions
      return;
    }

    const filter = { name: filterName, field: fieldName, items: selectedItems.map((i) => i.value) };
    let newFilters = filters;

    const index = newFilters.findIndex((f) => f.name === filterName);
    if (index === -1) {
      newFilters = [...newFilters, filter];
    } else {
      newFilters = [...newFilters.filter((f) => f.name !== filterName), filter];
    }
    updateFilters<LeasesArchivedFilters>(FilterEntity.LEASES_ARCHIVED, {
      filters: newFilters,
      balanceDept,
      endedSinceFromTo,
    });
  };

  const handleFilterReset = (filterName: string) => {
    if (filterName === 'balance') {
      updateFilters<LeasesArchivedFilters>(FilterEntity.LEASES_ARCHIVED, {
        filters,
        balanceDept: 0,
        endedSinceFromTo,
      });
    }
    if (filterName === 'endedSinceFromTo') {
      updateFilters<LeasesArchivedFilters>(FilterEntity.LEASES_ARCHIVED, {
        filters,
        balanceDept,
        endedSinceFromTo: null,
      });
    }
  };

  const handleFilterEndOfLeaseFromTo = (from: Date | null, to: Date | null) => {
    const leaseFilterFromTo =
      from && to
        ? {
            from: from.toJSON(),
            to: to.toJSON(),
          }
        : null;
    updateFilters<LeasesArchivedFilters>(FilterEntity.LEASES_ARCHIVED, {
      filters,
      balanceDept,
      endedSinceFromTo: leaseFilterFromTo,
    });
  };

  const onUpdateBalanceFilter = (fieldName: string, value: number) => {
    updateFilters<LeasesArchivedFilters>(FilterEntity.LEASES_ARCHIVED, {
      filters,
      balanceDept,
      endedSinceFromTo,
      [fieldName]: value,
    });
  };

  // End of lease
  const endOfLeaseItems = [
    { fromMonth: -3, toMonth: 0, labelId: 'rentalUnit.filter.endedSinceLessThan3Months' },
    { fromMonth: -6, toMonth: -3, labelId: 'rentalUnit.filter.endedSinceBetween3And6Months' },
    { fromMonth: -12, toMonth: -6, labelId: 'rentalUnit.filter.endedSinceBetween6And12Months' },
  ];

  return (
    <div
      style={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 15,
        marginRight: 24,
      }}
    >
      <LeasesFiltersCommons filters={filters} handleFilterChange={handleFilterChange} leases={leasesArchived} />
      <LeasesFiltersEndOfLease
        filterName="endedSinceFromTo"
        labelId="rentalUnit.filter.endedSince"
        onReset={handleFilterReset}
        value={endedSinceFromTo}
        onChange={handleFilterEndOfLeaseFromTo}
        items={endOfLeaseItems}
      />
      <LeasesFiltersBalance balanceDept={balanceDept} onChange={onUpdateBalanceFilter} onReset={handleFilterReset} />
      <FieldFilterResetButton resetFilterValues={() => resetFilters(FilterEntity.LEASES_ARCHIVED)} />
    </div>
  );
};

export default LeasesFiltersArchived;
