import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// eslint-disable-next-line import/named
import Button, { ButtonProps } from '@material-ui/core/Button';
// eslint-disable-next-line import/named
import { Colors, LooseObject } from '@rentguru/commons-utils';
import clsx from 'clsx';

export const actionButtonSecondStyles = makeStyles({
  root: {
    fontSize: 12,
    fontWeight: 'bold',
    color: Colors.DARK_SLATE_GREY,
    textAlign: 'center',
    background: Colors.CLASSICAL_WHITE,
    borderRadius: 60,
    textTransform: 'capitalize',
    lineHeight: '15px',
    borderCollapse: 'collapse',
    boxShadow: '0px 3px 10px 0px rgba(0,0,0,0.1)',
    fontStyle: 'normal',
    letterSpacing: 0,
    padding: '5px 15px',
  },
  buttonWithMargin: {
    marginRight: 20,
  },
});

export interface ActionButtonSecondProps extends ButtonProps {
  style?: React.CSSProperties;
  to?: LooseObject | string;
  component?: React.ReactNode | React.ElementType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (event: any) => void;
  startIcon?: React.ReactNode;
  id?: string;
}
export const ActionButtonSecond: React.FC<ActionButtonSecondProps> = ({ children, style = {}, className, ...rest }) => {
  const classes = actionButtonSecondStyles();

  return (
    <Button className={clsx(classes.root, className)} style={style} {...rest}>
      {children}
    </Button>
  );
};
