import React, { useState, useEffect } from 'react';
import { ElevatedPaper } from '@up2rent/ui';
import { Divider, Box, Button, Grid } from '@material-ui/core';
import ContentHeaderMessages from 'src/components/ui/ContentHeaderMessages';

import isEmpty from 'lodash/isEmpty';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { Colors, getContactNameFromObject, stringToColor, Ticket, File, S3Object } from '@rentguru/commons-utils';
import TemplateEditor from 'src/components/ui/TemplateEditor';
import { stripHTML } from 'src/utils/stripHTML';
import { EditorState } from 'draft-js';
import { getS3ObjectUrls } from 'src/hooks/FilesContext';
import isNil from 'lodash/isNil';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import FileItemRendering from 'src/components/ui/FileItemRendering';
import PreviewFileDialogs from 'src/components/ui/PreviewFileDialog';
import { getStateFromHTML } from 'src/components/Communications/CommunicationDetail';

interface TicketsDetailConversationsProps {
  conversation: Ticket;
  filteredFileArray: File[];
  loading: boolean;
}

const useStyles = makeStyles(() => ({
  fabProgress: {
    color: Colors.DODGER_BLUE,
  },
  leaseSelectPaper: {
    width: '350px',
    transform: 'translateX(-35px)', // (350px - 280px) / 2
  },
}));

const TicketDetailConversationsBox: React.FC<TicketsDetailConversationsProps> = ({
  conversation,
  filteredFileArray,
  loading,
}) => {
  const [fileToPreview, setFileToPreview] = useState<S3Object | null>(null);
  const classes = useStyles();
  const [s3Files, setS3Files] = useState<S3Object[]>([]);

  const editable: boolean = false;
  const editMode: boolean = false;

  // template editor

  const [, setBody] = React.useState(EditorState.createEmpty());

  let conversationContactName = '';
  let avatarName = '';
  let avatarColor = '';
  // for conversations
  if (!isNil(conversation.contact)) {
    conversationContactName = getContactNameFromObject(conversation.contact);
    avatarName = getContactNameFromObject(conversation.contact);
    avatarColor = stringToColor(avatarName);
  }
  const conversationSubject: string = conversation.subject!;
  const conversationContent: string = conversation.content!;
  const conversationCreationTime: string = conversation.createdAt!;

  const currentBody = EditorState.createWithContent(getStateFromHTML(conversationContent));
  useEffect(() => {
    if (!isEmpty(filteredFileArray)) {
      let unmounted = false;

      getS3ObjectUrls(filteredFileArray).then((s3Objects) => {
        if (!unmounted) {
          setS3Files(s3Objects);
        }
      });
      return () => {
        unmounted = true;
        // when component unmounts
      };
      /* As the getS3ObjectUrls method is defined outside, we need to disable the warning 
  because we cannot put the method in the dependency (it will do an infinite loop) */
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [filteredFileArray]);

  return (
    <ElevatedPaper key={conversation.id}>
      <ContentHeaderMessages
        title={conversationContactName}
        request={stripHTML(conversationSubject)}
        name={avatarName}
        color={avatarColor}
        date={conversationCreationTime}
      />
      <Divider />
      <Grid container>
        <TemplateEditor body={currentBody} setBody={setBody} editable={editable} editMode={editMode} />
      </Grid>
      {/* file box */}
      <Box
        display="flex"
        style={{ paddingLeft: 10, width: '95%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
      >
        {(loading || (!isEmpty(filteredFileArray) && isEmpty(s3Files))) && (
          <CircularProgress size={20} className={classes.fabProgress} />
        )}
        {!isEmpty(s3Files) && (
          <>
            {s3Files.map((file: S3Object) => {
              const shouldPreviewFirst =
                !isNil(file.mimeType) && (file.mimeType!.includes('image') || file.mimeType!.includes('pdf'));
              return (
                <ListItem key={file.key} style={{ width: 120 }}>
                  <Button
                    href={!shouldPreviewFirst ? file.url : ''}
                    onClick={shouldPreviewFirst ? () => setFileToPreview(file) : undefined}
                    style={{ textDecoration: 'none' }}
                  >
                    <ListItemAvatar>
                      <FileItemRendering
                        fileType={file.mimeType as string}
                        fileUrl={file.url}
                        fileName={file.fileName}
                      />
                    </ListItemAvatar>
                  </Button>
                </ListItem>
              );
            })}
          </>
        )}
      </Box>
      <PreviewFileDialogs open={!isNil(fileToPreview)} file={fileToPreview} onClose={() => setFileToPreview(null)} />
    </ElevatedPaper>
  );
};

export default TicketDetailConversationsBox;
