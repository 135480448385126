/* eslint-disable react/no-array-index-key */
/* eslint-disable func-names */
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { toUpper, isNil, isEmpty, get } from 'lodash';
import { useFormikContext } from 'formik';
import { format } from 'date-fns';
import * as Yup from 'yup';
import Add from '@material-ui/icons/Add';
import { Divider, Grid, Typography, Tooltip, makeStyles } from '@material-ui/core';
import Dialog from 'src/components/ui/Dialog';
import { Colors, isNilOrEmpty, MessagesEN, Technic, UnitInventory, UnitType } from '@rentguru/commons-utils';
import { useLocale, dateLocaleMap, dateLocaleMapType } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as Delete } from 'src/icons/delete.svg';
import AddDetectorForm from 'src/components/Technics/Detectors/AddDetectorForm';
import FormHeader from 'src/components/ui/FormHeader';
import ResetIcon from 'src/components/ui/ResetIcon';
import { ActionButton , CustomIconButton } from '@up2rent/ui';
import { resolveMainUnitAndSubUnitIndexAndPrefix } from 'src/components/Leases/AddLease/useAddEditLeaseUtils';

import { UnitStructure } from 'src/components/ui/Forms/FormField/UnitStructureFields';
import IconnedRemark from '../../IconnedRemark';
import { ReactComponent as AttentionSvg } from 'src/icons/attention.svg';

export const doesUnitTypeHaveSmokeDetectors = (unitType: UnitType): boolean => {
  return ![UnitType.PARKING, UnitType.OTHER].includes(unitType);
};

/**
 * Check if smoke detectors are valid as it is mandatory in Flanders.
 */
export const areSmokeDetectorsValid = (
  region?: string | null,
  smokeDetectors?: {}[] | null,
  unitType?: UnitType | null
) => {
  if (!region || !unitType) {
    return false;
  }
  if (
    region === MessagesEN.enums.AddressRegion.Flanders &&
    isNilOrEmpty(smokeDetectors) &&
    doesUnitTypeHaveSmokeDetectors(unitType)
  ) {
    return false;
  }
  return true;
};

export const getDynamicSmokeDetectorsSchema = () => {
  return Yup.object().shape({
    smokeDetectors: Yup.array()
      .of(
        Yup.object().shape({
          contractNumber: Yup.string().nullable(),
          detectorType: Yup.string().required(),
          detectorSerialNumber: Yup.string().required(),
          detectorBrand: Yup.string().required(),
          detectorLastControlDate: Yup.string().required(),
          detectorUnitInventoryId: Yup.string().nullable(),
        })
      )
      .notRequired(),
  });
};

const useStyles = makeStyles({
  addButton: {
    background: Colors.CLASSICAL_WHITE,
    color: Colors.DARK_SLATE_GREY,
    '&:hover': {
      background: Colors.PORCELAIN_GREY_2,
    },
  },
  paperWidthFalse: {
    maxWidth: 620,
  },
});

export interface SmokeDetectorsFormValues {
  smokeDetectors: Technic[];
  originalSmokeDetectors: Technic[];
}
interface SmokeDetectorRowProps {
  detector: Technic;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  locale: any;
  unitInventory?: UnitInventory;
  deleteFunction: () => void;
  divider?: boolean;
}

interface SmokeDetectorsFormProps {
  unitId?: string;
}

const SmokeDetectorRow: React.FC<SmokeDetectorRowProps> = ({
  detector,
  locale,
  unitInventory,
  deleteFunction,
  divider = true,
}) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Grid container style={{ paddingTop: 13, paddingBottom: 13 }} data-test="smokeDetectorRow">
        <Grid item xs={2}>
          <Typography variant="h6" style={{ fontSize: 14, fontWeight: 700 }}>
            {formatMessage({ id: `technic.detectorTab.${detector.detectorType!.toLowerCase()}` })}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1" style={{ fontSize: 14, color: Colors.SLATE_GREY }}>
            {!isNil(detector.detectorSerialNumber) ? `SN: ${detector.detectorSerialNumber}` : ''}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="subtitle1" style={{ fontSize: 14, color: Colors.SLATE_GREY }}>
            {!isNil(detector.detectorBrand) ? detector.detectorBrand : ''}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1" style={{ fontSize: 14, color: Colors.SLATE_GREY }}>
            {!isNil(unitInventory) ? unitInventory.roomName : ''}
          </Typography>
        </Grid>
        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="subtitle1" style={{ fontSize: 14, color: Colors.SLATE_GREY }}>
            {format(new Date(detector.detectorLastControlDate!), 'd/MM/yyyy', {
              locale: (dateLocaleMap as dateLocaleMapType)[locale],
            })}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Tooltip title={formatMessage({ id: 'discard' })}>
            <CustomIconButton
              onClick={deleteFunction}
              size="small"
              Icon={Delete}
              iconStyle={{ fill: Colors.BLUE_GREY }}
            />
          </Tooltip>
        </Grid>
      </Grid>
      {divider && <Divider />}
    </>
  );
};

const SmokeDetectorsForm: React.FC<SmokeDetectorsFormProps> = ({ unitId }) => {
  const { formatMessage } = useIntl();
  const [dialog, setDialog] = useState<boolean>(false);
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext<SmokeDetectorsFormValues>();
  const { language } = useLocale();
  const { prefixFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(values, unitId);
  const { prefixFieldName: completeFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(
    values,
    unitId,
    'smokeDetectors'
  );
  const { prefixFieldName: completeOriginalFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(
    values,
    unitId,
    'originalSmokeDetectors'
  );
  const { prefixFieldName: unitStructuresFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(
    values,
    unitId,
    'unitStructures'
  );

  const unitStructures = get(values, unitStructuresFieldName, []) as UnitStructure[];
  const allUnitInventories = unitStructures.reduce((acc: UnitInventory[], currentUnitStructure: UnitStructure) => {
    const unitInventoryOfCurrentUnit = currentUnitStructure.unitInventories.filter(
      (unitInventory) => unitInventory.unitId === unitId
    );
    acc.push(...unitInventoryOfCurrentUnit);
    return acc;
  }, []);

  const decreaseNumberOfDetectors = (index: number) => {
    setFieldValue(
      completeFieldName,
      (get(values, completeFieldName, []) as Technic[]).filter((_s, idx) => index !== idx)
    );
  };

  const originalSmokeDetectors = get(values, completeOriginalFieldName, []) as Technic[];
  const smokeDetectors = get(values, completeFieldName, []) as Technic[];
  const mainUnitRegion = get(values, 'unit.building.address.region');
  const unitType = get(values, `${prefixFieldName}unit.type`);
  const missingSmokeDetectorsError = !areSmokeDetectorsValid(mainUnitRegion, smokeDetectors, unitType);

  return (
    <>
      <FormHeader title={formatMessage({ id: 'technic.detectorTab.smokeDetectors' })}>
        {!isEmpty(originalSmokeDetectors) && (
          <Tooltip title={formatMessage({ id: 'backToDefault' })}>
            <CustomIconButton
              onClick={() => setFieldValue(completeFieldName, originalSmokeDetectors)}
              Icon={ResetIcon}
              iconStyle={{ fill: Colors.BLUE_GREY }}
            />
          </Tooltip>
        )}
      </FormHeader>
      {!isNilOrEmpty(smokeDetectors) && (
        <Grid>
          <Divider style={{ marginBottom: 10 }} />
          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={2}>
              <Typography variant="h6" style={{ fontSize: 12, color: Colors.LIGHT_BLUE_GREY }}>
                {formatMessage({ id: 'lease.detail.action.type' })}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1" style={{ fontSize: 12, color: Colors.LIGHT_BLUE_GREY }}>
                {formatMessage({ id: 'lease.detail.action.serialNumber' })}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="subtitle1" style={{ fontSize: 12, color: Colors.LIGHT_BLUE_GREY }}>
                {formatMessage({ id: 'lease.detail.action.brand' })}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1" style={{ fontSize: 12, color: Colors.LIGHT_BLUE_GREY }}>
                {formatMessage({ id: 'lease.detail.action.room' })}
              </Typography>
            </Grid>
            <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="subtitle1" style={{ fontSize: 12, color: Colors.LIGHT_BLUE_GREY }}>
                {formatMessage({ id: 'lease.detail.action.lastControl' })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Divider style={{ marginBottom: 10 }} />

      {missingSmokeDetectorsError && (
        <IconnedRemark
          Icon={AttentionSvg}
          noFixWidth
          style={{ marginTop: 10, marginBottom: 10, marginLeft: 30, marginRight: 30 }}
          iconStyles={{ fill: Colors.BURNING_ORANGE, width: 20, height: 20 }}
          message={
            <Typography style={{ fontSize: 14, color: Colors.SLATE_GREY, textAlign: 'left' }} data-test="smokeError">
              {formatMessage({
                id: 'technic.detectorTab.noDetectorsError',
              })}
            </Typography>
          }
        />
      )}

      {smokeDetectors.map((smokeDetector, index) => (
        <SmokeDetectorRow
          key={`${smokeDetector.id} - ${index}`}
          detector={smokeDetector}
          divider={index !== smokeDetectors.length - 1}
          unitInventory={allUnitInventories.find((ui) => ui.id === smokeDetector.detectorUnitInventoryId)}
          locale={language}
          deleteFunction={() => decreaseNumberOfDetectors(index)}
        />
      ))}
      {!isEmpty(smokeDetectors) && <Divider style={{ marginBottom: 10 }} />}
      <div style={{ display: 'flex' }}>
        <ActionButton
          data-test="addDetector"
          onClick={() => setDialog(true)}
          style={{
            marginLeft: 20,
          }}
          className={classes.addButton}
        >
          <Add />
          {toUpper(formatMessage({ id: 'technic.detectorTab.smokeDetectors' }))}
        </ActionButton>
      </div>
      <Dialog
        open={dialog}
        onClose={() => setDialog(false)}
        scroll="body"
        fullWidth
        classes={{ paper: classes.paperWidthFalse }}
      >
        <AddDetectorForm
          afterSubmit={(param: Omit<Technic, 'id' | 'clientId' | 'readId'>[]) => {
            setDialog(false);
            setFieldValue(completeFieldName, [...get(values, completeFieldName, []), ...param]);
          }}
          showComplementaryData={false}
          cancelSubmit={() => setDialog(false)}
          entity="leaseUnit"
          id={unitId!}
          unitInventories={allUnitInventories}
        />
      </Dialog>
    </>
  );
};

export default SmokeDetectorsForm;
