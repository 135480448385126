import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { ElevatedPaperTable } from '@up2rent/ui';
import FormHeaderColorDots from 'src/components/ui/FormHeaderColorDots';
import PicturesList from './PicturesList';
import PublicationDescriptionEdit from './PublicationDescriptionEdit';

const DescriptionEdits = () => {
  const { formatMessage } = useIntl();

  return (
    <Grid item style={{ width: 640 }}>
      <ElevatedPaperTable style={{ maxWidth: 640, paddingBottom: 10 }}>
        <FormHeaderColorDots
          title={formatMessage({ id: 'rentalUnit.detail.description.description' })}
          mandatoryDot={false}
          recommendedDot={false}
        />
        <PicturesList />
        <PublicationDescriptionEdit />
      </ElevatedPaperTable>
    </Grid>
  );
};

export default DescriptionEdits;
