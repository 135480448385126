import React from 'react';
import * as Yup from 'yup';
import { isNil } from 'lodash';
import { FormikHelpers } from 'formik';

export const isValidString = (str: string | undefined | null, nullable: boolean = false) => {
  if (!nullable && isNil(str)) return false;
  return typeof str === 'string' && str !== '';
};

export const isValidPositiveNumber = (nbr: number | undefined | null, nullable: boolean = false) => {
  if (!nullable && isNil(nbr)) return false;
  return typeof nbr === 'number' && nbr > 0;
};

export const EmptySchema = Yup.object().shape({ dummy: Yup.string().notRequired() });

export const onChangeToLowerCaseFieldValue = (
  event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  fieldName: string,
  setFieldValue: FormikHelpers<unknown>['setFieldValue']
) => {
  const value = event.target.value;
  setFieldValue(fieldName, value.toLocaleLowerCase());
};
