import isNil from 'lodash/isNil';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import BarChart, { BarChartData } from '../../ui/Graphs/BarChart';
import {
  getLastYearFilter,
  getOneYearFilter,
  getThisYearPlus6MonthsFilter,
  MetricBoxTimeFiltersProps,
} from './DashboardFilters/MetricBoxTimeFilter';
import { CustomFilterMenuBundle } from './DashboardMetrics';
import MetricBox, { MetricBoxLayoutProps } from './MetricBox';
import {
  calculateExpectedMonthIncomesInTimeWindow,
  preFilterLeaseIndexationsAndDiscountsBasedOnUnits,
} from './utils/MetricUtils';
import { useLeases } from 'src/hooks/LeasesContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { format, isBefore, differenceInMonths } from 'date-fns';
import { max } from 'd3';
import { useDashboardFilters } from 'src/hooks/DashboardFiltersContext';
import { useLeasePriceHistories } from 'src/hooks/LeasePriceHistoriesContext';
import { LeasePriceHistory, LeaseVariousOperation, RENTAL_INCOME_BOX_KEY } from '@rentguru/commons-utils';
import { dateLocaleMap, dateLocaleMapType, useLocale } from 'src/i18n/IntlProviderWrapper';
import { useLeaseVariousOperations } from 'src/hooks/LeaseVariousOperationsContext';

const MAX_BAR_LABELS = 16;
interface DashboardMetricRentalIncomeProps extends MetricBoxLayoutProps {
  openCustomFilterMenu: (bundle: CustomFilterMenuBundle) => void;
}

const DashboardMetricRentalIncome: React.FC<DashboardMetricRentalIncomeProps> = ({
  openCustomFilterMenu: openCustomFilterMenuFunction,
  forceUpdateLayout,
}) => {
  const { formatMessage } = useIntl();
  const { leasePriceHistories, loading: leasesIndexationsLoading } = useLeasePriceHistories();
  const { discounts, loading: leaseVariousOperationsLoading } = useLeaseVariousOperations();
  const { unitsLoading } = useUnits();
  const { leasesLoading } = useLeases();

  const timeFilters: MetricBoxTimeFiltersProps = {
    name: 'rentalIncome',
    filters: [
      getLastYearFilter(formatMessage),
      getOneYearFilter(formatMessage),
      getThisYearPlus6MonthsFilter(formatMessage),
    ],
    openCustomFilterMenu: (event?: React.MouseEvent<HTMLElement>) => {
      if (!isNil(event)) {
        openCustomFilterMenuFunction({ anchor: event.currentTarget, name: 'rentalIncome' });
      }
    },
  };

  return (
    <MetricBox
      boxKey={RENTAL_INCOME_BOX_KEY}
      title={formatMessage({ id: 'dashboard.keyFigures.expectedRentalIncome' })}
      timeFilterBundle={timeFilters}
      forceUpdateLayout={forceUpdateLayout}
      loading={unitsLoading || leasesLoading || leasesIndexationsLoading || leaseVariousOperationsLoading}
    >
      <MetricBoxContent
        leaseIndexations={leasePriceHistories ?? []}
        discounts={discounts ?? []}
        loading={leasesIndexationsLoading || leaseVariousOperationsLoading}
      />
    </MetricBox>
  );
};

const MetricBoxContent: React.FC<{
  leaseIndexations: LeasePriceHistory[];
  discounts: LeaseVariousOperation[];
  loading: boolean;
}> = ({ leaseIndexations, discounts, loading }) => {
  const { unitsLoading } = useUnits();
  const { language } = useLocale();
  const { leasesLoading } = useLeases();
  const {
    filteredUnits,
    dashboardFilters: { rentalIncome: rentalIncomeFilters },
  } = useDashboardFilters();

  const datas: BarChartData[] = useMemo(() => {
    if (unitsLoading || leasesLoading || loading) {
      return [];
    }
    const fromDate = new Date(rentalIncomeFilters.from);
    const toDate = new Date(rentalIncomeFilters.to);
    const { leaseIndexations: filteredLeaseIndexations, discounts: filteredDiscounts } =
      preFilterLeaseIndexationsAndDiscountsBasedOnUnits(filteredUnits, leaseIndexations, discounts);
    const expectedMonthIncomes = calculateExpectedMonthIncomesInTimeWindow(
      filteredUnits,
      filteredLeaseIndexations,
      filteredDiscounts,
      {
        start: fromDate,
        end: toDate,
      }
    );
    const differenceInMonthsValue = differenceInMonths(toDate, fromDate);
    return expectedMonthIncomes.map((monthlyValue) => {
      const { value, startOfMonth } = monthlyValue;
      let legend = '';
      if (differenceInMonthsValue <= MAX_BAR_LABELS) {
        legend = format(startOfMonth, 'MMM', { locale: (dateLocaleMap as dateLocaleMapType)[language] });
      } else if (startOfMonth.getMonth() === 0) {
        legend = format(startOfMonth, 'yyyy', { locale: (dateLocaleMap as dateLocaleMapType)[language] });
      }
      return {
        value,
        text: legend,
        id: format(startOfMonth, 'MMM-yyyy', { locale: (dateLocaleMap as dateLocaleMapType)[language] }),
        disabled: !isBefore(startOfMonth, new Date()),
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredUnits, rentalIncomeFilters, unitsLoading, leasesLoading, loading]);

  const maxY = max(datas, (val) => val.value);
  return (
    <BarChart
      datas={datas}
      yAxisOptions={{
        labelFormat: '€',
        ticksViewable: 5,
        maxValue: !isNil(maxY) ? (Math.ceil(maxY) / 1000) * 1000 : undefined,
      }}
      tooltipHtml={(data: BarChartData) => {
        return `${data.id}<br />${data.value}€`;
      }}
    />
  );
};

export default DashboardMetricRentalIncome;
