import isNil from 'lodash/isNil';
import { Team } from '@rentguru/commons-utils';

export const getTeamChildren = (teamId: string, teams: Team[] | undefined, arrayToMutate: string[]) => {
  if (!isNil(teams)) {
    const children = teams.filter((team) => team.parentId === teamId);
    for (const child of children) {
      arrayToMutate.push(child.id);
      getTeamChildren(child.id, teams, arrayToMutate);
    }
  }
};

export const getTeamParents = (team: Team, teams: Team[] | undefined, arrayToMutate: string[]) => {
  if (!isNil(teams)) {
    const parent = teams.find((t) => t.id === team.parentId);
    if (!isNil(parent)) {
      arrayToMutate.push(parent.id);
      getTeamParents(parent, teams, arrayToMutate);
    }
  }
};

/**
 * Given a hierarchical team list, returns the one that is at its bottom
 */
export const getLeafTeam = (teams: Team[]) => {
  return teams.find((team) => !teamHasChildInList(team, teams));
};

const teamHasChildInList = (team: Team, list: Team[]) => {
  return list.some((t) => t.parentId === team.id);
};

export const getCompleteTeams = (teamIds: string[], getTeam: (id: string) => Team | undefined) => {
  return teamIds.reduce((acc: Team[], teamId: string) => {
    const completeTeam = getTeam(teamId);
    if (!isNil(completeTeam)) acc.push(completeTeam);
    return acc;
  }, []);
};
