import amplifyConfig from './amplifyconfiguration.json';

const { host } = window.location;

// Fix issues with multiple redirect urls.
// Try to figure out which one to use...
if (amplifyConfig.oauth.redirectSignIn.includes(',')) {
  const filterHost = (url: string) => new URL(url).host === host;
  amplifyConfig.oauth.redirectSignIn = amplifyConfig.oauth.redirectSignIn.split(',').filter(filterHost).shift() ?? '';
  amplifyConfig.oauth.redirectSignOut = amplifyConfig.oauth.redirectSignOut.split(',').filter(filterHost).shift() ?? '';
}
export default amplifyConfig;
