import { Divider, Typography } from '@material-ui/core';
import {
  Colors,
  ContactType,
  EntityType,
  FileCategory as FileCategoryCtx,
  File as FileModel,
  NewFile,
  Technic,
} from '@rentguru/commons-utils';
import { ActionButton, LoaderButton } from '@up2rent/ui';
import { isAfter } from 'date-fns';
import { Form, Formik, FormikHelpers } from 'formik';
import isNil from 'lodash/isNil';
import toUpper from 'lodash/toUpper';
import React from 'react';
import { useIntl } from 'react-intl';
import FormikDatePicker from 'src/components/ui/FormikDatePicker';
import FileFields from 'src/components/ui/Forms/FormField/FileByCategoryFields';
import ContactSelectForm from 'src/components/ui/Forms/FormSection/ContactSelectForm';
import { useDocumentCategory } from 'src/hooks/FileCategoryContext';
import { FilesContext } from 'src/hooks/FilesContext';
import { FileCategoryTypeForm as FileCategory, useTechnics } from 'src/hooks/TechnicsContext';
import * as Yup from 'yup';

interface AddTankControlProps {
  tank: Technic;
  createFile: FilesContext['createFile'];
  afterSubmit: () => void;
  cancelSubmit: () => void;
}

const TankSchema = Yup.object().shape({
  date: Yup.date().required(),
});

interface AddTankControlValues {
  date: Date;
  filesByCategories: FileCategory[];
  contact: { id: string }[];
}

const AddTankControl: React.FC<AddTankControlProps> = ({ tank, createFile, afterSubmit, cancelSubmit }) => {
  const { formatMessage } = useIntl();
  const { updateTechnic } = useTechnics();
  const { getDocumentCategoryByFileCategory } = useDocumentCategory();
  const hydroCarbonTankCertificateDocumentCategory = getDocumentCategoryByFileCategory(
    FileCategoryCtx.HYDROCARBONTANK_CERTIFICATE
  );

  const handleAfterSubmit = () => {
    afterSubmit();
  };
  const handleCancel = () => {
    cancelSubmit();
  };

  const handleControlCreate = async (
    values: AddTankControlValues,
    { setSubmitting, setStatus }: FormikHelpers<AddTankControlValues>
  ): Promise<void> => {
    // Create file if attached
    const fileTank = values.filesByCategories.find(
      (fileCategory) => fileCategory.category.fileCategory === FileCategoryCtx.HYDROCARBONTANK_CERTIFICATE
    );
    let file: FileModel | null = null;
    if (!isNil(fileTank)) {
      // Compare with originalFile
      const attachmentsPromises = fileTank.files.map((fileItem) =>
        createFile(
          (fileItem as NewFile).file,
          EntityType.TECHNIC,
          tank.id,
          hydroCarbonTankCertificateDocumentCategory?.id
        )
      );

      file = (await Promise.all(attachmentsPromises))[0];
    }
    // Update technic
    const previousHistory = !isNil(tank.maintenanceHistory) ? tank.maintenanceHistory : [];
    const contractorId = values.contact[0].id === '' ? undefined : values.contact[0].id;
    const fileId = !isNil(file) ? file.id : undefined;
    await updateTechnic(tank, {
      tankLastControlDate:
        isNil(tank.tankLastControlDate) || isAfter(values.date, new Date(tank.tankLastControlDate))
          ? values.date.toISOString()
          : tank.tankLastControlDate,
      maintenanceHistory: [...previousHistory, { date: values.date.toISOString(), contactId: contractorId, fileId }],
    });
    setStatus(true);
    setSubmitting(false);
    handleAfterSubmit();
  };

  return (
    <Formik
      initialValues={{
        date: new Date(),
        contact: [{ id: '' }],
        filesByCategories: [{ category: hydroCarbonTankCertificateDocumentCategory!, files: [] }],
      }}
      validationSchema={TankSchema}
      onSubmit={handleControlCreate}
    >
      {({ values, errors, touched, isSubmitting, status, setFieldValue, handleChange, handleBlur }) => (
        <Form>
          <div style={{ marginLeft: 20, marginRight: 20, marginTop: 25, marginBottom: 25 }}>
            <Typography
              style={{
                fontWeight: 'bold',
                fontSize: 20,
                textAlign: 'left',
              }}
            >
              {formatMessage({ id: 'technic.addControl' })}
            </Typography>
          </div>
          <Divider style={{ marginBottom: 10 }} />
          <div style={{ alignItems: 'baseline', marginLeft: 20, marginRight: 20 }}>
            <FormikDatePicker
              name="date"
              label={formatMessage({ id: 'technic.lastControlledDate' })}
              value={values.date}
              style={{ width: 580 }}
              error={Boolean(errors.date && touched.date)}
            />
            <div>
              <ContactSelectForm
                values={values}
                errors={errors}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                fieldLabel={`
                  ${formatMessage({
                    id: 'technic.contractorName',
                  })} ${formatMessage({
                  id: 'optional',
                })}`}
                addLabel={formatMessage({
                  id: 'technic.contractorName',
                })}
                type={ContactType.CONTRACTOR}
                fieldName="contact"
                showPercent={false}
                multiple={false}
                checkAdress={false}
              />
            </div>
          </div>
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          <FileFields
            documentCategory={hydroCarbonTankCertificateDocumentCategory!}
            categoryType="noHeader"
            centerSelectedFile
            maxFiles={1}
          />
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          <div
            style={{
              marginBottom: 20,
              marginRight: 30,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <ActionButton
              onClick={() => {
                handleCancel();
              }}
              style={{ background: 'none', color: Colors.DARK_SLATE_GREY, marginRight: 20 }}
            >
              {toUpper(formatMessage({ id: 'cancel' }))}
            </ActionButton>
            <LoaderButton loading={isSubmitting} success={status}>
              {formatMessage({
                id: 'save',
              })}
            </LoaderButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddTankControl;
