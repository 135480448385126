import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { ElevatedPaperTable } from '@up2rent/ui';
import FormHeaderColorDots from 'src/components/ui/FormHeaderColorDots';
import AmenitiesEdit from './AmenitiesEdit';
// import ListedRoomsEdit from './ListedRoomsEdit';
// import PropertyEdit from './PropertyEdit';
import RoomsEdit from './InsideInventory';

const InteriorEdits = () => {
  const { formatMessage } = useIntl();
  return (
    <Grid item style={{ width: 640 }}>
      <ElevatedPaperTable style={{ maxWidth: 640, paddingBottom: 10 }}>
        <FormHeaderColorDots
          title={formatMessage({ id: 'rentalUnit.detail.inside.inside' })}
          mandatoryDot={false}
          recommendedDot={false}
        />
        {/* {<PropertyEdit />} */}
        <RoomsEdit />
        {/* <ListedRoomsEdit /> */}
        <AmenitiesEdit />
      </ElevatedPaperTable>
    </Grid>
  );
};

export default InteriorEdits;
