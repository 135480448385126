/* eslint-disable react/no-unstable-nested-components */
import {
  LeaseAmountUpdateStatus,
  LeasePriceHistory,
  ModelWithVersion,
  UpdateLeasePriceHistoryInput,
} from '@rentguru/commons-utils';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { CustomSimpleDialog } from '@up2rent/ui';
import * as Yup from 'yup';
import TextDetailEditable from 'src/components/ui/TextDetailEditable';

export interface IgnoreLeaseAdjustmentDialogProps {
  leaseChargeAdjustment: LeasePriceHistory;
  open: boolean;
  onClose: () => void;
  updateLeaseChargeAdjustment: (updates: UpdateLeasePriceHistoryInput) => Promise<LeasePriceHistory>;
}

interface FormIndexationSkippingValues {
  comment: string;
}

const IgnoreLeaseAdjustmentSchema = Yup.object().shape({
  comment: Yup.string().min(1).required('You have to specify a reason for skipping indexation!'),
});

const IgnoreLeaseAdjustmentDialog: React.FC<IgnoreLeaseAdjustmentDialogProps> = ({
  leaseChargeAdjustment,
  open,
  onClose,
  updateLeaseChargeAdjustment,
}) => {
  const { formatMessage } = useIntl();

  const handleSubmit = async (
    values: FormIndexationSkippingValues,
    { setSubmitting }: FormikHelpers<FormIndexationSkippingValues>
  ) => {
    setSubmitting(true);
    await updateLeaseChargeAdjustment({
      id: leaseChargeAdjustment.id,
      _version: (leaseChargeAdjustment as ModelWithVersion<LeasePriceHistory>)._version,
      comment: values.comment,
      status: LeaseAmountUpdateStatus.TO_PROCESS_CLIENT_REFUSED,
    });
    setSubmitting(false);
    onClose();
  };

  return (
    <Formik
      initialValues={{
        comment: '',
      }}
      validationSchema={IgnoreLeaseAdjustmentSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isSubmitting, handleSubmit: onActionButtonClick }) => (
        <Form>
          <CustomSimpleDialog
            open={open}
            onClose={onClose}
            onActionButtonClick={onActionButtonClick}
            actionButtonLabel={formatMessage({ id: 'lease.addLease.ignore' })}
            actionButtonLoading={isSubmitting}
            secondaryButtonLabel={formatMessage({ id: 'contact.addContact.cancelLabel' })}
            title={formatMessage({ id: 'accounting.chargeAdjustments.ignoreLeaseChargeAdjustment' })}
            dividerBelowTitle
            formatMessage={formatMessage}
          >
            <TextDetailEditable
              title={formatMessage({ id: 'dashboard.indexationDialog.reasonToSkip' })}
              editMode={true}
              name="comment"
              type="text"
              style={{ maxWidth: 'none', width: '100%', marginTop: 20 }}
              error={Boolean(errors.comment && touched.comment)}
            />
          </CustomSimpleDialog>
        </Form>
      )}
    </Formik>
  );
};

export default IgnoreLeaseAdjustmentDialog;
