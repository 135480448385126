import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import MetricBox, { MetricBoxLayoutProps } from './MetricBox';
import { useDashboardFilters } from '../../../hooks/DashboardFiltersContext';
import { isNil } from 'lodash';
import { AREAS_BOX_KEY, truncateAndEllipsisAfter, UnitType } from '@rentguru/commons-utils';
import BarChart, { BarChartData } from 'src/components/ui/Graphs/BarChart';

const DashboardMetricAreas: React.FC<MetricBoxLayoutProps> = ({ forceUpdateLayout }) => {
  const { formatMessage } = useIntl();

  return (
    <MetricBox
      boxKey={AREAS_BOX_KEY}
      title={formatMessage({ id: 'dashboard.overview.totalAreaPerType' })}
      forceUpdateLayout={forceUpdateLayout}
    >
      <MetricBoxContent />
    </MetricBox>
  );
};

const MetricBoxContent = () => {
  const { formatMessage } = useIntl();
  const { filteredUnits } = useDashboardFilters(); // temporal local filters

  const labelFormat = 'm²';
  const tooltipHtml = (data: BarChartData) => {
    return `${data.value}${labelFormat}<br />${data.id}`;
  };

  const datas = useMemo<{ datas: BarChartData[]; maxValue: number }>(() => {
    const emptyDatas: BarChartData[] = [];

    Object.keys(UnitType).forEach((value) => {
      const text = formatMessage({ id: `enums.UnitType.${value}` });
      emptyDatas.push({
        value: 0, // totalSurface
        text: truncateAndEllipsisAfter(text, 7),
        id: text,
        disabled: false,
      });
    });

    let maxValue = 0;
    const filteredDatas = filteredUnits.reduce((result, currentUnit) => {
      if (isNil(currentUnit.surface) || currentUnit.surface === 0) {
        return result;
      }
      const text = formatMessage({ id: `enums.UnitType.${currentUnit.type}` });
      const oldValue = result.find((currentResult) => currentResult.id === text);
      if (!oldValue) {
        return result;
      }

      const newValue = Math.round(oldValue.value + currentUnit.surface);
      if (newValue > maxValue) {
        maxValue = newValue;
      }
      oldValue.value = newValue;

      return result;
    }, emptyDatas);

    return { datas: filteredDatas, maxValue: 0 };
  }, [filteredUnits, formatMessage]);
  return (
    <BarChart
      datas={datas.datas}
      yAxisOptions={{ labelFormat, ticksViewable: Object.keys(UnitType).length, maxValue: datas.maxValue }}
      tooltipHtml={tooltipHtml}
    />
  );
};

export default DashboardMetricAreas;
