import React, { useState } from 'react';
import { useNotifications } from 'src/hooks/NotificationsContext';
import NotificationBox from './NotificationBox';
import SlicedContent from './SlicedContent';
import {
  Notification,
  NotificationStatus,
  NotificationType,
  CUSTOM_NOTIFICATION_BOX_KEY,
} from '@rentguru/commons-utils';
import isNil from 'lodash/isNil';
import { compareAsc, isBefore } from 'date-fns';
import SnoozeNotificationDialog from './DashboardDialogs/SnoozeNotificationDialog';
import CustomNotificationsHeader from './BoxCustomHeaders/CustomNotificationsHeader';
import CustomNotificationRow from './BoxCustomRows/CustomNotificationRow';
import { useIntl } from 'react-intl';
import TodosLoading from './TodosLoading';

export const ROW_HEIGHT = 50;

const customNotificationSortFunction = (notification1: Notification, notification2: Notification): number => {
  if (isNil(notification1.createdAt)) return 1;
  if (isNil(notification2.createdAt)) return -1;
  return compareAsc(new Date(notification1.createdAt), new Date(notification2.createdAt));
};

const DashboardCustomNotifications: React.FC = () => {
  const { notifications, notificationsLoading } = useNotifications();
  const [notificationToSnooze, setNotificationToSnooze] = useState<Notification | null>(null);
  const { formatMessage } = useIntl();
  if (notificationsLoading) {
    return (
      <>
        <TodosLoading />
      </>
    );
  }
  const notificationsToDisplay = notifications
    .filter(
      (notification) =>
        !isNil(notification.type) &&
        notification.type === NotificationType.Custom &&
        notification.status !== NotificationStatus.Done &&
        notification.status !== NotificationStatus.Ignored &&
        isBefore(new Date(notification.popupDate), new Date())
    )
    .sort(customNotificationSortFunction)
    .map((notification: Notification) => {
      return (
        <CustomNotificationRow
          key={notification.id}
          notification={notification}
          setNotificationToSnooze={setNotificationToSnooze}
        />
      );
    });

  return (
    <>
      <NotificationBox
        boxKey={CUSTOM_NOTIFICATION_BOX_KEY}
        title={`${formatMessage({ id: 'dashboard.notifications.customNotifications' })} (${
          notificationsToDisplay.length
        })`}
      >
        <SlicedContent
          data={notificationsToDisplay}
          header={<CustomNotificationsHeader />}
          type="table"
          limit={5}
          rowHeight={ROW_HEIGHT}
          boxKey={CUSTOM_NOTIFICATION_BOX_KEY}
        />
      </NotificationBox>
      {!isNil(notificationToSnooze) && (
        <SnoozeNotificationDialog notification={notificationToSnooze} onClose={() => setNotificationToSnooze(null)} />
      )}
    </>
  );
};

export default DashboardCustomNotifications;
