import { Paper } from '@material-ui/core';
import { Colors, getTenantsName, truncateAndEllipsisAfter, Contact } from '@rentguru/commons-utils';
import React from 'react';
import HorizontalPoppedComponent from 'src/components/ui/HorizontalPoppedComponent';
import { OverflowableTypography } from '@up2rent/ui';

interface LeaseTenantsColumnProps {
  tenants: Contact[];
  startDate: string;
  endDate: string;
  open: boolean;
}

const LeaseTenantsColumn: React.FC<LeaseTenantsColumnProps> = ({ tenants, startDate, endDate, open }) => {
  return (
    <>
      <div>
        <OverflowableTypography
          text={truncateAndEllipsisAfter(getTenantsName(tenants), 20)}
          style={{
            fontSize: 14,
            color: Colors.SLATE_GREY,
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
          }}
        />
      </div>
      {open && (
        <Paper
          elevation={8}
          style={{
            position: 'absolute',
            margin: 0,
            zIndex: 1,
            minWidth: 400,
            minHeight: 16,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: 10,
            transform: 'translateX(-200px)',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <HorizontalPoppedComponent startDate={startDate} endDate={endDate} type="LEASE" tenants={tenants} />
        </Paper>
      )}
    </>
  );
};

export default LeaseTenantsColumn;
