import { FormControlLabel, Grid, RadioGroup, Typography } from '@material-ui/core';
import { Colors, CommunicationChannel } from '@rentguru/commons-utils';
import { toLower } from 'lodash';
import { useIntl } from 'react-intl';
import { RouteDestination } from 'src/components/Routes/Routes';
import { SettingsRouteDestination } from 'src/components/Settings/Settings';
import CustomRouterLink from 'src/components/ui/CustomRouterLink';
import StyledRadio from 'src/components/ui/StyledRadio';
import { channelToTemplateType } from 'src/utils/communicationUtils';
import { SettableCommunicationType } from '../CommunicationSettingsProfileUtils/CommunicationSettingsCommonsUtils';

interface CommunicationChannelRadioItemProps {
  communicationType: SettableCommunicationType;
  channel: CommunicationChannel;
  disabled: boolean;
  previewMode: boolean;
}

const CommunicationChannelRadioItem: React.FC<CommunicationChannelRadioItemProps> = ({
  communicationType,
  channel,
  disabled,
  previewMode,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Grid container>
      <FormControlLabel
        style={{ marginTop: -10 }}
        value={channel}
        disabled={disabled}
        control={<StyledRadio name={channel} />}
        label={
          <Grid container>
            <Typography style={{ fontSize: 14, fontWeight: 600 }}>
              {formatMessage({ id: `enums.CommunicationChannel.${channel}` })}
            </Typography>
            {[CommunicationChannel.EMAIL, CommunicationChannel.IN_APP].includes(channel) && (
              <Typography style={{ marginTop: 2, marginLeft: 5, color: Colors.SLATE_GREY, fontStyle: 'italic' }}>
                ({formatMessage({ id: 'free' })})
              </Typography>
            )}
          </Grid>
        }
        id={channel}
      />
      {!previewMode && (
        <CustomRouterLink
          to={`${RouteDestination.SETTINGS}/${SettingsRouteDestination.TEMPLATE_ROUTE}/${toLower(
            channelToTemplateType(channel)
          )}/${communicationType}`}
          text={formatMessage({ id: 'settings.communications.previewTemplate' })}
          textStyle={{ marginTop: 2 }}
        />
      )}
    </Grid>
  );
};

interface CommunicationChannelFormItemProps {
  communicationType: SettableCommunicationType;
  possibleChannels: CommunicationChannel[];
  selectedChannel: CommunicationChannel;
  disabled: boolean;
  previewMode: boolean;
  onChange: (value: CommunicationChannel) => void;
}

const CommunicationChannelFormItem: React.FC<CommunicationChannelFormItemProps> = ({
  communicationType,
  possibleChannels,
  selectedChannel,
  disabled,
  onChange,
  previewMode,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Grid style={{ display: 'flex', marginTop: 20 }}>
      <Typography style={{ fontSize: 14, fontWeight: 600, width: 120 }}>
        {formatMessage({ id: 'settings.communications.communicationChannel' })}
      </Typography>
      <RadioGroup
        style={{ marginLeft: 20 }}
        name={`${communicationType}.communicationChannel`}
        value={selectedChannel}
        onChange={(_e, value) => onChange(value as CommunicationChannel)}
      >
        {possibleChannels.map((possibleChannel) => (
          <CommunicationChannelRadioItem
            key={`${communicationType}_${selectedChannel}_${possibleChannel}`}
            communicationType={communicationType}
            channel={possibleChannel}
            disabled={disabled}
            previewMode={previewMode}
          />
        ))}
      </RadioGroup>
    </Grid>
  );
};

export default CommunicationChannelFormItem;
