import { clearStorage } from 'mapbox-gl';
import { isNil } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import BuildingsMap from 'src/components/ui/Graphs/BuildingsMap';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { useDashboardFilters } from 'src/hooks/DashboardFiltersContext';

import MetricBox, { MetricBoxLayoutProps } from './MetricBox';
import { MAP_BOX_KEY } from '@rentguru/commons-utils';

const DashboardMetricMap: React.FC<MetricBoxLayoutProps> = ({ forceUpdateLayout }) => {
  const { formatMessage } = useIntl();
  const { filteredBuildings } = useDashboardFilters();
  const { buildingsLoading } = useBuildings();

  const geoStatistics = filteredBuildings.reduce(
    (acc, building) => {
      if (!isNil(building.address) && !isNil(building.address.longitude) && !isNil(building.address.latitude)) {
        const lat = building.address.latitude;
        const long = building.address.longitude;
        acc.latSum += lat;
        acc.longSum += long;
        acc.buildingsSum += 1;
        if (lat > acc.latMax) {
          acc.latMax = lat;
        }
        if (lat < acc.latMin) {
          acc.latMin = lat;
        }
        if (long > acc.longMax) {
          acc.longMax = long;
        }
        if (long < acc.longMin) {
          acc.longMin = long;
        }
      }
      return acc;
    },
    { latMin: -100, latMax: 100, latSum: 0, longMin: -100, longMax: 100, longSum: 0, buildingsSum: 0 }
  );
  const centerStart: [number, number] =
    geoStatistics.buildingsSum > 0
      ? [geoStatistics.longSum / geoStatistics.buildingsSum, geoStatistics.latSum / geoStatistics.buildingsSum]
      : [4.666709, 50.640182];
  const diagDistance = Math.sqrt(
    (geoStatistics.latMax - geoStatistics.latMin) ** 2 + (geoStatistics.longMax - geoStatistics.longMin) ** 2
  );

  const zoomStart: [number] =
    geoStatistics.buildingsSum > 0
      ? diagDistance > 1000
        ? [5]
        : diagDistance > 600
        ? [6]
        : diagDistance > 300
        ? [7]
        : diagDistance > 150
        ? [8]
        : diagDistance > 75
        ? [9]
        : diagDistance > 40
        ? [10]
        : [11]
      : [7];

  return (
    <MetricBox
      boxKey={MAP_BOX_KEY}
      title={formatMessage({ id: 'dashboard.cellNames.map' })}
      onCollapseClose={() => {
        try {
          clearStorage();
        } catch (e) {
          console.error(e);
        }
      }}
      forceUpdateLayout={forceUpdateLayout}
      loading={buildingsLoading}
    >
      <BuildingsMap buildings={filteredBuildings} zoomStart={zoomStart} centerStart={centerStart} />
    </MetricBox>
  );
};

export default DashboardMetricMap;
