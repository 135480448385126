import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { type CSSProperties } from '@material-ui/styles';

export const useStyles = makeStyles({
  boxRoot: {
    width: '100%',
    height: '100%',
    paddingTop: 5,
    paddingRight: 8,
    paddingBottom: 5,
    paddingLeft: 8,
    display: 'inline',
    verticalAlign: 'middle',
    borderWidth: 'thin',
    borderStyle: 'solid',
    borderRadius: 4,
    whiteSpace: 'nowrap',
  },
});

interface ColoredBoxProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  boxColor: string;
  dataTest?: string;
  boxStyle?: CSSProperties;
}
export const ColoredBox: React.FC<ColoredBoxProps> = ({ boxColor, dataTest, children, boxStyle = {}, ...rest }) => {
  const classes = useStyles();
  return (
    <div
      style={{
        fontSize: 14,
        fontWeight: 'bold',
        color: boxColor,
      }}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="center"
        style={{
          borderColor: boxColor,
          ...boxStyle,
        }}
        className={classes.boxRoot}
        data-test={dataTest}
      >
        {children}
      </Box>
    </div>
  );
};
