import { Typography } from '@material-ui/core';
import { Colors, LeaseType } from '@rentguru/commons-utils';
import React from 'react';
import { useIntl } from 'react-intl';

interface LeaseTypeColumnProps {
  type: LeaseType | keyof typeof LeaseType;
}
const LeaseTypeColumn: React.FC<LeaseTypeColumnProps> = ({ type }) => {
  const { formatMessage } = useIntl();

  return (
    <Typography
      style={{
        fontSize: 14,
        color: Colors.SLATE_GREY,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
      }}
    >
      {formatMessage({ id: `enums.LeaseType.${type}` })}
    </Typography>
  );
};

export default LeaseTypeColumn;
