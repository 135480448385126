import { useEffect, useState } from 'react';
import { EnhanceTableRowProps, EnhanceTableShareContextValues } from './EnhancedTable';
import { EnhancedTableSorted, EnhancedTableSortedProps } from './EnhancedTableSorted';
import { EnhancedTableSharedContextConsumer } from './EnhancedTableSharedContext';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { getSorting, stableSort } from '../../utils';

const DEFAULT_TABLE_LIMIT = 50;
export const EnhancedTableScrollable = <T extends EnhanceTableRowProps>({
  datas,
  defaultOrderBy,
  columns,
  ...rest
}: Omit<EnhancedTableSortedProps<T>, 'rowCount'>) => {
  const scrollPercentageThreshold = 85;
  const [tableLimit, setTableLimit] = useState(DEFAULT_TABLE_LIMIT);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleScroll = (event: any) => {
      const body = event.target.body;
      const parent = body.parentNode;
      const scrollPercentage =
        ((body.scrollTop || parent.scrollTop) / (parent.scrollHeight - parent.clientHeight)) * 100;
      if (scrollPercentage > scrollPercentageThreshold) {
        setTableLimit(Math.min(datas.length, tableLimit + DEFAULT_TABLE_LIMIT));
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPercentageThreshold, setTableLimit, datas, tableLimit]);

  useEffect(() => {
    // Avoid to have to scroll if data is less than default table limit after some datas changes
    if (datas.length > tableLimit && tableLimit < DEFAULT_TABLE_LIMIT) {
      setTableLimit(DEFAULT_TABLE_LIMIT);
    }
    // eslint-disable-next-line
  }, [datas.length]);

  return (
    <EnhancedTableSharedContextConsumer>
      {({ order, orderBy, orderByColumnType }: EnhanceTableShareContextValues) => {
        const orderByColumn = isEmpty(orderBy) ? defaultOrderBy : orderBy;

        let orderByColumnFunction;
        columns.forEach((c) => {
          if (c.id === orderByColumn && !isNil(c.displayFunction)) {
            orderByColumnFunction = c.displayFunction;
          }
        });
        const sortedData = stableSort(
          datas,
          getSorting(order as unknown as 'asc' | 'desc', orderByColumn, orderByColumnFunction, orderByColumnType)
        );
        const filteredSortedData = sortedData.slice(0, tableLimit);
        return (
          <EnhancedTableSorted
            datas={filteredSortedData}
            defaultOrderBy={defaultOrderBy}
            columns={columns}
            rowCount={datas.length}
            {...rest}
          />
        );
      }}
    </EnhancedTableSharedContextConsumer>
  );
};
