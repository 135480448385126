import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import { TableRow, Typography, Tooltip } from '@material-ui/core';
import {
  Colors,
  formatNumber,
  formatStandardizedUTCDate,
  StatementSource,
  CustomValue,
  Statement,
} from '@rentguru/commons-utils';
import { dateLocaleMap, useLocale } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as CircleCheck } from 'src/icons/circle-check.svg';
import { ReactComponent as RecalculateIcon } from 'src/icons/recalculate.svg';
import { RouteDestination } from 'src/components/Routes/Routes';
import { CustomTableCell , CustomIconButton , CustomLoadableIconButton } from '@up2rent/ui';
import { ROW_HEIGHT } from 'src/components/Dashboard/DashboardTodos/DashboardTenantStatements';
import TenantStatementsToValidateAmountPopper from './TenantStatementsToValidateAmountPopper';
import MoreHorizontalIconButton from 'src/components/ui/CustomIconButtons/MoreHorizontalIconButton';

interface TenantStatementsToValidateRowProps extends Statement {
  tenantsNames: string;
  unitsNames: string;
  totalAmount: number | undefined;
  amounts: CustomValue[];
  isSelected: boolean;
  actionMenu: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    e: any,
    id: string,
    type: 'MORE' | 'RECALCULATE' | 'VALIDATE',
    afterSubmit?: () => void
  ) => Promise<void>;
}

const TenantStatementsToValidateRow: React.FC<TenantStatementsToValidateRowProps> = ({
  id,
  periodFrom,
  periodTo,
  tenantsNames,
  unitsNames,
  source,
  amounts,
  totalAmount,
  actionMenu,
  isSelected,
}) => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const [calculationLoading, setCalculationLoading] = useState<boolean>(false);
  const [amountDescriptionAnchorEl, setAmountDescriptionAnchorEl] = useState<HTMLElement | null>(null);

  const closeAmountDescriptionPopper = () => {
    setAmountDescriptionAnchorEl(null);
  };

  const amountColor = !isNil(totalAmount) && totalAmount < 0 ? Colors.CARNATION_RED : Colors.TURQUOISE_BLUE;

  return (
    <TableRow
      hover
      onClick={() => {
        if (calculationLoading) return;
        history.push({
          pathname: RouteDestination.VALIDATE_TENANT_STATEMENT,
          state: { statementId: id },
        });
      }}
      aria-checked={isSelected}
      tabIndex={-1}
      key={id}
      selected={isSelected}
      style={{
        paddingTop: 10,
        cursor: calculationLoading ? 'default' : 'pointer',
        minHeight: ROW_HEIGHT,
        height: ROW_HEIGHT,
      }}
    >
      <CustomTableCell scope="row" padding="none" style={{ paddingLeft: 20 }}>
        <Typography style={{ fontSize: 14, color: Colors.SLATE_GREY, display: 'inline' }}>
          {formatStandardizedUTCDate(new Date(periodFrom), 'dd MMMM yyyy', { locale: dateLocaleMap[language] })}
        </Typography>
        <Typography
          style={{ fontSize: 14, color: Colors.SLATE_GREY, display: 'inline', marginLeft: 5, marginRight: 5 }}
        >
          {'\u2192'}
        </Typography>
        <Typography style={{ fontSize: 14, color: Colors.SLATE_GREY, display: 'inline', textAlign: 'right' }}>
          {formatStandardizedUTCDate(new Date(periodTo), 'dd MMMM yyyy', { locale: dateLocaleMap[language] })}
        </Typography>
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        <Typography style={{ color: Colors.CLASSICAL_BLACK, fontSize: 14, fontWeight: 600 }}>{tenantsNames}</Typography>
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        <Typography style={{ color: Colors.CLASSICAL_BLACK, fontSize: 14 }}>{unitsNames}</Typography>
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14, lineHeight: '15px' }}>
          {source === StatementSource.AUTOMATIC
            ? formatMessage({ id: 'settings.communications.automatic' })
            : formatMessage({ id: 'settings.communications.manual' })}
        </Typography>
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        {!isNil(totalAmount) && (
          <div
            style={{
              borderRadius: 4,
              border: `1px solid ${amountColor}`,
              color: amountColor,
              fontWeight: 'bold',
              height: 30,
              marginRight: 20,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: '100px',
              overflow: 'hidden',
            }}
            onMouseEnter={(e) => {
              setAmountDescriptionAnchorEl(e.currentTarget);
            }}
            onMouseLeave={closeAmountDescriptionPopper}
          >
            {formatNumber(totalAmount, language, { style: 'currency', currency: 'EUR' })}
          </div>
        )}
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 20, alignItems: 'center' }}>
          <Tooltip title={formatMessage({ id: 'accounting.statement.toValidateSection.recalculate' })}>
            <CustomLoadableIconButton
              isLoading={calculationLoading}
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                setCalculationLoading(true);
                await actionMenu(e, id, 'RECALCULATE', () => setCalculationLoading(false));
              }}
              iconStyle={{ fill: Colors.BLUEY }}
              Icon={RecalculateIcon}
            />
          </Tooltip>
          <Tooltip title={formatMessage({ id: 'transactions.list.validate' })}>
            <CustomIconButton
              Icon={CircleCheck}
              iconStyle={{ fill: Colors.DODGER_BLUE }}
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                await actionMenu(e, id, 'VALIDATE');
              }}
            />
          </Tooltip>
          <MoreHorizontalIconButton
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              await actionMenu(e, id, 'MORE');
            }}
          />
        </div>
      </CustomTableCell>
      {amountDescriptionAnchorEl && (
        <TenantStatementsToValidateAmountPopper
          statementAmounts={amounts}
          anchorEl={amountDescriptionAnchorEl}
          open={!isNil(amountDescriptionAnchorEl)}
          handleClose={closeAmountDescriptionPopper}
        />
      )}
    </TableRow>
  );
};

export default TenantStatementsToValidateRow;
