import { TableCell, TableRow, Typography } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import { useIntl } from 'react-intl';

const CommunicationsHeader = () => {
  const { formatMessage } = useIntl();
  const headerPadding = { paddingLeft: 0, paddingBottom: 10, paddingRight: 0, paddingTop: 10 };
  return (
    <TableRow>
      <TableCell align="left" style={{ ...headerPadding, paddingLeft: 20 }}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'templates.columnHeader.type' })}
        </Typography>
      </TableCell>
      <TableCell align="left" style={headerPadding}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'communications.list.recipient' })}
        </Typography>
      </TableCell>
      <TableCell align="left" style={headerPadding}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'rentalUnit.detail.general.building' })}
        </Typography>
      </TableCell>
      <TableCell align="left" style={headerPadding}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'templates.tags.categories.rentalUnit' })}
        </Typography>
      </TableCell>
      <TableCell align="left" />
      <TableCell align="left" />
      <TableCell align="left" />
    </TableRow>
  );
};

export default CommunicationsHeader;
