import { useEffect, useRef, useState } from 'react';
import {
  Valuation,
  syncValuations,
  createValuation as createMutation,
  updateValuation as updateMutation,
  deleteValuation as deleteMutation,
  CreateValuationMutationVariables,
  DeleteValuationMutationVariables,
  CreateValuationInput,
  UpdateValuationInput,
  cleanInputUpdate,
  cleanInputCreate,
  getReadId,
  getTableClientId,
} from '@rentguru/commons-utils';
import { deleteAndHideEntity, list, mutation } from '@up2rent/fetch-utils';
import { useUser } from './UserContext';
import isNil from 'lodash/isNil';
import { usePermissions } from './utils/PermissionsContext';

export interface ValuationContext {
  valuations: Valuation[];
  createValuation: (
    input: CreateValuationInput | Omit<CreateValuationInput, 'clientId' | 'readId'>
  ) => Promise<Valuation>;
  updateValuation: (updates: UpdateValuationInput) => Promise<Valuation>;
  deleteValuation: (valuation: Valuation) => Promise<Valuation>;
  getUnitValuations: (unitId: string) => Valuation[];
  getBuildingValuations: (buildingId: string) => Valuation[];
  loading: boolean;
}

export const useValuations = (shouldFetchValuations: boolean = false): ValuationContext => {
  const [loading, setLoading] = useState<boolean>(false);
  const [valuations, setValuations] = useState<Valuation[]>([]);
  const firstRender = useRef<boolean>(false);
  const { clientId, isOwner, userId } = useUser();
  const { financialValuationsAndCostsDelete } = usePermissions();

  const fetchValuations = async (): Promise<Valuation[]> => {
    return await list<Valuation>(syncValuations, 'clientId', getTableClientId(clientId!, 'Valuation'));
  };

  useEffect(() => {
    const buildValuations = async () => {
      setLoading(true);
      firstRender.current = true;
      const result = await fetchValuations();
      setValuations(result);
      setLoading(false);
    };
    if (shouldFetchValuations && !firstRender.current) buildValuations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchValuations, firstRender]);

  const getUnitValuations = (unitId: string) => {
    if (isNil(valuations)) {
      return [];
    }
    return (valuations as Valuation[]).filter((v) => v.unit && v.unit.id === unitId);
  };
  const getBuildingValuations = (buildingId: string) => {
    if (isNil(valuations)) {
      return [];
    }
    return (valuations as Valuation[]).filter((v) => v.building && v.building.id === buildingId);
  };

  const createValuation = async (
    input: CreateValuationInput | Omit<CreateValuationInput, 'clientId' | 'readId'>
  ): Promise<Valuation> => {
    const valuation = await mutation<Valuation, CreateValuationMutationVariables>(createMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateValuationInput),
        readId: getReadId(clientId!, 'Valuation'),
        clientId: getTableClientId(clientId!, 'Valuation'),
        ...(isOwner && { writers: [userId!] }),
      },
    });
    setValuations((v) => [...v, valuation]);
    return valuation;
  };

  const updateValuation = async (updates: UpdateValuationInput): Promise<Valuation> => {
    const updatedValuation = await mutation<Valuation>(updateMutation, {
      input: cleanInputUpdate(updates, false),
    });
    setValuations((vs) =>
      vs.map((v) => {
        if (v.id === updatedValuation.id) return updatedValuation;
        return v;
      })
    );
    return updatedValuation;
  };

  const deleteValuation = async (valuation: Valuation): Promise<Valuation> => {
    if (!financialValuationsAndCostsDelete) {
      return valuation;
    }
    const result = await deleteAndHideEntity<Valuation, DeleteValuationMutationVariables>(
      valuation,
      deleteMutation,
      updateMutation,
      undefined,
      { input: { writers: [] } }
    );
    setValuations(valuations.filter((v) => v.id !== result.id));
    return result;
  };

  return {
    valuations,
    createValuation,
    updateValuation,
    deleteValuation,
    getUnitValuations,
    getBuildingValuations,
    loading,
  };
};
