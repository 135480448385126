import React from 'react';
import { makeStyles } from '@material-ui/styles';
import CustomizedSelect, { CustomizedSelectProps } from '../../../../ui/CustomizedSelect';
import { Colors } from '@rentguru/commons-utils';

const useLocalStyles = makeStyles({
  overLap: {
    backgroundColor: 'transparent',
    opacity: '1',
    // width: '280px',
    height: '47px',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    pointerEvents: 'none',
    borderRadius: 6,
    overflow: 'hidden',
  },
  coloredBanner: {
    width: 10,
    height: 30,
    transform: 'rotate(45deg)',
    position: 'absolute',
    backgroundColor: Colors.TURQUOISE_BLUE,
    top: -14,
  },
});

interface CustomizedSelectPropsWithImportance extends CustomizedSelectProps {
  importance?: 'mandatory' | 'recommended' | 'normal';
  id?: string;
  children?: React.ReactNode;
}

const CustomizedSelectWithImportance: React.FC<CustomizedSelectPropsWithImportance> = ({
  children,
  error,
  label,
  fieldName,
  FormControlProps = {},
  SelectProps = {},
  value,
  disabled = false,
  className,
  margin = 'dense',
  importance = 'normal',
  id,
}) => {
  const localClasses = useLocalStyles();

  let bannerColor = '';
  if (importance === 'recommended') {
    bannerColor = Colors.TURQUOISE_BLUE;
  } else if (importance === 'mandatory') {
    bannerColor = Colors.CARNATION_RED;
  } else {
    bannerColor = 'transparent';
  }

  const customStyles = {
    width: 280, // Default width value.
    marginTop: 8, // Default margin top value. Will keep the banner in place if no margin value is given
  };

  return (
    <div style={{ position: 'relative' }} className={className}>
      <CustomizedSelect
        id={id}
        // eslint-disable-next-line react/no-children-prop
        children={children}
        error={error}
        label={label}
        fieldName={fieldName}
        FormControlProps={FormControlProps}
        SelectProps={SelectProps}
        value={value}
        disabled={disabled}
        className={className}
        margin={margin}
      />
      <div className={localClasses.overLap} style={customStyles}>
        <div className={localClasses.coloredBanner} style={{ backgroundColor: bannerColor }} />
      </div>
    </div>
  );
};

export default CustomizedSelectWithImportance;
