import { Dialog, Divider, Grid, Menu, MenuItem, Typography } from '@material-ui/core';
import { Building, Colors, Team } from '@rentguru/commons-utils';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';
import { ActionButtonSecond, actionButtonSecondStyles, TablePlaceHolder, CustomPopover , MenuItemText } from '@up2rent/ui';
import DropDownIconButton from 'src/components/ui/CustomIconButtons/DropDownIconButton';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { ReactComponent as HomeSvg } from 'src/icons/home.svg';
import SwitchBuildingTeamForm from './SwitchBuildingTeamForm';

interface TeamBuildingsRowProps {
  building: Building;
  deleteFunction: (b: Building) => void;
  divider?: boolean;
}

const TeamBuildingsRow: React.FC<TeamBuildingsRowProps> = ({ building, deleteFunction, divider }) => {
  const { formatMessage } = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  return (
    <>
      <Grid container style={{ marginTop: 15, marginBottom: 15, alignItems: 'center' }}>
        <Grid item xs={4}>
          <Typography style={{ fontWeight: 600, fontSize: 14 }}>
            {!isNil(building.address) ? `${building.address.street} ${building.address?.number}` : ''}
          </Typography>
        </Grid>
        <Grid item xs={2} style={{ color: Colors.SLATE_GREY, fontSize: 14 }}>
          <Typography>{!isNil(building.address) ? building.address.city : ''}</Typography>
        </Grid>
        <Grid item xs={3} style={{ color: Colors.SLATE_GREY, fontSize: 14, display: 'flex', justifyContent: 'center' }}>
          <Typography>{building.name}</Typography>
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            color: Colors.SLATE_GREY,
            fontSize: 14,
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: 20,
          }}
        >
          <HomeSvg style={{ width: 14, height: 14, fill: Colors.TOWER_GREY, marginRight: 10 }} />
          <Typography>{building.units?.length}</Typography>
        </Grid>
        <Grid item xs={1}>
          <DropDownIconButton onClick={(event) => setAnchorEl(event.currentTarget)} />
        </Grid>
        <Menu
          id="action-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          style={{ transform: 'translateY(40px)' }}
        >
          <MenuItem
            onClick={() => {
              deleteFunction(building);
              setAnchorEl(null);
            }}
          >
            <MenuItemText primary={formatMessage({ id: 'settings.editTeam.moveBuildingToTeam' })} />
          </MenuItem>
        </Menu>
      </Grid>
      {divider && <Divider />}
    </>
  );
};

interface TeamBuildingsProps {
  team: Team;
}

const TeamBuildings: React.FC<TeamBuildingsProps> = ({ team }) => {
  const actionButtonClasses = actionButtonSecondStyles();
  const { buildings } = useBuildings();
  const [buildingDialog, setBuildingDialog] = useState<Building | null>(null);
  const history = useHistory();
  const [anchorE1Second, setAnchorE1Second] = useState<null | HTMLElement>(null);
  const { formatMessage } = useIntl();
  const addInfoMenu = [
    {
      name: formatMessage({ id: 'settings.addTeam.addBuildingToTeam' }),
      onClick: () => {
        history.push({
          pathname: RouteDestination.ADD_PROPERTY,
          state: { teamId: team.id },
        });
      },
    },
  ];
  const teamBuildings = buildings.filter((b) => !isNil(b.teams) && b.teams.includes(team.id));
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30 }}>
        <Typography variant="h6">{formatMessage({ id: 'menu.buildings' })}</Typography>
        <ActionButtonSecond
          className={actionButtonClasses.buttonWithMargin}
          onClick={(event) => setAnchorE1Second(event.currentTarget)}
        >
          {formatMessage({ id: 'add' })}
        </ActionButtonSecond>
        <CustomPopover
          id={team.id}
          open={Boolean(anchorE1Second)}
          anchorEl={anchorE1Second}
          onClose={() => {
            setAnchorE1Second(null);
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          paperPadding={0}
          paperWidth={'unset'}
          enablePointerEvents
        >
          {addInfoMenu.map((menu) => {
            return (
              <MenuItem
                key={menu.name}
                onClick={() => {
                  menu.onClick();
                  setAnchorE1Second(null);
                }}
              >
                <MenuItemText primary={menu.name} />
              </MenuItem>
            );
          })}
        </CustomPopover>
      </div>
      {isEmpty(teamBuildings) && (
        <TablePlaceHolder mainText={formatMessage({ id: 'settings.editTeam.noBuildingPlaceHolder' })} subText="" />
      )}
      {teamBuildings.map((b, index) => {
        return (
          <TeamBuildingsRow
            key={b.id}
            building={b}
            deleteFunction={(building: Building) => setBuildingDialog(building)}
            divider={index !== teamBuildings.length - 1}
          />
        );
      })}
      {!isNil(buildingDialog) && (
        <Dialog
          PaperProps={{ style: { maxWidth: 'none', borderRadius: 15 } }}
          open={!isNil(buildingDialog)}
          onClose={() => setBuildingDialog(null)}
          scroll="body"
        >
          <SwitchBuildingTeamForm building={buildingDialog!} onClose={() => setBuildingDialog(null)} />
        </Dialog>
      )}
    </>
  );
};

export default TeamBuildings;
