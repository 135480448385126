import { Grid } from '@material-ui/core';
import {
  endOfUTCDay,
  getOverlappingEvents,
  getUnitEvents,
  getUTCDate,
  startOfUTCDay,
  BuildingEvent,
  Unit,
  UnitEvent,
  UnitLease,
} from '@rentguru/commons-utils';
import { useFormikContext } from 'formik';
import { isNil, minBy } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { AddLeaseFormValues } from 'src/components/Leases/AddLease/AddLeaseForm';
import { useUnits } from 'src/hooks/UnitsContext';
import { getDetailedOverlappingError } from 'src/utils/unitsUtils';
import CustomDatePicker from '../../CustomDatePicker';
import { dateLocaleMap, dateLocaleMapType, useLocale } from '../../../../i18n/IntlProviderWrapper';
import FormAlertBox from '../../FormAlertBox';
import * as Yup from 'yup';
import { resolveMainUnitAndSubUnitIndexAndPrefix } from 'src/components/Leases/AddLease/useAddEditLeaseUtils';
import { areIntervalsOverlapping, isValid, parseISO, subMinutes } from 'date-fns';

interface KeyDatesProps {
  unitId: string;
}

type OverlappingEvent =
  | UnitLease
  | UnitEvent
  | BuildingEvent
  | {
      startDate: string;
      endDate: string;
    };

export interface KeyDatesFieldValues {
  startDate: string | Date;
  endDate: string | Date;
  signatureDate: string | Date;
}

export const KeyDatesSchema = Yup.object().shape({
  startDate: Yup.date().nullable(),
  endDate: Yup.date().nullable(),
  signatureDate: Yup.date().nullable(),
});

const getFirstOverlappingEvent = (
  values: AddLeaseFormValues,
  unitId: string,
  getUnit: (id: string) => Unit | undefined
): OverlappingEvent | undefined => {
  const { isMainUnit, subUnitIndex } = resolveMainUnitAndSubUnitIndexAndPrefix(values, unitId);

  const isUnitIdAndEndDatePresent = isMainUnit
    ? !isNil(values.unit.id) && !isNil(values.endDate)
    : subUnitIndex > -1 && !isNil(values.subUnits[subUnitIndex].id) && !isNil(values.subUnits[subUnitIndex].endDate);

  const startDateValue = getDateValue(values, 'startDate', unitId);
  const endDateValue = getDateValue(values, 'endDate', unitId);

  if (!startDateValue || !endDateValue || !isValid(startDateValue) || !isValid(endDateValue)) {
    return undefined;
  }

  const overlappingEventsForUnit: OverlappingEvent[] = isUnitIdAndEndDatePresent
    ? getOverlappingEvents(
        startDateValue.toISOString(),
        endDateValue.toISOString(),
        getUnitEvents(isMainUnit ? values.unit.id! : values.subUnits[subUnitIndex].id!, getUnit)
      )
    : [];
  const overlappingNonEditedEvent = minBy(overlappingEventsForUnit, 'startDate');
  return overlappingNonEditedEvent;
};

export const areLeaseDatesOverlappingWithEventsOrOtherLease = (
  values: AddLeaseFormValues,
  unitId: string,
  getUnit: (id: string) => Unit | undefined
) => {
  const overlappingNonEditedEvent = getFirstOverlappingEvent(values, unitId, getUnit);

  if (!overlappingNonEditedEvent) {
    return false;
  }

  const startDateValue = getDateValue(values, 'startDate', unitId);
  const endDateValue = getDateValue(values, 'endDate', unitId);

  if (!startDateValue || !endDateValue) {
    return false;
  }

  const dateOverlappingProblem =
    !isNil(overlappingNonEditedEvent) &&
    areIntervalsOverlapping(
      { start: startDateValue, end: endDateValue },
      { start: parseISO(overlappingNonEditedEvent.startDate), end: parseISO(overlappingNonEditedEvent.endDate) }
    );

  return dateOverlappingProblem;
};

export const getDateValue = (
  values: AddLeaseFormValues,
  fieldName: keyof Pick<AddLeaseFormValues, 'startDate' | 'endDate' | 'signatureDate'>,
  unitId: string
) => {
  const { isMainUnit, subUnitIndex } = resolveMainUnitAndSubUnitIndexAndPrefix(values, unitId);

  if (isMainUnit) {
    return values[fieldName] ? getUTCDate(new Date(values[fieldName] ?? '')) : null;
  }

  return values.subUnits[subUnitIndex][fieldName] ? new Date(values.subUnits[subUnitIndex][fieldName]) : null;
};

const KeyDatesFields: React.FC<KeyDatesProps> = ({ unitId }) => {
  const { formatMessage } = useIntl();
  const { values, setFieldValue, errors, touched } = useFormikContext<AddLeaseFormValues>();
  const { getUnit } = useUnits();
  const { language } = useLocale();

  const { isMainUnit, subUnitIndex } = resolveMainUnitAndSubUnitIndexAndPrefix(values, unitId);

  const overlappingNonEditedEvent = getFirstOverlappingEvent(values, unitId, getUnit);
  if (!isMainUnit && subUnitIndex === -1) {
    return null;
  }

  const startDateValue = getUTCDate(getDateValue(values, 'startDate', unitId));
  const endDateValue = getUTCDate(getDateValue(values, 'endDate', unitId));
  const signatureDate = getUTCDate(getDateValue(values, 'signatureDate', unitId));

  const dateOverlappingProblem = areLeaseDatesOverlappingWithEventsOrOtherLease(values, unitId, getUnit);

  const mainUnitStartDate = values.startDate ? getUTCDate(new Date(values.startDate)) : undefined;
  // We are removing 5 minutes to avoid conflict if dates are equals (maxDate will raise error in this case)
  const mainUnitEndDate = values.endDate ? subMinutes(getUTCDate(new Date(values.endDate)), 5) : undefined;
  const mainUnitSignatureDate = values.signatureDate ? getUTCDate(new Date(values.signatureDate)) : mainUnitStartDate;

  return (
    <>
      <Grid>
        <CustomDatePicker
          name={isMainUnit ? `startDate` : `subUnits[${subUnitIndex}].startDate`}
          label={formatMessage({ id: 'lease.addLease.startDate' })}
          value={isMainUnit && mainUnitStartDate ? mainUnitStartDate : startDateValue}
          style={{ width: 280, marginRight: 20 }}
          onChange={(date) => {
            setFieldValue(`subUnits[${subUnitIndex}].startDate`, date ? startOfUTCDay(date) : null);
          }}
          safeDateFormat={true}
          onClear={() => setFieldValue('startDate', null)}
          error={Boolean((errors.startDate && touched.startDate) || dateOverlappingProblem)}
          maxDate={mainUnitEndDate}
          minDate={mainUnitStartDate}
          disabled={isMainUnit}
        />
        <CustomDatePicker
          name={isMainUnit ? `endDate` : `subUnits[${subUnitIndex}].endDate`}
          label={formatMessage({ id: 'lease.addLease.endDate' })}
          value={isMainUnit && mainUnitEndDate ? mainUnitEndDate : endDateValue}
          style={{ width: 280 }}
          onChange={(date) => {
            setFieldValue(`subUnits[${subUnitIndex}].endDate`, date ? endOfUTCDay(date) : null);
          }}
          safeDateFormat={true}
          error={Boolean((errors.endDate && touched.endDate) || dateOverlappingProblem)}
          maxDate={mainUnitEndDate}
          minDate={mainUnitStartDate}
          disabled={isMainUnit}
        />
      </Grid>
      <Grid style={{ textAlign: 'left', marginLeft: 30 }}>
        <CustomDatePicker
          name={isMainUnit ? `signatureDate` : `subUnits[${subUnitIndex}].signatureDate`}
          label={formatMessage({ id: 'lease.detail.general.signatureDate' })}
          value={isMainUnit && mainUnitSignatureDate ? mainUnitSignatureDate : signatureDate}
          format="dd/MM/yyyy"
          style={{ width: 280, marginRight: 20 }}
          onChange={(date) => {
            setFieldValue(`subUnits[${subUnitIndex}].signatureDate`, date ? startOfUTCDay(date) : null);
          }}
          margin="dense"
          disabled={isMainUnit}
          maxDate={mainUnitEndDate}
          minDate={mainUnitStartDate}
        />
      </Grid>
      <Grid style={{ width: '100%' }}>
        {dateOverlappingProblem && overlappingNonEditedEvent && (
          <FormAlertBox
            message1={formatMessage({
              id: 'event.overlapping',
            })}
            message2={getDetailedOverlappingError(
              overlappingNonEditedEvent,
              formatMessage,
              (dateLocaleMap as dateLocaleMapType)[language]
            )}
          />
        )}
      </Grid>
    </>
  );
};

export default KeyDatesFields;
