import { Divider, Grid, Typography } from '@material-ui/core';
import { toUpper } from 'lodash';
import React from 'react';
import { IntlFormatters, useIntl } from 'react-intl';
import { ActionButton , ContentHeader, LoaderButton } from '@up2rent/ui';
import IconnedRemark from 'src/components/ui/IconnedRemark';
import * as Yup from 'yup';
import { ReactComponent as Info } from '../../../icons/info.svg';
import { useSendingSources } from 'src/hooks/SendingSourceContext';
import { Form, Formik, FormikHelpers } from 'formik';
import { SendingSource, Colors, ModelWithVersion } from '@rentguru/commons-utils';
import TextDetailEditable from 'src/components/ui/TextDetailEditable';

interface EditDomainDialogProps {
  id: string;
  closeAction: () => void;
}

interface EditDomainValues {
  emailAddress: string;
}

const EditDomainSchema = (formatMessage: IntlFormatters['formatMessage']) =>
  Yup.object().shape({
    emailAddress: Yup.string()
      .email(formatMessage({ id: 'settings.sendingSources.invalidEmail' }))
      .required(formatMessage({ id: 'mandatoryField' })),
  });

const fitEmailToDomain = (emailAddress: string, domainName: string): string => {
  const index = emailAddress.indexOf('@');
  if (index < 0) {
    // the @ has been deleted
    return emailAddress.replace(domainName, `@${domainName}`);
  }
  if (!emailAddress.endsWith(domainName) || index !== emailAddress.length - domainName.length - 1) {
    // the domain name has been modified or a second @ has been inserted

    return `${emailAddress.slice(0, index)}@${domainName}`;
  }
  return emailAddress;
};

const EditDomainDialog: React.FC<EditDomainDialogProps> = ({ id, closeAction }) => {
  const { formatMessage } = useIntl();
  const { getSendingSource, updateSendingSource } = useSendingSources();

  const sendingSource = getSendingSource(id)!;

  const handleEdit = async (
    values: EditDomainValues,
    { setSubmitting, setStatus }: FormikHelpers<EditDomainValues>
  ) => {
    const updatedSendingSource = await updateSendingSource({
      id,
      emailAddress: values.emailAddress,
      _version: (sendingSource as ModelWithVersion<SendingSource>)._version,
    });
    setStatus(true);
    setSubmitting(false);

    if (updatedSendingSource) closeAction();
  };

  const initialValues: EditDomainValues = {
    emailAddress: sendingSource.emailAddress,
  };

  return (
    <>
      <ContentHeader title={formatMessage({ id: 'settings.sendingSources.editTitle' })} />

      <Divider style={{ marginBottom: 20 }} />

      <IconnedRemark
        style={{ marginLeft: 30, marginRight: 30 }}
        noFixWidth
        Icon={Info}
        message={
          <Grid style={{ textAlign: 'left' }}>
            <Typography>
              {formatMessage({ id: 'settings.sendingSources.editInfo1' })}
              <b>{sendingSource.name}</b>
              {formatMessage({ id: 'settings.sendingSources.editInfo2' })}
            </Typography>
          </Grid>
        }
      />
      <Formik initialValues={initialValues} validationSchema={EditDomainSchema(formatMessage)} onSubmit={handleEdit}>
        {({ values, errors, touched, isSubmitting, status, setFieldValue }) => (
          <Form>
            <TextDetailEditable
              style={{ marginLeft: 30, marginRight: 30, maxWidth: 540 }}
              title={formatMessage({
                id: 'settings.sendingSources.sendingEmail',
              })}
              editMode={true}
              name="emailAddress"
              type="text"
              noMaxWidth
              inputProps={{
                onFocus: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  event.target.setSelectionRange(0, values.emailAddress.indexOf('@'));
                },
              }}
              helperText={errors.emailAddress && touched.emailAddress ? errors.emailAddress : ''}
              error={Boolean(errors.emailAddress && touched.emailAddress)}
              onChangeObserver={(value) => {
                setFieldValue('emailAddress', fitEmailToDomain(value, sendingSource.name));
              }}
            />

            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <Grid
              style={{
                marginRight: 30,
                marginBottom: 20,
                display: 'flex',
                float: 'right',
                alignItems: 'center',
              }}
            >
              <ActionButton
                onClick={closeAction}
                style={{
                  background: 'none',
                  color: Colors.DARK_SLATE_GREY,
                  marginRight: 20,
                }}
              >
                {toUpper(
                  formatMessage({
                    id: 'cancel',
                  })
                )}
              </ActionButton>
              <LoaderButton loading={isSubmitting} success={status}>
                {formatMessage({
                  id: 'save',
                })}
              </LoaderButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EditDomainDialog;
