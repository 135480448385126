import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';

/* Style */
import './css/index.css';
import WebFontLoader from 'webfontloader';

/* Router */
import { BrowserRouter } from 'react-router-dom';

/* Bug Tracking */
import * as Sentry from '@sentry/react';
import ErrorBoundary from './ErrorBoundary';

/* i18n */
import { LocaleProvider } from './i18n/IntlProviderWrapper';

/* AWS */
import { Amplify } from 'aws-amplify';
import amplifyConfiguration from './aws-exports-oauth';
import { fetchAuthSession } from 'aws-amplify/auth';

import { UserProvider } from './hooks/UserContext';
import EntityConditionalHooks from './hooks/EntityConditionalHooks';
import { FiltersContextProvider } from './hooks/FiltersContext';

/* Style */
import { ThemeProvider } from '@material-ui/styles';
import { getUp2rentTheme } from '@up2rent/ui';

/* SnackBar */
import { SnackbarProvider } from 'notistack';

// eslint-disable-next-line import/extensions
import 'src/components/ui/CustomAudioPlayer.css';
// eslint-disable-next-line import/extensions
import 'src/components/RentalUnits/Details/Publication/BlinkingArrow.css';

/* PDF */
import * as pdfjsLib from 'pdfjs-dist/build/pdf';

WebFontLoader.load({
  google: {
    families: ['Mulish:300,400,500,600,700,800,900', 'Material Icons'],
  },
});

/* Bug Tracking */
const IS_DEVELOP = process.env.NODE_ENV === 'development';
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
if (!IS_DEVELOP) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [Sentry.replayIntegration()],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: 'up2rent_release_name',
  });
}

/* AWS */
Amplify.configure(amplifyConfiguration, {
  API: {
    GraphQL: {
      headers: async () => {
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        return {
          Authorization: idToken?.toString(),
        };
      },
    },
  },
});

/* PDF */
pdfjsLib.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <ErrorBoundary>
    <ThemeProvider theme={getUp2rentTheme()}>
      {/* @ts-ignore */}
      <BrowserRouter>
        <SnackbarProvider maxSnack={10} autoHideDuration={3000}>
          <UserProvider>
            <FiltersContextProvider>
              <EntityConditionalHooks>
                <LocaleProvider>
                  <App />
                </LocaleProvider>
              </EntityConditionalHooks>
            </FiltersContextProvider>
          </UserProvider>
        </SnackbarProvider>
      </BrowserRouter>
    </ThemeProvider>
  </ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
