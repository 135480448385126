import { getContactNameFromObject, User } from '@rentguru/commons-utils';
import { isNil } from 'lodash';

export const isInvitePending = (user: User) => {
  return isNil(user.cognitoSub) && isNil(user.facebookSub) && isNil(user.googleSub);
};

export const getDisplayableUserName = (user: User) => {
  return isInvitePending(user)
    ? `${user.email}`
    : user.contact
    ? getContactNameFromObject(user.contact)
    : `${user.firstName} ${user.lastName}`;
};
