/* eslint-disable react/no-array-index-key */
import { List, ListItem, makeStyles, Typography } from '@material-ui/core';
import {
  GenerateReportType,
  CustomSubscription,
  SendingSourceVerifiedType,
  IntegrationName,
  Colors,
  SendingSourceMessageType,
  IntegrationNotificationMessageType,
  CommunicationStatus,
  CustomSubscriptionType,
} from '@rentguru/commons-utils';
import { isEmpty, orderBy } from 'lodash';
import { useIntl } from 'react-intl';
import { useCustomSubscriptions } from 'src/hooks/CustomSubscriptionContext';
import NotificationsReportItem from './NotificationsReportItem';
import NotificationsSignatureDocumentItem from './NotificationsSignatureDocumentItem';
import NotificationsSendingSourceItem from './NotificationsSendingSourceItem';
import { getFilteredSubscription } from './AccountListItem';
import NotificationsIntegrationItem from './NotificationsIntegrationItem';
import { NotificationsTacitRenewalItem } from './NotificationsTacitRenewalItem';
import NotificationsBlockedCommunicationsItem from './NotificationsBlockedCommunicationsItem';
import NotificationsCreditsItem, { NotificationsCreditsItemProps } from './NotificationsCreditsItem';
import { NotificationsOcrItem } from './NotificationsOcrItem';
import {
  NotificationsEntityDeletionItem,
  NotificationsEntityDeletionItemProps,
} from './NotificationsEntityDeletionItem';

export const useNotificationListStyle = makeStyles({
  listItem: {
    backgroundColor: Colors.PORCELAIN_GREY_3,
    borderRadius: 8,
    marginBottom: 10,
    padding: '20px 15px',
  },
});

const ocrTypes = [CustomSubscriptionType.OCR_FAILURE, CustomSubscriptionType.OCR_SUCCESS];

const NotificationsList = () => {
  const { formatMessage } = useIntl();
  const localStyles = useNotificationListStyle();
  const { customSubscriptions = [] } = useCustomSubscriptions();

  // Filter for subscriptions
  const filteredSubscriptions = getFilteredSubscription(customSubscriptions);
  if (isEmpty(filteredSubscriptions)) {
    return (
      <List>
        <ListItem
          key="NothingToShow"
          className={localStyles.listItem}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Typography>{formatMessage({ id: `accountMenu.nothingToShow` })}</Typography>
        </ListItem>
      </List>
    );
  }

  const reversedArray = orderBy(filteredSubscriptions, ['createdAt'], ['desc']) as CustomSubscription[];
  return (
    <List>
      {reversedArray.map((customSubscription, index) => {
        if (customSubscription.name === CustomSubscriptionType.REPORT) {
          const reportResult: { reportUrl: string; reportType: GenerateReportType; fileName: string } = JSON.parse(
            customSubscription.value!
          );

          return (
            <NotificationsReportItem
              key={`${index}_${reportResult.reportType}`}
              {...reportResult}
              customSubscription={customSubscription}
            />
          );
        }
        if (customSubscription.name === CustomSubscriptionType.SIGNATURE_DOCUMENT) {
          const signatureDocumentNotification: {
            signatureDocumentId: string;
            signatureUrl?: string;
            hasError: boolean;
            leaseId?: string;
          } = JSON.parse(customSubscription.value!);

          return (
            <NotificationsSignatureDocumentItem
              key={`${index}_${signatureDocumentNotification.signatureDocumentId}`}
              customSubscription={customSubscription}
              signatureUrl={signatureDocumentNotification.signatureUrl}
              leaseId={signatureDocumentNotification.leaseId}
              signatureDocumentId={signatureDocumentNotification.signatureDocumentId}
              hasError={signatureDocumentNotification.hasError}
            />
          );
        }
        if (customSubscription.name === CustomSubscriptionType.SENDING_SOURCE) {
          const sendingSourceResult: {
            name: string;
            emailAddress: string;
            verifiedType: SendingSourceVerifiedType;
            notificationType: SendingSourceMessageType;
          } = JSON.parse(customSubscription.value!);

          return (
            <NotificationsSendingSourceItem
              key={`${index}_${sendingSourceResult.name}`}
              {...sendingSourceResult}
              customSubscription={customSubscription}
            />
          );
        }
        if (customSubscription.name === CustomSubscriptionType.BLOCKED_COMMUNICATIONS) {
          const blockedCommunicationsResult: {
            notificationType: CommunicationStatus;
          } = JSON.parse(customSubscription.value!);

          return (
            <NotificationsBlockedCommunicationsItem
              key={`${index}_blocked_communication`}
              {...blockedCommunicationsResult}
              customSubscription={customSubscription}
            />
          );
        }
        if (customSubscription.name === CustomSubscriptionType.INTEGRATION) {
          const result: {
            name: IntegrationName;
            id: string;
            newBankAccountId?: string;
            type: IntegrationNotificationMessageType;
          } = JSON.parse(customSubscription.value!);

          return (
            <NotificationsIntegrationItem
              key={`${index}_${result.name}`}
              {...result}
              customSubscription={customSubscription}
            />
          );
        }
        if (customSubscription.name === CustomSubscriptionType.TACIT_RENEWAL) {
          const result: {
            name: IntegrationName;
            id: string;
            leaseIds: string[];
          } = JSON.parse(customSubscription.value!);

          return (
            <NotificationsTacitRenewalItem
              key={`${index}_${result.name}`}
              customSubscription={customSubscription}
              leaseIds={result.leaseIds}
            />
          );
        }
        if (customSubscription.name === CustomSubscriptionType.TACIT_WARNING) {
          const result: {
            name: IntegrationName;
            id: string;
            leaseIds: string[];
          } = JSON.parse(customSubscription.value!);

          return (
            <NotificationsTacitRenewalItem
              key={`${index}_${result.name}`}
              customSubscription={customSubscription}
              leaseIds={result.leaseIds}
              isWarning
            />
          );
        }
        if (customSubscription.name === CustomSubscriptionType.CREDITS) {
          const creditsResult: Omit<NotificationsCreditsItemProps, 'customSubscription'> = JSON.parse(
            customSubscription.value!
          );

          return (
            <NotificationsCreditsItem
              key={`${index}_${creditsResult.action}`}
              {...creditsResult}
              customSubscription={customSubscription}
            />
          );
        }
        if (customSubscription.name === CustomSubscriptionType.ENTITY_DELETION) {
          const result: NotificationsEntityDeletionItemProps & { entityId: string } = JSON.parse(
            customSubscription.value!
          );
          return (
            <NotificationsEntityDeletionItem
              key={`${index}_${result.entityId}`}
              {...result}
              customSubscription={customSubscription}
            />
          );
        }
        const isOcr = ocrTypes.includes(customSubscription.name);
        if (isOcr) {
          const result: {
            id: string;
            chargeIds: string[];
          } = JSON.parse(customSubscription.value!);

          return (
            <NotificationsOcrItem
              key={`${index}_ocr_${customSubscription.name}`}
              customSubscription={customSubscription}
              chargeIds={result.chargeIds}
              isFailure={customSubscription.name === CustomSubscriptionType.OCR_FAILURE}
            />
          );
        }

        return null;
      })}
    </List>
  );
};

export default NotificationsList;
