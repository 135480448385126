import { Divider, Grid, Typography } from '@material-ui/core';
import {
  Colors,
  getSendingSourceStatusColor,
  getSendingSourceStatusName,
  SendingSource,
  SendingSourceStatus,
  SendingSourceVerifiedType,
} from '@rentguru/commons-utils';
import { formatDistance, parseISO } from 'date-fns';
import { toUpper } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { ActionButton, ContentHeader, LoaderButton , ColoredBox } from '@up2rent/ui';
import { dateLocaleMap, dateLocaleMapType, useLocale } from 'src/i18n/IntlProviderWrapper';

interface PendingDomainDialogProps {
  sendingSource: SendingSource;
  handleVerifyAgainButton: () => void;
  closeAction: () => void;
}

const PendingDomainDialog: React.FC<PendingDomainDialogProps> = ({
  sendingSource,
  closeAction,
  handleVerifyAgainButton,
}) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const statusText = getSendingSourceStatusName(
    sendingSource.status as SendingSourceStatus,
    sendingSource.verifiedType as SendingSourceVerifiedType,
    formatMessage
  );
  const statusColor = getSendingSourceStatusColor(sendingSource.status as SendingSourceStatus);
  const duration = formatDistance(parseISO(sendingSource.statusLastVerification!), new Date(), {
    locale: (dateLocaleMap as dateLocaleMapType)[language],
  });
  return (
    <>
      <ContentHeader title={formatMessage({ id: 'settings.sendingSources.pendingTitle' })}>
        <ColoredBox boxColor={statusColor}>{statusText}</ColoredBox>
      </ContentHeader>

      <Divider style={{ marginBottom: 20 }} />
      <Grid style={{ marginLeft: 30, marginRight: 30 }}>
        <Typography>
          {formatMessage({ id: 'settings.sendingSources.pendingInfo1' })}
          <b>{sendingSource.name}</b>
          {formatMessage({ id: 'settings.sendingSources.pendingInfo2' })}
        </Typography>
        <Typography>
          {formatMessage({
            id: 'settings.sendingSources.pendingInfo3',
          })}
        </Typography>
        <Typography style={{ marginTop: 10, marginBottom: 10, fontWeight: 'bold' }}>
          {formatMessage(
            {
              id: 'settings.sendingSources.pendingInfo4',
            },
            {
              name: sendingSource.name,
              duration,
            }
          )}
        </Typography>
        <Typography>
          {formatMessage({
            id: 'settings.sendingSources.pendingInfo5',
          })}
        </Typography>
      </Grid>
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      <Grid
        style={{
          marginRight: 30,
          marginBottom: 20,
          display: 'flex',
          float: 'right',
          alignItems: 'center',
        }}
      >
        <ActionButton
          onClick={closeAction}
          style={{
            background: 'none',
            color: Colors.DARK_SLATE_GREY,
            marginRight: 20,
          }}
        >
          {toUpper(
            formatMessage({
              id: 'close',
            })
          )}
        </ActionButton>

        <LoaderButton onClick={handleVerifyAgainButton}>
          {formatMessage({
            id: 'settings.sendingSources.verifyAgain',
          })}
        </LoaderButton>
      </Grid>
    </>
  );
};

export default PendingDomainDialog;
