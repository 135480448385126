/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-shadow */
import { CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Colors } from '@rentguru/commons-utils';
import isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { handleNewHEICFile } from 'src/utils/heicConvertor';
import { IconComponent } from './FileType';
import PDFViewer from './PDFViewer';

interface FileItemRenderingProps {
  fileType: string;
  fileUrl?: string;
  fileBlob?: File;
  fileName: string;
  removeFunction?: () => void;
  style?: React.CSSProperties;
  height?: number;
  width?: number;
  deleteIconStyle?: React.CSSProperties;
}

interface CloseIconButtonProps {
  removeFunction: () => void;
  deleteIconStyle?: React.CSSProperties;
}

const CloseIconButton: React.FC<CloseIconButtonProps> = ({ deleteIconStyle, removeFunction }) => (
  <CloseIcon
    style={{
      position: 'absolute',
      top: 5,
      right: 2,
      cursor: 'pointer',
      fill: Colors.LIGHT_BLUE_GREY,
      zIndex: 2,
      ...deleteIconStyle,
    }}
    onClick={(event) => {
      event.stopPropagation();
      removeFunction();
    }}
  />
);

const FileItemRendering: React.FC<FileItemRenderingProps> = ({
  fileType,
  fileUrl,
  fileName,
  fileBlob,
  removeFunction,
  height = 100,
  width = 100,
  style = {},
  deleteIconStyle = {},
}) => {
  const [filePreviewUrl, setFilePreviewUrl] = useState<string | undefined>(fileUrl || undefined);
  useEffect(() => {
    // Method to get HEIC files to JPG
    const convertHEICToJPG = async (fileBlob: File) => {
      setFilePreviewUrl(undefined);
      const jpgBlob = await handleNewHEICFile(fileBlob);

      if (jpgBlob) {
        const preview = URL.createObjectURL(jpgBlob);
        setFilePreviewUrl(preview);
      }
    };

    if (fileBlob) {
      if (['image/heic', 'image/heif'].includes(fileType)) {
        convertHEICToJPG(fileBlob);
      } else {
        const preview = URL.createObjectURL(fileBlob);
        setFilePreviewUrl(preview);
      }
    }
    return () => {
      if (fileBlob && filePreviewUrl) {
        URL.revokeObjectURL(filePreviewUrl);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileBlob]);

  if (fileType.includes('image/')) {
    return (
      <div style={{ position: 'relative', display: 'inline-block', top: 1 }}>
        {!filePreviewUrl && (
          <div style={{ display: 'block', width, height, borderRadius: 6, ...style }}>
            <CircularProgress size={20} style={{ color: Colors.DODGER_BLUE }} />
          </div>
        )}
        {filePreviewUrl && (
          <img
            src={filePreviewUrl}
            style={{ display: 'block', width, height, borderRadius: 6, ...style }}
            alt={fileName}
          />
        )}
        {!isNil(removeFunction) && (
          <CloseIconButton deleteIconStyle={deleteIconStyle} removeFunction={removeFunction} />
        )}
      </div>
    );
  }

  if (fileType.includes('application/pdf')) {
    return (
      <div
        style={{
          width,
          height,
          borderStyle: 'solid',
          borderRadius: 6,
          borderColor: Colors.SEPARATOR_GREY,
          borderWidth: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          ...style,
        }}
      >
        {filePreviewUrl && <PDFViewer url={filePreviewUrl} width={width} />}
        {!isNil(removeFunction) && (
          <CloseIconButton deleteIconStyle={deleteIconStyle} removeFunction={removeFunction} />
        )}
      </div>
    );
  }

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <div
        style={{
          width,
          height,
          borderStyle: 'solid',
          borderRadius: 6,
          borderColor: Colors.SEPARATOR_GREY,
          borderWidth: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          ...style,
        }}
      >
        <div
          style={{
            justifyContent: 'inherit',
            alignItems: 'inherit',
            paddingTop: 5,
            paddingLeft: 5,
            paddingRight: 5,
            paddingBottom: 0,
          }}
        >
          <IconComponent dataType={fileType} iconWidth={width * 0.4} iconHeight={height / 2} />
        </div>
        <div
          style={{
            justifyContent: 'inherit',
            alignItems: 'inherit',
            fontFamily: 'Mulish',
            fontSize: Math.ceil((height / 100) * 12),
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: Colors.SLATE_GREY,
            width: width * 0.8,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            textAlign: 'center',
            ...style,
          }}
        >
          {fileName}
        </div>
      </div>
      {!isNil(removeFunction) && <CloseIconButton deleteIconStyle={deleteIconStyle} removeFunction={removeFunction} />}
    </div>
  );
};

export default FileItemRendering;
