import { FormControlLabel, Grid, makeStyles, MenuItem, RadioGroup, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { isNil } from 'lodash';
import { useIntl } from 'react-intl';
import { LandTerrainType } from '@rentguru/commons-utils';
import CustomizedSelect from 'src/components/RentalUnits/Details/Publication/EditPublication/CustomizedSelectWithImportance';
import { EditAdvertisementFormValues } from 'src/components/RentalUnits/Details/Publication/EditPublication/EditPublicationForm';
import TextDetailEditable from 'src/components/RentalUnits/Details/Publication/EditPublication/TextDetailEditableWithImportance';
import useEditPublicationFormStyles from 'src/components/RentalUnits/Details/Publication/EditPublication/utils/EditPublicationFormStyle';
import CustomCheckBox from 'src/components/ui/CustomCheckBox';
import StyledRadio from 'src/components/ui/StyledRadio';

const useLocalStyles = makeStyles({
  rootSelect: {
    '& > *': {
      width: 280,
    },
  },
  gridItem: { display: 'flex', justifyContent: 'flex-start', position: 'relative' },
  styledRadio: { width: '5%', left: 5 },
});

const LandTerrainEdit = () => {
  const { values, setFieldValue, errors, touched } = useFormikContext<EditAdvertisementFormValues>();
  const { formatMessage } = useIntl();
  const classes = useLocalStyles();
  const publicationFormClasses = useEditPublicationFormStyles();

  const getRadioValue = () => {
    const landTerrainType: Array<LandTerrainType> = values.advertisement.landTerrain as Array<LandTerrainType>;
    if (!isNil(landTerrainType) && landTerrainType.find((type) => type === LandTerrainType.STREET_BACK_LAND)) {
      return 'STREET_BACK_LAND';
    }
    if (!isNil(landTerrainType) && landTerrainType.find((type) => type === LandTerrainType.STREET_FRONT_LAND)) {
      return 'STREET_FRONT_LAND';
    }
    return 'none';
  };

  const landTypeArray: Array<LandTerrainType> = values.advertisement.landTerrain as Array<LandTerrainType>;

  const handleRadioCheck = (landType: LandTerrainType) => {
    const switchedValue =
      landType === LandTerrainType.STREET_BACK_LAND
        ? LandTerrainType.STREET_FRONT_LAND
        : LandTerrainType.STREET_BACK_LAND;
    if (landTypeArray.includes(switchedValue)) {
      setFieldValue('advertisement.landTerrain', [
        ...landTypeArray.filter((landTypeElement) => landTypeElement !== switchedValue),
        landType,
      ]);
    } else {
      setFieldValue('advertisement.landTerrain', [...landTypeArray, landType]);
    }
  };

  return (
    <>
      <Typography variant="h6" className={publicationFormClasses.title}>
        {formatMessage({ id: 'rentalUnit.detail.outside.landTerrain.landTerrain' })}
      </Typography>
      <Grid container style={{ justifyContent: 'space-evenly' }}>
        <Grid item>
          <TextDetailEditable
            style={{ width: 280 }}
            title={formatMessage({ id: 'rentalUnit.detail.outside.landTerrain.landArea' })}
            editMode={true}
            name="advertisement.landArea"
            endAdornment="m²"
            error={Boolean(
              errors.advertisement &&
                errors.advertisement?.landArea &&
                touched.advertisement &&
                touched.advertisement.landArea
            )}
          />
        </Grid>

        <Grid item>
          <CustomizedSelect
            label={formatMessage({ id: 'rentalUnit.detail.outside.landTerrain.resort' })}
            fieldName="advertisement.resort"
            value={values.advertisement.resort}
            className={classes.rootSelect}
            error={false}
            margin="dense"
            importance="recommended"
          >
            <MenuItem id="none" key="none" value="none">
              {formatMessage({ id: 'rentalUnit.detail.inside.listedRoom.none' })}
            </MenuItem>

            <MenuItem id="false" key="false" value="false">
              {formatMessage({ id: 'rentalUnit.detail.inside.listedRoom.no' })}
            </MenuItem>
            <MenuItem id="true" key="true" value="true">
              {formatMessage({ id: 'rentalUnit.detail.inside.listedRoom.yes' })}
            </MenuItem>
          </CustomizedSelect>
        </Grid>
      </Grid>
      <Grid container style={{ justifyContent: 'space-evenly' }}>
        <Grid item>
          <TextDetailEditable
            style={{ width: 280 }}
            title={formatMessage({ id: 'rentalUnit.detail.outside.landTerrain.length' })}
            editMode={true}
            name="advertisement.length"
            endAdornment="m"
            error={Boolean(
              errors.advertisement &&
                errors.advertisement?.length &&
                touched.advertisement &&
                touched.advertisement.length
            )}
          />
        </Grid>
        <Grid item>
          <TextDetailEditable
            style={{ width: 280 }}
            title={formatMessage({ id: 'rentalUnit.detail.outside.landTerrain.width' })}
            editMode={true}
            name="advertisement.width"
            endAdornment="m"
            error={Boolean(
              errors.advertisement &&
                errors.advertisement?.width &&
                touched.advertisement &&
                touched.advertisement.width
            )}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} className={classes.gridItem}>
          <RadioGroup
            style={{ padding: '20px 30px 0 30px', width: '100%' }}
            name="LandTerrainType"
            value={getRadioValue()}
            onChange={(_, value) => handleRadioCheck(value as LandTerrainType)}
          >
            <Grid container>
              <Grid item xs={6} className={classes.gridItem}>
                <FormControlLabel
                  value="STREET_FRONT_LAND"
                  control={<StyledRadio className={classes.styledRadio} size="small" name="STREET_FRONT_LAND" />}
                  label={
                    <Typography className={publicationFormClasses.label}>
                      {formatMessage({ id: 'rentalUnit.detail.outside.landTerrain.streetFrontLand' })}
                    </Typography>
                  }
                  id="streetfrontLand"
                />
              </Grid>
              <Grid item xs={6} className={classes.gridItem} style={{ left: 13 }}>
                <FormControlLabel
                  value="STREET_BACK_LAND"
                  control={<StyledRadio className={classes.styledRadio} size="small" name="STREET_BACK_LAND" />}
                  label={
                    <Typography className={publicationFormClasses.label}>
                      {formatMessage({ id: 'rentalUnit.detail.outside.landTerrain.landBG' })}
                    </Typography>
                  }
                  id="streetBackLand"
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} className={classes.gridItem} style={{ left: 30 }}>
          <FormControlLabel
            control={
              <CustomCheckBox
                isChecked={!isNil(landTypeArray.find((type) => type === LandTerrainType.FLAT_LAND))}
                onCheck={() => {
                  if (landTypeArray.includes(LandTerrainType.FLAT_LAND)) {
                    setFieldValue(
                      'advertisement.landTerrain',
                      landTypeArray.filter((landTypeElement) => landTypeElement !== LandTerrainType.FLAT_LAND)
                    );
                  } else {
                    setFieldValue('advertisement.landTerrain', [...landTypeArray, LandTerrainType.FLAT_LAND]);
                  }
                }}
                secondary
                name="FlatLand"
                size="small"
              />
            }
            label={formatMessage({ id: 'rentalUnit.detail.outside.landTerrain.flatLand' })}
            id="flatLand"
          />
        </Grid>
        <Grid item />
      </Grid>
    </>
  );
};

export default LandTerrainEdit;
