import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Colors, LooseObject } from '@rentguru/commons-utils';
import isNil from 'lodash/isNil';

const useStyles = makeStyles(() =>
  createStyles({
    item: {
      paddingLeft: 15,
      paddingTop: 1,
      paddingBottom: 1,
      borderRadius: 5,
    },
    selected: {
      backgroundColor: 'white !important',
    },
    tenantSelected: {
      backgroundColor: `${Colors.PORCELAIN_GREY_3} !important`,
    },
    typographyNonSelected: {
      fontSize: 14,
      fontFamily: 'Mulish',
      lineHeight: 1.5,
      color: Colors.CHARCOAL_GREY,
      opacity: 0.9,
      fontWeight: 600,
    },
    typographySelected: {
      fontSize: 14,
      fontFamily: 'Mulish',
      lineHeight: 1.5,
      opacity: 0.9,
      fontWeight: 800,
      letterSpacing: 0.5,
    },
  })
);

export interface LocationDescriptorObject {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  search?: any;
  state?: LooseObject;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hash?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  key?: any;
}

export type CustomizedMenuItemProps = {
  to: string;
  selected: boolean;
  historyProps?: LocationDescriptorObject;
  children?: React.ReactNode;
  isTenant?: boolean;
};

const CustomizedMenuItem: React.FC<CustomizedMenuItemProps> = ({
  to,
  selected,
  historyProps,
  children,
  isTenant = false,
  ...rest
}) => {
  const classes = useStyles();
  const history = useHistory();
  const splittedTo = to.split('/');
  const unitId = splittedTo[splittedTo.length - 2].split('-').pop();

  return (
    <ListItem
      id="customItem"
      data-test={`customItemTest_${unitId}_${splittedTo.pop()}`}
      button
      className={classes.item}
      classes={{ selected: isTenant ? classes.tenantSelected : classes.selected }}
      onClick={() => history.push({ pathname: to, ...(!isNil(historyProps) && historyProps) })}
      selected={selected}
      {...rest}
    >
      <ListItemText
        classes={{ primary: selected ? classes.typographySelected : classes.typographyNonSelected }}
        primary={children}
      />
      {selected && <NavigateNextIcon style={{ fill: Colors.CARNATION_RED }} />}
    </ListItem>
  );
};

export default CustomizedMenuItem;
