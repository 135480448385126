import { Divider, Grid, makeStyles, MenuItem, Typography } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import { FormikProps, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import CustomizedSelect from '../CustomizedSelectWithImportance';
import { EditAdvertisementFormValues } from '../EditPublicationForm';
import TextDetailEditable from '../TextDetailEditableWithImportance';

const useLocalStyles = makeStyles({
  rootSelect: {
    '& > *': {
      width: 280,
    },
  },
  h6: {
    flex: 1,
    textAlign: 'left' as const,
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Mulish',
    fontSize: '16px',
    fontWeight: 700,
    margin: '20px 0 15px 30px',
  },
});

const BuildingInformations = () => {
  const classes = useLocalStyles();
  const { formatMessage } = useIntl();
  const [hasElevator, setHasElevator] = useState<string>('false');
  const { values, errors, touched }: FormikProps<EditAdvertisementFormValues> = useFormikContext();

  return (
    <>
      <Typography variant="h6" color="inherit" className={classes.h6}>
        {formatMessage({ id: 'rentalUnit.editUnit.advertisement.building.building' })}
      </Typography>

      <Grid container style={{ justifyContent: 'space-evenly' }}>
        <Grid item>
          <TextDetailEditable
            style={{ width: 280 }}
            title={formatMessage({ id: 'rentalUnit.editUnit.advertisement.building.floor' })}
            editMode={true}
            name="unit.floor"
            importance="recommended"
            error={Boolean(errors.unit && errors.unit.floor && touched.unit && touched.unit.floor)}
          />
        </Grid>
        <Grid item>
          <CustomizedSelect
            label={formatMessage({ id: 'rentalUnit.editUnit.advertisement.building.elevator' })}
            fieldName="elevator"
            value={hasElevator}
            className={classes.rootSelect}
            error={false}
            margin="dense"
            importance="normal"
          >
            <MenuItem
              id="false"
              key="false"
              value="false"
              onClick={() => {
                setHasElevator('false');
              }}
            >
              {formatMessage({ id: 'rentalUnit.detail.general.no' })}
            </MenuItem>
            <MenuItem
              id="true"
              key="true"
              value="true"
              onClick={() => {
                setHasElevator('true');
              }}
            >
              {formatMessage({ id: 'rentalUnit.detail.general.yes' })}
            </MenuItem>
          </CustomizedSelect>
        </Grid>
      </Grid>

      <Grid container style={{ margin: '5px 0 0 0', justifyContent: 'space-evenly' }}>
        <Grid item>
          <TextDetailEditable
            style={{ width: 280 }}
            title={formatMessage({ id: 'rentalUnit.editUnit.advertisement.building.residenceName' })}
            editMode={true}
            name="building.residenceName"
            error={Boolean(
              errors.building && errors.building.residenceName && touched.building && touched.building.residenceName
            )}
          />
        </Grid>
        <Grid item>
          <TextDetailEditable
            style={{ width: 280 }}
            title={formatMessage({ id: 'rentalUnit.editUnit.advertisement.building.constructionYear' })}
            editMode={true}
            name="building.constructionYear"
            importance="normal"
            error={Boolean(
              errors.building &&
                errors.building.constructionYear &&
                touched.building &&
                touched.building.constructionYear
            )}
          />
        </Grid>
      </Grid>
      <Grid container style={{ margin: '5px 0 0 0', justifyContent: 'space-evenly' }}>
        <CustomizedSelect
          id="building.buildingState"
          label={formatMessage({ id: 'rentalUnit.editUnit.advertisement.building.buildingCondition' })}
          fieldName="building.buildingState"
          key="building.buildingState"
          value={values.building.buildingState}
          className={classes.rootSelect}
          importance="recommended"
          error={Boolean(
            errors.advertisement &&
              errors.advertisement.wheelChairAccessible &&
              touched.advertisement &&
              touched.advertisement.wheelChairAccessible
          )}
          margin="dense"
        >
          <MenuItem value="none">
            {formatMessage({ id: 'rentalUnit.editUnit.advertisement.general.availability.none' })}
          </MenuItem>
          <MenuItem value="VERY_GOOD">
            {formatMessage({ id: 'enums.leaseInventoryStructureStateType.VERY_GOOD' })}
          </MenuItem>
          <MenuItem value="GOOD">{formatMessage({ id: 'enums.leaseInventoryStructureStateType.GOOD' })}</MenuItem>
          <MenuItem value="BAD">{formatMessage({ id: 'enums.leaseInventoryStructureStateType.BAD' })}</MenuItem>
          <MenuItem value="VERY_BAD">
            {formatMessage({ id: 'enums.leaseInventoryStructureStateType.VERY_BAD' })}
          </MenuItem>
        </CustomizedSelect>

        <Grid item>
          <CustomizedSelect
            id="advertisement.wheelChairAccessible"
            label={formatMessage({ id: 'rentalUnit.editUnit.advertisement.building.wheelchairAccessible' })}
            fieldName="advertisement.wheelChairAccessible"
            value={values.advertisement.wheelChairAccessible}
            className={classes.rootSelect}
            importance="recommended"
            error={Boolean(
              errors.advertisement &&
                errors.advertisement.wheelChairAccessible &&
                touched.advertisement &&
                touched.advertisement.wheelChairAccessible
            )}
            margin="dense"
          >
            <MenuItem value="none">
              {formatMessage({ id: 'rentalUnit.editUnit.advertisement.general.availability.none' })}
            </MenuItem>
            <MenuItem value="false">{formatMessage({ id: 'rentalUnit.detail.general.no' })}</MenuItem>
            <MenuItem value="true">{formatMessage({ id: 'rentalUnit.detail.general.yes' })}</MenuItem>
          </CustomizedSelect>
        </Grid>
      </Grid>
      <Grid container style={{ margin: '5px 0 0 0', justifyContent: 'space-evenly' }}>
        <Grid item>
          <TextDetailEditable
            style={{ width: 280 }}
            title={formatMessage({ id: 'rentalUnit.editUnit.advertisement.building.numberOfFacades' })}
            editMode={true}
            name="building.amountOfFacades"
            error={Boolean(
              errors.building && errors.building.amountOfFacades && touched.building && touched.building.amountOfFacades
            )}
          />
        </Grid>
        <Grid item>
          <TextDetailEditable
            style={{ width: 280 }}
            title={formatMessage({ id: 'rentalUnit.editUnit.advertisement.building.numberOfHousings' })}
            editMode={true}
            name="building.amountOfHousings"
            error={Boolean(
              errors.building &&
                errors.building.amountOfHousings &&
                touched.building &&
                touched.building.amountOfHousings
            )}
          />
        </Grid>
      </Grid>

      <Grid container style={{ margin: '5px 0 30px 0', justifyContent: 'space-evenly' }}>
        <Grid item>
          <CustomizedSelect
            label={formatMessage({ id: 'rentalUnit.editUnit.advertisement.building.janitor' })}
            fieldName="building.janitor"
            value={values.building.janitor}
            className={classes.rootSelect}
            error={false}
            margin="dense"
          >
            <MenuItem value="none">
              {formatMessage({ id: 'rentalUnit.editUnit.advertisement.general.availability.none' })}
            </MenuItem>

            <MenuItem value="false">{formatMessage({ id: 'rentalUnit.detail.general.no' })}</MenuItem>
            <MenuItem value="true">{formatMessage({ id: 'rentalUnit.detail.general.yes' })}</MenuItem>
          </CustomizedSelect>
        </Grid>
        <Grid item>
          <TextDetailEditable
            title={formatMessage({ id: 'rentalUnit.detail.general.amoutOfFloors' })}
            editMode={true}
            min={0}
            name="building.amountOfFloors"
            type="number"
            style={{ width: 280 }}
            error={Boolean(
              errors.building && errors.building.amountOfFloors && touched.building && touched.building.amountOfFloors
            )}
            importance="recommended"
          />
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

/*



*/

export default BuildingInformations;
