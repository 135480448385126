import React from 'react';
import { useIntl } from 'react-intl';
import { TablePlaceHolder } from '@up2rent/ui';

const MenuNotFound: React.FC = () => {
  const { formatMessage } = useIntl();
  return (
    <TablePlaceHolder
      mainText={formatMessage({ id: 'emptySectionTitle' })}
      subText={formatMessage({ id: 'pageNotFound' })}
    />
  );
};
export default MenuNotFound;
