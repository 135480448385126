import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import { AddLeaseFormValues } from 'src/components/Leases/AddLease/AddLeaseForm';
import { resolveMainUnitAndSubUnitIndexAndPrefix } from 'src/components/Leases/AddLease/useAddEditLeaseUtils';
import TitledSwitch from '../../TitledSwitch';

export interface AuthorizeProfesionnalActivityFormValues {
  profesionnalActivity: {
    profesionnalActivity: boolean;
  };
}

interface AuthorizeProfesionnalActivityProps {
  unitId: string;
}

const AuthorizeProfesionnalActivity: React.FC<AuthorizeProfesionnalActivityProps> = ({ unitId }) => {
  const { values } = useFormikContext<AddLeaseFormValues>();
  const { prefixFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(values, unitId);
  return (
    <Grid style={{ margin: '10px 0px 0px 30px', maxWidth: 580 }}>
      <Grid>
        <TitledSwitch
          fieldName={`${prefixFieldName}profesionnalActivity.profesionnalActivity`}
          titleMessageId="lease.addLease.authorizeProfessionalActivity"
          switchOnTextId="lease.addLease.includeInLease"
          switchOffTextId="lease.addLease.ignore"
        />
      </Grid>
    </Grid>
  );
};

export default AuthorizeProfesionnalActivity;
