import { ListItemText, type ListItemTextProps } from '@material-ui/core';
import { isNil } from 'lodash';
import { Colors } from '@rentguru/commons-utils';

export const MenuItemText = ({
  deleteOption,
  selected,
  dataTest,
  ...props
}: { deleteOption?: boolean; selected?: boolean; dataTest?: string } & ListItemTextProps) => {
  return (
    <ListItemText
      primaryTypographyProps={{
        style: {
          fontSize: 14,
          fontWeight: !isNil(selected) && selected ? 700 : 400,
          color: !isNil(deleteOption) && deleteOption ? 'red' : Colors.SLATE_GREY,
        },
      }}
      data-test={dataTest}
      {...props}
    />
  );
};
