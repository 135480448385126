import { Grid } from '@material-ui/core';
import React from 'react';
import DashboardLoader from 'src/components/Dashboard/DashboardLoader';

const EditAdvertisementLoader = () => {
  return (
    <Grid>
      <DashboardLoader style={{ position: 'relative', width: '100vw', top: -20, height: 80 }} />

      <Grid style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid>
          <DashboardLoader style={{ position: 'relative', width: 220, top: -20, height: 300 }} />
        </Grid>
        <Grid>
          <DashboardLoader style={{ position: 'relative', width: 640, top: -20, height: 560 }} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditAdvertisementLoader;
