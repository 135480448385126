/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState } from 'react';
import {
  Editor,
  EditorState,
  RichUtils,
  Modifier,
  ContentBlock,
  SelectionState,
  DraftEditorCommand,
  DraftInlineStyle,
  DraftHandleValue,
  genKey,
  DraftDragType,
  ContentState,
} from 'draft-js';
import { useIntl } from 'react-intl';
import { Button, ButtonGroup, Divider, Grid, Typography, useMediaQuery } from '@material-ui/core';
import FormatListBulletedRoundedIcon from '@material-ui/icons/FormatListBulletedRounded';
import FormatListNumberedRoundedIcon from '@material-ui/icons/FormatListNumberedRounded';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import { makeStyles } from '@material-ui/core/styles';
import SplitButton from './SplitButton';
import TemplateTagsTabs from './TemplateTagsTabs';
import { TemplateTag, LooseObject, TemplateTagTypes, TemplateFileMetaData, Colors } from '@rentguru/commons-utils';
import '../../css/templateEditor.css';
import { OrderedSet } from 'immutable';
import './TemplateEditorStyle.css';
import {
  addNewBlocksAt,
  correctSelectionToSelectEntities,
  getNewBlockColor,
  isCursorOnConditionalBlock,
  isCursorPartiallyOnEntity,
  isSelectionOnConditionalBlock,
  isSelectionPartiallyOnCondionnalBlock,
  putMetaDataIntoIfBlocks,
} from 'src/utils/templateTagsUtils';
import { isEmpty } from 'lodash';
import {
  compressEditorOnOneLine,
  textContainsConditionalTags as textContainsConditionalTags,
} from 'src/utils/templateutils';
import TemplateFileMetaDataComponent from '../Templates/Details/TemplateFileMetaData';

//@ts-ignore
import editOnCut from 'draft-js/lib/editOnCut';
import { MOBILE_MAX_WIDTH } from '@up2rent/ui';

export const useStyles = makeStyles({
  buttonRoot: {
    backgroundColor: 'white',
    borderRadius: 0,
    textTransform: 'none',
    border: 0,
    color: Colors.SLATE_GREY,
    marginLeft: '0 !important',
  },
  buttonGroupRoot: {
    borderRadius: 0,
    alignItems: 'center',
    zIndex: 3,
  },
  itemRoot: {
    zIndex: 3,
  },
  buttonText: {
    color: Colors.SLATE_GREY,
    fontSize: 12,
    marginLeft: 6,
  },
  buttonTextActive: {
    border: `1px ${Colors.DODGER_BLUE} solid !important`,
    textTransform: 'none',
    color: Colors.DODGER_BLUE,
    marginLeft: '0 !important',
    backgroundColor: 'rgba(75, 123, 236,0.2)',
  },
  buttonTextDisabled: {
    border: '0 !important',
    marginLeft: '0 !important',
    textTransform: 'none',
    color: Colors.SEPARATOR_GREY,
    backgroundColor: Colors.PORCELAIN_GREY_1,
  },
  textDisabled: {
    color: Colors.SEPARATOR_GREY,
  },
  textEnabled: {
    color: Colors.DODGER_BLUE,
    textTransform: 'none',
  },
  textNormal: {
    color: Colors.SLATE_GREY,
  },
});

export const customStyleMap = {
  TAG: {
    color: Colors.CHARCOAL_GREY,
    border: `1px solid ${Colors.CHARCOAL_GREY}`,
    borderRadius: '5px',
    padding: '1px',
    fontSize: '80%',
    margin: 5,
  },
  TINY: {
    fontSize: 9,
  },
  SMALL: {
    fontSize: 12,
  },
  MEDIUM_SMALL: {
    fontSize: 13,
  },
  MEDIUM: {
    fontSize: 15,
  },
  MEDIUM_BIG: {
    fontSize: 18,
  },
  BIG: {
    fontSize: 24,
  },
  HUGE: {
    fontSize: 30,
  },
};

interface TemplateEditorProps {
  body: EditorState;
  title?: EditorState;
  setBody: (v: EditorState) => void;
  setTitle?: (v: EditorState) => void;
  editMode: boolean;
  editable: boolean;
  customBlockRenderer?: (
    block: ContentBlock
  ) => { component: React.ReactNode; editable: boolean; props?: LooseObject } | void;
  error?: Boolean;
  errorMessage?: string;
  errorSubject?: Boolean;
  errorSubjectMessage?: string;
  tags?: TemplateTag[];
  dividerStyle?: React.CSSProperties;
  maxHeight?: number | string;
  editorPadding?: number | string;
  templateFileMetadata?: TemplateFileMetaData[];
  fullWidth?: boolean;
  hideTextStyle?: boolean;
}

const TemplateEditor: React.FC<TemplateEditorProps> = ({
  body,
  title,
  setBody,
  setTitle,
  editMode,
  editable,
  customBlockRenderer,
  error = false,
  errorMessage,
  errorSubject = false,
  errorSubjectMessage,
  tags,
  dividerStyle = {},
  maxHeight = 400,
  editorPadding,
  templateFileMetadata,
  fullWidth = true,
  hideTextStyle = false,
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const [conditionalBlockWasPasted, setConditionalBlockWasPasted] = useState<boolean>(false);
  const cursorStyle = body.getCurrentInlineStyle();
  const boldActive = cursorStyle.has('BOLD');
  const italicActive = cursorStyle.has('ITALIC');
  const underlineActive = cursorStyle.has('UNDERLINE');
  const cursorPartiallyOnTag = isCursorPartiallyOnEntity(body, 'TAG');
  const isMobileLayout = useMediaQuery(MOBILE_MAX_WIDTH);

  if (cursorPartiallyOnTag) {
    const newSelection = correctSelectionToSelectEntities(body, 'TAG');
    if (newSelection) setBody(EditorState.forceSelection(body, newSelection));
  }
  const cursorOnConditionBlock = isCursorOnConditionalBlock(body);
  const selectionOnConditionalBlock = isSelectionOnConditionalBlock(body);
  const styleButtonsDisabled = cursorPartiallyOnTag || cursorOnConditionBlock;
  const blockStyleButtonDisabled = cursorOnConditionBlock || selectionOnConditionalBlock;
  // When conditional blocks are copy-pasted their keys need to readjust to their new values.
  if (conditionalBlockWasPasted) {
    const newContentWithCorrectKeys = putMetaDataIntoIfBlocks(body.getCurrentContent());
    const newEditorWithCorrectKeys = EditorState.push(body, newContentWithCorrectKeys, 'change-block-data');
    setBody(newEditorWithCorrectKeys);
    setConditionalBlockWasPasted(false);
  }
  /**
   * Handles all key events like split-block, delete, etc
   *  */
  const handleKeyCommand = (command: DraftEditorCommand) => {
    if (cursorPartiallyOnTag || isSelectionPartiallyOnCondionnalBlock(body)) {
      // No action are permitted if we are partially on a tag
      return 'handled';
    }
    if (cursorOnConditionBlock) {
      if (command === 'bold' || command === 'italic' || command === 'underline') {
        return 'handled';
      }
    }
    const newState = RichUtils.handleKeyCommand(body, command);
    if (newState) {
      setBody(newState);

      return 'handled';
    }
    return 'not-handled';
  };

  /**
   * Handle the typing of characters
   */
  const handleBeforeInput = (chars: string, editorState: EditorState, _eventTimeStamp: number): DraftHandleValue => {
    if (cursorPartiallyOnTag || isSelectionPartiallyOnCondionnalBlock(body)) {
      // No action are permitted if we are partially on a tag
      return 'handled';
    }
    const selection = editorState.getSelection();
    let style: DraftInlineStyle = cursorStyle;
    if (cursorStyle.has('TAG')) {
      // We are writing but with a tag style, but we need to stop the style propagation
      style = style.remove('TAG');
    }
    const content = editorState.getCurrentContent();
    let newContent: ContentState;
    if (!selection.isCollapsed()) {
      newContent = Modifier.replaceText(content, selection, chars, style);
    } else {
      newContent = Modifier.insertText(content, selection, chars, style);
    }
    const newState = EditorState.push(editorState, newContent, 'insert-characters');
    setBody(newState);
    return 'handled';
  };

  const handlePastedText = (text: string, _html: string | undefined, _editorState: EditorState): DraftHandleValue => {
    if (cursorPartiallyOnTag || isSelectionPartiallyOnCondionnalBlock(body)) {
      // No action are permitted if we are partially on a tag
      return 'handled';
    }
    if (tags && textContainsConditionalTags(text, tags)) {
      // We are pasting condionnal blocks
      setConditionalBlockWasPasted(true);
    }

    return 'not-handled';
  };

  // editOnCut(editor: DraftEditor, e: SyntheticClipboardEvent<>):
  const onCut = (editor: any, e: any) => {
    const editorState = editor._latestEditorState;
    const selectionPartiallyOnTag = isCursorPartiallyOnEntity(editorState, 'TAG');
    const selectionPartiallyOnCondionnalBlock = isSelectionPartiallyOnCondionnalBlock(editorState);
    // We disable cutting through a tag or through a conditional block
    if (selectionPartiallyOnTag || selectionPartiallyOnCondionnalBlock) {
      e.preventDefault();
      return;
    }
    editOnCut(editor, e);
  };

  const handleDrop = (_selection: SelectionState, _dataTransfer: Object, _isInternal: DraftDragType) => {
    // We disable dragging text through a tag or through a conditional block
    if (cursorPartiallyOnTag || isSelectionPartiallyOnCondionnalBlock(body)) {
      return 'handled';
    }
    if (selectionOnConditionalBlock) {
      // We are pasting condionnal blocks
      setConditionalBlockWasPasted(true);
    }
    return 'not-handled';
  };

  const onTitleClick = (title: string) => {
    setBody(RichUtils.toggleBlockType(body, title));
  };

  const onListClick = (listType: string) => {
    setBody(RichUtils.toggleBlockType(body, listType));
  };

  const onFontSizeClick = (fontSize: string) => {
    setBody(RichUtils.toggleInlineStyle(body, fontSize));
  };

  const onItalicClick = () => {
    setBody(RichUtils.toggleInlineStyle(body, 'ITALIC'));
  };

  const onBoldClick = () => {
    setBody(RichUtils.toggleInlineStyle(body, 'BOLD'));
  };

  const onUnderlineClick = () => {
    setBody(RichUtils.toggleInlineStyle(body, 'UNDERLINE'));
  };

  const resetSelection = () => {
    setBody(EditorState.forceSelection(body, body.getSelection()));
  };

  /**
   * Triggers whenever the user clicks on a tag, we insert the Tag in the Editor.
   */
  const insertTagDraftJS = (startTag: TemplateTag, endTag?: TemplateTag) => {
    const contentState = body.getCurrentContent();
    const selection = body.getSelection(); // Get cursor position
    let newEditorState: EditorState;
    if (startTag.type === TemplateTagTypes.CONDITION_IF_START && endTag) {
      // we have a conditional tag, we need to insert its start and end block
      const blockColor = getNewBlockColor(body);
      const startBlockKey = genKey();
      const middleBlockKey = genKey();
      const endBlockKey = genKey();
      const startBlock = {
        type: 'conditionalStart',
        data: { label: startTag.label, endBlockKey, color: blockColor, html: startTag.html },
        key: startBlockKey,
      };
      const middleBlock = { type: 'unstyled', key: middleBlockKey };
      const endBlock = {
        type: 'conditionalEnd',
        data: { label: endTag.label, startBlockKey, color: blockColor, html: endTag.html },
        key: endBlockKey,
      };
      const newEditorStateWithBlocks = addNewBlocksAt(body, selection.getAnchorKey(), [
        startBlock,
        middleBlock,
        endBlock,
      ]);
      // And put the cursor inside the block
      const newSelection = new SelectionState({ anchorKey: middleBlockKey, anchorOffset: 0 });
      newEditorState = EditorState.forceSelection(newEditorStateWithBlocks, newSelection);
    } else {
      // Simple TAG, add it as a Immutable entity
      const contentStateWithEntity = contentState.createEntity('TAG', 'IMMUTABLE', startTag);
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const textToInsert = `{{${startTag.label}}}`;
      const newContentWithTag = Modifier.insertText(
        contentState,
        selection,
        textToInsert,
        OrderedSet.of('TAG'),
        entityKey
      );
      const newEditorStateWithText = EditorState.createWithContent(newContentWithTag);
      // Create a new SelectionState to place cursor just after the new TAG
      const newSelectionState = new SelectionState({
        anchorKey: selection.getAnchorKey(),
        anchorOffset: selection.getStartOffset() + textToInsert.length,
      });
      newEditorState = EditorState.forceSelection(newEditorStateWithText, newSelectionState);
    }
    setBody(newEditorState);
  };

  const secondButtonRow = [
    <Button
      variant="outlined"
      disabled={styleButtonsDisabled}
      className={italicActive ? classes.buttonTextActive : classes.buttonRoot}
      classes={{ disabled: classes.buttonTextDisabled }}
      onClick={onItalicClick}
      // prevent loss of text selection hightlight when clicking on the syle button
      onMouseDown={(e) => {
        e.preventDefault();
      }}
    >
      <FormatItalicIcon
        className={
          styleButtonsDisabled ? classes.textDisabled : italicActive ? classes.textEnabled : classes.textNormal
        }
      />
      <Typography
        className={
          styleButtonsDisabled ? classes.textDisabled : italicActive ? classes.textEnabled : classes.textNormal
        }
      >
        {formatMessage({ id: 'templates.editor.italic' })}
      </Typography>
    </Button>,
    <Button
      variant="outlined"
      disabled={styleButtonsDisabled}
      className={boldActive ? classes.buttonTextActive : classes.buttonRoot}
      classes={{ disabled: classes.buttonTextDisabled }}
      onClick={onBoldClick}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
    >
      <FormatBoldIcon
        className={styleButtonsDisabled ? classes.textDisabled : boldActive ? classes.textEnabled : classes.textNormal}
      />
      <Typography
        className={styleButtonsDisabled ? classes.textDisabled : boldActive ? classes.textEnabled : classes.textNormal}
      >
        {formatMessage({ id: 'templates.editor.bold' })}
      </Typography>
    </Button>,
    <Button
      variant="outlined"
      disabled={styleButtonsDisabled}
      className={underlineActive ? classes.buttonTextActive : classes.buttonRoot}
      classes={{ disabled: classes.buttonTextDisabled }}
      onClick={onUnderlineClick}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
    >
      <FormatUnderlinedIcon
        className={
          styleButtonsDisabled ? classes.textDisabled : underlineActive ? classes.textEnabled : classes.textNormal
        }
      />
      <Typography
        className={
          styleButtonsDisabled ? classes.textDisabled : underlineActive ? classes.textEnabled : classes.textNormal
        }
      >
        {formatMessage({ id: 'templates.editor.underline' })}
      </Typography>
    </Button>,
  ];

  return (
    <Grid
      style={{
        width: fullWidth ? '100%' : undefined,
        minHeight: 100,
        fontWeight: 'normal',
        fontSize: 15,
        fontFamily: 'Mulish',
        textAlign: 'left',
      }}
    >
      {title && setTitle && (
        <>
          {errorSubject ? (
            <Typography style={{ color: Colors.SCARLET_ORANGE, fontSize: 13, padding: '12px 24px 12px 24px' }}>
              {errorSubjectMessage}
            </Typography>
          ) : (
            <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12, padding: '12px 24px 12px 24px' }}>
              {formatMessage({ id: 'subject' })}
            </Typography>
          )}
          <Grid style={{ padding: '0px 24px 12px 24px' }}>
            <Editor
              editorState={title}
              readOnly={!editMode || !editable}
              onChange={(editorState: EditorState) => {
                // Subjects cannot be multiline nor styled !
                const compressedEditor = compressEditorOnOneLine(editorState);
                setTitle(compressedEditor);
              }}
              placeholder={formatMessage({ id: 'tickets.detail.newSubject' })}
            />
          </Grid>
          <Divider style={dividerStyle} />
        </>
      )}
      {editMode && editable && !hideTextStyle && (
        <>
          <ButtonGroup fullWidth={fullWidth} classes={{ root: classes.buttonGroupRoot }}>
            <SplitButton
              aliases={[<>H1</>, <>H2</>, <>H3</>, <>H4</>, <>H5</>, <>H6</>]}
              options={['header-one', 'header-two', 'header-three', 'header-four', 'header-five', 'header-six']}
              onOptionClick={onTitleClick}
              disabled={blockStyleButtonDisabled}
            />
            <SplitButton
              aliases={[<FormatListBulletedRoundedIcon />, <FormatListNumberedRoundedIcon />]}
              options={['unordered-list-item', 'ordered-list-item']}
              onOptionClick={onListClick}
              disabled={blockStyleButtonDisabled}
            />
            <SplitButton
              aliases={[
                <Grid style={{ fontSize: 9 }}>{formatMessage({ id: 'templates.tags.fontSize.verySmall' })}</Grid>,
                <Grid style={{ fontSize: 12 }}>{formatMessage({ id: 'templates.tags.fontSize.small' })}</Grid>,
                <Grid style={{ fontSize: 13 }}>{formatMessage({ id: 'templates.tags.fontSize.mediumSmall' })}</Grid>,
                <Grid style={{ fontSize: 15 }}>{formatMessage({ id: 'templates.tags.fontSize.medium' })}</Grid>,
                <Grid style={{ fontSize: 18 }}>{formatMessage({ id: 'templates.tags.fontSize.mediumBig' })}</Grid>,
                <Grid style={{ fontSize: 24 }}>{formatMessage({ id: 'templates.tags.fontSize.big' })}</Grid>,
                <Grid style={{ fontSize: 30 }}>{formatMessage({ id: 'templates.tags.fontSize.huge' })}</Grid>,
              ]}
              selectedAliases={[
                <Grid style={{ fontSize: 14 }}>{formatMessage({ id: 'templates.tags.fontSize.verySmall' })}</Grid>,
                <Grid style={{ fontSize: 14 }}>{formatMessage({ id: 'templates.tags.fontSize.small' })}</Grid>,
                <Grid style={{ fontSize: 14 }}>{formatMessage({ id: 'templates.tags.fontSize.mediumSmall' })}</Grid>,
                <Grid style={{ fontSize: 14 }}>{formatMessage({ id: 'templates.tags.fontSize.medium' })}</Grid>,
                <Grid style={{ fontSize: 14 }}>{formatMessage({ id: 'templates.tags.fontSize.mediumBig' })}</Grid>,
                <Grid style={{ fontSize: 14 }}>{formatMessage({ id: 'templates.tags.fontSize.big' })}</Grid>,
                <Grid style={{ fontSize: 14 }}>{formatMessage({ id: 'templates.tags.fontSize.huge' })}</Grid>,
              ]}
              options={['TINY', 'SMALL', 'MEDIUM_SMALL', 'MEDIUM', 'MEDIUM_BIG', 'BIG', 'HUGE']}
              onOptionClick={onFontSizeClick}
              defaultIndex={3}
              disabled={styleButtonsDisabled}
            />
            {!isMobileLayout && <>{...secondButtonRow}</>}
          </ButtonGroup>
          {isMobileLayout && (
            <ButtonGroup fullWidth={fullWidth} classes={{ root: classes.buttonGroupRoot }}>
              {...secondButtonRow}
            </ButtonGroup>
          )}
          <Divider style={dividerStyle} />
        </>
      )}
      {error ? (
        <Typography style={{ color: Colors.SCARLET_ORANGE, fontSize: 13, padding: '12px 24px 12px 24px' }}>
          {errorMessage}
        </Typography>
      ) : (
        title && (
          <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12, padding: '12px 24px 12px 24px' }}>
            {formatMessage({ id: 'message' })}
          </Typography>
        )
      )}

      <Grid style={{ padding: editorPadding ?? '0px 24px 12px 24px', maxHeight }}>
        <Editor
          editorState={body}
          readOnly={!editMode || !editable}
          onChange={setBody}
          customStyleMap={customStyleMap}
          handleKeyCommand={handleKeyCommand}
          handleBeforeInput={handleBeforeInput}
          handlePastedText={handlePastedText}
          placeholder={formatMessage({ id: 'tickets.detail.newConversationError' })}
          blockRendererFn={customBlockRenderer}
          handleDrop={handleDrop}
          // @ts-ignore next-line
          onCut={onCut}
        />
      </Grid>
      {!editMode && templateFileMetadata && (
        <>
          {templateFileMetadata.map((templateFileMetadata) => (
            <TemplateFileMetaDataComponent
              key={templateFileMetadata.labelId}
              templateFileMetadata={templateFileMetadata}
            />
          ))}
        </>
      )}
      {editMode && editable && tags && !isEmpty(tags) && (
        <TemplateTagsTabs insertTag={insertTagDraftJS} tags={tags} resetSelection={resetSelection} />
      )}
    </Grid>
  );
};

export default TemplateEditor;
