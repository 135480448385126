import { Divider, Grid, Typography } from '@material-ui/core';
import {
  DEFAULT_SENDING_EMAIL,
  ModelWithVersion,
  Colors,
  getDefaultSendingSource,
  SendingSourceVerifiedType,
  SendingSource,
  SendingSourceStatus,
} from '@rentguru/commons-utils';
import { toUpper } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { ActionButton , ContentHeader, LoaderButton } from '@up2rent/ui';
import { useCommunications } from 'src/hooks/CommunicationsContext';
import { useSendingSources } from 'src/hooks/SendingSourceContext';

interface ResetDialogProps {
  closeAction: () => void;
}

const ResetDomainDialog: React.FC<ResetDialogProps> = ({ closeAction }) => {
  const { formatMessage } = useIntl();
  const { updateSendingSource, sendingSources, sendingSourcesLoading } = useSendingSources();
  const { batchSendCommunications } = useCommunications();

  const handleReset = async () => {
    const oldDefaultSendingSource = getDefaultSendingSource(sendingSources)!;

    await updateSendingSource({
      id: oldDefaultSendingSource.id,
      verifiedType: SendingSourceVerifiedType.NONE,
      _version: (oldDefaultSendingSource as ModelWithVersion<SendingSource>)._version,
    });

    closeAction();

    if (oldDefaultSendingSource.status === SendingSourceStatus.ERROR) {
      await batchSendCommunications([], true);
    }
  };

  return (
    <Grid style={{ width: 600 }}>
      <ContentHeader title={formatMessage({ id: 'settings.sendingSources.defaultResetTitle' })} />

      <Divider style={{ marginBottom: 20 }} />

      <Typography style={{ marginLeft: 30, marginRight: 30 }}>
        {formatMessage({ id: 'settings.sendingSources.defaultResetInfo' }, { emailAddress: DEFAULT_SENDING_EMAIL })}
      </Typography>

      <Divider style={{ marginBottom: 20, marginTop: 20 }} />

      <Grid
        style={{
          marginRight: 30,
          marginBottom: 20,
          display: 'flex',
          float: 'right',
          alignItems: 'center',
        }}
      >
        <ActionButton
          onClick={closeAction}
          style={{
            background: 'none',
            color: Colors.DARK_SLATE_GREY,
            marginRight: 20,
          }}
        >
          {toUpper(
            formatMessage({
              id: 'cancel',
            })
          )}
        </ActionButton>
        <LoaderButton loading={sendingSourcesLoading} onClick={handleReset}>
          {formatMessage({
            id: 'settings.sendingSources.defaultReset',
          })}
        </LoaderButton>
      </Grid>
    </Grid>
  );
};

export default ResetDomainDialog;
