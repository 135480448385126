/* eslint-disable jsx-a11y/anchor-is-valid */
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { LoadingScreen } from '@up2rent/ui';
import { isNil } from 'lodash';
import { lazy, Suspense, useEffect, useState } from 'react';
import ConfirmSignUp from './components/Auth/ConfirmSignUp';
import CreateAccount from './components/Auth/CreateAccount';
import ForgotPassword from './components/Auth/ForgotPassword';
import JoinSignup from './components/Auth/JoinSignup';
import Login from './components/Auth/Login';
import NewPassword from './components/Auth/NewPassword';
import Signup from './components/Auth/Signup';
import Up2RentLoginLogo from './components/Auth/Up2RentLoginLogo';
import { retryLazy } from './components/Routes/LazyErrorBoundary';
import { decodeHash } from './components/Settings/Integrations/cryptoUtils';
import './css/App.css';
import { useUser } from './hooks/UserContext';
import { useLocale } from './i18n/IntlProviderWrapper';
import { setCacheItem } from './utils/cacheUtils';
import { querystring } from './utils/routeutils';

const Routes = lazy(() => retryLazy(() => import('./components/Routes/Routes')));
const TenantRoutes = lazy(() => retryLazy(() => import('./components/Routes/TenantRoutes')));

interface AppProps {
  [key: string]: unknown;
}

export interface AuthProps {
  updateFormType: (type: FormType) => void;
}

export enum FormType {
  FORGOT_PASSWORD,
  JOIN_SIGN_UP,
  LOADING,
  SIGN_IN,
  SIGN_UP,
}

const App: React.FC<AppProps> = (props) => {
  const { language, setLanguage } = useLocale();
  const { clientId, isFetchingUser, changePasswordChallenge, confirmSignUpChallenge, isTenant, userAttributes } =
    useUser();
  const join = querystring('join');
  const isContact = join ? querystring('contact', `?${decodeHash(join)}`, false) === 'true' : false;
  const isOwner = join ? querystring('owner', `?${decodeHash(join)}`, false) === 'true' : false;
  const languageFromQuery = join ? querystring('language', `?${decodeHash(join)}`, false) : null;

  const [formType, updateFormType] = useState<FormType>(!isNil(join) ? FormType.JOIN_SIGN_UP : FormType.SIGN_IN);

  useEffect(() => {
    const handlePontoCallback = async () => {
      const queryStrings = new URLSearchParams(window.location.search);
      const code = queryStrings.get('code');
      const state = queryStrings.get('state');
      if (code && state) {
        await setCacheItem('pontoCallback', { pontoCode: code, pontoState: state });
      }
    };
    handlePontoCallback();
  }, []);

  if (languageFromQuery && languageFromQuery !== language) setLanguage(languageFromQuery);

  if (isFetchingUser) {
    return null;
  }

  if (clientId) {
    if (isTenant) {
      return (
        <Suspense fallback={<Skeleton />}>
          <TenantRoutes {...props} />
        </Suspense>
      );
    }
    return (
      <Suspense fallback={<Skeleton />}>
        <Routes {...props} />
      </Suspense>
    );
  }

  if (formType === FormType.LOADING) {
    return <LoadingScreen />;
  }

  const noUserAttributes = isNil(userAttributes);
  const noUserAttributesAndNoConfirmSignUpChallenge = noUserAttributes && !confirmSignUpChallenge;
  const showLogin = noUserAttributesAndNoConfirmSignUpChallenge && formType === FormType.SIGN_IN;
  const showForgotPassword = noUserAttributes && formType === FormType.FORGOT_PASSWORD;
  const showSignUp = noUserAttributesAndNoConfirmSignUpChallenge && formType === FormType.SIGN_UP;
  const showConfirmSignUp = noUserAttributes && confirmSignUpChallenge;
  const showJoinSignUp = noUserAttributesAndNoConfirmSignUpChallenge && formType === FormType.JOIN_SIGN_UP;
  const showNewPassword = userAttributes && changePasswordChallenge;
  const showCreateAccount = userAttributes && !clientId;

  return (
    <Grid alignContent="center" alignItems="center" container direction="column">
      <Up2RentLoginLogo formType={formType} isContact={isContact} isOwner={isOwner} joinQuery={join} />
      {showLogin && <Login updateFormType={updateFormType} />}
      {showForgotPassword && <ForgotPassword updateFormType={updateFormType} />}
      {showSignUp && <Signup updateFormType={updateFormType} />}
      {showConfirmSignUp && <ConfirmSignUp />}
      {showJoinSignUp && (
        <JoinSignup
          updateFormType={updateFormType}
          email={querystring('email', `?${decodeHash(join!)}`, false)!}
          name={querystring('firstName', `?${decodeHash(join!)}`, false)}
          familyName={querystring('lastName', `?${decodeHash(join!)}`, false)}
          isOwner={isOwner}
        />
      )}
      {showNewPassword && <NewPassword />}
      {showCreateAccount && <CreateAccount updateFormType={updateFormType} />}
    </Grid>
  );
};

export default App;
