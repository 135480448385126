/* eslint-disable react/no-array-index-key */
import { Divider, Grid, Typography } from '@material-ui/core';
import { Colors, CustomValue, formatNumber } from '@rentguru/commons-utils';
import React from 'react';
import { useIntl } from 'react-intl';
import { CustomPopover } from '@up2rent/ui';
import { useLocale } from 'src/i18n/IntlProviderWrapper';

interface TenantStatementsToValidateAmountPopperProps {
  statementAmounts: CustomValue[];
  anchorEl: HTMLElement;
  open: boolean;
  handleClose: () => void;
}

const TenantStatementsToValidateAmountPopper: React.FC<TenantStatementsToValidateAmountPopperProps> = ({
  statementAmounts,
  anchorEl,
  open,
  handleClose,
}) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  return (
    <CustomPopover
      id="split-info-popover"
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handleClose}
      disableRestoreFocus
      paperBorderRadius={10}
    >
      {statementAmounts.map((statementAmount, index) => {
        const { name, value } = statementAmount;
        const amount = Number(value);
        return (
          <React.Fragment key={index}>
            <Grid container style={{ marginTop: 15, marginBottom: 15 }} alignItems="center">
              <Grid item xs={3}>
                <Typography
                  style={{
                    fontSize: 12,
                    fontWeight: 'bold',
                    fontStyle: 'normal',
                    letterSpacing: 0,
                    color: amount > 0 ? Colors.DODGER_BLUE : amount < 0 ? Colors.CARNATION_RED : Colors.SILVER,
                  }}
                >
                  {formatNumber(amount, language, { style: 'currency', currency: 'EUR' })}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography
                  variant="subtitle1"
                  style={{
                    fontSize: 12,
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    letterSpacing: 0,
                    color: Colors.SLATE_GREY,
                    textOverflow: 'ellipsis',
                  }}
                >
                  {formatMessage({ id: `enums.statementAmount.${name}` })}
                </Typography>
              </Grid>
            </Grid>
            {index !== statementAmounts.length - 1 && <Divider />}
          </React.Fragment>
        );
      })}
    </CustomPopover>
  );
};

export default TenantStatementsToValidateAmountPopper;
