import React, { useState } from 'react';
import isNil from 'lodash/isNil';
import { FormikProps, useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { Divider, Grid, Typography } from '@material-ui/core';
import { ConnectionPlugsType, LeaseInventoryUnitKeyType, Colors, isNilOrEmpty } from '@rentguru/commons-utils';
import CustomCheckBox from 'src/components/ui/CustomCheckBox';
import { ReactComponent as AddIcon } from 'src/icons/add.svg';
import { CustomIconButton } from '@up2rent/ui';
import { InventoryOfFixturesFormik } from '../EditInventory/EditInventory';
import AddKeyDialog from './LeaseInventoryDialogs/AddKeyDialog';

interface ReducedKey {
  keyType: LeaseInventoryUnitKeyType;
  ids: string[];
  nums: number;
}

interface KeyDetailsRowProps {
  reducedKey: ReducedKey;
  divider: boolean;
}

interface InventoryTechnicEncodingsProps {
  readOnly: boolean;
}

const KeyDetailsRow: React.FC<KeyDetailsRowProps> = ({ reducedKey, divider }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Grid container style={{ marginLeft: 30, marginRight: 30, maxWidth: 580, textAlign: 'left' }}>
        <Grid item xs={3}>
          <Typography style={{ fontWeight: 700 }}>
            {formatMessage({ id: `enums.leaseInventoryKeyTypes.${reducedKey.keyType}` })}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography style={{ color: Colors.SLATE_GREY }}>{reducedKey.ids.join(', ')}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography style={{ color: Colors.SLATE_GREY }}>{reducedKey.nums}</Typography>
        </Grid>
      </Grid>
      {divider && <Divider style={{ marginTop: 10, marginBottom: 10, marginLeft: 30, marginRight: 30 }} />}
    </>
  );
};

const InventoryTechnicEncodings: React.FC<InventoryTechnicEncodingsProps> = ({ readOnly }) => {
  const { formatMessage } = useIntl();
  const { values, setFieldValue }: FormikProps<InventoryOfFixturesFormik> = useFormikContext();
  const [addKey, setAddKey] = useState<boolean>(false);
  const [tvPlug, phonePlug, internetPlug] = !isNilOrEmpty(values.leaseInventory.connectionPlugs)
    ? (values.leaseInventory.connectionPlugs as ConnectionPlugsType[]).reduce(
        (acc: boolean[], plug: ConnectionPlugsType) => {
          if (plug === ConnectionPlugsType.TELEVISION) {
            acc[0] = true;
          }
          if (plug === ConnectionPlugsType.TELEPHONE) {
            acc[1] = true;
          }
          if (plug === ConnectionPlugsType.INTERNET) {
            acc[2] = true;
          }
          return acc;
        },
        [false, false, false]
      )
    : [false, false, false];

  const reducedKeys = values.leaseInventory.keys
    ? values.leaseInventory.keys.reduce((acc, key) => {
        if (
          acc.some((reducedKey) => {
            return reducedKey.keyType === key.keyType;
          })
        ) {
          acc.forEach((accKey) => {
            if (accKey.keyType === key.keyType) {
              if (!isNil(key.keyId)) {
                accKey.ids.push(key.keyId);
              }

              accKey.nums += 1;
            }
          });
        } else {
          acc.push({
            keyType: key.keyType as LeaseInventoryUnitKeyType,
            ids: key.keyId ? [key.keyId] : [],
            nums: 1,
          });
        }

        return acc;
      }, [] as ReducedKey[])
    : [];

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>, plugType: ConnectionPlugsType) => {
    if (event.target.checked) {
      if (!isNil(values.leaseInventory.connectionPlugs) && !values.leaseInventory.connectionPlugs.includes(plugType)) {
        setFieldValue('leaseInventory.connectionPlugs', [
          ...(values.leaseInventory.connectionPlugs as ConnectionPlugsType[]),
          plugType,
        ]);
      } else if (isNil(values.leaseInventory.connectionPlugs)) {
        setFieldValue('leaseInventory.connectionPlugs', [plugType]);
      }
    } else if (!isNil(values.leaseInventory.connectionPlugs)) {
      setFieldValue(
        'leaseInventory.connectionPlugs',
        (values.leaseInventory.connectionPlugs as ConnectionPlugsType[]).filter((i) => i !== plugType)
      );
    }
  };

  return (
    <>
      <Typography
        style={{ fontWeight: 700, fontSize: 14, marginBottom: 20, marginTop: 20, textAlign: 'left', marginLeft: 30 }}
      >
        {formatMessage({ id: 'leaseInventory.technics.connectors' })}
      </Typography>
      <Grid container style={{ marginBottom: 20 }}>
        <Grid item xs={4}>
          <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
            {formatMessage({ id: 'leaseInventory.technics.television' })}
          </Typography>
          <CustomCheckBox
            checkedColor={Colors.DODGER_BLUE}
            isChecked={tvPlug}
            onCheck={(event) => {
              handleCheck(event, ConnectionPlugsType.TELEVISION);
            }}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
            {formatMessage({ id: 'leaseInventory.technics.telephone' })}
          </Typography>
          <CustomCheckBox
            checkedColor="#4B7BEC"
            isChecked={phonePlug}
            onCheck={(event) => {
              handleCheck(event, ConnectionPlugsType.TELEPHONE);
            }}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
            {formatMessage({ id: 'leaseInventory.technics.internet' })}
          </Typography>
          <CustomCheckBox
            checkedColor="#4B7BEC"
            isChecked={internetPlug}
            onCheck={(event) => {
              handleCheck(event, ConnectionPlugsType.INTERNET);
            }}
            disabled={readOnly}
          />
        </Grid>
      </Grid>
      <Divider style={{ marginLeft: 30, marginRight: 30 }} />
      <Grid
        container
        style={{ display: 'flex', marginTop: 20, marginBottom: 20, marginLeft: 30, marginRight: 30, maxWidth: 580 }}
      >
        <Grid item xs={10}>
          <Typography style={{ fontWeight: 700, textAlign: 'left', fontSize: 14 }}>
            {formatMessage({ id: 'leaseInventory.technics.keys' })}
          </Typography>
        </Grid>
        {!readOnly && (
          <Grid item xs={2}>
            <CustomIconButton
              onClick={() => {
                setAddKey(true);
              }}
              size="small"
              Icon={AddIcon}
              iconStyle={{ fill: Colors.DARK_SLATE_GREY }}
            />
          </Grid>
        )}
      </Grid>
      {isNil(values.leaseInventory.keys) && (
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12, marginBottom: 20 }}>
          {formatMessage({ id: 'leaseInventory.technics.keyPlaceholder' })}
        </Typography>
      )}
      {!isNil(values.leaseInventory.keys) && (
        <div style={{ marginBottom: 20 }}>
          {reducedKeys.map((reducedKey, index) => {
            // eslint-disable-next-line react/jsx-key
            return <KeyDetailsRow reducedKey={reducedKey} divider={index !== reducedKeys.length - 1} />;
          })}
        </div>
      )}

      <AddKeyDialog open={addKey} onClose={() => setAddKey(false)} />
    </>
  );
};

export default InventoryTechnicEncodings;
