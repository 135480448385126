import React, { useState } from 'react';
import { Grid, makeStyles, MenuList, Typography } from '@material-ui/core';
import CustomizedMenuItem from '../../../ui/CustomizedMenuItem';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ContentDetailHeader from '../../../ui/ContentDetailHeader';
import { ReactComponent as ContactSvg } from '../../../../icons/contact.svg';
import MenuNotFound from 'src/components/Routes/MenuNotFound';
import { useAgencyRates } from 'src/hooks/AgencyRatesContext';
import AgencyRateDescription from './AgencyRateDescription';
import AgencyRateOwners from './AgencyRateOwners';
import { ActionButtonSecond, actionButtonSecondStyles } from '@up2rent/ui';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import AddOwnerToAgencyRate from './AddOwnerToAgencyRate';
import { RouteDestination } from 'src/components/Routes/Routes';
import SettingDetailPanel from '../../SettingDetailPanel';
import { Colors } from '@rentguru/commons-utils';

const TO_PREFIX = '';

const useStyles = makeStyles({
  sectionTabTitle: {
    color: Colors.LIGHT_BLUE_GREY,
    fontSize: 10,
    letterSpacing: '1px',
    lineHeight: '12px',
    textTransform: 'uppercase',
    marginLeft: 15,
    marginRight: 15,
  },
});

interface GeneralDetailsProps {
  agencyRateId: string;
  onClose?: () => void;
}

const GeneralDetails: React.FC<GeneralDetailsProps> = ({ agencyRateId, onClose }) => {
  const navItems = [
    {
      to: `${TO_PREFIX}description`,
      labelId: 'agencyRates.description',
      exact: true,
      component: AgencyRateDescription,
      editable: true,
    },
    {
      to: `${TO_PREFIX}owners`,
      labelId: 'agencyRates.owners',
      exact: true,
      component: AgencyRateOwners,
      editable: true,
    },
  ];
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const actionButtonClasses = actionButtonSecondStyles();

  const match = useRouteMatch<{ agencyRateId: string; detailName: string }>({
    path: `${RouteDestination.SETTINGS}/agencyRates/:agencyRateId?/:detailName?`,
  });
  const { getAgencyRate } = useAgencyRates();
  const { agencyRateWrite } = usePermissions();
  const [addOwnerDialogOpen, setAddOwnerDialogOpen] = useState<boolean>(false);

  const handleClose = () => {
    setAddOwnerDialogOpen(false);
  };

  const agencyRate = getAgencyRate(agencyRateId);
  if (isNil(match) || isNil(agencyRate)) {
    return null;
  }

  return (
    <>
      <SettingDetailPanel
        onClose={onClose}
        TitleContent={
          <ContentDetailHeader
            EntityIcon={ContactSvg}
            title={agencyRate.name}
            actions={
              agencyRateWrite && !agencyRate.custom
                ? [
                    <ActionButtonSecond
                      className={actionButtonClasses.buttonWithMargin}
                      key="addOwner"
                      onClick={() => setAddOwnerDialogOpen(true)}
                    >
                      {formatMessage({ id: 'agencyRates.addAgencyRateOwner.actionButtonTitle' })}
                    </ActionButtonSecond>,
                  ]
                : []
            }
            titleStatus={
              agencyRate.custom ? (
                <Grid
                  style={{
                    marginLeft: 10,
                    borderRadius: 4,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderColor: Colors.LIGHT_BLUE_GREY,
                    padding: 8,
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 10,
                      fontWeight: 800,
                      fontStyle: 'normal',
                      letterSpacing: 0,
                      color: Colors.BLUEY,
                      textTransform: 'uppercase',
                    }}
                  >
                    {formatMessage({ id: 'agencyRates.custom' })}
                  </Typography>
                </Grid>
              ) : null
            }
          />
        }
        MenuContent={
          <Grid style={{ marginRight: 15, marginLeft: 15 }}>
            <Typography variant="subtitle1" className={classes.sectionTabTitle}>
              {formatMessage({
                id: 'settings.agencyRates',
              })}
            </Typography>
            <MenuList>
              {navItems.map((m) => {
                const to = !isNil(match.params.detailName)
                  ? match.url.replace(match.params.detailName, m.to)
                  : match.url + (match.url.charAt(match.url.length - 1) === '/' ? '' : '/') + m.to;
                const selected =
                  (!isNil(match.params.detailName) && match.params.detailName === m.to) ||
                  (m.to === TO_PREFIX && isNil(match.params.detailName));

                return (
                  <CustomizedMenuItem key={m.to} to={to} selected={selected}>
                    {formatMessage({ id: m.labelId })}
                  </CustomizedMenuItem>
                );
              })}
            </MenuList>
          </Grid>
        }
        contentStyle={{ marginLeft: 0, marginRight: 0 }}
      >
        <Switch>
          {navItems.map((m) => {
            const path = match.path.replace(':detailName?', m.to);
            const Comp = m.component;
            return (
              <Route
                path={path}
                key={m.to}
                exact={m.exact}
                render={() => {
                  return <Comp agencyRate={agencyRate} />;
                }}
              />
            );
          })}

          <Route
            path="*"
            render={() => {
              return <MenuNotFound />;
            }}
          />
        </Switch>
      </SettingDetailPanel>
      {addOwnerDialogOpen && (
        <AddOwnerToAgencyRate open={addOwnerDialogOpen} onClose={handleClose} agencyRate={agencyRate} />
      )}
    </>
  );
};

export default GeneralDetails;
