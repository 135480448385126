/* eslint-disable @typescript-eslint/no-shadow */
import { Grid, Typography } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Skeleton } from '@material-ui/lab';
import {
  Colors,
  LeaseAmountUpdateStatus,
  LeaseInventoryType,
  LeaseStatus,
  TechnicType,
  hasLeaseAlreadyBeenEnded,
} from '@rentguru/commons-utils';
import { CustomIconButton } from '@up2rent/ui';
import { isEmpty, isNil } from 'lodash';
import { Suspense, useState } from 'react';
import { useIntl } from 'react-intl';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import FurnitureInventoryDetail from 'src/components/FurnitureInventory/FurnitureInventoryDetails';
import LeaseInventoryDetail from 'src/components/LeaseInventory/LeaseInventoryDetail';
import { LeaseInventoryExtended } from 'src/components/RentalUnits/Details/Inventory/IntermediateInventory';
import MenuNotFound from 'src/components/Routes/MenuNotFound';
import { ExitableLocation, RouteDestination } from 'src/components/Routes/Routes';
import AddEditUnitEventDialog, { useAddEditUnitEventDialog } from 'src/components/ui/Dialogs/AddEditUnitEventDialog';
import EntityDetail, { ENTITY_DETAIL_PANEL_SIZE } from 'src/components/ui/EntityDetail/EntityDetail';
import SkeletonComponent from 'src/components/ui/SkeletonComponent';
import { useNoScrollbarStyles } from 'src/components/ui/noScrollbar';
import { useInvoices } from 'src/hooks/InvoicesContext';
import { useLeaseActionHistories } from 'src/hooks/LeaseActionHistoriesContext';
import { useLeasePriceHistories } from 'src/hooks/LeasePriceHistoriesContext';
import { useLeaseVariousOperations } from 'src/hooks/LeaseVariousOperationsContext';
import { useLeases } from 'src/hooks/LeasesContext';
import { useStatements } from 'src/hooks/StatementContext';
import { useTechnics } from 'src/hooks/TechnicsContext';
import { useTickets } from 'src/hooks/TicketsContext';
import { useTransactions } from 'src/hooks/TransactionsContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { useUser } from 'src/hooks/UserContext';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import ContentDetailHeader from '../../ui/ContentDetailHeader';
import EditLeaseNameDialog from '../AddLease/EditLeaseNameDialog';
import LeaseDetailActionsMenu from './ActionsMenu/LeaseDetailActionsMenu';
import { AddAndManageButton } from './AddAndManageButton';
import LeaseMenus from './LeaseMenus/LeaseMenus';
import {
  LeaseNavItemsElementsToShow,
  UnitNavItemsElementsToShow,
  getInventoryIdFromMatchParams,
  getLeaseNavItems,
  getUnitIdFromMatchParams,
  getUnitsNavItems,
} from './leaseDetailRouteUtils';

export const STICKY_HEADER_SIZE = 120;
export const LEFT_MENU_SIZE = 270;

export const fromStatusToColor = (status: LeaseStatus) => {
  if (status === LeaseStatus.Active) return Colors.TURQUOISE_BLUE;
  if (status === LeaseStatus.Rejected) return Colors.CARNATION_RED;
  if (status === LeaseStatus.Ended) return Colors.BLUEY;
  if (status === LeaseStatus.OutForSignature) return Colors.DODGER_BLUE;
  if (status === LeaseStatus.Draft) return Colors.CARROT_ORANGE;
  if (status === LeaseStatus.Cancelled) return Colors.CARNATION_RED;
  return Colors.CLASSICAL_WHITE;
};

const getTitleTranslationIdBasedOnPath = (path: string, leaseId: string) => {
  const eachWordInPath = path.split('/');
  let lastWordInPath = eachWordInPath[eachWordInPath.length - 1];
  if (isEmpty(lastWordInPath)) {
    lastWordInPath = eachWordInPath[eachWordInPath.length - 2];
  }
  if (lastWordInPath.startsWith('inventory-')) {
    lastWordInPath = 'inventory';
  }
  if (lastWordInPath === leaseId) {
    lastWordInPath = 'terms';
  }
  return `lease.detail.menu.${lastWordInPath}`;
};

interface LeaseDetailProps {
  id: string;
  onClose?: () => void;
}
const LeaseDetail: React.FC<LeaseDetailProps> = ({ id, onClose }) => {
  const scrollbarClasses = useNoScrollbarStyles();
  const {
    handleCloseAddEditUnitEventDialog,
    handleOpenAddUnitEventDialog,
    handleOpenEditUnitEventDialog,
    ...addEditEventDialogProps
  } = useAddEditUnitEventDialog();
  const { formatMessage } = useIntl();
  const { getLeasePriceHistoriesOfLease, loading: leasePriceHistoryLoading } = useLeasePriceHistories();
  const { getLeaseActionHistoriesOfLease } = useLeaseActionHistories();
  const { leaseVariousOperations, loading: leaseVariousOperationsLoading } = useLeaseVariousOperations();
  const { getTransactionsForLease } = useTransactions();
  const { getInvoicesOfLease } = useInvoices();
  const { statements, statementsLoading } = useStatements();
  const { getTechnicsFor } = useTechnics();
  const { getUnitTickets } = useTickets();
  const { getUnit } = useUnits();
  const [openEditLeaseName, setOpenEditLeaseName] = useState<boolean>(false);

  const operationsOfLease = leaseVariousOperations?.filter(
    (leaseVariousOperation) => leaseVariousOperation.lease?.id === id
  );
  const transactionsOfLease = getTransactionsForLease(id);
  const invoicesOfLease = getInvoicesOfLease(id);
  const statementsOfLease = statements.filter((statement) => statement.leaseId === id);
  const leaseActionHistories = getLeaseActionHistoriesOfLease(id);
  const isLeaseAlreadyEnded = hasLeaseAlreadyBeenEnded(leaseActionHistories);

  const match = useRouteMatch<{ id: string; detailName: string; nestedDetailName?: string }>({
    path: `${RouteDestination.LEASES}/detail/:id?/:detailName?/:nestedDetailName?`,
  });
  const { state: exitParams } = useLocation<ExitableLocation>();
  const history = useHistory();

  const permissions = usePermissions();
  const { features, isTenant } = useUser();

  const { unitsLoading } = useUnits();
  const { getLease, leasesLoading, leasesError } = useLeases();

  const loading = Boolean(
    leasesLoading ||
      permissions.loading ||
      unitsLoading ||
      leasePriceHistoryLoading ||
      leaseVariousOperationsLoading ||
      statementsLoading
  );
  const lease = getLease(id);

  if (isNil(match) || loading) {
    return <Skeleton />;
  }

  const [leaseInventories, leaseFurnitureInventories] = (lease?.leaseInventories ?? []).reduce(
    (acc: [LeaseInventoryExtended[], LeaseInventoryExtended[]], li: LeaseInventoryExtended) => {
      if (
        li.inventoryType === LeaseInventoryType.FIXTURES_ENTRANCE ||
        li.inventoryType === LeaseInventoryType.FIXTURES_EXIT
      ) {
        acc[0].push({ ...li, tenants: lease?.tenants });
      } else {
        acc[1].push({ ...li, tenants: lease?.tenants });
      }
      return acc;
    },
    [[], []]
  );

  const priceHistories = getLeasePriceHistoriesOfLease(match.params.id);
  const amountOfPriceHistories = priceHistories.length;
  const amountOfFilteredLeasePriceHistory = priceHistories.filter(
    (leasePriceHistory) =>
      (leasePriceHistory.amountDetails ?? []).length > 0 && leasePriceHistory.status === LeaseAmountUpdateStatus.DRAFT
  ).length;
  const leaseElementsToShow: Partial<LeaseNavItemsElementsToShow> = {
    shouldShowEndLease: isLeaseAlreadyEnded,
    shouldShowLeaseHistory: !isEmpty(leaseActionHistories),
    shouldShowIndexation: amountOfPriceHistories > 0,
    shouldShowOperations: !isEmpty(operationsOfLease),
    shouldShowFinancial: !isEmpty(transactionsOfLease) || !isEmpty(invoicesOfLease),
    shouldShowStatements: !isEmpty(statementsOfLease),
    shouldShowInventory: !isEmpty(leaseInventories) || !isEmpty(leaseFurnitureInventories),
    shouldShowDocuments: !isTenant,
  };
  const leaseNavItems = lease
    ? getLeaseNavItems(lease, permissions, features, leaseElementsToShow, amountOfFilteredLeasePriceHistory)
    : [];

  const specificUnitNavItems = (lease?.units ?? []).map((unitLease) => {
    const getAllUnitTechnics = getTechnicsFor(unitLease.unit?.id ?? '');
    const unitTechnics = getAllUnitTechnics.filter((technic) => technic.type !== TechnicType.UTILITY_PROVIDER);
    const unit = getUnit(unitLease?.unit?.id ?? '');
    const unitTickets = getUnitTickets(unitLease.unit?.id ?? '');
    const utilities = getAllUnitTechnics.filter((technic) => technic.type === TechnicType.UTILITY_PROVIDER);
    const unitElementsToShow: Partial<UnitNavItemsElementsToShow> = {
      shouldShowTechnics: !isEmpty(unitTechnics),
      shouldShowInventory: !isEmpty(unit?.unitInventories),
      shouldShowTickets: !isEmpty(unitTickets),
      shouldShowUtility: !isEmpty(utilities),
      shouldShowDocuments: !isTenant,
    };

    const unitsNavItems = lease ? getUnitsNavItems(permissions, features, unitElementsToShow) : [];

    return {
      unitLease,
      navItems: unitsNavItems,
    };
  });

  const canRenderLeasesRoutes = !leasesError && !leasesLoading;
  const canRenderUnitsRoutes = !leasesError && !unitsLoading;

  const unitId = getUnitIdFromMatchParams(match);
  const isInTermsTab = match && isNil(match.params.detailName);
  const leaseMenuShift = 35;
  const titleTranslationId = getTitleTranslationIdBasedOnPath(match.url, id);
  const showAddAndManageButton =
    lease &&
    ![LeaseStatus.Draft, LeaseStatus.OutForSignature, LeaseStatus.Cancelled, LeaseStatus.Rejected].includes(
      lease.status
    );

  return (
    <>
      <Grid
        style={{
          display: 'flex',
          height: '100%',
          overflow: 'auto',
          flexDirection: 'column',
        }}
      >
        <Grid
          style={{
            width: ENTITY_DETAIL_PANEL_SIZE, // Need to fix width as position is fixed
            overflow: 'hidden',
            position: 'fixed',
            display: 'flex',
          }}
        >
          {/* Header in fixed position */}
          <Grid style={{ width: LEFT_MENU_SIZE }}>
            <CustomIconButton
              id="exitBuildingDetails"
              style={{ marginLeft: 15, marginTop: 30, marginBottom: 40 }}
              aria-label="Menu"
              onClick={onClose}
              Icon={ArrowBack}
              iconStyle={{ fill: Colors.BLUE_GREY }}
            />
          </Grid>
          <Grid style={{ width: `calc(100% - ${LEFT_MENU_SIZE}px)` }}>
            <ContentDetailHeader
              loading={loading}
              toolbarStyle={{ borderRadius: 0, borderBottomLeftRadius: 10 }}
              title={match.url ? formatMessage({ id: titleTranslationId }) : ''}
              actions={
                titleTranslationId.endsWith('terms')
                  ? []
                  : [showAddAndManageButton && <AddAndManageButton key={'addAndManageButton'} lease={lease} />]
              }
            />
          </Grid>
        </Grid>

        {/* Menu, quick actions and Content */}
        <Grid
          style={{
            paddingTop: STICKY_HEADER_SIZE,
            height: `calc(100% - ${STICKY_HEADER_SIZE}px`,
            overflow: 'hidden',
          }}
        >
          <Grid style={{ display: 'flex', width: '100%', height: '100%' }}>
            <Grid
              style={{
                width: LEFT_MENU_SIZE,
                overflowY: 'auto',
                overflowX: 'hidden',
                position: 'relative',
                top: -leaseMenuShift,
                height: `calc(100% + ${leaseMenuShift}px)`,
              }}
              className={scrollbarClasses.noScrollbar}
            >
              <LeaseMenus
                loading={loading}
                lease={lease}
                leaseNavItems={leaseNavItems}
                specificUnitNavItems={specificUnitNavItems}
              />
            </Grid>

            <Grid
              style={{
                width: `calc(100% - ${LEFT_MENU_SIZE}px)`,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {/* Quick actions */}

              {lease && isInTermsTab && <LeaseDetailActionsMenu lease={lease} />}
              {/* Content */}
              <Grid
                style={{
                  width: `100%`,
                  backgroundColor: 'white',
                  borderTopLeftRadius: 10,
                  height: '100%',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                }}
                className={scrollbarClasses.noScrollbar}
              >
                <Grid
                  style={{
                    marginLeft: 30,
                    marginRight: 30,
                    marginTop: 24,
                  }}
                >
                  <Switch>
                    {/* Lease routes */}
                    {canRenderLeasesRoutes &&
                      leaseNavItems.map((m) => {
                        if (!m.permission) {
                          return null;
                        }
                        const path = match.path.replace(':detailName?/:nestedDetailName?', m.to);
                        const Comp = m.component;
                        return (
                          <Route
                            path={path}
                            key={m.to}
                            exact={m.exact}
                            render={() => {
                              return (
                                <Suspense fallback={<Skeleton />}>
                                  <Comp id={id} fromUnit={false} fromLease />
                                </Suspense>
                              );
                            }}
                          />
                        );
                      })}

                    {/* Units routes */}
                    {canRenderUnitsRoutes &&
                      specificUnitNavItems.map((unitNavItems) => {
                        return unitNavItems.navItems.map((unitNavItem) => {
                          const unitId = getUnitIdFromMatchParams(match);
                          if (!unitId) {
                            return null;
                          }
                          if (!unitNavItem.permission) {
                            return null;
                          }
                          const path = match.path.replace(':nestedDetailName?', unitNavItem.to);
                          const Comp = unitNavItem.component;
                          return (
                            <Route
                              path={path}
                              key={unitNavItem.to}
                              exact={unitNavItem.exact}
                              render={() => {
                                return (
                                  <Suspense fallback={<Skeleton />}>
                                    <Comp
                                      id={unitId}
                                      forBuilding={false}
                                      fromUnit
                                      fromLease
                                      openAddUnitEventDialog={handleOpenAddUnitEventDialog}
                                      openEditUnitEventDialog={handleOpenEditUnitEventDialog}
                                      multipleUnitOnLease={(lease?.units ?? []).length > 1}
                                      leaseId={lease?.id}
                                    />
                                  </Suspense>
                                );
                              }}
                            />
                          );
                        });
                      })}

                    {/* Inventorys routes */}
                    {canRenderLeasesRoutes && (
                      <>
                        <Route
                          path={match.path.replace(':nestedDetailName?', 'inventory-*')}
                          render={() => {
                            const inventoryId = getInventoryIdFromMatchParams(match);
                            const unitId = getUnitIdFromMatchParams(match);
                            if (!inventoryId || !unitId) {
                              return null;
                            }
                            return (
                              <EntityDetail
                                open={true}
                                onClose={() => {
                                  if (!isNil(exitParams) && !isNil(exitParams.goBackUrl))
                                    history.replace(exitParams.goBackUrl);
                                  else history.goBack();
                                }}
                              >
                                <LeaseInventoryDetail id={inventoryId} onClose={() => {}} unitId={unitId} />
                              </EntityDetail>
                            );
                          }}
                        />
                        <Route
                          path={match.path.replace(':nestedDetailName?', 'furniture-*')}
                          render={() => {
                            const inventoryId = getInventoryIdFromMatchParams(match);
                            const unitId = getUnitIdFromMatchParams(match);
                            if (!inventoryId || !unitId) {
                              return null;
                            }
                            return (
                              <EntityDetail
                                open={true}
                                onClose={() => {
                                  if (!isNil(exitParams) && !isNil(exitParams.goBackUrl))
                                    history.replace(exitParams.goBackUrl);
                                  else history.goBack();
                                }}
                              >
                                <FurnitureInventoryDetail id={inventoryId} onClose={() => {}} unitId={unitId} />
                              </EntityDetail>
                            );
                          }}
                        />
                      </>
                    )}

                    {/* Catch all route */}
                    <Route
                      path="*"
                      render={() => {
                        if (loading) {
                          return <SkeletonComponent loading={loading} />;
                        }
                        if (leasesError) {
                          return <Typography>{leasesError}</Typography>;
                        }
                        return <MenuNotFound />;
                      }}
                    />
                  </Switch>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {unitId && (
        <AddEditUnitEventDialog
          onClose={handleCloseAddEditUnitEventDialog}
          {...addEditEventDialogProps}
          unitId={unitId}
        />
      )}
      {lease && openEditLeaseName && (
        <EditLeaseNameDialog
          lease={lease}
          open={openEditLeaseName}
          onClose={() => {
            setOpenEditLeaseName(false);
          }}
        />
      )}
    </>
  );
};
export default LeaseDetail;
