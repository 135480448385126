import { ResourcesConfig } from 'aws-amplify';

import appConfig from './amplifyconfiguration.app.json';
import testConfig from './amplifyconfiguration.test.json';
import showcaseConfig from './amplifyconfiguration.showcase.json';
import developConfig from './amplifyconfiguration.develop.json';
import devhsConfig from './amplifyconfiguration.devhs.json';
import defaultConfig from '../amplifyconfiguration.json';

interface ResourcesConfigExtension {
  aws_user_files_s3_bucket: string;
  aws_project_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  aws_appsync_graphqlEndpoint: string;
}

function getAmplifyConfig(): ResourcesConfig & ResourcesConfigExtension {
  switch (process.env.CICD_AMPLIFY_ENV) {
    case 'app':
      return appConfig;
    case 'test':
      return testConfig;
    case 'showcase':
      return showcaseConfig;
    case 'devhs':
      return devhsConfig;
    case 'develop':
      return developConfig;
    default:
      return defaultConfig;
  }
}

export const AMPLIFY_CONFIG = getAmplifyConfig();
