import React from 'react';
import { UnitInventoryRoomType, UnitInventoryStructureType, Colors } from '@rentguru/commons-utils';
import * as Yup from 'yup';
import { FormikHelpers, Formik, Form, useFormikContext } from 'formik';
import isNil from 'lodash/isNil';
import FormikComboBox from '../../../ui/ComboBox/FormikTextComboBox';
import { useIntl } from 'react-intl';
import TextDetailEditable from '../../../ui/TextDetailEditable';
import { Divider } from '@material-ui/core';
import { ContentHeader, LoaderButton , ActionButton } from '@up2rent/ui';
import toUpper from 'lodash/toUpper';
import { ExtendedUnitInventory, ExtendedUnitInventoryStructure } from '../../../../hooks/UnitInventoryContext';
import { v4 as uuidv4 } from 'uuid';
import { InventoryOfFixturesFormik } from '../../EditInventory/EditInventory';

export interface AddUnitInventoryFormValues {
  roomName: string;
  roomType: UnitInventoryRoomType;
}

export const AddUnitInventorySchema = Yup.object()
  .shape({
    roomType: Yup.string().min(1).required(),
    roomName: Yup.string().min(1).required(),
  })
  .required();

interface AddRoomFormProps {
  onClose?: () => void;
  unitId: string;
  inside: boolean;
  addGeneralStructure?: boolean;
}

const initialValues: AddUnitInventoryFormValues = {
  roomType: UnitInventoryRoomType.ENTRANCE,
  roomName: '',
};

const AddRoomForm: React.FC<AddRoomFormProps> = ({ unitId, inside, onClose, addGeneralStructure = true }) => {
  const { formatMessage } = useIntl();
  const { values: parentValues, setFieldValue: setParentFieldValue } = useFormikContext<InventoryOfFixturesFormik>();

  const roomTypesInside = [
    { value: UnitInventoryRoomType.ENTRANCE, primary: formatMessage({ id: 'leaseInventory.roomType.entrance' }) },
    { value: UnitInventoryRoomType.LIVING, primary: formatMessage({ id: 'leaseInventory.roomType.living' }) },
    {
      value: UnitInventoryRoomType.LIVING_WITH_OPEN_KITCHEN,
      primary: formatMessage({ id: 'leaseInventory.roomType.living_with_open_kitchen' }),
    },
    { value: UnitInventoryRoomType.DINING, primary: formatMessage({ id: 'leaseInventory.roomType.dining' }) },
    { value: UnitInventoryRoomType.KITCHEN, primary: formatMessage({ id: 'leaseInventory.roomType.kitchen' }) },
    { value: UnitInventoryRoomType.OFFICE, primary: formatMessage({ id: 'leaseInventory.roomType.office' }) },
    { value: UnitInventoryRoomType.BEDROOM, primary: formatMessage({ id: 'leaseInventory.roomType.bedroom' }) },
    { value: UnitInventoryRoomType.BATHROOM, primary: formatMessage({ id: 'leaseInventory.roomType.bathroom' }) },
    { value: UnitInventoryRoomType.SHOWER, primary: formatMessage({ id: 'leaseInventory.roomType.shower' }) },
    { value: UnitInventoryRoomType.TOILET, primary: formatMessage({ id: 'leaseInventory.roomType.toilet' }) },
    { value: UnitInventoryRoomType.OTHER, primary: formatMessage({ id: 'leaseInventory.roomType.other' }) },
  ];
  const roomTypesOutside = [
    { value: UnitInventoryRoomType.CELLAR, primary: formatMessage({ id: 'leaseInventory.roomType.cellar' }) },
    { value: UnitInventoryRoomType.ATTIC, primary: formatMessage({ id: 'leaseInventory.roomType.attic' }) },
    { value: UnitInventoryRoomType.GARDEN, primary: formatMessage({ id: 'leaseInventory.roomType.garden' }) },
    { value: UnitInventoryRoomType.TERRACE, primary: formatMessage({ id: 'leaseInventory.roomType.terrace' }) },
    { value: UnitInventoryRoomType.PATIO, primary: formatMessage({ id: 'leaseInventory.roomType.patio' }) },
    { value: UnitInventoryRoomType.GARAGE, primary: formatMessage({ id: 'leaseInventory.roomType.garage' }) },
    { value: UnitInventoryRoomType.PARKING, primary: formatMessage({ id: 'leaseInventory.roomType.parking' }) },
    { value: UnitInventoryRoomType.OTHER, primary: formatMessage({ id: 'leaseInventory.roomType.other' }) },
  ];

  const handleCreate = async (
    values: AddUnitInventoryFormValues,
    { setSubmitting, setStatus }: FormikHelpers<AddUnitInventoryFormValues>
  ) => {
    const { roomType, roomName } = values;
    const newUnitInventoryId = `NEW-${uuidv4()}`;
    const newGeneralStructure = {
      id: `NEW-${uuidv4()}`,
      unitInventoryId: newUnitInventoryId,
      name: 'General structure',
      type: UnitInventoryStructureType.GENERAL,
      encodings: [],
      readId: '',
      clientId: '',
    } as ExtendedUnitInventoryStructure;
    const newUnitInventory = {
      id: newUnitInventoryId,
      roomName,
      roomType,
      unitId,
      inside,
      structures: addGeneralStructure ? [newGeneralStructure] : [],
      readId: '',
      clientId: '',
    } as ExtendedUnitInventory;

    const newUnitInventories = [...parentValues.localUnitInventories, newUnitInventory];
    setParentFieldValue('localUnitInventories', newUnitInventories);
    setStatus(true);
    setSubmitting(false);
    if (!isNil(onClose)) {
      onClose();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddUnitInventorySchema}
      onSubmit={handleCreate}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ errors, touched, status, isSubmitting }) => (
        <Form>
          <ContentHeader title={formatMessage({ id: 'leaseInventory.addRoom.title' })} />
          <div style={{ marginLeft: 30, marginRight: 30 }}>
            <FormikComboBox
              label={formatMessage({
                id: 'leaseInventory.addRoom.customRoomType',
              })}
              name="roomType"
              inputStyle={{ width: 280, maxWidth: 'none' }}
              data={inside ? roomTypesInside : roomTypesOutside}
              error={Boolean(errors.roomType && touched.roomType)}
            />
            <div style={{ alignItems: 'baseline' }}>
              <TextDetailEditable
                title={formatMessage({ id: 'leaseInventory.addRoom.roomName' })}
                editMode={true}
                name="roomName"
                type="text"
                style={{ width: 280, maxWidth: 'none' }}
                error={Boolean(errors.roomName && touched.roomName)}
              />
            </div>
          </div>
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          <div
            style={{
              marginRight: 30,
              marginBottom: 20,
              display: 'flex',
              float: 'right',
              alignItems: 'center',
            }}
          >
            <ActionButton
              onClick={() => {
                if (!isNil(onClose)) {
                  onClose();
                }
              }}
              style={{
                background: 'none',
                color: Colors.DARK_SLATE_GREY,
                marginRight: 20,
              }}
            >
              {toUpper(
                formatMessage({
                  id: 'bankAccount.addBankAccount.cancelLabel',
                })
              )}
            </ActionButton>
            <LoaderButton loading={isSubmitting} success={status}>
              {formatMessage({
                id: 'bankAccount.addBankAccount.submitLabel',
              })}
            </LoaderButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddRoomForm;
