/* eslint-disable no-redeclare */
import React, { useContext, useEffect, useMemo } from 'react';
import { useUser } from './UserContext';
import {
  syncLeaseContacts as syncQuery,
  getLeaseContact as getQuery,
  createLeaseContact as createMutation,
  updateLeaseContact as updateMutation,
  deleteLeaseContact as deleteMutation,
  CreateLeaseContactMutationVariables as CreateMutationVariables,
  DeleteLeaseContactMutationVariables as DeleteMutationVariables,
  CreateLeaseContactInput as CreateInput,
  cleanInputCreate,
  cleanInputUpdate,
  getReadId,
  getTableClientId,
  LeaseContact,
  isNilOrEmpty,
  Model,
  UserRightChangeEvent,
  ContactType,
  handleUserRightsChange,
  OnCreateLeaseContactSubscription,
  OnUpdateLeaseContactSubscription,
  OnDeleteLeaseContactSubscription,
  onCreateLeaseContact,
  onUpdateLeaseContact,
  onDeleteLeaseContact,
} from '@rentguru/commons-utils';
import { ContextLoaderAction, ContextLoaderStore, useContextLoader } from './ContextLoader';
import { usePermissions } from './utils/PermissionsContext';
import {
  deleteEntityWithFetchBefore,
  getFilterFieldNameForIndex,
  list,
  mutation,
  recordWasUpdated,
  useSubscriptions,
} from '@up2rent/fetch-utils';
import { isNil } from 'lodash';
import { useUsers } from './UsersContext';

const ENTITY_MODEL_NAME: Model = 'LeaseContact';

export type ActionLeaseContact =
  | {
      type: 'SHOULD_FETCH_LEASE_CONTACT' | 'IS_FETCHING_LEASE_CONTACT';
    }
  | {
      type: 'ADD_LEASE_CONTACT';
      payload: { leaseContact: LeaseContact };
    }
  | {
      type: 'FETCHED_LEASE_CONTACT';
      payload: { leaseContacts: LeaseContact[] };
    }
  | {
      type: 'UPDATE_LEASE_CONTACT';
      payload: { leaseContact: LeaseContact };
    }
  | {
      type: 'DELETE_LEASE_CONTACT';
      payload: { id: string };
    };

export interface LeaseContactContext extends LeaseContactState {
  createLeaseContact: (input: Omit<LeaseContact, 'id' | 'clientId' | 'readId'>) => Promise<LeaseContact>;
  getLeaseContact: (id: string) => LeaseContact | null | undefined;
  updateLeaseContact: (original: LeaseContact, updates: Partial<LeaseContact>) => Promise<LeaseContact>;
  deleteLeaseContact: (id: string) => Promise<LeaseContact>;
  setFetchLeaseContact: () => void;
}

interface LeaseContactState {
  leaseContacts: LeaseContact[];
  loading: boolean;
  shouldFetchLeaseContact: boolean;
  error: string | null;
}

export const leaseContactReducerDelegation = (
  state: ContextLoaderStore,
  action: ContextLoaderAction
): ContextLoaderStore => {
  switch (action.type) {
    case 'SHOULD_FETCH_LEASE_CONTACT':
      if (state.LeaseContact.loading || state.LeaseContact.shouldFetch) {
        return state;
      }
      return {
        ...state,
        LeaseContact: { ...state.LeaseContact, shouldFetch: true },
      };
    case 'IS_FETCHING_LEASE_CONTACT':
      if (state.LeaseContact.loading) {
        return state;
      }
      return {
        ...state,
        LeaseContact: { ...state.LeaseContact, loading: true },
      };
    case 'FETCHED_LEASE_CONTACT':
      return {
        ...state,
        LeaseContact: {
          ...state.LeaseContact,
          data: action.payload.leaseContacts,
          loading: false,
          shouldFetch: false,
          lastFetch: new Date(),
        },
      };
    case 'ADD_LEASE_CONTACT':
      // Check if already present - If already added by this user or coming from another user
      if (state.LeaseContact.data?.find((object) => object.id === action.payload.leaseContact.id)) {
        return state;
      }

      return {
        ...state,
        LeaseContact: {
          ...state.LeaseContact,
          data: [...state.LeaseContact.data, action.payload.leaseContact],
        },
      };
    case 'UPDATE_LEASE_CONTACT':
      // No data
      if (isNilOrEmpty(state.LeaseContact.data)) {
        return state;
      }

      // Already present and same object
      const currentObject = state.LeaseContact.data?.find((object) => object.id === action.payload.leaseContact.id);
      if (!currentObject) {
        return {
          ...state,
          LeaseContact: {
            ...state.LeaseContact,
            data: [...state.LeaseContact.data, action.payload.leaseContact],
          },
        };
      }
      if (!recordWasUpdated(currentObject, action.payload.leaseContact)) {
        return state;
      }

      // Update
      return {
        ...state,
        LeaseContact: {
          ...state.LeaseContact,
          data: state.LeaseContact.data.map((object) => {
            if (object.id === action.payload.leaseContact.id) {
              return action.payload.leaseContact;
            }
            return object;
          }),
        },
      };
    case 'DELETE_LEASE_CONTACT':
      if (isNilOrEmpty(state.LeaseContact.data)) {
        return state;
      }
      return {
        ...state,
        LeaseContact: {
          ...state.LeaseContact,
          data: state.LeaseContact.data.filter((object) => object.id !== action.payload.id),
        },
      };

    default:
      return state;
  }
};

export const fetchLeaseContacts = async (
  by: 'byClientId' | 'byLeaseId',
  byValue: string,
  additionalFilter?: object
): Promise<LeaseContact[]> => {
  const formattedByValue = by === 'byClientId' ? getTableClientId(byValue, ENTITY_MODEL_NAME) : byValue;
  return await list<LeaseContact>(syncQuery, getFilterFieldNameForIndex(by), formattedByValue, additionalFilter);
};

export const LeaseContactContext = React.createContext<LeaseContactContext | null>(null);

export const LeaseContactContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { clientId } = useUser();
  const { getUserFromContact } = useUsers();
  const {
    LeaseContact: { data: leaseContacts, loading: leaseContactsLoading, shouldFetch },
    dispatch: contextDispatch,
  } = useContextLoader();
  const { leasesCreationDelete } = usePermissions();

  const setFetchLeaseContact = () => {
    contextDispatch({ type: 'SHOULD_FETCH_LEASE_CONTACT' });
  };

  const loading = leaseContactsLoading;

  useEffect(() => {
    const fetchAndSet = async () => {
      contextDispatch({ type: 'IS_FETCHING_LEASE_CONTACT' });
      const result = await fetchLeaseContacts('byClientId', clientId!);
      contextDispatch({ type: 'FETCHED_LEASE_CONTACT', payload: { leaseContacts: result } });
    };
    if (shouldFetch) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch]);

  useSubscriptions<
    OnCreateLeaseContactSubscription,
    OnUpdateLeaseContactSubscription,
    OnDeleteLeaseContactSubscription
  >(
    onCreateLeaseContact,
    onUpdateLeaseContact,
    onDeleteLeaseContact,
    (data) => {
      contextDispatch({
        type: 'ADD_LEASE_CONTACT',
        payload: { leaseContact: data.onCreateLeaseContact as LeaseContact },
      });
    },
    (data) => {
      contextDispatch({
        type: 'UPDATE_LEASE_CONTACT',
        payload: { leaseContact: data.onUpdateLeaseContact as LeaseContact },
      });
    },
    (data) => {
      const { id } = data.onDeleteLeaseContact as LeaseContact;
      contextDispatch({
        type: 'DELETE_LEASE_CONTACT',
        payload: { id },
      });
    }
  );

  const createLeaseContact = async (input: Omit<LeaseContact, 'id' | 'clientId' | 'readId'>): Promise<LeaseContact> => {
    const leaseContact = await mutation<LeaseContact, CreateMutationVariables>(createMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateInput),
        clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME),
        readId: getReadId(clientId!, ENTITY_MODEL_NAME),
      },
    });
    contextDispatch({ type: 'ADD_LEASE_CONTACT', payload: { leaseContact } });
    return leaseContact;
  };

  const getLeaseContact = (id: string) => {
    if (loading) {
      return null;
    }
    return leaseContacts.find((stateObject) => stateObject.id === id);
  };

  const updateLeaseContact = async (original: LeaseContact, updates: Partial<LeaseContact>): Promise<LeaseContact> => {
    const result = await mutation<LeaseContact>(updateMutation, {
      input: { ...cleanInputUpdate({ id: original.id, _version: original._version, ...updates }, false) },
    });
    contextDispatch({ type: 'UPDATE_LEASE_CONTACT', payload: { leaseContact: result } });
    return result;
  };

  const deleteLeaseContact = async (id: string): Promise<LeaseContact> => {
    const leaseContact = getLeaseContact(id)!;
    if (!leasesCreationDelete) {
      return leaseContact;
    }

    if (!isNil(leaseContact) && leaseContact.contactRole === ContactType.TENANT) {
      const tenantUser = getUserFromContact(leaseContact.contact!.id);
      if (!isNil(tenantUser)) {
        await mutation(handleUserRightsChange, {
          input: {
            userId: tenantUser.id,
            event: UserRightChangeEvent.DELETE_TENANT_LEASE_ACCESS,
            clientId,
            leaseId: leaseContact.lease!.id,
          },
        });
      }
    }

    await deleteEntityWithFetchBefore<Pick<LeaseContact, 'id'>, DeleteMutationVariables>(
      { id },
      getQuery,
      deleteMutation
    );

    contextDispatch({ type: 'DELETE_LEASE_CONTACT', payload: { id } });
    return leaseContact;
  };

  const values = useMemo(
    () => ({
      leaseContacts: leaseContacts as LeaseContact[],
      createLeaseContact,
      getLeaseContact,
      updateLeaseContact,
      deleteLeaseContact,
      setFetchLeaseContact,
      error: null,
      loading,
      shouldFetchLeaseContact: shouldFetch,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [leaseContacts, loading, shouldFetch]
  );

  return <LeaseContactContext.Provider value={values}>{children}</LeaseContactContext.Provider>;
};

export const useLeaseContacts = (): LeaseContactContext => {
  const context = useContext<LeaseContactContext | null>(LeaseContactContext);

  if (context === undefined) {
    throw new Error('`useLeaseContacts` hook must be used within a `LeaseContactContextProvider` component');
  }
  return context as LeaseContactContext;
};
