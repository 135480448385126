/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  EmptyFile,
  EntityType,
  FileCategory,
  File as FileModel,
  InventoryCustomItem,
  LeaseInventoryCheckedItem,
  LeaseInventoryEncoding,
  UnitInventory,
  UnitInventoryRoomType,
  UnitInventoryStructure,
  isIdInList,
  shouldUpdate,
} from '@rentguru/commons-utils';
import { downloadData } from 'aws-amplify/storage';
import { isEqual, orderBy } from 'lodash';
import isNil from 'lodash/isNil';
import { IntlFormatters } from 'react-intl';
import { LeaseInventoryExtended } from 'src/components/RentalUnits/Details/Inventory/Inventory';
import { FilesContext } from 'src/hooks/FilesContext';
import { LeaseInventoryCheckedItemsContext } from 'src/hooks/LeaseInventoryCheckedItemContext';
import { LeaseInventoryEncodingsContext } from 'src/hooks/LeaseInventoryEncodingContext';
import {
  ExtendedLeaseInventoryEncoding,
  ExtendedUnitInventory,
  ExtendedUnitInventoryStructure,
  UnitInventoryContext,
} from '../hooks/UnitInventoryContext';
import { ReactComponent as UnitInventoryAtticIcon } from '../icons/unitInventory_attic.svg';
import { ReactComponent as UnitInventoryBalconyIcon } from '../icons/unitInventory_balcony.svg';
import { ReactComponent as UnitInventoryBathIcon } from '../icons/unitInventory_bath.svg';
import { ReactComponent as UnitInventoryBedIcon } from '../icons/unitInventory_bed.svg';
import { ReactComponent as UnitInventoryCellarIcon } from '../icons/unitInventory_cellar.svg';
import { ReactComponent as UnitInventoryDiningIcon } from '../icons/unitInventory_dining.svg';
import { ReactComponent as UnitInventoryEntranceIcon } from '../icons/unitInventory_entrance.svg';
import { ReactComponent as UnitInventoryOtherIcon } from '../icons/unitInventory_floorplan.svg';
import { ReactComponent as UnitInventoryGarageIcon } from '../icons/unitInventory_garage.svg';
import { ReactComponent as UnitInventoryGardenIcon } from '../icons/unitInventory_garden.svg';
import { ReactComponent as UnitInventoryKitchenIcon } from '../icons/unitInventory_kitchen.svg';
import { ReactComponent as UnitInventoryLivingIcon } from '../icons/unitInventory_living.svg';
import { ReactComponent as UnitInventoryOfficeIcon } from '../icons/unitInventory_office.svg';
import { ReactComponent as UnitInventoryPatioIcon } from '../icons/unitInventory_patio-terrace.svg';
import { ReactComponent as UnitInventoryShowerIcon } from '../icons/unitInventory_shower.svg';
import { ReactComponent as UnitInventoryToiletIcon } from '../icons/unitInventory_toilet.svg';

type groupedByStructureType = {
  [a in UnitInventoryRoomType]?: number;
};

type GroupedByStructure = {
  [a in UnitInventoryRoomType]?: number;
};

interface ObjectWithBody {
  Body: Blob;
}

export const generalRooms = {
  inside: [
    UnitInventoryRoomType.ENTRANCE,
    UnitInventoryRoomType.LIVING,
    UnitInventoryRoomType.DINING,
    UnitInventoryRoomType.KITCHEN,
    UnitInventoryRoomType.LIVING_WITH_OPEN_KITCHEN,
    UnitInventoryRoomType.OFFICE,
    UnitInventoryRoomType.BEDROOM,
    UnitInventoryRoomType.BATHROOM,
    UnitInventoryRoomType.SHOWER,
    UnitInventoryRoomType.TOILET,
    UnitInventoryRoomType.GARAGE,
  ],
  outside: [
    UnitInventoryRoomType.CELLAR,
    UnitInventoryRoomType.ATTIC,
    UnitInventoryRoomType.GARDEN,
    UnitInventoryRoomType.TERRACE,
    UnitInventoryRoomType.PATIO,
    UnitInventoryRoomType.GARAGE,
    UnitInventoryRoomType.PARKING,
    UnitInventoryRoomType.BALCONY,
  ],
};

export const commercialRooms = {
  inside: [
    UnitInventoryRoomType.KITCHEN,
    UnitInventoryRoomType.SHOWER,
    UnitInventoryRoomType.TOILET,
    UnitInventoryRoomType.REUNION,
    UnitInventoryRoomType.TECHNICAL,
    UnitInventoryRoomType.INDIVIDUAL_OFFICE,
    UnitInventoryRoomType.OPENSPACE,
    UnitInventoryRoomType.WAREHOUSE,
  ],
  outside: [
    UnitInventoryRoomType.ATTIC,
    UnitInventoryRoomType.GARDEN,
    UnitInventoryRoomType.TERRACE,
    UnitInventoryRoomType.PARKING,
  ],
};

export const getIconOfInventory = (inventoryType: string) => {
  switch (inventoryType) {
    case UnitInventoryRoomType.ENTRANCE:
      return UnitInventoryEntranceIcon;
    case UnitInventoryRoomType.LIVING:
    case UnitInventoryRoomType.LIVING_WITH_OPEN_KITCHEN:
      return UnitInventoryLivingIcon;
    case UnitInventoryRoomType.DINING:
      return UnitInventoryDiningIcon;
    case UnitInventoryRoomType.KITCHEN:
      return UnitInventoryKitchenIcon;
    case UnitInventoryRoomType.OFFICE:
      return UnitInventoryOfficeIcon;
    case UnitInventoryRoomType.BEDROOM:
      return UnitInventoryBedIcon;
    case UnitInventoryRoomType.BATHROOM:
      return UnitInventoryBathIcon;
    case UnitInventoryRoomType.SHOWER:
      return UnitInventoryShowerIcon;
    case UnitInventoryRoomType.TOILET:
      return UnitInventoryToiletIcon;
    case UnitInventoryRoomType.CELLAR:
      return UnitInventoryCellarIcon;
    case UnitInventoryRoomType.GARDEN:
      return UnitInventoryGardenIcon;
    case UnitInventoryRoomType.TERRACE:
      return UnitInventoryPatioIcon;
    case UnitInventoryRoomType.ATTIC:
      return UnitInventoryAtticIcon;
    case UnitInventoryRoomType.PATIO:
      return UnitInventoryPatioIcon;
    case UnitInventoryRoomType.GARAGE:
      return UnitInventoryGarageIcon;
    case UnitInventoryRoomType.PARKING:
      return UnitInventoryGarageIcon;
    case UnitInventoryRoomType.OTHER:
      return UnitInventoryOtherIcon;
    case UnitInventoryRoomType.BALCONY:
      return UnitInventoryBalconyIcon;
    case 'OTHER_INSIDE':
      return UnitInventoryOtherIcon;
    case 'OTHER_OUTSIDE':
      return UnitInventoryOtherIcon;
    default:
      return UnitInventoryOtherIcon;
  }
};

export const groupInventoriesByType = (unitInventories: UnitInventory[], returnType: 'array' | 'object' = 'object') => {
  const groupByCounts = unitInventories.reduce((acc: groupedByStructureType, unitInventory: UnitInventory) => {
    const type = unitInventory.roomType;
    if (Object.keys(acc).includes(type)) acc[type] = acc[type]! + 1;
    else acc[type] = 1;
    return acc;
  }, {});
  const sortedObjectByKeyToArray = Object.entries(groupByCounts).sort();
  if (returnType === 'object') return Object.fromEntries(sortedObjectByKeyToArray);
  return sortedObjectByKeyToArray;
};

export const groupInventoriesByTypeAndOther = (
  unitInventories: UnitInventory[],
  formatMessage: IntlFormatters['formatMessage']
) => {
  const groupByCounts = unitInventories.reduce(
    (acc, currentUnitInventory) => {
      const isOther = currentUnitInventory.roomType === UnitInventoryRoomType.OTHER;
      const newType = isOther ? currentUnitInventory.roomName ?? '' : currentUnitInventory.roomType;

      const inventoryName = !isOther
        ? formatMessage({ id: `leaseInventory.roomType.${newType.toLowerCase()}` })
        : newType;

      const inside = currentUnitInventory.inside;
      const surface = currentUnitInventory.surface ?? 0;
      const array = inside ? acc.inside : acc.outside;

      const alreadyPresent = array.find((object) => object.type === newType);
      if (alreadyPresent) {
        alreadyPresent.unitInventories.push(currentUnitInventory);
        alreadyPresent.totalSurface += surface;
        return acc;
      }
      array.push({
        type: newType,
        unitInventories: [currentUnitInventory],
        totalSurface: surface,
        name: inventoryName,
      });

      return acc;
    },
    { inside: [], outside: [] } as {
      inside: {
        type: UnitInventoryRoomType | string;
        name: string;
        unitInventories: UnitInventory[];
        totalSurface: number;
      }[];
      outside: {
        type: UnitInventoryRoomType | string;
        name: string;
        unitInventories: UnitInventory[];
        totalSurface: number;
      }[];
    }
  );

  const { inside, outside } = groupByCounts;
  const orderedInside = orderBy(inside, 'name');
  const orderedOutside = orderBy(outside, 'name');

  return { inside: orderedInside, outside: orderedOutside };
};

export const sortInventoriesByType = (unitInventories: UnitInventory[]) => {
  return unitInventories.sort((ui1, ui2) => ui1.roomType.localeCompare(ui2.roomType));
};

export interface CustomItemIdMapping {
  localId: string;
  cloudItem: InventoryCustomItem;
}
export interface SyncUnitInventoriesOperationsBundle {
  createUnitInventory: UnitInventoryContext['createUnitInventory'];
  createUnitInventoryStructure: UnitInventoryContext['createUnitInventoryStructure'];
  updateUnitInventoryStructure: UnitInventoryContext['updateUnitInventoryStructure'];
  deleteUnitInventoryStructure: UnitInventoryContext['deleteUnitInventoryStructure'];
  createLeaseInventoryEncoding: LeaseInventoryEncodingsContext['createLeaseInventoryEncoding'];
  updateLeaseInventoryEncoding: LeaseInventoryEncodingsContext['updateLeaseInventoryEncoding'];
  createLeaseInventoryCheckedItem: LeaseInventoryCheckedItemsContext['createLeaseInventoryCheckedItem'];
  updateLeaseInventoryCheckedItem: LeaseInventoryCheckedItemsContext['updateLeaseInventoryCheckedItem'];
  deleteLeaseInventoryCheckedItem: LeaseInventoryCheckedItemsContext['deleteLeaseInventoryCheckedItem'];
  createFile: FilesContext['createFile'];
  deleteFile: FilesContext['deleteFile'];
}
export interface SyncInventoryCustomItemOperationsBundle {
  createInventoryCustomItem: (
    input: Omit<InventoryCustomItem, 'id' | 'clientId' | 'readId'>
  ) => Promise<InventoryCustomItem>;
}

const createExtendedUnitInventory = async (
  unitInventory: ExtendedUnitInventory,
  operationsBundle: SyncUnitInventoriesOperationsBundle,
  customItemsIdMappings: CustomItemIdMapping[]
): Promise<ExtendedUnitInventory> => {
  const { createUnitInventory } = operationsBundle;
  const { structures, ...unitInventoryWithoutLinks } = unitInventory;
  const newUnitInventory = await createUnitInventory(unitInventoryWithoutLinks, false);
  const newUnitInventoryStructuresPromises: Promise<ExtendedUnitInventoryStructure>[] = [];
  for (const structure of unitInventory.structures) {
    newUnitInventoryStructuresPromises.push(
      createExtendedUnitInventoryStructure(structure, newUnitInventory.id, operationsBundle, customItemsIdMappings)
    );
  }
  const newUnitInventoryStructures = await Promise.all(newUnitInventoryStructuresPromises);
  return { ...newUnitInventory, structures: newUnitInventoryStructures };
};

const createExtendedUnitInventoryStructure = async (
  unitInventoryStructure: ExtendedUnitInventoryStructure,
  foreignUnitIventoryId: string,
  operationsBundle: SyncUnitInventoriesOperationsBundle,
  customItemsIdMappings: CustomItemIdMapping[]
): Promise<ExtendedUnitInventoryStructure> => {
  const { createUnitInventoryStructure } = operationsBundle;
  const { encodings, unitInventoryId, ...unitInventoryStructureWithoutLinks } = unitInventoryStructure;
  const newUnitInventoryStructure = await createUnitInventoryStructure({
    ...unitInventoryStructureWithoutLinks,
    unitInventoryId: foreignUnitIventoryId,
  });
  const newLeaseInventoryEncodingPromises: Promise<ExtendedLeaseInventoryEncoding>[] = [];
  for (const encoding of unitInventoryStructure.encodings) {
    newLeaseInventoryEncodingPromises.push(
      createExtendedLeaseInventoryEncoding(
        encoding,
        newUnitInventoryStructure.id,
        operationsBundle,
        customItemsIdMappings
      )
    );
  }
  const newUnitInventoryEncodings = await Promise.all(newLeaseInventoryEncodingPromises);
  return { ...newUnitInventoryStructure, encodings: newUnitInventoryEncodings };
};

const createExtendedLeaseInventoryEncoding = async (
  leaseInventoryEncoding: ExtendedLeaseInventoryEncoding,
  foreignStructureId: string,
  operationsBundle: SyncUnitInventoriesOperationsBundle,
  customItemsIdMappings: CustomItemIdMapping[],
  copyFiles?: boolean
): Promise<ExtendedLeaseInventoryEncoding> => {
  const { createLeaseInventoryEncoding, createFile } = operationsBundle;
  const { checkedItems, structureId, medias, furniturePrice, ...unitInventoryStructureWithoutLinks } =
    leaseInventoryEncoding;
  // stored as string in formik
  const cleanedFurniturePrice = !isNil(furniturePrice) ? Number(furniturePrice) : undefined;
  const newLeaseInventoryEncoding = await createLeaseInventoryEncoding({
    ...unitInventoryStructureWithoutLinks,
    ...(!isNil(furniturePrice) && { furniturePrice: cleanedFurniturePrice }),
    structureId: foreignStructureId,
  });
  const newCheckedItemsPromises: Promise<LeaseInventoryCheckedItem>[] = [];
  for (const checkedItem of leaseInventoryEncoding.checkedItems) {
    newCheckedItemsPromises.push(
      createCheckedItem(checkedItem, newLeaseInventoryEncoding.id, operationsBundle, customItemsIdMappings)
    );
  }
  const newMediasPromises: Promise<FileModel | null>[] = [];
  if (!isNil(copyFiles) && copyFiles && !isNil(medias)) {
    for (const media of !isNil(medias) ? medias : []) {
      const blobRes = await downloadData({ key: media.key }).result;
      const blobToCopy = await blobRes.body.blob();
      const fileName = media.key.split('_')[1];
      const fileToCopy = new File([blobToCopy], fileName, { type: media.mimeType! });
      newMediasPromises.push(
        createFile(
          fileToCopy as File,
          EntityType.ENCODING,
          newLeaseInventoryEncoding.id,
          FileCategory.INVENTORY_OF_FIXTURES
        )
      );
    }
  } else {
    for (const media of !isNil(medias) ? medias : []) {
      newMediasPromises.push(
        createFile(
          (media as unknown as EmptyFile).file,
          EntityType.ENCODING,
          newLeaseInventoryEncoding.id,
          FileCategory.INVENTORY_OF_FIXTURES
        )
      );
    }
  }

  const newEncodingCheckedItemsPromise = Promise.all(newCheckedItemsPromises);
  const newEncodingFilesPromise = Promise.all(newMediasPromises);
  const [newEncodingCheckedItems, newEncodingFiles] = await Promise.all([
    newEncodingCheckedItemsPromise,
    newEncodingFilesPromise,
  ]);
  return {
    ...newLeaseInventoryEncoding,
    checkedItems: newEncodingCheckedItems,
    medias: newEncodingFiles as FileModel[],
  };
};

const createCheckedItem = async (
  checkedItem: LeaseInventoryCheckedItem,
  encodingForeignId: string,
  operationsBundle: SyncUnitInventoriesOperationsBundle,
  customItemsIdMappings: CustomItemIdMapping[]
): Promise<LeaseInventoryCheckedItem> => {
  const { createLeaseInventoryCheckedItem } = operationsBundle;
  let checkedItemCleaned;
  if (checkedItem.custom && !isNil(checkedItem.customItem)) {
    const { leaseInventoryEncodingId, customItem, ...rest } = checkedItem;
    if (customItem.id.startsWith('NEW')) {
      checkedItemCleaned = {
        ...rest,
        leaseInventoryEncodingId: encodingForeignId,
        customItemId: customItemsIdMappings.find((ci) => ci.localId === customItem.id)!.cloudItem.id,
      };
    } else {
      checkedItemCleaned = { ...rest, leaseInventoryEncodingId: encodingForeignId, customItemId: customItem.id };
    }
  } else {
    const { leaseInventoryEncodingId, customItem, ...rest } = checkedItem;
    checkedItemCleaned = { ...rest, leaseInventoryEncodingId: encodingForeignId };
  }
  return createLeaseInventoryCheckedItem(checkedItemCleaned);
};

const createInventoryCustomItemAndLinkId = async (
  localCustomItem: InventoryCustomItem,
  createInventoryCustomItem: (
    input: Omit<InventoryCustomItem, 'id' | 'clientId' | 'readId'>
  ) => Promise<InventoryCustomItem>
) => {
  const newCustomItem = await createInventoryCustomItem(localCustomItem);
  return { localId: localCustomItem.id, cloudItem: newCustomItem };
};

export const syncUnitInventories = async (
  localUnitInventories: ExtendedUnitInventory[],
  lastSyncUnitInventories: ExtendedUnitInventory[],
  operationsBundle: SyncUnitInventoriesOperationsBundle,
  customItemsIdMappings: CustomItemIdMapping[],
  categoryId: string
) => {
  const {
    updateUnitInventoryStructure,
    deleteUnitInventoryStructure,
    updateLeaseInventoryEncoding,
    createFile,
    deleteFile,
    updateLeaseInventoryCheckedItem,
    deleteLeaseInventoryCheckedItem,
  } = operationsBundle;
  const unitInventoriesPromises: Promise<void | ExtendedUnitInventory>[] = [];
  /* UnitInventory */
  for (const localUnitInventory of localUnitInventories) {
    const originalUnitInventory = lastSyncUnitInventories.find((ui) => ui.id === localUnitInventory.id);
    // is it a new unitInventory ?
    if (isNil(originalUnitInventory)) {
      unitInventoriesPromises.push(
        createExtendedUnitInventory(localUnitInventory, operationsBundle, customItemsIdMappings)
      );
    }
    // The unit inventory already existed. Let's dig in its structure
    else {
      const unitInventoryStructuresPromises: Promise<void | ExtendedUnitInventoryStructure | UnitInventoryStructure>[] =
        [];
      for (const structure of localUnitInventory.structures) {
        const originalStructure = originalUnitInventory.structures.find((uis) => uis.id === structure.id);
        // is it a new structure ?
        if (isNil(originalStructure)) {
          unitInventoryStructuresPromises.push(
            createExtendedUnitInventoryStructure(
              structure,
              localUnitInventory.id,
              operationsBundle,
              customItemsIdMappings
            )
          );
        } else {
          const leaseInventoryEncodingsPromises: Promise<void | LeaseInventoryEncoding | UnitInventoryStructure>[] = [];
          // The structure already exists => Should it be updated?
          const structureUpdates = { name: structure.name, brand: structure.brand };
          if (shouldUpdate(originalStructure, structureUpdates)) {
            leaseInventoryEncodingsPromises.push(updateUnitInventoryStructure(originalStructure, structureUpdates));
          }
          // Let's dig in its encoding
          for (const encoding of structure.encodings) {
            const originalEncoding = originalStructure.encodings.find((enc) => enc.id === encoding.id);
            // is it a new encoding ?
            if (isNil(originalEncoding)) {
              leaseInventoryEncodingsPromises.push(
                createExtendedLeaseInventoryEncoding(encoding, structure.id, operationsBundle, customItemsIdMappings)
              );
            }
            // The encoding already exists
            else {
              const newCheckedItemsPromises: Promise<void | FileModel | LeaseInventoryCheckedItem | null>[] = [];
              const {
                checkedItems: originalCheckeditems,
                medias: originalMedias,
                furnitureExitIssues: originalIssues,
                furniturePrice: originalFurniturePrice,
                ...restOriginalEncoding
              } = originalEncoding;
              const { checkedItems, medias, furnitureExitIssues: issues, furniturePrice, ...restEncoding } = encoding;
              const checkedItemCleaned = !isNil(checkedItems) ? checkedItems : [];
              const mediasClean = (!isNil(medias) ? medias : []) as unknown as (EmptyFile | FileModel)[];
              const originalCheckeditemsCleaned = !isNil(originalCheckeditems) ? originalCheckeditems : [];
              const originalMediasClean = !isNil(originalMedias) ? originalMedias : [];
              const cleanedFurniturePrice = !isNil(furniturePrice) ? Number(furniturePrice) : undefined;
              const issuesHaveChanged = !isEqual(issues, originalIssues);
              const furniturePriceHasChanged = cleanedFurniturePrice !== originalFurniturePrice;
              // Has the encoding been modified ?
              if (shouldUpdate(restOriginalEncoding, restEncoding) || issuesHaveChanged || furniturePriceHasChanged) {
                leaseInventoryEncodingsPromises.push(
                  updateLeaseInventoryEncoding(originalEncoding, {
                    ...restEncoding,
                    ...(issuesHaveChanged && { furnitureExitIssues: issues }),
                    ...(furniturePriceHasChanged && { furniturePrice: cleanedFurniturePrice }),
                  })
                );
              }
              // Do we need to create new files ?
              for (const media of mediasClean) {
                const originalFile = originalMediasClean.find((m) => m.id === media.id);
                // Is it a new file?
                if (isNil(originalFile)) {
                  newCheckedItemsPromises.push(
                    createFile((media as EmptyFile).file, EntityType.ENCODING, encoding.id, categoryId)
                  );
                }
              }
              // Do we need to remove some files ?
              for (const originalMedia of originalMediasClean) {
                // If the original media is not in the list anymore => delete It
                if (!isIdInList(originalMedia, mediasClean)) {
                  newCheckedItemsPromises.push(deleteFile(originalMedia));
                }
              }
              for (const checkedItem of checkedItemCleaned) {
                const originalCheckedItem = originalCheckeditemsCleaned.find((och) => och.id === checkedItem.id);
                // Is it a new checkedItem ?
                if (isNil(originalCheckedItem)) {
                  newCheckedItemsPromises.push(
                    createCheckedItem(checkedItem, encoding.id, operationsBundle, customItemsIdMappings)
                  );
                } else {
                  // Should we update the existing checkedItem ?
                  const { customItem, ...checkedItemWithoutLinks } = checkedItem;
                  const { customItem: _, ...originalCheckedItemWithoutLinks } = originalCheckedItem;
                  if (shouldUpdate(originalCheckedItemWithoutLinks, checkedItemWithoutLinks)) {
                    newCheckedItemsPromises.push(
                      updateLeaseInventoryCheckedItem(originalCheckedItemWithoutLinks, checkedItemWithoutLinks)
                    );
                  }
                }
              }
              // Do we need to remove some checked items ?
              for (const originalCheckedItem of originalCheckeditemsCleaned) {
                // If the original checkedItem is not in the list anymore => delete It
                if (!isIdInList(originalCheckedItem, checkedItems)) {
                  newCheckedItemsPromises.push(deleteLeaseInventoryCheckedItem(originalCheckedItem.id));
                }
              }
              await Promise.all(newCheckedItemsPromises);
            }
          }
          await Promise.all(leaseInventoryEncodingsPromises);
        }
      }
      // Do we need to remove some structures?
      for (const originalStructure of originalUnitInventory.structures) {
        // If the original structure is not in the list anymore => delete It
        if (!isIdInList(originalStructure, localUnitInventory.structures)) {
          unitInventoryStructuresPromises.push(deleteUnitInventoryStructure(originalStructure.id));
        }
      }
      await Promise.all(unitInventoryStructuresPromises);
    }
  }
  await Promise.all(unitInventoriesPromises);
};

export const syncCustomItems = async (
  localCustomItems: InventoryCustomItem[],
  lastSyncCustomItems: InventoryCustomItem[],
  operationsBundle: SyncInventoryCustomItemOperationsBundle
) => {
  const { createInventoryCustomItem } = operationsBundle;
  const allOperationPromises: Promise<void>[] = [];
  const customItemsIdMappings: CustomItemIdMapping[] = [];
  const newCustomItems: InventoryCustomItem[] = [];
  for (const localCustomItem of localCustomItems) {
    const originalCustomItem = lastSyncCustomItems.find((ui) => ui.id === localCustomItem.id);
    // is it a new custom item ?
    if (isNil(originalCustomItem)) {
      allOperationPromises.push(
        createInventoryCustomItemAndLinkId(localCustomItem, createInventoryCustomItem).then((customIdMappingObject) => {
          customItemsIdMappings.push(customIdMappingObject);
          newCustomItems.push(customIdMappingObject.cloudItem);
        })
      );
    } else {
      newCustomItems.push(localCustomItem);
    }
  }
  await Promise.all(allOperationPromises);
  return { customItemsIdMappings };
};

export const copyLastUnitInventories = async (
  leaseInventory: LeaseInventoryExtended,
  latestUnitInventories: ExtendedUnitInventory[],
  operationsBundle: SyncUnitInventoriesOperationsBundle,
  customItemsIdMappings: CustomItemIdMapping[]
) => {
  const leaseInventoryEncodingsPromises: Promise<void | LeaseInventoryEncoding>[] = [];
  /* UnitInventory */
  for (const localUnitInventory of latestUnitInventories) {
    for (const structure of localUnitInventory.structures) {
      for (const encoding of structure.encodings) {
        const encodingWithNewLeaseInventoryId = { ...encoding, leaseInventoryId: leaseInventory.id };
        // copy encoding with new lease inventory id
        leaseInventoryEncodingsPromises.push(
          createExtendedLeaseInventoryEncoding(
            encodingWithNewLeaseInventoryId,
            structure.id,
            operationsBundle,
            customItemsIdMappings,
            true
          )
        );
      }
    }
  }
  await Promise.all(leaseInventoryEncodingsPromises);
};
