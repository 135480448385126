import { ComponentType } from 'react';

export const retryLazy = async <T>(
  importFunction: () => Promise<{ default: ComponentType<T> }>,
  retries = 5,
  interval = 1000
): Promise<{ default: ComponentType<T> }> => {
  try {
    return await importFunction();
  } catch (error) {
    if (retries) {
      await wait(interval);
      return retryLazy(importFunction, retries - 1, interval);
    }
    // @ts-ignore
    return import('src/components/Routes/LazyError');
  }
};

const wait = (time: number) => {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, time));
};
