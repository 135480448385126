import React, { useState } from 'react';
import { ElevatedPaper, CustomizedTabs, ContentHeader , ActionButton } from '@up2rent/ui';
import { useIntl } from 'react-intl';
import { useVariousOperations } from 'src/hooks/VariousOperationsContext';
import { Divider, Tab, Typography } from '@material-ui/core';
import Dialog from 'src/components/ui/Dialog';
import VariousOperationRow from './VariousOperations/VariousOperationRow';
import { isNil } from 'lodash';
import DeleteVariousOperation from './VariousOperations/DeleteVariousOperation';
import AddEditVariousOperation from './VariousOperations/AddEditVariousOperation';
import { LeaseVariousOperationType } from '@rentguru/commons-utils';

enum TabIndex {
  CHARGES,
  SERVICES,
}

const VariousOperationsSettings: React.FC = () => {
  const { formatMessage } = useIntl();
  const { loading, error, variousOperations } = useVariousOperations();
  const [addEditDialogOpen, setAddEditDialogOpen] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<string | null>(null);
  const [tabIndex, setTabIndex] = useState<TabIndex>(TabIndex.CHARGES);

  if (loading || !variousOperations) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{formatMessage({ id: 'loading' })}</Typography>
      </ElevatedPaper>
    );
  }
  if (error) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{error}</Typography>
      </ElevatedPaper>
    );
  }

  const doAction = (action: 'EDIT' | 'DELETE', id: string) => {
    if (action === 'EDIT') {
      setAddEditDialogOpen(id);
    } else if (action === 'DELETE') {
      setDeleteDialogOpen(id);
    }
  };

  const onCloseDialog = () => {
    setAddEditDialogOpen(null);
    setDeleteDialogOpen(null);
  };

  const handleTabChange = (index: TabIndex) => {
    setTabIndex(index);
  };

  const charges = variousOperations.filter(
    (variousOperation) => variousOperation.operationType === LeaseVariousOperationType.CHARGE
  );
  const services = variousOperations.filter(
    (variousOperation) => variousOperation.operationType === LeaseVariousOperationType.SERVICE
  );

  return (
    <>
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <ContentHeader title={formatMessage({ id: 'settings.variousOperationsMenu' })}>
          <ActionButton onClick={() => setAddEditDialogOpen('')}>{formatMessage({ id: 'add' })}</ActionButton>
        </ContentHeader>

        <CustomizedTabs handleChange={(index: number) => handleTabChange(index)}>
          <Tab label={formatMessage({ id: 'lease.detail.variousOperations.charges' })} id={`${TabIndex.CHARGES}`} />
          <Tab label={formatMessage({ id: 'lease.detail.variousOperations.services' })} id={`${TabIndex.SERVICES}`} />
        </CustomizedTabs>
        <Divider />
        <div
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 30,
          }}
        >
          {tabIndex === TabIndex.CHARGES &&
            charges.map((variousOperation, index) => (
              <VariousOperationRow
                key={variousOperation.id}
                variousOperation={variousOperation}
                divider={index !== charges.length - 1}
                doAction={doAction}
              />
            ))}
          {tabIndex === TabIndex.SERVICES &&
            services.map((variousOperation, index) => (
              <VariousOperationRow
                key={variousOperation.id}
                variousOperation={variousOperation}
                divider={index !== services.length - 1}
                doAction={doAction}
              />
            ))}
        </div>
      </ElevatedPaper>
      <Dialog
        open={!isNil(addEditDialogOpen)}
        onClose={onCloseDialog}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { width: 640 } }}
      >
        <AddEditVariousOperation
          afterSubmit={onCloseDialog}
          id={addEditDialogOpen && addEditDialogOpen !== '' ? addEditDialogOpen : undefined}
          operationType={
            tabIndex === TabIndex.CHARGES ? LeaseVariousOperationType.CHARGE : LeaseVariousOperationType.SERVICE
          }
        />
      </Dialog>
      <Dialog
        open={!isNil(deleteDialogOpen)}
        onClose={onCloseDialog}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DeleteVariousOperation afterSubmit={onCloseDialog} id={deleteDialogOpen!} />
      </Dialog>
    </>
  );
};

export default VariousOperationsSettings;
