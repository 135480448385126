/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef } from 'react';

type Point2D = {
  x: number;
  y: number;
};

type Point3D = Point2D & {
  z: number;
};

export type Point = Point2D | Point3D;

const isPoint = (point: Object): point is Point => point.hasOwnProperty('x') && point.hasOwnProperty('y');
const isNum = (v: any): v is number => typeof v === 'number';
const distance1D = (a: number, b: number) => Math.abs(a - b);
const isPoint3D = (point: Point): point is Point3D => isPoint(point) && point.hasOwnProperty('z');
/*
  Distance
  Returns the distance between two n dimensional points.
  @param [object/number]: x and y or just x of point A
  @param [object/number]: (optional): x and y or just x of point B
  @return [number]: The distance between the two points
*/

export function distance<P extends Point | number>(a: P, b: P): number {
  if (isNum(a) && isNum(b)) {
    // 1D dimensions
    return distance1D(a, b);
  }
  if (isPoint(a) && isPoint(b)) {
    // Multi-dimensional
    const xDelta = distance1D(a.x, b.x);
    const yDelta = distance1D(a.y, b.y);
    const zDelta = isPoint3D(a) && isPoint3D(b) ? distance1D(a.z, b.z) : 0;

    return Math.sqrt(xDelta ** 2 + yDelta ** 2 + zDelta ** 2);
  }
  return 0;
}

export const clamp = (min: number, max: number, v: number) => Math.min(Math.max(v, min), max);

const arrayMoveMutate = (array: any[], from: number, to: number) => {
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;

    const [item] = array.splice(from, 1);
    array.splice(endIndex, 0, item);
  }
};

export const arrayMove = (array: any[], from: number, to: number) => {
  array = [...array];
  arrayMoveMutate(array, from, to);
  return array;
};

export function useMeasurePosition(update: (arg: { height: number; top: number }) => void) {
  // We'll use a `ref` to access the DOM element that the `motion.li` produces.
  // This will allow us to measure its height and position, which will be useful to
  // decide when a dragging element should switch places with its siblings.
  const ref = useRef<HTMLDivElement>(null);

  // Update the measured position of the item so we can calculate when we should rearrange.
  useEffect(() => {
    update({
      height: ref!.current!.offsetHeight,
      top: ref!.current!.offsetTop,
    });
  });

  return ref;
}
