import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Colors, CreditCost, formatNumberToEuroCurrency, isCreditUsageCostValid } from '@rentguru/commons-utils';
import { orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import SkeletonComponent from 'src/components/ui/SkeletonComponent';
import { useClient } from 'src/hooks/ClientContext';
import SettingBoxDetailLine from '../SettingBoxDetailLine';
import { CAN_BUY_CREDITS } from '../SubscriptionSettings';

const useStyles = makeStyles(() => ({
  box: {
    width: 280,
    borderRadius: 10,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: Colors.SILVER,
    marginBottom: 15,
  },
  boxHeader: {
    backgroundColor: Colors.PORCELAIN_GREY_1,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
  },
  boxContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 10,
    paddingTop: 10,
  },
  boxLines: {
    display: 'flex',
    marginTop: 20,
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
  },
}));

const CreditCosts = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { loading, creditUsageCosts, getCreditCostsForClient } = useClient();
  const [creditPackCosts, setCreditPackCosts] = useState<CreditCost[] | null>(null);

  useEffect(() => {
    const fetchCreditCosts = async () => {
      const creditCosts = await getCreditCostsForClient();
      setCreditPackCosts(creditCosts);
    };
    fetchCreditCosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || !creditUsageCosts) {
    return <SkeletonComponent loading />;
  }

  const filteredCosts = creditUsageCosts.filter((creditCost) => isCreditUsageCostValid(creditCost));
  const orderedPackCosts = orderBy(creditPackCosts, 'numberOfCredits', 'asc');

  return (
    <Grid
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%',
      }}
    >
      <Box className={classes.box}>
        <Grid className={classes.boxHeader}>
          <Typography>{formatMessage({ id: 'settings.credits.costPerService' })}</Typography>
        </Grid>
        <Grid className={classes.boxContent}>
          <Grid className={classes.boxLines}>
            {filteredCosts.map((creditUsageCost) => (
              <SettingBoxDetailLine
                key={creditUsageCost.id}
                text={formatMessage({
                  id: `enums.CreditOperationType.${creditUsageCost.type}`,
                  defaultMessage: '',
                })}
                value={`${creditUsageCost.numberOfCreditsPerOperation} ${formatMessage({
                  id: 'settings.credits.name',
                })}`}
              />
            ))}
          </Grid>
        </Grid>
      </Box>
      {CAN_BUY_CREDITS && (
        <Box className={classes.box}>
          <Grid className={classes.boxHeader}>
            <Typography>{formatMessage({ id: 'settings.credits.creditCost' })}</Typography>
          </Grid>
          <Grid className={classes.boxContent}>
            <Grid className={classes.boxLines}>
              {orderedPackCosts.map((creditPackCost) => (
                <SettingBoxDetailLine
                  key={creditPackCost.id}
                  text={`${creditPackCost.numberOfCredits} ${formatMessage({
                    id: 'settings.credits.name',
                  })}`}
                  value={formatNumberToEuroCurrency(creditPackCost.price)}
                />
              ))}
            </Grid>
          </Grid>
        </Box>
      )}
    </Grid>
  );
};

export default CreditCosts;
