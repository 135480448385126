import TableRow from '@material-ui/core/TableRow';
import { File, S3Object } from '@rentguru/commons-utils';
import { CustomTableCell, EnhanceTableRowProps } from '@up2rent/ui';
import { format } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { IconComponent, UserFriendlyType } from 'src/components/ui/FileType';
import PreviewFileDialogs from 'src/components/ui/PreviewFileDialog';
import TextButton from 'src/components/ui/TextButton';
import { getS3ObjectUrls } from 'src/hooks/FilesContext';

export interface TicketsArchivedRowProps extends EnhanceTableRowProps {
  id: string;
  fileArray: File[];
}

const TicketsDetailAttachmentsRow: React.FC<TicketsArchivedRowProps> = ({ isSelected, id, fileArray }) => {
  const [fileToPreview, setFileToPreview] = useState<S3Object | null>(null);
  const [s3Files, setS3Files] = useState<S3Object[]>([]);
  const filteredfileArray =
    !isNil(fileArray) && !isEmpty(fileArray) ? fileArray.filter((file) => file.foreignKey === id) : [];

  useEffect(() => {
    let unmounted = false;
    // to get filename and urls.
    getS3ObjectUrls(filteredfileArray).then((s3Objects) => {
      if (!unmounted) {
        setS3Files(s3Objects);
      }
    });

    return () => {
      unmounted = true;
    };
    /* As the getS3ObjectUrls method is defined outside, we need to disable the warning 
      because we cannot put the method in the dependency (it will do an infinite loop) */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileArray]);

  return (
    <>
      {s3Files.map((file) => {
        const shouldPreviewFirst =
          !isNil(file.mimeType) && (file.mimeType!.includes('image') || file.mimeType!.includes('pdf'));
        return (
          <TableRow hover aria-checked={isSelected} tabIndex={-1} key={file.key} selected={isSelected}>
            <CustomTableCell style={{ height: 0, paddingTop: 20, paddingBottom: 20 }} scope="row" padding="normal">
              <IconComponent dataType={file.mimeType!} />
            </CustomTableCell>
            <CustomTableCell
              style={{ height: 0, paddingTop: 20, paddingBottom: 20, fontWeight: 600, color: 'black' }}
              scope="row"
              padding="normal"
            >
              <TextButton
                href={!shouldPreviewFirst ? file.url : ''}
                onClick={shouldPreviewFirst ? () => setFileToPreview(file) : undefined}
                style={{ color: 'inherit', textDecoration: 'none', display: 'inherit' }}
              >
                {file.fileName}
              </TextButton>
            </CustomTableCell>
            <CustomTableCell style={{ height: 0, paddingTop: 20, paddingBottom: 20 }} scope="row" padding="normal">
              {format(new Date(file.createdAt!), 'dd/MM/yyyy')}
            </CustomTableCell>
            <CustomTableCell style={{ height: 0, paddingTop: 20, paddingBottom: 20 }} scope="row" padding="normal">
              <UserFriendlyType dataType={file.mimeType!} />
            </CustomTableCell>
            <CustomTableCell style={{ height: 0, paddingTop: 20, paddingBottom: 20 }} scope="row" padding="normal">
              {`${Math.round(file.size! / 10000) / 100} mb`}
            </CustomTableCell>
            <PreviewFileDialogs
              open={!isNil(fileToPreview)}
              file={fileToPreview}
              onClose={() => setFileToPreview(null)}
            />
          </TableRow>
        );
      })}
    </>
  );
};

export default TicketsDetailAttachmentsRow;
