import { Grid, Paper, Typography } from '@material-ui/core';
import { Colors, getContactNameFromObject, SignatureDocumentStatus } from '@rentguru/commons-utils';
import { isNil } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { useContacts } from 'src/hooks/ContactsContext';
import { useSignatureDocuments } from 'src/hooks/SignatureDocumentContext';
import { ReactComponent as CheckIcon } from '../../../icons/check.svg';
import { ReactComponent as CloseIcon } from '../../../icons/close.svg';
import { ReactComponent as HistoryIcon } from '../../../icons/history.svg';

interface LeaseSignaturePopoverColumnProps {
  leaseId: string;
}

const LeaseSignaturePopoverColumn: React.FC<LeaseSignaturePopoverColumnProps> = ({ leaseId }) => {
  const { formatMessage } = useIntl();
  const { getContact } = useContacts();
  const { getSignatureDocumentsForEntity } = useSignatureDocuments();
  const pendingSignatureDocuments = getSignatureDocumentsForEntity('Lease', leaseId, SignatureDocumentStatus.PENDING);
  const currentSignatureResults =
    pendingSignatureDocuments && pendingSignatureDocuments[0] ? pendingSignatureDocuments[0].signatureResults : [];

  return (
    <Paper
      elevation={8}
      style={{
        position: 'absolute',
        padding: 12,
        transform: 'translate(-135px, 10px)',
        zIndex: 2,
        width: 400,
        borderRadius: 10,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Grid container style={{ marginBottom: 5 }}>
        <Grid item xs={6}>
          <Typography style={{ fontSize: 12, color: Colors.BLUE_GREY }}>
            {formatMessage({ id: 'lease.detail.progress.name' })}
          </Typography>
        </Grid>
        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography style={{ fontSize: 12, color: Colors.BLUE_GREY }}>
            {formatMessage({ id: 'lease.detail.progress.opened' })}
          </Typography>
        </Grid>
        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography style={{ fontSize: 12, color: Colors.BLUE_GREY }}>
            {formatMessage({ id: 'lease.detail.progress.signed' })}
          </Typography>
        </Grid>
      </Grid>
      {currentSignatureResults.map((result) => {
        const contact = getContact(result.contactId);
        return (
          <Grid key={result.contactId} container style={{ marginTop: 10 }}>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: 16,
                  whiteSpace: 'nowrap',
                  fontWeight: 600,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {getContactNameFromObject(contact)}
              </Typography>
            </Grid>
            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
              {!isNil(result.openedDate) ? <CheckIcon style={{ fill: Colors.TURQUOISE_BLUE }} /> : <HistoryIcon />}
            </Grid>
            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
              {!isNil(result.signed) ? (
                result.signed ? (
                  <CheckIcon style={{ fill: Colors.TURQUOISE_BLUE }} />
                ) : (
                  <CloseIcon style={{ fill: Colors.BURNING_ORANGE }} />
                )
              ) : (
                <HistoryIcon />
              )}
            </Grid>
          </Grid>
        );
      })}
    </Paper>
  );
};

export default LeaseSignaturePopoverColumn;
