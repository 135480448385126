import React from 'react';

import { getMainUnit, S3Object } from '@rentguru/commons-utils';
import { LeaseExtended, useLeases } from 'src/hooks/LeasesContext';
import { useUnits } from 'src/hooks/UnitsContext';
import IntermediateLeaseActionMenuDialogs from './IntermediateLeaseActionMenuDialog';
import { LeaseAction } from './useLeaseActionsMenuUtils';

interface LeaseActionMenuDialogsProps {
  lease: LeaseExtended;
  open: boolean;
  type: LeaseAction | null;
  onCloseDialog: () => void;
  leaseContract: S3Object | null | undefined;
}

const LeaseActionMenuDialogs: React.FC<LeaseActionMenuDialogsProps> = ({
  lease,
  open,
  type,
  onCloseDialog,
  leaseContract,
}) => {
  const { updateLease } = useLeases();
  const { getUnit } = useUnits();

  const mainUnitId = getMainUnit(lease.units ?? [])?.id;
  const mainUnit = getUnit(mainUnitId ?? '');

  return (
    <IntermediateLeaseActionMenuDialogs
      lease={lease}
      mainUnit={mainUnit}
      open={open}
      type={type}
      onCloseDialog={onCloseDialog}
      leaseContract={leaseContract}
      updateLease={updateLease}
    />
  );
};

export default LeaseActionMenuDialogs;
