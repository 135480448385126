/* eslint-disable react/no-array-index-key */
import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import AddInfoButton from 'src/components/ui/AddInfoButton';
import TextDetail from 'src/components/ui/TextDetail';
import { formatNumber, NearbyComodity, Unit, UnitInventory, UnitInventoryRoomType } from '@rentguru/commons-utils';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { useAdvertisement } from 'src/hooks/PublicationsContext';
import { parseISO } from 'date-fns';
import { isNil } from 'lodash';
import { useUnitInventories } from 'src/hooks/UnitInventoryContext';
import { EDIT_ADVERTISEMENT_GENERAL_MENU } from '../EditPublication/EditPublication';
import AlignedTextDetail from '../AlignedTextDetail';

// import { format } from 'date-fns';
interface AdvertisementDescriptionFullListDataProps {
  unit: Unit;
}

const useLocalStyles = makeStyles({
  title: {
    fontSize: 14,
    marginBottom: 20,
  },
});

export const toLowerCase = (message: string): string => {
  return message.charAt(0).toUpperCase() + message.slice(1).toLowerCase();
};

export const getAmountOfInventoryType = (
  inventory: UnitInventory[],
  type: UnitInventoryRoomType,
  inside: boolean = false
): number => {
  if (isNil(inventory) || isNil(type)) return -1;

  return inventory.filter((inv) => inv.roomType === type && inv.inside === inside).length;
};

const AdvertisementDescriptionFullListData: React.FC<AdvertisementDescriptionFullListDataProps> = ({ unit }) => {
  const { formatMessage } = useIntl();
  const localClasses = useLocalStyles();
  const { language } = useLocale();
  const { getUnitInventoriesFor } = useUnitInventories();
  const { advertisement, refetchAdvertisement } = useAdvertisement(
    false,
    unit ? (unit!.unitAdvertisementId ? unit!.unitAdvertisementId! : '') : ''
  );

  // This leads to infinite loop.
  useEffect(() => {
    let unmounted = false;
    const fetchNetworkData = async () => {
      if (!unmounted) {
        refetchAdvertisement();
      }
    };
    fetchNetworkData();
    return () => {
      unmounted = true;
    };
    // Leads to infinite loop if the warning message is totally respected.
    // eslint-disable-next-line
  }, []);

  const unitInventories = getUnitInventoriesFor(unit.id);

  // const sortComodities = (commodity1: NearbyComodity, commodity2: NearbyComodity) => {
  //   if (commodity1.type > commodity2.type) return 1;
  //   else if (commodity1.type < commodity2.type) return -1;
  //   return 0;
  // };
  const sortedNearbyCommodities = advertisement?.nearbyComodities
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      advertisement?.nearbyComodities?.reduce((result: any, commodity: NearbyComodity) => {
        result = {
          ...result,
          [commodity.type]: result[commodity.type] || [],
        };
        result[commodity.type].push(commodity);
        return result;
      }, {})
    : [];

  return (
    <>
      <Typography variant="h6" className={localClasses.title}>
        {formatMessage({ id: 'rentalUnit.detail.general.general' })}
      </Typography>

      <Grid container style={{ margin: ' 0 0 30px 0' }}>
        {!isNil(advertisement) && !isNil(advertisement.availabilityDate) && (
          <Grid style={{ margin: '0 0 10px 0' }} item xs={4}>
            <TextDetail
              textId="startDateValue"
              style={{ fontWeight: 'normal' }}
              title={formatMessage({ id: 'rentalUnit.editUnit.advertisement.general.startDate' })}
              text={parseISO(advertisement.availabilityDate).toLocaleDateString(language)}
            />
          </Grid>
        )}

        {!isNil(unit) && !isNil(unit.advertisedRentalPrice) && (
          <Grid style={{ margin: '0 0 10px 0' }} item xs={4}>
            <TextDetail
              textId="rentValue"
              style={{ fontWeight: 'normal' }}
              title={formatMessage({ id: 'lease.addLease.rent' })}
              text={formatNumber(unit.advertisedRentalPrice, language, {
                style: 'currency',
                currency: 'EUR',
              })}
            />
          </Grid>
        )}

        {!isNil(unit) && !isNil(unit.advertisedMonthlyCharges) && (
          <Grid style={{ margin: '0 0 10px 0' }} item xs={4}>
            <TextDetail
              textId="monthlyChargesValue"
              style={{ fontWeight: 'normal' }}
              title={formatMessage({ id: 'lease.detail.general.monthlyCharges' })}
              text={formatNumber(unit.advertisedMonthlyCharges, language, {
                style: 'currency',
                currency: 'EUR',
              })}
            />
          </Grid>
        )}

        {/* I want to be sure that even if the value of a bool is false, 
        it'll still show up. But not if it's undefined. */}
        {!isNil(advertisement) && !isNil(advertisement.furnished) && (
          <Grid style={{ margin: '0 0 10px 0' }} item xs={4}>
            <TextDetail
              textId="furnishedValue"
              style={{ fontWeight: 'normal' }}
              title={formatMessage({ id: 'rentalUnit.editUnit.advertisement.general.furnished' })}
              text={formatMessage({ id: `rentalUnit.editUnit.advertisement.general.${advertisement.furnished}` })}
            />
          </Grid>
        )}

        {!isNil(advertisement) && !isNil(advertisement.firstOccupancy) && (
          <Grid style={{ margin: '0 0 10px 0' }} item xs={4}>
            <TextDetail
              textId="firstOccupancyValue"
              style={{ fontWeight: 'normal' }}
              title={formatMessage({ id: 'rentalUnit.editUnit.advertisement.general.firstOccupancy' })}
              text={formatMessage({ id: `rentalUnit.editUnit.advertisement.general.${advertisement.firstOccupancy}` })}
            />
          </Grid>
        )}

        {!isNil(unit) && !isNil(unit.floor) && (
          <Grid style={{ margin: '0 0 10px 0' }} item xs={4}>
            <TextDetail
              textId="floorValue"
              style={{ fontWeight: 'normal' }}
              title={formatMessage({ id: 'rentalUnit.editUnit.advertisement.building.floor' })}
              text={unit.floor}
            />
          </Grid>
        )}

        {!isNil(unit) && !isNil(unit.building) && !isNil(unit.building.residenceName) && (
          <Grid style={{ margin: '0 0 10px 0' }} item xs={4}>
            <TextDetail
              textId="residenceNameValue"
              style={{ fontWeight: 'normal' }}
              title={formatMessage({ id: 'rentalUnit.editUnit.advertisement.building.residenceName' })}
              text={unit.building.residenceName}
            />
          </Grid>
        )}

        {!isNil(unit) && !isNil(unit.building) && !isNil(unit.building.constructionYear) && (
          <Grid style={{ margin: '0 0 10px 0' }} item xs={4}>
            <TextDetail
              textId="constructionYearValue"
              style={{ fontWeight: 'normal' }}
              title={formatMessage({ id: 'rentalUnit.editUnit.advertisement.building.constructionYear' })}
              text={parseISO(unit.building.constructionYear).getFullYear()}
            />
          </Grid>
        )}

        {!isNil(unit) && !isNil(unit.building) && !isNil(unit.building.buildingState) && (
          <Grid style={{ margin: '0 0 10px 0' }} item xs={4}>
            <TextDetail
              textId="buildingConditionValue"
              style={{ fontWeight: 'normal' }}
              title={formatMessage({ id: 'rentalUnit.editUnit.advertisement.building.buildingCondition' })}
              text={formatMessage({ id: `enums.leaseInventoryStructureStateType.${unit.building.buildingState}` })}
            />
          </Grid>
        )}

        {!isNil(advertisement) && !isNil(advertisement.wheelChairAccessible) && (
          <Grid style={{ margin: '0 0 10px 0' }} item xs={4}>
            <TextDetail
              textId="wheelchairAccessibleValue"
              style={{ fontWeight: 'normal' }}
              title={formatMessage({ id: 'rentalUnit.editUnit.advertisement.building.wheelchairAccessible' })}
              text={formatMessage({
                id: `rentalUnit.editUnit.advertisement.general.${advertisement.wheelChairAccessible}`,
              })}
            />
          </Grid>
        )}

        {!isNil(advertisement) && !isNil(advertisement.amountOfFacades) && (
          <Grid style={{ margin: '0 0 10px 0' }} item xs={4}>
            <TextDetail
              textId="numberOfFacadesValue"
              style={{ fontWeight: 'normal' }}
              title={formatMessage({ id: 'rentalUnit.editUnit.advertisement.building.numberOfFacades' })}
              text={advertisement.amountOfFacades}
            />
          </Grid>
        )}

        {!isNil(unitInventories) && (
          <Grid style={{ margin: '0 0 10px 0' }} item xs={4}>
            <TextDetail
              textId="numberOfInteriorParkingsValue"
              style={{ fontWeight: 'normal' }}
              title={formatMessage({ id: 'rentalUnit.editUnit.advertisement.building.numberOfInteriorParkings' })}
              text={getAmountOfInventoryType(unitInventories, UnitInventoryRoomType.GARAGE, true)}
            />
          </Grid>
        )}

        {!isNil(unitInventories) && (
          <Grid style={{ margin: '0 0 10px 0' }} item xs={4}>
            <TextDetail
              textId="numberOfExteriorParkingsValue"
              style={{ fontWeight: 'normal' }}
              title={formatMessage({ id: 'rentalUnit.editUnit.advertisement.building.numberOfExteriorParkings' })}
              text={getAmountOfInventoryType(unitInventories, UnitInventoryRoomType.GARAGE)}
            />
          </Grid>
        )}

        {!isNil(unit) && !isNil(unit.building) && !isNil(unit.building.janitor) && (
          <Grid style={{ margin: '0 0 10px 0' }} item xs={4}>
            <TextDetail
              textId="janitorValue"
              style={{ fontWeight: 'normal' }}
              title={formatMessage({ id: 'rentalUnit.editUnit.advertisement.building.janitor' })}
              text={formatMessage({
                id: `rentalUnit.editUnit.advertisement.general.${unit.building.janitor}`,
              })}
            />
          </Grid>
        )}

        {!isNil(unit) && !isNil(unit.building) && !isNil(unit.building.amountOfHousings) && (
          <Grid style={{ margin: '0 0 10px 0' }} item xs={4}>
            <TextDetail
              textId="numberOfHousingsValue"
              style={{ fontWeight: 'normal' }}
              title={formatMessage({ id: 'rentalUnit.editUnit.advertisement.building.numberOfHousings' })}
              text={unit.building.amountOfHousings}
            />
          </Grid>
        )}
      </Grid>

      {sortedNearbyCommodities && (
        <Grid container style={{ marginBottom: 30 }}>
          {Object.keys(sortedNearbyCommodities).map((type: string) => (
            <Grid item xs={6} style={{ marginBottom: 25 }} key={type}>
              <Typography variant="h6" className={localClasses.title} style={{ marginBottom: 5 }}>
                {formatMessage({
                  id:
                    sortedNearbyCommodities[type].length > 1
                      ? `rentalUnit.editUnit.advertisement.commodityType.plural.${type}`
                      : `rentalUnit.editUnit.advertisement.commodityType.singular.${type}`,
                })}
              </Typography>
              {sortedNearbyCommodities[type].map((commodity: NearbyComodity, index: number) => (
                <Grid key={`${commodity.name}-${commodity.distance}-${index}`}>
                  <AlignedTextDetail
                    style={{ fontWeight: 'normal', textAlign: 'right' }}
                    title={commodity.name}
                    text={commodity.distance}
                    endAdornment=" m"
                    comoditiesDisplay={true}
                  />
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
      )}

      {/* This conditions checks if we do have all the necessary intell for the general description tab. 
      Otherwise, we do propose to add some info */}
      {!(
        (
          !isNil(unit) &&
          !isNil(advertisement) &&
          !isNil(advertisement.availabilityDate) && // OK
          !isNil(advertisement.furnished) && // OK
          !isNil(advertisement.firstOccupancy) && // OK
          !isNil(advertisement.wheelChairAccessible) && // OK
          !isNil(unit.building) &&
          !isNil(unit.building.residenceName) && // OK
          !isNil(unit.building.constructionYear) && // OK
          !isNil(unit.building.buildingState) && // OK
          !isNil(unit.building.amountOfFacades) && // OK
          !isNil(unit.building.janitor) && // OK
          !isNil(unit.building.amountOfHousings) && // OK
          !isNil(unit.advertisedRentalPrice) && // OK
          !isNil(unit.advertisedMonthlyCharges) && // OK
          !isNil(unit.floor)
        ) // OK
      ) && (
        <Grid style={{ margin: ' -30px 0 30px 0' }}>
          <AddInfoButton
            destination={{
              pathname: '/editAdvertisement',
              state: { unit, location: EDIT_ADVERTISEMENT_GENERAL_MENU },
            }}
          />
        </Grid>
      )}
    </>
  );
};

export default AdvertisementDescriptionFullListData;
