import { type SvgIconComponent } from '@material-ui/icons';
import { CircularProgress, IconButton } from '@material-ui/core';
import { CSSProperties, forwardRef } from 'react';
import { CustomIconButton, CustomIconButtonProps } from './CustomIconButton';

interface CustomLoadableIconButtonProps extends CustomIconButtonProps {
  isLoading: boolean;
  Icon: SvgIconComponent | React.FC<React.SVGProps<SVGSVGElement>>;
  loadingStyle?: CSSProperties;
}

// eslint-disable-next-line react/display-name
export const CustomLoadableIconButton = forwardRef<HTMLButtonElement, CustomLoadableIconButtonProps>(
  ({ disabled, isLoading, loadingStyle, onClick, ...rest }, ref) => {
    return isLoading ? (
      <IconButton ref={ref} {...rest}>
        <CircularProgress style={{ width: 20, height: 20, ...loadingStyle }} />
      </IconButton>
    ) : (
      <CustomIconButton ref={ref} onClick={onClick} disabled={disabled || isLoading} {...rest} />
    );
  }
);
