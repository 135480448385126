import { Grid } from '@material-ui/core';
import {
  Communication,
  CommunicationStatus,
  Notification,
  NotificationStatus,
  isAfterOrEqual,
  isDateValid,
} from '@rentguru/commons-utils';
import { addDays } from 'date-fns';
import { Form, Formik, FormikHelpers } from 'formik';
import isNil from 'lodash/isNil';
import React from 'react';
import { useIntl } from 'react-intl';
import { CustomSimpleDialog } from '@up2rent/ui';
import FormikDatePicker from 'src/components/ui/FormikDatePicker';
import { useCommunications } from 'src/hooks/CommunicationsContext';
import { useNotifications } from 'src/hooks/NotificationsContext';

interface SnoozeNotificationDialogProps {
  notification?: Notification | Communication;
  onClose: (e?: React.MouseEvent) => void;
}

const SnoozeNotificationDialog: React.FC<SnoozeNotificationDialogProps> = ({ notification, onClose }) => {
  const { formatMessage } = useIntl();
  const { updateNotification } = useNotifications();
  const { updateCommunication } = useCommunications();
  const minimumSnoozedDate = addDays(new Date(), 1);
  return (
    <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Formik
        initialValues={{
          snoozeDate: minimumSnoozedDate,
        }}
        onSubmit={async (
          values: { snoozeDate: Date },
          { setSubmitting, setStatus }: FormikHelpers<{ snoozeDate: Date }>
        ) => {
          const snoozeDate = values.snoozeDate;
          if (isDateValid(snoozeDate) && isAfterOrEqual(snoozeDate, minimumSnoozedDate) && !isNil(notification)) {
            if ('body' in notification) {
              // Communication
              await updateCommunication(notification, {
                snoozedDate: snoozeDate.toISOString(),
                status: CommunicationStatus.SNOOZED,
              });
            } else {
              // Notification
              await updateNotification(notification, {
                popupDate: snoozeDate.toISOString(),
                status: NotificationStatus.Snoozed,
              });
            }
            onClose();
            setStatus(true);
          }
          setSubmitting(false);
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values, isSubmitting, handleSubmit }) => (
          <Form
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              marginTop: 20,
              width: '100%',
            }}
          >
            <CustomSimpleDialog
              open={!isNil(notification)}
              onClose={onClose}
              onActionButtonClick={(e) => {
                e.stopPropagation();
                handleSubmit(e as unknown as React.FormEvent<HTMLFormElement>);
              }}
              onSecondaryButtonClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                onClose(e);
              }}
              actionButtonLabel={formatMessage({ id: 'lease.detail.action.confirm' })}
              actionButtonLoading={isSubmitting}
              title={formatMessage({ id: 'dashboard.snooze.choose' })}
              dividerBelowTitle
              formatMessage={formatMessage}
            >
              <Grid style={{ marginTop: 20 }}>
                <FormikDatePicker
                  name="snoozeDate"
                  label={formatMessage({ id: 'lease.addLease.endDate' })}
                  value={values.snoozeDate}
                  style={{ width: '100%' }}
                  minDate={minimumSnoozedDate}
                />
              </Grid>
            </CustomSimpleDialog>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default SnoozeNotificationDialog;
