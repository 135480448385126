import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useIntl } from 'react-intl';
import { TablePlaceHolder } from '@up2rent/ui';
import { ExtendedUnitInventory, ExtendedUnitInventoryStructure } from '../../../../hooks/UnitInventoryContext';
import { LeaseInventory, UnitInventoryStructureType } from '@rentguru/commons-utils';
import { AddCheckedItemDialogBundle, FilePreviewDialogBundle } from '../InventoryEncodings';
import InventoryStructureEncoding from '../InventoryStructureEncoding';

interface StructuresTabProps {
  leaseInventory: LeaseInventory;
  completeUnitInventory: ExtendedUnitInventory;
  unitInventoryIndex: number;
  encodeDefects: (defectBundle: AddCheckedItemDialogBundle) => void;
  previewFileFunction: (bundle: FilePreviewDialogBundle) => void;
  readOnly: boolean;
}

const StructuresTab: React.FC<StructuresTabProps> = ({
  leaseInventory,
  completeUnitInventory,
  unitInventoryIndex,
  encodeDefects,
  previewFileFunction,
  readOnly,
}) => {
  const { formatMessage } = useIntl();
  const finishes = completeUnitInventory.structures.reduce(
    (acc: { struct: ExtendedUnitInventoryStructure; index: number }[], struct, index) => {
      if (struct.type === UnitInventoryStructureType.OTHER) {
        acc.push({ index, struct });
      }
      return acc;
    },
    []
  );
  if (isEmpty(finishes)) {
    return (
      <>
        <TablePlaceHolder
          mainText={formatMessage({ id: 'leaseInventory.finishesPlaceholder.mainText' })}
          subText={formatMessage({ id: 'leaseInventory.finishesPlaceholder.subText' }, { readOnly })}
        />
      </>
    );
  }
  return (
    <>
      {finishes.map(({ struct, index }) => (
        <InventoryStructureEncoding
          key={struct.id}
          completeUnitInventory={completeUnitInventory}
          leaseInventory={leaseInventory}
          structureFieldName={`localUnitInventories[${unitInventoryIndex}].structures[${index}]`}
          inventoryStructure={struct}
          previewFileFunction={previewFileFunction}
          encodeDefects={encodeDefects}
          readOnly={readOnly}
        />
      ))}
    </>
  );
};

export default StructuresTab;
