/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/*
  Utilities functions and polyfills
 */
export const entries = (obj: Record<string, any> | []): [] => {
  // @ts-ignore
  return 'entries' in Object ? Object.entries(obj) : Object.keys(obj).map((prop: any) => [prop, obj[prop]]);
};

export const areEqual = (val1: any, val2: any): boolean => {
  if ((val1 === 0 || val2 === 0) && val1 === val2) return true;
  if (!val1 || !val2 || typeof val1 !== typeof val2) return false;
  if (typeof val1 === 'string' || typeof val1 === 'number' || typeof val1 === 'boolean') return val1 === val2;
  if (typeof val1 === 'object') {
    return (
      Object.keys(val1).length === Object.keys(val2).length &&
      // @ts-ignore
      entries(val2).every(([key2, value2]) => val1[key2] === value2)
    );
  }
  return false;
};
