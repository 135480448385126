import React, { useState } from 'react';
import { Divider, makeStyles, Grid } from '@material-ui/core';
import { useFormikContext, FormikProps } from 'formik';
import Add from '@material-ui/icons/Add';
import { MessageDescriptor, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { get, toUpper } from 'lodash';
import { ActionButton } from '@up2rent/ui';
import isNil from 'lodash/isNil';
import toLower from 'lodash/toLower';
import { UnitSelectorFormValues } from './UnitSelectorField';
import isEmpty from 'lodash/isEmpty';
import AddRoomFormDialog from 'src/components/ui/Forms/FormSection/AddRoomFormDialog';
import { commercialRooms, generalRooms } from 'src/utils/unitInventoryUtils';
import { v4 as uuidv4 } from 'uuid';
import PlusMinusSelectableDeleteButtoGroup from 'src/components/RentalUnits/Details/Publication/PlusMinusUnitInventoryButtonGroup';
import PlusMinusButtonGroup from 'src/components/ui/PlusMinusButtonGroup';
import { useUnits } from 'src/hooks/UnitsContext';
import { resolveMainUnitAndSubUnitIndexAndPrefix } from 'src/components/Leases/AddLease/useAddEditLeaseUtils';
import { Colors, UnitInventoryRoomType, Unit, UnitInventory, UnitType } from '@rentguru/commons-utils';

const useStyles = makeStyles({
  addButton: {
    background: Colors.CLASSICAL_WHITE,
    color: Colors.DARK_SLATE_GREY,
    '&:hover': {
      background: Colors.PORCELAIN_GREY_2,
    },
  },
});

export interface UnitInventoriesFormValues {
  originalUnitInventories?: UnitInventory[];
  unitStructures: UnitStructure[];
}

export interface UnitStructure {
  roomType: UnitInventoryRoomType | keyof typeof UnitInventoryRoomType;
  unitInventories: UnitInventory[];
}

export const getUnitInventoriesGroupedByType = (unitInventories: UnitInventory[]): UnitStructure[] => {
  const groupedUnitInventories = unitInventories.reduce((grouped: UnitStructure[], current: UnitInventory) => {
    const alreadyThere = grouped.find((g) => g.roomType === current.roomType);
    if (!isNil(alreadyThere)) {
      alreadyThere.unitInventories.push(current);
      return grouped;
    }
    return [...grouped, { roomType: current.roomType as UnitInventoryRoomType, unitInventories: [current] }];
  }, [] as UnitStructure[]);
  return groupedUnitInventories;
};

const getAllTypesOfRooms = (currentUnit: Unit | undefined, inside: boolean) => {
  if (isNil(currentUnit)) {
    return inside ? generalRooms.inside : generalRooms.outside;
  }
  if (currentUnit.type === UnitType.COMMERCIAL || currentUnit.type === UnitType.OFFICE) {
    return inside ? commercialRooms.inside : commercialRooms.outside;
  }
  return inside ? generalRooms.inside : generalRooms.outside;
};

export const groupStructuresByType = (
  unitStructures: UnitStructure[],
  inside: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatMessage: (descriptor: MessageDescriptor, values?: any) => string,
  currentUnit: Unit | undefined
) => {
  const types = getAllTypesOfRooms(currentUnit, inside);

  let typesMapForRender: {
    text: string;
    name: UnitInventoryRoomType | string;
    initialValue: number;
    initialUnits: UnitInventory[];
  }[] = types.map((type) => {
    const filteredValues = unitStructures.find((us) => us.roomType === type);
    const text = formatMessage({ id: `leaseInventory.roomType.${toLower(type)}` });
    if (isNil(filteredValues)) {
      return {
        text,
        name: type,
        initialValue: 0,
        initialUnits: [],
      };
    }

    return {
      text,
      name: type,
      // filteredValues.unitInventories.length,
      initialValue: filteredValues.unitInventories.filter((inv) => !isNil(inv.inside) && inv.inside === inside).length,
      initialUnits: filteredValues.unitInventories.filter(
        (inv) => !isNil(inv.inside) && inv.inside === inside
      ) as UnitInventory[],
    };
  });
  // Check for other
  const otherType = unitStructures.find((unitStructure) => unitStructure.roomType === UnitInventoryRoomType.OTHER);
  if (!isNil(otherType)) {
    const filteredOtherType = otherType.unitInventories.filter((unitInventory) => unitInventory.inside === inside);

    const otherTypeToAddToMap = filteredOtherType.reduce((result, current) => {
      const alreadyThere = result.find((r) => (current.roomName ? r.text === current.roomName : r.text === '?'));
      if (!isNil(alreadyThere)) {
        alreadyThere.initialValue += 1;
        alreadyThere.initialUnits = [...alreadyThere.initialUnits, current] as UnitInventory[];
      } else {
        const text = current.roomName ? current.roomName : '?';
        result.push({
          text,
          name: `${UnitInventoryRoomType.OTHER}_${text}`,
          initialValue: 1,
          initialUnits: [current] as UnitInventory[],
        });
      }
      return result;
      // eslint-disable-next-line max-len
    }, [] as { text: string; name: UnitInventoryRoomType | string; initialValue: number; initialUnits: UnitInventory[] }[]);

    typesMapForRender = [...typesMapForRender, ...otherTypeToAddToMap];
  }
  return typesMapForRender;
};

export const UnitInventoriesSchema = Yup.object()
  .shape({
    unitStructures: Yup.array()
      .of(
        Yup.object().shape({
          roomType: Yup.string().min(0).required(),
          unitInventories: Yup.array()
            .of(
              Yup.object().shape({
                roomType: Yup.string().min(0).required(),
                roomName: Yup.string().min(0).nullable(),
                inside: Yup.boolean().nullable(),
              })
            )
            .notRequired(),
        })
      )
      .notRequired(),
  })
  .required();

interface UnitStructureFieldsProps {
  inside: boolean;
  canDelete?: boolean;
  handleDeleteWarning?: (action: 'open' | 'close') => void;
  unitId?: string;
  paddingLeft?: number | string;
  selectUnitInventoryToBeDeleted?: boolean;
  fieldName?: string;
  originalFieldName?: string;
}

const UnitStructureFields: React.FC<UnitStructureFieldsProps> = ({
  inside,
  unitId,
  canDelete = true,
  handleDeleteWarning,
  paddingLeft = 0,
  selectUnitInventoryToBeDeleted = false,
  fieldName,
  originalFieldName,
}) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const classes = useStyles();
  const { getUnit } = useUnits();
  const { values, setFieldValue }: FormikProps<UnitInventoriesFormValues> = useFormikContext();
  const { formatMessage } = useIntl();

  const { prefixFieldName: completeFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(values, unitId, fieldName);
  const { prefixFieldName: completeOriginalFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(
    values,
    unitId,
    originalFieldName
  );

  const currentUnit = getUnit(unitId ?? '');
  // Iterate througth types and fill in with values
  const unitStructures = get(values, completeFieldName, []);
  const typesMapForRender = groupStructuresByType(unitStructures, inside, formatMessage, currentUnit);

  const onChangeValue = (value: number, name?: string) => {
    if (isNil(name)) {
      return;
    }

    // Get the item related to the name
    let existingList = (get(values, completeFieldName, []) as UnitStructure[]).find(
      (unitStructure) =>
        unitStructure.roomType === name ||
        (unitStructure.roomType === UnitInventoryRoomType.OTHER && name.startsWith(`${UnitInventoryRoomType.OTHER}_`))
    );

    // For other, extract only the one corresponding
    let roomName = '';
    if (name.startsWith(`${UnitInventoryRoomType.OTHER}_`)) {
      roomName = `${name.replace(`${UnitInventoryRoomType.OTHER}_`, '')}`;
    }

    // Not yet in the list
    if (isNil(existingList)) {
      setFieldValue(completeFieldName, [
        ...(get(values, completeFieldName, []) as UnitStructure[]),
        {
          roomType: name,
          unitInventories: [
            {
              roomType: name as UnitInventoryRoomType,
              roomName:
                roomName === ''
                  ? `${formatMessage({ id: `leaseInventory.roomType.${toLower(name)}` })} ${value}`
                  : roomName,
              inside,
              // showSurface: true,
              unitId: !isNil(unitId) ? unitId : (values as unknown as UnitSelectorFormValues).unit.id!,
              id: `NEW-${uuidv4()}`,
              clientId: '',
            },
          ],
        },
      ]);
      return;
    }

    if (name.startsWith(`${UnitInventoryRoomType.OTHER}_`)) {
      existingList = {
        ...existingList,
        unitInventories: existingList.unitInventories.filter(
          (unitInventory) =>
            unitInventory.roomName && unitInventory.roomName === roomName && unitInventory.inside === inside
        ),
      };
    }
    const existingListUnitInventories = existingList.unitInventories.filter((ui) => ui.inside === inside);

    const oldAmount = existingListUnitInventories.length;

    if (!isNil(handleDeleteWarning)) {
      // We have hit a maximum or minimum value
      if (value === oldAmount && value > 0) {
        handleDeleteWarning('open');
      } else {
        handleDeleteWarning('close');
      }
    }
    if (value > oldAmount) {
      // More than before
      let elementToAdd: UnitInventory | null = null;
      if (!isNil(get(values, completeOriginalFieldName, [])) && !isEmpty(get(values, completeOriginalFieldName, []))) {
        // Check with originalValues if we can not re-add an element
        // Get same type
        const filteredOriginalInventories = (get(values, completeOriginalFieldName, []) as UnitInventory[]).filter(
          (originalUnitInventory) =>
            (originalUnitInventory.roomType !== UnitInventoryRoomType.OTHER &&
              originalUnitInventory.roomType === name &&
              originalUnitInventory.inside === inside) ||
            (originalUnitInventory.roomType === UnitInventoryRoomType.OTHER &&
              !isNil(originalUnitInventory.roomName) &&
              roomName === originalUnitInventory.roomName &&
              originalUnitInventory.inside === inside)
        );
        if (!isNil(filteredOriginalInventories) && !isEmpty(filteredOriginalInventories)) {
          // Check if there is a value we can re-add
          elementToAdd = filteredOriginalInventories.reduce((result: UnitInventory | null, current) => {
            if (!isNil(existingList!.unitInventories.find((u) => u.id === current.id))) {
              return result;
            }
            return current;
          }, null);
        }
      }
      if (isNil(elementToAdd)) {
        // Re-Add element to group
        elementToAdd = {
          roomType: roomName === '' ? (name as UnitInventoryRoomType) : UnitInventoryRoomType.OTHER,
          roomName:
            roomName === ''
              ? `${formatMessage({ id: `leaseInventory.roomType.${toLower(name)}` })} ${value}`
              : roomName,
          inside,
          unitId: !isNil(unitId) ? unitId : (values as unknown as UnitSelectorFormValues).unit.id!,
          id: `NEW-${uuidv4()}`,
          clientId: '',
          readId: '',
        };
      }
      // Otherwhise, we just add new entry to the table
      setFieldValue(
        completeFieldName,
        (get(values, completeFieldName, []) as UnitStructure[]).reduce(
          (acc: UnitStructure[], current: UnitStructure) => {
            if (current.roomType === existingList!.roomType) {
              current.unitInventories.push(elementToAdd!);
            }
            acc.push(current);
            return acc;
          },
          [] as UnitStructure[]
        )
      );
    } else if (value < oldAmount) {
      // Check if less than before -> Remove the non-id element first then the id element
      let found = false;
      const filetredUnitStructures = (get(values, completeFieldName, []) as UnitStructure[]).reduce(
        (result: UnitStructure[], current: UnitStructure) => {
          if (current.roomType === existingList!.roomType && current.roomType !== UnitInventoryRoomType.OTHER) {
            return [
              ...result,
              {
                roomType: existingList!.roomType,
                unitInventories: existingList!.unitInventories.reduce(
                  (newList: UnitInventory[], currentInventory: UnitInventory, index: number) => {
                    if (found) {
                      return [...newList, currentInventory];
                    }
                    if (
                      currentInventory.id.startsWith('NEW-') &&
                      currentInventory.roomName ===
                        `${formatMessage({
                          id: `leaseInventory.roomType.${toLower(currentInventory.roomType)}`,
                        })} ${oldAmount}`
                    ) {
                      found = true;
                      return newList;
                    }
                    if (index + 1 === existingList!.unitInventories.length) {
                      // remove this one
                      return newList;
                    }
                    return [...newList, currentInventory];
                  },
                  [] as UnitInventory[]
                ),
              },
            ];
          }
          if (current.roomType === existingList!.roomType && current.roomType === UnitInventoryRoomType.OTHER) {
            const [sameRoomNameCustomElements, otherCustomElements] = current.unitInventories.reduce(
              (acc: [UnitInventory[], UnitInventory[]], currentInventory: UnitInventory) => {
                if (
                  currentInventory.roomName &&
                  currentInventory.roomName === roomName &&
                  currentInventory.inside === inside
                ) {
                  acc[0].push(currentInventory);
                } else {
                  acc[1].push(currentInventory);
                }
                return acc;
              },
              [[], []]
            );
            // Get all others to add to list
            return [
              ...result,
              {
                ...current,
                unitInventories: [
                  ...otherCustomElements,
                  ...sameRoomNameCustomElements.reduce(
                    (newList: UnitInventory[], currentInventory: UnitInventory, index: number) => {
                      if (found) {
                        return [...newList, currentInventory];
                      }
                      if (currentInventory.id.startsWith('NEW-') && currentInventory.inside === inside) {
                        found = true;
                        return newList;
                      }
                      if (index === sameRoomNameCustomElements.length - 1) {
                        // remove this one
                        return newList;
                      }
                      return [...newList, currentInventory];
                    },
                    [] as UnitInventory[]
                  ),
                ],
              },
            ];
          }
          return [...result, current];
        },
        [] as UnitStructure[]
      );
      setFieldValue(completeFieldName, filetredUnitStructures);
    }
  };

  const onCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {typesMapForRender.map((type) => {
          // If the user cannot delete, the minimum value is the original number of unit inventories of this type
          const minValue =
            !canDelete && !isNil(get(values, completeOriginalFieldName, []))
              ? (get(values, completeOriginalFieldName, []) as UnitInventory[]).filter((originalUnitInventory) => {
                  const roomName = originalUnitInventory.roomName ? originalUnitInventory.roomName : '?';
                  const originalUnitInventoryName =
                    originalUnitInventory.roomType !== UnitInventoryRoomType.OTHER
                      ? originalUnitInventory.roomType
                      : `${UnitInventoryRoomType.OTHER}_${roomName}`;
                  return originalUnitInventoryName === type.name;
                }).length
              : undefined;
          return (
            <Grid key={type.name} style={{ paddingLeft }}>
              {selectUnitInventoryToBeDeleted ? (
                <PlusMinusSelectableDeleteButtoGroup
                  id={type.name}
                  key={type.name}
                  text={type.text}
                  initialValue={type.initialValue}
                  name={type.name}
                  onChange={onChangeValue}
                  minValue={minValue}
                  editable={false}
                  listOfUnits={type.initialUnits}
                  inside={inside}
                />
              ) : (
                <PlusMinusButtonGroup
                  key={type.name}
                  text={type.text}
                  initialValue={type.initialValue}
                  name={type.name}
                  onChange={onChangeValue}
                  minValue={minValue}
                  editable={false}
                />
              )}
            </Grid>
          );
        })}
      </div>

      <Divider style={{ marginBottom: 10, marginTop: 10 }} />
      <div style={{ display: 'flex', justifyContent: 'center', paddingLeft: '0px' }}>
        <ActionButton onClick={() => setDialogOpen(true)} className={classes.addButton}>
          <Add />
          {toUpper(
            formatMessage({
              id: 'leaseInventory.addRoom.title',
            })
          )}
        </ActionButton>
      </div>
      <AddRoomFormDialog
        open={dialogOpen}
        onClose={onCloseDialog}
        unitId={!isNil(unitId) ? unitId : (values as unknown as UnitSelectorFormValues).unit.id!}
        inside={inside}
        onlyOther
        fieldName="unitStructures"
      />
    </>
  );
};

export default UnitStructureFields;
