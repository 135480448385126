import { Grid, Typography } from '@material-ui/core';
import {
  Colors,
  CommunicationChannel,
  CommunicationSendingRule,
  CommunicationSettingRecipient,
  CommunicationSettings,
  CommunicationSettingsProfile,
  CommunicationSettingsProfileReferent,
  CommunicationType,
  getCommunicationSettings,
} from '@rentguru/commons-utils';
import { CollapsingChevron } from '@up2rent/ui';
import { FormikErrors, FormikTouched, useFormikContext } from 'formik';
import { isNil } from 'lodash';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import CommunicationChannelFormItem from '../CommunicationSettingFormItems/CommunicationChannelFormItem';
import CommunicationNumberOfDaysFormItem from '../CommunicationSettingFormItems/CommunicationNumberOfDaysFormItem';
import CommunicationRecipientFormItem from '../CommunicationSettingFormItems/CommunicationRecipientFormItem';
import CommunicationRecipientInCopyFormItem from '../CommunicationSettingFormItems/CommunicationRecipientInCopyFormItem';
import CommunicationSendingRuleFormItem from '../CommunicationSettingFormItems/CommunicationSendingRuleFormItem';
import {
  CommunicationSettingsFormValues,
  OptionalSettings,
  SettableCommunicationType,
} from '../CommunicationSettingsProfileUtils/CommunicationSettingsCommonsUtils';
import { BaseCommunicationSettingsFormValue } from '../CommunicationSettingsProfileUtils/CommunicationSettingsSharedUtils';
import {
  LeaseCommunicationSettingsProfileFormValues,
  LeaseSettableCommunicationType,
} from '../CommunicationSettingsProfileUtils/LeaseCommunicationSettingsUtils';
import {
  OwnerCommunicationSettingsFormValues,
  OwnerSettableCommunicationType,
} from '../CommunicationSettingsProfileUtils/OwnerCommunicationSettingsUtils';

interface CommunicationSettingsFormItemProps {
  communicationType: SettableCommunicationType;
  possibleCommunicationChannels: CommunicationChannel[];
  referent: CommunicationSettingsProfileReferent;
  defaultCollapse: boolean;
  previewProfile: CommunicationSettingsProfile | null;
  optionalSettings?: OptionalSettings[];
}

const getCommunicationTypeValues = (
  communicationType: SettableCommunicationType,
  referentIsLease: boolean,
  values: CommunicationSettingsFormValues,
  previewProfile: CommunicationSettingsProfile | null
) => {
  if (!isNil(previewProfile))
    return getCommunicationSettings(communicationType as unknown as CommunicationType, previewProfile);
  if (referentIsLease) {
    return (values as LeaseCommunicationSettingsProfileFormValues)[communicationType as LeaseSettableCommunicationType];
  }
  return (values as OwnerCommunicationSettingsFormValues)[communicationType as OwnerSettableCommunicationType];
};

const CommunicationSettingsFormItem: React.FC<CommunicationSettingsFormItemProps> = ({
  communicationType,
  possibleCommunicationChannels,
  referent,
  defaultCollapse,
  optionalSettings = [],
  previewProfile = null,
}) => {
  const { formatMessage } = useIntl();
  const { settingsAutomationWrite } = usePermissions();
  const previewMode = !isNil(previewProfile);

  const [settingsCollapsed, setSettingsCollapsed] = useState<boolean>(previewMode || defaultCollapse);
  const disableForm = previewMode ?? !settingsAutomationWrite;

  const { values, setFieldValue, errors, touched } = useFormikContext<CommunicationSettingsFormValues>();

  const referentIsLease = referent === CommunicationSettingsProfileReferent.LEASE;

  const communicationTypeValues = getCommunicationTypeValues(
    communicationType,
    referentIsLease,
    values,
    previewProfile
  );

  const communicationTypeErrors = referentIsLease
    ? (errors as FormikErrors<LeaseCommunicationSettingsProfileFormValues>)[
        communicationType as LeaseSettableCommunicationType
      ]
    : (errors as FormikErrors<OwnerCommunicationSettingsFormValues>)[
        communicationType as OwnerSettableCommunicationType
      ];
  const communicationTypeTouched = referentIsLease
    ? (touched as FormikTouched<LeaseCommunicationSettingsProfileFormValues>)[
        communicationType as LeaseSettableCommunicationType
      ]
    : (touched as FormikTouched<OwnerCommunicationSettingsFormValues>)[
        communicationType as OwnerSettableCommunicationType
      ];

  const handleCommunicationSendingRuleChange = (value: CommunicationSendingRule) => {
    setFieldValue(`${communicationType}.sendingRule`, value);
  };

  const handleCommunicationRecipientChange = (newRecipientValue: CommunicationSettingRecipient[]) => {
    setFieldValue(`${communicationType}.recipients`, newRecipientValue);
    const bccRecipientsWithoutClassicalRecipients = communicationTypeValues.recipientsInBcc.filter(
      (bccRecipient) => !newRecipientValue.includes(bccRecipient)
    );
    setFieldValue(`${communicationType}.recipientsInBcc`, bccRecipientsWithoutClassicalRecipients);
  };

  const handleCommunicationRecipientInBccChange = (newBccRecipientValue: CommunicationSettingRecipient[]) => {
    setFieldValue(`${communicationType}.recipientsInBcc`, newBccRecipientValue);
    const recipientsWithoutBccRecipients = communicationTypeValues.recipients.filter(
      (recipient) => !newBccRecipientValue.includes(recipient)
    );
    setFieldValue(`${communicationType}.recipients`, recipientsWithoutBccRecipients);
  };

  const handleCommunicationRecipientInCopyChange = (value: string[]) => {
    setFieldValue(`${communicationType}.recipientsInCopy`, value);
  };

  const handleCommunicationChannelChange = (value: CommunicationChannel) => {
    setFieldValue(`${communicationType}.communicationChannel`, value);
  };

  const handleReminderDaysOverdueChange = (value: number) => {
    if (
      communicationType === LeaseSettableCommunicationType.LEASE_UNPAID_AMOUNT_REMINDER &&
      value >=
        (values as LeaseCommunicationSettingsProfileFormValues)[
          LeaseSettableCommunicationType.LEASE_UNPAID_AMOUNT_SECOND_REMINDER
        ].daysOverdue
    ) {
      value += 1;
      setFieldValue(`${LeaseSettableCommunicationType.LEASE_UNPAID_AMOUNT_SECOND_REMINDER}.daysOverdue`, value);
    }
    if (
      [
        LeaseSettableCommunicationType.LEASE_UNPAID_AMOUNT_REMINDER,
        LeaseSettableCommunicationType.LEASE_UNPAID_AMOUNT_SECOND_REMINDER,
      ].includes(communicationType as LeaseSettableCommunicationType) &&
      value >=
        (values as LeaseCommunicationSettingsProfileFormValues)[
          LeaseSettableCommunicationType.LEASE_UNPAID_AMOUNT_FORMAL_NOTICE
        ].daysOverdue
    ) {
      setFieldValue(`${LeaseSettableCommunicationType.LEASE_UNPAID_AMOUNT_FORMAL_NOTICE}.daysOverdue`, value + 1);
    }
  };

  const isDisabled = communicationTypeValues.sendingRule === CommunicationSendingRule.DISABLE;

  return (
    <Grid style={{ marginTop: 10 }}>
      <Grid
        container
        style={{ alignItems: 'center', justifyContent: 'space-between', cursor: previewMode ? 'default' : 'pointer' }}
        onClick={() => !previewMode && setSettingsCollapsed(!settingsCollapsed)}
      >
        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
          {formatMessage({
            id: `settings.communications.communicationTypes.${communicationType}`,
          })}
        </Typography>
        {!previewMode && (
          <Grid style={{ display: 'flex' }}>
            {isDisabled && (
              <Typography style={{ color: Colors.SLATE_GREY, fontStyle: 'italic', marginRight: 5 }}>
                {formatMessage({ id: 'settings.communications.disabled' })}
              </Typography>
            )}
            <CollapsingChevron isOpen={settingsCollapsed} setOpen={setSettingsCollapsed} />
          </Grid>
        )}
      </Grid>

      {settingsCollapsed && (
        <Grid style={{ backgroundColor: Colors.PORCELAIN_GREY_1, borderRadius: 12, marginTop: 12 }}>
          <Grid style={{ padding: 40 }}>
            <CommunicationSendingRuleFormItem
              communicationType={communicationType}
              sendingRule={communicationTypeValues.sendingRule}
              disabled={disableForm}
              onChange={handleCommunicationSendingRuleChange}
            />
            {!isDisabled && (
              <Grid>
                {optionalSettings.includes(OptionalSettings.DAYS_OVERDUE) && (
                  <CommunicationNumberOfDaysFormItem
                    communicationType={communicationType}
                    settingType={OptionalSettings.DAYS_OVERDUE}
                    error={Boolean(
                      (
                        communicationTypeErrors as FormikErrors<
                          BaseCommunicationSettingsFormValue & { daysOverdue: number }
                        >
                      )?.daysOverdue &&
                        (
                          communicationTypeTouched as FormikTouched<
                            BaseCommunicationSettingsFormValue & { daysOverdue: number }
                          >
                        )?.daysOverdue
                    )}
                    numberOfDays={
                      previewMode && (communicationTypeValues as unknown as CommunicationSettings).daysOverdue!
                    }
                    disabled={disableForm}
                    onChange={handleReminderDaysOverdueChange}
                  />
                )}
                {optionalSettings.includes(OptionalSettings.DAYS_BEFORE_DUE_DATE) && (
                  <CommunicationNumberOfDaysFormItem
                    communicationType={communicationType}
                    settingType={OptionalSettings.DAYS_BEFORE_DUE_DATE}
                    error={Boolean(
                      (
                        communicationTypeErrors as FormikErrors<
                          BaseCommunicationSettingsFormValue & { daysBeforeDueDate: number }
                        >
                      )?.daysBeforeDueDate &&
                        (
                          communicationTypeTouched as FormikTouched<
                            BaseCommunicationSettingsFormValue & { daysBeforeDueDate: number }
                          >
                        )?.daysBeforeDueDate
                    )}
                    numberOfDays={
                      previewMode && (communicationTypeValues as unknown as CommunicationSettings).daysBeforeDueDate!
                    }
                    disabled={disableForm}
                  />
                )}
                <CommunicationChannelFormItem
                  communicationType={communicationType}
                  possibleChannels={possibleCommunicationChannels}
                  selectedChannel={communicationTypeValues.channel}
                  onChange={handleCommunicationChannelChange}
                  disabled={disableForm}
                  previewMode={previewMode}
                />
                <CommunicationRecipientFormItem
                  recipients={communicationTypeValues.recipients}
                  setRecipients={handleCommunicationRecipientChange}
                  disabled={disableForm}
                />
                {communicationTypeValues.channel === CommunicationChannel.EMAIL && (
                  <CommunicationRecipientInCopyFormItem
                    recipientsInBcc={communicationTypeValues.recipientsInBcc}
                    setRecipientsInBcc={handleCommunicationRecipientInBccChange}
                    selectedContactIds={communicationTypeValues.recipientsInCopy}
                    setSelectedContactIds={handleCommunicationRecipientInCopyChange}
                    disabled={disableForm}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
export default CommunicationSettingsFormItem;
