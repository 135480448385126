import { NotificationOption, Notification, NotificationType } from '@rentguru/commons-utils';

export const findNotificationsToDelete = (
  allNotifications: Notification[],
  heatingReminder: NotificationOption,
  pebReminder: NotificationOption,
  tankReminder: NotificationOption,
  chimneyReminder: NotificationOption,
  detectorReminder: NotificationOption
) => {
  return allNotifications.filter((notification) => {
    if (notification.technic) {
      if (notification.type === NotificationType.PebCertificate) {
        return pebReminder === NotificationOption.DISABLED;
      }
      if (notification.type === NotificationType.SmokeDetectorBattery) {
        return detectorReminder === NotificationOption.DISABLED;
      }
      if (notification.type === NotificationType.FuelTankCertificate) {
        return tankReminder === NotificationOption.DISABLED;
      }
      if (notification.type === NotificationType.HeatingCertificate) {
        return heatingReminder === NotificationOption.DISABLED;
      }
      if (notification.type === NotificationType.ChimneySweeping) {
        return chimneyReminder === NotificationOption.DISABLED;
      }
    }
    return false;
  });
};
