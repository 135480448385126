import { Collapse, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { useLeases } from 'src/hooks/LeasesContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { ReactComponent as BulbIcon } from 'src/icons/bulb.svg';
import UnitSelectorField from 'src/components/ui/Forms/FormField/UnitSelectorField';
import IconnedRemark from 'src/components/ui/IconnedRemark';
import * as Yup from 'yup';
import { useBuildings } from 'src/hooks/BuildingsContext';
import useTechnicsUtils from './useTechnicsUtils';
import { isEmpty } from 'lodash';

export const AddTechnicComplementaryDataSchema = Yup.object().shape({
  unitId: Yup.string().when('onBuilding', {
    is: false,
    then: Yup.string().required(),
    otherwise: Yup.string().nullable(),
  }),
  onBuilding: Yup.boolean().required(),
});

interface AddTechnicDialogComplementaryDataProps {
  messageId?: string;
  forcedOnUnit?: boolean;
}

export interface AddTechnicDialogComplementaryDataFormValues {
  unitId?: string;
  onBuilding?: boolean;
  buildingId?: string;
}

const AddTechnicDialogComplementaryData: React.FC<AddTechnicDialogComplementaryDataProps> = ({
  messageId,
  forcedOnUnit = false,
}) => {
  const { values, setFieldValue } = useFormikContext<AddTechnicDialogComplementaryDataFormValues>();
  const { formatMessage } = useIntl();
  const { getLease, leasesLoading } = useLeases();
  const { getUnit, unitsLoading } = useUnits();
  const { getBuilding, buildingsLoading } = useBuildings();
  const { getCurrentDestinationId } = useTechnicsUtils();

  if (leasesLoading || unitsLoading || buildingsLoading) {
    return <Typography>{formatMessage({ id: 'loading' })}</Typography>;
  }

  const completeLease = getLease(getCurrentDestinationId() ?? '');
  const completeBuilding = getBuilding(getCurrentDestinationId() ?? '');

  const leaseUnits = (completeLease?.units ?? []).map((unitLease) => {
    const unit = getUnit(unitLease.unit?.id ?? '')!;
    return unit;
  });

  const buildingUnits = (completeBuilding?.units ?? []).map((buildingUnit) => {
    const unit = getUnit(buildingUnit.id)!;
    return unit;
  });

  const units = !isEmpty(leaseUnits) ? leaseUnits : !isEmpty(buildingUnits) ? buildingUnits : [];

  const onSwitchChange = () => {
    setFieldValue('onBuilding', !values.onBuilding);
  };

  const shouldNotShowAllOptions = isEmpty(buildingUnits) && isEmpty(leaseUnits);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Collapse in={!values.onBuilding} style={{ width: '96%' }}>
          {!shouldNotShowAllOptions && (
            <Grid item xs={12}>
              <UnitSelectorField initialValue={values.unitId ?? ''} units={units} fieldName="unitId" />
            </Grid>
          )}
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <RadioGroup value={values.onBuilding} onChange={onSwitchChange}>
            {!shouldNotShowAllOptions && (
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={
                  <Typography style={{ fontSize: 15, fontWeight: 500 }}>
                    {formatMessage({ id: 'lease.detail.action.linkedToUnit' })}
                  </Typography>
                }
              />
            )}
            {!forcedOnUnit && (
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={
                  <Typography style={{ fontSize: 15, fontWeight: 500 }}>
                    {formatMessage({ id: 'lease.detail.action.linkedToBuilding' })}
                  </Typography>
                }
              />
            )}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {messageId && (
          <IconnedRemark
            Icon={BulbIcon}
            message={
              <Grid>
                <Typography>{formatMessage({ id: 'lease.detail.action.warning' })}</Typography>
                <Typography>{formatMessage({ id: messageId })}</Typography>
              </Grid>
            }
            style={{ marginLeft: 0 }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default AddTechnicDialogComplementaryData;
