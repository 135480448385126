/* eslint-disable react/no-array-index-key */
import { isEmpty, get, isNil } from 'lodash';
import { Collapse, Grid, Typography } from '@material-ui/core';
import { Colors, LeaseVariousOperationType, isNilOrEmpty } from '@rentguru/commons-utils';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { AddLeaseFormValues } from '../../../Leases/AddLease/AddLeaseForm';
import { getPotentialErrorIndexInDiscounts } from '../../../Leases/AddLease/AddLeaseUtils';
import { OperationFormValue } from '../FormField/AddOperation';
import FormAlertBox from '../../FormAlertBox';
import RentOperationRow from './RentOperationRow';
import { resolveMainUnitAndSubUnitIndexAndPrefix } from 'src/components/Leases/AddLease/useAddEditLeaseUtils';
import AddLeaseVariousOperationDialog from 'src/components/ui/Dialogs/AddLeaseVariousOperationDialog';

interface RentOperationsListProps {
  unitId: string;
  operationType: LeaseVariousOperationType;
}

const RentOperationsList: React.FC<RentOperationsListProps> = ({ unitId, operationType }) => {
  const [updatingLeaseVariousOperationForAddLease, setUpdatingLeaseVariousOperationForAddLease] = useState<{
    operation: OperationFormValue;
    index: number;
  } | null>(null);
  const { formatMessage } = useIntl();
  const { values } = useFormikContext<AddLeaseFormValues>();
  const { prefixFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(values, unitId);

  const isDiscount = operationType === LeaseVariousOperationType.DISCOUNT;

  const getListOfOperations = (): OperationFormValue[] => {
    if (isDiscount) {
      return get(values, `${prefixFieldName}discounts`, []);
    }

    const operations = get(values, `${prefixFieldName}leaseOperations`, []) as OperationFormValue[];
    return operations.filter((operation) => operation.operationType === operationType);
  };

  const leaseOperations = getListOfOperations();

  let showError = false;
  let potentialErrorData: {
    potentialErrorIndex: number;
    amountDistributedInMonths: {
      year: number;
      month: number;
      amount: number;
      indexes: number[];
    }[];
  } = {
    potentialErrorIndex: -1,
    amountDistributedInMonths: [
      {
        year: -1,
        month: -1,
        amount: -1,
        indexes: [-1],
      },
    ],
  };
  if (isDiscount) {
    potentialErrorData = getPotentialErrorIndexInDiscounts(
      get(values, `${prefixFieldName}rentalPrice`),
      leaseOperations
    );
  } else {
    leaseOperations.forEach((operation) => {
      if (values.applyVat && isNil(operation.vatRate)) {
        showError = true;
      }
    });
  }

  if (potentialErrorData.potentialErrorIndex !== -1) {
    showError = true;
  }

  return (
    <>
      {!isEmpty(leaseOperations) && (
        <Grid
          style={{
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            border: `1px solid ${Colors.GEYSER_GREY}`,
            marginTop: 15,
          }}
        >
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '100%',
              backgroundColor: Colors.PORCELAIN_GREY_3,
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
          >
            <Grid
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '30%',
                paddingLeft: 20,
                borderRight: `1px solid ${Colors.GEYSER_GREY}`,
                height: 40,
              }}
            >
              <Typography style={{ textAlign: 'left' }}>
                {formatMessage({ id: 'lease.detail.variousOperations.label' })}
              </Typography>
            </Grid>
            <Grid
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '42%',
                paddingLeft: 20,
                borderRight: `1px solid ${Colors.GEYSER_GREY}`,
                height: 40,
              }}
            >
              <Typography style={{ textAlign: 'left' }}>
                {formatMessage({ id: 'lease.detail.variousOperations.occurrencesColumn' })}
              </Typography>
            </Grid>
            <Grid
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '28%',
                height: 40,
              }}
            >
              <Grid style={{ width: '50%' }}>
                <Typography style={{ textAlign: 'left', marginLeft: 20 }}>
                  {formatMessage({ id: 'lease.detail.variousOperations.amount' })}
                </Typography>
              </Grid>
              <Grid style={{ width: '50%' }}>
                <Typography style={{ textAlign: 'right', marginRight: 20 }}>
                  {formatMessage({ id: 'lease.addLease.actionShort' })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {leaseOperations.map((lo, index) => (
            <RentOperationRow
              unitId={unitId}
              operation={lo}
              lvoIndex={index}
              last={index === leaseOperations.length - 1}
              setUpdatingLeaseVariousOperationForAddLease={setUpdatingLeaseVariousOperationForAddLease}
              discount={isDiscount}
              key={index}
              error={
                isDiscount &&
                potentialErrorData.potentialErrorIndex !== -1 &&
                potentialErrorData.amountDistributedInMonths[potentialErrorData.potentialErrorIndex].indexes.includes(
                  index
                )
              }
              dataTest={isDiscount ? `Discount-${index}` : `LeaseOperation-${index}`}
            />
          ))}
        </Grid>
      )}
      <Collapse in={showError && !isNilOrEmpty(leaseOperations)}>
        <FormAlertBox
          gridStyle={{ marginTop: 15 }}
          removeLeftMargin
          message1={formatMessage({ id: `lease.addLease.${isDiscount ? 'discountToHigh' : 'noVatIndicated'}` })}
        />
      </Collapse>
      {!isNil(updatingLeaseVariousOperationForAddLease) && (
        <AddLeaseVariousOperationDialog
          open={!isNil(updatingLeaseVariousOperationForAddLease)}
          unitId={unitId}
          operationType={
            (updatingLeaseVariousOperationForAddLease.operation.operationType ??
              LeaseVariousOperationType.CHARGE) as LeaseVariousOperationType
          }
          updatingLeaseVariousOperationForAddLease={updatingLeaseVariousOperationForAddLease}
          onClose={() => setUpdatingLeaseVariousOperationForAddLease(null)}
        />
      )}
    </>
  );
};

export default RentOperationsList;
