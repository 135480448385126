import { useFormikContext } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { EndLeaseFormValues, useEndLeaseStyles } from './EndLeaseForm';
import { Typography } from '@material-ui/core';
import FormikDatePicker from 'src/components/ui/FormikDatePicker';
import { LeaseActionEndInitiator, LeaseType } from '@rentguru/commons-utils';
import { subMonths } from 'date-fns';
import { getNumberOrMonthsForNotice } from './utils';

const EndLeaseNoticePeriodField = ({
  leaseType,
  initiator,
}: {
  leaseType: LeaseType;
  initiator: LeaseActionEndInitiator;
}) => {
  const { formatMessage } = useIntl();
  const { values } = useFormikContext<EndLeaseFormValues>();
  const classes = useEndLeaseStyles();

  const newEndDate = new Date(values.endDate);
  const numberOfMonthForNotice = getNumberOrMonthsForNotice(initiator, leaseType);
  const noticeShouldHaveBeenSentOn = subMonths(newEndDate, numberOfMonthForNotice);

  return (
    <>
      <Typography
        className={classes.informationFillInTitle}
        style={{
          marginTop: 20,
        }}
      >
        {formatMessage({ id: `lease.endExtendLease.noticePeriodTitle` })}
      </Typography>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <FormikDatePicker
          name="currentEndDate"
          label={formatMessage({ id: 'lease.endExtendLease.noticePeriod' })}
          value={noticeShouldHaveBeenSentOn}
          style={{ width: 260, maxWidth: 260 }}
          views={['year', 'month', 'date']}
          disabled
        />
      </div>
    </>
  );
};

export default EndLeaseNoticePeriodField;
