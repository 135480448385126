import { Edit, AddCircle } from '@material-ui/icons';
import { Colors } from '@rentguru/commons-utils';
import { CustomIconButton, CustomIconButtonProps } from '@up2rent/ui';

interface EditOrAddIconButtonProps extends CustomIconButtonProps {
  isSelected: boolean;
}

const EditOrAddIconButton: React.FC<EditOrAddIconButtonProps> = ({ isSelected, ...rest }) => (
  <CustomIconButton
    style={{ position: 'absolute', top: 'calc(50% - 14px)', right: '30px' }}
    size="small"
    Icon={isSelected ? Edit : AddCircle}
    iconStyle={{ fill: Colors.SILVER }}
    {...rest}
  />
);

export default EditOrAddIconButton;
