import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { TemplateTag, TagCategory, TemplateTagTypes, Colors } from '@rentguru/commons-utils';

interface TemplateTagsTabsProps {
  insertTag: (startTag: TemplateTag, end?: TemplateTag) => void;
  tags: TemplateTag[];
  resetSelection: () => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => {
  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} {...other}>
      <Box p={3}>{children}</Box>
    </Typography>
  );
};

const useStyles = makeStyles((_: Theme) => ({
  root: {
    width: '100%',
    marginTop: 20,
  },
  'tab-root': {
    minWidth: 0,
    minHeight: 24,
  },
  'tabs-root': {
    minHeight: 24,
  },
  'app-bar': {
    height: 'auto',
    backgroundColor: 'white',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    'box-shadow': '0px 0px 0px 0px',
  },
  noIndicator: {
    height: '0px',
  },
  tagButton: {
    marginRight: '8px',
    border: '1px rgba(0, 0, 0, 0.32) solid',
    borderRadius: '8px',
    marginBottom: 10,
  },
  disabledTagButton: {
    border: '0px #f5f7f8 solid',
    color: Colors.PORCELAIN_GREY_3,
    backgroundColor: Colors.PORCELAIN_GREY_1,
  },
  buttonText: {
    color: Colors.SLATE_GREY,
    fontSize: 12,
  },
  buttonTextDisabled: {
    color: Colors.SEPARATOR_GREY,
    fontSize: 12,
  },
}));

const getTagsOfCategory = (tags: TemplateTag[], category: TagCategory) => {
  return tags.filter(
    (tag) =>
      tag.category === category && tag.type !== TemplateTagTypes.CONDITION_IF_END && tag.type !== TemplateTagTypes.ELSE
  );
};

const TemplateTagsTabs: React.FC<TemplateTagsTabsProps> = ({ insertTag, tags, resetSelection }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const [value, setValue] = React.useState(0);

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    resetSelection();
  };
  const handleChangeIndex = (index: number) => {
    setValue(index);
  };
  const categoryNames = Object.values(TagCategory);
  // Keep the category if at least one tag belongs in it
  const templateCategoryNames = categoryNames.filter((categoryName) =>
    tags.some((tag) => {
      if (categoryName !== TagCategory.CONDITION) return tag.category === categoryName;

      return tag.category === TagCategory.CONDITION && tag.type === TemplateTagTypes.CONDITION_IF_START;
    })
  );
  return (
    <div className={classes.root}>
      <AppBar position="static" classes={{ root: classes['app-bar'] }}>
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{ indicator: classes.noIndicator, root: classes['tabs-root'] }}
          variant="fullWidth"
          textColor="secondary"
        >
          {templateCategoryNames.map((categoryName: TagCategory) => (
            <Tab
              key={categoryName}
              classes={{ root: classes['tab-root'] }}
              label={formatMessage({ id: `enums.TagCategory.${categoryName}` })}
            />
          ))}
        </Tabs>
      </AppBar>
      {/* @ts-ignore */}
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {templateCategoryNames.map((categoryName: TagCategory, index) => (
          <TabPanel key={categoryName} value={value} index={index} dir={theme.direction}>
            {getTagsOfCategory(tags, categoryName).map((tag: TemplateTag) => {
              const startTag = tag;
              const tagLabel = tag.label;
              const disabled = false;

              return (
                <Button
                  key={`${tag.label} ${tag.category}`}
                  className={classes.tagButton}
                  classes={{ disabled: classes.disabledTagButton }}
                  disabled={disabled}
                  onClick={() => {
                    const endTag =
                      startTag.type === TemplateTagTypes.CONDITION_IF_START
                        ? tags.find((endt) => endt.type === TemplateTagTypes.CONDITION_IF_END)
                        : undefined;
                    insertTag(startTag, endTag);
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Typography className={disabled ? classes.buttonTextDisabled : classes.buttonText}>
                    {tagLabel}
                  </Typography>
                </Button>
              );
            })}
          </TabPanel>
        ))}
      </SwipeableViews>
    </div>
  );
};
export default TemplateTagsTabs;
