/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-shadow */
import { Button, createStyles, Grid, makeStyles, MenuItem, Popper, Theme, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { NestedElement } from './NestedSelector';
import { ReactComponent as DropDown } from 'src/icons/drop-down.svg';
import { isEmpty, isNil } from 'lodash';
import { BOX_SHADOW, MenuItemText } from '@up2rent/ui';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import { ReactComponent as DeleteIcon } from 'src/icons/delete.svg';
import { DataTypes, SearchButtons } from 'src/components/Dashboard/DashboardTodos/DashboardDialogs/AddNotification';
import { useIntl } from 'react-intl';
import { MultipleSelectLeaseBuildingUnitData } from './MultipleSelectLeaseBuildingUnit';
import { Colors } from '@rentguru/commons-utils';

interface NestedPopperProps {
  handleClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFirstNodeValue: (value: any) => void;
  generalClosing: () => void;
  onSearchChange?: (value: string) => void;
  searchValue?: string;
  searchBar: boolean;
  open: boolean;
  parentAnchorEl: null | HTMLElement;
  datas: NestedElement[];
  width?: number;
  SearchButtons?: SearchButtons[];
  setSelectedSearchType?: (value: DataTypes) => void;
  selectedSearchType?: DataTypes;
  placement:
    | 'bottom'
    | 'bottom-end'
    | 'bottom-start'
    | 'left'
    | 'left-end'
    | 'left-start'
    | 'right'
    | 'right-end'
    | 'right-start'
    | 'top'
    | 'top-end'
    | 'top-start';
}

export const getCorrectPlacement = (
  index: number,
  datas: NestedElement[] | MultipleSelectLeaseBuildingUnitData[]
):
  | 'bottom'
  | 'bottom-end'
  | 'bottom-start'
  | 'left'
  | 'left-end'
  | 'left-start'
  | 'right'
  | 'right-end'
  | 'right-start'
  | 'top'
  | 'top-end'
  | 'top-start' => {
  if (datas.length === 1) {
    return 'right-end';
  }
  if (datas.length === index + 1) {
    return 'right-end';
  }
  return index === 0 ? 'right-start' : index === 1 || index === 2 ? 'right' : 'right-end';
};

const localAnimationStyles = makeStyles((theme: Theme) => ({
  left: {
    transform: 'rotate(270deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
}));

const useStyles = makeStyles(() =>
  createStyles({
    popper: {
      border: 'none',
      boxShadow: BOX_SHADOW,
      borderRadius: 5,
      maxHeight: 253,
      // height: 400,
      zIndex: 2000, // Be on top also in dialog
      backgroundColor: Colors.CLASSICAL_WHITE,
      padding: 0,
      margin: 0,
      overflowY: 'scroll',
    },
    menuItem: {
      padding: '5px 0px 5px 20px',
    },
    input: {
      outline: 'none',
      border: 'none',
      height: 43,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Mulish',
      fontSize: 14,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: Colors.SLATE_GREY,
    },
    button: {
      '& .MuiButton-root': {
        minWidth: 30,
        padding: 0,
      },
    },
  })
);

// eslint-disable-next-line react/display-name
const NestedPopper: React.FC<NestedPopperProps> = React.forwardRef(
  (
    {
      parentAnchorEl,
      datas,
      handleClose,
      onSearchChange,
      searchValue,
      searchBar,
      open,
      placement,
      setFirstNodeValue,
      generalClosing,
      width = 260,
      SearchButtons,
      setSelectedSearchType,
      selectedSearchType,
    },
    ref
  ) => {
    const styles = useStyles();
    const arrowDirection = localAnimationStyles();
    const [localAnchorEl, setLocalAnchorEl] = useState<null | { htmlElement: HTMLElement; index: number }>(null);
    const [localOpen, setlOcalOpen] = useState(false);
    const [popperHover, setPoppetHover] = useState(false);
    const { formatMessage } = useIntl();
    const resetSearchValue = () => {
      if (!isNil(onSearchChange)) onSearchChange('');
    };

    useEffect(() => {
      setlOcalOpen(false);
      setLocalAnchorEl(null);
    }, [parentAnchorEl, searchValue, selectedSearchType]);

    return (
      <Grid innerRef={ref}>
        <Popper
          open={open}
          anchorEl={parentAnchorEl}
          placement={placement}
          className={styles.popper}
          style={{ width }}
          onMouseEnter={() => setPoppetHover(true)}
          onMouseLeave={() => setPoppetHover(false)}
        >
          {searchBar && (
            <Grid style={{ display: 'flex' }} container>
              <Grid
                item
                xs={2}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 2100 }}
              >
                <Tooltip
                  title={
                    <Grid>
                      {formatMessage({ id: 'dashboard.nestedComponent.helper' })} <br />
                      <br />{' '}
                      {formatMessage({
                        id: 'dashboard.nestedComponent.suggestion',
                      })}
                    </Grid>
                  }
                  placement="top-start"
                >
                  <InfoSvg style={{ fill: Colors.SILVER }} />
                </Tooltip>
              </Grid>
              <Grid item xs={8}>
                <input
                  value={searchValue}
                  onChange={({ currentTarget }) => {
                    if (!isNil(onSearchChange)) onSearchChange(currentTarget.value);
                  }}
                  className={styles.input}
                  placeholder={formatMessage({ id: 'dashboard.nestedComponent.search' })}
                />
              </Grid>
              <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Button
                  onClick={() => resetSearchValue()}
                  style={{ minWidth: 50, maxWidth: 50, width: 50, height: '100%' }}
                >
                  <DeleteIcon fill={Colors.BLUEY} />
                </Button>
              </Grid>
              <Grid container style={{ borderBottom: '1px solid rgba(84, 110, 122, .4)', paddingBottom: 10 }}>
                {!isNil(SearchButtons) &&
                  SearchButtons.map((searchButton) => (
                    <Grid
                      item
                      xs={4}
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      key={searchButton.buttonType}
                    >
                      <Button
                        style={{
                          width: '100%',
                        }}
                        onClick={() =>
                          !isNil(setSelectedSearchType) ? setSelectedSearchType(searchButton.buttonType) : null
                        }
                      >
                        {searchButton.ButtonIcon}
                      </Button>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          )}
          {datas.map((data, index) => {
            const isDataTheLastOne = isNil(datas[index + 1]);
            const isGroupBreak = () => {
              if (!isDataTheLastOne) {
                if (datas[index + 1].group !== datas[index].group) return true;
              } else if (isDataTheLastOne && index !== 0) {
                if (datas[index - 1].group !== datas[index].group) return true;
              }
            };
            return (
              <MenuItem
                key={`${data.primaryLabel}-${index}`}
                onMouseOver={(event) => {
                  setLocalAnchorEl({ htmlElement: event.currentTarget, index });
                  setlOcalOpen(true);
                }}
                onClick={() => {
                  if (!isNil(data.selectable) && data.selectable) {
                    setFirstNodeValue(data);
                    generalClosing();
                  }
                }}
                className={styles.menuItem}
                style={{
                  borderBottom: isGroupBreak() ? '1px solid #BEC7CE' : 'none',
                  background:
                    index === localAnchorEl?.index &&
                    (popperHover || (localOpen && !isEmpty(datas[localAnchorEl!.index]?.children ?? [])))
                      ? Colors.PORCELAIN_GREY_2
                      : Colors.CLASSICAL_WHITE,
                }}
                id={`${data.primaryLabel}-${index}`}
              >
                <Grid
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  {data.FrontIcon && <Grid style={{ marginRight: 20 }}>{data.FrontIcon}</Grid>}
                  <MenuItemText primary={data.primaryLabel} secondary={data.secondaryLabel} />
                  {!isEmpty(datas[index]?.children ?? []) && (
                    <Grid style={{ marginRight: 15 }}>
                      <DropDown style={{ fill: Colors.BLUEY }} className={arrowDirection.left} />
                    </Grid>
                  )}
                </Grid>
              </MenuItem>
            );
          })}
        </Popper>

        {localOpen && !isEmpty(datas[localAnchorEl!.index]?.children ?? []) && (
          <NestedPopper
            handleClose={handleClose}
            open={localOpen}
            parentAnchorEl={localAnchorEl?.htmlElement ?? null}
            searchBar={false}
            datas={(datas[localAnchorEl!.index].children ?? []) as NestedElement[]}
            placement={getCorrectPlacement(localAnchorEl?.index as number, datas)}
            setFirstNodeValue={setFirstNodeValue}
            generalClosing={generalClosing}
            width={340}
          />
        )}
      </Grid>
    );
  }
);

export default NestedPopper;
