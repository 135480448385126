import { Divider, Grid } from '@material-ui/core';
import { isNil } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { useUnits } from 'src/hooks/UnitsContext';
import { ElevatedPaper } from '@up2rent/ui';
import FormHeader from '../../FormHeader';
import VatManagementFields from '../FormField/VatManagementFields';

interface VatManagementForm {
  isMainUnit?: boolean;
  unitId?: string;
}

// eslint-disable-next-line no-redeclare
const VatManagementForm: React.FC<VatManagementForm> = ({ isMainUnit, unitId }) => {
  const { getUnit } = useUnits();
  const { formatMessage } = useIntl();

  if (isNil(unitId)) {
    return null;
  }
  const currentUnit = getUnit(unitId);
  if (isNil(currentUnit)) {
    return null;
  }

  return (
    <Grid>
      {isMainUnit && (
        <>
          <ElevatedPaper>
            <FormHeader title={formatMessage({ id: 'contact.detail.general.vatLabel' })} />
            <Divider style={{ marginBottom: 10 }} />
            <VatManagementFields />
          </ElevatedPaper>
        </>
      )}
    </Grid>
  );
};

export default VatManagementForm;
