import { Typography } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { useUserRoles } from 'src/hooks/UserRolesContext';
import { UserRole } from '@rentguru/commons-utils';
import RoleMemberRow from './RoleMemberRow';

interface RoleMembersProps {
  userRole: UserRole;
}

const RoleMembers: React.FC<RoleMembersProps> = ({ userRole }) => {
  const { formatMessage } = useIntl();
  const { getUsersWithRole } = useUserRoles();
  const users = getUsersWithRole(userRole.id);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30 }}>
        <Typography variant="h6">{formatMessage({ id: 'settings.members' })}</Typography>
      </div>
      {users.map((user, index) => {
        return <RoleMemberRow key={user.id} user={user} divider={index !== users.length - 1} />;
      })}
    </>
  );
};

export default RoleMembers;
