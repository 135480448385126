/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-param-last */
import React, { Reducer, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import {
  LeaseActionHistory,
  syncLeaseActionHistories,
  getLeaseActionHistory as getLeaseActionHistoryQuery,
  createLeaseActionHistory as createMutation,
  updateLeaseActionHistory as updateMutation,
  deleteLeaseActionHistory as deleteMutation,
  CreateLeaseActionHistoryMutationVariables,
  DeleteLeaseActionHistoryMutationVariables,
  CreateLeaseActionHistoryInput,
  UpdateLeaseActionHistoryInput,
  cleanInputUpdate,
  cleanInputCreate,
  getReadId,
  getTableClientId,
  isIdInList,
  LooseObject,
} from '@rentguru/commons-utils';
import { deleteAndHideEntityWithFetchBefore, list, mutation } from '@up2rent/fetch-utils';
import { useUser } from './UserContext';
import isNil from 'lodash/isNil';
import { usePermissions } from './utils/PermissionsContext';
import { LeaseExtended, useLeases } from './LeasesContext';

export interface LeaseActionHistoryContext extends LeaseActionHistoryState {
  createLeaseActionHistory: (
    input: CreateLeaseActionHistoryInput | Omit<CreateLeaseActionHistoryInput, 'clientId' | 'readId'>
  ) => Promise<LeaseActionHistory>;
  getLeaseActionHistoriesOfLease: (leaseId: string) => LeaseActionHistory[];
  getLeaseActionHistory: (id: string) => LeaseActionHistory | undefined;
  getLeaseOfLeaseActionHistory: (id: string) => LeaseExtended | null | undefined;
  updateLeaseActionHistory: (updates: UpdateLeaseActionHistoryInput) => Promise<LeaseActionHistory>;
  deleteLeaseActionHistory: (leaseActionHistory: LeaseActionHistory) => Promise<LeaseActionHistory | null>;
  setFetchLeaseActionHistories: () => void;
}

interface LeaseActionHistoryState {
  leaseActionHistories: LeaseActionHistory[] | null;
  loading: boolean;
  shouldFetchLeaseActionHistory: boolean;
}

type Action =
  | {
      type: 'SHOULD_FETCH' | 'IS_FETCHING';
    }
  | {
      type: 'ADD_LEASE_ACTION_HISTORY';
      payload: { leaseActionHistory: LeaseActionHistory };
    }
  | {
      type: 'FETCHED';
      payload: { leaseActionHistories: LeaseActionHistory[] };
    }
  | {
      type: 'UPDATE_LEASE_ACTION_HISTORY';
      payload: { leaseActionHistory: LeaseActionHistory };
    }
  | {
      type: 'DELETE_LEASE_ACTION_HISTORY';
      payload: { id: string };
    };

const initialState: LeaseActionHistoryState = {
  loading: false,
  leaseActionHistories: null,
  shouldFetchLeaseActionHistory: false,
};

const leaseActionHistoryReducer = (state: LeaseActionHistoryState, action: Action): LeaseActionHistoryState => {
  switch (action.type) {
    case 'SHOULD_FETCH':
      if (state.loading || state.shouldFetchLeaseActionHistory) {
        return state;
      }
      return {
        ...state,
        shouldFetchLeaseActionHistory: true,
      };
    case 'IS_FETCHING':
      return {
        ...state,
        loading: true,
      };
    case 'FETCHED':
      // Build List with merged object
      return {
        ...state,
        loading: false,
        shouldFetchLeaseActionHistory: false,
        leaseActionHistories: action.payload.leaseActionHistories,
      };
    case 'ADD_LEASE_ACTION_HISTORY':
      return {
        ...state,
        leaseActionHistories: [...(state.leaseActionHistories ?? []), action.payload.leaseActionHistory],
      };
    case 'UPDATE_LEASE_ACTION_HISTORY':
      if (!state.leaseActionHistories) {
        return state;
      }
      return {
        ...state,
        leaseActionHistories: state.leaseActionHistories.map((leaseActionHistory) => {
          if (leaseActionHistory.id === action.payload.leaseActionHistory.id) {
            return action.payload.leaseActionHistory;
          }
          return leaseActionHistory;
        }),
      };
    case 'DELETE_LEASE_ACTION_HISTORY':
      if (!state.leaseActionHistories) {
        return state;
      }
      return {
        ...state,
        leaseActionHistories: state.leaseActionHistories.filter(
          (leaseActionHistory) => leaseActionHistory.id !== action.payload.id
        ),
      };
    default:
      return state;
  }
};

export const fetchLeaseActionHistories = async (
  by: 'byClientId' | 'byLease',
  byValue: string,
  additionalFilter?: LooseObject
): Promise<LeaseActionHistory[]> => {
  const indexName = by === 'byClientId' ? 'clientId' : 'leaseId';
  return await list<LeaseActionHistory>(syncLeaseActionHistories, indexName, byValue, additionalFilter);
};

export const LeaseActionHistoryContext = React.createContext<LeaseActionHistoryContext | null>(null);

export const LeaseActionHistoryContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<LeaseActionHistoryState, Action>>(
    leaseActionHistoryReducer,
    initialState
  );
  const { leases, leasesLoading, getLease } = useLeases();
  const { leasesCreationDelete } = usePermissions();
  const { clientId, rootUser } = useUser();

  const setFetchLeaseActionHistories = () => {
    dispatch({ type: 'SHOULD_FETCH' });
  };

  useEffect(() => {
    const fetchAndSet = async () => {
      dispatch({ type: 'IS_FETCHING' });
      const result = await fetchLeaseActionHistories('byClientId', getTableClientId(clientId!, 'LeaseActionHistory'));
      const resultFiltered = rootUser ? result : result.filter((li) => li.lease && isIdInList(li.lease, leases));
      dispatch({ type: 'FETCHED', payload: { leaseActionHistories: resultFiltered } });
    };
    if (state.shouldFetchLeaseActionHistory && !leasesLoading) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetchLeaseActionHistory, leasesLoading]);

  const createLeaseActionHistory = async (
    input: CreateLeaseActionHistoryInput | Omit<CreateLeaseActionHistoryInput, 'clientId' | 'readId'>
  ): Promise<LeaseActionHistory> => {
    const leaseActionHistory = await mutation<LeaseActionHistory, CreateLeaseActionHistoryMutationVariables>(
      createMutation,
      {
        input: {
          ...(cleanInputCreate(input) as CreateLeaseActionHistoryInput),
          clientId: getTableClientId(clientId!, 'LeaseActionHistory'),
          readId: getReadId(clientId!, 'LeaseActionHistory'),
        },
      }
    );
    dispatch({ type: 'ADD_LEASE_ACTION_HISTORY', payload: { leaseActionHistory } });
    return leaseActionHistory;
  };

  const getLeaseActionHistoriesOfLease = (leaseId: string) => {
    if (isNil(state.leaseActionHistories)) {
      return [];
    }
    return (state.leaseActionHistories as LeaseActionHistory[]).filter(
      (leaseActionHistory) => leaseActionHistory.lease && leaseActionHistory.lease.id === leaseId
    );
  };

  const updateLeaseActionHistory = async (updates: UpdateLeaseActionHistoryInput): Promise<LeaseActionHistory> => {
    const result = await mutation<LeaseActionHistory>(updateMutation, {
      input: { ...cleanInputUpdate(updates, false) },
    });
    dispatch({ type: 'UPDATE_LEASE_ACTION_HISTORY', payload: { leaseActionHistory: result } });
    dispatch({ type: 'SHOULD_FETCH' });
    return result;
  };

  const deleteLeaseActionHistory = async (
    leaseActionHistory: LeaseActionHistory
  ): Promise<LeaseActionHistory | null> => {
    if (!leasesCreationDelete) {
      return null;
    }
    const result = await deleteAndHideEntityWithFetchBefore<
      LeaseActionHistory,
      DeleteLeaseActionHistoryMutationVariables
    >(leaseActionHistory, getLeaseActionHistoryQuery, deleteMutation, updateMutation);

    dispatch({ type: 'DELETE_LEASE_ACTION_HISTORY', payload: { id: leaseActionHistory.id } });
    return result;
  };

  const getLeaseActionHistory = (id: string) => {
    const leaseActionHistory = (state.leaseActionHistories ?? []).find(
      (leaseActionHistoryElement) => leaseActionHistoryElement.id === id
    );
    return leaseActionHistory;
  };

  const getLeaseOfLeaseActionHistory = (id: string) => {
    const leaseActionHistory = getLeaseActionHistory(id);
    if (isNil(leaseActionHistory) || isNil(leaseActionHistory.lease)) {
      return undefined;
    }
    return getLease(leaseActionHistory.lease.id);
  };

  const values = useMemo(
    () => ({
      ...state,
      createLeaseActionHistory,
      getLeaseActionHistoriesOfLease,
      getLeaseActionHistory,
      updateLeaseActionHistory,
      deleteLeaseActionHistory,
      getLeaseOfLeaseActionHistory,
      setFetchLeaseActionHistories,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <LeaseActionHistoryContext.Provider value={values}>{children}</LeaseActionHistoryContext.Provider>;
};

export const useLeaseActionHistories = (): LeaseActionHistoryContext => {
  const firstRender = useRef<boolean>(false);
  const context = useContext<LeaseActionHistoryContext | null>(LeaseActionHistoryContext);

  if (isNil(context)) {
    throw new Error('`useLeaseActionHistories` hook must be used within a `LeaseActionHistoryContext` component');
  }
  useEffect(() => {
    if (!firstRender.current && isNil(context.leaseActionHistories)) {
      context.setFetchLeaseActionHistories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  return context;
};
