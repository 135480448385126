import React from 'react';
import { FormikProps, useFormikContext } from 'formik';
import { AddEditAgencyRateValues } from '../AddEditAgencyRateForm';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { AgencyRateAmountDetailType, Colors } from '@rentguru/commons-utils';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    borderRadius: 20,
    width: 32,
    height: 24,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: 'rgba(38, 50, 56, 0.1)',
    color: Colors.SLATE_GREY,
    '&$selected': {
      backgroundColor: Colors.PORCELAIN_GREY_1,
      '&:hover': {
        backgroundColor: Colors.PORCELAIN_GREY_1,
      },
    },
  },
});

interface AmountTypeToogleSwitch {
  index: number;
}

// eslint-disable-next-line no-redeclare
const AmountTypeToogleSwitch: React.FC<AmountTypeToogleSwitch> = ({ index }) => {
  const { setFieldValue, values }: FormikProps<AddEditAgencyRateValues> = useFormikContext();
  const classes = useStyles();

  return (
    <ToggleButtonGroup
      size="small"
      value={values.amountDetails[index].amountType}
      exclusive
      onChange={(_e, value) => {
        if (value !== null) {
          setFieldValue(`amountDetails[${index}].amountType`, value);
        }
      }}
    >
      <ToggleButton value={AgencyRateAmountDetailType.PERCENTAGE} classes={{ root: classes.root }}>
        %
      </ToggleButton>
      <ToggleButton value={AgencyRateAmountDetailType.PRICE} classes={{ root: classes.root }}>
        €
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default AmountTypeToogleSwitch;
