import { ReactElement } from 'react';
import { Colors, SignatureDocument, SignatureDocumentStatus, isNilOrEmpty } from '@rentguru/commons-utils';
import { useIntl } from 'react-intl';
import { ColoredBox } from '@up2rent/ui';
import { Grid, Tooltip } from '@material-ui/core';

export const fromStatusToColor = (status: SignatureDocumentStatus) => {
  if (status === SignatureDocumentStatus.DRAFT) return Colors.CARROT_ORANGE;
  if (status === SignatureDocumentStatus.ERROR) return Colors.CARNATION_RED;
  if (status === SignatureDocumentStatus.PENDING) return Colors.DODGER_BLUE;
  if (status === SignatureDocumentStatus.REJECTED) return Colors.CARNATION_RED;
  if (status === SignatureDocumentStatus.SIGNED) return Colors.BLUEY;
  return Colors.CLASSICAL_WHITE;
};

const ConditionalToolTip = ({
  isVisible,
  title,
  children,
}: {
  isVisible: boolean;
  title: string;
  children: ReactElement;
}) => (isVisible ? <Tooltip title={title}>{children}</Tooltip> : children);

const RegisteredLetterStatusBox = ({ signatureDocument }: { signatureDocument?: SignatureDocument | null }) => {
  const { formatMessage } = useIntl();
  if (!signatureDocument) {
    return null;
  }
  const statusText = formatMessage({ id: `enums.SignatureDocumentLetterStatus.${signatureDocument.status}` });
  const statusColor = fromStatusToColor(signatureDocument.status as SignatureDocumentStatus);
  const isToolTipVisible = !isNilOrEmpty(signatureDocument.externalStatus);

  return (
    <ConditionalToolTip
      isVisible={isToolTipVisible}
      title={
        isToolTipVisible
          ? formatMessage({
              id: `enums.PostbirdStatus.${signatureDocument.externalStatus}`,
              defaultMessage: '',
            })
          : ''
      }
    >
      <Grid>
        <ColoredBox boxColor={statusColor}>{statusText}</ColoredBox>
      </Grid>
    </ConditionalToolTip>
  );
};

export default RegisteredLetterStatusBox;
