import React from 'react';
import clsx from 'clsx';
import { Add } from '@material-ui/icons';
import { ButtonBase } from '@material-ui/core';
import { useStyles } from './FieldFilterResetButton';

interface PlusButtonProps {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  text: string;
  style?: React.CSSProperties;
}

const PlusButton: React.FC<PlusButtonProps> = ({ onClick, text, style }) => {
  const classes = useStyles();
  return (
    <ButtonBase
      className={clsx(classes.root, { [classes.active]: true })}
      component="span"
      onClick={onClick}
      style={style}
    >
      <Add className={clsx(classes.icon)} />
      {text}
    </ButtonBase>
  );
};
export default PlusButton;
