import React from 'react';
import { useIntl } from 'react-intl';
import { useTeams } from 'src/hooks/TeamsContext';
import { Typography, TextField } from '@material-ui/core';
import TeamSelectorField, { TeamSelectorFormValues as ImpFormValues } from '../FormField/TeamSelectorField';
import { useFormikContext, FormikProps } from 'formik';
import { useCustomizedComboStyles } from 'src/components/ui/ComboBox/TextComboBox';
import { isNil } from 'lodash';
import { CustomPopover } from '@up2rent/ui';

export { TeamSelectorSchema } from 'src/components/ui/Forms/FormField/TeamSelectorField';
export type TeamSelectorFormValues = ImpFormValues;

interface TeamSelectorInputProps {
  readonly?: boolean;
  onChange?: (event: string) => void;
  label?: string;
}
const TeamSelectorInput: React.FC<TeamSelectorInputProps> = ({ readonly = false, onChange, label }) => {
  const { getTreeTeams, loading, error, getTeam } = useTeams();
  const { formatMessage } = useIntl();
  const classes = useCustomizedComboStyles();
  const { values, errors, touched }: FormikProps<TeamSelectorFormValues> = useFormikContext();

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'team-select' : undefined;

  if (error) {
    return <Typography>{error}</Typography>;
  }
  if (loading) {
    return <Typography>{formatMessage({ id: 'loading' })}</Typography>;
  }

  const selectedTeam = !isNil(values.parentId) ? getTeam(values.parentId)!.name : '';

  return (
    <>
      <TextField
        data-test="userTeam"
        type={undefined}
        name="dummyField"
        variant="filled"
        margin="dense"
        label={label || formatMessage({ id: 'settings.addTeam.parent' })}
        value={selectedTeam}
        error={Boolean((errors.parentId && touched.parentId) || (errors.teamId && touched.teamId))}
        InputProps={{
          classes: { underline: classes.underline, root: classes.root, disabled: classes.disabled },
        }}
        InputLabelProps={{ className: classes.label }}
        SelectProps={{ classes: { select: classes.select } }}
        onClick={handleClick}
        style={{ width: 280, maxWidth: 'none' }}
      />
      <CustomPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        paperPadding={0}
        paperWidth={'unset'}
        enablePointerEvents
      >
        <TeamSelectorField teams={getTreeTeams()} readonly={readonly} onChange={onChange} />
      </CustomPopover>
    </>
  );
};

export default TeamSelectorInput;
