import React from 'react';
import { useIntl } from 'react-intl';
import { match, useRouteMatch } from 'react-router-dom';
import { isNil } from 'lodash';
import { Skeleton } from '@material-ui/lab';
import { Grid, MenuList, Typography } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import {
  Colors,
  INTEGRATIONS_WITH_CATEGORIES,
  IntegrationCategory,
  IntegrationCategories,
  IntegrationName,
} from '@rentguru/commons-utils';
import { useIntegrations } from 'src/hooks/IntegrationsContext';
import { RouteDestination } from 'src/components/Routes/Routes';
import CustomizedMenuItem from 'src/components/ui/CustomizedMenuItem';
import ContentDetailHeader from 'src/components/ui/ContentDetailHeader';
import { useNoScrollbarStyles } from 'src/components/ui/noScrollbar';
import { ENTITY_DETAIL_PANEL_SIZE } from 'src/components/ui/EntityDetail/EntityDetail';
import { CustomIconButton } from '@up2rent/ui';
import IntegrationSmallBox from './IntegrationSmallBox';

const STICKY_HEADER_SIZE = 120;
const LEFT_MENU_SIZE = 270;

const categoriesNavigation = [
  {
    to: ``,
    labelId: 'settings.integrations.details.menuAll',
    categories: Object.keys(IntegrationCategory) as unknown as IntegrationCategory[],
  },
  {
    to: `accounting`,
    labelId: 'settings.integrations.details.menuAccounting',
    categories: [IntegrationCategory.ACCOUNTING],
  },
  {
    to: `banking`,
    labelId: 'settings.integrations.details.menuBanking',
    categories: [IntegrationCategory.BANKING],
  },
  {
    to: `AI`,
    labelId: 'settings.integrations.details.menuAi',
    categories: [IntegrationCategory.AI],
  },
];

const isMenuSelected = (
  matchParam: match<{
    detailName: string;
  }>,
  navigationTo: string
) => matchParam.params.detailName === navigationTo || (navigationTo === '' && isNil(matchParam.params.detailName));

const useStyles = makeStyles({
  sectionTabTitle: {
    color: Colors.LIGHT_BLUE_GREY,
    fontSize: 10,
    letterSpacing: '1px',
    lineHeight: '12px',
    textTransform: 'uppercase',
    marginLeft: 15,
    marginRight: 15,
  },
  root: {
    display: 'flex',
    height: '100%',
    overflow: 'auto',
    flexDirection: 'column',
  },
  header: {
    height: STICKY_HEADER_SIZE,
    width: ENTITY_DETAIL_PANEL_SIZE, // Need to fix width as position is fixed
    overflow: 'hidden',
    position: 'fixed',
    display: 'flex',
  },
  headerBack: { width: LEFT_MENU_SIZE },
  headerTitle: { width: `calc(100% - ${LEFT_MENU_SIZE}px)` },
  rootBelowHeader: {
    paddingTop: STICKY_HEADER_SIZE,
    height: `calc(100% - ${STICKY_HEADER_SIZE}px)`,
    overflow: 'hidden',
  },
  rootBelowHeaderContent: { display: 'flex', width: '100%', height: '100%' },
  leftMenu: { width: LEFT_MENU_SIZE, overflowY: 'auto', overflowX: 'hidden' },
  contentContainer: {
    width: `calc(100% - ${LEFT_MENU_SIZE}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    width: `100%`,
    backgroundColor: 'white',
    borderTopLeftRadius: 10,
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
});

interface IntegrationsDetailsProps {
  onIntegrationClick: (id: string | null, name: IntegrationName) => void;
  onClose?: () => void;
}

const IntegrationsDetails: React.FC<IntegrationsDetailsProps> = ({ onIntegrationClick, onClose }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const scrollbarClasses = useNoScrollbarStyles();
  const { error, loading, integrations } = useIntegrations();

  const matchParam = useRouteMatch<{ detailName: string }>({
    path: `${RouteDestination.SETTINGS}/integrations/details/:detailName?`,
  });
  if (!matchParam) {
    return null;
  }

  const selectedCategory =
    categoriesNavigation.find((navigation) => isMenuSelected(matchParam, navigation.to)) ?? categoriesNavigation[0];
  const filteredIntegrations = INTEGRATIONS_WITH_CATEGORIES.filter((integration: IntegrationCategories) =>
    selectedCategory.categories.some((category) => integration.categories.includes(category))
  );

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        {/* Header in fixed position */}
        <Grid className={classes.headerBack}>
          <CustomIconButton
            id="exitDetails"
            style={{ marginLeft: 15, marginTop: 30, marginBottom: 40 }}
            aria-label="Menu"
            onClick={onClose}
            Icon={ArrowBack}
            iconStyle={{ fill: Colors.BLUE_GREY }}
          />
        </Grid>
        <Grid className={classes.headerTitle}>
          <ContentDetailHeader title={formatMessage({ id: 'settings.integrationsMenu' })} actions={[]} />
        </Grid>
      </Grid>

      {/* Menu and Content */}
      <Grid className={classes.rootBelowHeader}>
        <Grid className={classes.rootBelowHeaderContent}>
          <Grid className={clsx(scrollbarClasses.noScrollbar, classes.leftMenu)}>
            <div style={{ marginRight: 15, marginLeft: 15 }}>
              <Typography variant="subtitle1" className={classes.sectionTabTitle}>
                {formatMessage({
                  id: 'settings.integrations.details.categories',
                })}
              </Typography>
              <MenuList>
                {categoriesNavigation.map((navigation) => {
                  const to = !isNil(matchParam.params.detailName)
                    ? matchParam.url.replace(matchParam.params.detailName, navigation.to)
                    : matchParam.url +
                      (matchParam.url.charAt(matchParam.url.length - 1) === '/' ? '' : '/') +
                      navigation.to;
                  const selected = isMenuSelected(matchParam, navigation.to);

                  return (
                    <CustomizedMenuItem key={navigation.labelId} to={to} selected={selected}>
                      {formatMessage({ id: navigation.labelId })}
                    </CustomizedMenuItem>
                  );
                })}
              </MenuList>
            </div>
          </Grid>

          <Grid className={classes.contentContainer}>
            {/* Content */}
            <Grid className={clsx(scrollbarClasses.noScrollbar, classes.content)}>
              <Grid
                style={{
                  marginLeft: 30,
                  marginRight: 30,
                  marginTop: 24,
                }}
              >
                {(loading || !integrations) && <Skeleton />}
                {error && <Typography>{error}</Typography>}
                {!loading && integrations && !error && (
                  <div
                    style={{
                      paddingRight: 60,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-evenly',
                      flexWrap: 'wrap',
                    }}
                  >
                    {filteredIntegrations.map((integration: IntegrationCategories) => {
                      const existingIntegration = integrations.find(
                        (currentIntegration) => currentIntegration.name === integration.name
                      );
                      return (
                        <IntegrationSmallBox
                          name={integration.name}
                          onClick={onIntegrationClick}
                          key={integration.name}
                          integration={existingIntegration}
                        />
                      );
                    })}
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IntegrationsDetails;
