import { post } from 'aws-amplify/api';
import { LooseObject } from '@rentguru/commons-utils';
import { convertFromRaw, convertToRaw, EditorState, RawDraftContentBlock, RawDraftInlineStyleRange } from 'draft-js';

export const translateText = async (text: string, sourceLanguage: string, destinationLanguage: string) => {
  try {
    const apiName = 'translation';
    const path = '/translate';
    const myInit = {
      body: {
        text,
        sourceLanguage,
        destinationLanguage,
      },
      headers: {},
    };

    const response = await post({ apiName, path, options: myInit }).response;
    const bodyResponse = (await response.body.json()) as { result: string };
    return bodyResponse.result;
  } catch (err) {
    console.error('error in translation', err);
    return '';
  }
};

export const translateEditorState = async (
  editorState: EditorState,
  sourceLanguage: string,
  destinationLanguage: string
) => {
  const contentState = editorState.getCurrentContent();
  const newContentBlockArray: RawDraftContentBlock[] = [];
  const { blocks, entityMap } = convertToRaw(contentState);
  const newBlockPromises = blocks.map(async (block, index) => {
    const newBlock = await getNewTranslatedBlock(block, sourceLanguage, destinationLanguage);
    newContentBlockArray[index] = newBlock;
  });
  await Promise.all(newBlockPromises);
  const blocksWithTags = insertTagEntitiesIntoBlocks(newContentBlockArray, entityMap);
  const newContent = convertFromRaw({ blocks: blocksWithTags, entityMap });
  return EditorState.createWithContent(newContent);
};

const getNewTranslatedBlock = async (
  block: RawDraftContentBlock,
  sourceLanguage: string,
  destinationLanguage: string
): Promise<RawDraftContentBlock> => {
  if (block.type === 'conditionalStart' || block.type === 'conditionalEnd' || block.type === 'conditionalElse') {
    // Nothing to do for conditional blocks
    return block;
  }
  const blockText: string = block.text;
  if (blockText.trim() === '') {
    // No text => nothing to do
    return block;
  }
  const translationResult = await translateBlockText(blockText, sourceLanguage, destinationLanguage);
  return {
    key: block.key,
    text: translationResult,
    type: block.type,
    depth: block.depth,
    data: block.data,
    inlineStyleRanges: block.inlineStyleRanges.reduce((acc: RawDraftInlineStyleRange[], inlineStyle) => {
      // Keep styles that were applied on the whole block
      if (isStyleAppliedOnWholeBlock(block, inlineStyle)) {
        acc.push({ ...inlineStyle, length: translationResult.length });
      }
      return acc;
    }, []),
    entityRanges: [],
  };
};

/**
 * Split the block on the tags (i.e. {{...}}) and reconstruct with the translations of the non tags strings
 */
const translateBlockText = async (blockText: string, sourceLanguage: string, destinationLanguage: string) => {
  if (blockText.trim() === '') return blockText;
  const textWithHtmlTags = InsertNonTranslatableHtml(blockText);
  const translatedText = await translateText(textWithHtmlTags, sourceLanguage, destinationLanguage);
  return removeNonTranslatableHtmlTags(translatedText);
};

/**
 * Insert html tags between tags to prevent them from being translated
 */
const InsertNonTranslatableHtml = (text: string) => {
  const tagAndBracketsRegexp = /\{\{.*?\}\}/gm;
  const startHtmlTag = '<x>';
  const endHtmlTag = '</x>';
  return text.replaceAll(tagAndBracketsRegexp, (matchedTag) => {
    return `${startHtmlTag}${matchedTag}${endHtmlTag}`;
  });
};

/**
 * remove html tags between tags
 */
const removeNonTranslatableHtmlTags = (text: string) => {
  const startHtmlTag = '<x>';
  const endHtmlTag = '</x>';
  return text.replaceAll(startHtmlTag, '').replaceAll(endHtmlTag, '');
};

const insertTagEntitiesIntoBlocks = (blocks: RawDraftContentBlock[], entityMap: LooseObject) => {
  // tag label => entity key of the tag
  const tagLabelsToKeys = tagLabelToKey(entityMap);
  for (const block of blocks) {
    const blockText = block.text;
    const tagAndBracketsRegexp = /\{\{.*?\}\}/gm;
    let regexpArray;
    // eslint-disable-next-line no-cond-assign
    while ((regexpArray = tagAndBracketsRegexp.exec(blockText)) !== null) {
      const match = regexpArray[0];
      const startIndex = regexpArray.index;
      const entityKeyOfMatch = tagLabelsToKeys[match];
      // Apply entity on the match {{...}} with its entityKey
      block.inlineStyleRanges.push({
        offset: startIndex,
        length: match.length,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        style: 'TAG' as any,
      });
      block.entityRanges.push({
        offset: startIndex,
        length: match.length,
        key: Number(entityKeyOfMatch),
      });
    }
  }
  return blocks;
};

const tagLabelToKey = (entityMap: LooseObject) => {
  const tagLabelToKeyObject: { [key: string]: string } = {};
  // eslint-disable-next-line guard-for-in
  for (const key in Object.keys(entityMap)) {
    const entityMetaData = entityMap[key];
    if (entityMetaData) {
      const label = entityMetaData.data.label;
      tagLabelToKeyObject[label] = key;
    }
  }
  return tagLabelToKeyObject;
};

const isStyleAppliedOnWholeBlock = (block: RawDraftContentBlock, style: RawDraftInlineStyleRange) => {
  return style.offset === 0 && style.length === block.text.length && (style.style as string) !== 'TAG';
};
