import { Grid, Tooltip, Typography } from '@material-ui/core';
import { ReactComponent as ArrowRight } from 'src/icons/arrow-right.svg';
import { ReactComponent as CircleCheck } from 'src/icons/circle-check.svg';
import { useIntl } from 'react-intl';
import { Colors, formatStandardizedUTCDate, getLocaleFromLanguage } from '@rentguru/commons-utils';
import { useLocale } from 'src/i18n/IntlProviderWrapper';

interface LeaseDatesColumnProps {
  startDate: string;
  endDate: string;
  hasLeaseAlreadyBeenEnded: boolean;
}

const LeaseDatesColumn: React.FC<LeaseDatesColumnProps> = ({ startDate, endDate, hasLeaseAlreadyBeenEnded }) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();

  const locale = getLocaleFromLanguage(language);

  return (
    <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Grid style={{ display: 'flex', alignItems: 'flex-start' }}>
        <Grid style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            style={{
              fontSize: 14,
              fontWeight: 'normal',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: 'normal',
              letterSpacing: 'normal',
              color: Colors.SLATE_GREY,
            }}
          >
            {formatStandardizedUTCDate(new Date(startDate), 'd MMM yyyy', { locale })}
          </Typography>
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 'normal',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: 'normal',
              letterSpacing: 'normal',
              color: Colors.BLUEY,
              paddingTop: 4,
            }}
          >
            {formatMessage({ id: 'lease.detail.general.startDate' })}
          </Typography>
        </Grid>
        <ArrowRight
          style={{
            fill: Colors.BLUEY,
            paddingRight: 10,
            paddingLeft: 10,
            width: 20,
            height: 20,
          }}
        />
        <Grid style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            style={{
              fontSize: 14,
              fontWeight: 'normal',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: 'normal',
              letterSpacing: 'normal',
              color: Colors.SLATE_GREY,
            }}
          >
            {formatStandardizedUTCDate(new Date(endDate), 'd MMM yyyy', { locale })}
          </Typography>
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 'normal',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: 'normal',
              letterSpacing: 'normal',
              color: Colors.BLUEY,
              paddingTop: 4,
            }}
          >
            {formatMessage({ id: 'lease.detail.general.endDate' })}
          </Typography>
        </Grid>
      </Grid>
      {hasLeaseAlreadyBeenEnded && (
        <Tooltip title={formatMessage({ id: 'lease.endExtendLease.confirmedEndDate' })}>
          <Grid>
            <CircleCheck
              style={{
                fill: Colors.DODGER_BLUE,
                paddingLeft: 10,
                marginRight: 20,
              }}
            />
          </Grid>
        </Tooltip>
      )}
    </Grid>
  );
};

export default LeaseDatesColumn;
