import React from 'react';
import CustomInputComboBox, { CustomInputComboBoxProps, CustomInputItemType } from './FullCustomComboBox';
import { useFormikContext, FormikProps } from 'formik';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

export interface AdditionalFormikProps {
  name: string;
  emptyValue?: CustomInputItemType;
  fieldToReturn?: keyof CustomInputItemType;
  onChangeObserver?: (
    valueToReturn: CustomInputItemType,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => void;
}

type FormikComboBox = AdditionalFormikProps & Omit<CustomInputComboBoxProps, 'onChange'>;

// eslint-disable-next-line no-redeclare
const FormikComboBox: React.FC<FormikComboBox> = ({ name, fieldToReturn, emptyValue, onChangeObserver, ...rest }) => {
  const { setFieldValue }: FormikProps<unknown> = useFormikContext();
  const onChange = (_event: unknown, values: CustomInputItemType[] | null) => {
    let valueToReturn: CustomInputItemType | null;

    if (isNil(values) || isEmpty(values)) {
      if (isNil(emptyValue)) {
        valueToReturn = null;
      } else {
        valueToReturn = emptyValue;
      }
    } else if (values.length === 1) valueToReturn = values[0];
    else valueToReturn = values[1];
    if (!isNil(fieldToReturn) && !isNil(valueToReturn)) valueToReturn = valueToReturn[fieldToReturn];
    setFieldValue(name, valueToReturn);
    if (!isNil(onChangeObserver) && !isNil(valueToReturn)) {
      onChangeObserver(valueToReturn, setFieldValue);
    }
  };
  return <CustomInputComboBox {...rest} onChange={onChange} />;
};
export default FormikComboBox;
