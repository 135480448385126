import React from 'react';
import { Menu, MenuItem, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import CustomDatePicker from 'src/components/ui/CustomDatePicker';
import { MetricBoxTimeFilterName } from '../DashboardFilters/MetricBoxTimeFilter';
import isNil from 'lodash/isNil';
import { parseISO } from 'date-fns';
import TextButton from 'src/components/ui/TextButton';
import { useDashboardFilters } from 'src/hooks/DashboardFiltersContext';
import { isAfterOrEqual, isBeforeOrEqual, isDateValid } from '@rentguru/commons-utils';
import { stopPropagation } from 'src/components/Dashboard/Dashboard';

export interface CustomFilterMenuProps {
  name: MetricBoxTimeFilterName;
  anchor: HTMLElement | null;
  onClose: () => void;
}

const CustomFilterMenu: React.FC<CustomFilterMenuProps> = ({ name, anchor, onClose }) => {
  const { formatMessage } = useIntl();
  const { dashboardFilters, updateDashboardFilters, resetDashboardFilters } = useDashboardFilters();
  const menuFiltersFromTo = dashboardFilters[name];

  const handleFromFilterChange = async (from: Date) => {
    await updateDashboardFilters({
      ...dashboardFilters,
      [name]: { ...menuFiltersFromTo, from: from.toISOString() },
    });
  };
  const handleToFilterChange = async (to: Date) => {
    await updateDashboardFilters({
      ...dashboardFilters,
      [name]: { ...menuFiltersFromTo, to: to.toISOString() },
    });
  };

  const resetFilter = async () => {
    await resetDashboardFilters(name);
  };

  const fromFilterMaxDate =
    !isNil(menuFiltersFromTo) && isDateValid(parseISO(menuFiltersFromTo.to))
      ? parseISO(menuFiltersFromTo.to)
      : undefined;
  const toFilterMinDate =
    !isNil(menuFiltersFromTo) && isDateValid(parseISO(menuFiltersFromTo.from))
      ? parseISO(menuFiltersFromTo.from)
      : undefined;

  return (
    <Menu open={!isNil(anchor)} anchorEl={anchor} onClose={onClose}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingRight: 10,
          paddingLeft: 10,
          alignItems: 'center',
        }}
      >
        <Typography style={{ fontSize: 14, fontWeight: 700 }}>
          {formatMessage({ id: 'dashboard.filters.chooseCustomDate' })}
        </Typography>

        <TextButton onClick={resetFilter} onMouseDown={stopPropagation}>
          {formatMessage({ id: 'communications.filter.reset' })}
        </TextButton>
      </div>
      <MenuItem style={{ alignItems: 'baseline' }}>
        <CustomDatePicker
          name="from"
          label={formatMessage({ id: 'rentalUnit.filter.from' })}
          value={isNil(menuFiltersFromTo) ? null : parseISO(menuFiltersFromTo.from)}
          style={{ width: 150, marginRight: 20 }}
          onChange={(date) => {
            if (
              !isNil(date) &&
              (isNil(fromFilterMaxDate) || (!isNil(fromFilterMaxDate) && isBeforeOrEqual(date, fromFilterMaxDate)))
            )
              handleFromFilterChange(date);
          }}
          keyboardIconStyle={{ width: '20px' }}
          keyboardIconProps={{ size: 'small' }}
          safeDateFormat={true}
          maxDate={fromFilterMaxDate}
        />
        <CustomDatePicker
          name="to"
          label={formatMessage({ id: 'rentalUnit.filter.to' })}
          value={isNil(menuFiltersFromTo) ? null : parseISO(menuFiltersFromTo.to)}
          style={{ width: 150 }}
          minDate={toFilterMinDate}
          onChange={(date) => {
            if (
              !isNil(date) &&
              (isNil(toFilterMinDate) || (!isNil(toFilterMinDate) && isAfterOrEqual(date, toFilterMinDate)))
            )
              handleToFilterChange(date);
          }}
          keyboardIconStyle={{ width: '20px' }}
          keyboardIconProps={{ size: 'small' }}
          safeDateFormat={true}
        />
      </MenuItem>
    </Menu>
  );
};

export default CustomFilterMenu;
