import { Divider, Grid, MenuItem, Typography } from '@material-ui/core';
import Dialog from 'src/components/ui/Dialog';
import { Colors, TechnicType } from '@rentguru/commons-utils';
import { toUpper } from 'lodash';
import React, { Reducer, useReducer } from 'react';
import { useIntl } from 'react-intl';
import { useFiles } from 'src/hooks/FilesContext';
import { ActionButton , LoaderButton } from '@up2rent/ui';
import { CustomizedSelect } from '../ui/CustomizedSelect';
import AddChimneyForm from './Chimneys/AddChimneyForm';
import AddDetectorForm from './Detectors/AddDetectorForm';
import AddHeatingForm from './Heatings/AddHeatingForm';
import AddEPBForm from './EPBs/AddEPBForm';
import AddTankForm from './Tanks/AddTankForm';
import * as Yup from 'yup';
import { AddTechnicComplementaryDataSchema } from './AddTechnicDialogComplementaryData';

interface AddTechnicDialogProps {
  open: boolean;
  id?: string;
  onClose: () => void;
  entity: 'building' | 'unit' | 'leaseBuilding' | 'leaseUnit';
  shouldRedirectAfterCreate?: boolean;
}

// eslint-disable-next-line no-shadow
enum AddTechnicReducerAction {
  SET_PEB_TAB = 'SET_PEB_TAB',
  SET_DETECTORS_TAB = 'SET_DETECTORS_TAB',
  SET_HEATING_TAB = 'SET_HEATING_TAB',
  SET_CHIMNEY_TAB = 'SET_CHIMNEY_TAB',
  SET_FUEL_TANK_TAB = 'SET_FUEL_TANK_TAB',
  SET_ALL_TO_FALSE = 'SET_ALL_TO_FALSE',
  SET_SELECT_VALUE = 'SET_SELECT_VALUE',
}

const fromTabToEnumElement = (type: Omit<TechnicType, 'UTILITY_PROVIDER' | 'LIFT'>) => {
  switch (type) {
    case TechnicType.CHIMNEY:
      return AddTechnicReducerAction.SET_CHIMNEY_TAB;
    case TechnicType.DETECTOR:
      return AddTechnicReducerAction.SET_DETECTORS_TAB;
    case TechnicType.FUELTANK:
      return AddTechnicReducerAction.SET_FUEL_TANK_TAB;
    case TechnicType.HEATING:
      return AddTechnicReducerAction.SET_HEATING_TAB;
    case TechnicType.PEB:
      return AddTechnicReducerAction.SET_PEB_TAB;
    default:
      return AddTechnicReducerAction.SET_PEB_TAB;
  }
};

interface AddTechnicReducerState {
  pebTab: boolean;
  detectorsTab: boolean;
  heatingTab: boolean;
  chimneyTab: boolean;
  fuelTankTab: boolean;
  selectValue: Omit<TechnicType, 'UTILITY_PROVIDER' | 'LIFT'>;
}

type Action =
  | {
      type: AddTechnicReducerAction.SET_PEB_TAB;
      payload: boolean;
    }
  | {
      type: AddTechnicReducerAction.SET_DETECTORS_TAB;
      payload: boolean;
    }
  | {
      type: AddTechnicReducerAction.SET_HEATING_TAB;
      payload: boolean;
    }
  | {
      type: AddTechnicReducerAction.SET_CHIMNEY_TAB;
      payload: boolean;
    }
  | {
      type: AddTechnicReducerAction.SET_FUEL_TANK_TAB;
      payload: boolean;
    }
  | {
      type: AddTechnicReducerAction.SET_SELECT_VALUE;
      payload: Omit<TechnicType, 'UTILITY_PROVIDER' | 'LIFT'>;
    }
  | {
      type: AddTechnicReducerAction.SET_ALL_TO_FALSE;
    };

const initialState: AddTechnicReducerState = {
  chimneyTab: false,
  detectorsTab: false,
  fuelTankTab: false,
  heatingTab: false,
  pebTab: false,
  selectValue: '',
};

const AddTechnicReducer = (state: AddTechnicReducerState, action: Action): AddTechnicReducerState => {
  switch (action.type) {
    case AddTechnicReducerAction.SET_PEB_TAB:
      return {
        ...initialState,
        pebTab: action.payload,
      };
    case AddTechnicReducerAction.SET_DETECTORS_TAB:
      return {
        ...initialState,
        detectorsTab: action.payload,
      };
    case AddTechnicReducerAction.SET_HEATING_TAB:
      return {
        ...initialState,
        heatingTab: action.payload,
      };
    case AddTechnicReducerAction.SET_CHIMNEY_TAB:
      return {
        ...initialState,
        chimneyTab: action.payload,
      };
    case AddTechnicReducerAction.SET_FUEL_TANK_TAB:
      return {
        ...initialState,
        fuelTankTab: action.payload,
      };
    case AddTechnicReducerAction.SET_ALL_TO_FALSE:
      return {
        ...initialState,
      };
    case AddTechnicReducerAction.SET_SELECT_VALUE:
      return {
        ...state,
        selectValue: action.payload as Omit<TechnicType, 'UTILITY_PROVIDER' | 'LIFT'>,
      };
    default:
      return {
        ...state,
      };
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const includeComplementaryDataSchema = (currentSchema: Yup.ObjectSchema<any>, shouldInclude: boolean) => {
  if (!shouldInclude) return currentSchema;
  return currentSchema.concat(AddTechnicComplementaryDataSchema);
};

const AddTechnicDialog: React.FC<AddTechnicDialogProps> = ({
  id,
  onClose,
  open,
  entity,
  shouldRedirectAfterCreate = false,
}) => {
  const [state, dispatch] = useReducer<Reducer<AddTechnicReducerState, Action>>(AddTechnicReducer, initialState);
  const { createFile } = useFiles();
  const { formatMessage } = useIntl();

  const handleDialogChange = (value: Omit<TechnicType, 'UTILITY_PROVIDER' | 'LIFT'>) => {
    dispatch({ type: fromTabToEnumElement(value), payload: true });
    dispatch({ type: AddTechnicReducerAction.SET_SELECT_VALUE, payload: value });
  };

  const hasSelectedTechnicType = state.selectValue !== '';

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      fullWidth
      PaperProps={{ style: { borderRadius: 10, maxWidth: 640 } }}
    >
      <Grid
        style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: 20,
          marginRight: 20,
          marginTop: 25,
          marginBottom: 25,
        }}
      >
        <Typography style={{ fontWeight: 'bold', fontSize: 20, marginTop: 10, textAlign: 'left' }}>
          {formatMessage({ id: 'lease.detail.action.addTechnic' })} :
        </Typography>
        <CustomizedSelect
          value={state.selectValue}
          onChange={handleDialogChange}
          style={{ width: 280, marginLeft: 5, backgroundColor: 'white' }}
        >
          {Object.keys(TechnicType).map((key) => {
            if ([TechnicType.UTILITY_PROVIDER, TechnicType.LIFT].includes(key as TechnicType)) return null;
            return (
              <MenuItem value={key} key={key}>
                {formatMessage({ id: `enums.TechnicType.${key}` })}
              </MenuItem>
            );
          })}
        </CustomizedSelect>
      </Grid>
      {state.pebTab && (
        <AddEPBForm
          afterSubmit={onClose}
          cancelSubmit={onClose}
          createFile={createFile}
          id={id ?? ''}
          entity={entity}
          showTitle={false}
          showComplementaryData
          shouldRedirectAfterCreate={shouldRedirectAfterCreate}
        />
      )}
      {state.detectorsTab && (
        <AddDetectorForm
          afterSubmit={onClose}
          cancelSubmit={onClose}
          id={id ?? ''}
          entity={entity}
          showTitle={false}
          showComplementaryData
          shouldRedirectAfterCreate={shouldRedirectAfterCreate}
        />
      )}
      {state.heatingTab && (
        <AddHeatingForm
          afterSubmit={onClose}
          cancelSubmit={onClose}
          id={id ?? ''}
          entity={entity}
          showTitle={false}
          showComplementaryData
          shouldRedirectAfterCreate={shouldRedirectAfterCreate}
        />
      )}
      {state.chimneyTab && (
        <AddChimneyForm
          afterSubmit={onClose}
          cancelSubmit={onClose}
          id={id ?? ''}
          entity={entity}
          showTitle={false}
          showComplementaryData
          shouldRedirectAfterCreate={shouldRedirectAfterCreate}
        />
      )}
      {state.fuelTankTab && (
        <AddTankForm
          afterSubmit={onClose}
          cancelSubmit={onClose}
          id={id ?? ''}
          entity={entity}
          showTitle={false}
          showComplementaryData
          shouldRedirectAfterCreate={shouldRedirectAfterCreate}
        />
      )}

      {!hasSelectedTechnicType && (
        <>
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          <div
            style={{
              marginBottom: 20,
              marginRight: 30,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <ActionButton
              onClick={onClose}
              style={{ background: 'none', color: Colors.DARK_SLATE_GREY, marginRight: 20 }}
            >
              {toUpper(formatMessage({ id: 'cancel' }))}
            </ActionButton>
            <LoaderButton loading={false} success={false} disabled>
              {formatMessage({
                id: 'save',
              })}
            </LoaderButton>
          </div>
        </>
      )}
    </Dialog>
  );
};

export default AddTechnicDialog;
