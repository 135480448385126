import { Collapse, Divider, Grid, LinearProgress, MenuItem, TextField } from '@material-ui/core';
import { MessagesEN, MessagesFR, MessagesNL, User } from '@rentguru/commons-utils';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useUser } from 'src/hooks/UserContext';
import { usePasswordUtils } from 'src/hooks/usePasswordUtils';
import { ContentHeader , ElevatedPaper, LoaderButton } from '@up2rent/ui';
import CustomizedSelect from 'src/components/ui/CustomizedSelect';

interface SecuritySettingsFormValues {
  originalValues: User;
  newEmail: string;
  oldPassword: string;
  newPassword1: string;
  newPassword2: string;
  language: string;
  defaultErrorMessage: string;
}

const SecuritySettingsFormFields = () => {
  const { isOwner } = useUser();
  const { formatMessage } = useIntl();
  const [passwordFocused, setPasswordFocused] = useState(false);
  const { values, handleChange, handleBlur, errors, touched, isSubmitting } =
    useFormikContext<SecuritySettingsFormValues>();
  const { actualProgress, amountOfConditionsForPassword, handleChangeNewPassword, localStyles } = usePasswordUtils();

  const getStyleOfLinearProgress = () => {
    switch (amountOfConditionsForPassword) {
      case 1:
        return 'linearProgress1';
      case 2:
        return 'linearProgress2';
      case 3:
        return 'linearProgress3';
      case 4:
        return 'linearProgress4';
      case 5:
        return 'linearProgress5';
      default:
        return 'linearProgress1';
    }
  };

  return (
    <ElevatedPaper style={{ margin: 20, maxWidth: 640, padding: 0, paddingBottom: 20 }}>
      <ContentHeader title={formatMessage({ id: 'settings.security.login' })} />
      <Divider style={{ marginTop: 10, marginBottom: 10 }} />
      <div
        style={{
          marginTop: 20,
          marginLeft: 30,
          marginRight: 30,
          marginBottom: 20,
        }}
      >
        {!isOwner && (
          <TextField
            label={formatMessage({ id: 'signup.emailLabel' })}
            id="newEmail"
            type="email"
            name="newEmail"
            autoComplete="email"
            margin="dense"
            variant="filled"
            fullWidth
            value={values.newEmail}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        )}
        <Collapse in={passwordFocused} mountOnEnter>
          <TextField
            label={formatMessage({ id: 'settings.security.oldPassword' })}
            id="oldPassword"
            type="password"
            autoComplete="current-password"
            margin="dense"
            variant="filled"
            fullWidth
            value={values.oldPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(errors.oldPassword && touched.oldPassword)}
            helperText={
              errors.oldPassword && touched.oldPassword ? formatMessage({ id: 'error.differentPasswords' }) : ''
            }
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
        </Collapse>
        <TextField
          label={formatMessage({ id: 'settings.security.newPassword' })}
          id="newPassword1"
          type="password"
          autoComplete="new-password"
          margin="dense"
          variant="filled"
          fullWidth
          value={values.newPassword1}
          onChange={(event) => handleChangeNewPassword(event.target.value)}
          onBlur={handleBlur}
          onClick={() => setPasswordFocused(true)}
          error={Boolean(errors.newPassword1 && touched.newPassword1)}
          helperText={
            errors.newPassword1 && touched.newPassword1
              ? formatMessage({
                  id: `error.${errors.newPassword1}`,
                  defaultMessage: errors.defaultErrorMessage,
                })
              : ''
          }
        />
        <Grid style={{ display: ' flex', marginBottom: 5 }}>
          {Object.keys(actualProgress).map((_element, index) => {
            return (
              <LinearProgress
                key={_element}
                variant="determinate"
                value={amountOfConditionsForPassword > index ? 100 : 0}
                style={{
                  width: '5%',
                  marginRight: 5,
                }}
                className={localStyles[getStyleOfLinearProgress()]}
              />
            );
          })}
        </Grid>
        <Collapse in={passwordFocused}>
          <TextField
            label={formatMessage({ id: 'settings.security.confirmNewPassword' })}
            id="newPassword2"
            type="password"
            autoComplete="new-password"
            margin="dense"
            variant="filled"
            fullWidth
            value={values.newPassword2}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(errors.newPassword2 && touched.newPassword2)}
            helperText={
              errors.newPassword2 && touched.newPassword2 ? formatMessage({ id: 'error.confirmationPassword' }) : ''
            }
          />
        </Collapse>
        <CustomizedSelect
          label={formatMessage({
            id: 'settings.languages',
          })}
          fieldName="language"
          value={values.language}
          FormControlProps={{ required: false, style: { width: 580 } }}
          error={Boolean(errors.language && touched.language)}
          id="languages"
        >
          <MenuItem value="en">{MessagesEN.enums.AdvertisementLanguages.en}</MenuItem>
          <MenuItem value="fr">{MessagesFR.enums.AdvertisementLanguages.fr}</MenuItem>
          <MenuItem value="nl">{MessagesNL.enums.AdvertisementLanguages.nl}</MenuItem>
        </CustomizedSelect>
      </div>
      <Divider style={{ marginTop: 10, marginBottom: 10 }} />
      <Grid
        style={{
          marginRight: 30,
          display: 'flex',
          float: 'right',
          alignItems: 'center',
        }}
      >
        <LoaderButton id="submitConnexionSettings" loading={isSubmitting}>
          {formatMessage({
            id: 'save',
          })}
        </LoaderButton>
      </Grid>
    </ElevatedPaper>
  );
};

export default SecuritySettingsFormFields;
