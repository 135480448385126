import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { Typography, Grid, Divider, Menu, MenuItem } from '@material-ui/core';
import Dialog from 'src/components/ui/Dialog';
import { User, Colors, UserStatus } from '@rentguru/commons-utils';
import { useUsers } from 'src/hooks/UsersContext';
import { ReactComponent as Team } from 'src/icons/team.svg';
import { ReactComponent as Face } from 'src/icons/face.svg';
import { getDisplayableUserName, isInvitePending } from 'src/utils/userutils';
import { MenuItemText, ConfirmDialog } from '@up2rent/ui';
import DeleteMember from './DeleteMember';
import EditMember from './EditMember';
import UserRoleField from './UserRoleField';
import MoreHorizontalIconButton from 'src/components/ui/CustomIconButtons/MoreHorizontalIconButton';

interface MemberRowProps {
  user: User;
  divider?: boolean;
  onClick: (user: User) => void;
}

enum MemberDialog {
  DISABLE_MEMBER,
  EDIT_MEMBER,
  ENABLE_MEMBER,
  DELETE_MEMBER,
}

const MemberRow: React.FC<MemberRowProps> = ({ user, divider = true, onClick }) => {
  const [dialogOpen, setDialogOpen] = useState<MemberDialog | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { formatMessage } = useIntl();
  const { inviteMemberToAccount, disableUser, enableUser } = useUsers();
  const { enqueueSnackbar } = useSnackbar();

  const isPending = isInvitePending(user);

  const closeDialog = () => {
    setDialogOpen(null);
  };

  return (
    <>
      <Grid
        container
        style={{ marginTop: 15, marginBottom: 15, cursor: 'pointer' }}
        alignItems="center"
        onClick={(event) => {
          event.preventDefault();
          onClick(user);
        }}
      >
        <Grid item xs={1}>
          <Face style={{ fill: Colors.TOWER_GREY }} />
        </Grid>
        <Grid item xs={7}>
          <Typography variant="h6" style={{ fontSize: 14, fontWeight: 600, textAlign: 'left' }}>
            {getDisplayableUserName(user)}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <UserRoleField user={user} />
        </Grid>
        <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
          <Team />
          <Typography variant="subtitle1" style={{ marginLeft: 8, fontSize: 14, color: Colors.SLATE_GREY }}>
            {user.teams ? user.teams.length : 0}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          {!user.rootUser && (
            <MoreHorizontalIconButton
              data-test={`button-${getDisplayableUserName(user)}`}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                setAnchorEl(event.currentTarget);
              }}
              size="small"
              iconStyle={{ verticalAlign: 'middle', fill: Colors.SLATE_GREY }}
            />
          )}
        </Grid>
        <Menu
          id="action-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            event.preventDefault();
            setAnchorEl(null);
          }}
          style={{ transform: 'translateY(40px)' }}
        >
          {!isPending && (
            <MenuItem
              data-test="editMember"
              onClick={(event) => {
                event.stopPropagation();
                setDialogOpen(MemberDialog.EDIT_MEMBER);
                setAnchorEl(null);
              }}
            >
              <MenuItemText primary={formatMessage({ id: 'edit' })} />
            </MenuItem>
          )}
          {!isPending &&
            (user.status === UserStatus.DISABLED ? (
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  setDialogOpen(MemberDialog.ENABLE_MEMBER);
                  setAnchorEl(null);
                }}
              >
                <MenuItemText primary={formatMessage({ id: 'users.actions.enableTitle' })} />
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  setDialogOpen(MemberDialog.DISABLE_MEMBER);
                  setAnchorEl(null);
                }}
              >
                <MenuItemText primary={formatMessage({ id: 'users.actions.disableTitle' })} />
              </MenuItem>
            ))}
          {isPending && (
            <MenuItem
              onClick={async (event) => {
                event.stopPropagation();
                const { status } = await inviteMemberToAccount(
                  user.email,
                  user.userRole ? user.userRole.id : '',
                  user.teams ? user.teams[0].team!.id : ''
                );
                if (status !== true) {
                  enqueueSnackbar(formatMessage({ id: 'error.apicall' }), { variant: 'error' });
                } else {
                  enqueueSnackbar(formatMessage({ id: 'settings.addMember.sent' }), { variant: 'success' });
                }
                setAnchorEl(null);
              }}
            >
              <MenuItemText primary={formatMessage({ id: 'settings.addMember.resend' })} />
            </MenuItem>
          )}
          {isPending && (
            <MenuItem
              onClick={(event) => {
                event.stopPropagation();
                setDialogOpen(MemberDialog.DELETE_MEMBER);
                setAnchorEl(null);
              }}
            >
              <MenuItemText primary={formatMessage({ id: 'delete' })} deleteOption />
            </MenuItem>
          )}
        </Menu>
      </Grid>
      {divider && <Divider />}

      {/* Dialogs */}
      {dialogOpen === MemberDialog.DELETE_MEMBER && (
        <Dialog open onClose={closeDialog} scroll="body">
          <DeleteMember afterSubmit={closeDialog} user={user} />
        </Dialog>
      )}
      {dialogOpen === MemberDialog.EDIT_MEMBER && (
        <Dialog open onClose={closeDialog} scroll="body">
          <EditMember afterSubmit={closeDialog} id={user.id} />
        </Dialog>
      )}
      {dialogOpen === MemberDialog.DISABLE_MEMBER && (
        <ConfirmDialog
          mainText={formatMessage({ id: 'users.actions.disableMainText' })}
          confirmText={formatMessage({ id: 'yes' })}
          cancelText={formatMessage({ id: 'no' })}
          confirmAction={async () => {
            await disableUser(user!.id);
            closeDialog();
            enqueueSnackbar(formatMessage({ id: 'users.actions.disableSnackbarText' }), { variant: 'success' });
          }}
          cancelAction={closeDialog}
          open
          formatMessage={formatMessage}
        />
      )}
      {dialogOpen === MemberDialog.ENABLE_MEMBER && (
        <ConfirmDialog
          mainText={formatMessage({ id: 'users.actions.enableMainText' })}
          confirmText={formatMessage({ id: 'yes' })}
          cancelText={formatMessage({ id: 'no' })}
          confirmAction={async () => {
            await enableUser(user!.id);
            closeDialog();
            enqueueSnackbar(formatMessage({ id: 'users.actions.enableSnackbarText' }), { variant: 'success' });
          }}
          cancelAction={closeDialog}
          open
          formatMessage={formatMessage}
        />
      )}
    </>
  );
};

export default MemberRow;
