import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import { stopPropagation } from 'src/components/Dashboard/Dashboard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
      cursor: 'pointer',
      display: 'inline-flex',
      justifyContent: 'flex-start',
      flexDirection: 'inherit',
      alignItems: 'center',
      fontFamily: 'Mulish',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '15px',
      color: Colors.BLUE_GREY,
      fill: Colors.BLUE_GREY,
      '&:hover': {
        color: Colors.SILVER,
        fill: Colors.SILVER,
      },
      '&:focus': {
        color: Colors.BLUE_GREY,
        fill: Colors.BLUE_GREY,
      },
    },
    active: {
      fill: Colors.BLUE_GREY,
      color: Colors.BLUE_GREY,
    },
  })
);

interface FilterButtonProps {
  label: string;
  selected?: boolean;
  onClick: (e?: React.MouseEvent<HTMLElement>) => void;
}

const FilterButton: React.FC<FilterButtonProps> = ({ label, selected, onClick }) => {
  const classes = useStyles();
  return (
    <ButtonBase
      onMouseDown={stopPropagation}
      className={clsx(classes.root, { [classes.active]: true })}
      component="span"
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        onClick(e);
      }}
      style={selected === true ? { fontWeight: 700 } : {}}
    >
      {label}
    </ButtonBase>
  );
};
export default FilterButton;
