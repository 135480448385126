import { Typography } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import React, { CSSProperties } from 'react';
import { ReactComponent as HomeSvg } from '../../icons/home.svg';

interface UnitNumbersPictoProps {
  value: string | number;
  total?: string | number;
  containerStyle?: CSSProperties;
}

const UnitNumbersPicto: React.FC<UnitNumbersPictoProps> = ({ value, total, containerStyle }) => {
  let totalPrinted = '';
  if (total && total !== value) {
    totalPrinted = ` / ${total}`;
  }
  return (
    <div
      style={{
        borderRadius: 15,
        border: `1px solid ${Colors.PORCELAIN_GREY_3}`,
        backgroundColor: Colors.PORCELAIN_GREY_3,
        height: 30,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        width: 63,
        ...containerStyle,
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <HomeSvg style={{ fill: Colors.BLUEY, width: 16, height: 16, marginRight: totalPrinted !== '' ? 5 : 10 }} />
        <Typography
          style={{
            fontSize: 14,
            fontWeight: 600,
            fontStyle: 'normal',
            letterSpacing: 0,
            textAlign: 'right',
            color: Colors.LIGHT_BLUE_GREY,
          }}
        >
          {value}
          {totalPrinted}
        </Typography>
      </div>
    </div>
  );
};

export default UnitNumbersPicto;
