import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import { useVariousOperations } from 'src/hooks/VariousOperationsContext';
import { getVariousOperationLabel } from '@rentguru/commons-utils';
import { useLocale } from 'src/i18n/IntlProviderWrapper';

interface DeleteVariousOperationProps {
  afterSubmit: () => void;
  id: string;
}
const DeleteVariousOperation: React.FC<DeleteVariousOperationProps> = ({ afterSubmit, id }) => {
  const { formatMessage } = useIntl();
  const { getVariousOperation, loading, error, deleteVariousOperation } = useVariousOperations();
  const { language } = useLocale();

  const handleClose = () => {
    afterSubmit();
  };
  const variousOperation = getVariousOperation(id);
  if (loading || isNil(variousOperation)) {
    return (
      <>
        <DialogContent>
          <Typography>{formatMessage({ id: 'loading' })}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {formatMessage({ id: 'cancel' })}
          </Button>
        </DialogActions>
      </>
    );
  }
  if (error) {
    return <Typography>{error}</Typography>;
  }

  return (
    <>
      <DialogTitle id="alert-dialog-slide-title">
        {formatMessage({ id: 'settings.deleteVariousOperation.menu' })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description" style={{ color: 'red' }}>
          {formatMessage(
            { id: 'settings.deleteVariousOperation.title' },
            { name: getVariousOperationLabel(variousOperation, language) }
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {formatMessage({ id: 'cancel' })}
        </Button>
        <Button
          onClick={async (e) => {
            e.preventDefault();
            await deleteVariousOperation(variousOperation);
            handleClose();
          }}
          color="primary"
        >
          {formatMessage({ id: 'delete' })}
        </Button>
      </DialogActions>
    </>
  );
};

export default DeleteVariousOperation;
