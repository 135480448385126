import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import isNil from 'lodash/isNil';
import { Colors } from '@rentguru/commons-utils';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      borderRadius: 12,
      textAlign: 'center',
      marginBottom: 30,
      overflowX: 'auto',
      height: '310px',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    mainText: {
      color: Colors.CLASSICAL_BLACK,
      fontSize: 16,
      fontWeight: 700,
      marginTop: '10px',
      maxWidth: '290px',
    },
    subText: {
      color: Colors.SLATE_GREY,
      fontSize: 14,
      marginBottom: '10px',
      maxWidth: '290px',
    },
  })
);

interface TablePlaceHolderProps {
  Icon?: React.ReactNode;
  mainText?: string;
  subText: string;
  actionButton?: React.ReactNode;
  secondActionButton?: React.ReactNode;
  mainStyle?: React.CSSProperties;
  mainTextStyle?: React.CSSProperties;
  subTextStyle?: React.CSSProperties;
}
export const TablePlaceHolder: React.FC<TablePlaceHolderProps> = ({
  Icon,
  mainText,
  subText,
  actionButton,
  secondActionButton,
  mainStyle = {},
  mainTextStyle = {},
  subTextStyle = {},
}) => {
  const classes = useStyles();
  return (
    <Grid className={classes.paper} style={mainStyle}>
      {Icon}
      {!isNil(mainText) && (
        <Typography className={classes.mainText} style={mainTextStyle}>
          {mainText}
        </Typography>
      )}
      <Typography variant="subtitle1" className={classes.subText} style={subTextStyle}>
        {subText}
      </Typography>
      {actionButton}
      {secondActionButton}
    </Grid>
  );
};
