/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-len */
import { Collapse, Divider, Menu, MenuItem, Typography } from '@material-ui/core';
import { Colors, EmptyFileWithUrl, S3Object, Technic, TechnicType, uniquePush } from '@rentguru/commons-utils';
import { MenuItemText, TablePlaceHolder } from '@up2rent/ui';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { AddLeaseFormValues, UnitFormValues } from 'src/components/Leases/AddLease/AddLeaseForm';
import { resolveMainUnitAndSubUnitIndexAndPrefix } from 'src/components/Leases/AddLease/useAddEditLeaseUtils';
import AddChimneyForm, { AddChimneyProps } from 'src/components/Technics/Chimneys/AddChimneyForm';
import AddHeatingForm from 'src/components/Technics/Heatings/AddHeatingForm';
import AddTankForm from 'src/components/Technics/Tanks/AddTankForm';
import Dialog from 'src/components/ui/Dialog';
import { fromTechnicTypeToFileCategory, useTechnics } from 'src/hooks/TechnicsContext';
import CustomizedSwitch from '../../CustomizedSwitch';
import TechnicSelectorDialog from '../../Dialogs/TechnicSelectorDialog';
import { useStyles as useFormHeaderWithSwitchStyles } from '../../FormSubHeaderWithSwitch';
import PlusButton from '../../PlusButton';
import PreviewFileDialog from '../../PreviewFileDialog';
import ResetIcon from '../../ResetIcon';
import MaintenanceControlFields from './MaintenanceControlFields';
import MaintenanceFields, { NewMaintenanceHistory } from './MaintenanceFields';
import { UnitStructure } from './UnitStructureFields';

const resetTechnicCategory = (
  type: TechnicType,
  technics: Technic[],
  originalTechnics: Technic[],
  setFieldValue: (field: string, value: Technic[]) => void,
  prefixFieldName: string = ''
) => {
  const originalTechnicsOfType = originalTechnics.filter((t) => t.type === type);
  const technicsResetted = [...technics.filter((t) => t.type !== type), ...originalTechnicsOfType];
  setFieldValue(`${prefixFieldName}technics`, technicsResetted);
};

const addNewTechnic = (
  newTechnic: Technic,
  technics: Technic[],
  setFieldValue: (field: string, value: Technic[]) => void,
  prefixFieldName: string = ''
) => {
  const newTechnics = [...technics, newTechnic];
  setFieldValue(`${prefixFieldName}technics`, newTechnics);
};
const removeTechnic = (
  technics: Technic[],
  technicToRemove: Technic,
  setFieldValue: (field: string, value: Technic[]) => void,
  prefixFieldName: string = ''
) => {
  const newTechnics = technics.filter((technic) => technic.id !== technicToRemove.id);
  setFieldValue(`${prefixFieldName}technics`, newTechnics);
};

export interface NewTechnic extends Omit<Technic, 'maintenanceHistory'> {
  maintenanceHistory?: NewMaintenanceHistory[] | null;
}

export interface TechnicFormValues {
  includeChimney: boolean;
  includeFuelTank: boolean;
  includeHeating: boolean;
  technics: NewTechnic[];
  originalTechnics: Technic[];
}

export interface MaintenanceDialogBundle {
  afterSubmit: (newMaintenance: NewMaintenanceHistory) => void;
}

interface TechnicFieldsProps {
  type: TechnicType;
  editMode?: boolean;
  unitId?: string;
  showFilesForBuilding?: boolean;
}

export const canAddTechnicOnBuilding = (values: AddLeaseFormValues, buildingId: string, subUnitIndex: number) => {
  const mainUnitBuildingId = values.unit.building.id;
  if (mainUnitBuildingId === buildingId && subUnitIndex === -1) {
    return true;
  }
  if (mainUnitBuildingId === buildingId) {
    return false;
  }
  return !values.subUnits.some(
    (subUnitValues, currentIndex) => currentIndex < subUnitIndex && subUnitValues.buildingId === buildingId
  );
};

const TechnicFields: React.FC<TechnicFieldsProps> = ({
  type,
  editMode = false,
  unitId,
  showFilesForBuilding = true,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formHeaderWithSwitchClass = useFormHeaderWithSwitchStyles();
  const { technics: allTechnics } = useTechnics();
  const { values, setFieldValue } = useFormikContext<TechnicFormValues>();
  const { formatMessage } = useIntl();
  const { prefixFieldName, subUnitIndex } = resolveMainUnitAndSubUnitIndexAndPrefix(values, unitId);
  const buildingId = get(
    values as unknown as UnitFormValues,
    prefixFieldName === '' ? 'unit.building.id' : `${prefixFieldName}buildingId`,
    ''
  );

  const canAddOnBuilding = canAddTechnicOnBuilding(values as unknown as AddLeaseFormValues, buildingId, subUnitIndex);
  const [previewMaintenanceFileDialog, setPreviewMaintenanceFileDialog] = useState<S3Object | EmptyFileWithUrl | null>(
    null
  );
  const [proposeTechnics, setProposeTechnics] = useState<Technic[]>([]);
  const [addNewControlBundle, setAddNewControlBundle] = useState<MaintenanceDialogBundle | null>(null);
  const [anchorTechnicEntity, setAnchorTechnicEntity] = useState<HTMLElement | null>(null);
  const technics = (get(values, `${prefixFieldName}technics`, []) as NewTechnic[]).filter((t) => t.type === type);
  const originalTechnics = (get(values, `${prefixFieldName}originalTechnics`, []) as NewTechnic[]).filter(
    (t) => t.type === type
  );
  const noInitialTechnicsForThisType = isEmpty(originalTechnics);
  const [addTechnicOnEntityDialog, setAddTechnicOnEntityDialog] = useState<'onBuilding' | 'onUnit' | null>(null);

  const allUnitInventories = (get(values, `${prefixFieldName}unitStructures`, []) as UnitStructure[]).flatMap(
    (us) => us.unitInventories
  );
  let addTechnicButtonText = '';
  let includeTechnicField: 'includeChimney' | 'includeFuelTank' | 'includeHeating' = 'includeChimney';
  let placeholder = '';
  let placeholderSubText = '';
  let selectTitle = '';
  let AddTechnicForm: React.ComponentType<AddChimneyProps> | null = null;
  if (type === TechnicType.FUELTANK) {
    addTechnicButtonText = formatMessage({ id: 'technic.fuelTankTab.addFuelTank' });
    AddTechnicForm = AddTankForm;
    includeTechnicField = 'includeFuelTank';
    placeholder = formatMessage({ id: 'technic.fuelTankTab.noFuelTanks' });
    placeholderSubText = formatMessage({ id: 'technic.fuelTankTab.helper' });
    selectTitle = formatMessage({ id: 'technic.fuelTankTab.selectFuelTank' });
  } else if (type === TechnicType.CHIMNEY) {
    addTechnicButtonText = formatMessage({ id: 'technic.chimneyTab.addChimney' });
    AddTechnicForm = AddChimneyForm;
    includeTechnicField = 'includeChimney';
    placeholder = formatMessage({ id: 'technic.chimneyTab.noChimneys' });
    placeholderSubText = formatMessage({ id: 'technic.chimneyTab.helper' });
    selectTitle = formatMessage({ id: 'technic.chimneyTab.selectChimney' });
  } else if (type === TechnicType.HEATING) {
    addTechnicButtonText = formatMessage({ id: 'technic.heatingTab.add' });
    AddTechnicForm = AddHeatingForm;
    includeTechnicField = 'includeHeating';
    placeholder = formatMessage({ id: 'technic.heatingTab.noHeatings' });
    placeholderSubText = formatMessage({ id: 'technic.heatingTab.helper' });
    selectTitle = formatMessage({ id: 'technic.heatingTab.selectHeating' });
  }
  const includeTechnic = get(values, `${prefixFieldName}${includeTechnicField}`, false);
  const categoryRealName = formatMessage({ id: `enums.FileCategory.${fromTechnicTypeToFileCategory(type)}` });

  return (
    <>
      <div className={formHeaderWithSwitchClass.root} style={{ paddingLeft: 30, paddingRight: 30 }}>
        <Typography variant="subtitle2" style={{ fontWeight: 700, fontSize: 14 }}>
          {categoryRealName}
        </Typography>
        <>
          <PlusButton
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              setAnchorTechnicEntity(e.currentTarget);
            }}
            text={addTechnicButtonText}
            style={{ visibility: includeTechnic ? 'visible' : 'hidden' }}
          />
          <CustomizedSwitch
            checked={includeTechnic}
            onChange={() => setFieldValue(`${prefixFieldName}${includeTechnicField}`, !includeTechnic)}
            switchOnText={formatMessage({ id: 'lease.addLease.includeInLease' })}
            switchOffText={formatMessage({ id: 'lease.addLease.ignore' })}
            aria-label="Collapse"
            color="primary"
            disableRipple
          />
        </>
      </div>
      <Collapse
        in={includeTechnic}
        mountOnEnter
        unmountOnExit
        style={{ marginLeft: 10, marginRight: 10, paddingBottom: 10 }}
      >
        {!noInitialTechnicsForThisType && (
          <div style={{ marginBottom: 30 }}>
            <ResetIcon
              style={{ fill: Colors.BLUE_GREY, float: 'right', marginRight: 40 }}
              onClick={() => {
                resetTechnicCategory(
                  type,
                  get(values, `${prefixFieldName}technics`, []),
                  get(values, `${prefixFieldName}originalTechnics`, []),
                  setFieldValue,
                  prefixFieldName
                );
              }}
            />
          </div>
        )}
        {technics.map((technic, technicTypeIndex) => {
          const technicIndex = (get(values, `${prefixFieldName}technics`, []) as NewTechnic[]).findIndex(
            (t) => technic.id === t.id
          );
          // Propose the original technics that are not in the technics anymore
          const proposedTechnics = originalTechnics.filter((ot) => {
            const technicAlreadySelected = technics.some((t) => t.id === ot.id);
            return !technicAlreadySelected;
          });
          uniquePush(proposedTechnics, technic); // And add itself, so it can choose itself back again
          if (!isNil(technic.building) && !showFilesForBuilding) {
            return null;
          }
          return (
            <MaintenanceFields
              technic={technic}
              name={`${prefixFieldName}technics[${technicIndex}]`}
              previewMaintenanceFile={setPreviewMaintenanceFileDialog}
              openNewControlDialog={setAddNewControlBundle}
              type={technic.type as TechnicType}
              index={technicTypeIndex}
              key={technic.id}
              removeFunction={(technic: Technic) => {
                removeTechnic(
                  get(values, `${prefixFieldName}technics`, []) as NewTechnic[],
                  technic,
                  setFieldValue,
                  prefixFieldName
                );
              }}
            />
          );
        })}
        {isEmpty(technics) && <TablePlaceHolder mainText={placeholder} subText={placeholderSubText} />}
      </Collapse>
      <Menu
        id="add-event-menu"
        anchorEl={anchorTechnicEntity}
        keepMounted
        open={Boolean(anchorTechnicEntity)}
        onClose={() => setAnchorTechnicEntity(null)}
      >
        {canAddOnBuilding && (
          <MenuItem
            onClick={() => {
              setAddTechnicOnEntityDialog('onBuilding');
              setAnchorTechnicEntity(null);
            }}
          >
            <MenuItemText primary={formatMessage({ id: 'technic.technicOnBuilding' })} />
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setAddTechnicOnEntityDialog('onUnit');
            setAnchorTechnicEntity(null);
          }}
        >
          <MenuItemText primary={formatMessage({ id: 'technic.technicOnUnit' })} />
        </MenuItem>
        {editMode && <Divider />}
        {editMode && (
          <MenuItem
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const unitId = get(values as any, prefixFieldName === '' ? 'unit.id' : `${prefixFieldName}id`, '');
              const buildingId = get(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                values as any,
                prefixFieldName === '' ? 'unit.building.id' : `${prefixFieldName}buildingId`,
                ''
              );
              // Propose the technic that lie on the unit/building ans that are not yet in the formik values
              const technicsToPropose = allTechnics.filter(
                (t) =>
                  ((!isNil(t.unit) && t.unit.id === unitId) || (!isNil(t.building) && t.building.id === buildingId)) &&
                  t.type === type &&
                  !technics.some((technic) => technic.id === t.id)
              );
              if (isEmpty(technicsToPropose)) {
                enqueueSnackbar(placeholder);
              } else {
                setProposeTechnics(technicsToPropose);
              }
            }}
          >
            <MenuItemText primary={formatMessage({ id: 'technic.fuelTankTab.existing' })} />
          </MenuItem>
        )}
      </Menu>
      {!isNil(AddTechnicForm) && (
        <Dialog
          open={!isNil(addTechnicOnEntityDialog)}
          onClose={() => setAddTechnicOnEntityDialog(null)}
          scroll="paper"
          fullWidth
          PaperProps={{ style: { borderRadius: 10, maxWidth: 640 } }}
        >
          <AddTechnicForm
            afterSubmit={(technics?: Technic[]) => {
              if (!isEmpty(technics)) {
                technics?.forEach((technic) => {
                  addNewTechnic(technic, get(values, `${prefixFieldName}technics`, []), setFieldValue, prefixFieldName);
                });
                setAddTechnicOnEntityDialog(null);
              }
            }}
            cancelSubmit={() => setAddTechnicOnEntityDialog(null)}
            id={addTechnicOnEntityDialog === 'onUnit' ? unitId! : buildingId!}
            entity={addTechnicOnEntityDialog === 'onUnit' ? 'leaseUnit' : 'leaseBuilding'}
            unitInventories={allUnitInventories}
          />
        </Dialog>
      )}
      {!isNil(previewMaintenanceFileDialog) && (
        <PreviewFileDialog
          open={!isNil(previewMaintenanceFileDialog)}
          file={previewMaintenanceFileDialog}
          onClose={() => setPreviewMaintenanceFileDialog(null)}
        />
      )}
      {editMode && !isEmpty(proposeTechnics) && (
        <TechnicSelectorDialog
          technics={proposeTechnics}
          afterSubmit={(technic: Technic) => {
            addNewTechnic(technic, values.technics, setFieldValue);
          }}
          title={selectTitle}
          open={!isEmpty(proposeTechnics)}
          onClose={() => setProposeTechnics([])}
        />
      )}
      {!isNil(addNewControlBundle) && (
        <Dialog
          open={!isNil(addNewControlBundle)}
          onClose={() => setAddNewControlBundle(null)}
          scroll="paper"
          fullWidth
          PaperProps={{ style: { borderRadius: 10 } }}
        >
          <MaintenanceControlFields
            afterSubmit={addNewControlBundle.afterSubmit}
            cancelSubmit={() => setAddNewControlBundle(null)}
          />
        </Dialog>
      )}
    </>
  );
};

export default TechnicFields;
