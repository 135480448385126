import { Grid, Typography } from '@material-ui/core';
import { ReactComponent as NoSearchResult } from '../../../../icons/noResult.svg';
import React from 'react';
import { useIntl } from 'react-intl';

export const NoResultPlaceholder = () => {
  const { formatMessage } = useIntl();

  return (
    <Grid style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', marginTop: 5, marginBottom: 15 }}>
      <NoSearchResult height={40} width={40} />
      <Typography style={{ fontSize: 14, fontStyle: 'normal', fontWeight: 600, letterSpacing: 0, marginTop: 5 }}>
        {formatMessage({ id: 'noResult' })}
      </Typography>
    </Grid>
  );
};
