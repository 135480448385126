/* eslint-disable no-redeclare */
import { isNil } from 'lodash';
import React, { Reducer, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import {
  CustomSubscription,
  syncCustomSubscriptions as syncQuery,
  getCustomSubscription as getQuery,
  deleteCustomSubscription as deleteMutation,
  DeleteCustomSubscriptionMutationVariables as DeleteMutationVariables,
  isNilOrEmpty,
  OnCreateCustomSubscriptionSubscription,
  OnUpdateCustomSubscriptionSubscription,
  OnDeleteCustomSubscriptionSubscription,
  onCreateCustomSubscription,
  onUpdateCustomSubscription,
  onDeleteCustomSubscription,
  CustomSubscriptionType,
} from '@rentguru/commons-utils';
import { deleteEntityWithFetchBefore, list, recordWasUpdated, useSubscriptions } from '@up2rent/fetch-utils';

export const CUSTOM_SUBSCRIPTIONS_FOR_REFETCH = CustomSubscriptionType.REFETCH;
export const CUSTOM_SUBSCRIPTIONS_FOR_NOTIFICATION = [
  CustomSubscriptionType.BLOCKED_COMMUNICATIONS,
  CustomSubscriptionType.CREDITS,
  CustomSubscriptionType.INTEGRATION,
  CustomSubscriptionType.REPORT,
  CustomSubscriptionType.SENDING_SOURCE,
  CustomSubscriptionType.SIGNATURE_DOCUMENT,
  CustomSubscriptionType.TACIT_RENEWAL,
  CustomSubscriptionType.TACIT_WARNING,
  CustomSubscriptionType.OCR_FAILURE,
  CustomSubscriptionType.OCR_SUCCESS,
  CustomSubscriptionType.ENTITY_DELETION,
];

type ActionCustomSubscription =
  | {
      type: 'SHOULD_FETCH_CUSTOM_SUBSCRIPTION' | 'IS_FETCHING_CUSTOM_SUBSCRIPTION';
    }
  | {
      type: 'ADD_CUSTOM_SUBSCRIPTION';
      payload: { customSubscription: CustomSubscription };
    }
  | {
      type: 'FETCHED_CUSTOM_SUBSCRIPTION';
      payload: { customSubscriptions: CustomSubscription[] };
    }
  | {
      type: 'UPDATE_CUSTOM_SUBSCRIPTION';
      payload: { customSubscription: CustomSubscription };
    }
  | {
      type: 'DELETE_CUSTOM_SUBSCRIPTION';
      payload: { id: string };
    };

interface CustomSubscriptionState {
  customSubscriptions: CustomSubscription[] | undefined;
  loading: boolean;
  shouldFetch: boolean;
  lastFetch: Date | null;
}

interface CustomSubscriptionContext extends CustomSubscriptionState {
  getCustomSubscription: (id: string) => CustomSubscription | undefined;
  deleteCustomSubscriptions: (customSubscription: CustomSubscription[]) => void;
  deleteCustomSubscription: (customSubscription: CustomSubscription) => void;
  setFetchCustomSubscriptions: () => void;
}
export interface CustomSubscriptionRefetch {
  table: string;
  id: string;
}

const initialState: CustomSubscriptionState = {
  loading: false,
  customSubscriptions: undefined,
  shouldFetch: false,
  lastFetch: null,
};

const customSubscriptionReducer = (
  state: CustomSubscriptionState,
  action: ActionCustomSubscription
): CustomSubscriptionState => {
  switch (action.type) {
    case 'SHOULD_FETCH_CUSTOM_SUBSCRIPTION':
      if (state.loading || state.shouldFetch) {
        return state;
      }
      return {
        ...state,
        shouldFetch: true,
      };
    case 'IS_FETCHING_CUSTOM_SUBSCRIPTION':
      return {
        ...state,
        loading: true,
      };
    case 'FETCHED_CUSTOM_SUBSCRIPTION':
      return {
        ...state,
        loading: false,
        shouldFetch: false,
        customSubscriptions: action.payload.customSubscriptions,
        lastFetch: new Date(),
      };
    case 'ADD_CUSTOM_SUBSCRIPTION':
      if (state.customSubscriptions?.find((object) => object.id === action.payload.customSubscription.id)) {
        return state;
      }

      return {
        ...state,
        customSubscriptions: [...(state.customSubscriptions ?? []), action.payload.customSubscription],
      };

    case 'UPDATE_CUSTOM_SUBSCRIPTION':
      // No data
      if (isNilOrEmpty(state.customSubscriptions)) {
        return state;
      }

      // Already present and same object
      const currentObject = state.customSubscriptions.find(
        (object) => object.id === action.payload.customSubscription.id
      );
      if (!currentObject) {
        return {
          ...state,
          customSubscriptions: [...state.customSubscriptions, action.payload.customSubscription],
        };
      }
      if (!recordWasUpdated(currentObject, action.payload.customSubscription)) {
        return state;
      }

      // Update
      return {
        ...state,
        customSubscriptions: state.customSubscriptions.map((object) => {
          if (object.id === action.payload.customSubscription.id) {
            return action.payload.customSubscription;
          }
          return object;
        }),
      };

    case 'DELETE_CUSTOM_SUBSCRIPTION':
      if (isNilOrEmpty(state.customSubscriptions)) {
        return state;
      }

      return {
        ...state,
        customSubscriptions: state.customSubscriptions.filter((object) => object.id !== action.payload.id),
      };
    default:
      return state;
  }
};

export const fetchCustomSubscriptions = async (): Promise<CustomSubscription[]> => {
  return await list<CustomSubscription>(syncQuery);
};
export const CustomSubscriptionContext = React.createContext<CustomSubscriptionContext | null>(null);

export const CustomSubscriptionContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<CustomSubscriptionState, ActionCustomSubscription>>(
    customSubscriptionReducer,
    initialState
  );

  useEffect(() => {
    const fetchAndSet = async () => {
      dispatch({ type: 'IS_FETCHING_CUSTOM_SUBSCRIPTION' });
      const result = await fetchCustomSubscriptions();
      dispatch({ type: 'FETCHED_CUSTOM_SUBSCRIPTION', payload: { customSubscriptions: result } });
    };
    if (state.shouldFetch) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetch]);

  useSubscriptions<
    OnCreateCustomSubscriptionSubscription,
    OnUpdateCustomSubscriptionSubscription,
    OnDeleteCustomSubscriptionSubscription
  >(
    onCreateCustomSubscription,
    onUpdateCustomSubscription,
    onDeleteCustomSubscription,
    (data) => {
      dispatch({
        type: 'ADD_CUSTOM_SUBSCRIPTION',
        payload: { customSubscription: data.onCreateCustomSubscription as CustomSubscription },
      });
    },
    (data) => {
      dispatch({
        type: 'UPDATE_CUSTOM_SUBSCRIPTION',
        payload: { customSubscription: data.onUpdateCustomSubscription as CustomSubscription },
      });
    },
    (data) => {
      const { id } = data.onDeleteCustomSubscription as CustomSubscription;
      dispatch({
        type: 'DELETE_CUSTOM_SUBSCRIPTION',
        payload: { id },
      });
    }
  );

  const setFetchCustomSubscriptions = () => {
    dispatch({ type: 'SHOULD_FETCH_CUSTOM_SUBSCRIPTION' });
  };

  const now = Math.floor(new Date().getTime() / 1000);
  const filteredCustomSubscription = state.customSubscriptions?.filter((customSubscription) => {
    return customSubscription._ttl > now;
  });

  const getCustomSubscription = (customSubscriptionId: string) => {
    return state.customSubscriptions?.find((customSubscription) => customSubscription.id === customSubscriptionId);
  };

  const deleteCustomSubscriptions = async (customSubscriptionsToDelete: CustomSubscription[]) => {
    const result = await Promise.all(
      customSubscriptionsToDelete.map((customSubscription) => deleteCustomSubscription(customSubscription))
    );
    return result;
  };

  const deleteCustomSubscription = async (customSubscriptionToDelete: CustomSubscription) => {
    const result = await deleteEntityWithFetchBefore<CustomSubscription, DeleteMutationVariables>(
      customSubscriptionToDelete,
      getQuery,
      deleteMutation
    );
    dispatch({ type: 'DELETE_CUSTOM_SUBSCRIPTION', payload: { id: result.id } });
    return result;
  };

  const values = useMemo(
    () => ({
      ...state,
      customSubscriptions: filteredCustomSubscription,
      deleteCustomSubscription,
      deleteCustomSubscriptions,
      getCustomSubscription,
      setFetchCustomSubscriptions,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredCustomSubscription, state]
  );

  return <CustomSubscriptionContext.Provider value={values}>{children}</CustomSubscriptionContext.Provider>;
};

export const useCustomSubscriptions = (): CustomSubscriptionContext => {
  const firstRender = useRef<boolean>(false);
  const context = useContext<CustomSubscriptionContext | null>(CustomSubscriptionContext);

  if (isNil(context)) {
    throw new Error('`useCustomSubscriptions` hook must be used within a `CustomSubscriptionContext` component');
  }

  useEffect(() => {
    if (!firstRender.current && isNil(context.customSubscriptions)) {
      context.setFetchCustomSubscriptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  return context;
};
