/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { formatDistance, parseISO, isWithinInterval, subMilliseconds, addMilliseconds, isEqual } from 'date-fns';
import { getPrintableDate } from './dates';
import { MessageDescriptor } from 'react-intl';
import { PlaceHolderEvent, TimelineEvent } from '../components/ui/HorizontalTimeline';
import {
  getFirstCurrentLease,
  getFutureLeases,
  isPastEvent,
  isPresentEvent,
  isFutureEvent,
  getEventType,
  formatStandardizedUTCDate,
  startOfUTCDay,
  isNilOrEmpty,
  LeaseExtended,
  Unit,
  UnitLease,
  UnitEvent,
  UnitInventory,
  UnitInventoryRoomType,
  BuildingEvent,
  UnitType,
} from '@rentguru/commons-utils';
import { RouteDestination } from 'src/components/Routes/Routes';
import { LEASES_DETAIL_TO } from 'src/components/Leases/Leases';
import { UnitContext } from 'src/hooks/UnitsContext';

export const getOccupancy = (units: Unit[]) => {
  let occupancy = 0;
  if (!isNil(units) && !isEmpty(units)) {
    const today = new Date();
    const leasesOfOwner = units.reduce((leases: UnitLease[], currentUnit: Unit) => {
      if (isNil(currentUnit.leases) || isEmpty(currentUnit.leases)) {
        return leases;
      }
      // Get in period
      const unitLease = currentUnit.leases.find(
        (unitLease) =>
          unitLease.lease &&
          isWithinInterval(today, {
            start: parseISO(unitLease.lease.startDate),
            end: parseISO(unitLease.lease.endDate),
          })
      );
      if (!isNil(unitLease)) {
        leases.push(unitLease);
      }
      return leases;
    }, []);

    occupancy = Math.ceil((leasesOfOwner.length / units.length) * 100);
  }
  return occupancy;
};
export const getNumbers = (units: Unit[]) => {
  if (isNil(units)) {
    return 0;
  }
  return units.length;
};

export const buildPredictedText = (
  unit: Unit,
  unitInventories: UnitInventory[],
  formatMessage: (descriptor: MessageDescriptor, values?: any) => string
) => {
  let predictedHelperText = '';
  const [, containsBedrooms, containsBathrooms] = unitInventories.reduce(
    (acc: [boolean, boolean, boolean], ui: UnitInventory) => {
      if (ui.roomType === UnitInventoryRoomType.GARDEN) acc[0] = true;
      else if (ui.roomType === UnitInventoryRoomType.BEDROOM) acc[1] = true;
      else if (ui.roomType === UnitInventoryRoomType.BATHROOM) acc[2] = true;
      return acc;
    },
    [false, false, false]
  );
  if (
    isNil(unit.type) ||
    isNil(unit.surface) ||
    !containsBedrooms ||
    !containsBathrooms ||
    isNil(unit.building!.address) ||
    isNil(unit.building!.address.longitude) ||
    isNil(unit.building!.address.latitude)
  ) {
    predictedHelperText += formatMessage({ id: 'rentalUnit.detail.general.predictedHelper.missingValues' });
    if (isNil(unit.type))
      predictedHelperText += `\n- ${formatMessage({
        id: 'rentalUnit.detail.general.predictedHelper.missingType',
      })}`;
    if (isNil(unit.surface))
      predictedHelperText += `\n - ${formatMessage({
        id: 'rentalUnit.detail.general.predictedHelper.missingSurface',
      })}`;
    /* if (!containsGarden)
      predictedHelperText += `\n - ${formatMessage({
        id: 'rentalUnit.detail.general.predictedHelper.missingGarden',
      })}`; */
    if (!containsBedrooms)
      predictedHelperText += `\n - ${formatMessage({
        id: 'rentalUnit.detail.general.predictedHelper.missingBedrooms',
      })}`;
    if (!containsBathrooms)
      predictedHelperText += `\n - ${formatMessage({
        id: 'rentalUnit.detail.general.predictedHelper.missingBathrooms',
      })}`;
    if (
      isNil(unit.building!.address) ||
      isNil(unit.building!.address.longitude) ||
      isNil(unit.building!.address.latitude)
    )
      predictedHelperText += `\n - ${formatMessage({
        id: 'rentalUnit.detail.general.predictedHelper.missingAddress',
      })}`;
  }
  if (!isNil(unit.type) && unit.type !== UnitType.RESIDENTIAL) {
    if (predictedHelperText === '') {
      predictedHelperText += `${formatMessage({
        id: 'rentalUnit.detail.general.predictedHelper.onlyHouseAndAppart1',
      })}`;
    } else {
      predictedHelperText += `\n ${formatMessage({
        id: 'rentalUnit.detail.general.predictedHelper.furthermore',
      })}, ${formatMessage({
        id: 'rentalUnit.detail.general.predictedHelper.onlyHouseAndAppart2',
      })}`;
    }
  }
  return predictedHelperText;
};

export const getLeaseSignatureDate = (input: any) => {
  if (isNil(input)) {
    return '...';
  }
  return getPrintableDate(input);
};

export const getDetailedOverlappingError = (
  event: UnitEvent | UnitLease | BuildingEvent | { startDate: string; endDate: string },
  formatMessage: (descriptor: MessageDescriptor, values?: any) => string,
  locale: any
) => {
  let eventType = '';
  let formattedStartDate = '...';
  let formattedEndDate = '...';
  if ('unitId' in event && 'leaseId' in event) {
    eventType = formatMessage({ id: 'event.aLease' });
    formattedStartDate = formatStandardizedUTCDate(new Date((event as UnitLease).lease!.startDate), 'dd/MM/yyyy', {
      locale,
    });
    formattedEndDate = formatStandardizedUTCDate(new Date((event as UnitLease).lease!.endDate), 'dd/MM/yyyy', {
      locale,
    });
  } else {
    formattedStartDate = formatStandardizedUTCDate(new Date(event.startDate), 'dd/MM/yyyy', { locale });
    formattedEndDate = formatStandardizedUTCDate(new Date(event.endDate), 'dd/MM/yyyy', { locale });
    if ('unitId' in event || 'building' in event) {
      if ((event as UnitEvent | BuildingEvent).type === 'OWN_USE')
        eventType = formatMessage({ id: 'event.anOwnUsePeriod' });
      else if ((event as UnitEvent | BuildingEvent).type === 'WORK')
        eventType = formatMessage({ id: 'event.aWorkPeriod' });
    } else {
      eventType = formatMessage({ id: 'event.anotherFormEvent' });
    }
  }
  return formatMessage(
    {
      id: 'event.overlappingDetailed',
    },
    {
      type: eventType,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    }
  );
};

export const sortByEndDate = (arrayOfUnits: (UnitLease | UnitEvent)[]) => {
  return arrayOfUnits.sort((x, y) => {
    if (isNil(x.endDate) && !isNil(y.endDate)) {
      return 1;
    }
    if (isNil(y.endDate) && !isNil(x.endDate)) {
      return -1;
    }
    if (isNil(x.endDate) && isNil(y.endDate)) {
      return 0;
    }
    if (x.endDate! > y.endDate!) {
      return 1;
    }
    if (x.endDate! < y.endDate!) {
      return -1;
    }
    return 0;
  });
};

export const getFirstActiveLease = (leases: LeaseExtended[] | UnitLease[]) => {
  const currentLease = getFirstCurrentLease(leases) as LeaseExtended;
  const futurLeases = getFutureLeases(leases) as LeaseExtended[];
  const noCurrentOrFuturLeases = isNil(currentLease) && isNil(futurLeases);
  if (noCurrentOrFuturLeases) {
    return null;
  }

  return !isNil(currentLease) ? currentLease : futurLeases[0];
};

const getEventColor = (eventType: string): string => {
  const color: string = 'rgba(0,0,0,1)';
  if (eventType.includes('PAST')) {
    return 'rgba(207, 216, 220, 0.95)';
  }
  if (eventType.includes('PRESENT')) {
    return 'rgba(252, 92, 101, 0.95)';
  }
  if (eventType.includes('OWN_USE')) {
    return 'rgba(75, 123, 236, 0.95)';
  }
  if (eventType.includes('WORK')) {
    return 'rgba(253, 150, 68, 0.95)';
  }
  if (eventType.includes('LEASE')) {
    return 'rgba(84, 110, 122, 0.95)';
  }
  return color;
};

export const getHorizontalEvents = (
  unitId: string,
  locale: any,
  formatMessage: (descriptor: MessageDescriptor, values?: any) => string,
  openUnitEventDialog: (event: UnitEvent) => void,
  deleteUnitEvent: (id: string) => void,
  unitLeases?: UnitLease[] | undefined,
  unitEvents?: UnitEvent[] | undefined | null,
  roomEvents = false
): (PlaceHolderEvent | TimelineEvent)[] => {
  const today = new Date();
  if (isNilOrEmpty(unitLeases) && isNilOrEmpty(unitEvents)) {
    return [{ type: 'PLACEHOLDER_PRESENT', unitId, color: '', startDate: startOfUTCDay(today).toISOString() }];
  }
  const orderedEvents = sortByEndDate([
    ...(!isNil(unitLeases) ? unitLeases : []),
    ...(!isNil(unitEvents) ? unitEvents : []),
  ]);

  const events = orderedEvents.reduce(
    (acc: (PlaceHolderEvent | TimelineEvent)[], event: UnitLease | UnitEvent, index: number) => {
      const isEventPast = isPastEvent(event);
      const isEventCurrent = isPresentEvent(event);
      const isEventFuture = isFutureEvent(event);
      // Special case where only future events => Add a placeholder for present behind
      if (index === 0 && isEventFuture) {
        acc.push({
          type: 'PLACEHOLDER_PRESENT',
          unitId,
          color: '',
          startDate: startOfUTCDay(today).toISOString(),
          endDate: subMilliseconds(new Date(event.startDate), 1).toISOString(),
        });
      }
      // Always check the event before you to see if there is gap between your startDate and his endDate
      if (index > 0 && isEventFuture) {
        const previousEvent = orderedEvents[index - 1];
        const periodsAreContigous = isEqual(
          subMilliseconds(new Date(event.startDate), 1),
          new Date(previousEvent.endDate)
        );
        if (!periodsAreContigous) {
          /* Check if the placeholder is for the current event i.e. current is future and previous is past */
          const isPreviousEventInThePast = isPastEvent(previousEvent);
          const placeHolderStartDate = isPreviousEventInThePast
            ? startOfUTCDay(today).toISOString()
            : addMilliseconds(new Date(previousEvent.endDate), 1).toISOString();
          acc.push({
            type: isPreviousEventInThePast ? 'PLACEHOLDER_PRESENT' : 'PLACEHOLDER_FUTURE',
            unitId,
            color: '',
            startDate: placeHolderStartDate,
            endDate: subMilliseconds(new Date(event.startDate), 1).toISOString(),
          });
        }
      }
      const eventType = `${getEventType(event, roomEvents)}_${
        isEventPast ? 'PAST' : isEventCurrent ? 'PRESENT' : 'FUTURE'
      }`;
      const eventColor = getEventColor(eventType);
      let title = '';
      let redirect;
      let clickActions;
      if (eventType.startsWith('LEASE')) {
        title = formatMessage({ id: 'event.lease' });
        redirect = `${RouteDestination.LEASES}/${LEASES_DETAIL_TO}/${(event as UnitLease).lease?.id ?? ''}`;
      } else if (eventType.startsWith('LEASE_ROOM')) {
        title = formatMessage({ id: 'event.lease' });
        redirect = isEventPast
          ? undefined
          : `${RouteDestination.LEASES}/${LEASES_DETAIL_TO}/${(event as UnitLease).lease?.id ?? ''}`;
      } else {
        title = eventType.startsWith('OWN_USE')
          ? formatMessage({ id: 'event.ownUse' })
          : formatMessage({ id: 'event.work' });
        const editAction = () => openUnitEventDialog(event as UnitEvent);
        const editName = formatMessage({ id: 'edit' });
        const deleteAction = () => deleteUnitEvent(event.id);
        const deleteName = formatMessage({ id: 'delete' });
        clickActions = [
          { action: editAction, legend: editName },
          { action: deleteAction, legend: deleteName },
        ];
      }
      let subtitle = formatMessage({ id: 'event.current' });
      if (isEventPast) {
        subtitle = `${formatDistance(new Date(event.endDate!), today, {
          addSuffix: true,
          locale,
        })}`;
      } else if (isEventFuture) {
        subtitle = `${formatDistance(new Date(event.startDate), today, {
          addSuffix: true,
          locale,
        })}`;
      }
      acc.push({
        type: eventType,
        title,
        subtitle,
        data: event,
        redirect,
        clickActions,
        color: eventColor,
      });
      return acc;
    },
    []
  );
  // always a placeholder at the end of the timeline
  const lastEvent = orderedEvents[orderedEvents.length - 1];
  const isLastEventPast = isPastEvent(lastEvent);
  events.push({
    type: isLastEventPast ? 'PLACEHOLDER_PRESENT' : 'PLACEHOLDER_FUTURE',
    unitId,
    startDate: isLastEventPast
      ? startOfUTCDay(today).toISOString()
      : addMilliseconds(new Date(lastEvent.endDate), 1).toISOString(),
    color: '',
  });
  return events;
};

export const canDeleteUnit = (unitId: string | null, getUnit: UnitContext['getUnit']) => {
  if (!unitId) {
    return false;
  }
  const selectedUnit = getUnit(unitId);
  if (!selectedUnit) {
    return false;
  }
  return isNilOrEmpty(selectedUnit.leases);
};
