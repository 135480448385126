/* eslint-disable @typescript-eslint/no-shadow */
import {
  contactContainsType,
  isIdInList,
  uniqueObjectWithIdAppend,
  uniquePushId,
  getUnitsOfJointOwnership,
  getBuildingsOfJointOwnership,
  Building,
  Contact,
  ContactType,
  Unit,
} from '@rentguru/commons-utils';
import { isNil } from 'lodash';

const allOwnersInJointOwnerShip = (existingJointOwnerships: Contact, ownerIds: string[]) => {
  return ownerIds.filter((ownerId) => !ownerBelongInJointOwnership(existingJointOwnerships, ownerId)).length === 0;
};

export const ownerBelongInJointOwnership = (existingJointOwnerships: Contact, ownerId: string) => {
  return (
    existingJointOwnerships.jointOwners &&
    existingJointOwnerships.jointOwners.some((jointOwner) => jointOwner.contactId === ownerId)
  );
};

export const getExistingJointOwnershipOfOwners = (existingJointOwnerships: Contact[], ownerIds: string[]) => {
  return existingJointOwnerships.find(
    (jointOwnerShip) =>
      jointOwnerShip.jointOwners &&
      jointOwnerShip.jointOwners.length === ownerIds.length &&
      allOwnersInJointOwnerShip(jointOwnerShip, ownerIds)
  );
};

export const getOwnersOfJointownership = (jointOwnership: Contact, getContact: (id: string) => Contact | undefined) => {
  if (!jointOwnership.jointOwners) return [];
  return jointOwnership.jointOwners.reduce((acc: Contact[], jointOwner) => {
    const { contactId } = jointOwner;
    const completeContact = getContact(contactId);
    if (completeContact) acc.push(completeContact);
    return acc;
  }, []);
};

export const isOwnerInAtLeastOneJointOwnership = (jointOwners: Contact[], ownerId: string) => {
  return jointOwners.some((jointOwnership) => ownerBelongInJointOwnership(jointOwnership, ownerId));
};

export const getJointOwnershipsOfOwner = (jointOwners: Contact[], ownerId: string) => {
  return jointOwners.filter((jointOwnership) => ownerBelongInJointOwnership(jointOwnership, ownerId));
};

export const addJointOwnershipOnUnit = async (
  unit: Unit,
  existingJointOwnerships: Contact[],
  ownerIds: string[],
  updateUnit: (original: Unit, updates: Partial<Unit>) => Promise<Unit>,
  getExistingJointOwnershipOfOwnersOrCreateIfNotExists: (
    existingJointOwnerships: Contact[],
    ownerIds: string[]
  ) => Promise<Contact>
) => {
  const unitJointOwnership = await getExistingJointOwnershipOfOwnersOrCreateIfNotExists(
    existingJointOwnerships,
    ownerIds
  );
  if (isNil(unit.jointOwnershipId) || unit.jointOwnershipId !== unitJointOwnership.id) {
    await updateUnit(unit, { jointOwnershipId: unitJointOwnership.id });
  }
};

export const addJointOwnershipOnBuilding = async (
  building: Building,
  existingJointOwnerships: Contact[],
  ownerIds: string[],
  updateBuilding: (original: Building, updates: Partial<Building>) => Promise<Building>,
  getExistingJointOwnershipOfOwnersOrCreateIfNotExists: (
    existingJointOwnerships: Contact[],
    ownerIds: string[]
  ) => Promise<Contact>
) => {
  const buildingJointOwnership = await getExistingJointOwnershipOfOwnersOrCreateIfNotExists(
    existingJointOwnerships,
    ownerIds
  );
  if (isNil(building.jointOwnershipId) || building.jointOwnershipId !== buildingJointOwnership.id) {
    await updateBuilding(building, { jointOwnershipId: buildingJointOwnership.id });
  }
};

export const contactIsRelatedToBuilding = (
  contact: Contact,
  building: Building,
  buildings: Building[],
  units: Unit[]
) => {
  if (contactContainsType(contact, ContactType.CLIENT)) return true;
  if (contactContainsType(contact, ContactType.JOINT_OWNERSHIP)) {
    const jointOwnerShipsUnits = getUnitsOfJointOwnership(units, contact.id);
    const jointOwnerShipBuildings = getBuildingsOfJointOwnership(buildings, contact.id);

    const jointOwnerShipRelatedToBuilding = jointOwnerShipBuildings.some((b) => b.id === building.id);
    if (jointOwnerShipRelatedToBuilding) return true;

    const jointOwnerShipRelatedToOneUnitOfBuilding = jointOwnerShipsUnits.some(
      (u) => building.units && isIdInList(u, building.units)
    );
    if (jointOwnerShipRelatedToOneUnitOfBuilding) return true;

    return false;
  }
  const contactRelatedToBuilding =
    contact.buildings && contact.buildings.some((bo) => bo.building && bo.building.id === building.id);
  if (contactRelatedToBuilding) return true;

  const contactRelatedToOneUnitOfBuilding =
    contact.units && contact.units.some((uo) => uo.unit && building.units && isIdInList(uo.unit, building.units));
  if (contactRelatedToOneUnitOfBuilding) return true;

  return false;
};

export const getBuildingAndUnitsOwnedByContact = (contact: Contact, buildings: Building[], units: Unit[]) => {
  if (contactContainsType(contact, ContactType.CLIENT)) {
    return buildings;
  }
  if (contactContainsType(contact, ContactType.JOINT_OWNERSHIP)) {
    const allBuildingsOwned: Building[] = [];
    const jointOwnerShipsUnits = getUnitsOfJointOwnership(units, contact.id);
    const jointOwnerShipBuildings = getBuildingsOfJointOwnership(buildings, contact.id);

    // The contact owns the building, put it entirely into the list
    for (const building of jointOwnerShipBuildings) {
      uniquePushId(allBuildingsOwned, building);
    }

    for (const unit of jointOwnerShipsUnits) {
      // The contact owns an unit, put its building with the unit inside the list
      // or add the unit if it's already in the list
      const completeBuilding = buildings.find((b) => b.id === unit.building?.id);
      if (completeBuilding) {
        const buildingIndex = allBuildingsOwned.findIndex((b) => b.id === completeBuilding.id);
        if (buildingIndex > -1) {
          uniquePushId(allBuildingsOwned[buildingIndex].units!, unit);
        } else {
          allBuildingsOwned.push({ ...completeBuilding, units: [unit] });
        }
      }
    }

    return allBuildingsOwned;
  }
  const allBuildingsOwned: Building[] = [];

  if (contact.buildings) {
    // The contact owns the building, put it entirely into the list
    for (const buildingOwner of contact.buildings) {
      if (buildingOwner.stake === 100) {
        const completeBuilding = buildings.find((b) => b.id === buildingOwner.building?.id);
        if (completeBuilding) {
          uniquePushId(allBuildingsOwned, completeBuilding);
        }
      }
    }
  }
  if (contact.units) {
    for (const unitOwner of contact.units) {
      if (unitOwner.stake === 100) {
        // The contact owns an unit, put its building with the unit inside the list
        // or add the unit if it's already in the list
        const completeUnit = unitOwner.unit ? units.find((u) => u.id === unitOwner.unit!.id) : undefined;
        const completeBuilding = completeUnit ? buildings.find((b) => b.id === completeUnit.building?.id) : undefined;
        if (completeBuilding && completeUnit) {
          const buildingIndex = allBuildingsOwned.findIndex((b) => b.id === completeBuilding.id);
          if (buildingIndex > -1) {
            uniquePushId(allBuildingsOwned[buildingIndex].units!, completeUnit);
          } else {
            allBuildingsOwned.push({ ...completeBuilding, units: [completeUnit] });
          }
        }
      }
    }
  }

  return allBuildingsOwned;
};

export const getStatementsUnitsOfContact = (contact: Contact, units: Unit[], buildings: Building[]) => {
  let allUnits: Unit[] = [];
  // Statement is only on the properties 100% possessed by the owner.
  // Partially owned properties will be handled in joint ownership statements
  if (contactContainsType(contact, ContactType.OWNER)) {
    if (contact.units) {
      for (const unitOwner of contact.units) {
        if (unitOwner.unit && !unitOwner.unit.jointOwnershipId) {
          const completeUnit = units.find((u) => u.id === unitOwner.unit!.id);
          if (completeUnit) uniquePushId(allUnits, completeUnit);
        }
      }
    }
    if (contact.buildings) {
      for (const buildingOwner of contact.buildings) {
        if (buildingOwner.building && !buildingOwner.building.jointOwnershipId) {
          const completeBuilding = buildings.find((b) => b.id === buildingOwner.building!.id);
          if (completeBuilding && completeBuilding.units) {
            for (const unit of completeBuilding.units) {
              const completeUnit = units.find((u) => u.id === unit.id);
              if (completeUnit) uniquePushId(allUnits, completeUnit);
            }
          }
        }
      }
    }
  } else if (contactContainsType(contact, ContactType.JOINT_OWNERSHIP)) {
    const buildingJointOwnerShip = getBuildingsOfJointOwnership(buildings, contact.id);
    for (const building of buildingJointOwnerShip) {
      if (building.units) {
        for (const unit of building.units) {
          const completeUnit = units.find((u) => u.id === unit.id);
          if (completeUnit) uniquePushId(allUnits, completeUnit);
        }
      }
    }
    const unitsJointOwnerShip = getUnitsOfJointOwnership(units, contact.id);
    allUnits = uniqueObjectWithIdAppend(allUnits, unitsJointOwnerShip);
  }
  return allUnits;
};
