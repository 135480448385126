import { Divider, Grid } from '@material-ui/core';
import CreditCosts from './CreditCosts';
import MandateStatus from './MandateStatus';

const CAN_CREATE_MANDATE = false; // TODO REN-3552

const SubscriptionPlan = () => (
  <Grid style={{ marginLeft: 25, marginRight: 25 }}>
    {CAN_CREATE_MANDATE && <MandateStatus />}
    <Divider style={{ marginTop: 20, marginBottom: 20 }} />
    <CreditCosts />
  </Grid>
);

export default SubscriptionPlan;
