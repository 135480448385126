import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  EnhancedTableScrollable,
  PASS_FULL_OBJECT_TO_FUNCTION,
  TablePlaceHolder,
  EnhancedTableSharedContextProvider,
} from '@up2rent/ui';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { useContacts } from 'src/hooks/ContactsContext';
import { useUnits } from 'src/hooks/UnitsContext';
import AgencyRateIncompleteOwnersRow from './AgencyRateIncompleteOwnersRow';
import {
  AgencyRateOwner,
  Contact,
  UnitType,
  getMissingUnitTypeForAgencyRateOfOwner,
  compareContactsByName,
} from '@rentguru/commons-utils';
import { isEmpty, isNil } from 'lodash';
import { useAgencyRates } from 'src/hooks/AgencyRatesContext';
import { FilterEntity, useFilters } from 'src/hooks/FiltersContext';
import ContactsFilter from 'src/components/Contacts/ContactsFilter';
import { FieldFilterResetProvider } from 'src/components/ui/FieldFilterSelector';
import { arrayIncludesFieldOfObject } from 'src/utils/array';
import { ReactComponent as NoResultIcon } from 'src/icons/noResult.svg';

type IncompleteOwner = {
  id: string;
  owner: Contact;
  missingTypes: UnitType[];
};

const AgencyRateIncompleteOwners: React.FC = () => {
  const { formatMessage } = useIntl();

  const { buildingsLoading, buildings } = useBuildings();
  const { unitsLoading, units } = useUnits();
  const { contactsLoading, jointOwners, owners } = useContacts();
  const { agencyRateOwners, loading: agencyRatesLoading } = useAgencyRates();
  const {
    rateCardAssignableOwners: { filters },
    resetFilters,
  } = useFilters();

  const datasLoading = buildingsLoading || contactsLoading || unitsLoading || agencyRatesLoading || !agencyRateOwners;

  const ownersWithMissingTypes = useMemo<IncompleteOwner[]>(() => {
    if (datasLoading) {
      return [];
    }
    const filteredContacts = [...owners, ...jointOwners].reduce((currentResult: IncompleteOwner[], currentOwner) => {
      const missingTypes = getMissingUnitTypeForAgencyRateOfOwner(
        currentOwner,
        buildings,
        units,
        agencyRateOwners ?? ([] as AgencyRateOwner[])
      );
      if (!missingTypes || missingTypes.length === 0) {
        return currentResult;
      }

      currentResult.push({ id: currentOwner.id, owner: currentOwner, missingTypes: missingTypes as UnitType[] });
      return currentResult;
    }, []);

    return filteredContacts.sort((a, b) => {
      return compareContactsByName(a.owner, b.owner);
    });
  }, [datasLoading, owners, agencyRateOwners, jointOwners, units, buildings]);

  if (datasLoading) {
    return <Typography>{formatMessage({ id: 'loading' })}</Typography>;
  }

  let filteredOwners = ownersWithMissingTypes;
  // Filtered list based on menu
  if (!isEmpty(filters)) {
    filteredOwners = filteredOwners.reduce((currentList: IncompleteOwner[], currentObject: IncompleteOwner) => {
      // Check in the filter list if i need to keep this object
      let keepObject = true;
      filters.forEach((f) => {
        if (keepObject && (isEmpty(f.items) || arrayIncludesFieldOfObject(f.items, f.field, currentObject.owner))) {
          keepObject = true;
        } else {
          keepObject = false;
        }
      });

      if (!keepObject) {
        return currentList;
      }
      return [...currentList, currentObject];
    }, [] as IncompleteOwner[]);
  }

  const initialContactsSelectedItems = filters.find((f) => f.name === 'name');
  const initialContactsSelectedItemsOrNull = initialContactsSelectedItems
    ? initialContactsSelectedItems.items.map((b) => ({ value: b }))
    : null;
  const noFilterSelected = isNil(initialContactsSelectedItemsOrNull);

  const ownersForFilter = ownersWithMissingTypes.map((ownersWithMissingType) => ownersWithMissingType.owner);

  return (
    <FieldFilterResetProvider>
      <ContactsFilter
        contacts={ownersForFilter}
        resetAllFilters={() => resetFilters(FilterEntity.RATE_CARD_ASSIGNABLE_OWNERS)}
        type={FilterEntity.RATE_CARD_ASSIGNABLE_OWNERS}
        containerStyle={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}
      />
      <EnhancedTableSharedContextProvider
        value={{ orderBy: `${PASS_FULL_OBJECT_TO_FUNCTION}name` }}
        orderFirst="desc"
        datas={filteredOwners}
      >
        <EnhancedTableScrollable
          NoResultIcon={<NoResultIcon />}
          formatMessage={formatMessage}
          datas={filteredOwners}
          noMinWidth
          columns={[]}
          defaultOrderBy={`${PASS_FULL_OBJECT_TO_FUNCTION}name`}
          rowComponent={AgencyRateIncompleteOwnersRow}
          placeHolder={
            <TablePlaceHolder
              mainText={formatMessage({ id: 'emptySectionTitle' })}
              subText={formatMessage({ id: 'emptySectionText' })}
            />
          }
          menuShown={false}
          filtersSelected={!noFilterSelected}
          resetFiltersFunction={() => {
            resetFilters(FilterEntity.RATE_CARD_ASSIGNABLE_OWNERS);
          }}
        />
      </EnhancedTableSharedContextProvider>
    </FieldFilterResetProvider>
  );
};

export default AgencyRateIncompleteOwners;
