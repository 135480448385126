import { makeStyles } from '@material-ui/core';
import {
  containsSpecialChars,
  PasswordConditions,
  containsSpecificCaseCase,
  containsNumbers,
  Colors,
} from '@rentguru/commons-utils';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import { useState } from 'react';

const useLocalStyles = makeStyles({
  linearProgress1: {
    '& .MuiLinearProgress-bar': {
      backgroundColor: Colors.STRONG_PINK,
    },
  },
  linearProgress2: {
    '& .MuiLinearProgress-bar': {
      backgroundColor: Colors.SCARLET_ORANGE,
    },
  },
  linearProgress3: {
    '& .MuiLinearProgress-bar': {
      backgroundColor: Colors.DARK_SKY_BLUE,
    },
  },
  linearProgress4: {
    '& .MuiLinearProgress-bar': {
      backgroundColor: Colors.AQUAMARINE_BLUE,
    },
  },
  linearProgress5: {
    '& .MuiLinearProgress-bar': {
      backgroundColor: Colors.MINTY_GREEN,
    },
  },
});

export const usePasswordUtils = () => {
  const localStyles = useLocalStyles();
  const { setFieldValue } = useFormikContext();
  const [actualProgress, setActualProgress] = useState<PasswordConditions>({
    has8Characters: false,
    hasLowerCase: false,
    hasNumber: false,
    hasSpecialCharacter: false,
    hasUppercase: false,
  });

  const handleChangeNewPassword = (password: string) => {
    if (isEmpty(password)) {
      setActualProgress((previousValue) => {
        return {
          ...previousValue,
          has8Characters: false,
          hasSpecialCharacter: false,
          hasLowerCase: false,
          hasUppercase: false,
          hasNumber: false,
        };
      });
    } else {
      setActualProgress((previousValue) => {
        return {
          ...previousValue,
          has8Characters: password.length >= 8,
          hasSpecialCharacter: containsSpecialChars(password),
          hasLowerCase: containsSpecificCaseCase(password, 'Lower'),
          hasUppercase: containsSpecificCaseCase(password, 'Upper'),
          hasNumber: containsNumbers(password),
        };
      });
    }
    setFieldValue('newPassword1', password);
  };

  const amountOfConditionsForPassword = Object.keys(actualProgress).reduce((amount, actualProgressKey) => {
    if (actualProgress[actualProgressKey as keyof PasswordConditions]) return amount + 1;
    return amount;
  }, 0);

  return { actualProgress, handleChangeNewPassword, amountOfConditionsForPassword, localStyles };
};
