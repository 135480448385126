/* eslint-disable @typescript-eslint/no-shadow */
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as Logo } from '../../../../icons/transactionPlaceholder.svg';
import { EnhancedTableScrollable, EnhancedTableSharedContextProvider, TablePlaceHolder } from '@up2rent/ui';
import { FilterEntity, useFilters } from 'src/hooks/FiltersContext';
import {
  getContactNameFromObject,
  STATEMENT_AMOUNT_OWNER_TOTAL_ADJUSTEMENT,
  EntityType,
  FileCategory,
  isNilOrEmpty,
  S3Object,
} from '@rentguru/commons-utils';
import { filterOwnerStatements } from '../OwnerStatementsFilters';
import { useFieldFilterReset } from '../../../ui/FieldFilterSelector';
import { OwnerStatementExtended } from '../OwnerStatements';
import PreviewFileDialog from 'src/components/ui/PreviewFileDialog';
import { getS3ObjectUrls, useFiles } from 'src/hooks/FilesContext';
import OwnerStatementsArchivedRow from './OwnerStatementsArchivedRow';
import { ReactComponent as NoResultIcon } from 'src/icons/noResult.svg';

const columns = [
  {
    id: 'periodSortLabel',
    labelId: 'financial.period',
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
  {
    id: 'sentDate',
    labelId: 'accounting.statement.archivedSection.sentDate',
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
  {
    id: 'ownerName',
    labelId: 'building.detail.general.owner',
    numeric: false,
    disablePadding: true,
    sortable: true,
  },
  {
    id: 'numberOfUnits',
    labelId: 'building.columnHeader.units',
    numeric: false,
    disablePadding: true,
    sortable: true,
  },
  {
    id: 'source',
    labelId: 'tickets.filter.sourceType',
    numeric: false,
    disablePadding: true,
    sortable: true,
  },
  {
    id: 'types',
    labelId: 'accounting.statement.statementType',
    numeric: false,
    disablePadding: false,
    sortable: false,
  },
  {
    id: 'totalAmount',
    labelId: 'transactions.list.amount',
    numeric: false,
    disablePadding: true,
    sortable: true,
  },
  {
    id: 'more',
    labelId: 'transactions.list.action',
    numeric: false,
    disablePadding: false,
    sortable: false,
    style: { textAlign: 'center' },
  },
];

interface OwnerStatementsArchivedProps {
  ownerStatementsArchived: OwnerStatementExtended[];
}

const OwnerStatementsArchived: React.FC<OwnerStatementsArchivedProps> = ({ ownerStatementsArchived }) => {
  const { formatMessage } = useIntl();
  const [statementS3Objects, setStatementFiles] = useState<S3Object[] | null>(null);
  const [fileToPreview, setFileToPreview] = useState<S3Object | null>(null);
  const { ownerStatementsFilters, resetFilters } = useFilters();
  const { executeResetFilters } = useFieldFilterReset();
  const { getFiles, loading: filesLoading } = useFiles();

  useEffect(() => {
    const fetchAllStatementsFiles = async () => {
      const statementsFiles = getFiles(
        EntityType.STATEMENT,
        ownerStatementsArchived.map((statement) => statement.id)
      );
      const fetchedStatementS3Objects = await getS3ObjectUrls(statementsFiles);
      setStatementFiles(fetchedStatementS3Objects);
    };

    if (isNilOrEmpty(statementS3Objects) && !filesLoading) fetchAllStatementsFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerStatementsArchived, filesLoading]);

  const noFiltersSelected =
    isEmpty(ownerStatementsFilters.filters) &&
    isNil(ownerStatementsFilters.statementsPeriod) &&
    isNil(ownerStatementsFilters.statementsSentDate);
  const ownerStatementsToCheckData = ownerStatementsArchived.map((statement) => {
    const numberOfTypes = statement.types ? statement.types.length : 0;
    const numberOfUnits = statement.units.length;
    const adjustementAmount = statement.amounts
      ? statement.amounts.find((amount) => amount.name === STATEMENT_AMOUNT_OWNER_TOTAL_ADJUSTEMENT)
      : undefined;
    const totalAmount = adjustementAmount ? Number(adjustementAmount.value) : undefined;
    const ownerName = statement.owner ? getContactNameFromObject(statement.owner) : '';
    const periodSortLabel = `${statement.periodFrom}${statement.periodTo}`;
    const statementDetail = statementS3Objects?.find(
      (sf) => sf.foreignKey === statement.id && sf.category?.fileCategory === FileCategory.STATEMENT_DETAIL
    );
    return {
      ...statement,
      ownerName,
      numberOfTypes,
      numberOfUnits,
      totalAmount,
      periodSortLabel,
      statementDetail,
    };
  });
  const filteredOwnerStatementsToCheck = filterOwnerStatements(ownerStatementsToCheckData, ownerStatementsFilters);

  return (
    <EnhancedTableSharedContextProvider
      value={{ orderBy: 'periodFrom' }}
      orderFirst="desc"
      datas={filteredOwnerStatementsToCheck}
      handleActionMenu={(_e: React.MouseEvent, _id: string, fileToPreview: S3Object) => {
        setFileToPreview(fileToPreview);
      }}
    >
      <EnhancedTableScrollable
        NoResultIcon={<NoResultIcon />}
        formatMessage={formatMessage}
        datas={filteredOwnerStatementsToCheck}
        columns={columns}
        defaultOrderBy="periodFrom"
        rowComponent={OwnerStatementsArchivedRow}
        placeHolder={
          <TablePlaceHolder
            Icon={<Logo width={72} />}
            mainText={formatMessage(
              { id: 'accounting.statement.archivedSection.noStatements' },
              { statements: formatMessage({ id: 'accounting.statement.ownerStatements' }, { value: 1 }).toLowerCase() }
            )}
            subText=""
            mainTextStyle={{ maxWidth: 450 }}
          />
        }
        filtersSelected={!noFiltersSelected}
        resetFiltersFunction={() => {
          executeResetFilters();
          resetFilters(FilterEntity.OWNER_STATEMENTS);
        }}
      />
      {fileToPreview && (
        <PreviewFileDialog
          open={!isNil(fileToPreview)}
          file={fileToPreview}
          onClose={() => setFileToPreview(null)}
          paperStyle={{ width: 'auto', overflowY: 'unset' }}
        />
      )}
    </EnhancedTableSharedContextProvider>
  );
};

export default OwnerStatementsArchived;
