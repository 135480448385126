import React from 'react';
import {
  Colors,
  INTEGRATIONS_WITH_CATEGORIES,
  IntegrationPrice,
  integrationPrices,
  IntegrationCategories,
  Integration,
  IntegrationName,
  IntegrationPriceType,
} from '@rentguru/commons-utils';
import { Box, Tooltip, Typography } from '@material-ui/core';
import SettingBoxDetailLine from '../SettingBoxDetailLine';
import { useIntl } from 'react-intl';
import { getDisplayPrice, useIntegrationStyles } from './IntegrationBox';
import { makeStyles } from '@material-ui/styles';
import { ActionButton , ActionButtonSecond } from '@up2rent/ui';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import IntegrationLogo from './IntegrationLogo';

const useStyles = makeStyles(() => ({
  box: {
    width: 280,
    height: 350,
    borderRadius: 10,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: Colors.SILVER,
    marginBottom: 15,
  },
  header: {
    backgroundColor: Colors.PORCELAIN_GREY_1,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 80,
  },
  boxContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 10,
    paddingTop: 10,
  },
  boxLines: {
    display: 'flex',
    marginTop: 20,
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  boxButton: {
    display: 'flex',
    marginTop: 20,
    alignSelf: 'end',
    flexDirection: 'column',
  },
  integrationDescription: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: Colors.CHARCOAL_GREY,
    textAlign: 'left',
    height: 60,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
    whiteSpace: 'pre-wrap',
  },
}));

interface IntegrationSmallBoxProps {
  name: IntegrationName;
  integration?: Integration;
  onClick: (id: string | null, name: IntegrationName) => void;
}

const IntegrationSmallBox: React.FC<IntegrationSmallBoxProps> = ({ name, integration, onClick }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const integrationClasses = useIntegrationStyles();
  const { settingsAutomationWrite } = usePermissions();

  const category =
    INTEGRATIONS_WITH_CATEGORIES.find(
      (integrationWithCategory: IntegrationCategories) => integrationWithCategory.name === name
    )?.categories[0] ?? '';

  const integrationPrice = integrationPrices.find((price: IntegrationPrice) => price.name === name);
  const getIntegrationPriceDisplay = (price?: IntegrationPrice) => {
    if (!price) return formatMessage({ id: 'settings.integrations.free' });

    if (price.type === IntegrationPriceType.SUBSCRIPTION) {
      return getDisplayPrice(price.price!, formatMessage({ id: `settings.integrations.frequency.${price.frequency}` }));
    }

    if (price.type === IntegrationPriceType.USAGE) {
      return formatMessage({ id: 'settings.credits.name' });
    }

    return `${price.price}€`;
  };

  return (
    <Box className={classes.box}>
      <div className={classes.header}>
        <IntegrationLogo name={name} />
      </div>
      <div className={classes.boxContent}>
        <Typography className={integrationClasses.integrationName}>
          {formatMessage({ id: `settings.integrations.IntegrationName.${name}` })}
        </Typography>

        <Tooltip title={formatMessage({ id: `settings.integrations.description.${name}` })} placement="bottom">
          <Typography className={classes.integrationDescription}>
            {formatMessage({ id: `settings.integrations.description.${name}` })}
          </Typography>
        </Tooltip>
        <div className={classes.boxLines}>
          <SettingBoxDetailLine
            text={formatMessage({ id: 'settings.integrations.details.category' })}
            value={formatMessage({
              id: `settings.integrations.IntegrationCategory.${category}`,
              defaultMessage: '',
            })}
          />
          <SettingBoxDetailLine
            text={formatMessage({ id: 'settings.integrations.details.price' })}
            value={getIntegrationPriceDisplay(integrationPrice)}
          />
        </div>
        <div className={classes.boxButton}>
          {!integration && settingsAutomationWrite && (
            <ActionButton onClick={() => onClick(null, name)}>
              {formatMessage({ id: 'settings.integrations.details.configure' })}
            </ActionButton>
          )}
          {integration && settingsAutomationWrite && (
            <ActionButtonSecond onClick={() => onClick(integration.id, name)}>
              {formatMessage({ id: 'edit' })}
            </ActionButtonSecond>
          )}
        </div>
      </div>
    </Box>
  );
};

export default IntegrationSmallBox;
