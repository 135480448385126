import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '@rentguru/commons-utils';

const useEditPublicationFormStyles = makeStyles({
  title: {
    flex: 1,
    textAlign: 'left' as const,
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Mulish',
    fontSize: 16,
    fontWeight: 700,
    margin: '20px 0 20px 30px',
  },
  label: {
    margin: '0 0 0 10px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: Colors.DARK,
    fontSize: 12,
    fontWeight: 400,
  },
  textLabel: {
    fontSize: 14,
    fontWeight: 600,
  },
});

export default useEditPublicationFormStyles;
