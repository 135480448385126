/* eslint-disable default-param-last */
import type { LooseObject, BasicModel } from '@rentguru/commons-utils';
import { isEmpty, isNil } from 'lodash';
import { generateClient } from 'aws-amplify/api';
import { getFilters, getGraphQlMethodName, getLambdaAuthorizerTokenParam } from './utils';

const GRAPHQL_CLIENT = generateClient();

export const mutation = async <ResultType extends object, VariablesType extends object = object>(
  query: string,
  variables?: VariablesType,
  lambdaAuthorizer: boolean = false
) => {
  const lambdaAuthorizerParam = await getLambdaAuthorizerTokenParam(lambdaAuthorizer);
  const method = getGraphQlMethodName(query, 'mutation');
  const { data } = (await GRAPHQL_CLIENT.graphql({
    query,
    variables,
    ...(lambdaAuthorizer ? { authMode: 'lambda', authToken: lambdaAuthorizerParam! } : {}),
  })) as {
    data: { [method: string]: ResultType };
  };
  return data[method] as ResultType;
};

export const deleteEntityWithFetchBefore = async <
  ResultType extends object,
  DeleteVariablesType extends { input?: object; condition?: object | null } = object,
>(
  entity: ResultType,
  getQuery: string,
  deleteQuery: string,
  deleteVariables: DeleteVariablesType = {} as DeleteVariablesType,
  lambdaAuthorizer: boolean = false
) => {
  const lambdaAuthorizerParam = await getLambdaAuthorizerTokenParam(lambdaAuthorizer);
  const method = getGraphQlMethodName(getQuery, 'query');
  const variables = { id: (entity as BasicModel<ResultType>).id };
  const { data } = (await GRAPHQL_CLIENT.graphql({
    query: getQuery,
    variables,
    ...(lambdaAuthorizer ? { authMode: 'lambda', authToken: lambdaAuthorizerParam! } : {}),
  })) as {
    data: { [method: string]: ResultType };
  };

  const latestEntityVersion = data[method] as BasicModel<ResultType>;

  const result = await mutation<ResultType>(
    deleteQuery,
    {
      input: { id: latestEntityVersion.id, _version: latestEntityVersion._version, ...deleteVariables.input },
      ...deleteVariables.condition,
    },
    lambdaAuthorizer
  );

  return result;
};

export const deleteAndHideEntityWithFetchBefore = async <
  ResultType extends object,
  DeleteVariablesType extends { input?: object; condition?: object | null } = object,
  UpdateVariablesType extends { input?: object; condition?: object | null } = object,
>(
  entity: ResultType,
  getQuery: string,
  deleteQuery: string,
  updateQuery: string,
  deleteVariables: DeleteVariablesType = {} as DeleteVariablesType,
  updateVariables: UpdateVariablesType = {} as UpdateVariablesType,
  lambdaAuthorizer: boolean = false
) => {
  const lambdaAuthorizerParam = await getLambdaAuthorizerTokenParam(lambdaAuthorizer);
  const method = getGraphQlMethodName(getQuery, 'query');
  const variables = { id: (entity as BasicModel<ResultType>).id };
  const { data } = (await GRAPHQL_CLIENT.graphql({
    query: getQuery,
    variables,
    ...(lambdaAuthorizer ? { authMode: 'lambda', authToken: lambdaAuthorizerParam! } : {}),
  })) as {
    data: { [method: string]: ResultType };
  };

  const latestEntityVersion = data[method] as BasicModel<ResultType>;

  const result = await deleteAndHideEntity(
    { ...entity, _version: latestEntityVersion._version },
    deleteQuery,
    updateQuery,
    deleteVariables,
    updateVariables,
    lambdaAuthorizer
  );

  return result;
};

export const deleteAndHideEntity = async <
  ResultType extends object,
  DeleteVariablesType extends { input?: object; condition?: object | null } = object,
  UpdateVariablesType extends { input?: object; condition?: object | null } = object,
>(
  entity: ResultType,
  deleteQuery: string,
  _updateQuery: string,
  deleteVariables: DeleteVariablesType = {} as DeleteVariablesType,
  _updateVariables: UpdateVariablesType = {} as UpdateVariablesType,
  lambdaAuthorizer: boolean = false
) => {
  const entityWithVersion: BasicModel<ResultType> = entity as BasicModel<ResultType>;
  const result = await mutation<ResultType>(
    deleteQuery,
    {
      input: { id: entityWithVersion.id, _version: entityWithVersion._version, ...deleteVariables.input },
      ...deleteVariables.condition,
    },
    lambdaAuthorizer
  );
  return result;
};

export const list = async <ResultType extends object>(
  query: string,
  indexName?: string,
  indexValue?: string,
  additionalFilter?: LooseObject,
  lambdaAuthorizer: boolean = false,
  lastSync?: Date,
  limit: number = 1000
) => {
  const lambdaAuthorizerParam = await getLambdaAuthorizerTokenParam(lambdaAuthorizer);
  const method = getGraphQlMethodName(query, 'query');
  const filter = getFilters(indexName, indexValue, additionalFilter);
  const isSync = Boolean(query.match(/query Sync[\W\w]*\([\W\w]*\$lastSync/gm));
  let entities: ResultType[] = [];
  let nextToken: string | null = '';

  try {
    while (!isNil(nextToken)) {
      const variables: { limit: number; filter: LooseObject | null; nextToken: string | null; lastSync?: string } = {
        limit,
        filter,
        nextToken: isEmpty(nextToken) ? null : nextToken,
      };
      if (isSync && lastSync) {
        variables['lastSync'] = lastSync.toISOString();
      }
      const listResultRaw = (await GRAPHQL_CLIENT.graphql({
        query,
        variables,
        ...(lambdaAuthorizer ? { authMode: 'lambda', authToken: lambdaAuthorizerParam! } : {}),
      })) as {
        data: { [method: string]: { items: ResultType[]; nextToken: string | null } };
      };

      const listResultCleaned = listResultRaw.data[method].items.filter(
        (res: { _deleted?: boolean }) => res._deleted !== true
      );
      entities = [...entities, ...listResultCleaned];
      nextToken = listResultRaw.data[method].nextToken;
    }
    return entities;
  } catch (err) {
    console.error('error', err);
    return [] as ResultType[];
  }
};

export const get = async <ResultType extends object>(
  getQuery: string,
  id: string,
  lambdaAuthorizer: boolean = false
) => {
  const lambdaAuthorizerParam = await getLambdaAuthorizerTokenParam(lambdaAuthorizer);
  const method = getGraphQlMethodName(getQuery, 'query');
  const variables = { id };
  const { data } = (await GRAPHQL_CLIENT.graphql({
    query: getQuery,
    variables,
    ...(lambdaAuthorizer ? { authMode: 'lambda', authToken: lambdaAuthorizerParam! } : {}),
  })) as {
    data: { [method: string]: ResultType };
  };

  const result = data[method] as BasicModel<ResultType>;

  return result;
};
