/* eslint-disable react/prefer-stateless-function */
import * as React from 'react';
import * as Sentry from '@sentry/react';

interface IEmptyProps {
  children?: React.ReactNode;
}

class ErrorBoundary extends React.Component<IEmptyProps> {
  render() {
    return (
      <>
        {/* @ts-ignore */}
        <Sentry.ErrorBoundary fallback="An error has occurred" showDialog>
          {this.props.children}
        </Sentry.ErrorBoundary>
      </>
    );
  }
}

export default ErrorBoundary;
