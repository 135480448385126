import { Divider, Tab } from '@material-ui/core';
import { Colors, LeaseStatus } from '@rentguru/commons-utils';
import { ActionButton, ActionButtonSecond, ContentHeader, CustomizedTabs, actionButtonSecondStyles } from '@up2rent/ui';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { Link, useRouteMatch } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';
import SkeletonComponent from 'src/components/ui/SkeletonComponent';
import { LeaseExtended, useLeases } from 'src/hooks/LeasesContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { ReactComponent as AddIcon } from 'src/icons/add.svg';
import {
  LEASES_DETAIL_TO,
  getDefaultLeasesTab,
  getTabAssignedToSelectedLease,
  isLeaseDetailOpen,
  leasesNavItems,
} from './Leases';

const LeasesTabHeader = () => {
  const actionButtonClasses = actionButtonSecondStyles();

  const { leasesCreationWrite, buildingsUnitsDetailsWrite } = usePermissions();
  const { units, unitsLoading } = useUnits();
  const { getLease, leasesLoading } = useLeases();
  const { formatMessage } = useIntl();
  const match = useRouteMatch<{ tabName?: string; id?: string }>({
    path: `${RouteDestination.LEASES}/:tabName?/:id?`,
  });

  if (!match) {
    return null;
  }
  const defaultTab = getDefaultLeasesTab();
  if (!defaultTab) {
    return null;
  }

  const loading = unitsLoading || (leasesLoading ?? true);

  let tabName = match.params.tabName ?? defaultTab.to;
  const openDetail = isLeaseDetailOpen(match);
  let selectedLease: LeaseExtended | null | undefined = null;
  if (openDetail) {
    // Check lease status to put in tab name
    selectedLease = getLease(match.params!.id!);
    if (selectedLease) {
      const tabAssignedForLease = getTabAssignedToSelectedLease(selectedLease.status as LeaseStatus);

      tabName = tabAssignedForLease.to ?? defaultTab.to;
    }
  }
  tabName = tabName === LEASES_DETAIL_TO ? defaultTab.to : tabName;

  return (
    <>
      <ContentHeader title={formatMessage({ id: 'menu.leases' })}>
        {leasesCreationWrite && units && !isEmpty(units) && (
          <ActionButtonSecond
            className={actionButtonClasses.buttonWithMargin}
            component={Link}
            to={RouteDestination.ADD_LEASE}
            style={{ textTransform: 'none' }}
            startIcon={<AddIcon style={{ fill: Colors.BLUEY }} />}
          >
            {formatMessage({ id: 'lease.addLease.action' })}
          </ActionButtonSecond>
        )}
        {buildingsUnitsDetailsWrite && (
          <SkeletonComponent loading={loading}>
            <ActionButton
              component={Link}
              to={RouteDestination.ADD_UNIT}
              style={{ textTransform: 'none' }}
              data-test="addUnit"
            >
              {formatMessage({ id: 'rentalUnit.addUnit.action' })}
            </ActionButton>
          </SkeletonComponent>
        )}
      </ContentHeader>
      <CustomizedTabs value={tabName} dynamicDefaultTab>
        {leasesNavItems.map((navItem) => {
          if (navItem.isDetail || !navItem.to) {
            return null;
          }
          const urlWithoutId = match.params.id ? match.url.replace(`/${match.params.id}`, '') : match.url;

          const to = match.params.tabName
            ? urlWithoutId.replace(match.params.tabName, navItem.to)
            : urlWithoutId + (urlWithoutId.charAt(urlWithoutId.length - 1) === '/' ? '' : '/') + navItem.to;

          return (
            <Tab
              label={formatMessage({ id: navItem.labelId })}
              component={Link}
              key={navItem.to}
              to={to}
              value={navItem.to}
            />
          );
        })}
      </CustomizedTabs>
      <Divider />
    </>
  );
};

export default LeasesTabHeader;
