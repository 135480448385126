import { OptionsObject, SnackbarKey, SnackbarMessage, SnackbarProvider, VariantType } from 'notistack';
import { FilesContext } from 'src/hooks/FilesContext';

import {
  CustomLabel,
  FileCategory,
  FolderEntity,
  Language,
  DocumentCategoryWithoutReadIdAndClientId,
  DocumentSortOptions,
  FolderModel,
  getTranslatedCategory,
  DEFAULT_ACCEPTED_MIME_TYPES,
  File as FileModel,
  EntityType,
  Contact,
  contactMostImportantType,
  getUniqueFileName,
} from '@rentguru/commons-utils';
import { groupBy, isEmpty, isNil, orderBy } from 'lodash';
import { translateText } from 'src/utils/translationUtils';
import Fuse from 'fuse.js';
import {
  ConfirmationDialogContext,
  ConfirmationDialogOptions,
  DialogConfig,
} from 'src/hooks/ConfirmationDialogContext';
import { IntlFormatters } from 'react-intl';

export const openSnackbar = (
  enqueueSnackbar: (message: SnackbarMessage, options: OptionsObject) => SnackbarKey,
  message: string,
  variant: VariantType
) => {
  enqueueSnackbar(message, {
    variant,
    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  });
};

export const sortDocumentCategories = (
  categories: DocumentCategoryWithoutReadIdAndClientId[],
  language: string | null
) => {
  const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

  const alphanumeric = categories.sort((a, b) =>
    collator.compare(getTranslatedCategory(a, language), getTranslatedCategory(b, language))
  );

  const groupedList = groupBy(alphanumeric, (category) => category.fileCategory === FileCategory.CUSTOM);

  return [...(groupedList['false'] ?? []), ...(groupedList['true'] ?? [])];
};

const makeSortedList = (folders: FolderModel[]) => {
  const groupedFolders = groupBy(folders, (folder) => folder.folderEntity);

  const groupedFolderEntityFolders = groupBy(
    groupedFolders[FolderEntity.FOLDER],
    (folder) => folder.documentCategory.fileCategory === FileCategory.CUSTOM
  );

  const sortedList = [
    ...(groupedFolders[FolderEntity.CONTACT] ?? []),
    ...(groupedFolders[FolderEntity.BUILDING] ?? []),
    ...(groupedFolders[FolderEntity.UNIT] ?? []),
    ...(groupedFolders[FolderEntity.LEASE] ?? []),
    ...(groupedFolderEntityFolders['false'] ?? []),
    ...(groupedFolderEntityFolders['true'] ?? []),
    ...(groupedFolders[FolderEntity.FILE] ?? []),
  ];

  return sortedList;
};

export const sortFolders = (folders: FolderModel[], sortOption: DocumentSortOptions): FolderModel[] => {
  if (sortOption === DocumentSortOptions.ALPAHBETICALLY_ASC) {
    // We need to use this for alphanumeric sorting
    // Without this the sorting will be like 1, 10, 2, 3, 4, 5, 6, 7, 8, 9 (lexicographical order)
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

    const alphanumeric = folders.sort((a, b) =>
      collator.compare(a.labelToSortOn ?? a.translatedLabel, b.labelToSortOn ?? b.translatedLabel)
    );

    const sortedList = makeSortedList(alphanumeric);

    return sortedList;
  }
  if (sortOption === DocumentSortOptions.ALPAHBETICALLY_DES) {
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

    const alphanumeric = folders
      .sort((a, b) => collator.compare(a.labelToSortOn ?? a.translatedLabel, b.labelToSortOn ?? b.translatedLabel))
      .reverse();

    const newSortedFolders = makeSortedList(alphanumeric);
    return newSortedFolders;
  }
  if (sortOption === DocumentSortOptions.SIZE_ASC) {
    const orderedByFile = orderBy(folders, [(folder) => folder.size], ['asc']);

    const newSortedFolders = makeSortedList(orderedByFile);

    return newSortedFolders;
  }
  if (sortOption === DocumentSortOptions.SIZE_DES) {
    const orderedByFile = orderBy(folders, [(folder) => folder.size], ['desc']);

    const newSortedFolders = makeSortedList(orderedByFile);

    return newSortedFolders;
  }
  if (sortOption === DocumentSortOptions.DATE_ASC) {
    const orderByDate = orderBy(folders, [(folder) => new Date(folder.size).getTime()], ['asc']);

    const newSortedFolders = makeSortedList(orderByDate);

    return newSortedFolders;
  }
  if (sortOption === DocumentSortOptions.DATE_DES) {
    const orderByDate = orderBy(folders, [(folder) => new Date(folder.size).getTime()], ['desc']);

    const newSortedFolders = makeSortedList(orderByDate);

    return newSortedFolders;
  }
  return folders;
};

export const handleSaveWithTranslation = async (labels: CustomLabel[]) => {
  let translate = false;
  const filledLanguages: Language[] = labels.reduce((acc, label) => {
    if (!isEmpty(label.label)) {
      acc.push(label.language as Language);
      return acc;
    }
    translate = true;
    return acc;
  }, [] as Language[]);

  if (isEmpty(filledLanguages)) {
    return;
  }
  if (translate && filledLanguages.length > 0) {
    const allLanguages = Object.values(Language);
    const textToTranslate = labels.find((label) => label.language === filledLanguages[0])?.label;
    const destinationLanguages = allLanguages.filter((lang) => !filledLanguages.includes(lang));
    for (const destinationLanguage of destinationLanguages) {
      const newText = await translateText(textToTranslate!, filledLanguages[0], destinationLanguage);
      const labelToUpdate = labels.find((label) => label.language.toUpperCase() === destinationLanguage);
      if (labelToUpdate) {
        labelToUpdate.label = newText;
      }
    }
  }

  return labels;
};

export const filterFoldersOnName = (folders: FolderModel[], filter: string) => {
  const options = {
    keys: ['translatedLabel'],
    findAllMatches: true,
    threshold: 0,
    ignoreLocation: true,
  };
  const fuse = new Fuse(folders, options);
  return fuse.search(filter).map((result) => result.item);
};

export const getUniqueFiles = async (
  files: File[],
  filesInFolder: string[],
  entityTypeCreateFolder: EntityType,
  entityId: string,
  folderDocumentCategoryId: string,
  contact: Contact | undefined,
  getConfirmation: ConfirmationDialogContext['getConfirmation'],
  formatMessage: IntlFormatters['formatMessage'],
  enqueueSnackbar: SnackbarProvider['enqueueSnackbar'],
  createFile: FilesContext['createFile']
) => {
  const uniqueFiles: FileModel[] = [];
  const filesToReplace: string[] = [];
  for (const file of files) {
    const fileType = file.type.split('/')[0];
    if (DEFAULT_ACCEPTED_MIME_TYPES.includes(file.type) || fileType === 'image') {
      let uniqueFileName = file.name;
      let replaceFile = false;
      if (filesInFolder.includes(file.name)) {
        const result = await getConfirmation({
          title: formatMessage({ id: 'folders.fileActions.duplicateFolderTitle' }),
          text: formatMessage({ id: 'folders.fileActions.duplicateFolder' }, { fileName: file.name }),
          actionButtonLabel: formatMessage({ id: 'folders.fileActions.replace' }),
          thirdButtonLabel: formatMessage({ id: 'folders.fileActions.keepBoth' }),
          formatMessage,
        } as DialogConfig);
        if (result === ConfirmationDialogOptions.THIRD_BUTTON) {
          uniqueFileName = getUniqueFileName(filesInFolder, file.name);
        }
        if (result === ConfirmationDialogOptions.ACTION_BUTTON) {
          uniqueFileName = file.name;
          replaceFile = true;
        }
        if (isNil(result)) {
          continue;
        }
      }
      const encodeFile = new File([file], encodeURIComponent(uniqueFileName), {
        type: file.type,
        lastModified: file.lastModified,
      });
      const newFile = await createFile(
        encodeFile,
        entityTypeCreateFolder,
        entityId,
        folderDocumentCategoryId,
        undefined,
        !isNil(contact) ? contactMostImportantType(contact) : undefined,
        contact?.id
      );
      uniqueFiles.push(newFile!);
      if (replaceFile) {
        filesToReplace.push(file.name);
      }
    } else {
      openSnackbar(enqueueSnackbar, formatMessage({ id: 'folders.dragAndDropWrapper.fileTypeNotSupported' }), 'error');
    }
  }
  return { uniqueFiles, filesToReplace };
};

export const sortFoldersOnType = (foldersToSort: FolderModel[], sortTypes: string[]) => {
  return foldersToSort.filter((folder) => {
    if (!folder.documentModel?.mimeType) return false;
    const typeToSortOn = folder.documentModel!.mimeType!.includes('image')
      ? 'image/*'
      : folder.documentModel!.mimeType!;

    return sortTypes.includes(typeToSortOn);
  });

};
