import { Divider, Grid } from '@material-ui/core';
import React from 'react';
import AddOperation, {
  AddOperationFormValue,
  getArrayOfDiscountsSchema,
  getArrayOfOperationsSchema,
} from './AddOperation';
import RentIndexation, { RentIndexationFormValues } from './RentIndexation';
import MonthlyCharges, { MonthlyChargesFormValues } from '../FormSection/MonthlyCharges';
import Discounts, { AddDiscountFormValues } from '../FormSection/Discounts';
import FurnishedRental, { FurnishedRentalFormValues } from '../FormSection/FurnishedRental';
import AuthorizeProfesionnalActivity, {
  AuthorizeProfesionnalActivityFormValues,
} from './AuthorizeProfesionnalActivity';
import { VatOnRentFormValues } from './VatOnRent';
import { UnitType } from '@rentguru/commons-utils';
import * as Yup from 'yup';
import { getSubUnitSchema } from '../FormSection/AddAdditionalUnits';
import { SurfaceOfUnitFormValues } from './SurfaceOfUnitFields';
import RentalPrice from './RentalPrice';
import { LeaseRentSchema } from './LeaseRentFields';

export interface RentFieldFormValues
  extends MonthlyChargesFormValues,
    AddOperationFormValue,
    AddDiscountFormValues,
    RentIndexationFormValues,
    FurnishedRentalFormValues,
    AuthorizeProfesionnalActivityFormValues,
    VatOnRentFormValues,
    SurfaceOfUnitFormValues {
  rentalPrice: number;
}
interface RentFieldsProps {
  unitId: string;
  unitType: UnitType | undefined;
}

export const getAddOperationCompleteSchemaDynamically = (startDate: Date | undefined, endDate: Date | undefined) => {
  return Yup.object()
    .shape({
      leaseOperations: getArrayOfOperationsSchema(),
      discounts: getArrayOfDiscountsSchema(),
      subUnits: getSubUnitSchema(startDate, endDate),
    })
    .concat(LeaseRentSchema);
};

export const RentDivider = () => {
  return <Divider style={{ marginBottom: 30, marginTop: 30 }} />;
};

const RentFields: React.FC<RentFieldsProps> = ({ unitId, unitType }) => {
  return (
    <Grid style={{ paddingBottom: 15 }}>
      <RentalPrice unitId={unitId} />
      <RentDivider />
      <MonthlyCharges unitId={unitId} />
      <RentDivider />
      <RentIndexation unitId={unitId} />
      <RentDivider />
      <AddOperation unitId={unitId} />
      <RentDivider />
      <Discounts unitId={unitId} />
      {![UnitType.PARKING, UnitType.OTHER].includes(unitType!) && (
        <>
          <RentDivider />
          <FurnishedRental unitId={unitId} />
        </>
      )}
      {![UnitType.PARKING, UnitType.OTHER, UnitType.STUDENT, UnitType.OFFICE, UnitType.COMMERCIAL].includes(
        unitType!
      ) && (
        <>
          <RentDivider />
          <AuthorizeProfesionnalActivity unitId={unitId} />
        </>
      )}
    </Grid>
  );
};

export default RentFields;
