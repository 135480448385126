import { Divider, Grid, Typography } from '@material-ui/core';
import {
  Colors,
  getSendingSourceStatusColor,
  getSendingSourceStatusName,
  SendingSourceStatus,
  SendingSourceVerifiedType,
} from '@rentguru/commons-utils';
import { toUpper } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { ActionButton, ContentHeader , ColoredBox } from '@up2rent/ui';
import { useSendingSources } from 'src/hooks/SendingSourceContext';
import DnsRecordElement from './DnsRecord';

interface VerifiedDomainDialogProps {
  id: string;
  closeAction: () => void;
  showDns?: boolean;
}

const VerifiedDomainDialog: React.FC<VerifiedDomainDialogProps> = ({ id, closeAction, showDns = false }) => {
  const { formatMessage } = useIntl();
  const { getSendingSource } = useSendingSources();

  const sendingSource = getSendingSource(id)!;
  const statusText = getSendingSourceStatusName(
    sendingSource.status as SendingSourceStatus,
    sendingSource.verifiedType as SendingSourceVerifiedType,
    formatMessage
  );
  const statusColor = getSendingSourceStatusColor(sendingSource.status as SendingSourceStatus);
  return (
    <Grid style={{ width: 600 }}>
      <ContentHeader title={formatMessage({ id: 'settings.sendingSources.successTitle' })}>
        <ColoredBox boxColor={statusColor}>{statusText}</ColoredBox>
      </ContentHeader>

      <Divider style={{ marginBottom: 20 }} />
      {!showDns && (
        <Grid style={{ marginLeft: 30, marginRight: 30 }}>
          <Typography>
            {formatMessage({ id: 'settings.sendingSources.successInfo1' })}
            <b>{sendingSource.name}</b>
            {formatMessage({ id: 'settings.sendingSources.successInfo2' })}
          </Typography>
          <Typography style={{ fontWeight: 'bold' }}>
            {formatMessage(
              {
                id: 'settings.sendingSources.successInfo3',
              },
              { emailAddress: sendingSource.emailAddress }
            )}
          </Typography>
        </Grid>
      )}
      {showDns &&
        sendingSource.dnsRecords?.map((dnsRecord) => (
          <DnsRecordElement domainName={sendingSource.name} dnsRecord={dnsRecord} key={dnsRecord.value} />
        ))}

      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      <Grid style={{ float: 'right' }}>
        <ActionButton
          onClick={closeAction}
          style={{
            background: 'none',
            color: Colors.DARK_SLATE_GREY,
            marginRight: 30,
            marginBottom: 20,
          }}
        >
          {toUpper(
            formatMessage({
              id: 'close',
            })
          )}
        </ActionButton>
      </Grid>
    </Grid>
  );
};

export default VerifiedDomainDialog;
