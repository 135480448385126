import React, { useEffect } from 'react';
import { FormikProps, useFormikContext } from 'formik';
import PhoneNumbersFields, { PhoneNumbersFieldsFormValues } from '../FormField/PhoneNumbersFields';
import { PhoneNumber } from '@rentguru/commons-utils';
import { isEmpty } from 'lodash';
import isNil from 'lodash/isNil';

interface PhoneNumbersFormProps {
  phoneNumbersList?: PhoneNumber[];
}

const PhoneNumbersForm: React.FC<PhoneNumbersFormProps> = ({ phoneNumbersList }) => {
  const { values, setFieldValue }: FormikProps<PhoneNumbersFieldsFormValues> = useFormikContext();

  const firstRenderRef = React.useRef<boolean>(true);

  useEffect(() => {
    if (
      firstRenderRef.current &&
      isEmpty(values.phoneNumbers) &&
      !isNil(phoneNumbersList) &&
      !isEmpty(phoneNumbersList)
    ) {
      setFieldValue('phoneNumbers', [...phoneNumbersList]);
      firstRenderRef.current = false;
    }
  }, [values.phoneNumbers, phoneNumbersList, setFieldValue, firstRenderRef]);

  return <PhoneNumbersFields marginLeftRight={0} />;
};

export default PhoneNumbersForm;
