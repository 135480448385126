import { ListItem, ListItemText } from '@material-ui/core';
import { addMonths, isEqual, startOfDay } from 'date-fns';
import { isNil } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { FilterFromTo } from 'src/hooks/FiltersContext';
import { FieldFilterSelector } from '../../ui/FieldFilterSelector';
import StyledRadio from '../../ui/StyledRadio';

interface LeasesFiltersEndOfLeaseProps {
  filterName: string;
  labelId: string;
  items: { fromMonth: number; toMonth: number; labelId: string }[];
  value: FilterFromTo | null;
  onChange: (from: Date | null, to: Date | null) => void;
  onReset: (filterName: string, fieldName: string) => void;
}
const LeasesFiltersEndOfLease: React.FC<LeasesFiltersEndOfLeaseProps> = ({
  value,
  onChange,
  onReset,
  filterName,
  items,
  labelId,
}) => {
  const { formatMessage } = useIntl();

  const today = startOfDay(new Date());

  // Selected?
  const endOfLeaseFilterSelected = !isNil(value);

  return (
    <FieldFilterSelector
      label={formatMessage({ id: labelId })}
      showResetButton={true}
      showSearchField={false}
      filterName={filterName}
      fieldName={filterName}
      textReset={formatMessage({ id: 'reinitFilter' })}
      textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
      textSearchField={formatMessage({ id: 'search' })}
      textSelectAll={formatMessage({ id: 'selectAll' })}
      onChange={() => {}}
      onReset={onReset}
      minSelectorWidth={1500}
      selected={endOfLeaseFilterSelected}
    >
      {items.map((item) => {
        const firstDate = addMonths(today, item.fromMonth);
        const lastDate = addMonths(today, item.toMonth);
        const selected = value
          ? isEqual(new Date(value.from), firstDate) && isEqual(new Date(value.to), lastDate)
          : false;
        return (
          <ListItem
            key={item.labelId}
            onClick={() => {
              onChange(firstDate, lastDate);
            }}
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            <StyledRadio checked={selected} style={{ marginTop: 0 }} tabIndex={-1} />
            <ListItemText primary={formatMessage({ id: item.labelId })} />
          </ListItem>
        );
      })}
    </FieldFilterSelector>
  );
};

export default LeasesFiltersEndOfLease;
