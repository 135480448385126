import {
  CommunicationSettings,
  CommunicationSettingsProfileReferent,
  CommunicationType,
  areCommunicationSettingsDefault,
  getDefaultSetting,
} from '@rentguru/commons-utils';
import * as Yup from 'yup';
import { BaseCommunicationSettingsFormValue, BasicSettingsSchema } from './CommunicationSettingsSharedUtils';

export enum OwnerSettableCommunicationType {
  OWNER_STATEMENT = CommunicationType.OWNER_STATEMENT,
}

export interface OwnerCommunicationSettingsFormValues {
  defaultName: string;
  [OwnerSettableCommunicationType.OWNER_STATEMENT]: BaseCommunicationSettingsFormValue;
}

export const getOwnerCommunicationSettingsFromValues = (values: OwnerCommunicationSettingsFormValues) => {
  const newOwnerSettings: CommunicationSettings[] = [];

  const ownerStatementSettings: CommunicationSettings = {
    communicationType: CommunicationType.OWNER_STATEMENT,
    ...values[OwnerSettableCommunicationType.OWNER_STATEMENT],
  };
  if (!areCommunicationSettingsDefault(ownerStatementSettings, CommunicationSettingsProfileReferent.OWNER)) {
    newOwnerSettings.push(ownerStatementSettings);
  }

  return newOwnerSettings;
};

export const OwnerCommunicationSettingsSchema = Yup.object().shape({
  [OwnerSettableCommunicationType.OWNER_STATEMENT]: BasicSettingsSchema,
});

const ownerStatementDefaultSettings = getDefaultSetting(
  CommunicationSettingsProfileReferent.OWNER,
  CommunicationType.OWNER_STATEMENT
);

export const ownerDefaultSettings = {
  [OwnerSettableCommunicationType.OWNER_STATEMENT]: {
    sendingRule: ownerStatementDefaultSettings.sendingRule,
    recipients: ownerStatementDefaultSettings.recipients,
    recipientsInBcc: ownerStatementDefaultSettings.recipientsInBcc,
    recipientsInCopy: ownerStatementDefaultSettings.recipientsInCopy,
    channel: ownerStatementDefaultSettings.channel,
  },
};
