import { Skeleton } from '@material-ui/lab';
import React from 'react';

const TodosLoading = () => {
  return (
    <>
      <Skeleton style={{ width: '100%', height: 75, margin: 0, padding: 0 }} variant="text" />
      <Skeleton style={{ width: '90%', height: 20, margin: 0, padding: 0, marginLeft: '5%' }} variant="text" />
      <Skeleton style={{ width: '90%', height: 20, margin: 0, padding: 0, marginLeft: '5%' }} variant="text" />
      <Skeleton style={{ width: '90%', height: 20, margin: 0, padding: 0, marginLeft: '5%' }} variant="text" />
      <Skeleton style={{ width: '90%', height: 20, margin: 0, padding: 0, marginLeft: '5%' }} variant="text" />
      <Skeleton style={{ width: '90%', height: 20, margin: 0, padding: 0, marginLeft: '5%' }} variant="text" />
    </>
  );
};

export default TodosLoading;
