import { Colors } from '@rentguru/commons-utils';
import { ReactComponent as MoreHoriz } from 'src/icons/more.svg';

import { CustomIconButton, CustomIconButtonProps } from '@up2rent/ui';
import { stopPropagation } from 'src/components/Dashboard/Dashboard';
import { forwardRef } from 'react';

// eslint-disable-next-line react/display-name
const MoreHorizontalIconButton = forwardRef<HTMLButtonElement, CustomIconButtonProps>(
  ({ style, iconStyle, ...props }, ref) => (
    <CustomIconButton
      ref={ref}
      onMouseDown={stopPropagation}
      Icon={MoreHoriz}
      iconStyle={{ fill: Colors.BLUEY, ...iconStyle }}
      style={style}
      {...props}
    />
  )
);

export default MoreHorizontalIconButton;
