import React from 'react';
import { Team, Colors } from '@rentguru/commons-utils';
import * as Yup from 'yup';
import { FormikHelpers, Formik, Form } from 'formik';
import isNil from 'lodash/isNil';
import { useIntl } from 'react-intl';
import TextDetailEditable from '../../ui/TextDetailEditable';
import { useTeams } from '../../../hooks/TeamsContext';
import { Divider } from '@material-ui/core';
import { ContentHeader, LoaderButton , ActionButton } from '@up2rent/ui';
import toUpper from 'lodash/toUpper';
import TeamSelectorInput from '../../ui/Forms/FormSection/TeamSelectorInput';

interface AddTeamFormValues {
  name: string;
  parentId?: string;
}

const AddTeamSchema = Yup.object()
  .shape({
    name: Yup.string().required(),
    parentId: Yup.string().required(),
  })
  .required();

interface AddTeamFormProps {
  onClose?: (team?: Team) => void;
}

const initialValues: AddTeamFormValues = {
  name: '',
  parentId: undefined,
};

const AddTeamForm: React.FC<AddTeamFormProps> = ({ onClose }) => {
  const { formatMessage } = useIntl();
  const { createTeam } = useTeams();

  const handleCreate = async (
    values: AddTeamFormValues,
    { setSubmitting, setStatus }: FormikHelpers<AddTeamFormValues>
  ) => {
    const team = await createTeam({
      ...values,
    });

    setStatus(true);
    setSubmitting(false);
    if (!isNil(onClose)) {
      onClose(team);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddTeamSchema}
      onSubmit={handleCreate}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ errors, touched, status, isSubmitting }) => (
        <Form>
          <ContentHeader title={formatMessage({ id: 'settings.addTeam.nenu' })} />
          <div style={{ marginLeft: 30, marginRight: 30 }}>
            <div style={{ alignItems: 'baseline' }}>
              <TextDetailEditable
                title={formatMessage({ id: 'settings.addTeam.name' })}
                editMode={true}
                name="name"
                type="text"
                style={{ width: 280, maxWidth: 'none' }}
                error={Boolean(errors.name && touched.name)}
              />
            </div>
            <div style={{ alignItems: 'baseline' }}>
              <TeamSelectorInput />
            </div>
          </div>
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          <div
            style={{
              marginRight: 30,
              marginBottom: 20,
              display: 'flex',
              float: 'right',
              alignItems: 'center',
            }}
          >
            <ActionButton
              onClick={() => {
                if (!isNil(onClose)) {
                  onClose();
                }
              }}
              style={{
                background: 'none',
                color: Colors.DARK_SLATE_GREY,
                marginRight: 20,
              }}
            >
              {toUpper(
                formatMessage({
                  id: 'cancel',
                })
              )}
            </ActionButton>
            <LoaderButton loading={isSubmitting} success={status}>
              {formatMessage({
                id: 'save',
              })}
            </LoaderButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddTeamForm;
