import { Avatar, Grid, Typography, makeStyles } from '@material-ui/core';
import Dialog from 'src/components/ui/Dialog';
import { useFormikContext } from 'formik';
import isNil from 'lodash/isNil';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { CustomInputItemType } from '../ComboBox/FullCustomComboBox';
import CustomInputComboBox, { AdditionalFormikProps } from '../ComboBox/FormikFullCustomComboBox';
import { getSafeValueInObject } from 'src/utils/object';
import isEmpty from 'lodash/isEmpty';
import {
  getContactNameFromObject,
  contactMostImportantType,
  getFirstLettersUppercase,
  stringToColor,
  Colors,
  Contact,
  ContactType,
  ContactStatus,
  isNilOrEmpty,
} from '@rentguru/commons-utils';
import EditContact from 'src/components/Contacts/EditContact';
import AddContact from 'src/components/Contacts/AddContact';
import EditOrAddIconButton from './utils/EditOrAddIconButton';

const useStyles = makeStyles({
  infoContact: {
    color: Colors.BLUE_GREY,
    fontSize: '9.5px',
    letterSpacing: '0.6px',
  },
});
interface SimpleContactSelectorProps extends Pick<AdditionalFormikProps, 'onChangeObserver'> {
  contacts: (Contact & { confidence?: number | null })[];
  defaultValue?: string;
  type?: ContactType[];
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  fieldName: string;
  fieldLabel: string;
  inputWidth?: number;
  paperClass?: string;
  disabled?: boolean;
  dataTest?: string;
  addItemButton?: boolean;
  groupBy?: 'name' | 'type';
  resultType?: 'string' | 'array';
  stickOnRightSide?: boolean;
  includeOcr?: boolean;

  onEndAdornmentClick?: () => void;
}

const SimpleContactSelector: React.FC<SimpleContactSelectorProps> = ({
  contacts,
  defaultValue,
  type,
  style,
  inputStyle,
  fieldName,
  fieldLabel,
  inputWidth = 560,
  disabled = false,
  dataTest = 'contactSelector',
  groupBy,
  addItemButton = true,
  resultType = 'string',
  stickOnRightSide = false,
  includeOcr = false,
  onEndAdornmentClick,
  ...rest
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [dialogAddContactOpen, setDialogAddContactOpen] = useState<boolean>(false);
  const [dialogEditContactId, setDialogEditContactId] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { values, errors, setFieldValue } = useFormikContext<any>();

  const handleAddContactOpen = () => {
    setDialogAddContactOpen(true);
  };

  const handleAddContactClose = () => {
    setDialogAddContactOpen(false);
  };

  const handleEditContactOpen = (id: string) => {
    setDialogEditContactId(id);
  };

  const handleEditContactClose = () => {
    setDialogEditContactId('');
  };

  const contactToCustomComboItem = (contact: Contact & { confidence?: number | null }) => {
    const name = getContactNameFromObject(contact);
    const color = stringToColor(name);
    return {
      name,
      color,
      value: contact.id,
      type: formatMessage({ id: `contact.type.${contactMostImportantType(contact).toLowerCase()}` }),
      status: contact.status === ContactStatus.DRAFT_OCR ? formatMessage({ id: `contact.status.draftOCR` }) : undefined,
      confidence: contact.confidence
        ? `${formatMessage({ id: 'contact.confidence' })}: ${contact.confidence}%`
        : undefined,
    };
  };
  const defaultContact = !isNil(defaultValue) ? contacts.find((c) => c.id === defaultValue) : undefined;
  const emptyContact = !isNil(defaultContact)
    ? contactToCustomComboItem(defaultContact)
    : { name: '', value: null, color: null, type: '' };
  const contactIdSelected: string = getSafeValueInObject(values, fieldName);
  const contactSelected = !isNil(contactIdSelected) ? contacts.find((c) => c.id === contactIdSelected) : undefined;
  let options = contacts.map((c) => contactToCustomComboItem(c));
  if (!isNil(groupBy)) {
    options = options.sort((option1, option2) => {
      if (groupBy === 'type') {
        // Custom sort for 'type' groupBy => Client is always on top
        if (option1.type === formatMessage({ id: `contact.type.client` })) return -1;
        if (option2.type === formatMessage({ id: `contact.type.client` })) return 1;
      }
      return option1[groupBy] > option2[groupBy] ? 1 : -1;
    });
  }

  if (includeOcr) {
    options = options.sort((option1, option2) => {
      if (option1.status && option1.status === formatMessage({ id: `contact.status.draftOCR` })) return -1;
      if (option2.status && option2.status === formatMessage({ id: `contact.status.draftOCR` })) return 1;
      return option1.name > option2.name ? 1 : -1;
    });
  }

  return (
    <div
      style={{
        alignItems: 'baseline',
        display: 'flex',
        ...style,
      }}
    >
      <CustomInputComboBox
        name={fieldName}
        value={!isNilOrEmpty(contactSelected) ? contactToCustomComboItem(contactSelected) : emptyContact}
        label={fieldLabel}
        options={options}
        fieldToReturn="value"
        dataTest={dataTest}
        emptyValue={emptyContact}
        inputStyle={{ width: inputWidth, ...inputStyle }}
        groupBy={groupBy}
        renderOption={(value: CustomInputItemType) => {
          return (
            <Grid
              container
              alignContent="space-between"
              alignItems="center"
              style={{ marginBottom: '5px', marginTop: '5px' }}
            >
              <Grid
                item
                xs={value.status ? (value.confidence ? 5 : 7) : value.confidence ? 7 : 9}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Avatar
                  style={{
                    color: `${value.color}`,
                    backgroundColor: 'rgba(0,0,0,0)',
                    border: `1.5px ${value.color} solid`,
                    fontSize: '10px',
                    fontWeight: 'bold',
                    width: 20,
                    height: 20,
                    marginRight: 10,
                  }}
                >
                  {getFirstLettersUppercase(value.name)}
                </Avatar>
                {value.name}
              </Grid>
              {value.status && (
                <Grid item xs={2} style={{ textAlign: 'left' }}>
                  <Typography className={classes.infoContact}>{value.status}</Typography>
                </Grid>
              )}
              {value.confidence && (
                <Grid item xs={2} style={{ textAlign: 'left' }}>
                  <Typography className={classes.infoContact}>{value.confidence}</Typography>
                </Grid>
              )}
              <Grid item xs={2} style={{ textAlign: 'right' }}>
                <Typography className={classes.infoContact}>{value.type}</Typography>
              </Grid>
            </Grid>
          );
        }}
        renderInput={(value: CustomInputItemType) => {
          return (
            <>
              <Avatar
                style={{
                  color: `${value.color}`,
                  backgroundColor: 'rgba(0,0,0,0)',
                  border: `1.5px ${value.color} solid`,
                  fontSize: '8px',
                  fontWeight: 'bold',
                  width: 15,
                  height: 15,
                  marginRight: 10,
                }}
              >
                {getFirstLettersUppercase(value.name)}
              </Avatar>
              <Typography
                style={{
                  fontSize: 12,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  maxWidth: `calc(${inputWidth}px - 85px)`,
                }}
              >
                {value.name}
              </Typography>
            </>
          );
        }}
        inputAdornment={
          addItemButton && (
            <EditOrAddIconButton
              disabled={disabled}
              onClick={() => {
                if (!isNil(contactSelected)) {
                  handleEditContactOpen(contactSelected.id);
                } else {
                  handleAddContactOpen();
                }
                if (!isNil(onEndAdornmentClick)) {
                  onEndAdornmentClick();
                }
              }}
              isSelected={!isNil(contactSelected)}
            />
          )
        }
        stringToCompare={(value: CustomInputItemType) => value.name}
        error={Boolean(errors && getSafeValueInObject(errors, fieldName))}
        noOptionsText={formatMessage({ id: 'comboBox.noContacts2' })}
        disabled={disabled}
        {...rest}
      />
      <Dialog
        open={dialogAddContactOpen}
        onClose={handleAddContactClose}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{
          ...(stickOnRightSide && {
            style: {
              left: 'calc(50% - 350px)',
            },
          }),
        }}
      >
        <AddContact
          afterSubmit={(contact: Contact) => {
            if (!isNil(contact)) {
              if (resultType === 'string') {
                setFieldValue(fieldName, contact.id);
              } else {
                setFieldValue(fieldName, [contact.id] as string[]);
              }
            }
            handleAddContactClose();
          }}
          type={type}
        />
      </Dialog>
      <Dialog
        open={!isEmpty(dialogEditContactId)}
        onClose={handleEditContactClose}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{
          ...(stickOnRightSide && {
            style: {
              left: 'calc(50% - 350px)',
            },
          }),
        }}
      >
        <EditContact
          afterSubmit={(contact?: Contact) => {
            if (!isNil(contact)) {
              if (resultType === 'string') {
                setFieldValue(fieldName, contact.id);
              } else {
                setFieldValue(fieldName, [contact.id] as string[]);
              }
            }
            handleEditContactClose();
          }}
          id={dialogEditContactId}
        />
      </Dialog>
    </div>
  );
};

export default SimpleContactSelector;
