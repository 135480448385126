import React from 'react';
import { useFormikContext, FormikProps } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import TextDetailEditable from '../../TextDetailEditable';
import { isValidPositiveNumber } from 'src/utils/formutils';
import { Colors, UnitType } from '@rentguru/commons-utils';
import { isEmpty } from 'lodash';

export interface UnitSurfaceFormValues {
  unit: {
    surface?: number;
  };
}

export const isSurfaceValid = (surface?: number | null, unitType?: UnitType | null) => {
  if (!unitType || isEmpty(surface) || surface === 0) {
    return true;
  }

  return isValidPositiveNumber(surface);
};

export const SurfaceField = Yup.number()
  .transform((value, original) => (original === '' ? undefined : value))
  .nullable()
  // eslint-disable-next-line func-names
  .test(function (value) {
    const parent = this.parent;
    const type = parent.unit?.type ?? parent.type;
    return isSurfaceValid(value, type);
  });

export const SurfaceSchema = Yup.object().shape({ surface: SurfaceField });

export const UnitSurfaceSchema = Yup.object()
  .shape({
    unit: Yup.object().shape({ surface: SurfaceField }),
  })
  .required();

export const useStyles = makeStyles({
  label: {
    color: Colors.LIGHT_BLUE_GREY,
  },
  underline: {
    '&:before': {
      borderBottom: `2px solid ${Colors.TOWER_GREY}`,
    },
  },
  root: {
    '&$disabled:before': {
      borderBottomStyle: 'hidden',
    },
    '&$disabled': {
      backgroundColor: Colors.PORCELAIN_GREY_1,
    },
  },
  disabled: {
    backgroundColor: Colors.PORCELAIN_GREY_1,
  },
  adornmentRoot: {
    '&$disabled': {
      backgroundColor: Colors.PORCELAIN_GREY_1,
    },
  },
});

const UnitSurfaceFields: React.FC = () => {
  const { errors, touched }: FormikProps<UnitSurfaceFormValues> = useFormikContext();
  const { formatMessage } = useIntl();

  return (
    <div style={{ alignItems: 'baseline' }}>
      <TextDetailEditable
        title={formatMessage({ id: 'lease.addLease.totalUnit' })}
        editMode={true}
        name="unit.surface"
        type="number"
        endAdornment="m²"
        style={{ width: 580, maxWidth: 'none' }}
        error={Boolean(errors.unit && errors.unit.surface && touched.unit && touched.unit.surface)}
      />
    </div>
  );
};

export default UnitSurfaceFields;
