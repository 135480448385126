import { Grid } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';

interface ScrollableComponentProps {
  datas: React.ReactNode[];
  scrollPercentageThreshold: number;
  scrollableInitialLimit: number;
}

const ScrollableComponent: React.FC<ScrollableComponentProps> = ({
  datas,
  scrollPercentageThreshold,
  scrollableInitialLimit,
}) => {
  const [listItemLimit, setListItemLimit] = useState(Math.min(scrollableInitialLimit, datas.length));

  const handleScroll = useCallback(
    (event: Event) => {
      const eventTarget = event.target as HTMLElement;
      const verticalScrollPosition = eventTarget.scrollTop;
      const scrollHeight = eventTarget.scrollHeight;
      const scrollPercentage = (verticalScrollPosition / scrollHeight) * 100;
      if (scrollPercentage > scrollPercentageThreshold) {
        setListItemLimit((prevLimit) => Math.min(datas.length, prevLimit + scrollableInitialLimit));
      }
    },
    [datas.length, scrollPercentageThreshold, scrollableInitialLimit]
  );

  useEffect(() => {
    setListItemLimit(Math.min(scrollableInitialLimit, datas.length));
  }, [datas, scrollableInitialLimit]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);

  const slicedChildren = datas.slice(0, listItemLimit);

  return <Grid>{slicedChildren}</Grid>;
};

export default ScrollableComponent;
