/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { ActionButton, ElevatedPaper, ContentHeader } from '@up2rent/ui';
import { ReactComponent as Info } from 'src/icons/info.svg';
import IconnedRemark from 'src/components/ui/IconnedRemark';
import DomainsTable from './SendingSource/DomainsTable';
import { Divider, Grid, Typography } from '@material-ui/core';
import AddDomainDialog from './SendingSource/AddDomainDialog';
import DnsDialog from './SendingSource/DnsDialog';
import VerifyDomainDialog from './SendingSource/VerifyDomainDialog';
import ErrorDomainDialog from './SendingSource/ErrorDomainDialog';
import VerifiedDomainDialog from './SendingSource/VerifiedDomainDialog';
import DeleteDomainDialog from './SendingSource/DeleteDomainDialog';
import EditDomainDialog from './SendingSource/EditDomainDialog';
import { DEFAULT_SENDING_EMAIL, SendingSourceErrorType } from '@rentguru/commons-utils';
import MakeDefaultDialog from './SendingSource/MakeDefaultDialog';
import ResetDialog from './SendingSource/ResetDialog';
import Dialog from 'src/components/ui/Dialog';

export interface SelectedDomainAction {
  action: 'EDIT' | 'DELETE' | 'VERIFY' | 'DNS' | 'ERROR' | 'VERIFIED' | 'DEFAULT' | 'RESET';
  id?: string;
  loadingBeforeDisplay?: boolean;
}

interface AddDomainAction {
  open: boolean;
  defaultEmailAddress?: string;
  errorMessage?: SendingSourceErrorType;
}

const SendingSourceSettings = () => {
  const { formatMessage } = useIntl();
  const { loading, settingsAutomationWrite } = usePermissions();
  const [addDomainDialogOpen, setAddDomainDialogOpen] = useState<AddDomainAction>({ open: false });
  const [selectedDomainAction, setSelectedDomainAction] = useState<SelectedDomainAction | null>(null);
  const editDomainDialogOpen = selectedDomainAction?.action === 'EDIT';
  const deleteDomainDialogOpen = selectedDomainAction?.action === 'DELETE';
  const verifyDomainDialogOpen = selectedDomainAction?.action === 'VERIFY';
  const errorDomainDialogOpen = selectedDomainAction?.action === 'ERROR';
  const verifiedDomainDialogOpen = selectedDomainAction?.action === 'VERIFIED';
  const makeDefaultDialogOpen = selectedDomainAction?.action === 'DEFAULT';
  const resetDialogOpen = selectedDomainAction?.action === 'RESET';
  const dnsDialogOpen = selectedDomainAction?.action === 'DNS';

  const closeAddDialog = () => {
    setAddDomainDialogOpen({ open: false });
  };

  const openDnsDialog = (id: string) => {
    setSelectedDomainAction({ id, action: 'DNS', loadingBeforeDisplay: true });
  };

  const openVerifyDomainDialog = (id: string) => {
    if (id) {
      setSelectedDomainAction({ id, action: 'VERIFY', loadingBeforeDisplay: true });
    }
  };

  const closeSelectedDomainActionDialog = (
    nextAction?: 'RESET' | 'ADD',
    emailAddress?: string,
    errorMessage?: SendingSourceErrorType
  ) => {
    if (nextAction === 'RESET') {
      setSelectedDomainAction({ action: 'RESET' });
      return;
    }
    if (nextAction === 'ADD') {
      setSelectedDomainAction(null);
      setAddDomainDialogOpen({ open: true, defaultEmailAddress: emailAddress, errorMessage });
      return;
    }
    setSelectedDomainAction(null);
  };

  if (loading) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{formatMessage({ id: 'loading' })}</Typography>
      </ElevatedPaper>
    );
  }

  return (
    <>
      <ElevatedPaper style={{ margin: 20, maxWidth: 640, paddingBottom: 0 }}>
        <ContentHeader title={formatMessage({ id: 'settings.sendingSourceMenu' })}>
          {settingsAutomationWrite && (
            <ActionButton onClick={() => setAddDomainDialogOpen({ open: true })} style={{ textTransform: 'none' }}>
              {formatMessage({ id: 'settings.sendingSources.addDomain' })}
            </ActionButton>
          )}
        </ContentHeader>
        <IconnedRemark
          Icon={Info}
          message={
            <Grid style={{ textAlign: 'left' }}>
              <Typography>
                {formatMessage({ id: 'settings.sendingSources.domainInfo1' }, { emailAddress: DEFAULT_SENDING_EMAIL })}
              </Typography>
              <Typography style={{ marginTop: 20 }}>
                {formatMessage({ id: 'settings.sendingSources.domainInfo2' }, { emailAddress: DEFAULT_SENDING_EMAIL })}
              </Typography>{' '}
              <Typography style={{ marginTop: 20 }}>
                {formatMessage({ id: 'settings.sendingSources.domainInfo3' })}
              </Typography>
            </Grid>
          }
        />
        <Divider style={{ marginTop: 20 }} />

        <DomainsTable setSelectedDomainActions={setSelectedDomainAction} />
      </ElevatedPaper>

      {addDomainDialogOpen && (
        <Dialog open={addDomainDialogOpen.open} onClose={closeAddDialog} scroll="body">
          <AddDomainDialog
            afterSubmit={openDnsDialog}
            onClose={closeAddDialog}
            defaultEmailAddress={addDomainDialogOpen.defaultEmailAddress}
            errorMessage={addDomainDialogOpen.errorMessage}
          />
        </Dialog>
      )}

      {deleteDomainDialogOpen && (
        <Dialog open={deleteDomainDialogOpen} scroll="body">
          <DeleteDomainDialog id={selectedDomainAction?.id!} closeAction={closeSelectedDomainActionDialog} />
        </Dialog>
      )}

      {editDomainDialogOpen && (
        <Dialog open={editDomainDialogOpen} scroll="body">
          <EditDomainDialog id={selectedDomainAction?.id!} closeAction={closeSelectedDomainActionDialog} />
        </Dialog>
      )}

      {dnsDialogOpen && (
        <Dialog open={dnsDialogOpen} scroll="body">
          <DnsDialog
            id={selectedDomainAction?.id!}
            loadingBeforeDisplay={selectedDomainAction?.loadingBeforeDisplay}
            verifyAction={openVerifyDomainDialog}
            closeAction={closeSelectedDomainActionDialog}
          />
        </Dialog>
      )}

      {verifyDomainDialogOpen && (
        <Dialog open={verifyDomainDialogOpen} scroll="body">
          <VerifyDomainDialog id={selectedDomainAction?.id!} closeAction={closeSelectedDomainActionDialog} />
        </Dialog>
      )}

      {errorDomainDialogOpen && (
        <Dialog open={errorDomainDialogOpen} scroll="body">
          <ErrorDomainDialog id={selectedDomainAction?.id!} closeAction={closeSelectedDomainActionDialog} />
        </Dialog>
      )}

      {verifiedDomainDialogOpen && (
        <Dialog open={verifiedDomainDialogOpen} scroll="body">
          <VerifiedDomainDialog id={selectedDomainAction?.id!} closeAction={closeSelectedDomainActionDialog} showDns />
        </Dialog>
      )}

      {makeDefaultDialogOpen && (
        <Dialog open={makeDefaultDialogOpen} scroll="body">
          <MakeDefaultDialog id={selectedDomainAction?.id!} closeAction={closeSelectedDomainActionDialog} />
        </Dialog>
      )}

      {resetDialogOpen && (
        <Dialog open={resetDialogOpen} scroll="body">
          <ResetDialog closeAction={closeSelectedDomainActionDialog} />
        </Dialog>
      )}
    </>
  );
};

export default SendingSourceSettings;
