import { CommunicationSettingRecipient } from '@rentguru/commons-utils';
import MultipleContactTypeSelector from 'src/components/ui/MultipleContactTypeSelector';

interface CommunicationRecipientFormItemProps {
  recipients: CommunicationSettingRecipient[];
  setRecipients: (value: CommunicationSettingRecipient[]) => void;
  disabled: boolean;
}

const CommunicationRecipientFormItem: React.FC<CommunicationRecipientFormItemProps> = ({
  recipients,
  setRecipients,
  disabled,
}) => {
  const possibleContactTypes = [
    CommunicationSettingRecipient.GUARANTOR,
    CommunicationSettingRecipient.TENANT,
    CommunicationSettingRecipient.OWNER,
  ];
  return (
    <MultipleContactTypeSelector
      contactTypes={possibleContactTypes}
      selectedContactTypes={recipients}
      setValue={setRecipients}
      labelFormatMessageId="communications.detail.recipient.TO"
      disabled={disabled}
      inputWidth={464}
      noContactTypesChip
    />
  );
};

export default CommunicationRecipientFormItem;
