/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import { Divider } from '@material-ui/core';
import { UnitInventory, UnitInventoryStructureType, Colors } from '@rentguru/commons-utils';
import * as Yup from 'yup';
import { FormikHelpers, Formik, Form, useFormikContext } from 'formik';
import isNil from 'lodash/isNil';
import { useIntl } from 'react-intl';
import TextDetailEditable from '../../../ui/TextDetailEditable';
import { ContentHeader, LoaderButton , ActionButton } from '@up2rent/ui';
import toUpper from 'lodash/toUpper';
import UnitInventorySelectorField from '../../../ui/Forms/FormField/UnitInventorySelectorField';
import { ExtendedUnitInventory, ExtendedUnitInventoryStructure } from '../../../../hooks/UnitInventoryContext';
import { v4 as uuidv4 } from 'uuid';
import { InventoryOfFixturesFormik } from '../../EditInventory/EditInventory';
import Dialog from 'src/components/ui/Dialog';

export interface AddStructureFormValues {
  unitInventoryId: string;
  name: string;
}

export const AddStructureSchema = Yup.object()
  .shape({
    unitInventoryId: Yup.string().min(1).required(),
    name: Yup.string().min(1).required(),
  })
  .required();

interface AddStructureDialogProps {
  onClose?: () => void;
  open: boolean;
  unitInventoryId?: string;
  proposedUnitInventories: ExtendedUnitInventory[];
  structureType: UnitInventoryStructureType;
}
const AddStructureDialog: React.FC<AddStructureDialogProps> = ({
  open,
  onClose = () => {},
  unitInventoryId,
  proposedUnitInventories,
  structureType,
}) => {
  const { formatMessage } = useIntl();
  const { values: parentValues, setFieldValue: setParentFieldValue } = useFormikContext<InventoryOfFixturesFormik>();
  const handleCreate = async (
    values: AddStructureFormValues,
    { setSubmitting, setStatus }: FormikHelpers<AddStructureFormValues>
  ) => {
    const { unitInventoryId, name } = values;
    const newUnitInventories = parentValues.localUnitInventories.reduce(
      (acc: ExtendedUnitInventory[], unitInventory: ExtendedUnitInventory) => {
        if (unitInventory.id === unitInventoryId) {
          const newStructure = {
            id: `NEW-${uuidv4()}`,
            unitInventoryId,
            name,
            type: structureType,
            encodings: [],
            readId: '',
            clientId: '',
          };
          acc.push({
            ...unitInventory,
            structures: [...unitInventory.structures, newStructure as ExtendedUnitInventoryStructure],
          });
        } else {
          acc.push(unitInventory);
        }
        return acc;
      },
      []
    );
    setParentFieldValue('localUnitInventories', newUnitInventories);
    setStatus(true);
    setSubmitting(false);
    if (!isNil(onClose)) {
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose()} scroll="body" PaperProps={{ style: { borderRadius: 10 } }}>
      <Formik
        initialValues={{
          unitInventoryId: !isNil(unitInventoryId) ? unitInventoryId : '',
          name: '',
        }}
        validationSchema={AddStructureSchema}
        onSubmit={handleCreate}
      >
        {({ errors, touched, isSubmitting, status }) => (
          <Form>
            <ContentHeader
              title={formatMessage({
                id:
                  structureType === UnitInventoryStructureType.OTHER
                    ? 'leaseInventory.room.addStructure.title'
                    : 'leaseInventory.room.addStructure.equipment',
              })}
            />
            <div style={{ marginLeft: 30, marginRight: 30 }}>
              <UnitInventorySelectorField
                unitsInventory={proposedUnitInventories as unknown as UnitInventory[]}
                initialValue={!isNil(unitInventoryId) ? unitInventoryId : ''}
                fieldName="unitInventoryId"
                inputStyle={{ width: 280 }}
                required={true}
              />
              <div style={{ alignItems: 'baseline' }}>
                <TextDetailEditable
                  title={formatMessage({
                    id:
                      structureType === UnitInventoryStructureType.OTHER
                        ? 'leaseInventory.room.addStructure.structureName'
                        : 'leaseInventory.room.addStructure.equipmentName',
                  })}
                  editMode={true}
                  name="name"
                  type="text"
                  style={{ width: 280, maxWidth: 'none' }}
                  error={Boolean(errors.name && touched.name)}
                />
              </div>
            </div>

            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <div
              style={{
                marginBottom: 20,
                marginRight: 30,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <ActionButton
                onClick={() => onClose()}
                style={{ background: 'none', color: Colors.DARK_SLATE_GREY, marginRight: 20 }}
              >
                {toUpper(formatMessage({ id: 'cancel' }))}
              </ActionButton>
              <LoaderButton loading={isSubmitting} success={status}>
                {formatMessage({
                  id: 'save',
                })}
              </LoaderButton>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddStructureDialog;
