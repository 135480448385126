/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import NotificationBox from './NotificationBox';
import SlicedContent from './SlicedContent';
import { ReactComponent as ExternalIcon } from '../../../icons/external.svg';
import { Link } from 'react-router-dom';
import {
  LeaseAmountUpdateStatus,
  LeasePriceHistory,
  getContactNameFromObject,
  applyProRata,
  LEASE_CHARGE_PROVISION_ADJUSTMENT_KEY,
  Colors,
  ModelWithVersion,
} from '@rentguru/commons-utils';
import { MenuItemText } from '@up2rent/ui';
import { useLeases } from 'src/hooks/LeasesContext';
import { extendChargeAdjustment } from 'src/components/Accounting/TenantStatements/TenantStatements';
import { ActionMenuChargeInbox } from 'src/components/Accounting/TenantStatements/TenantStatementsToValidate/TenantStatementsToValidate';
import LeaseChargeAdjustmentRow from 'src/components/Accounting/TenantStatements/LeaseChargeAdjustments/LeaseChargeAdjustmentRow';
import { useLeasePriceHistories } from 'src/hooks/LeasePriceHistoriesContext';
import ConfirmLeaseAdjustmentDialog from 'src/components/Accounting/TenantStatements/LeaseChargeAdjustments/ConfirmLeaseAdjustmentDialog';
import { isNil } from 'lodash';
import LeaseChargeAdjustmentHeader from './BoxCustomHeaders/LeaseChargeAdjustmentHeader';
import SkipChargeAdjustmentDialog from 'src/components/Accounting/TenantStatements/LeaseChargeAdjustments/SkipChargeAdjustmentDialog';
import TodosLoading from './TodosLoading';
import { DashboardTodoProps } from './DashboardTodos';
import { stopPropagation } from 'src/components/Dashboard/Dashboard';

export const ROW_HEIGHT = 74;

const DashboardLeaseChargeAdjustment: React.FC<DashboardTodoProps> = ({ forceUpdateLayout }) => {
  const { formatMessage } = useIntl();
  const {
    leasePriceHistories,
    loading: leaseChargeAdjustmentsLoading,
    updateLeasePriceHistory,
  } = useLeasePriceHistories();
  const { getLease, leasesLoading } = useLeases();
  const [dialogDeleteOpen, setDialogDeleteOpen] = useState<string>('');
  const [validateDialogOpen, setValidateDialogOpen] = useState<string>('');
  const [actionMenu, setActionMenu] = useState<ActionMenuChargeInbox | null>(null);

  if (leaseChargeAdjustmentsLoading || leasesLoading) {
    return (
      <>
        <TodosLoading />
      </>
    );
  }

  const leaseChargeAdjustmentsTodosData = (leasePriceHistories ?? []).map((leaseChargeAdjustment) => {
    const extendedLeaseChargeAdjustment = extendChargeAdjustment(leaseChargeAdjustment, getLease);
    const unitName = extendedLeaseChargeAdjustment.amountDetails![0].unitId
      ? extendedLeaseChargeAdjustment.amountDetails![0].unitId
      : '';
    const tenantsNames = extendedLeaseChargeAdjustment.tenants.map((t) => getContactNameFromObject(t)).join(', ');

    const unitLease = extendedLeaseChargeAdjustment.amountDetails![0].unitId
      ? extendedLeaseChargeAdjustment.lease?.units?.find(
          (u) => u.unit && u.unit.id === extendedLeaseChargeAdjustment.amountDetails![0].unitId
        )
      : undefined;
    const currentAmount = unitLease && unitLease.monthlyCharges ? unitLease.monthlyCharges : 0;
    const adjustmentRatio =
      currentAmount !== 0 ? applyProRata(100, leaseChargeAdjustment.totalMonthlyCharges, currentAmount) - 100 : 0;
    return {
      ...extendedLeaseChargeAdjustment,
      tenantsNames,
      unitName,
      adjustmentRatio,
      currentMonthlyCharges: currentAmount,
    };
  });

  const leaseChargeAdjustmentToValidate =
    validateDialogOpen !== '' ? (leasePriceHistories ?? []).find((st) => st.id === validateDialogOpen) : undefined;
  const leaseChargeAdjustmentToDelete =
    dialogDeleteOpen !== '' ? (leasePriceHistories ?? []).find((st) => st.id === dialogDeleteOpen) : undefined;

  const leaseChargeAdjustmentsToDisplay = leaseChargeAdjustmentsTodosData
    .sort((l1, l2) => {
      return l1.applicationDate > l2.applicationDate ? 1 : -1;
    })
    .map((leaseChargeAdjustment) => {
      return <LeaseChargeAdjustmentRow key={leaseChargeAdjustment.id} {...leaseChargeAdjustment} isSelected={false} />;
    });

  const titleComponent = (
    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
      <Typography
        style={{
          flex: 1,
          textAlign: 'left',
          color: Colors.CLASSICAL_BLACK,
          fontFamily: 'Mulish',
          fontSize: '16px',
          fontWeight: 'bold',
          marginLeft: 20,
        }}
      >
        {`${formatMessage({ id: 'accounting.statement.provisionAdjustments' })} (${
          leaseChargeAdjustmentsToDisplay.length
        })`}
      </Typography>
      <IconButton
        size="small"
        onMouseDown={stopPropagation}
        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => e.stopPropagation()}
        component={Link}
        to="/accounting/tenantStatements/provisionsAdjustments"
        style={{ marginLeft: 10 }}
      >
        <ExternalIcon style={{ fill: Colors.DODGER_BLUE }} />
      </IconButton>
    </div>
  );

  return (
    <>
      <NotificationBox boxKey={LEASE_CHARGE_PROVISION_ADJUSTMENT_KEY} titleComponent={titleComponent}>
        <SlicedContent
          data={leaseChargeAdjustmentsToDisplay}
          header={<LeaseChargeAdjustmentHeader />}
          limit={3}
          rowHeight={ROW_HEIGHT}
          boxKey={LEASE_CHARGE_PROVISION_ADJUSTMENT_KEY}
          forceUpdateLayout={forceUpdateLayout}
        />
      </NotificationBox>
      {actionMenu && (
        <Menu
          id="action-menu"
          anchorEl={actionMenu.anchorEl}
          open={Boolean(actionMenu.anchorEl)}
          onClose={() => setActionMenu(null)}
        >
          <MenuItem
            onClick={() => {
              setDialogDeleteOpen(actionMenu.id);
              setActionMenu(null);
            }}
          >
            <MenuItemText primary={formatMessage({ id: 'delete' })} deleteOption />
          </MenuItem>
        </Menu>
      )}
      {leaseChargeAdjustmentToDelete && (
        <SkipChargeAdjustmentDialog
          open={!isNil(leaseChargeAdjustmentToDelete)}
          leaseChargeAdjustment={leaseChargeAdjustmentToDelete}
          updateLeaseChargeAdjustment={updateLeasePriceHistory}
          onClose={() => setDialogDeleteOpen('')}
        />
      )}
      {leaseChargeAdjustmentToValidate && (
        <ConfirmLeaseAdjustmentDialog
          open={!isNil(leaseChargeAdjustmentToValidate)}
          leaseChargeAdjustment={leaseChargeAdjustmentToValidate}
          valideAdjustment={async (leaseChargeAdjustmentToValidate: LeasePriceHistory) => {
            await updateLeasePriceHistory({
              id: leaseChargeAdjustmentToValidate.id,
              status: LeaseAmountUpdateStatus.ACCEPTED_PROCESSED_NEED_TO_APPLY,
              _version: (leaseChargeAdjustmentToValidate as ModelWithVersion<LeasePriceHistory>)._version,
            });
          }}
          onClose={() => setValidateDialogOpen('')}
        />
      )}
    </>
  );
};

export default DashboardLeaseChargeAdjustment;
