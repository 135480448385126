/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-param-last */
import React, { useContext, useEffect, useMemo } from 'react';
import {
  cleanInputCreate,
  EntityType,
  cleanInputUpdate,
  getReadId,
  getTableClientId,
  LeaseInventory,
  LeaseInventoryCheckedItem,
  LeaseInventoryEncoding,
  File as FileModel,
  InventoryCustomItem,
  InventoryItemType,
  SignatureDocument,
  LeaseInventoryType,
  syncLeaseInventories as syncQuery,
  getLeaseInventory as getQuery,
  createLeaseInventory as createMutation,
  updateLeaseInventory as updateMutation,
  deleteLeaseInventory as deleteMutation,
  CreateLeaseInventoryMutationVariables as CreateMutationVariables,
  DeleteLeaseInventoryMutationVariables as DeleteMutationVariables,
  CreateLeaseInventoryInput as CreateInput,
  Model,
  isNilOrEmpty,
  OnCreateLeaseInventorySubscription,
  OnUpdateLeaseInventorySubscription,
  OnDeleteLeaseInventorySubscription,
  onCreateLeaseInventory,
  onUpdateLeaseInventory,
  onDeleteLeaseInventory,
} from '@rentguru/commons-utils';
import { useUser } from './UserContext';
import { useFiles } from './FilesContext';
import { isEmpty } from 'lodash';
import isNil from 'lodash/isNil';
import { useUnitInventories } from './UnitInventoryContext';
import { usePermissions } from './utils/PermissionsContext';
import {
  deleteEntityWithFetchBefore,
  getFilterFieldNameForIndex,
  list,
  mutation,
  recordWasUpdated,
  useSubscriptions,
} from '@up2rent/fetch-utils';
import { InventoryCustomItemsContext, useInventoryCustomItems } from './InventoryCustomItemContext';
import { LeaseInventoryEncodingsContext, useLeaseInventoryEncodings } from './LeaseInventoryEncodingContext';
import { LeaseInventoryCheckedItemsContext, useLeaseInventoryCheckedItems } from './LeaseInventoryCheckedItemContext';
import { ContextLoaderAction, ContextLoaderStore, useContextLoader } from './ContextLoader';

const ENTITY_MODEL_NAME: Model = 'LeaseInventory';

export const classifyInventoryCheckedItems = (checkedItems: LeaseInventoryCheckedItem[]) => {
  return checkedItems.reduce(
    (
      acc: [LeaseInventoryCheckedItem[], LeaseInventoryCheckedItem[], LeaseInventoryCheckedItem[]],
      lici: LeaseInventoryCheckedItem
    ) => {
      if (lici.type === InventoryItemType.DEFECT) {
        acc[0].push(lici);
      } else if (lici.type === InventoryItemType.COLOR) {
        acc[1].push(lici);
      } else if (lici.type === InventoryItemType.MATERIAL) {
        acc[2].push(lici);
      } else if (isNil(lici.type) && lici.custom && !isNil(lici.customItem)) {
        const customItem = lici.customItem;
        if (customItem.type === InventoryItemType.DEFECT) {
          acc[0].push(lici);
        } else if (customItem.type === InventoryItemType.COLOR) {
          acc[1].push(lici);
        } else if (customItem.type === InventoryItemType.MATERIAL) {
          acc[2].push(lici);
        }
      }
      return acc;
    },
    [[], [], []]
  );
};
export interface LeaseInventoryContext {
  getLeaseInventory: (id: string) => LeaseInventory | undefined;
  createLeaseInventory: (input: Omit<LeaseInventory, 'id' | 'clientId' | 'readId'>) => Promise<LeaseInventory>;
  updateLeaseInventory: (original: LeaseInventory, updates: Partial<LeaseInventory>) => Promise<LeaseInventory>;
  deleteLeaseInventory: (id: string) => Promise<LeaseInventory | null>;
  deepDeleteLeaseInventory: (
    leaseInventory: LeaseInventory,
    pendingSignatureDocuments?: SignatureDocument[] | null,
    setSignatureResultToZero?: (signatureDocument: SignatureDocument) => Promise<SignatureDocument>
  ) => Promise<void>;
  createLeaseInventoryEncoding: LeaseInventoryEncodingsContext['createLeaseInventoryEncoding'];
  updateLeaseInventoryEncoding: LeaseInventoryEncodingsContext['updateLeaseInventoryEncoding'];
  deepDeleteLeaseInventoryEncoding: LeaseInventoryEncodingsContext['deepDeleteLeaseInventoryEncoding'];
  getCheckedItemsForLeaseInventoryEncoding: (leaseInventoryEncodingId: string) => Promise<LeaseInventoryCheckedItem[]>;
  createLeaseInventoryCheckedItem: LeaseInventoryCheckedItemsContext['createLeaseInventoryCheckedItem'];
  updateLeaseInventoryCheckedItem: LeaseInventoryCheckedItemsContext['updateLeaseInventoryCheckedItem'];
  deleteLeaseInventoryCheckedItem: LeaseInventoryCheckedItemsContext['deleteLeaseInventoryCheckedItem'];
  createInventoryCustomItem: InventoryCustomItemsContext['createInventoryCustomItem'];
  deleteInventoryCustomItem: InventoryCustomItemsContext['deleteInventoryCustomItem'];
  listCustomItems: () => Promise<InventoryCustomItem[]>;
  getLeaseInventoryByType: (leaseId: string, InventoryType: LeaseInventoryType) => LeaseInventory | undefined;
  setFetchLeaseInventory: () => void;
  leaseInventories: LeaseInventory[] | null;
  loading: boolean;
}

export type ActionLeaseInventory =
  | {
      type: 'SHOULD_FETCH_LEASE_INVENTORY' | 'IS_FETCHING_LEASE_INVENTORY';
    }
  | {
      type: 'ADD_LEASE_INVENTORY';
      payload: { leaseInventory: LeaseInventory };
    }
  | {
      type: 'FETCHED_LEASE_INVENTORY';
      payload: { leaseInventories: LeaseInventory[]; error?: string };
    }
  | {
      type: 'UPDATE_LEASE_INVENTORY';
      payload: { leaseInventory: LeaseInventory };
    }
  | {
      type: 'DELETE_LEASE_INVENTORY';
      payload: { id: string };
    };

export const leaseInventoryReducerDelegation = (
  state: ContextLoaderStore,
  action: ContextLoaderAction
): ContextLoaderStore => {
  switch (action.type) {
    case 'SHOULD_FETCH_LEASE_INVENTORY':
      if (state.LeaseInventory.loading || state.LeaseInventory.shouldFetch) {
        return state;
      }
      return {
        ...state,
        LeaseInventory: { ...state.LeaseInventory, shouldFetch: true },
      };
    case 'IS_FETCHING_LEASE_INVENTORY':
      if (state.LeaseInventory.loading) {
        return state;
      }
      return {
        ...state,
        LeaseInventory: { ...state.LeaseInventory, loading: true },
      };
    case 'FETCHED_LEASE_INVENTORY':
      return {
        ...state,
        LeaseInventory: {
          ...state.LeaseInventory,
          data: action.payload.leaseInventories,
          loading: false,
          shouldFetch: false,
          lastFetch: new Date(),
        },
      };
    case 'ADD_LEASE_INVENTORY':
      // Check if already present - If already added by this user or coming from another user
      if (state.LeaseInventory.data?.find((object) => object.id === action.payload.leaseInventory.id)) {
        return state;
      }

      return {
        ...state,
        LeaseInventory: {
          ...state.LeaseInventory,
          data: [...state.LeaseInventory.data, action.payload.leaseInventory],
        },
      };
    case 'UPDATE_LEASE_INVENTORY':
      // No data
      if (isNilOrEmpty(state.LeaseInventory.data)) {
        return state;
      }

      // Already present and same object
      const currentObject = state.LeaseInventory.data?.find((object) => object.id === action.payload.leaseInventory.id);
      if (!currentObject) {
        return {
          ...state,
          LeaseInventory: {
            ...state.LeaseInventory,
            data: [...state.LeaseInventory.data, action.payload.leaseInventory],
          },
        };
      }
      if (!recordWasUpdated(currentObject, action.payload.leaseInventory)) {
        return state;
      }

      // Update
      return {
        ...state,
        LeaseInventory: {
          ...state.LeaseInventory,
          data: state.LeaseInventory.data.map((object) => {
            if (object.id === action.payload.leaseInventory.id) {
              return action.payload.leaseInventory;
            }
            return object;
          }),
        },
      };
    case 'DELETE_LEASE_INVENTORY':
      if (isNilOrEmpty(state.LeaseInventory.data)) {
        return state;
      }
      return {
        ...state,
        LeaseInventory: {
          ...state.LeaseInventory,
          data: state.LeaseInventory.data.filter((object) => object.id !== action.payload.id),
        },
      };

    default:
      return state;
  }
};

const fetchLeaseInventories = async (clientId: string, additionalFilter?: object): Promise<LeaseInventory[]> => {
  return await list<LeaseInventory>(
    syncQuery,
    getFilterFieldNameForIndex('byClientId'),
    getTableClientId(clientId, ENTITY_MODEL_NAME),
    additionalFilter
  );
};

export const LeaseInventoryContext = React.createContext<LeaseInventoryContext | null>(null);

export const LeaseInventoryContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const {
    LeaseInventory: { data: leaseInventories, loading, shouldFetch },
    dispatch: contextDispatch,
  } = useContextLoader();
  const { clientId } = useUser();
  const { inventoryOfFixturesCreationDelete, furnituresInventoryCreationDelete } = usePermissions();
  const { getEncodingsOfLeaseInventory } = useUnitInventories();
  const { createLeaseInventoryEncoding, updateLeaseInventoryEncoding, deepDeleteLeaseInventoryEncoding } =
    useLeaseInventoryEncodings();
  const { deleteFile, fetchFiles } = useFiles();
  const { createInventoryCustomItem, deleteInventoryCustomItem, inventoryCustomItems } = useInventoryCustomItems();
  const {
    createLeaseInventoryCheckedItem,
    updateLeaseInventoryCheckedItem,
    deleteLeaseInventoryCheckedItem,
    leaseInventoryCheckedItems,
  } = useLeaseInventoryCheckedItems();

  const setFetchLeaseInventory = () => {
    contextDispatch({ type: 'SHOULD_FETCH_LEASE_INVENTORY' });
  };

  useEffect(() => {
    const fetchAndSet = async () => {
      contextDispatch({ type: 'IS_FETCHING_LEASE_INVENTORY' });
      const result = await fetchLeaseInventories(clientId!);
      contextDispatch({ type: 'FETCHED_LEASE_INVENTORY', payload: { leaseInventories: result } });
    };
    if (shouldFetch) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch]);

  useSubscriptions<
    OnCreateLeaseInventorySubscription,
    OnUpdateLeaseInventorySubscription,
    OnDeleteLeaseInventorySubscription
  >(
    onCreateLeaseInventory,
    onUpdateLeaseInventory,
    onDeleteLeaseInventory,
    (data) => {
      contextDispatch({
        type: 'ADD_LEASE_INVENTORY',
        payload: { leaseInventory: data.onCreateLeaseInventory as LeaseInventory },
      });
    },
    (data) => {
      contextDispatch({
        type: 'UPDATE_LEASE_INVENTORY',
        payload: { leaseInventory: data.onUpdateLeaseInventory as LeaseInventory },
      });
    },
    (data) => {
      const { id } = data.onDeleteLeaseInventory as LeaseInventory;
      contextDispatch({
        type: 'DELETE_LEASE_INVENTORY',
        payload: { id },
      });
    }
  );

  const createLeaseInventory = async (input: Omit<LeaseInventory, 'id' | 'clientId' | 'readId'>) => {
    const leaseInventory = await mutation<LeaseInventory, CreateMutationVariables>(createMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateInput),
        clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME),
        readId: getReadId(clientId!, ENTITY_MODEL_NAME),
      },
    });
    contextDispatch({ type: 'ADD_LEASE_INVENTORY', payload: { leaseInventory } });
    return leaseInventory;
  };

  const updateLeaseInventory = async (original: LeaseInventory, updates: Partial<LeaseInventory>) => {
    const result = await mutation<LeaseInventory>(updateMutation, {
      input: { id: original.id, _version: original._version, ...cleanInputUpdate(updates, false) },
    });
    contextDispatch({ type: 'UPDATE_LEASE_INVENTORY', payload: { leaseInventory: result } });
    return result;
  };

  const deleteLeaseInventory = async (id: string) => {
    const leaseInventory = getLeaseInventory(id);
    if (!leaseInventory) {
      return null;
    }
    if (!inventoryOfFixturesCreationDelete && !furnituresInventoryCreationDelete) {
      return leaseInventory;
    }

    await deleteEntityWithFetchBefore<Pick<LeaseInventory, 'id'>, DeleteMutationVariables>(
      { id: leaseInventory.id },
      getQuery,
      deleteMutation
    );

    contextDispatch({ type: 'DELETE_LEASE_INVENTORY', payload: { id: leaseInventory.id } });
    return leaseInventory;
  };

  const deepDeleteLeaseInventory = async (
    leaseInventory: LeaseInventory,
    pendingSignatureDocuments?: SignatureDocument[] | null,
    setSignatureResultToZero?: (signatureDocument: SignatureDocument) => Promise<SignatureDocument>
  ) => {
    if (!inventoryOfFixturesCreationDelete && !furnituresInventoryCreationDelete) {
      return;
    }
    const deletePromises: Promise<
      | LeaseInventoryEncoding
      | LeaseInventoryCheckedItem[]
      | LeaseInventory[]
      | FileModel
      | void
      | null
      | SignatureDocument
      | LeaseInventory
    >[] = [];
    const leaseInventoryContract = (await fetchFiles(EntityType.LEASE_INVENTORY, leaseInventory.id))[0];
    const leaseInventoryEncodings = getEncodingsOfLeaseInventory(leaseInventory.id);
    if (!isEmpty(leaseInventoryEncodings)) {
      for (const leaseInventoryEncoding of leaseInventoryEncodings) {
        const encodingsFiles = await fetchFiles(EntityType.ENCODING, leaseInventoryEncoding.id);
        deletePromises.push(deepDeleteLeaseInventoryEncoding(leaseInventoryEncoding, encodingsFiles));
      }
    }
    if (setSignatureResultToZero && pendingSignatureDocuments && pendingSignatureDocuments.length > 0) {
      pendingSignatureDocuments.forEach((document) => {
        deletePromises.push(setSignatureResultToZero(document));
      });
    }
    if (!isNil(leaseInventoryContract)) deletePromises.push(deleteFile(leaseInventoryContract));

    deletePromises.push(deleteLeaseInventory(leaseInventory.id));
    await Promise.all(deletePromises);
  };

  const getLeaseInventory = (id: string): LeaseInventory | undefined => {
    return (leaseInventories ?? []).find((li) => li.id === id);
  };

  const getCheckedItemsForLeaseInventoryEncoding = async (leaseInventoryEncodingId: string) => {
    return (leaseInventoryCheckedItems ?? []).filter(
      (leaseInventoryCheckedItem) => leaseInventoryCheckedItem.leaseInventoryEncodingId === leaseInventoryEncodingId
    );
  };

  const listCustomItems = async () => {
    return inventoryCustomItems ?? [];
  };

  const getLeaseInventoryByType = (leaseId: string, inventoryType: LeaseInventoryType) => {
    const inventoriesOfLease = (leaseInventories ?? []).filter((leaseInventory) => leaseInventory.leaseId === leaseId);
    return inventoriesOfLease.find((inventory) => inventory.inventoryType === inventoryType);
  };

  const values = useMemo(
    () => ({
      leaseInventories,
      loading,
      getLeaseInventory,
      createLeaseInventory,
      updateLeaseInventory,
      deleteLeaseInventory,
      deepDeleteLeaseInventory,
      createLeaseInventoryEncoding,
      updateLeaseInventoryEncoding,
      deepDeleteLeaseInventoryEncoding,
      getCheckedItemsForLeaseInventoryEncoding,
      createLeaseInventoryCheckedItem,
      updateLeaseInventoryCheckedItem,
      deleteLeaseInventoryCheckedItem,
      createInventoryCustomItem,
      deleteInventoryCustomItem,
      listCustomItems,
      getLeaseInventoryByType,
      setFetchLeaseInventory,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, leaseInventories]
  );

  return <LeaseInventoryContext.Provider value={values}>{children}</LeaseInventoryContext.Provider>;
};

export const useLeaseInventories = (): LeaseInventoryContext => {
  const context = useContext<LeaseInventoryContext | null>(LeaseInventoryContext);

  if (context === undefined) {
    throw new Error('`useUnits` hook must be used within a `LeaseInventoryContextProvider` component');
  }
  return context as LeaseInventoryContext;
};
