import { CommunicationSettingsProfileReferent } from '@rentguru/commons-utils';

import { AddLeaseFormValues } from 'src/components/Leases/AddLease/AddLeaseForm';
import * as Yup from 'yup';
import {
  CommunicationSettingsProfileFormValues,
  CommunicationSettingsProfileType,
  LeaseCommunicationSettingsProfileFormValues,
  LeaseCommunicationSettingsSchema,
  LeaseSettableCommunicationType,
  getLeaseCommunicationSettingsFromValues,
} from './LeaseCommunicationSettingsUtils';
import {
  OwnerCommunicationSettingsFormValues,
  OwnerCommunicationSettingsSchema,
  OwnerSettableCommunicationType,
  getOwnerCommunicationSettingsFromValues,
} from './OwnerCommunicationSettingsUtils';
import {
  getCommunicationSettingsProfile,
  useCommunicationSettingsProfiles,
} from 'src/hooks/CommunicationSettingsProfilesContext';

export enum OptionalSettings {
  DAYS_OVERDUE = 'daysOverdue',
  DAYS_BEFORE_DUE_DATE = 'daysBeforeDueDate',
}

export type SettableCommunicationType = LeaseSettableCommunicationType | OwnerSettableCommunicationType;

export type CommunicationSettingsFormValues =
  | LeaseCommunicationSettingsProfileFormValues
  | OwnerCommunicationSettingsFormValues;

export const getCommunicationSettingsFromValues = (
  values: CommunicationSettingsFormValues | CommunicationSettingsProfileFormValues | AddLeaseFormValues,
  referent: CommunicationSettingsProfileReferent
) => {
  if (referent === CommunicationSettingsProfileReferent.OWNER) {
    return getOwnerCommunicationSettingsFromValues(values as OwnerCommunicationSettingsFormValues);
  }
  return getLeaseCommunicationSettingsFromValues(values as LeaseCommunicationSettingsProfileFormValues);
};

const defaultProfileSchema = Yup.object().shape({ defaultName: Yup.string().min(1).required() });

const getCommunicationSettingsSchema = (referent: CommunicationSettingsProfileReferent) => {
  if (referent === CommunicationSettingsProfileReferent.OWNER) {
    return OwnerCommunicationSettingsSchema;
  }
  return LeaseCommunicationSettingsSchema;
};

export const getCommunicationSettingsProfileSchema = (
  referent: CommunicationSettingsProfileReferent,
  isDefault = true
) => {
  if (isDefault) {
    return defaultProfileSchema.concat(getCommunicationSettingsSchema(referent));
  }
  return getCommunicationSettingsSchema(referent);
};

export const useCommunicationSettingsProfileUtils = () => {
  const { createCommunicationSettingsProfile, deleteCommunicationSettingsProfile, updateCommunicationSettingsProfile } =
    useCommunicationSettingsProfiles();
  const updateEntityCommunicationSettingsProfile = async (
    values: AddLeaseFormValues | CommunicationSettingsProfileFormValues,
    oldCommunicationSettingsProfileId?: string
  ) => {
    if (values.communicationSettingsProfileType === CommunicationSettingsProfileType.DEFAULT) {
      if (
        oldCommunicationSettingsProfileId &&
        oldCommunicationSettingsProfileId !== values.communicationSettingsProfileId
      ) {
        const currentProfile = await getCommunicationSettingsProfile(oldCommunicationSettingsProfileId);
        if (!currentProfile.isDefaultSetting) {
          await deleteCommunicationSettingsProfile(currentProfile);
        }
      }
      return values.communicationSettingsProfileId;
    }
    if (oldCommunicationSettingsProfileId) {
      const currentProfile = await getCommunicationSettingsProfile(oldCommunicationSettingsProfileId);
      if (!currentProfile.isDefaultSetting) {
        const newSettings = getCommunicationSettingsFromValues(values, CommunicationSettingsProfileReferent.LEASE);
        const updatedProfile = await updateCommunicationSettingsProfile(currentProfile, {
          communicationsSettings: newSettings,
        });

        return updatedProfile.id;
      }
    }
    const customProfile = await createCommunicationSettingsProfile({
      communicationsSettings: getCommunicationSettingsFromValues(values, CommunicationSettingsProfileReferent.LEASE),
      isDefaultSetting: false,
      referent: CommunicationSettingsProfileReferent.LEASE,
    });
    return customProfile.id;
  };
  return { updateEntityCommunicationSettingsProfile };
};
