import React from 'react';
import { Typography } from '@material-ui/core';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { Colors, getCountryCodeFromDialCode, PhoneNumber } from '@rentguru/commons-utils';
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js';

interface TextDetailPhoneNumbersProps {
  title: string;
  phones: PhoneNumber[];
}
const TextDetailPhoneNumbers: React.FC<TextDetailPhoneNumbersProps> = ({ title, phones }) => {
  return (
    <>
      <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>{title}</Typography>
      {!isNil(phones) &&
        !isEmpty(phones) &&
        phones.map((phone) => {
          let parsedPhoneNumber;
          try {
            parsedPhoneNumber = parsePhoneNumber(
              `${phone.number}`,
              getCountryCodeFromDialCode(phone.countryCode) as CountryCode
            );
          } catch (err) {
            console.error(err);
          }
          return (
            <Typography
              key={`${phone.countryCode} ${phone.number}`}
              style={{ color: Colors.CLASSICAL_BLACK, fontSize: 16 }}
            >
              {parsedPhoneNumber ? parsedPhoneNumber.formatInternational() : `${phone.countryCode} ${phone.number}`}
            </Typography>
          );
        })}
    </>
  );
};

export default TextDetailPhoneNumbers;
