import { Grid, Typography } from '@material-ui/core';
import { FormikProps, useFormikContext } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { AddEditAgencyRateValues } from '../AddEditAgencyRateForm';
import TextDetailEditable from 'src/components/ui/TextDetailEditable';
import HelpIconPopover from 'src/components/ui/HelpIconPopover';
import { AgencyRateRepartitionType, Colors } from '@rentguru/commons-utils';
import { useSettings } from 'src/hooks/SettingsContext';
import { isNil } from 'lodash';

const AgencyRateMinimumFeesAndAmountIfVacantFields = () => {
  const { values, errors, touched }: FormikProps<AddEditAgencyRateValues> = useFormikContext();
  const { formatMessage } = useIntl();
  const { agencyFeesVat } = useSettings();

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography
          style={{
            fontSize: 14,
            fontWeight: 'bold',
            fontStyle: 'normal',
            letterSpacing: 0,
            color: Colors.CLASSICAL_BLACK,
          }}
        >
          {`${formatMessage({ id: 'agencyRates.detail.amountMinimumFees' })} ${
            values.repartitionType === AgencyRateRepartitionType.BY_UNIT
              ? `(${formatMessage({
                  id: 'agencyRates.detail.BY_UNIT',
                })})`
              : ''
          }`}
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextDetailEditable
            title={
              !isNil(agencyFeesVat)
                ? formatMessage({ id: 'agencyRates.detail.amountMinimumFeesVATExcl' })
                : formatMessage({ id: 'agencyRates.detail.amountMinimumFees' })
            }
            editMode={true}
            name="amountMinimumFees"
            type="number"
            style={{ width: 280, maxWidth: 'none' }}
            error={Boolean(errors.amountMinimumFees && touched.amountMinimumFees)}
            endAdornment="€"
          />
          <HelpIconPopover
            helperText={formatMessage({ id: 'agencyRates.detail.amountMinimumFeesHelper' })}
            paperStyle={{ maxWidth: 400 }}
            iconStyle={{ marginLeft: 10 }}
          />
        </div>
      </Grid>
      <Grid item xs={6}>
        <Typography
          style={{
            fontSize: 14,
            fontWeight: 'bold',
            fontStyle: 'normal',
            letterSpacing: 0,
            color: Colors.CLASSICAL_BLACK,
          }}
        >
          {formatMessage({ id: 'agencyRates.detail.monthlyVacancyAmount' })}
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextDetailEditable
            title={
              !isNil(agencyFeesVat)
                ? formatMessage({ id: 'agencyRates.detail.monthlyVacancyAmountVATExcl' })
                : formatMessage({ id: 'agencyRates.detail.amountIfVacantUnit' })
            }
            editMode={true}
            name="amountIfVacantUnit"
            type="number"
            style={{ width: 280, maxWidth: 'none' }}
            error={Boolean(errors.amountIfVacantUnit && touched.amountIfVacantUnit)}
            endAdornment="€"
          />
          <HelpIconPopover
            helperText={formatMessage({ id: 'agencyRates.detail.monthlyVacancyAmountHelper' })}
            paperStyle={{ maxWidth: 400 }}
            iconStyle={{ marginLeft: 10 }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default AgencyRateMinimumFeesAndAmountIfVacantFields;
