import React from 'react';
import CustomDatePicker, { CustomDatePickerProps } from './CustomDatePicker';
import { useFormikContext, FormikProps } from 'formik';
import { isNil } from 'lodash';
import { makeStyles } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';

const useLocalStyles = makeStyles({
  overLap: {
    backgroundColor: 'transparent',
    opacity: '1',
    height: '47px',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    pointerEvents: 'none',
    borderRadius: 6,
    overflow: 'hidden',
  },
  coloredBanner: {
    width: 10,
    height: 30,
    transform: 'rotate(45deg)',
    position: 'absolute',
    backgroundColor: Colors.TURQUOISE_BLUE,
    top: -14,
  },
});

type FormikDatePickerProps = Omit<CustomDatePickerProps, 'onChange'> & {
  type?: 'Date | null' | 'string';
  importance?: 'recommended' | 'mandatory' | 'normal';
  importanceBannerWidth?: number;
  id?: string;
  required?: boolean;
  helperText?: string;
  onDateChange?: (date: Date | null) => void;
};

const FormikDatePicker: React.FC<FormikDatePickerProps> = ({
  name,
  value,
  type = 'Date | null',
  importance = 'normal',
  importanceBannerWidth,
  id,
  required,
  onDateChange,
  helperText,
  ...rest
}) => {
  const localClasses = useLocalStyles();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { setFieldValue }: FormikProps<any> = useFormikContext();

  const onChange = (date: Date | null ) => {
    if (!isNil(date)) {
      if (!isNil(onDateChange)) {
        if (type !== 'string') {
          onDateChange(date);
        }
      } else if (type === 'string') {
        setFieldValue(name, date.toJSON());
      } else setFieldValue(name, date);
    }
  };

  let bannerColor = '';
  if (importance === 'recommended') {
    bannerColor = Colors.TURQUOISE_BLUE;
  } else if (importance === 'mandatory') {
    bannerColor = Colors.CARNATION_RED;
  } else {
    bannerColor = 'transparent';
  }

  const customStyles = {
    width: importanceBannerWidth ?? 280, // Default width value.
    marginTop: 8, // Default margin top value. Will keep the banner in place if no margin value is given
  };
  return (
    <div style={{ position: 'relative' }}>
      <CustomDatePicker
        id={id}
        {...rest}
        value={value}
        name={name}
        onChange={onChange}
        data-test="date-picker"
        required={required}
        helperText={helperText}
      />
      <div className={localClasses.overLap} style={customStyles}>
        <div className={localClasses.coloredBanner} style={{ backgroundColor: bannerColor }} />
      </div>
    </div>
  );
};
export default FormikDatePicker;
