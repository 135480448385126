import React, { useState } from 'react';
import { ElevatedPaper, ContentHeader , ActionButton } from '@up2rent/ui';
import { useIntl } from 'react-intl';
import { useUsers } from '../../hooks/UsersContext';
import { Typography } from '@material-ui/core';
import AddMemberForm from './Members/AddMemberForm';
import MemberRow from './Members/MemberRow';
import { ContactType, User, contactContainsType } from '@rentguru/commons-utils';
import EntityDetail from '../ui/EntityDetail/EntityDetail';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { isNil } from 'lodash';
import GeneralDetails from './Members/Details/GeneralDetails';
import { RouteDestination } from '../Routes/Routes';
import Dialog from 'src/components/ui/Dialog';

const MembersSettings: React.FC = () => {
  const { formatMessage } = useIntl();
  const { loading, error, users } = useUsers();
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
  const history = useHistory();
  const matchLocal = useRouteMatch<{ memberId: string }>({
    path: `${RouteDestination.SETTINGS}/members/:memberId?`,
  });

  const openDetail = !isNil(matchLocal) && !isNil(matchLocal.params) && !isNil(matchLocal.params.memberId);

  if (loading) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{formatMessage({ id: 'loading' })}</Typography>
      </ElevatedPaper>
    );
  }
  if (error) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{error}</Typography>
      </ElevatedPaper>
    );
  }

  const onCloseDialog = () => {
    setAddDialogOpen(false);
  };

  const onRowClick = (user: User) => {
    history.push(`${RouteDestination.SETTINGS}/members/${user.id}/details`);
  };

  const members = users.filter((user) => user.contact && contactContainsType(user.contact, ContactType.MEMBER));

  return (
    <>
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <ContentHeader title={formatMessage({ id: 'settings.members' })}>
          <ActionButton data-test="addMember" onClick={() => setAddDialogOpen(true)}>
            {formatMessage({ id: 'add' })}
          </ActionButton>
        </ContentHeader>
        <div style={{ paddingLeft: 30, paddingRight: 30 }}>
          {members.map((user, index) => (
            <MemberRow key={user.id} user={user} divider={index !== members.length - 1} onClick={onRowClick} />
          ))}
        </div>
      </ElevatedPaper>
      <Dialog open={addDialogOpen} onClose={() => onCloseDialog()} scroll="body">
        <AddMemberForm onClose={onCloseDialog} />
      </Dialog>
      <EntityDetail open={openDetail} onClose={() => history.push(`${RouteDestination.SETTINGS}/members`)}>
        <GeneralDetails userId={!isNil(matchLocal) ? matchLocal.params.memberId : ''} />
      </EntityDetail>
    </>
  );
};

export default MembersSettings;
