import React from 'react';
import isNil from 'lodash/isNil';
import { Typography, Grid } from '@material-ui/core';
import Phone from '@material-ui/icons/Phone';
import Mail from '@material-ui/icons/Mail';
import Skeleton from '@material-ui/lab/Skeleton';
import { Colors, getContactName, isNilOrEmpty, truncateAndEllipsisAfter } from '@rentguru/commons-utils';
import { useContacts } from 'src/hooks/ContactsContext';
import { CustomIconButton } from '@up2rent/ui';

interface ContactQuickCallMailProps {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  companyName?: string | null;
}

const ContactQuickCallMail: React.FC<ContactQuickCallMailProps> = ({ id, firstName, lastName, companyName }) => {
  const { contacts, contactsError, contactsLoading } = useContacts();
  const contact = contacts.find((c) => c.id === id);
  if (isNil(contact)) {
    return null;
  }
  if (contactsError) {
    return <Typography>{contactsError}</Typography>;
  }

  return (
    <Grid container spacing={4} style={{ alignItems: 'center' }}>
      <Grid item xs={8}>
        {contactsLoading ? (
          <Skeleton variant="text" height={16} width="50%" />
        ) : (
          <Typography style={{ color: Colors.CLASSICAL_BLACK, fontSize: 16 }}>
            {truncateAndEllipsisAfter(getContactName(firstName, lastName, companyName), 30)}
          </Typography>
        )}
      </Grid>
      <Grid item xs={2}>
        <CustomIconButton
          disabled={contactsLoading || isNil(contact.email)}
          href={isNil(contact.email) ? '#' : `mailto:${contact.email}`}
          target="_top"
          Icon={Mail}
        />
      </Grid>
      <Grid item xs={2}>
        <CustomIconButton
          disabled={contactsLoading || isNilOrEmpty(contact.phoneNumbers)}
          href={
            isNilOrEmpty(contact.phoneNumbers)
              ? '#'
              : `tel:${contact.phoneNumbers[0].countryCode}${contact.phoneNumbers[0].number}`
          }
          target="_top"
          Icon={Phone}
        />
      </Grid>
    </Grid>
  );
};

export default ContactQuickCallMail;
