import React, { useState } from 'react';
import './ResetIcon.css';
import CachedIcon from '@material-ui/icons/Cached';
import { SvgIconProps } from '@material-ui/core';

const ResetIcon: React.FC<SvgIconProps> = ({ onClick = () => {}, style = {}, ...rest }) => {
  const [rotateIndex, setRotateIndex] = useState<number>(0);
  return (
    <CachedIcon
      style={style}
      className={rotateIndex === 0 ? '' : rotateIndex % 2 === 0 ? 'rotate' : 'rotate2'}
      onClick={(e) => {
        onClick(e);
        setRotateIndex(rotateIndex + 1);
      }}
      {...rest}
    />
  );
};

export default ResetIcon;
