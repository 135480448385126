import { Link } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { Colors } from '@rentguru/commons-utils';

export const BlueLink = styled(Link)({
  color: Colors.DODGER_BLUE,
  '& .MuiLink-underlineAlways': {
    color: Colors.DODGER_BLUE,
  },
  '& .MuiLink-underlineHover': {
    color: Colors.DODGER_BLUE,
  },
  width: 'fit-content',
  display: 'block',
});
