import { DialogProps, Dialog as MuiDialog } from '@material-ui/core';

const Dialog = ({ children, ...props }: DialogProps) => {
  return (
    <MuiDialog disableEnforceFocus {...props}>
      {children}
    </MuiDialog>
  );
};

export default Dialog;
