import React from 'react';
import TextDetail from 'src/components/ui/TextDetail';

interface TextDetailWithFrontIconProps {
  title: string;
  text?: string | null | number;
  style?: React.CSSProperties;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
  endAdornment?: string;
  FrontIcon: React.FunctionComponent;
  subTitle?: string;
  children?: React.ReactNode;
}
const TextDetailWithFrontIcon: React.FC<TextDetailWithFrontIconProps> = ({
  title,
  text,
  children,
  style,
  icon,
  endAdornment,
  FrontIcon,
  subTitle,
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ margin: '0 10px 0 0' }}>
        <FrontIcon />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <TextDetail
          title={title}
          text={text}
          // eslint-disable-next-line react/no-children-prop
          children={children}
          style={style}
          icon={icon}
          endAdornment={endAdornment}
          subTitle={subTitle}
        />
      </div>
    </div>
  );
};

export default TextDetailWithFrontIcon;
