import { Typography } from '@material-ui/core';
import { getMonthYear } from '@rentguru/commons-utils';
import { isBefore, lastDayOfMonth } from 'date-fns';
import { useFormikContext } from 'formik';
import { isNil } from 'lodash';
import { useIntl } from 'react-intl';
import FormikDatePicker from 'src/components/ui/FormikDatePicker';
import IconnedRemark from 'src/components/ui/IconnedRemark';
import { dateLocaleMap, useLocale } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import { EndLeaseFormValues, useEndLeaseStyles } from './EndLeaseForm';

const EndLeaseEndDateFields = ({
  leaseStartDate,
  leaseEndDate,
  maxLeaseEndDate,
  leaseLastInvoiceDate,
}: {
  leaseStartDate: Date;
  leaseEndDate: Date;
  maxLeaseEndDate: Date;
  leaseLastInvoiceDate?: Date;
}) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const { values, errors } = useFormikContext<EndLeaseFormValues>();
  const classes = useEndLeaseStyles();

  const showWarningExistingInvoices =
    !isNil(leaseLastInvoiceDate) && isBefore(new Date(values.endDate), lastDayOfMonth(leaseLastInvoiceDate));
  const dateLocale = dateLocaleMap[language];

  return (
    <>
      <Typography
        className={classes.informationFillInTitle}
        style={{
          marginTop: 20,
        }}
      >
        {formatMessage({ id: `lease.endExtendLease.selectNewEndDate` })}
      </Typography>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <FormikDatePicker
          name="currentEndDate"
          label={formatMessage({ id: 'lease.endExtendLease.currentEndDate' })}
          value={leaseEndDate}
          style={{ width: 260, maxWidth: 260 }}
          views={['year', 'month', 'date']}
          minDate={leaseStartDate}
          disabled
        />
        <FormikDatePicker
          name="endDate"
          label={formatMessage({ id: 'lease.endExtendLease.newEndDate' })}
          value={values.endDate}
          style={{ width: 260, maxWidth: 260 }}
          error={Boolean(errors.endDate)}
          views={['year', 'month', 'date']}
          minDate={leaseStartDate}
          maxDate={maxLeaseEndDate}
        />
      </div>

      {showWarningExistingInvoices && (
        <IconnedRemark
          Icon={InfoSvg}
          noFixWidth
          style={{
            marginRight: 0,
            marginTop: 10,
            marginBottom: 10,
            marginLeft: 0,
          }}
          message={
            <Typography className={classes.infoMessageText}>
              {formatMessage(
                { id: 'lease.endExtendLease.warningAlreadyExistingInvoices' },
                { date: getMonthYear(leaseLastInvoiceDate, dateLocale) }
              )}
            </Typography>
          }
        />
      )}
    </>
  );
};

export default EndLeaseEndDateFields;
