import React, { useEffect, useRef } from 'react';
import useResizeObserver from './utils/useResizeObserver';
import { max, scaleBand, scaleLinear, select, axisLeft, axisBottom, NumberValue, pointer } from 'd3';
import { isNil } from 'lodash';
import { distance } from '../ReordableList/utils';
import { Colors } from '@rentguru/commons-utils';

interface BarChartAxisOptions {
  labelFormat?: string;
  ticksViewable?: number;
  maxValue?: number;
}

export interface BarChartData {
  value: number;
  text: string;
  id: string;
  disabled?: boolean;
}
interface BarChartProps {
  datas: BarChartData[];
  yAxisOptions?: BarChartAxisOptions;
  tooltipHtml?: (data: BarChartData) => string;
}

const margin = { top: 30, right: 30, bottom: 30, left: 30, text: 10 };

const BarChart: React.FC<BarChartProps> = ({
  datas,
  yAxisOptions = { labelFormat: '', ticksViewable: 1 },
  tooltipHtml = (data) => `${data.value}${yAxisOptions.labelFormat}`,
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const dimensions = useResizeObserver(wrapperRef);
  const startOfXAxisLeftPadding = 40;

  useEffect(() => {
    if (!wrapperRef || !wrapperRef.current) return;
    const svg = select(svgRef.current);
    const svgContent = svg.select<SVGGElement>('.content');

    const { width, height } = dimensions || wrapperRef.current.getBoundingClientRect();

    // *** TOOLTIP ***
    const divTooltip = select(wrapperRef.current).select<HTMLDivElement>('.toolTip').style('opacity', 0);
    svgContent
      .append('rect')
      .attr('class', 'overlay')
      .attr('width', width)
      .attr('height', height)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('mouseover', () => {
        divTooltip.transition().duration(200).style('opacity', 0.9);
      })
      .on('mousemove', (event) => {
        const [pointerX, pointerY] = pointer(event);
        const res = datas.reduce((closestData: BarChartData | null, currentData) => {
          if (isNil(closestData)) {
            return currentData;
          }
          const pointOfCurrentData = xScale(currentData.id) || 0;
          const pointOfLastestData = xScale(closestData.id) || 0;
          if (distance(pointOfCurrentData, pointerX) < distance(pointOfLastestData, pointerX)) {
            return currentData;
          }

          return closestData;
        }, null);
        if (!isNil(res)) {
          divTooltip.style('top', `${pointerY + 50}px`).style('left', `${pointerX + 50}px`);
          divTooltip.html(tooltipHtml(res));
        }
      })
      .on('mouseout', () => {
        divTooltip.transition().duration(200).style('opacity', 0);
      });
    // *** SCALES ***
    const xScale = scaleBand()
      .domain(datas.map((data) => data.id))
      .range([startOfXAxisLeftPadding, width])
      .padding(0.9);
    const yScale = scaleLinear()
      .domain(yAxisOptions.maxValue ? [0, yAxisOptions.maxValue] : [0, max(datas, (d) => d.value) || height])
      .range([height - (margin.bottom + margin.text), margin.text]);

    // *** BARS ***
    svgContent

      .selectAll('.bar')
      .data(datas)
      .join('rect')
      .attr('class', 'bar')
      .attr('x', (data) => xScale(data.id) || 0)
      .attr('y', () => yScale(0))
      .attr('width', () => (xScale.bandwidth() < 6 ? xScale.bandwidth() : '6'))
      .attr('ry', '3')
      .attr('rx', '3')
      .attr('fill', (data) => (data.disabled ? Colors.SILVER : 'url(#defaultColor)'))
      .attr('y', (data) => yScale(data.value))
      .attr('height', (data) => yScale(0) - yScale(data.value));

    // *** AXES ***
    // X
    const xAxis = axisBottom(xScale).tickSizeOuter(0).tickSizeInner(0);
    svg
      .select<SVGGElement>('.x-axis')
      .attr('transform', `translate(0, ${yScale(0)})`)
      .call(xAxis)
      .call((g) => g.select('.domain').attr('stroke', Colors.GEYSER_GREY).attr('stroke-width', '1'))
      .call((g) => g.select('.tick line').attr('stroke', 'none'))
      .selectAll('text')
      .text((d, i) => datas[i].text)
      .attr('transform', `translate(0, ${margin.text})`)
      .style('font-size', '12px')
      .style('font-family', 'Mulish')
      .style('font-weight', '500')
      .style('font-stretch', 'normal')
      .style('font-style', 'normal')
      .style('line-height', 'normal')
      .style('letter-spacing', 'normal')
      .style('text-align', 'center')
      .style('fill', Colors.BLUEY);

    // Y
    const formatTick = (d: NumberValue) => `${d}${d === 0 ? '' : yAxisOptions.labelFormat}`;
    const yAxis = axisLeft(yScale).tickSize(-width).ticks(yAxisOptions.ticksViewable).tickFormat(formatTick);
    svg
      .select<SVGGElement>('.y-axis')
      .call(yAxis)
      .call((g) => g.select('.domain').remove())
      .selectAll('text')
      .style('font-size', '12px')
      .style('transform', 'translateY(-10px)')
      .style('font-family', 'Mulish')
      .style('font-weight', '500')
      .style('font-stretch', 'normal')
      .style('font-style', 'normal')
      .style('line-height', 'normal')
      .style('letter-spacing', 'normal')
      .style('text-anchor', 'start')
      .style('fill', Colors.BLUEY);
    svg
      .select<SVGGElement>('.y-axis')
      .selectAll('.tick line')
      .attr('stroke', Colors.GEYSER_GREY)
      .attr('stroke-width', '1');
  }, [datas, dimensions, yAxisOptions, tooltipHtml]);

  return (
    <>
      <div ref={wrapperRef} style={{ margin: 30 }}>
        <svg ref={svgRef} style={{ display: 'block', width: '100%', height: 260, overflow: 'visible' }}>
          <defs>
            <linearGradient id="defaultColor" x1="1" y1="1">
              <stop stopColor={Colors.BURNING_ORANGE} />
              <stop offset="1" stopColor={Colors.STRONG_PINK} />
            </linearGradient>
          </defs>
          <g className="x-axis" />
          <g className="y-axis" />
          <g className="content" />
        </svg>
        <div
          className="toolTip"
          style={{
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            fontFamily: 'Mulish',
            fontSize: 12,
            fontWeight: 500,
            color: Colors.BLUEY,
            maxWidth: 320,
            display: 'block',
            zIndex: 999,
            backgroundColor: Colors.CLASSICAL_WHITE,
            borderRadius: 4,
          }}
        />
      </div>
    </>
  );
};

export default BarChart;
