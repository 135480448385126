import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, DatePicker, DatePickerProps } from '@material-ui/pickers';
import { ReactComponent as ResolutionSvg } from 'src/icons/resolution.svg';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { Colors, LooseObject, isDateValid } from '@rentguru/commons-utils';
import { isAfter } from 'date-fns';
import isNil from 'lodash/isNil';
import { useIntl } from 'react-intl';
import { WrapperVariant } from '@material-ui/pickers/wrappers/Wrapper';

export const useStyles = makeStyles({
  label: {
    color: Colors.LIGHT_BLUE_GREY,
  },
  underline: {
    '&:before': {
      borderBottom: `2px solid ${Colors.TOWER_GREY}`,
    },
  },
  select: {
    height: '1.1875em',
  },
  root: {
    '&$disabled:before': {
      borderBottomStyle: 'hidden',
    },
  },
  disabled: {
    backgroundColor: Colors.PORCELAIN_GREY_1,
  },
});

export const areDatesEqual = (date1: Date | string | null, date2: Date | string | null): boolean => {
  if (isNil(date1) && isNil(date2)) return true;
  if ((!isNil(date1) && isNil(date2)) || (isNil(date1) && !isNil(date2))) return false;
  if (typeof date1 === 'string' && typeof date2 === 'string') return date1 === date2;
  if (date1 instanceof Date && typeof date2 === 'string') return date1.getTime() === new Date(date2).getTime();
  if (typeof date1 === 'string' && date2 instanceof Date)
    return new Date(date1).getTime() === new Date(date2).getTime();
  return (date1 as Date).getTime() === (date2 as Date).getTime();
};

export interface CustomDatePickerProps extends DatePickerProps {
  name: string;
  keyboard?: boolean;
  label?: string | null;
  value: string | Date | null;
  style?: React.CSSProperties;
  onChange: (date: Date | null) => void;
  onClear?: () => void;

  disabled?: boolean;
  format?: string;
  views?: Array<'year' | 'date' | 'month'>;
  minDate?: ParsableDate;
  maxDate?: ParsableDate;
  safeDateFormat?: boolean;
  keyboardIconStyle?: React.CSSProperties;
  keyboardIconProps?: LooseObject;
  required?: boolean;
  margin?: 'normal' | 'dense';
  error?: boolean;
  id?: string;
  forceOnChange?: boolean;
  helperText?: string;
  variant?: WrapperVariant;
  clearable?: boolean;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  name,
  keyboard = true,
  label,
  value: initialValue,
  style = {},
  onChange,
  onClear,
  disabled = false,
  format = 'dd/MM/yyyy',
  views = ['date'],
  minDate = new Date(1900, 0, 1),
  maxDate,
  safeDateFormat = false, // Only update when date is in a correct state
  keyboardIconStyle = {},
  keyboardIconProps = { id: 'date-picker-button' },
  required = true,
  margin = 'dense',
  error = false,
  id,
  forceOnChange = false,
  helperText,
  variant = 'inline',
  clearable,
}) => {
  const [value, setValue] = useState<string | Date | null>(initialValue);
  const { formatMessage } = useIntl();
  // Reset, the filter value goes back to null so should the displayed value
  if (isNil(initialValue) && !isNil(value)) {
    setValue(null);
  }

  useEffect(() => {
    if (!areDatesEqual(value, initialValue)) {
      setValue(initialValue);
    }
    // eslint-disable-next-line
  }, [initialValue]);

  const classes = useStyles();
  if (keyboard) {
    return (
      <KeyboardDatePicker
        name={name}
        label={label}
        variant={variant}
        inputVariant="filled"
        value={value}
        format={format}
        style={style}
        onChange={(val) => {
          if (safeDateFormat) {
            if (isDateValid(val) && isAfter(val as Date, minDate as Date)) {
              onChange(val);
            } else if (isNil(val) && onClear) {
              onClear();
            }
            if (forceOnChange) {
              onChange(val);
            }
          } else onChange(val);
          if (!forceOnChange) setValue(val);
        }}
        autoOk
        required={required}
        margin={margin}
        allowKeyboardControl
        InputAdornmentProps={{ position: 'end' }}
        InputProps={{ classes: { underline: classes.underline, root: classes.root, disabled: classes.disabled } }}
        InputLabelProps={{ className: classes.label }}
        disabled={disabled}
        keyboardIcon={<ResolutionSvg style={{ fill: Colors.ROLLING_STONE_GREY, ...keyboardIconStyle }} />}
        KeyboardButtonProps={keyboardIconProps}
        views={views}
        minDate={minDate}
        maxDate={maxDate}
        minDateMessage={formatMessage({ id: 'communications.list.minDateMessage' })}
        maxDateMessage={formatMessage({ id: 'communications.list.maxDateMessage' })}
        invalidDateMessage={formatMessage({ id: 'communications.list.invalidDateMessage' })}
        error={error}
        helperText={helperText}
        id={id}
        clearable={clearable}
      />
    );
  }
  return (
    <DatePicker
      name={name}
      label={label}
      variant="inline"
      inputVariant="filled"
      value={value}
      format={format}
      style={style}
      onChange={(val) => {
        if (safeDateFormat) {
          if (isDateValid(val) && isAfter(val as Date, minDate as Date)) onChange(val);
        } else {
          onChange(val);
        }
        if (forceOnChange) {
          onChange(val);
        } else setValue(val);
      }}
      autoOk
      required
      margin={margin}
      allowKeyboardControl
      InputProps={{ classes: { underline: classes.underline, root: classes.root, disabled: classes.disabled } }}
      InputLabelProps={{ className: classes.label }}
      disabled={disabled}
      views={views}
      minDate={minDate}
      maxDate={maxDate}
      minDateMessage={formatMessage({ id: 'communications.list.minDateMessage' })}
      maxDateMessage={formatMessage({ id: 'communications.list.maxDateMessage' })}
      invalidDateMessage={formatMessage({ id: 'communications.list.invalidDateMessage' })}
      error={error}
      helperText={helperText}
      id={id}
    />
  );
};

export default CustomDatePicker;
