import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { ElevatedPaperTable } from '@up2rent/ui';
import FormHeaderColorDots from 'src/components/ui/FormHeaderColorDots';
import LeasePEBForm from 'src/components/ui/Forms/FormSection/LeasePEBForm';
import ElectricalInstallationEdit from './ElectricalInstallationEdit';
import HeatingEdit from './HeatingEdit';

const EnergyEdits = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Grid item style={{ width: 640 }}>
        <ElevatedPaperTable style={{ maxWidth: 640, paddingBottom: 10 }}>
          <FormHeaderColorDots title={formatMessage({ id: 'rentalUnit.detail.energy.energy' })} mandatoryDot={false} />
          <LeasePEBForm parentElement={false} />
          <HeatingEdit />
          <ElectricalInstallationEdit />
        </ElevatedPaperTable>
      </Grid>
    </>
  );
};

export default EnergyEdits;
