import { Skeleton } from '@material-ui/lab';

interface SkeletonComponentProps {
  loading: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  className?: string;
}

const SkeletonComponent: React.FC<SkeletonComponentProps> = ({ loading, children, style = {}, className }) =>
  loading ? (
    <Skeleton className={className} style={style}>
      {children}
    </Skeleton>
  ) : (
    <>{children}</>
  );

export default SkeletonComponent;
