import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import * as Yup from 'yup';
import { Formik, Form, FormikHelpers } from 'formik';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { LoaderButton, ConfirmDialog } from '@up2rent/ui';
import {
  getSettingValueForHeatingReminder,
  getSettingValueForChimneyReminder,
  getSettingValueForFuelTankReminder,
  getSettingValueForPEBReminder,
  getSettingValueForDetectorReminder,
  NotificationOption,
  getSettingObjectIfExist,
  SETTING_HEATING_REMINDER,
  SETTING_PEB_REMINDER,
  SETTING_TANK_REMINDER,
  SETTING_CHIMNEY_REMINDER,
  SETTING_DETECTOR_REMINDER,
  Setting,
  Notification,
} from '@rentguru/commons-utils';
import { useSettings } from '../../../hooks/SettingsContext';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import MaintenanceRemindersForm from './MaintenanceRemindersForm';
import { useNotifications } from 'src/hooks/NotificationsContext';
import { findNotificationsToDelete } from './remindersUtils';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';

const ChangeRemindersSchema = Yup.object().shape({
  heatingReminder: Yup.string().min(1).required(),
  pebReminder: Yup.string().min(1).required(),
  tankReminder: Yup.string().min(1).required(),
  chimneyReminder: Yup.string().min(1).required(),
  detectorReminder: Yup.string().min(1).required(),
});

export interface ChangeRemindersValues {
  heatingReminder: NotificationOption;
  pebReminder: NotificationOption;
  tankReminder: NotificationOption;
  chimneyReminder: NotificationOption;
  detectorReminder: NotificationOption;
}

const RemindersForm: React.FC = () => {
  const { settingsAutomationWrite } = usePermissions();
  const { formatMessage } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { settings, settingsLoading, settingsError, createSetting, updateSetting } = useSettings();
  const { notifications, notificationsLoading, deleteNotification } = useNotifications();
  const [notificationsToDelete, setNotificationsToDelete] = useState<Notification[]>([]);

  if (settingsLoading || notificationsLoading || isNil(settings)) {
    return <Typography>{formatMessage({ id: 'loading' })}</Typography>;
  }
  if (settingsError) {
    return <Typography>{settingsError}</Typography>;
  }

  const handleReminderSubmit = async (
    values: ChangeRemindersValues,
    { setSubmitting, setStatus }: FormikHelpers<ChangeRemindersValues>
  ) => {
    const promiseAddSetting: Promise<Setting>[] = [];
    const promiseUpdateSetting: Promise<Setting>[] = [];
    const { heatingReminder, pebReminder, tankReminder, chimneyReminder, detectorReminder } = values;

    // Heating
    const settingHeatingReminder = getSettingObjectIfExist(settings, SETTING_HEATING_REMINDER);
    if (isNil(settingHeatingReminder)) {
      promiseAddSetting.push(
        createSetting({
          name: SETTING_HEATING_REMINDER,
          value: `${heatingReminder}`,
        })
      );
    } else if (settingHeatingReminder.value !== heatingReminder) {
      promiseUpdateSetting.push(
        updateSetting(settingHeatingReminder as Setting, {
          id: settingHeatingReminder.id,
          name: SETTING_HEATING_REMINDER,
          value: `${heatingReminder}`,
        })
      );
    }

    // PEB
    const settingPebReminder = getSettingObjectIfExist(settings, SETTING_PEB_REMINDER);
    if (isNil(settingPebReminder)) {
      promiseAddSetting.push(
        createSetting({
          name: SETTING_PEB_REMINDER,
          value: `${pebReminder}`,
        })
      );
    } else if (settingPebReminder.value !== pebReminder) {
      promiseUpdateSetting.push(
        updateSetting(settingPebReminder as Setting, {
          id: settingPebReminder.id,
          name: SETTING_PEB_REMINDER,
          value: `${pebReminder}`,
        })
      );
    }

    // Tank
    const settingTankReminder = getSettingObjectIfExist(settings, SETTING_TANK_REMINDER);
    if (isNil(settingTankReminder)) {
      promiseAddSetting.push(
        createSetting({
          name: SETTING_TANK_REMINDER,
          value: `${tankReminder}`,
        })
      );
    } else if (settingTankReminder.value !== tankReminder) {
      promiseUpdateSetting.push(
        updateSetting(settingTankReminder as Setting, {
          id: settingTankReminder.id,
          name: SETTING_TANK_REMINDER,
          value: `${tankReminder}`,
        })
      );
    }

    // Chimney
    const settingChimneyReminder = getSettingObjectIfExist(settings, SETTING_CHIMNEY_REMINDER);
    if (isNil(settingChimneyReminder)) {
      promiseAddSetting.push(
        createSetting({
          name: SETTING_CHIMNEY_REMINDER,
          value: `${chimneyReminder}`,
        })
      );
    } else if (settingChimneyReminder.value !== chimneyReminder) {
      promiseUpdateSetting.push(
        updateSetting(settingChimneyReminder as Setting, {
          id: settingChimneyReminder.id,
          name: SETTING_CHIMNEY_REMINDER,
          value: `${chimneyReminder}`,
        })
      );
    }

    // Detector
    const settingDetectorsReminder = getSettingObjectIfExist(settings, SETTING_DETECTOR_REMINDER);
    if (isNil(settingDetectorsReminder)) {
      promiseAddSetting.push(
        createSetting({
          name: SETTING_DETECTOR_REMINDER,
          value: `${detectorReminder}`,
        })
      );
    } else if (settingDetectorsReminder.value !== detectorReminder) {
      promiseUpdateSetting.push(
        updateSetting(settingDetectorsReminder as Setting, {
          id: settingDetectorsReminder.id,
          name: SETTING_DETECTOR_REMINDER,
          value: `${detectorReminder}`,
        })
      );
    }

    await Promise.all([...promiseAddSetting, ...promiseUpdateSetting]);
    const notificationToDelete = findNotificationsToDelete(
      notifications,
      heatingReminder,
      pebReminder,
      tankReminder,
      chimneyReminder,
      detectorReminder
    );
    if (!isEmpty(notificationToDelete)) setNotificationsToDelete(notificationToDelete);
    setStatus(true);
    setSubmitting(false);
    enqueueSnackbar(formatMessage({ id: 'settings.changesSaved' }), { variant: 'success' });
  };

  const disableForm = !settingsAutomationWrite;
  return (
    <>
      <Formik
        initialValues={
          {
            heatingReminder: getSettingValueForHeatingReminder(settings),
            pebReminder: getSettingValueForPEBReminder(settings),
            tankReminder: getSettingValueForFuelTankReminder(settings),
            chimneyReminder: getSettingValueForChimneyReminder(settings),
            detectorReminder: getSettingValueForDetectorReminder(settings),
          } as ChangeRemindersValues
        }
        validationSchema={ChangeRemindersSchema}
        onSubmit={handleReminderSubmit}
      >
        {({ isSubmitting, status }) => (
          <Form>
            <div style={{ marginLeft: 30, marginRight: 30 }}>
              <MaintenanceRemindersForm disabled={disableForm} />
            </div>

            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            {!disableForm && (
              <div
                style={{
                  marginRight: 30,
                  marginBottom: 20,
                  display: 'flex',
                  float: 'right',
                }}
              >
                <LoaderButton loading={isSubmitting} success={status}>
                  {formatMessage({ id: 'save' })}
                </LoaderButton>
              </div>
            )}
          </Form>
        )}
      </Formik>
      {!isEmpty(notificationsToDelete) && (
        <ConfirmDialog
          open={!isEmpty(notificationsToDelete)}
          confirmText={formatMessage({ id: 'delete' })}
          confirmAction={async () => {
            const deleteNotificationPromises = notificationsToDelete.map(async (notification) => {
              return deleteNotification(notification.id);
            });
            await Promise.all(deleteNotificationPromises);
            setNotificationsToDelete([]);
          }}
          cancelAction={() => {
            setNotificationsToDelete([]);
          }}
          // eslint-disable-next-line max-len
          mainText="It appears there are some notifications in conflict with your new reminder settings, do you want to delete them?"
          formatMessage={formatMessage}
        />
      )}
    </>
  );
};

export default RemindersForm;
