/* eslint-disable default-case */
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns/esm';
import { isNil } from 'lodash';
import { Grid, ListItem, Typography } from '@material-ui/core';
import { Colors, CommunicationStatus, getLocaleFromLanguage, CustomSubscription } from '@rentguru/commons-utils';
import { useCustomSubscriptions } from 'src/hooks/CustomSubscriptionContext';
import { useUser } from 'src/hooks/UserContext';
import { ReactComponent as SettingsIcon } from 'src/icons/lease-action-charge.svg';
import { RouteDestination } from 'src/components/Routes/Routes';
import NotificationDeleteIconButton from 'src/components/ui/CustomIconButtons/NotificiationDeleteIconButton';
import { useNotificationListStyle } from './NotificationsList';

interface NotificationsBlockedCommunicationsItemProps {
  notificationType: CommunicationStatus;
  customSubscription: CustomSubscription;
}

const NotificationsBlockedCommunicationsItem: React.FC<NotificationsBlockedCommunicationsItemProps> = ({
  notificationType,
  customSubscription,
}) => {
  const { formatMessage } = useIntl();
  const localStyles = useNotificationListStyle();

  const { language } = useUser();
  const { deleteCustomSubscription } = useCustomSubscriptions();

  return (
    <ListItem className={localStyles.listItem}>
      <Grid>
        <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <SettingsIcon
                style={{
                  transform: 'scale(0.6)',
                }}
              />
            </div>
            <Typography variant="h6" style={{ color: Colors.CLASSICAL_BLACK, textJustify: 'inter-word' }}>
              {formatMessage({
                id: `communications.notification.title.${notificationType.toLowerCase()}`,
              })}
            </Typography>
          </Grid>
          <NotificationDeleteIconButton
            onClick={() => {
              deleteCustomSubscription(customSubscription);
            }}
          />
        </Grid>
        {!isNil(customSubscription.createdAt) && (
          <Typography style={{ color: Colors.BLUEY, paddingBottom: 5 }}>
            {formatDistanceToNow(new Date(customSubscription.createdAt!), {
              locale: getLocaleFromLanguage(language || 'en'),
            })}
          </Typography>
        )}
        <Grid style={{ color: Colors.SLATE_GREY }}>
          <Typography>
            {formatMessage({ id: `communications.notification.body.${notificationType.toLowerCase()}.info1` })}
            <Link
              to={{
                pathname: `${RouteDestination.COMMUNICATIONS}`,
              }}
              onClick={(e) => e.stopPropagation()}
              style={{ color: Colors.DODGER_BLUE, textDecoration: 'underline', cursor: 'pointer' }}
            >
              {formatMessage({ id: `communications.notification.body.${notificationType.toLowerCase()}.link` })}
            </Link>
            {formatMessage({ id: `communications.notification.body.${notificationType.toLowerCase()}.info2` })}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default NotificationsBlockedCommunicationsItem;
