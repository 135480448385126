/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-param-last */
import React, { Reducer, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import {
  LeasePriceHistory,
  syncLeasePriceHistories,
  getLeasePriceHistory as getLeasePriceHistoryQuery,
  createLeasePriceHistory as createMutation,
  updateLeasePriceHistory as updateMutation,
  deleteLeasePriceHistory as deleteMutation,
  CreateLeasePriceHistoryMutationVariables,
  DeleteLeasePriceHistoryMutationVariables,
  CreateLeasePriceHistoryInput,
  UpdateLeasePriceHistoryInput,
  cleanInputUpdate,
  cleanInputCreate,
  getReadId,
  getTableClientId,
  isIdInList,
  LooseObject,
  LeasePriceHistoryDetailInput,
} from '@rentguru/commons-utils';
import { deleteAndHideEntityWithFetchBefore, list, mutation } from '@up2rent/fetch-utils';
import { useUser } from './UserContext';
import isNil from 'lodash/isNil';
import { usePermissions } from './utils/PermissionsContext';
import { LeaseExtended, useLeases } from './LeasesContext';

export interface LeasePriceHistoryContext extends LeasePriceHistoryState {
  createLeasePriceHistory: (
    input: CreateLeasePriceHistoryInput | Omit<CreateLeasePriceHistoryInput, 'clientId' | 'readId'>
  ) => Promise<LeasePriceHistory>;
  getLeasePriceHistoriesOfLease: (leaseId: string) => LeasePriceHistory[];
  getLeasePriceHistory: (id: string) => LeasePriceHistory | undefined;
  getLeaseOfLeasePriceHistory: (id: string) => LeaseExtended | null | undefined;
  updateLeasePriceHistory: (updates: UpdateLeasePriceHistoryInput) => Promise<LeasePriceHistory>;
  deleteLeasePriceHistory: (leasePriceHistory: LeasePriceHistory) => Promise<LeasePriceHistory | null>;
  setFetchLeasePriceHistories: () => void;
}

interface LeasePriceHistoryState {
  leasePriceHistories: LeasePriceHistory[] | null;
  loading: boolean;
  shouldFetchLeasePriceHistory: boolean;
}

type Action =
  | {
      type: 'SHOULD_FETCH' | 'IS_FETCHING';
    }
  | {
      type: 'ADD_LEASE_PRICE_HISTORY';
      payload: { leasePriceHistory: LeasePriceHistory };
    }
  | {
      type: 'FETCHED';
      payload: { leasePriceHistories: LeasePriceHistory[] };
    }
  | {
      type: 'UPDATE_LEASE_PRICE_HISTORY';
      payload: { leasePriceHistory: LeasePriceHistory };
    }
  | {
      type: 'DELETE_LEASE_PRICE_HISTORY';
      payload: { id: string };
    };

const initialState: LeasePriceHistoryState = {
  loading: false,
  leasePriceHistories: null,
  shouldFetchLeasePriceHistory: false,
};

const leasePriceHistoryReducer = (state: LeasePriceHistoryState, action: Action): LeasePriceHistoryState => {
  switch (action.type) {
    case 'SHOULD_FETCH':
      // Check //lastFetchForAgencyRates
      if (state.loading || state.shouldFetchLeasePriceHistory) {
        return state;
      }
      return {
        ...state,
        shouldFetchLeasePriceHistory: true,
      };
    case 'IS_FETCHING':
      return {
        ...state,
        loading: true,
      };
    case 'FETCHED':
      // Build List with merged object
      return {
        ...state,
        loading: false,
        shouldFetchLeasePriceHistory: false,
        leasePriceHistories: action.payload.leasePriceHistories,
      };
    case 'ADD_LEASE_PRICE_HISTORY':
      return {
        ...state,
        leasePriceHistories: [...(state.leasePriceHistories ?? []), action.payload.leasePriceHistory],
      };
    case 'UPDATE_LEASE_PRICE_HISTORY':
      if (!state.leasePriceHistories) {
        return state;
      }
      return {
        ...state,
        leasePriceHistories: state.leasePriceHistories.map((leasePriceHistory) => {
          if (leasePriceHistory.id === action.payload.leasePriceHistory.id) {
            return action.payload.leasePriceHistory;
          }
          return leasePriceHistory;
        }),
      };
    case 'DELETE_LEASE_PRICE_HISTORY':
      if (!state.leasePriceHistories) {
        return state;
      }
      return {
        ...state,
        leasePriceHistories: state.leasePriceHistories.filter(
          (leasePriceHistory) => leasePriceHistory.id !== action.payload.id
        ),
      };
    default:
      return state;
  }
};

export const fetchLeasePriceHistories = async (
  by: 'byClientId' | 'byLease',
  byValue: string,
  additionalFilter?: LooseObject
): Promise<LeasePriceHistory[]> => {
  const indexName = by === 'byClientId' ? 'clientId' : 'leaseId';
  return await list<LeasePriceHistory>(syncLeasePriceHistories, indexName, byValue, additionalFilter);
};

export const LeasePriceHistoryContext = React.createContext<LeasePriceHistoryContext | null>(null);

export const LeasePriceHistoryContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<LeasePriceHistoryState, Action>>(leasePriceHistoryReducer, initialState);
  const { leases, leasesLoading, getLease } = useLeases();
  const { leasesCreationDelete } = usePermissions();
  const { clientId, rootUser } = useUser();

  const setFetchLeasePriceHistories = () => {
    dispatch({ type: 'SHOULD_FETCH' });
  };

  useEffect(() => {
    const fetchAndSet = async () => {
      dispatch({ type: 'IS_FETCHING' });
      const result = await fetchLeasePriceHistories('byClientId', getTableClientId(clientId!, 'LeasePriceHistory'));
      const resultFiltered = rootUser ? result : result.filter((li) => li.lease && isIdInList(li.lease, leases));
      dispatch({ type: 'FETCHED', payload: { leasePriceHistories: resultFiltered } });
    };
    if (state.shouldFetchLeasePriceHistory && !leasesLoading) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetchLeasePriceHistory, leasesLoading]);

  const createLeasePriceHistory = async (
    input: CreateLeasePriceHistoryInput | Omit<CreateLeasePriceHistoryInput, 'clientId' | 'readId'>
  ): Promise<LeasePriceHistory> => {
    const leasePriceHistory = await mutation<LeasePriceHistory, CreateLeasePriceHistoryMutationVariables>(
      createMutation,
      {
        input: {
          ...(cleanInputCreate(input) as CreateLeasePriceHistoryInput),
          clientId: getTableClientId(clientId!, 'LeasePriceHistory'),
          readId: getReadId(clientId!, 'LeasePriceHistory'),
        },
      }
    );
    dispatch({ type: 'ADD_LEASE_PRICE_HISTORY', payload: { leasePriceHistory } });
    return leasePriceHistory;
  };

  const getLeasePriceHistoriesOfLease = (leaseId: string) => {
    if (isNil(state.leasePriceHistories)) {
      return [];
    }
    return (state.leasePriceHistories as LeasePriceHistory[]).filter(
      (leasePriceHistory) => leasePriceHistory.lease && leasePriceHistory.lease.id === leaseId
    );
  };

  const updateLeasePriceHistory = async (updates: UpdateLeasePriceHistoryInput): Promise<LeasePriceHistory> => {
    const cleanInput = { ...cleanInputUpdate(updates, false) };
    if (updates.amountDetails) {
      cleanInput.amountDetails = updates.amountDetails.map((amountDetail) =>
        cleanInputUpdate(amountDetail)
      ) as LeasePriceHistoryDetailInput[];
    }
    const result = await mutation<LeasePriceHistory>(updateMutation, {
      input: cleanInput,
    });
    dispatch({ type: 'UPDATE_LEASE_PRICE_HISTORY', payload: { leasePriceHistory: result } });
    dispatch({ type: 'SHOULD_FETCH' });
    return result;
  };

  const deleteLeasePriceHistory = async (leasePriceHistory: LeasePriceHistory): Promise<LeasePriceHistory | null> => {
    if (!leasesCreationDelete) {
      return null;
    }
    const result = await deleteAndHideEntityWithFetchBefore<
      LeasePriceHistory,
      DeleteLeasePriceHistoryMutationVariables
    >(leasePriceHistory, getLeasePriceHistoryQuery, deleteMutation, updateMutation);

    dispatch({ type: 'DELETE_LEASE_PRICE_HISTORY', payload: { id: leasePriceHistory.id } });
    return result;
  };

  const getLeasePriceHistory = (id: string) => {
    const leasePriceHistory = (state.leasePriceHistories ?? []).find(
      (leasePriceHistoryElement) => leasePriceHistoryElement.id === id
    );
    return leasePriceHistory;
  };

  const getLeaseOfLeasePriceHistory = (id: string) => {
    const leasePriceHistory = getLeasePriceHistory(id);
    if (isNil(leasePriceHistory) || isNil(leasePriceHistory.lease)) {
      return undefined;
    }
    return getLease(leasePriceHistory.lease.id);
  };

  const values = useMemo(
    () => ({
      ...state,
      createLeasePriceHistory,
      getLeasePriceHistoriesOfLease,
      getLeasePriceHistory,
      updateLeasePriceHistory,
      deleteLeasePriceHistory,
      getLeaseOfLeasePriceHistory,
      setFetchLeasePriceHistories,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <LeasePriceHistoryContext.Provider value={values}>{children}</LeasePriceHistoryContext.Provider>;
};

export const useLeasePriceHistories = (): LeasePriceHistoryContext => {
  const firstRender = useRef<boolean>(false);
  const context = useContext<LeasePriceHistoryContext | null>(LeasePriceHistoryContext);

  if (isNil(context)) {
    throw new Error('`useLeasePriceHistories` hook must be used within a `LeasePriceHistoryContext` component');
  }
  useEffect(() => {
    if (!firstRender.current && isNil(context.leasePriceHistories)) {
      context.setFetchLeasePriceHistories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  return context;
};
