/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
// eslint-disable-next-line import/named
import { Colors, LooseObject } from '@rentguru/commons-utils';
import clsx from 'clsx';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import capitalize from 'lodash/capitalize';
import isNil from 'lodash/isNil';

const useStyles = makeStyles((theme) => ({
  /* Styles applied to the root element. */
  root: {
    cursor: 'pointer',
    display: 'inline-flex',
    justifyContent: 'flex-start',
    flexDirection: 'inherit',
    alignItems: 'center',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '15px',
    color: Colors.BLUE_GREY,
    '&:hover': {
      color: Colors.SILVER,
    },
  },
  focusable: {
    '&:focus': {
      color: Colors.BLUE_GREY,
    },
  },
  /* Styles applied to the root element if `active={true}`. */
  active: {
    color: Colors.BLUE_GREY,
    '& $icon': {
      opacity: 1,
    },
  },
  /* Styles applied to the icon component. */
  icon: {
    height: 16,
    marginRight: 4,
    marginLeft: 4,
    transition: theme.transitions.create(['opacity', 'transform'], {
      duration: theme.transitions.duration.shorter,
    }),
    userSelect: 'none',
    width: 16,
  },
  /* Styles applied to the icon component if `direction="desc"`. */
  iconDirectionDesc: {
    transform: 'rotate(0deg)',
  },
  /* Styles applied to the icon component if `direction="asc"`. */
  iconDirectionAsc: {
    transform: 'rotate(180deg)',
  },
}));

interface SortLabelProps {
  active?: boolean;
  direction?: string;
  onClick?: () => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLElement>) => void;
  onMouseLeave?: () => void;
  hideSortIcon?: boolean;
  style?: React.CSSProperties;
  focusable?: boolean;
  children?: React.ReactNode;
}

// eslint-disable-next-line react/display-name
export const SortLabel: React.FC<SortLabelProps> = React.forwardRef((props, ref) => {
  const {
    active = false,
    focusable = true,
    children,
    direction = 'desc',
    hideSortIcon = false,
    onMouseEnter,
    onMouseLeave,
    onClick,
    style = {},
  } = props;
  const classes = useStyles();
  const rootClass = focusable ? clsx(classes.root, classes.focusable) : classes.root;
  return (
    <ButtonBase
      classes={{ root: rootClass }}
      component="span"
      disableRipple
      ref={ref as any}
      {...(!isNil(onMouseEnter) && { onMouseEnter })}
      {...(!isNil(onMouseLeave) && { onMouseLeave })}
      {...(!isNil(onClick) && { onClick })}
      style={style}
    >
      {children}
      {hideSortIcon || !active ? null : (
        <ExpandMore className={clsx(classes.icon, (classes as LooseObject)[`iconDirection${capitalize(direction)}`])} />
      )}
    </ButtonBase>
  );
});
