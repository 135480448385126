import {
  MessagesEN,
  LeaseExtended,
  Contact,
  LeaseActionEndInitiator,
  LeaseType,
  isAfterOrEqual,
} from '@rentguru/commons-utils';
import {
  addDays,
  addMonths,
  addYears,
  differenceInCalendarYears,
  differenceInYears,
  isSameDay,
  subDays,
  subMonths,
} from 'date-fns';
import { ContactContext } from 'src/hooks/ContactsContext';

export const isShortTermsLease = (leaseType: LeaseType) => {
  return [LeaseType.MAIN_RESIDENCE_SHORT, LeaseType.STUDENT].includes(leaseType as LeaseType);
};

export const calculateMaxDate = (
  leaseStartDate: Date,
  leaseEndDate: Date,
  initiator: LeaseActionEndInitiator,
  leaseType: LeaseType
) => {
  if ([LeaseActionEndInitiator.MUTUAL, LeaseActionEndInitiator.TENANT].includes(initiator)) {
    const dateWithTenMoreMonths = subDays(addMonths(leaseEndDate, 10), 1);
    if (isShortTermsLease(leaseType)) {
      // Short contracts have a maximum duration of 3 years
      const limitDate = subDays(addYears(leaseStartDate, 3), 1);
      return isAfterOrEqual(dateWithTenMoreMonths, limitDate) ? limitDate : dateWithTenMoreMonths;
    }
    return dateWithTenMoreMonths;
  }
  return leaseEndDate;
};

export const isResidentialLease = (leaseType: LeaseType) => {
  return [
    LeaseType.MAIN_RESIDENCE_9_YEARS,
    LeaseType.FLATSHARING,
    LeaseType.MAIN_RESIDENCE_LONG,
    LeaseType.MAIN_RESIDENCE_INFINITE,
  ].includes(leaseType);
};

export const isLongTermsOrCommercialLease = (leaseType: LeaseType) => {
  return isResidentialLease(leaseType) || leaseType === LeaseType.COMMERCIAL;
};

export const isInFlanders = (region: string | null | undefined) => {
  return region === MessagesEN.enums.AddressRegion.Flanders;
};

export const getNumberOfMonthsForIndemnity = (
  initiator: LeaseActionEndInitiator,
  isShortTerms: boolean,
  isCommercial: boolean,
  isFlanders: boolean,
  startDate: Date,
  newEndDate: Date
): number => {
  if (initiator === LeaseActionEndInitiator.MUTUAL) {
    return 0;
  }
  const numberOfLeaseYears = differenceInYears(addDays(newEndDate, 1), startDate);
  const isLessorEnding = initiator === LeaseActionEndInitiator.LESSOR;
  const isTenantEnding = initiator === LeaseActionEndInitiator.TENANT;

  if (isTenantEnding && isShortTerms && !isFlanders) {
    return 1;
  }

  if (isTenantEnding && isShortTerms && isFlanders) {
    if (numberOfLeaseYears === 1) {
      return 1.5;
    }
    if (numberOfLeaseYears === 2) {
      return 1;
    }
    return 0.5;
  }
  if (isLessorEnding && isShortTerms) {
    return 1;
  }

  if (!isCommercial && isTenantEnding) {
    if (numberOfLeaseYears === 1) {
      return 3;
    }
    if (numberOfLeaseYears === 2) {
      return 2;
    }
    if (numberOfLeaseYears >= 3) {
      return 1;
    }
  }
  if (!isCommercial && isLessorEnding) {
    if (numberOfLeaseYears >= 3 && numberOfLeaseYears < 6) {
      return 9;
    }
    if (numberOfLeaseYears >= 6 && numberOfLeaseYears < 9) {
      return 6;
    }
    return 3;
  }
  if (isCommercial) {
    return 6;
  }

  return 0;
};

export const getNoticeDateForLessorEnd = (newEndDate: Date, leaseType: LeaseType) => {
  const isShortTerms = isShortTermsLease(leaseType);
  const isCommercial = leaseType === LeaseType.COMMERCIAL;
  const isLongTerms = isResidentialLease(leaseType);

  if (isShortTerms) {
    return subMonths(newEndDate, 3);
  }
  if (isLongTerms) {
    return subMonths(newEndDate, 6);
  }
  if (isCommercial) {
    return subMonths(newEndDate, 12);
  }

  return newEndDate;
};

export const getNumberOrMonthsForNotice = (initiator: LeaseActionEndInitiator, leaseType: LeaseType) => {
  if (initiator === LeaseActionEndInitiator.TENANT) {
    return leaseType === LeaseType.COMMERCIAL ? 6 : 3;
  }
  if (initiator === LeaseActionEndInitiator.LESSOR) {
    const isShortTerms = isShortTermsLease(leaseType);
    const isLongTermsOrCommercial = isLongTermsOrCommercialLease(leaseType);
    if (isLongTermsOrCommercial) {
      return 6;
    }
    if (isShortTerms) {
      return 3;
    }
  }
  return 0;
};

export const isCommercialEndNotInNormalPeriod = (leaseType: LeaseType, leaseStartDate: Date, endDate: Date) => {
  const isCommercial = leaseType === LeaseType.COMMERCIAL;
  if (!isCommercial) {
    return false;
  }

  const differenceInYearsBetweenStartAndNewEnd = differenceInCalendarYears(endDate, leaseStartDate);
  const differenceIn3YearPeriodsMod = differenceInYearsBetweenStartAndNewEnd % 3;
  if (differenceIn3YearPeriodsMod !== 0) {
    return true;
  }
  const normalEndDate = subDays(addYears(leaseStartDate, differenceInYearsBetweenStartAndNewEnd), 1);
  return !isSameDay(endDate, normalEndDate);
};

export const isAddressConfiguredForRegisteredLetter = (
  lease: LeaseExtended,
  clientContact: Contact | undefined | null,
  getContact: ContactContext['getContact']
) => {
  if (!clientContact || !lease.tenants || !lease.tenants[0]?.id) {
    return false;
  }
  const contactFrom = getContact(clientContact.id);
  const contactAddress = getContact(lease.tenants[0].id);
  return Boolean(
    contactFrom?.address &&
      contactFrom.address.street &&
      contactFrom.address.number &&
      contactFrom.address.postalCode &&
      contactFrom.address.city &&
      contactAddress?.address &&
      contactAddress.address.street &&
      contactAddress.address.number &&
      contactAddress.address.postalCode &&
      contactAddress.address.city
  );
};
