import React from 'react';
import { Team, Colors } from '@rentguru/commons-utils';
import * as Yup from 'yup';
import { FormikHelpers, Formik, Form } from 'formik';
import isNil from 'lodash/isNil';
import { useIntl } from 'react-intl';
import TextDetailEditable from '../../ui/TextDetailEditable';
import { useTeams } from '../../../hooks/TeamsContext';
import { Divider } from '@material-ui/core';
import { ContentHeader, LoaderButton , ActionButton } from '@up2rent/ui';
import toUpper from 'lodash/toUpper';

interface EditTeamFormValues {
  name: string;
}

const EditTeamSchema = Yup.object()
  .shape({
    name: Yup.string().required(),
  })
  .required();

interface EditTeamProps {
  onClose?: (team?: Team) => void;
  id: string;
}

const EditTeam: React.FC<EditTeamProps> = ({ onClose, id }) => {
  const { formatMessage } = useIntl();
  const { updateTeam, getTeam } = useTeams();

  const original = getTeam(id);
  if (isNil(original)) {
    return null;
  }

  const handleUpdate = async (
    values: EditTeamFormValues,
    { setSubmitting, setStatus }: FormikHelpers<EditTeamFormValues>
  ) => {
    const team = await updateTeam(original!, { name: values.name });

    setStatus(true);
    setSubmitting(false);
    if (!isNil(onClose)) {
      onClose(team);
    }
  };

  const initialValues: EditTeamFormValues = {
    name: original.name,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={EditTeamSchema}
      onSubmit={handleUpdate}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ errors, touched, status, isSubmitting }) => (
        <Form>
          <ContentHeader title={formatMessage({ id: 'settings.editTeam.menu' })} />
          <div style={{ marginLeft: 30, marginRight: 30 }}>
            <div style={{ alignItems: 'baseline' }}>
              <TextDetailEditable
                title={formatMessage({ id: 'settings.addTeam.name' })}
                editMode={true}
                name="name"
                type="text"
                style={{ width: 280, maxWidth: 'none' }}
                error={Boolean(errors.name && touched.name)}
              />
            </div>
          </div>
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          <div
            style={{
              marginRight: 30,
              marginBottom: 20,
              display: 'flex',
              float: 'right',
              alignItems: 'center',
            }}
          >
            <ActionButton
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.stopPropagation();
                if (!isNil(onClose)) {
                  onClose();
                }
              }}
              style={{
                background: 'none',
                color: Colors.DARK_SLATE_GREY,
                marginRight: 20,
              }}
            >
              {toUpper(
                formatMessage({
                  id: 'cancel',
                })
              )}
            </ActionButton>
            <LoaderButton loading={isSubmitting} success={status}>
              {formatMessage({
                id: 'save',
              })}
            </LoaderButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditTeam;
