/* eslint-disable no-redeclare */
import React, { useContext, useEffect, useMemo } from 'react';
import {
  Ticket,
  resolveOneToMany,
  resolveOneToOne,
  cleanInputCreate,
  cleanInputUpdate,
  getReadId,
  getTableClientId,
  syncTickets as syncQuery,
  getTicket as getQuery,
  createTicket as createMutation,
  updateTicket as updateMutation,
  deleteTicket as deleteMutation,
  CreateTicketMutationVariables as CreateMutationVariables,
  DeleteTicketMutationVariables as DeleteMutationVariables,
  CreateTicketInput as CreateInput,
  isNilOrEmpty,
  Model,
  OnCreateTicketSubscription,
  OnUpdateTicketSubscription,
  OnDeleteTicketSubscription,
  onCreateTicket,
  onUpdateTicket,
  onDeleteTicket,
} from '@rentguru/commons-utils';
import { isNil } from 'lodash';
import { useUser } from './UserContext';
import { ContextLoaderAction, ContextLoaderStore, useContextLoader } from './ContextLoader';
import { usePermissions } from './utils/PermissionsContext';
import {
  cleanSyncQueries,
  deleteEntityWithFetchBefore,
  getFilterFieldNameForIndex,
  list,
  mutation,
  recordWasUpdated,
  useSubscriptions,
} from '@up2rent/fetch-utils';

const ENTITY_MODEL_NAME: Model = 'Ticket';

export type ActionTicket =
  | {
      type: 'SHOULD_FETCH_TICKET' | 'IS_FETCHING_TICKET';
    }
  | {
      type: 'ADD_TICKET';
      payload: { ticket: Ticket };
    }
  | {
      type: 'FETCHED_TICKET';
      payload: { tickets: Ticket[] };
    }
  | {
      type: 'UPDATE_TICKET';
      payload: { ticket: Ticket };
    }
  | {
      type: 'DELETE_TICKET';
      payload: { id: string };
    };

export interface TicketContext {
  ticketsResolved: Ticket[];
  ticketsArchived: Ticket[];
  ticketsAssignedToMe: Ticket[];
  ticketsAssignedToOthers: Ticket[];
  ticketsUnassigned: Ticket[];
  ticketsAll: Ticket[];
  getTicket: (id: string) => Ticket | undefined;
  getBuildingTickets: (id: string) => Ticket[];
  getUnitTickets: (id: string) => Ticket[];
  getWholeConversationOfTicket: (ticket: Ticket) => Ticket | null;
  createTicket: (input: Omit<Ticket, 'clientId' | 'readId'>) => Promise<Ticket>;
  updateTicket: (original: Ticket, updates: Partial<Ticket>) => Promise<Ticket>;
  deleteTicket: (id: string) => Promise<Ticket>;
  ticketsLoading: boolean;
  ticketsError: string | undefined;
  setFetchTicket: () => void;
}

const classifyTickets = (
  tickets: Ticket[],
  memberId: string
): Pick<
  TicketContext,
  'ticketsAssignedToMe' | 'ticketsAssignedToOthers' | 'ticketsUnassigned' | 'ticketsResolved' | 'ticketsArchived'
> => {
  const [ticketsAssignedToMe, ticketsAssignedToOthers, ticketsUnassigned, ticketsResolved, ticketsArchived] =
    tickets.reduce(
      (acc: [Ticket[], Ticket[], Ticket[], Ticket[], Ticket[]], ticket: Ticket) => {
        if (
          !isNil(ticket.assignedTo) &&
          (ticket.status === 'PENDING' || ticket.status === 'URGENT') &&
          ticket.assignedTo.id === memberId
        )
          acc[0].push(ticket);
        else if (
          !isNil(ticket.assignedTo) &&
          (ticket.status === 'PENDING' || ticket.status === 'URGENT') &&
          ticket.assignedTo.id !== memberId
        )
          acc[1].push(ticket);
        else if (isNil(ticket.assignedTo) && (ticket.status === 'PENDING' || ticket.status === 'URGENT'))
          acc[2].push(ticket);
        else if (ticket.status === 'RESOLVED') acc[3].push(ticket);
        else if (ticket.status === 'ARCHIVED') acc[4].push(ticket);
        return acc;
      },
      [[], [], [], [], []]
    );
  return { ticketsAssignedToMe, ticketsAssignedToOthers, ticketsUnassigned, ticketsResolved, ticketsArchived };
};

export const ticketReducerDelegation = (state: ContextLoaderStore, action: ContextLoaderAction): ContextLoaderStore => {
  switch (action.type) {
    case 'SHOULD_FETCH_TICKET':
      if (state.Ticket.loading || state.Ticket.shouldFetch) {
        return state;
      }
      return {
        ...state,
        Ticket: { ...state.Ticket, shouldFetch: true },
      };
    case 'IS_FETCHING_TICKET':
      if (state.Ticket.loading) {
        return state;
      }
      return {
        ...state,
        Ticket: { ...state.Ticket, loading: true },
      };
    case 'FETCHED_TICKET':
      return {
        ...state,
        Ticket: {
          ...state.Ticket,
          data: action.payload.tickets,
          loading: false,
          shouldFetch: false,
          lastFetch: new Date(),
        },
      };
    case 'ADD_TICKET':
      // Check if already present - If already added by this user or coming from another user
      if (state.Ticket.data?.find((object) => object.id === action.payload.ticket.id)) {
        return state;
      }

      return {
        ...state,
        Ticket: {
          ...state.Ticket,
          data: [...state.Ticket.data, action.payload.ticket],
        },
      };
    case 'UPDATE_TICKET':
      // No data
      if (isNilOrEmpty(state.Ticket.data)) {
        return state;
      }

      // Already present and same object
      const currentObject = state.Ticket.data?.find((object) => object.id === action.payload.ticket.id);
      if (!currentObject) {
        return {
          ...state,
          Ticket: {
            ...state.Ticket,
            data: [...state.Ticket.data, action.payload.ticket],
          },
        };
      }
      if (!recordWasUpdated(currentObject, action.payload.ticket)) {
        return state;
      }

      // Update
      return {
        ...state,
        Ticket: {
          ...state.Ticket,
          data: state.Ticket.data.map((object) => {
            if (object.id === action.payload.ticket.id) {
              return action.payload.ticket;
            }
            return object;
          }),
        },
      };
    case 'DELETE_TICKET':
      if (isNilOrEmpty(state.Ticket.data)) {
        return state;
      }
      return {
        ...state,
        Ticket: {
          ...state.Ticket,
          data: state.Ticket.data.filter((object) => object.id !== action.payload.id),
        },
      };

    default:
      return state;
  }
};

const fetchTickets = async (
  by: 'byClientId' | 'byParent',
  byValue: string,
  additionalFilter?: object
): Promise<Ticket[]> => {
  return await list<Ticket>(cleanSyncQueries(syncQuery), getFilterFieldNameForIndex(by), byValue, additionalFilter);
};

export const TicketContext = React.createContext<TicketContext | null>(null);
export const TicketContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const {
    Ticket: { data: ticketsLoader, loading, shouldFetch },
    Building: { data: buildings, loading: buildingLoading },
    Unit: { data: units, loading: unitLoading },
    Contact: { data: contacts, loading: contactLoading },
    dispatch: contextDispatch,
  } = useContextLoader();
  const { ticketsDetailsDelete } = usePermissions();
  const { clientId, userId, memberId, isOwner } = useUser();

  const ticketsLoading = loading || buildingLoading || unitLoading || contactLoading;

  const setFetchTicket = () => {
    contextDispatch({ type: 'SHOULD_FETCH_TICKET' });
  };

  const tickets = useMemo<Ticket[]>(() => {
    const ticketsReduced = ticketsLoader.reduce((acc: Ticket[], ticket: Ticket) => {
      acc.push({
        ...ticket,
        conversations: resolveOneToMany(ticket.id, ticketsLoader, 'parentId'),
        ...(ticket.buildingId ? { building: resolveOneToOne(ticket.buildingId, buildings, 'id') } : {}),
        ...(ticket.unitId ? { unit: resolveOneToOne(ticket.unitId, units, 'id') } : {}),
        ...(ticket.assignedToId ? { assignedTo: resolveOneToOne(ticket.assignedToId, contacts, 'id') } : {}),
        ...(ticket.contactId ? { contact: resolveOneToOne(ticket.contactId, contacts, 'id') } : {}),
      });
      return acc;
    }, []);

    return ticketsReduced;
  }, [ticketsLoader, buildings, contacts, units]);

  useEffect(() => {
    const fetchAndSet = async () => {
      contextDispatch({ type: 'IS_FETCHING_TICKET' });
      const result = await fetchTickets('byClientId', getTableClientId(clientId!, ENTITY_MODEL_NAME));
      contextDispatch({ type: 'FETCHED_TICKET', payload: { tickets: result } });
    };
    if (shouldFetch) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch]);

  useSubscriptions<OnCreateTicketSubscription, OnUpdateTicketSubscription, OnDeleteTicketSubscription>(
    onCreateTicket,
    onUpdateTicket,
    onDeleteTicket,
    (data) => {
      contextDispatch({
        type: 'ADD_TICKET',
        payload: { ticket: data.onCreateTicket as Ticket },
      });
    },
    (data) => {
      contextDispatch({
        type: 'UPDATE_TICKET',
        payload: { ticket: data.onUpdateTicket as Ticket },
      });
    },
    (data) => {
      const { id } = data.onDeleteTicket as Ticket;
      contextDispatch({
        type: 'DELETE_TICKET',
        payload: { id },
      });
    }
  );

  const { ticketsAssignedToMe, ticketsAssignedToOthers, ticketsUnassigned, ticketsResolved, ticketsArchived } =
    classifyTickets(tickets, memberId!);

  const getTicket = (id: string): Ticket | undefined => {
    if (ticketsLoading) {
      return undefined;
    }
    return tickets.find((t) => t.id === id);
  };
  const getBuildingTickets = (id: string): Ticket[] => {
    if (ticketsLoading) {
      return [];
    }
    return tickets.filter((t) => !isNil(t.building) && !isNil(t.building.id) && t.building.id === id);
  };
  const getUnitTickets = (id: string): Ticket[] => {
    if (ticketsLoading) {
      return [];
    }
    return tickets.filter((t) => !isNil(t.unit) && !isNil(t.unit.id) && t.unit.id === id);
  };

  const getWholeConversationOfTicket = (ticket: Ticket): Ticket | null => {
    if (ticket.parentId) {
      const parentTicket = tickets.find((t) => t.id === ticket.parentId);
      if (!parentTicket) return null;
      return getWholeConversationOfTicket(parentTicket);
    }
    return { ...ticket, conversations: tickets.filter((t) => t.parentId === ticket.id) };
  };

  const createTicket = async (input: Omit<Ticket, 'clientId' | 'readId'>) => {
    const ticket = await mutation<Ticket, CreateMutationVariables>(createMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateInput),
        clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME),
        readId: getReadId(clientId!, ENTITY_MODEL_NAME),
        ...(isOwner && { readers: [userId!] }),
      },
    });
    contextDispatch({ type: 'ADD_TICKET', payload: { ticket } });
    return ticket;
  };

  const updateTicket = async (original: Ticket, updates: Partial<Ticket>) => {
    const result = await mutation<Ticket>(updateMutation, {
      input: { ...cleanInputUpdate({ id: original.id, _version: original._version, ...updates }, false) },
    });
    contextDispatch({ type: 'UPDATE_TICKET', payload: { ticket: result } });
    return result;
  };

  const deleteTicket = async (id: string) => {
    const ticket = getTicket(id)!;
    if (!ticketsDetailsDelete) {
      return ticket;
    }

    await deleteEntityWithFetchBefore<Pick<Ticket, 'id'>, DeleteMutationVariables>({ id }, getQuery, deleteMutation);

    contextDispatch({ type: 'DELETE_TICKET', payload: { id } });
    return ticket;
  };

  const values = useMemo(
    () => ({
      ticketsAll: tickets,
      ticketsAssignedToMe,
      ticketsAssignedToOthers,
      ticketsUnassigned,
      ticketsResolved,
      ticketsArchived,
      getTicket,
      getBuildingTickets,
      getUnitTickets,
      getWholeConversationOfTicket,
      createTicket,
      updateTicket,
      deleteTicket,
      ticketsError: undefined,
      ticketsLoading,
      setFetchTicket,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      tickets,
      ticketsLoading,
      ticketsAssignedToMe,
      ticketsAssignedToOthers,
      ticketsUnassigned,
      ticketsResolved,
      ticketsArchived,
    ]
  );

  return <TicketContext.Provider value={values}>{children}</TicketContext.Provider>;
};
export const useTickets = (): TicketContext => {
  const context = useContext<TicketContext | null>(TicketContext);
  if (context === undefined) {
    throw new Error('`useTickets` hook must be used within a `TicketContextProvider` component');
  }
  return context as TicketContext;
};
