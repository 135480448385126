import { Colors } from '@rentguru/commons-utils';
import { ReactComponent as DeleteIcon } from 'src/icons/delete.svg';

import { CustomIconButton, CustomIconButtonProps } from '@up2rent/ui';

const FieldsDeleteIconButton: React.FC<CustomIconButtonProps> = (props) => (
  <CustomIconButton
    style={{ color: Colors.LIGHT_BLUE_GREY }}
    size="small"
    Icon={DeleteIcon}
    iconStyle={{ fill: Colors.BLUE_GREY }}
    {...props}
  />
);

export default FieldsDeleteIconButton;
