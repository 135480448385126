import React from 'react';
import { Avatar, Typography, Grid, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as CommunicationSvg } from 'src/icons/communication.svg';
import { Colors, getFirstLettersUppercase } from '@rentguru/commons-utils';
import { OverflowableTypography } from '@up2rent/ui';
import { format, formatDistance } from 'date-fns';
import { useLocale, dateLocaleMap, dateLocaleMapType } from 'src/i18n/IntlProviderWrapper';

const useStyles = makeStyles({
  root: {
    padding: '0px 20px 8px 20px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  avatarItem: {
    display: 'flex',
  },
  h6: {
    textAlign: 'left',
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Mulish',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  h7: {
    textAlign: 'left',
    color: Colors.LIGHT_BLUE_GREY,
    fontFamily: 'Mulish',
    fontSize: '14px',
    fontWeight: 'normal',
  },
  date: {
    textAlign: 'right',
    color: Colors.SLATE_GREY,
    fontFamily: 'Mulish',
    fontSize: '14px',
  },
});

interface ContentHeaderMessagesProps {
  name: string;
  color: string;
  title: string;
  request: string;
  date: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const ContentHeaderMessages: React.FC<ContentHeaderMessagesProps> = ({
  name,
  color,
  title,
  children,
  request,
  date,
  style = {},
}) => {
  const classes = useStyles();
  const gridRequestWidth = children ? 3 : 4;
  const gridTitleWidth = children ? 2 : 3;
  const { language } = useLocale();
  const white = Colors.CLASSICAL_WHITE;

  return (
    <Grid container className={classes.root} style={style} spacing={0}>
      <Grid item xs={2} className={classes.avatarItem}>
        <Avatar
          style={{
            fill: white,
            backgroundColor: Colors.SLATE_GREY,
            paddingBottom: 1,
            paddingTop: 1,
            paddingLeft: 1,
            paddingRight: 1,
            borderRadius: 50,
          }}
        >
          <CommunicationSvg />
        </Avatar>
        {name && (
          <Avatar
            style={{
              color: `${color}`,
              backgroundColor: white,
              border: `1.5px ${color} solid`,
              boxShadow: `0px 0px 0px 2px ${white}`,
              fontSize: '14px',
              fontWeight: 'bold',
              marginLeft: -3,
            }}
          >
            {getFirstLettersUppercase(name)}
          </Avatar>
        )}
      </Grid>
      <Grid item xs={gridTitleWidth}>
        <Typography color="inherit" className={classes.h6}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={gridRequestWidth}>
        <OverflowableTypography color="inherit" className={classes.h7} text={request} />
      </Grid>
      {children && (
        <Grid item xs={2} className={classes.h7}>
          {children}
        </Grid>
      )}
      <Grid item xs={3}>
        <Tooltip
          placement="top"
          title={format(new Date(date), 'dd/MM/yyyy', {
            locale: (dateLocaleMap as dateLocaleMapType)[language],
          })}
        >
          <Typography color="inherit" className={classes.date}>
            {formatDistance(new Date(date), new Date(), {
              includeSeconds: true,
              addSuffix: true,
              locale: (dateLocaleMap as dateLocaleMapType)[language],
            })}
          </Typography>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default ContentHeaderMessages;
