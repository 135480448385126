import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// eslint-disable-next-line import/named
import Paper, { PaperProps } from '@material-ui/core/Paper';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    textAlign: 'center',
    marginBottom: 30,
    overflowX: 'auto',
    boxShadow: '0 5px 25px 0 rgba(0, 0, 0, 0.15)',
  },
});

interface ElevatedPaperTableProps extends PaperProps {}

export const ElevatedPaperTable: React.FC<ElevatedPaperTableProps> = ({ children, style, ...rest }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper} style={style || {}} elevation={10} {...rest}>
      {children}
    </Paper>
  );
};
