import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { isNil } from 'lodash';
import { TableCell, TableRow, useMediaQuery } from '@material-ui/core';
import { LeaseStatus, Colors } from '@rentguru/commons-utils';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { ColoredBox } from '@up2rent/ui';
import MoreHorizontalIconButton from 'src/components/ui/CustomIconButtons/MoreHorizontalIconButton';
import { LeaseRowProps } from 'src/components/Leases/Leases';
import { fromStatusToColor } from 'src/components/Leases/Details/LeaseDetail';
import LeaseDatesColumn from './LeaseDatesColumn';
import LeaseMainUnitColumn from './LeaseMainUnitColumn';
import LeaseTenantsColumn from './LeaseTenantsColumn';
import LeaseTypeColumn from './LeaseTypeColumn';
import LeaseSignaturePopoverColumn from './LeaseSignaturePopoverColumn';

const LeaseDraftRow: React.FC<LeaseRowProps> = ({
  isSelected,
  handleDetail,
  actionMenu,
  id,
  name,
  status,
  type,
  tenants,
  units,
  startDate,
  endDate,
}) => {
  const { formatMessage } = useIntl();
  const { leasesCreationWrite, leasesCreationDelete } = usePermissions();
  const [openTenants, setOpenTenants] = useState<boolean>(false);
  const [openSignatureResult, setOpenSignatureResult] = useState<boolean>(false);
  const [unitListAnchorEl, setUnitListAnchorEl] = useState<HTMLElement | null>(null);
  const closeUnitListPopper = () => {
    setUnitListAnchorEl(null);
  };

  const veryLargeSpace = useMediaQuery('(min-width:1500px)');

  const canChangeLease = leasesCreationWrite || leasesCreationDelete;

  const statusText = formatMessage({ id: `enums.LeaseStatus.${status}` });
  const statusColor = fromStatusToColor(status as LeaseStatus);

  return (
    <TableRow
      hover
      aria-checked={isSelected}
      tabIndex={-1}
      key={id}
      selected={isSelected}
      style={{ paddingTop: 10 }}
      onClick={() => {
        if (!isNil(handleDetail)) {
          handleDetail(id);
        }
      }}
    >
      <TableCell
        component="th"
        scope="row"
        padding="normal"
        style={{ fontWeight: 600, color: Colors.CLASSICAL_BLACK, padding: 0, height: 80, paddingLeft: 30 }}
      >
        {name}
      </TableCell>
      <TableCell
        padding="normal"
        style={{ padding: 0, height: 80 }}
        onMouseEnter={(e) => setUnitListAnchorEl(e.currentTarget)}
        onMouseLeave={closeUnitListPopper}
      >
        <LeaseMainUnitColumn
          unitLeases={units ?? []}
          anchorEl={unitListAnchorEl}
          open={!isNil(unitListAnchorEl)}
          handleClose={closeUnitListPopper}
        />
      </TableCell>
      <TableCell padding="none" style={{ padding: 0, height: 80 }}>
        <LeaseDatesColumn startDate={startDate} endDate={endDate} />
      </TableCell>
      <TableCell padding="normal" style={{ padding: 0, height: 80 }}>
        <LeaseTypeColumn type={type} />
      </TableCell>
      <TableCell
        padding="normal"
        style={{ padding: 0, height: 80, ...(!veryLargeSpace ? { maxWidth: 150 } : {}) }}
        onMouseEnter={() => setOpenTenants(true)}
        onMouseLeave={() => setOpenTenants(false)}
      >
        <LeaseTenantsColumn tenants={tenants} startDate={startDate} endDate={endDate} open={openTenants} />
      </TableCell>
      <TableCell
        padding="none"
        style={{
          padding: 0,
          height: 80,
          paddingRight: canChangeLease ? 0 : 30,
        }}
        onMouseEnter={() => setOpenSignatureResult(true)}
        onMouseLeave={() => setOpenSignatureResult(false)}
      >
        <ColoredBox boxColor={statusColor}>{statusText}</ColoredBox>
        {openSignatureResult && status === LeaseStatus.OutForSignature && <LeaseSignaturePopoverColumn leaseId={id} />}
      </TableCell>

      {canChangeLease && (
        <TableCell
          padding="none"
          style={{
            padding: 0,
            paddingRight: 30,
            height: 80,
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
          }}
        >
          <MoreHorizontalIconButton
            style={{ height: 'fit-content' }}
            onClick={(e) => {
              e.stopPropagation();
              if (!isNil(actionMenu)) {
                actionMenu(e, id);
              }
            }}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

export default LeaseDraftRow;
