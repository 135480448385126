import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { getCacheItem, setCacheItem } from 'src/utils/cacheUtils';

const UP2RENT_VERSION = 9;
const UP2RENT_VERSION_CACHE_NAME = 'UP2RENT_VERSION';

export function useUp2RentVersion() {
  const [shouldClearCache, setShouldClearCache] = useState<boolean>(false);

  useEffect(() => {
    const updateCacheVersionIfNeeded = async () => {
      const currentVersion = await getCacheItem(UP2RENT_VERSION_CACHE_NAME);
      setShouldClearCache(Boolean(!isNil(currentVersion) && currentVersion !== UP2RENT_VERSION));
      if (isNil(currentVersion)) {
        await setCacheItem(UP2RENT_VERSION_CACHE_NAME, UP2RENT_VERSION);
      }
    };
    updateCacheVersionIfNeeded();
  }, []);

  const clearCache = async () => {
    await setCacheItem(UP2RENT_VERSION_CACHE_NAME, UP2RENT_VERSION);
    setShouldClearCache(false);
    window.location.reload();
  };

  return { shouldClearCache, clearCache };
}
