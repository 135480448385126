import React from 'react';
import * as Yup from 'yup';
import { FormikHelpers, Formik, Form } from 'formik';
import isNil from 'lodash/isNil';
import { useIntl } from 'react-intl';
import { Divider } from '@material-ui/core';
import { ContentHeader, LoaderButton , ActionButton } from '@up2rent/ui';
import toUpper from 'lodash/toUpper';
import { useUsers } from '../../../hooks/UsersContext';
import TeamSelectorInput from '../../ui/Forms/FormSection/TeamSelectorInput';
import RoleSelectorForm from '../../ui/Forms/FormSection/RoleSelectorForm';
import { useUserRoles } from '../../../hooks/UserRolesContext';
import { useTeams } from '../../../hooks/TeamsContext';
import { Colors } from '@rentguru/commons-utils';

interface EditMemberFormValues {
  teamId: string;
  parentId?: string; // Needed for teamselector
  roleId: string;
}

const EditMemberSchema = Yup.object()
  .shape({
    roleId: Yup.string().required(),
    teamId: Yup.string().required(),
  })
  .required();

interface EditMemberProps {
  afterSubmit?: () => void;
  id: string;
}

const EditMember: React.FC<EditMemberProps> = ({ afterSubmit, id }) => {
  const { formatMessage } = useIntl();
  const { getUser, updateUser, createUserTeam, deleteUserTeam } = useUsers();
  const { getTeam } = useTeams();
  const { getUserRole } = useUserRoles();

  const original = getUser(id);
  if (isNil(original)) {
    return null;
  }
  const handleUpdate = async (
    values: EditMemberFormValues,
    { setSubmitting, setStatus }: FormikHelpers<EditMemberFormValues>
  ) => {
    const role = getUserRole(values.roleId);
    const user = await updateUser(original!, {
      userRoleId: role!.id,
      email: original.email,
      clientId: original.clientId,
    });
    if (original!.teams![0]!.team!.id !== values.teamId) {
      const userTeam = original!.teams![0]!;
      if (!isNil(userTeam)) await deleteUserTeam(userTeam.id);
      const team = getTeam(values.teamId);
      await createUserTeam({ userId: user.id, teamId: team!.id });
    }

    setStatus(true);
    setSubmitting(false);
    if (!isNil(afterSubmit)) {
      afterSubmit();
    }
  };

  const initialValues: EditMemberFormValues = {
    roleId: original.userRole ? original.userRole.id : '',
    teamId: original.teams && original.teams[0] && original.teams[0].team ? original.teams[0].team!.id : '',
    parentId: original.teams && original.teams[0] && original.teams[0].team ? original.teams[0].team!.id : '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={EditMemberSchema}
      onSubmit={handleUpdate}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, status, isSubmitting, setFieldValue }) => (
        <Form>
          <ContentHeader title={formatMessage({ id: 'settings.editMember.menu' })} />
          <div style={{ marginLeft: 30, marginRight: 30 }}>
            <div style={{ alignItems: 'baseline' }}>
              <RoleSelectorForm
                fieldName="roleId"
                required
                inputStyle={{ width: 280, maxWidth: 'none' }}
                initialValue={values.roleId}
              />
            </div>
            <div style={{ alignItems: 'baseline' }}>
              <TeamSelectorInput
                label={formatMessage({ id: 'settings.addMember.team' })}
                onChange={(teamId: string) => setFieldValue('teamId', teamId)}
              />
            </div>
          </div>
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          <div
            style={{
              marginRight: 30,
              marginBottom: 20,
              display: 'flex',
              float: 'right',
              alignItems: 'center',
            }}
          >
            <ActionButton
              onClick={() => {
                if (!isNil(afterSubmit)) {
                  afterSubmit();
                }
              }}
              style={{
                background: 'none',
                color: Colors.DARK_SLATE_GREY,
                marginRight: 20,
              }}
            >
              {toUpper(
                formatMessage({
                  id: 'cancel',
                })
              )}
            </ActionButton>
            <LoaderButton data-test="editMember" loading={isSubmitting} success={status}>
              {formatMessage({ id: 'save' })}
            </LoaderButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditMember;
