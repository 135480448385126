/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useRef, useState } from 'react';
import {
  RepartitionKey,
  syncRepartitionKeys,
  getRepartitionKey as getQuery,
  createRepartitionKey as createMutation,
  updateRepartitionKey as updateMutation,
  deleteRepartitionKey as deleteMutation,
  CreateRepartitionKeyMutationVariables,
  DeleteRepartitionKeyMutationVariables,
  CreateRepartitionKeyInput,
  UpdateRepartitionKeyInput,
  cleanInputUpdate,
  cleanInputCreate,
  getReadId,
  getTableClientId,
} from '@rentguru/commons-utils';
import { deleteEntityWithFetchBefore, list, mutation } from '@up2rent/fetch-utils';
import { useUser } from './UserContext';

export interface RepartitionKeyContext {
  repartitionKeysLoading: boolean;
  repartitionKeys: RepartitionKey[];
  getRepartitionKey: (id: string) => RepartitionKey | undefined;
  createRepartitionKey: (input: Omit<CreateRepartitionKeyInput, 'clientId' | 'readId'>) => Promise<RepartitionKey>;
  updateRepartitionKey: (updates: UpdateRepartitionKeyInput) => Promise<RepartitionKey>;
  deleteRepartitionKey: (repartitionKey: RepartitionKey) => Promise<RepartitionKey>;
}

export const fetchRepartitionKeys = async (clientId: string): Promise<RepartitionKey[]> => {
  return await list<RepartitionKey>(syncRepartitionKeys, 'clientId', getTableClientId(clientId!, 'RepartitionKey'));
};

export const fetchTechnicRepartitionKeys = async (technicId: string, clientId: string): Promise<RepartitionKey[]> => {
  const repartitionsKeys = await fetchRepartitionKeys(clientId);
  return repartitionsKeys.filter(
    (repartitonKey) => repartitonKey.technics && repartitonKey.technics.includes(technicId)
  );
};

export const useRepartitionKeys = (shouldFetchRepartitionKey: boolean = false): RepartitionKeyContext => {
  const [repartitionKeysLoading, setRepartitionKeysLoading] = useState<boolean>(false);
  const [repartitionKeys, setRepartitionKeys] = useState<RepartitionKey[]>([]);
  const firstRender = useRef<boolean>(false);
  const { clientId, isOwner, userId } = useUser();

  useEffect(() => {
    const buildRepartitionKeys = async () => {
      setRepartitionKeysLoading(true);
      firstRender.current = true;
      const result = await fetchRepartitionKeys(clientId!);
      setRepartitionKeys(result);
      setRepartitionKeysLoading(false);
    };
    if (shouldFetchRepartitionKey && !firstRender.current) buildRepartitionKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchRepartitionKey, firstRender]);

  const getRepartitionKey = (id: string): RepartitionKey | undefined => {
    return repartitionKeys.find((repartitionKey) => repartitionKey.id === id);
  };

  const createRepartitionKey = async (
    input: Omit<CreateRepartitionKeyInput, 'clientId' | 'readId'>
  ): Promise<RepartitionKey> => {
    const repartitionKey = await mutation<RepartitionKey, CreateRepartitionKeyMutationVariables>(createMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateRepartitionKeyInput),
        readId: getReadId(clientId!, 'RepartitionKey'),
        clientId: getTableClientId(clientId!, 'RepartitionKey'),
        ...(isOwner && { writers: [userId!] }),
      },
    });
    setRepartitionKeys((m) => [...m, repartitionKey]);
    return repartitionKey;
  };

  const updateRepartitionKey = async (updates: UpdateRepartitionKeyInput): Promise<RepartitionKey> => {
    const result = await mutation<RepartitionKey>(updateMutation, {
      input: cleanInputUpdate(updates, false),
    });
    setRepartitionKeys((repartitionKeys) =>
      repartitionKeys.map((repartitionKey) => {
        if (repartitionKey.id === result.id) return result;
        return repartitionKey;
      })
    );
    return result;
  };

  const deleteRepartitionKey = async (repartitionKey: RepartitionKey): Promise<RepartitionKey> => {
    const result = await deleteEntityWithFetchBefore<RepartitionKey, DeleteRepartitionKeyMutationVariables>(
      repartitionKey,
      getQuery,
      deleteMutation
    );
    setRepartitionKeys(repartitionKeys.filter((m) => m.id !== repartitionKey.id));
    return result;
  };

  return {
    repartitionKeysLoading,
    repartitionKeys,
    getRepartitionKey,
    createRepartitionKey,
    updateRepartitionKey,
    deleteRepartitionKey,
  };
};
