import React from 'react';
import * as Yup from 'yup';
import { FormikHelpers, Formik, Form } from 'formik';
import isNil from 'lodash/isNil';
import { useIntl } from 'react-intl';
import { Divider } from '@material-ui/core';
import { ContentHeader, LoaderButton , ActionButton } from '@up2rent/ui';
import toUpper from 'lodash/toUpper';
import { useTeams } from '../../../../hooks/TeamsContext';
import { Building, Colors } from '@rentguru/commons-utils';
import TeamSelectorField from '../../../ui/Forms/FormField/TeamSelectorField';
import { useBuildings } from '../../../../hooks/BuildingsContext';
import { getCompleteTeams, getLeafTeam, getTeamParents } from 'src/utils/teamUtils';
import { Skeleton } from '@material-ui/lab';

interface SwitchBuildingTeamFormValues {
  teamId: string;
}

const SwitchBuildingTeamSchema = Yup.object()
  .shape({
    teamId: Yup.string().min(1).required(),
  })
  .required();

interface SwitchBuildingTeamFormProps {
  building: Building;
  onClose?: (teamId?: string) => void;
}

const SwitchBuildingTeamForm: React.FC<SwitchBuildingTeamFormProps> = ({ building, onClose }) => {
  const { formatMessage } = useIntl();
  const { getTreeTeams, getTeam, teams } = useTeams();
  const { updateBuilding } = useBuildings();

  const buildingTeam = getLeafTeam(getCompleteTeams(building.teams!, getTeam));
  if (isNil(buildingTeam)) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton />
      </div>
    );
  }
  const initialValues: SwitchBuildingTeamFormValues = {
    teamId: buildingTeam.id,
  };

  const handleCreate = async (
    values: SwitchBuildingTeamFormValues,
    { setSubmitting, setStatus }: FormikHelpers<SwitchBuildingTeamFormValues>
  ) => {
    const { teamId } = values;
    if (teamId !== buildingTeam.id) {
      const newTeamIds = [teamId];
      getTeamParents(getTeam(teamId)!, teams, newTeamIds);
      await updateBuilding(building, { teams: newTeamIds });
    }
    setStatus(true);
    setSubmitting(false);
    if (!isNil(onClose)) {
      onClose(values.teamId);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SwitchBuildingTeamSchema}
      onSubmit={handleCreate}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ status, setFieldValue, isSubmitting }) => {
        return (
          <Form>
            <ContentHeader title={formatMessage({ id: 'settings.editTeam.moveBuildingToTeam' })} />
            <div style={{ paddingLeft: 30, paddingRight: 30 }}>
              <TeamSelectorField
                teams={getTreeTeams()}
                initTeam={buildingTeam.id}
                onChange={(teamId: string) => setFieldValue('teamId', teamId)}
              />
            </div>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <div
              style={{
                marginRight: 30,
                marginBottom: 20,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <ActionButton
                onClick={() => {
                  if (!isNil(onClose)) {
                    onClose();
                  }
                }}
                style={{
                  background: 'none',
                  color: Colors.DARK_SLATE_GREY,
                  marginRight: 20,
                }}
              >
                {toUpper(
                  formatMessage({
                    id: 'cancel',
                  })
                )}
              </ActionButton>
              <LoaderButton loading={isSubmitting} success={status}>
                {formatMessage({
                  id: 'save',
                })}
              </LoaderButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SwitchBuildingTeamForm;
