import React from 'react';
import { Typography, Toolbar, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Colors } from '@rentguru/commons-utils';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  h6: {
    flex: 1,
    textAlign: 'left',
    color: Colors.CLASSICAL_BLACK,
    fontSize: '20px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 'calc(100% - 250px)',
    textOverflow: 'ellipsis',
  },
});

interface ContentHeaderProps {
  title: string;
  handleBack?: () => void;
  toolbarStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entityIcon?: any | null;
  children?: React.ReactNode;
}

export const ContentHeader: React.FC<ContentHeaderProps> = ({
  title,
  handleBack,
  entityIcon: EntityIcon,
  toolbarStyle = {},
  textStyle = {},
  children,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Toolbar style={toolbarStyle}>
        {handleBack && (
          <IconButton style={{ marginRight: 20 }} aria-label="Menu" onClick={handleBack}>
            <ArrowBack style={{ fill: Colors.BLUE_GREY }} />
          </IconButton>
        )}
        {EntityIcon && <EntityIcon style={{ marginRight: 20, fill: Colors.BLUE_GREY }} />}
        <Typography variant="h6" color="inherit" className={classes.h6} style={textStyle}>
          {title}
        </Typography>
        {children}
      </Toolbar>
    </div>
  );
};
