import React, { useState } from 'react';
import { ElevatedPaper, ContentHeader, ActionButton, ConfirmDialog } from '@up2rent/ui';
import { useIntl } from 'react-intl';
import { Divider, Typography } from '@material-ui/core';
import AddEditAccountLabelDialog from '../ui/Dialogs/AddEditAccountLabelDialog';
import { AccountLabel, ACCOUNT_CHARGES_TOPCLASS, ModelWithVersion } from '@rentguru/commons-utils';
import AccountLabelRow from './AccountLabels/AccountLabelRow';
import AccountLabelHeader from './AccountLabels/AccountLabelHeader';
import { useTransactions } from 'src/hooks/TransactionsContext';
import isNil from 'lodash/isNil';
import { isEmpty } from 'lodash';

const AccountLabelSettings: React.FC = () => {
  const { formatMessage } = useIntl();
  const { accountLabels, transactionsLoading, createAccountLabel, updateAccountLabel } = useTransactions();
  const [dialogAddEditAccountLabel, setDialogAddEditAccountLabel] = useState<AccountLabel | boolean>(false);
  const [disableAccountLabel, setDisableAccountLabel] = useState<AccountLabel | null>(null);

  const handleAddAccountLabelOpen = () => {
    setDialogAddEditAccountLabel(true);
  };

  const handleAddEditAccountLabelClose = () => {
    setDialogAddEditAccountLabel(false);
  };

  const handleEditAccountLabelOpen = (accountLabel: AccountLabel) => {
    setDialogAddEditAccountLabel(accountLabel);
  };

  const handleDisableAccountLabelOpen = (accountLabel: AccountLabel) => {
    setDisableAccountLabel(accountLabel);
  };

  if (transactionsLoading) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{formatMessage({ id: 'loading' })}</Typography>
      </ElevatedPaper>
    );
  }
  // Only charges
  const [chargeAccountLabels, disabledChargeAccountLabels] = accountLabels.reduce(
    (acc: [AccountLabel[], AccountLabel[]], accountLabel) => {
      if (Math.floor(accountLabel.topClass / 100000) === 6) {
        if (accountLabel.disabled) acc[1].push(accountLabel);
        else {
          acc[0].push(accountLabel);
        }
      }
      return acc;
    },
    [[], []]
  );

  chargeAccountLabels.sort((al1, al2) => (al1.class > al2.class ? 1 : -1));
  disabledChargeAccountLabels.sort((al1, al2) => (al1.class > al2.class ? 1 : -1));

  return (
    <>
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <ContentHeader title={formatMessage({ id: 'settings.chargeAccountMenu' })}>
          <ActionButton onClick={() => handleAddAccountLabelOpen()}>{formatMessage({ id: 'add' })}</ActionButton>
        </ContentHeader>
        <div style={{ paddingLeft: 30, paddingRight: 30 }}>
          <AccountLabelHeader />
          {chargeAccountLabels.map((accountLabel, index) => (
            <AccountLabelRow
              key={accountLabel.id}
              accountLabel={accountLabel}
              divider={index !== chargeAccountLabels.length - 1}
              openEditDialog={handleEditAccountLabelOpen}
              openDisableDialog={handleDisableAccountLabelOpen}
            />
          ))}
          {!isEmpty(disabledChargeAccountLabels) && (
            <>
              <Typography
                variant="h6"
                style={{ marginTop: 30, marginBottom: 15, fontSize: 14, fontWeight: 700, textAlign: 'left' }}
              >
                {formatMessage({ id: 'accounting.accountLabel.disabledAccounts' })}
              </Typography>
              <Divider style={{ marginLeft: -30, marginRight: -30 }} />
              {disabledChargeAccountLabels.map((accountLabel, index) => (
                <AccountLabelRow
                  key={accountLabel.id}
                  accountLabel={accountLabel}
                  divider={index !== disabledChargeAccountLabels.length - 1}
                  openEditDialog={handleEditAccountLabelOpen}
                  openDisableDialog={handleDisableAccountLabelOpen}
                  disabled={true}
                />
              ))}
            </>
          )}
        </div>
      </ElevatedPaper>
      {dialogAddEditAccountLabel && (
        <AddEditAccountLabelDialog
          open={(dialogAddEditAccountLabel as boolean) !== false}
          onClose={handleAddEditAccountLabelClose}
          afterSubmit={() => {
            handleAddEditAccountLabelClose();
          }}
          createAccountLabel={createAccountLabel}
          updateAccountLabel={updateAccountLabel}
          title={
            typeof dialogAddEditAccountLabel === 'boolean'
              ? formatMessage({ id: 'accounting.accountLabel.titleAddChargeType' })
              : formatMessage({ id: 'accounting.accountLabel.titleEditChargeType' })
          }
          topClass={ACCOUNT_CHARGES_TOPCLASS}
          accountLabel={typeof dialogAddEditAccountLabel !== 'boolean' ? dialogAddEditAccountLabel : undefined}
          allAccountLabels={accountLabels}
        />
      )}
      {disableAccountLabel && (
        <ConfirmDialog
          mainText={formatMessage({ id: 'accounting.accountLabel.disableConfirmation' })}
          confirmText={formatMessage({ id: 'disable' })}
          open={!isNil(disableAccountLabel)}
          confirmAction={async () => {
            await updateAccountLabel({
              id: disableAccountLabel.id,
              disabled: true,
              _version: (disableAccountLabel as ModelWithVersion<AccountLabel>)._version,
            });
            setDisableAccountLabel(null);
          }}
          cancelAction={() => setDisableAccountLabel(null)}
          formatMessage={formatMessage}
        />
      )}
    </>
  );
};

export default AccountLabelSettings;
