/* eslint-disable no-console */
/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
import React, { useState } from 'react';
import { Formik, FormikHelpers, Form } from 'formik';
import * as Yup from 'yup';
import { checkVAT, countries } from '@accountable/jsvat';
import isNil from 'lodash/isNil';
import { useUser, UserContext } from 'src/hooks/UserContext';
import { ElevatedPaper } from '@up2rent/ui';
import { CountryCode } from 'src/components/ui/CountrySelector';
import SelectAccount from './SelectAccount';
import CreateClient from './CreateClient';
import { AccountType } from '@rentguru/commons-utils';
import { AuthProps, FormType } from 'src/App';
import { authStyles } from './authStyles';
import { Grid } from '@material-ui/core';

const NOT_VALID_VAT = 'VAT is not valid';
const CreateClientSchema = Yup.object().shape({
  companyName: Yup.string().when('privateUser', {
    is: false,
    then: Yup.string().min(1).required(),
    otherwise: Yup.string().nullable(),
  }),
  vat: Yup.string().when('privateUser', {
    is: false,
    then: Yup.string().test('validVat', NOT_VALID_VAT, function (value) {
      const vatResult = checkVAT(value!, countries);
      return isNil(value) || vatResult.isValid;
    }),
    otherwise: Yup.string().nullable(),
  }),
  businessNumber: Yup.string().when('privateUser', {
    is: false,
    then: Yup.string().min(4).required(),
    otherwise: Yup.string().nullable(),
  }),
});

const removeVATExtraChars = (vat = '') => {
  return vat
    .toString()
    .toUpperCase()
    .replace(/(\s|-|\.)+/g, '');
};
export interface CreateClientFormValues {
  companyName: string;
  vat: string;
  businessNumber: string;
  address: {
    country: CountryCode;
    postalCode: string;
    street: string;
    number: string;
    box: string;
    city: string;
  };
  privateUser: boolean;
  accountType: AccountType;
  vatLiable: boolean;
}

const getIdentityProvider = (userAttributes: UserContext['userAttributes'] | null) => {
  if (isNil(userAttributes?.identities)) {
    return 'cognito';
  }
  const isGoogle = (
    userAttributes.identities as unknown as { providerName: 'Google' | 'Facebook' }[] | undefined
  )?.some((identity) => identity.providerName === 'Google');
  if (isGoogle) {
    return 'google';
  }
  return 'facebook';
};

const CreateAccount: React.FC<AuthProps> = ({ updateFormType }) => {
  const { createClientUserAndTeam, countryCode, userAttributes } = useUser();
  const [createAccountStep, setCreateAccountStep] = useState<number>(0);
  const styles = authStyles();

  const goToNextStep = () => {
    setCreateAccountStep(createAccountStep + 1);
  };

  const handleSubmit = async (
    values: CreateClientFormValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<CreateClientFormValues>
  ) => {
    const companyName = values.companyName;
    const vat = values.privateUser ? '' : removeVATExtraChars(values.vat);
    const businessNumber = values.businessNumber;
    const address = values.address;
    const firstName = userAttributes?.given_name ?? '';
    const lastName = userAttributes?.family_name ?? '';
    const email = userAttributes?.email ?? '';

    const phoneNumber = userAttributes?.phone_number;
    const provider = getIdentityProvider(userAttributes);

    const accountType = values.accountType;
    try {
      updateFormType(FormType.LOADING);
      // Confirmation
      await createClientUserAndTeam(
        companyName,
        vat,
        businessNumber,
        address,
        firstName,
        lastName,
        email,
        provider,
        accountType,
        phoneNumber
      );
      setStatus(true);
      setSubmitting(false);
    } catch (err) {
      console.error('errr', err);
      setErrors({ privateUser: err as string });
      setSubmitting(false);
    }
  };

  const initialValues: CreateClientFormValues = {
    companyName: '',
    vat: '',
    vatLiable: false,
    businessNumber: '',
    address: {
      country: countryCode as CountryCode,
      postalCode: '',
      street: '',
      number: '',
      box: '',
      city: '',
    },
    privateUser: true,
    accountType: AccountType.OWNER,
  };

  return (
    <Grid alignContent="center" alignItems="center" container direction="column">
      <Formik
        initialValues={initialValues}
        validationSchema={CreateClientSchema}
        onSubmit={handleSubmit}
        validateOnChange
      >
        {({ values, setValues }) => {
          const defaultTab = values.accountType === AccountType.AGENCY ? 1 : undefined;
          const goToInitialStep = () => {
            setValues({ ...initialValues, accountType: values.accountType });
            setCreateAccountStep(0);
          };
          return (
            <ElevatedPaper className={styles.createAccountCointainer}>
              <Form>
                {createAccountStep === 0 && <SelectAccount goToNextStep={goToNextStep} />}
                {createAccountStep === 1 && <CreateClient defaultTab={defaultTab} goToPreviousStep={goToInitialStep} />}
              </Form>
            </ElevatedPaper>
          );
        }}
      </Formik>
    </Grid>
  );
};

export default CreateAccount;
