import { Divider, Grid } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import FormHeader from '../../FormHeader';
import SurfaceOfUnitFields from '../FormField/SurfaceOfUnitFields';

interface SurfaceOfUnitProps {
  fieldName?: string;
}

const SurfaceOfUnit: React.FC<SurfaceOfUnitProps> = ({ fieldName = 'surface' }) => {
  const { formatMessage } = useIntl();
  return (
    <Grid>
      <FormHeader title={formatMessage({ id: 'rentalUnit.detail.general.surface' })} />
      <Divider style={{ marginBottom: 10 }} />
      <SurfaceOfUnitFields fieldName={fieldName} />
    </Grid>
  );
};

export default SurfaceOfUnit;
