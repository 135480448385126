import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useLeases } from 'src/hooks/LeasesContext';
import { LeaseStatus } from '@rentguru/commons-utils';
import { RouteDestination } from '../../Routes/Routes';
import {
  getDefaultLeasesTab,
  getTabAssignedToSelectedLease,
  isLeaseDetailOpen,
  leasesNavItems,
  LeasesTableProps,
  LEASES_DETAIL_TO,
} from '../Leases';

const LeasesFilters: React.FC<LeasesTableProps> = (props) => {
  const { getLease } = useLeases();
  const match = useRouteMatch<{ tabName?: string; id?: string }>({
    path: `${RouteDestination.LEASES}/:tabName?/:id?`,
  });

  if (!match) {
    return null;
  }
  const defaultTab = getDefaultLeasesTab();
  if (!defaultTab) {
    return null;
  }

  const openDetail = isLeaseDetailOpen(match);

  return (
    <Switch>
      <Route
        path={`${RouteDestination.LEASES}/${LEASES_DETAIL_TO}`}
        key="detail"
        render={() => {
          if (!openDetail) {
            return null;
          }
          const selectedLease = getLease(match.params!.id!);
          if (!selectedLease) {
            return null;
          }
          const tabAssignedForLease = getTabAssignedToSelectedLease(selectedLease.status as LeaseStatus);
          if (!tabAssignedForLease.filterComponent) {
            return null;
          }
          return <tabAssignedForLease.filterComponent {...props} />;
        }}
      />
      <Route
        path={RouteDestination.LEASES}
        exact={true}
        key="default"
        render={() => {
          if (!defaultTab.filterComponent) {
            return null;
          }
          return <defaultTab.filterComponent {...props} />;
        }}
      />
      {leasesNavItems.map((navItem) => {
        const FilterComponent = navItem.filterComponent;
        if (!FilterComponent || navItem.isDetail || !navItem.to) {
          return null;
        }

        const path = match.path.replace(':tabName?', navItem.to);
        return (
          <Route
            path={path}
            key={navItem.to}
            render={() => {
              return <FilterComponent {...props} />;
            }}
          />
        );
      })}
    </Switch>
  );
};

export default LeasesFilters;
