import { CircularProgress, IconButton, type IconButtonProps, type SvgIconProps } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import { CSSProperties, forwardRef } from 'react';
import { stopPropagation } from '../../utils';

export interface CustomIconButtonProps extends IconButtonProps {
  href?: string;
  target?: string;
  iconStyle?: CSSProperties;
  iconTransform?: string;
  disabledIconStyle?: CSSProperties;
}

export interface BaseCustomIconButtonProps extends CustomIconButtonProps {
  Icon: React.FC<SvgIconProps> | React.FC<React.SVGProps<SVGSVGElement>> | typeof CircularProgress;
}

// eslint-disable-next-line react/display-name
export const CustomIconButton = forwardRef<HTMLButtonElement, BaseCustomIconButtonProps>(
  ({ disabled = false, disabledIconStyle, iconStyle, iconTransform, Icon, ...rest }, ref) => {
    const disabledStyle = { fill: Colors.SILVER, ...disabledIconStyle };
    return (
      <IconButton onMouseDown={stopPropagation} disabled={disabled} ref={ref} {...rest}>
        <Icon style={disabled ? disabledStyle : iconStyle} transform={iconTransform} />
      </IconButton>
    );
  }
);
