import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useTickets } from '../../hooks/TicketsContext';
import { Ticket } from '@rentguru/commons-utils';

interface DeleteTicketProps {
  afterSubmit: () => void;
  ticket: Ticket;
}

const DeleteTicket: React.FC<DeleteTicketProps> = ({ afterSubmit, ticket }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { deleteTicket } = useTickets();
  const handleClose = () => {
    afterSubmit();
    history.goBack();
  };
  const handleCancel = () => {
    afterSubmit();
  };

  return (
    <>
      <DialogTitle id="alert-dialog-slide-title">{formatMessage({ id: 'tickets.deleteTicket.title' })}</DialogTitle>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {formatMessage({ id: 'tickets.deleteTicket.cancelLabel' })}
        </Button>
        <Button
          onClick={async (e) => {
            e.preventDefault();
            await deleteTicket(ticket.id);
            handleClose();
          }}
          color="primary"
        >
          {formatMessage({ id: 'tickets.deleteTicket.validateLabel' })}
        </Button>
      </DialogActions>
    </>
  );
};

export default DeleteTicket;
