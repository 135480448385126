import React from 'react';
import isNil from 'lodash/isNil';
import { Typography, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Colors } from '@rentguru/commons-utils';
import { CustomIconButton } from '@up2rent/ui';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  client: {
    textAlign: 'left',
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Mulish',
    fontSize: '20px',
    fontWeight: 'bold',
    marginRight: 10,
  },
  request: {
    flex: 3,
    textAlign: 'left',
    color: Colors.LIGHT_BLUE_GREY,
    fontFamily: 'Mulish',
    fontSize: '20px',
    fontWeight: 'normal',
    marginLeft: 10,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
});

interface ContentHeaderTabDetailsProps {
  mainText: string;
  subText?: string;
  goBackFunction?: () => void;
  children?: React.ReactNode;
}

const ContentHeaderTabDetails: React.FC<ContentHeaderTabDetailsProps> = ({
  mainText,
  subText,
  goBackFunction,
  children,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Toolbar style={{ zIndex: 1 }}>
        {!isNil(goBackFunction) && (
          <CustomIconButton
            style={{ marginLeft: -12, marginRight: 20 }}
            aria-label="Menu"
            onClick={() => goBackFunction()}
            Icon={ArrowBack}
          />
        )}
        <Typography color="inherit" className={classes.client}>
          {mainText}
        </Typography>
        <Typography color="inherit" className={classes.request}>
          {subText}
        </Typography>
        {children}
      </Toolbar>
    </div>
  );
};

export default ContentHeaderTabDetails;
