import React from 'react';
import { useIntl } from 'react-intl';
import toUpper from 'lodash/toUpper';
import { ActionButton , LoaderButton } from '@up2rent/ui';
import UnitInventorySelectorField from '../../ui/Forms/FormField/UnitInventorySelectorField';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTechnics } from '../../../hooks/TechnicsContext';
import { useUnitInventories } from '../../../hooks/UnitInventoryContext';
import * as Yup from 'yup';
import FormikDatePicker from '../../ui/FormikDatePicker';
import { UnitInventory, Technic, Colors } from '@rentguru/commons-utils';
import { Divider, Typography } from '@material-ui/core';

interface EditDetectorProps {
  detector: Technic;
  entity: 'building' | 'unit';
  afterSubmit: () => void;
  cancelSubmit: () => void;
}

const EditDetectorSchema = Yup.object().shape({
  detectorLastControlDate: Yup.date().required(),
  detectorUnitInventoryId: Yup.string().nullable(),
});

interface DetectorFormValues {
  detectorLastControlDate: Date;
  detectorUnitInventoryId: string;
}

const EditDetector: React.FC<EditDetectorProps> = ({ detector, entity, afterSubmit, cancelSubmit }) => {
  const { formatMessage } = useIntl();
  const { updateTechnic } = useTechnics();
  const { getUnitInventoriesFor } = useUnitInventories();
  let unitInventories: UnitInventory[] = [];
  if (entity === 'unit' && detector.unit) unitInventories = getUnitInventoriesFor(detector.unit.id, new Date());

  const handleAfterSubmit = () => {
    afterSubmit();
  };
  const handleCancel = () => {
    cancelSubmit();
  };

  const handleDetectorEdit = async (
    values: DetectorFormValues,
    { setSubmitting, setStatus }: FormikHelpers<DetectorFormValues>
  ) => {
    const { detectorLastControlDate, detectorUnitInventoryId } = values;
    await updateTechnic(detector, {
      detectorUnitInventoryId,
      detectorLastControlDate: detectorLastControlDate.toISOString(),
    });

    setStatus(true);
    setSubmitting(false);
    handleAfterSubmit();
  };

  return (
    <Formik
      initialValues={{
        detectorLastControlDate: new Date(),
        detectorUnitInventoryId: detector.detectorUnitInventoryId ? detector.detectorUnitInventoryId : '',
      }}
      validationSchema={EditDetectorSchema}
      onSubmit={handleDetectorEdit}
    >
      {({ values, errors, touched, isSubmitting, status }) => (
        <Form>
          <div style={{ marginLeft: 20, marginRight: 20, marginTop: 25, marginBottom: 25 }}>
            <Typography
              style={{
                fontWeight: 'bold',
                fontSize: 20,
                textAlign: 'left',
              }}
            >
              {formatMessage({ id: 'technic.detectorTab.editDetector' })}
            </Typography>
          </div>
          <Divider style={{ marginBottom: 10 }} />
          <div style={{ alignItems: 'baseline', marginLeft: 20, marginRight: 20 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormikDatePicker
                name="detectorLastControlDate"
                label={formatMessage({ id: 'technic.detectorTab.detectorLastControlDate' })}
                value={values.detectorLastControlDate}
                style={{ width: 560 }}
                error={Boolean(errors.detectorLastControlDate && touched.detectorLastControlDate)}
              />
            </div>
            {entity === 'unit' && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <UnitInventorySelectorField
                  unitsInventory={unitInventories}
                  initialValue={detector.detectorUnitInventoryId}
                  fieldName="detectorUnitInventoryId"
                  inputStyle={{ width: 560 }}
                />
              </div>
            )}
          </div>

          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          <div
            style={{
              marginBottom: 20,
              marginRight: 30,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <ActionButton
              onClick={() => {
                handleCancel();
              }}
              style={{ background: 'none', color: Colors.DARK_SLATE_GREY, marginRight: 20 }}
            >
              {toUpper(formatMessage({ id: 'cancel' }))}
            </ActionButton>
            <LoaderButton loading={isSubmitting} success={status}>
              {formatMessage({
                id: 'save',
              })}
            </LoaderButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditDetector;
