import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { ContentHeader , ElevatedPaper } from '@up2rent/ui';
import { useUsers } from 'src/hooks/UsersContext';
import isNil from 'lodash/isNil';
import EditContact from 'src/components/Contacts/EditContact';
import { useSnackbar } from 'notistack';
import { useContacts } from 'src/hooks/ContactsContext';

const CompanyContactForm = () => {
  const { formatMessage } = useIntl();
  const { getClientContact, loading } = useUsers();
  const { contactsLoading } = useContacts();
  const { enqueueSnackbar } = useSnackbar();

  if (loading || contactsLoading) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{formatMessage({ id: 'loading' })}</Typography>
      </ElevatedPaper>
    );
  }

  const clientContact = getClientContact();

  if (isNil(clientContact)) return null;

  return (
    <ElevatedPaper style={{ margin: 20, maxWidth: 640, paddingBottom: 0 }}>
      <ContentHeader title={formatMessage({ id: 'settings.organisation' })} />
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />

      <EditContact
        id={clientContact.id}
        afterSubmit={() => {
          enqueueSnackbar(formatMessage({ id: 'settings.changesSaved' }), { variant: 'success' });
        }}
        displayCancelButton={false}
        displayTitle={false}
        displayTabs={false}
        displayCommercialName={true}
        showSendInvite={false}
      />
    </ElevatedPaper>
  );
};

export default CompanyContactForm;
