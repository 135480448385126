import React from 'react';
import { CSSProperties } from '@material-ui/styles';
import { Colors } from '@rentguru/commons-utils';
import { ReactComponent as Info } from 'src/icons/info.svg';
import FormAlertBox from './FormAlertBox';

interface FormInfoBoxProps {
  message: string;
  subContent?: React.ReactNode;
  gridStyle?: CSSProperties;
  messagesGridStyle?: CSSProperties;
}

const FormInfoBox: React.FC<FormInfoBoxProps> = ({ message, gridStyle, messagesGridStyle, subContent }) => {
  return (
    <FormAlertBox
      message1={message}
      gridStyle={{
        backgroundColor: Colors.PORCELAIN_GREY_1,
        width: '100%',
        marginRight: 30,
        marginLeft: 20,
        ...gridStyle,
      }}
      messagesGridStyle={{ ...messagesGridStyle, color: Colors.SLATE_GREY, marginRight: 30, fontSize: 14 }}
      subContent={subContent}
      icon={<Info style={{ width: 20, height: 20, fill: Colors.SLATE_GREY }} />}
    />
  );
};

export default FormInfoBox;
