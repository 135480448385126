import React, { useState } from 'react';
import isNil from 'lodash/isNil';
import { useIntl, MessageDescriptor } from 'react-intl';
import { ColoredBox } from '@up2rent/ui';
import { useSignatureDocuments } from 'src/hooks/SignatureDocumentContext';
import LeaseSignaturePopoverColumn from '../Leases/Tables/LeaseSignaturePopoverColumn';
import {
  Colors,
  LeaseExtended,
  LeaseStatus,
  SignatureDocumentResult,
  SignatureDocumentStatus,
} from '@rentguru/commons-utils';

interface LeaseProgressStepperProps {
  lease: LeaseExtended;
  style?: React.CSSProperties;
}

export const getStatusAndColorDetail = (
  status: LeaseStatus,
  signatureResults: SignatureDocumentResult[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatMessage: (descriptor: MessageDescriptor, values?: any) => string
) => {
  const draftColor = status === LeaseStatus.Draft ? Colors.CARROT_ORANGE : Colors.BLUEY;
  const pendingColor =
    status === LeaseStatus.OutForSignature
      ? Colors.DODGER_BLUE
      : status === LeaseStatus.Rejected
      ? Colors.CARNATION_RED
      : Colors.BLUEY;
  const pendingText =
    status === LeaseStatus.OutForSignature
      ? formatMessage(
          { id: 'lease.detail.progress.missingSignatures' },
          { missingSignatures: signatureResults.filter((result) => isNil(result.signed)).length }
        )
      : status === LeaseStatus.Rejected
      ? formatMessage({ id: 'enums.LeaseStatus.Rejected' })
      : formatMessage({ id: 'enums.LeaseStatus.OutForSignature' });
  const signedColor = status === LeaseStatus.Active ? Colors.TURQUOISE_BLUE : Colors.BLUEY;
  return { status, draftColor, pendingColor, pendingText, signedColor };
};

const LeaseProgressStepper: React.FC<LeaseProgressStepperProps> = ({ lease, style = {} }) => {
  const { formatMessage } = useIntl();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { getSignatureDocumentsForEntity } = useSignatureDocuments();
  const pendingSignatureDocuments = getSignatureDocumentsForEntity('Lease', lease.id, SignatureDocumentStatus.PENDING);
  const currentSignatureResults =
    pendingSignatureDocuments && pendingSignatureDocuments[0] ? pendingSignatureDocuments[0].signatureResults : [];
  const { draftColor, pendingColor, pendingText, signedColor } = getStatusAndColorDetail(
    lease.status as LeaseStatus,
    currentSignatureResults,
    formatMessage
  );
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', ...style }}>
      <ColoredBox boxColor={draftColor}>{formatMessage({ id: 'enums.LeaseStatus.Draft' })}</ColoredBox>
      <div
        style={{ width: '100%', height: 1, backgroundColor: Colors.SEPARATOR_GREY, marginLeft: 10, marginRight: 10 }}
      />
      <ColoredBox
        boxColor={pendingColor}
        onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
        onMouseLeave={() => setAnchorEl(null)}
      >
        {pendingText}
        {!isNil(anchorEl) &&
          (lease.status === LeaseStatus.OutForSignature || lease.status === LeaseStatus.Rejected) && (
            <LeaseSignaturePopoverColumn leaseId={lease.id} />
          )}
      </ColoredBox>
      <div
        style={{ width: '100%', height: 1, backgroundColor: Colors.SEPARATOR_GREY, marginLeft: 10, marginRight: 10 }}
      />
      <ColoredBox boxColor={signedColor}>
        {formatMessage({
          id: `enums.LeaseStatus.${lease.status}`,
        })}
      </ColoredBox>
    </div>
  );
};

export default LeaseProgressStepper;
