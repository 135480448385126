import { Divider, makeStyles, Typography } from '@material-ui/core';
import { Colors, FileCategory } from '@rentguru/commons-utils';
import { FormikProps, useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import FileFields, { FileFormValues } from 'src/components/ui/Forms/FormField/FileByCategoryFields';
import { useDocumentCategory } from 'src/hooks/FileCategoryContext';

const useLocalStyles = makeStyles({
  container: {
    display: 'flex',
    maxWidth: '92%',
    overflow: 'scroll',
    paddingLeft: 0,
    marginBottom: 13,
    paddingBottom: 17,
    marginTop: 20,
  },
  containerItem: {
    position: 'relative',
    left: '0px',
    display: 'flex',
    minWidth: '100px',
    maxWidth: '100px',
    width: '100px',
    height: '100px',
    border: `dashed 1px ${Colors.BLUEY}`,
    borderRadius: '6px',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '16px',
    color: Colors.BLUEY,
  },
  h6: {
    flex: 1,
    textAlign: 'left' as const,
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Mulish',
    fontSize: '16px',
    fontWeight: 700,
    margin: '20px 0 20px 30px',
  },
});

const PicturesList = () => {
  const localClasses = useLocalStyles();
  const { values }: FormikProps<FileFormValues> = useFormikContext();
  const { formatMessage } = useIntl();
  const { getDocumentCategoryByFileCategory } = useDocumentCategory();

  const advertisementPicturesDocumentCategory = getDocumentCategoryByFileCategory(FileCategory.ADVERTISEMENT_PICTURE);

  const advertisementPicturesIndex = values.filesByCategories.findIndex(
    (cat) => cat.category.fileCategory === FileCategory.ADVERTISEMENT_PICTURE
  );
  const currentValues =
    advertisementPicturesIndex !== -1
      ? values.filesByCategories[advertisementPicturesIndex]
      : { category: advertisementPicturesDocumentCategory, files: [] };

  return (
    <>
      <Typography variant="h6" color="inherit" className={localClasses.h6}>
        {formatMessage({ id: 'rentalUnit.detail.advertisement.pictures' })}({currentValues.files.length})
      </Typography>
      <FileFields
        documentCategory={advertisementPicturesDocumentCategory!}
        categoryType="noHeader"
        dropZoneStyle={{ marginLeft: 14, width: '94.5%' }}
        centerSelectedFile
        displayingPictures={true}
        additionalMimeTypes={['image/heic', 'image/heif']}
      />
      <Divider style={{ marginTop: 30 }} />
    </>
  );
};

export default PicturesList;
