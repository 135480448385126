import { AMPLIFY_CONFIG } from '../config/configSelection';

const S3_BUCKET_NAME_PREFIX = 'rentguru-files-';

export const getCurrentEnv = () => AMPLIFY_CONFIG.aws_user_files_s3_bucket.replace(S3_BUCKET_NAME_PREFIX, '');

export const isDevAccount = () => {
  const currentEnv = getCurrentEnv();
  return currentEnv.startsWith('dev') && currentEnv.length === 5;
};
