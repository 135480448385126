import React from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import { LoaderButton , ActionButton } from '@up2rent/ui';
import { Formik, Form, FormikHelpers } from 'formik';
import { Divider, Typography } from '@material-ui/core';
import { useUnits } from '../../../hooks/UnitsContext';
import { StaticRenovationCostsSchema, Event } from '../../ui/Forms/FormField/RenovationCostsFields';
import toUpper from 'lodash/toUpper';
import { UnitEvent, Colors, shouldUpdate } from '@rentguru/commons-utils';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import OwnUseFields, { getDynamicOwnUseSchema } from 'src/components/ui/Forms/FormField/OwnUseFields';
import { Skeleton } from '@material-ui/lab';

interface EditRenovationFormProps {
  event: UnitEvent;
  afterSubmit?: (event: UnitEvent) => void;
  cancelSubmit?: () => void;
}

const EditRenovationForm: React.FC<EditRenovationFormProps> = ({ event, afterSubmit, cancelSubmit }) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const { updateUnitEvent, unitsLoading, getUnit } = useUnits();

  if (unitsLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton />
      </div>
    );
  }

  const handleAfterSubmit = () => {
    if (!isNil(afterSubmit)) afterSubmit(event);
  };

  const handleCancel = () => {
    if (!isNil(cancelSubmit)) {
      cancelSubmit();
    }
  };

  const handleRenovationUpdate = async (values: Event, { setSubmitting, setStatus }: FormikHelpers<Event>) => {
    const { startDate, endDate, note } = values;
    const updateObject = { startDate, endDate, note };
    if (shouldUpdate(event, updateObject)) {
      await updateUnitEvent(event, updateObject);
    }

    setStatus(true);
    setSubmitting(false);
    handleAfterSubmit();
  };

  const entity = getUnit(event.unitId);
  const validationSchema = !isNil(entity)
    ? getDynamicOwnUseSchema(entity, formatMessage, language)
    : StaticRenovationCostsSchema;

  return (
    <Formik initialValues={event as Event} validationSchema={validationSchema} onSubmit={handleRenovationUpdate}>
      {({ isSubmitting, status }) => {
        return (
          <Form>
            <div style={{ marginLeft: 20, marginRight: 20, marginTop: 25, marginBottom: 25 }}>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: 20,
                  textAlign: 'left',
                }}
              >
                {formatMessage({ id: 'event.editOwnUse.title' })}
              </Typography>
            </div>
            <Divider style={{ marginBottom: 10 }} />
            <div style={{ alignItems: 'baseline', marginLeft: 20, marginRight: 20 }}>
              <OwnUseFields />
            </div>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <div
              style={{
                marginBottom: 20,
                marginRight: 30,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <ActionButton
                onClick={() => {
                  handleCancel();
                }}
                style={{ background: 'none', color: Colors.DARK_SLATE_GREY, marginRight: 20 }}
              >
                {toUpper(formatMessage({ id: 'cancel' }))}
              </ActionButton>
              <LoaderButton loading={isSubmitting} success={status}>
                {formatMessage({ id: 'save' })}
              </LoaderButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditRenovationForm;
