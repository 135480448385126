import { Grid } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import { Colors } from '@rentguru/commons-utils';
import React from 'react';

interface IconnedRemarkProps {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  // eslint-disable-next-line no-undef
  message: JSX.Element;
  style?: CSSProperties;
  iconGridStyles?: CSSProperties;
  iconStyles?: CSSProperties;
  noFixWidth?: boolean;
}

const IconnedRemark: React.FC<IconnedRemarkProps> = ({
  Icon,
  message,
  style,
  iconGridStyles,
  iconStyles,
  noFixWidth = false,
}) => {
  return (
    <Grid
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: Colors.PORCELAIN_GREY_1,
        width: noFixWidth ? undefined : 550,
        margin: '10px 2px 14px 30px',
        padding: '15px 15px 18px 17px',
        borderRadius: 8,
        ...style,
      }}
    >
      <Grid style={{ margin: '0px 10px 0px 0px', display: 'flex', alignItems: 'center', ...iconGridStyles }}>
        <Icon fill={Colors.SLATE_GREY} style={iconStyles} />
      </Grid>
      {message}
    </Grid>
  );
};

export default IconnedRemark;
