import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { Colors } from '@rentguru/commons-utils';

export default withStyles({
  root: {
    color: Colors.BLUE_GREY,
    '&$checked': {
      color: Colors.CHAMBRAY_BLUE,
    },
  },
  checked: {},
})(Checkbox);
