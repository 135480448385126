import React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import { CustomSimpleDialog } from '@up2rent/ui';

interface DiscardChangesDialogProps {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const DiscardChangesDialog: React.FC<DiscardChangesDialogProps> = ({ open, onClose, onConfirm }) => {
  const { formatMessage } = useIntl();

  return (
    <CustomSimpleDialog
      open={open}
      onClose={onClose}
      onActionButtonClick={onConfirm}
      actionButtonLabel={formatMessage({ id: 'yes' })}
      actionButtonId="discardChanges"
      secondaryButtonLabel={formatMessage({ id: 'no' })}
      secondaryButtonDataTest="cancel"
      title={formatMessage({ id: 'warning' })}
      formatMessage={formatMessage}
    >
      <Typography id="alert-dialog-description" style={{ fontSize: 16 }}>
        {formatMessage({ id: 'discardChanges' })}
      </Typography>
    </CustomSimpleDialog>
  );
};

export default DiscardChangesDialog;
