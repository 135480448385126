/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-param-last */
import React, { Reducer, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { useUser } from './UserContext';
import {
  CreateLeaseInventoryCheckedItemInput,
  UpdateLeaseInventoryCheckedItemInput,
  LeaseInventoryCheckedItem,
  cleanInputCreate,
  cleanInputUpdate,
  getReadId,
  getTableClientId,
  syncLeaseInventoryCheckedItems as syncQuery,
  getLeaseInventoryCheckedItem as getQuery,
  createLeaseInventoryCheckedItem as createMutation,
  updateLeaseInventoryCheckedItem as updateMutation,
  deleteLeaseInventoryCheckedItem as deleteMutation,
  CreateLeaseInventoryCheckedItemMutationVariables as CreateMutationVariables,
  DeleteLeaseInventoryCheckedItemMutationVariables as DeleteMutationVariables,
  CreateLeaseInventoryCheckedItemInput as CreateInput,
  Model,
} from '@rentguru/commons-utils';
import { isEmpty, isNil } from 'lodash';
import { usePermissions } from './utils/PermissionsContext';
import {
  deleteEntityWithFetchBefore,
  getFilterFieldNameForIndex,
  list,
  mutation,
  recordWasUpdated,
} from '@up2rent/fetch-utils';

const ENTITY_MODEL_NAME: Model = 'LeaseInventoryCheckedItem';

export interface LeaseInventoryCheckedItemsContext extends LeaseInventoryCheckedItemState {
  getLeaseInventoryCheckedItem: (id: string) => LeaseInventoryCheckedItem | null | undefined;
  createLeaseInventoryCheckedItem: (
    input: Omit<CreateLeaseInventoryCheckedItemInput, 'clientId' | 'readId'>
  ) => Promise<LeaseInventoryCheckedItem>;
  updateLeaseInventoryCheckedItem: (
    original: LeaseInventoryCheckedItem,
    updates: UpdateLeaseInventoryCheckedItemInput
  ) => Promise<LeaseInventoryCheckedItem>;
  deleteLeaseInventoryCheckedItem: (id: string) => Promise<LeaseInventoryCheckedItem | null>;
  setFetchLeaseInventoryCheckedItem: () => void;
}

interface LeaseInventoryCheckedItemState {
  leaseInventoryCheckedItems: LeaseInventoryCheckedItem[] | null;
  loading: boolean;
  lastSync?: Date;
  shouldFetch: boolean;
}

type Action =
  | {
      type: 'SHOULD_FETCH' | 'IS_FETCHING';
    }
  | {
      type: 'ADD_LEASE_INVENTORY_CHECKED_ITEM';
      payload: { leaseInventoryCheckedItem: LeaseInventoryCheckedItem };
    }
  | {
      type: 'FETCHED';
      payload: { leaseInventoryCheckedItems: LeaseInventoryCheckedItem[]; error?: string };
    }
  | {
      type: 'ERROR';
      payload: { error: string };
    }
  | {
      type: 'UPDATE_LEASE_INVENTORY_CHECKED_ITEM';
      payload: { leaseInventoryCheckedItem: LeaseInventoryCheckedItem };
    }
  | {
      type: 'DELETE_LEASE_INVENTORY_CHECKED_ITEM';
      payload: { id: string };
    };

const initialState: LeaseInventoryCheckedItemState = {
  leaseInventoryCheckedItems: [],
  loading: false,
  shouldFetch: false,
};

const leaseInventoryCheckedItemReducer = (
  state: LeaseInventoryCheckedItemState,
  action: Action
): LeaseInventoryCheckedItemState => {
  switch (action.type) {
    case 'SHOULD_FETCH':
      if (state.loading) {
        return state;
      }
      return {
        ...state,
        shouldFetch: true,
      };
    case 'IS_FETCHING':
      return {
        ...state,
        loading: true,
      };

    case 'FETCHED':
      if (action.payload.error) {
        return {
          ...state,
          leaseInventoryCheckedItems: [],
          loading: false,
          shouldFetch: false,
        };
      }
      const leaseInventoryCheckedItems = action.payload.leaseInventoryCheckedItems;
      return {
        ...state,
        loading: false,
        shouldFetch: false,
        lastSync: new Date(),
        leaseInventoryCheckedItems,
      };
    case 'ADD_LEASE_INVENTORY_CHECKED_ITEM':
      return {
        ...state,
        leaseInventoryCheckedItems: [
          ...(state.leaseInventoryCheckedItems ?? []),
          action.payload.leaseInventoryCheckedItem,
        ],
      };
    case 'UPDATE_LEASE_INVENTORY_CHECKED_ITEM':
      if (!state.leaseInventoryCheckedItems) {
        return state;
      }

      const updatedLeaseInventoryCheckedItem = action.payload.leaseInventoryCheckedItem;
      const oldRecord = state.leaseInventoryCheckedItems.find(
        (leaseInventoryCheckedItem) => leaseInventoryCheckedItem.id === updatedLeaseInventoryCheckedItem.id
      );
      if (isNil(oldRecord) || !recordWasUpdated(oldRecord, updatedLeaseInventoryCheckedItem)) {
        return state;
      }

      return {
        ...state,
        leaseInventoryCheckedItems: state.leaseInventoryCheckedItems.map((leaseInventoryCheckedItem) => {
          if (leaseInventoryCheckedItem.id === updatedLeaseInventoryCheckedItem.id) {
            return updatedLeaseInventoryCheckedItem;
          }
          return leaseInventoryCheckedItem;
        }),
      };
    case 'DELETE_LEASE_INVENTORY_CHECKED_ITEM':
      if (!state.leaseInventoryCheckedItems) {
        return state;
      }

      return {
        ...state,
        leaseInventoryCheckedItems: state.leaseInventoryCheckedItems.filter(
          (leaseInventoryCheckedItem) => leaseInventoryCheckedItem.id !== action.payload.id
        ),
      };
    default:
      return state;
  }
};

const fetchLeaseInventoryCheckedItems = async (
  clientId: string,
  additionalFilter?: object
): Promise<LeaseInventoryCheckedItem[]> => {
  return await list<LeaseInventoryCheckedItem>(
    syncQuery,
    getFilterFieldNameForIndex('byClientId'),
    getTableClientId(clientId, ENTITY_MODEL_NAME),
    additionalFilter
  );
};

export const LeaseInventoryCheckedItemsContext = React.createContext<LeaseInventoryCheckedItemsContext | null>(null);

export const LeaseInventoryCheckedItemsContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<LeaseInventoryCheckedItemState, Action>>(
    leaseInventoryCheckedItemReducer,
    initialState
  );
  const { clientId } = useUser();
  const { inventoryOfFixturesCreationDelete, furnituresInventoryCreationDelete } = usePermissions();

  const { loading, leaseInventoryCheckedItems } = state;

  const setFetchLeaseInventoryCheckedItem = () => {
    dispatch({ type: 'SHOULD_FETCH' });
  };

  useEffect(() => {
    const fetchAndSet = async () => {
      dispatch({ type: 'IS_FETCHING' });
      const result = await fetchLeaseInventoryCheckedItems(clientId!);
      dispatch({ type: 'FETCHED', payload: { leaseInventoryCheckedItems: result } });
    };
    if (state.shouldFetch) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetch]);

  const getLeaseInventoryCheckedItem = (id: string) => {
    if (loading || !leaseInventoryCheckedItems) {
      return null;
    }
    return leaseInventoryCheckedItems.find((item) => item.id === id);
  };

  const createLeaseInventoryCheckedItem = async (
    input: Omit<CreateLeaseInventoryCheckedItemInput, 'clientId' | 'readId'>
  ): Promise<LeaseInventoryCheckedItem> => {
    const leaseInventoryCheckedItem = await mutation<LeaseInventoryCheckedItem, CreateMutationVariables>(
      createMutation,
      {
        input: {
          ...(cleanInputCreate(input) as CreateInput),
          clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME),
          readId: getReadId(clientId!, ENTITY_MODEL_NAME),
        },
      }
    );
    dispatch({ type: 'ADD_LEASE_INVENTORY_CHECKED_ITEM', payload: { leaseInventoryCheckedItem } });
    return leaseInventoryCheckedItem;
  };

  const updateLeaseInventoryCheckedItem = async (
    original: LeaseInventoryCheckedItem,
    updates: UpdateLeaseInventoryCheckedItemInput
  ): Promise<LeaseInventoryCheckedItem> => {
    const result = await mutation<LeaseInventoryCheckedItem>(updateMutation, {
      input: { id: original.id, _version: original._version, ...cleanInputUpdate(updates, false) },
    });
    dispatch({ type: 'UPDATE_LEASE_INVENTORY_CHECKED_ITEM', payload: { leaseInventoryCheckedItem: result } });
    return result;
  };

  const deleteLeaseInventoryCheckedItem = async (id: string): Promise<LeaseInventoryCheckedItem | null> => {
    const leaseInventoryCheckedItem = getLeaseInventoryCheckedItem(id);
    if (!leaseInventoryCheckedItem) {
      return null;
    }
    if (!inventoryOfFixturesCreationDelete && !furnituresInventoryCreationDelete) {
      return leaseInventoryCheckedItem;
    }

    await deleteEntityWithFetchBefore<Pick<LeaseInventoryCheckedItem, 'id'>, DeleteMutationVariables>(
      { id: leaseInventoryCheckedItem.id },
      getQuery,
      deleteMutation
    );

    dispatch({ type: 'DELETE_LEASE_INVENTORY_CHECKED_ITEM', payload: { id: leaseInventoryCheckedItem.id } });
    return leaseInventoryCheckedItem;
  };

  const values = useMemo(
    () => ({
      ...state,
      error: null,
      getLeaseInventoryCheckedItem,
      createLeaseInventoryCheckedItem,
      updateLeaseInventoryCheckedItem,
      deleteLeaseInventoryCheckedItem,
      setFetchLeaseInventoryCheckedItem,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return (
    <LeaseInventoryCheckedItemsContext.Provider value={values}>{children}</LeaseInventoryCheckedItemsContext.Provider>
  );
};

export const useLeaseInventoryCheckedItems = (): LeaseInventoryCheckedItemsContext => {
  const firstRender = useRef<boolean>(false);
  const context = useContext<LeaseInventoryCheckedItemsContext | null>(LeaseInventoryCheckedItemsContext);

  if (isNil(context)) {
    throw new Error(
      '`useLeaseInventoryCheckedItems` hook must be used within a `LeaseInventoryCheckedItemsContext` component'
    );
  }
  useEffect(() => {
    if (!firstRender.current && isEmpty(context.leaseInventoryCheckedItems) && isNil(context.lastSync)) {
      context.setFetchLeaseInventoryCheckedItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  return context;
};
