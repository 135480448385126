/* eslint-disable no-redeclare */
import React, { useContext, useEffect, useMemo } from 'react';
import {
  Address,
  syncAddresses as syncQuery,
  getAddress as getQuery,
  createAddress as createMutation,
  updateAddress as updateMutation,
  deleteAddress as deleteMutation,
  CreateAddressMutationVariables as CreateMutationVariables,
  DeleteAddressMutationVariables as DeleteMutationVariables,
  CreateAddressInput as CreateInput,
  cleanInputUpdate,
  cleanInputCreate,
  getReadId,
  getTableClientId,
  isNilOrEmpty,
  Model,
  OnCreateAddressSubscription,
  OnUpdateAddressSubscription,
  OnDeleteAddressSubscription,
  onCreateAddress,
  onUpdateAddress,
  onDeleteAddress,
} from '@rentguru/commons-utils';
import {
  deleteEntityWithFetchBefore,
  getFilterFieldNameForIndex,
  list,
  mutation,
  useSubscriptions,
} from '@up2rent/fetch-utils';
import { useUser } from './UserContext';
import isNil from 'lodash/isNil';
import { ContextLoaderAction, ContextLoaderStore, useContextLoader } from './ContextLoader';
import { isEqual } from 'lodash';

const ENTITY_MODEL_NAME: Model = 'Address';

export interface AddressContext extends AddressState {
  getAddress: (id: string) => Address | undefined;
  createAddress: (input: Omit<Address, 'id' | 'readId' | 'clientId'>) => Promise<Address>;
  updateAddress: (original: Address, updates: Partial<Address>) => Promise<Address>;
  deleteAddress: (id: string) => Promise<void>;
  setFetchAddress: () => void;
}

interface AddressState {
  address: Address[] | null;
  loading: boolean;
  shouldFetchAddress: boolean;
}

export type ActionAddress =
  | {
      type: 'SHOULD_FETCH_ADDRESS' | 'IS_FETCHING_ADDRESS';
    }
  | {
      type: 'ADD_ADDRESS';
      payload: { address: Address };
    }
  | {
      type: 'FETCHED_ADDRESS';
      payload: { addresses: Address[] };
    }
  | {
      type: 'UPDATE_ADDRESS';
      payload: { address: Address };
    }
  | {
      type: 'DELETE_ADDRESS';
      payload: { id: string };
    };

export const addressReducerDelegation = (
  state: ContextLoaderStore,
  action: ContextLoaderAction
): ContextLoaderStore => {
  switch (action.type) {
    case 'SHOULD_FETCH_ADDRESS':
      if (state.Address.loading || state.Address.shouldFetch) {
        return state;
      }
      return {
        ...state,
        Address: { ...state.Address, shouldFetch: true },
      };
    case 'IS_FETCHING_ADDRESS':
      if (state.Address.loading) {
        return state;
      }
      return {
        ...state,
        Address: { ...state.Address, loading: true },
      };
    case 'FETCHED_ADDRESS':
      return {
        ...state,
        Address: {
          ...state.Address,
          data: action.payload.addresses,
          loading: false,
          shouldFetch: false,
          lastFetch: new Date(),
        },
      };
    case 'ADD_ADDRESS':
      // Check if already present - If already added by this user or coming from another user
      if (state.Address.data?.find((address) => address.id === action.payload.address.id)) {
        return state;
      }

      return {
        ...state,
        Address: { ...state.Address, data: [...state.Address.data, action.payload.address] },
      };
    case 'UPDATE_ADDRESS':
      // No data
      if (isNilOrEmpty(state.Address.data)) {
        return state;
      }
      // Already present and same object
      const currentObject = state.Address.data?.find((address) => address.id === action.payload.address.id);
      if (isEqual(currentObject, action.payload.address)) {
        return state;
      }

      // Update
      return {
        ...state,
        Address: {
          ...state.Address,
          data: state.Address.data.map((address) => {
            if (address.id === action.payload.address.id) {
              return action.payload.address;
            }
            return address;
          }),
        },
      };
    case 'DELETE_ADDRESS':
      if (isNilOrEmpty(state.Address.data)) {
        return state;
      }
      return {
        ...state,
        Address: {
          ...state.Address,
          data: state.Address.data.filter((address) => address.id !== action.payload.id),
        },
      };
    default:
      return state;
  }
};

const fetchAddresses = async (clientId: string, additionalFilter?: object): Promise<Address[]> => {
  return await list<Address>(
    syncQuery,
    getFilterFieldNameForIndex('byClientId'),
    getTableClientId(clientId, ENTITY_MODEL_NAME),
    additionalFilter
  );
};

export const AddressContext = React.createContext<AddressContext | null>(null);

export const AddressContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { clientId } = useUser();
  const { Address: contextAddressState, dispatch: contextDispatch } = useContextLoader();

  const setFetchAddress = () => {
    contextDispatch({ type: 'SHOULD_FETCH_ADDRESS' });
  };

  useEffect(() => {
    const fetchAndSet = async () => {
      contextDispatch({ type: 'IS_FETCHING_ADDRESS' });
      const result = await fetchAddresses(clientId!);
      contextDispatch({ type: 'FETCHED_ADDRESS', payload: { addresses: result } });
    };
    if (contextAddressState.shouldFetch) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextAddressState.shouldFetch]);

  useSubscriptions<OnCreateAddressSubscription, OnUpdateAddressSubscription, OnDeleteAddressSubscription>(
    onCreateAddress,
    onUpdateAddress,
    onDeleteAddress,
    (data) => {
      contextDispatch({
        type: 'ADD_ADDRESS',
        payload: { address: data.onCreateAddress as Address },
      });
    },
    (data) => {
      contextDispatch({
        type: 'UPDATE_ADDRESS',
        payload: { address: data.onUpdateAddress as Address },
      });
    },
    (data) => {
      const { id } = data.onDeleteAddress as Address;
      contextDispatch({
        type: 'DELETE_ADDRESS',
        payload: { id },
      });
    }
  );

  const createAddress = async (input: Omit<CreateInput, 'clientId' | 'readId'>): Promise<Address> => {
    const address = await mutation<Address, CreateMutationVariables>(createMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateInput),
        clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME),
        readId: getReadId(clientId!, ENTITY_MODEL_NAME),
      },
    });
    contextDispatch({ type: 'ADD_ADDRESS', payload: { address } });
    return address;
  };

  const updateAddress = async (original: Address, updates: Partial<Address>): Promise<Address> => {
    const result = await mutation<Address>(updateMutation, {
      input: { ...cleanInputUpdate({ ...updates, id: original.id, _version: original._version }, false) },
    });
    contextDispatch({ type: 'UPDATE_ADDRESS', payload: { address: result } });
    return result;
  };

  const deleteAddress = async (id: string): Promise<void> => {
    await deleteEntityWithFetchBefore<Pick<Address, 'id'>, DeleteMutationVariables>({ id }, getQuery, deleteMutation);

    contextDispatch({ type: 'DELETE_ADDRESS', payload: { id } });
  };

  const getAddress = (id: string) => {
    if (contextAddressState.loading) {
      return undefined;
    }
    return (contextAddressState.data ?? []).find((stateObject) => stateObject.id === id);
  };

  const values = useMemo(
    () => ({
      ...contextAddressState,
      address: contextAddressState.data,
      shouldFetchAddress: contextAddressState.shouldFetch,
      createAddress,
      getAddress,
      updateAddress,
      deleteAddress,
      setFetchAddress,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contextAddressState]
  );

  return <AddressContext.Provider value={values}>{children}</AddressContext.Provider>;
};

export const useAddresses = (): AddressContext => {
  const context = useContext<AddressContext | null>(AddressContext);
  if (isNil(context)) {
    throw new Error('`useLeaseActionHistories` hook must be used within a `AddressContext` component');
  }

  return context;
};
