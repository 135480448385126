import { Divider, Grid, Typography } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import { useFormikContext } from 'formik';
import { isNil } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { AddLeaseFormValues } from 'src/components/Leases/AddLease/AddLeaseForm';
import {
  isLeaseAlreadyStarted,
  resolveMainUnitAndSubUnitIndexAndPrefix,
} from 'src/components/Leases/AddLease/useAddEditLeaseUtils';
import { useUnits } from 'src/hooks/UnitsContext';
import { ElevatedPaper } from '@up2rent/ui';
import FormHeader from '../../FormHeader';
import KeyDatesFields from '../FormField/KeyDatesFields';

interface KeyDatesProps {
  unitId?: string;
}

const KeyDates: React.FC<KeyDatesProps> = ({ unitId }) => {
  const { formatMessage } = useIntl();
  const { values } = useFormikContext<AddLeaseFormValues>();
  const { getUnit } = useUnits();

  if (isNil(unitId)) {
    return null;
  }
  const currentUnit = getUnit(unitId);
  if (isNil(currentUnit)) {
    return null;
  }
  const { isMainUnit } = resolveMainUnitAndSubUnitIndexAndPrefix(values, unitId);
  return (
    <>
      <ElevatedPaper>
        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography
            style={{
              fontSize: '12px',
              textAlign: 'center',
              color: Colors.LIGHT_BLUE_GREY,
            }}
          >
            {formatMessage({ id: `lease.addLease.${isMainUnit ? 'mainUnit' : 'subUnit'}` }, { value: 1 })}
          </Typography>
          <Typography
            style={{
              fontSize: '14px',
            }}
          >
            {currentUnit.name}
          </Typography>
        </Grid>
      </ElevatedPaper>
      {isLeaseAlreadyStarted(values.startDate) && !values.allowedToCheckAllDatesAreSame && (
        <>
          <ElevatedPaper>
            <FormHeader title={formatMessage({ id: 'lease.addLease.keyDates' })} />
            <Divider style={{ marginBottom: 10 }} />
            <KeyDatesFields unitId={unitId} />
          </ElevatedPaper>
        </>
      )}
    </>
  );
};

export default KeyDates;
