import { createTheme } from '@material-ui/core/styles';
import { Colors } from '@rentguru/commons-utils';

export const getUp2rentTheme = (fontFamily: string = 'Mulish') =>
  createTheme({
    overrides: {
      MuiFormControl: {
        root: {
          fontFamily: fontFamily,
          fontSize: 16,
          fontWeight: 400,
          color: 'black',
          textAlign: 'left',
        },
      },
      MuiFormLabel: {
        asterisk: {
          display: 'none',
        },
      },
      MuiAvatar: {
        root: {
          width: 36,
          height: 36,
        },
      },
      MuiFilledInput: {
        root: {
          backgroundColor: Colors.PORCELAIN_GREY_3,
          borderTopLeftRadius: '6px',
          borderTopRightRadius: '6px',
          '&$disabled': {
            backgroundColor: Colors.PORCELAIN_GREY_1,
            borderBottomStyle: 'hidden',
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
          },
          '&$disabled:before': {
            borderBottomStyle: 'hidden',
          },
        },
        underline: {
          '&$disabled:before': {
            display: `none`,
          },
        },
      },
      MuiLink: {
        root: {
          color: Colors.STRONG_PINK,
        },
      },
      MuiAppBar: {
        root: {
          backgroundColor: Colors.CLASSICAL_WHITE,
          height: 80,
        },
        colorPrimary: {
          backgroundColor: Colors.CLASSICAL_WHITE,
        },
      },
      MuiStepIcon: {
        root: {
          width: '18px',
          height: '18px',
          color: Colors.GEYSER_GREY,
          '&$completed': {
            color: Colors.TURQUOISE_BLUE,
          },
          '&$active': {
            color: Colors.DODGER_BLUE,
          },
        },
        text: {
          fontSize: '13px',
          fontWeight: 'bold',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 1.08,
          letterSpacing: 'normal',
          textAlign: 'center',
          '&$active': {
            fill: Colors.CLASSICAL_WHITE,
          },
          fill: Colors.TOWER_GREY,
        },
        active: {
          color: Colors.DODGER_BLUE,
        },
        completed: {
          color: Colors.TURQUOISE_BLUE,
        },
      },
      MuiStepLabel: {
        label: {
          fontSize: '13px',
          letterSpacing: '1.3px',
          color: Colors.TOWER_GREY,
          fontWeight: 600,
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 1.08,
          textAlign: 'left',
          '&$active': {
            color: Colors.BLUE_GREY,
            fontWeight: 'bold',
          },
          '&$completed': {
            color: Colors.TOWER_GREY,
          },
        },
        active: {
          fontSize: '13px',
          letterSpacing: '1.3px',
          color: Colors.BLUE_GREY,
          fontWeight: 'bold',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 1.08,
          textAlign: 'left',
        },
        completed: {
          fontSize: '13px',
          letterSpacing: '1.3px',
          color: Colors.TOWER_GREY,
          fontWeight: 'bold',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 1.08,
          textAlign: 'left',
        },
      },
      MuiStepper: {
        root: {
          padding: 30,
        },
        vertical: {
          alignItems: 'flex-start',
          flexDirection: 'column',
        },
      },
      MuiStep: {
        vertical: {
          paddingBottom: 20,
        },
      },
      MuiTab: {
        wrapper: {
          fontSize: '12px',
          fontWeight: 'bold',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: '1.2px',
          textAlign: 'center',
          '&$selected': {
            fontWeight: 'bold',
            fontSize: '12px',
            letterSpacing: '1.2px',
          },
        },
        textColorInherit: {
          color: Colors.DARK,
          '&$selected': {
            color: Colors.SCARLET_ORANGE,
          },
        },
      },
      MuiInputBase: {
        root: {
          fontFamily: fontFamily,
          fontSize: 16,
          '&$disabled': {
            backgroundColor: Colors.PORCELAIN_GREY_1,
            borderBottomStyle: 'hidden',
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
          },
        },
        input: {
          borderTopLeftRadius: '6px',
          borderTopRightRadius: '6px',
        },
        inputMultiline: {
          borderTopLeftRadius: '6px',
          borderTopRightRadius: '6px',
          '&$disabled': {
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
          },
          '&$disabled:before': {
            borderBottomStyle: 'hidden',
          },
        },
      },
      MuiDialog: {
        paper: {
          borderRadius: 10,
        },
      },
    },

    typography: {
      fontFamily: fontFamily,
      fontSize: 14,
      body1: {
        fontFamily: fontFamily,
        fontSize: 12,
      },
      h6: {
        opacity: 0.9,
        fontFamily: fontFamily,
        fontSize: 16,
        fontWeight: 'bold',
        color: Colors.CLASSICAL_BLACK,
      },
    },
  });
