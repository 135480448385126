/* eslint-disable react/no-array-index-key */
import { Avatar, Chip, Grid, Tooltip, Typography } from '@material-ui/core';
import {
  AgencyRate,
  Colors,
  Contact,
  ContactType,
  UnitType,
  contactContainsTypes,
  getFirstLettersUppercase,
  ownerIdsToCustomComboItem,
  ownersToCustomComboItem,
} from '@rentguru/commons-utils';
import { Form, Formik, FormikHelpers } from 'formik';
import { isEmpty, toLower, xor } from 'lodash';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import CustomInputComboBox, { CustomInputItemType } from 'src/components/ui/ComboBox/FullCustomComboBox';
import { CustomSimpleDialog } from '@up2rent/ui';
import { useAgencyRates } from 'src/hooks/AgencyRatesContext';
import { useContacts } from 'src/hooks/ContactsContext';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';

interface AddOwnerToAgencyRateProps {
  open: boolean;
  onClose: () => void;
  agencyRate: AgencyRate;
}

interface FormValues {
  ownerIds: string[];
}

const AddOwnerToAgencyRate: React.FC<AddOwnerToAgencyRateProps> = ({ open, onClose, agencyRate }) => {
  const { formatMessage } = useIntl();
  const { loading, createAgencyRateOwner, getAgencyRateOwnersOfOwner } = useAgencyRates();
  const { contactsLoading, owners, jointOwners } = useContacts();

  const dataLoading = loading || contactsLoading;

  const assignableOwners = useMemo<Contact[]>(() => {
    if (dataLoading) {
      return [];
    }
    const agencyRateWithAllTypes = isEmpty(xor(agencyRate.unitType, Object.keys(UnitType)));
    return [...owners, ...jointOwners].reduce((result: Contact[], currentOwner) => {
      if (currentOwner.agencyRateDisabled) {
        return result;
      }

      if (contactContainsTypes(currentOwner, [ContactType.CLIENT, ContactType.MEMBER])) {
        return result;
      }

      // Take only owners that can be assigned to this rate card:
      const agencyRatesOfOwner = getAgencyRateOwnersOfOwner(currentOwner.id);
      if (!agencyRatesOfOwner || isEmpty(agencyRatesOfOwner)) {
        // No rate card
        result.push(currentOwner);
        return result;
      }

      if (agencyRateWithAllTypes) {
        // If this rate card type is including all unit type, only return owner with no rate card (before)
        return result;
      }

      // Still no rate card for this type
      const alreadyThisUnitType = agencyRatesOfOwner.some((aro) =>
        (aro.agencyRate!.unitType as UnitType[]).some(
          (unitType: UnitType) => agencyRate.unitType.indexOf(unitType) >= 0
        )
      );
      if (!alreadyThisUnitType) {
        result.push(currentOwner);
      }

      return result;
    }, []);
  }, [owners, jointOwners, dataLoading, agencyRate, getAgencyRateOwnersOfOwner]);

  if (dataLoading) {
    return null;
  }

  const handleSubmit = async (values: FormValues, { setSubmitting, setStatus }: FormikHelpers<FormValues>) => {
    const createAgencyRateOwnersPromises = values.ownerIds.map((ownerId) =>
      createAgencyRateOwner({ agencyRateId: agencyRate.id, contactId: ownerId })
    );
    await Promise.all(createAgencyRateOwnersPromises);
    setSubmitting(false);
    setStatus(true);
    onClose();
  };

  return (
    <Formik
      initialValues={
        {
          ownerIds: [],
        } as FormValues
      }
      onSubmit={handleSubmit}
    >
      {({ values, handleSubmit: onActionButtonClick, isSubmitting, setFieldValue }) => {
        const { ownerIds } = values;
        const options: CustomInputItemType[] = ownersToCustomComboItem(assignableOwners);
        return (
          <Form>
            <CustomSimpleDialog
              open={open}
              onClose={onClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              title={formatMessage({ id: 'agencyRates.addAgencyRateOwner.dialogTitle' }, { name: agencyRate.name })}
              dividerBelowTitle
              onActionButtonClick={onActionButtonClick}
              actionButtonLabel={formatMessage({ id: 'agencyRates.addAgencyRateOwner.dialogAction' })}
              actionButtonId="validationButton"
              actionButtonLoading={isSubmitting}
              formatMessage={formatMessage}
            >
              <Grid style={{ marginTop: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <CustomInputComboBox
                  onChange={(e, changedValues) => {
                    if (changedValues) {
                      setFieldValue('ownerIds', [...changedValues.map((option) => option.value)]);
                    }
                  }}
                  value={ownerIdsToCustomComboItem(ownerIds, assignableOwners) as CustomInputItemType[]}
                  label={formatMessage({ id: 'agencyRates.owners' })}
                  options={options}
                  // emptyValue={{ name: '', value: null, color: null, type: '' }}
                  inputStyle={{ width: 520 }}
                  renderOption={(value: CustomInputItemType) => {
                    const contactType = formatMessage({ id: `contact.type.${toLower(value.type)}` });
                    return (
                      <Grid
                        container
                        alignContent="space-between"
                        alignItems="center"
                        style={{ marginBottom: '5px', marginTop: '5px' }}
                      >
                        <Grid item xs={10} style={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar
                            style={{
                              color: `${value.color}`,
                              backgroundColor: 'rgba(0,0,0,0)',
                              border: `1.5px ${value.color} solid`,
                              fontSize: '10px',
                              fontWeight: 'bold',
                              width: 20,
                              height: 20,
                              marginRight: 10,
                            }}
                          >
                            {getFirstLettersUppercase(value.name)}
                          </Avatar>
                          {value.name}
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                          <Typography
                            style={{
                              color: Colors.BLUE_GREY,
                              fontFamily: 'Mulish',
                              fontSize: '9.5px',
                              letterSpacing: '0.6px',
                            }}
                          >
                            {contactType}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  }}
                  renderInput={(value: CustomInputItemType) => {
                    return (
                      <>
                        <Avatar
                          style={{
                            color: `${value.color}`,
                            backgroundColor: 'rgba(0,0,0,0)',
                            border: `1.5px ${value.color} solid`,
                            fontSize: '8px',
                            fontWeight: 'bold',
                            width: 15,
                            height: 15,
                            marginRight: 10,
                          }}
                        >
                          {getFirstLettersUppercase(value.name)}
                        </Avatar>
                        {value.name}
                      </>
                    );
                  }}
                  renderTags={(value: CustomInputItemType[], getTagProps) => {
                    return value.map((option: CustomInputItemType, index: number) => (
                      <Chip key={`${index}`} variant="outlined" label={option.name} {...getTagProps({ index })} />
                    ));
                  }}
                  getOptionSelected={(option1: CustomInputItemType, option2: CustomInputItemType) => {
                    return option1.value === option2.value;
                  }}
                  stringToCompare={(value: CustomInputItemType) => value.name}
                  noOptionsText={formatMessage({ id: 'comboBox.noContacts2' })}
                  disableCloseOnSelect
                />
                <Tooltip title={formatMessage({ id: 'agencyRates.addAgencyRateOwner.dialogInfo' })} placement="bottom">
                  <Grid>
                    <InfoSvg style={{ fill: Colors.SILVER, paddingLeft: 10 }} />
                  </Grid>
                </Tooltip>
              </Grid>
            </CustomSimpleDialog>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddOwnerToAgencyRate;
