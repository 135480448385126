import { FormControlLabel, Grid, makeStyles, RadioGroup, Typography } from '@material-ui/core';
import { DelimitedZoneType, FloodProneAreaType } from '@rentguru/commons-utils';
import { useFormikContext } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { EditAdvertisementFormValues } from 'src/components/RentalUnits/Details/Publication/EditPublication/EditPublicationForm';
import useEditPublicationFormStyles from 'src/components/RentalUnits/Details/Publication/EditPublication/utils/EditPublicationFormStyle';
import StyledRadio from 'src/components/ui/StyledRadio';

const useLocalStyles = makeStyles({
  title: { margin: '0 0 10px 5px' },
  radioGroup: { margin: '0 0 0 10px' },
});

const FloodEdits = () => {
  const { values, setFieldValue } = useFormikContext<EditAdvertisementFormValues>();
  const { formatMessage } = useIntl();
  const classes = useLocalStyles();
  const publicationFormClasses = useEditPublicationFormStyles();

  return (
    <Grid container style={{ justifyContent: 'space-evenly' }}>
      <Grid item style={{ width: 280, justifyContent: 'left' }}>
        <Typography variant="h6" className={`${classes.title} ${publicationFormClasses.title}`}>
          {formatMessage({ id: 'rentalUnit.detail.more.floodProneAreas.floodProneAreas' })}
        </Typography>
        <RadioGroup
          className={classes.radioGroup}
          aria-label="floodProneAreas"
          name="advertisement.floodProneArea"
          value={values.advertisement.floodProneArea}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('advertisement.floodProneArea', e.target.value);
          }}
        >
          <FormControlLabel
            value={FloodProneAreaType.RECOGNIZED_FLOOD_ZONE}
            control={<StyledRadio size="small" name={FloodProneAreaType.RECOGNIZED_FLOOD_ZONE} />}
            label={
              <Typography className={publicationFormClasses.label}>
                {formatMessage({
                  id: `rentalUnit.detail.more.floodProneAreas.${FloodProneAreaType.RECOGNIZED_FLOOD_ZONE}`,
                })}
              </Typography>
            }
          />
          <FormControlLabel
            value={FloodProneAreaType.POTENTIAL_FLOOD_ZONE}
            control={<StyledRadio size="small" name="potentialFloodZone" />}
            label={
              <Typography className={publicationFormClasses.label}>
                {formatMessage({
                  id: `rentalUnit.detail.more.floodProneAreas.${FloodProneAreaType.POTENTIAL_FLOOD_ZONE}`,
                })}
              </Typography>
            }
          />
          <FormControlLabel
            value={FloodProneAreaType.NON_FLOODABLE_ZONE}
            control={<StyledRadio size="small" name="nonFloodableArea" />}
            label={
              <Typography className={publicationFormClasses.label}>
                {formatMessage({
                  id: `rentalUnit.detail.more.floodProneAreas.${FloodProneAreaType.NON_FLOODABLE_ZONE}`,
                })}
              </Typography>
            }
          />
          <FormControlLabel
            value={DelimitedZoneType.NOT_COMMUNICATED}
            control={<StyledRadio size="small" name="notCommunicated" />}
            label={
              <Typography className={publicationFormClasses.label}>
                {formatMessage({
                  id: `rentalUnit.detail.more.floodProneAreas.${DelimitedZoneType.NOT_COMMUNICATED}`,
                })}
              </Typography>
            }
          />
        </RadioGroup>
      </Grid>

      <Grid item style={{ width: 280 }}>
        <Typography variant="h6" className={`${classes.title} ${publicationFormClasses.title}`}>
          {formatMessage({ id: 'rentalUnit.detail.more.delimitedZone.delimitedZone' })}
        </Typography>
        <RadioGroup
          className={classes.radioGroup}
          aria-label="advertisement.delimitedZone"
          name="advertisement.delimitedZone"
          value={values.advertisement.delimitedZone}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue('advertisement.delimitedZone', e.target.value)
          }
        >
          <FormControlLabel
            value={DelimitedZoneType.DELIMITED_FLOOD_ZONE}
            control={<StyledRadio size="small" name="delimitedFloodZone" />}
            label={
              <Typography className={publicationFormClasses.label}>
                {formatMessage({
                  id: `rentalUnit.detail.more.delimitedZone.${DelimitedZoneType.DELIMITED_FLOOD_ZONE}`,
                })}
              </Typography>
            }
          />
          <FormControlLabel
            value={DelimitedZoneType.DELIMITED_REPARIAN_ZONE}
            control={<StyledRadio size="small" name="delimitedReparianZone" />}
            label={
              <Typography className={publicationFormClasses.label}>
                {formatMessage({
                  id: `rentalUnit.detail.more.delimitedZone.${DelimitedZoneType.DELIMITED_REPARIAN_ZONE}`,
                })}
              </Typography>
            }
          />
          <FormControlLabel
            value={DelimitedZoneType.NOT_COMMUNICATED}
            control={<StyledRadio size="small" name="notCommunicated" />}
            label={
              <Typography className={publicationFormClasses.label}>
                {formatMessage({ id: `rentalUnit.detail.more.delimitedZone.${DelimitedZoneType.NOT_COMMUNICATED}` })}
              </Typography>
            }
          />
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

export default FloodEdits;
