import { TableCell, TableRow, Typography } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import React from 'react';
import { useIntl } from 'react-intl';

const TicketsHeader = () => {
  const { formatMessage } = useIntl();
  const headerPadding = { paddingLeft: 0, paddingBottom: 10, paddingRight: 0, paddingTop: 10 };
  return (
    <TableRow>
      <TableCell align="left" padding="none" style={{ ...headerPadding, paddingLeft: 20 }}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'tickets.list.clientName' })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none" style={headerPadding}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'tickets.list.subject' })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none" style={headerPadding}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'tickets.list.status' })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none" style={headerPadding}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'tickets.list.createdAt' })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none" style={headerPadding}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'tickets.list.building' })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none" style={headerPadding}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'tickets.list.user' })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none" style={headerPadding}>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {formatMessage({ id: 'tickets.list.plannedAt' })}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default TicketsHeader;
