import { Grid, MenuItem, Typography } from '@material-ui/core';
import { NotificationOption } from '@rentguru/commons-utils';
import { useFormikContext } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import CustomizedSelect from 'src/components/ui/CustomizedSelect';
import HelpIconPopover from 'src/components/ui/HelpIconPopover';
import { ChangeRemindersValues } from './RemindersForm';

interface MaintenanceRemindersFormRowProps {
  title: string;
  value: NotificationOption;
  name: string;
  helperText: string;
  disabled: boolean;
}

const MaintenanceRemindersFormRow: React.FC<MaintenanceRemindersFormRowProps> = ({
  title,
  value,
  name,
  helperText,
  disabled,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Grid container style={{ alignItems: 'center' }}>
      <Grid item xs={7} style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Typography style={{ fontSize: 14, fontWeight: 600 }}>{title}</Typography>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={3}>
        <CustomizedSelect
          fieldName={name}
          value={value}
          FormControlProps={{ style: { width: 120, fontSize: 13 } }}
          disabled={disabled}
        >
          <MenuItem key="ENABLED" value={NotificationOption.ENABLED}>
            {formatMessage({ id: 'settings.communications.enabled' })}
          </MenuItem>
          <MenuItem key="DISABLED" value={NotificationOption.DISABLED}>
            {formatMessage({ id: 'settings.communications.disabled' })}
          </MenuItem>
        </CustomizedSelect>
      </Grid>
      <Grid item xs={1}>
        <HelpIconPopover
          helperText={helperText}
          paperStyle={{ maxWidth: 350 }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        />
      </Grid>
    </Grid>
  );
};

interface MaintenanceRemindersFormProps {
  disabled: boolean;
}

const MaintenanceRemindersForm: React.FC<MaintenanceRemindersFormProps> = ({ disabled }) => {
  const { formatMessage } = useIntl();
  const { values } = useFormikContext<ChangeRemindersValues>();
  return (
    <>
      <Typography style={{ fontSize: 16, fontWeight: 700, textAlign: 'left' }}>
        {formatMessage({ id: 'settings.reminders.maintenanceTitle' })}
      </Typography>
      <MaintenanceRemindersFormRow
        title={formatMessage({ id: 'settings.reminders.heatingMaintenanceTitle' })}
        value={values.heatingReminder}
        name="heatingReminder"
        disabled={disabled}
        helperText={formatMessage({ id: 'settings.reminders.heatingMaintenanceHelper' })}
      />
      <MaintenanceRemindersFormRow
        title={formatMessage({ id: 'settings.reminders.chimneyMaintenanceTitle' })}
        value={values.chimneyReminder}
        name="chimneyReminder"
        disabled={disabled}
        helperText={formatMessage({ id: 'settings.reminders.chimneyMaintenanceHelper' })}
      />
      <MaintenanceRemindersFormRow
        title={formatMessage({ id: 'settings.reminders.epbMaintenanceTitle' })}
        value={values.pebReminder}
        name="pebReminder"
        disabled={disabled}
        helperText={formatMessage({ id: 'settings.reminders.epbMaintenanceHelper' })}
      />
      <MaintenanceRemindersFormRow
        title={formatMessage({ id: 'settings.reminders.fuelTanksMaintenanceTitle' })}
        value={values.tankReminder}
        name="tankReminder"
        disabled={disabled}
        helperText={formatMessage({ id: 'settings.reminders.fuelTanksMaintenanceHelper' })}
      />
      <MaintenanceRemindersFormRow
        title={formatMessage({ id: 'settings.reminders.detectorsMaintenanceTitle' })}
        value={values.detectorReminder}
        name="detectorReminder"
        disabled={disabled}
        helperText={formatMessage({ id: 'settings.reminders.detectorsMaintenanceHelper' })}
      />
    </>
  );
};

export default MaintenanceRemindersForm;
