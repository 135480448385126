import { CommunicationSettingsProfileReferent } from '@rentguru/commons-utils';
import AddCommunicationSettingsProfileDialog from './AddCommunicationSettingsProfileDialog';
import OwnerCommunicationSettingsForm from '../CommunicationSettingsProfileForm/OwnerCommunicationSettingsProfileForm';
import {
  OwnerCommunicationSettingsFormValues,
  ownerDefaultSettings,
} from '../CommunicationSettingsProfileUtils/OwnerCommunicationSettingsUtils';

const initialValues: OwnerCommunicationSettingsFormValues = {
  defaultName: '',
  ...ownerDefaultSettings,
};

const AddOwnerCommunicationSettingsProfileDialog: React.FC<{ closeDialog: () => void }> = ({ closeDialog }) => (
  <AddCommunicationSettingsProfileDialog
    initialValues={initialValues}
    referent={CommunicationSettingsProfileReferent.OWNER}
    closeDialog={closeDialog}
    CustomForm={OwnerCommunicationSettingsForm}
  />
);
export default AddOwnerCommunicationSettingsProfileDialog;
