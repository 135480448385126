import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import { isNil } from 'lodash';
import { CSSProperties, ReactNode } from 'react';
import { type IntlFormatters } from 'react-intl';
import { ActionButton, LoaderButton } from '../buttons';

export const useCustomSimpleDialogStyles = makeStyles({
  buttonsGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 15,
    marginRight: 9,
  },
  cancelButton: {
    background: 'none',
    color: Colors.DARK_SLATE_GREY,
    marginRight: 20,
    textTransform: 'uppercase',
    '&:hover': {
      background: 'none',
    },
  },
  confirmButton: {
    minWidth: 120,
    height: 50,
    fontSize: 16,
    fontWeight: 800,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
});

export interface CustomSimpleDialogProps {
  open: boolean;
  onClose: (() => void) | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
  onActionButtonClick?: (() => void) | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
  onSecondaryButtonClick?: (() => void) | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
  onThirdButtonClick?: (() => void) | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
  title?: string;
  actionButtonDataTest?: string;
  actionButtonDisabled?: boolean;
  actionButtonId?: string;
  actionButtonLabel: string;
  actionButtonLoading?: boolean;
  actionButtonAutofocus?: boolean;
  secondaryButtonDataTest?: string;
  secondaryButtonId?: string;
  secondaryButtonLabel?: string;
  secondaryButtonDisabled?: boolean;
  thirdButtonLabel?: string;
  hideThirdButton?: boolean;
  hideSecondaryButton?: boolean;
  dividerBelowTitle?: boolean;
  maxWidth?: number;
  Subtitle?: ReactNode;
  children: ReactNode;
  contentGridStyle?: CSSProperties;
  formatMessage: IntlFormatters['formatMessage'];
}

export const CustomSimpleDialog: React.FC<CustomSimpleDialogProps> = ({
  open,
  onClose,
  onActionButtonClick = () => {},
  onSecondaryButtonClick,
  onThirdButtonClick,
  title,
  actionButtonId,
  actionButtonLabel,
  actionButtonLoading = false,
  actionButtonDisabled = false,
  actionButtonDataTest,
  actionButtonAutofocus = false,
  secondaryButtonDataTest,
  secondaryButtonId,
  secondaryButtonLabel,
  secondaryButtonDisabled = false,
  thirdButtonLabel,
  hideSecondaryButton = false,
  hideThirdButton = true,
  dividerBelowTitle = false,
  maxWidth,
  Subtitle,
  children,
  contentGridStyle = {},
  formatMessage,
}) => {
  const classes = useCustomSimpleDialogStyles();
  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // We check the number of arguments
    if (onActionButtonClick.length === 0) {
      (onActionButtonClick as () => void)();
      return;
    }
    onActionButtonClick(event);
  };

  const onSecondaryClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // We check the number of arguments
    if (onSecondaryButtonClick!.length === 0) {
      (onSecondaryButtonClick as () => void)();
      return;
    }
    onSecondaryButtonClick!(event);
  };

  const onCloseClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // We check the number of arguments
    if (onClose.length === 0) {
      (onClose as () => void)();
      return;
    }
    onClose(event);
  };

  return (
    <Dialog
      open={open}
      onClose={onCloseClick}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      disableEnforceFocus // Needed for Marker.io
      disableRestoreFocus
      PaperProps={{ style: maxWidth ? { maxWidth: maxWidth } : {} }}
      onMouseDown={(e: React.MouseEvent) => {
        e.stopPropagation();
      }}
    >
      {title && (
        <DialogTitle id="alert-dialog-title" style={{ fontSize: 50 }}>
          <Typography className={classes.title}>{title}</Typography>
        </DialogTitle>
      )}
      {dividerBelowTitle && <Divider />}
      {!isNil(Subtitle) && <Grid style={{ paddingLeft: 24, paddingRight: 24 }}>{Subtitle}</Grid>}
      <DialogContent style={{ paddingTop: 0 }}>
        <Grid style={{ marginBottom: 16, paddingTop: 0, ...contentGridStyle }}>{children}</Grid>
      </DialogContent>
      <Divider />
      <DialogActions className={classes.buttonsGrid}>
        {!hideThirdButton && (
          <ActionButton className={classes.cancelButton} onClick={onThirdButtonClick}>
            {thirdButtonLabel}
          </ActionButton>
        )}
        {!hideSecondaryButton && (
          <ActionButton
            className={classes.cancelButton}
            onClick={onSecondaryButtonClick ? onSecondaryClick : onCloseClick}
            id={secondaryButtonId}
            disabled={secondaryButtonDisabled}
            data-test={secondaryButtonDataTest}
          >
            {secondaryButtonLabel ?? formatMessage({ id: 'cancel' })}
          </ActionButton>
        )}
        <LoaderButton
          loading={actionButtonLoading}
          onClick={onClick}
          disabled={actionButtonDisabled}
          id={actionButtonId}
          data-test={actionButtonDataTest}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={actionButtonAutofocus}
        >
          {actionButtonLabel}
        </LoaderButton>
      </DialogActions>
    </Dialog>
  );
};
